import { useEffect } from 'react';

const useCommands = (key, callback) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === key && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        callback();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [key, callback]);
};

export default useCommands;
