import TableView from '@/layouts/TableView';
import { BackTop, Button, Modal, Table, TablePaginationConfig } from 'antd';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ContainerView from '@/layouts/ContainerView';
import { IDocumentTemplate } from '@/types/document.model';
import colors from '@/styles/colors';
import TableRowActions from './TableRowActions';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { RSA_X931_PADDING } from 'constants';

moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  documentType: 'CONTRACT' | 'DOCUMENT';
  loadingTemplates: boolean;
  filteringTemplates: boolean;
  filteredTemplates: IDocumentTemplate[];
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
  onDeleteTemplate: (templateId: string) => void;
}

const DocumentsList: React.FC<Props> = ({
  className,
  documentType,
  loadingTemplates,
  filteringTemplates,
  filteredTemplates,
  setFilterSearch,
  onDeleteTemplate,
}) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);
  const [sortInfo, setSortInfo] = useState<SorterResult<IDocumentTemplate>>({});
  const history = useHistory();

  useEffect(() => {
    const template_sorter = localStorage.getItem('template_sorter');
    if (template_sorter) {
      const json = JSON.parse(template_sorter);
      setSortInfo(json);
    }
  }, []);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.ID'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: IDocumentTemplate, b: IDocumentTemplate) => (a.id as any) - (b.id as any),
        sortOrder: sortInfo.columnKey === 'id' ? sortInfo.order : false,
      },
      {
        title: t('GLOBAL.NAME'),
        key: 'name',
        dataIndex: 'name',
        sorter: (a: IDocumentTemplate, b: IDocumentTemplate) => (b.name as any) < (a.name as any),
        sortOrder: sortInfo && sortInfo.columnKey === 'name' ? sortInfo.order : null,
      },
      {
        title: t('GLOBAL.TYPE'),
        key: 'type',
        dataIndex: 'type',
        sorter: (a: IDocumentTemplate, b: IDocumentTemplate) => (b.type as any) < (a.type as any),
        sortOrder: sortInfo && sortInfo.columnKey === 'type' ? sortInfo.order : null,
      },
      {
        title: t('GLOBAL.CREATION'),
        key: 'createdAt',
        sorter: (a: IDocumentTemplate, b: IDocumentTemplate) => a.createdDate - b.createdDate,
        sortOrder: sortInfo && sortInfo.columnKey === 'createdAt' ? sortInfo.order : null,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IDocumentTemplate) => {
          const itemDate = record.createdDate;
          if (itemDate) {
            return <span>{moment.unix(itemDate).format('L HH:mm')}</span>;
          } else {
            return null;
          }
        },
      },
      {
        title: t('GLOBAL.CREATOR'),
        key: 'creator',
        sorter: (a: IDocumentTemplate, b: IDocumentTemplate) => a.creator.displayName! > b.creator.displayName!,
        sortOrder: sortInfo && sortInfo.columnKey === 'creator' ? sortInfo.order : null,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IDocumentTemplate) => {
          return (
            <Button
              type="text"
              style={{ color: colors.green }}
              onClick={() => {
                history.push(`/app/team/collaborators/${record.creator?.recordId}`);
              }}
            >
              {record.creator?.displayName}
            </Button>
          );
        },
      },
      {
        align: 'right',
        key: 'actions',
        // eslint-disable-next-line react/display-name
        render: (text: string, template: IDocumentTemplate) => {
          return (
            <TableRowActions template={template} onEdit={onEdit} onWantToDeleteTemplate={onWantToDeleteTemplate} />
          );
        },
      },
    ]);
  }, [i18n.language, sortInfo]);

  const onEdit = (templateId: string) => {
    history.push(`${templateId}`);
  };

  const onWantToDeleteTemplate = (templateId: string) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('DOCUMENTS.TEMPLATES.MODAL_DELETE_TEMPLATE.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDeleteTemplate(templateId);
      },
      onCancel: () => {},
    });
  };

  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IDocumentTemplate> | SorterResult<IDocumentTemplate>[],
  ) => {
    window.scrollTo(0, 0);
    setSortInfo(sorter as SorterResult<IDocumentTemplate>);
    localStorage.setItem('template_sorter', JSON.stringify(sorter));
  };

  return (
    <ContainerView>
      <TableView className={className}>
        <BackTop visibilityHeight={20}>
          <i className="icon-up-big"></i>
        </BackTop>
        <Header documentType={documentType} setFilterSearch={setFilterSearch} disabled={loadingTemplates} />
        <Table
          loading={loadingTemplates || filteringTemplates}
          dataSource={filteredTemplates}
          columns={columns}
          rowKey="id"
          onChange={onChange}
        />
      </TableView>
    </ContainerView>
  );
};

export default DocumentsList;
