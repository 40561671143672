import { IDocument } from '@/types/document.model';
import { Checkbox, Modal, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '@/pages/app/context';
import moment from 'moment';
import 'moment-timezone';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  document: IDocument | null;
  onClose: () => void;
}

const ModalDocumentDetail: React.FC<Props> = ({ className, document, onClose }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.DISPLAY_NAME'),
        key: 'displayName',
        dataIndex: 'displayName',
      },
      {
        title: t('GLOBAL.SIGNATURE'),
        key: 'sign',
        sorter: (a: IDocument['userList'][0], b: IDocument['userList'][0]) => b.sign !== a.sign,
        // eslint-disable-next-line react/display-name
        render: (text: any, document: IDocument['userList'][0]) => {
          return <Checkbox disabled={true} checked={document.sign} />;
        },
      },
      {
        title: t('CONTRACTS.COLUMNS.SIGNATURE_DATE'),
        key: 'signDate',
        // eslint-disable-next-line react/display-name
        sorter: (a: IDocument['userList'][0], b: IDocument['userList'][0]) => b.signDate - a.signDate,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IDocument['userList'][0]) => {
          const signed = record.signDate;
          if (signed) {
            return <span>{moment.unix(signed).format('L HH:mm')}</span>;
          } else {
            return null;
          }
        },
      },
      {
        title: 'PDF',
        key: 'pdf_file',
        // eslint-disable-next-line react/display-name
        render: (text: any, document: IDocument['userList'][0]) => {
          return (
            <a href={document.pdf_file} target="_blank" rel="noreferrer">
              <i className="icon-doc-text-inv" />
            </a>
          );
        },
      },
    ]);
  }, [i18n.language]);

  return (
    <Modal
      className={className}
      visible={!!document}
      onCancel={onClose}
      width={'80%'}
      title={document?.name}
      getContainer={false}
      forceRender
      footer={null}
    >
      <Table dataSource={document?.userList || []} columns={columns} rowKey="recordId" pagination={false} />
    </Modal>
  );
};

export default ModalDocumentDetail;
