import AuthContext from '@/context';
import { minutesToHoursAndOrMinutes } from '@/utils';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  price: number;
  durationInMinutes: number;
  pauseDurationInMinutes: number;
  style?: React.CSSProperties;
  currency?: string;
  hidePrice?: boolean;
  clockings?: {
    start?: number;
    end?: number;
    duration?: number;
    pause?: number;
    worktime?: number;
  }[];
}
const ShiftDetailsIcons: React.FC<Props> = ({
  className,
  style,
  price,
  currency = 'EUR',
  durationInMinutes,
  pauseDurationInMinutes,
  hidePrice,
}) => {
  const {
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });
  const { state: authState } = useContext(AuthContext);

  return (
    <div className={className} style={style}>
      <span>
        <i className="icon-clock" />
        {minutesToHoursAndOrMinutes(durationInMinutes)}
      </span>
      {pauseDurationInMinutes > 0 && (
        <span>
          <i className="icon-pause" />
          {minutesToHoursAndOrMinutes(pauseDurationInMinutes)}
        </span>
      )}
      {!hidePrice && price > 0 && (
        <span className="no-print">
          <i className="icon-money" />{' '}
          {new Intl.NumberFormat(language, {
            style: 'currency',
            currency,
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(price)}
        </span>
      )}
    </div>
  );
};

export default styled(ShiftDetailsIcons)`
  > span {
    margin-right: 4px;
    display: inline-block;
  }

  .clocking {
    color: rgb(96, 205, 255);
    font-weight: bold;
    display: flex;
    gap: 2px;
  }
`;
