import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { IBiDepartment } from '@/types/bi/department.model';
import { IBiShift } from '@/types/bi/shift.model';
import { formatPrice, getContrast, minutesToHoursAndOrMinutes } from '@/utils';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  department: IBiDepartment;
  shifts: IBiShift[];
}

const DepartmentHeader: React.FC<Props> = ({ className, department, shifts }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, groupAdminStart, groupAdminUsers, groupAdminActiveUser },
    dispatch,
  } = useContext(AppContext);

  const hours = shifts.reduce((totalHours, shift) => {
    if (groupAdminUsers.length > 0 && !groupAdminUsers.includes(shift.ressource_record_id)) return totalHours;
    return totalHours + shift.shift_duration / 60;
  }, 0);
  const cost = shifts.reduce((totalCost, shift) => {
    if (groupAdminUsers.length > 0 && !groupAdminUsers.includes(shift.ressource_record_id)) return totalCost;
    return totalCost + (shift.price_per_hour * shift.shift_duration) / 60;
  }, 0);

  return (
    <div className={className}>
      <div className="left">
        <span className="title">
          {department.shortcode} - {department.name}
        </span>
      </div>
      <div className="center">
        <div className="date">
          {groupAdminActiveUser
            ? `${groupAdminStart.format('YYYY-MM-DD')} - ${groupAdminStart.clone().endOf('week').format('YYYY-MM-DD')}`
            : groupAdminStart.format('YYYY-MM-DD')}
        </div>
      </div>
      <div className="right">
        <span className="stats">
          {minutesToHoursAndOrMinutes(hours * 60)}/{formatPrice(cost)}
        </span>
      </div>
    </div>
  );
};

export default styled(DepartmentHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left,
  .center,
  .right {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .left {
    .title {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .center {
    justify-content: center;

    .date {
      font-size: 14px;
    }
  }

  .right {
    justify-content: flex-end;

    .stats {
      font-size: 12px;
      background-color: #ccc;
      padding: 2px 10px;
      border-radius: 5px;
      color: #fff;
    }
  }
`;
