import produce from 'immer';
import { InitialStateType, initialState } from './store';
import { ActionType } from './actions';

export default function reducer(state = initialState, action: ActionType): InitialStateType {
  switch (action.type) {
    case 'RESET': {
      return initialState;
    }
    case 'SET_WIDGETS': {
      return produce(state, (draftState) => {
        draftState.widgets = action.payload;
      });
    }
    case 'SET_START_DATE': {
      return produce(state, (draftState) => {
        draftState.startDate = action.payload;
      });
    }
    case 'SET_END_DATE': {
      return produce(state, (draftState) => {
        draftState.endDate = action.payload;
      });
    }
    case 'SET_SELECTED_DATE': {
      return produce(state, (draftState) => {
        draftState.selectedDate = action.payload;
      });
    }
    case 'SET_WIDGET_VISIBILITY': {
      return produce(state, (draftState) => {
        const { index, visibility } = action.payload;
        draftState.widgets[index].visible = visibility;
      });
    }
    case 'SET_WIDGETS_POSITION': {
      return produce(state, (draftState) => {
        const widgetsMap = action.payload;
        const widgets = draftState.widgets;
        for (let i = 0; i < widgets.length; i++) {
          const widget = widgets[i];
          widget.position = widgetsMap.get(widget.id!);
        }
      });
    }
    default: {
      return state;
    }
  }
}
