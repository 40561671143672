import React from 'react';
import { Form, Input, Select } from 'antd';
import styled from 'styled-components';
import { FormInstance } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface Props {
  className?: string;
  form: FormInstance;
}

const ToolFields: React.FC<Props> = ({ className, form }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <div className={className}>
      <Form.Item name={'codeType'} label={t('GLOBAL.CODE_TYPE')} rules={[{ required: true }]}>
        <Select style={{ width: '100%' }}>
          <Option value="qr-code">{t('GLOBAL.QR_CODE')}</Option>
          <Option value="bar-code">{t('GLOBAL.BAR_CODE')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) => prevValues.codeType !== curValues.codeType}
        style={{ margin: 0 }}
      >
        {() => {
          const codeType = form.getFieldValue(['codeType']);
          const isBarCode = codeType === 'bar-code';
          return (
            <Form.Item name="code" label={t('GLOBAL.CODE')} rules={[{ required: isBarCode ? true : false }]}>
              <Input placeholder={isBarCode ? t('GLOBAL.BAR_CODE') : t('ATTRIBUTES.CODE_PLACEHOLDER')} />
            </Form.Item>
          );
        }}
      </Form.Item>
    </div>
  );
};

export default styled(ToolFields)``;
