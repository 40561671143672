import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { IShift } from '@/types/shift.model';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  shift: IShift;
  position?: 'absolute' | 'relative';
}

const TopIconsDayoff: React.FC<Props> = ({ className, shift, position = 'absolute' }) => {
  const { t } = useTranslation();
  return (
    <div className={`${className} ${position}`} style={{ position }}>
      {shift.hide && (
        <Tooltip overlay={<span>{t('DAYOFFS.NOT_COUNTED')}</span>}>
          <i className="icon-eye-off" style={{ color: colors.grey }} />
        </Tooltip>
      )}
    </div>
  );
};

export default styled(TopIconsDayoff)`
  &.absolute {
    top: 7px;
    right: 28px;
    @media print {
      right: 9px;
    }
  }

  font-size: 11px;

  i.icon-attention {
    color: ${colors.red};
  }

  .count {
    background: #d0d3dd;
    width: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #66676a;
    font-size: 11px;
    font-weight: bold;
    padding: 0px 12px;
    border: 1px solid white;
  }
`;
