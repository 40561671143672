import TableView from '@/layouts/TableView';
import { BackTop, Modal, Table, Image } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from './components/Header';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import ContainerView from '@/layouts/ContainerView';
import Axios from 'axios';
import { ISignature } from '@/types/signature.model';
import AppContext from '@/pages/app/context';
import TableRowActions from './components/TableRowActions';
import DrawerSignature from './components/DrawerSignature';
import { AlignType } from '@/types/alignType.model';

moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
}

const AppDocumentsSignatures: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [loadingSignatures, setLoadingSignatures] = useState<boolean>(false);
  const [signatures, setSignatures] = useState<ISignature[]>([]);
  const signaturesRef = useRef<ISignature[]>([]);
  const [activeSignature, setActiveSignature] = useState<Partial<ISignature> | null>(null);
  const [columns, setColumns] = useState<any[]>([]);
  signaturesRef.current = signatures;

  useEffect(() => {
    const momentLocale = moment.locale();
    if (i18n.language !== momentLocale) {
      moment.tz.setDefault('Atlantic/Reykjavik');
      moment.updateLocale(i18n.language, {
        week: {
          dow: 1,
        },
      });
    }
    const columns = [
      {
        title: t('GLOBAL.FULLNAME'),
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: t('DOCUMENTS.SIGNATURES.TITLE/FUNCTION'),
        key: 'position',
        dataIndex: 'title',
      },
      {
        title: t('GLOBAL.SIGNATURE'),
        key: 'signature',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: ISignature) => {
          return (
            <Image
              src={record.signaturePath}
              height={60}
              width={200}
              preview={false}
              fallback={`${process.env.PUBLIC_URL}/img/image-placeholder.png`}
            />
          );
        },
      },
      {
        key: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: ISignature) => {
          return (
            <TableRowActions
              signature={record}
              onWantToDeleteSignature={onWantToDeleteSignature}
              setActiveSignature={setActiveSignature}
            />
          );
        },
      },
    ];
    setColumns(columns);
  }, [i18n.language]);

  useEffect(() => {
    if (!location?.pathname) {
      return;
    }
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    setLoadingSignatures(true);
    setSignatures([]);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/signatures`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then(({ data }) => {
        if (mounted) {
          setSignatures(data);
          setLoadingSignatures(false);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingSignatures(false);
        }
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [location?.pathname]);

  const onWantToDeleteSignature = (signatureId: string) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('DOCUMENTS.SIGNATURES.MODAL_DELETE_SIGNATURE.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDeleteSignature(signatureId);
      },
      onCancel: () => {},
    });
  };

  const onDeleteSignature = (signatureId: string) => {
    Axios.delete(`${process.env.REACT_APP_API_URL}/v3/documents/signatures/${signatureId}`, {
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then((response) => {
        const signaturesCopy = [...signaturesRef.current];
        const index = signaturesCopy.findIndex((x) => x.id === signatureId);
        if (~index) {
          signaturesCopy.splice(index, 1);
          setSignatures(signaturesCopy);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSave = (result: ISignature) => {
    if (signatures.find((signature) => signature.id == result.id)) {
      setSignatures(signatures.map((signature) => (signature.id == result.id ? result : signature)));
    } else {
      setSignatures([...signatures, result]);
    }
  };

  return (
    <ContainerView>
      <TableView className={className}>
        <BackTop visibilityHeight={20}>
          <i className="icon-up-big"></i>
        </BackTop>
        <Header onNewSignature={() => setActiveSignature({})} />
        <Table loading={loadingSignatures} dataSource={signatures} columns={columns} rowKey="id" />
      </TableView>
      <DrawerSignature
        signature={activeSignature}
        departmentId={activeDepartmentId}
        onClose={() => setActiveSignature(null)}
        onSave={onSave}
      />
    </ContainerView>
  );
};

export default AppDocumentsSignatures;
