import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { AlignType } from '@/types/alignType.model';
import { IDayoff } from '@/types/dayoff.model';
import { handleError } from '@/utils';
import { Modal, Table, message } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerDayoff from '../../../components/drawers/Dayoff';
import Header from './components/Header';
import TableRowActions from './components/TableRowActions';

interface Props {
  className?: string;
}

const HrDayoffs: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartmentId, loadingDayoffs, dayoffs, dayoffTypes },
    dispatch,
  } = useContext(AppContext);
  const dayoffsRef = useRef(dayoffs);
  dayoffsRef.current = dayoffs;
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });
  const [filteredDayoffs, setFilteredDayoffs] = useState<IDayoff[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeDayoff, setActiveDayoff] = useState<IDayoff | null>(null);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.ID'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      },
      {
        title: t('GLOBAL.CODE'),
        dataIndex: 'shortCode',
        key: 'shortCode',
      },
      {
        title: t('GLOBAL.TYPE'),
        dataIndex: 'dayoffType',
        key: 'dayoffType',
        sorter: (a: any, b: any) => a.dayoffType?.localeCompare(b.dayoffType),
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IDayoff) => {
          const type = record.dayoffType;
          if (!type) return;
          const dayoffType = dayoffTypes.find((dayoffType) => dayoffType.id == type);
          if (!dayoffType) return;
          return dayoffType.label;
        },
      },
      {
        title: t('GLOBAL.APP'),
        dataIndex: 'displayUser',
        key: 'displayUser',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IDayoff) => <Checkbox disabled={false} checked={record.displayUser} />,
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IDayoff) => (
          <TableRowActions onEdit={() => setActiveDayoff(record)} onDelete={() => onWantToDelete(record.id!)} />
        ),
      },
    ]);
  }, [i18n.language, dayoffTypes]);

  useEffect(() => {
    const filteredDayoffs = dayoffs?.filter((dayoff: any) => {
      return Object.keys(dayoff).some((key) => {
        if (key === 'background') {
          return false;
        }
        if (typeof dayoff[key] === 'string') {
          return dayoff[key].toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return false;
        }
      });
    });
    setFilteredDayoffs(filteredDayoffs);
  }, [dayoffs, dayoffTypes, searchTerm]);

  const onWantToDelete = (dayoffId: string) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('DAYOFFS.MODAL_DELETE_DAYOFF_TYPE.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDelete(dayoffId);
      },
      onCancel: () => {},
    });
  };

  const onDelete = (dayoffId: string) => {
    const dayoffsCopy = [...dayoffsRef.current];
    const index = dayoffsCopy.findIndex((dayoff) => dayoff.id === dayoffId);

    if (~index) {
      const dayoffsResult = [...dayoffsCopy];
      dayoffsResult.splice(index, 1);
      dispatch({
        type: 'SET_DAYOFFS',
        payload: dayoffsResult,
      });
    }

    axios.delete(`${process.env.REACT_APP_API_URL}/v3/dayoffs/${dayoffId}`).catch((error) => {
      if (~index) {
        console.error(error);
        message.error(t('DAYOFFS.MODAL_DELETE_DAYOFF_TYPE.MESSAGE_ERROR'));
        if (dayoffsCopy) {
          dispatch({
            type: 'SET_DAYOFFS',
            payload: dayoffsCopy,
          });
        }
      }
    });
  };

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchTerm(value);
  };

  const onSave = (result: IDayoff) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/dayoffs`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_DAYOFFS',
          payload: data.dayoffs,
        });
      })
      .catch((error) => {
        handleError(error);
      });
    setActiveDayoff(null);
  };

  return (
    <React.Fragment>
      <h2>{t('GLOBAL.ABSENCES')}</h2>
      <div
        style={{
          backgroundColor: 'white',
          padding: 25,
          borderRadius: 10,
          marginTop: 25,
        }}
      >
        <TableView>
          <Header disabled={false} onSearchChange={onSearchChange} onCreate={() => setActiveDayoff({})} />
          <Table
            className={className}
            loading={loadingDayoffs}
            dataSource={filteredDayoffs}
            columns={columns}
            rowKey="id"
            pagination={false}
          />
        </TableView>
        <DrawerDayoff
          dayoff={activeDayoff}
          visible={!!activeDayoff}
          onClose={() => setActiveDayoff(null)}
          onSave={onSave}
        />
      </div>
    </React.Fragment>
  );
};

export default HrDayoffs;
