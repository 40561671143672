import AppContext from '@/pages/app/context';
import Colors from '@/styles/colors';
import { IUser } from '@/types/user.model';
import { Col, Row, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DayoffRate from './DayoffRate';
import DelayRate from './DelayRate';
import Indicators from './Indicators';
import Leaves from './Leaves';
import MonthlyActivity from './MonthlyActivity';
import WorkRate from './WorkRate';
import { isFeatureEnabled } from '@/utils';
import { FEATURES } from '@/types/features.model';

interface Props {
  className?: string;
  user?: IUser;
  departmentId?: string;
}

const Dashboard: React.FC<Props> = ({ className, user, departmentId }) => {
  const {
    state: { features },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: true });

  const [hideMonthlyActivity, setHideMonthlyActivity] = useState<boolean>(false);
  const [hideDelayRate, setHideDelayRate] = useState<boolean>(false);
  const [hideWorkRate, setHideWorkRate] = useState<boolean>(false);
  const [hideDayoffRate, setHideDayoffRate] = useState<boolean>(false);

  const hasDayoffCounter = !!user?.enable_dayoff_counter;

  return (
    <div className={`${className}`}>
      <Indicators recordId={user?.recordId || undefined} departmentId={departmentId} />
      {!!user?.enable_dayoff_counter && (
        <Row gutter={[40, 30]}>
          <Col span={24}>
            <div className="card-shadow">
              <Leaves recordId={user?.recordId || undefined} departmentId={departmentId} />
            </div>
          </Col>
        </Row>
      )}
      {(!hideMonthlyActivity || !hideDelayRate) && (
        <Row gutter={[20, 30]}>
          {!hideMonthlyActivity && (
            <Col className="card">
              <div className="card-shadow">
                <MonthlyActivity
                  userMainId={user?.mainId || undefined}
                  departmentId={departmentId}
                  setHideMonthlyActivity={setHideMonthlyActivity}
                />
              </div>
            </Col>
          )}
          {!hideDelayRate && isFeatureEnabled(features, FEATURES.CLOCKING) && (
            <Col className="card">
              <div className="card-shadow">
                <DelayRate
                  userMainId={user?.mainId || undefined}
                  departmentId={departmentId}
                  setHideDelayRate={setHideDelayRate}
                />
              </div>
            </Col>
          )}
        </Row>
      )}
      {(!hideWorkRate || !hideDayoffRate) && (
        <Row gutter={[20, 20]}>
          {!hideWorkRate && (
            <Col md={24} lg={hasDayoffCounter ? 12 : 24} style={{ width: '100%' }}>
              <div className="card-shadow">
                <WorkRate
                  userMainId={user?.mainId || undefined}
                  departmentId={departmentId}
                  setHideWorkRate={setHideWorkRate}
                />
              </div>
            </Col>
          )}
          {!hideDayoffRate && hasDayoffCounter && (
            <Col md={24} lg={12}>
              <div className="card-shadow">
                <DayoffRate
                  userMainId={user?.mainId || undefined}
                  departmentId={departmentId}
                  setHideDayoffRate={setHideDayoffRate}
                />
              </div>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};

const DashboardStyled = styled(Dashboard)`
  .ant-row {
    margin-bottom: 20px;
    .ant-col {
      > div {
        padding: 20px 10px;
        height: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .chart-container {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .card {
    width: 50%;
  }

  @media screen and (max-width: 900px) {
    .card {
      width: 100%;
    }
  }
`;

export default DashboardStyled;
