import ScrollToTop from '@/components/ScrollToTop';
import ContainerView from '@/layouts/ContainerView';
import TableViewStyled from '@/layouts/TableView';
import ModalHowToUseVariables from '@/pages/app/components/ModalHowToUseVariables';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { DocumentPreview } from '@/types/document-preview.model';
import { IDocumentPlaceholder, IDocumentTemplate } from '@/types/document.model';
import { FEATURES } from '@/types/features.model';
import { ISignature } from '@/types/signature.model';
import {
  TINYMCE_CONTENT_STYLE,
  TINYMCE_PLUGINS,
  TINYMCE_TOOLBAR,
  getHtmlWithCodes,
  getWindowSize,
  isFeatureEnabled,
} from '@/utils';
import { Editor } from '@tinymce/tinymce-react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  message,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { RcFile } from 'antd/lib/upload/interface';
import Axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
}

interface PromiseWithCancel<T> extends Promise<T> {
  cancel: () => void;
}

const Document: React.FC<Props> = ({ className }) => {
  const location = useLocation<any>();
  const {
    state: {
      users,
      loadingUsers,
      userCategories,
      loadingUserCategories,
      skills,
      loadingSkills,
      activeDepartment,
      activeDepartmentId,
      features,
    },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const history = useHistory();
  const previewRef = React.createRef<any>();
  const { documentId } = useParams<any>();
  const [loadingDocument, setLoadingDocument] = useState<boolean>(false);
  const [templates, setTemplates] = useState<IDocumentTemplate[]>([]);
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
  const [activeTemplateId, setActiveTemplateId] = useState<string | null>(null);
  const [placeholders, setPlaceholders] = useState<IDocumentPlaceholder[]>([]);
  const placeholdersRef = useRef<IDocumentPlaceholder[]>(placeholders);
  placeholdersRef.current = placeholders;
  const [placeholdersReady, setPlaceholdersReady] = useState<boolean>(false);
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const editorRef = useRef<any>(null);
  const [signatures, setSignatures] = useState<ISignature[]>([]);
  const [loadingSignatures, setLoadingSignatures] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
  const [file, setFile] = useState<RcFile | null>(null);
  const [fileNameOnUpdate, setFileNameOnUpdate] = useState<string | null>(null);
  const [preview, setPreview] = useState<DocumentPreview | null>(null);
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [activeTemplatePageIndex, setActiveTemplatePageIndex] = useState<number>(0);
  const [howToUseVariablesVisible, setHowToUseVariablesVisible] = useState<boolean>(false);
  const [originalEditorContent, setOriginalEditorContent] = useState<string>('');
  const [showFileUpload, setShowFileUpload] = useState<boolean>(true);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [previewDocumentQuery, setPreviewDocumentQuery] = useState<PromiseWithCancel<DocumentPreview | null>>();

  const cancelUploadFileController = new AbortController();

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!file) return;

    if (!preview || (preview && preview.images && preview.images.length > 0)) return;

    beforeUpload(file);
  }, [preview, file]);

  const updatingDocument = documentId !== 'new';

  useEffect(() => {
    if (!location?.pathname) {
      return;
    }
    form.resetFields();
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    setTemplates([]);
    setLoadingTemplates(true);
    setActiveTemplateId(null);
    setPlaceholders([]);
    setPlaceholdersReady(false);

    Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/templates`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
        filter: 'DOCUMENT',
      },
    })
      .then(({ data }) => {
        if (mounted) {
          setTemplates(data.templates);
          setLoadingTemplates(false);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingTemplates(false);
        }
      });

    Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/placeholders`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
        docType: 'DOCUMENT',
      },
    })
      .then(({ data }) => {
        if (mounted) {
          setPlaceholders(data);
          setPlaceholdersReady(true);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
      });

    Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/signatures`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then(({ data }) => {
        if (mounted) {
          setSignatures(data);
          setLoadingSignatures(false);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingSignatures(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [location?.pathname]);

  useEffect(() => {
    if (!placeholdersReady) {
      return;
    }
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    const originDocumentId = location?.state?.documentId || documentId;
    if (originDocumentId && originDocumentId !== 'new') {
      setLoadingDocument(true);
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/${originDocumentId}`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
          docType: 'DOCUMENT',
        },
      })
        .then(({ data }) => {
          if (mounted) {
            if (data.type !== 'DOCUMENT') {
              return history.push(`/app/documents/contracts/${data.id}`);
            }
            setFormFieldsAndDraft(data);
          }
        })
        .catch((error) => {
          if (Axios.isCancel(error)) {
            console.error(error);
          }
          if (mounted) {
            setLoadingDocument(false);
          }
        });
    }
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [location?.pathname, placeholdersReady]);

  useEffect(() => {
    if (!activeTemplateId || !placeholdersReady) {
      setHtmlContent('');
      return;
    }
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();

    Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/templates/${activeTemplateId}`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then((response) => {
        if (mounted) {
          setShowFileUpload(false);
          const data = response.data;
          if (data.render === 'docx') {
            const source_splitted = data.template_download_url.split('/');
            const name = source_splitted[source_splitted.length - 1];
            if (name) {
              setFileNameOnUpdate(name);
            }
            setPreviewLoading(true);
            fetch(data.template_download_url)
              .then(async (res) => {
                if (data.render == 'docx' && data && data.images && data.images.length == 0) {
                  beforeUpload((await res.blob()) as RcFile);
                }
                setFile((await res.blob()) as any);
                setPreview(data);
                setPreviewLoading(false);
              })
              .catch((err) => console.log(err));
          }

          if (data.render === 'html') {
            setFile(null);
            setPreview(null);
            setPreviewLoading(false);
            setOriginalEditorContent(data.html);
            setHtmlContent(getHtmlWithCodes(data?.html || '', placeholdersRef?.current));
          }
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeTemplateId, placeholdersReady]);

  const onWantToChangeTemplateId = (value: string) => {
    if (!value) {
      setFile(null);
      setPreview(null);
      setPreviewLoading(false);
    }
    if (editorRef.current.getContent()) {
      Modal.confirm({
        title: t('GLOBAL.WARNING'),
        type: 'warning',
        content: t('DOCUMENTS.MODAL_CHANGE_TEMPLATE.CONTENT'),
        cancelText: t('GLOBAL.CANCEL'),
        okText: t('GLOBAL.VALIDATE'),
        okType: 'danger',
        onOk: () => {
          if (!value) {
            setShowFileUpload(true);
          }
          setActiveTemplateId(value);
        },
        onCancel: () => {
          setShowFileUpload(false);
          form.setFieldsValue({ templateId: activeTemplateId });
        },
      });
    } else {
      setActiveTemplateId(value);
    }
  };

  const setFormFieldsAndDraft = (data: any) => {
    const { formValues, render } = data;
    if (render.as === 'docx') {
      const source_splitted = render.source.split('/');
      const name = source_splitted[source_splitted.length - 1];
      if (name) {
        setFileNameOnUpdate(name);
      }
      setPreviewLoading(true);
      fetch(render.source)
        .then(async (res) => {
          setFile((await res.blob()) as any);
          setPreview(render);
          setPreviewLoading(false);
        })
        .catch((err) => console.log(err));
    }
    form.setFieldsValue({
      ...formValues,
      expirationDate: formValues.expirationDate ? moment.unix(formValues.expirationDate!) : null,
    });
    if (render.as === 'html') {
      setHtmlContent(getHtmlWithCodes(render.user_html || '', placeholdersRef?.current));
    }
    setLoadingDocument(false);
  };

  // const setFormFieldsAndDraft = (data: any) => {
  //   const { formValues, render } = data;
  //   setActiveUser(formValues.userRecordId);
  //   if (render.as === 'docx') {
  //     const source_splitted = render.source.split('/');
  //     const name = source_splitted[source_splitted.length - 1];
  //     if (name) {
  //       setFileNameOnUpdate(name);
  //     }
  //     setPreviewLoading(true);
  //     fetch(render.source)
  //       .then(async (res) => {
  //         setFile((await res.blob()) as any);
  //         setPreview(render);
  //         setPreviewLoading(false);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  //   form.setFieldsValue({
  //     ...formValues,
  //     start: formValues.start ? moment.unix(formValues.start!) : null,
  //     end: formValues.end ? moment.unix(formValues.end!) : null,
  //   });
  //   if (render.as === 'html') {
  //     setHtmlContent(getHtmlWithCodes(render.user_html || '', placeholdersRef?.current));
  //   }
  //   setLoadingContract(false);
  // };

  const getHtmlWithPlaceholders = () => {
    const html = editorRef.current.getContent() || '';
    const regex = /(<code.+?data\-key\="([a-zA-Z0-9_]+)".+?>)(.*?)(<\/code>)?/g;

    const htmlWithIds = html.replace(regex, (match: any, p1: string, p2: string) => {
      if (p2) {
        return `{{${p2}}}`;
      } else {
        return match;
      }
    });

    return htmlWithIds;
  };

  const onPreview = async () => {
    try {
      await form.validateFields();
      const formData = new FormData();
      const values = form.getFieldsValue();

      const parsedValues = {
        ...values,
        expirationDate: values.expirationDate?.unix(),
      };

      for (var key in parsedValues) {
        if (parsedValues[key]) {
          formData.append(`formValues[${key}]`, parsedValues[key]);
        }
      }
      if (file) {
        formData.append('formValues[file]', file);
        formData.delete('formValues[templateId]');
        formData.delete('html');
      }
      if (originalEditorContent !== editorRef.current.getContent()) {
        formData.delete('formValues[file]');
        formData.delete('formValues[templateId]');
        formData.append('html', getHtmlWithPlaceholders());
      }
      // render_signature is boolean but can only send strings in FormData
      formData.append('render_signature', values.signature ? '1' : '0');

      setPreviewLoading(true);
      Axios({
        url: `${process.env.REACT_APP_API_URL}/v3/documents/preview`,
        method: 'POST',
        data: formData,
        params: {
          departmentId: activeDepartmentId,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(({ data }) => {
          Modal.info({
            closable: true,
            icon: null,
            width: '100%',
            content: <div dangerouslySetInnerHTML={{ __html: data?.html }} />,
            onOk() {},
          });
          setPreview(data);
          setPreviewLoading(false);
        })
        .catch((err) => {
          setFile(null);
          setPreview(null);
          setPreviewLoading(false);
          if (err.response?.data?.errors) {
            setShowErrorsModal(true);
            setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
          }
        });
    } catch (error) {
      console.log(error);
      window.scrollTo(0, 0);
    }
  };

  const onFinish = (values: any) => {
    setIsSaving(true);
    const parsedValues = {
      ...values,
      expirationDate: values.expirationDate ? values.expirationDate?.unix() : null,
      signature: values.signature ? '1' : '0',
    };

    const url = updatingDocument
      ? `${process.env.REACT_APP_API_URL}/v3/documents/${documentId}`
      : `${process.env.REACT_APP_API_URL}/v3/documents`;

    const formData = new FormData();
    for (var key in parsedValues) {
      if (parsedValues[key]) {
        formData.append(`formValues[${key}]`, parsedValues[key]);
      }
    }

    if (file) {
      formData.append('formValues[file]', file);
      formData.delete('formValues[templateId]');
      formData.delete('html');
    }

    if (originalEditorContent !== editorRef.current.getContent()) {
      formData.delete('formValues[file]');
      formData.delete('formValues[templateId]');
      formData.append('html', getHtmlWithPlaceholders());
    }

    Axios({
      url,
      method: 'POST',
      data: formData,
      params: {
        departmentId: activeDepartmentId,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        form.setFieldsValue({ ...data });
        message.success(t('DOCUMENTS.DOCUMENTS.MESSAGE_SAVING_SUCCESS'));
        setIsSaving(false);
        if (!updatingDocument) {
          history.push('/app/documents/documents/all');
        } else {
          history.push(data.id);
        }
      })
      .catch((err) => {
        if (err.response.data.errors) {
          setShowErrorsModal(true);
          setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
        }
        setIsSaving(false);
      });
  };

  const beforeUpload = (_file: RcFile) => {
    setFile(_file);
    setPreview(null);
    setPreviewLoading(true);

    const formData = new FormData();
    const values = form.getFieldsValue();

    const parsedValues = {
      ...values,
      expirationDate: values.expirationDate ? values.expirationDate?.unix() : null,
    };
    for (var key in parsedValues) {
      if (parsedValues[key]) {
        formData.append(`formValues[${key}]`, parsedValues[key]);
        if (key === 'signature') {
          formData.append('render_signature', '1');
        }
      }
    }
    if (_file) {
      formData.append('formValues[file]', _file);
      formData.delete('html');
      formData.delete('formValues[templateId]');
    } else {
      if (originalEditorContent !== editorRef.current.getContent()) {
        formData.delete('formValues[file]');
        formData.delete('formValues[templateId]');
        formData.append('html', getHtmlWithPlaceholders());
      }
    }

    const previewQuery = previewContent(formData);
    setPreviewDocumentQuery(previewQuery);

    previewQuery
      .then((data) => {
        setPreviewLoading(false);
        setPreview(data);
      })
      .catch((err) => {
        setFile(null);
        setPreview(null);
        setPreviewLoading(false);
        if (err.response?.data?.errors) {
          setShowErrorsModal(true);
          setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
        }
      });

    return false;
  };

  const previewContent = (formData: FormData) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        const response = await Axios({
          url: `${process.env.REACT_APP_API_URL}/v3/documents/preview`,
          method: 'POST',
          data: formData,
          params: {
            departmentId: activeDepartmentId,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          signal: cancelUploadFileController.signal,
        });
        const data = await response.data;
        resolve(data);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
    (promise as PromiseWithCancel<DocumentPreview | null>).cancel = () => cancelUploadFileController.abort();
    return promise as PromiseWithCancel<DocumentPreview | null>;
  };

  const onClearFile = () => {
    previewDocumentQuery?.cancel();
    setFile(null);
    setPreview(null);
  };

  const onChangePage = (direction: string, pagination: string) => {
    if (preview && preview.images.length > 0) {
      switch (direction) {
        case 'left': {
          if (activeTemplatePageIndex == 0) {
            setActiveTemplatePageIndex(preview.images.length - 1);
          } else {
            setActiveTemplatePageIndex(activeTemplatePageIndex - 1);
          }
          if (pagination == 'bottom') {
            window.scroll({ behavior: 'smooth', top: previewRef?.current?.offsetTop });
          }
          break;
        }
        case 'right': {
          if (activeTemplatePageIndex == preview.images.length - 1) {
            setActiveTemplatePageIndex(0);
          } else {
            setActiveTemplatePageIndex(activeTemplatePageIndex + 1);
          }
          if (pagination == 'bottom') {
            window.scroll({ behavior: 'smooth', top: previewRef?.current?.offsetTop });
          }
          break;
        }
      }
    }
  };

  const onErrorsOk = () => {
    setShowErrorsModal(false);
  };

  const onErrorsCancel = () => {
    setShowErrorsModal(false);
  };

  const onHowToUseVariables = () => {
    setHowToUseVariablesVisible(true);
  };

  return (
    <ContainerView>
      <ModalHowToUseVariables
        howToUseVariablesVisible={howToUseVariablesVisible}
        setHowToUseVariablesVisible={setHowToUseVariablesVisible}
        placeholders={placeholders}
        documentType="DOCUMENT"
      />
      <Modal
        forceRender={true}
        maskClosable={false}
        destroyOnClose={true}
        visible={showErrorsModal}
        className={'modal-danger'}
        title={t('CONTRACTS.CREATE_CONTRACT_ERROR')}
        onOk={onErrorsOk}
        onCancel={onErrorsCancel}
        footer={[
          <Button key="back" onClick={onErrorsCancel}>
            {t('GLOBAL.OK')}
          </Button>,
        ]}
      >
        <List
          dataSource={errors}
          renderItem={(item) => (
            <List.Item>
              <p>{item}</p>
            </List.Item>
          )}
        />
      </Modal>
      <TableViewStyled className={className}>
        <header>
          <div className="left">
            <h2>
              {documentId !== 'new'
                ? t('DOCUMENTS.DOCUMENTS.DOCUMENT.EDIT_DOCUMENT')
                : t('DOCUMENTS.DOCUMENTS.DOCUMENT.NEW_DOCUMENT')}
            </h2>
          </div>
          {!previewLoading && (
            <div className="right">
              <Space>
                <Button loading={isSaving} type="primary" onClick={() => form.submit()}>
                  {t('GLOBAL.SAVE')}
                </Button>
                <Button type="primary" danger onClick={() => history.push('/app/documents/documents/all')}>
                  {t('GLOBAL.BACK')}
                </Button>
              </Space>
            </div>
          )}
        </header>
        <ScrollToTop />
        <Spin spinning={loadingDocument || isSaving}>
          <Form
            layout="vertical"
            size="large"
            form={form}
            onFinish={onFinish}
            scrollToFirstError={true}
            initialValues={{ recipientType: 'ALL' }}
            className="contract-container"
          >
            <Row gutter={[20, 30]}>
              <Col span={24}>
                <div className="card-shadow">
                  <Form.Item label={t('GLOBAL.RECIPIENTS')} name="recipientType" rules={[{ required: true }]}>
                    <Radio.Group
                      options={[
                        { label: t('GLOBAL.ALL'), value: 'ALL' },
                        { label: t('GLOBAL.USER(S)'), value: 'USERS' },
                        { label: t('GLOBAL.USER_CATEGORY(IES)'), value: 'STATUSES' },
                        ...(isFeatureEnabled(features, FEATURES.SKILLS)
                          ? [{ label: t('GLOBAL.SKILL(S)'), value: 'SKILLS' }]
                          : []),
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    shouldUpdate={(prevValues, curValues) => prevValues.recipientType !== curValues.recipientType}
                  >
                    {(form) => {
                      const recipientType = form.getFieldValue('recipientType');
                      let formItem = null;
                      switch (recipientType) {
                        case 'USERS': {
                          formItem = (
                            <Form.Item
                              label={t('GLOBAL.USER(S)')}
                              name="users"
                              rules={[{ required: true }]}
                              style={{
                                display: 'inline-block',
                                width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                                marginRight: '10px',
                              }}
                            >
                              <Select
                                mode="multiple"
                                allowClear
                                getPopupContainer={(trigger) => trigger}
                                showSearch
                                loading={loadingUsers}
                                placeholder={t('FORMS.USER_PLACEHOLDER')}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {users?.map((user) => {
                                  const { recordId, displayName } = user;
                                  return (
                                    <Option key={`user_${recordId}`} value={`${recordId}`}>
                                      {displayName}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          );
                          break;
                        }
                        case 'STATUSES': {
                          formItem = (
                            <Form.Item
                              label={t('GLOBAL.USER_CATEGORY(IES)')}
                              name="statuses"
                              rules={[{ required: true }]}
                              style={{
                                display: 'inline-block',
                                width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                                marginRight: '10px',
                              }}
                            >
                              <Select
                                allowClear
                                mode="multiple"
                                getPopupContainer={(trigger) => trigger}
                                showSearch
                                loading={loadingUserCategories}
                                placeholder={t('FORMS.USER_CATEGORY_PLACEHOLDER')}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {userCategories?.map((userCategory) => {
                                  const { id, name } = userCategory;
                                  return (
                                    <Option key={`userCategory_${id}`} value={`${id}`}>
                                      {name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          );
                          break;
                        }
                        case 'SKILLS': {
                          formItem = (
                            <Form.Item
                              label={t('GLOBAL.SKILL(S)')}
                              name="skills"
                              rules={[{ required: true }]}
                              style={{
                                display: 'inline-block',
                                width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                                marginRight: '10px',
                              }}
                            >
                              <Select
                                allowClear
                                mode="multiple"
                                getPopupContainer={(trigger) => trigger}
                                showSearch
                                loading={loadingSkills}
                                placeholder={t('FORMS.SKILL_PLACEHOLDER')}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {skills?.map((skill) => {
                                  const { id, name } = skill;
                                  return (
                                    <Option key={`skill_${id}`} value={`${id}`}>
                                      {name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          );
                          break;
                        }
                      }
                      return (
                        <React.Fragment>
                          {formItem}
                          <Form.Item
                            label={t('DOCUMENTS.DOCUMENTS.DOCUMENT.FORM.NAME')}
                            name="name"
                            rules={[{ required: true }]}
                            style={{
                              display: 'inline-block',
                              width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                              marginRight: '10px',
                            }}
                          >
                            <Input placeholder={t('DOCUMENTS.DOCUMENTS.DOCUMENT.FORM.NAME')} />
                          </Form.Item>
                          <Form.Item
                            label={t('DOCUMENTS.DOCUMENTS.DOCUMENT.FORM.TEMPLATE')}
                            name="templateId"
                            style={{
                              display: 'inline-block',
                              width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                            }}
                          >
                            <Select
                              allowClear
                              getPopupContainer={(trigger) => trigger}
                              loading={loadingTemplates}
                              showSearch
                              placeholder={t('DOCUMENTS.DOCUMENTS.DOCUMENT.FORM.TEMPLATE_PLACEHOLDER')}
                              optionFilterProp="children"
                              onChange={onWantToChangeTemplateId}
                              filterOption={(input, option) =>
                                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {templates?.map((template) => {
                                const { name, id } = template;
                                return (
                                  <Option key={`template_${id}`} value={`${id}`}>
                                    {name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </React.Fragment>
                      );
                    }}
                  </Form.Item>
                  <div style={{ display: 'flex', flexDirection: windowSize.innerWidth > 900 ? 'row' : 'column' }}>
                    <Form.Item
                      name="expirationDate"
                      label={t('GLOBAL.EXPIRATION_DATE')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                        marginRight: '10px',
                      }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            expirationDate: e,
                          });
                        }}
                      />
                    </Form.Item>
                    {showFileUpload && (
                      <Col span={windowSize.innerWidth > 900 ? 8 : 24} style={{ paddingLeft: 0 }}>
                        <Form.Item label={t('DOCUMENTS.DOCUMENTS.UPLOAD_DOCUMENT')}>
                          <div className="upload-file-container">
                            <div className="left">
                              <Upload
                                beforeUpload={beforeUpload}
                                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                maxCount={1}
                                showUploadList={false}
                                className="browse-btn-container"
                              >
                                <div className="content">
                                  <button type="button" className="browse-btn">
                                    {file
                                      ? file.name
                                        ? file.name
                                        : fileNameOnUpdate && fileNameOnUpdate
                                      : t('GLOBAL.BROWSE')}
                                  </button>
                                </div>
                              </Upload>
                              {file && <i className="icon-plus" onClick={onClearFile} />}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            <button type="button" style={{ textDecoration: 'underline' }} onClick={onHowToUseVariables}>
                              {t('DOCUMENTS.VARIABLES.TITLE')}
                            </button>
                          </div>
                        </Form.Item>
                      </Col>
                    )}
                    <Form.Item
                      label={t('GLOBAL.SIGNATURE')}
                      name="signature"
                      valuePropName="checked"
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                      }}
                    >
                      <Checkbox>{t('FORMS.REQUIRED_SIGNATURE')}</Checkbox>
                    </Form.Item>
                  </div>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => prevValues.signature !== curValues.signature}
                  >
                    {(form) => {
                      const signature = form.getFieldValue('signature');
                      if (signature) {
                        return (
                          <Form.Item name="signatory" label={t('CONTRACTS.FORM.SIGNATORY')}>
                            <Select
                              allowClear
                              getPopupContainer={(trigger) => trigger}
                              showSearch
                              loading={loadingSignatures}
                              placeholder={t('CONTRACTS.FORM.SIGNATORY_PLACEHOLDER')}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option!.children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {signatures.map((signature) => {
                                const { name, id, title } = signature;
                                return (
                                  <Option key={`signatory_${id}`} value={`${id}`}>
                                    {name} - {title}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        );
                      }
                    }}
                  </Form.Item>
                  <Form.Item label={t('GLOBAL.MESSAGE')} name="message">
                    <TextArea rows={4} placeholder={t('DOCUMENTS.DOCUMENTS.DOCUMENT.FORM.MESSAGE_PLACEHOLDER')} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            {!file && (
              <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={htmlContent}
                init={{
                  height: 600,
                  menubar: false,
                  setup: function (editor) {
                    editor.ui.registry.addMenuButton('placeholders', {
                      text: 'Variables',
                      fetch: (callback) => {
                        callback(
                          placeholdersRef.current?.map(({ id, label }: any) => ({
                            type: 'menuitem',
                            text: label,
                            onAction: function () {
                              editor.insertContent(
                                `<code data-key="${id}" class="mceNonEditable" contenteditable="false">${label}</code>`,
                              );
                            },
                          })),
                        );
                      },
                    });
                  },
                  plugins: TINYMCE_PLUGINS,
                  toolbar: TINYMCE_TOOLBAR,
                  content_style: TINYMCE_CONTENT_STYLE,
                }}
              />
            )}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 15 }} ref={previewRef}>
              <>
                {file && (
                  <>
                    {previewLoading ? (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Spin spinning={previewLoading} />
                        <span>{t('DOCUMENTS.PREVIEWS.LOADING')}</span>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: 15,
                          width: '100%',
                          paddingBottom: 50,
                        }}
                      >
                        {preview && (
                          <div>
                            {preview.images.length !== 1 && (
                              <button
                                className="slider-arrow"
                                type="button"
                                onClick={() => onChangePage('left', 'top')}
                              >
                                &lt;
                              </button>
                            )}
                            {t('DOCUMENTS.PREVIEWS.PAGE_X_OF_X', {
                              current: activeTemplatePageIndex + 1,
                              length: preview.images.length,
                            })}
                            {preview.images.length !== 1 && (
                              <button
                                className="slider-arrow"
                                type="button"
                                onClick={() => onChangePage('right', 'top')}
                              >
                                &gt;
                              </button>
                            )}
                          </div>
                        )}
                        <div style={{ width: '100%' }}>
                          {preview && (
                            <img
                              className="template-image"
                              src={preview.images[activeTemplatePageIndex]}
                              style={{ width: '100%' }}
                            />
                          )}
                        </div>
                        <div>
                          {preview && (
                            <div>
                              {preview.images.length !== 1 && (
                                <button
                                  className="slider-arrow"
                                  type="button"
                                  onClick={() => onChangePage('left', 'bottom')}
                                >
                                  &lt;
                                </button>
                              )}
                              {t('DOCUMENTS.PREVIEWS.PAGE_X_OF_X', {
                                current: activeTemplatePageIndex + 1,
                                length: preview.images.length,
                              })}
                              {preview.images.length !== 1 && (
                                <button
                                  className="slider-arrow"
                                  type="button"
                                  onClick={() => onChangePage('right', 'bottom')}
                                >
                                  &gt;
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            </div>
            <div className={`actions active`}>
              {/* <Button
                type="primary"
                onClick={onPreview}
                style={{ background: colors.orange, borderColor: colors.orange, marginRight: 10 }}
                loading={previewLoading}
              >
                {t('GLOBAL.PREVIEW')}
              </Button> */}
              <Button loading={isSaving} type="primary" htmlType="submit" disabled={previewLoading}>
                {t('GLOBAL.SAVE')}
              </Button>
            </div>
          </Form>
        </Spin>
      </TableViewStyled>
    </ContainerView>
  );
};

const DocumentStyled = styled(Document)`
  background: ${colors.blueExtraLight};
  border-radius: 3px;

  .upload-file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      border-radius: 5px;
      background-color: #f6f8fc;
      border: 1px solid #e2e6ef;

      .icon-plus {
        transform: rotate(45deg);
        padding: 0 15px;
        cursor: pointer;
      }
    }

    .browse-btn-container {
      flex: 1;

      .ant-upload-select {
        width: 100%;

        .browse-btn {
          display: flex;
          justify-content: space-between;
          padding: 9px;
          width: 100%;
        }
      }
    }
  }

  .ant-row {
    margin-bottom: 20px;
  }

  .contract-container {
    padding: 0 25px 25px;

    .ant-col .card-shadow {
      padding: 0 20px;
    }
  }

  .tox-tinymce {
    margin-bottom: 40px;
  }

  .card-shadow {
    padding: 0;
    margin-bottom: 40px;
  }

  .actions {
    position: fixed;
    width: calc(100vw - 160px);
    background: white;
    bottom: -80px;
    margin: 0;
    margin-left: 40px;
    display: flex;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
    padding: 10px;
    border-radius: 3px 3px 0 0;
    transition: bottom 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    &.active {
      transition-timing-function: ease-in;
      bottom: 0px;
    }
  }
`;

export default DocumentStyled;
