import colors from '@/styles/colors';
import { IDocumentPlaceholder } from '@/types/document.model';
import { Collapse, message, Modal, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface Props {
  className?: string;
  howToUseVariablesVisible: boolean;
  setHowToUseVariablesVisible: React.Dispatch<React.SetStateAction<boolean>>;
  placeholders: IDocumentPlaceholder[];
  documentType: 'DOCUMENT' | 'CONTRACT';
}

const ModalHowToUseVariables: React.FC<Props> = ({
  className,
  howToUseVariablesVisible,
  setHowToUseVariablesVisible,
  placeholders,
  documentType,
}) => {
  const { t } = useTranslation();
  const [groupedPlaceholders, setGroupedPlaceholders] = useState<any>({});

  useEffect(() => {
    if (placeholders) {
      setGroupedPlaceholders(
        placeholders.reduce(
          (group: any, placeholder) => {
            const { id, label, type } = placeholder;
            group[type] = group[type] ?? [];
            group[type].push({ id, label });
            return group;
          },
          documentType === 'CONTRACT' ? { signature: [] } : {},
        ),
      );
    }
  }, [placeholders]);

  const onCopy = (text: string) => {
    message.success(t('DOCUMENTS.VARIABLES.CATEGORIES.COPIED', { name: text }), 1);
  };

  return (
    <div className={className}>
      <Modal
        visible={howToUseVariablesVisible && placeholders.length > 0}
        onCancel={() => setHowToUseVariablesVisible(false)}
        closable
        footer={null}
        width="75%"
        style={{ top: '16px' }}
      >
        <h1>{t('DOCUMENTS.VARIABLES.MODAL.TITLE')}</h1>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1em' }}>
          <span>{t('DOCUMENTS.VARIABLES.MODAL.DESCRIPTION_1')}</span>
          <span>{t('DOCUMENTS.VARIABLES.MODAL.DESCRIPTION_2')}</span>
        </div>
        <Collapse accordion defaultActiveKey={[documentType === 'CONTRACT' ? 'signature' : 'account']}>
          {Object.entries(groupedPlaceholders).map(([key, value]: [string, any]) => {
            let name = '';
            let required = false;
            switch (key) {
              case 'account': {
                name = t('GLOBAL.ACCOUNT');
                break;
              }
              case 'department': {
                name = t('GLOBAL.DEPARTMENT');
                break;
              }
              case 'user_default': {
                name = t('DOCUMENTS.VARIABLES.CATEGORIES.USER_DEFAULT');
                break;
              }
              case 'user_custom': {
                name = t('DOCUMENTS.VARIABLES.CATEGORIES.USER_CUSTOM');
                break;
              }
              case 'document': {
                name = t('GLOBAL.DOCUMENT');
                break;
              }
              case 'signature': {
                name = t('DOCUMENTS.VARIABLES.CATEGORIES.SIGNATURES');
                required = documentType === 'CONTRACT' && true;
                break;
              }
            }
            return (
              <Collapse.Panel
                header={`${name}`}
                key={`${key}`}
                extra={required && <span style={{ color: colors.redDark }}>{t('GLOBAL.REQUIRED')}</span>}
              >
                {value.map((el: any) => (
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                    <span>{el.label}</span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <div
                        style={{
                          width: 'fit-content',
                          backgroundColor: colors.greyLight,
                          padding: '2px 5px',
                        }}
                      >
                        &#36;{`{${el.id}}`}
                      </div>
                      <CopyToClipboard text={`$\{${el.id}\}`} onCopy={() => onCopy(`$\{${el.id}\}`)}>
                        <Tooltip title={t('GLOBAL.COPY')}>
                          <i className="icon-clone" style={{ cursor: 'pointer' }} />
                        </Tooltip>
                      </CopyToClipboard>
                    </div>
                  </div>
                ))}
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </Modal>
    </div>
  );
};

export default styled(ModalHowToUseVariables)``;
