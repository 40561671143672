import colors from '@/styles/colors';
import { Button, message, Modal, Table } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import TableView from '@/layouts/TableView';
import moment from 'moment';
import { IInvoice } from '@/types/invoice.model';
import { DownloadOutlined } from '@ant-design/icons';
import Tag from './Tag';

interface Props {
  className?: string;
  visible: boolean;
  onClose: () => void;
}

const ModalOpenInvoices: React.FC<Props> = ({ className, visible, onClose }) => {
  const {
    state: { activeDepartmentId, activeDepartment },
  } = useContext(AppContext);

  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [loadingInvoices, setLoadingInvoices] = useState<boolean>(false);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });

    setColumns([
      {
        title: t('GLOBAL.BILL'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.DATE'),
        dataIndex: 'date',
        key: 'date',
        sorter: (a: IInvoice, b: IInvoice) => b.date - a.date,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IInvoice) => {
          return <span>{moment.unix(record.date).format('L')}</span>;
        },
      },
      {
        title: t('GLOBAL.TVAC_AMOUNT'),
        dataIndex: 'total',
        key: 'total',
        sorter: (a: IInvoice, b: IInvoice) => b.total - a.total,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IInvoice) => {
          return (
            <span>
              {new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: activeDepartment?.currency || 'EUR',
              }).format(record.total / 100)}
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.STATUS'),
        dataIndex: 'status',
        key: 'status',
        sorter: (a: any, b: any) => a.status.localeCompare(b.status),
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IInvoice) => {
          return (
            <Tag
              size="large"
              color={'white'}
              name={record.status}
              background={record.status === 'PAID' ? colors.green : colors.red}
            />
          );
        },
      },
      {
        title: t('GLOBAL.DOCUMENT'),
        key: 'document',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IInvoice) => {
          const [isLoading, setIsLoading] = useState<boolean>(false);
          return (
            <Button
              loading={isLoading}
              icon={<DownloadOutlined />}
              onClick={() => {
                setIsLoading(true);
                downloadInvoice(record.id).finally(() => {
                  setIsLoading(false);
                });
              }}
            >
              {t('GLOBAL.DOWNLOAD')}
            </Button>
          );
        },
      },
    ]);
  }, [i18n]);

  useEffect(() => {
    setInvoices([]);
    setLoadingInvoices(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/billing/invoices`, {
        params: {
          departmentId: activeDepartmentId,
          limit: 100,
          status: 'OPEN',
        },
      })
      .then((response) => {
        setInvoices(response.data?.datas);
        setLoadingInvoices(false);
      })
      .catch((error) => {
        console.log(error);
        message.error(t('SETTINGS.BILLING.BILLS.MESSAGE_ERROR_LOADING'));
        setLoadingInvoices(false);
      });
  }, [activeDepartmentId]);

  const downloadInvoice = (documentId: string) => {
    return new Promise<boolean>((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/billing/download`, {
          params: {
            departmentId: activeDepartmentId,
            type: 'invoice',
            documentId,
          },
        })
        .then((response) => {
          window.open(response.data, '_blank');
          resolve(true);
        })
        .catch((error) => {
          console.error(error);
          reject(false);
        });
    });
  };

  return (
    <Modal className={className} width={600} forceRender={true} onCancel={onClose} visible={visible} footer={null}>
      <h2>{t('SETTINGS.BILLING.BILLS.TITLE')}</h2>
      <TableView>
        <Table
          className={className}
          loading={loadingInvoices}
          dataSource={invoices}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
      </TableView>
    </Modal>
  );
};

export default ModalOpenInvoices;
