import AppContext from '@/pages/app/context';
import { default as Colors, default as colors } from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { IQuotaTemplateDetail } from '@/types/insights/quota-template-detail.model';
import { IScheduleHour } from '@/types/schedule-hour.model';
import { handleError, isFeatureEnabled, isNullOrUndefined } from '@/utils';
import { Affix, Input, Tooltip } from 'antd';
import axios from 'axios';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  offsetTop: number;
  totalEmployees: number[];
  totalHeadCounts: (number | null)[];
  columnWidth: number;
  refTotalHours: React.MutableRefObject<null> | null;
  refPageContainer: React.MutableRefObject<null> | null;
  hours: IScheduleHour[];
  selectedDate: Moment;
  balance: any;
  quotas: any;
  turnover: any;
}

const TotalEmployees: React.FC<Props> = ({
  className,
  offsetTop,
  totalEmployees,
  totalHeadCounts,
  columnWidth,
  refTotalHours,
  refPageContainer,
  hours,
  selectedDate,
  balance,
  quotas,
  turnover,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [affixed, setAffixed] = useState(false);
  const {
    state: { quotaTemplates, activeSection, activeDepartmentId, quotaCustom, quotaCustomTemplates, features },
    dispatch,
  } = useContext(AppContext);
  const [editingQuotaId, setEditingQuotaId] = useState<string | null>(null);

  useEffect(() => {
    setEditingQuotaId(null);
  }, [activeSection]);

  const handleAffixed = (affixed: boolean) => {
    setAffixed(affixed);
    setTimeout(() => {
      const element = refPageContainer!.current! as HTMLElement;
      if (element) {
        element.dispatchEvent(new Event('scroll'));
      }
    });
  };

  const onEditContent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const id = e.currentTarget.id;
    if (editingQuotaId == id) {
      setEditingQuotaId(null);
    } else {
      setEditingQuotaId(id);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      const input = e.target as HTMLInputElement;
      if (!input.value) return;
      const hour = input.id;
      const date = moment(`${selectedDate.format('YYYY-MM-DD')} ${hour}`);

      const activeQuotaTemplate = quotaTemplates.find((template) => template.default);
      const quotaTemplateDetails = activeQuotaTemplate
        ? activeSection
          ? activeQuotaTemplate.details.filter((detail) => String(detail.section_id) == activeSection)
          : activeQuotaTemplate.details
        : [];
      const quotaForHour = quotaTemplateDetails.find(
        (detail) => detail.hour == hour && detail.day == selectedDate.day(),
      );

      const customQuota = hour
        ? quotaCustom.find(
            (custom) => custom.section_id == activeSection && moment(custom.date).hour() == Number(hour.split(':')[0]),
          )
        : null;

      if (!customQuota && quotaForHour && quotaForHour.value == Number(input.value)) {
        setEditingQuotaId(null);
        return;
      }

      if (customQuota && customQuota.value == Number(input.value)) {
        setEditingQuotaId(null);
        return;
      }

      if (customQuota) {
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}/v3/quotas/quota-custom/${customQuota.id}`,
            {
              value: Number(input.value),
              date: date.unix(),
            },
            {
              params: {
                departmentId: activeDepartmentId,
                sectionId: activeSection,
              },
            },
          )
          .then(({ data }) => {
            dispatch({
              type: 'SET_QUOTA_CUSTOM',
              payload: quotaCustom.map((quota) => (quota.id == data.id ? data : quota)),
            });
          })
          .catch((err) => {
            handleError(err);
          })
          .finally(() => {
            setEditingQuotaId(null);
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v3/quotas/quota-custom`,
            {
              value: Number(input.value),
              date: date.unix(),
            },
            {
              params: {
                departmentId: activeDepartmentId,
                sectionId: activeSection,
              },
            },
          )
          .then(({ data }) => {
            dispatch({
              type: 'SET_QUOTA_CUSTOM',
              payload: [...quotaCustom, data],
            });
          })
          .catch((err) => {
            handleError(err);
          })
          .finally(() => {
            setEditingQuotaId(null);
          });
      }
    }
  };

  const customTemplateDay = quotaCustomTemplates.find((template) => template.day == selectedDate.format('YYYY-MM-DD'));
  const customTemplateWeek = quotaCustomTemplates.find(
    (template) => template.week_number == selectedDate.week() && template.year == selectedDate.year(),
  );

  const customTemplate = quotaTemplates.find((template) =>
    customTemplateDay ? template.id == customTemplateDay.template_id : template.id == customTemplateWeek?.template_id,
  );

  const activeQuotaTemplate = customTemplate ? customTemplate : quotaTemplates.find((template) => template.default);
  const quotaTemplateDetails = (activeQuotaTemplate
    ? activeSection
      ? activeQuotaTemplate.details.filter((detail) => String(detail.section_id) == activeSection)
      : activeQuotaTemplate.details
    : []
  ).filter((detail) => detail.day == selectedDate.day());

  return (
    <Affix
      target={() => document.getElementsByClassName('app-hours-container')[0] as HTMLElement}
      onChange={(affixed) => handleAffixed(!!affixed)}
      offsetTop={offsetTop}
    >
      <div ref={refTotalHours} className={`${className} ${affixed ? 'affixed' : ''}`}>
        <div className="left">
          {(!activeQuotaTemplate || !quotaTemplateDetails || quotaTemplateDetails.length == 0) && (
            <i className="icon-user" />
          )}
          {activeQuotaTemplate && quotaTemplateDetails && quotaTemplateDetails.length > 0 ? (
            <span className="active-quota-template">{activeQuotaTemplate?.name}</span>
          ) : (
            t('SCHEDULE.TOTAL_USERS_PLANNED')
          )}
        </div>
        <div className={`right`}>
          {((quotas && turnover) ||
            (!isFeatureEnabled(features, FEATURES.QUOTAS) && !isFeatureEnabled(features, FEATURES.PRODUCTIVITY))) &&
            hours.map((hour, index) => {
              const actualNumber = totalEmployees[index];
              if (!isFeatureEnabled(features, FEATURES.QUOTAS) && !isFeatureEnabled(features, FEATURES.PRODUCTIVITY)) {
                return (
                  <div
                    style={{
                      width: columnWidth,
                      fontWeight: 'bold',
                    }}
                    onClick={(e) => onEditContent(e)}
                  >
                    <i className="icon-user"></i>{' '}
                    <span
                      className={`total-planned-actualNumber hour-string-${moment.unix(hour.date).format('HH:mm')}`}
                    >
                      {actualNumber}
                    </span>
                  </div>
                );
              }
              const outOfBounds = !moment.unix(hour.date).isSame(selectedDate, 'day');
              let hourString = moment.unix(hour.date).local().format('HH:mm');
              const quotaForHour = activeSection
                ? quotaTemplateDetails.find((detail) => detail.hour == hourString && detail.day == selectedDate.day())
                  ? quotaTemplateDetails.find((detail) => detail.hour == hourString && detail.day == selectedDate.day())
                      ?.value
                  : 0
                : quotaTemplateDetails
                    .filter((detail) => detail.hour == hourString && detail.day == selectedDate.day())
                    .reduce((result: number, item: IQuotaTemplateDetail) => result + item.value, 0);

              const customValue = hour
                ? quotaCustom.find(
                    (custom) =>
                      custom.section_id == activeSection &&
                      moment(custom.date).hour() == Number(hourString.split(':')[0]),
                  )
                : null;

              const quotaValue = customValue ? customValue.value : quotaForHour ? quotaForHour : 0;

              if (!actualNumber && !quotaForHour) {
                return <div key={`budget_${index}`} style={{ width: columnWidth }}></div>;
              }

              if (quotaForHour) {
                const id = `totalEmployee_${index}`;
                return (
                  <Tooltip
                    title={
                      id == editingQuotaId ? t('GLOBAL.ENTER_TO_SAVE') : t('SCHEDULE.QUOTA_TEMPLATES.CLICK_EDIT_QUOTA')
                    }
                  >
                    {id != editingQuotaId ? (
                      <div
                        // className={`${outOfBounds ? 'out-of-bounds' : ''}`}
                        id={id}
                        key={id}
                        style={{
                          width: columnWidth,
                          cursor: quotaForHour ? 'pointer' : 'default',
                          backgroundColor: customTemplate || customValue ? colors.orangeBright : 'initial',
                          color:
                            actualNumber > quotaValue ? 'orangered' : actualNumber <= quotaValue ? 'seagreen' : 'black',
                          fontWeight: 'bold',
                        }}
                        onClick={(e) => onEditContent(e)}
                      >
                        <i className="icon-user"></i>{' '}
                        <span
                          className={`total-planned-actualNumber hour-string-${moment.unix(hour.date).format('HH:mm')}`}
                        >
                          {actualNumber}
                        </span>
                        /<div id={`${id}-quota`}>{quotaValue}</div>
                      </div>
                    ) : (
                      <div className={`${outOfBounds ? 'out-of-bounds' : ''}`}>
                        <Input id={hourString} defaultValue={quotaValue} onKeyPress={(e) => onKeyPress(e)} autoFocus />
                      </div>
                    )}
                  </Tooltip>
                );
              }

              const quotasForDay = quotas[moment.unix(hour.date).locale('en').format('dddd').toLowerCase()];
              const hourHours = moment.unix(hour.date).hours();
              if (!quotasForDay || !hourHours) {
                return (
                  <div key={`budget_${index}`} style={{ width: columnWidth }}>
                    <i className="icon-user"></i>{' '}
                    <span
                      className={`total-planned-actualNumber hour-string-${moment.unix(hour.date).format('HH:mm')}`}
                    >
                      {actualNumber}
                    </span>
                  </div>
                );
              }

              const found = quotasForDay.find((quota: any) => quota.start == hourHours);
              if (!found)
                return (
                  <div key={`budget_${index}`} style={{ width: columnWidth }}>
                    <i className="icon-user"></i>{' '}
                    <span
                      className={`total-planned-actualNumber hour-string-${moment.unix(hour.date).format('HH:mm')}`}
                    >
                      {actualNumber}
                    </span>
                  </div>
                );

              let date = moment.unix(hour.date).startOf('day').unix();
              if (moment.unix(hour.date).day() != selectedDate.day()) {
                date = selectedDate.startOf('day').unix();
              }

              const turnoverForDay = turnover[date];
              if (!turnoverForDay)
                return (
                  <div key={`budget_${index}`} style={{ width: columnWidth }}>
                    <i className="icon-user"></i>{' '}
                    <span
                      className={`total-planned-actualNumber hour-string-${moment.unix(hour.date).format('HH:mm')}`}
                    >
                      {actualNumber}
                    </span>
                  </div>
                );
              const diffInHours = found.start > found.end ? found.end + 24 - found.start : found.end - found.start;
              const dayInsight = Number((turnoverForDay.value * found.percentage) / 100 / diffInHours);
              if (!dayInsight) {
                return (
                  <div key={`budget_${index}`} style={{ width: columnWidth }}>
                    <i className="icon-user"></i>{' '}
                    <span
                      className={`total-planned-actualNumber hour-string-${moment.unix(hour.date).format('HH:mm')}`}
                    >
                      {actualNumber}
                    </span>
                  </div>
                );
              }

              const totalUsers =
                balance && dayInsight
                  ? balance.find((element: any) => dayInsight > element.start && dayInsight <= element.end) &&
                    balance.find((element: any) => dayInsight > element.start && dayInsight <= element.end).staff
                  : null;

              return (
                <div
                  className={`${outOfBounds ? 'out-of-bounds' : ''}`}
                  key={`totalEmployee_${index}`}
                  style={{
                    width: columnWidth,
                    color: actualNumber > totalUsers ? 'orangered' : actualNumber <= totalUsers ? 'seagreen' : 'black',
                  }}
                >
                  <i className="icon-user"></i>{' '}
                  <span className={`total-planned-actualNumber hour-string-${moment.unix(hour.date).format('HH:mm')}`}>
                    {actualNumber}
                  </span>
                  {!isNullOrUndefined(totalUsers) && '/'}
                  {!isNullOrUndefined(totalUsers) && (
                    <span className={`total-planned-objective hour-string-${moment.unix(hour.date).format('HH:mm')}`}>
                      {totalUsers}
                    </span>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </Affix>
  );
};

const TotalEmployeesStyled = styled(TotalEmployees)`
  display: flex;
  position: relative;
  border-bottom: 1px solid ${Colors.blueLight};
  background-color: #ffcf97;
  width: 100%;

  @media (max-width: 1440px) {
    min-width: 100%;
  }

  &.affixed {
    max-width: 100%;
    overflow: hidden;
  }

  > div {
    &.left {
      flex-shrink: 0;
      position: sticky;
      left: 0;
      border-right: 1px solid ${Colors.blueLight};
      width: 210px;
      background-color: #ffcf97;
      padding: 10px;

      .active-quota-template {
        background-color: ${colors.blueLightPastel};
        padding: 3px 10px;
        border-radius: 8px;
        color: #fff;
      }
    }

    &.right {
      font-size: 10px;
      display: flex;
      flex-grow: 1;

      > div {
        padding: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 0px;
        flex-shrink: 0;

        &:nth-child(n + 2) {
          border-left: 1px solid ${Colors.blueLight};
        }
        &.out-of-bounds {
          background: ${Colors.greyLight} !important;
        }
      }
    }
  }
`;

export default TotalEmployeesStyled;
