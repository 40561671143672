import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void | AxiosResponse<any>>;
}

const ModalDeleteShiftConfirmation: React.FC<Props> = ({ visible, onClose, onConfirm }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!visible) {
      setIsSaving(false);
    }
  }, [visible]);

  const handleConfirm = () => {
    setIsSaving(true);
    const promise = onConfirm();
    promise
      .then((response) => {
        setIsSaving(false);
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      className={'modal-danger'}
      title={t('SHIFTS.MODAL_DELETE_SHIFT.TITLE')}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={isSaving} onClick={handleConfirm}>
          {t('SHIFTS.MODAL_DELETE_SHIFT.CONFIRM_BUTTON_FOR_ONE')}
        </Button>,
      ]}
    >
      <p>{t('SHIFTS.MODAL_DELETE_SHIFT.DESCRIPTION_FOR_ONE')}</p>
    </Modal>
  );
};

export default ModalDeleteShiftConfirmation;
