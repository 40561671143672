import React from 'react';
import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { IMessage } from '@/types/message.model';

interface Props {
  className?: string;
  setActiveMessage: React.Dispatch<React.SetStateAction<IMessage | null>>;
}

const Header: React.FC<Props> = ({ className, setActiveMessage }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <header className={className}>
      <div className="left">
        <Space>
          <h2>{t('FREE_SHIFT_NOTIFICATIONS.TITLE')}</h2>
        </Space>
      </div>
      <div className="right">
        <div className="actions">
          <Button type="primary" style={{ width: '160px' }} onClick={() => setActiveMessage({ type: 'CUSTOM' })}>
            {t('GLOBAL.ADD')}
          </Button>
        </div>
      </div>
    </header>
  );
};

export default Header;
