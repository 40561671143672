import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { IBiUser } from '@/types/bi/user.model';
import { IBiDepartment } from '@/types/bi/department.model';
import { createNumberArray } from '@/utils';
import DepartmentUserShift from './DepartmentUserShift';
import { debounce } from 'lodash';
import { IBiSort } from '@/types/bi/sort.model';

interface Props {
  className?: string;
  department: IBiDepartment;
  user: IBiUser;
  getDashboard: (recordId?: string | null) => void;
}

const DepartmentUserRow: React.FC<Props> = ({ className, department, user, getDashboard }) => {
  const { t } = useTranslation();
  const {
    state: { groupAdminSections, groupAdminAttributes, groupAdminSkills, groupAdminStatus, groupAdminUsers },
    dispatch,
  } = useContext(AppContext);
  let rightRef = useRef<HTMLDivElement | null>(null);
  const [fullWidth, setFullWidth] = useState<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (rightRef.current) {
        setFullWidth(rightRef.current.clientWidth);
      }
    };
    const handleResizeDebounced = debounce(handleResize, 100);

    handleResizeDebounced();

    window.addEventListener('resize', handleResizeDebounced);

    return () => {
      window.removeEventListener('resize', handleResizeDebounced);
    };
  }, []);

  const hours = createNumberArray(0, 23);

  let shifts = user.shifts ? [...user.shifts] : [];
  shifts = shifts.filter((shift) => {
    if (
      groupAdminAttributes.length == 0 &&
      groupAdminSections.length == 0 &&
      groupAdminSkills.length == 0 &&
      groupAdminStatus.length == 0 &&
      groupAdminUsers.length == 0
    )
      return true;
    if (user && groupAdminUsers.length > 0 && groupAdminUsers.includes(user.id)) return true;
    if (user && user.user_type && groupAdminStatus.length > 0 && groupAdminStatus.includes(user.user_type)) return true;
    if (groupAdminSections.length > 0 && groupAdminSections.includes(String(shift.section))) return true;
    if (
      shift.skills &&
      groupAdminSkills.length > 0 &&
      groupAdminSkills.some((element) => shift.skills.includes(element))
    )
      return true;
    if (
      shift.attributes &&
      groupAdminAttributes.length > 0 &&
      groupAdminAttributes.some((element) => shift.attributes.includes(element))
    )
      return true;
    return false;
  });

  const onUserClick = () => {
    dispatch({
      type: 'SET_GROUP_ADMIN_ACTIVE_USER',
      payload: user,
    });
    getDashboard(user.id);
  };

  if (shifts.length == 0) return null;

  return (
    <div className={className}>
      <div className="left">
        <span className="username" onClick={onUserClick}>
          {user.name}
        </span>
      </div>
      <div ref={rightRef} className="right">
        {fullWidth &&
          hours.map((hour) => (
            <div key={hour} className="hour">
              {shifts.map((shift) => (
                <DepartmentUserShift
                  key={shift.id}
                  department={department}
                  shift={shift}
                  fullWidth={fullWidth}
                  hours={hours}
                />
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default styled(DepartmentUserRow)`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;

  .left {
    width: 200px;
    border-right: 1px solid #efefef;
    border-left: 1px solid #efefef;
    padding: 5px 10px;

    .username {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    .hour {
      flex: 1;
      text-align: center;
      border-right: 1px solid #efefef;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
