import jwt_decode from 'jwt-decode';
import { InitialStateType } from './context';

interface RESET {
  type: 'RESET';
}

interface SET_ACCESS_TOKEN {
  type: 'SET_ACCESS_TOKEN';
  payload: string;
}

interface SET_LANG {
  type: 'SET_LANG';
  payload: string | null;
}

interface SET_USER_DETAILS {
  type: 'SET_USER_DETAILS';
  payload: InitialStateType['userDetails'];
}

interface SET_IMPERSONATE_TOKEN {
  type: 'SET_IMPERSONATE_TOKEN';
  payload: string;
}

interface SET_IMPERSONATE_DEPARTMENT_ID {
  type: 'SET_IMPERSONATE_DEPARTMENT_ID';
  payload: string | null;
}

interface SET_TRIAL_END_DATE {
  type: 'SET_TRIAL_END_DATE';
  payload: number | null;
}

interface SET_GIT_SHA {
  type: 'SET_GIT_SHA';
  payload: string | null;
}

interface SET_BLOCKED {
  type: 'SET_BLOCKED';
  payload: boolean;
}

interface SET_ERRORS {
  type: 'SET_ERRORS';
  payload: string[];
}

interface SET_PUSHER_ID {
  type: 'SET_PUSHER_ID';
  payload: string | null;
}

export type ActionsType =
  | RESET
  | SET_BLOCKED
  | SET_ACCESS_TOKEN
  | SET_USER_DETAILS
  | SET_LANG
  | SET_IMPERSONATE_TOKEN
  | SET_GIT_SHA
  | SET_TRIAL_END_DATE
  | SET_ERRORS
  | SET_IMPERSONATE_DEPARTMENT_ID
  | SET_PUSHER_ID;

export const setToken = (
  dispatch: React.Dispatch<ActionsType>,
  token: string | null,
  impersonate = false,
  departmentId: string | null = null,
): void => {
  if (token) {
    const decoded = jwt_decode(token) as any;
    const userDetails = decoded.user as InitialStateType['userDetails'];
    if (userDetails?.role === 'USER') {
      window.location.replace('https://mobile.shyfter.co');
      return;
    }

    if (impersonate) {
      dispatch({
        type: 'SET_IMPERSONATE_TOKEN',
        payload: token,
      });
      dispatch({
        type: 'SET_IMPERSONATE_DEPARTMENT_ID',
        payload: departmentId,
      });
    } else {
      dispatch({
        type: 'SET_ACCESS_TOKEN',
        payload: token,
      });
    }

    dispatch({
      type: 'SET_USER_DETAILS',
      payload: userDetails,
    });
  } else {
    dispatch({
      type: 'RESET',
    });
  }
};
