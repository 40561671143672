import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { isFeatureEnabled, isNullOrUndefined } from '@/utils';
import { BackTop, Button, Checkbox, Form, Modal, Table, message } from 'antd';
import Axios from 'axios';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IHrSheet } from '..';
import Header from './Header';
moment.tz.setDefault('Atlantic/Reykjavik');

import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { CheckOutlined, WarningOutlined } from '@ant-design/icons';

interface Props {
  departmentId?: string;
  dataSource: any[];
  loading: boolean;
  datePickerValue: Moment;
  setDatePickerValue: React.Dispatch<React.SetStateAction<Moment>>;
  userRecordId: string | null;
  setUserRecordId: React.Dispatch<React.SetStateAction<string | null>>;
  setHrSheets: React.Dispatch<React.SetStateAction<IHrSheet[]>>;
  setLoadingHrSheets: React.Dispatch<React.SetStateAction<boolean>>;
}

const HrSheetsList: React.FC<Props> = ({
  departmentId,
  dataSource,
  loading,
  datePickerValue,
  setDatePickerValue,
  userRecordId,
  setUserRecordId,
  setHrSheets,
  setLoadingHrSheets,
}) => {
  const {
    state: { loadingUsers, users, userCategories, activeDepartmentId, activeDepartment, features },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);
  const [isExportingTimesheets, setIsExportingTimesheets] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [validating, setValidating] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const savedFilters = localStorage.getItem(`table-filters-${activeDepartmentId}`);
    if (savedFilters) {
      setFilters(JSON.parse(savedFilters));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(`table-filters-${activeDepartmentId}`, JSON.stringify(filters));
  }, [filters]);

  const onChange = (pagination: any, filters: any) => {
    setFilters(filters);
  };

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    form.resetFields();
    form.setFieldsValue({
      ...dataSource?.reduce((x: any, b: IHrSheet) => ({ ...x, [b.recordId]: b.validated }), {}),
    });
    checkIfEverythingIsSelected();
    setColumns([
      {
        title: t('GLOBAL.ID'),
        key: 'recordId',
        dataIndex: 'recordId',
        sorter: (a: IHrSheet, b: IHrSheet) => +a.recordId - +b.recordId,
      },
      {
        title: 'SSA-ID',
        key: 'ssaId',
        dataIndex: 'ssaId',
        sorter: (a: IHrSheet, b: IHrSheet) => +a.ssaId - +b.ssaId,
      },
      {
        title: t('GLOBAL.NAME'),
        key: 'displayName',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IHrSheet) => {
          const { recordId, displayName, ssa } = record || {};
          const User = recordId ? (
            <Link to={`/app/team/collaborators/${recordId}`}>{displayName}</Link>
          ) : (
            <span>{displayName}</span>
          );

          const Icon = isNullOrUndefined(ssa) ? null : ssa ? (
            <CheckOutlined style={{ color: colors.green, marginRight: 10 }} />
          ) : (
            <WarningOutlined style={{ color: colors.red, marginRight: 10 }} />
          );

          return (
            <div>
              {Icon}
              {User}
            </div>
          );
        },
        sorter: (a: IHrSheet, b: IHrSheet) => a.displayName.localeCompare(b.displayName),
      },
      {
        title: t('GLOBAL.PERIOD'),
        key: 'start',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IHrSheet) => {
          return <span style={{ textTransform: 'capitalize' }}>{moment.unix(record.start!).format('MMMM YYYY')}</span>;
        },
      },
      {
        title: t('GLOBAL.USER_CATEGORY_SHORT'),
        key: 'status',
        dataIndex: ['status', 'name'],
        onFilter: (value: any, record: any) => record.status?.id === value,
        filters: userCategories.map((userCategory) => ({ value: userCategory.id, text: userCategory.name })),
        render: (text: any, record: IHrSheet) => {
          return <span style={{ textTransform: 'capitalize' }}>{text}</span>;
        },
      },
      {
        title: t('GLOBAL.PLANNED'),
        key: 'hours',
        dataIndex: 'hours',
        sorter: (a: IHrSheet, b: IHrSheet) => +a.hours - +b.hours,
      },
      {
        title: t('GLOBAL.WORKED'),
        key: 'clocking',
        dataIndex: 'clocking',
        sorter: (a: IHrSheet, b: IHrSheet) => a.clocking - b.clocking,
      },
      {
        title: t('GLOBAL.ABSENCES'),
        key: 'dayoffs',
        dataIndex: 'dayoffs',
        sorter: (a: IHrSheet, b: IHrSheet) => +a.dayoffs - +b.dayoffs,
      },
      // {
      //   title: t('REPORTS.HR_TIMESHEETS.UNPLANNED_SHORT'),
      //   key: 'unplanned',
      //   dataIndex: 'unplanned',
      // },
      {
        title: t('GLOBAL.VALIDATED'),
        key: 'validated',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IHrSheet) => {
          return (
            <Form.Item name={record.recordId} valuePropName="checked" noStyle>
              {record.validated ? (
                <>
                  <i className="icon-ok-circled" style={{ color: colors.green }} />
                  {record.viewmode == 'PLANNED' && <span>📅</span>}
                  {record.viewmode == 'CLOCKIN' && <span>⏱️</span>}
                </>
              ) : (
                <i className="icon-delete" style={{ color: colors.red }} />
              )}
            </Form.Item>
          );
        },
        sorter: (a: IHrSheet, b: IHrSheet) => +a.validated - +b.validated,
      },
      {
        key: 'actions',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IHrSheet) => {
          const { recordId } = record;
          return (
            <>
              <Link to={`${recordId}/${datePickerValue.unix()}`}>
                <Button type="primary">{t('GLOBAL.DETAIL')}</Button>
              </Link>
              <Checkbox
                checked={selectedIds.includes(recordId)}
                style={{ marginLeft: 15 }}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedIds([...selectedIds, recordId]);
                  } else {
                    setSelectedIds(selectedIds.filter((id) => id !== recordId));
                  }
                }}
              />
            </>
          );
        },
      },
    ]);
  }, [i18n.language, dataSource, userCategories, allSelected, selectedIds]);

  const checkIfEverythingIsSelected = () => {
    for (const row in form.getFieldsValue()) {
      if (!row) {
        return false;
      }
    }
    return true;
  };

  const onWantToExportTimesheets = () => {
    Modal.confirm({
      title: t('REPORTS.HR_TIMESHEETS.MODAL_EXPORT.TITLE'),
      type: 'warning',
      content: t('REPORTS.HR_TIMESHEETS.MODAL_EXPORT.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.YES'),
      okType: 'danger',
      onOk: () => {
        onExportTimesheets();
      },
      onCancel: () => {},
    });
  };

  const onExportTimesheets = () => {
    setIsExportingTimesheets(true);
    Axios.post(`${process.env.REACT_APP_API_URL}/v3/operations/hr-sheets/generateNew`, {
      start: datePickerValue.unix(),
      departmentId,
    })
      .then(() => {
        //history.push(`/app/reports/hr-sheet-exports`);
        setIsExportingTimesheets(false);
      })
      .catch((error) => {
        setIsExportingTimesheets(false);
        message.error(t('REPORTS.HR_TIMESHEETS.GENERATE_EXPORT_FILES_ERROR'));
        /*
        if (error.response?.data?.message) {
          message.error(error.response?.data?.message);
        }
        */
      });
  };

  const selectAll = (selected: boolean) => {
    const ids = Object.keys(form.getFieldsValue());

    setAllSelected(selected);
    if (selected) {
      setSelectedIds(ids);
    } else {
      setSelectedIds([]);
    }
  };

  const onValidate = () => {
    setValidating(true);
    const values = form.getFieldsValue();
    const recordIds: string[] = [];
    for (const row in values) {
      if (values[row]) {
        recordIds.push(row);
      }
    }
    Axios.post(`${process.env.REACT_APP_API_URL}/v3/reports/hr-sheets/new/validateMultiple`, {
      start: datePickerValue.unix(),
      departmentId,
      recordIds: selectedIds,
      validated: true,
    })
      .then(() => {
        Axios.get(`${process.env.REACT_APP_API_URL}/v3/reports/hr-sheets`, {
          params: {
            departmentId: activeDepartmentId,
            start: datePickerValue.unix(),
            recordId: userRecordId,
          },
        })
          .then(({ data }) => {
            message.success(
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span>{t('REPORTS.TIMESHEETS.HR_SHEET_GENERATING_MESSAGE')}</span>
                <span>{t('REPORTS.TIMESHEETS.HR_SHEET_GENERATING_MESSAGE_2')}</span>
              </div>,
            );
            selectAll(false);
            setValidating(false);
            setHrSheets(data);
            setLoadingHrSheets(false);
          })
          .catch((error) => {
            if (Axios.isCancel(error)) {
              console.error(error);
            }
            setLoadingHrSheets(false);
          });
      })
      .catch((error) => {
        setValidating(false);
        message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
      });
  };

  const onInvalidate = () => {
    setValidating(true);
    const values = form.getFieldsValue();
    const recordIds: string[] = [];
    for (const row in values) {
      if (values[row]) {
        recordIds.push(row);
      }
    }
    Axios.post(`${process.env.REACT_APP_API_URL}/v3/reports/hr-sheets/new/validateMultiple`, {
      start: datePickerValue.unix(),
      departmentId,
      recordIds: selectedIds,
      validated: false,
    })
      .then(() => {
        Axios.get(`${process.env.REACT_APP_API_URL}/v3/reports/hr-sheets`, {
          params: {
            departmentId: activeDepartmentId,
            start: datePickerValue.unix(),
            recordId: userRecordId,
          },
        })
          .then(({ data }) => {
            message.success(t('REPORTS.TIMESHEETS.HR_SHEET_GENERATING_MESSAGE'));
            selectAll(false);
            setValidating(false);
            setHrSheets(data);
            setLoadingHrSheets(false);
          })
          .catch((error) => {
            if (Axios.isCancel(error)) {
              console.error(error);
            }
            setLoadingHrSheets(false);
          });
      })
      .catch((error) => {
        setValidating(false);
        message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
      });
  };

  const filteredColumns = columns.map((col) => ({
    ...col,
    filteredValue: col && filters[col.key],
  }));

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header
        userRecordId={userRecordId}
        setUserRecordId={setUserRecordId}
        datePickerValue={datePickerValue}
        setDatePickerValue={setDatePickerValue}
        onWantToExportTimesheets={onWantToExportTimesheets}
        allSelected={allSelected}
        selectAll={selectAll}
        onValidate={onValidate}
        validating={validating}
        isExportingTimesheets={isExportingTimesheets}
        loadingUsers={loadingUsers}
        users={users}
        onInvalidate={onInvalidate}
        dataSource={dataSource}
      />
      <Form form={form}>
        <Table
          loading={loading}
          dataSource={dataSource}
          columns={
            isFeatureEnabled(features, FEATURES.CLOCKING)
              ? filteredColumns
              : filteredColumns.filter((column) => column.key !== 'clocking')
          }
          rowKey="recordId"
          pagination={false}
          onChange={onChange}
        />
      </Form>
    </TableView>
  );
};

export default HrSheetsList;
