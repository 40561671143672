import DrawerSkill from '@/pages/app/components/drawers/Skill';
import AppContext from '@/pages/app/context';
import { ISkill } from '@/types/skill.model';
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Option = Select.Option;

interface Props {
  className?: string;
  form: FormInstance;
}

const Skills: React.FC<Props> = ({ className, form }) => {
  const {
    state: { skills, loadingSkills },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: true });

  const [drawerSkillIsVisible, setDrawerSkillIsVisible] = useState<boolean>(false);

  const onCreateSkill = (result: ISkill) => {
    const skillsCopy = [...skills];
    skillsCopy.push(result);
    const skillsCopySorted = skillsCopy.sort((a, b) => {
      if (a.name!.toLowerCase() < b.name!.toLowerCase()) {
        return -1;
      }
      if (a.name!.toLowerCase() > b.name!.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    appContextDispatch({
      type: 'SET_SKILLS',
      payload: skillsCopySorted,
    });
    const selectedSkills = form.getFieldValue('skills');
    form.setFieldsValue({
      skills: [...selectedSkills, result.id],
    });
  };

  return (
    <div className={className}>
      <h4>{t('GLOBAL.SKILLS')}</h4>
      <Form.Item name="skills" style={{ marginBottom: 0 }}>
        <Select
          loading={loadingSkills}
          disabled={loadingSkills}
          mode="multiple"
          placeholder={t('FORMS.SKILLS_PLACEHOLDER')}
          optionFilterProp="children"
          getPopupContainer={(trigger) => trigger}
          filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          suffixIcon={<i className="icon-search" />}
        >
          {skills.map((skill) => (
            <Option value={skill.id!} key={`skill_${skill.id}`}>
              {skill.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <button
        className="green"
        style={{ marginTop: 8, marginBottom: 15 }}
        onClick={() => setDrawerSkillIsVisible(!drawerSkillIsVisible)}
      >
        + {t('FORMS.ADD_NEW_SKILL')}
      </button>
      <DrawerSkill
        visible={drawerSkillIsVisible}
        onClose={() => setDrawerSkillIsVisible(false)}
        onSave={onCreateSkill}
      />
    </div>
  );
};

export default styled(Skills)`
  width: 50%;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;
