import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { IBiDepartment } from '@/types/bi/department.model';
import { createNumberArray, getContrast, minutesToHoursAndOrMinutes } from '@/utils';
import { IBiShift } from '@/types/bi/shift.model';
import moment from 'moment';
import { Tooltip } from 'antd';
import { IBiSort } from '@/types/bi/sort.model';

interface Props {
  className?: string;
  shift: IBiShift;
  fullWidth: number;
  hours: number[];
  department: IBiDepartment;
}

const DepartmentUserShift: React.FC<Props> = ({ className, shift, fullWidth, hours, department }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, groupAdminSort },
    dispatch,
  } = useContext(AppContext);

  const cellWidth = fullWidth / hours.length;
  const startHour = moment(shift.startdate).hour();
  const offset = hours.findIndex((hour) => hour == startHour) + moment(shift.startdate).minutes() / 60;
  const shiftWidth = (shift.shift_duration / 60) * cellWidth;
  const breakWidth = ((shift.pause_paid + shift.pause_unpaid) / 60) * cellWidth;
  const user = department.users.find((user) => user.id == shift.ressource_record_id);
  const section =
    shift.section && department.sections
      ? department.sections.find((section) => section.id == String(shift.section))
      : null;
  const skill =
    shift.skills && shift.skills.length > 0 && department.skills
      ? department.skills.find((skill) => skill.id == shift.skills[0])
      : null;
  const status =
    user && department.categories ? department.categories.find((status) => status.id == user.user_type) : null;

  let background = '#EFEFEF';
  if (skill && skill.color && groupAdminSort == IBiSort.SKILLS) {
    background = skill.color;
  }
  if (status && status.color && groupAdminSort == IBiSort.STATUS) {
    background = status.color;
  }
  if (section && section.color && groupAdminSort == IBiSort.SECTIONS) {
    background = section.color;
  }
  if (shift.shyft_type == 2) {
    background = '#ED6E5D';
  }

  const content = (
    <div
      className={`${className} ${shift.shyft_type == 2 ? 'dayoff' : ''}`}
      style={{
        left: cellWidth * offset,
        width: shiftWidth,
        backgroundColor: background,
        color: getContrast(background),
      }}
    >
      <span className="hours">
        {moment(shift.startdate).format('HH:mm')} - {moment(shift.enddate).format('HH:mm')} (
        {minutesToHoursAndOrMinutes(shift.shift_duration + shift.pause_paid + shift.pause_unpaid)})
      </span>
      {(shift.pause_paid > 0 || shift.pause_unpaid > 0) && (
        <div className="break" style={{ width: breakWidth, color: getContrast(background) }}>
          {minutesToHoursAndOrMinutes(shift.pause_paid + shift.pause_unpaid)}
        </div>
      )}
    </div>
  );

  const tooltip =
    groupAdminSort == IBiSort.SKILLS
      ? skill?.name
      : groupAdminSort == IBiSort.SECTIONS
      ? section?.name
      : groupAdminSort == IBiSort.STATUS
      ? status?.name
      : null;

  if (tooltip) {
    return <Tooltip title={tooltip}>{content}</Tooltip>;
  }

  return content;
};

export default styled(DepartmentUserShift)`
  position: absolute;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;

  .hours {
    font-size: 10px;
  }

  &.dayoff {
    background-image: repeating-linear-gradient(
      -55deg,
      transparent,
      transparent 10px,
      rgba(0, 0, 0, 0.1) 10px,
      rgba(0, 0, 0, 0.1) 20px
    );
  }

  .break {
    height: 95%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    background-image: repeating-linear-gradient(
      -55deg,
      transparent,
      transparent 10px,
      rgba(0, 0, 0, 0.1) 10px,
      rgba(0, 0, 0, 0.1) 20px
    );
    font-size: 10px;
  }
`;
