import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { List, Modal } from 'antd';
import moment from 'moment';

interface Props {
  className?: string;
  visible: boolean;
  invalidInputKeys: string[];
  setModalInvalidInputs: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalInvalidInputs: React.FC<Props> = ({ className, visible, invalidInputKeys, setModalInvalidInputs }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);

  const onOk = () => {
    setModalInvalidInputs(false);
  };

  return (
    <Modal
      className={`modal-danger ${className}`}
      maskClosable={true}
      visible={visible}
      onCancel={onOk}
      onOk={onOk}
      title={t('REPORTS.TIMESHEETS.INVALID_INPUTS.TITLE')}
    >
      <p dangerouslySetInnerHTML={{ __html: t('REPORTS.TIMESHEETS.INVALID_INPUTS.CONTENT') }}></p>
      <List
        dataSource={invalidInputKeys}
        renderItem={(key) => {
          const parts = key.split('-');
          const codeId = parts[2];
          const date = moment(`${parts[3]}-${parts[4]}-${parts[5]}`).format('L');
          const element = document.getElementById(key) as HTMLInputElement;

          return (
            <List.Item>
              <span className="code-id">{codeId}</span> {date} {element ? `: ${element.value}` : ''}
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};

export default styled(ModalInvalidInputs)`
  .code-id {
    background-color: #ebebeb;
    align-self: flex-start;
    padding: 1px 5px;
    border-radius: 5px;
  }
`;
