import AppContext from '@/pages/app/context';
import { Modal } from 'antd';
import React, { Dispatch, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../../redux/actions';

interface Props {
  className?: string;
  errors: any;
  visible: boolean;
}

const ModalAyError: React.FC<Props> = ({ className, errors, visible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const onCancel = () => {
    hoursDispatch({
      type: 'SET_AY_ERRORS',
      payload: null,
    });
  };

  if (!errors) return <></>;

  return (
    <Modal
      className={`${className} modal-danger`}
      title={t('USERS.AY_SYNC_ERRORS')}
      visible={visible}
      onCancel={onCancel}
      onOk={onCancel}
    >
      <div className="errors">
        {Object.values(errors).map((error) => (
          <span>- {error}</span>
        ))}
      </div>
    </Modal>
  );
};

export default styled(ModalAyError)`
  .errors {
    display: flex;
    flex-direction: column;
  }
`;
