import Leaves from '@/pages/app/reports/pay-reports/components/Leaves';
import WorkedHours from '@/pages/app/reports/pay-reports/components/WorkedHours';
import { IDepartment } from '@/types/department.model';
import { IUser } from '@/types/user.model';
import { DatePicker, Tabs } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import BalanceSheet from './balance-sheet';
import ExtraHours from './extra-hours';
import Addendum from './addendum';
import NewBadge from '@/pages/app/components/NewBadge';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

interface Props {
  className?: string;
  user?: IUser;
  department?: IDepartment;
  activeSubTab: string;
  setActiveSubTab: React.Dispatch<React.SetStateAction<string>>;
}

const Counters: React.FC<Props> = ({ className, user, department, activeSubTab, setActiveSubTab }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();
  const [rangePickerValues, setRangePickerValues] = useState<[Moment, Moment]>([
    moment().startOf('month'),
    moment().endOf('month'),
  ]);

  useEffect(() => {
    if (activeSubTab == '') {
      history.replace(`/app/team/collaborators/${user?.recordId}/counters/extra-hours`);
    }
  }, [activeSubTab]);

  const onTabChange = (tab: string) => {
    setActiveSubTab(tab);
    history.replace(`/app/team/collaborators/${user?.recordId}/counters/${tab.toLowerCase()}`);
  };

  return (
    <div className={className}>
      <Tabs type="card" onChange={onTabChange} activeKey={activeSubTab == '' ? 'EXTRA-HOURS' : activeSubTab}>
        {!!department?.params?.extrahoursEnabled && (
          <TabPane disabled={user?.pending} tab={t('EXTRA_HOURS.EXTRA_HOURS_SHORT')} key="EXTRA-HOURS">
            <ExtraHours user={user} department={department} />
          </TabPane>
        )}
        {!!department?.params?.extrahoursEnabled && (
          <TabPane disabled={user?.pending} tab={t('USERS.BALANCE_SHEET.TAB_NAME')} key="BALANCE">
            <BalanceSheet department={department} user={user} />
          </TabPane>
        )}
        {!!department?.params?.enable_balance_avenant && (
          <TabPane
            disabled={user?.pending}
            tab={<span style={{ display: 'flex', gap: 10, alignItems: 'center' }}>{t('USERS.ADDENDUM.TAB_NAME')}</span>}
            key="ADDENDUM"
          >
            <Addendum department={department} user={user} />
          </TabPane>
        )}
        {department?.access?.reports && department?.params?.payReport && (
          <React.Fragment>
            <TabPane tab={t('GLOBAL.WORKED_HOURS')} key="WORKED-HOURS">
              <RangePicker
                allowClear={false}
                getPopupContainer={(trigger) => trigger}
                format={'L'}
                value={rangePickerValues}
                onChange={(e: any) => {
                  setRangePickerValues(e);
                }}
                style={{ marginBottom: 10 }}
              />
              <WorkedHours
                rangePickerValues={rangePickerValues}
                selectedUsers={[]}
                userRecordId={user?.recordId || undefined}
              />
            </TabPane>
            <TabPane tab={t('GLOBAL.ABSENCES')} key="ABSENCES">
              <RangePicker
                allowClear={false}
                getPopupContainer={(trigger) => trigger}
                format={'L'}
                value={rangePickerValues}
                onChange={(e: any) => {
                  setRangePickerValues(e);
                }}
                style={{ marginBottom: 10 }}
              />
              <Leaves
                rangePickerValues={rangePickerValues}
                selectedUsers={[]}
                userRecordId={user?.recordId || undefined}
              />
            </TabPane>
          </React.Fragment>
        )}
      </Tabs>
    </div>
  );
};

export default styled(Counters)``;
