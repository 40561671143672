import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { IShiftBlock } from '@/types/shift-block.model';
import { ISkill } from '@/types/skill.model';
import { getWindowSize, isFeatureEnabled, isNullOrUndefined, valueForSearch } from '@/utils';
import { Button, Checkbox, Drawer, Form, Input, InputNumber, Radio, RadioChangeEvent, Select } from 'antd';
import { Store } from 'antd/lib/form/interface';
import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../../hours/manage/redux/actions';
import { InitialStateType } from '../../hours/manage/redux/store';
import CustomColorPicker from '../CustomColorPicker';
import TimePickerFormItem from '../TimePickerFormItem';
import DrawerSkill from './Skill';

interface Props {
  className?: string;
}

const ShiftBlock: React.FC<Props> = ({ className }) => {
  const { state: appContextState, dispatch: appContextDispatch } = useContext(AppContext);
  const {
    activeDepartment,
    activeDepartmentId,
    skills,
    loadingSkills,
    shortcuts,
    loadingShortcuts,
    dayoffs,
    loadingDayoffs,
    resources,
    loadingResources,
    loadingSections,
    sections,
    activeSection,
    features,
  } = appContextState;
  const { create_new_shift_block, active_shift_block, editing_shift_block } = useSelector(
    ({ create_new_shift_block, active_shift_block, editing_shift_block }: InitialStateType) => ({
      create_new_shift_block,
      active_shift_block,
      editing_shift_block,
    }),
  );

  const dispatch: Dispatch<ActionType> = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [shiftType, setShiftType] = useState<string>();
  const [drawerSkillVisible, setDrawerSkillVisible] = useState<boolean>(false);
  const [selectedColor, setSelectedColor] = useState<any>(colors.green);
  const [newStart, setNewStart] = useState<number>();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(BLOCK_INITIAL_VALUES);
    setShiftType(BLOCK_INITIAL_VALUES.shift_type);
    form.setFieldsValue({
      background_color: selectedColor,
      section: activeSection && activeSection,
    });
    if (create_new_shift_block) {
      const default_shift_start = moment().set({
        hour: activeDepartment?.scheduleParams?.default_shift_start_time || 0,
        minutes: 0,
      });
      const default_shift_end = moment().set({
        hour: activeDepartment?.scheduleParams?.default_shift_end_time || 0,
        minutes: 0,
      });
      form.setFieldsValue({
        start_time: default_shift_start.unix(),
        end_time: default_shift_end.unix(),
        start: default_shift_start.format('HH:mm'),
        end: default_shift_end.format('HH:mm'),
      });
    }
  }, [create_new_shift_block || editing_shift_block]);

  useEffect(() => {
    if (editing_shift_block) {
      const { start, end, pause_paid, pause_unpaid, background_color, shift_type } = active_shift_block!;
      setShiftType(shift_type);
      setSelectedColor(background_color);
      form.setFieldsValue({
        ...active_shift_block,
        start: moment(start * 1000).format('HH:mm'),
        end: moment(end * 1000).format('HH:mm'),
        pause_paid: pause_paid && moment.utc(pause_paid * 1000).format('HH:mm'),
        pause_unpaid: pause_unpaid && moment.utc(pause_unpaid * 1000).format('HH:mm'),
      });
    }
  }, [active_shift_block]);

  const onChangeType = (e: RadioChangeEvent) => {
    setShiftType(e.target.value);
  };

  const onClose = () => {
    if (create_new_shift_block) {
      dispatch({
        type: 'SET_CREATE_NEW_SHIFT_BLOCK',
        payload: false,
      });
    }
    if (editing_shift_block) {
      dispatch({
        type: 'SET_EDITING_SHIFT_BLOCK',
        payload: false,
      });
      dispatch({
        type: 'SET_ACTIVE_SHIFT_BLOCK',
        payload: null,
      });
    }
  };

  const onFinish = async (values: any) => {
    try {
      if (create_new_shift_block) {
        const cancel_token_source: CancelTokenSource = axios.CancelToken.source();
        const payload = {
          ...values,
          pause_paid: values?.paid_time && values.paid_time,
          pause_unpaid: values?.unpaid_time && values.unpaid_time,
          background_color: selectedColor,
        };
        const { data }: { data: IShiftBlock[] } = await axios.post(
          `${process.env.REACT_APP_API_URL}/v3/shifts/block`,
          payload,
          {
            cancelToken: cancel_token_source.token,
            params: {
              departmentId: activeDepartmentId,
              picker: 'month',
            },
          },
        );
        dispatch({
          type: 'SET_SHIFT_BLOCKS',
          payload: data.filter((sb) => sb.active),
        });
        dispatch({
          type: 'SET_CREATE_NEW_SHIFT_BLOCK',
          payload: false,
        });
        form.resetFields();
      } else {
        const cancel_token_source: CancelTokenSource = axios.CancelToken.source();
        const payload = {
          ...values,
          pause_paid: values?.paid_time || values?.paid_time == 0 ? values.paid_time : active_shift_block?.pause_paid,
          pause_unpaid:
            values?.unpaid_time || values?.unpaid_time == 0 ? values.unpaid_time : active_shift_block?.pause_unpaid,
          background_color: selectedColor,
        };
        const { data }: { data: IShiftBlock[] } = await axios.patch(
          `${process.env.REACT_APP_API_URL}/v3/shifts/block/${active_shift_block!.id}`,
          payload,
          {
            cancelToken: cancel_token_source.token,
            params: {
              departmentId: activeDepartmentId,
              picker: 'month',
            },
          },
        );
        dispatch({
          type: 'SET_SHIFT_BLOCKS',
          payload: data.filter((sb) => sb.active),
        });
        dispatch({
          type: 'SET_EDITING_SHIFT_BLOCK',
          payload: false,
        });
        form.resetFields();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onValuesChange = (changedValues: Store, values: Store) => {};

  const onChangeShortcut = (value: string) => {
    if (value) {
      const shortcut = shortcuts.find((x) => x.id === value);
      if (shortcut) {
        const { start, end } = shortcut;
        form.setFieldsValue({
          start: moment(start! * 1000).format('HH:mm'),
          end: moment(end! * 1000).format('HH:mm'),
        });
      }
    }
  };

  const onSkillSave = (skill: ISkill) => {
    const skillsCopy = [...skills];
    skillsCopy.push(skill);
    const skillsCopySorted = skillsCopy.sort((a, b) => {
      if (a.name!.toLowerCase() < b.name!.toLowerCase()) {
        return -1;
      }
      if (a.name!.toLowerCase() > b.name!.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    appContextDispatch({
      type: 'SET_SKILLS',
      payload: skillsCopySorted,
    });
    const selectedSkills = form.getFieldValue('skills');
    if (selectedSkills) {
      form.setFieldsValue({
        skills: [...selectedSkills, skill.id],
      });
    } else {
      form.setFieldsValue({
        skills: [skill.id],
      });
    }
  };

  const onChangeColor = (e: any) => {
    setSelectedColor(e.hex);
  };

  const onDelete = async () => {
    try {
      const cancel_token_source: CancelTokenSource = axios.CancelToken.source();
      const { data }: { data: IShiftBlock[] } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/v3/shifts/block/${active_shift_block!.id}`,
        {
          cancelToken: cancel_token_source.token,
          params: {
            departmentId: activeDepartmentId,
            picker: 'month',
          },
        },
      );
      dispatch({
        type: 'SET_SHIFT_BLOCKS',
        payload: data.filter((sb) => sb.active),
      });
      dispatch({
        type: 'SET_EDITING_SHIFT_BLOCK',
        payload: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const changePaidPauseDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    let pause = form.getFieldValue('paid_time');
    if (isNullOrUndefined(hour)) {
      pause = 0;
    } else {
      pause = moment
        .duration(0)
        .add(hour || 0, 'hour')
        .add(minute || 0, 'minute')
        .asSeconds();
    }
    form.setFieldsValue({
      paid_time: pause,
    });
  };

  const changeUnpaidPauseDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    let pause = form.getFieldValue('unpaid_time');
    if (isNullOrUndefined(hour)) {
      pause = 0;
    } else {
      pause = moment
        .duration(0)
        .add(hour || 0, 'hour')
        .add(minute || 0, 'minute')
        .asSeconds();
    }
    form.setFieldsValue({
      unpaid_time: pause,
    });
  };

  const changeStartDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    const timestamp = moment()
      .set('hour', hour || 0)
      .set('minute', minute || 0)
      .unix();

    setNewStart(timestamp);
    form.setFieldsValue({
      start_time: timestamp,
    });

    const endDate = form.getFieldValue('end_time');
    if (endDate) {
      const endMoment = moment.unix(endDate);
      let endTimestamp = moment(timestamp).set('hour', endMoment.hours()).set('minute', endMoment.minutes()).unix();
      if (endTimestamp < timestamp) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      form.setFieldsValue({
        end_time: endTimestamp,
      });
    }
  };

  const changeEndDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    const timestamp = moment()
      .set('hour', hour || 0)
      .set('minute', minute || 0)
      .unix();

    let endValue = null;
    const startDate = form.getFieldValue('start_time');
    if (!isNullOrUndefined(hour)) {
      let endTimestamp = moment(timestamp)
        .set('hour', hour || 0)
        .set('minute', minute || 0)
        .unix();

      if (startDate && endTimestamp < startDate) {
        endTimestamp = moment.unix(timestamp).add(1, 'day').unix();
      }
      endValue = endTimestamp;
    }

    form.setFieldsValue({
      end_time: endValue,
    });
  };

  return (
    <Drawer
      visible={create_new_shift_block || editing_shift_block}
      onClose={onClose}
      getContainer={false}
      forceRender={true}
      maskClosable={false}
      title={
        !editing_shift_block
          ? t('SCHEDULE.MONTHLY_VIEW.SHIFT_BLOCKS.SHIFT_BLOCK_DRAWER.CREATE')
          : t('SCHEDULE.MONTHLY_VIEW.SHIFT_BLOCKS.SHIFT_BLOCK_DRAWER.UPDATE', { name: active_shift_block!.name })
      }
      placement="right"
      destroyOnClose={true}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        initialValues={BLOCK_INITIAL_VALUES}
        scrollToFirstError={true}
        onValuesChange={onValuesChange}
      >
        <Form.Item name="shift_type">
          <Radio.Group buttonStyle="solid" className="light block" onChange={onChangeType}>
            <Radio.Button value="1">{t('GLOBAL.SHIFT')}</Radio.Button>
            <Radio.Button value="2">{t('GLOBAL.ABSENCE')}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <div className="names-container">
          <div className="name" style={{ flex: 1 }}>
            <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
              <Input placeholder={t('GLOBAL.NAME')} />
            </Form.Item>
          </div>
          <div className="short-code">
            <Form.Item name="shortcode" label={t('GLOBAL.SHORT_CODE')} rules={[{ required: true }]}>
              <Input placeholder="MAL" maxLength={3} />
            </Form.Item>
          </div>
        </div>
        <>
          <>
            {shiftType === '1' && !!isFeatureEnabled(features, FEATURES.SECTIONS) && !!sections?.length && (
              <Form.Item label={t('GLOBAL.SECTION')}>
                <Form.Item name="section" style={{ marginBottom: 0 }}>
                  <Select
                    clearIcon
                    getPopupContainer={(trigger) => trigger}
                    showSearch
                    placeholder={t('FORMS.SECTION_PLACEHOLDER')}
                    optionFilterProp="children"
                    loading={loadingSections}
                    disabled={loadingSections}
                    allowClear={true}
                    filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Select.Option value={''}>{t('GLOBAL.NO_SECTION')}</Select.Option>
                    {sections.map((section) => (
                      <Select.Option key={`section_${section.id}`} value={`${section.id}`}>
                        {section.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            )}
            {shiftType === '1' ? (
              <>
                {!!isFeatureEnabled(features, FEATURES.SKILLS) && skills?.length && (
                  <>
                    <Form.Item
                      label={t('GLOBAL.SKILLS')}
                      shouldUpdate={(prevValues, curValues) => prevValues.userRecordId !== curValues.userRecordId}
                    >
                      {(form) => {
                        return (
                          <Form.Item name="skills" style={{ marginBottom: 0 }}>
                            <Select
                              id="shift_block_skills"
                              getPopupContainer={(trigger) => trigger}
                              showSearch
                              allowClear
                              mode="multiple"
                              placeholder={t('FORMS.SKILLS_PLACEHOLDER')}
                              optionFilterProp="children"
                              disabled={loadingSkills}
                              loading={loadingSkills}
                              filterOption={(input, option) =>
                                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {skills.map((skill: ISkill) => (
                                <Select.Option value={skill.id!} key={`skill_${skill.id}`}>
                                  {skill.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                    {/* <button
                      type="button"
                      className="green add-skill-button"
                      onClick={() => setDrawerSkillVisible(!drawerSkillVisible)}
                    >
                      + {t('SKILLS.CREATE_NEW_SKILL')}
                    </button> */}
                  </>
                )}
                {!!isFeatureEnabled(features, FEATURES.ATTRIBUTES) && !!resources?.length && (
                  <Form.Item name="attributes" label={t('GLOBAL.ATTRIBUTES')}>
                    <Select
                      mode="multiple"
                      placeholder={t('FORMS.ATTRIBUTES_PLACEHOLDER')}
                      maxTagCount={3}
                      suffixIcon={<i className="icon-search" />}
                      disabled={loadingResources}
                      loading={loadingResources}
                      filterOption={(input, option) => {
                        if (option) {
                          if (!option.label) {
                            let children = option.children;
                            if (Array.isArray(children)) {
                              children = children.join();
                            }
                            return valueForSearch(children).includes(valueForSearch(input));
                          }
                        }
                        return false;
                      }}
                    >
                      {resources.map((resource = {}) => (
                        <Select.OptGroup label={resource.name} key={`resource_${resource.id}`}>
                          {(resource.attributes || []).map((attribut) => (
                            <Select.Option value={attribut.id!} key={`attribut_${attribut.id}`}>
                              {attribut.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </>
            ) : (
              <Form.Item name="dayoff_type" label={t('FORMS.ABSENCE_TYPE')} rules={[{ required: true, message: '' }]}>
                <Select
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  placeholder={t('FORMS.ABSENCE_TYPE_PLACEHOLDER')}
                  optionFilterProp="children"
                  loading={loadingDayoffs}
                  disabled={loadingDayoffs}
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {dayoffs.map((dayoff) => (
                    <Select.Option value={dayoff.id!} key={`dayoff_${dayoff.id}`}>
                      {dayoff.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </>
          {shiftType === '2' && (
            <Form.Item style={{ marginBottom: 0, display: 'inline-block' }}>
              <Form.Item
                name="dayoff_fullday"
                valuePropName="checked"
                style={{ marginBottom: 0, display: 'inline-block' }}
              >
                <Checkbox style={{ lineHeight: '32px' }}>{t('GLOBAL.FULL_DAY')}</Checkbox>
              </Form.Item>
              <Form.Item
                name="dayoff_paid"
                valuePropName="checked"
                style={{ marginBottom: 0, display: 'inline-block' }}
              >
                <Checkbox style={{ lineHeight: '32px' }}>{t('GLOBAL.PAID_ABSENCE')}</Checkbox>
              </Form.Item>
            </Form.Item>
          )}
          <div className="time-container">
            <div className="time">
              <Form.Item name="start_time" className="hidden">
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                rules={[{ required: true }]}
                name="start"
                label={t('GLOBAL.START')}
                start={moment().startOf('day').unix()}
                changeTime={changeStartDate}
              />
            </div>
            <div className="time">
              <Form.Item name="end_time" className="hidden">
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                rules={[{ required: true, message: '' }]}
                name="end"
                label={t('GLOBAL.END')}
                displayDuration={true}
                start={newStart || moment().startOf('day').unix()}
                changeTime={changeEndDate}
              />
            </div>
            <div className="shortcuts">
              <Form.Item label={t('GLOBAL.SHORTCUTS')}>
                <Select
                  allowClear
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  placeholder={t('GLOBAL.SHORTCUTS')}
                  optionFilterProp="children"
                  loading={loadingShortcuts}
                  disabled={loadingShortcuts}
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={onChangeShortcut}
                >
                  {shortcuts.map((shortcut) => (
                    <Select.Option key={`shortcut_${shortcut.id}`} value={`${shortcut.id}`}>
                      {shortcut.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          {shiftType == '1' && (
            <div className="pauses-container">
              <div className="pause-container">
                <Form.Item name="paid_time" className="hidden">
                  <InputNumber disabled={true} />
                </Form.Item>
                <TimePickerFormItem
                  name="pause_paid"
                  label={t('GLOBAL.PAID_BREAK')}
                  maxHours={7}
                  changeTime={changePaidPauseDate}
                />
              </div>
              <div className="pause-container">
                <Form.Item name="unpaid_time" className="hidden">
                  <InputNumber disabled={true} />
                </Form.Item>
                <TimePickerFormItem
                  name="pause_unpaid"
                  label={t('GLOBAL.UNPAID_BREAK')}
                  maxHours={7}
                  changeTime={changeUnpaidPauseDate}
                />
              </div>
            </div>
          )}
          <div className="color-picker">
            <Form.Item name="background_color" label="Color" rules={[{ required: true }]}>
              <CustomColorPicker form={form} color={selectedColor} property="background" onChange={onChangeColor} />
            </Form.Item>
          </div>
        </>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            {editing_shift_block && (
              <Button type="default" danger onClick={onDelete}>
                {t('GLOBAL.REMOVE')}
              </Button>
            )}
            <Button type="primary" htmlType="submit">
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
      {isFeatureEnabled(features, FEATURES.SKILLS) && (
        <DrawerSkill
          skill={null}
          visible={drawerSkillVisible}
          onClose={() => setDrawerSkillVisible(false)}
          onSave={onSkillSave}
        />
      )}
    </Drawer>
  );
};

export default styled(ShiftBlock)`
  .hidden {
    display: none;
  }
  .names-container {
    display: flex;
    justify-content: space-between;
    gap: 15px;

    .name {
      flex: 1;
    }

    .short-code {
      width: 150px;
      .ant-form-item-control {
        .ant-form-item-control-input {
          width: 100%;
        }
      }
    }
  }

  .time-container {
    display: flex;
    justify-content: space-between;
    gap: 15px;

    .time,
    .shortcuts {
      flex: 1;
    }
  }

  .pauses-container {
    display: flex;
    gap: 15px;

    .pause-container {
      flex: 1;
    }
  }

  .add-skill-button {
    padding: 0;
    margin-top: 8px;
    margin-bottom: 15px;
  }

  .color-picker {
    .chrome-picker {
      width: 100% !important;
      height: 400px !important;
      background: none !important;
      box-shadow: none !important;
      border: none !important;
      border-radius: 0 !important;

      > div {
        border-radius: 10px 10px 0 0 !important;

        &:last-child {
          border-radius: 0 0 10px 10px !important;
          border: 1px solid lightgray !important;
        }
      }
    }
  }
`;

const BLOCK_INITIAL_VALUES: IShiftBlock = {
  id: '',
  name: '',
  shortcode: '',
  section: '',
  start: 0,
  end: 0,
  pause_paid: null,
  pause_unpaid: null,
  background_color: '',
  skills: [],
  shift_type: '1',
  dayoff_type: null,
  dayoff_paid: false,
  dayoff_fullday: false,
};
