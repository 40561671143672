import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { Select } from 'antd';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
}

const NoSectionSelected: React.FC<Props> = ({ className }) => {
  const { state: appContextState, dispatch } = useContext(AppContext);
  const { activeSection, loadingSections, sections } = appContextState;
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <div className={className}>
      <h1>{t('SECTIONS.NO_SECTION_SELECTED_TITLE')}</h1>

      <Select
        placeholder={t('FORMS.SECTION_PLACEHOLDER')}
        value={activeSection || undefined}
        allowClear
        loading={loadingSections}
        className="select-section"
        onChange={(value) => {
          dispatch({
            payload: value,
            type: 'SET_ACTIVE_SECTION',
          });
        }}
        style={{ marginTop: 10, marginBottom: 20 }}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        {sections.map((section) => (
          <Select.Option key={`section_${section.id!}`} value={section.id!}>
            {section.name!}
          </Select.Option>
        ))}
      </Select>
      <p>
        <Trans i18nKey="SECTIONS.NO_SECTION_SELECTED_MESSAGE">
          {
            'Grâce aux sections, vous découpez vos plannings de façon séparées. Pour gérer vos sections, rendez-vous dans les paramètres de votre compte.Boîte à outil ->'
          }
          <Link
            style={{ color: colors.green, fontWeight: 'bold', textDecoration: 'underline' }}
            to="/app/settings/sections"
          >
            Sections
          </Link>
        </Trans>
      </p>
    </div>
  );
};

export default styled(NoSectionSelected)`
  display: flex;
  align-items: center;
  padding: 50px 20px;
  justify-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-weight: bold;
    font-size: 20px;
    max-width: 560px;
  }

  p {
    font-size: 12px;
    color: ${colors.grey};
    max-width: 420px;
  }
`;
