import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IShift } from '@/types/shift.model';
import { ISkill } from '@/types/skill.model';
import { ITask } from '@/types/task.model';
import { minutesToHoursAndOrMinutes } from '@/utils';
import { Checkbox } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InitialStateType } from '../../redux/store';

interface Props {
  className?: string;
  shift: IShift;
}

const ShiftTooltip: React.FC<Props> = ({ className, shift }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { start, end, price, pause, comment, tasks, skills, section, dayoff } = shift;
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const {
    showTasks,
    showSkills,
    showDetails,
    showSections,
    showAttributes,
    showOtherDepartments,
    showPrices,
    showComment,
    showHrCode,
    showMonthlyHours,
  } = useSelector(
    ({
      showTasks,
      showSkills,
      showDetails,
      showSections,
      showAttributes,
      showOtherDepartments,
      showPrices,
      showComment,
      showHrCode,
      showMonthlyHours,
    }: InitialStateType) => ({
      showTasks,
      showSkills,
      showDetails,
      showSections,
      showAttributes,
      showOtherDepartments,
      showPrices,
      showComment,
      showHrCode,
      showMonthlyHours,
    }),
  );

  const duration = moment
    .duration(moment.unix(end!).diff(moment.unix(start!)))
    .subtract(pause?.unpaid, 'seconds')
    .asMinutes();
  const pause_duration = moment.duration(pause!.paid! + pause!.unpaid!, 'seconds').asMinutes();

  const hr_code_id =
    activeDepartment?.hr_codes && activeDepartment?.hr_codes.find((code) => code.id === shift.hr_code)?.code;

  return (
    <div className={className}>
      <div className="times">
        <span>
          {shift.shyftType == 2
            ? shift.package?.shortcode
              ? shift.package.shortcode
              : shift.dayoff?.shortCode
              ? shift.dayoff?.shortCode
              : ''
            : shift.package
            ? shift.package.shortcode
              ? shift.package.shortcode
              : shift.skills?.length && showMonthlyHours
              ? shift.skills.map((skill) => skill.name![0]).join('')
              : t('SCHEDULE.MONTHLY_VIEW.WORK_SHORT')
            : shift.skills?.length && showMonthlyHours
            ? shift.skills.map((skill) => skill.name![0]).join('')
            : t('SCHEDULE.MONTHLY_VIEW.WORK_SHORT')}{' '}
          - {moment(start! * 1000).format('H:mm')}-{moment(end! * 1000).format('HH:mm')}
        </span>
        {showDetails && dayoff && (
          <div className="details">
            <span>
              <i className="icon-clock" />
              {minutesToHoursAndOrMinutes(duration!)}
            </span>
          </div>
        )}
      </div>
      {showTasks && tasks && (
        <div className="tasks">
          {tasks.map(({ id, name, start, end }: ITask) => (
            <div className="task" key={id}>
              <span>{name}</span>
              <span>
                {moment(start! * 1000).format('HH:mm')}-{moment(end! * 1000).format('HH:mm')}
              </span>
            </div>
          ))}
        </div>
      )}
      {showDetails && !dayoff && (
        <div className="details">
          <span>
            <i className="icon-clock" />
            {minutesToHoursAndOrMinutes(duration!)}
          </span>
          {pause_duration > 0 && (
            <span>
              <i className="icon-pause" />
              {minutesToHoursAndOrMinutes(pause_duration!)}
            </span>
          )}
          {price! > 0 && (
            <span className="no-print">
              <i className="icon-money" />{' '}
              {new Intl.NumberFormat(language, {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }).format(price!)}
            </span>
          )}
        </div>
      )}
      {showHrCode && hr_code_id && (
        <>
          <div className="hr-code">{hr_code_id}</div>
          <br />
        </>
      )}
      {showSections && section && (
        <div className="section" style={{ background: section.background, color: section.color }}>
          {section.name}
        </div>
      )}
      {showSkills && skills && (
        <div className="skills">
          {skills.map(({ id, name, background, color }: ISkill) => (
            <div className="skill" key={id} style={{ background, color }}>
              {name}
            </div>
          ))}
        </div>
      )}
      {showDetails && dayoff && (
        <div className="dayoff">
          <span>{dayoff.name}</span>
          <div className="dayoff-details">
            {dayoff.fullDay && (
              <Checkbox checked={dayoff.fullDay} disabled>
                {t('GLOBAL.FULL_DAY')}
              </Checkbox>
            )}
            {dayoff.paid && (
              <Checkbox checked={dayoff.paid} disabled>
                {t('GLOBAL.PAID_ABSENCE')}
              </Checkbox>
            )}
          </div>
        </div>
      )}
      {showComment && comment && activeDepartment?.scheduleParams?.showComment && (
        <div className="comment">{comment}</div>
      )}
    </div>
  );
};

export default styled(ShiftTooltip)`
  background-color: #f7f9ff;
  color: #000;
  min-width: 200px;
  border-radius: 5px;

  .times {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .tasks {
    font-size: 10px;
    .task {
      display: flex;
      justify-content: space-between;
    }
  }

  .details {
    display: flex;
    color: ${colors.grey};
    font-size: 10px;
    margin: 5px 0;
    gap: 5px;
  }

  .hr-code {
    background-color: #000;
    display: inline-block;
    color: #fff;
    padding: 0 5px;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .section {
    display: inline-block;
    border-radius: 5px;
    padding: 0 5px;
  }

  .skills {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 5px;
    .skill {
      border-radius: 5px;
      padding-left: 5px;
    }
  }

  .dayoff {
    display: flex;
    flex-direction: column;

    .dayoff-details {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      .ant-checkbox-wrapper {
        margin: 0;
      }
    }
  }

  .comment {
    border-top: 1px solid lightgray;
    margin-top: 5px;
  }
`;
