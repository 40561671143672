import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { IAttribut } from '@/types/attribut.model';
import { ISection } from '@/types/section.model';
import { ISkill } from '@/types/skill.model';
import { minutesToHoursAndOrMinutes } from '@/utils';
import { BackTop, Button, Modal, Space, Table, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tag from '../../components/Tag';
import Header from './components/Header';
import ModalDetail from './components/ModalDetail';
moment.tz.setDefault('Atlantic/Reykjavik');

export interface IShyftMarket {
  id: string;
  status: 'PENDING' | 'SUCCESS' | 'ENDED' | 'DELETED';
  start: number;
  end: number;
  applicants: number;
  section?: ISection;
  skills: ISkill[];
  attributes: IAttribut[];
  last_applicant: number;
  count_id?: string;
  requestHasPending?: boolean;
}

const COLORS = {
  SUCCESS: '#00A65150',
  PENDING: '#ED6E5D90',
  ENDED: '#00000090',
  DELETED: '#00000090',
};

const AppHoursShyftsMarketPage: React.FC = () => {
  const {
    state: { activeDepartment, activeDepartmentId, activeSection },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [statusFilter, setStatusFilter] = useState<string>('PENDING');
  const [columns, setColumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<IShyftMarket[]>([]);
  const refDataSource = useRef<any[]>(dataSource);
  refDataSource.current = dataSource;
  const [loadingDataSource, setLoadingDataSource] = useState<boolean>(false);
  const [selectedShift, setSelectedShift] = useState<IShyftMarket | null>(null);
  const [timestamp, setTimestamp] = useState<number>(moment().unix());

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingDataSource(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/shyfts-market`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
          sectionId: activeSection,
          status: statusFilter,
        },
      })
      .then(({ data }) => {
        if (mounted) {
          setDataSource(data);
          setLoadingDataSource(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
          console.error(error);
        }
        if (mounted) {
          setLoadingDataSource(false);
        }
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId, activeSection, statusFilter, timestamp]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.DAY'),
        key: 'day',
        // eslint-disable-next-line react/display-name
        render: (_text: string, record: IShyftMarket) => {
          const { start } = record;
          return <span style={{ textTransform: 'capitalize' }}>{moment.unix(start).format('dddd')}</span>;
        },
      },
      {
        title: t('GLOBAL.SHIFTS'),
        key: 'count',
        dataIndex: 'count',
      },
      {
        title: t('GLOBAL.DATE'),
        key: 'date',
        // eslint-disable-next-line react/display-name
        render: (_text: string, record: IShyftMarket) => {
          const { start } = record;
          return moment.unix(start).format('L');
        },
        sorter: (a: IShyftMarket, b: IShyftMarket) => b?.start - a?.start,
      },
      {
        title: t('GLOBAL.HOURS'),
        key: 'date',
        // eslint-disable-next-line react/display-name
        render: (_text: string, record: IShyftMarket) => {
          const { start, end } = record;
          return `${moment.unix(start).format('HH:mm')} - ${moment.unix(end).format('HH:mm')}`;
        },
      },
      {
        title: t('GLOBAL.DURATION'),
        key: 'duration',
        align: 'center',
        // eslint-disable-next-line react/display-name
        render: (_text: string, record: IShyftMarket) => {
          const { start, end } = record;
          const duration = moment.duration(moment.unix(end!).diff(moment.unix(start!)));
          const durationAsMinutes = duration.asMinutes();
          return minutesToHoursAndOrMinutes(durationAsMinutes);
        },
      },
      {
        title: t('GLOBAL.DETAIL'),
        key: 'detail',
        // eslint-disable-next-line react/display-name
        render: (_text: string, record: IShyftMarket) => {
          const { id, section, skills, attributes } = record;
          const tags = [];
          if (section) {
            tags.push(section);
          }
          if (skills?.length) {
            tags.push(...skills);
          }
          if (attributes?.length) {
            tags.push(...attributes);
          }

          return (
            <div style={{ maxWidth: 400 }}>
              {tags.map(({ color, name, background }, i) => (
                <Tag
                  key={`shyftMarketDetail_${id}_${i}`}
                  size="large"
                  color={color}
                  background={background}
                  name={name}
                />
              ))}
            </div>
          );
        },
      },
      {
        title: t('GLOBAL.APPLICANTS'),
        key: 'applicants',
        align: 'center',
        dataIndex: 'applicants',
        sorter: (a: IShyftMarket, b: IShyftMarket) => b?.applicants - a?.applicants,
      },
      {
        title: t('GLOBAL.LAST_APPLICANT'),
        key: 'lastApplicant',
        align: 'center',
        render: (_text: string, record: IShyftMarket) => {
          const { last_applicant } = record;
          if (last_applicant) {
            return moment.unix(last_applicant).format('L HH:mm');
          }
        },
        sorter: (a: IShyftMarket, b: IShyftMarket) => b?.last_applicant - a?.last_applicant,
      },
      {
        align: 'center',
        title: t('GLOBAL.STATUS'),
        key: 'status',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IShyftMarket) => {
          return (
            <Tag background={COLORS[record.status]} color="#ffffff" name={t(`SHYFTS_MARKET.STATUS.${record.status}`)} />
          );
        },
      },
      {
        key: 'actions',
        align: 'right',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IShyftMarket) => {
          if (record.status === 'PENDING') {
            return (
              <Space>
                <Button disabled={record.applicants === 0} type="primary" onClick={() => setSelectedShift(record)}>
                  {t('GLOBAL.TREAT')}
                </Button>
                {/* <Button danger type="primary" onClick={() => onWantDelete(record)}>
                  <i className="icon-trash-empty" />
                </Button> */}
              </Space>
            );
          }
          return (
            <Space>
              <Button disabled={record.applicants === 0} type="ghost" onClick={() => setSelectedShift(record)}>
                <i className="icon-eye" />
              </Button>
            </Space>
          );
        },
      },
    ]);
  }, [i18n.language, dataSource]);

  const onAssign = () => {
    setTimestamp(moment().unix());
  };

  const onWantDelete = (shyft: IShyftMarket) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('SHYFTS_MARKET.MODAL_DELETE.DESCRIPTION'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      onOk: () => {
        return new Promise((resolve, reject) => {
          axios
            .delete(`${process.env.REACT_APP_API_URL}/v3/shyfts-market/${shyft.id}`, {
              params: {
                departmentId: activeDepartmentId,
                count_id: shyft.count_id,
              },
              headers: {
                'X-Locale': i18n.language,
              },
            })
            .then(() => {
              resolve(true);
              setTimestamp(moment().unix());
            })
            .catch((error) => {
              reject();
              if (error.response?.data?.message) {
                message.error(error.response?.data?.message);
              } else {
                message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
              }
            });
        });
      },
      onCancel: () => {},
    });
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header statusFilter={statusFilter} setStatusFilter={setStatusFilter} />
      <Table
        loading={loadingDataSource}
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        pagination={false}
        locale={{
          emptyText: (
            <div>
              <h3>{t('SHYFTS_MARKET.NO_DATA.TITLE')}</h3>
              <p>{t('SHYFTS_MARKET.NO_DATA.DESCRIPTION')}</p>
            </div>
          ),
        }}
      />
      <ModalDetail
        activeDepartment={activeDepartment}
        shift={selectedShift}
        onClose={() => setSelectedShift(null)}
        onAssign={onAssign}
      />
    </TableView>
  );
};

export default AppHoursShyftsMarketPage;
