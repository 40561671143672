import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { FEATURES } from '@/types/features.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { handleError, isFeatureEnabled } from '@/utils';
import { Form, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';
import ModalDimonaCorrectionAllUsers from './components/ModalDimonaCorrectionAllUsers';
import ModalFreeClockingDimona from './components/ModalFreeClockingDimona';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const FreeShiftsSettings: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, features },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<(string | null)[]>([]);
  const [modalDimonaCorrection, setModalDimonaCorrection] = useState<boolean>(false);
  const [activateDimonaCorrectionAll, setActivateDimonaCorrectionAll] = useState<boolean>(false);
  const [modalFreeClockingDimona, setModalFreeClockingDimona] = useState<boolean>(false);
  const [activateFreeClockingDimonaAll, setActivateFreeClockingDimonaAll] = useState<boolean>(false);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const updateForm = (c: boolean, e: any, name: any) => {
    setChecked(c ? [...checked, name] : checked.filter((el) => el != name));
  };

  const onDimonaCorrectionChange = (checked: boolean, e: any) => {
    updateForm(checked, e, 'dimona_shyft_clocking_correction');
    setModalDimonaCorrection(checked);
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.DIMONA.SETTINGS.ACTIVATE_AUTO_DIMONA.LABEL'),
          description: t('SETTINGS.DIMONA.SETTINGS.ACTIVATE_AUTO_DIMONA.DESCRIPTION'),
          name: ['general', 'automatic_dimona'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.general?.automatic_dimona} />,
          show: isFeatureEnabled(features, FEATURES.AUTOMATIC_DIMONA_CONTRACTS),
        },
        {
          label: t('SETTINGS.DIMONA.SETTINGS.CONTRACT_CREATION_DIMONA.LABEL'),
          description: t('SETTINGS.DIMONA.SETTINGS.CONTRACT_CREATION_DIMONA.DESCRIPTION'),
          name: ['general', 'contract_direct_dimona'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.general?.contract_direct_dimona} />,
          show: isFeatureEnabled(features, FEATURES.CONTRACTS),
        },
        {
          label: t('SETTINGS.DIMONA.SETTINGS.CLOCKING_DETAILS_DIMONA.LABEL'),
          description: t('SETTINGS.DIMONA.SETTINGS.CLOCKING_DETAILS_DIMONA.DESCRIPTION'),
          name: ['general', 'dimona_shyft_clocking_correction'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.general?.dimona_shyft_clocking_correction}
              onChange={onDimonaCorrectionChange}
            />
          ),
          show: isFeatureEnabled(features, FEATURES.CLOCKING),
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    setModalDimonaCorrection(false);
    setActivateDimonaCorrectionAll(false);
    setActivateFreeClockingDimonaAll(false);
    form.resetFields();
    if (currentSettings?.general) {
      setChecked(
        Object.entries(currentSettings.general).map(([key, value]: any) =>
          value == true || (value !== undefined && value > 1) ? key : null,
        ),
      );
    }
    form.setFieldsValue(currentSettings);
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    if (activateDimonaCorrectionAll) {
      values = {
        ...values,
        general: {
          ...values.general,
          dimona_shyft_clocking_correction_all_users: true,
        },
      };
    }
    if (activateFreeClockingDimonaAll) {
      values = {
        ...values,
        general: {
          ...values.general,
          dimona_free_clocking_registration_all_users: true,
        },
      };
    }
    updateCurrentSettings(values)
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.DIMONA.SETTINGS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
      <ModalDimonaCorrectionAllUsers
        visible={modalDimonaCorrection}
        setVisible={setModalDimonaCorrection}
        setActivateDimonaCorrectionAll={setActivateDimonaCorrectionAll}
      />
      <ModalFreeClockingDimona
        visible={modalFreeClockingDimona}
        setVisible={setModalFreeClockingDimona}
        setActivateFreeClockingDimonaAll={setActivateFreeClockingDimonaAll}
      />
    </div>
  );
};

export default styled(FreeShiftsSettings)``;
