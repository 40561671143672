import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { handleError, isFeatureEnabled } from '@/utils';
import axios from 'axios';
import { Moment } from 'moment';
import { useContext, useEffect, useState } from 'react';
import { V4DailyTurnover } from '../types/daily-turnover.types';

type Props = {
  startDate: Moment;
  activeKey: string;
};

export const useDailyTurnover = ({ startDate, activeKey }: Props) => {
  const {
    state: { activeDepartmentId, features },
  } = useContext(AppContext);

  const [dailyTurnover, setDailyTurnover] = useState<V4DailyTurnover | null>(null);
  const [loadingDailyTurnover, setLoadingDailyTurnover] = useState<boolean>(false);

  useEffect(() => {
    getDailyTurnover();
  }, [activeKey, startDate]);

  const getDailyTurnover = () => {
    setLoadingDailyTurnover(true);
    axios
      .get(`${process.env.REACT_APP_API_URL_V4}/insights`, {
        params: {
          departmentId: activeDepartmentId,
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: startDate.clone().endOf('month').format('YYYY-MM-DD'),
          sectionId: isFeatureEnabled(features, FEATURES.SECTIONS) ? (activeKey == 'null' ? null : activeKey) : null,
        },
      })
      .then(({ data }) => {
        setDailyTurnover(data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingDailyTurnover(false);
      });
  };

  return {
    loadingDailyTurnover,
    dailyTurnover,
    getDailyTurnover,
    setLoadingDailyTurnover,
    setDailyTurnover,
  };
};
