import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const ClockingTrialVideoModal: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const [timeLeft, setTimeLeft] = useState(38);
  const [isVideoWatched, setIsVideoWatched] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          setIsVideoWatched(true);
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleClose = () => {
    if (isVideoWatched) {
      setVisible(false);
      localStorage.setItem('clocking-trial-video-watched', 'true');
    }
  };

  return (
    <Modal visible={visible} closable={false} zIndex={10000} width={'75%'} className={className} footer={null}>
      <video style={{ width: '100%' }} autoPlay loop muted src={process.env.PUBLIC_URL + '/clocking-trial.mp4'} />
      <Button
        type="text"
        style={{
          position: 'absolute',
          top: -18,
          right: -18,
          backgroundColor: isVideoWatched ? 'gray' : '#000',
          borderRadius: 50,
          padding: '2px 4px',
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={handleClose}
        disabled={!isVideoWatched}
      >
        <i className="icon-cancel" style={{ fontSize: 24, color: '#fff' }} />
        {!isVideoWatched && <span style={{ color: '#fff', fontSize: 20 }}>{timeLeft}s</span>}
      </Button>
    </Modal>
  );
};

export default styled(ClockingTrialVideoModal)`
  .ant-modal-body {
    padding: 5px;
    padding-bottom: 0;
  }
`;
