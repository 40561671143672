import { IDepartment } from '@/types/department.model';
import { Checkbox, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IResponse } from '.';

const { Option } = Select;

interface Props {
  className?: string;
  department?: IDepartment;
  form: FormInstance;
  meta?: IResponse['meta'];
}

const MealVoucher: React.FC<Props> = ({ className, form, meta }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <div className={className}>
      <Row gutter={[20, 10]}>
        <Col lg={8} md={12}>
          <Form.Item name={'company_lunch_ticket'} valuePropName="checked">
            <Checkbox>{t('USERS.USER.HR.COMPANY_LUNCH_TICKET')}</Checkbox>
          </Form.Item>
        </Col>
        <Form.Item
          className="one-line"
          shouldUpdate={(prevValues, curValues) => prevValues.company_lunch_ticket !== curValues.company_lunch_ticket}
          noStyle
        >
          {(form) => {
            const company_lunch_ticket = form.getFieldValue('company_lunch_ticket');
            if (company_lunch_ticket) {
              return (
                <React.Fragment>
                  <Col lg={8} md={12}>
                    <Form.Item label={t('USERS.USER.HR.MEAL_VOUCHER_FROM_DATE')} name={'meal_voucher_from_date'}>
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            meal_voucher_from_date: e,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={12}>
                    <Form.Item label={t('USERS.USER.HR.MEAL_VOUCHER_TO_DATE')} name={'meal_voucher_to_date'}>
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            meal_voucher_to_date: e,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </React.Fragment>
              );
            }
          }}
        </Form.Item>
      </Row>
    </div>
  );
};

export default styled(MealVoucher)``;
