import { IDepartment } from '@/types/department.model';
import { Checkbox, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IResponse } from '.';

const { Option } = Select;

interface Props {
  className?: string;
  department?: IDepartment;
  form: FormInstance;
  meta?: IResponse['meta'];
}

const Car: React.FC<Props> = ({ className, form, meta }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <div className={className}>
      <Row gutter={[20, 10]}>
        <Col lg={8} md={12}>
          <Form.Item name="company_car" valuePropName="checked" style={{ marginBottom: 0 }}>
            <Checkbox>{t('USERS.USER.HR.COMPANY_CAR')}</Checkbox>
          </Form.Item>
        </Col>
        <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.company_car !== curValues.company_car} noStyle>
          {() => {
            const hasCompanyCar = form.getFieldValue('company_car');
            if (hasCompanyCar) {
              return (
                <React.Fragment>
                  <Col lg={8} md={12}>
                    <Form.Item label={t('USERS.USER.HR.COMPANY_CAR_PLATE')} name={'company_car_plate'}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={12}>
                    <Form.Item label={t('USERS.USER.HR.COMPANY_CAR_BRAND_MODEL')} name={'company_car_brand_model'}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={12}>
                    <Form.Item name={'catalog_value'} label={t('USERS.USER.HR.CATALOG_VALUE')}>
                      <InputNumber
                        style={{ width: '100%' }}
                        min={0}
                        step="any"
                        parser={(value) => {
                          return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={12}>
                    <Form.Item label={t('USERS.USER.HR.FIRST_REGISTRATION_DATE')} name={'first_registration_date'}>
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            first_registration_date: e,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={12}>
                    <Form.Item label={t('USERS.USER.HR.FUEL_TYPE')} name={'fuel_type'}>
                      <Select style={{ width: '100%' }}>
                        {meta?.['fuel_type']?.map(({ id, name }) => (
                          <Option key={`fuel_type_${id}`} value={id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={12}>
                    <Form.Item name={'emmission'} label={t('USERS.USER.HR.EMMISSION')}>
                      <InputNumber
                        style={{ width: '100%' }}
                        min={0}
                        step="any"
                        parser={(value) => {
                          return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={12}>
                    <Form.Item name={'net_deduction'} label={t('USERS.USER.HR.NET_DEDUCTION')}>
                      <InputNumber
                        style={{ width: '100%' }}
                        min={0}
                        step="any"
                        parser={(value) => {
                          return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={12}>
                    <Form.Item label={t('USERS.USER.HR.CAR_FROM_DATE')} name={'car_from_date'}>
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            car_from_date: e,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={12}>
                    <Form.Item label={t('USERS.USER.HR.CAR_DATE_TO')} name={'car_date_to'}>
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            car_date_to: e,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </React.Fragment>
              );
            }
          }}
        </Form.Item>
      </Row>
    </div>
  );
};

export default styled(Car)``;
