import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Form, Input, Button, Checkbox, notification } from 'antd';
import { IField, RESOURCE_TYPES } from '@/types/field.model';
import { isEmpty } from 'lodash';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  visible: boolean;
  field?: IField | null;
  onClose: () => void;
  onSave: (result: IField[]) => void;
  departmentId?: string;
}
const ModalUserField: React.FC<Props> = ({ className, visible, field, departmentId, onSave, onClose }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ ...field });
  }, [field]);

  const onFinish = async (values: any) => {
    setIsSaving(true);
    let userFieldResponse;

    try {
      if (field?.id) {
        userFieldResponse = await Axios.patch(
          `${process.env.REACT_APP_API_URL}/v3/users-fields/${field.custom_field_id}`,
          {
            ...values,
          },
          {
            params: {
              departmentId,
            },
          },
        );
      } else {
        userFieldResponse = await Axios.post(
          `${process.env.REACT_APP_API_URL}/v3/users-fields`,
          {
            ...values,
          },
          {
            params: {
              departmentId,
            },
          },
        );
      }

      if (userFieldResponse) {
        onSave([...userFieldResponse?.data]);
        onClose();
      } else {
        onClose();
      }
    } catch (error) {
      console.error(error);
      const description = field?.id ? t('USER_FIELDS.UPDATE_FIELD_ERROR') : t('USER_FIELDS.FIELD_CREATION_ERROR');
      notification.open({
        message: t('GLOBAL.AN_ERROR_OCCURRED'),
        description,
        type: 'error',
      });
    }
    setIsSaving(false);
  };

  const onValuesChange = (values: any) => {
    if (values.mandatory) {
      form.setFieldsValue({
        display: values.mandatory,
      });
    }
  };

  if (field) {
    const disabledCheckbox = ([
      RESOURCE_TYPES.FIRSTNAME,
      RESOURCE_TYPES.LASTNAME,
      RESOURCE_TYPES.EMAIL,
    ] as string[]).includes(field.resourceType || '');
    return (
      <Modal
        className={className}
        visible={visible}
        onOk={() => form.submit()}
        onCancel={onClose}
        title={isEmpty(field) ? t('USER_FIELDS.ADDING_FIELD') : t('USER_FIELDS.FIELD_MODIFICATION')}
        footer={[
          <Button key="back" onClick={onClose}>
            {t('GLOBAL.CANCEL')}
          </Button>,
          <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
            {field?.id ? t('GLOBAL.MODIFY') : t('GLOBAL.CREATE')}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
          <Form.Item name="name" label={t('USER_FIELDS.FIELD_NAME')} required rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <div className="form-items-group one-line">
            <Form.Item name="mandatory" valuePropName="checked">
              <Checkbox disabled={disabledCheckbox}>{t('GLOBAL.MANDATORY')}</Checkbox>
            </Form.Item>
            <Form.Item name="display" valuePropName="checked">
              <Checkbox disabled={disabledCheckbox}>{t('GLOBAL.DISPLAY_FIELD')}</Checkbox>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    );
  } else {
    return <Form form={form} style={{ display: 'none' }} />;
  }
};

export default styled(ModalUserField)``;
