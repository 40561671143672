import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import { IPcCategory } from '@/types/hr/pc-category.model';
import { ISkill } from '@/types/skill.model';
import { difference, getWindowSize } from '@/utils';
import { Button, Drawer, Form, Input, InputNumber, notification } from 'antd';
import { SelectValue } from 'antd/es/select';
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomColorPicker from '../CustomColorPicker';

interface Props {
  className?: string;
  visible: boolean;
  skill?: ISkill | null;
  userRecordId?: string;
  onClose: () => void;
  onSave: (result: ISkill) => void;
}

const DrawerSkill: React.FC<Props> = ({ className, visible, skill, userRecordId, onClose, onSave }) => {
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [activeCategory, setActiveCategory] = useState<IPcCategory | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    state: { activeDepartmentId, activeDepartment, pcCategories, loadingPcCategories },
  } = useContext(AppContext);

  const initialValues: any = {
    background: '#03a9f4',
  };

  useEffect(() => {
    form.resetFields();
    let fieldsValue: any = {
      ...initialValues,
    };

    if (skill) {
      fieldsValue = {
        ...fieldsValue,
        ..._(skill).pick(['name', 'background', 'priceBruto', 'price', 'ay_category_id', 'ay_function_id']).value(),
      };

      if (skill.ay_category_id) {
        const category = pcCategories.find((category) => category.id == skill.ay_category_id);
        if (category) {
          setActiveCategory(category);
        }
      }

      if (!fieldsValue.background) {
        fieldsValue.background = '#ffffff';
      }
    }
    form.setFieldsValue(fieldsValue);
    // eslint-disable-next-line
  }, [skill]);

  const onFinish = async (values: any) => {
    setIsSaving(true);
    let skillResponse;

    try {
      if (skill && !_.isEmpty(skill)) {
        const differences = difference(values, skill);
        if (!_.isEmpty(differences)) {
          skillResponse = await axios.patch(`${process.env.REACT_APP_API_URL}/v3/skills/${skill.id!}`, {
            ...differences,
          });
        }
      } else {
        skillResponse = await axios.post(`${process.env.REACT_APP_API_URL}/v3/skills`, {
          departmentId: activeDepartmentId,
          ...values,
          userRecordId,
        });
      }

      if (skillResponse) {
        onSave({
          ...skillResponse?.data.skill,
        });
        form.resetFields();
        onClose();
      } else {
        form.resetFields();
        onClose();
      }
    } catch (error) {
      console.error(error);
      const description = skill?.id ? t('SKILLS.SKILL_UPDATE_FAILED') : t('SKILLS.SKILL_CREATION_FAILED');
      notification.open({
        message: t('GLOBAL.AN_ERROR_OCCURRED'),
        description,
        type: 'error',
      });
    }
    setIsSaving(false);
  };

  const onAyCategoryChange = (value: SelectValue) => {
    const category = pcCategories.find((category) => category.id == value);
    if (category) {
      setActiveCategory(category);
    }
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={skill?.id ? t('SKILLS.SKILL_MODIFICATION') : t('SKILLS.ADDING_SKILL')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 400 : '75%'}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
          <Input placeholder={t('SKILLS.SKILL_NAME_PLACEHOLDER')} id="skill-name" />
        </Form.Item>
        <Form.Item label={t('GLOBAL.EMPLOYEE_COST')}>
          <div className="ant-input-wrapper ant-input-group">
            <Form.Item name="price" style={{ margin: 0 }}>
              <InputNumber className="ant-input" />
            </Form.Item>
            <span className="ant-input-group-addon">{getSymbolFromCurrency(`${activeDepartment?.currency}`)}/h</span>
          </div>
        </Form.Item>
        <Form.Item label={t('GLOBAL.EMPLOYER_COST')}>
          <div className="ant-input-wrapper ant-input-group">
            <Form.Item name="priceBruto" style={{ margin: 0 }}>
              <InputNumber className="ant-input" />
            </Form.Item>
            <span className="ant-input-group-addon">{getSymbolFromCurrency(`${activeDepartment?.currency}`)}/h</span>
          </div>
        </Form.Item>
        <Form.Item
          label={t('GLOBAL.COLOR')}
          shouldUpdate={(prevValues, curValues) => prevValues.background !== curValues.background}
        >
          {() => {
            const background = form.getFieldValue('background');
            return (
              <React.Fragment>
                <Form.Item name="background" style={{ display: 'none' }}>
                  <Input hidden id="skill-background" />
                </Form.Item>
                <CustomColorPicker form={form} color={background} property="background" />
              </React.Fragment>
            );
          }}
        </Form.Item>
        {/* {activeDepartment?.ay_core && (
          <div style={{ marginTop: 25 }}>
            <h4 style={{ color: Colors.ayBrand, fontWeight: 'bold' }}>AbsoluteYOU</h4>
            <Form.Item label={t('GLOBAL.AY_CATEGORY')} name="ay_category_id">
              <Select
                placeholder={t('GLOBAL.AY_CATEGORY')}
                loading={loadingPcCategories}
                disabled={loadingPcCategories}
                allowClear
                showSearch
                filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={onAyCategoryChange}
              >
                {pcCategories.map((category) => (
                  <Select.Option value={String(category.id)}>{category[`name_${locale}`]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            {activeCategory && (
              <Form.Item label={t('GLOBAL.AY_FUNCTION')} name="ay_function_id">
                <Select
                  placeholder={t('GLOBAL.AY_FUNCTION')}
                  loading={loadingPcCategories}
                  disabled={loadingPcCategories}
                  allowClear
                  showSearch
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {activeCategory.functions.map((func) => (
                    <Select.Option value={String(func.id)}>{func[`name_${locale}`]}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </div>
        )} */}
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerSkill;
