import React from 'react';
import { InitialStateType as AuthInitialStateType } from '@/context';
import styled from 'styled-components';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  authState: AuthInitialStateType;
}

const ImpersonateBar: React.FC<Props> = ({ className, authState }) => {
  return <div className={className}>{authState?.userDetails?.email}</div>;
};

export default styled(ImpersonateBar)`
  display: block;
  width: 100%;
  background: ${colors.red};
  color: white;
  position: fixed;
  top: 0;
  z-index: 999999999999;
  font-size: 10px;
  text-align: center;
`;
