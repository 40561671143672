import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { Form, FormInstance, List } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import NewBadge from '../../components/NewBadge';
import FormActions from './FormActions';

interface Props {
  className?: string;
  form: FormInstance<any>;
  lists: ISettingsListElement[][][];
  elements: {
    title: string;
    visible: boolean;
  }[];
  loading: boolean;
  formHasChanged: boolean;
  onReset: () => void;
  onFinish: (values: any) => void;
  onValuesChange: (changedValues: any, values: any) => void;
}

const SettingsForm: React.FC<Props> = ({
  className,
  form,
  lists,
  elements,
  loading,
  formHasChanged,
  onFinish,
  onValuesChange,
  onReset,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, loadingSettings },
    dispatch,
  } = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    if (!location || !location.search || loadingSettings) return;

    const params = new URLSearchParams(location.search);
    const command = params.get('command');
    if (!command) return;
    const element = document.getElementById(command);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // Add the blinking border after 1 second
      setTimeout(() => {
        element.classList.add('blinking-border');
        // Remove the blinking border after 2 seconds (2 * 500ms)
        setTimeout(() => {
          element.classList.remove('blinking-border');
        }, 2000);
      }, 1000);
    }
  }, [location, location.search, loadingSettings]);

  return (
    <Form className={className} form={form} layout="vertical" onFinish={onFinish} onValuesChange={onValuesChange}>
      {lists.map((list, i) => {
        if (
          list.length == 0 ||
          (list.length == 1 && list.every((element) => element.every((setting) => setting.show == false)))
        ) {
          return;
        }
        return (
          <div key={i} style={{ display: elements[i].visible ? 'block' : 'none' }}>
            <h3
              id={
                elements.length > 1 || elements[0].title !== t('GLOBAL.GENERAL')
                  ? list.flatMap((element) => element.flatMap((sub) => sub.label)).includes(elements[i].title)
                    ? ''
                    : elements[i].title
                  : ''
              }
              style={{ marginTop: '20px', padding: 0, display: 'inline-block' }}
            >
              {elements.length > 1 || elements[0].title !== t('GLOBAL.GENERAL') ? elements[i].title : ''}
            </h3>
            <List
              key={i}
              dataSource={list}
              style={{ borderRadius: 10, backgroundColor: 'white', padding: '10px 25px' }}
              renderItem={(arr, i) => {
                const found = arr.find((el) => el.show == true);
                if (found) {
                  return (
                    <>
                      <List.Item
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        key={i}
                      >
                        {arr.map(
                          ({
                            label,
                            description,
                            name,
                            style,
                            element,
                            elementBelow,
                            show,
                            showCallout,
                            callout,
                            elementAddon,
                            elementLeft,
                            newBadge,
                            rowStyle,
                            shouldUpdate,
                          }) => (
                            <>
                              {show && (
                                <>
                                  <div
                                    id={label}
                                    key={label}
                                    style={{
                                      width: '100%',
                                      display: elementBelow ? 'block' : 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      margin: '10px 0px',
                                      gap: '15px',
                                      ...rowStyle,
                                    }}
                                  >
                                    {!elementLeft && (
                                      <div>
                                        {label != '' && (
                                          <div>
                                            {!newBadge ? (
                                              <p id={label} style={{ margin: 0, display: 'inline-block' }}>
                                                {label}
                                              </p>
                                            ) : (
                                              <p
                                                id={description ? undefined : label}
                                                style={{ display: 'flex', margin: 0 }}
                                              >
                                                <NewBadge end={newBadge} noTooltip />
                                                {label}
                                              </p>
                                            )}
                                          </div>
                                        )}
                                        {description && description != '' && (
                                          <span id={description} style={{ color: `${colors.grey}`, marginRight: 10 }}>
                                            {description}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                    {element && (
                                      <Form.Item name={name} style={style} shouldUpdate={shouldUpdate}>
                                        {element}
                                      </Form.Item>
                                    )}
                                    {elementAddon}
                                  </div>
                                  {showCallout && callout && (
                                    <div style={{ width: '100%', margin: '10px 0' }}>{callout}</div>
                                  )}
                                </>
                              )}
                            </>
                          ),
                        )}
                      </List.Item>
                    </>
                  );
                } else {
                  return null;
                }
              }}
            />
          </div>
        );
      })}
      <div style={{ marginTop: 50 }}>
        <FormActions saving={loading} active={formHasChanged} onReset={onReset} />
      </div>
    </Form>
  );
};

export default styled(SettingsForm)`
  .blinking-border {
    animation: blink-border 1s 2;
  }

  @keyframes blink-border {
    0% {
      box-shadow: 0 0 0 0 transparent;
    }
    50% {
      box-shadow: 0 0 0 4px #00a651;
      padding: 2px;
    }
    100% {
      box-shadow: 0 0 0 0 transparent;
    }
  }
`;
