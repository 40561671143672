import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Layout, Skeleton, Button } from 'antd';
import Colors from '@/styles/colors';
import { IAttribut } from '@/types/attribut.model';
import { IResource } from '@/types/resource.model';
import colors from '@/styles/colors';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const QRCode = require('qrcode.react');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Barcode = require('react-barcode');

const { Sider } = Layout;

interface Props {
  className?: string;
  resource?: IResource;
}

const SiderRight: React.FC<Props> = ({ className, resource }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [attribut, setAttribut] = useState<IAttribut | null>(null);

  useEffect(() => {
    setAttribut(null);
    if (resource?.attributes?.length) {
      setAttribut(resource.attributes[0]);
    }
  }, [resource]);

  const downloadQR = () => {
    const canvas = document.getElementById('qr-code') as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png', 1).replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = 'qrcode.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const downloadBarCode = () => {
    const svgElement = document.getElementsByClassName('bar-code')[0]?.childNodes[0] as SVGGraphicsElement;
    if (svgElement) {
      const { width, height } = svgElement.getBBox();
      const clonedSvgElement = svgElement.cloneNode(true);
      const outerHTML = (clonedSvgElement as SVGGraphicsElement).outerHTML,
        blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' });
      const URL = window.URL || window.webkitURL || window;
      const blobURL = URL.createObjectURL(blob);
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');

        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext('2d')!;
        // draw image in canvas starting left-0 , top - 0
        context.drawImage(image, 0, 0, width, height);
        const pngUrl = canvas.toDataURL('image/png', 1).replace('image/png', 'image/octet-stream');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'bar-code.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        //  downloadImage(canvas); need to implement
      };
      image.src = blobURL;
    }
  };

  if (!attribut) {
    return (
      <div className={className} width={350} theme="light">
        <Skeleton title={false} paragraph={{ rows: 4 }} className="skeleton" active />
        <br />
        <Skeleton.Button className="skeleton-button" active /> <br />
        <Skeleton.Button className="skeleton-button" active /> <br />
      </div>
    );
  } else {
    const { codeType, code, id } = attribut;
    if (code || (codeType === 'qr-code' && id)) {
      return (
        <div width={350} theme="light" className={className}>
          {codeType === 'qr-code' && (
            <div className="qr-code-container">
              <div className="qr-code">
                <QRCode id="qr-code" size={640} level="H" value={code || id} />
              </div>
              <Button type="default" style={{ margin: '10px 0 20px', width: '100%' }} onClick={downloadQR}>
                {t('GLOBAL.DOWNLOAD_QR_CODE')}
              </Button>
            </div>
          )}
          {codeType === 'bar-code' && (
            <div className="bar-code-container">
              <div className="bar-code">
                <Barcode value={code} />
              </div>
              <Button type="default" style={{ margin: '10px 0 20px', width: '100%' }} onClick={downloadBarCode}>
                {t('GLOBAL.DOWNLOAD_BAR_CODE')}
              </Button>
            </div>
          )}
        </div>
      );
    } else {
      return <div className={className} width={350} theme="light"></div>;
    }
  }
};

const SiderRightStyled = styled(SiderRight)`
  .ant-layout-sider-children {
    .ant-collapse {
      border-left: none;
      background: none;

      .ant-collapse-header {
        background: none;
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        color: ${Colors.blue};

        .ant-collapse-arrow {
          color: ${Colors.green};
          left: 12px;
          font-size: 14px;
        }
      }

      .ant-collapse-item {
        border-radius: 0 !important;
      }
    }

    .skeleton {
      text-align: center;
      width: 258px;
    }

    .skeleton-button {
      text-align: center;
      width: 150px;
    }
  }

  .qr-code-container {
    .qr-code {
      border: 1px solid ${colors.greyLight};
      padding: 20px;
      border-radius: 3px;
      width: 310px;
      height: 310px;
    }
  }

  .bar-code-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px;

    .bar-code {
      border: 1px solid ${colors.greyLight};
      padding: 20;
      border-radius: 3;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  #qr-code {
    width: 270px !important;
    height: 270px !important;
  }
`;

export default SiderRightStyled;
