import React, { useState, useEffect, useContext, useRef } from 'react';
import TableView from '@/layouts/TableView';
import Header from './components/Header';
import TableRowActions from './components/TableRowActions';
import { Table, message, Modal } from 'antd';
import axios from 'axios';
import AppContext from '@/pages/app/context';
import { AlignType } from '@/types/alignType.model';
import { IShortcut } from '@/types/shortcut.model';
import DrawerShortcut from '@/pages/app/components/drawers/Shorcut';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
}

const Shortcuts: React.FC<Props> = ({ className }) => {
  const {
    state: { loadingShortcuts, shortcuts },
    dispatch,
  } = useContext(AppContext);
  const shortcutsRef = useRef(shortcuts);
  shortcutsRef.current = shortcuts;
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });
  const [filteredShortcuts, setFilteredShortcuts] = useState<IShortcut[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeShortcut, setActiveShortcut] = useState<IShortcut | null>(null);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.ID'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      },
      {
        title: t('GLOBAL.CODE'),
        dataIndex: 'shortcut',
        key: 'shortcut',
      },
      {
        title: t('GLOBAL.START'),
        key: 'start',
        displayName: 'start',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IShortcut) => moment.unix(record.start!).format('HH:mm'),
      },
      {
        title: t('GLOBAL.END'),
        key: 'end',
        displayName: 'end',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IShortcut) => moment.unix(record.end!).format('HH:mm'),
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IShortcut) => (
          <TableRowActions onEdit={() => setActiveShortcut(record)} onDelete={() => onWantToDelete(record.id!)} />
        ),
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    const filteredShortcuts = shortcuts?.filter((shortcut: any) => {
      return Object.keys(shortcut).some((key) => {
        if (key === 'background') {
          return false;
        }
        if (typeof shortcut[key] === 'string') {
          return shortcut[key].toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return false;
        }
      });
    });
    setFilteredShortcuts(filteredShortcuts);
  }, [shortcuts, searchTerm]);

  const onWantToDelete = (shortcutId: string) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('SHORTCUTS.MODAL_DELETE_SHORTCUT.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDelete(shortcutId);
      },
      onCancel: () => {},
    });
  };

  const onDelete = (shortcutId: string) => {
    const shortcutsCopy = [...shortcutsRef.current];
    const index = shortcutsCopy.findIndex((shortcut) => shortcut.id === shortcutId);

    if (~index) {
      const shortcutsResult = [...shortcutsCopy];
      shortcutsResult.splice(index, 1);
      dispatch({
        type: 'SET_SHORTCUTS',
        payload: shortcutsResult,
      });
    }

    axios.delete(`${process.env.REACT_APP_API_URL}/v3/shortcuts/${shortcutId}`).catch((error) => {
      if (~index) {
        console.error(error);
        message.error(t('SHORTCUTS.MODAL_DELETE_SHORTCUT.MESSAGE_ERROR'));
        if (shortcutsCopy) {
          dispatch({
            type: 'SET_SHORTCUTS',
            payload: shortcutsCopy,
          });
        }
      }
    });
  };

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchTerm(value);
  };

  const onSave = (result: IShortcut) => {
    const shortcutsCopy = [...shortcutsRef.current];
    const index = shortcutsCopy.findIndex((shortcut) => shortcut.id === result.id);

    if (~index) {
      shortcutsCopy[index] = result;
      dispatch({
        type: 'SET_SHORTCUTS',
        payload: shortcutsCopy,
      });
    } else {
      shortcutsCopy.push(result);
      const shortcutsCopySorted = shortcutsCopy.sort((a, b) => {
        if (a.name!.toLowerCase() < b.name!.toLowerCase()) {
          return -1;
        }
        if (a.name!.toLowerCase() > b.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      dispatch({
        type: 'SET_SHORTCUTS',
        payload: shortcutsCopySorted,
      });
    }
    setActiveShortcut(null);
  };

  return (
    <React.Fragment>
      <h2>{t('GLOBAL.SHORTCUTS')}</h2>
      <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10, marginTop: 25 }}>
        <TableView>
          <Header disabled={false} onSearchChange={onSearchChange} onCreate={() => setActiveShortcut({})} />
          <Table
            className={className}
            loading={loadingShortcuts}
            dataSource={filteredShortcuts}
            columns={columns}
            rowKey="id"
            pagination={false}
          />
        </TableView>
        <DrawerShortcut
          shortcut={activeShortcut}
          visible={!!activeShortcut}
          onClose={() => setActiveShortcut(null)}
          onSave={onSave}
        />
      </div>
    </React.Fragment>
  );
};

export default Shortcuts;
