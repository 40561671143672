import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { IHrField } from '@/types/hr/hr-field.model';
import { Form, Input, InputNumber, Select } from 'antd';
import PhoneInput from 'react-phone-input-2';

interface Props {
  className?: string;
  field: IHrField;
}

const HrField: React.FC<Props> = ({ className, field }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);

  return (
    <Form.Item
      name={`${field.name}`}
      label={t(`HR.FIELDS.AY.${field.name.toUpperCase()}.TITLE`)}
      className={className}
      rules={[{ required: field.mandatory }]}
    >
      {field.type == 'dropdown' ? (
        <Select size="large" placeholder={t(`HR.FIELDS.AY.${field.name.toUpperCase()}.TITLE`)}>
          {field.options.map((option) => (
            <Select.Option value={option.value}>
              {t(`HR.FIELDS.AY.${field.name.toUpperCase()}.${option.name.toUpperCase()}`)}
            </Select.Option>
          ))}
        </Select>
      ) : field.type == 'phone' ? (
        <PhoneInput
          containerClass="ant-form-item-control-input-content"
          inputClass="ant-input"
          inputStyle={{ width: '100%', height: 40, background: '#f7f8fb' }}
        />
      ) : (
        <Input size="large" />
      )}
    </Form.Item>
  );
};

export default styled(HrField)``;
