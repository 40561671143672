import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, InputNumber, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-timezone';
import TextArea from 'antd/lib/input/TextArea';
import axios, { AxiosResponse } from 'axios';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  period: number | null;
  departmentId?: string;
  recordId?: string | null;
  onClose: () => void;
  onSave: (response: AxiosResponse<any>) => void;
}

const ModalAdjustment: React.FC<Props> = ({ className, period, departmentId, recordId, onClose, onSave }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [form] = Form.useForm();
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
    setHours('');
    setMinutes('');
  }, [period]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    let value = Math.abs(Number(hours) * 60) + Number(minutes);

    if (Number(hours) < 0 || hours == '-0') {
      value = value * -1;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/hr/extra-hours`,
        {
          adjust: value,
          comment: values?.comment,
          period,
        },
        {
          params: {
            departmentId,
            recordId,
          },
        },
      )
      .then((response) => {
        setIsSaving(false);
        onSave(response);
      })
      .catch(() => {
        setIsSaving(false);
        message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
      });
  };

  const onWantToClose = () => {
    onClose();
  };

  return (
    <Modal
      className={className}
      visible={!!period}
      maskClosable={false}
      onCancel={onWantToClose}
      title={t('EXTRA_HOURS.MODAL_ADD_ADJUSTMENT.TITLE')}
      getContainer={false}
      forceRender
      footer={[
        <Button key="back" onClick={onWantToClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
          {t('GLOBAL.SAVE')}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="hours"
          label={t('EXTRA_HOURS.MODAL_ADD_ADJUSTMENT.ADJUSTMENT_IN_HOURS')}
          rules={[{ required: true }]}
        >
          <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <Input
              value={hours}
              onChange={(e) => setHours(e.target.value)}
              placeholder="123"
              maxLength={hours.includes('-') ? 4 : 3}
              max={999}
              style={{ width: 100, textAlign: 'right' }}
            />
            <span>h</span>
            <Input
              value={minutes}
              onChange={(e) => setMinutes(e.target.value)}
              placeholder="45"
              maxLength={2}
              max={59}
              style={{ width: 100 }}
            />
          </div>
        </Form.Item>
        <Form.Item label={t('GLOBAL.COMMENT')} name="comment" rules={[{ required: true }]}>
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalAdjustment)``;
