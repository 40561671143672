import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const FormHeader: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div>
        <span>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.PROVISIONAL')}</span>
        <span>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.REAL')}</span>
      </div>
      <div>
        <span>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.PROVISIONAL')}</span>
        <span>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.REAL')}</span>
      </div>
    </div>
  );
};

export default styled(FormHeader)`
  display: flex;
  > div {
    margin-bottom: 15px;
    display: flex;
    width: 50%;
    span {
      flex: 1;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      &:nth-of-type(1) {
        margin-left: 185px;
        margin-right: 10px;
      }
    }
    &:nth-of-type(1) {
      margin-right: 20px;
    }
    &:nth-of-type(2) {
      margin-left: 20px;
    }
  }
`;
