import colors from '@/styles/colors';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { getWindowSize } from '@/utils';
import { UploadOutlined } from '@ant-design/icons';
import { Form, Input, List, message, Select, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import FormActions from '../../components/FormActions';
import AppContext from '@/pages/app/context';
import SettingsForm from '../../components/SettingsForm';
import { ISettingsListElement } from '@/types/settings/list-element.model';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const { Option } = Select;

const Profile: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const [form] = Form.useForm();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [fileList, setFileList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const pictureCustomRequest = (options: any) => {
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append('file', file);

    const user = currentSettings?.user;
    if (user) {
      Axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/picture`,
        params: {
          departmentId: activeDepartmentId,
        },
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          const { url, status } = response.data;
          setFileList([
            {
              ...file,
              url,
              status,
            },
          ]);
          onSuccess(response, file);
        })
        .catch((error) => {
          message.error(t('FORMS.PICTURE_UPLOAD_ERROR'));
          onError(error);
          console.log(error);
        });
    }
  };

  const onPictureRemove = async (file: any) => {
    return new Promise<boolean>((resolve, reject) => {
      const user = currentSettings?.user;
      if (user) {
        Axios.delete(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/picture`, {
          params: {
            departmentId: activeDepartmentId,
          },
        })
          .then(() => {
            resolve(true);
            setFileList([]);
          })
          .catch((error) => {
            message.error(t('FORMS.PICTURE_DELETION_ERROR'));
            console.error(error);
            reject(false);
          });
      } else {
        reject(false);
      }
    });
  };

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    let fieldsValue: any = {};
    if (currentSettings) {
      fieldsValue = {
        ...currentSettings,
      };
    }
    const image = currentSettings?.user?.picture;
    if (image) {
      setFileList([
        {
          uid: '-1',
          status: 'done',
          url: image,
        },
      ]);
    } else {
      setFileList([]);
    }
    form.setFieldsValue(fieldsValue);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings({
      user: {
        lang: values?.user?.lang,
      },
    })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const elements = [
    {
      title: t('SETTINGS.INFORMATION.PROFILE.TITLES.PERSONAL_DATA'),
      visible: true,
    },
    {
      title: t('SETTINGS.INFORMATION.PROFILE.TITLES.ADDITIONAL_DATA'),
      visible: true,
    },
  ];

  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.INFORMATION.PROFILE.FIRST_NAME.LABEL'),
          description: t('SETTINGS.INFORMATION.PROFILE.FIRST_NAME.DESCRIPTION'),
          name: ['user', 'firstname'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input disabled={true} placeholder={t('GLOBAL.FIRSTNAME')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
        {
          label: t('SETTINGS.INFORMATION.PROFILE.LAST_NAME.LABEL'),
          description: t('SETTINGS.INFORMATION.PROFILE.LAST_NAME.DESCRIPTION'),
          name: ['user', 'lastname'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input disabled={true} placeholder={t('GLOBAL.LASTNAME')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
        {
          label: t('SETTINGS.INFORMATION.PROFILE.EMAIL.LABEL'),
          description: t('SETTINGS.INFORMATION.PROFILE.EMAIL.DESCRIPTION'),
          name: ['user', 'email'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input disabled={true} placeholder={t('GLOBAL.EMAIL')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
      ],
      [
        {
          label: t('SETTINGS.INFORMATION.PROFILE.PHONE.LABEL'),
          description: t('SETTINGS.INFORMATION.PROFILE.PHONE.DESCRIPTION'),
          name: ['user', 'phone'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: (
            <PhoneInput
              containerClass="ant-form-item-control-input-content"
              inputClass="ant-input"
              inputStyle={{ width: windowSize.innerWidth > 900 ? 265 : '100%' }}
              disabled={true}
            />
          ),
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.INFORMATION.PROFILE.LANGUAGE.LABEL'),
          description: t('SETTINGS.INFORMATION.PROFILE.LANGUAGE.DESCRIPTION'),
          name: ['user', 'lang'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: (
            <Select style={{ width: '100%' }} placeholder={t('FORMS.LANGUAGE')}>
              {currentSettings?.datas?.languages?.map((language) => (
                <Option key={`lang_${language.id}`} value={language.id}>
                  {language.name}
                </Option>
              ))}
            </Select>
          ),
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
      ],
      [
        {
          label: t('SETTINGS.INFORMATION.PROFILE.PROFILE_PICTURE.LABEL'),
          description: t('SETTINGS.INFORMATION.PROFILE.PROFILE_PICTURE.DESCRIPTION'),
          style: { margin: 0 },
          element: (
            <ImgCrop>
              <Upload
                listType="picture-card"
                customRequest={pictureCustomRequest}
                onRemove={onPictureRemove}
                fileList={fileList}
                accept="image/*"
              >
                {fileList.length < 1 && <UploadOutlined />}
              </Upload>
            </ImgCrop>
          ),
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
      ],
    ],
  ];

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.INFORMATION.PROFILE.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(Profile)``;
