import { IAddress } from '@/types/address.model';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PlaceAutocomplete from './PlaceAutocomplete';

interface Props {
  className?: string;
  form: FormInstance;
  onChange?: () => void;
  withoutCode?: boolean;
}

const PlaceFields: React.FC<Props> = ({ className, form, withoutCode, onChange }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const onAddressChange = (address: IAddress) => {
    if (isEmpty(address)) {
      form.setFieldsValue({
        address: null,
        city: null,
        zip: null,
        country: null,
        lng: null,
        lat: null,
      });
    } else {
      form.setFieldsValue({
        ...address,
        ['search-input']: address.fullAddress,
      });
    }
    onChange?.();
  };

  return (
    <div className={className}>
      <Form.Item label={t('GLOBAL.ADDRESS')} name="search-input" rules={[{ required: true, max: 254 }]}>
        <PlaceAutocomplete onAddressChange={onAddressChange} />
      </Form.Item>
      {!withoutCode && (
        <Form.Item name="code" label={t('GLOBAL.CODE')} rules={[{ max: 254 }]}>
          <Input placeholder={t('ATTRIBUTES.CODE_PLACEHOLDER')} />
        </Form.Item>
      )}
      <Form.Item name="address" label={t('GLOBAL.STREET')} rules={[{ required: true }]}>
        <Input disabled={true} />
      </Form.Item>
      <Form.Item name="city" label={t('GLOBAL.CITY')}>
        <Input disabled={true} />
      </Form.Item>
      <Form.Item name="zip" label={t('GLOBAL.ZIP')}>
        <Input disabled={true} />
      </Form.Item>
      <Form.Item name="country" label={t('GLOBAL.COUNTRY')} rules={[{ required: true }]}>
        <Input disabled={true} />
      </Form.Item>
      <Form.Item label="Longitude" name="lng" style={{ display: 'none' }}>
        <Input disabled={true} />
      </Form.Item>
      <Form.Item label="Latitude" name="lat" style={{ display: 'none' }}>
        <Input disabled={true} />
      </Form.Item>
    </div>
  );
};

export default styled(PlaceFields)``;
