import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Form, Checkbox, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { IDepartment } from '@/types/department.model';
interface Props {
  visible: boolean;
  department?: IDepartment;
  userId: string;
  onClose: () => void;
}

const ModalDeleteUserConfirmation: React.FC<Props> = ({ visible, department, userId, onClose }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    form.resetFields();
  }, [userId]);

  const onFinish = async (values: any) => {
    setIsSaving(true);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/users/${userId}`, {
        data: {
          ...values,
        },
        params: {
          departmentId: department?.id,
        },
      })
      .then((response) => {
        message.success(t('USERS.MODAL_DELETE_USER.MESSAGE_SUCCESS'));
        onClose();
        history.push('/app/team/collaborators');
      })
      .catch((error) => {
        message.error(t('USERS.MODAL_DELETE_USER.MESSAGE_ERROR'));
      });
  };

  return (
    <Modal
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={visible}
      onCancel={onClose}
      className={'modal-danger'}
      title={t('GLOBAL.DELETION')}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
          {t('GLOBAL.REMOVE')}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <p>{t('USERS.MODAL_DELETE_USER.CONTENT')}</p>
        <>
          <Form.Item name="deleteShifts" valuePropName="checked" noStyle>
            <Checkbox>{t('USERS.MODAL_DELETE_USER.DELETE_SHIFTS')}</Checkbox>
          </Form.Item>
          <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.deleteShifts !== curValues.deleteShifts}>
            {() => {
              if (form.getFieldValue('deleteShifts')) {
                return (
                  <Form.Item name="replaceShifts" valuePropName="checked" noStyle>
                    <Checkbox>{t('USERS.MODAL_DELETE_USER.REPLACE_SHIFTS')}</Checkbox>
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
        </>
      </Form>
    </Modal>
  );
};

export default ModalDeleteUserConfirmation;
