import { IResource } from '@/types/resource.model';
import { Layout, Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Notes from './notes';
import Parameters from './parameters';
import SiderLeft from './sider-left';
import SiderRight from './sider-right';

const { Content } = Layout;
const { TabPane } = Tabs;

interface Props {
  className?: string;
  departmentId?: string;
  resource?: IResource;
  updateResource: (updatedResource: IResource) => void;
}

const AttributOverview: React.FC<Props> = ({ className, departmentId, resource, updateResource }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <div className={className}>
      <SiderLeft resource={resource} departmentId={departmentId} />
      <div className="content">
        <Tabs defaultActiveKey="1" size="large">
          <TabPane tab={t('GLOBAL.SETTINGS')} key="1">
            <Parameters resource={resource} departmentId={departmentId} updateResource={updateResource} />
          </TabPane>
          <TabPane disabled={!!resource?.pending} tab={t('GLOBAL.NOTES')} key="2">
            <Notes departmentId={departmentId} />
          </TabPane>
        </Tabs>
      </div>
      <SiderRight resource={resource} />
    </div>
  );
};

export default styled(AttributOverview)`
  background: #fff;
  position: relative;

  .content {
    margin-top: 25px;
  }

  > * {
    max-height: 100%;
    overflow: auto;
    &.ant-layout-content {
      padding: 10px 20px 20px;

      .ant-tabs-tabpane {
        padding: 20px;
        border-radius: 3px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      }
    }
    &.ant-layout-sider {
      background: #fff;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      &.right {
        padding: 0;
      }
    }
  }
`;
