import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { ITurnoverTemplate } from '@/types/insights/turnover-template.model';
import { handleError } from '@/utils';
import { Button, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const TemplatesTable: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, turnoverTemplates, loadingTurnoverTemplates },
    dispatch,
  } = useContext(AppContext);
  const [columns, setColumns] = useState<ColumnsType<ITurnoverTemplate>>([]);
  const [loadingDeleteId, setLoadingDeleteId] = useState<string | null>(null);
  const history = useHistory();

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        width: '100%',
      },
      {
        key: 'extra',
        render: (value, record: ITurnoverTemplate) => {
          return (
            <div className="actions">
              <span className="default-badge">{t('GLOBAL.DEFAULT')}</span>
              <Popconfirm
                title={t('GLOBAL.ARE_YOU_SURE?')}
                onConfirm={(e) => onDeleteTemplate(e, record)}
                onCancel={(e) => e?.stopPropagation()}
              >
                <Button
                  type="primary"
                  danger
                  loading={loadingDeleteId == record.id}
                  onClick={(e) => e.stopPropagation()}
                >
                  <i className="icon-trash-empty" />
                </Button>
              </Popconfirm>
            </div>
          );
        },
      },
    ]);
  }, [turnoverTemplates, loadingDeleteId]);

  const onDeleteTemplate = (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined, template: ITurnoverTemplate) => {
    if (!e) return;
    e.stopPropagation();
    e.preventDefault();
    setLoadingDeleteId(template.id);
    axios
      .delete(`${process.env.REACT_APP_API_URL_V4}/turnover-quota-templates/${template.id}`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_TURNOVER_TEMPLATES',
          payload: turnoverTemplates.filter((t) => template.id !== t.id),
        });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoadingDeleteId(null);
      });
  };

  const onRow = (template: ITurnoverTemplate) => {
    return {
      onClick: () => {
        history.push(`/app/settings/insights/turnover-templates/${template.id}`);
      },
    };
  };

  return (
    <>
      <Table
        className={`${className} row-clickable`}
        dataSource={turnoverTemplates}
        columns={columns}
        loading={loadingTurnoverTemplates}
        rowKey="id"
        onRow={onRow}
        pagination={false}
        showHeader={false}
      />
    </>
  );
};

export default styled(TemplatesTable)`
  .actions {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .default-badge {
    background-color: ${colors.blueLightPastel};
    padding: 2px 10px;
    border-radius: 5px;
    color: #fff;
  }
`;
