import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Menu } from 'antd';
import { IShift } from '@/types/shift.model';

interface Props {
  className?: string;
  selected_shifts_on_shift_key: IShift[] | null;
  onClick: () => void;
}

const DeleteMonth: React.FC<Props> = ({ className, selected_shifts_on_shift_key, onClick }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);

  return (
    <Menu.Item
      className={className}
      onClick={onClick}
      danger
      disabled={selected_shifts_on_shift_key ? selected_shifts_on_shift_key.length <= 0 : true}
    >
      <i className="icon-delete" /> {t('SCHEDULE.MONTHLY_VIEW.HEADER.DELETE_ALL_SELECTED_SHIFTS')}
    </Menu.Item>
  );
};

export default styled(DeleteMonth)`
  margin: 0 5px;
  &:hover {
    background-color: #efefef !important;
    border-radius: 8px;
  }
`;
