import React, { useEffect, useState, useContext, useRef } from 'react';
import Header from './components/Header';
import { Table, Space, BackTop, Form, InputNumber } from 'antd';
import { EnvironmentFilled } from '@ant-design/icons';
import TableRowActions from './components/TableRowActions';
import TableView from '@/layouts/TableView';
import { IShift } from '@/types/shift.model';
import Axios, { AxiosResponse } from 'axios';
import AppContext from '@/pages/app/context';
import { IUser } from '@/types/user.model';
import { IClockin } from '@/types/clockin.model';
import moment from 'moment';
import { isFeatureEnabled, isNullOrUndefined, minutesToHoursAndOrMinutes } from '@/utils';
import { IUserStatus } from '@/types/user-status.model';
import { useHistory } from 'react-router-dom';
import DisplayNameColumn from '@/pages/app/components/DisplayNameColumn';
import { useTranslation } from 'react-i18next';
import Tag from '../../components/Tag';
import { useForm } from 'antd/es/form/Form';
import TimePickerFormItem from '../../components/TimePickerFormItem';
import styled from 'styled-components';
import { FEATURES } from '@/types/features.model';
interface Props {
  className?: string;
  onlyAnomalies: boolean;
  setOnlyAnomalies: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClockinAnomaliesPage: React.FC<Props> = ({ className, onlyAnomalies, setOnlyAnomalies }) => {
  const {
    state: { activeDepartmentId, activeDepartment, features },
  } = useContext(AppContext);
  const [clockins, setClockins] = useState<IClockin[]>([]);
  const [statuses, setStatuses] = useState<IUserStatus[]>([]);
  const [activeStatus, setActiveStatus] = useState<string | null>(null);
  const [filteredClockins, setFilteredClockins] = useState<IClockin[]>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);
  const [form] = useForm();
  const clockingsRef = useRef(clockins);
  clockingsRef.current = clockins;

  useEffect(() => {
    history.push('clocking-validation');
  }, []);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.NAME'),
        key: 'displayName',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (_text: string, clockin: IClockin) => {
          return (
            <DisplayNameColumn
              user={clockin.user!}
              onClick={() => history.push(`/app/team/collaborators/${clockin.user!.recordId!}`)}
            />
          );
        },
        sorter: (a: IClockin, b: IClockin) => a.user?.displayName?.localeCompare(b.user?.displayName || '') || 0,
      },
      {
        title: t('GLOBAL.USER_CATEGORY_SHORT'),
        key: 'status',
        // eslint-disable-next-line react/display-name
        render: (_text: string, clockin: IClockin) => {
          return <Space>{clockin?.user?.userType?.name}</Space>;
        },
        sorter: (a: IClockin, b: IClockin) => a.user?.userType?.name?.localeCompare(b.user?.userType?.name || '') || 0,
      },
      {
        title: t('GLOBAL.DETAIL'),
        key: 'detail',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (_text: string, clockin: IClockin) => {
          const { section, attributes, skills } = clockin;
          return (
            <div className="column-details">
              {attributes?.map((attribute) => {
                const { background, color, name, id } = attribute;
                return (
                  <Tag
                    key={`clockin_${clockin.id}_attribute_${id}`}
                    color={color}
                    name={name}
                    background={background}
                  />
                );
              })}
              {section && <Tag color={section.color} name={section.name} background={section.background} />}
              {isFeatureEnabled(features, FEATURES.SKILLS) &&
                skills?.map((skill) => {
                  const { background, color, name, id } = skill;
                  return (
                    <Tag key={`clockin_${clockin.id}_skill_${id}`} color={color} name={name} background={background} />
                  );
                })}
            </div>
          );
        },
      },
      {
        title: t('GLOBAL.DATE'),
        key: 'date',
        width: 120,
        // eslint-disable-next-line react/display-name
        render: (_text: string, clockin: IClockin) => {
          return <Space>{moment.unix(clockin.start!).format('L')}</Space>;
        },
        sorter: (a: IClockin, b: IClockin) => a.start! - b.start!,
      },
      {
        title: t('GLOBAL.CLOCKING'),
        key: 'pointage',
        width: 160,
        // eslint-disable-next-line react/display-name
        render: (_text: string, clockin: IClockin) => {
          const { start, position, id } = clockin;
          return (
            <div className="time">
              {position.start && (
                <EnvironmentFilled
                  className="separator"
                  onClick={() => {
                    window.open(
                      `http://www.google.com/maps/place/${position.start?.latitude},${position.start?.longitude}`,
                      '_blank',
                    );
                  }}
                />
              )}
              {!position.start && <EnvironmentFilled className="separator" style={{ opacity: 0.1 }} />}
              <Form.Item name={`clocking_${id}_start`} style={{ display: 'none' }}>
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                allowClear={false}
                name={`clocking_${id}_start_time`}
                start={moment.unix(start!).startOf('day').unix()}
                changeTime={({ hour, minute }) => changeStartDate({ hour, minute, clockingId: id! })}
              />
              <span className="separator">-</span>
              <Form.Item name={`clocking_${id}_end`} style={{ display: 'none' }}>
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                allowClear={false}
                name={`clocking_${id}_end_time`}
                displayDuration={true}
                start={form.getFieldValue(`clocking_${id}_start`)}
                changeTime={({ hour, minute }) => changeEndDate({ hour, minute, clockingId: id! })}
              />
              {/* {`${moment.unix(start!).format('HH:mm')} - ${moment.unix(end!).format('HH:mm')}`} */}
              {position.end && (
                <EnvironmentFilled
                  className="separator"
                  onClick={() => {
                    window.open(
                      `http://www.google.com/maps/place/${position.end?.latitude},${position.end?.longitude}`,
                      '_blank',
                    );
                  }}
                />
              )}
              {!position.end && <EnvironmentFilled className="separator" style={{ opacity: 0.1 }} />}
            </div>
          );
        },
      },
      {
        title: t('SCHEDULE.WORKTIME_SHORT'),
        key: 't.preste',
        // eslint-disable-next-line react/display-name
        render: (_text: string, clockin: IShift) => {
          const worktimeInMinutes = (clockin.worktime && clockin.worktime / 60) || 0;
          return <Space>{minutesToHoursAndOrMinutes(worktimeInMinutes)}</Space>;
        },
      },
      {
        title: t('GLOBAL.BREAKS'),
        key: 'pauses',
        // eslint-disable-next-line react/display-name
        render: (_text: string, clockin: IClockin) => {
          const pauses: string[] = clockin?.pauses.map((pause) => {
            const { start, end } = pause;
            return `${moment.unix(start!).format('HH:mm')} - ${moment.unix(end!).format('HH:mm')}`;
          });
          return <Space>{pauses}</Space>;
        },
      },
      {
        title: t('SCHEDULE.BREAKTIME_SHORT'),
        key: 'totalPauses',
        // eslint-disable-next-line react/display-name
        render: (_text: string, clockin: IClockin) => {
          const totalPauses = Math.floor(clockin.pauses.reduce((a, b) => a + (b.duration || 0), 0) / 60);
          return <Space>{minutesToHoursAndOrMinutes(totalPauses)}</Space>;
        },
      },
      {
        title: t('SCHEDULE.TOTAL_WORKED_SHORT'),
        key: 'totalWorked',
        // eslint-disable-next-line react/display-name
        render: (_text: string, clockin: IClockin) => {
          const effectiveWorktimeInMinutes = (clockin.effectiveWorktime && clockin.effectiveWorktime / 60) || 0;
          return <Space>{minutesToHoursAndOrMinutes(effectiveWorktimeInMinutes)}</Space>;
        },
      },
      {
        title: t('GLOBAL.COST'),
        key: 'cout',
        // eslint-disable-next-line react/display-name
        render: (_text: string, clockin: IClockin) => {
          return (
            <Space>
              {new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: activeDepartment?.currency || 'EUR',
              }).format(clockin.price || 0)}
            </Space>
          );
        },
        sorter: (a: IClockin, b: IClockin) => (a.price || 0) - (b.price || 0),
      },
      {
        key: 'actions',
        displayName: 'actions',
        // eslint-disable-next-line react/display-name
        render: (_text: string, clockin: IClockin) => (
          <TableRowActions clockin={clockin} handleActions={handleActions} />
        ),
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    form.resetFields();
    setIsLoading(true);
    const cancelTokenSource = Axios.CancelToken.source();
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/operations/approve-clockins`, {
      params: {
        departmentId: activeDepartmentId,
        freeshiftApproved: 0,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        const { clockins, users, userCategories: statuses } = response.data as {
          clockins: IClockin[];
          users: IUser[];
          userCategories: IUserStatus[];
        };

        const usersMap = new Map<string, IUser>();
        for (let i = 0; i < users.length; i++) {
          const user = users[i];
          usersMap.set(user.recordId!, user);
        }

        const clockinsWithUser = clockins.map((clockin) => ({
          ...clockin,
          user: usersMap.get(clockin.userRecordId!),
        }));

        const fieldsValue: any = {};
        clockinsWithUser.map((clocking) => {
          const { id, start, end } = clocking;
          fieldsValue[`clocking_${id}_start`] = start;
          fieldsValue[`clocking_${id}_start_time`] = moment.unix(start!).format('HH:mm');
          fieldsValue[`clocking_${id}_end`] = end;
          fieldsValue[`clocking_${id}_end_time`] = moment.unix(end!).format('HH:mm');
        });
        form.setFieldsValue(fieldsValue);
        setClockins(clockinsWithUser);
        setStatuses(statuses);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (clockins) {
      let filteredClockins = [...clockins];
      if (searchTerm) {
        filteredClockins = filteredClockins.filter((clockin: IClockin) => {
          return clockin.user?.displayName?.toLowerCase().includes(searchTerm.toLowerCase());
        });
      }
      if (activeStatus) {
        filteredClockins = filteredClockins?.filter((clockin) => clockin.user?.userType?.id === activeStatus);
      }
      setFilteredClockins(filteredClockins);
    } else {
      setFilteredClockins([]);
    }
  }, [clockins, searchTerm, activeStatus]);

  const changeStartDate = ({
    clockingId,
    hour,
    minute,
  }: {
    clockingId: string;
    hour: number | null;
    minute: number | null;
  }) => {
    let startTimestamp = 0;
    const clocking = clockingsRef.current.find((clocking) => clocking.id === clockingId)!;
    if (isNullOrUndefined(hour)) {
      form.setFieldsValue({
        [`clocking_${clockingId}_start`]: clocking.start,
        [`clocking_${clockingId}_start_time`]: moment.unix(clocking.start!).format('HH:mm'),
      });
    } else {
      startTimestamp = moment
        .unix(clocking.start!)
        .startOf('day')
        .set('hour', hour || 0)
        .set('minute', minute || 0)
        .unix();
      form.setFieldsValue({
        [`clocking_${clockingId}_start`]: startTimestamp,
      });
    }

    const endDate = clocking.end;
    if (endDate) {
      const endMoment = moment.unix(endDate);
      let endTimestamp = moment
        .unix(clocking.start!)
        .startOf('day')
        .set('hour', endMoment.hours())
        .set('minute', endMoment.minutes())
        .unix();
      if (endTimestamp < startTimestamp) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      form.setFieldsValue({
        [`clocking_${clockingId}_end`]: endTimestamp,
      });
    }
  };

  const changeEndDate = ({
    hour,
    minute,
    clockingId,
  }: {
    hour: number | null;
    minute: number | null;
    clockingId: string;
  }) => {
    const clocking = clockingsRef.current.find((clocking) => clocking.id === clockingId)!;
    if (isNullOrUndefined(hour)) {
      form.setFieldsValue({
        [`clocking_${clockingId}_end`]: clocking.end,
        [`clocking_${clockingId}_end_time`]: moment.unix(clocking.end!).format('HH:mm'),
      });
    } else {
      let endTimestamp = moment
        .unix(clocking.start!)
        .startOf('day')
        .set('hour', hour || 0)
        .set('minute', minute || 0)
        .unix();
      const startDate = form.getFieldValue(`clocking_${clockingId}_start`);
      if (startDate && endTimestamp < startDate) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      form.setFieldsValue({
        [`clocking_${clockingId}_end`]: endTimestamp,
      });
    }
  };

  const handleActions = (clockingId: string, freeshiftApproved: number): Promise<void | AxiosResponse<any>> => {
    const start = form.getFieldValue(`clocking_${clockingId}_start`);
    const end = form.getFieldValue(`clocking_${clockingId}_end`);

    return Axios.patch(`${process.env.REACT_APP_API_URL}/v3/operations/approve-clockins/${clockingId}`, {
      freeshiftApproved,
      start,
      end,
      validated: true,
    }).then(() => {
      setClockins(clockingsRef.current.filter((clockin) => clockin.id !== clockingId));
    });
  };

  return (
    <TableView className={className}>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header
        onSearchChange={(e) => setSearchTerm((e.target as HTMLInputElement).value)}
        disabled={isLoading}
        statuses={statuses}
        setActiveStatus={setActiveStatus}
        onlyAnomalies={onlyAnomalies}
        setOnlyAnomalies={setOnlyAnomalies}
      />
      <Form form={form}>
        <Table loading={isLoading} dataSource={filteredClockins} columns={columns} rowKey="id" pagination={false} />
        {/* <VirtualTable
          loading={isLoading}
          dataSource={filteredClockins}
          columns={columns}
          rowKey="id"
          pagination={false}
        /> */}
      </Form>
    </TableView>
  );
};

export default styled(ClockinAnomaliesPage)`
  .ant-table-row {
    height: 70px;

    .column-details {
      max-height: 37px;
      overflow: hidden;
    }
  }
  .time {
    display: flex;
    align-items: center;
    justify-content: center;
    .separator {
      margin: 0 5px;
    }
    .ant-row.ant-form-item {
      margin-bottom: 0px;
      width: 60px;
    }
  }
`;
