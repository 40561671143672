import { AlignType } from '@/types/alignType.model';
import { WIDGETS } from '@/types/widget.model';
import { Table } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Callout from './Callout';

interface Props {
  departmentId?: string;
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'displayName',
    key: 'displayName',
  },
  {
    title: 'Date',
    key: 'date',
    // eslint-disable-next-line react/display-name
    render: (text: any, record: any) => {
      return <span>{moment.unix(record.start).format('L')}</span>;
    },
  },
  {
    title: 'Hours',
    key: 'hours',
    align: 'right' as AlignType,
    // eslint-disable-next-line react/display-name
    render: (text: any, record: any) => {
      return (
        <span>
          {moment.unix(record.start).format('HH:mm')} - {moment.unix(record.end).format('HH:mm')}
        </span>
      );
    },
  },
];

const Dimona: React.FC<Props> = ({ departmentId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [shiftsScanned, setShiftScanned] = useState<number>(0);
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    if (!departmentId) {
      return;
    }

    setIsLoading(true);
    setDataSource([]);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/widgets/${WIDGETS.DIMONA}`, {
      params: {
        departmentId: departmentId,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        if (mounted) {
          setIsLoading(false);
          const { dimonas = [], shiftsScanned = 0 } = response.data;
          setDataSource(dimonas);
          setShiftScanned(shiftsScanned);
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [departmentId]);

  return (
    <div>
      <h4>{t(`DASHBOARD.WIDGETS.DIMONA`)}</h4>
      {!!dataSource.length && (
        <Callout error={true}>{t('GLOBAL.X_ERRORS_FOUND', { count: dataSource.length })}</Callout>
      )}
      {!dataSource.length && (
        <Callout>
          {t('DASHBOARD.WIDGETS.X_SHIFTS_SCANNED', { count: shiftsScanned })}. {t('GLOBAL.NO_ERROR_FOUND')}
        </Callout>
      )}
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        showHeader={false}
        pagination={false}
        rowKey="id"
        size="small"
      />
    </div>
  );
};

export default Dimona;
