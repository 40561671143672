import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  background?: string;
  color?: string;
  name?: string;
  display?: 'inline-block' | 'block';
  size?: 'large' | 'small';
  style?: React.CSSProperties;
}

const Tag: React.FC<Props> = ({
  style,
  display = 'inline-block',
  size = 'small',
  background,
  color,
  name,
  className,
}) => (
  <span
    className={`${className} ${size}`}
    style={{
      ...style,
      background: background ? background : '#333333',
      color: color ? color : '#999999',
      display,
    }}
  >
    {name}
  </span>
);

export default styled(Tag)`
  border-radius: 5px;
  font-size: 11px;
  line-height: 10px;
  padding: 4px 6px;
  display: inline-block;
  margin-right: 2px;
  margin-bottom: 2px;
  margin-top: 3px;

  &.large {
    border-radius: 3px;
    font-weight: bold;
    padding: 5px 10px 6px;
  }
`;
