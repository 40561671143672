import { Tooltip, Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import colors from '@/styles/colors';
import { IDocument } from '@/types/document.model';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
interface Props {
  className?: string;
  document: IDocument;
  onlyPdf?: boolean;
  onWantToSendDocument: (documentId: string) => void;
  onWantToDeleteDocument: (documentId: string) => void;
  onShowDetail: (document: IDocument) => void;
  onDuplicateDocument: (documentId: string) => void;
}

const TableRowActions: React.FC<Props> = ({
  className,
  document,
  onWantToSendDocument,
  onWantToDeleteDocument,
  onShowDetail,
  onDuplicateDocument,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();

  return (
    <div className={className}>
      <div>
        <div className="right">
          <div className="actions">
            <Tooltip title={t('GLOBAL.DUPLICATE')}>
              <Button shape="circle" className="action" onClick={() => onDuplicateDocument(document.id)}>
                <i className="icon-clone" />
              </Button>
            </Tooltip>
            {!document?.sent && (
              <Tooltip title={t('GLOBAL.MODIFY')}>
                <Button
                  shape="circle"
                  className="action"
                  onClick={() => history.push(`/app/documents/documents/${document.id}`)}
                >
                  <i className="icon-edit" />
                </Button>
              </Tooltip>
            )}
            {!!document?.sent && (
              <Tooltip title={t('GLOBAL.DETAILS')}>
                <Button shape="circle" className="action" onClick={() => onShowDetail(document)}>
                  <i className="icon-info-circled" />
                </Button>
              </Tooltip>
            )}
            {!document.sent && (
              <Tooltip title={t('GLOBAL.SEND')}>
                <Button
                  shape="circle"
                  className="action"
                  onClick={() => {
                    onWantToSendDocument(document.id);
                  }}
                >
                  <i className="icon-paper-plane-empty" />
                </Button>
              </Tooltip>
            )}
            <Tooltip title={t('GLOBAL.REMOVE')}>
              <Button
                shape="circle"
                className="action"
                onClick={() => {
                  onWantToDeleteDocument(document.id);
                }}
              >
                <i className="icon-trash-empty" style={{ color: colors.red }} />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableRowActionsStyled = styled(TableRowActions)`
  > div {
    display: inline-block;
    button {
      &:nth-of-type(n + 2) {
        margin-left: 5px;
      }
      &.duplicate {
        background: ${colors.blueLightPastel};
        border-color: ${colors.blueLightPastel};
      }
      &.pdf {
        background: yellowgreen;
        border-color: yellowgreen;
      }
      &.edit {
        background: ${colors.grey};
        border-color: ${colors.grey};
      }
      &.detail {
        background: ${colors.orange};
        border-color: ${colors.orange};
      }
    }
  }
`;
export default TableRowActionsStyled;
