import AppContext from '@/pages/app/context';
import { Button, Checkbox, Modal } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
  onFinish: (values: any, skip?: boolean) => Promise<void>;
}

const ModalLateDimonaDelete: React.FC<Props> = ({ className, visible, setVisible, form, onFinish }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(false);
  }, [visible]);

  return (
    <Modal
      className={`${className} modal-danger`}
      width={750}
      title={t('DIMONA.LATE_DIMONA_DELETE_TITLE')}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setVisible(false);
          }}
        >
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          danger
          disabled={!checked}
          onClick={() => {
            setVisible(false);
            onFinish(form.getFieldsValue(), true);
          }}
        >
          {t('GLOBAL.OK')}
        </Button>,
      ]}
    >
      <div>
        <div dangerouslySetInnerHTML={{ __html: t('DIMONA.LATE_DIMONA_DELETE_CONTENT') }}></div>
        <Checkbox
          style={{ fontWeight: 'bold', fontSize: 14, marginTop: 15 }}
          onChange={(e) => setChecked(e.target.checked)}
        >
          {t('DIMONA.LATE_DIMONA_CHECK')}
        </Checkbox>
      </div>
    </Modal>
  );
};

export default styled(ModalLateDimonaDelete)``;
