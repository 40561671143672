import AuthContext from '@/context';
import { getContrast, minutesToHoursAndOrMinutes } from '@/utils';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InitialStateType } from '../redux/store';

interface Props {
  className?: string;
  price: number;
  durationInMinutes: number;
  pauseDurationInMinutes: number;
  style?: React.CSSProperties;
  currency?: string;
  hidePrice?: boolean;
  clockings?: {
    start?: number;
    end?: number;
    duration?: number;
    pause?: number;
    worktime?: number;
  }[];
  backgroundColor?: string;
}
const ShiftDetailsIcons: React.FC<Props> = ({
  className,
  style,
  price,
  currency = 'EUR',
  durationInMinutes,
  pauseDurationInMinutes,
  hidePrice,
  clockings,
  backgroundColor,
}) => {
  const {
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });
  const { showClocking } = useSelector(({ showClocking }: InitialStateType) => ({
    showClocking,
  }));
  const { state: authState } = useContext(AuthContext);

  return (
    <div className={className} style={style}>
      <span style={{ color: backgroundColor ? getContrast(backgroundColor) : undefined }}>
        <i className="icon-clock" />
        {minutesToHoursAndOrMinutes(durationInMinutes)}
      </span>
      {pauseDurationInMinutes > 0 && (
        <span style={{ color: backgroundColor ? getContrast(backgroundColor) : undefined }}>
          <i className="icon-pause" />
          {minutesToHoursAndOrMinutes(pauseDurationInMinutes)}
        </span>
      )}
      {!hidePrice && price > 0 && (
        <span className="no-print" style={{ color: backgroundColor ? getContrast(backgroundColor) : undefined }}>
          <i className="icon-money" />{' '}
          {new Intl.NumberFormat(language, {
            style: 'currency',
            currency,
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(price)}
        </span>
      )}
      {showClocking && clockings && authState?.userDetails?.params?.access?.clockings && (
        <div>
          {clockings.map((clocking) => {
            const clockingDuration = moment.duration(clocking.end! - clocking.start!, 'seconds');
            clockingDuration.subtract(clocking.pause, 'seconds');
            const durationInMinutes = clockingDuration.asMinutes();
            return (
              <div className="clocking">
                <span style={{ color: backgroundColor ? getContrast(backgroundColor) : undefined }}>
                  <i className="icon-stopwatch" />
                  {moment(clocking.start! * 1000).format('HH:mm')}-
                  {clocking.end ? `${moment(clocking.end! * 1000).format('HH:mm')}` : '__:__'}
                </span>
                {clocking.worktime && (
                  <span style={{ color: backgroundColor ? getContrast(backgroundColor) : undefined }}>
                    <i className="icon-clock" />
                    {minutesToHoursAndOrMinutes(clocking.worktime)}
                  </span>
                )}
                {clocking.pause ? (
                  <span style={{ color: backgroundColor ? getContrast(backgroundColor) : undefined }}>
                    <i className="icon-pause" />
                    {minutesToHoursAndOrMinutes(clocking.pause)}
                  </span>
                ) : null}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const ShiftDetailsIconsStyled = styled(ShiftDetailsIcons)`
  > span {
    margin-right: 4px;
    display: inline-block;
  }

  .clocking {
    color: rgb(96, 205, 255);
    font-weight: bold;
    display: flex;
    gap: 2px;
  }
`;

export default ShiftDetailsIconsStyled;
