import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  start: number;
  end: number;
  size?: 'small' | 'large';
}
const ShiftPeriod: React.FC<Props> = ({ className, start, end, size = 'large' }) => {
  return (
    <p className={`${className} ${size}`}>
      {moment.unix(start).format('H:mm')}-{moment.unix(end).format('H:mm')}
    </p>
  );
};

export default styled(ShiftPeriod)`
  font-weight: 500;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  &.small {
    font-size: 10px !important;
  }
`;
