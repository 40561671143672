import { Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  disabled: boolean;
  filter: string;
  onChange: (value: string) => void;
}

const Header: React.FC<Props> = ({ className, onChange, disabled, filter }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <header className={className}>
      <div className="left">
        <h2 style={{ marginLeft: 0 }}>{t('USERS.PENDING_REGISTRATIONS.TITLE')}</h2>
      </div>
      {/* <div className="right">
        <Select onChange={onChange} disabled={disabled} value={filter} style={{ width: 150 }}>
          <Select.Option value="PENDING">{t('GLOBAL.PENDING')}</Select.Option>
          <Select.Option value="COMPLETED">{t('GLOBAL.COMPLETED')}</Select.Option>
        </Select>
      </div> */}
    </header>
  );
};

export default Header;
