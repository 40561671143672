import React, { useState } from 'react';
import { Dropdown } from 'antd';
import ShiftRightClickMenu from './ShiftRightClickMenu';
import { IShift } from '@/types/shift.model';

interface Props {
  children?: any;
  shift: IShift;
}
const ShiftRightClick: React.FC<Props> = ({ children, shift }) => {
  const [rightClickMenuVisible, setRightClickMenuVisible] = useState<boolean>(false);

  if (shift.locked) {
    return <div>{children}</div>;
  }

  return (
    <Dropdown
      getPopupContainer={(trigger) => {
        return trigger.parentNode?.parentNode?.parentNode as HTMLElement;
      }}
      overlay={() => (
        <ShiftRightClickMenu
          shift={shift}
          close={() => {
            setRightClickMenuVisible(false);
          }}
        />
      )}
      trigger={['contextMenu']}
      visible={rightClickMenuVisible}
      onVisibleChange={(e) => {
        setRightClickMenuVisible(e);
      }}
    >
      {children}
    </Dropdown>
  );
};

export default ShiftRightClick;
