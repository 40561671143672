import { usePusher } from '@/components/PusherProvider';
import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IPendingRegistration } from '@/types/users/pending-registration';
import { BackTop, Button, Table } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Actions from './components/Actions';
import Header from './components/Header';
import ModalUpdateEmail from './components/ModalUpdateEmail';

interface Props {}

const AppTeamPendingPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const pusher = usePusher();

  const [loading, setLoading] = useState<boolean>(false);
  const [pendingUsers, setPendingUsers] = useState<IPendingRegistration[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [filter, setFilter] = useState<string>('PENDING');
  const [modalUpdateEmailVisible, setModalUpdateEmailVisible] = useState<boolean>(false);
  const [activeRegistration, setActiveRegistration] = useState<IPendingRegistration | null>(null);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.USER'),
        key: 'name',
        dataIndex: 'name',
        render: (text: string, record: IPendingRegistration) => {
          return (
            <Button type="link" href={`/app/team/collaborators/${record.record_id}/profile/profile-details`}>
              {record.name}
            </Button>
          );
        },
      },
      {
        title: t('SELF_SERVICE.COLUMNS.REGISTRATION_FORM'),
        key: 'hash',
        dataIndex: 'hash',
        render: (text: string, record: IPendingRegistration) => {
          return (
            <a
              href={`${window.location.origin}/self-service/${record.hash}`}
              target="_blank"
              style={{ color: colors.green }}
            >
              {t('GLOBAL.LINK')}
            </a>
          );
        },
      },
      {
        title: t('GLOBAL.CREATION_DATE'),
        key: 'created_at',
        dataIndex: 'created_at',
        render: (text: string, record: IPendingRegistration) => {
          return `${moment.unix(record.created_at).format('L HH:mm')}`;
        },
      },
      {
        title: t('SELF_SERVICE.COLUMNS.LAST_SENDING_DATE'),
        key: 'updated_at',
        dataIndex: 'updated_at',
        render: (text: string, record: IPendingRegistration) => {
          if (!record.updated_at) return;
          return `${moment.unix(record.updated_at).format('L HH:mm')}`;
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'actions',
        render: (text: string, record: IPendingRegistration) => {
          return (
            <Actions
              pendingUsers={pendingUsers}
              setPendingUsers={setPendingUsers}
              registration={record}
              setActiveRegistration={setActiveRegistration}
              setModalUpdateEmailVisible={setModalUpdateEmailVisible}
              get={get}
            />
          );
        },
      },
    ]);
  }, [pendingUsers]);

  useEffect(() => {
    if (pusher && activeDepartmentId) {
      const channel = pusher?.subscribe(`private-department-${activeDepartmentId}`);
      channel?.bind('refresh_pending_registrations', (message: any) => {
        get();
      });
    }
  }, [pusher, activeDepartmentId]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      get();
    }

    return () => {
      mounted = false;
    };
  }, [filter]);

  const get = () => {
    setLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/pending`, {
        params: {
          departmentId: activeDepartmentId,
          completed: filter === 'COMPLETED' ? 1 : undefined,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const { users } = response.data;
        setPendingUsers(users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const onChange = (value: string) => {
    setFilter(value);
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header onChange={onChange} disabled={loading} filter={filter} />
      <Table
        loading={loading}
        dataSource={pendingUsers}
        columns={columns}
        rowKey="recordId"
        pagination={{ position: ['bottomCenter', 'bottomCenter'], hideOnSinglePage: true }}
      />
      {activeRegistration && (
        <ModalUpdateEmail
          pendingRegistrations={pendingUsers}
          setPendingRegistrations={setPendingUsers}
          activeRegistration={activeRegistration}
          setActiveRegistration={setActiveRegistration}
          visible={modalUpdateEmailVisible}
          setVisible={setModalUpdateEmailVisible}
        />
      )}
    </TableView>
  );
};

export default AppTeamPendingPage;
