import { Dropdown, Button, Divider, Menu, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { IContract } from './ContractsList';
import colors from '@/styles/colors';
import { useTranslation } from 'react-i18next';
import { SendOutlined, FilePdfOutlined, DeleteOutlined } from '@ant-design/icons';
interface Props {
  className?: string;
  userRecordId?: string;
  contract: IContract;
  onDuplicateContract: (contract: IContract) => void;
  onEditContract: (contract: IContract) => void;
  onWantToDeleteContract: (contractId: string) => void;
  onWantToSendContract: (contractId: string, onSendContract: boolean) => void;
}

const TableRowActions: React.FC<Props> = ({
  className,
  userRecordId,
  contract,
  onDuplicateContract,
  onEditContract,
  onWantToDeleteContract,
  onWantToSendContract,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  function handleMenuClick(e: any) {
    switch (e.key) {
      case '1':
        onWantToSendContract(contract.id, false);
        break;
      case '2':
        onWantToSendContract(contract.id, true);
        break;
      default:
        break;
    }
  }

  const sendMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">{t('GLOBAL.RESEND')}</Menu.Item>
      <Menu.Item key="2">{t('GLOBAL.RESEND_FOR_SIGNATURE')}</Menu.Item>
    </Menu>
  );

  return (
    <div className={className}>
      {!userRecordId && (
        <React.Fragment>
          <div>
            <Tooltip title={t('GLOBAL.MODIFY')}>
              <Button shape="circle" className="action" onClick={() => onEditContract(contract)}>
                <i className="icon-edit" />
              </Button>
            </Tooltip>
            <Tooltip title={t('GLOBAL.DUPLICATE')}>
              <Button shape="circle" className="action" onClick={() => onDuplicateContract(contract)}>
                <i className="icon-clone" />
              </Button>
            </Tooltip>
          </div>
        </React.Fragment>
      )}
      <Tooltip title={t('GLOBAL.DOWNLOAD')}>
        <Button shape="circle" className="action" onClick={() => window.open(contract.uri, '_blank')}>
          <i className="icon-import" />
        </Button>
      </Tooltip>
      {!userRecordId && (
        <Button shape="circle" className="action">
          <Dropdown overlay={sendMenu}>
            <i className="icon-paper-plane-empty" />
          </Dropdown>
        </Button>
      )}

      {!userRecordId ? (
        <React.Fragment>
          <Tooltip title={t('GLOBAL.REMOVE')}>
            <Button
              shape="circle"
              className="action"
              onClick={() => {
                onWantToDeleteContract(contract.id);
              }}
            >
              <i className="icon-trash-empty" style={{ color: colors.red }} />
            </Button>
          </Tooltip>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Divider type="vertical" />
          <div>
            <Button size="small" type="primary" className="edit" onClick={() => onEditContract(contract)}>
              {t('GLOBAL.SEE')}
            </Button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const TableRowActionsStyled = styled(TableRowActions)`
  > div {
    display: inline-block;
    button {
      &:nth-of-type(n + 2) {
        margin-left: 5px;
      }
      &.duplicate {
        background: ${colors.blueLightPastel};
        border-color: ${colors.blueLightPastel};
      }
      &.pdf {
        background: yellowgreen;
        border-color: yellowgreen;
      }
      &.edit {
        background: ${colors.orange};
        border-color: ${colors.orange};
      }
    }
  }
`;
export default TableRowActionsStyled;
