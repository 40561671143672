import AuthContext from '@/context';
import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ModalSwitchToProAccount from './ModalSwitchToProAccount';

interface Props {
  className?: string;
  department?: IDepartment;
}

const PaymentRiskStrip: React.FC<Props> = ({ className, department }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [hover, setHover] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalSwitchProVisible, setModalSwitchProVisible] = useState<boolean>(false);
  const {
    state: { userDetails },
  } = useContext(AuthContext);
  const history = useHistory();

  const onChangePayementMethod = () => {
    if (department?.accountType == 'FREE-CLOCKING') {
      setModalSwitchProVisible(true);
    } else {
      history.push('/app/settings/billing/payment-details');
    }
  };

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  const renderBtn = () => {
    return (
      <>
        <Button
          loading={loading}
          type="link"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onChangePayementMethod}
          style={{
            color: hover
              ? '#fff'
              : department?.accountType == 'ONBOARDING FINISHED'
              ? '#00A651'
              : department?.accountType == 'FREE-CLOCKING'
              ? colors.green
              : 'black',
          }}
        >
          {t(
            department?.accountType == 'ONBOARDING FINISHED'
              ? 'ACCOUNT.MODAL_ONBOARDING_FINISHED.BUTTON'
              : 'ACCOUNT.MODAL_BLOCKED_ACCOUNT.BUTTON',
          )}
        </Button>
      </>
    );
  };

  const is_account_holder = department?.accountEmail == userDetails?.email;
  const hidden = department?.accountType
    ? ['ONBOARDING', 'ONBOARDING FINISHED', 'FREE-CLOCKING'].includes(department?.accountType) ||
      department.paymentRisk == false
    : true;

  if (hidden) return null;

  return (
    <div className={className}>
      <span style={{ fontSize: 14 }}>
        {department?.accountType == 'ONBOARDING'
          ? t('PAYMENT_RISK.ONBOARDING_TEXT')
          : department?.accountType == 'ONBOARDING FINISHED'
          ? t('PAYMENT_RISK.ONBOARDING_FINISHED_TEXT')
          : department?.accountType == 'FREE-CLOCKING'
          ? t('PAYMENT_RISK.FREEMIUM_TEXT')
          : t('PAYMENT_RISK.TEXT')}
      </span>
      {department?.accountType !== 'ONBOARDING' && department?.accountType != 'FREE-CLOCKING' && renderBtn()}
      <ModalSwitchToProAccount visible={modalSwitchProVisible} setVisible={setModalSwitchProVisible} />
    </div>
  );
};

export default styled(PaymentRiskStrip)`
  z-index: 9999999999999;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: ${({ department }) =>
    department?.accountType == 'ONBOARDING'
      ? '#00A651'
      : department?.accountType == 'ONBOARDING FINISHED'
      ? '#000'
      : department?.accountType == 'FREE-CLOCKING'
      ? '#000'
      : colors.red};
  color: white;
  font-weight: bold;
  font-size: 11px;
  padding: 5px 15px;
  border-top-right-radius: 6px;

  a {
    margin-left: 30px;
    color: ${colors.green};
    text-decoration: underline;
  }
`;
