import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { handleError } from '@/utils';
import { InboxOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { RcFile } from 'antd/es/upload/interface';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  modalImportTurnoverFileVisible: boolean;
  setModalImportTurnoverFileVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalImportTurnoverFile: React.FC<Props> = ({
  className,
  modalImportTurnoverFileVisible,
  setModalImportTurnoverFileVisible,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, turnoverTemplates },
    dispatch,
  } = useContext(AppContext);
  const [form] = useForm();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<RcFile | null>(null);

  const onCancel = () => {
    setModalImportTurnoverFileVisible(false);
  };

  const onFinish = (values: any) => {
    const formData = new FormData();

    formData.append('name', values.name);
    if (file) {
      formData.append('file', file);
    }

    axios({
      url: `${process.env.REACT_APP_API_URL_V4}/turnover-quota-templates`,
      method: 'POST',
      data: formData,
      params: {
        departmentId: activeDepartmentId,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        dispatch({
          type: 'SET_TURNOVER_TEMPLATES',
          payload: [...turnoverTemplates, ...data.data],
        });
        setModalImportTurnoverFileVisible(false);
        setFile(null);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const beforeUpload = (file: RcFile) => {
    setFile(file);
    return false;
  };

  const onRemoveFile = () => {
    setFile(null);
  };

  const onDownloadExampleFile = () => {
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_APP_URL}/turnover-templates-example.xlsx`;
    link.download = 'turnover-templates-example.xlsx';
    link.click();
  };

  return (
    <Modal
      className={className}
      visible={modalImportTurnoverFileVisible}
      onCancel={onCancel}
      maskClosable
      title={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.IMPORT_FROM_FILE')}
      okText={t('GLOBAL.CREATE')}
      cancelButtonProps={{ danger: true }}
      onOk={() => form.submit()}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="name"
          label={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_NAME')}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_NAME')} size="large" allowClear />
        </Form.Item>
        <Form.Item name="file" label={t('GLOBAL.FILE')} rules={[{ required: true }]}>
          <Upload.Dragger
            beforeUpload={beforeUpload}
            maxCount={1}
            multiple={false}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onRemove={onRemoveFile}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('UPLOAD.DRAGGER.TEXT')}</p>
          </Upload.Dragger>
        </Form.Item>
        <Button
          block
          type="primary"
          size="large"
          onClick={onDownloadExampleFile}
          style={{ backgroundColor: colors.blueLightPastel, borderColor: colors.blueLightPastel }}
        >
          {t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.DOWNLOAD_EXAMPLE_FILE')}
        </Button>
      </Form>
    </Modal>
  );
};

export default styled(ModalImportTurnoverFile)``;
