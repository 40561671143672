import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Chartjs from 'chart.js';
import { WIDGETS } from '@/types/widget.model';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  departmentId?: string;
  sectionId?: string;
}

const Turnover: React.FC<Props> = ({ departmentId, sectionId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState<Chartjs | null>(null);
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    if (!departmentId) {
      return;
    }

    setIsLoading(true);
    if (chartInstance) {
      chartInstance.destroy();
    }
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/widgets/${WIDGETS.TURNOVER}`, {
      params: {
        departmentId,
        sectionId,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        if (mounted) {
          setIsLoading(false);
          const { current, previous } = response.data;
          const newChartInstance = new Chartjs(chartContainer.current!, {
            type: 'line',
            data: {
              labels: Array.from({ length: Object.keys(current).length }, (_, i) => i + 1),
              datasets: [
                {
                  label: 'Current',
                  data: Object.values(current) as number[],
                  backgroundColor: 'rgba(255,0,0,0.3)',
                  fill: 'start',
                },
                {
                  label: 'Previous',
                  data: Object.values(previous) as number[],
                  backgroundColor: 'rgba(0,0,255,0.3)',
                  fill: 'start',
                },
              ],
            },
            options: {
              elements: {
                line: {
                  tension: 0.0000001,
                },
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            },
          });
          setChartInstance(newChartInstance);
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [departmentId, sectionId]);

  return (
    <>
      {isLoading ? (
        <Spin spinning={isLoading} />
      ) : (
        <>
          <h4>{t('DASHBOARD.WIDGETS.TURNOVER')}</h4>
          <div className="chart-container" style={{ position: 'relative' }}>
            <canvas ref={chartContainer} />
          </div>
        </>
      )}
    </>
  );
};

export default Turnover;
