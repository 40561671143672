import colors from '@/styles/colors';
import { getWindowSize } from '@/utils';
import { Form, Input, List, message } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormActions from '../../components/FormActions';
import AppContext from '@/pages/app/context';
import SettingsForm from '../../components/SettingsForm';
import { ISettingsListElement } from '@/types/settings/list-element.model';

interface Props {}

const ChangePassword: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onFinish = (values: any) => {
    const { password1: password } = values;
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/operations/change-password`,
        {
          password,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(() => {
        setLoading(false);
        message.success(t('SETTINGS.INFORMATION.PASSWORD.MESSAGE_SAVING_SUCCESS'));
      })
      .catch((error) => {
        message.error(t('SETTINGS.INFORMATION.PASSWORD.MESSAGE_SAVING_ERROR'));
        setLoading(false);
      });
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];

  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.INFORMATION.PASSWORD.NEW_PASSWORD.LABEL'),
          description: t('SETTINGS.INFORMATION.PASSWORD.NEW_PASSWORD.DESCRIPTION'),
          name: 'password1',
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          rules: [
            { required: true, message: 'Please input your password!' },
            {
              pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
              message: t('SETTINGS.INFORMATION.PASSWORD.FORM.PASSWORD_TOOLTIP'),
            },
          ],
          element: (
            <Input
              prefix={<i className="icon-lock"></i>}
              type="password"
              placeholder={t('SETTINGS.INFORMATION.PASSWORD.NEW_PASSWORD.LABEL')}
            />
          ),
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
        {
          label: t('SETTINGS.INFORMATION.PASSWORD.NEW_PASSWORD_CONFIRMATION.LABEL'),
          description: t('SETTINGS.INFORMATION.PASSWORD.NEW_PASSWORD_CONFIRMATION.DESCRIPTION'),
          name: 'password2',
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          rules: [
            {
              required: true,
              validator: (_rule: any, value: any) => {
                const password1 = form.getFieldValue('password1');
                if (password1 === value) {
                  return Promise.resolve(value);
                } else {
                  return Promise.reject('error');
                }
              },
              validateTrigger: 'onBlur',
              message: 'The passwords are not the same!',
            },
          ],
          element: (
            <Input
              prefix={<i className="icon-lock"></i>}
              type="password"
              placeholder={t('SETTINGS.INFORMATION.PASSWORD.NEW_PASSWORD_CONFIRMATION.LABEL')}
            />
          ),
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
      ],
    ],
  ];

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
  };

  return (
    <div>
      <h2>{t('SETTINGS.INFORMATION.PASSWORD.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default ChangePassword;
