import colors from '@/styles/colors';
import { IClockingTask } from '@/types/clocking.model';
import { getWindowSize } from '@/utils';
import { Button, Checkbox, Divider, Drawer, Form, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
moment.tz.setDefault('Atlantic/Reykjavik');

interface IFlowQuestions {
  name: string;
  details: IFlowQuestionDetail[];
}

interface IFlowQuestionDetail {
  label: string;
  value: string | boolean;
  format: string;
}

interface Props {
  className?: string;
  departmentId?: string;
  task: IClockingTask | null;
  visible: boolean;
  onClose: () => void;
}

const DrawerTaskDetail: React.FC<Props> = ({ className, departmentId, visible, task, onClose }) => {
  const [loadingFlow, setLoadingFlow] = useState<boolean>(false);
  const [flowQuestions, setFlowQuestions] = useState<IFlowQuestions[] | null>(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setLoadingFlow(true);
    setFlowQuestions(null);
    if (task) {
      const { flow, id } = task;
      if (flow) {
        const cancelTokenSource = Axios.CancelToken.source();
        Axios.get(`${process.env.REACT_APP_API_URL}/v3/tasks/flow/${id}`, {
          params: {
            departmentId,
          },
        })
          .then((response) => {
            setFlowQuestions(response.data?.flows);
            setLoadingFlow(false);
          })
          .catch((error) => {
            setLoadingFlow(false);
            console.log(error);
          });
        return () => {
          cancelTokenSource.cancel();
        };
      }
    }
  }, [task]);

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
      visible={visible}
      title="Détail de la tâche"
      onClose={onClose}
    >
      {!!task && (
        <h2>
          <span>{task?.task?.name}</span>
          <span>
            {moment.unix(task!.start!).format('HH:mm')}
            {' - '}
            {moment.unix(task!.end!).format('HH:mm')}
          </span>
        </h2>
      )}
      <Spin spinning={loadingFlow}>
        {flowQuestions?.map((flow, index) => {
          const { name, details } = flow;
          return (
            <div key={`flow_${index}`} className="flow-questions">
              <Divider orientation="left" plain>
                {name}
              </Divider>
              {details?.map((detail) => {
                const { format, label, value } = detail;
                switch (format) {
                  case 'text': {
                    return (
                      <div className="flow-question">
                        <div>{label}</div>
                        <div>{value}</div>
                      </div>
                    );
                  }
                  case 'checkbox': {
                    return (
                      <div className="flow-question">
                        <div>{label}</div>
                        <div>
                          <Checkbox checked={value as boolean} disabled={true} />
                        </div>
                      </div>
                    );
                  }
                  case 'textarea': {
                    return (
                      <div>
                        <div>
                          <Form.Item label={label} className="flow-question-textarea">
                            <TextArea value={value as string} rows={3} disabled={true} />
                          </Form.Item>
                        </div>
                      </div>
                    );
                  }
                  default: {
                    return null;
                  }
                }
              })}
            </div>
          );
        })}
      </Spin>
      <div className="actions-container">
        <div className="actions">
          <Button
            size="large"
            type="primary"
            danger
            onClick={() => {
              onClose();
            }}
          >
            Fermer
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default styled(DrawerTaskDetail)`
  z-index: 9999999;
  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: ${colors.green};
    font-weight: 800;
    text-transform: uppercase;
  }

  .flow-questions {
    color: ${colors.grey};
    .flow-question {
      display: flex;
      padding: 7px 12px;
      &:nth-of-type(odd) {
        background-color: #f9f9f9;
      }
      > div:first-child {
        width: 220px;
      }
    }
    .flow-question-textarea {
      flex: 1;
      flex-direction: column;
      > div {
        justify-content: flex-start;
        display: flex;
        flex: 1;
        width: 100%;
        &.ant-form-item-label > label {
          color: ${colors.grey};
        }
      }
    }
  }
`;
