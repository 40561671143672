import { message, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { IUser } from '@/types/user.model';
import axios from 'axios';

moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  user?: IUser;
  departmentId?: string;
}

interface IClocking {
  id: string;
  recordId: string;
  start: number;
  end?: number;
}

const Clockings: React.FC<Props> = ({ user, departmentId }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [clockings, setClockings] = useState<IClocking[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
      },
      {
        title: t('GLOBAL.START'),
        key: 'start',
        render: (text: string, record: IClocking) => {
          return moment.unix(record.start!).format('L - HH:mm');
        },
      },
      {
        title: t('GLOBAL.END'),
        key: 'end',
        render: (text: string, record: IClocking) => {
          if (record.end) {
            return moment.unix(record.end!).format('L - HH:mm');
          }
        },
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    setLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/clockings/user/${user?.recordId}`, {
        params: {
          departmentId,
          type: 'all',
          limit: 10,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        setClockings(response.data.clockings);
        setLoading(false);
      })
      .catch(() => {
        message.error(t('CLOCKINGS.MESSAGE_LOADING_ERROR'));
        setLoading(false);
      });
    return () => {
      cancelTokenSource.cancel();
    };
  }, [user, departmentId]);

  return <Table dataSource={clockings} columns={columns} rowKey="id" loading={loading} pagination={false} />;
};

export default Clockings;
