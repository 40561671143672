import { IDepartment } from '@/types/department.model';
import { FEATURES, IFeature } from '@/types/features.model';
import { IHeaderBadges } from '@/types/header-badges.model';
import { IHrPartner } from '@/types/hr-partner.model';
import {
  GROUP_ADMIN_ALLOWED_ACCOUNTS,
  isAdmin,
  isAdminOrHr,
  isAdminOrHrOrPlanning,
  isClockingAccount,
  isCountry,
  isEmptyAccount,
  isFeatureEnabled,
  isFree,
} from '@/utils';
import moment from 'moment';
import { TFunction } from 'react-i18next';

export type Command = {
  label: string;
  icon?: string;
  extra?: string;
  description?: string | null;
  keywords: string[];
  link: string;
  visible?: boolean;
};

export const getCommandsList = (
  t: TFunction<string>,
  department: IDepartment,
  features: IFeature[],
  badges: IHeaderBadges,
  hrPartners: IHrPartner[],
): Command[] => {
  return [
    // DASHBOARD
    {
      label: t('GLOBAL.DASHBOARD'),
      icon: 'layers',
      keywords: [],
      link: '/app/dashboard',
    },

    // SCHEDULE MENU
    {
      label: t('MENU.SCHEDULE.SCHEDULE.SCHEDULE_MANAGEMENT'),
      icon: 'calendar-alt',
      keywords: [],
      link: '/app/hours/manage',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department?.access?.shifts,
    },
    {
      label: 'Group admin',
      icon: 'calendar-alt',
      keywords: [],
      link: '/app/hours/group-admin',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department?.access?.shifts &&
        department.role &&
        isAdmin(department.role) &&
        GROUP_ADMIN_ALLOWED_ACCOUNTS.includes(department.accountId!),
    },
    {
      label: t('SCHEDULE.CHANGE_REQUESTS.TITLE'),
      icon: 'calendar-alt',
      keywords: [],
      link: '/app/hours/shift-modification-requests',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) && department?.access?.shifts && department?.access?.requests,
    },
    {
      label: 'Shifts Market',
      icon: 'calendar-alt',
      keywords: [],
      link: '/app/hours/shyfts-market',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department?.access?.shifts &&
        isFeatureEnabled(features, FEATURES.FREESHIFTS) &&
        department?.scheduleParams?.shyftsMarket,
    },
    {
      label: t('SCHEDULE.SHIFT_APPROVALS.TITLE'),
      icon: 'calendar-alt',
      keywords: [],
      link: '/app/hours/shift-approvals',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department?.access?.shifts &&
        department?.scheduleParams?.validateShifts,
    },
    {
      label: 'Tasks forecasts',
      icon: 'calendar-alt',
      keywords: [],
      link: '/app/hours/tasks-forecasts',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department?.access?.shifts && department?.id === '1445',
    },
    {
      label: t('VACATION_REQUESTS.TITLE'),
      icon: 'calendar-alt',
      keywords: [],
      link: '/app/hours/vacation-requests',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department?.access?.shifts &&
        isFeatureEnabled(features, FEATURES.LEAVE_MANAGEMENT),
    },
    {
      label: t('SWAP_SHIFT.TITLE'),
      icon: 'calendar-alt',
      keywords: [],
      link: '/app/hours/swap-shift',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department?.access?.shifts &&
        department?.scheduleParams?.swap_shift_enabled,
    },

    // CLOCKINGS MENU
    {
      label: t('MENU.CLOCKINGS.MANAGE'),
      icon: 'stopwatch',
      keywords: [],
      link: `/app/clockings/${moment().startOf('week').format('YYYY-MM-DD')}`,
      visible: isFeatureEnabled(features, FEATURES.CLOCKING) && department?.access?.clockings,
    },
    {
      label: t('MENU.CLOCKINGS.VALIDATION'),
      icon: 'stopwatch',
      keywords: [],
      link: '/app/clockings/clocking-validation',
      visible: !department?.clockinParams?.approveFreeclockin && isFeatureEnabled(features, FEATURES.SCHEDULE),
    },

    // TEAM MENU
    {
      label: t('GLOBAL.USERS'),
      icon: 'user',
      keywords: [],
      link: '/app/team/collaborators',
      visible: department.role && isAdminOrHrOrPlanning(department.role),
    },
    {
      label: t('USERS.USER_VALIDATION.TITLE'),
      icon: 'user',
      keywords: [],
      link: '/app/team/pending-collaborators',
      visible: department.role && isAdminOrHrOrPlanning(department.role) && department?.userSubscription,
    },
    {
      label: t('USERS.PENDING_REGISTRATIONS.TITLE'),
      icon: 'user',
      keywords: [],
      link: '/app/team/pending-registrations',
      visible:
        department.role &&
        isAdminOrHrOrPlanning(department.role) &&
        !!(badges.users?.registrations && badges.users?.registrations > 0),
    },
    {
      label: t('GLOBAL.MESSAGES'),
      icon: 'user',
      keywords: [],
      link: '/app/hours/messages',
      visible: department.role && isAdminOrHrOrPlanning(department.role) && department?.accountType !== 'FREE-TRIAL',
    },
    {
      label: t('FREE_SHIFT_NOTIFICATIONS.TITLE'),
      icon: 'user',
      keywords: [],
      link: '/app/hours/shift-notifications',
      visible:
        department.role &&
        isAdminOrHrOrPlanning(department.role) &&
        department?.accountType !== 'FREE-TRIAL' &&
        isFeatureEnabled(features, FEATURES.FREESHIFTS),
    },

    // REPORTS MENU
    {
      label: t('REPORTS.CONTROL_OF_SERVICES.TITLE'),
      icon: 'chart-bar',
      keywords: [],
      link: '/app/reports/timesheets',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) ||
        (isFeatureEnabled(features, FEATURES.CLOCKING) && !isFree(department)),
    },
    {
      label: t('REPORTS.PAY_PERIODS.TITLE'),
      icon: 'chart-bar',
      keywords: [],
      link: '/app/reports/pay-periods',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) ||
        (isFeatureEnabled(features, FEATURES.CLOCKING) && !isFree(department)),
    },
    {
      label: t('REPORTS.ACTIVITY_REPORT.TITLE'),
      icon: 'chart-bar',
      keywords: [],
      link: '/app/reports/activity-reports',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) ||
        (isFeatureEnabled(features, FEATURES.CLOCKING) && !isFree(department)),
    },
    {
      label: t('REPORTS.LEAVE_REPORTS.TITLE'),
      icon: 'chart-bar',
      keywords: [],
      link: '/app/reports/leave-reports',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) ||
        (isFeatureEnabled(features, FEATURES.CLOCKING) && !isFree(department)),
    },
    {
      label: t('REPORTS.HR_TIMESHEETS.TITLE'),
      icon: 'chart-bar',
      keywords: [],
      link: '/app/reports/hr-sheets',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) ||
        (isFeatureEnabled(features, FEATURES.CLOCKING) && !isFree(department)),
    },
    {
      label: t('REPORTS.EXPORT_HR_TIMESHEETS.TITLE'),
      icon: 'chart-bar',
      keywords: [],
      link: '/app/reports/hr-sheet-exports',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) ||
        (isFeatureEnabled(features, FEATURES.CLOCKING) && !isFree(department)),
    },
    {
      label: t('REPORTS.SIGNUP_SHEETS.TITLE'),
      icon: 'chart-bar',
      keywords: [],
      link: '/app/reports/service-sheets',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) ||
        (isFeatureEnabled(features, FEATURES.CLOCKING) && !isFree(department)),
    },

    // DOCUMENTS MENU
    {
      label: t('DOCUMENTS.CONTRACTS.TITLE'),
      icon: 'doc-text-inv',
      keywords: [],
      link: '/app/documents/contracts',
      visible: isFeatureEnabled(features, FEATURES.CONTRACTS) && department.access?.documents,
    },
    {
      label: t('CONTRACTS.NEW_CONTRACT'),
      icon: 'doc-text-inv',
      keywords: [],
      link: '/app/documents/contracts/new',
      visible: isFeatureEnabled(features, FEATURES.CONTRACTS) && department.access?.documents,
    },
    {
      label: t('CONTRACTS.TEMPLATES.TITLE'),
      icon: 'doc-text-inv',
      keywords: [],
      link: '/app/documents/contracts/templates',
      visible: isFeatureEnabled(features, FEATURES.CONTRACTS) && department.access?.documents,
    },
    {
      label: t('DOCUMENTS.SIGNATURES.TITLE'),
      icon: 'doc-text-inv',
      keywords: [],
      link: '/app/documents/contracts/signatures',
      visible: isFeatureEnabled(features, FEATURES.CONTRACTS) && department.access?.documents,
    },
    {
      label: t('DOCUMENTS.DOCUMENTS.TITLE'),
      icon: 'doc-text-inv',
      keywords: [],
      link: '/app/documents/documents',
      visible: isFeatureEnabled(features, FEATURES.CONTRACTS) && department.access?.documents,
    },
    {
      label: t('DOCUMENTS.DOCUMENTS.DOCUMENT.NEW_DOCUMENT'),
      icon: 'doc-text-inv',
      keywords: [],
      link: '/app/documents/documents/new',
      visible: isFeatureEnabled(features, FEATURES.CONTRACTS) && department.access?.documents,
    },
    {
      label: t('DOCUMENTS.TEMPLATES.TITLE'),
      icon: 'doc-text-inv',
      keywords: [],
      link: '/app/documents/documents/templates',
      visible: isFeatureEnabled(features, FEATURES.CONTRACTS) && department.access?.documents,
    },
    {
      label: t('DIMONA.TITLE'),
      icon: 'doc-text-inv',
      keywords: [],
      link: '/app/documents/dimona/statement',
      visible: isFeatureEnabled(features, FEATURES.DIMONA) && department?.params?.dimona && department?.access?.dimona,
    },
    {
      label: t('DIMONA.AUDIT.TITLE'),
      icon: 'doc-text-inv',
      keywords: [],
      link: '/app/documents/dimona/audit',
      visible: isFeatureEnabled(features, FEATURES.DIMONA) && department?.params?.dimona && department?.access?.dimona,
    },

    // SETTINGS

    // INFORMATION
    {
      label: t('SETTINGS.MENU.INFORMATIONS.PROFILE'),
      icon: 'info-circled',
      keywords: [],
      link: '/app/settings/information/profile',
    },
    {
      label: t('SETTINGS.MENU.INFORMATIONS.CHANGE_PASSWORD'),
      icon: 'info-circled',
      keywords: [],
      link: '/app/settings/information/change-password',
    },

    // INFORMATION - PROFILE
    {
      label: t('SETTINGS.INFORMATION.PROFILE.FIRST_NAME.LABEL'),
      description: t('SETTINGS.INFORMATION.PROFILE.FIRST_NAME.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/information/profile',
    },
    {
      label: t('SETTINGS.INFORMATION.PROFILE.LAST_NAME.LABEL'),
      description: t('SETTINGS.INFORMATION.PROFILE.LAST_NAME.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/information/profile',
    },
    {
      label: t('SETTINGS.INFORMATION.PROFILE.EMAIL.LABEL'),
      description: t('SETTINGS.INFORMATION.PROFILE.EMAIL.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/information/profile',
    },
    {
      label: t('SETTINGS.INFORMATION.PROFILE.PHONE.LABEL'),
      description: t('SETTINGS.INFORMATION.PROFILE.PHONE.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/information/profile',
    },
    {
      label: t('SETTINGS.INFORMATION.PROFILE.LANGUAGE.LABEL'),
      description: t('SETTINGS.INFORMATION.PROFILE.LANGUAGE.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/information/profile',
    },
    {
      label: t('SETTINGS.INFORMATION.PROFILE.PROFILE_PICTURE.LABEL'),
      description: t('SETTINGS.INFORMATION.PROFILE.PROFILE_PICTURE.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/information/profile',
    },

    // INFORMATION - CHANGE PASSWORD
    {
      label: t('SETTINGS.INFORMATION.PASSWORD.NEW_PASSWORD.LABEL'),
      description: t('SETTINGS.INFORMATION.PASSWORD.NEW_PASSWORD.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/information/change-password',
    },
    {
      label: t('SETTINGS.INFORMATION.PASSWORD.NEW_PASSWORD_CONFIRMATION.LABEL'),
      description: t('SETTINGS.INFORMATION.PASSWORD.NEW_PASSWORD_CONFIRMATION.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/information/change-password',
    },

    // GENERAL
    {
      label: t('SETTINGS.MENU.GENERAL.USER_STATUS'),
      icon: 'cog-alt',
      keywords: [],
      link: '/app/settings/general/user-status',
      visible: !isFree(department),
    },
    {
      label: t('SETTINGS.MENU.GENERAL.CUSTOM_FIELDS'),
      icon: 'cog-alt',
      keywords: [],
      link: '/app/settings/general/custom-fields',
    },
    {
      label: t('SETTINGS.MENU.GENERAL.NOTIFICATIONS'),
      icon: 'cog-alt',
      keywords: [],
      link: '/app/settings/general/notifications',
      visible: isAdmin(department.role!) && !isFree(department),
    },
    {
      label: t('SETTINGS.MENU.GENERAL.DEACTIVATED_USERS'),
      icon: 'cog-alt',
      keywords: [],
      link: '/app/settings/general/deactivated-users',
    },
    {
      label: t('SETTINGS.MENU.GENERAL.CUSTOM_REPORTS'),
      icon: 'cog-alt',
      keywords: [],
      link: '/app/settings/general/custom-reports',
      visible:
        !isFree(department) &&
        (isFeatureEnabled(features, FEATURES.SCHEDULE) ||
          (isFeatureEnabled(features, FEATURES.CLOCKING) && isAdmin(department.role!))),
    },
    {
      label: t('SETTINGS.MENU.GENERAL.REGISTRATION_FORM'),
      icon: 'cog-alt',
      keywords: [],
      link: '/app/settings/general/registration-forms',
      visible: !isFree(department),
    },

    // SECTIONS
    {
      label: t('SETTINGS.MENU.SECTIONS.MANAGE_SECTIONS'),
      icon: 'sections',
      keywords: [],
      link: '/app/settings/sections/manage',
      visible: isFeatureEnabled(features, FEATURES.SECTIONS),
    },

    // SECTIONS - SETTINGS
    {
      label: t('SETTINGS.SECTIONS.SETTINGS.FORCE_SECTION.LABEL'),
      description: t('SETTINGS.SECTIONS.SETTINGS.FORCE_SECTION.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/sections/settings',
      visible:
        isFeatureEnabled(features, FEATURES.SECTIONS) &&
        isAdmin(department.role!) &&
        isFeatureEnabled(features, FEATURES.SCHEDULE),
    },
    {
      label: t('SETTINGS.SECTIONS.SETTINGS.DISPLAY_OTHER_SECTIONS_SHIFTS.LABEL'),
      description: t('SETTINGS.SECTIONS.SETTINGS.DISPLAY_OTHER_SECTIONS_SHIFTS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/sections/settings',
      visible:
        isFeatureEnabled(features, FEATURES.SECTIONS) &&
        isAdmin(department.role!) &&
        isFeatureEnabled(features, FEATURES.SCHEDULE),
    },
    {
      label: t('SETTINGS.SECTIONS.SETTINGS.MONTH_VIEW_PER_SECTION.LABEL'),
      description: t('SETTINGS.SECTIONS.SETTINGS.MONTH_VIEW_PER_SECTION.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/sections/settings',
      visible:
        isFeatureEnabled(features, FEATURES.SECTIONS) &&
        isAdmin(department.role!) &&
        isFeatureEnabled(features, FEATURES.SCHEDULE),
    },

    // SKILLS
    {
      label: t('SETTINGS.MENU.SKILLS.TITLE'),
      icon: 'skills',
      keywords: [],
      link: '/app/settings/skills/manage',
      visible: isFeatureEnabled(features, FEATURES.SKILLS),
    },

    // TASKS
    {
      label: t('SETTINGS.MENU.TASKS.TITLE'),
      icon: 'tasks',
      keywords: [],
      link: '/app/settings/skills/manage',
      visible: isFeatureEnabled(features, FEATURES.TASKS),
    },

    // ATTRIBUTES
    {
      label: t('SETTINGS.MENU.ATTRIBUTES.TITLE'),
      icon: 'attributes',
      keywords: [],
      link: '/app/settings/attributes/manage',
      visible: isFeatureEnabled(features, FEATURES.ATTRIBUTES),
    },

    // ATTRIBUTES - FORMS
    {
      label: t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.ACTIVATE_FORM.LABEL'),
      description: t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.ACTIVATE_FORM.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/attributes/registration-forms',
      visible: isFeatureEnabled(features, FEATURES.ATTRIBUTES),
    },
    {
      label: t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.FORM_TITLE.LABEL'),
      description: t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.FORM_TITLE.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/attributes/registration-forms',
      visible: isFeatureEnabled(features, FEATURES.ATTRIBUTES),
    },
    {
      label: t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.FORM_DESCRIPTION.LABEL'),
      description: t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.FORM_DESCRIPTION.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/attributes/registration-forms',
      visible: isFeatureEnabled(features, FEATURES.ATTRIBUTES),
    },

    // ATTRIBUTES - PENDING ATTRIBUTES
    {
      label: t('SETTINGS.MENU.ATTRIBUTES.PENDING_ATTRIBUTES'),
      icon: 'attributes',
      keywords: [],
      link: '/app/settings/attributes/pending-attributes',
      visible: isFeatureEnabled(features, FEATURES.ATTRIBUTES),
    },

    // SCHEDULES - SCHEDULE TEMPLATES
    {
      label: t('SETTINGS.MENU.SCHEDULE.SCHEDULE_TEMPLATES'),
      icon: 'calendar-alt',
      keywords: [],
      link: '/app/settings/schedule/schedule-templates',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts,
    },

    // SCHEDULES - SHORTCUTS
    {
      label: t('SETTINGS.MENU.SCHEDULE.SHORTCUTS'),
      icon: 'calendar-alt',
      keywords: [],
      link: '/app/settings/schedule/shortucts',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts,
    },

    // SCHEDULES - SHIFT SWAP
    {
      label: t('SETTINGS.SCHEDULES.SHIFT_SWAP.ACTIVATE_SHIFT_SWAP.LABEL'),
      description: t('SETTINGS.SCHEDULES.SHIFT_SWAP.ACTIVATE_SHIFT_SWAP.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/shift-swap',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SHIFT_SWAP.MIN_DAYS.LABEL'),
      description: t('SETTINGS.SCHEDULES.SHIFT_SWAP.MIN_DAYS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/shift-swap',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.access?.shifts &&
        isAdmin(department.role!) &&
        department.scheduleParams?.swap_shift_enabled,
    },
    {
      label: t('SETTINGS.SCHEDULES.SHIFT_SWAP.AUTO_APPROVE.LABEL'),
      description: t('SETTINGS.SCHEDULES.SHIFT_SWAP.AUTO_APPROVE.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/shift-swap',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.access?.shifts &&
        isAdmin(department.role!) &&
        department.scheduleParams?.swap_shift_enabled,
    },
    {
      label: t('SETTINGS.SCHEDULES.SHIFT_SWAP.NOTIFY_NEW_REQUEST.LABEL'),
      description: t('SETTINGS.SCHEDULES.SHIFT_SWAP.NOTIFY_NEW_REQUEST.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/shift-swap',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.access?.shifts &&
        isAdmin(department.role!) &&
        department.scheduleParams?.swap_shift_enabled,
    },
    {
      label: t('SETTINGS.SCHEDULES.SHIFT_SWAP.NOTIFY_NEW_TRANSFER.LABEL'),
      description: t('SETTINGS.SCHEDULES.SHIFT_SWAP.NOTIFY_NEW_TRANSFER.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/shift-swap',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.access?.shifts &&
        isAdmin(department.role!) &&
        department.scheduleParams?.swap_shift_enabled,
    },

    // SCHEDULES - BREAKS
    {
      label: t('SETTINGS.SCHEDULES.BREAKS.CREATE_BREAK.LABEL'),
      description: t('SETTINGS.SCHEDULES.BREAKS.CREATE_BREAK.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/breaks',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },

    // SCHEDULES - NOTIFICATIONS
    {
      label: t('SETTINGS.SCHEDULES.NOTIFICATIONS.MANAGE_SHIFTS_ALERTS.LABEL'),
      description: t('SETTINGS.SCHEDULES.NOTIFICATIONS.MANAGE_SHIFTS_ALERTS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/notifications',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },

    // SCHEDULES - PDF SCHEDULES
    {
      label: t('SETTINGS.MENU.SCHEDULE.PDF_SCHEDULES'),
      icon: 'calendar-alt',
      keywords: [],
      link: '/app/settings/schedule/pdf-schedules',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts,
    },

    // SCHEDULES - PUBLIC SCHEDULES
    {
      label: t('SETTINGS.SCHEDULES.PUBLIC_SCHEDULES.PUBLIC_SCHEDULES_PAGE.LABEL'),
      description: t('SETTINGS.SCHEDULES.PUBLIC_SCHEDULES.PUBLIC_SCHEDULES_PAGE.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/public-schedules',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.PUBLIC_SCHEDULES.PUBLIC_SCHEDULES_ICAL.LABEL'),
      description: t('SETTINGS.SCHEDULES.PUBLIC_SCHEDULES.PUBLIC_SCHEDULES_ICAL.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/public-schedules',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },

    // SCHEDULES - MOBILE
    {
      label: t('SETTINGS.SCHEDULES.STAFF.HIDE_SHIFTS_END.LABEL'),
      description: t('SETTINGS.SCHEDULES.STAFF.HIDE_SHIFTS_END.DESCRIPTION'),
      icon: 'calendar-alt',
      keywords: [],
      link: '/app/settings/schedule/staff',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },

    // SCHEDULES - SETTINGS
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.HR_SHIFTS_VALIDATION.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.HR_SHIFTS_VALIDATION.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_EXTRA_INFO.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_EXTRA_INFO.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_EXTRA_INFO_SECTIONS.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_EXTRA_INFO_SECTIONS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_ALL_USERS.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_ALL_USERS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_SHIFTS_COMMENTS.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_SHIFTS_COMMENTS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_EXTRA_COUNTERS.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_EXTRA_COUNTERS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.DIFFERENCIATE_NIGHT_SHIFTS.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.DIFFERENCIATE_NIGHT_SHIFTS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.access?.shifts &&
        isAdmin(department.role!) &&
        department.scheduleParams?.splitDayNight,
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_SHIFT_PRICE_ON_EACH_BLOCK.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_SHIFT_PRICE_ON_EACH_BLOCK.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.FILTER_METHOD.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.FILTER_METHOD.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.SCHEDULE_VIEWS.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.SCHEDULE_VIEWS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.ACTIVATE_UNAVAILABILITIES.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.ACTIVATE_UNAVAILABILITIES.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.access?.shifts &&
        isAdmin(department.role!) &&
        department.params?.enableAvailability,
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.DYNAMIC_HOURS.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.DYNAMIC_HOURS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.DAY_START_HOUR.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.DAY_START_HOUR.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.access?.shifts &&
        isAdmin(department.role!) &&
        department.scheduleParams?.dynamicHour,
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.DAY_END_HOUR.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.DAY_END_HOUR.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible:
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.access?.shifts &&
        isAdmin(department.role!) &&
        department.scheduleParams?.dynamicHour,
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.SHIFTS_START_HOUR.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.SHIFTS_START_HOUR.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.SHIFTS_END_HOUR.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.SHIFTS_END_HOUR.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.ORDER_WEEK_MONTH.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.ORDER_WEEK_MONTH.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.ORDER_DAY.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.ORDER_DAY.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.DAYS_IN_SCHEDULE.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.DAYS_IN_SCHEDULE.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.DAY_HOURS_TARGET.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.DAY_HOURS_TARGET.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },
    {
      label: t('SETTINGS.SCHEDULES.SETTINGS.CHANGE_SHIFTS_COLORS.LABEL'),
      description: t('SETTINGS.SCHEDULES.SETTINGS.CHANGE_SHIFTS_COLORS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/schedule/settings',
      visible: isFeatureEnabled(features, FEATURES.SCHEDULE) && department.access?.shifts && isAdmin(department.role!),
    },

    // FREE SHIFTS - SETTINGS
    {
      label: t('SETTINGS.FREESHIFTS.SETTINGS.ACTIVATE_SHIFTS_MARKET.LABEL'),
      description: t('SETTINGS.FREESHIFTS.SETTINGS.ACTIVATE_SHIFTS_MARKET.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/freeshifts/settings',
      visible:
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.FREESHIFTS) &&
        department?.access?.shifts,
    },
    {
      label: t('SETTINGS.FREESHIFTS.SETTINGS.GROUP_SAME_SHIFTS.LABEL'),
      description: t('SETTINGS.FREESHIFTS.SETTINGS.GROUP_SAME_SHIFTS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/freeshifts/settings',
      visible:
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.FREESHIFTS) &&
        department?.access?.shifts,
    },
    {
      label: t('SETTINGS.FREESHIFTS.SETTINGS.COUNT_FREESHIFTS.LABEL'),
      description: t('SETTINGS.FREESHIFTS.SETTINGS.COUNT_FREESHIFTS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/freeshifts/settings',
      visible:
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.FREESHIFTS) &&
        department?.access?.shifts,
    },

    // CLOCKINGS - COSTS
    {
      label: t('SETTINGS.MENU.CLOCKING.MANAGE_COSTS'),
      icon: 'stopwatch',
      keywords: [],
      link: '/app/settings/clocking/costs',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.params?.enable_clocking_costs,
    },

    // CLOCKINGS - POS
    {
      label: t('SETTINGS.CLOCKING.POS.AUTO_PICTURE.LABEL'),
      description: t('SETTINGS.CLOCKING.POS.AUTO_PICTURE.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/pos',
      visible: isFeatureEnabled(features, FEATURES.CLOCKING) && department?.access?.clockings,
    },
    {
      label: t('SETTINGS.CLOCKING.POS.DISPLAY_CLOCKING_DETAILS.LABEL'),
      description: t('SETTINGS.CLOCKING.POS.DISPLAY_CLOCKING_DETAILS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/pos',
      visible: isFeatureEnabled(features, FEATURES.CLOCKING) && department?.access?.clockings,
    },
    {
      label: t('SETTINGS.CLOCKING.POS.DISPLAY_CLOCKING_DURATION.LABEL'),
      description: t('SETTINGS.CLOCKING.POS.DISPLAY_CLOCKING_DURATION.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/pos',
      visible: isFeatureEnabled(features, FEATURES.CLOCKING) && department?.access?.clockings,
    },
    {
      label: t('SETTINGS.CLOCKING.POS.ACTIVATE_CLOCKOUT_COMMENT.LABEL'),
      description: t('SETTINGS.CLOCKING.POS.ACTIVATE_CLOCKOUT_COMMENT.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/pos',
      visible: isFeatureEnabled(features, FEATURES.CLOCKING) && department?.access?.clockings,
    },
    {
      label: t('SETTINGS.CLOCKING.POS.COMMENT_MANDATORY.LABEL'),
      description: t('SETTINGS.CLOCKING.POS.COMMENT_MANDATORY.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/pos',
      visible: isFeatureEnabled(features, FEATURES.CLOCKING) && department?.access?.clockings,
    },
    {
      label: t('SETTINGS.CLOCKING.POS.CUSTOM_POS.LABEL'),
      description: t('SETTINGS.CLOCKING.POS.CUSTOM_POS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/pos',
      visible: isFeatureEnabled(features, FEATURES.CLOCKING) && department?.access?.clockings,
    },
    {
      label: t('SETTINGS.CLOCKING.POS.API_KEY.LABEL'),
      description: t('SETTINGS.CLOCKING.POS.API_KEY.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/pos',
      visible: isFeatureEnabled(features, FEATURES.CLOCKING) && department?.access?.clockings,
    },
    {
      label: t('SETTINGS.CLOCKING.POS.API_KEY_SECTION.LABEL'),
      description: t('SETTINGS.CLOCKING.POS.API_KEY_SECTION.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/pos',
      visible: isFeatureEnabled(features, FEATURES.CLOCKING) && department?.access?.clockings,
    },

    // CLOCKING - MOBILE
    {
      label: t('SETTINGS.CLOCKING.STAFF.ACTIVATE_MOBILE_CLOCKING.LABEL'),
      description: t('SETTINGS.CLOCKING.STAFF.ACTIVATE_MOBILE_CLOCKING.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/staff',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        !isClockingAccount(features),
    },
    {
      label: t('SETTINGS.CLOCKING.STAFF.ACTIVATE_QR_CODE_SCAN.LABEL'),
      description: t('SETTINGS.CLOCKING.STAFF.ACTIVATE_QR_CODE_SCAN.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/staff',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        department.clockinParams?.enableMobileClockin,
    },
    {
      label: t('SETTINGS.CLOCKING.STAFF.MANDATORY_QR_CODE.LABEL'),
      description: t('SETTINGS.CLOCKING.STAFF.MANDATORY_QR_CODE.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/staff',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        department.clockinParams?.enableQrCode,
    },
    {
      label: t('SETTINGS.CLOCKING.STAFF.ACTIVATE_GPS.LABEL'),
      description: t('SETTINGS.CLOCKING.STAFF.ACTIVATE_GPS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/staff',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        department.clockinParams?.enableMobileClockin,
    },
    {
      label: t('SETTINGS.CLOCKING.STAFF.ALLOW_OFFLINE_CLOCKINGS.LABEL'),
      description: t('SETTINGS.CLOCKING.STAFF.ALLOW_OFFLINE_CLOCKINGS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/staff',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        department.clockinParams?.enableMobileClockin,
    },
    {
      label: t('SETTINGS.CLOCKING.STAFF.HIDE_HISTORY.LABEL'),
      description: t('SETTINGS.CLOCKING.STAFF.HIDE_HISTORY.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/staff',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role),
    },
    {
      label: t('SETTINGS.CLOCKING.STAFF.COMMENT_MANDATORY.LABEL'),
      description: t('SETTINGS.CLOCKING.STAFF.COMMENT_MANDATORY.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/staff',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        department.clockinParams?.enableMobileClockin,
    },

    // CLOCKING - SETTINGS
    {
      label: t('SETTINGS.CLOCKING.SETTINGS.ACTIVATE_CLOCKING_DASHBOARD.LABEL'),
      description: t('SETTINGS.CLOCKING.SETTINGS.ACTIVATE_CLOCKING_DASHBOARD.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/settings',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role),
    },
    {
      label: t('SETTINGS.CLOCKING.SETTINGS.ALLOW_OFF_TIME_SLOT_CLOCKING.LABEL'),
      description: t('SETTINGS.CLOCKING.SETTINGS.ALLOW_OFF_TIME_SLOT_CLOCKING.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/settings',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.SCHEDULE),
    },
    {
      label: t('SETTINGS.CLOCKING.SETTINGS.AUTO_APPROVE_OFF_TIME_SLOT_CLOCKINGS.LABEL'),
      description: t('SETTINGS.CLOCKING.SETTINGS.AUTO_APPROVE_OFF_TIME_SLOT_CLOCKINGS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/settings',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.clockinParams?.freeclockin,
    },
    {
      label: t('SETTINGS.CLOCKING.SETTINGS.ROUND_BREAK_TIME.LABEL'),
      description: t('SETTINGS.CLOCKING.SETTINGS.ROUND_BREAK_TIME.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/settings',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role),
    },
    {
      label: t('SETTINGS.CLOCKING.SETTINGS.USE_SHIFT_BREAK.LABEL'),
      description: t('SETTINGS.CLOCKING.SETTINGS.USE_SHIFT_BREAK.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/settings',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.SCHEDULE),
    },
    {
      label: t('SETTINGS.ACCOUNT.CLOCKING.NAMES.CLOCKING_START_TIME'),
      description: t('SETTINGS.ACCOUNT.CLOCKING.DESCRIPTIONS.CLOCKING_START_TIME'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/settings',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role),
    },
    {
      label: t('SETTINGS.ACCOUNT.CLOCKING.NAMES.CLOCKING_END_TIME'),
      description: t('SETTINGS.ACCOUNT.CLOCKING.DESCRIPTIONS.CLOCKING_END_TIME'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/settings',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role),
    },
    {
      label: t('SETTINGS.CLOCKING.SETTINGS.CONSIDER_BREAKS_AS_PAID.LABEL'),
      description: t('SETTINGS.CLOCKING.SETTINGS.CONSIDER_BREAKS_AS_PAID.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/settings',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role),
    },
    {
      label: t('SETTINGS.CLOCKING.SETTINGS.TITLES.DEPARTMENT_QR_CODE'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/settings',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role),
    },

    // CLOCKING - NOTIFICATIONS
    {
      label: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_NO_CHECK_IN.LABEL'),
      description: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_NO_CHECK_IN.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/notifications',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.clockinParams?.clockinWarningManager,
    },
    {
      label: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_NO_CHECK_OUT.LABEL'),
      description: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_NO_CHECK_OUT.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/notifications',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.clockinParams?.clockoutWarningManager,
    },
    {
      label: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_BEFORE_END_SHIFT.LABEL'),
      description: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_BEFORE_END_SHIFT.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/notifications',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.clockinParams?.clockoutWarning,
    },
    {
      label: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_FORGOT_CHECK_OUT.LABEL'),
      description: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_FORGOT_CHECK_OUT.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/notifications',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.SCHEDULE) &&
        department.clockinParams?.clockoutWarningLate,
    },
    {
      label: t('SETTINGS.CLOCKING.NOTIFICATIONS.AUTO_CLOSE_CLOCKINGS.LABEL'),
      description: t('SETTINGS.CLOCKING.NOTIFICATIONS.AUTO_CLOSE_CLOCKINGS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/clocking/notifications',
      visible:
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        department?.access?.clockings &&
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.SCHEDULE),
    },

    // HR - ABSENCES
    {
      label: t('SETTINGS.MENU.HR.DAYOFFS'),
      icon: 'hr',
      keywords: [],
      link: '/app/settings/hr/dayoffs',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        isFeatureEnabled(features, FEATURES.SCHEDULE),
    },

    // HR - SOCSEC
    {
      label: t('SETTINGS.MENU.HR.SOCSEC_CODES'),
      icon: 'hr',
      keywords: [],
      link: '/app/settings/hr/soc-sec',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        isCountry(department, ['BE']) &&
        hrPartners.length > 0 &&
        isFeatureEnabled(features, FEATURES.HR_PARTNER),
    },

    // HR - HR RULES
    {
      label: t('SETTINGS.MENU.HR.HR_RULES'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/hr-rules',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        isCountry(department, ['BE']) &&
        isFeatureEnabled(features, FEATURES.HR_PARTNER) &&
        hrPartners.length > 0,
    },
    {
      label: t('SETTINGS.HR.HR_RULES.SPECIAL_CODES.LABEL'),
      description: t('SETTINGS.HR.HR_RULES.SPECIAL_CODES.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/hr-rules',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        isCountry(department, ['BE']) &&
        isFeatureEnabled(features, FEATURES.HR_PARTNER) &&
        hrPartners.length > 0,
    },
    {
      label: t('SETTINGS.HR.HR_RULES.BREAK_DEDUCTION.LABEL'),
      description: t('SETTINGS.HR.HR_RULES.BREAK_DEDUCTION.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/hr-rules',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        isCountry(department, ['BE']) &&
        isFeatureEnabled(features, FEATURES.HR_PARTNER) &&
        hrPartners.length > 0,
    },
    {
      label: t('SETTINGS.HR.HR_RULES.HR_RULES.LABEL'),
      description: t('SETTINGS.HR.HR_RULES.HR_RULES.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/hr-rules',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        isCountry(department, ['BE']) &&
        isFeatureEnabled(features, FEATURES.HR_PARTNER) &&
        hrPartners.length > 0,
    },

    // HR - PAYROLL REPORTS
    {
      label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.EXTRA_HOURS_SLOT_1'),
      description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.EXTRA_HOURS_SLOT_1'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/payroll-reports',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        (isFeatureEnabled(features, FEATURES.SCHEDULE) || isFeatureEnabled(features, FEATURES.CLOCKING)) &&
        isCountry(department, ['FR']),
    },
    {
      label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.EXTRA_HOURS_SLOT_2'),
      description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.EXTRA_HOURS_SLOT_2'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/payroll-reports',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        (isFeatureEnabled(features, FEATURES.SCHEDULE) || isFeatureEnabled(features, FEATURES.CLOCKING)) &&
        isCountry(department, ['FR']),
    },
    {
      label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.EXTRA_HOURS_SLOT_3'),
      description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.EXTRA_HOURS_SLOT_3'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/payroll-reports',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        (isFeatureEnabled(features, FEATURES.SCHEDULE) || isFeatureEnabled(features, FEATURES.CLOCKING)) &&
        isCountry(department, ['FR']),
    },
    {
      label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.EXTRA_HOURS_SLOT_4'),
      description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.EXTRA_HOURS_SLOT_4'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/payroll-reports',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        (isFeatureEnabled(features, FEATURES.SCHEDULE) || isFeatureEnabled(features, FEATURES.CLOCKING)) &&
        isCountry(department, ['FR']),
    },
    {
      label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.NIGHT_HOURS_SLOT_1'),
      description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.NIGHT_HOURS_SLOT_1'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/payroll-reports',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        (isFeatureEnabled(features, FEATURES.SCHEDULE) || isFeatureEnabled(features, FEATURES.CLOCKING)) &&
        isCountry(department, ['FR']),
    },
    {
      label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.NIGHT_HOURS_SLOT_2'),
      description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.NIGHT_HOURS_SLOT_2'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/payroll-reports',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        (isFeatureEnabled(features, FEATURES.SCHEDULE) || isFeatureEnabled(features, FEATURES.CLOCKING)) &&
        isCountry(department, ['FR']),
    },
    {
      label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.PUBLIC_HOLIDAYS_HOURS_SLOT_1'),
      description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.PUBLIC_HOLIDAYS_HOURS_SLOT_1'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/payroll-reports',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        (isFeatureEnabled(features, FEATURES.SCHEDULE) || isFeatureEnabled(features, FEATURES.CLOCKING)) &&
        isCountry(department, ['FR']),
    },
    {
      label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.PUBLIC_HOLIDAYS_HOURS_SLOT_2'),
      description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.PUBLIC_HOLIDAYS_HOURS_SLOT_2'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/payroll-reports',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        (isFeatureEnabled(features, FEATURES.SCHEDULE) || isFeatureEnabled(features, FEATURES.CLOCKING)) &&
        isCountry(department, ['FR']),
    },

    // HR - MOBILE
    {
      label: t('SETTINGS.HR.STAFF.HOMEWORKING.LABEL'),
      description: t('SETTINGS.HR.STAFF.HOMEWORKING.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/staff',
      visible: department.role && isAdminOrHr(department.role) && !isFree(department),
    },
    {
      label: t('SETTINGS.HR.STAFF.DISPLAY_EXTRA_HOURS.LABEL'),
      description: t('SETTINGS.HR.STAFF.DISPLAY_EXTRA_HOURS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/staff',
      visible: department.role && isAdminOrHr(department.role) && !isFree(department),
    },
    {
      label: t('SETTINGS.HR.STAFF.ALLOW_DAYOFF_REQUESTS.LABEL'),
      description: t('SETTINGS.HR.STAFF.ALLOW_DAYOFF_REQUESTS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/staff',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        isFeatureEnabled(features, FEATURES.LEAVE_MANAGEMENT),
    },

    // HR - SETTINGS
    {
      label: t('SETTINGS.HR.SETTINGS.DEFAULT_COMPUTE_TYPE.LABEL'),
      description: t('SETTINGS.HR.SETTINGS.DEFAULT_COMPUTE_TYPE.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/settings',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        !isClockingAccount(features) &&
        !isEmptyAccount(features),
    },
    {
      label: t('SETTINGS.HR.SETTINGS.DEFAULT_DAYS_HOURS.LABEL'),
      description: t('SETTINGS.HR.SETTINGS.DEFAULT_DAYS_HOURS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/settings',
      visible: department.role && isAdminOrHr(department.role) && !isFree(department),
    },
    {
      label: t('SETTINGS.HR.SETTINGS.ACTIVATE_EXTRA_HOURS_COUNTERS.LABEL'),
      description: t('SETTINGS.HR.SETTINGS.ACTIVATE_EXTRA_HOURS_COUNTERS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/settings',
      visible: department.role && isAdminOrHr(department.role) && !isFree(department),
    },
    {
      label: t('SETTINGS.HR.SETTINGS.DISPLAY_ABSENCE_COUNTERS.LABEL'),
      description: t('SETTINGS.HR.SETTINGS.DISPLAY_ABSENCE_COUNTERS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/settings',
      visible: department.role && isAdminOrHr(department.role) && !isFree(department),
    },
    {
      label: t('SETTINGS.HR.SETTINGS.FULL_DAY_DAYOFF.LABEL'),
      description: t('SETTINGS.HR.SETTINGS.FULL_DAY_DAYOFF.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/settings',
      visible:
        department.role &&
        isAdminOrHr(department.role) &&
        !isFree(department) &&
        isFeatureEnabled(features, FEATURES.LEAVE_MANAGEMENT),
    },
    {
      label: t('SETTINGS.HR.SETTINGS.EMPLOYEE_CALCULATION.LABEL'),
      description: t('SETTINGS.HR.SETTINGS.EMPLOYEE_CALCULATION.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/settings',
      visible: department.role && isAdminOrHr(department.role) && !isFree(department),
    },
    {
      label: t('SETTINGS.HR.SETTINGS.SELECT_CP.LABEL'),
      description: t('SETTINGS.HR.SETTINGS.SELECT_CP.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/hr/settings',
      visible: department.role && isAdminOrHr(department.role) && !isFree(department),
    },

    // DIMONA - SETTINGS
    {
      label: t('SETTINGS.DIMONA.SETTINGS.ACTIVATE_AUTO_DIMONA.LABEL'),
      description: t('SETTINGS.DIMONA.SETTINGS.ACTIVATE_AUTO_DIMONA.DESCRIPTION'),
      icon: 'onss-rsz',
      keywords: [],
      link: '/app/settings/dimona/settings',
      visible:
        department?.access?.dimona &&
        isCountry(department, ['BE']) &&
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.DIMONA) &&
        (isFeatureEnabled(features, FEATURES.AUTOMATIC_DIMONA_CONTRACTS) ||
          isFeatureEnabled(features, FEATURES.CONTRACTS) ||
          isFeatureEnabled(features, FEATURES.CLOCKING)) &&
        isFeatureEnabled(features, FEATURES.AUTOMATIC_DIMONA_CONTRACTS),
    },
    {
      label: t('SETTINGS.DIMONA.SETTINGS.CONTRACT_CREATION_DIMONA.LABEL'),
      description: t('SETTINGS.DIMONA.SETTINGS.CONTRACT_CREATION_DIMONA.DESCRIPTION'),
      icon: 'onss-rsz',
      keywords: [],
      link: '/app/settings/dimona/settings',
      visible:
        department?.access?.dimona &&
        isCountry(department, ['BE']) &&
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.DIMONA) &&
        (isFeatureEnabled(features, FEATURES.AUTOMATIC_DIMONA_CONTRACTS) ||
          isFeatureEnabled(features, FEATURES.CONTRACTS) ||
          isFeatureEnabled(features, FEATURES.CLOCKING)) &&
        isFeatureEnabled(features, FEATURES.CONTRACTS),
    },
    {
      label: t('SETTINGS.DIMONA.SETTINGS.CLOCKING_DETAILS_DIMONA.LABEL'),
      description: t('SETTINGS.DIMONA.SETTINGS.CLOCKING_DETAILS_DIMONA.DESCRIPTION'),
      icon: 'onss-rsz',
      keywords: [],
      link: '/app/settings/dimona/settings',
      visible:
        department?.access?.dimona &&
        isCountry(department, ['BE']) &&
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.DIMONA) &&
        (isFeatureEnabled(features, FEATURES.AUTOMATIC_DIMONA_CONTRACTS) ||
          isFeatureEnabled(features, FEATURES.CONTRACTS) ||
          isFeatureEnabled(features, FEATURES.CLOCKING)) &&
        isFeatureEnabled(features, FEATURES.CLOCKING),
    },

    // CONTRACTS - POS
    {
      label: t('SETTINGS.ACCOUNT.APPS.NAMES.ALLOW_CONTRACT_SIGNATURE'),
      description: t('SETTINGS.ACCOUNT.APPS.DESCRIPTIONS.ALLOW_CONTRACT_SIGNATURE', { app: 'Shyfter POS V3' }),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/contracts/pos',
      visible:
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.CONTRACTS) &&
        department.access?.documents,
    },

    // CONTRACTS - MOBILE
    {
      label: t('SETTINGS.CONTRACTS.STAFF.ALLOW_CONTRACT_SIGNATURE.LABEL'),
      description: t('SETTINGS.CONTRACTS.STAFF.ALLOW_CONTRACT_SIGNATURE.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/contracts/staff',
      visible:
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.CONTRACTS) &&
        department.access?.documents,
    },

    // CONTRACTS - SETTINGS
    {
      label: t('SETTINGS.CONTRACTS.SETTINGS.ACTIVATE_AUTO_CONTRACTS.LABEL'),
      description: t('SETTINGS.CONTRACTS.SETTINGS.ACTIVATE_AUTO_CONTRACTS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/contracts/staff',
      visible:
        department.role &&
        isAdmin(department.role) &&
        isFeatureEnabled(features, FEATURES.CONTRACTS) &&
        department.access?.documents &&
        isFeatureEnabled(features, FEATURES.AUTOMATIC_DIMONA_CONTRACTS),
    },

    // INSIGHTS - SETTINGS
    {
      label: t('SETTINGS.INSIGHTS.SETTINGS.TURNOVER_RECORDING.LABEL'),
      description: t('SETTINGS.INSIGHTS.SETTINGS.TURNOVER_RECORDING.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/insights/settings',
      visible: isFeatureEnabled(features, FEATURES.PRODUCTIVITY) || isFeatureEnabled(features, FEATURES.QUOTAS),
    },
    {
      label: t('SETTINGS.INSIGHTS.SETTINGS.ENABLE_PRODUCTIVITY.LABEL'),
      description: t('SETTINGS.INSIGHTS.SETTINGS.ENABLE_PRODUCTIVITY.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/insights/settings',
      visible: isFeatureEnabled(features, FEATURES.PRODUCTIVITY) || isFeatureEnabled(features, FEATURES.QUOTAS),
    },
    {
      label: t('SETTINGS.INSIGHTS.SETTINGS.USE_CLOCKING_DETAILS.LABEL'),
      description: t('SETTINGS.INSIGHTS.SETTINGS.USE_CLOCKING_DETAILS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/insights/settings',
      visible:
        (isFeatureEnabled(features, FEATURES.PRODUCTIVITY) || isFeatureEnabled(features, FEATURES.QUOTAS)) &&
        isFeatureEnabled(features, FEATURES.CLOCKING),
    },
    {
      label: t('SETTINGS.INSIGHTS.SETTINGS.USE_QUOTA_TEMPLATES.LABEL'),
      description: t('SETTINGS.INSIGHTS.SETTINGS.USE_QUOTA_TEMPLATES.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/insights/settings',
      visible: isFeatureEnabled(features, FEATURES.PRODUCTIVITY) || isFeatureEnabled(features, FEATURES.QUOTAS),
    },

    // INSIGHTS - DAILY TURNOVER
    {
      label: t('SETTINGS.MENU.INSIGHTS.DAILY_TURNOVER'),
      icon: 'chart-bar',
      keywords: [],
      link: '/app/settings/insights/daily-turnover',
      visible:
        (isFeatureEnabled(features, FEATURES.PRODUCTIVITY) || isFeatureEnabled(features, FEATURES.QUOTAS)) &&
        department.params?.enableTurnover,
    },

    // INSIGHTS - BALANCE TURNOVER
    {
      label: t('SETTINGS.MENU.INSIGHTS.BALANCE_TURNOVER'),
      icon: 'chart-bar',
      keywords: [],
      link: '/app/settings/insights/balance',
      visible:
        department.params?.enableTurnover &&
        isFeatureEnabled(features, FEATURES.PRODUCTIVITY) &&
        isFeatureEnabled(features, FEATURES.QUOTAS),
    },

    // INSIGHTS - STAFF QUOTA
    {
      label: t('SETTINGS.MENU.INSIGHTS.STAFF_QUOTA'),
      icon: 'chart-bar',
      keywords: [],
      link: '/app/settings/insights/quota-templates',
      visible: isFeatureEnabled(features, FEATURES.QUOTAS),
    },

    // BILLING - BILLING DETAILS
    {
      label: t('SETTINGS.BILLING.BILLING_DETAILS.COMPANY.LABEL'),
      description: t('SETTINGS.BILLING.BILLING_DETAILS.COMPANY.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/billing-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.BILLING_DETAILS.VAT.LABEL'),
      description: t('SETTINGS.BILLING.BILLING_DETAILS.VAT.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/billing-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.BILLING_DETAILS.COUNTRY.LABEL'),
      description: t('SETTINGS.BILLING.BILLING_DETAILS.COUNTRY.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/billing-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.BILLING_DETAILS.ADDRESS.LABEL'),
      description: t('SETTINGS.BILLING.BILLING_DETAILS.ADDRESS.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/billing-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.BILLING_DETAILS.CITY.LABEL'),
      description: t('SETTINGS.BILLING.BILLING_DETAILS.CITY.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/billing-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.BILLING_DETAILS.POSTAL_CODE.LABEL'),
      description: t('SETTINGS.BILLING.BILLING_DETAILS.POSTAL_CODE.DESCRIPTION'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/billing-details',
      visible: department.role && isAdmin(department.role),
    },

    // BILLING - ACCOUNT DETAILS
    {
      label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.COMPANY.LABEL'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/account-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.CODE.LABEL'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/account-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.PHONE.LABEL'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/account-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.COUNTRY.LABEL'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/account-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.ADDRESS.LABEL'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/account-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.CITY.LABEL'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/account-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.POSTAL_CODE.LABEL'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/account-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.LANGUAGE.LABEL'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/account-details',
      visible: department.role && isAdmin(department.role),
    },
    {
      label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.PROFILE_PICTURE.LABEL'),
      icon: 'settings',
      keywords: [],
      link: '/app/settings/billing/account-details',
      visible: department.role && isAdmin(department.role),
    },

    // BILLING - INVOICES
    {
      label: t('SETTINGS.MENU.BILLING.INVOICES'),
      icon: 'money',
      keywords: [],
      link: '/app/settings/billing/invoices',
      visible: department.role && isAdmin(department.role),
    },

    // BILLING - CREDIT NOTES
    {
      label: t('SETTINGS.MENU.BILLING.CREDIT_NOTES'),
      icon: 'money',
      keywords: [],
      link: '/app/settings/billing/credit-notes',
      visible: department.role && isAdmin(department.role),
    },
  ];
};
