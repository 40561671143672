import colors from '@/styles/colors';
import { Button, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
}

const ModalAlreadyConfirmed: React.FC<Props> = ({ className, visible }) => {
  const { t, i18n } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const lang = params && params.get('lang');

  useEffect(() => {
    i18n.changeLanguage(lang ?? 'fr');
  }, [lang]);

  const onLogin = () => {
    window.location.href = '/login';
  };

  return (
    <Modal className={className} forceRender={true} closable={false} visible={visible} footer={null}>
      <div className="container">
        <i className="icon-ok-circled icon" />
        <h2 className="title">{t('CONFIRM_EMAIL.MODAL.TITLE')}</h2>
        <p className="content">{t('CONFIRM_EMAIL.MODAL.CONTENT')}</p>
        <Button type="primary" block size="large" className="submit-button" onClick={onLogin}>
          {t('CONFIRM_EMAIL.MODAL.GO_TO_SHYFTER')}
        </Button>
      </div>
    </Modal>
  );
};

export default styled(ModalAlreadyConfirmed)`
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 0;

    .icon {
      font-size: 32px;
      color: ${colors.green};
    }

    .title {
      font-weight: bold;
    }

    .content {
      text-align: center;
    }

    .submit-button {
      margin-top: 25px;
    }
  }
`;
