import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Menu } from 'antd';

interface Props {
  className?: string;
  selectedShifts: Set<string>;
  onClick: () => void;
}

const DeleteShifts: React.FC<Props> = ({ className, selectedShifts, onClick }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);

  return (
    <Menu.Item className={className} onClick={onClick} danger disabled={selectedShifts.size <= 0}>
      <i className="icon-delete" /> {t('SCHEDULE.REMOVE_SHIFT(S)')}
    </Menu.Item>
  );
};

export default styled(DeleteShifts)`
  margin: 0 5px;
  &:hover {
    background-color: #efefef !important;
    border-radius: 8px;
  }
`;
