import { IBalanceSheetData } from '@/types/user/balance-sheet-data.model';
import { IBalanceDay } from '@/types/user/balance-sheet-day.model';
import { IBalanceSheet } from '@/types/user/balance-sheet.model';
import { MONTHS } from '@/utils';
import { Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  balanceSheets: IBalanceSheetData | null;
  activeBalanceSheet: IBalanceSheet | null;
  norm?: 'strict' | 'smooth' | 'adden';
}

const ModalBalanceSheetDetails: React.FC<Props> = ({
  balanceSheets,
  activeBalanceSheet,
  visible,
  norm,
  setVisible,
}) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState<any[]>([]);

  const data = balanceSheets?.days[`${activeBalanceSheet?.year}${activeBalanceSheet?.month}`];

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.DATE'),
        key: 'date',
        render: (text: string, record: IBalanceDay) => {
          return record.date;
        },
      },
      {
        title: t('GLOBAL.TYPE'),
        key: 'type',
        render: (text: string, record: IBalanceDay) => {
          return record.type;
        },
      },
      {
        title: t('GLOBAL.PLANNED'),
        key: 'planned',
        render: (text: string, record: IBalanceDay) => {
          const data = Number(record.planned / 60)
            .toFixed(2)
            .split('.');

          const decimal = Number(Number('0.' + data[1]) * 60);

          return `${data[0]}:${decimal >= 0 && decimal <= 9 ? `0${decimal.toFixed(0)}` : decimal.toFixed(0)}`;
        },
      },
      {
        title: t('GLOBAL.CLOCKING'),
        key: 'clocking',
        render: (text: string, record: IBalanceDay) => {
          const data = Number(record.clocking / 60)
            .toFixed(2)
            .split('.');

          const decimal = Number(Number('0.' + data[1]) * 60);

          return `${data[0]}:${decimal >= 0 && decimal <= 9 ? `0${decimal.toFixed(0)}` : decimal.toFixed(0)}`;
        },
      },
      ...(norm !== 'adden'
        ? [
            {
              title: t('GLOBAL.COUNTER'),
              key: 'counter',
              render: (text: string, record: IBalanceDay) => {
                const data = Number(record.counter / 60)
                  .toFixed(2)
                  .split('.');

                const decimal = Number(Number('0.' + data[1]) * 60);

                return `${data[0]}:${decimal >= 0 && decimal <= 9 ? `0${decimal.toFixed(0)}` : decimal.toFixed(0)}`;
              },
            },
          ]
        : []),
    ]);
  }, [activeBalanceSheet]);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={t('USERS.BALANCE_SHEET.MODAL_DETAILS.TITLE', {
        sheet_title: activeBalanceSheet
          ? `${t(`GLOBAL.${MONTHS[activeBalanceSheet?.month - 1]}`)} ${activeBalanceSheet?.year}`
          : '--',
      })}
      visible={visible}
      onCancel={onClose}
      getContainer={false}
      forceRender
      footer={null}
      width={800}
    >
      <Table dataSource={data} columns={columns} pagination={false} />
    </Modal>
  );
};

export default ModalBalanceSheetDetails;
