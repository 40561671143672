import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { handleError, minutesToHoursAndOrMinutes } from '@/utils';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CalloutStyled from '../../components/Callout';
import ModalPause from '../../components/ModalPause';
import SettingsForm from '../../components/SettingsForm';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const Breaks: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalPauseVisible, setModalPauseVisible] = useState<boolean>(false);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const onChangeDate = (value: moment.Moment, index: number, name: string) => {
    const schedule = { ...form.getFieldValue('schedule') };
    const backgrounds = { ...schedule.backgrounds };

    if (backgrounds[index] == undefined) {
      onChangeDate(value, index - 1, name);
      return;
    }

    if (typeof backgrounds[index][name] !== undefined) {
      backgrounds[index][name] = value;
      setFormHasChanged(true);
    }

    form.setFieldsValue({
      schedule,
    });
  };

  const deletePause = (index: number) => {
    const pauses = [...(currentSettings?.pauses || [])];
    pauses.splice(index, 1);
    updateCurrentSettings({
      pauses,
    });
  };

  const showModalPause = () => {
    setModalPauseVisible(true);
  };

  const hideModalPause = () => {
    setModalPauseVisible(false);
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.SCHEDULES.BREAKS.CREATE_BREAK.LABEL'),
          description: t('SETTINGS.SCHEDULES.BREAKS.CREATE_BREAK.DESCRIPTION'),
          name: ['clockin', 'paid_clockin_break'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <>
              {!!currentSettings?.pauses?.filter((pause) => !pause.onlyClocking).length && (
                <CalloutStyled style={{ marginTop: 10, maxWidth: 540 }}>
                  <ul className="pauses">
                    {currentSettings?.pauses
                      ?.filter((pause) => !pause.onlyClocking)
                      .map((pause, index: number) => (
                        <li key={index}>
                          <CloseOutlined
                            onClick={() => {
                              deletePause(index);
                            }}
                          />
                          {minutesToHoursAndOrMinutes(pause.duration / 60)} {t('SETTINGS.ACCOUNT.BREAKS.BREAK.AFTER')}{' '}
                          {minutesToHoursAndOrMinutes(pause.from / 60)} {t('SETTINGS.ACCOUNT.BREAKS.BREAK.OF_WORK')}
                          {', '}
                          {pause.paid
                            ? t('GLOBAL.PAID_BREAK').toLocaleLowerCase()
                            : t('GLOBAL.UNPAID_BREAK').toLocaleLowerCase()}
                        </li>
                      ))}
                  </ul>
                </CalloutStyled>
              )}
              <div className="row-with-helper">
                <Button
                  type="dashed"
                  style={{ marginTop: 15, marginBottom: 15, marginRight: 15 }}
                  onClick={showModalPause}
                >
                  {t('SETTINGS.ACCOUNT.BREAKS.ADD_AUTO_BREAK')}
                </Button>{' '}
              </div>
            </>
          ),
          elementBelow: true,
          show: true,
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    form.setFieldsValue({
      ...currentSettings,
    });
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings({
      ...values,
    })
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.SCHEDULES.BREAKS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
      <ModalPause
        currentSettings={currentSettings}
        updateCurrentSettings={updateCurrentSettings}
        visible={modalPauseVisible}
        onCancel={hideModalPause}
      />
    </div>
  );
};

export default styled(Breaks)`
  .pauses {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li:nth-of-type(n + 2) {
      padding-top: 5px;
    }

    .anticon-close {
      background: ${colors.red};
      padding: 1px;
      border-radius: 10px;
      margin-right: 5px;
      svg {
        transform: scale(0.8);
        fill: white;
      }
    }
  }
`;
