import React, { useState } from 'react';
import { Dropdown } from 'antd';
import DayRightClickMenu from './DayRightClickMenu';

interface Props {
  children?: any;
  dayIndex: number;
}
const DayRightClick: React.FC<Props> = ({ children, dayIndex }) => {
  const [rightClickMenuVisible, setRightClickMenuVisible] = useState<boolean>(false);

  return (
    <Dropdown
      getPopupContainer={(trigger) => {
        return trigger.parentNode?.parentNode as HTMLElement;
      }}
      overlay={() => (
        <DayRightClickMenu
          dayIndex={dayIndex}
          close={() => {
            setRightClickMenuVisible(false);
          }}
        />
      )}
      trigger={['contextMenu']}
      visible={rightClickMenuVisible}
      onVisibleChange={(e) => {
        setRightClickMenuVisible(e);
      }}
    >
      {children}
    </Dropdown>
  );
};

export default DayRightClick;
