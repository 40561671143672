import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { IScheduleClocking, IScheduleClockingBreak } from '@/types/schedule-clocking.model';
import { getContrast, isFeatureEnabled, minutesToHoursAndOrMinutes, now } from '@/utils';
import { Divider, Tooltip } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  clocking: IScheduleClocking;
  setActiveClocking: React.Dispatch<React.SetStateAction<IScheduleClocking | null>>;
}

const Clocking: React.FC<Props> = ({ className, clocking, setActiveClocking }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, sections, features, skills, resources, tasks, activeDepartment, activeSection },
    dispatch,
  } = useContext(AppContext);

  const attributes = resources.flatMap((resource) => resource.attributes || []);
  const section = sections.find((section) => section.id == clocking.section);
  const clockingSkills = (clocking.skill || '')
    .split(',')
    .map((skill) => {
      const found = skills.find((s) => s.id == skill);
      return found;
    })
    .filter((skill) => skill !== null && skill !== undefined);
  const clockingAttributes = (clocking.attribute || '')
    .split(',')
    .map((attribute) => {
      const found = attributes.find((a) => a.id == attribute);
      return found;
    })
    .filter((attribute) => attribute !== null && attribute !== undefined);

  const onClick = () => {
    setActiveClocking(clocking);
  };

  return (
    <div className={className} onClick={onClick}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className={`times ${!clocking.enddate ? 'ongoing' : ''}`}>
            {moment(clocking.startdate).format('HH:mm')}
            {clocking.enddate ? ` - ${moment(clocking.enddate).format('HH:mm')}` : ' - ...'}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {clocking?.origin && clocking?.origin && clocking.origin !== 'manual' && (
              <Tooltip overlay={t(`CLOCKINGS.ORIGINS.CHECK_IN.${clocking.origin}`)}>
                <i
                  className={`icon-${
                    clocking.origin == 'pos'
                      ? 'tablet'
                      : clocking.origin == 'mobile-v3' || clocking.origin == 'mobile-v4'
                      ? 'mobile'
                      : ''
                  }`}
                  style={{ color: colors.blueLightPastel, rotate: clocking.origin == 'pos' ? '90deg' : '' }}
                />
              </Tooltip>
            )}
            {clocking?.origin_clock_out && clocking?.origin_clock_out && clocking.origin_clock_out !== 'manual' && (
              <Tooltip overlay={t(`CLOCKINGS.ORIGINS.CHECK_OUT.${clocking.origin_clock_out}`)}>
                <i
                  className={`icon-${
                    clocking.origin_clock_out == 'pos'
                      ? 'tablet'
                      : clocking.origin_clock_out == 'mobile-v3' || clocking.origin_clock_out == 'mobile-v4'
                      ? 'mobile'
                      : ''
                  }`}
                  style={{ color: colors.red, rotate: clocking.origin == 'pos' ? '90deg' : '' }}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="icons">
          <div className="icon">
            <i className="icon-clock" />
            <span>
              {minutesToHoursAndOrMinutes(
                moment(clocking.enddate || now(activeDepartment?.timezone)).diff(moment(clocking.startdate), 'minutes'),
              ) || `0${t('GLOBAL.MINUTE_UNIT')}`}
            </span>
          </div>
          {clocking.breaks.length > 0 && (
            <div className="icon">
              <i className="icon-pause" />
              {minutesToHoursAndOrMinutes(
                clocking.breaks.reduce((prev: number, clockingBreak: IScheduleClockingBreak) => {
                  const start = moment(clockingBreak.startdate);
                  const end = moment(clockingBreak.enddate || now(activeDepartment?.timezone));
                  const duration = end.diff(start, 'minutes');
                  return prev + duration;
                }, 0),
              ) || `0${t('GLOBAL.MINUTE_UNIT')}`}
            </div>
          )}
        </div>
        {isFeatureEnabled(features, FEATURES.TASKS) && (
          <div className="tasks">
            {clocking.tasks.map((task) => {
              const start = moment(task.startdate);
              const end = moment(task.enddate);
              const found = tasks.find((t) => t.id == String(task.task_id));
              if (!found) return;
              return (
                <div key={task.id} className="task">
                  <span>{found.name}</span>
                  <span>
                    {start.format('HH:mm')} {end ? ` - ${end.format('HH:mm')}` : ' - ...'}
                  </span>
                </div>
              );
            })}
          </div>
        )}
        {!activeSection && isFeatureEnabled(features, FEATURES.SECTIONS) && section && (
          <div
            className="section"
            style={{ backgroundColor: section.background, color: getContrast(section.background!) }}
          >
            {section.name}
          </div>
        )}
        {isFeatureEnabled(features, FEATURES.SKILLS) && clockingSkills.length > 0 && (
          <div className="skills">
            {clockingSkills.map((skill) => {
              if (!skill) return;
              return (
                <div
                  key={skill.id}
                  className="skill"
                  style={{ backgroundColor: skill.background, color: getContrast(skill.background!) }}
                >
                  {skill.name}
                </div>
              );
            })}
          </div>
        )}
        {isFeatureEnabled(features, FEATURES.ATTRIBUTES) && clockingAttributes.length > 0 && (
          <div className="attributes">
            {clockingAttributes.map((attribute) => {
              if (!attribute) return;
              return (
                <div
                  key={attribute.id}
                  className="attribute"
                  style={{ backgroundColor: attribute.background, color: getContrast(attribute.background!) }}
                >
                  {attribute.name}
                </div>
              );
            })}
          </div>
        )}
        {clocking.hr_code_id && <div className="hr-code">{clocking.hr_code_id}</div>}
        {clocking.comment && (
          <div className="comment-container">
            <Divider className="divider" />
            <span className="comment">{clocking.comment}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default styled(Clocking)`
  background-color: ${colors.blueExtraLight};
  border-radius: 10px;
  padding: 7px;
  cursor: pointer;

  .times {
    &.ongoing {
      color: ${colors.green};
    }
  }

  .icons {
    display: flex;
    align-items: center;
    font-size: 10px;
    color: ${colors.grey};

    .icon {
      display: flex;
      align-items: center;
    }
  }

  .tasks {
    display: flex;
    flex-direction: column;
    color: ${colors.grey};

    .task {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 11px;
    }
  }

  .section {
    margin-top: 5px;
    padding: 0 5px;
    border-radius: 5px;
    font-size: 11px;
  }

  .skills {
    display: flex;
    align-items: center;
    column-gap: 5px;
    row-gap: 2px;
    flex-wrap: wrap;
    margin-top: 5px;

    .skill {
      padding: 0 5px;
      border-radius: 5px;
      font-size: 11px;
      margin-top: 2px;
    }
  }

  .attributes {
    display: flex;
    align-items: center;
    column-gap: 5px;
    row-gap: 2px;
    flex-wrap: wrap;
    margin-top: 5px;

    .attribute {
      padding: 0 5px;
      border-radius: 5px;
      font-size: 11px;
    }
  }

  .hr-code {
    background-color: #000;
    display: inline-block;
    color: #fff;
    padding: 0 5px;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 11px;
  }

  .comment-container {
    .divider {
      margin: 0;
      margin-top: 10px;
    }

    .comment {
      font-size: 11px;
      color: ${colors.grey};
    }
  }
`;
