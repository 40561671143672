import { Input, message, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { IUser } from '@/types/user.model';
import axios from 'axios';
import { ILog } from '@/types/log.model';

moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  user?: IUser;
  departmentId?: string;
}

const Logs: React.FC<Props> = ({ user, departmentId }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });

  const [columns, setColumns] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState<ILog[]>([]);
  const [filteredLogs, setFilteredLogs] = useState<ILog[]>([]);
  const [term, setTerm] = useState<string>('');

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.DATE'),
        key: 'data',
        render: (text: string, record: ILog) => {
          return moment.unix(record.dateCreated!).format('L');
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        dataIndex: 'action',
      },
      {
        title: t('GLOBAL.COMMENT'),
        key: 'comment',
        dataIndex: 'comment',
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    setLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/logs/${user?.mainId}`, {
        params: {
          departmentId,
          limit: 10,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        setLogs(response.data.logs);
        setLoading(false);
      })
      .catch(() => {
        message.error(t('LOGS.MESSAGE_LOADING_ERROR'));
        setLoading(false);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [user, departmentId]);

  useEffect(() => {
    if (term) {
      const filteredLogs = logs.filter((userStatus: any) => {
        return Object.keys(userStatus).some((key) => {
          if (typeof userStatus[key] === 'string') {
            return userStatus[key].toLowerCase().includes(term.toLowerCase());
          } else {
            return false;
          }
        });
      });
      setFilteredLogs(filteredLogs);
    } else {
      setFilteredLogs(logs);
    }
  }, [logs, term]);

  return (
    <div>
      <Input
        allowClear
        placeholder={t('GLOBAL.SEARCH')}
        suffix={<i className="icon-search"></i>}
        width={190}
        style={{ width: 200, marginBottom: 20 }}
        onChange={(e) => setTerm((e.target as HTMLInputElement).value)}
      />
      <Table dataSource={filteredLogs} columns={columns} rowKey="id" loading={loading} pagination={false} />
    </div>
  );
};

export default Logs;
