import TableView from '@/layouts/TableView';
import Color from '@/pages/app/components/Color';
import AppContext from '@/pages/app/context';
import { AlignType } from '@/types/alignType.model';
import { ISection } from '@/types/section.model';
import { message, Modal, Table } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerAssignUsers from '@/pages/app/components/drawers/AssignUsers';
import DrawerSection from '@/pages/app/components/drawers/Section';
import Header from './components/Header';
import TableRowActions from './components/TableRowActions';

interface Props {
  className?: string;
}

interface IUserForAttribution {
  recordId: string;
  displayName: string;
  active: boolean;
}

const SectionsList: React.FC<Props> = ({ className }) => {
  const {
    state: { loadingSections, sections, activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const sectionsRef = useRef(sections);
  sectionsRef.current = sections;
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });
  const [filteredSections, setFilteredSections] = useState<ISection[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeSection, setActiveSection] = useState<ISection | null>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [showAssignDrawer, setShowAssignDrawer] = useState<ISection | null>(null);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.ID'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      },
      {
        title: t('GLOBAL.COLOR'),
        dataIndex: 'background',
        key: 'background',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: ISection) => {
          return <Color style={{ background: record.background }} />;
        },
      },
      {
        title: t('GLOBAL.ADDRESS'),
        dataIndex: 'address',
        key: 'address',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: ISection) => {
          if (record.country) {
            return (
              <span>{`${record.street ? record.street + ', ' : ''}${
                record.city ? record.city + ' ' + record.zip + ', ' : ''
              }${record.country}`}</span>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: ISection) => (
          <TableRowActions
            onEdit={() => setActiveSection(record)}
            onDelete={() => onWantToDelete(record.id!)}
            onAssign={() => setShowAssignDrawer(record)}
          />
        ),
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    const filteredSections = sections.filter((section: any) => {
      return Object.keys(section).some((key) => {
        if (key === 'background') {
          return false;
        }
        if (typeof section[key] === 'string') {
          return section[key].toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return false;
        }
      });
    });
    setFilteredSections(filteredSections);
  }, [sections, searchTerm]);

  const onWantToDelete = (sectionId: string) => {
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/sections/${sectionId}/users`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        if (data.length > 0 && data.find((user: IUserForAttribution) => user.active == true)) {
          return Modal.error({
            title: t('GLOBAL.CANNOT_DELETE'),
            content: t('SETTINGS.TOOLBOX.SECTIONS.CANNOT_DELETE'),
          });
        } else {
          Modal.confirm({
            title: t('GLOBAL.DELETION'),
            icon: null,
            content: t('SECTIONS.MODAL_DELETE_SECTION.CONTENT'),
            cancelText: t('GLOBAL.CANCEL'),
            okText: t('GLOBAL.REMOVE'),
            okType: 'danger',
            onOk: () => {
              onDelete(sectionId);
            },
            onCancel: () => {},
          });
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      });
  };

  const onDelete = (sectionId: string) => {
    const sectionsCopy = [...sectionsRef.current];
    const index = sectionsCopy.findIndex((section) => section.id === sectionId);

    if (~index) {
      const sectionsResult = [...sectionsCopy];
      sectionsResult.splice(index, 1);
      dispatch({
        type: 'SET_SECTIONS',
        payload: sectionsResult,
      });
    }

    axios.delete(`${process.env.REACT_APP_API_URL}/v3/sections/${sectionId}`).catch((error) => {
      if (~index) {
        console.error(error);
        message.error(t('SECTIONS.MODAL_DELETE_SECTION.MESSAGE_ERROR'));
        if (sectionsCopy) {
          dispatch({
            type: 'SET_SECTIONS',
            payload: sectionsCopy,
          });
        }
      }
    });
  };

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchTerm(value);
  };

  const onSave = (result: ISection) => {
    const sectionsCopy = [...sectionsRef.current];
    const index = sectionsCopy.findIndex((section) => section.id === result.id);

    if (~index) {
      sectionsCopy[index] = result;
      dispatch({
        type: 'SET_SECTIONS',
        payload: sectionsCopy,
      });
    } else {
      sectionsCopy.push(result);
      const sectionsCopySorted = sectionsCopy.sort((a, b) => {
        if (a.name!.toLowerCase() < b.name!.toLowerCase()) {
          return -1;
        }
        if (a.name!.toLowerCase() > b.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      dispatch({
        type: 'SET_SECTIONS',
        payload: sectionsCopySorted,
      });
    }
  };

  return (
    <React.Fragment>
      <h2>{t('GLOBAL.SECTIONS')}</h2>
      <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10, marginTop: 25 }}>
        <TableView>
          <Header disabled={false} onSearchChange={onSearchChange} onCreate={() => setActiveSection({})} />
          <Table
            className={className}
            loading={loadingSections}
            dataSource={filteredSections}
            columns={columns}
            rowKey="id"
            pagination={false}
          />
        </TableView>
        <DrawerSection
          section={activeSection}
          visible={!!activeSection}
          onClose={() => setActiveSection(null)}
          onSave={onSave}
        />
        <DrawerAssignUsers
          departmentId={activeDepartmentId}
          entity={showAssignDrawer}
          entityName="sections"
          onClose={() => setShowAssignDrawer(null)}
        />
      </div>
    </React.Fragment>
  );
};

export default SectionsList;
