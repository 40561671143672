import axios from 'axios';
import produce from 'immer';
import { ActionsType } from './actions';
import { InitialStateType, initialState } from './context';

export default function reducer(state: InitialStateType, action: ActionsType): InitialStateType {
  switch (action.type) {
    case 'RESET': {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      delete axios.defaults.headers.common['Authorization'];
      return initialState;
    }
    case 'SET_LANG': {
      return produce(state, (draftState) => {
        draftState.lang = action.payload;
      });
    }
    case 'SET_ACCESS_TOKEN': {
      return produce(state, (draftState) => {
        const accessToken = action.payload;
        draftState.accessToken = accessToken;
        draftState.impersonate = false;
        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
          axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        } else {
          localStorage.removeItem('accessToken');
          delete axios.defaults.headers.common['Authorization'];
        }
      });
    }
    case 'SET_IMPERSONATE_DEPARTMENT_ID': {
      return produce(state, (draftState) => {
        const impersonateDepartmentId = action.payload || '';
        sessionStorage.setItem('impersonateDepartmentId', impersonateDepartmentId);
      });
    }
    case 'SET_IMPERSONATE_TOKEN': {
      return produce(state, (draftState) => {
        const impersonateToken = action.payload;
        draftState.accessToken = impersonateToken;
        draftState.impersonate = true;
        if (impersonateToken) {
          sessionStorage.setItem('impersonateToken', impersonateToken);
          axios.defaults.headers.common['Authorization'] = `Bearer ${impersonateToken}`;
        } else {
          draftState.impersonate = false;
          sessionStorage.removeItem('impersonateToken');
          delete axios.defaults.headers.common['Authorization'];
          const accessToken = localStorage.getItem('accessToken');
          if (accessToken) {
            draftState.accessToken = accessToken;
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          }
        }
      });
    }
    case 'SET_USER_DETAILS': {
      return produce(state, (drafState) => {
        drafState.userDetails = action.payload;
      });
    }
    case 'SET_TRIAL_END_DATE': {
      return produce(state, (drafState) => {
        drafState.trialEndDate = action.payload;
      });
    }
    case 'SET_BLOCKED': {
      return produce(state, (drafState) => {
        drafState.blocked = action.payload;
      });
    }
    case 'SET_GIT_SHA': {
      return produce(state, (drafState) => {
        drafState.gitSha = action.payload;
      });
    }
    case 'SET_PUSHER_ID': {
      return produce(state, (drafState) => {
        drafState.pusherId = action.payload;
      });
    }
    default: {
      return state;
    }
  }
}
