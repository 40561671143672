import TableView from '@/layouts/TableView';
import colors from '@/styles/colors';
import { AlignType } from '@/types/alignType.model';
import { IField } from '@/types/field.model';
import { MenuOutlined } from '@ant-design/icons';
import { message, Modal, Space, Table } from 'antd';
import arrayMove from 'array-move';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Header from './components/Header';
import ModalUserField from './components/ModalUserField';
import AppContext from '@/pages/app/context';

const SortableElementItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerItem = SortableContainer((props: any) => <tbody {...props} />);

interface Props {
  className?: string;
}

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />);

const ManageCustomFields: React.FC<Props> = ({ className }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [fields, setFields] = useState<IField[]>([]);
  const [filteredFields, setFilteredFields] = useState<IField[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeField, setActiveField] = useState<IField | null>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    setColumns([
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        // eslint-disable-next-line react/display-name
        render: () => <DragHandle />,
      },
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('GLOBAL.MANDATORY'),
        key: 'mandatory',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: any) => {
          return <span>{record?.mandatory ? t('GLOBAL.YES') : t('GLOBAL.NO')}</span>;
        },
      },
      {
        title: t('GLOBAL.VISIBLE'),
        key: 'display',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: any) => {
          return <span>{record?.display ? t('GLOBAL.YES') : t('GLOBAL.NO')}</span>;
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'mandatory',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: any) => {
          return (
            <Space>
              <button className="green" onClick={() => setActiveField(record)}>
                {t('GLOBAL.EDIT')}
              </button>
              <button disabled={record.locked} className="red" onClick={() => onWantToDeleteField(record.id)}>
                {t('GLOBAL.REMOVE')}
              </button>
            </Space>
          );
        },
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    let mounted = true;
    setFields([]);
    setIsLoading(true);
    if (activeDepartmentId) {
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/users-fields`, {
        params: {
          departmentId: activeDepartmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
        .then((response) => {
          if (mounted) {
            setFields(response.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (!Axios.isCancel(error)) {
            console.error(error);
          }
          if (mounted) {
            setIsLoading(false);
          }
        });

      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [activeDepartmentId]);

  useEffect(() => {
    setFilteredFields([]);
    if (fields && fields?.length) {
      const filteredFields = fields.filter((resource: any) => {
        return Object.keys(resource).some((key) => {
          if (typeof resource[key] === 'string') {
            return resource[key].toLowerCase().includes(searchTerm.toLowerCase());
          } else {
            return false;
          }
        });
      });
      setFilteredFields(filteredFields);
    }
  }, [fields, searchTerm]);

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const oldFields = [...fields];
    if (fields && oldIndex !== newIndex) {
      setIsLoading(true);
      const newFields: IField[] = arrayMove(oldFields, oldIndex, newIndex);
      setFields(newFields);
      Axios.patch(
        `${process.env.REACT_APP_API_URL}/v3/users-fields`,
        {
          fields: newFields.map((x) => x.id),
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
        .then((response) => {
          setFields(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setFields(oldFields);
          setIsLoading(false);
        });
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = fields.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableElementItem index={index} {...restProps} />;
  };

  const onSave = (result: IField[]) => {
    setFields(result);
  };

  const onWantToDeleteField = (fieldId: string) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('USER_FIELDS.MODAL_DELETE.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDeleteField(fieldId);
      },
      onCancel: () => {},
    });
  };

  const onDeleteField = (fieldId: string) => {
    Axios.delete(`${process.env.REACT_APP_API_URL}/v3/users-fields/${fieldId}`, {
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then((response) => {
        onSave(response.data);
      })
      .catch((error) => {
        console.log(error);
        message.error(t('USER_FIELDS.MODAL_DELETE.MESSAGE_ERROR'));
      });
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerItem useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchTerm(value);
  };

  return (
    <React.Fragment>
      <h2>{t('SETTINGS.GENERAL.CUSTOM_FIELDS.TITLE')}</h2>
      <span style={{ color: colors.grey }}>{t('SETTINGS.GENERAL.CUSTOM_FIELDS.DESCRIPTION')}</span>
      <div
        style={{
          backgroundColor: 'white',
          padding: 25,
          borderRadius: 10,
          marginTop: 25,
        }}
      >
        <TableView>
          <Header disabled={false} onSearchChange={onSearchChange} onCreate={() => setActiveField({})} />
          <Table
            className={className}
            loading={isLoading}
            dataSource={filteredFields}
            columns={columns}
            rowKey="id"
            pagination={false}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          />
        </TableView>
        <ModalUserField
          visible={!!activeField}
          field={activeField}
          departmentId={activeDepartmentId}
          onClose={() => setActiveField(null)}
          onSave={onSave}
        />
      </div>
    </React.Fragment>
  );
};

export default ManageCustomFields;
