import { default as Colors, default as colors } from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { IUser } from '@/types/user.model';
import { isFeatureEnabled, minutesToHoursAndOrMinutes } from '@/utils';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { InitialStateType } from '../redux/store';
import UserShiftRightClick from './UserShiftRightClick';
import AuthContext, { InitialStateType as AuthInitialStateType } from '@/context';
import { FEATURES } from '@/types/features.model';
import AppContext from '@/pages/app/context';

interface Props {
  baseClassName: string;
  className?: string;
  children?: any;
  user: IUser;
  totalPrice: number;
  totalMinutes: number;
  activeSection?: string;
  activeDepartment?: IDepartment;
  showSkillInsteadOfStatus?: boolean;
}

const UserShiftRow: React.FC<Props> = ({
  baseClassName,
  className,
  children,
  user,
  totalPrice,
  totalMinutes,
  activeSection,
  activeDepartment,
  showSkillInsteadOfStatus,
}) => {
  const {
    showDetails,
    showShifts,
    showAbsences,
    showOtherDepartments,
    filterOptions,
    picker,
    filteredShiftsByUsers,
    days,
  } = useSelector(
    ({
      showDetails,
      showShifts,
      showAbsences,
      showOtherDepartments,
      filterOptions,
      picker,
      filteredShiftsByUsers,
      days,
    }: InitialStateType) => ({
      showDetails,
      showShifts,
      showAbsences,
      showOtherDepartments,
      filterOptions,
      picker,
      filteredShiftsByUsers,
      days,
    }),
    shallowEqual,
  );

  const {
    i18n: { language },
    t,
  } = useTranslation(undefined, { useSuspense: true });
  const { state: authContextState } = useContext(AuthContext);
  const {
    state: { features },
  } = useContext(AppContext);
  const { userDetails } = authContextState;

  const {
    displayName,
    recordId: userRecordId,
    picture,
    initials,
    maxWeekMinutes,
    totalMinutes: globalTotalMinutes,
    price: globalTotalPrice,
    counter_dayoff_days,
    counter_dayoff_hours,
    userType,
    counter_dayoff,
  } = user;

  const daysWithoutWeekend = days.filter((day) => day.dayWeek !== '6' && day.dayWeek !== '7');
  const maxMonthMinutes = (maxWeekMinutes! / 5) * daysWithoutWeekend.length;
  const weekMinutesDifference = globalTotalMinutes! - maxMonthMinutes;
  const positiveDifference = weekMinutesDifference > 0;
  const shiftsByUser = filteredShiftsByUsers.find((shiftsByUser) => shiftsByUser.user?.recordId === userRecordId)!;

  return (
    <div className={`${baseClassName} ${className}`} key={`sgi_${userRecordId}`}>
      <UserShiftRightClick shiftsByUser={shiftsByUser} picker={picker}>
        <div
          className="left"
          style={{
            borderLeftColor: showSkillInsteadOfStatus ? user?.skills?.[0]?.background : user?.userType?.background,
            padding: '6px 15px 0',
          }}
        >
          <div className="user-info">
            <div className="right">
              <Link to={`/app/team/collaborators/${userRecordId}`} className="name">
                {displayName}
              </Link>
              <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                <span className="user-type" style={{ marginTop: userType ? '-5px' : '0', flex: userType ? 1 : 0 }}>
                  {userType && userType.name}
                </span>
                {maxMonthMinutes && maxMonthMinutes > 0 ? (
                  <span className="tag" style={{ marginLeft: userType ? '20px' : '0' }}>
                    {`${minutesToHoursAndOrMinutes(maxWeekMinutes!)}/${minutesToHoursAndOrMinutes(maxMonthMinutes!)}`}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </UserShiftRightClick>
      <div className="middle">{children}</div>
      <div className="right" style={{ width: isFeatureEnabled(features, FEATURES.CLOCKING) ? 225 : 150 }}>
        <div className="right-col">
          <span style={{ fontSize: 10 }}>{minutesToHoursAndOrMinutes(globalTotalMinutes!)}</span>
        </div>
        <div className="right-col">
          <span>{counter_dayoff}</span>
        </div>
        {isFeatureEnabled(features, FEATURES.CLOCKING) && userDetails?.params?.access?.clockings && (
          <div className="right-col">
            {user.counters?.clocking?.clockingTime && (
              <span className="clocking-time">
                {minutesToHoursAndOrMinutes(user.counters?.clocking?.clockingTime as number)}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const UserShiftRowStyled = styled(React.memo(UserShiftRow))`
  @media print {
    position: relative;
    page-break-inside: avoid;
  }

  transition: background 500ms;

  &:hover {
    background: #fafafa;
  }

  > .left {
    border-left: 6px solid transparent;
    background: #f7f9ff;
  }

  .middle {
    border-right: 1px solid ${Colors.blueLight};
    flex: 1;
  }

  .user-info {
    width: 100%;
    padding: 5px 0;

    > .left {
      padding-right: 9px;
    }

    > .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;

      span,
      a {
        font-weight: 500;
        display: block;

        &.name,
        &.role {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 170px;
          flex-wrap: wrap;
        }

        &.name {
          color: ${Colors.blue};
          font-size: 12px;
        }
        &.role {
          font-size: 10px;
          color: ${Colors.grey};
        }
      }

      .user-type {
        font-size: 9px;
        color: ${colors.grey};
      }
    }
  }

  .tag {
    font-size: 9px;
    color: ${colors.grey};
    padding: 0;
    margin-top: -3px;
    background-color: ${colors.blueLight};
    padding: 1px 5px;
  }

  .right {
    display: flex;

    .right-col {
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-right: 1px solid ${Colors.blueLight};
      border-bottom: 1px solid ${Colors.blueLight};
      flex: 1;
      font-size: 10px;
      background-color: #f4fff6;

      .overtime {
        background: #ed6f5d;
        color: #fff;
        padding: 0 5px;
        border-radius: 5px;
      }
    }

    .clocking-time {
      background-color: rgb(96, 205, 255);
      padding: 2px 5px;
      border-radius: 5px;
    }
  }
`;

export default UserShiftRowStyled;
