import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Form, Modal, Select, message } from 'antd';
import { IQuotaTemplate } from '@/types/insights/quota-template.model';
import colors from '@/styles/colors';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import { InitialStateType, PickerType } from '../hours/manage/redux/store';
import { useSelector } from 'react-redux';
import { getDatesBetweenDates, handleError } from '@/utils';
import moment from 'moment';

interface Props {
  className?: string;
  visible: boolean;
  picker: PickerType;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalEditQuotaTemplate: React.FC<Props> = ({ className, visible, picker, setVisible }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const {
    state: { activeDepartment, activeSection, quotaTemplates, loadingQuotaTemplates, quotaCustomTemplates },
    dispatch,
  } = useContext(AppContext);
  const { selectedDate } = useSelector(({ selectedDate }: InitialStateType) => ({
    selectedDate,
  }));

  const onCancel = () => {
    setVisible(false);
  };

  const onFinish = () => {
    const { quota_template_id } = form.getFieldsValue();
    const activeQuotaTemplate = quotaTemplates.find((template) => template.id == quota_template_id);
    if (!activeQuotaTemplate) return;

    if (picker == 'day') {
      const customTemplateDay = quotaCustomTemplates.find(
        (template) => template.day == selectedDate.format('YYYY-MM-DD'),
      );

      if (customTemplateDay) {
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}/v3/quotas/quota-custom-templates/${customTemplateDay.id}`,
            {
              templateId: quota_template_id,
              day: selectedDate.format('YYYY-MM-DD'),
            },
            {
              params: {
                departmentId: activeDepartment?.id,
                sectionId: activeSection,
              },
            },
          )
          .then(({ data }) => {
            setVisible(false);
            dispatch({
              type: 'SET_QUOTA_CUSTOM_TEMPLATES',
              payload: quotaCustomTemplates.map((template) => (template.id == data.id ? data : template)),
            });
            message.success(t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.QUOTA_WEEK_EDITED'));
          })
          .catch((err) => {
            handleError(err);
          })
          .finally(() => {});
      } else {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v3/quotas/quota-custom-templates`,
            {
              templateId: quota_template_id,
              day: selectedDate.format('YYYY-MM-DD'),
            },
            {
              params: {
                departmentId: activeDepartment?.id,
                sectionId: activeSection,
              },
            },
          )
          .then(({ data }) => {
            setVisible(false);
            dispatch({
              type: 'SET_QUOTA_CUSTOM_TEMPLATES',
              payload: [...quotaCustomTemplates, ...data],
            });
            message.success(t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.QUOTA_WEEK_EDITED'));
          })
          .catch((err) => {
            handleError(err);
          })
          .finally(() => {});
      }
    } else {
      const customTemplateWeek = quotaCustomTemplates.find(
        (template) => template.week_number == selectedDate.week() && template.year == selectedDate.year(),
      );
      if (customTemplateWeek) {
        const weekNumber = selectedDate.week();
        const year = selectedDate.year();

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v3/quotas/quota-custom-templates/${customTemplateWeek.id}`,
            {
              templateId: quota_template_id,
              weekNumber,
              year,
            },
            {
              params: {
                departmentId: activeDepartment?.id,
                sectionId: activeSection,
              },
            },
          )
          .then(({ data }) => {
            setVisible(false);
            dispatch({
              type: 'SET_QUOTA_CUSTOM_TEMPLATES',
              payload: quotaCustomTemplates.map((template) => (template.id == data.id ? data : template)),
            });
            message.success(t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.QUOTA_WEEK_EDITED'));
          })
          .catch((err) => {
            handleError(err);
          })
          .finally(() => {});
      } else {
        const weekNumber = selectedDate.week();
        const year = selectedDate.year();

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v3/quotas/quota-custom-templates`,
            {
              templateId: quota_template_id,
              weekNumber,
              year,
            },
            {
              params: {
                departmentId: activeDepartment?.id,
                sectionId: activeSection,
              },
            },
          )
          .then(({ data }) => {
            setVisible(false);
            dispatch({
              type: 'SET_QUOTA_CUSTOM_TEMPLATES',
              payload: [...quotaCustomTemplates, ...data],
            });
            message.success(t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.QUOTA_WEEK_EDITED'));
          })
          .catch((err) => {
            handleError(err);
          })
          .finally(() => {});
      }
    }
  };

  return (
    <Modal
      className={className}
      maskClosable={true}
      visible={visible}
      onCancel={onCancel}
      onOk={onFinish}
      title={t('SCHEDULE.EDIT_QUOTA_TEMPLATE')}
      okText={t('GLOBAL.MODIFY')}
      cancelText={t('GLOBAL.QUIT')}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item label={t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.TITLE')} name="quota_template_id">
          <Select
            allowClear
            showSearch
            loading={loadingQuotaTemplates}
            placeholder={t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.TITLE')}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {quotaTemplates.map((quota: IQuotaTemplate) => (
              <Select.Option value={quota.id}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>{quota.name}</span>
                  {quota.default && <span>{t('GLOBAL.DEFAULT')}</span>}
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalEditQuotaTemplate)``;
