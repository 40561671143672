import { IDepartment } from '@/types/department.model';
import { Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IResponse } from '.';

const { Option } = Select;

interface Props {
  className?: string;
  department?: IDepartment;
  form: FormInstance;
  meta?: IResponse['meta'];
}

const Contract: React.FC<Props> = ({ className, department, form, meta }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <div className={className}>
      <Row gutter={[20, 10]}>
        {/*
        <Col lg={8} md={12}>
          <Form.Item name={'contract_number'} label={t('CONTRACTS.CONTRACT_NUMBER')}>
            <Input maxLength={255} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item
            name={'contract_type'}
            label={t('USERS.USER.HR.CONTRACT_TYPE')}
            rules={[{ required: true, message: '' }]}
          >
            <Select style={{ width: '100%' }}>
              {meta?.['contract_type']?.map(({ id, name }) => (
                <Option key={`contract_type_${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('GLOBAL.START_DATE')} name={'contract_start'} rules={[{ required: true, message: '' }]}>
            <DatePicker
              style={{ width: '100%' }}
              format={'L'}
              onSelect={(e) => {
                form.setFieldsValue({
                  contract_start: e,
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('GLOBAL.END_DATE')} name={'contract_end'}>
            <DatePicker
              style={{ width: '100%' }}
              format={'L'}
              onSelect={(e) => {
                form.setFieldsValue({
                  contract_end: e,
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('GLOBAL.STATUS')} name={'profession_code'} rules={[{ required: true, message: '' }]}>
            <Select style={{ width: '100%' }}>
              {meta?.['profession_code']?.map(({ id, name }) => (
                <Option key={`profession_code_${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
              */}
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.EMPLOYEE_CODE')} name={'employee_code'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.JOINT_COMMITTEE')} name={'joint_committee'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.INDEX_NSSO')} name={'index_nsso'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.SERVICE_CHARGE_TYPE')} name={'service_charge_type'}>
            <Select style={{ width: '100%' }}>
              {meta?.['service_charge_type']?.map(({ id, name }) => (
                <Option key={`service_charge_type_${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('GLOBAL.DEPARTMENT')} name={'department'}>
            <Select style={{ width: '100%' }}>
              {meta?.['department']?.map(({ id, name }) => (
                <Option key={`department_${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.SORT_DEPARTMENT')} name={'sort_department'}>
            <Select style={{ width: '100%' }}>
              {meta?.['sort_department']?.map(({ id, name }) => (
                <Option key={`sort_department_${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.WAGE_CATEGORY')} name={'wage_category'}>
            <Select style={{ width: '100%' }}>
              {meta?.['wage_category']?.map(({ id, name }) => (
                <Option key={`wage_category_${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.FUNCTION_CLASSIFICATION')} name={'function_classification'}>
            <Select style={{ width: '100%' }}>
              {meta?.['function_classification']?.map(({ id, name }) => (
                <Option key={`function_classification_${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item
            label={t('USERS.USER.HR.FUNCTION_NAME')}
            name={'function_name'}
            rules={[{ required: true, message: '' }]}
          >
            <Select style={{ width: '100%' }}>
              {meta?.['function_name']?.map(({ id, name }) => (
                <Option key={`function_name_${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.FUNCTION_ON_PAYSLIP')} name={'function_on_payslip'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default styled(Contract)``;
