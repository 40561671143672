import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Button, Form, Modal, Select, Tooltip } from 'antd';
import { IHrSheetCode } from '@/types/reports/timesheets/code.model';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { getDatesBetweenDates } from '@/utils';
import moment from 'moment';
import { IHrSheet } from '@/types/reports/timesheets/sheet.model';

interface Props {
  className?: string;
  visible: boolean;
  codes: IHrSheetCode[];
  newCodes: any;
  timesheetsKeys: string[];
  form: FormInstance<any>;
  sheet: IHrSheet;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNewCodes: React.Dispatch<React.SetStateAction<IHrSheetCode[]>>;
}

const ModalAddNewCode: React.FC<Props> = ({
  className,
  visible,
  codes,
  newCodes,
  timesheetsKeys,
  form,
  sheet,
  setVisible,
  setNewCodes,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [newCode, setNewCode] = useState<any>(null);
  const [newCodeForm] = useForm();

  useEffect(() => {
    if (!visible) return;
    newCodeForm.resetFields();
  }, [visible]);

  const onCancel = () => {
    setVisible(false);
  };

  const onOk = () => {
    if (!newCode) return;
    setNewCodes([
      ...[...newCodes, newCode].filter((code) => code.key !== 'sent-new--1'),
      ...[...newCodes].filter((code) => code.key == 'sent-new--1'),
    ]);
    setVisible(false);
    const sentValues = form.getFieldsValue();
    let monthDates = getDatesBetweenDates(moment.unix(sheet.PARAMS.range.start), moment.unix(sheet.PARAMS.range.end));
    form.setFieldsValue({
      sent: {
        ...sentValues,
        [newCode.codeId]: {},
      },
    });
  };

  const onChange = (id: string) => {
    const code = codes.find((code) => code.id == id);
    if (!code) return;
    setNewCode({
      key: `sent-${code.id}-new-${newCodes.length + 1}`,
      title: (
        <Tooltip
          overlay={
            <span>
              {code.id} - {code.name}
            </span>
          }
        >
          <div className="tooltip-trigger">
            <span className="code-id">{code.id}</span>
            <span>{code.name}</span>
          </div>
        </Tooltip>
      ),
      data: {},
      codeId: code.id,
    });
  };

  return (
    <Modal
      className={className}
      maskClosable={true}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={t('REPORTS.TIMESHEETS.ADD_NEW_CODE')}
    >
      <Form form={newCodeForm} layout="vertical">
        <Form.Item name="new-code" label="Code">
          <Select
            className="new-code-select"
            placeholder={t('REPORTS.TIMESHEETS.ADD_NEW_CODE')}
            onChange={onChange}
            showSearch
            filterOption={(input, option) => {
              if (input && option?.children && option.children.length > 0) {
                return option.children.some((element: string) => element.toLowerCase().includes(input.toLowerCase()));
              }
              return false;
            }}
            allowClear
          >
            {codes
              .filter(
                (code) =>
                  !timesheetsKeys.includes(code.id) && newCodes.find((c: any) => c.codeId == code.id) == undefined,
              )
              .map((code) => (
                <Select.Option key={code.id} value={code.id}>
                  {code.id} - {code.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalAddNewCode)`
  .new-code-select {
    width: 100%;
    margin-top: 5px;
  }
`;
