import { isNullOrUndefined } from '@/utils';
import { AutoComplete, Form } from 'antd';
import React, { useRef } from 'react';
import moment, { Moment } from 'moment';
import { minutesToHoursAndOrMinutes } from '@/utils';
import { Rule } from 'antd/lib/form';
import { InternalNamePath } from 'antd/lib/form/interface';

interface Props {
  name: string | number | InternalNamePath | undefined;
  label?: string;
  style?: React.CSSProperties;
  start?: number;
  maxHours?: number;
  displayDuration?: boolean;
  rules?: Rule[];
  placeholder?: string;
  fieldKey?: string | number | (string | number)[] | undefined;
  changeTime?: ({ hour, minute }: { hour: number | null; minute: number | null }) => void;
  allowClear?: boolean;
  initialValue?: string;
  show24?: boolean;
}

const TimePickerFormItem: React.FC<Props> = ({
  name,
  maxHours,
  label,
  style,
  rules,
  placeholder,
  fieldKey,
  changeTime,
  start,
  displayDuration,
  allowClear = true,
  initialValue,
  show24,
}) => {
  const selectRef = useRef<any>(null);

  const getHoursAndMinutes = (value: string): { hour: number | null; minute: number | null } => {
    let hour = null;
    let minute = null;

    if (start) {
      const matchHour = /^([0-9]{0,2})/.exec(value);
      const matchMinute = /((:|-|h)(?<minute>[0-9]{0,2}))$/.exec(value);

      if (matchHour) {
        const hourInt = parseInt(matchHour[0]);
        if (hourInt || hourInt === 0) {
          hour = hourInt > 23 ? 23 : hourInt;
          minute = 0;
          if (matchMinute) {
            const minuteString = matchMinute?.groups?.minute || '';
            const minuteInt = parseInt(minuteString);
            if (minuteInt || minuteInt === 0) {
              minute = minuteInt > 59 ? 59 : minuteInt;
            }
          }
        }
      }
    } else {
      const matchHour = /^([0-9]{0,2})(:|-|h)/.exec(value);
      const matchMinute = /((:|-|h)?(?<minute>[0-9]{0,2}))$/.exec(value);

      const hourInt = parseInt(matchHour?.[0] || '0');
      if (hourInt || hourInt === 0) {
        hour = hourInt > 23 ? 23 : hourInt;
        minute = 0;
        if (matchMinute) {
          const minuteString = matchMinute?.groups?.minute || '';
          const minuteInt = parseInt(minuteString);
          if (minuteInt || minuteInt === 0) {
            minute = minuteInt > 59 ? 59 : minuteInt;
          }
        }
      }
    }

    return {
      hour,
      minute,
    };
  };

  const createHours = (start = 0, maxHours = 24): { value: string }[] => {
    const results: { value: string; label?: any }[] = [];
    if (start === 0) {
      for (let minutes = 15; minutes <= 60 * maxHours; minutes += 15) {
        const duration = moment.duration(start).add(minutes, 'minutes');
        results.push({
          value: `${duration.hours().toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}`,
          label: (
            <div>
              <span style={{ color: 'grey', fontStyle: 'italic' }}>
                {minutesToHoursAndOrMinutes(duration.asMinutes())}
              </span>
            </div>
          ),
        });
      }
    } else {
      const startMoment = moment.unix(start);
      for (let minutes = 0; minutes < 60 * maxHours; minutes += 15) {
        if (displayDuration && !minutes) {
          continue;
        }
        const currentMoment = moment.unix(start).add('minutes', minutes);
        const stringValue = `${currentMoment
          .hours()
          .toString()
          .padStart(2, '0')}:${currentMoment.minutes().toString().padStart(2, '0')}`;
        results.push({
          value: stringValue,
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>{stringValue}</span>
              {displayDuration && (
                <span style={{ marginLeft: '20px', color: 'grey', fontStyle: 'italic' }}>
                  ({minutesToHoursAndOrMinutes(moment.duration(currentMoment.diff(startMoment)).asMinutes())})
                </span>
              )}
            </div>
          ),
        });
      }
      if (show24) {
        results.push({
          value: '24:00',
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>24:00</span>
            </div>
          ),
        });
      }
    }
    return results;
  };

  const onChangeValue = (value: string) => {
    const { hour, minute } = getHoursAndMinutes(value);
    if (changeTime) {
      changeTime({
        hour,
        minute,
      });
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLElement>) => {
    const value = (e.target as HTMLInputElement).value;
    onChangeValue(value);
  };

  return (
    <Form.Item
      fieldKey={fieldKey}
      rules={rules}
      name={name}
      label={label}
      style={style}
      initialValue={initialValue}
      getValueFromEvent={(value) => {
        if (!isNullOrUndefined(value)) {
          const cleanValue = value.match(/^([0-9]{0,2})((:|h)([0-9]{0,2}))?/g)?.join() || null;
          return cleanValue;
        } else {
          if (changeTime) {
            changeTime({
              hour: null,
              minute: null,
            });
          }
          return '';
        }
      }}
    >
      <AutoComplete
        placeholder={placeholder}
        getPopupContainer={(trigger) => trigger}
        allowClear={allowClear}
        dropdownMatchSelectWidth={displayDuration ? 120 : undefined}
        ref={selectRef}
        onSelect={() => {
          setTimeout(() => selectRef?.current?.blur(), 0);
        }}
        filterOption={(inputValue, option) => {
          if (inputValue.length > 3) {
            return true;
          }
          return option!.value.startsWith(inputValue);
        }}
        onBlur={onBlur}
        options={createHours(start, maxHours)}
      />
    </Form.Item>
  );
};

export default TimePickerFormItem;
