import AppContext from '@/pages/app/context';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IHeaderBadges } from '@/types/header-badges.model';
import { isFeatureEnabled } from '@/utils';
import { Badge, Menu } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface Props {
  access?: IDepartment['access'];
  department?: IDepartment;
  scheduleBadges: IHeaderBadges['schedule'];
}

const MenuClocking: React.FC<Props> = (props) => {
  const {
    state: { features },
  } = useContext(AppContext);
  const { access, scheduleBadges, department, ...otherProps } = props;
  const { t } = useTranslation(undefined, { useSuspense: true });

  return (
    <Menu style={{ maxHeight: 'calc(100vh - 80px)', overflowY: 'auto' }} {...otherProps}>
      <Menu.ItemGroup title={t('MENU.CLOCKINGS.TITLE')}>
        <Menu.Item key="clocking-verification">
          <NavLink
            activeClassName="is-active"
            to={`/app/clockings/${moment().startOf('week').format('YYYY-MM-DD')}`}
            className="menu_hours_clockings"
          >
            {t('MENU.CLOCKINGS.MANAGE')}
            <Badge count={scheduleBadges?.clockinVerification} />
          </NavLink>
        </Menu.Item>
        {!department?.clockinParams?.approveFreeclockin && isFeatureEnabled(features, FEATURES.SCHEDULE) && (
          <Menu.Item key="clocking-verification">
            <NavLink
              activeClassName="is-active"
              to="/app/clockings/clocking-validation"
              className="menu_hours_clocking-validation"
            >
              {t('MENU.CLOCKINGS.VALIDATION')}
              <Badge count={scheduleBadges?.clockinVerification} />
            </NavLink>
          </Menu.Item>
        )}
      </Menu.ItemGroup>
    </Menu>
  );
};

export default MenuClocking;
