import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';
import { Form, InputNumber, Switch } from 'antd';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { getWindowSize, handleError } from '@/utils';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const ShiftSwapSettings: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [shiftSwapActive, setShiftSwapActive] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const onActivateShiftSwapChange = (checked: boolean) => {
    setShiftSwapActive(checked);
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.SCHEDULES.SHIFT_SWAP.ACTIVATE_SHIFT_SWAP.LABEL'),
          description: t('SETTINGS.SCHEDULES.SHIFT_SWAP.ACTIVATE_SHIFT_SWAP.DESCRIPTION'),
          style: { margin: 0 },
          name: ['schedule', 'swap_shift', 'active'],
          valuePropName: 'checked',
          element: (
            <Switch
              defaultChecked={currentSettings?.schedule?.swap_shift?.active}
              onChange={onActivateShiftSwapChange}
            />
          ),
          show: true,
        },
        {
          label: t('SETTINGS.SCHEDULES.SHIFT_SWAP.MIN_DAYS.LABEL'),
          description: t('SETTINGS.SCHEDULES.SHIFT_SWAP.MIN_DAYS.DESCRIPTION'),
          name: ['schedule', 'swap_shift', 'max_request_date'],
          style: { margin: 0 },
          element: (
            <InputNumber
              type="number"
              min={0}
              step={1}
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
            />
          ),
          elementAddon: <span style={{ marginLeft: 15 }}>{t('GLOBAL.DAY(S)')}</span>,
          show: shiftSwapActive,
          elementBelow: windowSize.innerWidth <= 900,
        },
        {
          label: t('SETTINGS.SCHEDULES.SHIFT_SWAP.AUTO_APPROVE.LABEL'),
          description: t('SETTINGS.SCHEDULES.SHIFT_SWAP.AUTO_APPROVE.DESCRIPTION'),
          style: { margin: 0 },
          name: ['schedule', 'swap_shift', 'auto_approve'],
          valuePropName: 'checked',
          element: <Switch defaultChecked={currentSettings?.schedule?.swap_shift?.auto_approve} />,
          show: shiftSwapActive,
        },
        {
          label: t('SETTINGS.SCHEDULES.SHIFT_SWAP.NOTIFY_NEW_REQUEST.LABEL'),
          description: t('SETTINGS.SCHEDULES.SHIFT_SWAP.NOTIFY_NEW_REQUEST.DESCRIPTION'),
          style: { margin: 0 },
          name: ['schedule', 'swap_shift', 'message_each_request'],
          valuePropName: 'checked',
          element: <Switch defaultChecked={currentSettings?.schedule?.swap_shift?.message_each_request} />,
          show: shiftSwapActive,
        },
        {
          label: t('SETTINGS.SCHEDULES.SHIFT_SWAP.NOTIFY_NEW_TRANSFER.LABEL'),
          description: t('SETTINGS.SCHEDULES.SHIFT_SWAP.NOTIFY_NEW_TRANSFER.DESCRIPTION'),
          style: { margin: 0 },
          name: ['schedule', 'swap_shift', 'message_on_transfert'],
          valuePropName: 'checked',
          element: <Switch defaultChecked={currentSettings?.schedule?.swap_shift?.message_on_transfert} />,
          show: shiftSwapActive,
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    if (currentSettings?.schedule) {
      setShiftSwapActive(currentSettings.schedule.swap_shift?.active ? true : false);
    }
    form.setFieldsValue(currentSettings);
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings(values)
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.SCHEDULES.SHIFT_SWAP.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(ShiftSwapSettings)``;
