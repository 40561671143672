import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';
import { Button, Form, Switch } from 'antd';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { handleError } from '@/utils';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const SectionSettings: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.SECTIONS.SETTINGS.FORCE_SECTION.LABEL'),
          description: t('SETTINGS.SECTIONS.SETTINGS.FORCE_SECTION.DESCRIPTION'),
          name: ['schedule', 'force_section'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.schedule?.force_section} />,
          show: true,
        },
        {
          label: t('SETTINGS.SECTIONS.SETTINGS.DISPLAY_OTHER_SECTIONS_SHIFTS.LABEL'),
          description: t('SETTINGS.SECTIONS.SETTINGS.DISPLAY_OTHER_SECTIONS_SHIFTS.DESCRIPTION'),
          name: ['schedule', 'ghost_shifts'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.schedule?.ghost_shifts} />,
          show: true,
        },
        {
          label: t('SETTINGS.SECTIONS.SETTINGS.MONTH_VIEW_PER_SECTION.LABEL'),
          description: t('SETTINGS.SECTIONS.SETTINGS.MONTH_VIEW_PER_SECTION.DESCRIPTION'),
          name: ['schedule', 'monthly_group_by_sections'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.schedule?.monthly_group_by_sections} />,
          show: true,
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    form.setFieldsValue(currentSettings);
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings(values)
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.SECTIONS.SETTINGS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(SectionSettings)``;
