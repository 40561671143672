import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import frFR from 'antd/lib/locale/fr_FR';
import nlNL from 'antd/lib/locale/nl_NL';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import Error404 from './components/Error404';
import PrivateRoute from './components/PrivateRoute';
import { PusherProvider } from './components/PusherProvider';
import AuthContext from './context';
import AppPage from './pages/app';
import ChangePassword from './pages/change-password';
import ConfirmEmailPage from './pages/confirm-email';
import ForgotPasswordPage from './pages/forgot-password';
import HorecaExpoPage from './pages/horeca-expo';
import LoginPage from './pages/login';
import ResourceSubscribePage from './pages/resource-subscribe';
import SchedulePage from './pages/schedule';
import SelfServicePage from './pages/self-service';
import ShiftsHistory from './pages/shifts-history';
import SubscribePage from './pages/subscribe';
import './styles/antdesign.less';

type TLanguages = 'fr' | 'en' | 'nl' | 'es';

const languages = {
  fr: frFR,
  en: enUS,
  nl: nlNL,
  es: esES,
};

const App: React.FC = () => {
  const { state: authContextState } = useContext(AuthContext);
  const {
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });

  return (
    <PusherProvider>
      <ConfigProvider locale={languages[language as TLanguages]}>
        <Helmet htmlAttributes={{ lang: language }} />
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPasswordPage />
          </Route>
          <Route exact path="/change-password">
            <ChangePassword />
          </Route>
          <Route exact path="/subscribe/:id">
            <SubscribePage />
          </Route>
          <Route exact path="/resources-subscribe/:id">
            <ResourceSubscribePage />
          </Route>
          <Route exact path="/schedule/:token">
            <SchedulePage />
          </Route>
          <Route exact path="/horeca-expo">
            <HorecaExpoPage />
          </Route>
          <Route exact path="/self-service/:hash">
            <SelfServicePage />
          </Route>
          <Route exact path="/confirmEmail">
            <ConfirmEmailPage />
          </Route>
          <Route exact path="/confirmTrialEmail">
            <ConfirmEmailPage trial />
          </Route>
          <Route exact path="/shifts-history">
            <ShiftsHistory />
          </Route>
          <PrivateRoute path="/app" component={AppPage} />
          <Route exact path="/" render={() => <Redirect to="/app" />} />
          <Route component={() => <Error404 />} />
        </Switch>
        {/* {authContextState.gitSha &&
          process.env.REACT_APP_SENTRY_RELEASE &&
          process.env.REACT_APP_SENTRY_RELEASE !== authContextState.gitSha && <NewVersionStrip />} */}
      </ConfigProvider>
    </PusherProvider>
  );
};

export default App;
