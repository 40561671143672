import colors from '@/styles/colors';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { POSColorType } from '@/types/pos-colors.model';
import { Tooltip } from 'antd';
import React from 'react';
import { ChromePicker } from 'react-color';
import POSColorBox from './POSColorBox';

interface Props {
  color: POSColorType;
  posColors: POSColorType[];
  currentSettings?: IDepartmentSettings | null;
  setPosColors: React.Dispatch<React.SetStateAction<POSColorType[]>>;
  setFormHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const POSColor: React.FC<Props> = ({ color, posColors, currentSettings, setPosColors, setFormHasChanged }) => {
  return (
    <div>
      <span>{color.title}</span>
      <div style={{ display: 'flex', gap: 15, marginTop: 18 }}>
        {color.colors.map((box) => (
          <POSColorBox
            color_key={color.key}
            color={box}
            selected={color.selected}
            _default={color.colors[0]}
            posColors={posColors}
            setPosColors={setPosColors}
            setFormHasChanged={setFormHasChanged}
            currentSettings={currentSettings}
          />
        ))}
        <POSColorBox
          color_key={color.key}
          color={null}
          selected={color.selected}
          _default={color.colors[0]}
          posColors={posColors}
          setPosColors={setPosColors}
          setFormHasChanged={setFormHasChanged}
          currentSettings={currentSettings}
        />
      </div>
    </div>
  );
};

export default POSColor;
