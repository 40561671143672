import { Button } from 'antd';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../redux/actions';

interface Props {
  small?: boolean;
  shiftNotApprovedIds: string[];
  shiftsToApprove: string[];
  isLoading: boolean;
}

const PublishShifts: React.FC<Props> = ({ small, shiftNotApprovedIds, shiftsToApprove, isLoading }) => {
  const { t } = useTranslation();
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const onClick = () => {
    hoursDispatch({ type: 'SET_SHIFT_IDS_TO_PUBLISH', payload: [...shiftsToApprove] });
  };

  return (
    <>
      {!isLoading && shiftNotApprovedIds.length > 0 && (
        <Button
          type="primary"
          style={{ width: small ? 'auto' : '160px', flex: 1, borderRadius: 8, padding: '5px 15px' }}
          onClick={onClick}
        >
          {t('SCHEDULE.PUBLISH_X_SHIFT(S)', { number: shiftsToApprove.length })}
        </Button>
      )}
    </>
  );
};

export default PublishShifts;
