import TableView from '@/layouts/TableView';
import { Checkbox, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Header from './components/Header';
import AppContext from '@/pages/app/context';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ICost } from '@/types/cost.model';
import TableRowActions from './components/TableRowActions';
import DrawerCost from '@/pages/app/components/drawers/Cost';
import ModalDeleteCost from './modals/ModalDeleteCost';
import axios from 'axios';
import Color from '@/pages/app/components/Color';

interface Props {
  className?: string;
}

const SortableElementItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerItem = SortableContainer((props: any) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />);

const ClockingCosts: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, loadingCosts, costs },
    dispatch,
  } = useContext(AppContext);
  const [columns, setColumns] = useState<any[]>([]);
  const [activeCost, setActiveCost] = useState<ICost | null>(null);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [costToDelete, setCostToDelete] = useState<ICost | null>(null);

  useEffect(() => {
    setColumns([
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      {
        title: t('GLOBAL.ID'),
        dataIndex: 'id',
        key: 'id',
        width: 100,
        sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('SETTINGS.TOOLBOX.COSTS.COLUMNS.FORMAT'),
        dataIndex: 'format',
        key: 'format',
      },
      {
        title: t('GLOBAL.MANDATORY'),
        dataIndex: 'mandatory',
        key: 'mandatory',
        render: (text: string, record: ICost) => {
          return <Checkbox checked={record.mandatory} disabled />;
        },
      },
      {
        title: t('GLOBAL.COLOR'),
        dataIndex: 'color',
        key: 'color',
        width: 100,
        render: (text: string, record: ICost) => {
          return <Color style={{ background: record.color }} />;
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right',
        width: 250,
        render: (text: string, record: ICost) => (
          <TableRowActions
            cost={record}
            setActiveCost={setActiveCost}
            setDrawerVisible={setDrawerVisible}
            setCostToDelete={setCostToDelete}
          />
        ),
      },
    ]);
  }, [costs]);

  useEffect(() => {
    setDrawerVisible(activeCost != null);
  }, [activeCost]);

  const onSearchChange = () => {};

  const onCreate = () => {
    setDrawerVisible(true);
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const oldCosts = [...costs];
    if (costs && oldIndex !== newIndex) {
      dispatch({
        type: 'SET_LOADING_COSTS',
        payload: true,
      });
      const newCosts: ICost[] = arrayMove(costs, oldIndex, newIndex);
      dispatch({
        type: 'SET_COSTS',
        payload: newCosts,
      });
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/costs`,
          {
            ids: newCosts.map((x) => x.id),
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }) => {
          dispatch({
            type: 'SET_COSTS',
            payload: data,
          });
          dispatch({
            type: 'SET_LOADING_COSTS',
            payload: false,
          });
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: 'SET_COSTS',
            payload: oldCosts,
          });
          dispatch({
            type: 'SET_LOADING_COSTS',
            payload: false,
          });
        });
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerItem useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = costs.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableElementItem index={index} {...restProps} />;
  };

  return (
    <div className={className}>
      <h2>{t('GLOBAL.COSTS')}</h2>
      <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10, marginTop: 25 }}>
        <TableView>
          <Header disabled={false} onSearchChange={onSearchChange} onCreate={onCreate} />
          <Table
            loading={loadingCosts}
            dataSource={costs}
            columns={columns}
            rowKey="id"
            pagination={false}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          />
        </TableView>
      </div>
      <DrawerCost
        cost={activeCost}
        visible={drawerVisible}
        setActiveCost={setActiveCost}
        setDrawerVisible={setDrawerVisible}
      />
      {costToDelete ? <ModalDeleteCost costToDelete={costToDelete} setCostToDelete={setCostToDelete} /> : null}
    </div>
  );
};

export default styled(ClockingCosts)``;
