import { getWindowSize } from '@/utils';
import { Button, Input, Select } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  code: any;
  hrPartners: any[];
  customHrCodesEdited: any[];
  setCustomHrCodesEdited: (arr: any[]) => void;
  onRemove: () => void;
  setFormHasChanged: Dispatch<SetStateAction<boolean>>;
}

const SpecialCode: React.FC<Props> = ({
  className,
  code,
  hrPartners,
  customHrCodesEdited,
  setCustomHrCodesEdited,
  onRemove,
  setFormHasChanged,
}) => {
  const { description, code: social_code } = code;
  const [codeObj, setCodeObj] = useState<any>();
  const [newDescription, setNewDesription] = useState<string>(description);
  const [newCode, setNewCode] = useState<string>(social_code);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    hrPartners.map((hr) =>
      hr.codes.map((code: any) => {
        if (code.id === social_code) {
          setCodeObj(code);
        }
      }),
    );
  }, [hrPartners]);

  const onDescriptionChange = (e: any) => {
    setFormHasChanged(true);
    setNewDesription(e.target.value);

    setCustomHrCodesEdited(
      customHrCodesEdited.map((c) => (c.code === e.target.id ? { ...c, description: e.target.value } : c)),
    );
  };

  const onCodeChange = (value: any) => {
    setFormHasChanged(true);
    const newCodeCopy = newCode;
    setNewCode(value);

    setCustomHrCodesEdited(customHrCodesEdited.map((c) => (c.code === newCodeCopy ? { ...c, code: value } : c)));
  };

  return (
    <div className={className}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          marginTop: 15,
        }}
      >
        <div style={{ display: 'flex', width: '100%', flexDirection: windowSize.innerWidth <= 900 ? 'column' : 'row' }}>
          <Input
            id={codeObj?.id}
            value={newDescription}
            onChange={onDescriptionChange}
            style={{ flex: 1, marginRight: 15 }}
          />
          <Select
            onChange={onCodeChange}
            value={newCode}
            style={{ flex: 1 }}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            showSearch
          >
            {hrPartners.map((hr) =>
              hr.codes.map((code: any) => (
                <Select.Option key={`code_${code.id}`} value={`${code.id}`}>
                  {`${code.name} (${code.code})`}
                </Select.Option>
              )),
            )}
          </Select>
        </div>
        <div>
          <Button
            type="primary"
            danger
            style={{ marginLeft: 15, padding: windowSize.innerWidth <= 900 ? '5px' : '3px 5px' }}
            onClick={onRemove}
          >
            <i className="icon-minus"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default styled(SpecialCode)`
  width: 100%;
  @media screen and (max-width: 900px) {
  }
`;
