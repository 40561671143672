import Pusher from 'pusher-js';
import React, { useContext, useEffect, useRef } from 'react';
import AuthContext from '../context';

const PusherContext = React.createContext<Pusher | undefined>(undefined);
export const usePusher = () => useContext(PusherContext);

const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY!;
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER!;

export const PusherProvider: React.FC = (props) => {
  const { state: authContextState } = useContext(AuthContext);
  const clientRef = useRef<Pusher>();

  useEffect(() => {
    if (!clientRef.current) {
      try {
        clientRef.current = new Pusher(PUSHER_APP_KEY, {
          cluster: PUSHER_CLUSTER,
          forceTLS: true,
          authEndpoint: `${process.env.REACT_APP_API_URL!.slice(0, -4)}/broadcasting/auth`,
          auth: {
            headers: {
              Authorization: `Bearer ${authContextState.accessToken}`,
            },
          },
        });
      } catch (err) {
        console.log(err);
      }
    }

    return () => {
      if (clientRef.current) {
        clientRef.current.disconnect();
      }
    };
  }, [clientRef]);

  return <PusherContext.Provider value={clientRef?.current}>{props.children}</PusherContext.Provider>;
};
