import React, { Dispatch, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import OperationalInterval from './components/Interval';
import OperationalRow from './components/Row';
import { useRouteMatch } from 'react-router-dom';
import { IShiftsByUser, InitialStateType, PickerType } from '../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ActionType } from '../redux/actions';
import { generateRandomColor, getContrast, hexToRgb, lighten } from '@/utils';
import NowRow from './components/NowRow';
import AppContext from '@/pages/app/context';
import { OpsFilter } from '@/types/ops-filter.model';
import OpsShift from './components/Shift';
import { IUser } from '@/types/user.model';
import { Spin } from 'antd';

interface Props {
  className?: string;
}

const ALL_INTERVALS = [
  ['00:00'],
  ['01:00'],
  ['02:00'],
  ['03:00'],
  ['04:00'],
  ['05:00'],
  ['06:00'],
  ['07:00'],
  ['08:00'],
  ['09:00'],
  ['10:00'],
  ['11:00'],
  ['12:00'],
  ['13:00'],
  ['14:00'],
  ['15:00'],
  ['16:00'],
  ['17:00'],
  ['18:00'],
  ['19:00'],
  ['20:00'],
  ['21:00'],
  ['22:00'],
  ['23:00'],
];
let INTERVALS = ALL_INTERVALS;

const AppHoursManageHourlyOperational: React.FC<Props> = ({ className }) => {
  const { picker, selectedDate, filteredShiftsByUsers, usersMap, isLoading } = useSelector(
    ({ picker, selectedDate, filteredShiftsByUsers, usersMap, isLoading }: InitialStateType) => ({
      picker,
      selectedDate,
      filteredShiftsByUsers,
      usersMap,
      isLoading,
    }),
  );
  const {
    state: { activeDepartment, activeSection, opsFilter, sections, userCategories, skills },
  } = useContext(AppContext);
  const [intervalHeight, setIntervalHeight] = useState<number>(0);
  const [usersWithShiftsBySection, setUsersWithShiftsBySection] = useState<{
    [key: string]: IShiftsByUser[];
  }>({});
  const [usersWithShiftsBySkills, setUsersWithShiftsBySkills] = useState<{
    [key: string]: IShiftsByUser[];
  }>({});
  const [usersWithShiftsByStatus, setUsersWithShiftsByStatus] = useState<{
    [key: string]: IShiftsByUser[];
  }>({});
  const refInterval = useRef<any>(null);
  const contentRef = useRef<any>(null);
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const routeMatch = useRouteMatch();
  const paths = routeMatch.path.split('/');
  const currentPicker = paths[paths.length - 2] as PickerType;
  const { formatedDate } = routeMatch.params as any;

  useEffect(() => {
    if (picker !== currentPicker || selectedDate.format('YYYY-MM-DD') !== formatedDate) {
      let startDate;
      let endDate;

      switch (currentPicker) {
        case 'week':
          startDate = moment(formatedDate).startOf('isoWeek');
          endDate = moment(formatedDate).endOf('isoWeek');
          break;
        case 'month':
          startDate = moment(formatedDate).startOf('month');
          endDate = moment(formatedDate).endOf('month');
          break;
        case 'operational':
          startDate = moment(formatedDate).startOf('day');
          endDate = moment(formatedDate).endOf('day');
          break;
        default:
          startDate = moment(formatedDate).startOf('isoWeek');
          endDate = moment(formatedDate).endOf('isoWeek');
      }

      hoursDispatch({
        type: 'SET_PICKER_AND_SELECTED_DATE',
        payload: {
          picker: currentPicker,
          selectedDate: moment(formatedDate).valueOf(),
          startDate: startDate.valueOf(),
          endDate: endDate.valueOf(),
        },
      });
    }
    // eslint-disable-next-line
  }, [currentPicker, formatedDate]);

  useEffect(() => {
    if (refInterval.current) {
      setIntervalHeight(Number(refInterval.current.getBoundingClientRect().height.toFixed(2)));
    }
  }, [refInterval, refInterval.current]);

  const usersWithShifts = filteredShiftsByUsers
    .map((shiftByUser) => {
      const filteredShifts = shiftByUser.shifts.filter((shift) => shift.shyftType === 1 && shift.userRecordId !== null);
      return { ...shiftByUser, shifts: filteredShifts };
    })
    .filter((shiftByUser) => shiftByUser.user.recordId !== null && shiftByUser.shifts.length > 0)
    .sort((a, b) => {
      // Get the start time of the first shift for 'a' and 'b'
      const startTimeA = new Date(a.shifts[0].start!).getTime();
      const startTimeB = new Date(b.shifts[0].start!).getTime();

      // Sort by start time in ascending order
      if (startTimeA < startTimeB) {
        return -1;
      } else if (startTimeA > startTimeB) {
        return 1;
      }

      // If start times are equal, calculate and sort by shift length in descending order
      const shiftLengthA = a.shifts.reduce((totalLength, shift) => {
        const start = new Date(shift.start!).getTime();
        const end = new Date(shift.end!).getTime();
        return totalLength + (end - start);
      }, 0);

      const shiftLengthB = b.shifts.reduce((totalLength, shift) => {
        const start = new Date(shift.start!).getTime();
        const end = new Date(shift.end!).getTime();
        return totalLength + (end - start);
      }, 0);

      return shiftLengthB - shiftLengthA;
    });

  useEffect(() => {
    const allShifts = usersWithShifts.flatMap((userWithShifts) => userWithShifts.shifts);
    const earliestShift = allShifts.reduce((earliestShift, currentShift) => {
      const shiftStartDate = moment(currentShift.startDate);
      if (shiftStartDate.isBefore(moment(earliestShift.startDate))) {
        return currentShift;
      }
      return earliestShift;
    }, allShifts[0]);
    const latestShift = allShifts.reduce((latestShift, currentShift) => {
      const shiftEndDate = moment(currentShift.endDate);
      if (shiftEndDate.isAfter(moment(latestShift.endDate))) {
        return currentShift;
      }
      return latestShift;
    }, allShifts[0]);
    if (!earliestShift || !latestShift) return;
    INTERVALS = ALL_INTERVALS.filter((interval) => {
      if (moment(earliestShift.startDate).day() != moment(latestShift.endDate).day()) {
        if (
          moment(earliestShift.startDate).day() > moment(latestShift.endDate).day() &&
          Number(interval[0].split(':')[0]) >= 0 &&
          Number(interval[0].split(':')[0]) <= 23
        ) {
          return true;
        } else if (
          moment(earliestShift.startDate).day() < moment(latestShift.endDate).day() &&
          Number(interval[0].split(':')[0]) >= 0 &&
          Number(interval[0].split(':')[0]) <= 23
        ) {
          return true;
        }
      } else {
        if (
          Number(interval[0].split(':')[0]) >= moment(earliestShift.startDate).hour() - 1 &&
          Number(interval[0].split(':')[0]) <= moment(latestShift.endDate).hour() + 1
        ) {
          return true;
        }
      }
    });
  }, [usersWithShifts]);

  useEffect(() => {
    if (!contentRef || !contentRef.current) return;
    const shifts = usersWithShifts.map((shiftByUser) => shiftByUser.shifts).flat();
    const hours = [];
    for (let i = 0; i < shifts.length; i++) {
      const shift = shifts[i];
      const hourStart = moment.unix(shift.start!).hours();
      const hourEnd = moment.unix(shift.end!).hours();
      const middle = (hourEnd - hourStart) / 2;
      hours.push(middle);
    }
    const sum = hours.reduce((total, num) => total + num, 0);
    const average = Math.ceil(sum / hours.length) - 4;

    const element = document.getElementById(`interval-${average}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [usersWithShifts, opsFilter, contentRef]);

  useEffect(() => {
    if (!opsFilter) return;

    if (opsFilter == OpsFilter.SECTIONS) {
      const usersWithShiftsBySection: { [key: string]: IShiftsByUser[] } = {};
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        if (!section || !section.id) return;

        for (let j = 0; j < usersWithShifts.length; j++) {
          const { user, shifts } = usersWithShifts[j];
          if (!user.recordId) continue;
          const shiftsWithSection = shifts
            .filter((shift) => shift.section && shift.section.id === section.id)
            .sort((a, b) => {
              const startTimeA = new Date(a.start!).getTime();
              const endTimeA = new Date(a.end!).getTime();
              const shiftLengthA = endTimeA - startTimeA;

              const startTimeB = new Date(b.start!).getTime();
              const endTimeB = new Date(b.end!).getTime();
              const shiftLengthB = endTimeB - startTimeB;

              // Sort in descending order (longest to smallest shift)
              return shiftLengthB - shiftLengthA;
            });
          if (shiftsWithSection.length > 0) {
            if (section.id in usersWithShiftsBySection) {
              const elements = usersWithShiftsBySection[section.id];
              usersWithShiftsBySection[section.id] = [...elements, { user, shifts: shiftsWithSection }];
            } else {
              usersWithShiftsBySection[section.id] = [{ user, shifts: shiftsWithSection }];
            }
          } else {
            // if (!(section.id in usersWithShiftsBySection)) {
            //   usersWithShiftsBySection[section.id] = [];
            // }
          }
        }
      }
      setUsersWithShiftsBySection(usersWithShiftsBySection);
    }

    if (opsFilter == OpsFilter.SKILLS) {
      const usersWithShiftsBySkills: { [key: string]: IShiftsByUser[] } = {};
      for (let i = 0; i < skills.length; i++) {
        const skill = skills[i];
        if (!skill || !skill.id) return;

        for (let j = 0; j < usersWithShifts.length; j++) {
          const { user, shifts } = usersWithShifts[j];
          if (!user.recordId) continue;
          const shiftsWithSkill = shifts
            .filter((shift) => shift.skills && shift.skills.map((skill) => skill.id).includes(skill.id))
            .sort((a, b) => {
              const startTimeA = new Date(a.start!).getTime();
              const endTimeA = new Date(a.end!).getTime();
              const shiftLengthA = endTimeA - startTimeA;

              const startTimeB = new Date(b.start!).getTime();
              const endTimeB = new Date(b.end!).getTime();
              const shiftLengthB = endTimeB - startTimeB;

              // Sort in descending order (longest to smallest shift)
              return shiftLengthB - shiftLengthA;
            });
          if (shiftsWithSkill.length > 0) {
            if (skill.id in usersWithShiftsBySkills) {
              const elements = usersWithShiftsBySkills[skill.id];
              usersWithShiftsBySkills[skill.id] = [...elements, { user, shifts: shiftsWithSkill }];
            } else {
              usersWithShiftsBySkills[skill.id] = [{ user, shifts: shiftsWithSkill }];
            }
          } else {
            // if (!(section.id in usersWithShiftsBySection)) {
            //   usersWithShiftsBySection[section.id] = [];
            // }
          }
        }
      }
      setUsersWithShiftsBySkills(usersWithShiftsBySkills);
    }

    if (opsFilter == OpsFilter.STATUS) {
      const usersWithShiftsByStatus: { [key: string]: IShiftsByUser[] } = {};

      for (let j = 0; j < usersWithShifts.length; j++) {
        const { user, shifts } = usersWithShifts[j];
        if (!user || !user.recordId) continue;
        const found = usersMap.get(user.recordId);
        if (!found || !found.recordId || !found.userType) continue;
        const status = userCategories.find((status) => status.id == found.userType!.id);
        if (!status || !status.id) continue;
        if (shifts.length == 0) continue;

        if (status.id in usersWithShiftsByStatus) {
          const elements = usersWithShiftsByStatus[status.id];
          usersWithShiftsByStatus[status.id] = [...elements, { user, shifts: shifts }];
        } else {
          usersWithShiftsByStatus[status.id] = [{ user, shifts: shifts }];
        }
      }

      setUsersWithShiftsByStatus(usersWithShiftsByStatus);
    }
  }, [usersWithShifts, opsFilter]);

  let uniqueIndex = -1;

  return (
    <div className={className} ref={contentRef}>
      {isLoading ? (
        <div className="loader">
          <Spin spinning={isLoading} />
          <span>Génération de votre planning...</span>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            width:
              contentRef &&
              contentRef.current &&
              contentRef.current.clientWidth &&
              contentRef.current.clientWidth > 105 * (usersWithShifts.length - 1) + 105
                ? contentRef.current.clientWidth - 55
                : 105 * (usersWithShifts.length - 1) + 175,
          }}
        >
          <div className="sidebar" style={{ paddingTop: opsFilter ? 25 : 0 }}>
            {INTERVALS.map((interval, i) => (
              <div ref={refInterval} className="interval" id={`interval-${i}`}>
                <OperationalInterval interval={interval} />
              </div>
            ))}
          </div>
          <div className="main" style={{ marginTop: opsFilter ? 26 : 0 }}>
            {selectedDate.isSame(moment.now(), 'day') && (
              <NowRow
                intervalHeight={intervalHeight}
                width={
                  contentRef &&
                  contentRef.current &&
                  contentRef.current.clientWidth &&
                  contentRef.current.clientWidth > 105 * (usersWithShifts.length - 1) + 105
                    ? contentRef.current.clientWidth - 55
                    : 105 * (usersWithShifts.length - 1) + 175
                }
                intervals={INTERVALS}
              />
            )}
            {/* <div className="sidebar">
            {INTERVALS.map((interval, i) => (
              <div ref={refInterval} className="interval" id={`interval-${i}`}>
                <OperationalInterval interval={interval} />
              </div>
            ))}
          </div> */}
            <div className="content">
              {!opsFilter &&
                INTERVALS.map((interval) => (
                  <div
                    className="height"
                    style={{
                      height: intervalHeight,
                      width:
                        contentRef &&
                        contentRef.current &&
                        contentRef.current.clientWidth &&
                        contentRef.current.clientWidth > 105 * (usersWithShifts.length - 1) + 105
                          ? contentRef.current.clientWidth - 55
                          : 105 * (usersWithShifts.length - 1) + 175,
                    }}
                  >
                    <OperationalRow />
                  </div>
                ))}
              {opsFilter && opsFilter == OpsFilter.SECTIONS && (
                <div style={{ display: 'flex' }}>
                  {Object.keys(usersWithShiftsBySection).map((sectionId, i) => {
                    const section = sections.find((section) => section.id == sectionId);
                    const shiftsByUser = usersWithShiftsBySection[sectionId];

                    if (!section || !section.background) return;

                    const rgb = hexToRgb(section.background);

                    return (
                      <div
                        style={{
                          backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`,
                          boxShadow: `inset 0 0 0 1px ${section.background}`,
                          marginLeft: i > 0 ? 60 : 0,
                        }}
                      >
                        <p
                          style={{
                            position: 'absolute',
                            top: -24,
                            padding: '2px 10px',
                            backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`,
                            border: `1px solid ${section.background}`,
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                          }}
                        >
                          {section.name}
                        </p>
                        {INTERVALS.map((interval, index) => {
                          return (
                            <div
                              className="height"
                              style={{
                                height: intervalHeight,
                                width: 100 * shiftsByUser.length + 5 * (shiftsByUser.length - 1) + 52.5,
                                backgroundColor:
                                  index % 2 == 0
                                    ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.15)`
                                    : `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`,
                              }}
                            >
                              <OperationalRow />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
              {opsFilter && opsFilter == OpsFilter.SKILLS && (
                <div style={{ display: 'flex', gap: 50 }}>
                  {Object.keys(usersWithShiftsBySkills).map((skillId, i) => {
                    const skill = skills.find((skill) => skill.id == skillId);
                    const shiftsByUser = usersWithShiftsBySkills[skillId];

                    if (!skill || !skill.background) return;

                    const rgb = hexToRgb(skill.background);

                    return (
                      <div
                        style={{
                          backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`,
                          marginLeft: i > 0 ? 5 : 0,
                          border: `1px solid ${skill.background}`,
                        }}
                      >
                        <p
                          style={{
                            position: 'absolute',
                            top: -24,
                            marginLeft: -1,
                            padding: '2px 10px',
                            backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`,
                            border: `1px solid ${skill.background}`,
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                          }}
                        >
                          {skill.name}
                        </p>
                        {INTERVALS.map((interval, index) => {
                          return (
                            <div
                              className="height"
                              style={{
                                height: intervalHeight,
                                width: 100 * shiftsByUser.length + 5 * (shiftsByUser.length - 1) + 52.5,
                                backgroundColor:
                                  index % 2 == 0
                                    ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.15)`
                                    : `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`,
                              }}
                            >
                              <OperationalRow />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
              {opsFilter && opsFilter == OpsFilter.STATUS && (
                <div style={{ display: 'flex', gap: 50 }}>
                  {Object.keys(usersWithShiftsByStatus).map((sectionId, i) => {
                    const status = userCategories.find((section) => section.id == sectionId);
                    if (!status || !status.id) return;
                    const shiftsByUser = usersWithShiftsByStatus[status.id];

                    if (!status || !status.background) return;

                    const rgb = hexToRgb(status.background);

                    return (
                      <div
                        style={{
                          backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`,
                          marginLeft: i > 0 ? 5 : 0,
                          border: `1px solid ${status.background}`,
                        }}
                      >
                        <p
                          style={{
                            position: 'absolute',
                            top: -24,
                            marginLeft: -1,
                            padding: '2px 10px',
                            backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`,
                            border: `1px solid ${status.background}`,
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                          }}
                        >
                          {status.name}
                        </p>
                        {INTERVALS.map((interval, index) => {
                          return (
                            <div
                              className="height"
                              style={{
                                height: intervalHeight,
                                width: 100 * shiftsByUser.length + 5 * (shiftsByUser.length - 1) + 52.5,
                                backgroundColor:
                                  index % 2 == 0
                                    ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.15)`
                                    : `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`,
                              }}
                            >
                              <OperationalRow />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
              {opsFilter &&
                opsFilter == OpsFilter.SECTIONS &&
                Object.keys(usersWithShiftsBySection).map((sectionId, index) => {
                  const elements = usersWithShiftsBySection[sectionId];
                  if (index > 0) {
                    uniqueIndex = uniqueIndex + 1;
                  }

                  return (
                    <>
                      {elements.map((element, i) => {
                        uniqueIndex = uniqueIndex + 1;

                        return (
                          <>
                            {element.shifts.map((shift) => {
                              if (!shift.userRecordId) return;
                              return (
                                <OpsShift
                                  key={shift.id}
                                  shift={shift}
                                  index={uniqueIndex}
                                  intervalHeight={intervalHeight}
                                  userShifts={element}
                                  contentRef={contentRef}
                                  intervals={INTERVALS}
                                />
                              );
                            })}
                          </>
                        );
                      })}
                    </>
                  );
                })}
              {opsFilter &&
                opsFilter == OpsFilter.SKILLS &&
                Object.keys(usersWithShiftsBySkills).map((skillId, index) => {
                  const elements = usersWithShiftsBySkills[skillId];
                  if (index > 0) {
                    uniqueIndex = uniqueIndex + 1;
                  }

                  return (
                    <>
                      {elements.map((element, i) => {
                        uniqueIndex = uniqueIndex + 1;

                        return (
                          <>
                            {element.shifts.map((shift) => {
                              if (!shift.userRecordId) return;
                              return (
                                <OpsShift
                                  key={shift.id}
                                  shift={shift}
                                  index={uniqueIndex}
                                  intervalHeight={intervalHeight}
                                  userShifts={element}
                                  contentRef={contentRef}
                                  intervals={INTERVALS}
                                />
                              );
                            })}
                          </>
                        );
                      })}
                    </>
                  );
                })}
              {opsFilter &&
                opsFilter == OpsFilter.STATUS &&
                Object.keys(usersWithShiftsByStatus).map((sectionId, index) => {
                  const elements = usersWithShiftsByStatus[sectionId];
                  if (index > 0) {
                    uniqueIndex = uniqueIndex + 1;
                  }

                  return (
                    <>
                      {elements.map((element, i) => {
                        uniqueIndex = uniqueIndex + 1;

                        return (
                          <>
                            {element.shifts.map((shift) => {
                              if (!shift.userRecordId) return;
                              return (
                                <OpsShift
                                  key={shift.id}
                                  shift={shift}
                                  index={uniqueIndex}
                                  intervalHeight={intervalHeight}
                                  userShifts={element}
                                  contentRef={contentRef}
                                  intervals={INTERVALS}
                                />
                              );
                            })}
                          </>
                        );
                      })}
                    </>
                  );
                })}
              {!opsFilter &&
                usersWithShifts.map((userShifts, i) => {
                  return (
                    <>
                      {userShifts.shifts.map((shift, j) => {
                        if (!shift.userRecordId) return;
                        return (
                          <OpsShift
                            key={shift.id}
                            shift={shift}
                            index={i}
                            intervalHeight={intervalHeight}
                            userShifts={userShifts}
                            contentRef={contentRef}
                            allShifts={usersWithShifts}
                            intervals={INTERVALS}
                          />
                        );
                      })}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default styled(AppHoursManageHourlyOperational)`
  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;

    span {
      font-size: 16px;
    }
  }

  .filters {
    display: flex;
    padding: 25px;
    margin-left: 55px;
    justify-content: space-between;

    .section {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 1;

    .interval {
      flex: 1;
      display: flex;
      align-items: center;

      &:nth-child(odd) {
        background-color: #efefef;
      }
    }
  }

  .main {
    display: flex;

    .content {
      position: relative;
      flex: 1;
      /* overflow: scroll; */

      .height {
        min-height: 40px;
        padding: 0 25px;
        width: 100%;
        &:nth-child(odd) {
          background-color: #fafafa;
        }
      }
    }

    .shift {
      position: absolute;
      background-color: white;
      width: 100px;
      text-align: center;

      .period {
        margin-top: 10px;
      }

      .details {
        font-size: 10px;
        color: #aaa;
        margin: 10px 0;
      }

      .tags {
        width: calc(100% - 15px);
      }
    }
  }
`;
