import { IScheduleModel } from '@/types/schedule-models/schedule-model.model';
import { IScheduleModelWeek } from '@/types/schedule-models/week.model';
import { minutesToHoursAndMinutes, WEEKDAYS } from '@/utils';
import { Button, Form, Input, InputNumber, message, Modal, Popover } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import { IShift } from '@/types/shift.model';
import moment from 'moment';
import { IScheduleDay } from '@/types/schedule-day.model';
import { valueType } from 'antd/lib/statistic/utils';
import styled from 'styled-components';

interface Props {
  className?: string;
  sm: IScheduleModel;
  smw: IScheduleModelWeek;
  setScheduleModels: React.Dispatch<React.SetStateAction<IScheduleModel[]>>;
  days: any[];
  setDays: React.Dispatch<React.SetStateAction<any[]>>;
}

const HOUR_FORMATTER = /\B(?=(\d{2})+(?!\d))/g;

const WeekRow: React.FC<Props> = ({ className, sm, smw, days, setScheduleModels, setDays }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const [form] = useForm();
  const [rowHasChanged, setRowHasChanged] = useState<boolean>(false);
  const [realTimeWorkTime, setRealTimeWorkTime] = useState<number>(0);

  const onDelete = () => {
    Modal.confirm({
      className: 'modal-danger',
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('SCHEDULE_MODELS.DELETE_WEEK_CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
      onOk: () => {
        let index = smw.week_number - 1;
        return axios
          .delete(`${process.env.REACT_APP_API_URL}/v3/users/schedule-models/${sm.id}/weeks/${index}`, {
            params: {
              departmentId: activeDepartment?.id,
            },
          })
          .then(({ data }) => {
            message.success(t('SCHEDULE_MODELS.MESSAGES.WEEK_DELETED'));
            setRowHasChanged(false);
            setScheduleModels(data);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });
  };

  const onSave = () => {
    if (days.length > 0) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/users/schedule-models/${sm.id}/weeks`,
          {
            days,
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }) => {
          message.success(t('SCHEDULE_MODELS.MESSAGES.WEEK_UPDATED'));
          setRowHasChanged(false);
          setScheduleModels(data);
          setDays([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, day_index: number) => {
    setRowHasChanged(true);
    const value = e.target.value;
    const formatted_value = formatInput(value);

    const inputs = document.getElementsByClassName('week_day_input');
    let total_worktime = 0;
    for (const iterator of inputs) {
      const input = iterator.getElementsByTagName('input')[0];
      const input_value = input.value;
      if (input_value && input.id.startsWith(`week_${smw.id}`)) {
        const formatted_value = formatInput(input_value);
        let worktime = moment.duration(formatted_value).asMinutes();
        total_worktime += worktime;
      }
    }

    setRealTimeWorkTime(total_worktime);
    let found = days.find((day) => day.week_id === smw.id && day.day_index === day_index);
    if (found) {
      setDays(
        days.map((day) =>
          day.week_id === found.week_id && day.day_index === day_index
            ? { ...found, value: formatted_value == '' ? null : formatted_value }
            : day,
        ),
      );
      form.setFieldsValue({
        [`week_${smw.id}_day_${day_index}`]: value,
      });
    } else {
      setDays([...days, { week_id: smw.id, day_index, value: formatted_value == '' ? null : formatted_value }]);
    }
  };

  return (
    <Form form={form} layout="vertical" className={`${className} row`}>
      <span style={{ alignSelf: 'flex-start', fontWeight: 'bold' }}>
        {t('GLOBAL.WEEK_SHORT')}
        {smw.week_number}
      </span>
      {smw.days.map((day, i) => {
        const shifts = day.shifts;

        if (shifts.length == 0) {
          form.setFieldsValue({
            [`week_${smw.id}_day_${i}`]: undefined,
          });
        } else {
          let work_time;
          shifts.forEach((shift) => {
            const start = moment(shift.startDate);
            const end = moment(shift.endDate);
            if (end.isBefore(start)) {
              end.add(1, 'day');
            }
            const diff = end.diff(start, 'minutes');

            const paid = shift.pause?.paid || 0;
            const unpaid = shift.pause?.unpaid || 0;
            const pause_duration = unpaid / 60;

            work_time = minutesToHoursAndMinutes(diff - pause_duration);
            const found = days.find((d) => d.week_id === smw.id && d.day_index === day.index);

            if (!found) {
              form.setFieldsValue({
                [`week_${smw.id}_day_${i}`]: work_time,
              });
            }
          });
        }

        return (
          <Form.Item
            style={{ flex: 1 }}
            label={t(`GLOBAL.${WEEKDAYS[i].toUpperCase()}`)}
            name={`week_${smw.id}_day_${i}`}
            className={`week_day_input`}
          >
            <Input style={{ width: '100%' }} placeholder="00:00" maxLength={5} onChange={(e) => onChange(e, i)} />
          </Form.Item>
        );
      })}
      <Button type="link">{minutesToHoursAndMinutes(rowHasChanged ? realTimeWorkTime : smw.totalMinutes)}</Button>
      {rowHasChanged && (
        <Popover content={() => t('GLOBAL.SAVE')}>
          <Button type="primary" onClick={onSave}>
            <i className="icon-ok" />
          </Button>
        </Popover>
      )}
      <Popover content={() => t('GLOBAL.REMOVE')}>
        <Button type="ghost" danger onClick={onDelete} style={{ backgroundColor: 'transparent' }}>
          <i className="icon-trash-empty" />
        </Button>
      </Popover>
    </Form>
  );
};

export default styled(WeekRow)`
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const formatInput = (input: string) => {
  let formatted_value = input;
  let has_special_char = false;

  if (input.includes(',')) {
    input = input.replace(',', ':');
    has_special_char = true;
  }

  if (input.includes('.')) {
    input = input.replace('.', ':');
    has_special_char = true;
  }

  const parts = input.split(':');
  const hours = parts[0];
  const minutes = has_special_char ? `0.${parts[1]}` : parts[1];
  const hours_number = Number(hours);
  const minutes_number = has_special_char ? Number(Number(minutes) * 60) : Number(minutes);

  if (!input.includes(':')) {
    if (hours_number <= 9) {
      formatted_value = `0${Number(input)}:00`;
    } else {
      formatted_value = `${Number(input)}:00`;
    }
  }

  if (input.startsWith('0:')) {
    if (minutes_number <= 9) {
      formatted_value = `00:0${minutes_number}`;
    } else {
      formatted_value = `00:${minutes_number}`;
    }
  }

  if (input.startsWith('00:') && minutes_number <= 9) {
    formatted_value = `00:0${minutes_number}`;
  }

  if (input.includes(':')) {
    if (minutes == '') {
      if (hours_number <= 9) {
        formatted_value = `0${hours_number}:00`;
      } else {
        formatted_value = `${hours_number}:00`;
      }
    } else {
      if (Number(minutes) <= 9) {
        if (hours_number <= 9) {
          formatted_value = `0${hours_number}:${minutes_number <= 9 ? '0' : ''}${minutes_number}`;
        } else {
          formatted_value = `${hours_number}:${minutes_number <= 9 ? '0' : ''}${minutes_number}`;
        }
      }
    }
  }

  if (hours_number > 23) {
    formatted_value = '23:59';
  }

  if (input == '') {
    formatted_value = '';
  }

  return formatted_value;
};
