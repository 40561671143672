import { IScheduleModel } from '@/types/schedule-models/schedule-model.model';
import { IUserParams } from '@/types/user-params.model';
import { IUser } from '@/types/user.model';
import { Modal } from 'antd';
import React from 'react';
import { Provider } from 'react-redux';
import styled from 'styled-components';
import ScheduleModelDetails from '../components/details';
import { store } from '../redux/store';

interface Props {
  className?: string;
  user: IUser;
  activeScheduleModel: IScheduleModel;
  userParams: IUserParams;
  setActiveScheduleModel: React.Dispatch<React.SetStateAction<IScheduleModel | null>>;
  setScheduleModels: React.Dispatch<React.SetStateAction<IScheduleModel[]>>;
}

const ModalScheduleModelDetails: React.FC<Props> = ({
  className,
  user,
  activeScheduleModel,
  userParams,
  setActiveScheduleModel,
  setScheduleModels,
}) => {
  const onCancel = () => {
    setActiveScheduleModel(null);
  };

  return (
    <Provider store={store}>
      <Modal className={className} visible={true} width={'100%'} footer={null} onCancel={onCancel}>
        <ScheduleModelDetails
          user={user}
          scheduleModel={activeScheduleModel}
          setScheduleModel={setActiveScheduleModel}
          setScheduleModels={setScheduleModels}
          userParams={userParams}
        />
      </Modal>
    </Provider>
  );
};

export default styled(ModalScheduleModelDetails)`
  width: 100%;
`;
