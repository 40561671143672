import { IShift } from '@/types/shift.model';
import { IUser } from '@/types/user.model';
import { isNullOrUndefined } from '@/utils';
import produce, { enableMapSet } from 'immer';
import moment from 'moment';
import { ActionType } from './actions';
import { initialState, InitialStateType, IShiftsByUser } from './store';

enableMapSet();

export default function reducer(state = initialState, action: ActionType): InitialStateType {
  switch (action.type) {
    case 'RESET': {
      return initialState;
    }
    case 'SET_FREE_SHIFT_TO_PASTE_MULTIPLE': {
      return produce(state, (draftState) => {
        draftState.freeShiftToPasteMultiple = action.payload;
      });
    }
    case 'SET_PASTE_MULTIPLE_FREE_SHIFTS_MODAL_VISIBLE': {
      return produce(state, (draftState) => {
        draftState.pasteMultipleFreeShiftsVisible = action.payload;
        draftState.shiftDrawerVisible = false;
      });
    }
    case 'SET_SHIFT_TO_ADD_OCCURRENCES': {
      return produce(state, (draftState) => {
        draftState.shiftToAddOccurrences = action.payload;
      });
    }
    case 'SET_ADD_OCCURRENCES_MODAL_VISIBLE': {
      return produce(state, (draftState) => {
        draftState.addOccurrencesModalVisible = action.payload;
        draftState.shiftDrawerVisible = false;
      });
    }
    case 'SET_ASSIGN_MODAL_VISIBLE': {
      return produce(state, (draftState) => {
        draftState.assignModalVisible = action.payload;
        draftState.shiftDrawerVisible = false;
      });
    }
    case 'SET_SHIFT_DRAWER_VISIBLE': {
      return produce(state, (draftState) => {
        draftState.shiftDrawerVisible = action.payload;
      });
    }
    case 'SET_MULTIPLE_SHIFTS_DRAWER_VISIBLE': {
      return produce(state, (draftState) => {
        draftState.multipleShiftsDrawerVisible = action.payload;
      });
    }
    case 'ADD_SELECTED_SHIFT': {
      const shiftId = action.payload;
      return produce(state, (draftState) => {
        draftState.selectedShifts.add(shiftId);
      });
    }
    case 'ADD_SELECTED_SHIFTS': {
      return produce(state, (draftState) => {
        const { payload = [] } = action;
        for (let i = 0; i < payload.length; i++) {
          draftState.selectedShifts.add(payload[i]);
        }
      });
    }
    case 'REMOVE_SELECTED_SHIFT': {
      const shiftId = action.payload;
      return produce(state, (draftState) => {
        draftState.selectedShifts.delete(shiftId);
      });
    }
    case 'SET_SELECTED_SHIFTS': {
      const shiftIds = action.payload;
      return produce(state, (draftState) => {
        draftState.selectedShifts = new Set(shiftIds);
      });
    }
    case 'SET_SHIFT_IDS_TO_PUBLISH': {
      return produce(state, (draftState) => {
        draftState.shiftIdsToPublish = action.payload;
      });
    }
    case 'SET_SHIFTS_TO_TRANSFER': {
      return produce(state, (draftState) => {
        draftState.shiftsToTransfer = action.payload;
      });
    }
    case 'SET_FILTER_OPTIONS': {
      return produce(state, (draftState) => {
        draftState.filterOptions = action.payload;
      });
    }
    case 'SET_SHOW_SHIFTS': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showShifts = payload;
        localStorage.setItem('showShifts', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_ABSENCES': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showAbsences = payload;
        localStorage.setItem('showAbsences', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_OPEN_SHIFTS': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showOpenShifts = payload;
        localStorage.setItem('showOpenShifts', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_SKILLS': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showSkills = payload;
        localStorage.setItem('showSkills', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_ATTRIBUTES': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showAttributes = payload;
        localStorage.setItem('showAttributes', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_DETAILS': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showDetails = payload;
        localStorage.setItem('showDetails', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_PRICES': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showPrices = payload;
        localStorage.setItem('showPrices', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_HEAD_COUNT': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showHeadCount = payload;
        localStorage.setItem('showHeadCount', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_COMMENT': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showComment = payload;
        localStorage.setItem('showComment', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_HR_CODE': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showHrCode = payload;
        localStorage.setItem('showHrCode', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_TASKS': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showTasks = payload;
        localStorage.setItem('showTasks', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_SECTIONS': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showSections = payload;
        localStorage.setItem('showSections', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_PRODUCTIVITY_INDEX': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showProductivityIndex = payload;
        localStorage.setItem('showProductivityIndex', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_OTHER_DEPARTMENTS': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showOtherDepartments = payload;
        localStorage.setItem('showOtherDepartments', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_CLOCKING': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showClocking = payload;
        localStorage.setItem('showClocking', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_MONTHLY_HOURS': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showMonthlyHours = payload;
        localStorage.setItem('showMonthlyHours', JSON.stringify(payload));
      });
    }
    case 'SET_SHOW_MONTHLY_START': {
      return produce(state, (draftState) => {
        const { payload } = action;
        draftState.showMonthlyStart = payload;
        localStorage.setItem('showMonthlyStart', JSON.stringify(payload));
      });
    }
    case 'SET_IS_LOADING': {
      return produce(state, (draftState) => {
        const isLoading = action.payload;
        draftState.isLoading = isLoading;
        if (isLoading) {
          draftState.filteredShiftsByUsers = [];
          draftState.shiftsByUsers = [];
          draftState.usersMap = new Map();
          draftState.shiftsMap = new Map();
          draftState.shiftsByUsersMapWithShiftId = new Map();
          draftState.shiftsByUsersMapWithUserId = new Map();
        }
      });
    }
    case 'SET_HAS_ERROR': {
      return produce(state, (draftState) => {
        draftState.hasError = action.payload;
      });
    }
    case 'SET_PICKER': {
      return produce(state, (draftState) => {
        draftState.picker = action.payload;
      });
    }
    case 'SET_PICKER_AND_SELECTED_DATE': {
      const { picker, selectedDate, startDate, endDate } = action.payload;
      return produce(state, (draftState) => {
        if (state.selectedDate.valueOf() !== selectedDate) {
          draftState.selectedDate = moment(selectedDate);
        }
        if (state.startDate.valueOf() !== startDate) {
          draftState.startDate = moment(startDate);
        }
        if (state.endDate.valueOf() !== endDate) {
          draftState.endDate = moment(endDate);
        }
        if (state.picker !== picker) {
          draftState.picker = picker;
          draftState.clipboardHistory = null;
          draftState.clipboardHistoryDay = null;
        }
      });
    }
    case 'SET_SHIFTS': {
      return produce(state, (draftState) => {
        const {
          users,
          shifts,
          days,
          hours,
          totalBudget,
          cycleNumber,
          comparisonTemplate,
          scheduleModel,
          productivityIndex,
          productivityIndexReal,
          activeSection,
          activeDepartment,
          picker,
          skills,
          userStatus,
        } = action.payload;
        let shiftsByUsers: IShiftsByUser[] = [];
        const usersMap = new Map();
        const shiftsMap = new Map();
        const shiftsByUsersMapWithShiftId = new Map();
        const shiftsByUsersMapWithUserId = new Map();

        shiftsByUsers.push({
          user: {
            recordId: null,
          },
          shifts: [],
        });

        let normalShiftsUsers: IUser[] = users;
        let noShiftsUsers: IUser[] = [];
        let allShiftsType2Users: IUser[] = [];
        let allShiftsType3Users: IUser[] = [];

        users.forEach((user) => {
          shiftsByUsers.push({
            user,
            shifts: [],
          });
          usersMap.set(user.recordId!, user);

          const userShifts = shifts.filter((shift) => shift.userRecordId == user.recordId);
          const allShiftsAreType2 = userShifts.every((shift) => shift.shyftType == 2);
          const allShiftsAreType3 = userShifts.every((shift) => shift.shyftType == 3);
          const allShiftsBeforeStart =
            hours && hours[0]
              ? userShifts.every((shift) => moment(shift.startDate).isBefore(moment(hours[0].dateHuman)))
              : false;
          const allShiftsAfterEnd =
            hours && hours[hours.length - 1]
              ? userShifts.every((shift) => moment(shift.startDate).isAfter(moment(hours[hours.length - 1].dateHuman)))
              : false;

          if (userShifts.length == 0 || allShiftsBeforeStart || allShiftsAfterEnd) {
            noShiftsUsers.push(user);
            normalShiftsUsers = normalShiftsUsers.filter((u) => u.recordId !== user.recordId);
          } else if (allShiftsAreType2) {
            allShiftsType2Users.push(user);
            normalShiftsUsers = normalShiftsUsers.filter((u) => u.recordId !== user.recordId);
          } else if (allShiftsAreType3) {
            allShiftsType3Users.push(user);
            normalShiftsUsers = normalShiftsUsers.filter((u) => u.recordId !== user.recordId);
          }
        });

        let newUsers = [...normalShiftsUsers, ...allShiftsType2Users, ...allShiftsType3Users, ...noShiftsUsers];

        if (activeSection) {
          newUsers
            // .filter((user) => user.sections?.find((section) => section.id == activeSection))
            .sort((userA, userB) => {
              const hasShiftInSectionA = shifts
                .filter((shift) => shift.userRecordId == userA.recordId)
                .some((shift) => shift.section?.id === activeSection);
              const hasShiftInSectionB = shifts
                .filter((shift) => shift.userRecordId == userB.recordId)
                .some((shift) => shift.section?.id === activeSection);

              if (hasShiftInSectionA && !hasShiftInSectionB) {
                return -1; // userA comes first
              } else if (!hasShiftInSectionA && hasShiftInSectionB) {
                return 1; // userB comes first
              }

              return 0; // maintain the original order
            });
        }

        let non_null_sorted: IShiftsByUser[] = [];
        let null_sorted: IShiftsByUser[] = [];
        shiftsByUsers.forEach((shiftByUser) => {
          // push free shifts at the top of the list (avoid indexes offset by 1)
          if (shiftByUser.user.recordId == null) {
            non_null_sorted.push(shiftByUser);
          } else {
            const found = shiftByUser.shifts.some((shift) => {
              if (shift.section && shift.section.id === activeSection) {
                return true;
              }
              return false;
            });

            if (found) {
              non_null_sorted.push(shiftByUser);
            } else {
              null_sorted.push(shiftByUser);
            }
          }
        });

        let sorted: IShiftsByUser[] = [...non_null_sorted, ...null_sorted];

        const dayOrder = activeDepartment?.scheduleParams?.orderByDay;
        const weekOrder = activeDepartment?.scheduleParams?.orderByWeek;

        if ((picker == 'day' && dayOrder == 'SKILL') || (picker == 'week' && weekOrder == 'SKILL')) {
          const order = skills.map((skill) => skill.id);
          const skillIndexMap = new Map(order.map((id, index) => [id, index]));
          const indexMap = new Map(sorted.map((obj, index) => [obj.user.recordId!, index]));
          newUsers
            .sort((a, b) => {
              if (!indexMap.get(a.recordId!) || !indexMap.get(b.recordId!)) return 0;
              return indexMap.get(a.recordId!)! - indexMap.get(b.recordId!)!;
            })
            .sort((a, b) => {
              const allShiftsTypeA = shifts
                .filter((shift) => shift.userRecordId == a.recordId)
                .every((shift) => shift.shyftType !== 1);
              const allShiftsTypeB = shifts
                .filter((shift) => shift.userRecordId == b.recordId)
                .every((shift) => shift.shyftType !== 1);

              if (allShiftsTypeA && !allShiftsTypeB) {
                return 1;
              } else if (!allShiftsTypeA && allShiftsTypeB) {
                return -1;
              } else if (allShiftsTypeA && allShiftsTypeB) {
                return 0;
              } else {
                return 0;
              }
            })
            .sort((a, b) => {
              const shiftsForUserA = shifts.filter(
                (shift) => shift.userRecordId == a.recordId && shift.skills && shift.skills.length > 0,
              );
              const shiftsForUserB = shifts.filter(
                (shift) => shift.userRecordId == b.recordId && shift.skills && shift.skills.length > 0,
              );
              if (!shiftsForUserA[0] || !shiftsForUserA[0].skills || !shiftsForUserB[0] || !shiftsForUserB[0].skills)
                return 0;
              const skillIdA = shiftsForUserA[0].skills.length > 0 ? shiftsForUserA[0].skills[0].id : '';
              const skillIdB = shiftsForUserB[0].skills.length > 0 ? shiftsForUserB[0].skills[0].id : '';

              const indexA = skillIndexMap.get(skillIdA) ?? Infinity;
              const indexB = skillIndexMap.get(skillIdB) ?? Infinity;

              return indexA - indexB;
            });
        }

        if (picker == 'day' && dayOrder == 'DATE' && hours && hours[0] && hours[hours.length - 1]) {
          newUsers.sort((a, b) => {
            const shiftsForUserA = shifts.filter(
              (shift) =>
                shift.shyftType == 1 &&
                shift.userRecordId == a.recordId &&
                moment(shift.startDate).isBetween(
                  moment(hours[0].dateHuman),
                  moment(hours[hours.length - 1].dateHuman),
                ),
            );
            const shiftsForUserB = shifts.filter(
              (shift) =>
                shift.shyftType == 1 &&
                shift.userRecordId == b.recordId &&
                moment(shift.startDate).isBetween(
                  moment(hours[0].dateHuman),
                  moment(hours[hours.length - 1].dateHuman),
                ),
            );
            if (!shiftsForUserA[0] || !shiftsForUserB[0]) return 0;

            const startDateA = moment(shiftsForUserA[0].startDate);
            const startDateB = moment(shiftsForUserB[0].startDate);

            if (startDateA.isBefore(startDateB)) {
              return -1;
            } else if (startDateA.isAfter(startDateB)) {
              return 1;
            } else {
              return 0;
            }
          });
        }

        newUsers.forEach((user, index) => {
          shiftsByUsersMapWithUserId.set(user.recordId!, index + 1);
        });

        newUsers.forEach((user, index) => {
          const shiftByUserIndex = shiftsByUsers.findIndex((el) => el.user.recordId == user.recordId);
          if (index + 1 !== shiftByUserIndex) {
            shiftsByUsers = moveObjectsByIndexes(shiftsByUsers, [[shiftByUserIndex, index + 1]]);
          }
        });

        shifts.forEach((shift) => {
          shiftsMap.set(shift.id!, shift);
          let userIndex = 0;
          if (shift.userRecordId) {
            userIndex = shiftsByUsersMapWithUserId.get(shift.userRecordId)!;
          }
          if (shiftsByUsers[userIndex]) {
            const shiftIndex = shiftsByUsers[userIndex].shifts.push(shift) - 1;
            shiftsByUsersMapWithShiftId.set(shift.id!, {
              shiftIndex,
              userIndex,
            });
          }
        });

        draftState.days = days;
        draftState.hours = hours;
        draftState.totalBudget = totalBudget;
        draftState.cycleNumber = cycleNumber;
        draftState.comparisonTemplate = comparisonTemplate;
        draftState.filteredShiftsByUsers = shiftsByUsers;
        draftState.shiftsByUsers = shiftsByUsers;
        draftState.usersMap = usersMap;
        draftState.shiftsMap = shiftsMap;
        draftState.shiftsByUsersMapWithShiftId = shiftsByUsersMapWithShiftId;
        draftState.shiftsByUsersMapWithUserId = shiftsByUsersMapWithUserId;
        draftState.scheduleModel = scheduleModel;
        draftState.productivityIndex = productivityIndex;
        draftState.productivityIndexReal = productivityIndexReal;
      });
    }
    case 'UPDATE_FILTERED_SHIFTS': {
      return produce(state, (draftState) => {
        const { shiftsByUsers: stateShiftsByUsers, filterOptions, showAbsences, showShifts, usersMap } = state;
        let shiftsByUsers = [...stateShiftsByUsers];
        const filteredShiftsByUsers: IShiftsByUser[] = [];

        const users: string[] = [];
        const sections: string[] = [];
        const departments: string[] = [];
        const skills: string[] = [];
        const tasks: string[] = [];
        const attributes: string[] = [];
        const userCategories: string[] = [];
        const dayoffs: string[] = [];

        if (state.filterOptions.includes('ay_users')) {
          shiftsByUsers = shiftsByUsers.filter((shiftByUser) => shiftByUser.user.ay_sync);
        }

        if (state.filterOptions.length) {
          for (let index = 0; index < filterOptions.length; index++) {
            const [property, id] = filterOptions[index].split('.');
            switch (property) {
              case 'user':
                users.push(id);
                break;
              case 'userCategory':
                userCategories.push(id);
                break;
              case 'section':
                sections.push(id);
                break;
              case 'department':
                departments.push(id);
                break;
              case 'skill':
                skills.push(id);
                break;
              case 'task':
                tasks.push(id);
                break;
              case 'attribut':
                attributes.push(id);
                break;
              case 'dayoff':
                dayoffs.push(id);
                break;
              default:
                break;
            }
          }
        }

        for (let i = 0; i < shiftsByUsers.length; i++) {
          const shiftsByUser = shiftsByUsers[i];
          const { user, shifts } = shiftsByUser;
          const filteredShiftsByUser: IShift[] = [];
          let hasAbsence = false;
          let hasShift = false;

          if (users.length) {
            if (user.recordId && !users.includes(user.recordId)) {
              continue;
            }
          }

          if (userCategories.length) {
            if (user.recordId && !userCategories.includes(user?.userType?.id || '')) {
              continue;
            }
          }

          for (let j = 0; j < shifts.length; j++) {
            const shift: IShift = {
              ...shifts[j],
              selected: false,
            };

            if (action.payload && action.payload.reset && shift.optimistic) {
              shift.optimistic = false;
            }

            if (shift.dayoff || shift.shyftType === 3) {
              hasAbsence = true;
            } else {
              hasShift = true;
            }

            if (!showShifts) {
              if (!shift.dayoff && shift.shyftType !== 3) {
                hasShift = true;
                continue;
              }
            }

            if (!showAbsences) {
              if (shift.dayoff || shift.shyftType === 3) {
                continue;
              }
            }

            if (departments.length) {
              if (!departments.includes(shift.departmentId!)) {
                continue;
              }
            }

            const filter_operator = action.payload?.department?.scheduleParams?.filter_operator;

            if (sections.length) {
              if (shift.dayoff) {
                if (user.sections?.some((section) => sections.includes(section.id!))) {
                  filteredShiftsByUser.push(shift);
                  continue;
                } else {
                  continue;
                }
              } else if (filter_operator == 'OR') {
                if (!shift.section || !sections.includes(shift.section.id!)) {
                  continue;
                }
              } else if (filter_operator == 'AND') {
                if (
                  !shift.section ||
                  !(
                    sections.sort().join(',') ==
                    (user.sections &&
                      user.sections
                        .map((el) => el.id)
                        .sort()
                        .join(','))
                  )
                ) {
                  continue;
                }
              } else {
              }
            }

            if (skills.length) {
              if (shift.dayoff) {
                if (skills.length == 1) {
                  if (user.skills?.some((skill) => skills.includes(skill.id!))) {
                    filteredShiftsByUser.push(shift);
                    continue;
                  }
                } else {
                  if (filter_operator == 'OR') {
                    if (user.skills?.some((skill) => skills.includes(skill.id!))) {
                      filteredShiftsByUser.push(shift);
                      continue;
                    }
                  } else if (filter_operator == 'AND') {
                    if (
                      user.skills?.some((skill) => skills.includes(skill.id!)) &&
                      skills.sort().join(',') ==
                        (user.skills &&
                          user.skills
                            .map((el) => el.id)
                            .sort()
                            .join(','))
                    ) {
                      filteredShiftsByUser.push(shift);
                      continue;
                    }
                  }
                }
              } else if (shift.skills) {
                if (skills.length == 1) {
                  if (shift.skills.some((skill) => skills.includes(skill.id!))) {
                    filteredShiftsByUser.push(shift);
                    continue;
                  }
                } else {
                  if (filter_operator == 'OR') {
                    if (shift.skills.some((skill) => skills.includes(skill.id!))) {
                      filteredShiftsByUser.push(shift);
                      continue;
                    }
                  } else if (filter_operator == 'AND') {
                    if (
                      shift.skills.some((skill) => skills.includes(skill.id!)) &&
                      skills.sort().join(',') ==
                        (shift.skills &&
                          shift.skills
                            .map((el) => el.id)
                            .sort()
                            .join(','))
                    ) {
                      filteredShiftsByUser.push(shift);
                      continue;
                    }
                  }
                }
              }
            }

            if (attributes.length) {
              if (shift.attributes) {
                if (attributes.length == 1) {
                  if (shift.attributes.some((attribut) => attributes.includes(attribut.id!))) {
                    filteredShiftsByUser.push(shift);
                    continue;
                  }
                } else {
                  if (filter_operator == 'OR') {
                    if (shift.attributes.some((attribut) => attributes.includes(attribut.id!))) {
                      filteredShiftsByUser.push(shift);
                      continue;
                    }
                  } else if (filter_operator == 'AND') {
                    if (
                      shift.attributes.some((attribut) => attributes.includes(attribut.id!)) &&
                      attributes.sort().join(',') ==
                        (shift.attributes &&
                          shift.attributes
                            .map((el) => el.id)
                            .sort()
                            .join(','))
                    ) {
                      filteredShiftsByUser.push(shift);
                      continue;
                    }
                  }
                }
              }
            }

            if (tasks.length) {
              if (shift.tasks) {
                if (tasks.length == 1) {
                  if (shift.tasks.some((task) => tasks.includes(task.taskTypeId!))) {
                    filteredShiftsByUser.push(shift);
                    continue;
                  }
                } else {
                  if (filter_operator == 'OR') {
                    if (shift.tasks.some((task) => tasks.includes(task.taskTypeId!))) {
                      filteredShiftsByUser.push(shift);
                      continue;
                    }
                  } else if (filter_operator == 'AND') {
                    if (
                      shift.tasks.some((task) => tasks.includes(task.taskTypeId!)) &&
                      tasks.sort().join(',') ==
                        (shift.tasks &&
                          shift.tasks
                            .map((el) => el.id)
                            .sort()
                            .join(','))
                    ) {
                      filteredShiftsByUser.push(shift);
                      continue;
                    }
                  }
                }
              }
            }

            if (dayoffs.length) {
              if (shift.dayoff) {
                if (dayoffs.length == 1) {
                  if (dayoffs.includes(shift.dayoff.dayOffType!)) {
                    filteredShiftsByUser.push(shift);
                    continue;
                  }
                } else {
                  if (filter_operator == 'OR') {
                    if (dayoffs.includes(shift.dayoff.dayOffType!)) {
                      filteredShiftsByUser.push(shift);
                      continue;
                    }
                  } else if (filter_operator == 'AND') {
                    if (
                      dayoffs.includes(shift.dayoff.dayOffType!) &&
                      dayoffs.sort().join(',') == (shift.dayoff && shift.dayoff.dayOffType)
                    ) {
                      filteredShiftsByUser.push(shift);
                      continue;
                    }
                  }
                }
              }
            }

            if (!tasks.length && !skills.length && !attributes.length && !dayoffs.length) {
              filteredShiftsByUser.push(shift);
              continue;
            }
          }

          const searchQuery =
            users?.length ||
            sections?.length ||
            departments?.length ||
            skills?.length ||
            tasks?.length ||
            tasks?.length ||
            attributes?.length ||
            dayoffs?.length;

          if (!user.recordId || (searchQuery && filteredShiftsByUser.length) || !searchQuery) {
            filteredShiftsByUsers.push({
              user,
              shifts: filteredShiftsByUser,
              hasOnlyAbsence: !hasShift && hasAbsence ? true : false,
            });
          }

          if (user.recordId && shifts.length == 0 && users.includes(user.recordId)) {
            filteredShiftsByUsers.push({
              user,
              shifts: [],
              hasOnlyAbsence: !hasShift && hasAbsence ? true : false,
            });
          }
        }

        if (state.picker === 'day') {
          let filteredShiftsByUsersOrdered = [];
          const filteredShiftsByUsersWithOnlyAbsence = [];
          const filteredShiftsByUsersWithoutOnlyAbsence = [];
          const filteredShiftsByUsersWithNoShift = [];

          for (let index = 0; index < filteredShiftsByUsers.length; index++) {
            const filteredShiftsByUser = filteredShiftsByUsers[index];
            if (!filteredShiftsByUser.user.recordId) {
              filteredShiftsByUsersOrdered.push(filteredShiftsByUser);
            } else if (!filteredShiftsByUser.shifts.length) {
              filteredShiftsByUsersWithNoShift.push(filteredShiftsByUser);
            } else if (filteredShiftsByUser.hasOnlyAbsence) {
              filteredShiftsByUsersWithOnlyAbsence.push(filteredShiftsByUser);
            } else {
              filteredShiftsByUsersWithoutOnlyAbsence.push(filteredShiftsByUser);
            }
          }

          filteredShiftsByUsersOrdered = filteredShiftsByUsersOrdered.concat(
            filteredShiftsByUsersWithoutOnlyAbsence,
            filteredShiftsByUsersWithOnlyAbsence,
            filteredShiftsByUsersWithNoShift,
          );

          draftState.filteredShiftsByUsers = filteredShiftsByUsersOrdered;
        } else {
          draftState.filteredShiftsByUsers = filteredShiftsByUsers;
        }
      });
    }
    case 'RESET_SHIFTS_BUT_KEEP_USERS': {
      return produce(state, (draftState) => {
        draftState.shiftsByUsers = draftState.shiftsByUsers.map((shiftsByUser) => {
          shiftsByUser.shifts = [];
          return shiftsByUser;
        });
      });
    }
    case 'ADD_SHIFTS': {
      const shifts = action.payload;
      return produce(state, (drafState) => {
        shifts.forEach((shift) => {
          const existingShift = getShiftById({
            shiftsByUsers: state.shiftsByUsers,
            shiftId: shift.id!,
            needToRemove: false,
          });

          if (!existingShift) {
            const shiftsByUsers = drafState.shiftsByUsers;
            const userIndex = shiftsByUsers.findIndex(
              (shiftsByUser) => shiftsByUser.user.recordId === shift.userRecordId,
            );
            if (~userIndex) {
              const shiftsByUser = shiftsByUsers[userIndex];
              shiftsByUser.shifts.push(shift);
              shiftsByUser.shifts = shiftsByUser.shifts.sort((a, b) => (a.start! > b.start! ? 1 : -1));
            } else {
              shiftsByUsers.push({
                user: {
                  recordId: shift.userRecordId!,
                  firstname: 'new',
                  lastname: 'new',
                  role: 'new',
                },
                shifts: [shift],
              });
            }
          }
        });
      });
    }
    case 'MOVE_SHIFT': {
      const { source, destination } = action.payload;
      return produce(state, (draftState) => {
        // Get the source shift and remove from the shifts list
        const shiftsByUsers = draftState.shiftsByUsers;
        const shift = getShiftById({
          shiftsByUsers,
          shiftId: source.shiftId,
          needToRemove: true,
        });

        if (shift) {
          const difference = destination.newStart - shift.start!;
          shift.start = destination.newStart;
          shift.end = destination.newEnd;
          shift.userRecordId = destination.userRecordId;
          const userIndex = shiftsByUsers.findIndex((x) => x.user.recordId === destination.userRecordId);
          if (~userIndex) {
            const shiftsByUser = shiftsByUsers[userIndex];
            shiftsByUser.shifts.push(shift);
            shiftsByUser.shifts = shiftsByUser.shifts.sort((a, b) => (a.start! > b.start! ? 1 : -1));
          }
          if (shift.tasks && shift.tasks.length > 0) {
            shift.tasks.forEach((task) => {
              task.start = task.start! + difference;
              task.end = task.end! + difference;
            });
          }
        }
      });
    }
    case 'UPDATE_SHIFT': {
      const newShiftDifferences = action.payload;
      return produce(state, (draftState) => {
        const { shiftsByUsersMapWithUserId, shiftsByUsersMapWithShiftId, shiftsByUsers, shiftsMap } = draftState;
        updateShift({
          newShiftDifferences,
          shiftsByUsersMapWithShiftId,
          shiftsByUsers,
          shiftsByUsersMapWithUserId,
          shiftsMap,
        });
      });
    }
    case 'UPDATE_SHIFTS': {
      const {
        payload: { newShiftsDifferences },
      } = action;

      return produce(state, (draftState) => {
        const { shiftsByUsersMapWithUserId, shiftsByUsersMapWithShiftId, shiftsByUsers, shiftsMap } = draftState;

        newShiftsDifferences.forEach((newShiftDifferences) => {
          updateShift({
            newShiftDifferences,
            shiftsByUsersMapWithShiftId,
            shiftsByUsers,
            shiftsByUsersMapWithUserId,
            shiftsMap,
          });
        });
      });
    }
    case 'UPDATE_USERS': {
      return produce(state, (draftState) => {
        const { shiftsByUsers, usersMap, shiftsByUsersMapWithUserId } = draftState;
        action.payload.forEach((user) => {
          const userRecordId = user.recordId!;
          const userIndex = shiftsByUsersMapWithUserId.get(userRecordId)!;
          shiftsByUsers[userIndex].user = { ...user };
          usersMap.set(userRecordId, { ...user });
        });
      });
    }
    case 'DELETE_SHIFTS': {
      const ids = action.payload.ids;
      return produce(state, (draftState) => {
        const { shiftsByUsersMapWithShiftId, shiftsByUsers, shiftsMap } = draftState;

        ids.forEach((shiftId) => {
          deleteShift({
            shiftId,
            shiftsByUsersMapWithShiftId,
            shiftsByUsers,
            shiftsMap,
          });
        });
      });
    }
    case 'SET_SHIFT_OPTIMISTIC': {
      const payload = action.payload;
      return produce(state, (draftState) => {
        const shift = getShiftById({
          shiftsByUsers: draftState.shiftsByUsers,
          shiftId: payload.shiftId,
          needToRemove: false,
        });
        if (shift) {
          shift.optimistic = payload.optimistic;
        }
      });
    }
    case 'SET_SELECTED_SHIFT': {
      return produce(state, (draftState) => {
        draftState.selectedShift = action.payload;
      });
    }
    case 'COPY_SHIFTS': {
      return produce(state, (draftState) => {
        const { ids } = action.payload;
        const { shiftsMap } = draftState;
        const shifts: IShift[] = [];

        for (let index = 0; index < ids.length; index++) {
          const shift = shiftsMap.get(ids[index]);
          if (shift) {
            shifts.push(shift);
          }
        }

        draftState.clipboardHistory = {
          shifts,
          startDate: moment(draftState.startDate),
        };
      });
    }
    case 'COPY_DAY_SHIFTS': {
      return produce(state, (draftState) => {
        const { ids } = action.payload;
        const { shiftsMap } = draftState;
        const shifts: IShift[] = [];

        for (let index = 0; index < ids.length; index++) {
          const shift = shiftsMap.get(ids[index]);
          if (shift) {
            shifts.push(shift);
          }
        }

        draftState.clipboardHistoryDay = {
          shifts,
          startDate: moment(draftState.startDate),
        };
      });
    }
    case 'MOVE_TASK': {
      const { source, destination } = action.payload;
      return produce(state, (draftState) => {
        // Get the source shift and remove from the shifts list
        const shiftsByUsers = draftState.shiftsByUsers;
        const sourceShift = source.shiftId
          ? getShiftById({
              shiftsByUsers,
              shiftId: source.shiftId,
              needToRemove: false,
            })
          : null;

        let destinationShift;
        if (source.shiftId === destination.shiftId) {
          destinationShift = sourceShift;
        } else {
          destinationShift = getShiftById({
            shiftsByUsers,
            shiftId: destination.shiftId,
            needToRemove: false,
          });
        }

        if (!destinationShift) {
          console.error('no destination shift valid');
          return;
        }

        const tasks = sourceShift?.tasks || [];
        const taskIndex = tasks.findIndex((task) => task.id === source.taskId);
        if (~taskIndex) {
          const task = tasks.splice(taskIndex, 1)[0];
          const destinationTasks = destinationShift.tasks || [];
          destinationTasks.push({
            ...task,
            start: destination.newStart,
            end: destination.newEnd,
          });
          destinationShift.tasks = destinationTasks;
        }
      });
    }
    case 'SET_SHIFT_IDS_TO_DELETE': {
      return produce(state, (draftState) => {
        draftState.shiftIdsToDelete = action.payload;
      });
    }
    case 'SET_NEW_DIMONA': {
      return produce(state, (draftState) => {
        draftState.newDimona = action.payload;
      });
    }
    case 'SET_TASK_SCHEDULER': {
      return produce(state, (draftState) => {
        draftState.taskScheduler = action.payload;
      });
    }
    case 'SET_MONTHLY_SELECTING_ELEMENTS': {
      return produce(state, (draftState) => {
        draftState.monthly_selecting_elements = action.payload;
      });
    }
    case 'SET_MONTHLY_SELECTED_ELEMENTS': {
      return produce(state, (draftState) => {
        draftState.monthly_selected_elements = action.payload;
      });
    }
    case 'SET_ERRORS': {
      return produce(state, (draftState) => {
        draftState.errors = action.payload;
      });
    }
    case 'SET_CREATE_NEW_SHIFT_BLOCK': {
      return produce(state, (draftState) => {
        draftState.create_new_shift_block = action.payload;
      });
    }
    case 'SET_SHIFT_BLOCKS': {
      return produce(state, (draftState) => {
        draftState.shift_blocks = action.payload;
      });
    }
    case 'SET_SHIFT_BLOCKS_LOADING': {
      return produce(state, (draftState) => {
        draftState.shift_blocks_loading = action.payload;
      });
    }
    case 'SET_ACTIVE_SHIFT_BLOCK': {
      return produce(state, (draftState) => {
        draftState.active_shift_block = action.payload;
      });
    }
    case 'SET_EDITING_SHIFT_BLOCK': {
      return produce(state, (draftState) => {
        draftState.editing_shift_block = action.payload;
      });
    }
    case 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY': {
      return produce(state, (draftState) => {
        draftState.selected_shifts_on_shift_key = action.payload;
      });
    }
    case 'SET_OPENED_MONTHLY_DROPDOWN_IDENTIFIER': {
      return produce(state, (draftState) => {
        draftState.opened_monthly_dropdown_identifier = action.payload;
      });
    }
    case 'SET_CLIPBOARD_HISTORY': {
      return produce(state, (draftState) => {
        draftState.clipboardHistory = action.payload;
      });
    }
    case 'SET_MONTH_START_POINT_ELEMENT': {
      return produce(state, (draftState) => {
        draftState.monthly_start_point_element = action.payload;
      });
    }
    case 'SET_MONTHLY_SELECTING_DIRECTION': {
      return produce(state, (draftState) => {
        draftState.monthly_selecting_direction = action.payload;
      });
    }
    case 'SET_COPIED_SHIFT': {
      return produce(state, (draftState) => {
        draftState.copied_shift = action.payload;
      });
    }
    case 'SET_MONTHLY_SHIFTS_TO_REPLACE': {
      return produce(state, (draftState) => {
        draftState.monthly_shifts_to_replace = action.payload;
      });
    }
    case 'SET_MONTHLY_SHIFTS_TO_REPLACE_SHIFT_BLOCK': {
      return produce(state, (draftState) => {
        draftState.monthly_shifts_to_replace_shift_block = action.payload;
      });
    }
    case 'SET_MONTHLY_SHIFTS_TO_REPLACE': {
      return produce(state, (draftState) => {
        draftState.monthly_shifts_to_replace = action.payload;
      });
    }
    case 'SET_ACTIVE_WEEKLY_VIEW': {
      return produce(state, (draftState) => {
        draftState.active_weekly_view = action.payload;
      });
    }
    case 'SET_LOADING_ELEMENTS': {
      return produce(state, (draftState) => {
        draftState.loading_elements = action.payload;
      });
    }
    case 'SET_SELECTED_DAILY_VIEW': {
      return produce(state, (draftState) => {
        localStorage.setItem('selected_daily_view', action.payload);
        draftState.selected_daily_view = action.payload;
      });
    }
    case 'SET_LOADING': {
      return produce(state, (draftState) => {
        draftState.loading = action.payload;
      });
    }
    case 'SET_AY_SHIFT_IDS': {
      return produce(state, (draftState) => {
        draftState.ayShiftIds = action.payload;
      });
    }
    case 'SET_AY_SHIFT_IDS_LOADING': {
      return produce(state, (draftState) => {
        draftState.ayShiftIdsLoading = action.payload;
      });
    }
    case 'SET_AY_ERRORS': {
      return produce(state, (draftState) => {
        draftState.ayErrors = action.payload;
      });
    }
    case 'SET_COMMAND_SELECTED_KEY': {
      return produce(state, (draftState) => {
        draftState.commandSelectedKey = action.payload;
      });
    }
    case 'SET_COMMAND_SELECTED_INDEX': {
      return produce(state, (draftState) => {
        draftState.commandSelectedIndex = action.payload;
      });
    }
    case 'SET_COMMAND_SELECTED_ELEMENT': {
      return produce(state, (draftState) => {
        draftState.commandSelectedElement = action.payload;
      });
    }
    case 'SET_COMMAND_SELECTED_ACTIVATED': {
      return produce(state, (draftState) => {
        draftState.commandSelectedActivated = action.payload;
      });
    }
    default: {
      return state;
    }
  }
}

function getShiftById({
  shiftsByUsers,
  shiftId,
  needToRemove = false,
}: {
  shiftsByUsers: IShiftsByUser[];
  shiftId: string;
  needToRemove: boolean;
}) {
  for (let i = 0; i < shiftsByUsers.length; i++) {
    const shiftsByUser = shiftsByUsers[i];
    for (let j = 0; j < shiftsByUser.shifts.length; j++) {
      const shift = shiftsByUser.shifts[j];
      if (shift.id === shiftId) {
        if (needToRemove) {
          shiftsByUser.shifts.splice(j, 1);
        }
        return shift;
      }
    }
  }
  return null;
}

function updateShift({
  newShiftDifferences,
  shiftsByUsersMapWithShiftId,
  shiftsByUsers,
  shiftsByUsersMapWithUserId,
  shiftsMap,
}: {
  newShiftDifferences: IShift;
  shiftsByUsersMapWithShiftId: InitialStateType['shiftsByUsersMapWithShiftId'];
  shiftsByUsers: InitialStateType['shiftsByUsers'];
  shiftsByUsersMapWithUserId: InitialStateType['shiftsByUsersMapWithUserId'];
  shiftsMap: InitialStateType['shiftsMap'];
}) {
  const shiftId = newShiftDifferences.id;
  if (isNullOrUndefined(shiftId)) {
    throw new Error('A valid shift id is mandatory!');
  }

  const shiftsByUsersMapWithShiftIdResult = shiftsByUsersMapWithShiftId.get(shiftId!);
  if (!shiftsByUsersMapWithShiftIdResult) {
    return console.log(`Shift not found ${JSON.stringify(shiftId)}`);
  }

  const { userIndex, shiftIndex } = shiftsByUsersMapWithShiftIdResult;
  const shift = shiftsByUsers[userIndex].shifts[shiftIndex];
  let shiftsByUser: IShiftsByUser | null = null;
  let newUserIndex = userIndex;

  Object.keys(newShiftDifferences).map((key) => {
    if (key === 'id') {
      return false;
    } else if (key === 'userRecordId') {
      const newUserRecordId = (newShiftDifferences as any)[key];
      if (shift.userRecordId !== newUserRecordId) {
        shift.userRecordId = newUserRecordId;
        const shiftsByUsersShifts = shiftsByUsers[userIndex].shifts;
        shiftsByUsersShifts.splice(shiftIndex, 1);
        for (let i = shiftIndex; i < shiftsByUsersShifts.length; i++) {
          const _shift = shiftsByUsersShifts[i];
          shiftsByUsersMapWithShiftId.set(_shift.id!, {
            userIndex,
            shiftIndex: i,
          });
        }
        if (newUserRecordId) {
          const shiftsByUsersMapWithUserIdResult = shiftsByUsersMapWithUserId.get(newUserRecordId);
          if (!isNullOrUndefined(shiftsByUsersMapWithUserIdResult)) {
            newUserIndex = shiftsByUsersMapWithUserIdResult!;
          } else {
            throw new Error(`user doesn't exist in the users list`);
          }
        } else {
          newUserIndex = 0;
        }
        shiftsByUser = shiftsByUsers[newUserIndex];
        shiftsByUser.shifts.push(shift);
      }
    } else {
      if (shift && (newShiftDifferences as any)[key]) {
        (shift as any)[key] = (newShiftDifferences as any)[key];
      }
    }
    return false;
  });

  if (!shiftsByUser) {
    shiftsByUser = shiftsByUsers[newUserIndex];
  }

  shiftsByUser.shifts = shiftsByUser.shifts.sort((a, b) => (a.start! > b.start! ? 1 : -1));
  for (let i = 0; i < shiftsByUser.shifts.length; i++) {
    const _shift = shiftsByUser.shifts[i];
    shiftsByUsersMapWithShiftId.set(_shift.id!, {
      userIndex: newUserIndex,
      shiftIndex: i,
    });
  }
  shiftsMap.set(shiftId!, shift);
}

function deleteShift({
  shiftId,
  shiftsByUsersMapWithShiftId,
  shiftsByUsers,
  shiftsMap,
}: {
  shiftId: string;
  shiftsByUsersMapWithShiftId: InitialStateType['shiftsByUsersMapWithShiftId'];
  shiftsByUsers: InitialStateType['shiftsByUsers'];
  shiftsMap: InitialStateType['shiftsMap'];
}) {
  if (isNullOrUndefined(shiftId)) {
    throw new Error('A valid shift id is mandatory!');
  }

  const shiftsByUsersMapWithShiftIdResult = shiftsByUsersMapWithShiftId.get(shiftId);
  if (!shiftsByUsersMapWithShiftIdResult) {
    return console.log(`Shift not found ${JSON.stringify(shiftId)}`);
  }

  const { userIndex, shiftIndex } = shiftsByUsersMapWithShiftIdResult;

  const shiftsByUsersShifts = shiftsByUsers[userIndex].shifts;
  shiftsByUsersShifts.splice(shiftIndex, 1);
  shiftsByUsersMapWithShiftId.delete(shiftId);
  for (let i = shiftIndex; i < shiftsByUsersShifts.length; i++) {
    const _shift = shiftsByUsersShifts[i];
    shiftsByUsersMapWithShiftId.set(_shift.id!, {
      userIndex,
      shiftIndex: i,
    });
  }
  shiftsMap.delete(shiftId);
}

function moveObjectsByIndexes(array: any, indexesToMove: any) {
  indexesToMove.forEach(([fromIndex, toIndex]: any) => {
    if (fromIndex >= 0 && fromIndex < array.length && toIndex >= 0 && toIndex < array.length) {
      const [movedObject] = array.splice(fromIndex, 1);
      array.splice(toIndex, 0, movedObject);
    }
  });

  return array;
}
