import Error404 from '@/components/Error404';
import AppContext from '@/pages/app/context';
import { IUser } from '@/types/user.model';
import Axios from 'axios';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import HrSheetPage from './components/HrSheetPage';
import HrSheetsList from './components/HrSheetsList';
moment.tz.setDefault('Atlantic/Reykjavik');

export interface IHrSheet {
  recordId: string;
  displayName: string;
  status?: {
    id: string;
    name: string;
  };
  start: number;
  end: number;
  hours: number;
  dayoffs: number;
  unplanned: number;
  validated: boolean;
  ssa: boolean | null;
  ssaId: string;
  clocking: number;
  viewmode: 'PLANNED' | 'CLOCKIN';
}

const AppReportsHrSheets: React.FC = () => {
  const {
    state: { activeDepartmentId, users, loadingUsers, sections, loadingSections, activeSection },
  } = useContext(AppContext);
  const [datePickerValue, setDatePickerValue] = useState<Moment>(moment().startOf('month'));
  const [userRecordId, setUserRecordId] = useState<string | null>(null);
  const [allHrSheets, setAllHrSheets] = useState<IHrSheet[]>([]);
  const [hrSheets, setHrSheets] = useState<IHrSheet[]>([]);
  const [loadingHrSheets, setLoadingHrSheets] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (!location?.pathname || !location?.pathname.endsWith('all')) {
      return;
    }

    if (location?.state) {
      const { pickerDate = null } = location?.state as any;
      if (pickerDate && pickerDate !== datePickerValue.unix()) {
        setDatePickerValue(moment.unix(pickerDate));
        return;
      }
    }

    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();

    if (!mounted || loadingUsers) return;

    setLoadingHrSheets(true);
    setAllHrSheets([]);
    setHrSheets([]);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/reports/hr-sheets`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
        start: datePickerValue.unix(),
        recordId: userRecordId,
      },
    })
      .then(({ data }) => {
        const userIds = users.map((user) => user.recordId);
        setAllHrSheets(data);
        setHrSheets(data.filter((sheet: IHrSheet) => userIds.includes(sheet.recordId)));
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingHrSheets(false);
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [location?.pathname, datePickerValue, userRecordId, loadingUsers]);

  useEffect(() => {
    if (loadingHrSheets || loadingUsers) return;

    if (!activeSection) {
      setHrSheets(allHrSheets);
    } else {
      setHrSheets(
        allHrSheets.filter((sheet) => {
          const user = users.find((user: IUser) => user.recordId == sheet.recordId);
          if (!user) return false;
          if (!user.sections) return false;
          const sectionsArray: string[] = (user.sections as any).split(',');
          if (sectionsArray.includes(activeSection)) return true;
          return false;
        }),
      );
    }
  }, [activeSection, loadingHrSheets, loadingUsers]);

  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} render={() => <Redirect to={`${url}/all`} />} />
      <Route path={`${path}/all`}>
        <HrSheetsList
          departmentId={activeDepartmentId}
          dataSource={hrSheets}
          loading={loadingHrSheets}
          datePickerValue={datePickerValue}
          setDatePickerValue={setDatePickerValue}
          userRecordId={userRecordId}
          setUserRecordId={setUserRecordId}
          setHrSheets={setHrSheets}
          setLoadingHrSheets={setLoadingHrSheets}
        />
      </Route>
      <Route path={`${path}/:userRecordId/:start`}>
        <HrSheetPage
          hrSheets={hrSheets}
          datePickerValue={datePickerValue}
          setDatePickerValue={setDatePickerValue}
          setHrSheets={setHrSheets}
        />
      </Route>
      <Route component={() => <Error404 />} />
    </Switch>
  );
};

export default AppReportsHrSheets;
