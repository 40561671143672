import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { IDimona, IV4Dimona } from '@/types/dimona.model';
import { IUser } from '@/types/user.model';
import { handleError } from '@/utils';
import { Modal, Table } from 'antd';
import { default as Axios } from 'axios';
import 'moment-timezone';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DrawerDimona from '../../../components/drawers/Dimona';
import DrawerUpdateDimona from './components/DrawerUpdateDimona';
import Header from './components/Header';
import ModalDimonaDetails from './components/ModalDimonaDetails';
import { useDimonaStatement } from './hooks/index.hooks';

interface Props {
  className?: string;
  user?: IUser;
}

const DimonaStatement: React.FC<Props> = ({ className, user }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartmentId, userCategories, loadingUserCategories },
  } = useContext(AppContext);

  const {
    columns,
    dates,
    dimonas,
    dimonaErrors,
    dimonaPending,
    filters,
    loadingDimonas,
    loadingDimonaErrors,
    loadingDimonaPending,
    setDates,
    setFilters,
    getDimonas,
    getDimonaErrors,
    getDimonaPending,
  } = useDimonaStatement();

  const [newDimona, setNewDimona] = useState<Partial<IDimona> | null>(null);
  const [activeDimona, setActiveDimona] = useState<IV4Dimona | null>(null);
  const [detailedDimona, setDetailedDimona] = useState<IV4Dimona | null>(null);

  const [filtered, setFiltered] = useState<IV4Dimona[]>([]);
  const [data, setData] = useState<IV4Dimona[]>([]);

  useEffect(() => {
    if (loadingDimonas || loadingDimonaPending) return;
    setData(filters.pending ? dimonaPending : filters.errors ? dimonaErrors : filters.states.includes('active_within_period') ? [...dimonas, ...dimonaPending] : dimonas)
  }, [filters, loadingDimonas, loadingDimonaPending])

  useEffect(() => {
    if (loadingUserCategories) return;
    if (loadingDimonas && !filters.errors) return;
    if (loadingDimonaErrors && filters.errors) return;
    if (loadingDimonaPending && filters.pending) return;

    setFiltered(
      data.filter((dimona: IV4Dimona) => {
        const search = filters.search.toLowerCase();
        const matchesSearch =
          filters.search !== ''
            ? (dimona.user.firstName && dimona.user.firstName.toLowerCase().includes(search)) ||
              (dimona.user.lastName && dimona.user.lastName.toLowerCase().includes(search)) ||
              (dimona.dimonaPeriodId && dimona.dimonaPeriodId.includes(search)) ||
              (dimona.niss && dimona.niss.includes(search)) ||
              (dimona.comment && dimona.comment.toLowerCase().includes(search)) ||
              (dimona.workerType && dimona.workerType.toLowerCase().includes(search)) ||
              (dimona.user.userStatusId &&
                dimona.user.userStatusId &&
                userCategories.find((status) => status.id == String(dimona.user.userStatusId)) &&
                userCategories
                  .find((status) => status.id == String(dimona.user.userStatusId))!
                  .name!.toLowerCase()
                  .includes(search)) ||
              (status && t(`DIMONA.STATUSES.${status.toUpperCase()}`).toLowerCase().startsWith(search))
            : true;

        const matchesUsers =
          filters.users.length > 0 ? dimona.user.recordId && filters.users.includes(dimona.user.recordId) : true;

        const matchesFilter = filters.filter
          ? filters.filter == 'all'
            ? true
            : filters.filter == 'scheduled'
            ? dimona.result.scheduled
            : filters.filter == 'not-scheduled'
            ? !dimona.result.scheduled
            : false
          : true;
        const matchesTypes = filters.types.length > 0 ? filters.types.includes(dimona.workerType) : true;

        const dimonaState = (dimona.pending && !filters.pending)
          ? 'active_within_period'
          : dimona.status
          ? dimona.status.locked
          ? 'locked'
          : dimona.status.active || moment(dimona.startDate).isBetween(dates[0], dates[1])
          ? 'active_within_period'
          : dimona.status.late
          ? 'late'
          : dimona.status.cancelled
          ? 'cancelled'
          : !dimona.status.active
          ? 'inactive'
          : dimona.status.active
          ? 'active'
          : ''
          : ''

        const matchesStates =
          filters.states.length > 0
            ? filters.errors
              ? true
              : filters.pending
              ? true
              : filters.states.includes(dimonaState)
            : filters.errors
            ? true
            : filters.pending
            ? true
            : true;

        return matchesSearch && matchesUsers && matchesFilter && matchesTypes && matchesStates;
      }),
    );
  }, [data, filters, loadingDimonas, loadingDimonaErrors, loadingUserCategories]);

  useEffect(() => {
    getDimonas();
    getDimonaErrors();
    getDimonaPending();
  }, [dates]);

  const onDelete = (dimondaId: string) => {
    Modal.confirm({
      className: 'modal-danger',
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('DIMONA.MODAL_DELETE.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        handleDelete(dimondaId);
      },
      onCancel: () => {},
    });
  };

  const handleDelete = (dimonaId: string) => {
    Axios.delete(`${process.env.REACT_APP_API_URL}/v3/dimona/${dimonaId}`, {
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then(() => {
        getDimonas();
        setActiveDimona(null);
        setDetailedDimona(null);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const onLock = (dimondaId: string) => {
    Modal.confirm({
      className: 'modal-danger',
      title: t('GLOBAL.ENCLOSURE'),
      icon: null,
      content: t('DIMONA.MODAL_LOCK_DIMONA.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.ENCLOSE'),
      okType: 'danger',
      onOk: () => {
        handleLock(dimondaId);
      },
      onCancel: () => {},
    });
  };

  const handleLock = (dimonaId: string) => {
    Axios.patch(
      `${process.env.REACT_APP_API_URL}/v3/dimona/${dimonaId}`,
      {
        locked: true,
      },
      {
        params: {
          departmentId: activeDepartmentId,
        },
      },
    )
      .then((response) => {
        getDimonas();
        setActiveDimona(null);
        setDetailedDimona(null);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const onUpdateFromOnss = () => {
    Axios.post(`${process.env.REACT_APP_API_URL}/v3/dimona/consult`, null, {
      params: {
        departmentId: activeDepartmentId,
      },
    }).then(({ data }) => {});
  };

  const onRow = (dimona: IV4Dimona) => {
    return {
      onClick: () => {
        if (filters.errors || filters.pending) return;
        setDetailedDimona(dimona);
      },
    };
  };

  const onCreateDimona = () => {
    if (user) {
      setNewDimona({
        user: {
          recordId: user.recordId!,
          displayName: user.displayName!,
        },
      });
    } else {
      setNewDimona({});
    }
  };

  const onUpdateDimona = () => {
    setActiveDimona(detailedDimona);
  };

  const onSave = () => {
    getDimonas();
    setActiveDimona(null);
    setDetailedDimona(null);
  };

  return (
    <React.Fragment>
      <TableView className={className}>
        <Header
          user={user}
          onCreateDimona={onCreateDimona}
          onUpdateFromOnss={onUpdateFromOnss}
          dates={dates}
          setDates={setDates}
          filters={filters}
          setFilters={setFilters}
          dimonaErrors={dimonaErrors}
          dimonaPending={dimonaPending}
        />
        <Table
          dataSource={filtered}
          columns={columns}
          loading={filters.pending ? loadingDimonaPending : filters.errors ? loadingDimonaErrors : loadingDimonas}
          rowKey="id"
          pagination={{ position: ['bottomRight'], defaultPageSize: 25 }}
          onRow={onRow}
          rowClassName={(dimona) =>
            `${dimona.user.recordId && !filters.errors && !filters.pending ? 'zoomable' : ''} ${
              filters.errors || filters.pending ? '' : 'row-clickable'
            }`
          }
        />
      </TableView>
      <DrawerDimona visible={!!newDimona} dimona={newDimona} onClose={() => setNewDimona(null)} onSave={onSave} />
      <DrawerUpdateDimona
        visible={!!activeDimona}
        onClose={() => setActiveDimona(null)}
        onSave={onSave}
        activeDimona={activeDimona}
      />
      <ModalDimonaDetails
        activeDimona={detailedDimona}
        visible={!!detailedDimona}
        setVisible={() => {
          setDetailedDimona(null);
        }}
        onUpdateDimona={onUpdateDimona}
        onCloseDimona={onLock}
        onDeleteDimona={onDelete}
      />
    </React.Fragment>
  );
};

export default styled(DimonaStatement)`
  .ant-table-row {
    &.processing {
      background-color: antiquewhite;
    }
    &.zoomable {
      cursor: zoom-in;
    }
  }
  .ant-table-thead {
    .ant-table-cell {
      padding: 16px 10px;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 10px 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .light-gray {
      color: #999;
    }
  }
`;
