import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IV4Dimona } from '@/types/dimona.model';
import { convertUtcToTimezoneDateAndTime } from '@/utils';
import { Button, Form, Modal, Table } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  activeDimona: IV4Dimona | null;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateDimona: () => void;
  onCloseDimona: (dimondaId: string) => void;
  onDeleteDimona: (dimondaId: string) => void;
}

const ModalDimonaDetails: React.FC<Props> = ({
  className,
  visible,
  setVisible,
  activeDimona,
  onUpdateDimona,
  onCloseDimona,
  onDeleteDimona,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, users, userCategories },
    dispatch,
  } = useContext(AppContext);
  const [columns, setColumns] = useState<any[]>([]);

  const status =
    activeDimona && activeDimona.status && activeDimona.user.userStatusId
      ? userCategories.find((status) => status.id == activeDimona.user.userStatusId)
      : null;

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.TYPE'),
        key: 'type',
        dataIndex: 'type',
      },
      {
        title: t('GLOBAL.START'),
        key: 'periodStart',
        dataIndex: 'periodStart',
        render: (_: string, record: any) => {
          return convertUtcToTimezoneDateAndTime(record.periodStart, activeDepartment);
        },
      },
      {
        title: t('GLOBAL.END'),
        key: 'periodEnd',
        dataIndex: 'periodEnd',
        render: (_: string, record: any) => {
          return convertUtcToTimezoneDateAndTime(record.periodEnd, activeDepartment);
        },
      },
      {
        title: t('GLOBAL.PROCESSED_ON'),
        key: 'processedAt',
        dataIndex: 'processedAt',
        render: (_: string, record: any) => {
          return convertUtcToTimezoneDateAndTime(record.processedAt, activeDepartment);
        },
      },
      {
        title: t('GLOBAL.ERRORS'),
        key: 'errors',
        dataIndex: 'errors',
        render: (_: string, record: any) => {
          if (!record.errors) return;
          return (
            <span>
              {record.errors.includes((error: any) => error.blocking) && (
                <i className="icon-attention" style={{ color: colors.red }} />
              )}
              {record.errors.map((error: any) => error.message).join(', ')}
            </span>
          );
        },
      },
    ]);
  }, [activeDimona, activeDepartment]);

  const onClose = () => {
    setVisible(false);
  };

  if (!activeDimona) return <></>;

  return (
    <Modal
      className={className}
      visible={visible}
      onCancel={onClose}
      getContainer={false}
      forceRender
      footer={null}
      width={1000}
    >
      <Form>
        <StyledFieldset>
          <StyledLegend>{t('GLOBAL.WORKER')}</StyledLegend>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
            <span>
              {t('GLOBAL.NATIONAL_NUMBER')}: <b>{activeDimona.niss}</b>
            </span>
            <span>
              {t('GLOBAL.BIRTH_DATE')}: <b>{moment(activeDimona.user.birthDate).format('L')}</b>
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
            <span>
              {t('GLOBAL.NAME')}: <b>{activeDimona.user.lastName}</b>
            </span>
            <span>
              {t('GLOBAL.STATUS')}: <b>{status ? status.name : '-'}</b>
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
            <span>
              {t('GLOBAL.FIRSTNAME')}: <b>{activeDimona.user.firstName}</b>
            </span>
            <span>
              {t('GLOBAL.WORKER_TYPE')}: <b>{activeDimona.workerType}</b>
            </span>
          </div>
          {/* <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
            <span>
              Nationalité: <b>Belge</b>
            </span>
          </div> */}
        </StyledFieldset>
        {/* <StyledFieldset>
          <StyledLegend>Données techniques</StyledLegend>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
            <span>
              {t('DIMONA.N_DECLARATION')}: <b>{activeDimona.dimona_period_id}</b>
            </span>
            <span>
              {t('GLOBAL.STATUS')}: <b>-</b>
            </span>
          </div>
          <div></div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
            <span>
              Méthode: <b>-</b>
            </span>
            <span>
              {t('GLOBAL.SENT_DATE')}: <b>{moment(activeDimona.created_at).format('L')}</b>
            </span>
          </div>
        </StyledFieldset> */}
        <StyledFieldset>
          <StyledLegend>{t('DIMONA.DECLARATION_CONTENT')}</StyledLegend>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
            <span>
              {t('DIMONA.N_DECLARATION')}: <b>{activeDimona.dimonaPeriodId}</b>
            </span>
            <span>
              {t('GLOBAL.START_DATE')}: <b>{moment(activeDimona.startDate).format('L')}</b>
            </span>
            <span>
              {t('GLOBAL.START_HOUR')}:{' '}
              <b>{activeDimona.startHour && activeDimona.startHour != '' ? activeDimona.startHour : '-'}</b>
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
            <span>
              {t('GLOBAL.SENT_DATE')}: <b>{moment(activeDimona.createdAt).format('L')}</b>
            </span>
            <span>
              {t('GLOBAL.END_DATE')}: <b>{activeDimona.endDate ? moment(activeDimona.endDate).format('L') : '-'}</b>
            </span>
            <span>
              {t('GLOBAL.END_HOUR')}:{' '}
              <b>{activeDimona.endHour && activeDimona.endHour != '' ? activeDimona.endHour : '-'}</b>
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
            <span>
              CP: <b>{activeDimona.jointCommissionNumber}</b>
            </span>
            <span>
              {t('GLOBAL.WORKER_TYPE')}: <b>{activeDimona.workerType}</b>
            </span>
            <span>
              {t('GLOBAL.NUMBER_OF_HOURS')}: <b>{activeDimona.plannedHours == 0 ? '-' : activeDimona.plannedHours}</b>
            </span>
          </div>
        </StyledFieldset>
        {activeDimona.history && activeDimona.history.length > 0 && (
          <StyledFieldset>
            <StyledLegend>{t('GLOBAL.NOTIFICATIONS')}</StyledLegend>
            <div style={{ gridColumn: '1/5' }}>
              <Table columns={columns} dataSource={activeDimona.history} pagination={false} />
            </div>
          </StyledFieldset>
        )}
        {!activeDimona.pending && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
              <Button style={{ flex: 1 }} type="primary" size="large" onClick={onUpdateDimona}>
                {t('DIMONA.UPDATE_DIMONA')}
              </Button>
              <Button
                type="primary"
                style={{ flex: 1, backgroundColor: colors.lightBlack, borderColor: colors.lightBlack }}
                size="large"
                onClick={() => {
                  onCloseDimona(activeDimona.id);
                }}
              >
                {t('DIMONA.CLOSE_DIMONA')}
              </Button>
              <Button
                style={{ flex: 1 }}
                type="primary"
                danger
                size="large"
                onClick={() => {
                  onDeleteDimona(activeDimona.id);
                }}
              >
                {t('DIMONA.DELETE_DIMONA')}
              </Button>
            </div>
          </div>
        )}
      </Form>
    </Modal>
  );
};

const StyledFieldset = styled.fieldset`
  border: 1px solid #ccc;
  padding: 10px;
  padding-bottom: 15px;
  margin: 15px 0;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const StyledLegend = styled.legend`
  padding: 0px 10px !important;
  font-weight: bold;
  border-bottom: 0 !important;
  width: auto !important;
  margin: 0 !important;
  font-weight: bolder !important;
  text-transform: uppercase !important;
  color: ${colors.green} !important;
`;

const StyledContent = styled.legend`
  display: flex;
  flex-direction: column;
`;

export default styled(ModalDimonaDetails)`
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 8px;
  }
`;
