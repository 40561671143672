import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { IResource } from '@/types/resource.model';
import { getWindowSize, isFeatureEnabled } from '@/utils';
import { MenuOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Drawer, Form, Input, Popconfirm, Select, notification } from 'antd';
import axios from 'axios';
import update from 'immutability-helper';
import React, { useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Card } from './Card';

const { Option } = Select;

interface Props {
  className?: string;
  departmentId?: string;
  visible: boolean;
  resource?: IResource | null;
  onClose: () => void;
  onSave: (result: IResource[]) => void;
}

const DrawerResource: React.FC<Props> = ({ className, departmentId, visible, resource, onClose, onSave }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    state: { activeDepartmentId, features },
  } = useContext(AppContext);
  useEffect(() => {
    form.resetFields();
    let fieldsValue: any = {
      resourceType: 'GENERIC',
    };

    if (resource) {
      fieldsValue = {
        ...fieldsValue,
        ...resource,
        fields: [
          ...(resource?.fields
            ?.filter((x) => !x.locked)
            .map((x) => ({
              id: x.id,
              label: x.label,
              name: x.name,
              mandatory: x.mandatory,
              showApp: x.showApp,
            })) || []),
        ],
      };
    }
    form.setFieldsValue(fieldsValue);
  }, [resource]);

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const fields = [...form.getFieldValue('fields')];
    const dragField = fields[dragIndex];
    form.setFieldsValue({
      fields: update(fields, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragField],
        ],
      }),
    });
  };

  const onFinish = async (values: any) => {
    setIsSaving(true);
    let resourceResponse;

    try {
      if (resource?.id) {
        resourceResponse = await axios.patch(
          `${process.env.REACT_APP_API_URL}/v3/resources/${resource.id!}`,
          {
            name: values.name,
            fields: [...values.fields],
            displayWhenFreeClocking: values.displayWhenFreeClocking || false,
          },
          {
            params: {
              departmentId,
            },
          },
        );
      } else {
        resourceResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/v3/resources`,
          {
            departmentId: activeDepartmentId,
            ...values,
            displayWhenFreeClocking: values.displayWhenFreeClocking || false,
          },
          {
            params: {
              departmentId,
            },
          },
        );
      }

      if (resourceResponse) {
        onSave([...resourceResponse?.data.resources]);
        onClose();
      } else {
        onClose();
      }
    } catch (error) {
      console.error(error);
      const description = resource?.id
        ? t('ATTRIBUTES.ATTRIBUTE_CATEGORY_UPDATE_FAILED')
        : t('ATTRIBUTES.ATTRIBUTE_CATEGORY_CREATION_FAILED');
      notification.open({
        message: t('GLOBAL.AN_ERROR_OCCURRED'),
        description,
        type: 'error',
      });
    }
    setIsSaving(false);
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={resource?.id ? t('ATTRIBUTES.UPDATE_ATTRIBUTES_CATEGORY') : t('ATTRIBUTES.CREATE_ATTRIBUTES_CATEGORY')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="name" label={t('ATTRIBUTES.CATEGORY_NAME')}>
          <Input placeholder={t('ATTRIBUTES.CATEGORY_NAME')} />
        </Form.Item>
        <Form.Item label={t('ATTRIBUTES.ATTRIBUTE_TYPE')} name="resourceType" style={{ marginBottom: 0 }}>
          <Select
            getPopupContainer={(trigger) => trigger}
            placeholder={t('ATTRIBUTES.ATTRIBUTE_TYPE')}
            optionFilterProp="children"
            disabled={!!resource?.id}
          >
            <Option value={'GENERIC'}>{t('GLOBAL.GENERIC')}</Option>
            <Option value={'PLACE'}>{t('GLOBAL.PLACE')}</Option>
            <Option value={'TOOL'}>{t('GLOBAL.TOOL')}</Option>
          </Select>
        </Form.Item>
        {isFeatureEnabled(features, FEATURES.CLOCKING) && (
          <Form.Item name="displayWhenFreeClocking" valuePropName="checked">
            <Checkbox>{t('FORMS.DISPLAY_FOR_FREE_CLOCKING')}</Checkbox>
          </Form.Item>
        )}
        <Divider />
        <Form.Item
          shouldUpdate={(prevValues, curValues) => prevValues.resourceType !== curValues.resourceType}
          style={{ margin: 0 }}
        >
          {() => {
            const activeType = form.getFieldValue('resourceType');
            switch (activeType) {
              case 'PLACE': {
                return (
                  <div className="default-fields">
                    <Input disabled={true} value={`${t('GLOBAL.NAME')}`} />
                    <Input disabled={true} value={`${t('GLOBAL.ADDRESS')}`} />
                    <Input disabled={true} value={`${t('GLOBAL.CODE')}`} />
                  </div>
                );
              }
              case 'TOOL': {
                return (
                  <div className="default-fields">
                    <Input disabled={true} value={`${t('GLOBAL.NAME')}`} />
                    <Input disabled={true} value={`${t('GLOBAL.CODE_TYPE')}`} />
                    <Input disabled={true} value={`${t('GLOBAL.CODE')}`} />
                  </div>
                );
              }
              default: {
                return <Input disabled={true} value={`${t('GLOBAL.NAME')}`} />;
              }
            }
          }}
        </Form.Item>
        <Divider />
        <DndProvider debugMode={true} backend={HTML5Backend}>
          <Form.List name="fields">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Card key={field.key} moveCard={moveCard} index={index} id={field.fieldKey}>
                    <MenuOutlined />
                    <div style={{ display: 'flex' }}>
                      <div>
                        <Form.Item
                          {...field}
                          name={[field.name, 'label']}
                          fieldKey={[field.fieldKey, 'id']}
                          rules={[{ required: true, message: 'Missing name' }]}
                          className="one-line"
                          style={{ flex: 1, margin: 0, padding: '0 10px' }}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <Form.Item
                          name={[field.name, 'mandatory']}
                          valuePropName="checked"
                          initialValue={false}
                          style={{ margin: 0 }}
                        >
                          <Checkbox>{t('GLOBAL.REQUIRED')}</Checkbox>
                        </Form.Item>
                        <Form.Item
                          name={[field.name, 'showApp']}
                          valuePropName="checked"
                          initialValue={false}
                          style={{ margin: 0 }}
                        >
                          <Checkbox>App</Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                    <Popconfirm
                      title={t('GLOBAL.ARE_YOU_SURE?')}
                      okText={t('GLOBAL.YES')}
                      cancelText={t('GLOBAL.NO')}
                      onConfirm={() => remove(field.name)}
                    >
                      <MinusCircleOutlined style={{ paddingLeft: 10 }} />
                    </Popconfirm>
                  </Card>
                ))}

                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    {t('FORMS.ADD_EXTRA_FIELD')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </DndProvider>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default styled(DrawerResource)`
  .default-fields {
    input:nth-of-type(n + 2) {
      margin-top: 5px;
    }
  }
`;
