import { Col, Form, Input, Row, Select } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IResponse } from '.';
import { SelectValue } from 'antd/es/select';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
  socialPartners: IResponse['meta'][0];
}

const SocialPartners: React.FC<Props> = ({ className, socialPartners }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [activeSsaId, setActiveSsaId] = useState<string>(
    socialPartners ? (socialPartners.length == 1 ? socialPartners[0].id : '') : '',
  );

  const onChangeSsa = (value: SelectValue) => {
    setActiveSsaId(value as string);
  };

  return (
    <div className={className}>
      <h4>{t('USERS.USER.SETTINGS.LINKED_HR_PARTNER')}</h4>

      <Row gutter={[20, 10]}>
        <Col lg={8} md={12} style={{ width: '100%' }}>
          <Form.Item name={['socialPartnerId']}>
            <Select
              style={{ minWidth: 120 }}
              getPopupContainer={(trigger) => trigger}
              showSearch
              placeholder={t('FORMS.HR_PARTNER_PLACEHOLDER')}
              optionFilterProp="children"
              filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={onChangeSsa}
            >
              {(socialPartners || []).map((socialPartner) => (
                <Option key={socialPartner.id} value={socialPartner.id!}>
                  {socialPartner.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} md={12} style={{ width: '100%' }}>
          <Form.Item name={'contractNumber'}>
            <Input placeholder={t('CONTRACTS.CONTRACT_NUMBER')} />
          </Form.Item>
        </Col>
        {activeSsaId == '22' && (
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'extraNumber'}>
              <Input placeholder={t('CONTRACTS.EXTRA_NUMBER')} />
            </Form.Item>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default styled(SocialPartners)`
  .form-list {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px !important;

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
  }
`;
