import { ICustomHrCode } from '@/types/custom-hr-code.model';
import { IHrPartner } from '@/types/hr-partner.model';
import { getWindowSize } from '@/utils';
import { Button, Drawer, Form, Input, Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
  customHrCode?: ICustomHrCode | null;
  defaultHrPartners: IHrPartner[];
  visible: boolean;
  onClose: () => void;
}

const DrawerCustomHr: React.FC<Props> = ({ className, customHrCode, defaultHrPartners, visible, onClose }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, customHrCodes },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...customHrCode,
    });
    // eslint-disable-next-line
  }, [customHrCode]);

  const onFinish = (values: any) => {
    setLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    if (!customHrCode) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/custom-hr-codes`,
          {
            ...values,
            description: values.name,
            partner_id: defaultHrPartners.length == 1 ? defaultHrPartners[0].id : values.partner_id,
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
            cancelToken: cancelTokenSource.token,
          },
        )
        .then(({ data }) => {
          dispatch({
            type: 'SET_CUSTOM_HR_CODES',
            payload: [...customHrCodes, data],
          });
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/custom-hr-codes/${customHrCode.id}`,
          {
            ...values,
            description: values.name,
            partner_id: defaultHrPartners.length == 1 ? defaultHrPartners[0].id : values.partner_id,
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
            cancelToken: cancelTokenSource.token,
          },
        )
        .then(({ data }) => {
          dispatch({
            type: 'SET_CUSTOM_HR_CODES',
            payload: customHrCodes.map((code) => (code.id == customHrCode.id ? data : code)),
          });
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <Drawer
      maskClosable
      title={
        customHrCode?.id
          ? t('CUSTOM_HR_CODES.EDIT_CODE', { name: customHrCode.hr_code })
          : t('CUSTOM_HR_CODES.ADD_CODE')
      }
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        {defaultHrPartners.length > 1 && (
          <Form.Item name="partner_id" label={t('GLOBAL.SSA')} rules={[{ required: true }]}>
            {defaultHrPartners.map((partner) => (
              <Select>
                <Select.Option value={partner.id}>{partner.name}</Select.Option>
              </Select>
            ))}
          </Form.Item>
        )}
        <Form.Item name="code" label={t('GLOBAL.CODE')} rules={[{ required: true }, { max: 7 }]}>
          <Input placeholder={t('GLOBAL.CODE')} />
        </Form.Item>
        <Form.Item name="hr_code" label={t('GLOBAL.HR_CODE_SHORT')} rules={[{ required: true }, { max: 7 }]}>
          <Input placeholder={t('GLOBAL.HR_CODE_SHORT')} />
        </Form.Item>
        <Form.Item name="name" label={t('GLOBAL.DESCRIPTION')} rules={[{ required: true }]}>
          <Input.TextArea rows={5} placeholder={t('GLOBAL.DESCRIPTION')} />
        </Form.Item>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerCustomHr;
