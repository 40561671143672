import AppContext from '@/pages/app/context';
import { IScheduleHour } from '@/types/schedule-hour.model';
import { IShiftTemplate } from '@/types/shift-template.model';
import { IShift } from '@/types/shift.model';
import { Button, Checkbox, Form, message, Modal, Select } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../redux/actions';
import { InitialStateType } from '../redux/store';

interface Props {
  className?: string;
  start: number;
  mode: 'import' | 'compare' | null;
  onClose: () => void;
}

const { Option } = Select;

const ModalChooseShiftTemplate: React.FC<Props> = ({ className, mode, start, onClose }) => {
  const [form] = Form.useForm();
  const {
    state: { activeDepartment, activeDepartmentId, activeSection, skills, userCategories },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const { startDate, endDate, picker } = useSelector(
    ({ startDate, endDate, picker }: InitialStateType) => ({
      startDate,
      endDate,
      picker,
    }),
    shallowEqual,
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [shiftTemplates, setShiftTemplates] = useState<IShiftTemplate[]>([]);
  const [loadingShiftTemplates, setLoadingShiftTemplates] = useState<boolean>(false);

  useEffect(() => {
    if (!mode) {
      return;
    }
    form.resetFields();
    setLoadingShiftTemplates(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/shift-templates`, {
        params: {
          departmentId: activeDepartmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        setShiftTemplates(response.data.shiftTemplates);
      })
      .catch((error) => {
        console.error(error);
        setShiftTemplates([]);
      })
      .finally(() => {
        setLoadingShiftTemplates(false);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [mode]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    hoursDispatch({
      type: 'SET_IS_LOADING',
      payload: true,
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/operations/${
          mode === 'import' ? 'import-shift-template' : 'week-comparison'
        }`,
        {
          startDate: start,
          shiftTemplateId: values?.template,
          convert_freeshifts: values?.convert_freeshifts ? values.convert_freeshifts : undefined,
          departmentId: activeDepartmentId,
          sectionId: activeSection,
        },
        {
          params: {
            startDate: startDate.unix(),
            endDate: endDate.unix(),
            picker,
          },
        },
      )
      .then((response) => {
        const {
          shifts,
          users,
          days = [],
          hours = [],
          totalBudget = null,
          comparisonTemplate = null,
          errors,
          cycleNumber = null,
          scheduleModel = false,
          productivityIndex = null,
          productivityIndexReal = null,
        } = response.data;
        const filteredShifts = [...shifts].filter(
          (shift: IShift) => moment.unix(shift.start!).day() == startDate.day() && shift.shyftType == 1,
        );
        const earliestShift = filteredShifts.reduce((earliestShift, currentShift) => {
          const shiftStartDate = moment.unix(currentShift.start);
          const shiftEndDate = moment.unix(currentShift.end);

          // Check if the start date is earlier than the current earliest shift
          if (shiftStartDate.isBefore(moment.unix(earliestShift.start))) {
            return currentShift;
          }

          return earliestShift;
        }, filteredShifts[0]);
        const latestShift = filteredShifts.reduce((latestShift, currentShift) => {
          const shiftStartDate = moment.unix(currentShift.start);
          const shiftEndDate = moment.unix(currentShift.end);

          // Check if the start date is today and the end date is greater than the current latest shift
          if (shiftEndDate.isAfter(moment.unix(latestShift.end))) {
            return currentShift;
          }

          return latestShift;
        }, filteredShifts[0]);
        batch(() => {
          const startHour = activeDepartment?.scheduleParams?.startHour || 0;
          const endHour = activeDepartment?.scheduleParams?.endHour || 24;
          const dynamicHours = activeDepartment?.scheduleParams?.dynamicHour;

          let nextDay = false;
          if (earliestShift && latestShift && earliestShift.start && latestShift.end) {
            nextDay = moment.unix(latestShift.end).day() != moment.unix(earliestShift.start).day();
          }

          const start = dynamicHours
            ? earliestShift && earliestShift.start
              ? moment.unix(earliestShift.start).subtract(1, 'hour').hours()
              : 9
            : startHour;
          const end = dynamicHours
            ? latestShift && latestShift.end
              ? moment.unix(latestShift.end).add(1, 'hour').hours()
              : 24
            : endHour;
          const tz = activeDepartment?.timezone || 'Europe/Brussels';
          const isMidnight =
            earliestShift && earliestShift.startDate ? earliestShift.startDate.split(' ')[1].startsWith('00:') : false;

          hoursDispatch({
            type: 'SET_SHIFTS',
            payload: {
              skills,
              userStatus: userCategories,
              picker,
              activeDepartment,
              shifts,
              users,
              days,
              hours:
                picker == 'day'
                  ? hours.filter(
                      (hour: IScheduleHour) =>
                        hour.date >= moment(startDate.clone().set({ hours: isMidnight ? -1 : start })).unix() &&
                        hour.date <=
                          moment(
                            startDate
                              .clone()
                              .add(1, 'day')
                              .toDate()
                              .setHours(end + 1),
                          ).unix(),
                    )
                  : hours,
              totalBudget,
              comparisonTemplate,
              cycleNumber,
              scheduleModel,
              productivityIndex,
              productivityIndexReal,
              activeSection,
            },
          });
          hoursDispatch({
            type: 'UPDATE_FILTERED_SHIFTS',
            payload: { department: activeDepartment },
          });
          hoursDispatch({
            type: 'SET_IS_LOADING',
            payload: false,
          });
        });

        if (errors) {
          const errorsLength = errors.length;
          if (errorsLength > 1) {
            message.error(t('WEEK_TEMPLATES.MODAL_CHOOSE_TEMPLATE.X_SHIFTS_NOT_IMPORTED', { count: errorsLength }));
          } else {
            message.error(t('WEEK_TEMPLATES.MODAL_CHOOSE_TEMPLATE.ONE_SHIFT_NOT_IMPORTED'));
          }
        }
        setIsSaving(false);
        onClose();
      })
      .catch((error) => {
        hoursDispatch({
          type: 'SET_IS_LOADING',
          payload: false,
        });
        message.error(t('WEEK_TEMPLATES.MODAL_CHOOSE_TEMPLATE.MESSAGE_IMPORTATION_ERROR'));
        console.error(error);
        setIsSaving(false);
      });
  };

  return (
    <Modal
      className={className}
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={!!mode}
      onOk={() => form.submit()}
      onCancel={() => onClose()}
      title={
        mode === 'compare'
          ? t('WEEK_TEMPLATES.MODAL_CHOOSE_TEMPLATE.COMPARE_TITLE')
          : t('WEEK_TEMPLATES.MODAL_CHOOSE_TEMPLATE.IMPORT_TITLE')
      }
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
          {mode === 'compare' ? t('GLOBAL.COMPARE') : t('GLOBAL.IMPORT')}
        </Button>,
      ]}
      style={{ zIndex: 999999 }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="template" rules={[{ required: true, message: '' }]}>
          <Select
            clearIcon
            getPopupContainer={(trigger) => trigger}
            showSearch
            placeholder={t('WEEK_TEMPLATES.MODAL_CHOOSE_TEMPLATE.TEMPLATE_PLACEHOLDER')}
            optionFilterProp="children"
            loading={loadingShiftTemplates}
            disabled={loadingShiftTemplates}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {shiftTemplates
              ?.filter((x) => (activeSection ? x.sectionId === activeSection : true))
              ?.map((shiftTemplate) => (
                <Option key={`shiftTemplate_${shiftTemplate.id}`} value={`${shiftTemplate.id}`}>
                  {shiftTemplate.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="convert_freeshifts" valuePropName="checked">
          <Checkbox>{t('WEEK_TEMPLATES.MODAL_CHOOSE_TEMPLATE.CREATE_FREE_SHIFTS')}</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalChooseShiftTemplate)`
  z-index: 10;
`;
