import React, { useState, useEffect, useRef, useContext } from 'react';
import TableView from '@/layouts/TableView';
import Header from './components/Header';
import TableRowActions from './components/TableRowActions';
import { Table, message, Modal } from 'antd';
import axios from 'axios';
import { AlignType } from '@/types/alignType.model';
import { useTranslation } from 'react-i18next';
import { IUser } from '@/types/user.model';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
}

const ManageDeactivatedUsers: React.FC<Props> = ({ className }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [deactivatedUsers, setDeactivatedUsers] = useState<IUser[]>([]);
  const [loadingDeactivatedUsers, setLoadingDeactivatedUsers] = useState<boolean>(false);
  const deactivatedUsersRef = useRef(deactivatedUsers);
  deactivatedUsersRef.current = deactivatedUsers;
  const [filteredDeactivatedUsers, setFilteredDeactivatedUsers] = useState<IUser[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    getInactiveUsers();
  }, []);

  const getInactiveUsers = () => {
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingDeactivatedUsers(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users`, {
        params: {
          departmentId: activeDepartmentId,
          inactive: true,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const { data } = response;
        setDeactivatedUsers(data.users);
      })
      .catch((error) => {
        console.error(error);
        setDeactivatedUsers([]);
      })
      .finally(() => {
        setLoadingDeactivatedUsers(false);
      });
  };

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.FIRSTNAME'),
        dataIndex: 'firstname',
        key: 'firstname',
        sorter: (a: any, b: any) => a.firstname?.localeCompare(b.firstname),
      },
      {
        title: t('GLOBAL.LASTNAME'),
        dataIndex: 'lastname',
        key: 'lastname',
        sorter: (a: any, b: any) => a.lastname?.localeCompare(b.lastname),
      },
      {
        title: t('GLOBAL.EMAIL'),
        dataIndex: 'email',
        key: 'email',
        sorter: (a: any, b: any) => a.email?.localeCompare(b.email),
      },
      {
        key: 'action',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IUser) => (
          <TableRowActions
            user={record}
            onActivate={() => onWantToActivate(record.recordId!)}
            onDeletedPermanently={onDeletedPermanently}
          />
        ),
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    const filteredDeactivatedUsers = deactivatedUsers.filter((user: any) => {
      return Object.keys(user).some((key) => {
        if (typeof user[key] === 'string') {
          return user[key].toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return false;
        }
      });
    });
    setFilteredDeactivatedUsers(filteredDeactivatedUsers);
  }, [deactivatedUsers, searchTerm]);

  const onWantToActivate = (userId: string) => {
    Modal.confirm({
      title: t('GLOBAL.ACTIVATION'),
      icon: null,
      content: t('USERS.MODAL_ACTIVATE_USER.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.ACTIVATE'),
      okType: 'danger',
      onOk: () => {
        onActivate(userId);
      },
      onCancel: () => {},
    });
  };

  const onActivate = (userId: string) => {
    if (!userId) return;
    const deactivatedUserCopy = [...deactivatedUsersRef.current];
    const index = deactivatedUserCopy.findIndex((user) => user.recordId === userId);

    if (~index) {
      const deactivatedUsersResult = [...deactivatedUserCopy];
      deactivatedUsersResult.splice(index, 1);
      setDeactivatedUsers(deactivatedUsersResult);
    }

    axios
      .patch(`${process.env.REACT_APP_API_URL}/v3/users/${userId}/params`, {
        departmentId: activeDepartmentId,
        active: true,
      })
      .catch((error) => {
        if (~index) {
          console.error(error);
          message.error(t('USERS.MODAL_ACTIVATE_USER.MESSAGE_ERROR'));
          if (deactivatedUserCopy) {
            setDeactivatedUsers(deactivatedUserCopy);
          }
        }
      });
  };

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchTerm(value);
  };

  const onDeletedPermanently = () => {
    getInactiveUsers();
  };

  return (
    <React.Fragment>
      <h2>{t('SETTINGS.GENERAL.DEACTIVATED_USERS.TITLE')}</h2>
      <div
        style={{
          backgroundColor: 'white',
          padding: 25,
          borderRadius: 10,
          marginTop: 25,
        }}
      >
        <TableView>
          <Header disabled={false} onSearchChange={onSearchChange} />
          <Table
            className={className}
            loading={loadingDeactivatedUsers}
            dataSource={filteredDeactivatedUsers}
            columns={columns}
            rowKey="id"
            pagination={false}
          />
        </TableView>
      </div>
    </React.Fragment>
  );
};

export default ManageDeactivatedUsers;
