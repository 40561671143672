import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Chartjs from 'chart.js';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Empty, Spin } from 'antd';
import colors from '@/styles/colors';
import styled from 'styled-components';

interface Props {
  className?: string;
  userMainId?: string;
  departmentId?: string;
  setHideMonthlyActivity: React.Dispatch<React.SetStateAction<boolean>>;
}

const MonthlyActivity: React.FC<Props> = ({ className, departmentId, userMainId, setHideMonthlyActivity }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState<Chartjs | null>(null);

  const { t, i18n } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    const cancelTokenSource = Axios.CancelToken.source();
    if (!departmentId || !userMainId) {
      return;
    }

    setIsLoading(true);
    if (chartInstance) {
      chartInstance.destroy();
      setChartInstance(null);
    }
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/dashboard/monthly-activity`, {
      params: {
        userMainId,
        departmentId,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        const { dates, workDays, absences, dayoffs } = response.data;
        if (!!dates?.length) {
          const newChartInstance = new Chartjs(chartContainer.current!, {
            type: 'bar',
            data: {
              labels: dates.map((date: number) => moment.unix(date).format('MMM')),
              datasets: [
                {
                  label: t('GLOBAL.DAYS_WORKED'),
                  backgroundColor: colors.green,
                  data: workDays,
                },
                {
                  label: t('GLOBAL.ABSENCES'),
                  backgroundColor: colors.greenLight,
                  data: absences,
                },
                {
                  label: t('GLOBAL.LEAVES'),
                  backgroundColor: colors.grey,
                  data: dayoffs,
                },
              ],
            },
            options: {
              legend: {
                position: 'bottom',
              },
              tooltips: {
                mode: 'index',
                intersect: false,
              },
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                xAxes: [
                  {
                    stacked: true,
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                  },
                ],
              },
            },
          });
          setChartInstance(newChartInstance);
        } else {
          setHideMonthlyActivity(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [departmentId, userMainId]);

  return (
    <div className={className}>
      <Spin spinning={isLoading}>
        <div>
          <h4>{t('USERS.USER.DASHBOARD.ACTIVITY_OVER_YEAR')}</h4>
          {!isLoading && !chartInstance && <Empty />}
          <div
            className="chart-container"
            style={chartInstance ? { position: 'relative', height: 230, width: '100%' } : { display: 'none' }}
          >
            <canvas ref={chartContainer} />
          </div>
        </div>
      </Spin>
    </div>
  );
};

const MonthlyActivityStyled = styled(MonthlyActivity)`
  width: 100%;
  .ant-spin-nested-loading {
    width: 100%;
  }
`;
export default MonthlyActivityStyled;
