import ModalChangesNotSaved from '@/pages/app/components/ModalChangesNotSaved';
import AppContext from '@/pages/app/context';
import FormActions from '@/pages/app/settings/components/FormActions';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { IHrSheet, IHrSheetTimesheets } from '@/types/reports/timesheets/sheet.model';
import {
  convertDecimalHourToTime,
  getDatesBetweenDates,
  handleError,
  isFeatureEnabled,
  minutesToHoursAndOrMinutes,
} from '@/utils';
import { Button, Form, Input, Table, Tooltip, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import { isNaN } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { IHrSheet as IHrSheetOld } from '..';
import HrSheetRuleLegendElement from './HrSheetRuleLegendElement';
import ModalAddNewCode from './ModalAddNewCode';
import ModalInvalidInputs from './ModalInvalidInputs';

interface Props {
  className?: string;
  loading: boolean;
  sheet: IHrSheet;
  hrSheets?: IHrSheetOld[];
  format: 'hh:mm' | 'h,m';
  formHasChanged: boolean;
  preventBack: boolean;
  newCodes: any[];
  newUrl: string | null;
  result: IHrSheetTimesheets;
  invalidInputKeys: string[];
  datePickerValue: Moment;
  timesheetCopy: IHrSheetTimesheets;
  recordId?: string;
  setPreventBack: React.Dispatch<React.SetStateAction<boolean>>;
  setFormHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
  setNewCodes: React.Dispatch<React.SetStateAction<any[]>>;
  setNewUrl: React.Dispatch<React.SetStateAction<string | null>>;
  setResult: React.Dispatch<React.SetStateAction<IHrSheetTimesheets>>;
  setInvalidInputKeys: React.Dispatch<React.SetStateAction<string[]>>;
  getHrSheet: (type?: 'planned' | 'clocking' | null) => void;
}

const HrSheetTable: React.FC<Props> = ({
  className,
  loading,
  sheet,
  format,
  hrSheets,
  formHasChanged,
  preventBack,
  newCodes,
  newUrl,
  result,
  invalidInputKeys,
  datePickerValue,
  timesheetCopy,
  recordId,
  setPreventBack,
  setFormHasChanged,
  setNewCodes,
  setResult,
  setInvalidInputKeys,
  getHrSheet,
  setNewUrl,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, activeDepartmentId, userCategories, users, features },
    dispatch,
  } = useContext(AppContext);
  const [columns, setColumns] = useState<any[]>([]);
  const [modalNewCodeVisible, setModalNewCodeVisible] = useState<boolean>(false);
  const [modalInvalidInputs, setModalInvalidInputs] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [validateLoading, setValidateLoading] = useState<boolean>(false);

  const [form] = useForm();
  const { userRecordId, start } = useParams() as any;
  const history = useHistory();

  const userId = userRecordId || recordId;

  useEffect(() => {
    setNewCodes([
      {
        key: `sent-new--1`,
        title: (
          <Button className="add-code" htmlType="button" type="link" onClick={() => setModalNewCodeVisible(true)}>
            <i className="icon-plus add-code-icon" /> {t('REPORTS.TIMESHEETS.ADD_CODE')}
          </Button>
        ),
        data: {},
      },
    ]);
    setInvalidInputKeys([]);
  }, [userId]);

  const plannedRulesKeys =
    sheet.PARAMS.type == 'SHIFT'
      ? sheet.RULES.PLANNED
        ? Object.keys(sheet.RULES.PLANNED)
        : []
      : sheet.RULES.CLOCKIN
      ? Object.keys(sheet.RULES.CLOCKIN)
      : [];
  const dayoffKeys = Object.keys(sheet.DAYOFF);
  const timesheetsKeys = Object.keys(sheet.TIMESHEETS).sort();
  const total = [
    ...new Set([
      ...(sheet.PARAMS.type === 'SHIFT'
        ? Object.keys(sheet.PLANNED).flatMap((key) => Object.keys(sheet.PLANNED[key]))
        : []),
      ...(sheet.PARAMS.type === 'CLOCKING'
        ? Object.keys(sheet.CLOCKIN).flatMap((key) => Object.keys(sheet.CLOCKIN[key]))
        : []),
    ]),
  ];

  const plannedRulesRecords = sheet.RULES.PLANNED
    ? plannedRulesKeys
        .map((key, i) => {
          const code = sheet.CODES.find((code) => code.id == key);
          const rules = sheet.PARAMS.type == 'SHIFT' ? sheet.RULES.PLANNED : sheet.RULES.CLOCKIN;
          if (!code) return;
          return {
            key: `rules-${code.id}-${i}-${userId}`,
            title: (
              <Tooltip
                overlay={
                  <span>
                    {code.id} - {code.name}
                  </span>
                }
              >
                <div className="tooltip-trigger">
                  <span className="code-id">{code.id}</span>
                  <span className="name">{code.name}</span>
                </div>
              </Tooltip>
            ),
            data: rules[key],
          };
        })
        .filter((result) => result !== undefined)
    : [];

  const dayoffRecords = dayoffKeys
    .map((key) => {
      const code = sheet.CODES.find((code) => code.id == key);
      if (!code) return;
      return {
        key: `dayoff-${code.id}-${userId}-${userId}`,
        title: (
          <Tooltip
            overlay={
              <span>
                {code.id} - {code.name}
              </span>
            }
          >
            <div className="tooltip-trigger">
              <span className="code-id">{code.id}</span>
              <span className="name">{code.name}</span>
            </div>
          </Tooltip>
        ),
        data: sheet.DAYOFF[key],
      };
    })
    .filter((result) => result !== undefined);

  const timesheetsRecords = sheet.TIMESHEETS
    ? timesheetsKeys
        .map((key, i) => {
          const code = sheet.CODES.find((code) => code.id == key);
          if (!code) return;
          return {
            key: `sent-${code.id}-${i}-${userId}`,
            title: (
              <Tooltip
                overlay={
                  <span>
                    {code.id} - {code.name}
                  </span>
                }
              >
                <div className="tooltip-trigger">
                  <span className="code-id">{code.id}</span>
                  <span className="name">{code.name}</span>
                </div>
              </Tooltip>
            ),
            data: sheet.TIMESHEETS[code.id],
            codeId: code.id,
          };
        })
        .filter((result) => result !== undefined)
    : [];

  const editPercentage =
    (Object.values(sheet.MODIFIED)
      .map((datesForId) => Object.keys(datesForId))
      .flat().length /
      Object.values(sheet.TIMESHEETS)
        .map((datesForId) => Object.keys(datesForId))
        .flat().length) *
    100;

  const dataArray: any[] = [
    {
      key: 'subheader-1',
      title: t('REPORTS.TIMESHEETS.TIMESHEETS'),
    },
    {
      key: `planned${sheet.PARAMS.type == 'CLOCKING' ? '-inactive' : ''}`,
      title: (
        <Tooltip
          overlay={
            <span>
              {Object.keys(sheet.PLANNED)[0]} - {t('REPORTS.TIMESHEETS.PLANNED')}
            </span>
          }
        >
          <div className="tooltip-trigger">
            <span className="code-id">{Object.keys(sheet.PLANNED)[0]}</span>
            <span className="name">{t('REPORTS.TIMESHEETS.PLANNED')}</span>
          </div>
        </Tooltip>
      ),
      data: Object.values(sheet.PLANNED),
    },
    ...(isFeatureEnabled(features, FEATURES.CLOCKING)
      ? [
          {
            key: `clocking${sheet.PARAMS.type == 'SHIFT' ? '-inactive' : ''}`,
            title: (
              <Tooltip
                overlay={
                  <span>
                    {Object.keys(sheet.PLANNED)[0]} - {t('REPORTS.TIMESHEETS.CLOCKED')}
                  </span>
                }
              >
                <div className="tooltip-trigger">
                  <span className="code-id">{Object.keys(sheet.PLANNED)[0]}</span>
                  <span className="name">{t('REPORTS.TIMESHEETS.CLOCKED')}</span>
                </div>
              </Tooltip>
            ),
            data: Object.values(sheet.CLOCKIN),
          },
        ]
      : []),
    {
      key: 'empty-1',
    },
    ...(plannedRulesRecords && plannedRulesRecords.length > 0
      ? [
          {
            key: 'subheader-2',
            title: t('REPORTS.TIMESHEETS.INTERPRETED'),
          },
        ]
      : []),
    ...plannedRulesRecords,
    ...(plannedRulesRecords && plannedRulesRecords.length > 0
      ? [
          {
            key: 'empty-2',
          },
        ]
      : []),
    ...(dayoffRecords && dayoffRecords.length > 0
      ? [
          {
            key: 'subheader-3',
            title: t('REPORTS.TIMESHEETS.DAYOFFS'),
          },
        ]
      : []),
    ...dayoffRecords,
    ...(dayoffRecords && dayoffRecords.length > 0
      ? [
          {
            key: 'empty-3',
          },
        ]
      : []),
    {
      key: 'subheader-4',
      title: (
        <div className="tooltip-trigger sent">
          <span className="name">{t('REPORTS.TIMESHEETS.SENT')}</span>
          {editPercentage > 20 && (
            <Tooltip
              overlay={<span>{t('REPORTS.TIMESHEETS.EXCESS_EDITS', { percentage: editPercentage.toFixed(0) })}</span>}
            >
              <span className="excess-edits">
                <i className="icon-attention" style={{ color: '#ffdb4d' }} />
                {editPercentage.toFixed(0)}%
              </span>
            </Tooltip>
          )}
        </div>
      ),
    },
    ...timesheetsRecords,
    ...newCodes,
  ];

  useEffect(() => {
    setColumns(generateDates());
  }, [sheet, format, selectedDate, result, timesheetCopy, newCodes]);

  useEffect(() => {
    setSentValues();
  }, [sheet, format]);

  const onSelectDate = (date: string) => {
    if (date == selectedDate) {
      setSelectedDate(null);
      return;
    }
    setSelectedDate(date);
  };

  const onFinalChange = (codeId: string, key: string, value: string, inputKey: string) => {
    const copy = JSON.parse(JSON.stringify(result));
    const isNewCode = newCodes.find((code) => code.codeId == codeId);

    const code = copy[codeId];
    if (!isNewCode) {
      if (!code) return;

      const day = code[key];
      if (!day) {
        copy[codeId][key] = { value: 0 };
      }
    }

    const formatted = formatInput(value);
    if (formatted !== -99) {
      if (!copy[codeId]) {
        copy[codeId] = {};
      }
      copy[codeId][key] = {};
      copy[codeId][key].value = formatInput(value);
      if (formatted == -1) {
        copy[codeId][key].value = 0;
        copy[codeId][key].negative = true;
      }
      setInvalidInputKeys(invalidInputKeys.filter((key) => key !== inputKey));
    } else {
      setInvalidInputKeys([...invalidInputKeys, inputKey]);
    }
    setResult(copy);
  };

  const setSentValues = () => {
    let monthDates = getDatesBetweenDates(moment.unix(sheet.PARAMS.range.start), moment.unix(sheet.PARAMS.range.end));
    let sentObject: any = {};
    for (let i = 0; i < timesheetsKeys.length; i++) {
      const element = timesheetsKeys[i];
      sentObject[element] = {};

      let resultObject = monthDates.reduce((acc: any, current) => {
        acc[current] = undefined;
        return acc;
      }, {});

      sentObject[element] = resultObject;
    }

    const mergedData = timesheetsRecords.reduce((result: any, obj) => {
      // Merge the 'data' property of each object into the result object
      if (!obj || !obj.data || !obj.codeId) return {};
      result[obj.codeId] = {};
      for (const prop in obj.data) {
        if (obj.data.hasOwnProperty(prop)) {
          result[obj.codeId][prop] = obj.data[prop];
        }
      }
      return result;
    }, {});

    for (const codeId in mergedData) {
      if (mergedData.hasOwnProperty(codeId)) {
        for (const key in mergedData[codeId]) {
          if (mergedData[codeId].hasOwnProperty(key)) {
            const formattedHour = convertDecimalHourToTime(mergedData[codeId][key].value, format);
            sentObject[codeId][key] = formattedHour;
          }
        }
      }
    }
    form.setFieldsValue({
      sent: sentObject,
    });
  };

  const onInvalidate = () => {
    setValidateLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/reports/hr-sheets/new/validateMultiple`, {
        start: moment.unix(sheet.PARAMS.range.end).startOf('month').unix(),
        departmentId: activeDepartmentId,
        recordIds: [userId],
        validated: false,
      })
      .then(() => {
        getHrSheet();
        onReset();
        message.success(t('REPORTS.TIMESHEETS.INVALIDATED_SHEET'));
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setValidateLoading(false);
      });
  };

  const generateDates = () => {
    const monthStart = moment.unix(sheet.PARAMS.range.start);
    const monthEnd = moment.unix(sheet.PARAMS.range.end);
    const dates = [];

    dates.push({
      title: () => {
        if (sheet.PARAMS.validated && typeof sheet.PARAMS.validated == 'string') {
          return (
            <div className="validated-sheet">
              <span className="title">{t('REPORTS.TIMESHEETS.VALIDATED_SHEET')}</span>
              <span className="description">
                {moment(sheet.PARAMS.validated).format('L')} {moment(sheet.PARAMS.validated).format('HH:mm')}
              </span>
              <span className="invalidate-button" onClick={onInvalidate}>
                <i className="icon-delete" />
              </span>
            </div>
          );
        }
        return (
          <Button type="primary" block className="validate-sheet" onClick={validateSheet}>
            {t('REPORTS.TIMESHEETS.VALIDATE_SHEET')}
          </Button>
        );
      },
      dataIndex: 'title',
      key: 'presta',
      fixed: 'left',
      className: 'left-cell',
      render: (text: string, data: any) => {
        if (!data) return;
        if (data.key.includes('empty')) return <span className="empty"></span>;
        if (data.key.includes('subheader')) return <span className="subheader">{data.title}</span>;
        const title = data['title'];
        if (!title) return;
        return title;
      },
    });

    let weekNumber = 0;
    while (monthStart.isSameOrBefore(monthEnd)) {
      const formattedDate = monthStart.format('ddd');
      const dayNumber = parseInt(monthStart.format('D'));
      const key = monthStart.format('YYYY-MM-DD');
      const selected = key == selectedDate;

      const plannedMissing = sheet.ORIGIN[key] && !sheet.ORIGIN[key].find((shift) => shift.shiftType == 'PLANNED');
      const clockingMissing =
        isFeatureEnabled(features, FEATURES.CLOCKING) &&
        sheet.ORIGIN[key] &&
        !sheet.ORIGIN[key].find((shift) => shift.shiftType == 'CLOCKIN');

      dates.push({
        title: (
          <div className="column-name" onClick={() => onSelectDate(key)}>
            <span className="day">{formattedDate}</span>
            <span className="date">
              {dayNumber <= 9 ? '0' : ''}
              {dayNumber}/{moment(key).month() + 1 <= 9 ? '0' : ''}
              {moment(key).month() + 1}
            </span>
            {(plannedMissing || clockingMissing) && (
              <Tooltip
                overlay={<span>{t(`REPORTS.TIMESHEETS.MISSING.${plannedMissing ? 'PLANNED' : 'CLOCKING'}`)}</span>}
              >
                <span className={`alert-dot ${plannedMissing ? 'planned' : clockingMissing ? 'clocking' : ''}`}></span>
              </Tooltip>
            )}
          </div>
        ),
        dataIndex: key,
        key,
        align: 'center',
        className: `${selected ? 'selected' : ''}`,
        render: (text: string, data: any) => {
          if (!data) return;
          if (data.key.includes('empty')) return <span className="empty"></span>;
          if (data.key.includes('subheader')) return <span className="subheader"></span>;

          if (data.key.includes('rules') || data.key.includes('dayoff')) {
            let day = data.data[key];
            if (!day || typeof day == 'string') return;
            const formattedHour = convertDecimalHourToTime(day.value, format);

            if (data.key.includes('rules')) {
              return <span className={`rule-cell ${day.type}`}>{formattedHour}</span>;
            }

            return `${formattedHour}`;
          }

          if (data.key.includes('sent')) {
            if (data.key == 'sent-new--1') {
              return;
            }

            const codeId = data.codeId;
            const modifiedCode = sheet.MODIFIED[codeId];
            const found = modifiedCode ? modifiedCode[key] : null;
            const modified = found ? true : false;
            const inputKey = `sent-input-${data.codeId}-${key}`;
            const invalid = invalidInputKeys.includes(inputKey);

            return (
              <Form.Item name={['sent', data.codeId, key]} style={{ margin: 0 }}>
                <Input
                  id={inputKey}
                  className={`sent-input ${modified ? 'modified' : ''} ${invalid ? 'invalid' : ''}`}
                  placeholder={format == 'h,m' ? '0,0' : '00:00'}
                  onChange={(e) => onFinalChange(data.codeId, key, e.target.value, inputKey)}
                  maxLength={5}
                />
              </Form.Item>
            );
          }

          if (!data.data[0]) return;
          let day = data.data[0][key];
          if (!day || typeof day == 'string') return;
          const formattedHour = convertDecimalHourToTime(day.value, format);

          return `${formattedHour}`;
        },
      });
      monthStart.add(1, 'day');

      if (monthStart.day() === 1 || monthStart.startOf('day').isAfter(monthEnd.startOf('day'))) {
        weekNumber++;
        let end = moment(key);
        let start = weekNumber == 1 ? moment.unix(sheet.PARAMS.range.start) : end.clone().startOf('week');
        let weekDates = getDatesBetweenDates(start, end);
        dates.push({
          title: `${t('GLOBAL.WEEK_SHORT')}${weekNumber}`,
          dataIndex: `week-${weekNumber}`,
          key: `week-${weekNumber}-${userId}`,
          align: 'center',
          className: 'week-cell',
          // Render function for week number cells (customize as needed)
          render: (text: string, data: any) => {
            if (!data) return;
            if (data.key.includes('empty')) return <span className="empty"></span>;
            if (data.key == 'subheader-1') return <span className="subheader"></span>;

            if (data.key == 'subheader-2') {
              const data = sheet.RULES[sheet.PARAMS.type == 'SHIFT' ? 'PLANNED' : 'CLOCKIN'] as any;
              const sums: any = {};

              weekDates.forEach((date) => {
                let sum = 0;
                for (const key in data) {
                  if (data[key][date]) {
                    sum += data[key][date].value;
                  }
                }
                sums[date] = sum;
              });
              const value: any = Object.values(sums).reduce(
                (accumulator: any, currentValue: any) => accumulator + currentValue,
                0,
              );

              const formattedHour = convertDecimalHourToTime(value, format);
              return `${formattedHour}`;
            }
            if (data.key == 'subheader-3') {
              const data = sheet.DAYOFF as any;
              const sums: any = {};

              weekDates.forEach((date) => {
                let sum = 0;
                for (const key in data) {
                  if (data[key][date]) {
                    sum += data[key][date].value;
                  }
                }
                sums[date] = sum;
              });
              const value: any = Object.values(sums).reduce(
                (accumulator: any, currentValue: any) => accumulator + currentValue,
                0,
              );

              const formattedHour = convertDecimalHourToTime(value, format);
              return `${formattedHour}`;
            }

            if (data.key == 'sent-new--1') {
              return;
            }

            if (data.key.includes('rules') || data.key.includes('dayoff')) {
              const values = weekDates
                .map((date) => data.data[date])
                .filter((element) => element !== undefined)
                .map((element) => (typeof element != 'string' ? element.value : 0));
              const sum = values.reduce((sum, value) => sum + value, 0);
              const formattedHour = convertDecimalHourToTime(sum, format);

              return `${formattedHour}`;
            }
            if (data.key == 'subheader-4') {
              const data = result;
              const sums: any = {};

              weekDates.forEach((date) => {
                let sum = 0;
                for (const key in data) {
                  if (data[key][date]) {
                    sum += data[key][date].value;
                  }
                }
                sums[date] = sum;
              });
              const value: any = Object.values(sums).reduce(
                (accumulator: any, currentValue: any) => accumulator + currentValue,
                0,
              );

              const formattedHour = convertDecimalHourToTime(value, format);
              return `${formattedHour}`;
            }

            if (data.key == 'sent-new--1') {
              return;
            }

            if (data.key.includes('rules') || data.key.includes('dayoff')) {
              const values = weekDates
                .map((date) => data.data[date])
                .filter((element) => element !== undefined)
                .map((element) => (typeof element != 'string' ? element.value : 0));
              const sum = values.reduce((sum, value) => sum + value, 0);
              const formattedHour = convertDecimalHourToTime(sum, format);

              return `${formattedHour}`;
            }

            if (data.key.includes('sent')) {
              const codeId = data.codeId;
              if (!codeId) return;

              const records = result[codeId];

              if (records) {
                const filtered = Object.fromEntries(
                  Object.entries(records).filter(([key, value]) => weekDates.includes(key)),
                );
                const values = Object.values(filtered).map((record: any) => record.value);
                const sum = values.reduce((sum, value) => sum + value, 0);
                const formattedHour = convertDecimalHourToTime(sum, format);

                return `${formattedHour}`;
              } else {
                return format == 'hh:mm' ? '00:00' : '0,0';
              }
            }

            const values = weekDates
              .map((date) => (data.data[0] ? data.data[0][date] : data.data[key]))
              .filter((element) => element !== undefined)
              .map((element) => (typeof element != 'string' ? element.value : 0));
            const sum = values.reduce((sum, value) => sum + value, 0);
            const formattedHour = convertDecimalHourToTime(sum, format);
            return `${formattedHour}`;
          },
        });
      }
    }

    dates.push({
      title: t('GLOBAL.TOTAL'),
      dataIndex: 'total',
      key: `total-${userId}`,
      align: 'center',
      fixed: 'right',
      className: 'right-cell',
      render: (text: string, data: any) => {
        if (!data) return;
        if (data.key.includes('empty')) return <span className="empty"></span>;
        if (data.key.includes('subheader')) {
          if (data.key == 'subheader-2') {
            const values = Object.values(sheet.RULES[sheet.PARAMS.type == 'SHIFT' ? 'PLANNED' : 'CLOCKIN']);
            const records = values.reduce((accumulator, currentValue: any) => {
              return accumulator.concat(Object.values(currentValue));
            }, []);
            const total = records.reduce((accumulator, currentValue: any) => {
              return accumulator + currentValue.value;
            }, 0);
            return <span className="subheader">{convertDecimalHourToTime(total, format)}</span>;
          }
          if (data.key == 'subheader-3') {
            const values = Object.values(sheet.DAYOFF);
            const records = values.reduce((accumulator, currentValue: any) => {
              return accumulator.concat(Object.values(currentValue));
            }, []);
            const total = records.reduce((accumulator, currentValue: any) => {
              return accumulator + currentValue.value;
            }, 0);
            return <span className="subheader">{convertDecimalHourToTime(total, format)}</span>;
          }
          if (data.key == 'subheader-4') {
            const values = Object.values(result);
            const records = values.reduce((accumulator, currentValue: any) => {
              return accumulator.concat(Object.values(currentValue));
            }, []);
            const total = records.reduce((accumulator, currentValue: any) => {
              return accumulator + currentValue.value;
            }, 0);
            return <span className="subheader">{convertDecimalHourToTime(total, format)}</span>;
          }
          return <span className="subheader"></span>;
        }
        if (data.key == 'sent-new--1') {
          return;
        }

        let monthDates = getDatesBetweenDates(
          moment.unix(sheet.PARAMS.range.start),
          moment.unix(sheet.PARAMS.range.end),
        );

        if (data.key.includes('rules') || data.key.includes('dayoff')) {
          const values = monthDates
            .map((date) => data.data[date])
            .filter((element) => element !== undefined)
            .map((element) => (typeof element != 'string' ? element.value : 0));

          const sum = values.reduce((sum, value) => sum + value, 0);
          const formattedHour = convertDecimalHourToTime(sum, format);

          return `${formattedHour}`;
        }

        if (data.key.includes('sent')) {
          const codeId = data.codeId;
          if (!codeId) return;

          const records = result[codeId];

          if (records) {
            const values = Object.values(records).map((record) => record.value);
            const sum = values.reduce((sum, value) => sum + value, 0);
            const formattedHour = convertDecimalHourToTime(sum, format);

            return `${formattedHour}`;
          } else {
            return format == 'hh:mm' ? '00:00' : '0,0';
          }
        }

        if (data.data.length == 0) return;

        const values = monthDates
          .map((date) => data.data[0][date])
          .filter((element) => element !== undefined)
          .map((element) => (typeof element != 'string' ? element.value : 0));

        const sum = values.reduce((sum, value) => sum + value, 0);
        const formattedHour = convertDecimalHourToTime(sum, format);

        return `${formattedHour}`;
      },
    });

    return dates;
  };

  const onReset = () => {
    setFormHasChanged(false);
    setNewCodes([
      {
        key: `sent-new--1`,
        title: (
          <Button className="add-code" htmlType="button" type="link" onClick={() => setModalNewCodeVisible(true)}>
            <i className="icon-plus add-code-icon" /> {t('REPORTS.TIMESHEETS.ADD_CODE')}
          </Button>
        ),
        data: {},
      },
    ]);
    form.resetFields();
    setSentValues();
    setPreventBack(false);
    setResult(JSON.parse(JSON.stringify(timesheetCopy)));
    setInvalidInputKeys([]);
  };

  const validateSheet = () => {
    if (formHasChanged || newCodes.length > 1) {
      setPreventBack(true);
      setNewUrl(null);
      return;
    }
    setValidateLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/reports/hr-sheets/new/validate/${userId}`,
        {
          TIMESHEETS: result,
        },
        {
          params: {
            departmentId: activeDepartmentId,
            start: datePickerValue ? datePickerValue.startOf('month').unix() : start.unix(),
            fullmonth:
              localStorage.getItem('timesheets-fullmonth') && localStorage.getItem('timesheets-fullmonth') == 'true'
                ? true
                : false,
            type: sheet.PARAMS.type,
          },
        },
      )
      .then(({ data }) => {
        getHrSheet();
        onReset();
        message.success(t('REPORTS.TIMESHEETS.VALIDATED_SHEET'));
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setValidateLoading(false);
      });
  };

  const onFinish = () => {
    if (invalidInputKeys.length > 0) {
      setModalInvalidInputs(true);
      return;
    }
    setSaveLoading(true);
    const newRowsObject: IHrSheetTimesheets = {};
    const newRows = newCodes
      .filter((row) => row.key !== 'sent-new--1')
      .forEach((row) => {
        if (!row.codeId) return;
        newRowsObject[row.codeId] = {};
        if (!row.data) return;
        newRowsObject[row.codeId] = row.data;
      });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/reports/hr-sheets/new/${userId}`,
        {
          TIMESHEETS: findValueDifferences(timesheetCopy, result),
        },
        {
          params: {
            departmentId: activeDepartmentId,
            start: datePickerValue ? datePickerValue.startOf('month').unix() : start.unix(),
            fullmonth:
              localStorage.getItem('timesheets-fullmonth') && localStorage.getItem('timesheets-fullmonth') == 'true'
                ? true
                : false,
            type: sheet.PARAMS.type,
          },
        },
      )
      .then(({ data }) => {
        getHrSheet();
        onReset();
        const user = users.find((user) => user.recordId == userId);
        if (user) {
          message.success(t('REPORTS.TIMESHEETS.SAVED_SHEET', { name: user.displayName }));
        }
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const user = users.find((user) => user.recordId == userId);
  const monthDatesWithoutWeekends = getDatesBetweenDates(
    moment(moment.unix(sheet.PARAMS.range.end).startOf('month')),
    moment(moment.unix(sheet.PARAMS.range.end).endOf('month')),
  ).filter((date) => moment(date).day() !== 0 && moment(date).day() !== 6);

  return (
    <div className={className}>
      {selectedDate && sheet.ORIGIN[selectedDate] && (
        <div className="shifts">
          {sheet.ORIGIN[selectedDate]
            .sort((shift) => (shift.shiftType == 'PLANNED' ? -1 : 1))
            .map((shift) => (
              <div className={`shift ${shift.shiftType}`}>
                <span className="type">
                  {shift.shiftType == 'PLANNED' ? t('GLOBAL.PLANNED') : t('GLOBAL.CLOCKING')}{' '}
                  {moment(shift.start).format('L')}
                </span>
                <span className="hours">
                  {moment(shift.start).format('HH:mm')} {moment(shift.end).format('HH:mm')}
                </span>
                {shift.pause_unpaid && shift.pause_unpaid !== '0' && (
                  <span>
                    <i className="icon-pause" />
                    {minutesToHoursAndOrMinutes(shift.pause_unpaid ? Number(shift.pause_unpaid) : 0)}
                  </span>
                )}
              </div>
            ))}
        </div>
      )}
      <Form form={form} onValuesChange={() => setFormHasChanged(true)} onFinish={onFinish}>
        <Table
          loading={loading || validateLoading || saveLoading}
          columns={columns}
          dataSource={dataArray}
          pagination={false}
        />
        <FormActions saving={loading || saveLoading} active={formHasChanged} onReset={onReset} />
      </Form>
      <div className="actions">
        <div className="left">
          {sheet.PARAMS.status && (
            <span>{userCategories.find((status) => status.id == sheet.PARAMS.status)?.name}</span>
          )}
          {user?.hrMaxHoursWeek && (
            <span>
              {user?.hrMaxHoursWeek}
              {t('GLOBAL.HOURS_SHORT').toLowerCase()}/{t('GLOBAL.WEEK_SHORT').toLowerCase()}
              {user?.hrDaysWeek &&
                user?.hrHoursWeek &&
                (user?.hrDaysWeek == 5 || user?.hrDaysWeek == 6) &&
                ` (${((user.hrHoursWeek / user.hrDaysWeek) * monthDatesWithoutWeekends.length).toFixed(2)}${t(
                  'GLOBAL.HOURS_SHORT',
                )}/m)`}
            </span>
          )}
          {hrSheets && hrSheets.find((sheet) => sheet.recordId == user?.recordId) && (
            <span>SSA: {hrSheets.find((sheet) => sheet.recordId == user?.recordId)?.ssaId}</span>
          )}
          {total && total.length >= 0 && (
            <span>
              {t('GLOBAL.DAYS_WORKED')}: {total.length}
            </span>
          )}
        </div>
        <div className="right">
          <HrSheetRuleLegendElement element="BLUE" />
          <HrSheetRuleLegendElement element="PINK" />
          <HrSheetRuleLegendElement element="LIGHTYELLOW" />
          <HrSheetRuleLegendElement element="RED" />
        </div>
      </div>
      <ModalAddNewCode
        visible={modalNewCodeVisible}
        setVisible={setModalNewCodeVisible}
        codes={sheet.CODES}
        timesheetsKeys={timesheetsKeys}
        newCodes={newCodes}
        setNewCodes={setNewCodes}
        form={form}
        sheet={sheet}
      />
      <ModalChangesNotSaved
        visible={preventBack}
        content={
          <p
            dangerouslySetInnerHTML={{
              __html: t(`REPORTS.TIMESHEETS.CHANGES_NOT_SAVED_MODAL.DESCRIPTION${!newUrl ? '_NO_LINK' : ''}`, {
                username: user?.displayName,
                month: moment.unix(sheet.PARAMS.range.start).format('MMMM YYYY'),
              }),
            }}
          ></p>
        }
        onOk={
          newUrl
            ? () => {
                setPreventBack(false);
                setNewUrl(null);
                history.push(newUrl);
              }
            : undefined
        }
        onCancel={() => setPreventBack(false)}
      />
      <ModalInvalidInputs
        visible={modalInvalidInputs}
        setModalInvalidInputs={setModalInvalidInputs}
        invalidInputKeys={invalidInputKeys}
      />
    </div>
  );
};

export default styled(HrSheetTable)`
  display: flex;
  flex-direction: column;
  gap: 25px;

  .ant-table-tbody {
    .ant-table-cell {
      padding: 5px;
    }
  }

  .column-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50px;
    cursor: pointer;

    .day {
      font-size: 12px;
      color: ${colors.grey};
    }

    .alert-dot {
      position: absolute;
      bottom: 5px;
      height: 7px;
      width: 7px;
      border-radius: 50px;

      &.planned {
        background-color: ${colors.red};
      }

      &.clocking {
        background-color: #00f2ff;
      }
    }
  }

  .validate-sheet {
    text-transform: uppercase;
  }

  .validated-sheet {
    display: flex;
    flex-direction: column;
    font-weight: bold;

    .title {
      text-transform: uppercase;
      color: ${colors.green};
    }

    .description {
      color: #c5c5c5;
    }

    .invalidate-button {
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;

      i {
        color: ${colors.red};
      }
    }
  }

  .week-cell {
    background-color: ${colors.blueLight};
    border-left: 1px solid #cacaca;
    border-right: 1px solid #cacaca;
  }

  .ant-table-thead {
    .left-cell {
      background-color: #fff;
    }
    .week-cell {
      font-weight: bold;
      border-top: 1px solid #cacaca;
    }
  }

  .left-cell {
    border-right: 1px solid ${colors.grey};
    min-width: 100px;
    max-width: 200px;
  }

  .right-cell {
    border-left: 3px solid ${colors.grey};
    min-width: 100px;
    font-weight: bold;
  }

  tr[data-row-key='clocking-inactive'] {
    color: #b9b9b9;
  }

  tr[data-row-key='planned-inactive'] {
    color: #b9b9b9;
  }

  tr[data-row-key*='subheader'] {
    background-color: #f5f5f5;
    .left-cell,
    .right-cell {
      background-color: #f5f5f5;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  tr[data-row-key*='empty'] {
    .left-cell,
    .right-cell {
      border: none;
    }
  }

  .week-cell:has(.empty) {
    background-color: #fff;
    height: 33px;
    border: none;
  }

  .sent-input {
    text-align: center;

    &.modified {
      background-color: ${colors.redDark};
      color: #fff;
    }

    &.invalid {
      background-color: ${colors.redLight};
      position: relative;
    }
  }

  .tooltip-trigger {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: default;

    .code-id {
      background-color: #ebebeb;
      align-self: flex-start;
      padding: 1px 5px;
      border-radius: 5px;
    }

    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.sent {
      justify-content: space-between;

      .excess-edits {
        background-color: ${colors.redDark};
        color: #fff;
        padding: 2px 7px;
        border-radius: 5px;
      }
    }
  }

  tr[data-row-key*='subheader-1'] {
    background-color: #ecf0ff;
    .left-cell,
    .right-cell {
      background-color: #ecf0ff;
      font-weight: bold;
      text-transform: uppercase;
    }
    .week-cell {
      background-color: #ecf0ff;
    }
  }

  tr[data-row-key*='subheader-2'] {
    background-color: #fcedff;
    .left-cell,
    .right-cell {
      background-color: #fcedff;
      font-weight: bold;
      text-transform: uppercase;
    }
    .week-cell {
      background-color: #fcedff;
    }
  }

  tr[data-row-key*='subheader-3'] {
    background-color: #fef4ec;
    .left-cell,
    .right-cell {
      background-color: #fef4ec;
      font-weight: bold;
      text-transform: uppercase;
    }
    .week-cell {
      background-color: #fef4ec;
    }
  }

  tr[data-row-key*='sent-'],
  tr[data-row-key*='subheader-4'] {
    background-color: #e4f6de;
    .left-cell,
    .right-cell {
      background-color: #e4f6de;
    }
  }

  tr[data-row-key*='sent-'] {
    .right-cell {
      font-weight: lighter;
    }
  }

  tr[data-row-key='sent-new--1'] {
    &,
    .left-cell,
    .week-cell,
    .right-cell {
      background-color: #fff;
    }
  }

  tr[data-row-key*='subheader-4'] {
    .week-cell {
      background-color: #e4f6de;
    }
  }

  .selected {
    background-color: ${colors.orangeLight};
  }

  .shifts {
    display: flex;
    align-items: center;
    gap: 10px;

    .shift {
      display: flex;
      flex-direction: column;
      background-color: #f4f4f4;
      align-self: flex-start;
      padding: 10px 15px;
      border-radius: 10px;

      &.CLOCKIN {
        background-color: #cfedff;
      }

      .type {
        font-size: 11px;
        color: #898989;
      }

      .hours {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .add-code {
    font-weight: lighter;
    color: ${colors.green};

    .add-code-icon {
      background-color: ${colors.green};
      color: #fff;
      border-radius: 50px;
      margin-right: 5px;
    }
  }

  .rule-cell {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .added {
    background-color: #daedfe;
  }

  .deducted {
    background-color: #ffecef;
  }

  .replaced {
    background-color: #ffffe9;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: bold;
    }

    .right {
      display: flex;
      flex-direction: row;
      gap: 5px;
      margin-left: 25px;
    }
  }
`;

const formatInput = (input: string) => {
  let formatted_input = input;
  let number_input = Number(formatted_input);
  const is_number = !isNaN(number_input);
  const pattern = /[^0-9.,:]/;

  if (formatted_input == '') {
    return -1;
  }

  if (pattern.test(formatted_input)) {
    return -99;
  }

  if (is_number) {
    if (formatted_input.includes('.')) {
      const parts = formatted_input.split('.');
      const hours = parts[0];
      const decimals = parts[1];

      if (decimals == '') {
        number_input = Number(`${Number(hours)}`);
      } else {
        number_input = Number(`${Number(hours)}.${decimals}`);
      }
    }
  } else {
    if (formatted_input.includes(',')) {
      const parts = formatted_input.split(',');
      const hours = parts[0];
      const decimals = parts[1];

      if (decimals == '') {
        number_input = Number(`${Number(hours)}`);
      } else {
        number_input = Number(`${Number(hours)}.${decimals}`);
      }
    }
    if (formatted_input.includes(':')) {
      const parts = formatted_input.split(':');
      const hours = parts[0];
      const decimals = parts[1];

      if (decimals == '') {
        number_input = Number(`${Number(hours)}`);
      } else {
        if (decimals.length < 2) {
          return -1;
        }
        if (Number(decimals) > 59) {
          number_input = Number(`${Number(hours)}.${(59 / 60).toFixed(2).split('.')[1]}`);
        } else {
          number_input = Number(`${Number(hours)}.${(Number(decimals) / 60).toFixed(2).split('.')[1]}`);
        }
      }
    }
  }

  if (number_input > 23.99) {
    number_input = 23.99;
  }

  const result = Number(number_input.toFixed(2));

  return result;
};

function findValueDifferences(objA: IHrSheetTimesheets, objB: IHrSheetTimesheets) {
  const result: IHrSheetTimesheets = {};

  for (const keyB in objB) {
    result[keyB] = {};

    for (const dateKey in objB[keyB]) {
      if (!objA[keyB] || !objA[keyB][dateKey]) {
        result[keyB][dateKey] = { value: objB[keyB][dateKey].negative ? -1 : objB[keyB][dateKey].value };
        continue;
      }

      if (objA[keyB][dateKey].value !== objB[keyB][dateKey].value) {
        result[keyB][dateKey] = { value: objB[keyB][dateKey].negative ? -1 : objB[keyB][dateKey].value };
      }
    }
  }

  return result;
}
