import { IAttribut } from '@/types/attribut.model';
import { IResource } from '@/types/resource.model';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Layout, Modal, Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import { default as Axios, default as axios } from 'axios';
import { icon as LIcon, Marker as LMarker } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker } from 'react-leaflet';
import VectorTileLayer from 'react-leaflet-vector-tile-layer';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
require('leaflet/dist/leaflet.css');

const DefaultIcon = LIcon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

LMarker.prototype.options.icon = DefaultIcon;

const { Sider } = Layout;

interface Props {
  className?: string;
  resource?: IResource;
  departmentId?: string;
}

const SiderLeft: React.FC<Props> = ({ className, resource, departmentId }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { id, attributeId } = useParams() as any;
  const [fileList, setFileList] = useState<any[]>([]);
  const [attribute, setAttribute] = useState<IAttribut | null>(null);
  const [position, setPosition] = useState<{ lat: number; lng: number } | null>(null);
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    setAttribute(null);
    setPosition(null);
    setFileList([]);
    if (resource?.attributes?.length) {
      const attribute = resource.attributes[0];
      setAttribute(attribute);
      const image = attribute?.image;

      const { lat, lng } = attribute;

      if (image) {
        setFileList([
          {
            uid: '-1',
            status: 'done',
            url: image,
          },
        ]);
      }

      if (lat && lng) {
        setPosition({ lat, lng });
      }
    }
  }, [resource]);

  const pictureCustomRequest = (options: any) => {
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append('file', file);
    Axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/v3/resources/${id}/${attributeId}/picture`,
      params: {
        departmentId,
      },
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        const { url, status } = response.data;
        setFileList([
          {
            ...file,
            url,
            status,
          },
        ]);
        onSuccess(response, file);
      })
      .catch((error) => {
        message.error(t('FORMS.PICTURE_UPLOAD_ERROR'));
        onError(error);
        console.log(error);
      });
  };

  const onPictureRemove = async (file: any) => {
    return new Promise<boolean>((resolve, reject) => {
      Axios.delete(`${process.env.REACT_APP_API_URL}/v3/resources/${id}/${attributeId}/picture`, {
        params: {
          departmentId,
        },
      })
        .then(() => {
          resolve(true);
          setFileList([]);
        })
        .catch((error) => {
          message.error(t('FORMS.PICTURE_DELETION_ERROR'));
          console.error(error);
          reject(false);
        });
    });
  };

  const onRemove = () => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('ATTRIBUTES.MODAL_DELETE.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        Axios.delete(`${process.env.REACT_APP_API_URL}/v3/resources/${id}/${attributeId}`, {
          data: {
            departmentId,
          },
        })
          .then((response) => {
            message.success(t('ATTRIBUTES.MODAL_DELETE.MESSAGE_SUCCESS'));
            history.push(`/app/settings/attributes/manage/${id}`);
          })
          .catch((error) => {
            message.error(t('ATTRIBUTES.MODAL_DELETE.MESSAGE_ERROR'));
          });
      },
      onCancel: () => {},
    });
  };

  const validateResource = () => {
    setLoadingValidation(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/resources/validation/${resource?.id}/${resource?.attributes?.[0]?.id}`,
        {},
        {
          params: {
            departmentId,
            approvePending: true,
          },
        },
      )
      .then(() => {
        setLoadingValidation(false);
        history.push(`/app/settings/attributes/manage/${resource?.id}`);
      })
      .catch((error) => {
        console.error(error);
        message.error('GLOBAL.AN_ERROR_OCCURRED');
        setLoadingValidation(false);
      });
  };

  if (!attribute) {
    return (
      <div className={className}>
        <div className="go-back">
          <Button onClick={() => history.goBack()}>
            <i className="icon-angle-left"></i>
            {t('GLOBAL.BACK')}
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={className} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ position: resource?.resourceType === 'PLACE' && position ? 'absolute' : 'initial', left: 325 }}>
          <ImgCrop>
            <Upload
              listType="picture-card"
              customRequest={pictureCustomRequest}
              onRemove={onPictureRemove}
              fileList={fileList}
              accept="image/*"
            >
              {fileList.length < 1 && (
                <div style={{ margin: 10 }}>
                  {t('GLOBAL.UPLOAD_PICTURE')}
                  <UploadOutlined style={{ marginLeft: 10 }} />
                </div>
              )}
            </Upload>
          </ImgCrop>
        </div>
        {resource?.resourceType === 'PLACE' && position && (
          <div style={{ height: 310, width: '100%' }}>
            <MapContainer
              style={{
                position: 'absolute',
                left: 0,
                height: 310,
                width: 310,
                padding: 10,
                borderRadius: 3,
              }}
              maxZoom={20}
              zoom={16}
              center={[position.lat, position.lng]}
              scrollWheelZoom={false}
            >
              <VectorTileLayer
                attribution="© MapTiler © OpenStreetMap contributors"
                styleUrl="https://api.maptiler.com/maps/70ddf71e-c9a7-4112-b00f-9124ba482a89/style.json?key=a9UYy1T8E5qkxvDgC0fH"
              />
              <Marker position={[position.lat, position.lng]}></Marker>
            </MapContainer>
          </div>
        )}
        <div style={{ display: 'flex', gap: 10, alignItems: 'flex-start' }}>
          <Button className="delete-btn" type="primary" danger onClick={onRemove}>
            <i className="icon-trash-empty"></i>
            {t('RESOURCES.REMOVE_ATTRIBUTE')}
          </Button>
          {!!resource?.pending && (
            <Button className="validate-btn" loading={loadingValidation} onClick={validateResource} type="primary">
              {t('GLOBAL.VALIDATE')}
            </Button>
          )}
        </div>
      </div>
    );
  }
};

const SiderLeftStyled = styled(SiderLeft)`
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .go-back {
      position: absolute;
      top: 20px;
      left: -5px;

      button {
        padding-left: 15px;
        padding-right: 20px;
      }
    }
  }

  .description {
    display: flex;

    .delete-btn {
      align-self: flex-start;
    }
    .validate-btn {
      align-self: flex-start;
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 200px !important;
  }
`;

export default SiderLeftStyled;
