import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Form, Button } from 'antd';
import axios from 'axios';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  visible: boolean;
  departmentId?: string;
  data: any;
  dataSourceExplode?: any[];
  onClose: () => void;
  onSave: () => void;
  setDataSourceExplode?: React.Dispatch<React.SetStateAction<any[]>>;
}

const ModalSaveClocking: React.FC<Props> = ({
  departmentId,
  visible,
  data,
  dataSourceExplode,
  onSave,
  onClose,
  setDataSourceExplode,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    const { modifyComment } = values;
    const { id, ...body } = data;
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/clockings/${id}`,
        {
          ...body,
          modifyComment,
        },
        {
          params: {
            departmentId,
            update: true,
          },
        },
      )
      .then(({ data }) => {
        if (dataSourceExplode && setDataSourceExplode) {
          setDataSourceExplode(dataSourceExplode);
        }
        setIsSaving(false);
        onSave();
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    form.submit();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={t('CLOCKINGS.MODAL_SAVE_CLOCKING.TITLE')}
      getContainer={false}
      forceRender
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" htmlType="submit" loading={isSaving} onClick={onSubmit}>
          {t('GLOBAL.SAVE')}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item label={t('GLOBAL.COMMENT')} name={'modifyComment'} rules={[{ required: true }]}>
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalSaveClocking)``;
