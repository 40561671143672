import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IShift } from '@/types/shift.model';
import { isFeatureEnabled } from '@/utils';
import { Tooltip } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InitialStateType } from '../redux/store';

interface Props {
  className?: string;
  shift: IShift;
  department: IDepartment;
  position?: 'absolute' | 'relative';
}

const TopIcons: React.FC<Props> = ({ className, shift, position = 'absolute', department }) => {
  const { t } = useTranslation();
  const {
    state: { features },
  } = useContext(AppContext);

  const { ayShiftIds } = useSelector(({ ayShiftIds }: InitialStateType) => ({
    ayShiftIds,
  }));

  // console.log(ayShiftIds);

  const aySynced = shift.id && ayShiftIds ? ayShiftIds.includes(shift.id) : false;

  return (
    <div
      className={`${className} ${position}`}
      style={{
        position,
        marginTop: isFeatureEnabled(features, FEATURES.DIMONA) && shift.dimona && !shift.dimonaQualify ? -2 : 0,
      }}
    >
      {aySynced && <i className="icon-ay_icon" style={{ color: colors.ayBrand }} />}
      {shift.locked && <i className="icon-lock" />}
      {shift.homeworking && <i className="icon-home" />}
      {shift.day && <i className="icon-sun-inv" />}
      {shift.night && <i className="icon-moon-inv" />}
      {shift.hide && (
        <Tooltip overlay={<span>{t('SHIFTS.NOT_COUNTED')}</span>}>
          <i className="icon-eye-off" style={{ color: colors.grey }} />
        </Tooltip>
      )}
      {isFeatureEnabled(features, FEATURES.DIMONA) && shift.dimona && shift.dimonaQualify && !shift.dimonaValid && (
        <Tooltip overlay={<span>{t('SHIFTS.SHIFT_MISSING_VALID_DIMONA')}</span>}>
          <i className="icon-attention" />
        </Tooltip>
      )}
      {shift.userRecordId !== null &&
        shift.shyftType == 1 &&
        isFeatureEnabled(features, FEATURES.DIMONA) &&
        shift.dimona &&
        shift.dimonaEligible &&
        !shift.dimonaQualify &&
        !shift.dimonaValid && (
          <Tooltip overlay={<span>{t('SHIFTS.DIMONA_FOREIGN_USER')}</span>}>
            <i className="icon-cancel" style={{ color: '#ca9d6a' }} />
          </Tooltip>
        )}
      {position === 'relative' && (shift.count || 1) > 1 && <div className="count">{shift.count}</div>}
    </div>
  );
};

export default styled(TopIcons)`
  &.absolute {
    top: 7px;
    right: 28px;
    @media print {
      right: 9px;
    }
  }

  font-size: 11px;

  i.icon-attention {
    color: ${colors.red};
  }

  .count {
    background: #d0d3dd;
    width: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #66676a;
    font-size: 11px;
    font-weight: bold;
    padding: 0px 12px;
    border: 1px solid white;
  }
`;
