import colors from '@/styles/colors';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { IDepartment } from '@/types/department.model';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { Popover, Spin } from 'antd';
import { IForecast } from './Forecasts';
import { isNullOrUndefined } from '@/utils';
import axios from 'axios';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  department: IDepartment | null;
  activeSection?: string;
  forecasts?: IForecast[];
  loading?: boolean;
}

const ForecastTable: React.FC<Props> = ({ className, forecasts, department, activeSection, loading }) => {
  const { formatedDate } = useParams();
  const [hours, setHours] = useState<Moment[]>([]);

  useEffect(() => {
    if (department && formatedDate) {
      let current = moment(formatedDate)
        .add(department.scheduleParams?.startHour || 0, 'hour')
        .unix();
      const end = moment(formatedDate)
        .add(department.scheduleParams?.endHour || 0, 'hour')
        .unix();
      const hours: Moment[] = [];
      while (current < end) {
        hours.push(moment.unix(current));
        current += 3600;
      }
      setHours(hours);
    }
  }, [formatedDate, department, forecasts]);

  const onValueChange = ({
    taskId,
    hour,
    forecastValue,
  }: {
    taskId: string;
    hour: number;
    forecastValue: number | null;
  }) => {
    axios.patch(
      `${process.env.REACT_APP_API_URL}/v3/operations/task-forecast`,
      {
        taskId,
        hour,
        forecastValue,
      },
      {
        params: {
          departmentId: department?.id,
          sectionId: activeSection,
        },
      },
    );
  };

  return (
    <Spin spinning={loading}>
      <div className={className}>
        <table>
          <thead>
            <tr>
              <th>{moment(formatedDate).format('dddd')}</th>
              {hours?.map((hour) => (
                <th key={`${hour}`}>{hour.format('HH:mm')}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {forecasts?.map((forecast) => {
              const { taskId, label, data } = forecast;
              const result: any = {};
              for (let i = 0; i < data.length; i++) {
                result[data[i].hour] = { ...data[i] };
              }
              return (
                <tr key={`taskId_${taskId}`}>
                  <td>{label}</td>
                  {hours?.map((hour) => {
                    const ts = hour.unix();
                    const { currentValue = 0, forecastValue = null } = result[ts] || {};
                    const color = !isNullOrUndefined(forecastValue)
                      ? currentValue >= forecastValue
                        ? 'blue'
                        : 'red'
                      : '';
                    const onInputChange = (v: number | null) => {
                      return onValueChange({ taskId, hour: ts, forecastValue: v });
                    };
                    return (
                      <td key={`taskId_${taskId}_${ts}`}>
                        <div className={`inputs`}>
                          {!!currentValue && (
                            <Popover
                              trigger="hover"
                              placement="bottomLeft"
                              content={() => {
                                return <ForceastDetails forecasts={forecasts} keys={[taskId, ts]} />;
                              }}
                            >
                              <div>
                                <input type="number" disabled={true} min="0" value={currentValue} />
                              </div>
                            </Popover>
                          )}
                          {!currentValue && <input type="number" disabled={true} min="0" value={currentValue} />}
                          <MyInput
                            color={color}
                            currentValue={currentValue}
                            forecastValue={forecastValue}
                            disabled={!activeSection}
                            onInputChange={onInputChange}
                          />
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Spin>
  );
};

const ForceastDetails: React.FC<{ forecasts: IForecast[]; keys: [string, number] }> = ({ forecasts, keys }) => {
  const details = forecasts.find((x) => x.taskId === keys[0])?.data.find((x) => x.hour === keys[1])?.details;

  if (details?.length) {
    return (
      <ul style={{ margin: '0 0 0 10px', padding: 0 }}>
        {details?.map((detail, i) => {
          const { displayName, start, end } = detail;
          return (
            <li style={{ margin: 0, padding: 0 }} key={`forecastDetail_${keys[0]}_${keys[1]}_${i}`}>
              {displayName} : {moment.unix(start).format('HH:mm')} - {moment.unix(end).format('HH:mm')}
            </li>
          );
        })}
      </ul>
    );
  } else {
    return null;
  }
};

export default styled(ForecastTable)`
  overflow: auto;
  width: 100%;
  padding: 20px;

  table {
    margin-bottom: 10px;
    th,
    td {
      min-width: 100px;
      border: 1px solid ${colors.grey};
      &:nth-of-type(n + 2) {
        width: 50px;
        text-align: center;
      }
      &:first-of-type {
        position: sticky;
        width: 150px;
        padding: 5px;
      }
      .inputs {
        padding: 5px;
        display: flex;
        input {
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &[type='number'] {
            -moz-appearance: textfield;
          }
          text-align: center;
          border-radius: 3px;
          border-color: #d8d8d8;
          border-style: solid;
          border-width: 1px;
          text-align: center;
          &[disabled] {
            border: none;
            cursor: not-allowed;
            font-weight: bold;
          }
          &.blue {
            background-color: ${colors.blueLightPastel};
          }
          &.red {
            background-color: ${colors.red};
          }
        }
        > *:first-child {
          margin-right: 2px;
          display: block;
          width: 50%;
          color: ${colors.grey};
          input {
            width: 100%;
          }
        }
        > *:last-child {
          margin-left: 2px;
          display: block;
          width: 50%;
        }
      }
    }
    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
    tr:first-of-type {
      th:nth-of-type(n + 2) {
        text-align: center;
        text-transform: capitalize;
      }
      padding: 5px;
    }
    td {
      .ant-form-item.added input {
        color: red;
      }
    }
  }
`;

const MyInput: React.FC<{
  currentValue: number;
  forecastValue: number | null;
  disabled: boolean;
  color: string;
  onInputChange: (v: number | null) => void;
}> = ({ currentValue, forecastValue, disabled, color, onInputChange }) => {
  const [value, setValue] = useState<number | string>(isNullOrUndefined(forecastValue) ? '' : forecastValue!);
  const [className, setClassName] = useState<string>(color);

  useEffect(() => {
    setValue(isNullOrUndefined(forecastValue) ? '' : forecastValue!);
    setClassName(color);
  }, [forecastValue, color]);

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    let parsedValue = null;
    if (!isNullOrUndefined(value) && value !== '') {
      parsedValue = parseInt(value);
      if (currentValue >= parsedValue) {
        setClassName('blue');
      } else {
        setClassName('red');
      }
    } else {
      setClassName('blue');
    }
    setValue(isNullOrUndefined(value) ? '' : value!);
    onInputChange(parsedValue);
  };

  return <input type="number" disabled={disabled} min="0" value={value!} className={className} onChange={onChange} />;
};
