import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  setModalSwitchToProVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const LimitReached: React.FC<Props> = ({ className, setModalSwitchToProVisible }) => {
  const { t } = useTranslation();
  const onClick = () => {
    setModalSwitchToProVisible(true);
  };

  return (
    <div className={className}>
      <h1 className="title" dangerouslySetInnerHTML={{ __html: t('FREEMIUM.MODAL_ONBOARDING.LIMIT_REACHED.TITLE') }} />
      <p
        className="description"
        dangerouslySetInnerHTML={{ __html: t('FREEMIUM.MODAL_ONBOARDING.LIMIT_REACHED.DESCRIPTION') }}
      />
      <Button type="primary" size="large" onClick={onClick}>
        {t('GLOBAL.I_WANT_PRO_ACCOUNT')}
      </Button>
    </div>
  );
};

export default styled(LimitReached)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;

  .title,
  .description {
    text-align: center;
  }
`;
