import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { getDisplayName } from '@/utils';

type ExtraInfoType = {
  quote: {
    quote: string;
    author: string;
  };
};

export function withQuote<P>(WrappedComponent: any & ExtraInfoType): {
  (props: P): JSX.Element;
  displayName: string;
} {
  const [quote, setQuote] = useState<{ author: string; quote: string }>({ quote: '', author: '' });
  const {
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });

  useEffect(() => {
    let mounted = true;
    setQuote({
      author: '',
      quote: '',
    });
    const cancelTokenSource = Axios.CancelToken.source();
    Axios.get(`${process.env.REACT_APP_API_URL}/quotes`, {
      params: {
        lang: language,
      },
    })
      .then((response) => {
        if (mounted) {
          setQuote(response.data);
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.log(error);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [language]);

  const NewComponent = (props: P) => {
    return <WrappedComponent {...props} quote={quote} />;
  };

  NewComponent.displayName = `WithQuote(${getDisplayName(WrappedComponent)})`;
  return NewComponent;
}
