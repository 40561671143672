import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { Button, Popconfirm, Tooltip } from 'antd';
import axios from 'axios';
import React, { Dispatch, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType, updateShiftsAndContextWithShiftResponse } from '../../redux/actions';
import { InitialStateType } from '../../redux/store';

interface Props {
  small?: boolean;
  setIsGeneratingSchedule: React.Dispatch<React.SetStateAction<boolean>>;
}

const GenerateScheduleModels: React.FC<Props> = ({ small, setIsGeneratingSchedule }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, activeSection, skills, userCategories },
  } = useContext(AppContext);
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const { picker, scheduleModel, startDate, endDate } = useSelector(
    ({ picker, scheduleModel, startDate, endDate }: InitialStateType) => ({
      picker,
      scheduleModel,
      startDate,
      endDate,
    }),
  );

  const onClickGenerateScheduleModels = () => {
    setIsGeneratingSchedule(true);
    hoursDispatch({
      type: 'SET_IS_LOADING',
      payload: true,
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/operations/generate-schedule`,
        {},
        {
          params: {
            departmentId: activeDepartment?.id,
            sectionId: activeSection,
            picker,
            startDate: startDate.unix(),
            endDate: endDate.unix(),
          },
        },
      )
      .then((response) => {
        updateShiftsAndContextWithShiftResponse(hoursDispatch, response, {
          department: activeDepartment!,
          picker,
          startDate,
          endDate,
          activeSection,
          skills,
          userStatus: userCategories,
        });
        hoursDispatch({
          type: 'SET_IS_LOADING',
          payload: false,
        });
        setIsGeneratingSchedule(false);
      })
      .catch((error) => {
        console.error(error);
        hoursDispatch({
          type: 'SET_IS_LOADING',
          payload: false,
        });
        setIsGeneratingSchedule(false);
      });
  };

  const content = (
    <Popconfirm
      title={t('GLOBAL.ARE_YOU_SURE?')}
      okText={t('GLOBAL.YES')}
      cancelText={t('GLOBAL.NO')}
      onConfirm={onClickGenerateScheduleModels}
    >
      <Button
        style={{
          marginRight: small ? 0 : 10,
          backgroundColor: colors.blueLightPastel,
          borderColor: colors.blueLightPastel,
          borderRadius: 8,
          alignSelf: 'normal',
          alignItems: 'center',
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <path
            fill="white"
            fillRule="evenodd"
            d="M14.064 0a8.75 8.75 0 00-6.187 2.563l-.459.458c-.314.314-.616.641-.904.979H3.31a1.75 1.75 0 00-1.49.833L.11 7.607a.75.75 0 00.418 1.11l3.102.954c.037.051.079.1.124.145l2.429 2.428c.046.046.094.088.145.125l.954 3.102a.75.75 0 001.11.418l2.774-1.707a1.75 1.75 0 00.833-1.49V9.485c.338-.288.665-.59.979-.904l.458-.459A8.75 8.75 0 0016 1.936V1.75A1.75 1.75 0 0014.25 0h-.186zM10.5 10.625c-.088.06-.177.118-.266.175l-2.35 1.521.548 1.783 1.949-1.2a.25.25 0 00.119-.213v-2.066zM3.678 8.116L5.2 5.766c.058-.09.117-.178.176-.266H3.309a.25.25 0 00-.213.119l-1.2 1.95 1.782.547zm5.26-4.493A7.25 7.25 0 0114.063 1.5h.186a.25.25 0 01.25.25v.186a7.25 7.25 0 01-2.123 5.127l-.459.458a15.21 15.21 0 01-2.499 2.02l-2.317 1.5-2.143-2.143 1.5-2.317a15.25 15.25 0 012.02-2.5l.458-.458h.002zM12 5a1 1 0 11-2 0 1 1 0 012 0zm-8.44 9.56a1.5 1.5 0 10-2.12-2.12c-.734.73-1.047 2.332-1.15 3.003a.23.23 0 00.265.265c.671-.103 2.273-.416 3.005-1.148z"
          />
        </svg>
      </Button>
    </Popconfirm>
  );

  return (
    <>
      {small ? (
        <>{content}</>
      ) : (
        <>
          {!!scheduleModel && (
            <Tooltip title={`${t('GLOBAL.SCHEDULE_MODELS')} : ${t('GLOBAL.GENERATION')}`}>{content}</Tooltip>
          )}
        </>
      )}
    </>
  );
};

export default GenerateScheduleModels;
