import colors from '@/styles/colors';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  setModalApplyTurnoverTemplateVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: () => void;
}

const ApplyTurnoverTemplate: React.FC<Props> = ({ className, setModalApplyTurnoverTemplateVisible, onClick }) => {
  const { t } = useTranslation();

  const onWantToApplyTurnoverTemplate = () => {
    setModalApplyTurnoverTemplateVisible(true);
    onClick();
  };

  return (
    <Menu.Item className={className} onClick={onWantToApplyTurnoverTemplate}>
      <div className="container">
        <span>
          <i className="icon-money" /> {t('SCHEDULE.APPLY_TURNOVER_TEMPLATE')}
        </span>
      </div>
    </Menu.Item>
  );
};

export default styled(ApplyTurnoverTemplate)`
  margin: 0 5px;

  .container {
    display: flex;
    flex-direction: column;

    .description {
      font-size: 11px;
      color: ${colors.grey};
      margin-left: 21.5px;
      margin-top: -5px;
    }
  }

  &:hover {
    background-color: #efefef !important;
    border-radius: 8px;
  }
`;
