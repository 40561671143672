import colors from '@/styles/colors';
import { IUser } from '@/types/user.model';
import { Avatar, List } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  user?: IUser;
  onClick?: () => void;
  currentTab?: string;
}

const UserListItem: React.FC<Props> = ({ className, user, onClick, currentTab }) => {
  const history = useHistory();
  const { t } = useTranslation(undefined, { useSuspense: true });

  if (user) {
    return (
      <List.Item
        className={className}
        onClick={() => {
          history.push(`/app/team/collaborators/${user.recordId}/${currentTab}`);
        }}
      >
        <List.Item.Meta
          avatar={<Avatar src={user.picture}>{user?.initials}</Avatar>}
          title={
            <>
              <span>{user.displayName}</span>
              {user.ay_sync && <i className="icon-ay_icon" style={{ color: colors.ayBrand }} />}
            </>
          }
          description={user.category?.name}
        />
      </List.Item>
    );
  } else {
    return (
      <List.Item className={`${className} new`} onClick={onClick}>
        <List.Item.Meta avatar={<Avatar>+</Avatar>} title={t('USERS.CREATE_USER')} />
      </List.Item>
    );
  }
};

export default styled(UserListItem)`
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background: ${colors.blueExtraLight};
  }

  .ant-list-item-meta {
    display: flex;
    align-items: center;
    .ant-list-item-meta-title {
      margin: 0;
    }
    .ant-list-item-meta-description {
      font-size: 12px;
    }
  }

  &.new {
    .ant-avatar {
      background: ${colors.green};
    }
    .ant-list-item-meta {
      .ant-list-item-meta-title {
        color: ${colors.green};
        font-weight: bold;
      }
    }
  }
`;
