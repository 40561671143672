import AppContext from '@/pages/app/context';
import { colorPalette } from '@/styles/colors';
import { FormInstance } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ChromePicker, CirclePicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  color: string;
  form: FormInstance<any>;
  property: string;
  onChange?: (e: any) => void;
}

const CustomColorPicker: React.FC<Props> = ({ className, color, form, property, onChange }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [customPickerVisible, setCustomPickerVisible] = useState<boolean>(false);

  const onCustomClick = () => {
    setCustomPickerVisible((prev) => !prev);
  };

  useEffect(() => {
    if (!document) return;
    const custom = document.getElementById('custom');
    if (custom) return;
    const element = document.getElementById('color-picker');
    if (!element) return;
    const picker = element.children[0];
    if (!picker) return;

    const span = document.createElement('span');
    const divWrapper = document.createElement('div');
    const innerDiv = document.createElement('div');

    // Style the elements as per your requirements
    span.id = 'custom';
    span.style.width = '28px';
    span.style.height = '28px';
    span.style.marginRight = '14px';
    span.style.marginBottom = '14px';
    span.style.transform = 'scale(1)';
    span.style.transition = 'transform 100ms ease 0s';

    divWrapper.style.background = 'conic-gradient(red, orange, yellow, green, cyan, blue, violet, red';
    divWrapper.style.height = '100%';
    divWrapper.style.width = '100%';
    divWrapper.style.cursor = 'pointer';
    divWrapper.style.position = 'relative';
    divWrapper.style.outline = 'none';
    divWrapper.style.borderRadius = '50%';
    divWrapper.style.transition = 'box-shadow 100ms ease 0s';
    divWrapper.onclick = onCustomClick;

    // Append the inner div to the wrapper div
    divWrapper.appendChild(innerDiv);

    // Append the wrapper div to the span
    span.appendChild(divWrapper);

    // Append the span to the color-picker element
    picker.appendChild(span);
  }, [document]);

  return (
    <div className={className} id="color-picker">
      <CirclePicker
        colors={{ ...colorPalette }}
        color={color}
        onChangeComplete={(color) => {
          form.setFieldsValue({ [`${property}`]: color.hex });
        }}
        className="full-width-chrome-picker"
        onChange={onChange ? onChange : undefined}
      />
      {customPickerVisible && (
        <div style={{ marginTop: 15 }}>
          <ChromePicker
            className="full-width-chrome-picker"
            color={color}
            onChange={
              onChange
                ? onChange
                : (color) => {
                    form.setFieldsValue({ [`${property}`]: color.hex });
                  }
            }
          />
        </div>
      )}
    </div>
  );
};

export default styled(CustomColorPicker)``;
