import AppContext from '@/pages/app/context';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IHeaderBadges } from '@/types/header-badges.model';
import { IResource } from '@/types/resource.model';
import { isFeatureEnabled } from '@/utils';
import { Badge, Menu } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
interface Props {
  resources?: IResource[];
  department?: IDepartment;
  teamBadges: IHeaderBadges['team'];
  usersBadges: IHeaderBadges['users'];
}

const MenuTeam: React.FC<Props> = (props) => {
  const { resources, department, teamBadges, usersBadges, ...otherProps } = props;
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { features },
  } = useContext(AppContext);

  return (
    <Menu getPopupContainer={(trigger) => trigger} {...otherProps}>
      <Menu.ItemGroup title={t('MENU.TEAM.STAFF_LIST')}>
        <Menu.Item key="collaborators">
          <NavLink activeClassName="is-active" to="/app/team/collaborators" className="menu_team_collaborators">
            {t('GLOBAL.USERS')}
          </NavLink>
        </Menu.Item>
        {!!department?.userSubscription && (
          <Menu.Item key="pending-collaborators">
            <NavLink
              activeClassName="is-active"
              to="/app/team/pending-collaborators"
              className="menu_team_pending-collaborators"
            >
              {t('USERS.USER_VALIDATION.TITLE')} <Badge count={usersBadges?.pending} />
            </NavLink>
          </Menu.Item>
        )}
        {usersBadges?.registrations && usersBadges?.registrations > 0 ? (
          <Menu.Item key="pending-registrations">
            <NavLink
              activeClassName="is-active"
              to="/app/team/pending-registrations"
              className="menu_team_collaborators_pending-registrations"
            >
              {t('USERS.PENDING_REGISTRATIONS.TITLE')} <Badge count={usersBadges?.registrations} />
            </NavLink>
          </Menu.Item>
        ) : null}
      </Menu.ItemGroup>
      {department?.accountType !== 'FREE-TRIAL' && (
        <Menu.ItemGroup title={t('MENU.SCHEDULE.ALERTS_AND_MESSAGING.TITLE')}>
          <Menu.Item key="messages">
            <NavLink activeClassName="is-active" to="/app/hours/messages" className="menu_hours_messages">
              {t('GLOBAL.MESSAGES')}
            </NavLink>
          </Menu.Item>
          {!!isFeatureEnabled(features, FEATURES.FREESHIFTS) && (
            <Menu.Item key="shift-notifications">
              <NavLink
                activeClassName="is-active"
                to="/app/hours/shift-notifications"
                className="menu_hours_notifications"
              >
                {t('FREE_SHIFT_NOTIFICATIONS.TITLE')}
              </NavLink>
            </Menu.Item>
          )}
        </Menu.ItemGroup>
      )}
    </Menu>
  );
};

export default MenuTeam;
