import { Input, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  disabled: boolean;
  setTerm: React.Dispatch<React.SetStateAction<string | null>>;
}

const Header: React.FC<Props> = ({ className, disabled, setTerm }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <header className={className}>
      <div className="left">
        <Space>
          <Input
            disabled={disabled}
            allowClear
            placeholder={t('GLOBAL.SEARCH')}
            suffix={<i className="icon-search"></i>}
            onChange={(e) => setTerm((e.target as HTMLInputElement).value)}
          />
        </Space>
      </div>
    </header>
  );
};

export default styled(Header)`
  @media screen and (max-width: 900px) {
    .left {
      width: 100%;
      .ant-space {
        width: 100%;
        .ant-space-item {
          width: 100%;
        }
      }
    }
  }
`;
