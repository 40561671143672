import React, { useState, useEffect, useContext, useRef } from 'react';
import TableView from '@/layouts/TableView';
import Header from './components/Header';
import { Table, message, Modal, Breadcrumb } from 'antd';
import axios from 'axios';
import { AlignType } from '@/types/alignType.model';
import AppContext from '@/pages/app/context';
import Axios from 'axios';
import { IShiftTemplate, IShiftTemplateShift } from '@/types/shift-template.model';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ColumnsType } from 'antd/es/table/interface';
import { WEEKDAYS, getContrast } from '@/utils';
import colors from '@/styles/colors';
import moment from 'moment';

interface Props {
  className?: string;
}

const ScheduleTemplates: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartmentId, sections, users, dayoffs, skills, resources, tasks },
  } = useContext(AppContext);
  const history = useHistory();
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });
  const [shiftTemplates, setShiftTemplates] = useState<IShiftTemplate[]>([]);
  const [filteredShiftTemplates, setFilteredShiftTemplates] = useState<IShiftTemplate[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [term, setTerm] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTemplate, setActiveTemplate] = useState<IShiftTemplate | null>(null);
  const [shiftsColumns, setShiftsColumns] = useState<ColumnsType<IShiftTemplateShift>>([]);
  const location = useLocation();

  const shiftTemplatesRef = useRef<any[]>([]);
  shiftTemplatesRef.current = shiftTemplates;

  useEffect(() => {
    const parts = location.pathname.split('schedule-templates');
    if (parts[1] == '' || !parts[1]) {
      setActiveTemplate(null);
      return;
    }
    const templateId = parts[1].replace('/', '');
    const template = filteredShiftTemplates.find((template) => template.id == templateId);
    if (!template) return;
    setActiveTemplate(template);
  }, [location, filteredShiftTemplates]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/shift-templates`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        const shiftTemplates = data as IShiftTemplate[];
        setShiftTemplates(shiftTemplates);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.ID'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'template_name',
        key: 'template_name',
        sorter: (a: any, b: any) => a.template_name?.localeCompare(b.template_name),
      },
      {
        title: t('GLOBAL.SECTION'),
        key: 'section',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IShiftTemplate) => {
          const section = sections.find((x) => x.id === record.section);
          if (section) {
            return <span>{section!.name}</span>;
          } else {
            return null;
          }
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IShiftTemplate) => (
          <button className="red" onClick={() => onWantToDelete(record.id!)}>
            {t('GLOBAL.REMOVE')}
          </button>
        ),
      },
    ]);
  }, [i18n.language, sections]);

  useEffect(() => {
    setShiftsColumns([
      {
        title: t('GLOBAL.USER'),
        dataIndex: 'ressource_record_id',
        key: 'ressource_record_id',
        render: (text: string, record: IShiftTemplateShift) => {
          if (String(record.ressource_record_id) == '0')
            return <span style={{ color: colors.green }}>{t('GLOBAL.FREE_SHIFT')}</span>;
          const user = users.find((user) => user.recordId == String(record.ressource_record_id));
          if (!user) return;
          return <span>{user.displayName}</span>;
        },
      },
      {
        title: t('GLOBAL.DAY'),
        dataIndex: 'day',
        key: 'day',
        render: (text: string, record: IShiftTemplateShift) => {
          if (!record.daynumber) return;
          return <span>{t(`GLOBAL.${WEEKDAYS[record.daynumber - 1].toUpperCase()}`)}</span>;
        },
      },
      {
        title: t('GLOBAL.HOURS'),
        dataIndex: 'start',
        key: 'start',
        render: (text: string, record: IShiftTemplateShift) => {
          return (
            <span>
              {record.starthour}:{(record.startminute || 0) < 10 ? `0${record.startminute || 0}` : record.startminute} -{' '}
              {record.endhour}:{(record.endminute || 0) < 10 ? `0${record.endminute || 0}` : record.endminute}
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.TYPE'),
        dataIndex: 'shyft_type',
        key: 'shyft_type',
        render: (text: string, record: IShiftTemplateShift) => {
          if (!record.shyft_type) return;
          let dayoff = null;
          if (record.dayoff_type && record.dayoff_type !== '') {
            dayoff = dayoffs.find((dayoff) => dayoff.id == String(record.dayoff_type));
          }
          return (
            <span>
              {record.shyft_type == 2 ? t('GLOBAL.ABSENCE') : t('GLOBAL.SHIFT')}
              {dayoff && ` - ${dayoff.name}`}{' '}
              <span style={{ fontSize: 10, color: colors.grey }}>
                {record.dayoff_paid ? `(${t('GLOBAL.PAID')})` : ''}
              </span>
              {record.hr_code && (
                <span
                  style={{ backgroundColor: '#000', color: '#FFF', padding: '2px 5px', borderRadius: 8, marginLeft: 5 }}
                >
                  {record.hr_code}
                </span>
              )}
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.BREAKS'),
        dataIndex: 'pauses',
        key: 'pauses',
        render: (text: string, record: IShiftTemplateShift) => {
          if (!record.pauses || record.pauses.length == 0) return;
          return (
            <div>
              {record.pauses.map((pause) => (
                <span>
                  {pause.duration}min.{' '}
                  <span style={{ fontSize: 10, color: colors.grey }}>{pause.paid ? `(${t('GLOBAL.PAID')})` : ''}</span>
                </span>
              ))}
            </div>
          );
        },
      },
      {
        title: t('GLOBAL.SKILLS'),
        dataIndex: 'skills',
        key: 'skills',
        render: (text: string, record: IShiftTemplateShift) => {
          if (!record.skills || record.skills == '') return;
          const skillIds = record.skills.split(',');
          return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 3 }}>
              {skillIds.map((skillId) => {
                const skill = skills.find((skill) => skill.id == skillId);
                if (!skill) return;
                return (
                  <div
                    style={{
                      backgroundColor: skill.background,
                      color: getContrast(skill.background || '#FFF'),
                      padding: '2px 10px',
                      borderRadius: 8,
                      fontSize: 10,
                    }}
                  >
                    {skill.name}
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        title: t('GLOBAL.ATTRIBUTES'),
        dataIndex: 'attributes',
        key: 'attributes',
        render: (text: string, record: IShiftTemplateShift) => {
          if (!record.attributes || record.attributes == '') return;
          const attributeIds = record.attributes.split(',');
          return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 3 }}>
              {attributeIds.map((attributeId) => {
                const resource = resources.find((resource) =>
                  resource.attributes?.find((attribute) => attribute.id == attributeId),
                );
                if (!resource || !resource.attributes) return;
                const attribute = resource.attributes.find((attribute) => attribute.id == attributeId);
                if (!attribute) return;
                return (
                  <div
                    style={{
                      backgroundColor: attribute.background,
                      color: getContrast(attribute.background || '#FFF'),
                      padding: '2px 10px',
                      borderRadius: 8,
                      fontSize: 10,
                    }}
                  >
                    {attribute.name}
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        title: t('GLOBAL.TASKS'),
        dataIndex: 'tasks',
        key: 'tasks',
        render: (text: string, record: IShiftTemplateShift) => {
          if (!record.tasks || record.tasks.length == 0) return;
          return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 3 }}>
              {record.tasks.map((shiftTask) => {
                const task = tasks.find((task) => task.id == shiftTask.task_id);
                if (!task) return;
                return (
                  <div>
                    {task.name}: ({moment(shiftTask.startdate).format('HH:mm')} -{' '}
                    {moment(shiftTask.enddate).format('HH:mm')})
                  </div>
                );
              })}
            </div>
          );
        },
      },
    ]);
  }, [activeTemplate]);

  useEffect(() => {
    if (!shiftTemplates) return;
    const filteredShiftTemplates = shiftTemplates.filter((shiftTemplate: IShiftTemplate) =>
      shiftTemplate.template_name && term
        ? shiftTemplate.template_name.toLowerCase().includes(term.toLowerCase())
        : true,
    );
    setFilteredShiftTemplates(filteredShiftTemplates);
  }, [shiftTemplates, term]);

  useEffect(() => {
    const handlePopstate = () => {
      setActiveTemplate(null);
    };
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const onWantToDelete = (shiftTemplateId: string) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('WEEK_TEMPLATES.MODAL_DELETE_TEMPLATE.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDelete(shiftTemplateId);
      },
      onCancel: () => {},
    });
  };

  const onDelete = (shiftTemplateId: string) => {
    Axios.delete(`${process.env.REACT_APP_API_URL}/v3/shift-templates/${shiftTemplateId}`)
      .then((response) => {
        const shiftTemplatesCopy = [...shiftTemplatesRef.current];
        const index = shiftTemplatesCopy.findIndex((x) => x.id === shiftTemplateId);
        shiftTemplatesCopy.splice(index, 1);
        setShiftTemplates(shiftTemplatesCopy);
      })
      .catch((error) => {
        console.error(error);
        message.error(t('WEEK_TEMPLATES.MODAL_DELETE_TEMPLATE.MESSAGE_ERROR'));
      });
  };

  const onRow = (template: IShiftTemplate) => {
    return {
      onClick: () => {
        history.push(`schedule-templates/${template.id}`);
        setActiveTemplate(template);
      },
    };
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.SCHEDULES.SCHEDULE_TEMPLATES.TITLE')}</h2>
      {activeTemplate && (
        <Breadcrumb separator=">">
          <Breadcrumb.Item
            onClick={() => {
              setActiveTemplate(null);
              history.push('/app/settings/schedules/schedule-templates');
            }}
          >
            {t('SETTINGS.SCHEDULES.SCHEDULE_TEMPLATES.TITLE')}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{activeTemplate && activeTemplate.template_name}</Breadcrumb.Item>
        </Breadcrumb>
      )}
      <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10, marginTop: 25 }}>
        {activeTemplate ? (
          <TableView>
            <Table
              className={className}
              loading={loading}
              dataSource={activeTemplate.shifts || []}
              columns={shiftsColumns}
              rowKey="id"
              pagination={false}
            />
          </TableView>
        ) : (
          <TableView>
            <Header disabled={false} setTerm={setTerm} />
            <Table
              className={className}
              loading={loading}
              dataSource={filteredShiftTemplates}
              columns={columns}
              rowKey="id"
              pagination={false}
              onRow={onRow}
              rowClassName="row-clickable"
            />
          </TableView>
        )}
      </div>
    </div>
  );
};

export default styled(ScheduleTemplates)`
  .ant-breadcrumb-link {
    cursor: pointer;
  }
`;
