import React from 'react';
import { ITask } from '@/types/task.model';
import moment from 'moment';
import styled from 'styled-components';
import Colors from '@/styles/colors';
import { getContrast } from '@/utils';

interface Props {
  className?: string;
  tasks: ITask[];
  shiftId: string;
  backgroundColor?: string;
}
const ShiftTasks: React.FC<Props> = ({ className, tasks, shiftId, backgroundColor }) => {
  return (
    <React.Fragment>
      {tasks.length > 0 && (
        <div className={className}>
          {tasks.map((task, taskIndex) => (
            <div className="task" key={`shift_${shiftId}_task_${task.id}_${taskIndex}`}>
              <span style={{ color: backgroundColor ? getContrast(backgroundColor) : undefined }}>{task.name}</span>
              <span style={{ color: backgroundColor ? getContrast(backgroundColor) : undefined }}>
                {moment.unix(task.start!).format('HH:mm')} - {moment.unix(task.end!).format('HH:mm')}
              </span>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

const ShiftTasksStyled = styled(ShiftTasks)`
  color: ${Colors.grey};
  font-size: 10px;
  line-height: 12px;

  .task {
    display: inline-flex;
    width: 100%;
    margin-bottom: 3px;
    span {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:last-child {
        min-width: 70px;
        text-align: right;
      }
    }
    @media print {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 5px;
      span:last-child {
        text-align: left;
      }
    }
  }
`;

export default ShiftTasksStyled;
