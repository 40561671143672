import colors from '@/styles/colors';
import { IScheduleDay } from '@/types/schedule-day.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  provisional: string;
  real: string;
}

const ProductivityIndexRealDetails: React.FC<Props> = ({ className, provisional, real }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });

  return (
    <div className={className}>
      <div className="container">
        <div className="names">
          <span>{t('GLOBAL.FORECAST')}</span>
          <span>{t('GLOBAL.REAL')}</span>
        </div>
        <div className="values">
          <span>{provisional}</span>
          <span>{real}</span>
        </div>
      </div>
    </div>
  );
};

const ProductivityIndexRealDetailsStyled = styled(ProductivityIndexRealDetails)`
  position: relative;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: ${colors.grey};

  .container {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
  }

  .names,
  .values {
    display: flex;
    flex-direction: column;
  }

  .values {
    text-align: end;
  }
`;

export default ProductivityIndexRealDetailsStyled;
