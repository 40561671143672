import colors from '@/styles/colors';
import { Button, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import axios from 'axios';

interface Props {
  className?: string;
  visible: boolean;
  onClose: () => void;
  setModalPayingOptionConfirmVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalPayingOption: React.FC<Props> = ({ visible, className, onClose, setModalPayingOptionConfirmVisible }) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: any) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/operations/freemium-contact`,
        {
          department_id: activeDepartmentId,
          ...values,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(() => {
        setModalPayingOptionConfirmVisible(true);
        onClose();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <Modal className={className} visible={visible} footer={null} onCancel={onClose} width={'40%'}>
      <div className="container">
        <h1 className="title">{t('SETTINGS.ACCOUNT.GENERAL.PAID_OPTION.MODAL.TITLE')}</h1>
        <div className="descriptions">
          <p className="description-1">{t('SETTINGS.ACCOUNT.GENERAL.PAID_OPTION.MODAL.DESCRIPTION_1')}</p>
          <p className="description-2">{t('SETTINGS.ACCOUNT.GENERAL.PAID_OPTION.MODAL.DESCRIPTION_2')}</p>
        </div>
        <h2 style={{ fontWeight: 'bold' }}>{t('SETTINGS.ACCOUNT.GENERAL.PAID_OPTION.MODAL.CAPTION')}</h2>
        <Form layout="vertical" size="large" form={form} onFinish={onFinish} style={{ width: '100%' }}>
          <Form.Item name="content">
            <Input.TextArea rows={5} placeholder={t('SETTINGS.ACCOUNT.GENERAL.PAID_OPTION.MODAL.PLACEHOLDER')} />
          </Form.Item>
          <div className="buttons">
            <Button className="button" type="primary" size="large" htmlType="submit" loading={loading}>
              {t('SETTINGS.ACCOUNT.GENERAL.PAID_OPTION.MODAL.AGREE')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default styled(ModalPayingOption)`
  .container {
    display: flex;
    flex-direction: column;
    padding: 25px;

    .title {
      text-transform: uppercase;
      color: ${colors.green};
      font-weight: bold;
    }

    .descriptions {
      margin: 15px 0;
    }

    .buttons {
      display: flex;
      gap: 15px;
      margin-top: 25px;

      .button {
        text-transform: uppercase;
      }
    }
  }
`;
