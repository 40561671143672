import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled } from '@/utils';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select, Space, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
  rangePickerValues: [Moment, Moment];
  setRangePickerValues: React.Dispatch<React.SetStateAction<[Moment, Moment]>>;
  userRecordId: string | null;
  setUserRecordId: React.Dispatch<React.SetStateAction<string | null>>;
  userCategoryId: string | null;
  setUserCategoryId: React.Dispatch<React.SetStateAction<string | null>>;
  groupBy: string;
  setGroupBy: React.Dispatch<React.SetStateAction<string>>;
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  setShowModalAddCustomColumn: React.Dispatch<React.SetStateAction<boolean>>;
  isDownloading: boolean;
  onDownload: () => void;
}

const Header: React.FC<Props> = ({
  className,
  rangePickerValues,
  setRangePickerValues,
  userRecordId,
  setUserRecordId,
  setUserCategoryId,
  userCategoryId,
  groupBy,
  setGroupBy,
  type,
  setType,
  setShowModalAddCustomColumn,
  isDownloading,
  onDownload,
}) => {
  const { RangePicker } = DatePicker;
  const {
    state: { activeDepartment, loadingUsers, users, loadingUserCategories, userCategories, features },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <header className={className}>
      <div className="left">
        <Space>
          <h2 style={{ marginBottom: 0, marginRight: 10 }}>{t('REPORTS.ACTIVITY_REPORT.TITLE')}</h2>
          <RangePicker
            allowClear={false}
            getPopupContainer={(trigger) => trigger}
            format={'L'}
            value={rangePickerValues}
            onChange={(e: any) => {
              setRangePickerValues(e);
            }}
          />
          <Select
            className="select-type"
            getPopupContainer={(trigger) => trigger}
            value={type}
            onChange={(v) => setType(`${v}`)}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value={'SHIFT'}>{t('GLOBAL.PLANNED_PLURAL')}</Option>
            {isFeatureEnabled(features, FEATURES.CLOCKING) && (
              <Option value={'CLOCKING'}>{t('GLOBAL.CLOCKING')}</Option>
            )}
          </Select>
          <Select
            className="select-users"
            getPopupContainer={(trigger) => trigger}
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder={t('GLOBAL.USER')}
            loading={loadingUsers}
            disabled={loadingUsers}
            value={userRecordId || undefined}
            onChange={(e) => {
              setUserRecordId(e);
            }}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="">{t('GLOBAL.ALL')}</Option>
            {users?.map((user) => {
              const { recordId, displayName } = user;
              return (
                <Option key={`user_${recordId}`} value={recordId!}>
                  {displayName}
                </Option>
              );
            })}
          </Select>
          <Select
            className="select-user-statuses"
            getPopupContainer={(trigger) => trigger}
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder={t('GLOBAL.USER_CATEGORY_SHORT')}
            loading={loadingUserCategories}
            disabled={loadingUserCategories}
            value={userCategoryId || undefined}
            onChange={(e) => {
              setUserCategoryId(e);
            }}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="">{t('GLOBAL.ALL')}</Option>
            {userCategories
              ?.filter((x) => x.active)
              .map((userCategory) => {
                const { id, name } = userCategory;
                return (
                  <Option key={`userCategory_${id}`} value={id!}>
                    {name}
                  </Option>
                );
              })}
          </Select>
          <Select
            className="select-group"
            getPopupContainer={(trigger) => trigger}
            value={groupBy}
            onChange={(v) => setGroupBy(`${v}`)}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value={'DAY'}>{t('GLOBAL.GROUP_BY_DAY')}</Option>
            <Option value={'WEEK'}>{t('GLOBAL.GROUP_BY_WEEK')}</Option>
            <Option value={'MONTH'}>{t('GLOBAL.GROUP_BY_MONTH')}</Option>
            <Option value={'QUARTER'}>{t('GLOBAL.GROUP_BY_QUARTER')}</Option>
            <Option value={'YEAR'}>{t('GLOBAL.GROUP_BY_YEAR')}</Option>
          </Select>
          <Button type="primary" onClick={() => setShowModalAddCustomColumn(true)}>
            {t('REPORTS.ACTIVITY_REPORT.ADD_COUNTER')}
          </Button>
        </Space>
      </div>
      <div className="right">
        <Tooltip title={t('GLOBAL.DOWNLOAD')}>
          <Button
            icon={<CloudDownloadOutlined />}
            type="primary"
            loading={isDownloading}
            onClick={onDownload}
            style={{ backgroundColor: colors.blueLightPastel, borderColor: colors.blueLightPastel }}
          >
            {t('GLOBAL.DOWNLOAD')}
          </Button>
        </Tooltip>
      </div>
    </header>
  );
};

export default styled(Header)`
  .left {
    .ant-space {
      .ant-space-item {
        .select-type {
          width: 100px;
        }

        .select-users,
        .select-user-statuses,
        .select-group {
          width: 200px;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 15px;

    .left {
      .ant-space {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .ant-space-item {
          width: 100%;

          > div,
          > button {
            width: 100%;
          }

          .select-type,
          .select-users,
          .select-user-statuses,
          .select-group {
            width: 100%;
          }
        }
      }
    }

    .right {
      justify-content: flex-start;
      margin-left: 0;

      button {
        width: 100%;
      }
    }
  }
`;
