import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  hr_code_id?: string;
}
const ShiftSpecialHrCode: React.FC<Props> = ({ className, hr_code_id }) => {
  return (
    <React.Fragment>
      {hr_code_id && (
        <span
          className="tag small dark-blue"
          key={`hr_code_${hr_code_id}`}
          style={{
            background: '#000000',
            color: 'white',
            marginTop: '5px',
          }}
        >
          {hr_code_id}
        </span>
      )}
    </React.Fragment>
  );
};

const ShiftSpecialHrCodeStyled = styled(ShiftSpecialHrCode)`
  span:nth-of-type(n - 1) {
    margin-right: 5px;
  }
`;

export default ShiftSpecialHrCodeStyled;
