import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { AlignType } from '@/types/alignType.model';
import { BackTop, Button, Space, Table } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Header from './components/Header';

interface IPendingResource {
  resourceName: string;
  resourceId: string;
  attributeName: string;
  attributeId: string;
  date: number;
}

const PendingAttributes: React.FC = () => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const history = useHistory();
  const [resources, setResources] = useState<IPendingResource[]>([]);
  const [filteredResources, setFilteredResources] = useState<IPendingResource[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.ATTRIBUTES_CATEGORY'),
        key: 'resourceName',
        dataIndex: 'resourceName',
        // eslint-disable-next-line react/display-name
        sorter: (a: IPendingResource, b: IPendingResource) =>
          a?.resourceName?.localeCompare(b?.resourceName || '') || 0,
      },
      {
        title: t('GLOBAL.NAME'),
        key: 'attributeName',
        dataIndex: 'attributeName',
        // eslint-disable-next-line react/display-name
        sorter: (a: IPendingResource, b: IPendingResource) =>
          a?.attributeName?.localeCompare(b?.attributeName || '') || 0,
      },
      {
        title: t('GLOBAL.DATE'),
        key: 'date',
        width: 120,
        // eslint-disable-next-line react/display-name
        render: (_text: string, resource: IPendingResource) => {
          return <Space>{moment.unix(resource.date!).format('L')}</Space>;
        },
        sorter: (a: IPendingResource, b: IPendingResource) => a.date! - b.date!,
      },
      {
        key: 'actions',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (_text: string, resource: IPendingResource) => (
          <Button
            type="primary"
            onClick={() =>
              history.push(
                `/app/settings/attributes/manage/${resource.resourceId}/${resource.attributeId}?pending=true`,
              )
            }
          >
            {t('GLOBAL.TREAT')}
          </Button>
        ),
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    if (!location?.pathname) {
      return;
    }
    let mounted = true;
    setIsLoading(true);
    const cancelTokenSource = Axios.CancelToken.source();
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/pending-resources`, {
      params: {
        departmentId: activeDepartmentId,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        if (mounted) {
          const resources = response.data;
          setResources(resources);
          setFilteredResources(resources);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (mounted) {
          setIsLoading(false);
        }
        console.error(error);
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [location]);

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    if (resources) {
      const filteredUsers = resources.filter((resource) => {
        return resource?.attributeName?.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredResources(filteredUsers);
    } else {
      setFilteredResources([]);
    }
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header onSearchChange={onSearchChange} disabled={isLoading} />
      <Table
        loading={isLoading}
        dataSource={filteredResources}
        columns={columns}
        rowKey="attributeId"
        pagination={false}
      />
    </TableView>
  );
};

export default PendingAttributes;
