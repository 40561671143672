import AppContext from '@/pages/app/context';
import { IPaySheetAbsence, IPaySheetAbsenceRow } from '@/types/pay-periods/pay-sheet.model';
import { convertDecimalHourToTime } from '@/utils';
import { Input, Table } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  absenceSheet: IPaySheetAbsence | null;
  rows: IPaySheetAbsenceRow[];
  rowsCopy: IPaySheetAbsenceRow[];
  loading: boolean;
  format: 'h,m' | 'hhmm';
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const WorkedTable: React.FC<Props> = ({ className, absenceSheet, rows, rowsCopy, loading, format, onSearch }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [columns, setColumns] = useState<ColumnsType<IPaySheetAbsenceRow>>([]);

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        className: 'user',
        title: <Input placeholder={t('GLOBAL.SEARCH')} onChange={onSearch} />,
        render(value, record, index) {
          return (
            <a
              href={`/app/team/collaborators/${record.record_id}`}
              style={{ textDecoration: 'underline' }}
            >{`${record.name}`}</a>
          );
        },
        fixed: 'left',
      },
      {
        title: 'Payées',
        align: 'center',
        className: 'group payed',
        children: [
          {
            key: 'public-holiday',
            align: 'center',
            title: t('REPORTS.PAY_PERIODS.ABSENCE_TABLE.HOLIDAYS'),
            render(value, record, index) {
              return `${convertDecimalHourToTime(record.public_holiday, format)}`;
            },
          },
          {
            key: 'paid-leave',
            align: 'center',
            title: t('REPORTS.PAY_PERIODS.ABSENCE_TABLE.PAID_LEAVES'),
            render(value, record, index) {
              return `${convertDecimalHourToTime(record.paid_leave, format)}`;
            },
          },
          {
            key: 'rtt',
            align: 'center',
            title: t('REPORTS.PAY_PERIODS.ABSENCE_TABLE.RTT'),
            render(value, record, index) {
              return `${convertDecimalHourToTime(record.rtt, format)}`;
            },
          },
          {
            key: 'rcr',
            align: 'center',
            title: t('REPORTS.PAY_PERIODS.ABSENCE_TABLE.RCR'),
            render(value, record, index) {
              return `${convertDecimalHourToTime(record.rcr, format)}`;
            },
          },
          {
            key: 'other',
            align: 'center',
            title: t('REPORTS.PAY_PERIODS.ABSENCE_TABLE.OTHER_PAID'),
            className: 'group',
            render(value, record, index) {
              return `${convertDecimalHourToTime(record.other_paid, format)}`;
            },
          },
        ],
      },
      {
        title: 'Non-rémunérées',
        className: 'group not-payed',
        align: 'center',
        children: [
          // {
          //   key: '',
          //   title: 'Entrée/sortie (h)',
          //   align: 'center',
          //   render(value, record, index) {},
          // },
          // {
          //   key: 'late',
          //   title: 'Retard (h)',
          //   align: 'center',
          //   render(value, record, index) {},
          // },
          {
            key: 'unjustified',
            align: 'center',
            title: t('REPORTS.PAY_PERIODS.ABSENCE_TABLE.UNJUSTIFIED'),
            render(value, record, index) {
              return `${convertDecimalHourToTime(record.unjustified, format)}`;
            },
          },
          {
            key: 'justified',
            align: 'center',
            title: t('REPORTS.PAY_PERIODS.ABSENCE_TABLE.JUSTIFIED'),
            render(value, record, index) {
              return `${convertDecimalHourToTime(record.justified, format)}`;
            },
          },
          {
            key: 'leave-without-pay',
            align: 'center',
            title: t('REPORTS.PAY_PERIODS.ABSENCE_TABLE.LEAVE_WITHOUT_PAID'),
            render(value, record, index) {
              return `${convertDecimalHourToTime(record.leave_without_pay, format)}`;
            },
          },
          {
            key: '',
            align: 'center',
            title: t('REPORTS.PAY_PERIODS.ABSENCE_TABLE.SUSPENSION'),
            className: 'group',
            render(value, record, index) {
              return `${convertDecimalHourToTime(record.suspension, format)}`;
            },
          },
        ],
      },
      {
        title: 'Indemnisées',
        align: 'center',
        className: 'returned',
        children: [
          {
            key: 'sick',
            align: 'center',
            title: t('REPORTS.PAY_PERIODS.ABSENCE_TABLE.SICK'),
            render(value, record, index) {
              return `${convertDecimalHourToTime(record.sick, format)}`;
            },
          },
          {
            key: 'others',
            align: 'center',
            title: t('REPORTS.PAY_PERIODS.ABSENCE_TABLE.OTHER_UNPAID'),
            render(value, record, index) {
              return `${convertDecimalHourToTime(record.other_unpaid, format)}`;
            },
          },
        ],
      },
    ]);
  }, [rowsCopy, absenceSheet, format]);

  return <Table className={className} columns={columns} dataSource={rows} pagination={false} loading={loading} />;
};

export default styled(WorkedTable)`
  .ant-table-thead {
    .ant-table-cell {
      font-size: 11px !important;
    }

    .payed {
      background-color: #f0f9ff;
      font-size: 16px !important;
      font-weight: bold;
    }

    .not-payed {
      background-color: #ffedf1;
      font-size: 16px !important;
      font-weight: bold;
    }

    .returned {
      background-color: #eee;
      font-size: 16px !important;
      font-weight: bold;
    }
  }
  .group {
    border-right: 1px solid #ccc;
  }

  .user {
    border-right: 1px solid #ccc;
  }

  .ant-table-tbody {
    .ant-table-cell {
      &.user {
        background-color: #f7f7f7;
      }
    }
  }
`;
