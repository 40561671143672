import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { createNumberArray } from '@/utils';
import { IBiDepartment } from '@/types/bi/department.model';

interface Props {
  className?: string;
  department: IBiDepartment;
}

const DepartmentSubHeader: React.FC<Props> = ({ className, department }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
    dispatch,
  } = useContext(AppContext);

  const hours = createNumberArray(0, 23);

  return (
    <div className={className}>
      <div className="empty"></div>
      <div className="hours">
        {hours.map((hour) => (
          <div key={hour} className="hour">
            {hour <= 9 ? `0${hour}` : hour}
          </div>
        ))}
      </div>
    </div>
  );
};

export default styled(DepartmentSubHeader)`
  display: flex;
  align-items: center;
  background-color: #f6f9ff;

  .empty {
    width: 200px;
  }

  .hours {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;

    .hour {
      flex: 1;
      text-align: center;
    }
  }
`;
