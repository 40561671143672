import { Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const TabSelector: React.FC<Props> = ({ className, activeTab, setActiveTab }) => {
  const { t } = useTranslation();

  const onChange = (value: string) => {
    setActiveTab(value);
  };

  return (
    <div className={className}>
      <span>{t('GLOBAL.TAB')}</span>
      <Select size="large" value={activeTab} onChange={onChange}>
        <Select.Option value="DASHBOARD">{t('DASHBOARD.TITLE')}</Select.Option>
        <Select.Option value="PROFILE">{t('USERS.PROFILE')}</Select.Option>
        <Select.Option value="ACTIVITY">{t('GLOBAL.ACTIVITY')}</Select.Option>
        <Select.Option value="COUNTERS">{t('GLOBAL.COUNTERS')}</Select.Option>
        <Select.Option value="REPORTS">{t('MENU.REPORTS.TITLE')}</Select.Option>
        <Select.Option value="DOCUMENTS">{t('GLOBAL.DOCUMENTS')}</Select.Option>
        <Select.Option value="NOTES">{t('GLOBAL.NOTES')}</Select.Option>
      </Select>
    </div>
  );
};

export default styled(TabSelector)`
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
    margin-left: 25px;
    margin-right: 25px;

    .ant-select {
      width: 100%;

      .ant-select-selector {
        background-color: #fff;
      }
    }
  }
`;
