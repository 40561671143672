import React, { useEffect, useState, useContext } from 'react';
import { Table, BackTop, Button } from 'antd';
import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import Header from './components/Header';
import moment from 'moment';
import 'moment-timezone';
import { IMessage } from '@/types/message.model';
import DrawerFreeShiftsAlert from '../../components/drawers/FreeShiftsAlert';
moment.tz.setDefault('Atlantic/Reykjavik');

const AppHoursFreeShiftAlertsPage: React.FC = () => {
  const {
    state: { activeDepartmentId, activeDepartment },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [activeMessage, setActiveMessage] = useState<IMessage | null>(null);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
  const [newMessageCounter, setNewMessageCounter] = useState<number>(0);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.SENDING_DATE'),
        key: 'sendingDate',
        render: (text: string, message: IMessage) => {
          return moment.unix(message.sendingDate!).local().format('L');
        },
      },
      {
        title: t('GLOBAL.HOUR'),
        key: 'sendingHour',
        render: (text: string, message: IMessage) => {
          return moment.unix(message.sendingDate!).tz(activeDepartment?.timezone!).format('HH:mm');
        },
      },
      {
        title: t('GLOBAL.MESSAGE'),
        key: 'subject',
        dataIndex: 'subject',
        render: (text: string, message: IMessage) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontWeight: 'bold', fontSize: 12 }}>{message.subject}</span>
              <span>{message.message}</span>
            </div>
          );
        },
      },
      {
        title: t('GLOBAL.RECIPIENTS'),
        key: 'recipients',
        render: (text: string, message: IMessage) => {
          return message?.recipients?.userCategories?.map((category) => category.name).join(', ');
        },
      },
      {
        title: t('GLOBAL.STATUS'),
        key: 'status',
        render: (text: string, message: IMessage) => {
          return t(`GLOBAL.${message?.status}`);
        },
      },
      {
        title: t('GLOBAL.CREATED_BY'),
        key: 'createdBy',
        render: (text: string, message: IMessage) => {
          return message?.createdBy?.displayName;
        },
      },
      {
        title: t('GLOBAL.DATE'),
        key: 'createdAt',
        render: (text: string, message: IMessage) => {
          return moment.unix(message.createdAt!).format('L');
        },
      },
      {
        key: 'actions',
        render: (text: string, message: IMessage) => {
          if (message?.status === 'SCHEDULED') {
            return (
              <Button size="small" type="primary" danger onClick={() => setActiveMessage(message)}>
                {t('GLOBAL.CANCEL')}
              </Button>
            );
          } else {
            return (
              <Button size="small" type="primary" onClick={() => setActiveMessage(message)}>
                {t('FREE_SHIFT_NOTIFICATIONS.SEE_ALERT')}
              </Button>
            );
          }
        },
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    const cancelTokenSource = Axios.CancelToken.source();
    setLoadingMessages(true);
    setMessages([]);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/messages`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
        type: 'FREE_SHIFT_ALERT',
      },
    })
      .then(({ data }) => {
        setMessages(data);
        setLoadingMessages(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingMessages(false);
      });
    return () => {
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId, newMessageCounter]);

  const onNewMessage = () => {
    setNewMessageCounter(newMessageCounter + 1);
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header setActiveMessage={setActiveMessage} />
      <Table
        loading={loadingMessages}
        dataSource={messages}
        columns={columns}
        rowKey="id"
        onChange={() => {
          window.scrollTo(0, 0);
        }}
      />
      <DrawerFreeShiftsAlert
        message={activeMessage}
        visible={!!activeMessage}
        onClose={() => setActiveMessage(null)}
        onSave={onNewMessage}
      />
    </TableView>
  );
};

export default AppHoursFreeShiftAlertsPage;
