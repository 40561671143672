import React from 'react';
import ContractsList from './components/ContractsList';
import Error404 from '@/components/Error404';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Contract from './components/Contract';
import Templates from '../templates';
import Signatures from '../signatures';
import ContainerView from '@/layouts/ContainerView';

const AppDocumentsContracts: React.FC = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} render={() => <Redirect to={`${url}/all`} />} />
      <Route path={`${path}/all`}>
        <ContainerView>
          <ContractsList />
        </ContainerView>
      </Route>
      <Route path={`${path}/templates`}>
        <Templates documentType="CONTRACT" />
      </Route>
      <Route path={`${path}/signatures`}>
        <Signatures />
      </Route>
      <Route path={`${path}/:contractId`}>
        <Contract />
      </Route>
      <Route component={() => <Error404 />} />
    </Switch>
  );
};

export default AppDocumentsContracts;
