import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { FEATURES } from '@/types/features.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { isClockingAccount, isFeatureEnabled, minutesToHoursAndOrMinutes } from '@/utils';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, InputNumber, Select, Switch } from 'antd';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CalloutStyled from '../../components/Callout';
import ModalPause from '../../components/ModalPause';
import SettingsForm from '../../components/SettingsForm';
import ModalConfirmAutoApprove from './components/ModalConfirmAutoApprove';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const QRCode = require('qrcode.react');
const { Option } = Select;

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const ClockingSettings: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartment, features },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [checked, setChecked] = useState<(string | null)[]>([]);
  const [isLoadingPinCodes, setIsLoadingPinCodes] = useState<boolean>(false);
  const [startStrategy, setStartStrategy] = useState<string | null>(null);
  const [endStrategy, setEndStrategy] = useState<string | null>(null);
  const [startMaxLimit, setStartMaxLimit] = useState<boolean>(false);
  const [endMaxLimit, setEndMaxLimit] = useState<boolean>(false);
  const [wantsAutoApprove, setWantsAutoApprove] = useState<boolean>(false);
  const [approveFreeClockingUpdate, setApproveFreeClockingUpdate] = useState<boolean>(false);
  const [modalPauseVisible, setModalPauseVisible] = useState<boolean>(false);

  const showModalPause = () => {
    setModalPauseVisible(true);
  };

  const hideModalPause = () => {
    setModalPauseVisible(false);
  };

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const onReset = () => {
    setFormHasChanged(false);
    setChecked([]);
    if (currentSettings?.clockin) {
      const checked = Object.entries(currentSettings.clockin).map(([key, value]) => (value == true ? key : null));
      if (
        currentSettings.clockin.rounding?.start.strategy !== null &&
        currentSettings.clockin.rounding?.start.strategy !== undefined
      ) {
        setStartStrategy(currentSettings.clockin.rounding?.start.strategy);
        checked.push('clocking_round_start');
      }
      if (currentSettings.clockin.rounding?.start.max_limit !== null) {
        checked.push('clocking_round_start');
      }
      if (
        currentSettings.clockin.rounding?.end.strategy !== null &&
        currentSettings.clockin.rounding?.end.strategy !== undefined
      ) {
        setEndStrategy(currentSettings.clockin.rounding?.end.strategy);
        checked.push('clocking_round_end');
      }
      if (currentSettings.clockin.rounding?.end.max_limit !== null) {
        checked.push('clocking_round_end');
      }
      if (
        currentSettings.clockin.breaks?.direction !== undefined &&
        currentSettings.clockin.breaks?.direction !== null &&
        currentSettings.clockin.breaks?.interval !== undefined &&
        currentSettings.clockin.breaks?.interval !== null
      ) {
        checked.push('break_rounding');
      }
      if (currentSettings.clockin.approve_freeclockin) {
        setApproveFreeClockingUpdate(true);
      }
      setStartMaxLimit(
        currentSettings?.clockin?.rounding?.start.max_limit !== null &&
          currentSettings?.clockin?.rounding?.start.max_limit !== 0,
      );
      setEndMaxLimit(
        currentSettings?.clockin?.rounding?.end.max_limit !== null &&
          currentSettings?.clockin?.rounding?.end.max_limit !== 0,
      );
      setChecked(checked);
    }
    form.resetFields();
    let fieldsValue: any = {};
    if (currentSettings) {
      fieldsValue = {
        ...currentSettings,
      };
    }
    form.setFieldsValue(fieldsValue);
  };

  const downloadQR = () => {
    const canvas = document.getElementById('qr-code') as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png', 1).replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = 'qrcode.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const downloadPinCodes = () => {
    setIsLoadingPinCodes(true);
    Axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/v3/operations/download-pincode`,
      responseType: 'arraybuffer',
      params: {
        departmentId: activeDepartment?.id,
      },
    })
      .then((response) => {
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileURL = URL.createObjectURL(file);
        if (fileURL) {
          window.open(fileURL);
        }
        setIsLoadingPinCodes(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingPinCodes(false);
      });
  };

  const onFinish = (values: any) => {
    setLoading(true);
    const parsed_values = values;
    if (parsed_values.clockin.rounding.start.strategy === false) {
      parsed_values.clockin.rounding.start.strategy = null;
    }
    if (parsed_values.clockin.rounding.end.strategy === false) {
      parsed_values.clockin.rounding.end.strategy = null;
    }
    parsed_values.clockin.rounding.start.strategy = startStrategy;
    parsed_values.clockin.rounding.end.strategy = endStrategy;

    if (!startStrategy) {
      parsed_values.clockin.rounding.start.until_direction = null;
      parsed_values.clockin.rounding.start.until_minutes = null;
      parsed_values.clockin.rounding.start.near_direction = null;
      parsed_values.clockin.rounding.start.near_interval = null;
    }
    if (!endStrategy) {
      parsed_values.clockin.rounding.end.until_direction = null;
      parsed_values.clockin.rounding.end.until_minutes = null;
      parsed_values.clockin.rounding.end.near_direction = null;
      parsed_values.clockin.rounding.end.near_interval = null;
    }
    if (currentSettings?.clockin?.rounding?.start.strategy !== startStrategy) {
      if (startStrategy == 'until') {
        parsed_values.clockin.rounding.start.near_direction = null;
        parsed_values.clockin.rounding.start.near_interval = null;
      }
      if (startStrategy == 'near') {
        parsed_values.clockin.rounding.start.until_direction = null;
        parsed_values.clockin.rounding.start.until_minutes = null;
      }
    }
    if (currentSettings?.clockin?.rounding?.end.strategy !== endStrategy) {
      if (endStrategy == 'until') {
        parsed_values.clockin.rounding.end.near_direction = null;
        parsed_values.clockin.rounding.end.near_interval = null;
      }
      if (endStrategy == 'near') {
        parsed_values.clockin.rounding.end.until_direction = null;
        parsed_values.clockin.rounding.end.until_minutes = null;
      }
    }

    if (!startMaxLimit) {
      parsed_values.clockin.rounding.start.max_limit = null;
    } else {
      const max_limit = form.getFieldsValue(['clockin', 'rounding', 'start', 'max_limit']).max_limit;
      const formValues = form.getFieldsValue();
      if (!max_limit) {
        parsed_values.clockin.rounding.start.max_limit = checked.includes('clocking_round_start')
          ? formValues.clockin.rounding.start.max_limit
            ? formValues.clockin.rounding.start.max_limit
            : 30
          : null;
      }
    }
    if (!endMaxLimit) {
      parsed_values.clockin.rounding.end.max_limit = null;
    } else {
      const max_limit = form.getFieldsValue(['clockin', 'rounding', 'end', 'max_limit']).max_limit;
      const formValues = form.getFieldsValue();

      if (!max_limit) {
        parsed_values.clockin.rounding.end.max_limit = checked.includes('clocking_round_end')
          ? formValues.clockin.rounding.end.max_limit
            ? formValues.clockin.rounding.end.max_limit
            : 30
          : null;
      }
    }

    if (!checked.includes('clocking_round_start')) {
      parsed_values.clockin.rounding.start.strategy = null;
      parsed_values.clockin.rounding.start.until_direction = null;
      parsed_values.clockin.rounding.start.until_minutes = null;
      parsed_values.clockin.rounding.start.near_direction = null;
      parsed_values.clockin.rounding.start.near_interval = null;
      parsed_values.clockin.rounding.start.max_limit = null;
      setStartStrategy(null);
    }
    if (!checked.includes('clocking_round_end')) {
      parsed_values.clockin.rounding.end.strategy = null;
      parsed_values.clockin.rounding.end.until_direction = null;
      parsed_values.clockin.rounding.end.until_minutes = null;
      parsed_values.clockin.rounding.end.near_direction = null;
      parsed_values.clockin.rounding.end.near_interval = null;
      parsed_values.clockin.rounding.end.max_limit = null;
      setEndStrategy(null);
    }
    if (approveFreeClockingUpdate) {
      parsed_values.clockin.approve_freeclockin_update = approveFreeClockingUpdate;
    }
    updateCurrentSettings(parsed_values)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const updateForm = (c: any, e: any, name: any) => {
    setChecked(c ? [...checked, name] : checked.filter((el) => el != name));
  };

  const preventRadioOnSelectClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
  };

  const deletePause = (index: number) => {
    const pauses = [...(currentSettings?.pauses || [])];
    pauses.splice(index, 1);
    updateCurrentSettings({
      pauses,
    });
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
    {
      title: t('SETTINGS.CLOCKING.SETTINGS.TITLES.AUTO_BREAKS_EDIT'),
      visible: true,
    },
    {
      title: t('SETTINGS.CLOCKING.SETTINGS.TITLES.CLOCKING_HOURS_EDIT'),
      visible: true,
    },
    {
      title: t('SETTINGS.CLOCKING.SETTINGS.TITLES.MANAGE_BREAKS'),
      visible: true,
    },
    {
      title: t('SETTINGS.CLOCKING.SETTINGS.TITLES.DEPARTMENT_QR_CODE'),
      visible: true,
    },
  ];

  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.CLOCKING.SETTINGS.ACTIVATE_CLOCKING_DASHBOARD.LABEL'),
          description: t('SETTINGS.CLOCKING.SETTINGS.ACTIVATE_CLOCKING_DASHBOARD.DESCRIPTION'),
          name: ['general', 'clockin_dashboard'],
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.general?.clockin_dashboard} />,
          show: !isClockingAccount(features),
        },
        {
          label: t('SETTINGS.CLOCKING.SETTINGS.ALLOW_OFF_TIME_SLOT_CLOCKING.LABEL'),
          description: t('SETTINGS.CLOCKING.SETTINGS.ALLOW_OFF_TIME_SLOT_CLOCKING.DESCRIPTION'),
          name: ['clockin', 'freeclockin'],
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.clockin?.freeclockin}
              onChange={(checked, e) => updateForm(checked, e, 'freeclockin')}
            />
          ),
          show: isFeatureEnabled(features, FEATURES.SCHEDULE),
        },
        {
          label: t('SETTINGS.CLOCKING.SETTINGS.AUTO_APPROVE_OFF_TIME_SLOT_CLOCKINGS.LABEL'),
          description: t('SETTINGS.CLOCKING.SETTINGS.AUTO_APPROVE_OFF_TIME_SLOT_CLOCKINGS.DESCRIPTION'),
          name: ['clockin', 'approve_freeclockin'],
          style: { margin: 0 },
          element: (
            <Switch
              checked={approveFreeClockingUpdate}
              onChange={(checked) => {
                if (checked) {
                  setApproveFreeClockingUpdate(true);
                  setWantsAutoApprove(checked);
                } else {
                  setApproveFreeClockingUpdate(false);
                }
              }}
            />
          ),
          show: checked.includes('freeclockin') && isFeatureEnabled(features, FEATURES.SCHEDULE),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.freeclockin !== curValues.clockin?.freeclockin;
          },
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.CLOCKING.SETTINGS.ROUND_BREAK_TIME.LABEL'),
          description: t('SETTINGS.CLOCKING.SETTINGS.ROUND_BREAK_TIME.DESCRIPTION'),
          name: ['clockin', 'breaks'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={
                currentSettings?.clockin?.breaks?.direction !== null &&
                currentSettings?.clockin?.breaks?.direction !== undefined &&
                currentSettings?.clockin?.breaks?.interval !== undefined &&
                currentSettings?.clockin?.breaks?.interval !== null
              }
              onChange={(checked, e) => updateForm(checked, e, 'break_rounding')}
            />
          ),
          show: true,
        },
        {
          label: '',
          description: '',
          style: { margin: 0 },
          element: (form: any) => {
            const show = form.getFieldValue(['clockin', 'breaks']);
            if (show) {
              return (
                <div>
                  <div className="form-items-group one-line">
                    <span>{t('SETTINGS.ACCOUNT.CLOCKING.NAMES.ROUND_BREAK_TIME')}</span>
                    <Form.Item name={['clockin', 'breaks', 'direction']} rules={[{ required: true, message: '' }]}>
                      <Select style={{ width: '180px' }}>
                        <Option value="UP">{t('SETTINGS.ACCOUNT.CLOCKING.UPWARDS')}</Option>
                        <Option value="DOWN">{t('SETTINGS.ACCOUNT.CLOCKING.DOWNWARDS')}</Option>
                        <Option value="CLOSEST">{t('SETTINGS.ACCOUNT.CLOCKING.CLOSEST')}</Option>
                      </Select>
                    </Form.Item>
                    <span>{t('SETTINGS.ACCOUNT.CLOCKING.BY_STEPS_OF')}</span>
                    <Form.Item name={['clockin', 'breaks', 'interval']} rules={[{ required: true, message: '' }]}>
                      <Select style={{ width: '70px' }}>
                        <Option value={5}>5</Option>
                        <Option value={15}>15</Option>
                        <Option value={30}>30</Option>
                      </Select>
                    </Form.Item>
                    <span>{t('GLOBAL.MINUTES')}</span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 25, color: '#b4b4b4' }}>
                    <p style={{ margin: 0 }}>
                      <span style={{ textDecoration: 'underline' }}>
                        {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')} 1
                      </span>{' '}
                      : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.BREAK_TIME.1')}
                    </p>
                    <p style={{ margin: 0 }}>
                      <span style={{ textDecoration: 'underline' }}>
                        {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')} 2
                      </span>{' '}
                      : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.BREAK_TIME.2')}
                    </p>
                  </div>
                </div>
              );
            }
          },
          elementLeft: true,
          show: checked.includes('break_rounding'),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.breaks !== curValues.clockin?.breaks;
          },
        },
        {
          label: t('SETTINGS.CLOCKING.SETTINGS.USE_SHIFT_BREAK.LABEL'),
          description: t('SETTINGS.CLOCKING.SETTINGS.USE_SHIFT_BREAK.DESCRIPTION'),
          name: ['clockin', 'breaks', 'takeShiftMinimum'],
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.clockin?.breaks?.takeShiftMinimum} />,
          show: isFeatureEnabled(features, FEATURES.SCHEDULE),
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.ACCOUNT.CLOCKING.NAMES.CLOCKING_START_TIME'),
          description: t('SETTINGS.ACCOUNT.CLOCKING.DESCRIPTIONS.CLOCKING_START_TIME'),
          name: ['clockin', 'rounding', 'start', 'strategy'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={
                currentSettings?.clockin?.rounding?.start.strategy !== null ||
                currentSettings.clockin.rounding.start.max_limit !== null
              }
              onChange={(checked, e) => updateForm(checked, e, 'clocking_round_start')}
            />
          ),
          show: true,
        },
        {
          label: '',
          description: '',
          style: { margin: 0 },
          element: (form: any) => {
            const show = form.getFieldValue(['clockin', 'rounding', 'start', 'strategy']);
            const show2 = form.getFieldValue(['clockin', 'rounding', 'start', 'max_limit']);

            if (show || show2) {
              return (
                <>
                  {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
                    <Checkbox
                      name="max"
                      defaultChecked={startMaxLimit}
                      onChange={(e) => {
                        setFormHasChanged(true);
                        setStartMaxLimit(e.target.checked);
                      }}
                    >
                      <div className="form-items-group one-line">
                        <span>{t('SETTINGS.ACCOUNT.CLOCKING.ALLOW_MAX_CLOCKING')}</span>
                        <Form.Item name={['clockin', 'rounding', 'start', 'max_limit']}>
                          <InputNumber
                            min={1}
                            defaultValue={30}
                            formatter={(input) => `${Math.abs(input!)}`}
                            style={{ width: '70px' }}
                            onClick={preventRadioOnSelectClick}
                          />
                        </Form.Item>
                        <span>{t('GLOBAL.MINUTES')}</span>
                        <span>{t('SETTINGS.ACCOUNT.CLOCKING.BEFORE_PLANNED_HOUR')}</span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: 10,
                          marginBottom: 15,
                          color: '#b4b4b4',
                        }}
                      >
                        <p style={{ margin: 0 }}>
                          <span style={{ textDecoration: 'underline' }}>
                            {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')}
                          </span>{' '}
                          : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.START_TIME.MAX.1')}
                        </p>
                      </div>
                    </Checkbox>
                  )}
                  <Form.Item name={['clockin', 'rounding', 'start', 'strategy']}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                      {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
                        <Checkbox
                          value="until"
                          defaultChecked={currentSettings?.clockin?.rounding?.start.strategy == 'until'}
                          checked={startStrategy === 'until'}
                          onChange={(value) => {
                            if (startStrategy == 'until') {
                              setStartStrategy(null);
                            } else {
                              setStartStrategy('until');
                            }
                          }}
                        >
                          <div className="form-items-group one-line">
                            <span>{t('SETTINGS.ACCOUNT.CLOCKING.USE_SHIFT_HOUR_IF_CLOCKING_OCCURS')}</span>
                            <Form.Item
                              name={['clockin', 'rounding', 'start', 'until_minutes']}
                              rules={[{ required: startStrategy === 'until', message: '' }]}
                            >
                              <Select style={{ width: '70px' }} onClick={preventRadioOnSelectClick}>
                                <Option value={5}>5</Option>
                                <Option value={15}>15</Option>
                                <Option value={30}>30</Option>
                              </Select>
                            </Form.Item>
                            <span>{t('GLOBAL.MINUTES')}</span>
                            <Form.Item
                              name={['clockin', 'rounding', 'start', 'until_direction']}
                              rules={[{ required: startStrategy === 'until', message: '' }]}
                            >
                              <Select style={{ width: '140px' }} onClick={preventRadioOnSelectClick}>
                                <Option value="DOWN">{t('GLOBAL.BEFORE')}</Option>
                                <Option value="UP">{t('GLOBAL.AFTER')}</Option>
                              </Select>
                            </Form.Item>
                            <span>{t('SETTINGS.ACCOUNT.CLOCKING.PLANNED_HOUR')}</span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginTop: 10,
                              marginBottom: 15,
                              color: '#b4b4b4',
                            }}
                          >
                            <p style={{ margin: 0 }}>
                              <span style={{ textDecoration: 'underline' }}>
                                {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')} 1
                              </span>{' '}
                              : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.START_TIME.UNTIL.1')}
                            </p>
                            <p style={{ margin: 0 }}>
                              <span style={{ textDecoration: 'underline' }}>
                                {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')} 2
                              </span>{' '}
                              : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.START_TIME.UNTIL.2')}
                            </p>
                            <p style={{ margin: 0 }}>
                              <span style={{ textDecoration: 'underline' }}>
                                {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')} 3
                              </span>{' '}
                              : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.START_TIME.UNTIL.3')}
                            </p>
                          </div>
                        </Checkbox>
                      )}
                      <Checkbox
                        value="near"
                        style={{ marginLeft: 0 }}
                        checked={startStrategy === 'near'}
                        onChange={(value) => {
                          if (startStrategy == 'near') {
                            setStartStrategy(null);
                          } else {
                            setStartStrategy('near');
                          }
                        }}
                      >
                        <div className="form-items-group one-line">
                          <span>{t('SETTINGS.ACCOUNT.CLOCKING.ROUND_CLOCKING_START_TIME')}</span>
                          <Form.Item
                            name={['clockin', 'rounding', 'start', 'near_direction']}
                            rules={[{ required: startStrategy === 'near', message: '' }]}
                          >
                            <Select style={{ width: '180px' }} onClick={preventRadioOnSelectClick}>
                              <Option value="UP">{t('SETTINGS.ACCOUNT.CLOCKING.UPWARDS')}</Option>
                              <Option value="DOWN">{t('SETTINGS.ACCOUNT.CLOCKING.DOWNWARDS')}</Option>
                              <Option value="CLOSEST">{t('SETTINGS.ACCOUNT.CLOCKING.CLOSEST')}</Option>
                            </Select>
                          </Form.Item>
                          <span>{t('SETTINGS.ACCOUNT.CLOCKING.BY_STEPS_OF')}</span>
                          <Form.Item
                            name={['clockin', 'rounding', 'start', 'near_interval']}
                            rules={[{ required: startStrategy === 'near', message: '' }]}
                          >
                            <Select style={{ width: '70px' }} onClick={preventRadioOnSelectClick}>
                              <Option value={5}>5</Option>
                              <Option value={15}>15</Option>
                              <Option value={30}>30</Option>
                            </Select>
                          </Form.Item>
                          <span>{t('GLOBAL.MINUTES')}</span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 10,
                            marginBottom: 15,
                            color: '#b4b4b4',
                          }}
                        >
                          <p style={{ margin: 0 }}>
                            <span style={{ textDecoration: 'underline' }}>
                              {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')} 1
                            </span>{' '}
                            : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.START_TIME.NEAR.1')}
                          </p>
                          <p style={{ margin: 0 }}>
                            <span style={{ textDecoration: 'underline' }}>
                              {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')} 2
                            </span>{' '}
                            : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.START_TIME.NEAR.2')}
                          </p>
                        </div>
                      </Checkbox>
                    </div>
                  </Form.Item>
                </>
              );
            }
          },
          elementLeft: true,
          show: checked.includes('clocking_round_start'),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.clocking_round_start !== curValues.clockin?.clocking_round_start;
          },
        },
        {
          label: t('SETTINGS.ACCOUNT.CLOCKING.NAMES.CLOCKING_END_TIME'),
          description: t('SETTINGS.ACCOUNT.CLOCKING.DESCRIPTIONS.CLOCKING_END_TIME'),
          name: ['clockin', 'rounding', 'end', 'strategy'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={
                currentSettings?.clockin?.rounding?.end.strategy !== null ||
                currentSettings.clockin.rounding.end.max_limit !== null
              }
              onChange={(checked, e) => updateForm(checked, e, 'clocking_round_end')}
            />
          ),
          show: true,
        },
        {
          label: '',
          description: '',
          style: { margin: 0 },
          element: (form: any) => {
            const show = form.getFieldValue(['clockin', 'rounding', 'end', 'strategy']);
            const show2 = form.getFieldValue(['clockin', 'rounding', 'end', 'max_limit']);
            if (show || show2) {
              return (
                <>
                  {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
                    <Checkbox
                      name="max"
                      defaultChecked={
                        currentSettings?.clockin?.rounding?.end.max_limit !== null &&
                        currentSettings?.clockin?.rounding?.end.max_limit !== 0
                      }
                      onChange={(e) => {
                        setFormHasChanged(true);
                        setEndMaxLimit(e.target.checked);
                      }}
                    >
                      <div className="form-items-group one-line">
                        <span>{t('SETTINGS.ACCOUNT.CLOCKING.ALLOW_MAX_OVERRUN')}</span>
                        <Form.Item name={['clockin', 'rounding', 'end', 'max_limit']}>
                          <InputNumber
                            min={1}
                            defaultValue={30}
                            formatter={(input) => `${Math.abs(input!)}`}
                            style={{ width: '70px' }}
                            onClick={preventRadioOnSelectClick}
                          />
                        </Form.Item>
                        <span>{t('GLOBAL.MINUTES')}</span>
                        <span>{t('SETTINGS.ACCOUNT.CLOCKING.AFTER_PLANNED_HOUR')}</span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: 10,
                          marginBottom: 15,
                          color: '#b4b4b4',
                        }}
                      >
                        <p style={{ margin: 0 }}>
                          <span style={{ textDecoration: 'underline' }}>
                            {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')}
                          </span>{' '}
                          : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.END_TIME.MAX.1')}
                        </p>
                      </div>
                    </Checkbox>
                  )}
                  <Form.Item name={['clockin', 'rounding', 'end', 'strategy']}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                      {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
                        <Checkbox
                          value="until"
                          defaultChecked={currentSettings?.clockin?.rounding?.end.strategy == 'until'}
                          checked={endStrategy === 'until'}
                          onChange={(value) => {
                            if (endStrategy == 'until') {
                              setEndStrategy(null);
                            } else {
                              setEndStrategy('until');
                            }
                          }}
                        >
                          <div className="form-items-group one-line">
                            <span>{t('SETTINGS.ACCOUNT.CLOCKING.USE_SHIFT_HOUR_IF_CLOCKING_OCCURS')}</span>
                            <Form.Item
                              name={['clockin', 'rounding', 'end', 'until_minutes']}
                              rules={[{ required: endStrategy === 'until', message: '' }]}
                            >
                              <Select style={{ width: '70px' }} onClick={preventRadioOnSelectClick}>
                                <Option value={5}>5</Option>
                                <Option value={15}>15</Option>
                                <Option value={30}>30</Option>
                              </Select>
                            </Form.Item>
                            <span>{t('GLOBAL.MINUTES')}</span>
                            <Form.Item
                              name={['clockin', 'rounding', 'end', 'until_direction']}
                              rules={[{ required: endStrategy === 'until', message: '' }]}
                            >
                              <Select style={{ width: '140px' }} onClick={preventRadioOnSelectClick}>
                                <Option value="DOWN">{t('GLOBAL.BEFORE')}</Option>
                                <Option value="UP">{t('GLOBAL.AFTER')}</Option>
                              </Select>
                            </Form.Item>
                            <span>{t('SETTINGS.ACCOUNT.CLOCKING.PLANNED_HOUR')}</span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginTop: 10,
                              marginBottom: 15,
                              color: '#b4b4b4',
                            }}
                          >
                            <p style={{ margin: 0 }}>
                              <span style={{ textDecoration: 'underline' }}>
                                {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')} 1
                              </span>{' '}
                              : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.END_TIME.UNTIL.1')}
                            </p>
                            <p style={{ margin: 0 }}>
                              <span style={{ textDecoration: 'underline' }}>
                                {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')} 2
                              </span>{' '}
                              : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.END_TIME.UNTIL.2')}
                            </p>
                            <p style={{ margin: 0 }}>
                              <span style={{ textDecoration: 'underline' }}>
                                {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')} 3
                              </span>{' '}
                              : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.END_TIME.UNTIL.3')}
                            </p>
                          </div>
                        </Checkbox>
                      )}
                      <Checkbox
                        value="near"
                        style={{ marginLeft: 0 }}
                        defaultChecked={currentSettings?.clockin?.rounding?.end.strategy == 'near'}
                        checked={endStrategy === 'near'}
                        onChange={(value) => {
                          if (endStrategy == 'near') {
                            setEndStrategy(null);
                          } else {
                            setEndStrategy('near');
                          }
                        }}
                      >
                        <div className="form-items-group one-line">
                          <span>{t('SETTINGS.ACCOUNT.CLOCKING.ROUND_CLOCKING_END_TIME')}</span>
                          <Form.Item
                            name={['clockin', 'rounding', 'end', 'near_direction']}
                            rules={[{ required: endStrategy === 'near', message: '' }]}
                          >
                            <Select style={{ width: '180px' }} onClick={preventRadioOnSelectClick}>
                              <Option value="UP">{t('SETTINGS.ACCOUNT.CLOCKING.UPWARDS')}</Option>
                              <Option value="DOWN">{t('SETTINGS.ACCOUNT.CLOCKING.DOWNWARDS')}</Option>
                              <Option value="CLOSEST">{t('SETTINGS.ACCOUNT.CLOCKING.CLOSEST')}</Option>
                            </Select>
                          </Form.Item>
                          <span>{t('SETTINGS.ACCOUNT.CLOCKING.BY_STEPS_OF')}</span>
                          <Form.Item
                            name={['clockin', 'rounding', 'end', 'near_interval']}
                            rules={[{ required: endStrategy === 'near', message: '' }]}
                          >
                            <Select style={{ width: '70px' }} onClick={preventRadioOnSelectClick}>
                              <Option value={5}>5</Option>
                              <Option value={15}>15</Option>
                              <Option value={30}>30</Option>
                            </Select>
                          </Form.Item>
                          <span>{t('GLOBAL.MINUTES')}</span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 10,
                            marginBottom: 15,
                            color: '#b4b4b4',
                          }}
                        >
                          <p style={{ margin: 0 }}>
                            <span style={{ textDecoration: 'underline' }}>
                              {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')} 1
                            </span>{' '}
                            : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.END_TIME.NEAR.1')}
                          </p>
                          <p style={{ margin: 0 }}>
                            <span style={{ textDecoration: 'underline' }}>
                              {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.EXAMPLE')} 2
                            </span>{' '}
                            : {t('SETTINGS.ACCOUNT.CLOCKING.EXAMPLES.END_TIME.NEAR.2')}
                          </p>
                        </div>
                      </Checkbox>
                    </div>
                  </Form.Item>
                </>
              );
            }
          },
          elementLeft: true,
          show: checked.includes('clocking_round_end'),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.clocking_round_end !== curValues.clockin?.clocking_round_end;
          },
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.CLOCKING.SETTINGS.CONSIDER_BREAKS_AS_PAID.LABEL'),
          description: t('SETTINGS.CLOCKING.SETTINGS.CONSIDER_BREAKS_AS_PAID.DESCRIPTION'),
          name: ['clockin', 'paid_clockin_break'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.clockin?.paid_clockin_break} />,
          show: true,
        },
        {
          label: t('SETTINGS.CLOCKING.SETTINGS.CREATE_BREAK.LABEL'),
          name: ['clockin', 'paid_clockin_break'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <>
              {!!currentSettings?.pauses?.filter((pause) => pause.onlyClocking).length && (
                <CalloutStyled style={{ maxWidth: 540 }}>
                  <ul className="pauses">
                    {currentSettings?.pauses
                      ?.filter((pause) => pause.onlyClocking)
                      .map((pause, index: number) => (
                        <li key={index}>
                          <CloseOutlined
                            onClick={() => {
                              deletePause(index);
                            }}
                          />
                          {minutesToHoursAndOrMinutes(pause.duration / 60)} {t('SETTINGS.ACCOUNT.BREAKS.BREAK.AFTER')}{' '}
                          {minutesToHoursAndOrMinutes(pause.from / 60)} {t('SETTINGS.ACCOUNT.BREAKS.BREAK.OF_WORK')}
                          {', '}
                          {pause.paid
                            ? t('GLOBAL.PAID_BREAK').toLocaleLowerCase()
                            : t('GLOBAL.UNPAID_BREAK').toLocaleLowerCase()}
                        </li>
                      ))}
                  </ul>
                </CalloutStyled>
              )}
              <div className="row-with-helper">
                <Button
                  type="dashed"
                  style={{ marginTop: 15, marginBottom: 15, marginRight: 15 }}
                  onClick={showModalPause}
                >
                  {t('SETTINGS.ACCOUNT.BREAKS.ADD_AUTO_BREAK')}
                </Button>{' '}
              </div>
            </>
          ),
          elementBelow: true,
          show: true,
        },
      ],
    ],
    [
      [
        {
          label: '',
          description: '',
          style: { margin: 0 },
          element: (
            <>
              {currentSettings?.department && currentSettings.department.shortcode && (
                <div style={{ border: `1px solid ${colors.greyLight}`, padding: 20, borderRadius: 3 }}>
                  <QRCode
                    id="qr-code"
                    size={640}
                    level="H"
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    value={currentSettings.department && currentSettings.department.shortcode}
                  />
                </div>
              )}
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                <div className="row-with-helper">
                  <Button type="default" style={{ marginBottom: 5 }} onClick={downloadQR}>
                    {t('GLOBAL.DOWNLOAD_QR_CODE')}
                  </Button>{' '}
                </div>
                <div className="row-with-helper">
                  <Button type="default" onClick={downloadPinCodes} loading={isLoadingPinCodes}>
                    {t('SETTINGS.ACCOUNT.CLOCKING.DOWNLOAD_PIN_CODES_BUTTON')}
                  </Button>{' '}
                </div>
              </div>
            </>
          ),
          elementLeft: true,
          show: true,
        },
      ],
    ],
  ];

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.CLOCKING.SETTINGS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
      <ModalConfirmAutoApprove
        form={form}
        visible={wantsAutoApprove}
        setVisible={setWantsAutoApprove}
        setApproveFreeClockingUpdate={setApproveFreeClockingUpdate}
        setFormHasChanged={setFormHasChanged}
      />
      <ModalPause
        currentSettings={currentSettings}
        updateCurrentSettings={updateCurrentSettings}
        visible={modalPauseVisible}
        onCancel={hideModalPause}
        onlyClocking
      />
    </div>
  );
};

export default styled(ClockingSettings)`
  #qr-code {
    width: 160px !important;
    height: 160px !important;
  }

  h3 {
    margin: 10px 0 5px;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-form-item-explain {
    min-height: 0px;
  }
  .pauses {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li:nth-of-type(n + 2) {
      padding-top: 5px;
    }

    .anticon-close {
      background: ${colors.red};
      padding: 1px;
      border-radius: 10px;
      margin-right: 5px;
      svg {
        transform: scale(0.8);
        fill: white;
      }
    }
  }
`;
