import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled, valueForSearch } from '@/utils';
import { Select } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import React, { Dispatch, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ActionType } from '../redux/actions';
import { InitialStateType } from '../redux/store';

const { Option, OptGroup } = Select;

interface Props {
  label?: string;
  size?: SizeType;
}

const SubHeaderSearch: React.FC<Props> = ({ label, size }) => {
  const { t } = useTranslation();
  const { filterOptions, active_weekly_view } = useSelector(
    ({ filterOptions, active_weekly_view }: InitialStateType) => ({
      filterOptions,
      active_weekly_view,
    }),
  );
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const { state: appContextState } = useContext(AppContext);
  const {
    activeSection,
    activeDepartment,
    departments,
    sections,
    skills,
    tasks,
    users,
    resources,
    userCategories,
    features,
  } = appContextState;

  const find = (arr: any[], id: string) => {
    return arr.find((el) => el.id == id);
  };

  const onMouseEnter = (e: React.MouseEvent) => {
    const all: any[] = [];
    for (let i = 2; i < filterOptions.length; i++) {
      const element = filterOptions[i];
      const data = element.split('.');
      const name = data[0];
      const id = data[1];

      switch (name) {
        case 'department': {
          const found = find(departments, id);
          if (found) {
            all.push(found.company);
          }
          break;
        }
        case 'section': {
          const found = find(sections, id);
          if (found) {
            all.push(found.name);
          }
          break;
        }
        case 'skill': {
          const found = find(skills, id);
          if (found) {
            all.push(found.name);
          }
          break;
        }
        case 'task': {
          const found = find(tasks, id);
          if (found) {
            all.push(found.name);
          }
          break;
        }
        case 'userCategory': {
          const found = find(userCategories, id);
          if (found) {
            all.push(found.name);
          }
          break;
        }
        case 'user': {
          const found = find(users, id);
          if (found) {
            all.push(found.name);
          }
          break;
        }
        default: {
          break;
        }
      }
    }
    const doc = document.getElementsByClassName('ant-select-selection-overflow-item-rest')[0] as HTMLElement;
    if (doc && doc.firstChild) {
      (doc.firstChild as HTMLElement).title = all.join(', ');
    }
  };

  const handdleTagsOnChange = (value: string[]) => {
    batch(() => {
      hoursDispatch({
        type: 'SET_FILTER_OPTIONS',
        payload: value,
      });
      hoursDispatch({
        type: 'UPDATE_FILTERED_SHIFTS',
        payload: { department: activeDepartment },
      });
    });
    if (active_weekly_view) {
      hoursDispatch({
        type: 'SET_ACTIVE_WEEKLY_VIEW',
        payload: null,
      });
    }
  };

  return (
    <div className="search-col no-print" onMouseEnter={onMouseEnter}>
      {label && <span>{label}</span>}
      <Select
        mode="multiple"
        style={{ width: '100%', height: 30 }}
        placeholder={t('GLOBAL.SEARCH')}
        maxTagTextLength={3}
        maxTagCount={2}
        defaultValue={filterOptions}
        suffixIcon={<i className="icon-search" />}
        onChange={handdleTagsOnChange}
        filterOption={(input, option) => {
          if (option) {
            if (!option.label) {
              let children = option.children;
              if (Array.isArray(children)) {
                children = children.join();
              }
              return valueForSearch(children).includes(valueForSearch(input));
            }
          }
          return false;
        }}
        size={size ? size : 'middle'}
      >
        {activeDepartment?.ay_core && (
          <OptGroup label="AbsoluteYOU">
            <Option key={`ay_users`} value={`ay_users`}>
              {t('GLOBAL.AY_USERS')}
            </Option>
          </OptGroup>
        )}
        {!activeDepartment && (
          <OptGroup label={t('GLOBAL.DEPARTMENTS')}>
            {departments.map((department) => (
              <Option key={`department_${department.id}`} value={`department.${department.id}`}>
                {department.company}
              </Option>
            ))}
          </OptGroup>
        )}
        {!activeSection && isFeatureEnabled(features, FEATURES.SECTIONS) && (
          <OptGroup label={t('GLOBAL.SECTIONS')}>
            {sections.map((section) => (
              <Option key={`section_${section.id}`} value={`section.${section.id}`}>
                {section.name}
              </Option>
            ))}
          </OptGroup>
        )}
        {isFeatureEnabled(features, FEATURES.SKILLS) && !!skills?.length && (
          <OptGroup label={t('GLOBAL.SKILLS')}>
            {skills.map((skill) => (
              <Option key={`skill.${skill.id}`} value={`skill.${skill.id}`}>
                {skill.name}
              </Option>
            ))}
          </OptGroup>
        )}
        {isFeatureEnabled(features, FEATURES.TASKS) && !!tasks?.length && (
          <OptGroup label={t('GLOBAL.TASKS')}>
            {tasks.map((task) => (
              <Option key={`task.${task.id}`} value={`task.${task.id}`}>
                {task.name}
              </Option>
            ))}
          </OptGroup>
        )}
        {!!userCategories?.length && (
          <OptGroup label={t('GLOBAL.USER_CATEGORY')}>
            {userCategories.map((userCategory) => (
              <Option key={`userCategory.${userCategory.id}`} value={`userCategory.${userCategory.id}`}>
                {userCategory.name}
              </Option>
            ))}
          </OptGroup>
        )}
        {!!users?.length && (
          <OptGroup label={t('GLOBAL.USERS')}>
            {users.map((user) => (
              <Option key={`user.${user.recordId}`} value={`user.${user.recordId}`}>
                {user.displayName}
              </Option>
            ))}
          </OptGroup>
        )}
        {!!resources?.length && (
          <React.Fragment>
            {resources.map((resource = {}) => (
              <OptGroup label={resource.name} key={`resource_${resource.id}`}>
                {(resource.attributes || []).map((attribut) => (
                  <Option value={`attribut.${attribut.id}`} key={`attribut.${attribut.id}`}>
                    {attribut.name}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </React.Fragment>
        )}
      </Select>
    </div>
  );
};

export default SubHeaderSearch;
