import { Col, Form, InputNumber, Row, Select, Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IResponse } from '.';

const { Option } = Select;

interface Props {
  className?: string;
  meta?: IResponse['meta'];
}

const Worker: React.FC<Props> = ({ className, meta }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <div className={className}>
      <h4>{t('PROFILE_EXTRA.TITLE')}</h4>
      <Row gutter={[20, 10]}>
        <Col lg={8} md={12}>
          <Form.Item
            label={t('GLOBAL.CIVIL_STATE')}
            name={'partner_maritalstatus'}
            rules={[{ required: true, message: '' }]}
          >
            <Select style={{ width: '100%' }}>
              {meta?.['partner_maritalstatus']?.map(({ id, name }) => (
                <Option key={`partner_maritalstatus_${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('PROFILE_EXTRA.KM')} name={'km'}>
            <InputNumber
              type="number"
              min={0}
              style={{ width: '100%' }}
              step="any"
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('GLOBAL.TRAVEL_MODE')} name={'transportation'}>
            <Select style={{ width: '100%' }}>
              {meta?.['transportation']?.map(({ id, name }) => (
                <Option key={`transportation_${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('PROFILE_EXTRA.ALIMONY')} name={'alimony'}>
            <InputNumber
              type="number"
              min={0}
              style={{ width: '100%' }}
              step="any"
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('PROFILE_EXTRA.DEPENDENT_CHILD')} name={'dependent_child'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('PROFILE_EXTRA.DEPENDENT_CHILD_DISABLED')} name={'dependent_child_disabled'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('PROFILE_EXTRA.DEPENDENT_ADULT_MIN65')} name={'dependent_adult_min65'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('PROFILE_EXTRA.DEPENDENT_ADULT_MIN65_DISABLED')} name={'dependent_adult_min65_disabled'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('PROFILE_EXTRA.DEPENDENT_ADULT_PLUS65')} name={'dependent_adult_plus65'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item
            label={t('PROFILE_EXTRA.DEPENDENT_ADULT_PLUS65_DISABLED')}
            name={'dependent_adult_plus65_disabled'}
          >
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('PROFILE_EXTRA.WAGE_SEIZURE')} name={'wage_seizure'} valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default styled(Worker)``;
