import React from 'react';
import { Skeleton } from 'antd';

interface Props {
  baseClassName: string;
  key?: string;
}

const UserShiftLoadingRow: React.FC<Props> = ({ baseClassName }) => {
  return (
    <div className={`${baseClassName}`}>
      <div className="left">
        <Skeleton avatar paragraph={{ rows: 2 }} active />
      </div>
    </div>
  );
};

export default UserShiftLoadingRow;
