import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { IUser } from '@/types/user.model';
import { IBalanceSheetData } from '@/types/user/balance-sheet-data.model';
import { IBalanceSheet } from '@/types/user/balance-sheet.model';
import { MONTHS, isFeatureEnabled } from '@/utils';
import { Button, Input, InputNumber, Popconfirm, Radio, RadioChangeEvent, Table, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EditableCell, EditableRow } from './components/EditableRow';
import ModalBalanceSheetDetails from './components/ModalBalanceSheetDetails';
import { FEATURES } from '@/types/features.model';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
  department?: IDepartment;
  user?: IUser;
}

const BalanceSheet: React.FC<Props> = ({ className, department, user }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [balanceSheets, setBalanceSheets] = useState<IBalanceSheetData | null>(null);
  const [defaultColumns, setDefaultColumns] = useState<any[]>([]);
  const [modalDetailsVisible, setModalDetailsVisible] = useState<boolean>(false);
  const [activeBalanceSheet, setActiveBalanceSheet] = useState<IBalanceSheet | null>(null);
  const [currentModified, setCurrentModified] = useState<number | null>(null);
  const [currentComment, setCurrentComment] = useState<string>('');
  const [displaySave, setDisplaySave] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<IBalanceSheet | null>(null);
  const [type, setType] = useState<'strict' | 'smooth'>(user?.norm ? user.norm : 'strict');
  const {
    state: { features },
  } = useContext(AppContext);

  useEffect(() => {
    if (user?.norm) {
      if (user.norm === type) {
        getBalanceSheet(user.norm);
      } else {
        setType(user?.norm ? user.norm : 'strict');
      }
    }
  }, [user]);

  useEffect(() => {
    if ((currentModified !== null && currentModified !== undefined) || currentComment !== '') {
      setDisplaySave(true);
    } else {
      setDisplaySave(false);
    }
  }, [currentModified, currentComment]);

  useEffect(() => {
    setDefaultColumns([
      {
        title: t('GLOBAL.YEAR'),
        key: 'year',
        className: 'title',
        children: [
          {
            render: (text: string, record: IBalanceSheet) => {
              return record.year.toString();
            },
          },
        ],
      },
      {
        title: t('GLOBAL.MONTH'),
        key: 'month',
        className: 'title',
        children: [
          {
            render: (text: string, record: IBalanceSheet) => {
              return t(`GLOBAL.${MONTHS[record.month - 1]}`);
            },
          },
        ],
      },
      {
        title: 'Base',
        key: 'contract',
        className: 'title',
        children: [
          {
            render: (text: string, record: IBalanceSheet) => {
              const hours = record.contract.split('/')[0];
              const days = record.contract.split('/')[1];
              return `${hours}${t('GLOBAL.HOURS_SHORT')}/${days}${t('GLOBAL.HOURS_SHORT')}`;
            },
          },
        ],
      },
      {
        title: t('GLOBAL.PLANNED'),
        key: 'planned',
        className: 'title title-planned',
        children: [
          {
            title: t('GLOBAL.SHIFTS'),
            key: 'shifts_count',
            className: 'small cell-planned',
            render: (text: string, record: IBalanceSheet) => {
              return record.shifts_count;
            },
          },
          {
            title: t('USERS.BALANCE_SHEET.COLUMNS.NORM'),
            key: 'planned_norm',
            className: 'small cell-planned',
            render: (text: string, record: IBalanceSheet) => {
              const data = Number(record.planned_norm / 60)
                .toFixed(2)
                .split('.');

              const decimal = Number(Number('0.' + data[1]) * 60);

              return `${data[0]}:${decimal >= 0 && decimal <= 9 ? `0${decimal.toFixed(0)}` : decimal.toFixed(0)}`;
            },
          },
          {
            title: t('GLOBAL.PLANNED'),
            key: 'planned',
            className: 'small cell-planned',
            render: (text: string, record: IBalanceSheet) => {
              const data = Number(record.planned / 60)
                .toFixed(2)
                .split('.');

              const decimal = Number(Number('0.' + data[1]) * 60);

              return `${data[0]}:${decimal >= 0 && decimal <= 9 ? `0${decimal.toFixed(0)}` : decimal.toFixed(0)}`;
            },
          },
        ],
      },
      ...(isFeatureEnabled(features, FEATURES.CLOCKING)
        ? [
            {
              title: t('GLOBAL.CLOCKING'),
              key: 'clocking',
              className: 'title title-clocking',
              children: [
                {
                  title: t('GLOBAL.CLOCKING'),
                  key: 'clocking_count',
                  className: 'small cell-clocking',
                  render: (text: string, record: IBalanceSheet) => {
                    return record.clocking_count;
                  },
                },
                {
                  title: t('USERS.BALANCE_SHEET.COLUMNS.NORM'),
                  key: 'clocking_norm',
                  className: 'small cell-clocking',
                  render: (text: string, record: IBalanceSheet) => {
                    const data = Number(record.clocking_norm / 60)
                      .toFixed(2)
                      .split('.');

                    const decimal = Number(Number('0.' + data[1]) * 60);
                    const minute = decimal.toFixed(0);

                    return `${data[0]}:${decimal >= 0 && decimal <= 9 ? `0${minute}` : minute}`;
                  },
                },
                {
                  title: t('USERS.BALANCE_SHEET.COLUMNS.WORKED'),
                  key: 'clocking',
                  className: 'small cell-clocking',
                  render: (text: string, record: IBalanceSheet) => {
                    const data = Number(record.clocking / 60)
                      .toFixed(2)
                      .split('.');

                    const decimal = Number(Number('0.' + data[1]) * 60);
                    const minute = decimal.toFixed(0);

                    return `${data[0]}:${decimal >= 0 && decimal <= 9 ? `0${minute}` : minute}`;
                  },
                },
              ],
            },
          ]
        : []),
      {
        title: t('GLOBAL.COUNTER'),
        key: 'counter',
        className: 'title title-counter',
        children: [
          {
            render: (text: string, record: IBalanceSheet) => {
              const data = Number(record.counter / 60)
                .toFixed(2)
                .split('.');

              const decimal = Number(Number('0.' + data[1]) * 60);
              const minute = decimal.toFixed(0);

              return `${data[0]}:${decimal >= 0 && decimal <= 9 ? `0${minute}` : minute}`;
            },
          },
        ],
      },
      {
        title: t('GLOBAL.BALANCE'),
        key: 'balance',
        className: 'title',

        children: [
          {
            render: (text: string, record: IBalanceSheet) => {
              const data = Number(record.balance / 60)
                .toFixed(2)
                .split('.');

              const decimal = Number(Number('0.' + data[1]) * 60);
              const minute = decimal.toFixed(0);

              return `${data[0]}:${decimal >= 0 && decimal <= 9 ? `0${minute}` : minute}`;
            },
          },
        ],
      },
      {
        title: t('USERS.BALANCE_SHEET.COLUMNS.MODIFIED'),
        key: 'modified',
        dataIndex: 'modified',
        editable: true,
        type: 'input-number',
        width: 150,
        className: 'title',
        children: [
          {
            render: (text: string, record: IBalanceSheet) => {
              return (
                <InputNumber
                  type="number"
                  value={
                    record.modified !== null && record.modified !== undefined
                      ? Number(Number(record?.modified / 60).toFixed(2))
                      : undefined
                  }
                  onChange={(value: number) => onModifiedChange(value, record)}
                />
              );
            },
          },
        ],
      },
      {
        title: t('GLOBAL.COMMENT'),
        key: 'comment',
        dataIndex: 'comment',
        editable: true,
        type: 'input',
        className: 'title',
        width: 200,
        children: [
          {
            width: 200,
            render: (text: string, record: IBalanceSheet) => {
              return (
                <Input
                  defaultValue={record.comment ? record?.comment?.toString() : ''}
                  onChange={(e: any) => onCommentChange(e, record)}
                />
              );
            },
          },
        ],
      },
      {
        width: 50,
        children: [
          {
            render: (text: string, record: IBalanceSheet) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button type="ghost" style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>
                    <Tooltip title={t('GLOBAL.DETAILS')}>
                      <i className="icon-eye" />
                    </Tooltip>
                  </Button>
                  {displaySave && activeRow?.year === record.year && activeRow.month === record.month && (
                    <Button
                      type="primary"
                      style={{ cursor: 'pointer', border: 'none', marginLeft: 5 }}
                      onClick={() => handleSave(record)}
                    >
                      <Tooltip title={t('GLOBAL.SAVE')}>
                        <i className="icon-ok" />
                      </Tooltip>
                    </Button>
                  )}
                  {((record.modified !== null && record.modified !== undefined) ||
                    (record.comment && record.comment !== '')) && (
                    <Popconfirm title={t('GLOBAL.ARE_YOU_SURE?')} onConfirm={() => handleDelete(record)}>
                      <Button
                        type="ghost"
                        style={{ backgroundColor: colors.red, color: '#fff', marginLeft: 5, border: 'none' }}
                      >
                        <Tooltip title={t('GLOBAL.DETAILS')}>
                          <i className="icon-trash-empty" />
                        </Tooltip>
                      </Button>
                    </Popconfirm>
                  )}
                </div>
              );
            },
          },
        ],
      },
    ]);
  }, [balanceSheets, displaySave, currentModified, currentComment, type]);

  useEffect(() => {
    getBalanceSheet(type);
  }, [type]);

  const getBalanceSheet = (norm: 'strict' | 'smooth') => {
    setLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/hr/balance`, {
        params: {
          departmentId: department?.id,
          recordId: user?.recordId,
          norm: user?.norm !== norm ? norm : undefined,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        setLoading(false);
        setBalanceSheets(data);
        if (user?.norm !== norm) {
          user!.norm = norm;
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const onModifiedChange = (value: number, record: IBalanceSheet) => {
    if (value !== null && value !== undefined) {
      setCurrentModified(value);
      setActiveRow(record);
    } else {
      setCurrentModified(null);
      setActiveRow(null);
    }
  };

  const onCommentChange = (e: any, record: IBalanceSheet) => {
    if (e.target.value) {
      setCurrentComment(e.target.value);
      setActiveRow(record);
    } else {
      setCurrentComment('');
      setActiveRow(null);
    }
  };

  const handleSave = (row: IBalanceSheet) => {
    const index = balanceSheets?.months.findIndex((item) => row.year === item.year && row.month === item.month);
    const item = balanceSheets?.months[index!];

    const data = {
      ...row,
      value: currentModified || Number(currentModified) == 0 ? currentModified : item!.modified,
      comment: currentComment ? currentComment : item!.comment,
    };

    if (currentModified || currentComment || Number(currentModified) == 0 || currentComment == '') {
      const cancelTokenSource = axios.CancelToken.source();
      axios
        .post(`${process.env.REACT_APP_API_URL}/v3/hr/balance`, data, {
          params: {
            departmentId: department?.id,
            recordId: user?.recordId,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then(({ data }) => {
          setCurrentModified(null);
          setCurrentComment('');
          setBalanceSheets(data);
        })
        .catch((error) => {
          setCurrentModified(null);
          setCurrentComment('');
          console.error(error);
        });
    }
  };

  const handleDelete = (record: IBalanceSheet) => {
    setLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/hr/balance`, {
        params: {
          departmentId: department?.id,
          recordId: user?.recordId,
          year: record.year,
          month: record.month,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        setCurrentModified(null);
        setCurrentComment('');
        setBalanceSheets(data);
        setLoading(false);
      })
      .catch((error) => {
        setCurrentModified(null);
        setCurrentComment('');
        setLoading(false);
        console.error(error);
      });
  };

  const rowKey = (record: IBalanceSheet) => {
    return `${record.year}_${record.month}`;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const onRowClick = (record: IBalanceSheet) => {
    setModalDetailsVisible(true);
    setActiveBalanceSheet(record);
  };

  const onTypeChange = (e: RadioChangeEvent) => {
    setType(e.target.value);
  };

  return (
    <div className={className}>
      <header className="header">
        <div className="left">
          <h4>{t('USERS.BALANCE_SHEET.TITLE')}</h4>
          <Radio.Group onChange={onTypeChange} value={type ? type : user?.norm}>
            <Radio value="strict">
              {t('USERS.BALANCE_SHEET.STRICT_NORM')}
              <Tooltip overlay={<span>{t('USERS.BALANCE_SHEET.STRICT_TOOLTIP')}</span>}>
                <i className="icon-info-circled" />
              </Tooltip>
            </Radio>
            <Radio value="smooth">
              {t('USERS.BALANCE_SHEET.SMOOTH_NORM')}
              <Tooltip overlay={<span>{t('USERS.BALANCE_SHEET.SMOOTH_TOOLTIP')}</span>}>
                <i className="icon-info-circled" />
              </Tooltip>
            </Radio>
          </Radio.Group>
        </div>
        <div className="right">
          <span dangerouslySetInnerHTML={{ __html: t('USERS.BALANCE_SHEET.EXPLANATION') }}></span>
        </div>
      </header>
      {!loading && (
        <Table
          components={components}
          loading={loading}
          dataSource={balanceSheets?.months}
          columns={defaultColumns}
          rowKey={rowKey}
          pagination={false}
          rowClassName="editable-row"
        />
      )}
      <ModalBalanceSheetDetails
        activeBalanceSheet={activeBalanceSheet}
        balanceSheets={balanceSheets}
        visible={modalDetailsVisible}
        setVisible={setModalDetailsVisible}
      />
    </div>
  );
};

export default styled(BalanceSheet)`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    min-height: 30px;
    width: '100%';
  }

  .header  {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      margin: 0 !important;
    }
    .left {
      display: flex;
      align-items: center;
      gap: 25px;
      flex: 1;
    }

    .right {
      flex: 1;
      text-align: right;

      span {
        font-size: 12px;
        color: ${colors.grey};
      }
    }
  }

  th.small {
    font-size: 12px;
    color: ${colors.grey};
    padding-top: 5px;
    padding-bottom: 5px;

    ::before {
      display: block;
    }
  }

  th.title {
    text-align: center;
    vertical-align: baseline;

    ::before {
      display: none;
    }
  }

  .ant-table-cell {
    ::before {
      display: none;
    }
  }

  .cell-planned  {
    background-color: #d5d5d533;
  }
  .cell-clocking {
    background-color: #9eddb933;
  }
  .title-planned {
    background-color: #d5d5d566;
  }
  .title-clocking {
    background-color: #9eddb966;
  }
  .title-counter {
    color: ${colors.green};
    font-weight: bold;
  }
`;
