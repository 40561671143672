import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Button, Popconfirm, Table } from 'antd';
import { IQuotaTemplate } from '@/types/insights/quota-template.model';
import { ColumnsType } from 'antd/es/table/interface';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { handleError, isFeatureEnabled } from '@/utils';
import colors from '@/styles/colors';
import { IQuotaTemplateDetail } from '@/types/insights/quota-template-detail.model';
import { FEATURES } from '@/types/features.model';

interface Props {
  className?: string;
}

const TemplatesTable: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, quotaTemplates, loadingQuotaTemplates, sections, features },
    dispatch,
  } = useContext(AppContext);
  const [columns, setColumns] = useState<ColumnsType<IQuotaTemplate>>([]);
  const [loadingMarkDefaultId, setLoadingMarkDefaultId] = useState<string | null>(null);
  const [loadingDeleteId, setLoadingDeleteId] = useState<string | null>(null);
  const history = useHistory();

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        width: '100%',
      },
      {
        key: 'value',
        render: (record: IQuotaTemplate) => {
          const filledSections = [...new Set(record.details.map((detail) => String(detail.section_id)))];
          if (isFeatureEnabled(features, FEATURES.SECTIONS))
            return <span>{`${filledSections.length}/${sections.length}`}</span>;
          return '';
        },
      },
      {
        key: 'extra',
        render: (value, record: IQuotaTemplate) => {
          if (record.default) {
            return <span className="default-badge">{t('GLOBAL.DEFAULT')}</span>;
          }
          return (
            <div className="actions">
              <Button
                type="ghost"
                loading={loadingMarkDefaultId == record.id}
                onClick={(e) => onMarkAsDefault(e, record)}
              >
                {t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.MARK_DEFAULT')}
              </Button>
              <Popconfirm
                title={t('GLOBAL.ARE_YOU_SURE?')}
                onConfirm={(e) => onDeleteTemplate(e, record)}
                onCancel={(e) => e?.stopPropagation()}
              >
                <Button
                  type="primary"
                  danger
                  loading={loadingDeleteId == record.id}
                  onClick={(e) => e.stopPropagation()}
                >
                  <i className="icon-trash-empty" />
                </Button>
              </Popconfirm>
            </div>
          );
        },
      },
    ]);
  }, [quotaTemplates, loadingMarkDefaultId, loadingDeleteId]);

  const onMarkAsDefault = (e: React.MouseEvent<HTMLElement, MouseEvent>, template: IQuotaTemplate) => {
    e.preventDefault();
    e.stopPropagation();
    setLoadingMarkDefaultId(template.id);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/quotas/quota-templates/${template.id}/mark-default`,
        {},
        {
          params: {
            departmentId: activeDepartment?.id,
          },
        },
      )
      .then(({ data }) => {
        dispatch({
          type: 'SET_QUOTA_TEMPLATES',
          payload: quotaTemplates
            .map((temp) => (temp.id == template.id ? { ...template, default: true } : { ...temp, default: false }))
            .filter((item: IQuotaTemplate) =>
              !isFeatureEnabled(features, FEATURES.SECTIONS)
                ? item.details.every((detail: IQuotaTemplateDetail) => detail.section_id === null)
                : true,
            )
            .sort((a: IQuotaTemplate, b: IQuotaTemplate) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
        });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoadingMarkDefaultId(null);
      });
  };

  const onDeleteTemplate = (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined, template: IQuotaTemplate) => {
    if (!e) return;
    e.stopPropagation();
    e.preventDefault();
    setLoadingDeleteId(template.id);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/quotas/quota-templates/${template.id}`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_QUOTA_TEMPLATES',
          payload: quotaTemplates
            .filter((temp) => temp.id !== template.id)
            .filter((item: IQuotaTemplate) =>
              !isFeatureEnabled(features, FEATURES.SECTIONS)
                ? item.details.every((detail: IQuotaTemplateDetail) => detail.section_id === null)
                : true,
            )
            .sort((a: IQuotaTemplate, b: IQuotaTemplate) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
        });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoadingDeleteId(null);
      });
  };

  const onRow = (template: IQuotaTemplate) => {
    return {
      onClick: () => {
        history.push(`/app/settings/insights/quota-templates/${template.id}`);
      },
    };
  };

  return (
    <Table
      className={`${className} row-clickable`}
      dataSource={quotaTemplates}
      columns={columns}
      loading={loadingQuotaTemplates}
      rowKey="id"
      onRow={onRow}
      pagination={false}
      showHeader={false}
    />
  );
};

export default styled(TemplatesTable)`
  .actions {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .default-badge {
    background-color: ${colors.blueLightPastel};
    padding: 2px 10px;
    border-radius: 5px;
    color: #fff;
  }
`;
