import { Button, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ISignature } from '@/types/signature.model';
import { useTranslation } from 'react-i18next';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  signature: ISignature;
  onWantToDeleteSignature: (signatureId: string) => void;
  setActiveSignature: React.Dispatch<React.SetStateAction<Partial<ISignature> | null>>;
}

const TableRowActions: React.FC<Props> = ({ className, signature, onWantToDeleteSignature, setActiveSignature }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <div className={className}>
      <Tooltip title={t('GLOBAL.MODIFY')}>
        <Button shape="circle" className="action" onClick={() => setActiveSignature(signature)}>
          <i className="icon-edit" />
        </Button>
      </Tooltip>
      <Tooltip title={t('GLOBAL.REMOVE')}>
        <Button
          shape="circle"
          className="action"
          onClick={() => {
            onWantToDeleteSignature(signature.id);
          }}
        >
          <i className="icon-trash-empty" style={{ color: colors.red }} />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(TableRowActions)`
  display: 'flex';
  justify-content: center;
`;
