import moment from 'moment';
import React, { useEffect, useState } from 'react';

interface Props {
  intervalHeight: number;
  width: number;
  intervals: string[][];
}

const NowRow: React.FC<Props> = ({ intervalHeight, width, intervals }) => {
  const [currentHours, setCurrentHours] = useState<number>(moment().local().hours());
  const [currentMinutes, setCurrentMinutes] = useState<number>(moment().local().minutes());

  useEffect(() => {
    const interval = setInterval(() => {
      const hours = moment().local().hours();
      const minutes = moment().local().minutes();
      setCurrentHours(hours);
      setCurrentMinutes(minutes);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const interval = intervals.findIndex((interval) => Number(interval[0].split(':')[0]) == currentHours) || currentHours;

  return (
    <span
      style={{
        position: 'absolute',
        zIndex: 99,
        width: width + 105,
        height: 2,
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2300A651FF' stroke-width='6' stroke-dasharray='0%2c13' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        top: interval * intervalHeight + (intervalHeight / 60) * currentMinutes,
        left: -50,
      }}
    ></span>
  );
};

export default React.memo(NowRow);
