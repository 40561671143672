import { IUser } from '@/types/user.model';
import React from 'react';
import { Avatar } from 'antd';
import colors from '@/styles/colors';
import styled from 'styled-components';
import { IShift } from '@/types/shift.model';

interface Props {
  className?: string;
  user: IUser;
  shift?: IShift;
  page?: string;
  onClick?: () => void;
}

const DisplayNameColumn: React.FC<Props> = ({ className, user, shift, page, onClick }) => (
  <div className={`${className} ${onClick ? 'clickable' : ''}`} onClick={onClick ? onClick : undefined}>
    {page ? (
      <>
        {page === 'clocking-validation' && (
          <>
            {shift && (
              <Avatar src={user?.picture} size={20} style={{ marginRight: 10 }}>
                {user?.initials}
              </Avatar>
            )}
            {!shift && <i className="icon-attention" style={{ color: colors.red }} />}
          </>
        )}
      </>
    ) : (
      <Avatar src={user?.picture} size={20} style={{ marginRight: 10 }}>
        {user?.initials}
      </Avatar>
    )}
    <span>{user?.displayName}</span>
  </div>
);

export default styled(DisplayNameColumn)`
  display: flex;
  .clickable {
    color: ${colors.green};
    cursor: pointer;
  }
  .ant-avatar {
    flex-shrink: 0;
  }
  span:not(.ant-avatar) {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
`;
