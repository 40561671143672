import TableView from '@/layouts/TableView';
import { Breadcrumb, Button, Checkbox, Input, message, Modal, Select, Space, Switch, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import AppContext from '@/pages/app/context';
import Header from './components/Header';
import {
  IReportTemplate,
  IReportTemplateColumn,
  IReportTemplateColumnField,
  IReportTemplateField,
} from '@/types/report-template.model';
import styled from 'styled-components';
import ReportTable from './components/ReportTable';
import DrawerTimesheetReport from '../../../components/drawers/TimesheetReport';
import { AlignType } from '@/types/alignType.model';
import { useHistory } from 'react-router-dom';
import { SelectValue } from 'antd/es/select';
import { handleError, isFeatureEnabled } from '@/utils';
import { FEATURES } from '@/types/features.model';

interface Props {
  className?: string;
}

const ManageCustomReports: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartmentId, activeDepartment, tasks, features },
    dispatch,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [titleEditable, setTitleEditable] = useState<boolean>(false);
  const [duplicateLoading, setDuplicateLoading] = useState<boolean>(false);
  const [reports, setReports] = useState<IReportTemplate[]>([]);
  const [fields, setFields] = useState<IReportTemplateField[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [activeReport, setActiveReport] = useState<IReportTemplate | null>(null);
  const [title, setTitle] = useState<string>('');
  const [reportColumns, setReportColumns] = useState<IReportTemplateColumn[]>([]);

  const getReport = (id: string) => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${id}`, {
          params: {
            departmentId: activeDepartmentId,
          },
        })
        .then(({ data }) => {
          setActiveReport(data);
          setReportColumns(data.columns);
        })
        .catch((err) => {
          message.error(Object.values(err.response.data.errors).flat(1)[0]);
        });
    }
  };

  useEffect(() => {
    if (location.pathname) {
      const elements = location.pathname.split('/');
      const last = elements[elements.length - 1];
      if (isNaN(Number(last)) && last !== 'new') {
        setActiveReport(null);
      } else if (!isNaN(Number(last))) {
        const found = reports.find((report) => report.id == last);
        if (found) {
          getReport(found.id);
        }
      }
    }
  }, [location.pathname, reports]);

  useEffect(() => {
    if (activeReport) {
      setTitle(activeReport.name);
      if (location.pathname) {
        const elements = location.pathname.split('/');
        const last = elements[elements.length - 1];
        if (isNaN(Number(last))) {
          history.push(`/app/settings/general/custom-reports/${activeReport.id}`);
        }
      }
    }
  }, [activeReport]);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      },
      {
        title: t('GLOBAL.ACTIVE'),
        dataIndex: 'active',
        key: 'active',
        width: '10%',
        render: (text: string, record: IReportTemplate) => {
          return <Checkbox checked={record.active} disabled />;
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        width: '30%',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IReportTemplate) => (
          <Space size="middle" className={className}>
            <button className="green" onClick={() => setActiveReport(record)}>
              {t('GLOBAL.EDIT')}
            </button>
            <button className="gray" onClick={() => onDuplicate(record)}>
              {t('GLOBAL.DUPLICATE')}
            </button>
            <button className="red" onClick={() => onDeleteReport(record)}>
              {t('GLOBAL.REMOVE')}
            </button>
          </Space>
        ),
      },
    ]);
  }, [activeReport, reports]);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();

    if (mounted) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template`, {
          params: {
            departmentId: activeDepartmentId,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then(({ data }) => {
          setReports(data.templates);
          const path = window.location.pathname;
          const options = path.split('/');
          const id = options[options.length - 1];

          if (!activeReport && id) {
            setActiveReport(data.templates.find((template: any) => template.id == id));
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          handleError(err);
        });

      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/fields`, {
          params: {
            departmentId: activeDepartmentId,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then(({ data }) => {
          setFields(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          handleError(err);
        });
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  const onCreate = () => {
    setDrawerVisible(true);
  };

  const onEditTitle = () => {
    setTitleEditable(true);
  };

  const onSaveTitle = () => {
    if (activeReport) {
      setTitleEditable(false);
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${activeReport.id}`,
          {
            ...activeReport,
            name: title,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          setActiveReport(data);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  };

  const onDuplicate = (report: IReportTemplate) => {
    Modal.confirm({
      title: t('GLOBAL.CONFIRM'),
      icon: null,
      content: t('REPORT_TEMPLATES.DUPLICATE_MODAL_CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.DUPLICATE'),
      onOk: () => {
        setDuplicateLoading(true);
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${report.id}/duplicate`,
            {},
            {
              params: {
                departmentId: activeDepartmentId,
              },
            },
          )
          .then(({ data }) => {
            setActiveReport(data);
            setReports([...reports, data]);
          })
          .catch((err) => {
            handleError(err);
          });
      },
      onCancel: () => {},
    });
  };

  const onDeleteReport = (report: IReportTemplate) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${report.id}`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setReports(reports.filter((rep) => rep.id !== report.id));
        setActiveReport(null);
        setReportColumns([]);
        message.success(t('REPORT_TEMPLATES.DELETED'));
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const onChangeActive = (checked: boolean) => {
    if (activeReport) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${activeReport.id}`,
          {
            ...activeReport,
            active: checked,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          setActiveReport(data);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  };

  const onChangeCosts = (checked: boolean) => {
    if (activeReport) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${activeReport.id}`,
          {
            ...activeReport,
            includeCosts: checked,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          setActiveReport(data);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  };

  const onChangeTasks = (checked: boolean) => {
    if (activeReport) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${activeReport.id}`,
          {
            ...activeReport,
            includeTasks: checked,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          setActiveReport(data);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  };

  const onChangeLeaves = (checked: boolean) => {
    if (activeReport) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${activeReport.id}`,
          {
            ...activeReport,
            includeLeaves: checked,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          setActiveReport(data);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  };

  const onChangeMethod = (value: SelectValue) => {
    if (activeReport) {
      const calculationMethod = value !== 'null' ? value : null;
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${activeReport.id}`,
          {
            ...activeReport,
            columns: reportColumns,
            calculationMethod,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          const name =
            calculationMethod == 'shift'
              ? t('GLOBAL.PLANNED_HOURS')
              : calculationMethod == 'clocking'
              ? t('GLOBAL.CLOCKING_HOURS')
              : t('GLOBAL.DEFAULT');
          setActiveReport(data);
          message.success(t('REPORT_TEMPLATES.CALCULATION_METHOD_CHANGED', { calculationMethod: name }));
        })
        .catch((err) => {
          console.log(err);
          message.error(Object.values(err.response.data.errors).flat(1)[0]);
        });
    }
  };

  const onBack = () => {
    setActiveReport(null);
    history.push(`/app/settings/general/custom-reports`);
  };

  return (
    <div className={className}>
      <h2>
        {activeReport
          ? activeReport.id
            ? t('REPORT_TEMPLATES.EDIT', { name: activeReport.name })
            : t('REPORT_TEMPLATES.CREATE')
          : t('SETTINGS.GENERAL.REPORT_TEMPLATES.TITLE')}
      </h2>
      {activeReport && (
        <Breadcrumb separator=">">
          <Breadcrumb.Item onClick={onBack}>{t('REPORT_TEMPLATES.TITLE')}</Breadcrumb.Item>
          <Breadcrumb.Item>{activeReport.id ? activeReport.name : t('REPORT_TEMPLATES.CREATE')}</Breadcrumb.Item>
        </Breadcrumb>
      )}
      {activeReport && (
        <div style={{ margin: '25px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: 10 }}>
            {titleEditable ? <Input value={title} onChange={onChangeTitle} /> : <h2>{activeReport.name}</h2>}
            {titleEditable ? (
              <Button htmlType="button" type="link" onClick={onSaveTitle}>
                <i className="icon-ok" />
              </Button>
            ) : (
              <Button htmlType="button" type="link" onClick={onEditTitle}>
                <i className="icon-edit" />
              </Button>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            <div>
              <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'space-between' }}>
                <span>{t('GLOBAL.ACTIVE')}</span>
                <Switch checked={activeReport.active} onChange={onChangeActive} />
              </div>
            </div>
            {activeDepartment?.params?.enable_clocking_costs && (
              <div>
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'space-between' }}>
                  <span>{t('REPORT_TEMPLATES.INCLUDE_COSTS')}</span>
                  <Switch checked={activeReport.includeCosts} onChange={onChangeCosts} />
                </div>
              </div>
            )}
            {isFeatureEnabled(features, FEATURES.TASKS) && tasks && tasks.length > 0 && (
              <div>
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'space-between' }}>
                  <span>{t('REPORT_TEMPLATES.INCLUDE_TASKS')}</span>
                  <Switch checked={activeReport.includeTasks} onChange={onChangeTasks} />
                </div>
              </div>
            )}
            <div>
              <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'space-between' }}>
                <span>{t('REPORT_TEMPLATES.INCLUDE_LEAVES')}</span>
                <Switch checked={activeReport.includeLeaves} onChange={onChangeLeaves} />
              </div>
            </div>
            <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
              <span>{t('REPORT_TEMPLATES.CALCULATION_METHOD')}</span>
              <Select
                defaultValue={activeReport.calculationMethod ? activeReport.calculationMethod : 'null'}
                style={{ width: 150 }}
                onChange={onChangeMethod}
              >
                <Select.Option value="null">{t('GLOBAL.DEFAULT')}</Select.Option>
                <Select.Option value="shift">{t('GLOBAL.PLANNED_HOURS')}</Select.Option>
                <Select.Option value="clocking">{t('GLOBAL.CLOCKING_HOURS')}</Select.Option>
              </Select>
            </div>
          </div>
        </div>
      )}
      <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10, marginTop: 25 }}>
        {!activeReport ? (
          <TableView>
            <Header onCreate={onCreate} />
            <Table
              loading={loading}
              className={className}
              dataSource={reports}
              columns={columns}
              rowKey="id"
              pagination={false}
            />
          </TableView>
        ) : (
          <TableView>
            <ReportTable
              reports={reports}
              setReports={setReports}
              fields={fields}
              activeReport={activeReport}
              setActiveReport={setActiveReport}
              reportColumns={reportColumns}
              setReportColumns={setReportColumns}
            />
          </TableView>
        )}
        <DrawerTimesheetReport
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          reports={reports}
          setReports={setReports}
          setActiveReport={setActiveReport}
          reportColumns={reportColumns}
          setReportColumns={setReportColumns}
        />
      </div>
    </div>
  );
};

export default styled(ManageCustomReports)`
  .ant-breadcrumb-link {
    cursor: pointer;
  }
`;
