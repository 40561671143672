import AppContext from '@/pages/app/context';
import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import HeaderSmallDrawer from './drawers/HeaderSmallDrawer';

interface Props {
  className?: string;
}

const HeaderSmall: React.FC<Props> = ({ className }) => {
  const { state, dispatch } = useContext(AppContext);
  const { activeDepartment } = state;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <HeaderSmallDrawer visible={drawerOpen} toggleDrawer={toggleDrawer} />
      <div className={className}>
        <NavLink to="/app/dashboard" className="logo">
          {activeDepartment?.partner?.icon ? (
            <img className="logo-icon" src={activeDepartment?.partner?.icon} alt={activeDepartment?.partner?.name} />
          ) : (
            <img className="logo-icon" src={process.env.PUBLIC_URL + '/shyfter-logo.svg'} alt="Shyfter" />
          )}
        </NavLink>
        <button className="hamburger" onClick={toggleDrawer}>
          <i className="icon-menu" />
        </button>
      </div>
    </>
  );
};

export default styled(HeaderSmall)`
  top: 0;
  left: 0;
  position: fixed;
  height: 50px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .logo {
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100vw;
    padding-left: 15px;

    img {
      height: 22px;
    }
  }

  .ant-drawer-header-title {
    flex: 1;

    .ant-drawer-title {
      flex: 1;
    }
  }

  .hamburger {
    padding: 0;
  }
`;
