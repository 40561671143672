import { IDepartment } from '@/types/department.model';
import { Checkbox, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IResponse } from '.';

const { Option } = Select;

interface Props {
  className?: string;
  department?: IDepartment;
  form: FormInstance;
  meta?: IResponse['meta'];
}

const WageGap: React.FC<Props> = ({ className, form, meta }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <div className={className}>
      <Row gutter={[20, 10]}>
        <Col lg={8} md={12}>
          <Form.Item name={'function_level'} label={t('USERS.USER.HR.FUNCTION_LEVEL')}>
            <Select style={{ width: '100%' }}>
              {meta?.['function_level']?.map(({ id, name }) => (
                <Option key={`function_level_${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item name={'type274_tax_exemption'} valuePropName="checked">
            <Checkbox>{t('USERS.USER.HR.TYPE274_TAX_EXEMPTION')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default styled(WageGap)``;
