import { Button, FormInstance, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  form: FormInstance<any>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setApproveFreeClockingUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setFormHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalConfirmAutoApprove: React.FC<Props> = ({
  form,
  visible,
  setVisible,
  setApproveFreeClockingUpdate,
  setFormHasChanged,
}) => {
  const { t } = useTranslation();
  const onCancel = () => {
    setVisible(false);
    setFormHasChanged(false);
    form.setFieldsValue({
      clocking: {
        approve_freeclockin: false,
      },
    });
    setApproveFreeClockingUpdate(false);
  };

  const onOk = () => {
    setApproveFreeClockingUpdate(true);
    setVisible(false);
  };

  return (
    <Modal
      className="modal-danger"
      maskClosable={false}
      visible={visible}
      okText="Je confirm"
      cancelText="non"
      title={t('GLOBAL.WARNING')}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" onClick={onOk} style={{ backgroundColor: 'green', borderColor: 'green' }}>
          {t('GLOBAL.AGREE')}
        </Button>,
      ]}
    >
      {t('SETTINGS.ACCOUNT.CLOCKING.AUTO_APPROVE_WARNING_TEXT')}
    </Modal>
  );
};

export default ModalConfirmAutoApprove;
