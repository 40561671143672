import React, { Dispatch, useContext } from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';
import Colors from '@/styles/colors';
import { IWidget, WIDGETS } from '@/types/widget.model';
import { ActionType } from '../redux/actions';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { isFeatureEnabled } from '@/utils';
import { FEATURES } from '@/types/features.model';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
  widgets: IWidget[];
  departmentId?: string;
}

const HeaderOptionDetails: React.FC<Props> = ({ className, widgets, departmentId }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { features },
  } = useContext(AppContext);
  const dashboardDispatch: Dispatch<ActionType> = useDispatch();
  const widgetsVisible = [
    { id: WIDGETS.TURNOVER, visible: isFeatureEnabled(features, FEATURES.PRODUCTIVITY) },
    { id: WIDGETS.DIMONA, visible: isFeatureEnabled(features, FEATURES.DIMONA) },
    { id: WIDGETS.SCHEDULE_TODAY, visible: isFeatureEnabled(features, FEATURES.SCHEDULE) },
    { id: WIDGETS.SCHEDULE_TOMORROW, visible: isFeatureEnabled(features, FEATURES.SCHEDULE) },
    { id: WIDGETS.OPENSHIFTS, visible: isFeatureEnabled(features, FEATURES.FREESHIFTS) },
    { id: WIDGETS.USER_TYPE_GRAPH, visible: true },
    { id: WIDGETS.SHIFTS_PERIOD, visible: isFeatureEnabled(features, FEATURES.SCHEDULE) },
    { id: WIDGETS.SHIFTS_CHECK, visible: isFeatureEnabled(features, FEATURES.SCHEDULE) },
    { id: WIDGETS.SHIFTS_TO_APPROVE, visible: isFeatureEnabled(features, FEATURES.SCHEDULE) },
    { id: WIDGETS.CLOCKINGS, visible: isFeatureEnabled(features, FEATURES.CLOCKING) },
    { id: WIDGETS.GEOLOC, visible: true },
    { id: WIDGETS.DAYOFF, visible: isFeatureEnabled(features, FEATURES.LEAVE_MANAGEMENT) },
    { id: WIDGETS.WEATHER, visible: true },
  ];
  return (
    <div className={className}>
      {widgets.map((widget, index) => {
        const found = widgetsVisible.find((w) => w.id == widget.id);
        if (!found || !found.visible) return;

        return (
          <div className="row" key={`switch_widget_${widget.id}`}>
            <span>{t(`DASHBOARD.WIDGETS.${widget.name === 'WEATHER' ? 'WEATHER_TITLE' : widget.name}`)}</span>
            <Switch
              size="small"
              checked={widget.visible}
              onClick={() => {
                dashboardDispatch({
                  type: 'SET_WIDGET_VISIBILITY',
                  payload: {
                    index,
                    visibility: !widget.visible,
                  },
                });
                Axios.patch(`${process.env.REACT_APP_API_URL}/v3/widgets`, {
                  departmentId,
                  widgets: widgets.map((w) => {
                    return {
                      id: w.id,
                      position: w.position,
                      visible: widget.id === w.id ? !w.visible : w.visible,
                    };
                  }),
                });
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const HeaderOptionDetailsStyled = styled(HeaderOptionDetails)`
  width: 230px;
  color: ${Colors.grey};

  .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:nth-of-type(n + 2) {
      padding-top: 5px;
    }
  }
`;

export default HeaderOptionDetailsStyled;
