import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  activeApp: IMarketplaceApp | null;
}

const Content: React.FC<Props> = ({ className, activeApp }) => {
  return (
    <div className={className}>
      {activeApp?.full_description && (
        <>
          {activeApp?.full_description.split('\n\n').map((part) => (
            <p>{part}</p>
          ))}
        </>
      )}
      {activeApp?.video && <iframe width="100%" height="350" src={activeApp.video}></iframe>}
      {activeApp?.images &&
        activeApp?.images.length > 0 &&
        activeApp?.images.map((image, index) => {
          if (image.trim() == '') return;
          return <img className="app-image" src={image} alt={`${activeApp.company}_image_${index}`} />;
        })}
    </div>
  );
};

export default styled(Content)`
  flex: 3;
  padding: 50px;
  padding-top: 15px;

  .title {
    font-weight: bold;
  }

  .app-image {
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin-top: 15px;
  }
`;
