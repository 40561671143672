import colors from '@/styles/colors';
import { Button, Modal, Select, Spin, Form, Divider } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import AuthContext from '@/context';
import { setToken } from '@/actions';
import { store as manageStore } from '../hours/manage/redux/store';
import { store as dashboardStore } from '../dashboard/redux/store';
import ModalResetTrial from './ModalResetTrial';

const { Option } = Select;
interface Props {
  className?: string;
  visible: boolean;
}

const ModalTrialOver: React.FC<Props> = ({ className, visible }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { activeDepartmentId, departments, loadingDepartments },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const {
    dispatch: authContextDispatch,
    state: { impersonate },
  } = useContext(AuthContext);
  const [payementInfo, setPayementInfo] = useState<{
    pricePerUser: number;
    totalUsers: number;
    currency: string;
  } | null>(null);
  const [loadingPayementInfo, setLoadingPayementInfo] = useState<boolean>(false);
  const [reseting, _] = useState<boolean>(false);
  const [showModalResetTrial, setShowModalResetTrial] = useState<boolean>(false);
  const history = useHistory();

  const myDepartments = departments?.filter((department) => department.role !== 'USER');

  useEffect(() => {
    if (visible) {
      setLoadingPayementInfo(true);
      setShowModalResetTrial(false);
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/operations/payment-info`, {
          params: {
            departmentId: activeDepartmentId,
          },
        })
        .then(({ data }) => {
          setPayementInfo({
            ...data,
          });
          setLoadingPayementInfo(false);
        });
    }
  }, [visible]);

  const onSubscribe = () => {
    history.push('/app/settings/manage');
    onCheckout();
  };

  const onCheckout = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/update-method`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        location.href = data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDepartmentChange = (value: string) => {
    if (value && activeDepartmentId !== value) {
      const department = departments?.find((x) => x.id === value);
      if (department) {
        appContextDispatch({
          type: 'SET_ACTIVE_DEPARTMENT',
          payload: department?.id,
        });
        authContextDispatch({
          type: 'SET_TRIAL_END_DATE',
          payload: department?.trialEndDate || null,
        });
        authContextDispatch({
          type: 'SET_BLOCKED',
          payload: department?.accountType === 'BLOCKED' ? true : false,
        });
        history.push('/app/dashboard');
      }
    }
  };

  const handleLogout = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (impersonate && accessToken) {
      sessionStorage.removeItem('impersonateToken');
      history.push('/app');
      setToken(authContextDispatch, accessToken);
    } else {
      history.push('/app');
      localStorage.clear();
      manageStore.dispatch({
        type: 'RESET',
      });
      dashboardStore.dispatch({
        type: 'RESET',
      });
      authContextDispatch({
        type: 'RESET',
      });
      appContextDispatch({
        type: 'RESET',
      });
    }
  };

  return (
    <Modal className={className} forceRender={true} closable={false} visible={visible} footer={null}>
      {loadingPayementInfo && <Spin spinning={true} size="large" />}
      {payementInfo && !loadingPayementInfo && (
        <React.Fragment>
          <h1>{t('TRIAL.MODAL_TRIAL_OVER.TITLE')}</h1>
          <Trans
            i18nKey="TRIAL.MODAL_TRIAL_OVER.SUBSCRIBE_TEXT"
            values={{
              totalPrice: new Intl.NumberFormat(language, {
                style: 'currency',
                currency: payementInfo.currency || 'EUR',
              }).format(payementInfo.pricePerUser * payementInfo.totalUsers),
              totalUsers: payementInfo.totalUsers,
              pricePerUser: new Intl.NumberFormat(language, {
                style: 'currency',
                currency: payementInfo.currency || 'EUR',
              }).format(payementInfo.pricePerUser),
            }}
            components={{ bold: <b />, green: <span style={{ color: colors.green }} />, br: <br /> }}
          />
          <Button type="primary" size="large" onClick={onSubscribe} style={{ width: 'auto', margin: '30px 0' }}>
            {t(`TRIAL.MODAL_TRIAL_OVER.SUBSCRIBE_BTN`)}
          </Button>
          <p>{t(`TRIAL.MODAL_TRIAL_OVER.RESET_TEXT`)}</p>
          <Button
            loading={reseting}
            type="primary"
            size="large"
            onClick={() => setShowModalResetTrial(true)}
            style={{ width: 'auto', backgroundColor: '#FFCF97', borderColor: '#FFCF97', color: 'black' }}
          >
            {t(`TRIAL.MODAL_TRIAL_OVER.RESET_BTN`)}
          </Button>
          <br />
          {(true || myDepartments?.length > 1) && (
            <Form layout="vertical">
              <Divider />
              <Form.Item label={t('FORMS.ACCOUNT_PLACEHOLDER')}>
                <Select
                  getPopupContainer={(trigger) => trigger}
                  disabled={loadingDepartments}
                  loading={loadingDepartments}
                  placeholder={t('FORMS.ACCOUNT_PLACEHOLDER')}
                  onChange={handleDepartmentChange}
                >
                  {myDepartments?.map((department) => {
                    const { id, company } = department;
                    return (
                      <Option key={`dept_${id}`} value={`${id}`}>
                        {company}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Button danger size="large" style={{ width: '100%' }} onClick={handleLogout}>
                {t('GLOBAL.LOGOUT')}
              </Button>
            </Form>
          )}
          <ModalResetTrial
            visible={showModalResetTrial}
            departmentId={activeDepartmentId}
            onClose={() => setShowModalResetTrial(false)}
          />
        </React.Fragment>
      )}
    </Modal>
  );
};

export default styled(ModalTrialOver)`
  .ant-modal-body {
    padding-top: 50px;
    text-align: center;
    h1 {
      font-weight: 600;
      margin-bottom: 20px;
    }
  }
`;
