import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Button } from 'antd';
import colors from '@/styles/colors';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import { capitalize } from '@/utils';

interface Props {
  className?: string;
  app: IMarketplaceApp;
  setActiveApp: React.Dispatch<React.SetStateAction<IMarketplaceApp | null>>;
}

const SuggestedApp: React.FC<Props> = ({ className, app, setActiveApp }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);

  const onClick = () => {
    setActiveApp(app);
  };

  return (
    <div className={className} onClick={onClick}>
      <div className="top">
        <span className="title">{app.title}</span>
        <span className="price">
          <i className="icon icon-money" />
          <span className="app-top-icon-text">
            {app.price_type !== 'FREE' && <>{app.price}&euro;</>}
            {app.price_type !== 'ONETIME' && app.price_type !== 'FREE' ? '/' : ' '}
            {app.price_type == 'FREE'
              ? `${capitalize(t(`MARKETPLACE.PRICE_TYPES.FREE`))}`
              : t(`MARKETPLACE.PRICE_TYPES.${app.price_type}`).toLowerCase()}
          </span>
        </span>
      </div>
      <span className="description">{app.description}</span>
      <Button className="more-btn" type="primary">
        {t('GLOBAL.READ_MORE')}
      </Button>
    </div>
  );
};

export default styled(SuggestedApp)`
  min-width: 240px;
  max-width: 240px;
  border: 1px solid #efefef;
  padding: 15px;
  border-radius: 10px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;

    .title {
      font-size: 16px;
      color: #000;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin: 15px 0;
      margin-top: 0;
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 10px;
      color: ${colors.grey};
    }
  }

  .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin-top: 5px;
    margin-bottom: 15px;
    flex: 1;
  }

  .more-btn {
    width: 100%;
    background-color: ${colors.blueLightPastel};
    border-color: ${colors.blueLightPastel};
  }
`;
