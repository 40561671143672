import AppContext from '@/pages/app/context';
import { handleError } from '@/utils';
import { Menu } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InitialStateType } from '../../redux/store';

interface Props {
  className?: string;
  onClick: () => void;
}

const PrintSchedules: React.FC<Props> = ({ className, onClick }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, activeSection, tasks, sections, loadingWeeklyViews, weeklyViews },
  } = useContext(AppContext);
  const { picker, startDate, hours, endDate, shiftsByUsers } = useSelector(
    ({ picker, startDate, endDate, hours, shiftsByUsers }: InitialStateType) => ({
      picker,
      startDate,
      endDate,
      hours,
      shiftsByUsers,
    }),
  );

  const onPrintDaySchedule = (key: any) => {
    setPrinting(true);
    if (key === 'PDF') {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/shifts/print`, {
          params: {
            departmentId: activeDepartment?.id,
            sectionId: activeSection,
            day: startDate.format('YYYY-MM-DD'),
            picker: 'day',
          },
        })
        .then(({ data }) => {
          setPrinting(false);
          const WINDOW = window.open('', '', '_blank');
          WINDOW?.document.write(data);
        })
        .catch((error) => {
          console.log(error);
          setPrinting(false);
        });
    } else {
      // axios({
      //   method: 'GET',
      //   url: `${process.env.REACT_APP_API_URL}/v3/shifts/export`,
      //   responseType: 'arraybuffer',
      //   params: {
      //     departmentId: activeDepartment?.id,
      //     sectionId: activeSection,
      //     startDate: startDate.unix(),
      //     endDate: endDate.unix(),
      //     picker,
      //     print: true,
      //   },
      // })
      //   .then((response) => {
      //     const file = new Blob([response.data], {
      //       type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //     });
      //     const fileURL = URL.createObjectURL(file);
      //     if (fileURL) {
      //       window.open(fileURL);
      //     }
      //     setPrinting(false);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     setPrinting(false);
      //   });

      const plannedCounts: { hour: string; value: string }[] = [];
      const actualNumbersElements = document.getElementsByClassName('total-planned-actualNumber');
      for (const actualNumber of actualNumbersElements) {
        const hour = actualNumber.className.split('hour-string-')[1];
        plannedCounts.push({ hour, value: actualNumber.innerHTML });
      }

      const objectiveCounts: { hour: string; value: string }[] = [];
      const objectivesElements = document.getElementsByClassName('total-planned-objective');
      for (const obejctiveCount of objectivesElements) {
        const hour = obejctiveCount.className.split('hour-string-')[1];
        objectiveCounts.push({ hour, value: obejctiveCount.innerHTML });
      }

      const insights: { hour: string; value: string }[] = [];
      const insightsElements = document.getElementsByClassName('day-insights');
      for (const insight of insightsElements) {
        const hour = insight.className.split('hour-string-')[1];
        insights.push({ hour, value: insight.innerHTML.slice(0, -1) });
      }

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/shifts/day-export`,
          {
            date: startDate.format('YYYY-MM-DD'),
            hours: hours.map((hour) => {
              const value = moment(hour.date * 1000).format('HH:mm');
              const insight = insights.find((insight) => insight.hour == value);
              const plannedCount = plannedCounts.find((plannedCount) => plannedCount.hour == value);
              const objectiveCount = objectiveCounts.find((objectiveCount) => objectiveCount.hour == value);
              return {
                value,
                timestamp: hour.date,
                insight: insight ? insight.value : null,
                count: plannedCount ? plannedCount.value : null,
                objective: objectiveCount ? objectiveCount.value : null,
              };
            }),
            users: shiftsByUsers
              .filter(
                (shiftByUser) =>
                  shiftByUser.user.recordId !== null &&
                  shiftByUser.shifts.filter(
                    (shift) => moment(shift.startDate).isBetween(startDate, endDate) && shift.shyftType == 1,
                  ).length > 0,
              )
              .map((shiftByUser) => {
                return {
                  ...shiftByUser,
                  shifts: shiftByUser.shifts.filter(
                    (shift) => moment(shift.startDate).isBetween(startDate, endDate) && shift.shyftType == 1,
                  ),
                };
              }),
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
            responseType: 'arraybuffer',
          },
        )
        .then(({ data }) => {
          const file = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          const fileURL = URL.createObjectURL(file);
          if (fileURL) {
            const a = document.createElement('a');
            a.href = fileURL;
            a.download = `${activeDepartment?.company}-${startDate.format(
              'YYYY-MM-DD',
            )}-S${startDate.isoWeek()}-${moment().unix()}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
        .catch((err) => handleError(err));
    }
    onClick();
  };
  const [printing, setPrinting] = useState<boolean>(false);

  const handleGenerateWeekPdf = () => {
    if (picker == 'day') {
      onPrintDaySchedule('XLS');
      return;
    }
    const content = document.getElementsByClassName('app-hours-manage-container')[0].innerHTML;
    document.body.innerHTML = content;
    window.print();
    location.reload();
  };

  // if (picker == 'day') {
  //   return (
  //     <Menu.Item className={`${className} day`}>
  //       <Menu.SubMenu
  //         title={
  //           <>
  //             <i className="icon-print" /> {t('SCHEDULE.PRINT_SCHEDULE')}
  //           </>
  //         }
  //       >
  //         <Menu.Item key="PDF" onClick={() => onPrintDaySchedule('PDF')}>
  //           PDF
  //         </Menu.Item>
  //         <Menu.Item key="XLS" onClick={() => onPrintDaySchedule('XLS')}>
  //           XLS
  //         </Menu.Item>
  //       </Menu.SubMenu>
  //     </Menu.Item>
  //   );
  // }

  return (
    <Menu.Item className={className} onClick={handleGenerateWeekPdf}>
      <i className="icon-print" /> {t('SCHEDULE.PRINT_SCHEDULE')}
    </Menu.Item>
  );
};

export default styled(PrintSchedules)`
  margin: 0 5px;
  &:hover {
    background-color: #efefef !important;
    border-radius: 8px;
  }

  &.day {
    .ant-dropdown-menu-submenu-title {
      padding: 0 !important;
      &:hover {
        background-color: #efefef !important;
      }
    }
  }
`;
