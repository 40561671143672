import React from 'react';
import { Input, Select, Space, Switch, Tooltip } from 'antd';
import { IUserStatus } from '@/types/user-status.model';
import { useTranslation } from 'react-i18next';

const Option = Select.Option;

interface Props {
  className?: string;
  disabled: boolean;
  statuses: IUserStatus[];
  setActiveStatus: React.Dispatch<React.SetStateAction<string | null>>;
  onSearchChange?: (e: React.ChangeEvent) => void;
  onlyAnomalies: boolean;
  setOnlyAnomalies: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<Props> = ({
  className,
  onSearchChange,
  disabled,
  statuses,
  setActiveStatus,
  onlyAnomalies,
  setOnlyAnomalies,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <header className={className}>
      <div className="left" style={{ display: 'flex', alignItems: 'center' }}>
        <h2 style={{ marginLeft: 0, marginRight: 15 }}>{t('SCHEDULE.CLOCKING_ANOMALY.TITLE')}</h2>
        <Tooltip title={t('SCHEDULE.CLOCKING_VERIFICATION.SHOW_ONLY_ANOMALIES')}>
          <Switch checked={onlyAnomalies} onChange={() => setOnlyAnomalies(!onlyAnomalies)} />
        </Tooltip>
      </div>
      <div className="right">
        <Space>
          <Input
            disabled={disabled}
            allowClear
            placeholder={t('GLOBAL.SEARCH')}
            suffix={<i className="icon-search"></i>}
            width={190}
            onChange={onSearchChange}
          />
          <Select
            placeholder={t('GLOBAL.USER_CATEGORY_SHORT')}
            allowClear
            className="select-section"
            getPopupContainer={(trigger) => trigger.parentNode}
            disabled={disabled}
            onChange={(value: any) => {
              setActiveStatus(value || null);
            }}
          >
            {statuses.map((status) => (
              <Option key={`status_${status.id}`} value={status.id!}>
                {status.name}
              </Option>
            ))}
          </Select>
        </Space>
      </div>
    </header>
  );
};

export default Header;
