import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Button, Checkbox, Drawer, Input, message } from 'antd';
import { getWindowSize, handleError } from '@/utils';
import { IUser } from '@/types/user.model';
import colors from '@/styles/colors';
import axios from 'axios';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DrawerBatchUserInfos: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, users },
    dispatch,
  } = useContext(AppContext);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const activeUsers = users.filter((user) => user.active);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>(activeUsers);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!users) return;
    setFilteredUsers(users);
    setSelectedUserIds(users.map((user) => user.recordId!));
  }, [users]);

  const onClose = () => {
    setVisible(false);
  };

  const onFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredUsers(activeUsers.filter((user) => user.displayName?.toLowerCase().includes(e.target.value)));
  };

  const onCheck = (recordId: string) => {
    if (selectedUserIds.includes(recordId)) {
      setSelectedUserIds(selectedUserIds.filter((id) => id !== recordId));
    } else {
      setSelectedUserIds([...selectedUserIds, recordId]);
    }
  };

  const onSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/users/send-connection-info`,
        {
          recordIds: selectedUserIds,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        message.success(t('USERS.BATCH_USER_INFO_SENT'));
        setVisible(false);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleAllUsers = () => {
    if (selectedUserIds.length == users.length) {
      setSelectedUserIds([]);
    } else {
      setSelectedUserIds(users.map((user) => user.recordId!));
    }
  };

  return (
    <Drawer
      visible={visible}
      className={className}
      maskClosable
      onClose={onClose}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <React.Fragment>
        <div className="counter">
          <span className="number">{selectedUserIds.length}</span>
          <span className="description">{t('GLOBAL.USER(S)')}</span>
          <Button style={{ marginTop: '10px' }} type="primary" onClick={toggleAllUsers}>
            {selectedUserIds.length == users.length ? t('GLOBAL.DESELECT_ALL_USERS') : t('GLOBAL.SELECT_ALL_USERS')}
          </Button>
        </div>
        <Input placeholder={t('GLOBAL.SEARCH')} onChange={(e) => onFilter(e)} style={{ marginBottom: 10 }} />
        <ul className="user-list">
          {filteredUsers.map((user) => (
            <li key={user.recordId}>
              <Checkbox checked={selectedUserIds.includes(user.recordId!)} onClick={() => onCheck(user.recordId!)}>
                {user.displayName}
              </Checkbox>{' '}
            </li>
          ))}
        </ul>
      </React.Fragment>
      <div className="actions-container">
        <div className="actions">
          <Button type="primary" danger onClick={onClose} disabled={loading}>
            {t('GLOBAL.CANCEL')}
          </Button>
          <Button disabled={loading} loading={loading} type="primary" onClick={onSubmit}>
            {t('GLOBAL.SEND')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default styled(DrawerBatchUserInfos)`
  .counter {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: column;
    margin-bottom: 20px;
    color: ${colors.green};

    .number {
      font-size: 3em;
      line-height: 0.9em;
      font-weight: bold;
    }
  }

  .user-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 5px 0;
    }
  }
`;
