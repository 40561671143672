import { Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import TurnoverTemplate from './TurnoverTemplate';
import TemplatesTable from './TurnoverTemplatesTable';
import ModalCreateTurnoverTemplate from './modal-create-turnover-template';
import ModalImportTurnoverFile from './modal-import-turnover-file';

interface Props {
  className?: string;
}

const TurnoverTemplates: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const { templateId } = useParams() as any;
  const [modalCreateTurnoverTemplateVisible, setModalCreateTurnoverTemplateVisible] = useState<boolean>(false);
  const [modalImportTurnoverFileVisible, setModalImportTurnoverFileVisible] = useState<boolean>(false);

  const onCreateNewTemplate = () => {
    setModalCreateTurnoverTemplateVisible(true);
  };

  const onImport = () => {
    setModalImportTurnoverFileVisible(true);
  };

  const Table = () => {
    return (
      <div className="container">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button className="add-btn" type="primary" onClick={onCreateNewTemplate}>
            <i className="icon-plus" style={{ marginRight: 5 }} />{' '}
            {t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.CREATE_TEMPLATE')}
          </Button>
          <Button type="ghost" onClick={onImport}>
            <i className="icon-download" style={{ marginRight: 5 }} />{' '}
            {t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.IMPORT_FROM_FILE')}
          </Button>
        </div>
        <TemplatesTable />
      </div>
    );
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TITLE')}</h2>
      {templateId ? <TurnoverTemplate /> : <Table />}
      <ModalCreateTurnoverTemplate
        modalCreateTurnoverTemplateVisible={modalCreateTurnoverTemplateVisible}
        setModalCreateTurnoverTemplateVisible={setModalCreateTurnoverTemplateVisible}
      />
      <ModalImportTurnoverFile
        modalImportTurnoverFileVisible={modalImportTurnoverFileVisible}
        setModalImportTurnoverFileVisible={setModalImportTurnoverFileVisible}
      />
    </div>
  );
};

export default styled(TurnoverTemplates)`
  .container {
    background-color: #fff;
    padding: 25px;
    border: 1px solid #f0f0f0;
    border-top: 0px;

    .add-btn {
      margin-bottom: 15px;
    }
  }
`;
