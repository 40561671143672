import AppContext from '@/pages/app/context';
import { ITurnoverTemplate } from '@/types/insights/turnover-template.model';
import { Breadcrumb } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  activeTemplate: ITurnoverTemplate | null;
}

const TurnoverTemplateBreakcrumb: React.FC<Props> = ({ className, activeTemplate }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();

  const onClick = (link: string) => {
    history.push(link);
  };

  return (
    <div className={className}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item onClick={() => onClick('/app/settings/insights/turnover-templates')}>
          {t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TITLE')}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {activeTemplate ? activeTemplate.name : t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.NEW_TEMPLATE')}
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default styled(TurnoverTemplateBreakcrumb)`
  .ant-breadcrumb-link {
    cursor: pointer;
  }
`;
