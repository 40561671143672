import React from 'react';
import { ITask } from '@/types/task.model';
import styled from 'styled-components';
import ShiftTask from './ShiftTask';

interface Props {
  className?: string;
  tasks: ITask[];
  taskHeight: number;
  shiftStart: number;
  shiftId: string;
  columnWidth: number;
  draggable: boolean;
  refPageContainer: React.MutableRefObject<null> | null;
  taskIsResizingRef: React.MutableRefObject<boolean>;
  onTaskDragStartHandler: (e: React.DragEvent, task: ITask) => void;
  onTaskDragEndHandler: (e: React.DragEvent) => void;
  activeDepartmentId?: string;
  activeSection?: string;
  backgroundColor?: string;
}

const ShiftTasks: React.FC<Props> = ({
  className,
  draggable,
  tasks,
  shiftStart,
  shiftId,
  columnWidth,
  taskHeight,
  refPageContainer,
  taskIsResizingRef,
  onTaskDragStartHandler,
  onTaskDragEndHandler,
  activeDepartmentId,
  activeSection,
  backgroundColor,
}) => {
  return (
    <React.Fragment>
      {tasks.length > 0 && (
        <div className={className} style={{ height: taskHeight }}>
          {tasks.map((task, taskIndex) => (
            <ShiftTask
              refPageContainer={refPageContainer}
              taskIsResizingRef={taskIsResizingRef}
              key={`shift_${shiftId}_task_${task.id}_${taskIndex}`}
              task={task}
              shiftStart={shiftStart}
              shiftId={shiftId}
              draggable={draggable}
              columnWidth={columnWidth}
              onTaskDragStartHandler={onTaskDragStartHandler}
              onTaskDragEndHandler={onTaskDragEndHandler}
              taskHeight={taskHeight}
              activeDepartmentId={activeDepartmentId}
              activeSection={activeSection}
              backgroundColor={backgroundColor}
            />
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

const ShiftTasksStyled = styled(ShiftTasks)`
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  height: auto;
  overflow: hidden;
  margin-left: -10px;
  z-index: 3;
  position: absolute;
  bottom: 5px;
  font-weight: 600;
  letter-spacing: 0.3px;
`;

export default ShiftTasksStyled;
