import ShiftPeriod from '@/pages/app/hours/manage/components/ShiftPeriod';
import { default as Colors, default as colors } from '@/styles/colors';
import { ColoredShiftsTypes } from '@/types/department-settings.model';
import { IDepartment } from '@/types/department.model';
import { IShift } from '@/types/shift.model';
import { getContrast } from '@/utils';
import { Checkbox, Divider } from 'antd';
import moment from 'moment';
import React, { Dispatch, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ShiftAttributes from '../../components/ShiftAttributes';
import ShiftDepartment from '../../components/ShiftDepartment';
import ShiftDetailsIcons from '../../components/ShiftDetailsIcons';
import ShiftRightClick from '../../components/ShiftRightClick';
import ShiftSectionBar from '../../components/ShiftSectionBar';
import ShiftSpecialHrCodeStyled from '../../components/ShiftSpecialHrCode';
import ShiftTags from '../../components/ShiftTags';
import TopIcons from '../../components/TopIcons';
import TopIconsDayoff from '../../components/TopIconsDayoff';
import { ActionType } from '../../redux/actions';
import { InitialStateType } from '../../redux/store';
import ShiftTasks from './ShiftTasks';

interface Props {
  className?: string;
  onDragStartHandler: (e: React.DragEvent, shiftId: string) => void;
  onDragEndHandler: (e: React.DragEvent) => void;
  shift: IShift;
  activeDepartment?: IDepartment;
  activeSection?: string;
}

const Shift: React.FC<Props> = ({
  className,
  shift,
  activeDepartment,
  activeSection,
  onDragStartHandler,
  onDragEndHandler,
}) => {
  const {
    showSkills,
    showTasks,
    showSections,
    showAttributes,
    showDetails,
    showPrices,
    showHrCode,
    showComment,
    showOtherDepartments,
    picker,
    selectedShifts,
    usersMap,
    ayErrors,
  } = useSelector(
    ({
      showSkills,
      showTasks,
      showSections,
      showAttributes,
      showDetails,
      showPrices,
      showHrCode,
      showComment,
      showOtherDepartments,
      picker,
      selectedShifts,
      usersMap,
      ayErrors,
    }: InitialStateType) => ({
      showSkills,
      showTasks,
      showSections,
      showAttributes,
      showDetails,
      showPrices,
      showHrCode,
      showComment,
      showOtherDepartments,
      picker,
      selectedShifts,
      usersMap,
      ayErrors,
    }),
    shallowEqual,
  );

  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const { start: shiftStart, end: shiftEnd, price, pause } = shift;
  const duration = moment.duration(shiftEnd! - shiftStart!, 'seconds');
  duration.subtract(pause!.unpaid!, 'seconds');
  const durationInMinutes = duration.asMinutes();
  const pauseDurationInMinutes = moment.duration(pause!.paid! + pause!.unpaid!, 'seconds').asMinutes();

  const [selected, setSelected] = useState<boolean>(false);

  const hr_code_id =
    activeDepartment?.hr_codes && activeDepartment?.hr_codes.find((code) => code.id === shift.hr_code)?.code;

  const checkboxOnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selected) {
      hoursDispatch({
        type: 'REMOVE_SELECTED_SHIFT',
        payload: shift.id!,
      });
    } else {
      hoursDispatch({
        type: 'ADD_SELECTED_SHIFT',
        payload: shift.id!,
      });
    }
    setSelected(!selected);
  };

  useEffect(() => {
    if (selectedShifts.has(shift.id!)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedShifts]);

  const onClick = (e: React.MouseEvent) => {
    if (!shift.locked || ['ADMIN', 'SUPER-ADMIN'].includes(activeDepartment!.role!)) {
      hoursDispatch({
        type: 'SET_SELECTED_SHIFT',
        payload: shift,
      });
      hoursDispatch({
        type: 'SET_SHIFT_DRAWER_VISIBLE',
        payload: true,
      });
    }
  };

  let style: any = {};

  /**
   * 
    background-image: repeating-linear-gradient(-55deg, 
      transparent 0 10px, 
      rgba(0,0,0,0.03) 10px 20px);
   */

  const coloredShifts = activeDepartment?.scheduleParams?.colored_shifts;
  if (coloredShifts && shift.userRecordId) {
    if (coloredShifts == ColoredShiftsTypes.SKILLS && shift.skills && shift.skills[0].background) {
      style.background = shift.skills[0].background;
    }
    if (coloredShifts == ColoredShiftsTypes.ATTRIBUTES && shift.attributes && shift.attributes[0].background) {
      style.background = shift.attributes[0].background;
    }
    if (coloredShifts == ColoredShiftsTypes.STATUS) {
      const user = usersMap.get(shift.userRecordId);
      if (user && user.userType && user.userType.background) {
        style.background = user.userType.background;
        if (shift.dayoff)
          style.backgroundImage = `repeating-linear-gradient(-55deg, transparent 0 10px, rgba(0,0,0,0.08) 10px 20px)`;
      }
    }
  }

  if (shift.background) {
    style = { backgroundColor: shift.background };
  }

  if (shift.userRecordId && usersMap.get(shift.userRecordId)?.package && shift.package) {
    style = {
      backgroundColor: shift.background ? shift.background : shift.package.background,
      color: shift.package.color,
      fontWeight: 800,
    };
  }

  if (shift.locked) {
    style.backgroundColor = '#bdbcc657';
    if (!['ADMIN', 'SUPER-ADMIN'].includes(activeDepartment!.role!)) {
      style.cursor = 'not-allowed';
    }
  }

  return (
    <div
      id={shift.id}
      className={`
        ${className}
        ${shift.optimistic ? 'optimistic' : ''}
        ${shift.userRecordId ? '' : 'open-shift'}
        ${shift.dayoff ? 'dayoff' : ''}
        ${shift.approved ? '' : 'not-approved'}
        ${shift.request ? 'request' : ''}
        ${
          activeDepartment?.scheduleParams?.ghost_shifts && activeSection && activeSection !== shift.section?.id
            ? 'other-section'
            : ''
        }
      `}
      draggable={shift.locked ? ['ADMIN', 'SUPER-ADMIN'].includes(activeDepartment!.role!) && 'true' : 'true'}
      onDragStart={(e) => {
        if ((shift.locked && ['ADMIN', 'SUPER-ADMIN'].includes(activeDepartment!.role!)) || !shift.locked) {
          onDragStartHandler(e, shift.id!);
        }
      }}
      onDragEnd={(e) => {
        if ((shift.locked && ['ADMIN', 'SUPER-ADMIN'].includes(activeDepartment!.role!)) || !shift.locked) {
          onDragEndHandler(e);
        }
      }}
      onClick={onClick}
      style={style}
    >
      <ShiftRightClick shift={shift}>
        <div className="card">
          {activeSection !== shift.section?.id && <ShiftSectionBar section={shift.section} />}
          {!shift.locked && <Checkbox className="no-print" checked={selected} onClick={checkboxOnClick} />}
          {!shift.dayoff && <TopIcons shift={shift} department={activeDepartment!} />}
          {shift.dayoff && <TopIconsDayoff shift={shift} />}
          {showOtherDepartments && <ShiftDepartment departmentId={shift.departmentId!} />}
          {!shift.dayoff && (
            <React.Fragment>
              <ShiftPeriod start={shiftStart!} end={shiftEnd!} backgroundColor={style.background} />
              {showTasks && (
                <ShiftTasks tasks={shift.tasks || []} shiftId={shift.id!} backgroundColor={style.background} />
              )}
              {showDetails && (
                <div className="shift-details">
                  <ShiftDetailsIcons
                    hidePrice={!showPrices || !activeDepartment?.scheduleParams?.showShiftPrice}
                    currency={activeDepartment?.currency}
                    durationInMinutes={durationInMinutes}
                    pauseDurationInMinutes={pauseDurationInMinutes}
                    price={price!}
                    clockings={shift.clocking}
                    backgroundColor={style.background}
                  />
                </div>
              )}
              <ShiftTags
                picker={picker}
                section={showSections && activeSection !== shift.section?.id ? shift.section : undefined}
                skills={showSkills ? shift.skills || [] : []}
                backgroundColor={style.background}
                coloredShifts={coloredShifts}
              />
              {showHrCode && hr_code_id && <ShiftSpecialHrCodeStyled hr_code_id={hr_code_id} />}
              <ShiftAttributes
                attributes={showAttributes ? shift.attributes || [] : []}
                backgroundColor={style.background}
              />
            </React.Fragment>
          )}
          {picker !== 'week' && !shift.dayoff && shift.package && (
            <div>
              <div>{shift.package.name}</div>
              {showTasks && <ShiftTasks tasks={shift.tasks || []} shiftId={shift.id!} />}
              <ShiftTags
                picker={picker}
                section={showSections && activeSection !== shift.section?.id ? shift.section : undefined}
                skills={showSkills ? shift.skills || [] : []}
                backgroundColor={style.background}
                coloredShifts={coloredShifts}
              />
              <ShiftAttributes
                attributes={showAttributes ? shift.attributes || [] : []}
                backgroundColor={style.background}
              />
            </div>
          )}
          {shift.dayoff && (
            <React.Fragment>
              <h3 style={{ flex: 1 }}>
                <span
                  className="smaller"
                  style={{ color: style.background ? getContrast(style.background) : undefined }}
                >
                  {shift.dayoff!.shortCode}
                </span>
                {!!showDetails && (
                  <span className="detail-dayoff">
                    <ShiftDetailsIcons
                      hidePrice={!showPrices || !activeDepartment?.scheduleParams?.showShiftPrice}
                      durationInMinutes={durationInMinutes}
                      pauseDurationInMinutes={pauseDurationInMinutes}
                      price={price!}
                      currency={activeDepartment?.currency}
                      backgroundColor={style.background}
                    />
                  </span>
                )}
                {/* {shift.dayoff.paid ? (
                  <span className="devise">{getSymbolFromCurrency(`${activeDepartment?.currency}`)}</span>
                ) : (
                  ''
                )} */}
              </h3>
              <p
                className="dayoff-label"
                style={{ color: style.background ? getContrast(style.background) : undefined }}
              >
                {shift.dayoff!.name}
              </p>
              {showDetails && (
                <div className="shift-details" style={{ display: 'flex', flexWrap: 'wrap', whiteSpace: 'nowrap' }}>
                  {!shift.dayoff!.fullDay && (
                    <React.Fragment>
                      <ShiftPeriod
                        size="small"
                        start={shiftStart!}
                        end={shiftEnd!}
                        backgroundColor={style.background}
                      />
                    </React.Fragment>
                  )}
                </div>
              )}
              <ShiftTags
                picker={picker}
                section={showSections ? shift.section : undefined}
                skills={showSkills ? shift.skills || [] : []}
                backgroundColor={style.background}
                coloredShifts={coloredShifts}
              />
              <div></div>
            </React.Fragment>
          )}
          {!shift.dayoff && showComment && activeDepartment?.scheduleParams?.showComment && shift.comment && (
            <div className="comment" style={{ color: style.background ? getContrast(style.background) : undefined }}>
              <Divider plain />
              {shift.comment}
            </div>
          )}
          {(shift.count || 1) > 1 && <div className="count">{shift.count}</div>}
        </div>
      </ShiftRightClick>
    </div>
  );
};

export default styled(React.memo(Shift))`
  position: relative;
  background: #f7f9ff;
  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;
  margin: 2px 0;
  text-align: left;
  color: ${Colors.blue};
  cursor: grab;
  flex: 1;
  box-sizing: border-box;

  @media print {
    font-size-adjust: 0.5;
  }

  &.open-shift {
    background-color: white;
    border: 1px solid ${Colors.greenLight};
  }

  &.month {
    width: 180px;
  }

  &.optimistic {
    pointer-events: none;
    animation: blink 1s infinite alternate-reverse;
  }

  &.other-section {
    opacity: 0.5;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }

  &.not-approved {
    &:after {
      content:'';
      top: 0;
      left: 0;
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      border: 2px dashed ${Colors.green};
      border-radius: 5px;
      pointer-events: none;
    }
  }

  &.request {
    background-color: #B3E6EF;
  }

  &.dayoff {

    .detail-dayoff {
      font-weight: normal;
      font-size: 10px;
      color: ${colors.grey};
      line-height: 12px;
    }

    background-image: repeating-linear-gradient(-55deg,
      transparent,
      transparent 10px,
      rgba(0,0,0,0.03) 10px,
      rgba(0,0,0,0.03) 20px);

    background-image: repeating-linear-gradient(-55deg, 
      transparent 0 10px, 
      rgba(0,0,0,0.03) 10px 20px);
      
    @media print {
      background-image: none;
      background-color: antiquewhite;
    }
  }
  
}

  .shift-details {
    font-size: 10px;
    color: gray;
    font-weight: 100 !important;
  }

  .ant-checkbox-wrapper {
    right: 3px;
    top: 0px;
    position: absolute;

    &.ant-checkbox-wrapper-checked:hover {
      border: none !important;
    }

    
    .ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after, .ant-checkbox-checked::after {
      visibility: hidden !important;
      border: none !important;
    }
    .ant-checkbox {
      padding: 3px;
    }
  }

  .section {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    border-right: 5px solid black;
    pointer-events: none;
  }

  .card {
    height: 100%;
    h3 {
      font-weight: 600;
      color: black;
      font-size: 22px;
      margin: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      .smaller {
        font-size: 16px;
      }

      .devise {
        margin-left: 5px;
        background-color: ${colors.blue};
        font-size: 12px;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
      }
    }

    p {
      margin-bottom: 4px;
      &.dayoff-label {
        color: ${Colors.grey};
        white-space: nowrap;
        overflow: hidden;
        font-size: 11px;
      }
      

    > div {
      &:nth-of-type(n + 2) {
        margin-top: 2px;
        span {
          display: inline-block;
          &:nth-of-type(n - 1) {
            margin-right: 5px;
          }
        }
      }
    }
    
  }
  

  .comment {
    color: #444444;
    font-size: 12px;
    line-height: 16px;
    .ant-divider-horizontal {
      margin: 10px 0 8px;
    }
  }

  .count {
    background: #d0d3dd;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    float: right;
    color: #66676a;
    font-size: 11px;
    font-weight: bold;
    padding: 0px 12px;
    margin-top: 5px;
    border: 1px solid white;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.6;
    }
  }

  p {
    font-size: 13px;
    font-weight: normal;
  }
`;
