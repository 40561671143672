import colors from '@/styles/colors';
import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  error?: boolean;
  chilren?: any;
}

const Callout: React.FC<Props> = ({ className, children, error }) => (
  <div className={className} style={{ backgroundColor: error ? colors.red : colors.green }}>
    {children}
  </div>
);

const CalloutStyled = styled(Callout)`
  color: white;
  border-radius: 3px;
  padding: 7px 10px;
  font-weight: bold;
`;

export default CalloutStyled;
