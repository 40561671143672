import { List, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  errors: any[];
  clearErrors: () => void;
}

const ModalErrors: React.FC<Props> = ({ errors, clearErrors }) => {
  const { t } = useTranslation();

  return (
    <Modal
      destroyOnClose={true}
      onOk={clearErrors}
      onCancel={clearErrors}
      visible={errors && errors?.length > 0}
      className={'modal-danger'}
      title={t('SCHEDULE.CONSTRAINTS.TITLE')}
    >
      <List dataSource={errors!} renderItem={(el) => <List.Item>{el}</List.Item>} />
    </Modal>
  );
};

export default ModalErrors;
