import colors from '@/styles/colors';
import { message, Popover, Spin, Tooltip } from 'antd';
import axios from 'axios';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IVacationRequest } from '..';
import ShiftsDetails from './ShiftsDetails';

interface Props {
  className?: string;
  request: IVacationRequest;
  departmentId?: string;
}

const Availabilities: React.FC<Props> = ({ className, request, departmentId }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<IResponse | null>(null);
  const [days, setDays] = useState<Moment[]>([]);

  useEffect(() => {
    setResponse(null);
    setDays([]);
    if (request) {
      let mounted = true;
      const cancelTokenSource = axios.CancelToken.source();
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/vacation-requests/${request.id}`, {
          cancelToken: cancelTokenSource.token,
          params: {
            departmentId,
          },
        })
        .then(({ data }) => {
          setResponse(data);
          setLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            console.error(error);
            message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
          }
          if (mounted) {
            setLoading(false);
          }
        });
      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [request]);

  useEffect(() => {
    if (response) {
      const { start, end } = response;
      const days: Moment[] = [];
      let current = start - DAY * MORE_DAYS;
      while (current <= end + DAY * MORE_DAYS) {
        days.push(moment.unix(current));
        current += 86400;
      }
      setDays(days);
    }
  }, [response]);

  return (
    <Spin spinning={loading}>
      {response && (
        <div className={className}>
          {response?.currentBalance && (
            <span className="counter">{t('LEAVES.H_AVAILABLE_LEAVE', { count: response?.currentBalance })}</span>
          )}
          <div className="table">
            <div className="header">
              <div className="cell"></div>
              {days.map((day) => (
                <div key={day.unix()} className="cell">
                  {day.format('DD/MM')}
                </div>
              ))}
            </div>
            <div className="body">
              <div className="row">
                <div className="cell">
                  <Tooltip title={response?.currentUser?.displayName}>{response?.currentUser?.initials}</Tooltip>
                </div>
                {days.map((day) => {
                  const shifts = response?.currentUser.days?.[day.format('YYYY-MM-DD')] || [];
                  if (shifts?.length) {
                    return (
                      <Popover key={day.unix()} content={() => <ShiftsDetails shifts={shifts} />}>
                        <div className={`cell ${shifts[0].type}`} />
                      </Popover>
                    );
                  } else {
                    const ts = day.unix();
                    const leaveRequested = ts >= response.start && ts <= response.end;
                    return <div key={day.unix()} className={leaveRequested ? 'cell PERIOD' : 'cell'} />;
                  }
                })}
              </div>
              {response.users?.map((user: any) => (
                <div key={user.recordId} className="row">
                  <div className="cell">
                    <Tooltip title={user?.displayName}>{user.initials}</Tooltip>
                  </div>
                  {days.map((day) => {
                    const shifts = user.days?.[day.format('YYYY-MM-DD')] || [];
                    if (shifts?.length) {
                      return (
                        <Popover key={day.unix()} content={() => <ShiftsDetails shifts={shifts} />}>
                          <div className={`cell ${shifts[0].type}`} />
                        </Popover>
                      );
                    } else {
                      return <div key={day.unix()} className="cell" />;
                    }
                  })}
                </div>
              ))}
            </div>
          </div>
          <div className="legend">
            <div>
              <div className="color PERIOD" />
              <span>{t('GLOBAL.LEAVE_PERIOD')}</span>
            </div>
            <div>
              <div className="color SHIFT" />
              <span>{t('GLOBAL.PLANNED_SHIFT')}</span>
            </div>
            <div>
              <div className="color LEAVE" />
              <span>{t('GLOBAL.LEAVE')}</span>
            </div>
            <div>
              <div className="color UNAVAILABILITY" />
              <span>{t('GLOBAL.UNAVAILABILITY')}</span>
            </div>
          </div>
        </div>
      )}
    </Spin>
  );
};

export default styled(Availabilities)`
  .table {
    overflow: auto;
  }

  .header,
  .row {
    display: flex;
    > div {
      text-align: center;
      flex: 1;
      border-bottom: 1px solid ${colors.greyLight};
      border-right: 1px solid ${colors.greyLight};
      :first-child  {
        border-left: 1px solid ${colors.greyLight};
        max-width: 40px;
        font-size: 12px;
        font-weight: bold;
        position: sticky;
      }
    }
  }

  .header {
    border-top: 1px solid ${colors.greyLight};
    font-size: 10px;
  }

  .body {
    .row:first-child  {
      background-color: ${colors.greenLight};
    }
  }

  .cell {
    height: 25px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cell,
  .legend .color {
    &.SHIFT {
      background-color: ${colors.blue};
    }
    &.PERIOD {
      background-color: ${colors.red};
    }
    &.LEAVE {
      background-color: ${colors.blueLight};
    }
    &.UNAVAILABILITY {
      background-color: ${colors.redLight};
    }
  }

  .legend {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      font-size: 10px;
      .color {
        width: 30px;
        height: 20px;
      }
    }
  }

  .counter {
    font-weight: bold;
    color: ${colors.green};
    text-align: right;
    display: block;
    margin-bottom: 10px;
  }
`;

const MORE_DAYS = 3;
const DAY = 86400;

const EXAMPLE = {
  start: 1638489600,
  end: 1638662400,
  currentBalance: 123,
  currentUser: {
    recordId: '123',
    displayName: 'Lio',
    initials: 'L',
    shifts: {
      '2021-12-03': {
        type: 'SHIFT',
      },
    },
  },
  users: [
    {
      recordId: '123',
      displayName: 'Nico',
      initials: 'L',
      shifts: {
        '2021-12-02': {
          type: 'LEAVE', // LEAVE, UNAVAILABILITY,
        },
      },
    },
  ],
} as any;

interface IResponse {
  start: number;
  end: number;
  currentBalance: number;
  currentUser: {
    recordId: string;
    displayName: string;
    initials: string;
    days: {
      [key: string]: {
        type: 'SHIFT' | 'LEAVE' | 'UNAVAILABILITY';
        start: number;
        end: number;
        id: string;
      }[];
    };
  };
  users: {
    recordId: string;
    displayName: string;
    initials: string;
    days: {
      [key: string]: {
        type: 'LEAVE' | 'UNAVAILABILITY';
        start: number;
        end: number;
        id: string;
      }[];
    };
  }[];
}
