import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IUser } from '@/types/user.model';
import { handleError } from '@/utils';
import { BackTop, Table, Tooltip, message } from 'antd';
import { default as axios } from 'axios';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
}

const AppReportsLeaveReports: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartment, activeDepartmentId, activeSection },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [rangePickerValues, setRangePickerValues] = useState<[Moment, Moment]>([
    moment().startOf('year'),
    moment().endOf('year'),
  ]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const [columns, setColumns] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [showAllAbsences, setShowAllAbsences] = useState<boolean>(false);
  const [newColumns, setNewColumns] = useState<any[]>([]);
  const [newUsers, setNewUsers] = useState<any[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);

  useEffect(() => {
    const show = localStorage.getItem('leave-reports-show-all-absences');
    setShowAllAbsences(show == 'true');
  }, []);

  useEffect(() => {
    setFilteredUsers(
      newUsers.filter((x) => {
        if (selectedUsers.length) {
          if (selectedUsers.includes(x.id || '')) {
            return true;
          } else {
            return false;
          }
        }
        if (selectedCategories.length) {
          if (selectedCategories.includes(x.category || '')) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }),
    );
  }, [selectedCategories, selectedUsers, newUsers]);

  useEffect(() => {
    const [start, end] = rangePickerValues;

    if (activeDepartmentId && start && end) {
      let mounted = true;
      const cancelTokenSource = axios.CancelToken.source();
      const [startMoment, endMoment] = rangePickerValues;

      setLoading(true);
      setNewColumns([]);

      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/leaves/usersV3`, {
          cancelToken: cancelTokenSource.token,
          params: {
            departmentId: activeDepartmentId,
            start: startMoment.unix(),
            end: endMoment.unix(),
            activeSection,
          },
        })
        .then(({ data }) => {
          setNewColumns(data.dayoffs);
          setNewUsers(data.users);
        })
        .catch((error) => {
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });

      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [activeDepartmentId, rangePickerValues]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });

    setColumns([
      {
        title: (
          <div>
            <span>{t('GLOBAL.NAME')}</span>
          </div>
        ),
        columnName: t('GLOBAL.NAME'),
        key: 'displayName',
        dataIndex: ['user', 'displayName'],
        fixed: 'left',
        className: 'one-line-text',
        render: (text: any, record: any) => {
          const { id, firstname, lastname } = record;
          if (id) {
            return (
              <Link to={`/app/team/collaborators/${id}`}>
                {firstname} {lastname}
              </Link>
            );
          } else {
            return (
              <span>
                {firstname} {lastname}
              </span>
            );
          }
        },
      },
      {
        title: t('GLOBAL.STATUS'),
        columnName: t('GLOBAL.STATUS'),
        key: 'category',
        dataIndex: ['user', 'category'],
        className: 'border-right-thin',
        render: (text: any, record: any) => {
          const { category } = record;
          if (category) {
            return <span>{category}</span>;
          }
        },
      },
      ...newColumns
        .filter(
          ({ type_customer, type_extra_legal, type_legal_leaves, type_recup, type_rtt, shortcode }) =>
            (type_customer || type_extra_legal || type_legal_leaves || type_rtt) &&
            ![
              'TOTAL_LEAVES',
              'TOTAL_LEAVES_TAKEN',
              'TOTAL_LEAVES_REMAINING',
              'type_legal_leaves',
              'type_extra_legal',
              'type_rtt',
            ].includes(shortcode) &&
            !type_recup,
        )
        .map(({ id, shortcode, name }, i) => ({
          columnName: name,
          group: t('GLOBAL.LEAVES'),
          title: (
            <Tooltip title={name}>
              {i == 0 && (
                <span
                  style={{
                    position: 'absolute',
                    left: 15,
                    top: -25,
                    zIndex: 99,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: 12,
                    color: colors.green,
                  }}
                >
                  {t('GLOBAL.LEAVES')}
                </span>
              )}
              <span>{shortcode}</span>
            </Tooltip>
          ),
          key: shortcode,
          align: 'center',
          // eslint-disable-next-line react/display-name
          render: (text: any, record: any) => {
            if ((!record[shortcode] && record[shortcode] !== 0) || isNaN(Number(record[shortcode])))
              return {
                props: {
                  style: {
                    backgroundColor: '#F9F9F9',
                  },
                },
                children: <div></div>,
              };
            return {
              props: {
                style: {
                  backgroundColor: '#F9F9F9',
                },
              },
              children: (
                <div style={{ color: Number(record[shortcode]) == 0 ? '#ccc' : '#000' }}>
                  {Number(record[shortcode]) == 0
                    ? 0
                    : Number(record[shortcode]).toFixed(1).endsWith('.0')
                    ? Number(record[shortcode]).toFixed(0)
                    : Number(record[shortcode]).toFixed(1)}
                </div>
              ),
            };
          },
        })),
      ...newColumns
        .filter(({ shortcode }) => ['type_legal_leaves', 'type_extra_legal', 'type_rtt'].includes(shortcode))
        .map(({ id, shortcode, name }, i) => ({
          columnName: t(`REPORTS.LEAVE_REPORTS.${shortcode?.toUpperCase()}`),
          group: t('GLOBAL.TYPE'),
          title: (
            <Tooltip title={t(`REPORTS.LEAVE_REPORTS.${shortcode?.toUpperCase()}`)}>
              {i == 0 && (
                <span
                  style={{
                    position: 'absolute',
                    left: 15,
                    top: -25,
                    zIndex: 99,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: 12,
                    color: colors.green,
                  }}
                >
                  {t('GLOBAL.TYPE')}
                </span>
              )}
              <span>{t(`REPORTS.LEAVE_REPORTS.${shortcode?.toUpperCase()}_SHORT`)}</span>
            </Tooltip>
          ),
          key: shortcode,
          align: 'center',
          className: i == 0 && 'border-left-thin-light',
          // eslint-disable-next-line react/display-name
          render: (text: any, record: any) => {
            if ((!record[shortcode] && record[shortcode] !== 0) || isNaN(Number(record[shortcode])))
              return {
                props: {
                  style: {
                    backgroundColor: '#fff',
                  },
                },
                children: <div></div>,
              };
            return {
              props: {
                style: {
                  backgroundColor: '#fff',
                },
              },
              children: (
                <div style={{ color: Number(record[shortcode]) == 0 ? '#ccc' : '#000' }}>
                  {Number(record[shortcode]) == 0
                    ? 0
                    : Number(record[shortcode]).toFixed(1).endsWith('.0')
                    ? Number(record[shortcode]).toFixed(0)
                    : Number(record[shortcode]).toFixed(1)}
                  {record['legal_leaves'][shortcode] && Number(record['legal_leaves'][shortcode]) !== 0
                    ? `/${record['legal_leaves'][shortcode]}`
                    : ''}
                </div>
              ),
            };
          },
        })),
      ...newColumns
        .filter(({ shortcode }) => ['TOTAL_LEAVES', 'TOTAL_LEAVES_TAKEN', 'TOTAL_LEAVES_REMAINING'].includes(shortcode))
        .map(({ id, shortcode, name }, i) => ({
          columnName: t(`REPORTS.LEAVE_REPORTS.${shortcode}`),
          group: t('GLOBAL.COUNT'),
          title: (
            <Tooltip title={t(`REPORTS.LEAVE_REPORTS.${shortcode}`)}>
              {i == 0 && (
                <span
                  style={{
                    position: 'absolute',
                    left: 15,
                    top: -25,
                    zIndex: 99,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: 12,
                    color: colors.green,
                  }}
                >
                  {t('GLOBAL.COUNT')}
                </span>
              )}
              <span>{t(`REPORTS.LEAVE_REPORTS.${shortcode}_SHORT`)}</span>
            </Tooltip>
          ),
          key: shortcode,
          align: 'center',
          className: i == 0 ? 'border-left-thin' : '',
          // eslint-disable-next-line react/display-name
          render: (text: any, record: any) => {
            if ((!record[shortcode] && record[shortcode] !== 0) || isNaN(Number(record[shortcode])))
              return {
                props: {
                  style: {
                    backgroundColor: '#edf7ff',
                  },
                },
                children: <div></div>,
              };
            return {
              props: {
                style: {
                  backgroundColor:
                    shortcode == 'TOTAL_LEAVES_REMAINING'
                      ? Number(record[shortcode]) >= 0
                        ? '#DDFAE6'
                        : Number(record[shortcode]) < 0
                        ? '#FADDDD'
                        : '#edf7ff'
                      : '#edf7ff',
                },
              },
              children: (
                <div>
                  {Number(record[shortcode]) == 0
                    ? 0
                    : Number(record[shortcode]).toFixed(1).endsWith('.0')
                    ? Number(record[shortcode]).toFixed(0)
                    : Number(record[shortcode]).toFixed(1)}
                </div>
              ),
            };
          },
        })),
      ...newColumns
        .filter(
          ({ type_recup, shortcode }) =>
            type_recup &&
            !['TOTAL_LEAVES', 'TOTAL_LEAVES_TAKEN', 'TOTAL_LEAVES_REMAINING'].includes(shortcode) &&
            showAllAbsences,
        )
        .map(({ id, shortcode, name }, i) => {
          const length = newColumns.filter(
            ({ type_recup, shortcode }) =>
              type_recup && !['TOTAL_LEAVES', 'TOTAL_LEAVES_TAKEN', 'TOTAL_LEAVES_REMAINING'].includes(shortcode),
          ).length;
          return {
            columnName: name,
            group: t('GLOBAL.RECOVERY'),
            title: (
              <Tooltip title={name}>
                {showAllAbsences && i == 0 && (
                  <span
                    style={{
                      position: 'absolute',
                      left: 15,
                      top: -25,
                      zIndex: 99,
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      fontSize: 12,
                      color: colors.green,
                    }}
                  >
                    {t('GLOBAL.RECOVERY')}
                  </span>
                )}
                <span>{shortcode}</span>
              </Tooltip>
            ),
            key: shortcode,
            align: 'center',
            width: length == 1 ? 150 : undefined,
            className:
              i == 0 && length == 1
                ? 'border-left-bold border-right-thin-light'
                : i == 0 && length > 1
                ? 'border-left-bold'
                : i == length - 1
                ? 'border-right-thin-light'
                : '',
            // eslint-disable-next-line react/display-name
            render: (text: any, record: any) => {
              if ((!record[shortcode] && record[shortcode] !== 0) || isNaN(Number(record[shortcode])))
                return {
                  props: {
                    style: {
                      backgroundColor: '#F9F9F9',
                    },
                  },
                  children: <div></div>,
                };
              return {
                props: {
                  style: {
                    backgroundColor: '#F9F9F9',
                  },
                },
                children: (
                  <div style={{ color: Number(record[shortcode]) == 0 ? '#ccc' : '#000' }}>
                    {Number(record[shortcode]) == 0
                      ? 0
                      : Number(record[shortcode]).toFixed(1).endsWith('.0')
                      ? Number(record[shortcode]).toFixed(0)
                      : Number(record[shortcode]).toFixed(1)}
                  </div>
                ),
              };
            },
          };
        }),
      ...newColumns
        .filter(
          ({ type_customer, type_extra_legal, type_legal_leaves, type_recup, type_rtt, shortcode }) =>
            !type_customer &&
            !type_extra_legal &&
            !type_legal_leaves &&
            !type_recup &&
            !type_rtt &&
            showAllAbsences &&
            !['TOTAL_LEAVES', 'TOTAL_LEAVES_TAKEN', 'TOTAL_LEAVES_REMAINING'].includes(shortcode),
        )
        .map(({ id, shortcode, name }, i) => ({
          columnName: name,
          group: t('GLOBAL.ABSENCES'),
          title: (
            <Tooltip title={name}>
              {showAllAbsences && i == 0 && (
                <span
                  style={{
                    position: 'absolute',
                    left: 15,
                    top: -25,
                    zIndex: 99,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: 12,
                    color: colors.green,
                  }}
                >
                  {t('GLOBAL.ABSENCES')}
                </span>
              )}
              <span>{shortcode}</span>
            </Tooltip>
          ),
          key: shortcode,
          align: 'center',
          // eslint-disable-next-line react/display-name
          render: (text: any, record: any) => {
            if ((!record[shortcode] && record[shortcode] !== 0) || isNaN(Number(record[shortcode]))) return;
            return {
              props: {
                style: {
                  backgroundColor: '#F9F9F9',
                },
              },
              children: (
                <div style={{ color: Number(record[shortcode]) == 0 ? '#ccc' : '#000' }}>
                  {Number(record[shortcode]) == 0
                    ? 0
                    : Number(record[shortcode]).toFixed(1).endsWith('.0')
                    ? Number(record[shortcode]).toFixed(0)
                    : Number(record[shortcode]).toFixed(1)}
                </div>
              ),
            };
          },
        })),
    ]);
  }, [i18n.language, newColumns, showAllAbsences]);

  const onDownload = () => {
    const [start, end] = rangePickerValues;
    setIsDownloading(true);
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/v3/reports/leaves/export`,
      responseType: 'blob',
      params: {
        departmentId: activeDepartmentId,
        start: start.unix(),
        end: end.unix(),
      },
      data: {
        columns: columns.map((column) => ({
          name: column.columnName,
          key: column.key,
          group: column.group,
        })),
        users: filteredUsers.map((user) => ({
          ...user,
          displayName: `${user.firstname} ${user.lastname}`,
        })),
      },
    })
      .then((response) => {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(file);
        downloadLink.download = `${t('REPORTS.LEAVE_REPORTS.EXPORT_FILENAME', {
          company: activeDepartment?.company,
          start: start.format('L'),
          end: end.format('L'),
        })}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setIsDownloading(false);
      })
      .catch((error) => {
        console.error(error);
        message.error(t('GLOBAL.MESSAGE_DOWNLOAD_ERROR'));
        setIsDownloading(false);
      });
  };

  return (
    <TableView className={className}>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header
        rangePickerValues={rangePickerValues}
        setRangePickerValues={setRangePickerValues}
        selectUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        isDownloading={isDownloading}
        onDownload={onDownload}
        showAllAbsences={showAllAbsences}
        setShowAllAbsences={setShowAllAbsences}
      />
      <Table
        loading={loading}
        dataSource={filteredUsers}
        columns={columns}
        rowKey={(user) => user.id}
        pagination={false}
        style={{ paddingTop: showAllAbsences ? 25 : 0 }}
      />
    </TableView>
  );
};

export default styled(AppReportsLeaveReports)`
  .border-left-thin {
    border-left: 1px solid #000;
  }

  .border-left-thin-light {
    border-left: 1px solid #ccc;
  }

  .border-left-bold {
    border-left: 5px solid #000;
  }

  .border-right-thin {
    border-right: 1px solid #000;
  }

  .border-right-thin-light {
    border-right: 1px solid #ccc;
  }

  .one-line-text {
    white-space: nowrap; /* Prevents text from wrapping */
    text-overflow: ellipsis; /* Adds an ellipsis (...) to the end of the overflowing text */
  }
`;

interface IColumn {
  id: string;
  title: string;
  code: string;
  background: string;
}

interface IRow {
  user: IUser;
  columns: {
    [key: string]: string | number;
  };
}
