import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { handleError } from '@/utils';
import { Form, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const ClockingStaff: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<(string | null)[]>([]);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const updateForm = (c: any, e: any, name: any) => {
    setChecked(c ? [...checked, name] : checked.filter((el) => el != name));
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.CLOCKING.STAFF.ACTIVATE_MOBILE_CLOCKING.LABEL'),
          description: t('SETTINGS.CLOCKING.STAFF.ACTIVATE_MOBILE_CLOCKING.DESCRIPTION'),
          name: ['clockin', 'enable_mobile_clockin'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.clockin?.enable_mobile_clockin}
              onChange={(checked, e) => updateForm(checked, e, 'enable_mobile_clockin')}
            />
          ),
          show: true,
        },
        {
          label: t('SETTINGS.CLOCKING.STAFF.ACTIVATE_QR_CODE_SCAN.LABEL'),
          description: t('SETTINGS.CLOCKING.STAFF.ACTIVATE_QR_CODE_SCAN.DESCRIPTION'),
          name: ['clockin', 'enable_qrcode'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.clockin?.enable_qrcode}
              onChange={(checked, e) => updateForm(checked, e, 'enable_qrcode')}
            />
          ),
          show: checked.includes('enable_mobile_clockin'),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.enable_mobile_clockin !== curValues.clockin?.enable_mobile_clockin;
          },
        },
        {
          label: t('SETTINGS.CLOCKING.STAFF.MANDATORY_QR_CODE.LABEL'),
          description: t('SETTINGS.CLOCKING.STAFF.MANDATORY_QR_CODE.DESCRIPTION'),
          name: ['clockin', 'qrcode_mandatory'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.clockin?.qrcode_mandatory} />,
          show: checked.includes('enable_qrcode'),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.enable_qrcode !== curValues.clockin?.enable_qrcode;
          },
          newBadge: '2024-07-01',
        },
        {
          label: t('SETTINGS.CLOCKING.STAFF.ACTIVATE_GPS.LABEL'),
          description: t('SETTINGS.CLOCKING.STAFF.ACTIVATE_GPS.DESCRIPTION'),
          name: ['clockin', 'geolocation'],
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.clockin?.geolocation} />,
          show: checked.includes('enable_mobile_clockin'),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.enable_mobile_clockin !== curValues.clockin?.enable_mobile_clockin;
          },
        },
        {
          label: t('SETTINGS.CLOCKING.STAFF.ALLOW_OFFLINE_CLOCKINGS.LABEL'),
          description: t('SETTINGS.CLOCKING.STAFF.ALLOW_OFFLINE_CLOCKINGS.DESCRIPTION'),
          name: ['clockin', 'offline_clockings'],
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.clockin?.offline_clockings} />,
          show: checked.includes('enable_mobile_clockin'),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.enable_mobile_clockin !== curValues.clockin?.enable_mobile_clockin;
          },
        },
        {
          label: t('SETTINGS.CLOCKING.STAFF.HIDE_HISTORY.LABEL'),
          description: t('SETTINGS.CLOCKING.STAFF.HIDE_HISTORY.DESCRIPTION'),
          name: ['general', 'hide_past_shifts'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.general?.hide_past_shifts} />,
          show: true,
        },
        {
          label: t('SETTINGS.CLOCKING.STAFF.COMMENT_MANDATORY.LABEL'),
          description: t('SETTINGS.CLOCKING.STAFF.COMMENT_MANDATORY.DESCRIPTION'),
          name: ['clockin', 'comment_mandatory'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.clockin?.comment_mandatory} />,
          show: checked.includes('enable_mobile_clockin'),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.enable_mobile_clockin !== curValues.clockin?.enable_mobile_clockin;
          },
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    if (currentSettings?.clockin) {
      setChecked(
        Object.entries(currentSettings?.clockin).map(([key, value]: any) =>
          value == true || (value !== undefined && value > 1) ? key : null,
        ),
      );
    }
    form.setFieldsValue(currentSettings);
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings(values)
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>Shyfter Staff</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(ClockingStaff)``;
