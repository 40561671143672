import Colors from '@/styles/colors';
import { getWindowSize } from '@/utils';
import { Switch } from 'antd';
import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../redux/actions';
import { InitialStateType } from '../redux/store';
import OpenShiftRightClick from './OpenShiftRightClick';

interface Props {
  baseClassName: string;
  className?: string;
  children?: any;
}

const OpenShiftsRow: React.FC<Props> = ({ className, baseClassName, children }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { showOpenShifts, filteredShiftsByUsers, picker } = useSelector(
    ({ showOpenShifts, filteredShiftsByUsers, picker }: InitialStateType) => ({
      showOpenShifts,
      filteredShiftsByUsers,
      picker,
    }),
    shallowEqual,
  );

  const shiftsByUser = filteredShiftsByUsers.find((shiftsByUser) => !shiftsByUser.user?.recordId)!;

  return (
    <div className={`${baseClassName} ${className}`} key={`sgi_open_shifts`}>
      <OpenShiftRightClick shiftsByUser={shiftsByUser} picker={picker}>
        <div
          className="left"
          style={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: windowSize.innerWidth > 900 ? 210 : 150,
          }}
        >
          <div>
            {windowSize.innerWidth > 900 ? t('SCHEDULE.AVAILABLE_SHIFTS') : t('GLOBAL.FREE_SHIFTS_SHORT')}
            <Switch
              size="small"
              checked={showOpenShifts}
              onClick={() => {
                hoursDispatch({
                  type: 'SET_SHOW_OPEN_SHIFTS',
                  payload: !showOpenShifts,
                });
              }}
              style={{ left: '10px', paddingBottom: 5 }}
            />
          </div>
        </div>
      </OpenShiftRightClick>
      <div className={`right`}>{children}</div>
    </div>
  );
};

const OpenShiftsRowStyled = styled(React.memo(OpenShiftsRow))`
  min-width: 100%;
  width: fit-content;

  > .left {
    background-color: ${Colors.greenExtraLight} !important;
  }
  > .right {
    background-color: ${Colors.greenExtraLight};
  }
`;

export default OpenShiftsRowStyled;
