import { InitialStateType } from '@/context';
import { IDepartment } from '@/types/department.model';
import { Avatar, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import AppContext from '../context';

const UserDropdown = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default;

  .ant-avatar {
    margin-right: 10px !important;
  }
`;

interface Props {
  department?: IDepartment;
  departments: IDepartment[];
  userDetails: InitialStateType['userDetails'];
  handleDepartmentChange: (value: string | null) => void;
  handleLogout: () => void;
}

const MenuUser: React.FC<Props> = (props) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const { department, departments, handleDepartmentChange, handleLogout, userDetails, ...otherProps } = props;
  return (
    <Menu getPopupContainer={(trigger) => trigger} {...otherProps} triggerSubMenuAction="click">
      <Menu.Item key="user">
        <UserDropdown>
          <div>
            <Avatar size={30} src={userDetails?.picture}>
              {userDetails?.initials}
            </Avatar>
          </div>
          <div>{userDetails?.email}</div>
        </UserDropdown>
      </Menu.Item>
      <Menu.Divider />
      <SubMenu title={department?.company} style={{ padding: '5px 0' }} key="departments">
        {departments
          .filter((department) => department.role !== 'USER')
          .map((department) => (
            <Menu.Item key={`department_${department.id}`} onClick={() => handleDepartmentChange(department.id!)}>
              {department.company}
            </Menu.Item>
          ))}
      </SubMenu>
      <Menu.Divider />
      {activeDepartment?.accountType !== 'RGPD' && (
        <>
          <Menu.Item key="settings-general">
            <NavLink
              activeClassName="is-active"
              to="/app/settings/information/profile"
              className="menu_user_settings-general"
            >
              {t('SETTINGS.ACCOUNT.TITLE')}
            </NavLink>
          </Menu.Item>
          {department?.role === 'ADMIN' && (
            <Menu.Item key="logs">
              <NavLink activeClassName="is-active" to="/app/logs" className="menu_user_logs">
                {t('LOGS.TITLE')}
              </NavLink>
            </Menu.Item>
          )}
          <Menu.Item key="profile">
            <NavLink
              activeClassName="is-active"
              to="/app/settings/information/profile"
              className="menu_user_settings-profile"
            >
              {t('SETTINGS.INFORMATION.PROFILE.TITLE')}
            </NavLink>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item key="logout">
        <button className="highlight menu_user_logout" onClick={handleLogout}>
          {t('GLOBAL.LOGOUT')}
        </button>
      </Menu.Item>
    </Menu>
  );
};

export default MenuUser;
