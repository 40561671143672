import React, { useEffect, useState, useContext } from 'react';
import { Table, BackTop } from 'antd';
import TableView from '@/layouts/TableView';
import Axios from 'axios';
import AppContext from '@/pages/app/context';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { ILog } from '@/types/log.model';
import ContainerView from '@/layouts/ContainerView';
import Header from './Header';

const AppLogsPage: React.FC = () => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const [logs, setLogs] = useState<ILog[]>([]);
  const [filteredLogs, setFilteredLogs] = useState<ILog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteringLogs, setFilteringLogs] = useState<boolean>(false);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [filterSearch, setFilterSearch] = useState<string>('');
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.DATE'),
        key: 'date',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: ILog) => {
          return moment.unix(record.dateCreated!).format('L HH:mm');
        },
      },
      {
        title: t('GLOBAL.DEPARTMENT'),
        key: 'departmentName',
        dataIndex: 'departmentName',
      },
      {
        title: t('GLOBAL.USER'),
        key: 'user',
        dataIndex: ['user', 'name'],
      },
      {
        title: t('GLOBAL.ACTIVITY'),
        key: 'action',
        dataIndex: 'action',
      },
      {
        title: t('GLOBAL.DETAIL'),
        key: 'comment',
        dataIndex: 'comment',
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    if (!location?.pathname) {
      return;
    }
    let mounted = true;
    setIsLoading(true);
    setLogs([]);
    const cancelTokenSource = Axios.CancelToken.source();
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/logs`, {
      params: {
        departmentId: activeDepartmentId,
        limit: 1000,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then(({ data }) => {
        if (mounted) {
          const { logs } = data;
          setLogs(logs);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (mounted) {
          setIsLoading(false);
        }
        console.error(error);
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [location]);

  useEffect(() => {
    setFilteringLogs(true);
    if (logs) {
      const filteredLogs = logs?.filter((log) => {
        let showLog = true;
        if (filterSearch) {
          const { departmentName = '', user: { name = '' } = {}, comment = '' } = log;
          showLog = [departmentName, name, comment].some((key) => {
            if (key) {
              return key.toLowerCase().includes(filterSearch.toLowerCase());
            }
          });
        }
        return showLog;
      });
      setFilteredLogs(filteredLogs);
    } else {
      setFilteredLogs([]);
    }
    setTimeout(() => {
      setFilteringLogs(false);
    }, 0);
  }, [logs, filterSearch]);

  return (
    <ContainerView>
      <TableView>
        <Header disabled={isLoading} setFilterSearch={setFilterSearch} />
        <BackTop visibilityHeight={20}>
          <i className="icon-up-big"></i>
        </BackTop>
        <Table loading={isLoading || filteringLogs} dataSource={filteredLogs} columns={columns} rowKey="id" />
      </TableView>
    </ContainerView>
  );
};

export default AppLogsPage;
