import ScrollToTop from '@/components/ScrollToTop';
import { IAttribut } from '@/types/attribut.model';
import { IResource } from '@/types/resource.model';
import { getWindowSize } from '@/utils';
import { Button, Divider, Drawer, Form, Input, notification } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomColorPicker from '../../CustomColorPicker';
import PlaceFields from '../../PlaceFields';
import ToolFields from '../../ToolFields';

interface Props {
  className?: string;
  departmentId?: string;
  visible: boolean;
  resource: IResource | null;
  onClose: () => void;
  onSave: (result: IAttribut) => void;
}

const DrawerAttribute: React.FC<Props> = ({ className, departmentId, visible, resource, onClose, onSave }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    form.resetFields();
    const background = '#000000';
    form.setFieldsValue({
      background,
    });
  }, [visible]);

  const onFinish = async (values: any) => {
    setIsSaving(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/resources/${resource!.id!}`,
        {
          ...values,
        },
        {
          params: {
            departmentId,
          },
        },
      )
      .then((response) => {
        onSave({
          ...response.data.resource.attributes[0],
        });
        onClose();
      })
      .catch((error) => {
        console.error(error);
        const description = t('ATTRIBUTES.ADD_ATTRIBUTE_ERROR');
        notification.open({
          message: t('GLOBAL.AN_ERROR_OCCURRED'),
          description,
          type: 'error',
        });
      });
    setIsSaving(false);
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={t('ATTRIBUTES.ADD_ATTRIBUTE')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
          <Input placeholder={t('GLOBAL.NAME')} />
        </Form.Item>
        <Form.Item
          label={t('GLOBAL.COLOR')}
          shouldUpdate={(prevValues, curValues) => prevValues.background !== curValues.background}
        >
          {() => {
            const background = form.getFieldValue('background');
            return (
              <React.Fragment>
                <Form.Item name="background" style={{ display: 'none' }}>
                  <Input hidden id="skill-background" />
                </Form.Item>
                <CustomColorPicker form={form} color={background} property="background" />
              </React.Fragment>
            );
          }}
        </Form.Item>
        {resource?.resourceType === 'PLACE' && <PlaceFields form={form} />}
        {resource?.resourceType === 'TOOL' && <ToolFields form={form} />}
        <Divider />
        {resource?.fields
          ?.filter((field) => !field.locked)
          .map((field) => (
            <Form.Item
              key={`field_${field.id}`}
              name={field.name}
              label={field.label}
              rules={[{ required: field.mandatory }]}
            >
              <Input placeholder={field.label} />
            </Form.Item>
          ))}
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerAttribute;
