import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IPaySheet, IPaySheetAbsence, IPaySheetAbsenceRow, IPaySheetRow } from '@/types/pay-periods/pay-sheet.model';
import { handleError } from '@/utils';
import { Spin, Tabs } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AbsenceTable from './components/AbsenceTable';
import Header from './components/Header';
import WorkedTable from './components/WorkedTable';

interface Props {
  className?: string;
}

const AppReportsPaySheet: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const { payPeriodId } = useParams() as any;
  const [activeTab, setActiveTab] = useState<string>('worked');
  const [loading, setLoading] = useState<boolean>(false);
  const [format, setFormat] = useState<'h,m' | 'hhmm'>('hhmm');
  const history = useHistory();

  const [paySheet, setPaySheet] = useState<IPaySheet | null>(null);
  const [rows, setRows] = useState<IPaySheetRow[]>([]);
  const [rowsCopy, setRowsCopy] = useState<IPaySheetRow[]>([]);

  const [absenceSheet, setAbsenceSheet] = useState<IPaySheetAbsence | null>(null);
  const [absenceRows, setAbsenceRows] = useState<IPaySheetAbsenceRow[]>([]);
  const [absenceRowsCopy, setAbsenceRowsCopy] = useState<IPaySheetAbsenceRow[]>([]);

  useEffect(() => {
    const localStorageFormat = localStorage.getItem('payroll-reports-format');
    if (localStorageFormat && (localStorageFormat == 'hhmm' || localStorageFormat == 'h,m')) {
      setFormat(localStorageFormat);
    }
    if (location.pathname.includes('absences')) {
      onTabChange('absences');
    }
  }, [location.pathname]);

  useEffect(() => {
    getWorked();
  }, [payPeriodId]);

  const getWorked = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/reports/pay-sheets/${payPeriodId}`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setPaySheet(data);
        setRows(data.report);
        setRowsCopy(data.report);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAbsences = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/reports/pay-sheets/${payPeriodId}/absences`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setAbsenceSheet(data);
        setAbsenceRows(data.report);
        setAbsenceRowsCopy(data.report);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onTabChange = (tab: string) => {
    setActiveTab(tab);
    if (tab == 'absences') {
      history.push(`/app/reports/pay-periods/${payPeriodId}/absences`);
      getAbsences();
    }
    if (tab == 'worked') {
      history.push(`/app/reports/pay-periods/${payPeriodId}`);
      getWorked();
    }
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const formattedValue = value.toLowerCase();

    if (activeTab == 'worked') {
      if (formattedValue == '') {
        setRows(rowsCopy);
        return;
      }
      setRows(rowsCopy.filter((row) => row.name.toLowerCase().includes(formattedValue)));
    }

    if (activeTab == 'absences') {
      if (formattedValue == '') {
        setAbsenceRows(absenceRowsCopy);
        return;
      }
      setAbsenceRows(absenceRowsCopy.filter((row) => row.name.toLowerCase().includes(formattedValue)));
    }
  };

  if (!paySheet)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '15px 0' }}>
        <Spin />
      </div>
    );

  return (
    <div className={className}>
      <Header payPeriodId={payPeriodId} paySheet={paySheet} format={format} setFormat={setFormat} />
      <div className="content">
        <Tabs onChange={onTabChange} activeKey={activeTab}>
          <Tabs.TabPane tab={t('GLOBAL.WORKED_HOURS')} key="worked">
            <WorkedTable
              loading={loading}
              paySheet={paySheet}
              rows={rows}
              rowsCopy={rowsCopy}
              onSearch={onSearch}
              format={format}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('GLOBAL.ABSENCES')} key="absences">
            <AbsenceTable
              loading={loading}
              absenceSheet={absenceSheet}
              rows={absenceRows}
              rowsCopy={absenceRowsCopy}
              format={format}
              onSearch={onSearch}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default styled(AppReportsPaySheet)`
  padding: 25px;

  .content {
    margin-top: 15px;
  }

  .selector {
    display: flex;
    justify-content: flex-start;
    border: 1px solid #ccc;
    border-radius: 50px;

    button {
      padding: 5px 10px;

      &.active {
        background-color: ${colors.bluePastel};
        border-radius: 50px;
        color: #fff;
      }

      &.v2 {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
`;
