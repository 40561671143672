import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Button, DatePicker, Input, Select } from 'antd';
import { IBiDashboard } from '@/types/bi/dashboard.model';
import PickerExtraFooter from '@/pages/app/components/PickerExtraFooter';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { IBiSort } from '@/types/bi/sort.model';
import { isFeatureEnabled } from '@/utils';
import { FEATURES } from '@/types/features.model';
import DateSelectorStyled from '@/pages/app/components/DateSelector';

interface Props {
  className?: string;
  dashboard: IBiDashboard | null;
  startDate: any;
  getDashboard: () => void;
}

const Header: React.FC<Props> = ({ className, dashboard, startDate, getDashboard }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, features, groupAdminActiveUser },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const [date, setDate] = useState<moment.Moment>(moment(startDate));
  const [users, setUsers] = useState<string[]>([]);
  const [sort, setSort] = useState<IBiSort | null>(null);
  const [filteredStatus, setFilteredStatus] = useState<string[]>([]);
  const [filteredSections, setFilteredSections] = useState<string[]>([]);
  const [filteredSkills, setFilteredSkills] = useState<string[]>([]);
  const [filteredAttributes, setFilteredAttributes] = useState<string[]>([]);

  useEffect(() => {
    if (!dashboard) return;

    const localUsers = localStorage.getItem('group-admin-users');
    if (localUsers && localUsers !== 'null') {
      setUsers(JSON.parse(localUsers));
    }

    const localSort = localStorage.getItem('group-admin-sort');
    if (localSort && localSort !== 'null') {
      setSort(localSort as IBiSort);
    }

    const localOptions = localStorage.getItem('group-admin-options');
    if (localOptions && localOptions !== 'null') {
      const parsed = localOptions ? JSON.parse(localOptions) : [];
      onOptionsChange(parsed);
    }

    onFilter();
  }, [dashboard]);

  useEffect(() => {
    if (!startDate) return;
    onDateChanged(moment(startDate));
  }, [startDate]);

  const onDateChanged = (date: any) => {
    if (!date) return;
    getDashboard();
    setDate(date);
    dispatch({
      type: 'SET_GROUP_ADMIN_START',
      payload: date,
    });
  };

  const onUsersChange = (users: string[]) => {
    localStorage.setItem('group-admin-users', JSON.stringify(users));
    setUsers(users);
  };

  const onSortChange = (value: string) => {
    if (!value) setSort(null);
    else setSort(value as IBiSort);
    localStorage.setItem('group-admin-sort', value);
  };

  const onOptionsChange = (options: string[]) => {
    const status = options.filter((option) => option.includes('status'));
    const sections = options.filter((option) => option.includes('sections'));
    const skills = options.filter((option) => option.includes('skills'));
    const attributes = options.filter((option) => option.includes('attributes'));

    localStorage.setItem('group-admin-options', JSON.stringify(options));

    setFilteredStatus(status.map((section) => section.split('.')[1]));
    setFilteredSections(sections.map((section) => section.split('.')[1]));
    setFilteredSkills(skills.map((section) => section.split('.')[1]));
    setFilteredAttributes(attributes.map((section) => section.split('.')[1]));
  };

  const onAddFilter = () => {
    setFiltersVisible(true);
  };

  const onFilter = () => {
    const newStart = date.format('YYYY-MM-DD');
    history.push(`/app/hours/group-admin/${newStart}`);
    dispatch({
      type: 'SET_GROUP_ADMIN_START',
      payload: date,
    });
    dispatch({
      type: 'SET_GROUP_ADMIN_USERS',
      payload: users,
    });
    dispatch({
      type: 'SET_GROUP_ADMIN_SORT',
      payload: sort,
    });
    dispatch({
      type: 'SET_GROUP_ADMIN_OPTIONS',
      payload: {
        sections: filteredSections,
        status: filteredStatus,
        skills: filteredSkills,
        attributes: filteredAttributes,
      },
    });
    if (startDate !== newStart) {
      getDashboard();
    }
  };

  const onArrowClick = (value: number) => {
    let newDate = moment(date.format());
    if (value == -1) {
      newDate.add(-1, 'day');
    } else {
      newDate.add(1, 'day');
    }
    getDashboard();
    setDate(newDate);
    dispatch({
      type: 'SET_GROUP_ADMIN_START',
      payload: newDate,
    });
  };

  return (
    <div className={className}>
      <h2 className="title">Group admin</h2>
      {!groupAdminActiveUser && (
        <div className="filters">
          <div className="top">
            <div className="date-container">
              <DateSelectorStyled
                picker="day"
                startDate={date}
                endDate={date}
                onArrowClick={onArrowClick}
                onDatePickerChange={onDateChanged}
              />
            </div>
            <Select
              placeholder={t('GLOBAL.USERS')}
              className="filter"
              mode="multiple"
              optionFilterProp="children"
              allowClear
              showSearch
              maxTagCount={1}
              onChange={onUsersChange}
              value={
                dashboard && localStorage.getItem('group-admin-users')
                  ? JSON.parse(localStorage.getItem('group-admin-users') as string)
                  : []
              }
            >
              {dashboard?.departments.map((department) => {
                let users = department.users;
                return (
                  <Select.OptGroup key={department.name}>
                    {users.map((user) => (
                      <Select.Option key={user.id} value={user.id}>
                        {user.name}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                );
              })}
            </Select>
            <Select
              mode="multiple"
              className="filter"
              placeholder={`${t('GLOBAL.SECTIONS')}, ${t('GLOBAL.STATUS')}, ${t('GLOBAL.SKILLS')}`}
              allowClear
              showSearch
              optionFilterProp="children"
              maxTagCount={1}
              onChange={onOptionsChange}
              value={
                dashboard && localStorage.getItem('group-admin-options')
                  ? JSON.parse(localStorage.getItem('group-admin-options') as string)
                  : []
              }
            >
              {dashboard?.departments.map((department) => {
                if (!department.categories) return;
                return (
                  <React.Fragment key={department.id}>
                    <Select.OptGroup key={`${department.name} - ${t('GLOBAL.STATUS')}`}>
                      {department.categories.map((status) => {
                        return (
                          <Select.Option key={status.id} value={`status.${status.id}`}>
                            {status.name}
                          </Select.Option>
                        );
                      })}
                    </Select.OptGroup>
                    {isFeatureEnabled(features, FEATURES.SECTIONS) && department.sections && (
                      <Select.OptGroup key={`${department.name} - ${t('GLOBAL.SECTIONS')}`}>
                        {department.sections.map((section) => {
                          return (
                            <Select.Option key={section.id} value={`sections.${section.id}`}>
                              {section.name}
                            </Select.Option>
                          );
                        })}
                      </Select.OptGroup>
                    )}
                    {isFeatureEnabled(features, FEATURES.SKILLS) && department.skills && (
                      <Select.OptGroup key={`${department.name} - ${t('GLOBAL.SKILLS')}`}>
                        {department.skills.map((skill) => {
                          return (
                            <Select.Option key={skill.id} value={`skills.${skill.id}`}>
                              {skill.name}
                            </Select.Option>
                          );
                        })}
                      </Select.OptGroup>
                    )}
                    {isFeatureEnabled(features, FEATURES.ATTRIBUTES) && department.attributes && (
                      <Select.OptGroup key={`${department.name} - ${t('GLOBAL.ATTRIBUTES')}`}>
                        {department.attributes.map((attribute) => {
                          return (
                            <Select.Option key={attribute.id} value={`attributes.${attribute.id}`}>
                              {attribute.name}
                            </Select.Option>
                          );
                        })}
                      </Select.OptGroup>
                    )}
                  </React.Fragment>
                );
              })}
            </Select>
            <Select
              className="filter"
              placeholder={t('GLOBAL.SORT_BY')}
              allowClear
              onChange={onSortChange}
              value={
                dashboard &&
                localStorage.getItem('group-admin-sort') &&
                localStorage.getItem('group-admin-sort') !== 'null' &&
                localStorage.getItem('group-admin-sort') !== 'undefined'
                  ? (localStorage.getItem('group-admin-sort') as string)
                  : undefined
              }
            >
              {Object.values(IBiSort).map((sort) => {
                if (!isFeatureEnabled(features, FEATURES.SECTIONS) && sort == IBiSort.SECTIONS) return;
                if (!isFeatureEnabled(features, FEATURES.SKILLS) && sort == IBiSort.SKILLS) return;
                return (
                  <Select.Option key={sort} value={sort}>
                    {t(`GLOBAL.SORT_OPTIONS.${sort.toUpperCase()}`)}
                  </Select.Option>
                );
              })}
            </Select>
            <Button type="primary" onClick={onFilter}>
              {t('GLOBAL.FILTER')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default styled(Header)`
  .title {
    font-weight: bold;
  }

  .filters {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .date-container {
        flex: 1;

        .datePicker {
          flex: 1;
        }
      }

      .filter {
        flex: 1;
      }
    }
  }
`;
