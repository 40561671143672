import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children?: any;
}

const Content: React.FC<Props> = ({ className, children }) => {
  return (
    <div className={className}>
      <div className="wrapper">{children}</div>
    </div>
  );
};

const ContentStyled = styled(Content)`
  background-color: rgb(240, 245, 250);
  min-height: 100vh;
  padding-top: 50px;
`;

export default ContentStyled;
