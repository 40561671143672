import { IDepartmentSettings } from '@/types/department-settings.model';
import { Modal, Form, Select, InputNumber } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }: { form: FormInstance; visible: boolean }) => {
  const prevVisibleRef = useRef(false);
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;

  useEffect(() => {
    if (visible && !prevVisible) {
      form.resetFields();
      form.setFieldsValue({
        unit: 'day',
        value: 1,
      });
    }
  }, [visible]);
};

interface ModalFormProps {
  visible: boolean;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
  onCancel: () => void;
}

const ModalNotification: React.FC<ModalFormProps> = ({ visible, currentSettings, updateCurrentSettings, onCancel }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    const values = form.getFieldsValue();
    const alerts = [...(currentSettings?.alerts || [])];
    updateCurrentSettings({
      alerts: [
        ...alerts,
        {
          value: values.value,
          unit: values.unit,
        },
      ],
    } as IDepartmentSettings);
    onCancel();
  };

  return (
    <Modal
      title={t('SETTINGS.ACCOUNT.NOTIFICATIONS.ADD_SHIFT_ALERT')}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" name="notificationForm">
        <div>
          <Form.Item name="value" style={{ display: 'inline-block', marginRight: 5 }} rules={[{ required: true }]}>
            <InputNumber type="number" min={1} step={1} />
          </Form.Item>
          <Form.Item name="unit" style={{ display: 'inline-block' }}>
            <Select style={{ minWidth: 150 }}>
              <Option value="hour">{t('SETTINGS.ACCOUNT.NOTIFICATIONS.HOUR(S)_BEFORE')}</Option>
              <Option value="day">{t('SETTINGS.ACCOUNT.NOTIFICATIONS.DAY(S)_BEFORE')}</Option>
            </Select>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalNotification;
