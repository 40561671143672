import colors from '@/styles/colors';
import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
  onClose: () => void;
}

const ModalPayingOption: React.FC<Props> = ({ visible, className, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal className={className} visible={visible} footer={null} onCancel={onClose} width={'40%'}>
      <div className="container">
        <h1 className="title">{t('GLOBAL.THANK_YOU')}</h1>
        <h2>{t('SETTINGS.ACCOUNT.GENERAL.PAID_OPTION_CONFIRM.MODAL.CONTENT')}</h2>
        <Button className="button" type="primary" size="large" onClick={onClose} style={{ marginTop: 25 }}>
          {t('GLOBAL.CLOSE')}
        </Button>
      </div>
    </Modal>
  );
};

export default styled(ModalPayingOption)`
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    text-align: center;

    .title {
      text-transform: uppercase;
      color: ${colors.green};
      font-weight: bold;
    }
  }
`;
