import DrawerSection from '@/pages/app/components/drawers/Section';
import { Select, Form } from 'antd';
import React, { useContext, useState } from 'react';
import AppContext from '@/pages/app/context';
import { FormInstance } from 'antd/lib/form';
import { ISection } from '@/types/section.model';
import { useTranslation } from 'react-i18next';

const Option = Select.Option;

interface Props {
  form: FormInstance;
}

const Sections: React.FC<Props> = ({ form }) => {
  const {
    state: { sections, loadingSections },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: true });

  const [drawerSectionIsVisible, setDrawerSectionIsVisible] = useState<boolean>(false);

  const onCreateSection = (result: ISection) => {
    const sectionsCopy = [...sections];
    sectionsCopy.push(result);
    const sectionsCopySorted = sectionsCopy.sort((a, b) => {
      if (a.name!.toLowerCase() < b.name!.toLowerCase()) {
        return -1;
      }
      if (a.name!.toLowerCase() > b.name!.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    appContextDispatch({
      type: 'SET_SECTIONS',
      payload: sectionsCopySorted,
    });

    const userSectionsCopy = [...form.getFieldValue(['sections'])];
    userSectionsCopy.push(result.id);
    form.setFieldsValue({ sections: userSectionsCopy });
  };

  return (
    <React.Fragment>
      <h4>{t('GLOBAL.SECTIONS')}</h4>
      {!!sections?.length && (
        <Form.Item name="sections" style={{ marginBottom: 0 }}>
          <Select
            loading={loadingSections}
            disabled={loadingSections}
            getPopupContainer={(trigger) => trigger}
            placeholder={t('FORMS.SECTIONS_PLACEHOLDER')}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            suffixIcon={<i className="icon-search" />}
          >
            {sections.map((section) => (
              <Option value={section.id!} key={`section_${section.id}`}>
                {section.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <button
        className="green"
        style={{ marginTop: 8, marginBottom: 15 }}
        onClick={() => setDrawerSectionIsVisible(!drawerSectionIsVisible)}
      >
        + {t('SECTIONS.ADD_NEW_SECTION')}
      </button>
      <DrawerSection
        visible={drawerSectionIsVisible}
        onClose={() => setDrawerSectionIsVisible(false)}
        onSave={onCreateSection}
      />
    </React.Fragment>
  );
};

export default Sections;
