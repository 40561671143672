import { default as Colors, default as colors } from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { IShift } from '@/types/shift.model';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { Dispatch, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled, { CSSProperties } from 'styled-components';
import ShiftRightClick from '../../components/ShiftRightClick';
import { ActionType } from '../../redux/actions';
import { InitialStateType } from '../../redux/store';
import ShiftTooltip from './ShiftTooltip';
// import { getTextColor } from '@/utils';
import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled } from '@/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  showComment?: boolean;
  onDragStartHandler: (e: React.DragEvent, shiftId: string) => void;
  onDragEndHandler: (e: React.DragEvent) => void;
  shift: IShift;
  activeDepartment?: IDepartment;
  activeSection?: string;
  closeMonthRightMenu?: () => void;
}

const Shift: React.FC<Props> = ({
  className,
  showComment,
  shift,
  activeDepartment,
  activeSection,
  onDragStartHandler,
  onDragEndHandler,
  closeMonthRightMenu,
}) => {
  const { t } = useTranslation();
  const {
    state: { features },
  } = useContext(AppContext);
  const {
    selectedShifts,
    selected_shifts_on_shift_key,
    shift_blocks,
    showMonthlyHours,
    showMonthlyStart,
  } = useSelector(
    ({
      shift_blocks,
      selectedShifts,
      selected_shifts_on_shift_key,
      showMonthlyHours,
      showMonthlyStart,
    }: InitialStateType) => ({
      selectedShifts,
      showMonthlyHours,
      shift_blocks,
      selected_shifts_on_shift_key,
      showMonthlyStart,
    }),
    shallowEqual,
  );

  const {
    state: { sections },
  } = useContext(AppContext);

  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const { start: shiftStart, end: shiftEnd, price, pause } = shift;
  const duration = moment.duration(shiftEnd! - shiftStart!, 'seconds');
  duration.subtract(pause!.unpaid!, 'seconds');

  const section = sections?.find((sect) => sect.id == activeSection);

  const onClick = (e: React.MouseEvent) => {
    if (!e.shiftKey) {
      if (selected_shifts_on_shift_key?.includes(shift)) {
        hoursDispatch({
          type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
          payload: selected_shifts_on_shift_key.filter((s) => shift.id !== s.id),
        });
      } else {
        if (selected_shifts_on_shift_key) {
          hoursDispatch({
            type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
            payload: [...selected_shifts_on_shift_key, shift],
          });
        } else {
          hoursDispatch({
            type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
            payload: [shift],
          });
        }
      }
    } else {
      hoursDispatch({
        type: 'SET_SELECTED_SHIFT',
        payload: shift,
      });
      hoursDispatch({
        type: 'SET_SHIFT_DRAWER_VISIBLE',
        payload: true,
      });
    }
  };

  let style: CSSProperties = {};
  if (shift.background) {
    style = { backgroundColor: shift.background };
  }

  if (shift.package) {
    style = {
      backgroundColor: shift.background ? shift.background : shift.package.background,
    };
  }

  if (shift.locked) {
    style.backgroundColor = '#bdbcc657';
    if (activeDepartment!.role! !== 'ADMIN') {
      style.cursor = 'not-allowed';
    }
  }

  if (shift.package) {
    const { background } = shift.package;
    style.backgroundColor = background;
    // style.color = getTextColor(background);
  }

  if (shift.dayoff) {
    if (shift.package) {
      const { background } = shift.package;
      if (background) {
        style.background = background;
        // style.color = getTextColor(background);
      } else {
        style.background = '#ed6f5d';
        style.color = '#fff';
      }
    } else {
      style.background = '#ed6f5d';
    }
  }

  if (selected_shifts_on_shift_key?.some((s) => s.id == shift.id)) {
    style.backgroundColor = 'blue';
  }

  return (
    <ShiftRightClick shift={shift} closeMonthRightMenu={closeMonthRightMenu}>
      <Tooltip color="#f7f9ff" title={<ShiftTooltip shift={shift} />} mouseEnterDelay={0.5}>
        <div
          className={`
        ${className}
        ${shift.optimistic ? 'optimistic' : ''}
        ${shift.userRecordId ? '' : 'open-shift'}
        ${shift.dayoff ? 'dayoff' : ''}
        ${shift.approved ? '' : 'not-approved'}
        ${shift.request ? 'request' : ''}
        ${
          activeDepartment?.scheduleParams?.ghost_shifts && activeSection && activeSection !== shift.section?.id
            ? 'other-section'
            : ''
        }
        ${
          isFeatureEnabled(features, FEATURES.DIMONA) &&
          shift.dimona &&
          shift.dimonaQualify &&
          !shift.dimonaValid &&
          'invalid-dimona'
        }
      `}
          draggable={shift.locked ? activeDepartment!.role! === 'ADMIN' && 'true' : 'true'}
          onDragStart={(e) => {
            if ((shift.locked && activeDepartment!.role! === 'ADMIN') || !shift.locked) {
              onDragStartHandler(e, shift.id!);
            }
          }}
          onDragEnd={(e) => {
            if ((shift.locked && activeDepartment!.role! === 'ADMIN') || !shift.locked) {
              onDragEndHandler(e);
            }
          }}
          onClick={(e) => {
            if ((shift.locked && activeDepartment!.role! === 'ADMIN') || !shift.locked) {
              onClick(e);
            }
          }}
          style={style}
        >
          {section && shift.section && shift.section.id !== section.id && (
            <div className="section-label" style={{ backgroundColor: section.background }}></div>
          )}
          {shift.shyftType == 2 ? (
            <div
              className="card"
              style={{
                display: showMonthlyHours || showMonthlyStart ? 'flex' : 'block',
                flexDirection: showMonthlyHours || showMonthlyStart ? 'column' : 'row',
                alignItems: 'center',
              }}
            >
              {!showMonthlyHours && (
                <span style={{ lineHeight: '10px' }}>
                  {shift.package?.shortcode
                    ? shift.package.shortcode
                    : shift.dayoff?.shortCode
                    ? shift.dayoff?.shortCode
                    : ''}
                </span>
              )}
              {(showMonthlyHours || showMonthlyStart) && (
                <span style={{ lineHeight: '10px', fontSize: 10 }}>{moment.unix(shift.start!).format('HHmm')}</span>
              )}
              {showMonthlyHours && (
                <span style={{ lineHeight: '10px', fontSize: 10 }}>{moment.unix(shift.end!).format('HHmm')}</span>
              )}
            </div>
          ) : (
            <div
              className="card"
              style={{
                display: showMonthlyHours || showMonthlyStart ? 'flex' : 'block',
                flexDirection: showMonthlyHours || showMonthlyStart ? 'column' : 'row',
                alignItems: 'center',
              }}
            >
              {!showMonthlyHours && (
                <span style={{ lineHeight: '10px' }}>
                  {shift.package
                    ? shift.package.shortcode
                      ? shift.package.shortcode
                      : shift.skills?.length && showMonthlyHours
                      ? shift.skills.map((skill) => skill.name![0]).join('')
                      : t('SCHEDULE.MONTHLY_VIEW.WORK_SHORT')
                    : shift.skills?.length && showMonthlyHours
                    ? shift.skills.map((skill) => skill.name![0]).join('')
                    : t('SCHEDULE.MONTHLY_VIEW.WORK_SHORT')}
                </span>
              )}
              {(showMonthlyHours || showMonthlyStart) && (
                <span style={{ lineHeight: '10px', fontSize: 10 }}>{moment.unix(shift.start!).format('HHmm')}</span>
              )}
              {showMonthlyHours && (
                <span style={{ lineHeight: '10px', fontSize: 10 }}>{moment.unix(shift.end!).format('HHmm')}</span>
              )}
            </div>
          )}
        </div>
      </Tooltip>
    </ShiftRightClick>
  );
};

export default styled(React.memo(Shift))`
  position: relative;
  background: #01a750;
  border-radius: 5px;
  width: 90%;
  max-height: 35px;
  margin: 1px 0;
  text-align: left;
  color: #fff;
  cursor: grab;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media print {
    font-size-adjust: 0.5;
  }

  @media screen {
    
  .section-label {
    position: absolute;
    left: 0;
    width: 20%;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 2px solid #fff;
  }

  &.open-shift {
    background-color: white;
    border: 1px solid ${Colors.greenLight};
  }

  &.month {
    width: 100px;
  }

  &.optimistic {
    pointer-events: none;
    animation: blink 1s infinite alternate-reverse;
  }

  &.other-section {
    opacity: 0.15;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }

  &.invalid-dimona {
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      right: -10px;
      top: -10px;
      transform: rotate(45deg);
      background-color: #ed6f5d;
      overflow: hidden;
    }
  }

  &.not-approved {
    &:before {
      content: '';
      top: 0;
      left: 0;
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      border: 3px dashed ${Colors.blueLightPastel};
      border-radius: 5px;
      pointer-events: none;
    }
  }

  &.request {
    background-color: #b3e6ef;
  }

  &.dayoff {
    .detail-dayoff {
      font-weight: normal;
      font-size: 10px;
      color: ${colors.grey};
      line-height: 12px;
    }

    background-image: repeating-linear-gradient(
      -55deg,
      transparent,
      transparent 10px,
      rgba(0, 0, 0, 0.03) 10px,
      rgba(0, 0, 0, 0.03) 20px
    );

    background-image: repeating-linear-gradient(-55deg, transparent 0 10px, rgba(0, 0, 0, 0.03) 10px 20px);

    @media print {
      background-image: none;
      background-color: antiquewhite;
    }
  }

  .shift-details {
    font-size: 10px;
    color: gray;
    font-weight: 100 !important;
  }

  .ant-checkbox-wrapper {
    right: 3px;
    top: 0px;
    position: absolute;

    &.ant-checkbox-wrapper-checked:hover {
      border: none !important;
    }

    .ant-checkbox:hover::after,
    .ant-checkbox-wrapper:hover .ant-checkbox::after,
    .ant-checkbox-checked::after {
      visibility: hidden !important;
      border: none !important;
    }
    .ant-checkbox {
      padding: 3px;
    }
  }

  .section {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    border-right: 5px solid black;
    pointer-events: none;
  }

  .card {
    span {

    }
    h3 {
      font-weight: 600;
      color: black;
      font-size: 22px;
      margin: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      .smaller {
        font-size: 16px;
      }

      .devise {
        margin-left: 5px;
        background-color: ${colors.blue};
        font-size: 12px;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
      }
    }

    p {
      margin-bottom: 4px;
      &.dayoff-label {
        color: ${Colors.grey};
        white-space: nowrap;
        overflow: hidden;
        font-size: 11px;
      }

      > div {
        &:nth-of-type(n + 2) {
          margin-top: 2px;
          span {
            display: inline-block;
            &:nth-of-type(n - 1) {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .comment {
    color: #444444;
    font-size: 12px;
    line-height: 16px;
    .ant-divider-horizontal {
      margin: 10px 0 8px;
    }
  }

  .count {
    background: #d0d3dd;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    float: right;
    color: #66676a;
    font-size: 11px;
    font-weight: bold;
    padding: 0px 12px;
    margin-top: 5px;
    border: 1px solid white;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.6;
    }
  }

  p {
    font-size: 13px;
    font-weight: normal;
  }
`;
