import React, { useEffect, useState, useContext, useRef } from 'react';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-timezone';
import TableView from '@/layouts/TableView';
import { BackTop, message, Table } from 'antd';
import Header from './components/Header';
import { IUser } from '@/types/user.model';
import axios from 'axios';
import DisplayNameColumn from '../../components/DisplayNameColumn';
import ModalUpdateClocking from './components/ModalUpdateClocking';
import { minutesToHoursAndMinutes2 } from '@/utils';
import TableRowActions from './components/TableRowActions';
import ModalClockingToAttach from '../../reports/timesheets/default/ModalClockingToAttach';
import colors from '@/styles/colors';
import ClockinAnomaliesPage from '../clockin-anomalies';
moment.tz.setDefault('Atlantic/Reykjavik');

const AppHoursClockingValidationPage: React.FC = () => {
  const {
    state: { activeDepartmentId, activeSection, userCategories },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<IClockingValidation[]>([]);
  const [dataSourceCopy, setDataSourceCopy] = useState<IClockingValidation[]>([]);
  const refDataSource = useRef<any[]>(dataSource);
  refDataSource.current = dataSource;
  const [loadingDataSource, setLoadingDataSource] = useState<boolean>(false);
  const [selectedClocking, setSelectedClocking] = useState<IClockingValidation | null>(null);
  const [clockingToAttach, setClockingToAttach] = useState<string | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [onlyAnomalies, setOnlyAnomalies] = useState<boolean>(localStorage.getItem('only-anomalies') == 'true');
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingDataSource(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/clocking-validation`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
          sectionId: activeSection ? activeSection : undefined,
        },
      })
      .then(({ data }) => {
        if (mounted) {
          if (onlyAnomalies) {
            setDataSource(data.filter((el: IClockingValidation) => !el.shift));
          } else {
            setDataSource(data);
          }
          setDataSourceCopy(data);
          setLoadingDataSource(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
          console.error(error);
        }
        if (mounted) {
          setLoadingDataSource(false);
        }
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId, counter, activeSection]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.NAME'),
        key: 'displayName',
        // eslint-disable-next-line react/display-name
        render: (_text: string, record: any) => {
          const { user, shift } = record;
          return <DisplayNameColumn user={user} shift={shift} page="clocking-validation" />;
        },
        sorter: (a: IClockingValidation, b: IClockingValidation) => {
          if (a.user.displayName && b.user.displayName) {
            return a.user.displayName?.localeCompare(b.user.displayName);
          }
        },
      },
      {
        title: t('GLOBAL.USER_CATEGORY_SHORT'),
        key: 'status',
        dataIndex: ['user', 'category', 'name'],
        render: (_text: string, record: IClockingValidation) => {
          return <span style={{ textTransform: 'capitalize' }}>{record.user.category?.name}</span>;
        },
        onFilter: (value: any, record: IClockingValidation) => record.user.category?.id === value,
        filters: userCategories.map((userCategory) => ({
          value: userCategory.id,
          text: `${
            userCategory.name &&
            userCategory.name.charAt(0).toUpperCase() + userCategory.name &&
            userCategory.name.slice(1)
          }`,
        })),
        sorter: (a: IClockingValidation, b: IClockingValidation) => {
          if (a.user.category?.name && b.user.category?.name) {
            return a.user.category?.name.localeCompare(b.user.category?.name);
          }
        },
      },
      {
        title: t('GLOBAL.CONTRACT'),
        key: 'contract',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IClockingValidation) => {
          if (record.contract) {
            return record.contract + t('GLOBAL.HOUR_UNIT');
          }
          return null;
        },
        sorter: (a: IClockingValidation, b: IClockingValidation) => {
          if (a.contract && b.contract) {
            return a.contract - b.contract;
          }
        },
      },
      {
        title: t('GLOBAL.DATE'),
        dataIndex: 'date',
        key: 'date',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IClockingValidation) => {
          return <span>{moment.unix(record.start).format('L')}</span>;
        },
        sorter: (a: IClockingValidation, b: IClockingValidation) => {
          if (a.start && b.start) {
            return a.start - b.start;
          }
        },
      },
      {
        title: t('GLOBAL.SHIFT'),
        key: 'shift',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IClockingValidation) => {
          if (record.shift) {
            return (
              <span>
                {moment.unix(record.shift?.start).format('HH:mm')} - {moment.unix(record.shift?.end).format('HH:mm')}
              </span>
            );
          } else {
            return <i className="icon-attention" style={{ color: colors.red }} />;
          }
        },
        onFilter: (value: any, record: IClockingValidation) => {
          if (value == 'shift') {
            return record.shift !== undefined;
          }

          if (value == 'no-shift') {
            return record.shift == undefined;
          }
        },
        filters: [
          { text: t('GLOBAL.SHIFT'), value: 'shift' },
          { text: t('GLOBAL.FREE_SHIFT'), value: 'no-shift' },
        ],
      },
      {
        title: t('GLOBAL.UNPAID_BREAK_SHORT'),
        key: 'break',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IClockingValidation) => {
          if (record.shift) {
            return Math.round(record.shift?.breaks || 0) + 'm';
          } else {
            return <i className="icon-attention" style={{ color: colors.red }} />;
          }
        },
      },
      {
        title: t('GLOBAL.PLANNED'),
        key: 'planned',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IClockingValidation) => {
          if (record.shift) {
            const { start, end, breaks = 0 } = record.shift;
            let diff = end - start;
            diff = diff ? diff / 60 : 0;
            diff -= breaks;
            return minutesToHoursAndMinutes2(diff, true).replace('+', '');
          } else {
            return <i className="icon-attention" style={{ color: colors.red }} />;
          }
        },
      },
      {
        title: t('GLOBAL.CLOCKING'),
        key: 'clocking',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IClockingValidation) => {
          return (
            <span>
              {moment.unix(record.start).format('HH:mm')} - {moment.unix(record.end).format('HH:mm')}
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.BREAKS'),
        key: 'breaks',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IClockingValidation) => {
          return Math.round(record.breaks || 0) + 'm';
        },
      },
      {
        title: t('SCHEDULE.WORKTIME_SHORT'),
        key: 'real',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IClockingValidation) => {
          const { start, end, breaks = 0 } = record;
          let diff = end - start;
          diff = diff ? diff / 60 : 0;
          diff -= breaks;
          return minutesToHoursAndMinutes2(diff, true).replace('+', '');
        },
      },
      {
        title: 'Diff.Plan.',
        key: 'plannedDiff',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IClockingValidation) => {
          if (record.shift) {
            const {
              start,
              end,
              breaks = 0,
              shift: { start: shiftStart, end: shiftEnd, breaks: shiftBreaks = 0 },
            } = record;
            let clockingDiff = end - start;
            clockingDiff = clockingDiff ? clockingDiff / 60 : 0;
            clockingDiff -= breaks;
            let shiftDiff = shiftEnd - shiftStart;
            shiftDiff = shiftDiff ? shiftDiff / 60 : 0;
            shiftDiff -= shiftBreaks;
            return (
              <span
                style={{
                  backgroundColor:
                    clockingDiff - shiftDiff < 0
                      ? 'rgb(221, 250, 230)'
                      : clockingDiff - shiftDiff == 0
                      ? 'transparent'
                      : 'rgb(250, 221, 221)',
                  padding: '2px 5px',
                }}
              >
                {minutesToHoursAndMinutes2(clockingDiff - shiftDiff, true)}
              </span>
            );
          } else {
            return null;
          }
        },
        filters: [
          { text: t('GLOBAL.POSITIVES'), value: 'positive' },
          { text: t('GLOBAL.NEGATIVES'), value: 'negative' },
          { text: t('GLOBAL.NULL'), value: 'null' },
          { text: t('GLOBAL.NONE'), value: 'none' },
        ],
        onFilter: (value: string, record: IClockingValidation) => {
          if (record.shift) {
            const {
              start,
              end,
              breaks = 0,
              shift: { start: shiftStart, end: shiftEnd, breaks: shiftBreaks = 0 },
            } = record;
            let clockingDiff = end - start;
            clockingDiff = clockingDiff ? clockingDiff / 60 : 0;
            clockingDiff -= breaks;
            let shiftDiff = shiftEnd - shiftStart;
            shiftDiff = shiftDiff ? shiftDiff / 60 : 0;
            shiftDiff -= shiftBreaks;

            if (value == 'positive') {
              return clockingDiff - shiftDiff > 0;
            }

            if (value == 'negative') {
              return clockingDiff - shiftDiff < 0;
            }

            if (value == 'null') {
              return clockingDiff - shiftDiff == 0;
            }
          }

          if (value == 'none') {
            return record.shift == undefined;
          }
        },
      },
      {
        key: 'actions',
        align: 'right',
        // eslint-disable-next-line react/display-name
        render: (_text: string, clocking: IClockingValidation) => (
          <TableRowActions
            departmentId={activeDepartmentId}
            clocking={clocking}
            handleValidate={onValidate}
            handleCopyFromShift={onCopyFromShift}
            setClockingToAttach={setClockingToAttach}
            handleDelete={onDelete}
            setSelectedClocking={setSelectedClocking}
          />
        ),
      },
    ]);
  }, [i18n.language, dataSource]);

  const onRow = (clocking: IClockingValidation) => {
    return {
      onClick: () => {
        setSelectedClocking(clocking);
      },
    };
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IClockingValidation[]) => {
      setSelectedRowKeys(selectedRows.map((x) => x.id));
    },
  };

  const onValidate = (clockingIds: string[]) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/operations/clocking-validation`,
        {
          clockingIds,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then((response) => {
        message.info(response.data.message);
        removeClockingFromDataSource(clockingIds);
        return response;
      });
  };

  const onCopyFromShift = (clockingId: string) => {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/operations/clocking-validation/${clockingId}`,
        {
          copyFromShift: true,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then((response) => {
        message.info(response.data.message);
        removeClockingFromDataSource([clockingId]);
        return response;
      });
  };

  const onModify = (clockingId: string, values: any) => {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/operations/clocking-validation/${clockingId}`,
        {
          ...values,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then((response) => {
        message.info(response.data.message);
        removeClockingFromDataSource([clockingId]);
        return response;
      });
  };

  const onDelete = (clockingId: string) => {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/operations/clocking-validation/${clockingId}`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then((response) => {
        message.info(response.data.message);
        removeClockingFromDataSource([clockingId]);
        return response;
      });
  };

  const removeClockingFromDataSource = (clockingIds: string[]) => {
    const dataSource = [...refDataSource.current.filter((x) => !clockingIds.includes(x.id))];
    setDataSource(dataSource);
    setDataSourceCopy(dataSource);
  };

  const onOnlyAnomalies = () => {
    setOnlyAnomalies(!onlyAnomalies);
    if (!onlyAnomalies) {
      setDataSource([...refDataSource.current.filter((el) => !el.shift)]);
      localStorage.setItem('only-anomalies', 'true');
    } else {
      setDataSource(dataSourceCopy);
      localStorage.removeItem('only-anomalies');
    }
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header
        selectedRowKeys={selectedRowKeys}
        handleValidate={onValidate}
        onlyAnomalies={onlyAnomalies}
        setOnlyAnomalies={onOnlyAnomalies}
      />
      <Table
        loading={loadingDataSource}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        onRow={onRow}
        rowClassName="row-clickable"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowKey={(record) => record.id}
      />
      <ModalUpdateClocking
        clocking={selectedClocking}
        onClose={() => setSelectedClocking(null)}
        onAfterSaved={() => {}}
        handleCopyFromShift={onCopyFromShift}
        handleModify={onModify}
      />
      <ModalClockingToAttach
        departmentId={activeDepartmentId}
        clockingId={clockingToAttach}
        onSave={() => {
          setCounter(counter + 1);
        }}
        onClose={() => {
          setClockingToAttach(null);
        }}
      />
    </TableView>
  );
};

export default AppHoursClockingValidationPage;

export interface IClockingValidation {
  id: string;
  user: IUser;
  start: number;
  end: number;
  breaks: number;
  shift?: {
    start: number;
    end: number;
    breaks: number;
  };
  contract?: number;
}
