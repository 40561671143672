import AppContext from '@/pages/app/context';
import { ITurnoverTemplate } from '@/types/insights/turnover-template.model';
import { WEEKDAYS, createNumberRangeArray, handleError } from '@/utils';
import { Button, Form, Input, InputNumber, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/es/table/interface';
import axios from 'axios';
import { uniqueId } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import TurnoverTemplateBreadcrumb from './TurnoverTemplateBreadcrumb';

interface Props {
  className?: string;
}

const TurnoverTemplate: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, turnoverTemplates },
    dispatch,
  } = useContext(AppContext);
  const [form] = useForm();
  const history = useHistory();
  const { templateId } = useParams() as any;

  const [activeTemplate, setActiveTemplate] = useState<ITurnoverTemplate | null>(null);
  const [columns, setColumns] = useState<ColumnsType<any[]>>();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const hours = createNumberRangeArray(0, 23);

  useEffect(() => {
    getActiveTemplateDetails();
  }, [templateId]);

  const getActiveTemplateDetails = () => {
    if (!templateId) return;
    axios
      .get(`${process.env.REACT_APP_API_URL_V4}/turnover-quota-templates/${templateId}`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setActiveTemplate(data);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (!activeTemplate || !activeTemplate.details) return;
    let result: ColumnsType<any[]> = [];

    hours.forEach((hour, index) => {
      const fullHour = `${hour <= 9 ? `0${hour}` : hour}`;
      result.push({
        className: 'input-cell',
        key: uniqueId(),
        title: `${fullHour}${t('GLOBAL.HOURS_SHORT')}`,
        render(value, record, index) {
          const detail = activeTemplate.details?.find(
            (d) => d.day_in_week == String(index + 1) && String(hour) == d.hour,
          );
          if (!detail) return;
          return (
            <Form.Item name={['data', detail.id]} style={{ width: 50 }}>
              <InputNumber min={0} />
            </Form.Item>
          );
        },
      });
    });

    setColumns([
      ...[
        {
          key: 'day',
          dataIndex: 'day',
        },
      ],
      ...result,
    ]);
  }, [activeTemplate]);

  useEffect(() => {
    const data = WEEKDAYS.map((day) => {
      return {
        id: day,
        day: t(`GLOBAL.${day.toUpperCase()}`),
      };
    });
    setData(data);
  }, []);

  useEffect(() => {
    if (!activeTemplate) return;

    form.setFieldsValue({
      ...activeTemplate,
    });

    if (activeTemplate.details) {
      activeTemplate.details.forEach(({ id, value }) => {
        form.setFieldsValue({
          data: {
            [id]: value,
          },
        });
      });
    }
  }, [activeTemplate]);

  const onFinish = (values: any) => {
    if (!activeTemplate) return;

    const data = {
      name: values.name,
      details: Object.entries(values.data).map((entry) => ({
        id: entry[0],
        value: entry[1],
      })),
    };
    setLoading(true);

    axios
      .patch(
        `${process.env.REACT_APP_API_URL_V4}/turnover-quota-templates/${activeTemplate.id}`,
        {
          ...data,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        history.push(`/app/settings/insights/turnover-templates`);
        dispatch({
          type: 'SET_TURNOVER_TEMPLATES',
          payload: [...turnoverTemplates.filter((template) => template.id !== data.template.id), { ...data.template }],
        });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <TurnoverTemplateBreadcrumb activeTemplate={activeTemplate} />
      <div className="container">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item className="name-container" name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
            <Input placeholder={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_NAME')} size="large" allowClear />
          </Form.Item>
          <Table columns={columns} dataSource={data} rowKey="id" pagination={false} className="table" />
          <Button
            className="submit-btn"
            htmlType="submit"
            size="large"
            type="primary"
            style={{ marginBottom: 16 }}
            loading={loading}
            disabled={loading}
          >
            {activeTemplate
              ? t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.SAVE_TEMPLATE')
              : t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.CREATE_TEMPLATE')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default styled(TurnoverTemplate)`
  .container {
    margin-top: 25px;

    .input-cell {
      width: 50px;
      text-align: center;
      padding: 0;

      .ant-input-number {
        width: 100%;
      }

      .ant-input-number-handler-wrap {
        display: none;
      }

      .ant-input-number-input-wrap {
        input {
          text-align: center;
          padding: 0 !important;
        }
      }
    }

    .submit-btn {
      margin-top: 25px;
    }

    .table {
      .ant-table-thead {
        .ant-table-cell {
          padding: 10px 0;
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          padding: 5px;
        }
      }
      .ant-form-item {
        margin: 0;
      }
    }
  }
`;
