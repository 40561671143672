import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { IScheduleModel } from '@/types/schedule-models/schedule-model.model';
import { IUserParams } from '@/types/user-params.model';
import { IUser } from '@/types/user.model';
import { handleError } from '@/utils';
import { Button, Form, message, Spin } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormActions from '../../FormActions';
import Model from './components/Model';
import ModalCreateScheduleModel from './modals/ModalCreateScheduleModel';
import ModalScheduleModelDetails from './modals/ModalScheduleModelDetails';

interface Props {
  className?: string;
  user?: IUser;
  department?: IDepartment;
  activeSubTab?: string;
}

const TabScheduleModels: React.FC<Props> = ({ className, user, department, activeSubTab }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [activeScheduleModel, setActiveScheduleModel] = useState<IScheduleModel | null>(null);
  const [scheduleModels, setScheduleModels] = useState<IScheduleModel[]>([]);
  const [scheduleModelsLoading, setScheduleModelsLoading] = useState<boolean>(false);
  const [userParamsLoading, setUserParamsLoading] = useState<boolean>(false);
  const [userParams, setUserParams] = useState<IUserParams>({});
  const [days, setDays] = useState<any[]>([]);

  const [modalCreateScheduleModelVisible, setModalCreateScheduleModelVisible] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      fetchScheduleModels();
    }

    return () => {
      mounted = false;
    };
  }, [user]);

  useEffect(() => {
    let mounted = true;

    const cancelTokenSource = axios.CancelToken.source();
    if (mounted && user && activeSubTab === 'SCHEDULE-MODELS') {
      setUserParamsLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/params`, {
          params: {
            departmentId: department?.id,
          },
        })
        .then(({ data }) => {
          setUserParams(data);
          setUserParamsLoading(false);
        })
        .catch((error) => {
          setUserParamsLoading(false);
        });
    }

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [user, activeSubTab]);

  const fetchScheduleModels = () => {
    setScheduleModelsLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    setScheduleModels([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/schedule-models`, {
        params: {
          departmentId: department?.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        if (data.length == 0) {
          createDefaultScheduleModel();
        }
        setScheduleModels(data);
        setScheduleModelsLoading(false);
      })
      .catch((err) => {
        handleError(err);
        setScheduleModelsLoading(false);
      });
  };

  useEffect(() => {
    setFields();
  }, [userParams]);

  const setFields = () => {
    form.resetFields();
    if (userParams) {
      let values: any = {};
      userParams.params?.schedule_models?.forEach((sm) => {
        values[`${sm.id}`] = {
          ...sm,
          start: sm.start ? moment.unix(sm.start) : undefined,
          end: sm.end ? moment.unix(sm.end) : undefined,
          generation: sm.generation,
        };
      });
      form.setFieldsValue(values);
    }
    setFormHasChanged(false);
  };

  const createDefaultScheduleModel = () => {
    if (user && department) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/users/${user.recordId}/schedule-models`,
          {
            name: t('SCHEDULE_MODELS.DEFAULT_NAME'),
          },
          {
            params: {
              departmentId: department.id,
            },
          },
        )
        .then(({ data }) => {
          setScheduleModels(data);
        })
        .catch((error) => {
          console.error(error);
          message.error(t('SCHEDULE_MODELS.MESSAGES.DEFAULT_ERROR'));
        });
    }
  };

  const onCreate = () => {
    setModalCreateScheduleModelVisible(true);
  };

  const onFinish = (values: any) => {
    if (!user?.recordId) return;
    setLoading(true);
    let formatted_values: any = {};
    for (const [key, value] of Object.entries(values)) {
      formatted_values[key] = {
        id: key,
        start: (value as any).start,
        end: (value as any).end,
        generation: (value as any).generation,
      };
    }

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/params`,
        {
          params: {
            schedule_models: formatted_values,
          },
        },
        {
          params: {
            departmentId: department?.id,
          },
        },
      )
      .then(({ data }) => {
        setFormHasChanged(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onValuesChange = () => {
    setFormHasChanged(true);
  };

  const onReset = () => {
    setFields();
  };

  return (
    <div className={className}>
      {!scheduleModelsLoading && !userParamsLoading ? (
        <Form form={form} className="container" layout="vertical" onFinish={onFinish} onValuesChange={onValuesChange}>
          <div className="content">
            <h4>{t('GLOBAL.SCHEDULE_MODELS')}</h4>
            {scheduleModels.map((sm, i) => (
              <Model
                scheduleModel={sm}
                scheduleModels={scheduleModels}
                setScheduleModels={setScheduleModels}
                days={days}
                index={i}
                setDays={setDays}
                setActiveScheduleModel={setActiveScheduleModel}
                userParams={userParams}
                setFormHasChanged={setFormHasChanged}
              />
            ))}
          </div>
          <div>
            <Button type="primary" htmlType="button" icon={<i className="icon-plus"></i>} onClick={onCreate}>
              {t('SCHEDULE_MODELS.ADD_SCHEDULE_MODEL')}
            </Button>
          </div>
          <FormActions saving={loading} active={formHasChanged} onReset={onReset} />
        </Form>
      ) : (
        <Spin spinning={true} style={{ color: colors.green }} />
      )}
      <ModalCreateScheduleModel
        visible={modalCreateScheduleModelVisible}
        user={user!}
        department={department!}
        setVisible={setModalCreateScheduleModelVisible}
        setScheduleModels={setScheduleModels}
      />
      {activeScheduleModel && (
        <ModalScheduleModelDetails
          user={user!}
          activeScheduleModel={activeScheduleModel}
          setActiveScheduleModel={setActiveScheduleModel}
          setScheduleModels={setScheduleModels}
          userParams={userParams}
        />
      )}
    </div>
  );
};

export default styled(TabScheduleModels)`
  .row {
    display: flex;
    gap: 15px;
    margin-bottom: 25px;
    align-items: flex-end;
    justify-content: center;

    .ant-row {
      margin: 0;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .content {
    display: flex;
    flex-direction: column;

    h4  {
      margin-bottom: 25px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      gap: 15px;
    }

    .right {
      display: flex;
      gap: 15px;
    }
  }
`;
