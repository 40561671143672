import AppContext from '@/pages/app/context';
import { Modal } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  errors: any;
  visible: boolean;
  setErrors: React.Dispatch<any>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalError: React.FC<Props> = ({ className, errors, visible, setErrors, setVisible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);

  const onCancel = () => {
    setVisible(false);
    setErrors({});
  };

  return (
    <Modal
      className={`${className} modal-danger`}
      title={t('USERS.AY_SYNC_ERRORS')}
      visible={visible}
      onCancel={onCancel}
      onOk={onCancel}
    >
      <div className="errors">
        {Object.values(errors).map((error) => (
          <span>- {error}</span>
        ))}
      </div>
    </Modal>
  );
};

export default styled(ModalError)`
  .errors {
    display: flex;
    flex-direction: column;
  }
`;
