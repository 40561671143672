import { IUserParams } from '@/types/user-params.model';
import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface Props {
  integrations: IUserParams['integrations'];
  integrationValues: IUserParams['integrationValues'];
}

const Integrations: React.FC<Props> = ({ integrations, integrationValues }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });

  if (!integrations) return null;

  return (
    <React.Fragment>
      <div>
        <h4>{t('USERS.USER.SETTINGS.INTEGRATIONS.TITLE')}</h4>
        {Object.keys(integrations!).map((key) => (
          <Form.Item
            style={{ display: 'flex', alignItems: 'center' }}
            key={key}
            name={['integrations', key]}
            label={t(`USERS.USER.SETTINGS.INTEGRATIONS.${key}`)}
          >
            <Select
              allowClear={true}
              getPopupContainer={(trigger) => trigger}
              placeholder={t('DIMONA.CHOOSE_EMPLOYEE_OR_WORKER')}
              showSearch
              filterOption={(input, option) => {
                return option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {integrationValues?.[key]?.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Integrations;
