import colors from '@/styles/colors';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  end: string;
  link?: string;
  noTooltip?: boolean;
}

const NewBadge: React.FC<Props> = ({ className, end, link, noTooltip }) => {
  const { t } = useTranslation();
  const [display, setDisplay] = useState<'flex' | 'none'>('flex');

  useEffect(() => {
    if (moment().unix() >= moment(end).unix()) {
      setDisplay('none');
    }
  }, []);

  const onClick = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <>
      {!noTooltip ? (
        <Tooltip
          mouseEnterDelay={0}
          overlay={<span>{t('GLOBAL.CLICK_TO_VIEW_MORE')}</span>}
          className={className}
          style={{ display }}
        >
          <div onClick={onClick} style={{ display, alignItems: 'center' }}>
            <span>{t('GLOBAL.NEW')}</span>
          </div>
        </Tooltip>
      ) : (
        <div className={className} style={{ display, alignItems: 'center' }}>
          <span>{t('GLOBAL.NEW')}</span>
        </div>
      )}
    </>
  );
};

export default styled(NewBadge)`
  display: inline;
  margin-right: 5px;
  background-color: ${colors.purple};
  color: #fff;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 12px;
  max-height: 23px;
`;
