import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { InitialStateType } from '../../redux/store';
import { useSelector } from 'react-redux';

interface Props {
  refPageContainer: React.MutableRefObject<null> | null;
}

const NowRow: React.FC<Props> = ({ refPageContainer }) => {
  const { hours } = useSelector(({ hours }: InitialStateType) => ({
    hours,
  }));
  const [currentHours, setCurrentHours] = useState<number>(moment().local().hours());
  const [currentMinutes, setCurrentMinutes] = useState<number>(moment().local().minutes());

  const hourWidth =
    refPageContainer?.current && refPageContainer?.current.clientWidth
      ? (refPageContainer?.current.clientWidth - 210) / hours.length
      : 0;

  const hourIndex = hours.findIndex((hour) => moment.unix(hour.date).hours() == currentHours);
  const minutesWidth = (hourWidth / 60) * currentMinutes;

  useEffect(() => {
    const interval = setInterval(() => {
      const hours = moment().local().hours();
      const minutes = moment().local().minutes();
      setCurrentHours(hours);
      setCurrentMinutes(minutes);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const offset = hourWidth == 0 ? -100 : 210 + hourWidth * hourIndex + minutesWidth;

  return (
    <span
      style={{
        display: offset < 210 ? 'none' : 'block',
        position: 'absolute',
        zIndex: 99,
        left: hourWidth == 0 ? -100 : 210 + hourWidth * hourIndex + minutesWidth,
        top: 0,
        width: 2,
        height: '100%',
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2300A65199' stroke-width='6' stroke-dasharray='0%2c13' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      }}
    ></span>
  );
};

export default React.memo(NowRow);
