import colors from '@/styles/colors';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  setModalSwitchProVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaidOptionOverlay: React.FC<Props> = ({ className, setModalSwitchProVisible }) => {
  const { t } = useTranslation();

  const onClick = () => {
    setModalSwitchProVisible(true);
  };

  return (
    <div className={className}>
      <div className="card">
        <h1 className="title">{t('PAID_OPTION.OVERLAY.TITLE')}</h1>
        <Button type="primary" size="large" className="button" onClick={onClick}>
          {t('PAID_OPTION.OVERLAY.GO_PRO_BUTTON')}
        </Button>
      </div>
    </div>
  );
};

export default styled(PaidOptionOverlay)`
  position: absolute;
  z-index: 999;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    background-color: #fff;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .title {
      font-weight: bold;
      color: ${colors.red};
    }

    .subtitle {
      font-weight: bold;
    }

    .button {
      margin-top: 25px;
    }
  }
`;
