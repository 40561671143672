import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled, isNullOrUndefined, minutesToHoursAndMinutes } from '@/utils';
import { Modal, Table } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
moment.tz.setDefault('Atlantic/Reykjavik');

interface IDayExtraHour {
  type: string;
  id: string;
  date: number;
  recup: 0;
  dayoff: null;
  dayoffCode: null;
  plannedWorktime: 600;
  plannedPause: 0;
  clockinWorktime: 0;
  clockinPause: 0;
  hoursWeek: 2280;
}

interface Props {
  selectedWeek: {
    title: string;
    dataSource: IDayExtraHour[] | null;
  } | null;
  department?: IDepartment;
  onClose: () => void;
}

const ModalWeekDetails: React.FC<Props> = ({ selectedWeek, department, onClose }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { features },
  } = useContext(AppContext);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    moment.updateLocale(language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.DATE'),
        key: 'date',
        width: 200,
        // eslint-disable-next-line react/display-name
        render: (_text: string, extraHour: IDayExtraHour) => {
          const { date } = extraHour;
          return moment.unix(date).format('L');
        },
      },
      {
        title: t('GLOBAL.TYPE'),
        key: 'type',
        dataIndex: 'type',
      },
      {
        title: t('GLOBAL.PLANNED'),
        key: 'plannedWorktime',
        // eslint-disable-next-line react/display-name
        render: (_text: string, extraHour: IDayExtraHour) => {
          const { plannedWorktime, recup } = extraHour;
          if (isNullOrUndefined(plannedWorktime)) {
            return null;
          }
          return (
            <span style={{ color: recup ? colors.red : 'auto', fontWeight: recup ? 'bold' : 'normal' }}>
              {minutesToHoursAndMinutes(plannedWorktime)}
            </span>
          );
        },
      },
      ...(isFeatureEnabled(features, FEATURES.CLOCKING)
        ? [
            {
              title: t('GLOBAL.CLOCKING'),
              key: 'clockinWorktime',
              // eslint-disable-next-line react/display-name
              render: (_text: string, extraHour: IDayExtraHour) => {
                const { clockinWorktime, recup } = extraHour;
                if (isNullOrUndefined(clockinWorktime)) {
                  return null;
                }
                return (
                  <span style={{ color: recup ? colors.red : 'auto', fontWeight: recup ? 'bold' : 'normal' }}>
                    {minutesToHoursAndMinutes(clockinWorktime)}
                  </span>
                );
              },
            },
            {
              title: t('GLOBAL.BREAK_SHORT'),
              key: 'clockinPause',
              // eslint-disable-next-line react/display-name
              render: (_text: string, extraHour: IDayExtraHour) => {
                const { clockinPause } = extraHour;
                if (isNullOrUndefined(clockinPause)) {
                  return null;
                }
                return minutesToHoursAndMinutes(clockinPause);
              },
            },
          ]
        : []),
    ]);
  }, [language]);

  return (
    <Modal
      title={selectedWeek?.title}
      visible={!!selectedWeek}
      onCancel={onClose}
      getContainer={false}
      forceRender
      footer={null}
      width={800}
    >
      <Table
        dataSource={selectedWeek?.dataSource || []}
        columns={columns}
        rowKey="id"
        pagination={false}
        rowClassName={(record: any) => record.status}
      />
    </Modal>
  );
};

export default ModalWeekDetails;
