import AppContext from '@/pages/app/context';
import Colors from '@/styles/colors';
import { Button, Checkbox, Form, message, Modal } from 'antd';
import axios from 'axios';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../redux/actions';
import { InitialStateType } from '../redux/store';

interface Props {
  className?: string;
  visible: boolean;
  shiftIds?: string[] | null;
  sectionId?: string;
  departmentId?: string;
  onClose: () => void;
}

const ModalPublishShift: React.FC<Props> = ({ className, visible, shiftIds, sectionId, departmentId, onClose }) => {
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const shiftsMap = useSelector((store: InitialStateType) => store.shiftsMap);
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasShiftWithUserRecordId, setHasShiftWithUserRecordId] = useState<boolean>(false);
  const [hasShiftWithoutUserRecordId, setHasShiftWithoutUserRecordId] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t } = useTranslation(undefined, { useSuspense: true });

  useEffect(() => {
    form.resetFields();
    if (shiftIds) {
      setHasShiftWithUserRecordId(false);
      setHasShiftWithoutUserRecordId(false);
      let hasShiftWithUserRecordId = false;
      let hasShiftWithoutUserRecordId = false;

      for (let i = 0; i < shiftIds.length; i++) {
        const shift = shiftsMap.get(shiftIds[i]);
        if (shift) {
          if (shift.userRecordId) {
            hasShiftWithUserRecordId = true;
          } else {
            hasShiftWithoutUserRecordId = true;
          }
          if (hasShiftWithUserRecordId && hasShiftWithoutUserRecordId) {
            break;
          }
        }
      }
      setHasShiftWithUserRecordId(hasShiftWithUserRecordId);
      setHasShiftWithoutUserRecordId(hasShiftWithoutUserRecordId);
    }
  }, [shiftIds]);

  const onFinish = async (values: any) => {
    setIsSaving(true);

    const { notifyUserUpdates, notifyOpenShifts } = values;
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/operations/publish-shifts`, {
        shiftIds,
        notifyUserUpdates,
        notifyOpenShifts,
        sectionId,
        departmentId,
      })
      .then(() => {
        batch(() => {
          hoursDispatch({
            type: 'UPDATE_SHIFTS',
            payload: {
              newShiftsDifferences: shiftIds!.map((id) => ({
                id,
                approved: true,
              })),
            },
          });
          hoursDispatch({
            type: 'UPDATE_FILTERED_SHIFTS',
            payload: { department: activeDepartment },
          });
        });
        setIsSaving(false);
        onClose();
      })
      .catch((error) => {
        console.error(error);
        message.error(t('SHIFTS.MODAL_PUBLISH_SHIFTS.MESSAGE_PUBLICATION_ERROR'));
        setIsSaving(false);
      });
  };

  if (shiftIds) {
    return (
      <Modal
        forceRender={true}
        maskClosable={false}
        destroyOnClose={true}
        visible={visible}
        onCancel={onClose}
        className={className}
        title={t('SHIFTS.MODAL_PUBLISH_SHIFTS.TITLE')}
        footer={[
          <Button key="back" onClick={onClose}>
            {t('GLOBAL.CANCEL')}
          </Button>,
          <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
            {t('GLOBAL.PUBLISH')}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={onFinish} labelCol={{ span: 2 }} wrapperCol={{ span: 24 }}>
          <p>{t('SHIFTS.MODAL_PUBLISH_SHIFTS.DESCRIPTION')}</p>
          {hasShiftWithUserRecordId === true && (
            <Form.Item name="notifyUserUpdates" valuePropName="checked">
              <Checkbox>{t('SHIFTS.MODAL_PUBLISH_SHIFTS.CONFIRM_SEND_EMAIL_FOR_UPDATED_SHIFTS')}</Checkbox>
            </Form.Item>
          )}
          {hasShiftWithoutUserRecordId === true && (
            <Form.Item name="notifyOpenShifts" valuePropName="checked">
              <Checkbox>{t('SHIFTS.MODAL_PUBLISH_SHIFTS.CONFIRM_SEND_EMAIL_FOR_FREE_SHIFTS')}</Checkbox>
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  } else {
    return <Form form={form} style={{ display: 'none' }} />;
  }
};

export default styled(ModalPublishShift)`
  .ant-modal-close-x {
    color: white;
    font-size: 20px;
    width: auto;
    height: auto;
    line-height: 72px;
    padding-right: 15px;
  }

  .ant-modal-header {
    padding: 25px;
    background-image: repeating-linear-gradient(
      -45deg,
      ${Colors.green},
      ${Colors.green} 15px,
      #0eb15d 15px,
      #0eb15d 30px
    );

    background-image: repeating-linear-gradient(-45deg, ${Colors.green} 0 15px, #0eb15d 15px 30px);

    .ant-modal-title {
      color: white;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.01em;
    }
  }

  .ant-modal-body {
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .ant-modal-footer {
    button {
      font-size: 15px;
      padding: 3px 10px;

      &.ant-btn:not(.ant-btn-primary) {
        background: #e2e6ef;
        border-color: #e2e6ef;
        color: #273140;
      }
      &.ant-btn-primary {
        background: ${Colors.green};
        border-color: ${Colors.green};
      }
    }
  }
`;
