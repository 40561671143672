import TableView from '@/layouts/TableView';
import Tag from '@/pages/app/components/Tag';
import AppContext from '@/pages/app/context';
import { IDocument } from '@/types/document.model';
import { FEATURES } from '@/types/features.model';
import { handleError, isFeatureEnabled } from '@/utils';
import { BackTop, Modal, Table, message } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Header from './Header';
import ModalDocumentDetail from './ModalDocumentDetail';
import TableRowActions from './TableRowActions';

moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  userRecordId?: string;
  onlyPdf?: boolean;
}

const DocumentsList: React.FC<Props> = ({ userRecordId, onlyPdf }) => {
  const {
    state: { activeDepartment, skills, userCategories, users, activeDepartmentId, activeSection, features },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<IDocument | null>(null);
  const history = useHistory();

  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const [filteringDocuments, setFilteringDocuments] = useState<boolean>(false);
  const [filteredDocuments, setFilteredDocuments] = useState<IDocument[]>([]);
  const [filterSearch, setFilterSearch] = useState<string>('');

  const documentsRef = useRef<any[]>([]);
  documentsRef.current = documents;

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.NUMBER_SHORT'),
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: t('GLOBAL.TITLE'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('GLOBAL.RECIPIENTS'),
        dataIndex: 'recipients',
        key: 'recipients',
        render: (text: any, document: IDocument) => {
          switch (document.recipientType) {
            case 'ALL': {
              return t('GLOBAL.ALL');
            }
            case 'USERS': {
              return document.users?.map((userId) => users?.find((x) => x.recordId === userId)?.displayName).join(', ');
            }
            case 'SKILLS': {
              return document.skills
                ?.map((skillId) => skills?.find((x) => x.id === skillId))
                .map((x) => {
                  if (x) {
                    return <Tag key={x.id} color={x.color} name={x.name} background={x.background} />;
                  }
                });
            }
            case 'STATUSES': {
              return document.statuses
                ?.map((statusId) => userCategories?.find((x) => x.id === statusId))
                .map((x) => {
                  if (x) {
                    return <Tag key={x.id} color={x.color} name={x.name} background={x.background} />;
                  }
                });
            }
          }
        },
      },
      {
        title: t('DOCUMENTS.DOCUMENTS.COLUMNS.SUBSCRIPTION_DATE'),
        key: 'subscribtionDate',
        // eslint-disable-next-line react/display-name
        render: (text: any, document: IDocument) => {
          return `${moment.unix(document.itemDate).format('L')}`;
        },
      },
      {
        title: t('GLOBAL.SENDING_DATE'),
        key: 'sendDate',
        render: (text: any, document: IDocument) => {
          if (document.sendDate) {
            return `${moment.unix(document.sendDate).format('L')}`;
          }
        },
      },
      {
        title: t('GLOBAL.SIGNATURE'),
        key: 'signature',
        // eslint-disable-next-line react/display-name
        render: (text: any, document: IDocument) => {
          return <Checkbox disabled={true} checked={document.signature} />;
        },
      },
      {
        title: t('GLOBAL.SIGN_DATE'),
        key: 'signDate',
        render: (text: any, document: IDocument) => {
          if (document?.signDate) {
            return `${moment.unix(document.signDate).format('L')}`;
          }
        },
      },
      {
        title: t('GLOBAL.EXPIRATION_DATE'),
        key: 'expirationDate',
        // eslint-disable-next-line react/display-name
        render: (text: any, document: IDocument) => {
          const { expirationDate } = document;
          if (expirationDate) {
            return `${moment.unix(expirationDate).format('L')}`;
          }
        },
      },
      {
        title: t('GLOBAL.CREATOR'),
        dataIndex: ['creator', 'displayName'],
        key: 'creator',
      },
      {
        align: 'right',
        key: 'actions',
        // eslint-disable-next-line react/display-name
        render: (text: string, document: IDocument) => {
          return (
            <TableRowActions
              document={document}
              onShowDetail={onShowDetail}
              onWantToDeleteDocument={onWantToDeleteDocument}
              onWantToSendDocument={onWantToSendDocument}
              onDuplicateDocument={onDuplicateDocument}
              onlyPdf={onlyPdf}
            />
          );
        },
      },
    ]);
  }, [i18n.language, skills, userCategories, users]);

  useEffect(() => {
    if (!users) return;
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingDocuments(true);
    setDocuments([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/documents/all`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
          sectionId: activeSection,
          userRecordId,
          docType: 'DOCUMENT',
        },
      })
      .then(({ data }) => {
        if (mounted) {
          let documents = data.documents;
          if (isFeatureEnabled(features, FEATURES.SECTIONS)) {
            if (activeSection) {
              const usersInSection = users
                .filter((user) => (user.sections ? (user.sections as any).split(',').includes(activeSection) : false))
                .map((user) => user.recordId);
              if (usersInSection) {
                documents = (documents as IDocument[]).filter((document) =>
                  document.users ? haveCommonElement(usersInSection as string[], document.users as string[]) : false,
                );
              }
            } else if (activeDepartment?.role !== 'ADMIN') {
              const activeUser = users.find((user) => user.recordId == activeDepartment?.userRecordId);
              if (activeUser) {
                const activeUserSections: string[] = (activeUser.sections as any).split(',');
                documents = (documents as IDocument[]).filter((document) => {
                  if (!document.users) return false;
                  const usersWithDetails = document.users
                    .map((u) => users.find((user) => user.recordId == u))
                    .filter((record) => record !== undefined);
                  if (usersWithDetails.length == 0) return false;
                  const filtered = usersWithDetails.filter((user) =>
                    user && user.sections
                      ? haveCommonElement(activeUserSections, (user.sections as any).split(','))
                      : false,
                  );
                  if (filtered.length == 0) return false;
                  return true;
                });
              }
            }
          }
          setDocuments(documents);
          setLoadingDocuments(false);
        }
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingDocuments(false);
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [userRecordId, activeSection, users]);

  useEffect(() => {
    setFilteringDocuments(true);
    if (documents) {
      const filteredDocuments = documents.filter((document) => {
        let showDocument = true;
        if (filterSearch) {
          const { name } = document;
          showDocument = [name].some((key) => {
            return key.toLowerCase().includes(filterSearch.toLowerCase());
          });
        }
        return showDocument;
      });
      setFilteredDocuments(filteredDocuments);
    } else {
      setFilteredDocuments([]);
    }
    setTimeout(() => {
      setFilteringDocuments(false);
    }, 0);
  }, [documents, filterSearch]);

  const onDeleteDocument = (documentId: string) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/documents/${documentId}`, {
        params: {
          departmentId: activeDepartmentId,
          docType: 'DOCUMENT',
        },
      })
      .then(() => {
        const documentsCopy = [...documentsRef.current];
        const index = documentsCopy.findIndex((x) => x.id === documentId);
        documentsCopy.splice(index, 1);
        setDocuments(documentsCopy);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSendDocument = (documentId: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/v3/documents/${documentId}/send`, null, {
      params: {
        departmentId: activeDepartmentId,
        docType: 'DOCUMENT',
      },
    });
  };

  const onDuplicateDocument = (documentId: string) => {
    history.push('new', { documentId });
  };

  const onWantToDeleteDocument = (documentId: string) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('DOCUMENTS.DOCUMENTS.MODAL_DELETE_DOCUMENT.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDeleteDocument(documentId);
      },
      onCancel: () => {},
    });
  };

  const onWantToSendDocument = (documentId: string) => {
    Modal.confirm({
      title: t('GLOBAL.SENDING'),
      icon: null,
      content: t('DOCUMENTS.DOCUMENTS.MODAL_SEND_DOCUMENT.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.SEND'),
      onOk: (close) => {
        return new Promise((resolve, reject) => {
          onSendDocument(documentId)
            .then(() => {
              message.success(t('DOCUMENTS.DOCUMENTS.MESSAGE_SEND_SUCCESS'));
              history.push('/app/documents/documents');
              resolve(true);
            })
            .catch((error) => {
              console.log(error);
              close();
              if (error.response && error.response.data && error.response.data.errors) {
                message.error(Object.values(error.response.data.errors).flat(1)[0]);
              } else {
                message.error(t('DOCUMENTS.DOCUMENTS.MESSAGE_SEND_ERROR'));
              }
              reject();
            });
        });
      },
      onCancel: () => {},
    });
  };

  const onShowDetail = (document: IDocument) => {
    setSelectedDocument(document);
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header userRecordId={userRecordId} setFilterSearch={setFilterSearch} disabled={loadingDocuments} />
      <Table
        dataSource={filteredDocuments}
        loading={loadingDocuments || filteringDocuments}
        columns={columns}
        rowKey="id"
        onChange={() => {
          window.scrollTo(0, 0);
        }}
        pagination={{ position: ['bottomCenter'] }}
      />
      <ModalDocumentDetail document={selectedDocument} onClose={() => setSelectedDocument(null)} />
    </TableView>
  );
};

function haveCommonElement(arr1: string[], arr2: string[]) {
  return arr1.some((item) => arr2.includes(item));
}

export default DocumentsList;
