import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';

interface Props {
  className?: string;
  shifts: {
    start: number;
    end: number;
    id: string;
    type: 'SHIFT' | 'LEAVE' | 'UNAVAILABILITY';
  }[];
  departmentId?: string;
}

const ShiftsDetails: React.FC<Props> = ({ className, shifts }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <div className={className}>
      {shifts.map(({ id, type, start, end }) => (
        <span key={id}>
          {t('GLOBAL.' + type)} : {moment.unix(start).format('HH:mm')} - {moment.unix(end).format('HH:mm')}
        </span>
      ))}
    </div>
  );
};

export default styled(ShiftsDetails)`
  span {
    display: block;
  }
`;
