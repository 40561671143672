import { Button, Form, Input, Spin, message } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import { RESOURCE_TYPES } from '@/types/field.model';
import PhoneInput from 'react-phone-input-2';
import LimitReached from './LimitReached';
import ModalSwitchToProAccount from '@/pages/app/components/ModalSwitchToProAccount';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';

interface Props {
  className?: string;
  setModalFreemiumOnboardingVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const MAX_FREEMIUM_USERS = 5;

const UsersForm: React.FC<Props> = ({ className, setModalFreemiumOnboardingVisible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, usersFields, users, loadingUsers },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [modalSwitchToProVisible, setModalSwitchToProVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
  }, []);

  const onFinish = (values: any) => {
    setLoading(true);
    const formattedValues = groupByNumber(values);
    const promises = [];

    if (formattedValues.length == 0) {
      setLoading(false);
      return;
    }

    for (let i = 0; i < formattedValues.length; i++) {
      const user = formattedValues[i];
      const promise = axios.post(`${process.env.REACT_APP_API_URL}/v3/users`, {
        departmentId: activeDepartmentId,
        fields: { ...user },
      });
      promises.push(promise);
    }

    Promise.all(promises)
      .then((responses) => {
        const usersCopy = [...users];
        for (let i = 0; i < responses.length; i++) {
          const user = responses[i].data.user;
          usersCopy.push(user);
        }
        const usersCopySorted = usersCopy.sort((a, b) => {
          if (a.lastname) {
            if (b.lastname) {
              if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) {
                return -1;
              }
              if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) {
                return 1;
              }
              return 0;
            }
            return 1;
          }
          return 0;
        });

        const infoPromises = [];
        for (let i = 0; i < responses.length; i++) {
          const user = responses[i].data.user;
          const promise = axios.post(`${process.env.REACT_APP_API_URL}/v3/users/send-connection-info`, {
            recordIds: [user?.recordId],
            departmentId: activeDepartmentId,
            message: true,
          });
          infoPromises.push(promise);
        }
        Promise.all(infoPromises)
          .then((responses) => {
            message.success(t('FREEMIUM.MODAL_ONBOARDING.CONNECTION_INFO_SENT'));
            dispatch({
              type: 'SET_USERS',
              payload: usersCopySorted,
            });
            form.resetFields();
            setLoading(false);
          })
          .catch((err) => {
            message.success(t('FREEMIUM.MODAL_ONBOARDING.CONNECTION_INFO_SEND_ERROR'));
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  function groupByNumber(obj: any) {
    let result = [];
    let groupedObj: any = {};

    // Remove undefined values and group by number
    for (let key in obj) {
      let resourceType = key.split('_')[0];
      let num = key.split('_')[1];
      let value = obj[key];

      if (value !== undefined) {
        if (!groupedObj[num]) {
          groupedObj[num] = {};
        }

        const field = usersFields.find((field) => field.resourceType == resourceType);

        groupedObj[num][field!.id!] = value;
      }
    }

    // Convert grouped object to array of objects
    for (let num in groupedObj) {
      let group = groupedObj[num];
      const lastNameField = usersFields.find((field) => field.resourceType == RESOURCE_TYPES.LASTNAME);
      const firstNameField = usersFields.find((field) => field.resourceType == RESOURCE_TYPES.FIRSTNAME);
      const emailField = usersFields.find((field) => field.resourceType == RESOURCE_TYPES.EMAIL);
      if (
        Object.keys(group).includes(lastNameField!.id!) &&
        Object.keys(group).includes(firstNameField!.id!) &&
        Object.keys(group).includes(emailField!.id!)
      ) {
        result.push(group);
      }
    }

    return result;
  }

  return (
    <Form className={className} form={form} layout="vertical" onFinish={onFinish}>
      {!loadingUsers ? (
        <>
          {users.length < 5 ? (
            <>
              <h1 className="title">{t('FREEMIUM.MODAL_ONBOARDING.TITLE')}</h1>
              <p dangerouslySetInnerHTML={{ __html: t('FREEMIUM.MODAL_ONBOARDING.DESCRIPTION') }} />
              {[...Array(MAX_FREEMIUM_USERS - users.length)].map((_, index) => (
                <div className="form-content">
                  <Form.Item label={t('GLOBAL.LASTNAME')} name={`${RESOURCE_TYPES.LASTNAME}_${index}`}>
                    <Input placeholder={t('GLOBAL.LASTNAME')} size="large" />
                  </Form.Item>
                  <Form.Item label={t('GLOBAL.FIRSTNAME')} name={`${RESOURCE_TYPES.FIRSTNAME}_${index}`}>
                    <Input placeholder={t('GLOBAL.FIRSTNAME')} size="large" />
                  </Form.Item>
                  <Form.Item label={t('GLOBAL.EMAIL')} name={`${RESOURCE_TYPES.EMAIL}_${index}`}>
                    <Input placeholder={t('GLOBAL.EMAIL')} size="large" />
                  </Form.Item>
                  <Form.Item label={t('GLOBAL.PHONE')} name={`${RESOURCE_TYPES.PHONE}_${index}`}>
                    <PhoneInput
                      placeholder={t('GLOBAL.PHONE')}
                      containerClass="ant-form-item-control-input-content"
                      inputClass="ant-input"
                      inputStyle={{ width: '100%', background: '#f7f8fb', height: 40 }}
                    />
                  </Form.Item>
                </div>
              ))}
              <div className="footer">
                <Button type="primary" htmlType="submit" style={{ marginLeft: 15 }} loading={loading}>
                  {t('GLOBAL.SEND')}
                </Button>
              </div>
            </>
          ) : (
            <>
              <LimitReached setModalSwitchToProVisible={setModalSwitchToProVisible} />
              <ModalSwitchToProAccount visible={modalSwitchToProVisible} setVisible={setModalSwitchToProVisible} />
            </>
          )}
        </>
      ) : (
        <Spin spinning={loadingUsers} className="spinner" />
      )}
    </Form>
  );
};

export default styled(UsersForm)`
  display: flex;
  flex-direction: column;

  .form-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    .ant-form-item {
      flex: 1;
    }

    .plus-btn {
      margin-top: 10px;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 1024px) {
    .form-content {
      flex-direction: column;
      align-items: unset;
      gap: 0;
      background-color: #f8f8f8;
      margin-top: 15px;
      padding: 15px;
      border-radius: 10px;
    }

    .footer {
      margin-top: 15px;
    }
  }
`;
