import { Modal } from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { CloseCircleOutlined } from '@ant-design/icons';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
}

const ModalHelpVideo: React.FC<Props> = ({ className }) => {
  const {
    state: { showHelpVideo },
    dispatch,
  } = useContext(AppContext);

  const [width, setWidth] = useState<number>(800);
  const [height, setHeight] = useState<number>(600);

  const resizeHandler = () => {
    const { innerWidth, innerHeight } = window;
    let width = 800;
    let height = 600;
    if (innerWidth <= innerHeight * 1.68) {
      width = innerWidth * 0.8;
      height = width / 1.68;
    } else {
      height = innerHeight * 0.8;
      width = height * 1.68;
    }

    setWidth(width);
    setHeight(height);
  };

  useEffect(() => {
    if (showHelpVideo) {
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      return () => {
        window.removeEventListener('resize', resizeHandler);
      };
    }
  }, [showHelpVideo]);

  return (
    <Modal
      className={className}
      visible={!!showHelpVideo}
      title={null}
      footer={null}
      width={width}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      centered
      closable={true}
      destroyOnClose
      closeIcon={<CloseCircleOutlined style={{ fontSize: 22 }} />}
      onCancel={() => {
        dispatch({
          type: 'SET_SHOW_HELP_VIDEO',
          payload: null,
        });
      }}
    >
      {showHelpVideo && (
        <iframe
          className="embed-video"
          width="100%"
          height={height}
          src={`https://www.youtube.com/embed/${showHelpVideo}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </Modal>
  );
};

export default styled(ModalHelpVideo)`
  margin: 20px;

  .embed-video {
    border-radius: 5px;
  }
  button.ant-modal-close {
    position: fixed;
    top: 10px;
    right: 10px;
    color: white;
  }
`;
