import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { IUser } from '@/types/user.model';
import { BackTop, Button, Table, Tooltip, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DisplayNameColumn from '../../components/DisplayNameColumn';
import Header from './components/Header';
import ModalDetail from './components/ModalDetail';
moment.tz.setDefault('Atlantic/Reykjavik');

export interface IVacationRequest {
  id: string;
  user: IUser;
  start: number;
  end: number;
  created: number;
  dayoffType: {
    id: '1';
    name: 'Vacances';
  };
  fullDay: boolean;
  files: string[];
  currentShifts: { id: string; start: number; end: number }[];
  status: string;
}

interface Props {
  userRecordId?: string;
}

const AppHoursVacationRequestsPage: React.FC<Props> = ({ userRecordId }) => {
  const {
    state: { activeDepartment, activeDepartmentId, activeSection },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [statusFilter, setStatusFilter] = useState<string>('PENDING');
  const [columns, setColumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<IVacationRequest[]>([]);
  const refDataSource = useRef<any[]>(dataSource);
  refDataSource.current = dataSource;
  const [loadingDataSource, setLoadingDataSource] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<IVacationRequest | null>(null);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingDataSource(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/vacation-requests`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
          userRecordId,
          sectionId: activeSection,
          status: statusFilter,
        },
      })
      .then(({ data }) => {
        if (mounted) {
          setDataSource(data);
          setLoadingDataSource(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
          console.error(error);
        }
        if (mounted) {
          setLoadingDataSource(false);
        }
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId, statusFilter, activeSection, userRecordId]);

  useEffect(() => {
    // moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      ...(!userRecordId
        ? [
            {
              title: t('GLOBAL.NAME'),
              key: 'displayName',
              // eslint-disable-next-line react/display-name
              render: (_text: string, record: IVacationRequest) => {
                const { user } = record;
                return <DisplayNameColumn user={user} />;
              },
            },
          ]
        : []),
      {
        title: t('GLOBAL.PERIOD'),
        key: 'period',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IVacationRequest) => {
          const { start, end, fullDay } = record || {};
          if (fullDay) {
            return `${moment.unix(start).format('L')} - ${moment.unix(end).format('L')}`;
          } else {
            return `${moment.unix(start).format('L')} ${moment.unix(start).format('HH:mm')}-${moment
              .unix(end)
              .format('HH:mm')}`;
          }
        },
      },
      {
        title: t('GLOBAL.TYPE'),
        key: 'type',
        dataIndex: ['dayoffType', 'name'],
      },
      {
        title: t('GLOBAL.FILES'),
        key: 'files',
        render: (text: any, record: IVacationRequest) => {
          return record.files?.length ? t('GLOBAL.YES') : t('GLOBAL.NO');
        },
      },
      {
        title: t('VACATION_REQUESTS.CREATED'),
        key: 'created',
        render: (text: any, record: IVacationRequest) => {
          const { created } = record;
          return `${moment.tz(moment.unix(created), activeDepartment?.timezone!).format('L HH:mm')}`;
        },
      },
      {
        title: t('GLOBAL.STATUS'),
        key: 'status',
        render: (text: any, record: IVacationRequest) => {
          if (record.status === 'PENDING') {
            return t(`GLOBAL.${record.status}`);
          } else {
            return t(`GLOBAL.${record.status}_FEMININE`);
          }
        },
      },
      {
        key: 'actions',
        align: 'right',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IVacationRequest) => {
          return (
            <>
              {record.files && record.files.length > 0 && (
                <Tooltip overlay={t('VACATION_REQUESTS.REQUEST_INCLUDES_FILES')}>
                  <i className="icon-doc-text-inv" style={{ marginRight: 5 }} />
                </Tooltip>
              )}
              <Button type="primary" onClick={() => setSelectedRequest(record)}>
                {t('GLOBAL.DETAIL')}
              </Button>
            </>
          );
        },
      },
    ]);
  }, [i18n.language, dataSource, userRecordId]);

  const onChangeStatus = (id: string, status: string, params?: any) => {
    return axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/vacation-requests/${id}`,
        {
          status,
          ...params,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(() => {
        const dataSource = [...refDataSource.current];
        const index = dataSource.findIndex((x) => x.id === id);
        if (~index) {
          dataSource.splice(index, 1);
        }
        setDataSource(dataSource);
      })
      .catch(() => {
        message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
      });
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header statusFilter={statusFilter} setStatusFilter={setStatusFilter} userRecordId={userRecordId} />
      <Table loading={loadingDataSource} dataSource={dataSource} columns={columns} rowKey="id" pagination={false} />
      <ModalDetail request={selectedRequest} onClose={() => setSelectedRequest(null)} onChangeStatus={onChangeStatus} />
    </TableView>
  );
};

export default AppHoursVacationRequestsPage;
