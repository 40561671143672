import { ICost } from '@/types/cost.model';
import { message, Modal } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
  costToDelete: ICost | null;
  setCostToDelete: React.Dispatch<React.SetStateAction<ICost | null>>;
}

const ModalDeleteCost: React.FC<Props> = ({ className, costToDelete, setCostToDelete }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, costs },
    dispatch,
  } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);

  const onCancel = () => {
    setCostToDelete(null);
  };

  const onOk = () => {
    if (costToDelete) {
      setLoading(true);

      axios
        .delete(`${process.env.REACT_APP_API_URL}/v3/costs/${costToDelete.id}`, {
          params: {
            departmentId: activeDepartment?.id,
          },
        })
        .then(({ data }: { data: ICost }) => {
          dispatch({
            type: 'SET_COSTS',
            payload: costs.filter((cost) => cost.id !== data.id),
          });
          message.success(t('COSTS.MESSAGES.DELETE_SUCCESS', { name: data.name }));
          setCostToDelete(null);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <Modal
      className={className}
      title={t('COSTS.MODAL_DELETE.TITLE')}
      visible={costToDelete !== null}
      destroyOnClose
      onCancel={onCancel}
      okText={t('GLOBAL.REMOVE')}
      onOk={onOk}
      okType="danger"
    >
      Are you sure ?
    </Modal>
  );
};

export default ModalDeleteCost;
