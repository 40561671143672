import AppContext from '@/pages/app/context';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IHeaderBadges } from '@/types/header-badges.model';
import { GROUP_ADMIN_ALLOWED_ACCOUNTS, isFeatureEnabled } from '@/utils';
import { Badge, Menu } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import NewBadge from '../components/NewBadge';

interface Props {
  access?: IDepartment['access'];
  department?: IDepartment;
  scheduleBadges: IHeaderBadges['schedule'];
}

const MenuHours: React.FC<Props> = (props) => {
  const { access, scheduleBadges, department, ...otherProps } = props;
  const { t } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { departments, activeDepartment, features },
  } = useContext(AppContext);

  const isAdmin = departments.find((department) => department.role == 'ADMIN');

  return (
    <Menu style={{ maxHeight: 'calc(100vh - 80px)', overflowY: 'auto' }} {...otherProps}>
      <Menu.ItemGroup title={t('MENU.SCHEDULE.SCHEDULE.TITLE')}>
        <Menu.Item key="schedule">
          <NavLink activeClassName="is-active" to="/app/hours/manage" className="menu_hours_manage">
            {t('MENU.SCHEDULE.SCHEDULE.SCHEDULE_MANAGEMENT')} <Badge count={scheduleBadges?.approvals} />
          </NavLink>
        </Menu.Item>
        {isAdmin && department?.accountId && GROUP_ADMIN_ALLOWED_ACCOUNTS.includes(department.accountId) && (
          <Menu.Item key="bi">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NewBadge end="2024-03-01" />
              <NavLink
                activeClassName="is-active"
                to={`/app/hours/group-admin/${moment().format('YYYY-MM-DD')}`}
                className="menu_hours_manage"
              >
                Group admin
              </NavLink>
            </div>
          </Menu.Item>
        )}
        {access?.requests && (
          <Menu.Item key="shift-modification-requests">
            <NavLink
              activeClassName="is-active"
              to="/app/hours/shift-modification-requests"
              className="menu_hours_shift-modification-requests"
            >
              {t('SCHEDULE.CHANGE_REQUESTS.TITLE')} <Badge count={scheduleBadges?.requests} />
            </NavLink>
          </Menu.Item>
        )}
        {!!isFeatureEnabled(features, FEATURES.FREESHIFTS) && !!department?.scheduleParams?.shyftsMarket && (
          <Menu.Item key="shyfts-market">
            <NavLink activeClassName="is-active" to="/app/hours/shyfts-market" className="menu_hours_shyfts-market">
              Shifts Market <Badge count={scheduleBadges?.shyftsMarket} />
            </NavLink>
          </Menu.Item>
        )}
        {!!department?.scheduleParams?.validateShifts && (
          <Menu.Item key="shift-approvals">
            <NavLink activeClassName="is-active" to="/app/hours/shift-approvals" className="menu_hours_shift-approvals">
              {t('SCHEDULE.SHIFT_APPROVALS.TITLE')} <Badge count={scheduleBadges?.validation} />
            </NavLink>
          </Menu.Item>
        )}
        {department?.id === '1445' && (
          <Menu.Item key="tasks-forecasts">
            <NavLink activeClassName="is-active" to="/app/hours/tasks-forecasts" className="menu_hours_tasks-forecasts">
              Tasks forecasts
            </NavLink>
          </Menu.Item>
        )}
        {isFeatureEnabled(features, FEATURES.LEAVE_MANAGEMENT) && (
          <Menu.Item key="vacation-requests">
            <NavLink
              activeClassName="is-active"
              to="/app/hours/vacation-requests"
              className="menu_hours_vacation-requests"
            >
              {t('VACATION_REQUESTS.TITLE')} <Badge count={scheduleBadges?.vacation} />
            </NavLink>
          </Menu.Item>
        )}
        {department?.scheduleParams?.swap_shift_enabled && (
          <Menu.Item key="swap-shift">
            <NavLink activeClassName="is-active" to="/app/hours/swap-shift" className="menu_hours_swap-shift">
              {t('SWAP_SHIFT.TITLE')} <Badge count={scheduleBadges?.swapShifts} />
            </NavLink>
          </Menu.Item>
        )}
      </Menu.ItemGroup>
    </Menu>
  );
};

export default MenuHours;
