import colors from '@/styles/colors';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  activeApp: IMarketplaceApp | null;
  style?: React.CSSProperties;
}

const Header: React.FC<Props> = ({ className, activeApp, style }) => {
  const { t } = useTranslation();
  return (
    <div className={className} style={style}>
      <img className="app-icon" src={activeApp?.logo} alt="App" />
      <div className="app-details">
        <h2 className="app-name">{activeApp?.title}</h2>
        <p className="app-description">{activeApp?.description}</p>
        <span className="app-caption">
          {t('MARKETPLACE.CREATED_BY')} {activeApp?.company}
        </span>
      </div>
    </div>
  );
};

export default styled(Header)`
  background-color: #efefef;
  display: flex;
  align-items: center;
  padding: 50px;

  .app-icon {
    width: 100px;
    height: 100px;
  }

  .app-details {
    margin-left: 50px;

    .app-name {
      font-size: 24px;
      font-weight: bold;
    }

    .app-description {
      margin: 0;
    }

    .app-caption {
      font-size: 11px;
      color: ${colors.grey};
    }
  }
`;
