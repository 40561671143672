import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Form, Button, Radio, Spin } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { IShift } from '@/types/shift.model';
import moment from 'moment';
import 'moment-timezone';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  clockingId: string | null;
  departmentId?: string;
  onClose: () => void;
  onSave: (shiftId: string) => void;
}

const ModalClockingToAttach: React.FC<Props> = ({ className, departmentId, clockingId, onSave, onClose }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [shifts, setShifts] = useState<IShift[]>([]);
  const [shiftsAreLoading, setShiftsAreLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    setShifts([]);
    if (clockingId) {
      const cancelTokenSource = axios.CancelToken.source();
      setShiftsAreLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/operations/clocking-reference/${clockingId}`, {
          cancelToken: cancelTokenSource.token,
          params: {
            departmentId,
          },
        })
        .then(({ data }) => {
          setShifts(data);
          setShiftsAreLoading(false);
        })
        .catch((error) => {
          setShiftsAreLoading(false);
          console.log(error);
        });

      return () => {
        cancelTokenSource.cancel();
      };
    }
  }, [clockingId]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/operations/clocking-reference`, {
        departmentId,
        clockinId: clockingId,
        shiftId: values.shift,
      })
      .then(() => {
        setIsSaving(false);
        onSave(values.shift);
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  return (
    <Modal
      visible={!!clockingId}
      onOk={() => form.submit()}
      onCancel={onClose}
      title={t('CLOCKINGS.MODAL_CLOCKING_TO_ATTACH.TITLE')}
      getContainer={false}
      forceRender
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={shiftsAreLoading}
          loading={isSaving}
          onClick={() => form.submit()}
        >
          {t('GLOBAL.SAVE')}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} className={className}>
        {shiftsAreLoading && (
          <Spin
            size="large"
            style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', padding: 20 }}
          />
        )}
        {!shiftsAreLoading && !shifts?.length && t('CLOCKINGS.MODAL_CLOCKING_TO_ATTACH.EMPTY_DATA')}
        <Form.Item name="shift" label={t('GLOBAL.SHIFT')} rules={[{ required: true }]}>
          <Radio.Group>
            {shifts?.map((shift) => {
              const { id, start, end } = shift;
              const startMoment = moment.unix(start!);
              return (
                <Radio key={id} value={id}>
                  {startMoment.format('dddd LL')}
                  {' - '}
                  {t('GLOBAL.FROM_HOUR_TO_HOUR', {
                    hour1: startMoment.format('HH:mm'),
                    hour2: moment.unix(end!).format('HH:mm'),
                  })}
                </Radio>
              );
            })}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalClockingToAttach)`
  .ant-form-item-label {
    display: none;
  }
  .ant-radio-group {
    width: 100%;
    .ant-radio-wrapper {
      padding-bottom: 10px;
    }
  }
`;
