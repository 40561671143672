import NewBadge from '@/pages/app/components/NewBadge';
import AppContext from '@/pages/app/context';
import ContractList from '@/pages/app/documents/contracts/components/ContractsList';
import DimonaStatement from '@/pages/app/documents/dimona/statement';
import DocumentsList from '@/pages/app/documents/documents/components/DocumentsList';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IUser } from '@/types/user.model';
import { isFeatureEnabled } from '@/utils';
import { Tabs } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import FilesList from './components/FilesList';

const { TabPane } = Tabs;

interface Props {
  className?: string;
  user?: IUser;
  department?: IDepartment;
  activeSubTab: string;
  setActiveSubTab: React.Dispatch<React.SetStateAction<string>>;
}

const Documents: React.FC<Props> = ({ className, user, department, activeSubTab, setActiveSubTab }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { features },
  } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (activeSubTab == '') {
      history.replace(
        `/app/team/collaborators/${user?.recordId}/documents/${department?.access?.documents ? 'contracts' : 'dimona'}`,
      );
    }
  }, [activeSubTab]);

  const onTabChange = (tab: string) => {
    setActiveSubTab(tab);
    history.replace(`/app/team/collaborators/${user?.recordId}/documents/${tab.toLowerCase()}`);
  };

  return (
    <div className={className}>
      <Tabs
        type="card"
        onChange={onTabChange}
        activeKey={activeSubTab == '' ? (department?.access?.documents ? 'CONTRACTS' : 'DIMONA') : activeSubTab}
      >
        {department?.access?.documents && (
          <TabPane disabled={user?.pending} tab={t('GLOBAL.CONTRACTS')} key="CONTRACTS">
            <ContractList userRecordId={user?.recordId || undefined} />
          </TabPane>
        )}
        {department?.access?.documents && (
          <TabPane disabled={user?.pending} tab={t('GLOBAL.DOCUMENTS')} key="DOCUMENTS">
            <DocumentsList userRecordId={user?.recordId || undefined} onlyPdf />
          </TabPane>
        )}
        {isFeatureEnabled(features, FEATURES.DIMONA) && (
          <TabPane disabled={user?.pending} tab={'Dimona'} key="DIMONA">
            <DimonaStatement user={user} />
          </TabPane>
        )}
        <TabPane
          disabled={user?.pending}
          tab={
            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <NewBadge end="2024-08-01" />
              <span>{t('GLOBAL.CUSTOM')}</span>
            </div>
          }
          key="CUSTOM"
        >
          <FilesList user={user} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default styled(Documents)``;
