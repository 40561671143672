import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import styled from 'styled-components';
import AuthContext from '@/context';
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { setToken } from '@/actions';
import { useForm } from 'antd/lib/form/Form';
import colors from '@/styles/colors';
import { withQuote } from '@/hoc/withQuote';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  quote?: {
    quote: string;
    author: string;
  };
}

const ChangePassword: React.FC<Props> = ({ className, quote }) => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();
  const [passwordToken, setPasswordToken] = useState<string>('');
  const { dispatch: authContextDispatch } = useContext(AuthContext);
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });

  const [backgroundImageLink, setBackgroundImageLink] = useState<string>('');

  useEffect(() => {
    const random = Math.floor(Math.random() * 2 + 1);
    setBackgroundImageLink(`${process.env.PUBLIC_URL}/img/image-login-${random}.jpg`);
  }, []);

  useEffect(() => {
    const regex = /(?:token\=)([^&]*)/;
    const token = regex.exec(window.location?.search || '')?.[1] || '';
    setPasswordToken(token);

    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();

    axios
      .get(`${process.env.REACT_APP_API_URL}/check-token`, {
        params: {
          token,
        },
        cancelToken: cancelTokenSource.token,
      })
      .catch((error) => {
        if (mounted) {
          if (error.response?.data?.message) {
            message.error(error.response?.data?.message);
          }
          history.push('/forgot-password');
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [location]);

  const onFinish = (values: any) => {
    const { password1: password } = values;
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/change-password`,
        {
          password,
          token: passwordToken,
        },
        {
          headers: {
            'X-Locale': i18n.language,
          },
        },
      )
      .then((response) => {
        setIsLoading(false);
        setToken(authContextDispatch, response?.data?.token);
        history.push('/app');
      })
      .catch((error) => {
        console.error(error);
        message.error('GLOBAL.AN_ERROR_OCCURRED');
        setIsLoading(false);
      });
  };

  return (
    <div className={`dual-page ${className}`}>
      <div className="left" style={{ backgroundImage: `url("${backgroundImageLink}")` }}>
        <h2>{quote?.quote}</h2>
        <i>{quote?.author}</i>
      </div>
      <div className="right">
        <Form form={form} size="large" layout="vertical" onFinish={onFinish} requiredMark={false}>
          <img src={process.env.PUBLIC_URL + '/shyfter-logo.svg'} alt="Shyfter" />
          <div className="fields">
            <Form.Item
              label={t('SETTINGS.INFORMATION.PASSWORD.FORM.NEW_PASSWORD')}
              name="password1"
              rules={[
                { required: true, message: t('FORMS.PASSWORD_ERROR') },
                {
                  pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                  message: t('SETTINGS.INFORMATION.PASSWORD.FORM.PASSWORD_TOOLTIP'),
                },
              ]}
            >
              <Input
                size="large"
                prefix={<i className="icon-lock"></i>}
                type="password"
                placeholder={t('SETTINGS.INFORMATION.PASSWORD.FORM.NEW_PASSWORD')}
              />
            </Form.Item>
            <Form.Item
              label={t('SETTINGS.INFORMATION.PASSWORD.FORM.NEW_PASSWORD2')}
              name="password2"
              rules={[
                {
                  required: true,
                  validator: (_rule, value) => {
                    const password1 = form.getFieldValue('password1');
                    if (password1 === value) {
                      return Promise.resolve(value);
                    } else {
                      return Promise.reject('error');
                    }
                  },
                  validateTrigger: 'onBlur',
                  message: t('SETTINGS.INFORMATION.PASSWORD.FORM.PASSWORD_UNEQUAL'),
                },
              ]}
            >
              <Input
                size="large"
                prefix={<i className="icon-lock"></i>}
                type="password"
                placeholder={t('FORMS.PASSWORD')}
              />
            </Form.Item>
          </div>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
              {t('SETTINGS.INFORMATION.PASSWORD.FORM.UPDATE_PASSWORD_BUTTON')}
            </Button>
          </Form.Item>
          <Link to={`/login`}>{`< ${t('GLOBAL.BACK')}`}</Link>
        </Form>
      </div>
    </div>
  );
};

const ChangePasswordStyled = styled(ChangePassword)`
  .right {
    form {
      img {
        width: 210px;
        display: block;
        margin: 0 auto;
      }

      .fields {
        .forgot-password {
          position: absolute;
          right: 0;
          margin-top: -70px;
          color: ${colors.green};
          text-decoration: underline;
        }
        label.ant-checkbox-wrapper {
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }
`;

const ChangePasswordWithQuotes: React.FC = () => {
  const Component = withQuote(ChangePasswordStyled);
  return <Component />;
};

export default ChangePasswordWithQuotes;
