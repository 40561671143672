import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import Header from './components/Header';
import Subheader from './components/Subheader';
import Department from './components/Department';
import { IBiDashboard } from '@/types/bi/dashboard.model';
import axios from 'axios';
import { handleError } from '@/utils';
import { Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

interface Props {
  className?: string;
}

const GroupAdmin: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { departments, activeDepartmentId, groupAdminStart, groupAdminUsers, groupAdminActiveUser },
    dispatch,
  } = useContext(AppContext);
  const { startDate } = useParams() as any;
  const [loading, setLoading] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<IBiDashboard | null>(null);

  // useEffect(() => {
  //   if (!startDate) return;
  //   dispatch({
  //     type: 'SET_GROUP_ADMIN_START',
  //     payload: moment(startDate),
  //   });
  //   dispatch({
  //     type: 'SET_GROUP_ADMIN_ACTIVE_USER',
  //     payload: null,
  //   });
  //   getDashboard();
  // }, [startDate]);

  useEffect(() => {
    getDashboard();
  }, []);

  const getDashboard = (recordId: string | null = null) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/bi/shifts`, {
        params: {
          departmentId: activeDepartmentId,
          startdate: recordId
            ? groupAdminStart.clone().startOf('week').format('YYYY-MM-DD')
            : groupAdminStart.format('YYYY-MM-DD'),
          enddate: recordId
            ? groupAdminStart.clone().endOf('week').format('YYYY-MM-DD')
            : groupAdminStart.clone().add(1, 'day').format('YYYY-MM-DD'),
          recordId: recordId ? recordId : undefined,
        },
      })
      .then(({ data }: { data: IBiDashboard }) => {
        const adminDepartments = departments
          .filter((department) => department.role == 'ADMIN')
          .map((department) => department.id);
        const sorted = data.departments
          .filter((department) => adminDepartments.includes(department.id))
          .sort((a, b) => a.name.localeCompare(b.name));
        setDashboard({
          departments: sorted,
          shifts: data.shifts,
        });
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRemoveUser = () => {
    dispatch({
      type: 'SET_GROUP_ADMIN_ACTIVE_USER',
      payload: null,
    });
    getDashboard(null);
  };

  return (
    <div className={className}>
      <div className="container">
        <Header dashboard={dashboard} getDashboard={getDashboard} startDate={startDate} />
        {dashboard && !loading ? (
          <>
            <div className="subheader-container">
              <Subheader dashboard={dashboard} />
            </div>
            {groupAdminActiveUser && (
              <div className="active-user-container" onClick={onRemoveUser}>
                <i className="icon-angle-left" />
                <span className="username">{groupAdminActiveUser.name}</span>
              </div>
            )}
            <div className="departments-container">
              {dashboard.departments.map((department) => {
                const filtered = department.users
                  .filter((user) => {
                    if (groupAdminUsers.length == 0) return true;
                    return groupAdminUsers.includes(user.id);
                  })
                  .map((user) => user.id);
                if (groupAdminUsers.length > 0 && filtered.length == 0) return;
                return (
                  <Department
                    key={department.id}
                    dashboard={dashboard}
                    department={department}
                    getDashboard={getDashboard}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <Spin className="spinner" spinning={loading} />
        )}
      </div>
    </div>
  );
};

export default styled(GroupAdmin)`
  padding: 15px;
  .container {
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;

    .subheader-container {
      margin-top: 25px;
    }

    .active-user-container {
      display: flex;
      align-items: center;
      margin-top: 25px;
      font-size: 14px;
      cursor: pointer;
    }

    .departments-container {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    .spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
    }
  }
`;
