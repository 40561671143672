import AppContext from '@/pages/app/context';
import { handleError } from '@/utils';
import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  modalCreateTurnoverTemplateVisible: boolean;
  setModalCreateTurnoverTemplateVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalCreateTurnoverTemplate: React.FC<Props> = ({
  className,
  modalCreateTurnoverTemplateVisible,
  setModalCreateTurnoverTemplateVisible,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, turnoverTemplates },
    dispatch,
  } = useContext(AppContext);
  const [form] = useForm();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    setModalCreateTurnoverTemplateVisible(false);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL_V4}/turnover-quota-templates`, values, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        form.resetFields();
        history.push(`/app/settings/insights/turnover-templates/${data.data[0].id}`);
        dispatch({
          type: 'SET_TURNOVER_TEMPLATES',
          payload: [...turnoverTemplates, ...data.data],
        });
        setModalCreateTurnoverTemplateVisible(false);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      className={className}
      visible={modalCreateTurnoverTemplateVisible}
      onCancel={onCancel}
      maskClosable
      title={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.CREATE_TEMPLATE')}
      okText={t('GLOBAL.CREATE')}
      cancelButtonProps={{ danger: true }}
      onOk={() => form.submit()}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="name"
          label={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_NAME')}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_NAME')} size="large" allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalCreateTurnoverTemplate)``;
