import AppContext from '@/pages/app/context';
import { IUser } from '@/types/user.model';
import { message, Radio, RadioChangeEvent, Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  user?: IUser;
}

const UserSelector: React.FC<Props> = ({ className, user }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, activeSection },
    dispatch,
  } = useContext(AppContext);
  const [users, setUsers] = useState<IUser[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<'active' | 'inactive'>('active');
  const [activeUser, setActiveUser] = useState<IUser | undefined>(user);

  useEffect(() => {
    setActiveUser(user);
  }, [user]);

  useEffect(() => {
    if (!activeDepartmentId) {
      return;
    }

    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setUsers([]);
    setLoadingUsers(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users`, {
        params: {
          departmentId: activeDepartmentId,
          inactive: activeTab === 'inactive' ? true : undefined,
          section: activeSection ? activeSection : undefined,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        if (mounted) {
          const { data } = response;
          if (activeTab !== 'inactive') {
            dispatch({
              type: 'SET_USERS',
              payload: data.users,
            });
            dispatch({
              type: 'SET_USERS_FIELDS',
              payload: data.fields,
            });
            dispatch({
              type: 'SET_LOADING_USERS',
              payload: false,
            });
          }
          setUsers(data.users);
          setLoadingUsers(false);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error(error);
          message.error(t('USERS.MESSAGE_LOADING_ERROR'));
        }
        if (mounted) {
          dispatch({
            type: 'SET_LOADING_USERS',
            payload: false,
          });
          setLoadingUsers(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId, activeTab]);

  const onUserChange = (value: string) => {
    if (value) {
      setActiveUser(users.find((user) => user.recordId == value)!);
      history.replace(`/app/team/collaborators/${value}/dashboard`);
    }
  };

  const onTabChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    if (!value) return;

    setActiveTab(value);
  };

  return (
    <div className={className}>
      <Radio.Group buttonStyle="solid" size="large" className="light block" value={activeTab} onChange={onTabChange}>
        <Radio.Button value="active">{t('GLOBAL.ACTIVE')}</Radio.Button>
        <Radio.Button value="inactive">{t('GLOBAL.INACTIVE')}</Radio.Button>
      </Radio.Group>
      <div className="user-select-container">
        <span>{t('GLOBAL.USER')}</span>
        <Select
          disabled={loadingUsers}
          loading={loadingUsers}
          value={activeUser?.recordId!}
          onChange={onUserChange}
          size="large"
          filterOption={(value, option) => {
            if (option?.children) {
              return option.children?.toLowerCase().includes(value.toLowerCase());
            }
            return false;
          }}
        >
          {users.map((user) => (
            <Select.Option key={user.recordId!} value={user.recordId!}>
              {user.displayName}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default styled(UserSelector)`
  display: none;
  margin: 25px;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;

    .user-select-container {
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      .ant-select-selector {
        background-color: #fff;
      }
    }
  }
`;
