import Error404 from '@/components/Error404';
import ContainerView from '@/layouts/ContainerView';
import AppContext from '@/pages/app/context';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { GROUP_ADMIN_ALLOWED_ACCOUNTS, isFeatureEnabled } from '@/utils';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import ClockinAnomaliesPage from './clockin-anomalies';
import AppHoursFreeShiftAlertsPage from './free-shift-alerts';
import GroupAdmin from './group-admin';
import AppHoursManagePage from './manage';
import ShiftBlocks from './manage/monthly/components/ShiftBlocks';
import AppHoursMessagesPage from './messages';
import ShiftApprovalsPage from './shift-approvals';
import ShiftModificationRequestsPage from './shift-modification-requests';
import AppHoursShyftsMarketPage from './shyfts-market';
import AppHoursShiftSwapRequestsPage from './swap-shift';
import AppHoursTasksForecastsPage from './tasks-forecasts';
import AppTeamVacationRequestsPage from './vacation-requests';

interface Props {
  className?: string;
  department?: IDepartment;
  access?: IDepartment['access'];
}

const AppHoursPage: React.FC<Props> = ({ className, access, department }) => {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const month_view = pathname.startsWith('/app/hours/manage/month');
  const {
    state: { features },
  } = useContext(AppContext);

  return (
    <div className={className}>
      <Helmet>
        <title>{t('GLOBAL.SCHEDULE')} - Shyfter</title>
      </Helmet>
      <div>
        <Switch>
          <Route exact path={`${path}`} render={() => <Redirect to={`${url}/manage`} />} />
          {department?.accountId && GROUP_ADMIN_ALLOWED_ACCOUNTS.includes(department.accountId) && (
            <Route path={`${path}/group-admin/:startDate`}>
              <GroupAdmin />
            </Route>
          )}
          <Route path={`${path}/manage`}>
            <div
              id="app-hours-container"
              className="app-hours-container"
              tabIndex={0}
              style={{
                maxHeight: month_view ? 'calc(100vh - 185px)' : 'calc(100vh - 80px)',
                boxShadow: !month_view ? '0 2px 4px rgba(0, 0, 0, 0.2)' : 'none',
              }}
            >
              <AppHoursManagePage />
            </div>
            {month_view && <ShiftBlocks />}
          </Route>
          {!!isFeatureEnabled(features, FEATURES.CLOCKING) && access?.clockings && (
            <Route path={`${path}/clockin-anomalies`}>
              <ContainerView>
                <ClockinAnomaliesPage onlyAnomalies={true} setOnlyAnomalies={() => {}} />
              </ContainerView>
            </Route>
          )}
          {!!department?.scheduleParams?.validateShifts && (
            <Route path={`${path}/shift-approvals`}>
              <ContainerView>
                <ShiftApprovalsPage />
              </ContainerView>
            </Route>
          )}
          {!!isFeatureEnabled(features, FEATURES.FREESHIFTS) && !!department?.scheduleParams?.shyftsMarket && (
            <Route path={`${path}/shyfts-market`}>
              <ContainerView>
                <AppHoursShyftsMarketPage />
              </ContainerView>
            </Route>
          )}
          {access?.requests && (
            <Route path={`${path}/shift-modification-requests`}>
              <ContainerView>
                <ShiftModificationRequestsPage />
              </ContainerView>
            </Route>
          )}
          <Route path={`${path}/messages`}>
            <ContainerView>
              <AppHoursMessagesPage />
            </ContainerView>
          </Route>
          {!!isFeatureEnabled(features, FEATURES.FREESHIFTS) && (
            <Route path={`${path}/shift-notifications`}>
              <ContainerView>
                <AppHoursFreeShiftAlertsPage />
              </ContainerView>
            </Route>
          )}
          {department?.id === '1445' && (
            <Route path={`${path}/tasks-forecasts`}>
              <ContainerView>
                <AppHoursTasksForecastsPage />
              </ContainerView>
            </Route>
          )}
          <Route path={`${path}/vacation-requests`}>
            <ContainerView>
              <AppTeamVacationRequestsPage />
            </ContainerView>
          </Route>
          <Route path={`${path}/swap-shift`}>
            <ContainerView>
              <AppHoursShiftSwapRequestsPage />
            </ContainerView>
          </Route>
          <Route component={() => <Error404 />} />
        </Switch>
      </div>
    </div>
  );
};

const AppHoursPageStyled = styled(AppHoursPage)`
  height: calc(100vh - 50px - 15px);

  .app-hours-container {
    margin: 15px;
    background-color: white;
    border-radius: 4px;
    overflow-y: auto;
  }
`;

export default AppHoursPageStyled;
