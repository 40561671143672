import React, { useState, useEffect, useContext, useRef } from 'react';
import TableView from '@/layouts/TableView';
import Header from './components/Header';
import TableRowActions from './components/TableRowActions';
import { Table, message, Modal, Input } from 'antd';
import axios from 'axios';
import AppContext from '@/pages/app/context';
import { AlignType } from '@/types/alignType.model';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import ModalDefaultHrCodes from './components/ModalDefaultHrCodes';
import DrawerCustomHr from '@/pages/app/components/drawers/CustomHr';
import { ICustomHrCode } from '@/types/custom-hr-code.model';
import colors from '@/styles/colors';
import { handleError } from '@/utils';
import ModalDeleteConfirmation from './components/ModalDeleteConfirmation';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
}

const ManageCustomHrCodes: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartment, loadingCustomHrCodes, customHrCodes, hrPartners },
    dispatch,
  } = useContext(AppContext);
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });
  const [filteredCustomHrCodes, setFilteredCustomHrCodes] = useState<ICustomHrCode[]>(customHrCodes);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [modalDefaultHrPartnersVisible, setModalDefaultHrPartnersVisible] = useState<boolean>(false);
  const [activeCustomHrCode, setActiveCustomHrCode] = useState<ICustomHrCode | null>(null);
  const [activeCodeId, setActiveCodeId] = useState<string | null>(null);
  const [modalDeleteConfirmationVisible, setModalDeleteConfirmationVisible] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {}, []);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.HR_CODE_SHORT'),
        dataIndex: 'hr_code',
        key: 'hr_code',
      },
      {
        title: t('GLOBAL.CODE'),
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: t('GLOBAL.DESCRIPTION'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: ICustomHrCode) => (
          <TableRowActions onEdit={() => onEdit(record)} onDelete={() => onWantToDelete(record.id!)} />
        ),
      },
    ]);
  }, [customHrCodes, i18n.language]);

  useEffect(() => {
    const customHrCodesCopy = [...customHrCodes];

    const filtered = customHrCodesCopy.filter(
      (code) =>
        code.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        code.hr_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        code.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    if (searchTerm == '') {
      setFilteredCustomHrCodes(customHrCodes);
    } else {
      setFilteredCustomHrCodes(filtered);
    }
  }, [customHrCodes, searchTerm]);

  const onWantToDelete = (id: string) => {
    setActiveCodeId(id);
    setModalDeleteConfirmationVisible(true);
  };

  const onDelete = () => {
    if (!activeCodeId) return;
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/custom-hr-codes/${activeCodeId}`, {
        params: {
          departmentId: activeDepartment?.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_CUSTOM_HR_CODES',
          payload: customHrCodes.filter((code) => code.id !== activeCodeId),
        });
        message.success(t('CUSTOM_HR_CODES.MESSAGES.DELETE_SUCCESS'));
        setModalDeleteConfirmationVisible(false);
        setActiveCodeId(null);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchTerm(value);
  };

  const onCreate = () => {
    setActiveCustomHrCode(null);
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const onEdit = (record: ICustomHrCode) => {
    setDrawerVisible(true);
    setActiveCustomHrCode(record);
  };

  return (
    <React.Fragment>
      <DrawerCustomHr
        visible={drawerVisible}
        onClose={onCloseDrawer}
        customHrCode={activeCustomHrCode}
        defaultHrPartners={hrPartners}
      />
      <ModalDefaultHrCodes
        defaultHrCodes={hrPartners}
        visible={modalDefaultHrPartnersVisible}
        setVisible={setModalDefaultHrPartnersVisible}
      />
      <h2>{t('SETTINGS.HR.SOCSEC.TITLE')}</h2>
      <span style={{ color: colors.grey }}>{t('SETTINGS.HR.SOCSEC.DESCRIPTION')}</span>
      <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10, marginTop: 25 }}>
        <TableView>
          <Header
            onSearchChange={onSearchChange}
            onCreate={onCreate}
            setModalDefaultHrPartnersVisible={setModalDefaultHrPartnersVisible}
          />
          <Table
            className={className}
            loading={loadingCustomHrCodes}
            dataSource={filteredCustomHrCodes}
            columns={
              hrPartners.length == 1
                ? columns
                : [
                    {
                      title: t('GLOBAL.SSA'),
                      dataIndex: 'partner_name',
                      key: 'partner_name',
                    },
                    ...columns,
                  ]
            }
            rowKey="id"
            pagination={false}
          />
        </TableView>
      </div>
      <ModalDeleteConfirmation
        visible={modalDeleteConfirmationVisible}
        setVisible={setModalDeleteConfirmationVisible}
        onOk={onDelete}
      />
    </React.Fragment>
  );
};

export default ManageCustomHrCodes;
