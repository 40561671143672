import React, { useEffect, useState, useContext } from 'react';
import Header from './components/Header';
import { BackTop } from 'antd';
import TableView from '@/layouts/TableView';
import Axios from 'axios';
import AppContext from '@/pages/app/context';
import { IShiftRequest } from '@/types/shift-request.model';
import ShiftRequestsTable from './components/ShiftRequestsTable';
import ModalProceedShiftRequest from './components/ModalProceedShiftRequest';

const ShiftModificationRequestsPage: React.FC = () => {
  const {
    state: { activeDepartmentId, activeSection },
  } = useContext(AppContext);
  const [shiftRequests, setShiftRequests] = useState<IShiftRequest[]>([]);
  const [shiftRequest, setShiftRequest] = useState<IShiftRequest | null>(null);
  const [filteredShiftRequests, setFilteredShiftRequests] = useState<IShiftRequest[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shiftRequestActionCounter, setShiftRequestActionCounter] = useState<number>(0);

  useEffect(() => {
    let mounted = true;
    setShiftRequests([]);
    setFilteredShiftRequests([]);
    setIsLoading(true);
    if (activeDepartmentId) {
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/shift-requests`, {
        params: {
          departmentId: activeDepartmentId,
          sectionId: activeSection,
        },
      })
        .then((response) => {
          setIsLoading(false);
          if (mounted) {
            const requests = response.data?.requests || [];
            setShiftRequests(requests);
            setFilteredShiftRequests(requests);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });

      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [activeDepartmentId, activeSection, shiftRequestActionCounter]);

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    if (shiftRequests) {
      const filteredShiftRequests = shiftRequests.filter((shiftRequest: IShiftRequest) => {
        return shiftRequest.shift?.user?.displayName?.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredShiftRequests(filteredShiftRequests);
    } else {
      setFilteredShiftRequests([]);
    }
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header onSearchChange={onSearchChange} disabled={isLoading} />
      <ShiftRequestsTable
        displayName={true}
        loading={isLoading}
        dataSource={filteredShiftRequests}
        setShiftRequest={setShiftRequest}
      />
      <ModalProceedShiftRequest
        departmentId={activeDepartmentId}
        shiftRequest={shiftRequest}
        onClose={() => setShiftRequest(null)}
        onSave={() => setShiftRequestActionCounter(shiftRequestActionCounter + 1)}
      />
    </TableView>
  );
};

export default ShiftModificationRequestsPage;
