import React, { Dispatch, useContext } from 'react';
import styled from 'styled-components';
import Colors from '@/styles/colors';
import Shift from './Shift';
import AddShift from './AddShift';
import { InitialStateType, ITypicalWeekShift } from '../../redux/store';
import moment from 'moment';
import { ActionType } from '../../redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AppContext from '@/pages/app/context';
import axios from 'axios';
import { IScheduleModel } from '@/types/schedule-model.model';
export const MONDAY = 345600;
export const DAY = 86400;

interface Props {
  className?: string;
  weekId: string;
  weekIndex: number;
  days: {
    index: number;
    shifts: ITypicalWeekShift[];
  }[];
  scheduleModel: IScheduleModel;
  setScheduleModel: React.Dispatch<React.SetStateAction<IScheduleModel | null>>;
  setScheduleModels: React.Dispatch<React.SetStateAction<IScheduleModel[]>>;
}

const Shifts: React.FC<Props> = ({
  className,
  weekIndex,
  weekId,
  days,
  scheduleModel,
  setScheduleModel,
  setScheduleModels,
}) => {
  const {
    state: { activeDepartmentId, activeDepartment, activeSection },
  } = useContext(AppContext);
  const { indexMapByShiftId, weeks } = useSelector(
    ({ indexMapByShiftId, weeks }: InitialStateType) => ({ indexMapByShiftId, weeks }),
    shallowEqual,
  );
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const onDrop = (e: React.DragEvent, dayIndex: number) => {
    (e.target as HTMLElement).style.opacity = '1';
    (e.target as HTMLElement).classList.remove('onDragOver');

    const source = {
      shiftId: e.dataTransfer.getData('shiftId'),
      weekId: e.dataTransfer.getData('weekId'),
      weekIndex: e.dataTransfer.getData('weekIndex'),
      dayIndex: e.dataTransfer.getData('dayIndex'),
    };

    if (`${dayIndex}` === source.dayIndex && weekId === source.weekId) {
      return;
    }

    const day = days.find((day) => `${day.index}` == `${source.dayIndex}`);
    const shift = day?.shifts.find((shift) => shift.id == source.shiftId);

    if (!shift) {
      return;
    }

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/users/schedule-models/${source.weekId}/shifts/${shift.id}`,
        {
          ...shift,
          day_number: dayIndex,
          section: shift.section ? shift.section.id : null,
          skills: shift.skills ? shift.skills.map((skill) => skill.id) : null,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        setScheduleModels(data);
        const found = data.find((sm: IScheduleModel) => sm.id === scheduleModel.id);
        if (found) {
          setScheduleModel(found);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDragEnter = (e: React.DragEvent) => {
    (e.target as HTMLElement).classList.add('onDragOver');
  };

  const onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const onDragLeave = (e: React.DragEvent) => {
    (e.target as HTMLElement).classList.remove('onDragOver');
  };

  const onDragStart = (e: React.DragEvent, shiftId: string, dayIndex: number) => {
    (e.target as HTMLElement).style.opacity = '0.3';
    e.dataTransfer.setData('shiftId', `${shiftId}`);
    e.dataTransfer.setData('dayIndex', `${dayIndex}`);
    e.dataTransfer.setData('weekId', `${weekId}`);
    e.dataTransfer.setData('weekIndex', `${weekIndex}`);
    e.dataTransfer.dropEffect = 'move';
  };

  const onDragEnd = (e: React.DragEvent) => {
    (e.target as HTMLElement).style.opacity = '1';
  };

  return (
    <div className={className}>
      {days.map((day, dayIndex) => {
        const timestamp = weekIndex * DAY * 7 + (dayIndex * DAY + MONDAY);
        return (
          <div
            key={`sgi_${weekIndex}_day_${dayIndex}`}
            onDrop={(e) => onDrop(e, dayIndex)}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            className={`droppable-zone week`}
          >
            <React.Fragment>
              <React.Fragment>
                {day.shifts.map((shift) => (
                  <Shift
                    shift={shift}
                    key={`_si_${shift.id}`}
                    onDragStartHandler={(e: any) => onDragStart(e, shift.id!, dayIndex)}
                    onDragEndHandler={onDragEnd}
                    activeSection={activeSection}
                    activeDepartment={activeDepartment}
                    weekId={weekId}
                  />
                ))}
              </React.Fragment>
              <AddShift
                size="large"
                shift={{
                  timestamp: timestamp * 1000,
                  section: activeSection ? { id: activeSection } : undefined,
                  start: timestamp + (activeDepartment?.scheduleParams?.default_shift_start_time || 0) * 3600,
                  end: timestamp + (activeDepartment?.scheduleParams?.default_shift_end_time || 0) * 3600,
                  weekId,
                }}
                scheduleModel={scheduleModel}
                setScheduleModel={setScheduleModel}
              />
            </React.Fragment>
          </div>
        );
      })}
    </div>
  );
};

const ShiftsStyled = styled(React.memo(Shifts))`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: flex-start;
  text-align: center;
  border-bottom: 1px solid ${Colors.blueLight};
  width: auto;
  @media print {
    align-items: stretch !important;
  }

  > .droppable-zone {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    box-sizing: border-box;
    flex-direction: column;
    padding: 10px;
    overflow: hidden;
    width: 50px;

    @media print {
      height: 100%;
      padding: 2px 4px;
    }

    &.month {
      width: 180px;
    }

    &.onDragOver  {
      background: rgba(196, 199, 208, 0.2);
      * {
        pointer-events: none;
      }
    }

    &:nth-child(n + 2) {
      border-left: 1px solid ${Colors.blueLight};
    }

    > .shift-counter {
      background: rgba(0, 0, 0, 0.1);
      padding: 0px 10px;
      border-radius: 5px;
    }
  }
`;

export default ShiftsStyled;
