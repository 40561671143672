import Error404 from '@/components/Error404';
import { usePusher } from '@/components/PusherProvider';
import DrawerShift from '@/pages/app/components/drawers/Shift';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { IScheduleHour } from '@/types/schedule-hour.model';
import { IShift } from '@/types/shift.model';
import { handleError, isFeatureEnabled } from '@/utils';
import { Button, Empty, Result, Spin, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider, batch, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import DrawerDimona from '../../components/drawers/Dimona';
import DrawerMultipleShifts from '../../components/drawers/MultipleShifts';
import DrawerShiftBlock from '../../components/drawers/ShiftBlock';
import Header from './components/Header';
import ModalErrors from './components/ModalErrors';
import MonthlySubHeader from './components/MonthlySubHeader';
import SubHeader from './components/SubHeader';
import ScheduleStats from './daily/components/ScheduleStats';
import AppHoursManageDaily from './daily/index';
import AppHoursManageHourly from './hourly/index';
import ModalShiftsToReplace from './monthly/components/ModalShiftsToReplace';
import AppHoursManageMonthly from './monthly/index';
import AppHoursManageOperational from './operational/index';
import { ActionType } from './redux/actions';
import { InitialStateType, store } from './redux/store';

const AppHoursManageProvider: React.FC = () => {
  return (
    <Provider store={store}>
      <AppHoursManagePage />
    </Provider>
  );
};

const AppHoursManagePage: React.FC = () => {
  const { path, url } = useRouteMatch();
  const {
    showOtherDepartments,
    startDate,
    endDate,
    isLoading,
    picker,
    shiftDrawerVisible,
    assignModalVisible,
    multipleShiftsDrawerVisible,
    hasError,
    shiftsMap,
    shiftsByUsers,
    newDimona,
    errors,
    opened_monthly_dropdown_identifier,
    monthly_shifts_to_replace,
    ayShiftIds,
    ayShiftIdsLoading,
  } = useSelector(
    ({
      showOtherDepartments,
      startDate,
      endDate,
      isLoading,
      picker,
      assignModalVisible,
      shiftDrawerVisible,
      multipleShiftsDrawerVisible,
      hasError,
      shiftsMap,
      shiftsByUsers,
      selectedShift,
      newDimona,
      errors,
      opened_monthly_dropdown_identifier,
      monthly_shifts_to_replace,
      ayShiftIds,
      ayShiftIdsLoading,
    }: InitialStateType) => ({
      showOtherDepartments,
      startDate,
      endDate,
      isLoading,
      picker,
      assignModalVisible,
      shiftDrawerVisible,
      multipleShiftsDrawerVisible,
      hasError,
      shiftsMap,
      shiftsByUsers,
      selectedShift,
      newDimona,
      errors,
      opened_monthly_dropdown_identifier,
      monthly_shifts_to_replace,
      ayShiftIds,
      ayShiftIdsLoading,
    }),
    shallowEqual,
  );

  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const { state: appContextState } = useContext(AppContext);
  const { activeDepartment, activeSection, skills, userCategories, features } = appContextState;
  const [shiftsToApprove, setShiftsToApprove] = useState<string[]>([]);
  const [shiftNotApprovedIds, setShiftNotApprovedIds] = useState<string[]>([]);
  const [showStats, setShowStats] = useState<boolean>(false);
  const [loadingStats, setLoadingStats] = useState<boolean>(false);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [turnover, setTurnover] = useState<any>(null);
  const [quotas, setQuotas] = useState<any>(null);
  const [balance, setBalance] = useState<any>(null);
  const [loadingQuotas, setLoadingQuotas] = useState<boolean>(false);
  const [loadingBalance, setLoadingBalance] = useState<boolean>(false);
  const [ayShiftIdTemp, setAyShiftIdTemp] = useState<string | null>(null);

  const refSubHeader = useRef(null);
  const refTotalHours = useRef(null);
  const refPageContainer = useRef(null);

  const pusher = usePusher();
  let channel: any = null;

  useEffect(() => {
    if (!ayShiftIdTemp) return;
    hoursDispatch({
      type: 'SET_AY_SHIFT_IDS',
      payload: [...ayShiftIds, ayShiftIdTemp],
    });
  }, [ayShiftIdTemp]);

  useEffect(() => {
    if (ayShiftIdsLoading || !activeDepartment) return;
    if (pusher) {
      // PUSHER
      channel = pusher.channel(`private-department-${activeDepartment.id}`);

      if (!channel) return;

      const callbacks = channel.callbacks;
      if (!callbacks.get('ay.updated')) {
        channel.bind('ay.updated', async (message: any) => {
          const { shiftId } = message;
          if (!shiftId) return;
          setAyShiftIdTemp(String(shiftId));
        });
      }
    }

    return () => {
      if (pusher) {
        pusher.disconnect();
      }
    };
  }, [pusher, channel, activeDepartment, ayShiftIdsLoading]);

  useEffect(() => {
    const scheduleStats = localStorage.getItem(`schedule-stats-${activeDepartment?.id}`);
    if (scheduleStats == 'true') {
      setShowStats(true);
    } else {
      setShowStats(false);
    }
  }, [localStorage]);

  const scrollHandler = (e: any) => {
    const scrollLeft = e.target.scrollLeft;
    const subHeaderElt = refSubHeader!.current! as HTMLElement;
    const totalCountElt = refTotalHours!.current! as HTMLElement;
    if (subHeaderElt) {
      subHeaderElt.scrollLeft = scrollLeft + window.scrollX;
    }
    if (totalCountElt) {
      totalCountElt.scrollLeft = scrollLeft + window.scrollX;
    }
  };

  const keyDownHandler = (e: any) => {
    const { keyCode } = e;
    if (keyCode === 38 || keyCode === 40) {
      if (document.activeElement?.tagName === 'BODY') {
        const hoursContainer = document.getElementsByClassName('app-hours-container')[0] as any;
        hoursContainer?.focus?.();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (refPageContainer) {
      const element = refPageContainer!.current! as HTMLElement;
      element.addEventListener('scroll', scrollHandler);

      return () => {
        element.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [refPageContainer]);

  useEffect(() => {
    window.addEventListener('click', onClick);
    return () => {
      window.removeEventListener('click', onClick);
    };
  }, []);

  const getAbsoluteYouShifts = () => {
    if (!activeDepartment?.ay_core) return;
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/ay/shifts`, {
        params: {
          departmentId: activeDepartment?.id,
          startDate: startDate.unix(),
          endDate: endDate.unix(),
        },
      })
      .then(({ data }) => {
        hoursDispatch({
          type: 'SET_AY_SHIFT_IDS',
          payload: data,
        });
      })
      .catch((err) => handleError(err))
      .finally(() => {
        hoursDispatch({
          type: 'SET_AY_SHIFT_IDS_LOADING',
          payload: false,
        });
      });
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    if (startDate && endDate) {
      getAbsoluteYouShifts();
      setTurnover(null);
      hoursDispatch({
        type: 'SET_IS_LOADING',
        payload: true,
      });
      hoursDispatch({
        type: 'SET_HAS_ERROR',
        payload: false,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/shifts`, {
          params: {
            departmentId: !showOtherDepartments ? activeDepartment?.id : undefined,
            sectionId: activeSection,
            startDate: startDate.startOf('day').unix(),
            endDate: endDate.unix() < startDate.unix() ? endDate.clone().add(1, 'day').unix() : endDate.unix(),
            picker: picker == 'operational' ? 'day' : picker,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          const {
            shifts,
            users,
            days = [],
            hours = [],
            totalBudget = null,
            comparisonTemplate = null,
            cycleNumber = null,
            scheduleModel = false,
            productivityIndex = null,
            productivityIndexReal = null,
          } = response.data;
          const filteredShifts = [...shifts].filter(
            (shift: IShift) => moment.unix(shift.start!).day() == startDate.day() && shift.shyftType == 1,
          );
          const earliestShift = filteredShifts.reduce((earliestShift, currentShift) => {
            const shiftStartDate = moment.unix(currentShift.start);

            if (shiftStartDate.isBefore(moment.unix(earliestShift.start))) {
              return currentShift;
            }

            return earliestShift;
          }, filteredShifts[0]);
          const latestShift = filteredShifts.reduce((latestShift, currentShift) => {
            const shiftEndDate = moment.unix(currentShift.end);

            if (shiftEndDate.isAfter(moment.unix(latestShift.end))) {
              return currentShift;
            }

            return latestShift;
          }, filteredShifts[0]);
          batch(() => {
            const startHour = activeDepartment?.scheduleParams?.startHour || 0;
            const endHour = activeDepartment?.scheduleParams?.endHour || 24;
            const dynamicHours = activeDepartment?.scheduleParams?.dynamicHour;

            let nextDay = false;
            if (earliestShift && latestShift && earliestShift.start && latestShift.end) {
              nextDay = moment.unix(latestShift.end).day() != moment.unix(earliestShift.start).day();
            }

            const start = dynamicHours
              ? earliestShift && earliestShift.start
                ? moment.unix(earliestShift.start).subtract(1, 'hour').hours()
                : 9
              : startHour;
            const end = dynamicHours
              ? latestShift && latestShift.end
                ? moment.unix(latestShift.end).add(1, 'hour').hours()
                : 24
              : endHour;

            const tz = activeDepartment?.timezone || 'Europe/Brussels';
            const isMidnight =
              earliestShift && earliestShift.startDate
                ? earliestShift.startDate.split(' ')[1].startsWith('00:')
                : false;

            hoursDispatch({
              type: 'SET_SHIFTS',
              payload: {
                skills,
                userStatus: userCategories,
                picker,
                activeDepartment,
                shifts,
                users,
                days,
                hours:
                  picker == 'day'
                    ? hours.filter(
                        (hour: IScheduleHour) =>
                          hour.date >= moment(startDate.clone().set({ hours: isMidnight ? -1 : start })).unix() &&
                          hour.date <=
                            moment(
                              startDate
                                .clone()
                                .add(1, 'day')
                                .set({ hour: end + 1 })
                                .toDate(),
                            ).unix(),
                      )
                    : hours,
                totalBudget,
                comparisonTemplate,
                cycleNumber,
                scheduleModel,
                productivityIndex,
                productivityIndexReal,
                activeSection,
              },
            });
            hoursDispatch({
              type: 'UPDATE_FILTERED_SHIFTS',
              payload: { department: activeDepartment },
            });
            hoursDispatch({
              type: 'SET_IS_LOADING',
              payload: false,
            });
          });
          setLoadingStats(true);
          axios
            .get(`${process.env.REACT_APP_API_URL}/v3/insights/daily-turnover`, {
              params: {
                departmentId: activeDepartment?.id,
                sectionId: activeSection,
                start: startDate.unix(),
              },
              cancelToken: cancelTokenSource.token,
            })
            .then(({ data }) => {
              const turnover = { ...data.provisional };
              Object.entries(data.provisional).map(([date, value]) => {
                if (data.real[date] !== null) {
                  turnover[date] = { value: data.real[date], type: 'real', provisional: data.provisional[date] };
                } else {
                  turnover[date] = { value: data.provisional[date], type: 'provisional' };
                }
              });
              setTurnover(turnover);
            })
            .catch((err) => {
              setTurnover(null);
            })
            .finally(() => {
              setLoadingStats(false);
            });

          if (isFeatureEnabled(features, FEATURES.QUOTAS)) {
            axios
              .get(`${process.env.REACT_APP_API_URL}/v3/insights/quota`, {
                params: {
                  departmentId: activeDepartment?.id,
                  sectionId: activeSection,
                },
                cancelToken: cancelTokenSource.token,
              })
              .then(({ data }) => {
                setQuotas(data);
              })
              .catch((err) => {
                setQuotas(null);
              })
              .finally(() => {
                setLoadingQuotas(false);
              });
          }

          if (isFeatureEnabled(features, FEATURES.PRODUCTIVITY)) {
            axios
              .get(`${process.env.REACT_APP_API_URL}/v3/insights/staff-balance`, {
                params: {
                  departmentId: activeDepartment?.id,
                  sectionId: activeSection,
                },
                cancelToken: cancelTokenSource.token,
              })
              .then(({ data }) => {
                setBalance(data.balance);
              })
              .catch((err) => {
                setBalance(null);
              })
              .finally(() => {
                setLoadingBalance(false);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          if (!axios.isCancel(error)) {
            batch(() => {
              hoursDispatch({
                type: 'SET_SHIFTS',
                payload: {
                  skills,
                  userStatus: userCategories,
                  picker,
                  activeDepartment,
                  users: [],
                  shifts: [],
                  days: [],
                  hours: [],
                  totalBudget: null,
                  comparisonTemplate: null,
                  cycleNumber: null,
                  productivityIndex: null,
                  productivityIndexReal: null,
                  scheduleModel: false,
                  activeSection,
                },
              });
              hoursDispatch({
                type: 'UPDATE_FILTERED_SHIFTS',
                payload: { department: activeDepartment },
              });
              hoursDispatch({
                type: 'SET_HAS_ERROR',
                payload: true,
              });
              hoursDispatch({
                type: 'SET_IS_LOADING',
                payload: false,
              });
            });
          }
        });
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [startDate, endDate, activeDepartment?.id, activeSection, showOtherDepartments, skills]);

  const clearErrors = () => {
    hoursDispatch({
      type: 'SET_ERRORS',
      payload: [],
    });
  };

  const onClick = () => {
    if (opened_monthly_dropdown_identifier) {
      hoursDispatch({
        type: 'SET_OPENED_MONTHLY_DROPDOWN_IDENTIFIER',
        payload: null,
      });
    }
  };

  return (
    <Provider store={store}>
      {picker === 'month' && (
        <ModalShiftsToReplace
          visible={monthly_shifts_to_replace && monthly_shifts_to_replace.length > 0 ? true : false}
        />
      )}
      <Header
        shiftsToApprove={shiftsToApprove}
        setShiftsToApprove={setShiftsToApprove}
        shiftNotApprovedIds={shiftNotApprovedIds}
        setShiftNotApprovedIds={setShiftNotApprovedIds}
        isLoading={isLoading}
        showStats={showStats}
        setShowStats={setShowStats}
        turnover={turnover}
      />
      {picker == 'week' && showStats && !shiftsByUsers.every((item) => item.shifts.length === 0) && (
        <ScheduleStats turnover={turnover} loadingStats={loadingStats} />
      )}
      <div
        className={`app-hours-manage-container ${picker}`}
        style={{ maxWidth: '100%', overflow: 'auto' }}
        ref={refPageContainer}
      >
        <Spin spinning={picker !== 'operational' && isLoading} size="large">
          {picker === 'month' ? (
            <MonthlySubHeader
              refSubHeader={refSubHeader}
              refPageContainer={refPageContainer}
              shiftNotApprovedIds={shiftNotApprovedIds}
              shiftsToApprove={shiftsToApprove}
            />
          ) : (
            <>
              {picker !== 'operational' && (
                <SubHeader
                  refSubHeader={refSubHeader}
                  refPageContainer={refPageContainer}
                  shiftNotApprovedIds={shiftNotApprovedIds}
                  shiftsToApprove={shiftsToApprove}
                />
              )}
            </>
          )}
          <Switch>
            <Route
              exact
              path={`${path}`}
              render={() => (
                <Redirect
                  to={`${url}/${
                    activeDepartment?.scheduleParams?.default_schedule_view == 'ops'
                      ? 'operational'
                      : activeDepartment?.scheduleParams?.default_schedule_view
                  }/${moment().format('YYYY-MM-DD')}`}
                />
              )}
            />
            <Route path={`${path}/day/:formatedDate`}>
              <AppHoursManageHourly
                refTotalHours={refTotalHours}
                refPageContainer={refPageContainer}
                activeSection={activeSection}
                department={activeDepartment || null}
                quotas={quotas}
                turnover={turnover}
                balance={balance}
              />
            </Route>
            <Route path={`${path}/operational/:formatedDate`}>
              <AppHoursManageOperational />
            </Route>
            <Route path={`${path}/week/:formatedDate`}>
              <AppHoursManageDaily
                key="app_hours_manage_week"
                refTotalHours={refTotalHours}
                refPageContainer={refPageContainer}
                activeSection={activeSection}
                department={activeDepartment || null}
                turnover={turnover}
              />
            </Route>
            {/* <Route path={`${path}/month/:formatedDate`}>
              <AppHoursManageDaily
                key="app_hours_manage_month"
                refTotalHours={refTotalHours}
                refPageContainer={refPageContainer}
                activeSection={activeSection}
                department={activeDepartment || null}
              />
            </Route> */}
            <Route path={`${path}/month/:formatedDate`}>
              <AppHoursManageMonthly
                key="app_hours_manage_month"
                refTotalHours={refTotalHours}
                refPageContainer={refPageContainer}
                activeSection={activeSection}
                department={activeDepartment || null}
              />
              <ModalErrors errors={errors} clearErrors={clearErrors} />
            </Route>
            <Route component={() => <Error404 />} />
          </Switch>
          {hasError && (
            <Result
              status="warning"
              title="There are some problems with your operation."
              extra={
                <Button type="primary" key="console">
                  Go Console
                </Button>
              }
            />
          )}
          {!hasError &&
            !isLoading &&
            shiftsMap.size === 0 &&
            picker !== 'month' &&
            !activeDepartment?.scheduleParams?.showallRessources && (
              <Empty style={{ padding: 40 }}>
                <h3 style={{ textAlign: 'center', color: colors.green }}>
                  {picker === 'day' ? t('SCHEDULE.ADD_SHIFT_DAY_HELP') : t('SCHEDULE.ADD_SHIFT_WEEK_HELP')}
                </h3>
              </Empty>
            )}
          {!hasError &&
            !isLoading &&
            shiftsMap.size > 0 &&
            shiftsByUsers.length === 1 &&
            picker !== 'month' &&
            !activeDepartment?.scheduleParams?.showallRessources && (
              <h3 style={{ padding: 40, textAlign: 'center', color: colors.green }}>
                {picker === 'day' ? t('SCHEDULE.ADD_SHIFT_DAY_HELP') : t('SCHEDULE.ADD_SHIFT_WEEK_HELP')}
              </h3>
            )}
        </Spin>
      </div>
      <DrawerShiftBlock />
      <DrawerShift
        visible={!assignModalVisible && shiftDrawerVisible}
        setVisible={(visibility: boolean) =>
          hoursDispatch({
            type: 'SET_SHIFT_DRAWER_VISIBLE',
            payload: visibility,
          })
        }
      />
      <DrawerMultipleShifts
        visible={multipleShiftsDrawerVisible}
        setVisible={(visibility: boolean) =>
          hoursDispatch({
            type: 'SET_MULTIPLE_SHIFTS_DRAWER_VISIBLE',
            payload: visibility,
          })
        }
      />
      {isFeatureEnabled(features, FEATURES.DIMONA) && (
        <DrawerDimona
          dimona={newDimona}
          visible={!!newDimona}
          onSave={() => {
            message.info(t('DIMONA.DIMONA_DECLARATION_IN_PROGRESS'));
          }}
          onClose={() =>
            hoursDispatch({
              type: 'SET_NEW_DIMONA',
              payload: null,
            })
          }
        />
      )}
    </Provider>
  );
};

export default AppHoursManageProvider;
