import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Forecasts from './Forecasts';
moment.tz.setDefault('Atlantic/Reykjavik');

const AppHoursTasksForecastsPage: React.FC = () => {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} render={() => <Redirect to={`${url}/${moment().format('YYYY-MM-DD')}`} />} />
      <Route path={`${path}/:formatedDate`}>
        <Forecasts />
      </Route>
    </Switch>
  );
};

export default AppHoursTasksForecastsPage;
