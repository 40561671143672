import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { FEATURES } from '@/types/features.model';
import { IWeeklySchedule } from '@/types/weekly-schedule';
import { difference, getWindowSize, isFeatureEnabled } from '@/utils';
import { Button, Drawer, Form, Input, Select, Switch, notification } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import NewBadge from '../NewBadge';

const { Option } = Select;

interface Props {
  className?: string;
  visible: boolean;
  weeklySchedule?: IWeeklySchedule | null;
  currentSettings?: IDepartmentSettings;
  onClose: () => void;
  onSave: (result: IWeeklySchedule) => void;
}

const DrawerWeeklySchedule: React.FC<Props> = ({
  className,
  visible,
  weeklySchedule,
  currentSettings,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    state: {
      activeDepartment,
      activeDepartmentId,
      loadingUserCategories,
      userCategories,
      tasks,
      sections,
      resources,
      features,
    },
  } = useContext(AppContext);

  useEffect(() => {
    form.resetFields();
    let fieldsValue: any = {};

    if (weeklySchedule) {
      fieldsValue = {
        ...fieldsValue,
        ..._(weeklySchedule)
          .pick([
            'name',
            'recipients',
            'accessibility',
            'shifts_colors',
            'freeshifts',
            'tasks',
            'section',
            'skills',
            'comment',
            'breaks',
            'total_hours',
            'attributes',
            'niss',
            'cost',
            'generation',
            'autosend',
          ])
          .value(),
        userCategories: weeklySchedule?.userCategories?.filter((schedule: IWeeklySchedule) =>
          userCategories.find((category) => category.id == schedule),
        ),
      };
    }
    form.setFieldsValue(fieldsValue);
    // eslint-disable-next-line
  }, [weeklySchedule]);

  const onFinish = async (values: any) => {
    setIsSaving(true);
    let weeklyScheduleResponse;

    try {
      if (weeklySchedule && !_.isEmpty(weeklySchedule)) {
        const differences = difference(values, weeklySchedule);
        if (!_.isEmpty(differences)) {
          if (differences.userCategories) {
            differences.userCategories = values.userCategories;
          }
          weeklyScheduleResponse = await axios.patch(
            `${process.env.REACT_APP_API_URL}/v3/weekly-schedules/${weeklySchedule.id!}`,
            {
              ...values,
            },
            {
              params: {
                departmentId: activeDepartmentId,
              },
            },
          );
        }
      } else {
        weeklyScheduleResponse = await axios.post(`${process.env.REACT_APP_API_URL}/v3/weekly-schedules`, {
          departmentId: activeDepartmentId,
          ...values,
        });
      }

      if (weeklyScheduleResponse) {
        onSave({
          ...weeklyScheduleResponse?.data?.weeklySchedule,
        });
        onClose();
      } else {
        onClose();
      }
    } catch (error) {
      console.error(error);
      const description = weeklySchedule?.id
        ? t('WEEKLY_SCHEDULES.UPDATE_WEEKLY_SCHEDULE_ERROR')
        : t('WEEKLY_SCHEDULES.WEEKLY_SCHEDULE_CREATION_ERROR');
      notification.open({
        message: t('GLOBAL.AN_ERROR_OCCURRED'),
        description,
        type: 'error',
      });
    }
    setIsSaving(false);
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={
        weeklySchedule?.id
          ? t('WEEKLY_SCHEDULES.WEEKLY_SCHEDULE_MODIFICATION')
          : t('WEEKLY_SCHEDULES.ADDING_WEEKLY_SCHEDULE')
      }
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
          <Input placeholder={t('GLOBAL.NAME')} />
        </Form.Item>
        <Form.Item name="recipients" label={t('GLOBAL.RECIPIENTS')}>
          <Input placeholder={t('WEEKLY_SCHEDULES.RECIPIENTS_PLACEHOLDER')} />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <label>{t('WEEKLY_SCHEDULES.AUTOSEND')}</label>
          <Form.Item name="autosend" valuePropName="checked" style={{ margin: 0 }}>
            <Switch />
          </Form.Item>
        </div>
        <Form.Item name="userCategories" label={t('GLOBAL.USER_CATEGORIES')}>
          <Select
            mode="multiple"
            placeholder={t('FORMS.USER_CATEGORIES_PLACEHOLDER')}
            allowClear
            getPopupContainer={(trigger) => trigger.parentNode}
            loading={loadingUserCategories}
            disabled={loadingUserCategories}
          >
            {userCategories?.map((userCategory) => {
              const { id, name } = userCategory;
              return (
                <Option key={`status_${id}`} value={`${id!}`}>
                  {name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <NewBadge end="2024-06-15" />
            <label>{t('SETTINGS.TOOLBOX.WEEK_TEMPLATES.ACCESSIBILITY')}</label>
          </div>
          <Form.Item name="accessibility" valuePropName="checked" style={{ margin: 0 }}>
            <Switch />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <NewBadge end="2024-06-15" />
            <label>{t('SETTINGS.TOOLBOX.WEEK_TEMPLATES.SHIFTS_COLOR')}</label>
          </div>
          <Form.Item name="shifts_colors" valuePropName="checked" style={{ margin: 0 }}>
            <Switch />
          </Form.Item>
        </div>
        {isFeatureEnabled(features, FEATURES.FREESHIFTS) && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <label>{t('SETTINGS.TOOLBOX.WEEK_TEMPLATES.DISPLAY_FREE_SHIFTS')}</label>
            <Form.Item name="freeshifts" valuePropName="checked" style={{ margin: 0 }}>
              <Switch />
            </Form.Item>
          </div>
        )}
        {isFeatureEnabled(features, FEATURES.TASKS) && !!tasks?.length && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <label>{t('GLOBAL.TASKS')}</label>
            <Form.Item name="tasks" valuePropName="checked" style={{ margin: 0 }}>
              <Switch />
            </Form.Item>
          </div>
        )}
        {isFeatureEnabled(features, FEATURES.SECTIONS) && !!sections?.length && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <label>{t('GLOBAL.SECTION')}</label>
            <Form.Item name="section" valuePropName="checked" style={{ margin: 0 }}>
              <Switch />
            </Form.Item>
          </div>
        )}
        {isFeatureEnabled(features, FEATURES.SKILLS) && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <label>{t('GLOBAL.SKILLS')}</label>
            <Form.Item name="skills" valuePropName="checked" style={{ margin: 0 }}>
              <Switch />
            </Form.Item>
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <label>{t('GLOBAL.COMMENT')}</label>
          <Form.Item name="comment" valuePropName="checked" style={{ margin: 0 }}>
            <Switch />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <label>{t('GLOBAL.BREAKS')}</label>
          <Form.Item name="breaks" valuePropName="checked" style={{ margin: 0 }}>
            <Switch />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <label>{t('GLOBAL.TOTAL_HOURS')}</label>
          <Form.Item name="total_hours" valuePropName="checked" style={{ margin: 0 }}>
            <Switch />
          </Form.Item>
        </div>
        {isFeatureEnabled(features, FEATURES.ATTRIBUTES) && !!resources?.length && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <label>{t('GLOBAL.ATTRIBUTES')}</label>
            <Form.Item name="attributes" valuePropName="checked" style={{ margin: 0 }}>
              <Switch />
            </Form.Item>
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <label>{t('SETTINGS.TOOLBOX.WEEK_TEMPLATES.NISS/STATUS')}</label>
          <Form.Item name="niss" valuePropName="checked" style={{ margin: 0 }}>
            <Switch />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <label>{t('SETTINGS.TOOLBOX.WEEK_TEMPLATES.DISPLAY_COST')}</label>
          <Form.Item name="cost" valuePropName="checked" style={{ margin: 0 }}>
            <Switch />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <label>{t('SETTINGS.TOOLBOX.WEEK_TEMPLATES.DISPLAY_GENERATION')}</label>
          <Form.Item name="generation" valuePropName="checked" style={{ margin: 0 }}>
            <Switch />
          </Form.Item>
        </div>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default styled(DrawerWeeklySchedule)`
  .ant-form-item-label {
    font-weight: bold;
  }
`;
