import { Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface Props {
  className?: string;
  statusFilter: string | null;
  setStatusFilter: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<Props> = ({ className, statusFilter, setStatusFilter }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <header className={className}>
      <div className="left">
        <h2>Shifts Market</h2>
      </div>
      <div className="right">
        <Select
          getPopupContainer={(trigger) => trigger}
          clearIcon
          allowClear
          style={{ width: 250 }}
          value={statusFilter ? statusFilter : undefined}
          onChange={(value: string) => setStatusFilter(value)}
          placeholder={t('GLOBAL.PENDING')}
        >
          <Option value="PENDING">{t('GLOBAL.PENDING')}</Option>
          <Option value="SUCCESS">{t('SHYFTS_MARKET.FILTERS.SUCCESS')}</Option>
          <Option value="ENDED">{t('SHYFTS_MARKET.FILTERS.ENDED')}</Option>
          <Option value="DELETED">{t('SHYFTS_MARKET.FILTERS.DELETED')}</Option>
        </Select>
      </div>
    </header>
  );
};

export default Header;
