import React from 'react';
import styled from 'styled-components';
import { minutesToHoursAndOrMinutes } from '@/utils';
import { ITotalRole } from '..';
import Colors from '@/styles/colors';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  totalByRole: ITotalRole[];
  currency: string;
}

const TotalHoursDetails: React.FC<Props> = ({ className, totalByRole, currency }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });
  const totalMinutes = totalByRole.reduce((a, b) => a + b.minutes, 0);
  const totalPrices = totalByRole.reduce((a, b) => a + b.price, 0);

  return (
    <div className={className}>
      {totalByRole.map((total) => {
        const { minutes, price, name, background, color } = total;
        if (minutes) {
          return (
            <div className="detail" key={`detail_${name}`}>
              <span
                className="tag small blue"
                style={{ backgroundColor: background || 'initial', color: color || 'initial' }}
              >
                {name}
              </span>
              <span className="values">
                {minutesToHoursAndOrMinutes(minutes)}
                {!!price &&
                  ` - ${new Intl.NumberFormat(language, { style: 'currency', currency: currency || 'EUR' }).format(
                    price,
                  )}`}
              </span>
            </div>
          );
        } else {
          return null;
        }
      })}
      <div className="detail">
        <span className="tag small blue-light">
          <b>{t('GLOBAL.TOTAL')}</b>
        </span>
        <span className="values">
          {minutesToHoursAndOrMinutes(totalMinutes)}
          {!!totalPrices &&
            ` - ${new Intl.NumberFormat(language, { style: 'currency', currency: currency || 'EUR' }).format(
              totalPrices,
            )}`}
        </span>
      </div>
    </div>
  );
};

const TotalHoursDetailsStyled = styled(TotalHoursDetails)`
  position: relative;
  z-index: 999999;

  .detail {
    font-style: normal;
    display: flex;
    justify-content: space-between;

    &:nth-of-type(n + 2) {
      padding-top: 5px;
    }

    .values {
      color: ${Colors.grey};
      margin-left: 10px;
    }
  }
`;

export default TotalHoursDetailsStyled;
