import React, { Dispatch, useContext } from 'react';
import { Menu, message } from 'antd';
import { ActionType, copyDayShifts, updateShiftsAndContextWithShiftResponse } from '../redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { InitialStateType } from '../redux/store';
import { MenuInfo } from 'rc-menu/lib/interface';
import moment from 'moment';
import 'moment-timezone';
import Axios from 'axios';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import { IShift } from '@/types/shift.model';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  dayIndex: number;
  close: () => void;
}

const DayRightClickMenu: React.FC<Props> = ({ dayIndex, close }) => {
  const {
    state: { activeDepartmentId, activeSection, activeDepartment, skills, userCategories },
  } = useContext(AppContext);
  const {
    filteredShiftsByUsers,
    days,
    clipboardHistoryDay,
    picker,
    startDate,
    endDate,
    showOpenShifts,
    selected_shifts_on_shift_key,
  } = useSelector(
    ({
      filteredShiftsByUsers,
      days,
      clipboardHistoryDay,
      picker,
      startDate,
      endDate,
      showOpenShifts,
      selected_shifts_on_shift_key,
    }: InitialStateType) => ({
      filteredShiftsByUsers,
      days,
      clipboardHistoryDay,
      picker,
      startDate,
      endDate,
      showOpenShifts,
      selected_shifts_on_shift_key,
    }),
    shallowEqual,
  );
  const { t } = useTranslation(undefined, { useSuspense: false });
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const handleCopyDayShifts = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    const startDay = moment.unix(days[dayIndex].date);
    const endDay = moment(startDay).add(1, 'day');
    const ids: string[] = [];

    filteredShiftsByUsers.forEach((userShifts) => {
      userShifts?.shifts?.forEach((shift) => {
        const startShiftMoment = moment.unix(shift.start!);
        if (startShiftMoment.isBetween(startDay, endDay, undefined, '[)')) {
          ids.push(shift.id!);
        }
      });
    });

    copyDayShifts(hoursDispatch, {
      ids,
    });
    close();
  };

  const handleSelectDayShifts = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    const startDay = moment.unix(days[dayIndex].date);
    const endDay = moment(startDay).add(1, 'day');
    const ids: string[] = [];
    const shifts: IShift[] = [];

    if (picker == 'month') {
      filteredShiftsByUsers.forEach((userShifts) => {
        userShifts?.shifts?.forEach((shift) => {
          if ((!showOpenShifts && !shift.userRecordId) || shift.shyftType == 3) {
            return;
          }
          const startShiftMoment = moment.unix(shift.start!);
          if (startShiftMoment.isBetween(startDay, endDay, undefined, '[)')) {
            if (picker !== 'month') {
              if (!['ADMIN', 'SUPER-ADMIN'].includes(activeDepartment!.role!)) {
                if (!shift.locked) {
                  ids.push(shift.id!);
                }
              } else {
                ids.push(shift.id!);
              }
            } else {
              if (!['ADMIN', 'SUPER-ADMIN'].includes(activeDepartment!.role!)) {
                if (!shift.locked) {
                  shifts.push(shift);
                }
              } else {
                shifts.push(shift);
              }
            }
          }
        });
      });
      if (selected_shifts_on_shift_key) {
        hoursDispatch({
          type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
          payload: [...selected_shifts_on_shift_key, ...shifts],
        });
      } else {
        hoursDispatch({
          type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
          payload: shifts,
        });
      }
    } else {
      filteredShiftsByUsers.forEach((userShifts) => {
        userShifts?.shifts?.forEach((shift) => {
          if (!showOpenShifts && !shift.userRecordId) {
            return;
          }
          const startShiftMoment = moment.unix(shift.start!);
          if (startShiftMoment.isBetween(startDay, endDay, undefined, '[)')) {
            if (!['ADMIN', 'SUPER-ADMIN'].includes(activeDepartment!.role!)) {
              if (!shift.locked) {
                ids.push(shift.id!);
              }
            } else {
              ids.push(shift.id!);
            }
          }
        });
      });

      hoursDispatch({
        type: 'ADD_SELECTED_SHIFTS',
        payload: [...ids],
      });
    }
    close();
  };

  const handlePasteDayShifts = (e: MenuInfo) => {
    hoursDispatch({
      type: 'SET_IS_LOADING',
      payload: true,
    });

    const { start } = clipboardHistoryDay!.shifts[0];
    const differenceInDays = moment.unix(days[dayIndex].date).diff(moment.unix(start!).startOf('day'), 'day');

    Axios.post(
      `${process.env.REACT_APP_API_URL}/v3/shifts`,
      {
        shifts: clipboardHistoryDay?.shifts?.map((shift) => ({
          id: shift.id,
          start: moment.unix(shift.start!).add(differenceInDays, 'day').unix(),
          end: moment.unix(shift.end!).add(differenceInDays, 'day').unix(),
        })),
      },
      {
        params: {
          picker,
          startDate: startDate.unix(),
          endDate: endDate.unix(),
          departmentId: activeDepartmentId,
          sectionId: activeSection,
        },
      },
    )
      .then((response) => {
        updateShiftsAndContextWithShiftResponse(hoursDispatch, response, {
          department: activeDepartment!,
          picker,
          startDate,
          endDate,
          activeSection,
          skills,
          userStatus: userCategories,
        });
        const { totalErrors = 0 } = response.data.message;
        if (totalErrors) {
          if (totalErrors > 1) {
            message.error(t('SCHEDULE.PASTE_SHIFTS_ERROR', { count: totalErrors }));
          } else {
            message.error(t('SCHEDULE.PASTE_SHIFT_ERROR'));
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        hoursDispatch({
          type: 'SET_IS_LOADING',
          payload: false,
        });
      });

    e.domEvent.stopPropagation();
  };

  return (
    <Menu>
      {picker !== 'month' && (
        <Menu.Item key={`day_${dayIndex}_rightClick_1`} onClick={handleCopyDayShifts}>
          <i className="icon-copy"></i>
          {t('SCHEDULE.COPY_DAY')}
        </Menu.Item>
      )}
      <Menu.Item key={`day_${dayIndex}_rightClick_2`} onClick={handleSelectDayShifts}>
        <i className="icon-check"></i>
        {t('SCHEDULE.SELECT_SHIFTS_OF_THE_DAY')}
      </Menu.Item>
      {!!clipboardHistoryDay && (
        <Menu.Item key={`day_${dayIndex}_rightClick_3`} onClick={handlePasteDayShifts}>
          <i className="icon-paste"></i> {t('SCHEDULE.PASTE_DAY')}
        </Menu.Item>
      )}
    </Menu>
  );
};

export default DayRightClickMenu;
