import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Button, Input, Modal } from 'antd';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  visible?: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onOk: () => void;
}

const ModalDeleteConfirmation: React.FC<Props> = ({ className, visible, setVisible, onOk }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [consent, setConsent] = useState<string>('');

  useEffect(() => {
    setConsent('');
  }, [visible]);

  const onCancel = () => {
    setVisible(false);
    setConsent('');
  };

  const onConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConsent(e.target.value);
  };

  return (
    <Modal
      width={750}
      className={`${className} modal-danger`}
      visible={visible}
      footer={null}
      onCancel={onCancel}
      title={t('GLOBAL.DELETE')}
    >
      <div className="content">
        <p>{}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: t('CUSTOM_HR_CODES.MODAL_DELETE_CODE.CONTENT', { word: t('GLOBAL.CONFIRM').toUpperCase() }),
          }}
        />
        <Input
          value={consent}
          className="input"
          size="large"
          placeholder={t('GLOBAL.CONFIRM').toUpperCase()}
          onChange={onConsentChange}
        />
        <div className="actions">
          <Button size="large" onClick={onCancel}>
            {t('GLOBAL.CANCEL')}
          </Button>
          <Button
            type="primary"
            size="large"
            disabled={t('GLOBAL.CONFIRM').toUpperCase() !== consent}
            danger
            onClick={onOk}
          >
            {t('GLOBAL.DELETE')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default styled(ModalDeleteConfirmation)`
  .ant-modal-content {
    border-radius: 8px !important;
  }
  .ant-modal-body {
    padding: 0;
  }

  .content {
    padding: 25px 35px;

    p {
      margin: 0;
      font-size: 14px;
      color: #444;
    }

    .input {
      margin-top: 18px;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 25px;
    }
  }
`;
