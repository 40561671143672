import { withQuote } from '@/hoc/withQuote';
import { IField } from '@/types/field.model';
import { getWindowSize } from '@/utils';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import Axios from 'axios';
import translatedCountries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import nl from 'i18n-iso-countries/langs/nl.json';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useParams } from 'react-router-dom';

translatedCountries.registerLocale(en);
translatedCountries.registerLocale(fr);
translatedCountries.registerLocale(nl);
interface ISelfServiceData {
  department: {
    company?: string;
    content?: string;
    title?: string;
    subtitle?: string;
    picturePath?: string;
    fields?: IField[];
    meta?: {
      [key: string]: {
        id?: string;
        name?: string;
      }[];
    };
    lang: string;
  };
  user: {
    fields: {
      [key: string]: any;
    };
    custom_fields: {
      id?: string;
      field_id?: string;
      label?: string;
      value?: string;
      mandatory?: boolean;
    };
  };
}

interface Props {
  quote?: {
    quote: string;
    author: string;
  };
}

const SubscribePage: React.FC<Props> = ({ quote }) => {
  const [form] = Form.useForm();
  const { hash } = useParams();
  const [data, setData] = useState<ISelfServiceData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [backgroundImageLink, setBackgroundImageLink] = useState<string>('');
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [countries, setCountries] = useState<[string, string][]>();
  const [showCompleted, setShowCompleted] = useState<boolean>(false);

  const fields =
    data?.department.fields?.filter((field: any) => field.display).sort((a, b) => a.order! - b.order!) || [];

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const random = Math.floor(Math.random() * 2 + 1);
    setBackgroundImageLink(`${process.env.PUBLIC_URL}/img/image-login-${random}.jpg`);
  }, []);

  useEffect(() => {
    let mounted = true;
    setData(null);
    if (quote?.quote && mounted) {
      setLoading(true);
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/users/${hash}/self-service`, {
        cancelToken: cancelTokenSource.token,
      })
        .then(({ data }: { data: ISelfServiceData }) => {
          if (mounted) {
            const countr = translatedCountries.getNames(data.department.lang.split('_')[0]);
            const sorted = Object.entries(countr).sort((a, b) => (a[1].toLowerCase() < b[1].toLowerCase() ? -1 : 1));
            setData(data);
            setCountries(sorted);
            form.setFieldsValue({
              fields: {
                ...data.user.fields,
                email: data.user.fields.name,
                birthdate: data.user.fields.birthdate ? moment(data.user.fields.birthdate) : null,
              },
              custom_fields: {
                ...data.user.custom_fields,
              },
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (mounted) {
          }
          if (!Axios.isCancel(error)) {
            if (error.response?.status === 403 || error.response?.status === 404) {
              setShowCompleted(true);
            }
          }
        });
      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, []);

  const onFinish = (values: any) => {
    setIsSaving(true);
    Axios.patch(`${process.env.REACT_APP_API_URL}/v3/users/${hash}/self-service`, {
      ...values,
    })
      .then(() => {
        setIsSaving(false);
        setShowCompleted(true);
      })
      .catch((error) => {
        setIsSaving(false);
        console.log(error);
      });
  };

  const content = (
    <div
      className="right"
      style={{
        display: 'flex',
        flexDirection: windowSize.innerWidth <= 900 ? 'column' : 'unset',
        justifyContent: 'center',
        padding: 25,
        alignItems: windowSize.innerWidth <= 900 ? 'inherit' : 'unset',
      }}
    >
      <Form form={form} size="large" layout="vertical" onFinish={onFinish}>
        <h1 dangerouslySetInnerHTML={{ __html: data?.department.title || '' }} />
        <h2>{t('SELF_SERVICE.TITLE')}</h2>
        <p dangerouslySetInnerHTML={{ __html: data?.department.content || '' }}></p>
        <div className="fields">
          {data &&
            fields &&
            fields?.map((field) => {
              const { name, id, mandatory, sections } = field;
              if (data.department.meta?.[id!]) {
                return (
                  <Form.Item
                    key={id}
                    name={sections === 'custom_fields' ? ['custom_fields', id!] : ['fields', id!]}
                    label={name}
                    rules={[{ required: mandatory }]}
                  >
                    <Select placeholder={name}>
                      {data.department.meta?.[id!].map((option) => (
                        <Select.Option value={option.id!} key={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              } else if (id === 'country' || id === 'nationality_country_code') {
                return (
                  <Form.Item
                    key={id}
                    name={sections === 'custom_fields' ? ['custom_fields', id!] : ['fields', id!]}
                    label={name}
                    rules={[{ required: mandatory }]}
                  >
                    <Select
                      showSearch
                      placeholder={t('GLOBAL.COUNTRY')}
                      filterOption={(input, option) => {
                        const label = option!.label! as string;
                        return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      }}
                      allowClear
                    >
                      {countries?.map((key, i) => {
                        return (
                          <Select.Option value={key[0]} key={key[0]} label={key[1]}>
                            <div className="demo-option-label-item">{key[1]}</div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                );
              } else if (id === 'birthdate') {
                return (
                  <Form.Item key={id} name={['fields', 'birthdate']} label={name} rules={[{ required: mandatory }]}>
                    <DatePicker
                      style={{ width: '100%' }}
                      format={'L'}
                      onSelect={(e) => {
                        const profile_extra = { ...form.getFieldValue('profile_extra') };
                        profile_extra.birthdate = e;
                        form.setFieldsValue({
                          profile_extra,
                        });
                      }}
                    />
                  </Form.Item>
                );
              } else if (id === 'phone') {
                return (
                  <Form.Item key={id} name={['fields', id!]} label={name} rules={[{ required: mandatory }]}>
                    <PhoneInput
                      containerClass="ant-form-item-control-input-content"
                      inputClass="ant-input"
                      inputStyle={{ width: '100%', backgroundColor: '#f7f8fb' }}
                    />
                  </Form.Item>
                );
              } else {
                if (id == 'email') {
                  return (
                    <Form.Item key={id} name={['fields', id!]} label={name} rules={[{ required: mandatory }]}>
                      <Input placeholder={name} disabled />
                    </Form.Item>
                  );
                } else {
                  return (
                    <Form.Item
                      key={id}
                      name={sections === 'custom_fields' ? ['custom_fields', id!] : ['fields', id!]}
                      label={name}
                      rules={[{ required: mandatory }]}
                    >
                      <Input placeholder={name} />
                    </Form.Item>
                  );
                }
              }
            })}
        </div>
        <Form.Item>
          <Button
            loading={isSaving}
            size="large"
            type="primary"
            htmlType="submit"
            className="login-form-button"
            block={windowSize.innerWidth <= 900}
          >
            {t('GLOBAL.SIGN_UP')}
          </Button>
        </Form.Item>
        <span className="accept_terms">{t('FORMS.ACCEPT_TERMS')}</span>
        <span className="generatedBy">{t('SUBSCRIBE.GENERATE_BY_SHYFTER')}</span>
      </Form>
    </div>
  );

  const completed = (
    <div
      className="right"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 100,
        alignItems: 'center',
      }}
    >
      <h1 style={{ fontSize: 32, marginBottom: 50, lineHeight: 1.2 }}>{t('SELF_SERVICE.MESSAGES.COMPLETED_TITLE')}</h1>
      <p style={{ fontSize: 16 }}>{t('SELF_SERVICE.MESSAGES.COMPLETED_CONTENT')}</p>
      <img src={process.env.PUBLIC_URL + '/img/appstores-qr-code.png'} alt="" style={{ width: 250, height: 250 }} />
    </div>
  );

  return (
    <>
      {windowSize.innerWidth > 900 ? (
        <div className="dual-page">
          <div className="left" style={{ backgroundImage: `url("${backgroundImageLink}")` }}>
            {!!data?.department.picturePath && <img src={data?.department.picturePath} />}
            <h2>{quote?.quote}</h2>
            <i>{quote?.author}</i>
          </div>
          {showCompleted ? completed : content}
        </div>
      ) : (
        <>{showCompleted ? completed : content}</>
      )}
    </>
  );
};

const SubscribePageWithQuotes: React.FC = () => {
  const Component = withQuote(SubscribePage);
  return <Component />;
};

export default SubscribePageWithQuotes;
