import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface Props {
  className?: string;
  statusFilter: string | null;
  setStatusFilter: React.Dispatch<React.SetStateAction<string>>;
  userRecordId?: string;
}

const Header: React.FC<Props> = ({ className, statusFilter, userRecordId, setStatusFilter }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <header className={className}>
      {!userRecordId ? (
        <div className="left">
          <h2>{t('VACATION_REQUESTS.TITLE')}</h2>
        </div>
      ) : (
        <div className="left"></div>
      )}
      <div className="right">
        <Select
          getPopupContainer={(trigger) => trigger}
          clearIcon
          allowClear
          style={{ width: 250 }}
          value={statusFilter ? statusFilter : undefined}
          onChange={(value: string) => setStatusFilter(value)}
        >
          <Option value="PENDING">{t('GLOBAL.PENDING')}</Option>
          <Option value="ACCEPTED">{t('VACATION_REQUESTS.ACCEPTED_PLURAL')}</Option>
          <Option value="DECLINED">{t('VACATION_REQUESTS.DECLINED_PLURAL')}</Option>
        </Select>
      </div>
    </header>
  );
};

export default Header;
