import ScrollToTop from '@/components/ScrollToTop';
import TimePickerFormItem from '@/pages/app/components/TimePickerFormItem';
import AppContext from '@/pages/app/context';
import { default as Colors, default as colors } from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IUserParams } from '@/types/user-params.model';
import { IUser } from '@/types/user.model';
import { difference, getWindowSize, isFeatureEnabled, isNullOrUndefined, valueForSearch } from '@/utils';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Drawer, Form, Input, InputNumber, message, Radio, Select, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { RadioChangeEvent } from 'antd/lib/radio';
import axios from 'axios';
import { cloneDeep, isEmpty, pick } from 'lodash';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { ReactHeight } from 'react-height';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../../redux/actions';
import { InitialStateType } from '../../redux/store';

const { Option, OptGroup } = Select;
const { TextArea } = Input;

interface Props {
  className?: string;
  visible: boolean;
  user?: IUser;
  userParams: IUserParams;
  setVisible: (visibility: boolean) => void;
  onSave: (data: any) => void;
}

const DrawerShift: React.FC<Props> = ({ className, visible, user, userParams, setVisible, onSave }) => {
  const { state: appContextState, dispatch: appContextDispatch } = useContext(AppContext);
  const {
    users,
    loadingSections,
    sections,
    activeDepartment,
    activeDepartmentId,
    departments,
    loadingSkills,
    skills,
    tasks,
    loadingTasks,
    resources,
    loadingResources,
    dayoffs,
    loadingDayoffs,
    packages,
    loadingPackages,
    shortcuts,
    loadingShortcuts,
    features,
  } = appContextState;
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const { selectedShift: shift, sections: userSections, skills: userSkills } = useSelector(
    ({ selectedShift, sections, skills }: InitialStateType) => ({
      selectedShift,
      sections,
      skills,
    }),
    shallowEqual,
  );
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const [form] = Form.useForm();
  const [shiftDay, setShiftDay] = useState<moment.Moment>();
  const [newStart, setNewStart] = useState<number | null>(null);
  const [tasksStart, setTasksStart] = useState<(number | null)[]>([]);
  const [hrPartners, setHrPartners] = useState<any[]>([]);
  const [loadingHrPartners, setLoadingHrPartners] = useState<boolean>(false);

  const [shiftType, setShiftType] = useState<'shift' | 'absence'>('shift');
  const [loading, setLoading] = useState<boolean>(false);
  const [originalForm, setOriginalForm] = useState<any>(null);
  const [department, setDepartment] = useState<IDepartment | null>(null);
  const [constraintsHeight, setConstraintsHeight] = useState<number>(0);
  const [lastTaskEndTimestamp, setLastTaskEndTimestamp] = useState<number>(0);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const departmentId = shift?.departmentId || activeDepartmentId;
    const department = departments.find((department) => department.id === departmentId);
    setDepartment(department || null);
  }, [activeDepartmentId, shift?.departmentId]);

  const hr_code_id =
    activeDepartment?.hr_codes && activeDepartment?.hr_codes.find((code) => code.id === shift?.hr_code)?.code;

  useEffect(() => {
    if (!visible || !shift) {
      return;
    }
    getHrPartners();
    // Set fields value
    form.resetFields();
    let shiftDay = moment().startOf('day');
    const fieldsValue: any = {
      ...cloneDeep(initialValues),
    };
    if (shift) {
      const { start, end, pause, section, id, comment, hr_code } = shift;

      if (comment) {
        fieldsValue.comment = comment;
      }

      if (section) {
        fieldsValue.section = section.id;
      }

      if (start) {
        const startMoment = moment.unix(start);
        fieldsValue.start = start;
        fieldsValue.start_time = startMoment.format('HH:mm');
        setNewStart(start);
        shiftDay = startMoment.startOf('day');
      } else if (shift.timestamp) {
        shiftDay = moment(shift.timestamp).startOf('day');
      }

      if (end) {
        const endMoment = moment.unix(end);
        fieldsValue.end = end;
        fieldsValue.end_time = endMoment.format('HH:mm');
      }

      if (shift.dayoff) {
        setShiftType('absence');
        fieldsValue.type = 'absence';
        fieldsValue.dayoff = pick(shift.dayoff, ['paid', 'fullDay', 'dayOffType']);
      } else {
        setShiftType('shift');
        fieldsValue.type = 'shift';

        if (pause?.unpaid) {
          const _minutes = moment.duration(pause!.unpaid, 'seconds').asMinutes();
          const minutes = _minutes % 60;
          const hours = Math.trunc(_minutes / 60);
          fieldsValue.pause.unpaid_time = `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`;
          fieldsValue.pause.unpaid = pause.unpaid;
        }

        if (pause?.paid) {
          const _minutes = moment.duration(pause!.paid, 'seconds').asMinutes();
          const minutes = _minutes % 60;
          const hours = Math.trunc(_minutes / 60);
          fieldsValue.pause.paid_time = `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`;
          fieldsValue.pause.paid = pause.paid;
        }

        if (shift.tasks && shift.tasks.length > 0) {
          fieldsValue.tasks = shift.tasks.map((task) => {
            const { end, start, taskTypeId } = task;
            return {
              taskTypeId,
              end,
              start,
              start_time: moment.unix(start!).format('HH:mm'),
              end_time: moment.unix(end!).format('HH:mm'),
            };
          });
          setTasksStart(fieldsValue.tasks.map((task: any) => task.start));
        } else {
          fieldsValue.tasks = [];
          setTasksStart([]);
        }

        if (shift.skills && shift.skills.length > 0) {
          fieldsValue.skills = shift.skills.map((skill) => {
            return skill.id;
          });
        } else {
          fieldsValue.skills = [];
        }

        if (shift.attributes && shift.attributes.length > 0) {
          fieldsValue.attributes = shift.attributes.map((attribut) => {
            return attribut.id;
          });
        } else {
          fieldsValue.attributes = [];
        }

        if (shift.package) {
          fieldsValue.packageId = [`${shift.package.id}`];
        }

        if (shift.hr_code) {
          fieldsValue.hr_code = hr_code;
        }

        if (shift.hide) {
          fieldsValue.hide = shift.hide;
        }
      }

      form.setFieldsValue(fieldsValue);
      const picked = pick(fieldsValue, ['start', 'end', 'skills', 'section', 'pause']);
      if (!picked.start) {
        picked.start = shift?.start ? moment.unix(shift.start!).startOf('day').unix() : shift?.timestamp;
      }
      setOriginalForm(fieldsValue);
      if (!id) {
        didChangeDate();
      }
    }
    setShiftDay(shiftDay);
    // eslint-disable-next-line
  }, [visible, shift]);

  const changeStartDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    const startTimestamp = moment(shiftDay)
      .set('hour', hour || 0)
      .set('minute', minute || 0)
      .unix();
    form.setFieldsValue({
      start: startTimestamp,
    });
    setNewStart(hour ? startTimestamp : null);
    const endDate = form.getFieldValue('end');
    if (endDate) {
      const endMoment = moment.unix(endDate);
      let endTimestamp = moment(shiftDay).set('hour', endMoment.hours()).set('minute', endMoment.minutes()).unix();
      if (endTimestamp < startTimestamp) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      form.setFieldsValue({
        end: endTimestamp,
      });
    }
    const tasks = form.getFieldValue('tasks');
    if (tasks && tasks.length > 0) {
      const newTasks = tasks.map((task: any, index: number) => {
        // START
        let taskStartTimestamp = 0;
        if (task.start) {
          const taskStartMoment = moment.unix(task.start);
          taskStartTimestamp = moment(shiftDay)
            .set('hour', taskStartMoment.hours())
            .set('minute', taskStartMoment.minutes())
            .unix();
          if (taskStartTimestamp < startTimestamp) {
            taskStartTimestamp = moment.unix(taskStartTimestamp).add(1, 'day').unix();
          }
          task.start = taskStartTimestamp;
          const _tasksStart = tasksStart;
          _tasksStart[index] = taskStartTimestamp;
          setTasksStart(_tasksStart);
        }

        // END
        if (task.end) {
          const taskEndMoment = moment.unix(task.end);
          let taskEndTimestamp = moment(shiftDay)
            .set('hour', taskEndMoment.hours())
            .set('minute', taskEndMoment.minutes())
            .unix();
          if (taskEndTimestamp < startTimestamp || taskEndTimestamp < taskStartTimestamp) {
            taskEndTimestamp = moment.unix(taskEndTimestamp).add(1, 'day').unix();
          }
          task.end = taskEndTimestamp;
        }

        return task;
      });
      form.setFieldsValue({
        tasks: newTasks,
      });
    }
    if (shiftType === 'shift') {
      didChangeDate();
    }
  };

  const changeEndDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    let endValue = null;
    if (!isNullOrUndefined(hour)) {
      let endTimestamp = moment(shiftDay)
        .set('hour', hour || 0)
        .set('minute', minute || 0)
        .unix();
      const startDate = form.getFieldValue('start');
      if (startDate && endTimestamp < startDate) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      endValue = endTimestamp;
    }
    form.setFieldsValue({
      end: endValue,
    });
    if (shiftType === 'shift') {
      didChangeDate();
    }
  };

  const didChangeDate = () => {
    if (department?.scheduleParams?.pauseAuto) {
      const { pauses } = department?.scheduleParams;
      const startDate = form.getFieldValue('start');
      const endDate = form.getFieldValue('end');
      const duration = endDate - startDate;

      const sortedPauses = pauses?.filter((x) => duration > x.from).sort((a, b) => b.from - a.from);
      if (sortedPauses?.length) {
        const greatestPause = sortedPauses[0];
        const paid = greatestPause?.paid ? greatestPause.duration : 0;
        const unpaid = !greatestPause?.paid ? greatestPause.duration : 0;

        if (shiftType === 'shift') {
          form.setFieldsValue({
            pause: {
              paid,
              unpaid,
              paid_time: paid ? moment.unix(paid).format('HH:mm') : null,
              unpaid_time: unpaid ? moment.unix(unpaid).format('HH:mm') : null,
            },
          });
        } else {
          if (unpaid) {
            const endDate = moment.unix(form.getFieldValue('end')).subtract(unpaid, 'seconds');
            form.setFieldsValue({
              end_time: endDate.format('HH:mm'),
            });
            changeEndDate({
              hour: endDate.hour(),
              minute: endDate.minute(),
            });
          }
        }
      } else {
        form.setFieldsValue({
          pause: {
            paid: 0,
            unpaid: 0,
            paid_time: null,
            unpaid_time: null,
          },
        });
      }
    }
  };

  const changePaidPauseDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    const pause = form.getFieldValue('pause');
    if (isNullOrUndefined(hour)) {
      pause.paid = 0;
    } else {
      pause.paid = moment
        .duration(0)
        .add(hour || 0, 'hour')
        .add(minute || 0, 'minute')
        .asSeconds();
    }
    form.setFieldsValue({
      pause,
    });
  };

  const changeUnpaidPauseDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    const pause = form.getFieldValue('pause');
    if (isNullOrUndefined(hour)) {
      pause.unpaid = 0;
    } else {
      pause.unpaid = moment
        .duration(0)
        .add(hour || 0, 'hour')
        .add(minute || 0, 'minute')
        .asSeconds();
    }
    form.setFieldsValue({
      pause,
    });
  };

  const changeTaskDate = ({
    index,
    name,
    hour,
    minute,
  }: {
    index: number;
    name: string;
    hour: number | null;
    minute: number | null;
  }) => {
    if (isNullOrUndefined(hour)) {
      const tasks = form.getFieldValue('tasks');
      if (tasks[index]) {
        tasks[index][name] = null;
        if (name === 'start') {
          const _tasksStart = tasksStart;
          _tasksStart[index] = null;
          setTasksStart(_tasksStart);
        }
      }
      form.setFieldsValue({
        tasks,
      });
    } else {
      let timestamp = moment(shiftDay)
        .set('hour', hour || 0)
        .set('minute', minute || 0)
        .unix();
      const startDate = form.getFieldValue('start');
      if (startDate && timestamp < startDate) {
        timestamp = moment.unix(timestamp).add(1, 'day').unix();
      }
      const taskItems = form.getFieldValue('tasks');
      if (name === 'end') {
        const task = taskItems[index];
        if (task) {
          const startTask = task['start'];
          if (startTask && timestamp < startTask) {
            timestamp = moment.unix(timestamp).add(1, 'day').unix();
          }
        }
      } else if (name === 'start') {
        const _tasksStart = tasksStart;
        _tasksStart[index] = timestamp;
        setTasksStart(_tasksStart);
        const { end, taskTypeId } = taskItems[index];
        if (!end && !!taskTypeId) {
          const task = tasks?.find((x) => x.id === taskTypeId);
          if (task?.minutes) {
            const endTimestamp = task.minutes * 60 + timestamp;
            taskItems[index].end = endTimestamp;
            taskItems[index].end_time = moment.unix(endTimestamp).format('HH:mm');
          }
        }
      }

      taskItems[index][name] = timestamp;
      const _lastTaskEndTimestamp = Math.max(...(taskItems?.map((task: { end?: number }) => task?.end || 0) || [0]), 0);
      setLastTaskEndTimestamp(_lastTaskEndTimestamp);
      form.setFieldsValue({
        tasks: taskItems,
      });
    }
  };

  const changeShortcut = (value: string) => {
    if (value) {
      const shortcut = shortcuts.find((x) => x.id === value);
      if (shortcut) {
        const { starthour, startmin, endhour, endmin } = shortcut;
        form.setFieldsValue({
          start_time: `${starthour?.toString().padStart(2, '0')}:${startmin?.toString().padStart(2, '0')}`,
        });
        changeStartDate({ hour: shortcut.starthour || null, minute: shortcut.startmin || null });
        form.setFieldsValue({
          end_time: `${endhour?.toString().padStart(2, '0')}:${endmin?.toString().padStart(2, '0')}`,
        });
        changeEndDate({ hour: shortcut.endhour || null, minute: shortcut.endmin || null });
      }
    }
    if (shiftType === 'absence') {
      didChangeDate();
    }
  };

  const onFinish = async (values: Store) => {
    setLoading(true);
    const diffs = difference({ ...values }, { ...originalForm });
    let shiftResponse;

    try {
      if (shift && shift.id) {
        if (!isEmpty(diffs)) {
          if (diffs.type === 'shift') {
            diffs.dayoff = null;
          }

          if (diffs.skills) {
            diffs.skills = values.skills;
          }

          if (diffs.tasks) {
            diffs.tasks = values.tasks;
          }

          if (diffs.pause) {
            diffs.pause = values.pause;
          }

          if (diffs.attributes) {
            diffs.attributes = values.attributes;
          }

          if (diffs.dayoff) {
            diffs.dayoff = values.dayoff;
          }

          if (diffs.hide) {
            diffs.hide = values.hide;
          }

          if (Object.keys(diffs).includes('hr_code')) {
            if (diffs.hr_code === undefined) {
              diffs.hr_code = null;
            }
          }

          shiftResponse = await axios.patch(
            `${process.env.REACT_APP_API_URL}/v3/users/schedule-models/${shift.weekId}/shifts/${shift.id}`,
            {
              ...removeUnnecessaryProperties(diffs),
              departmentId: activeDepartmentId,
            },
            {
              params: {
                departmentId: activeDepartmentId,
              },
              headers: {
                'X-Locale': i18n.language,
              },
            },
          );
        } else {
          setLoading(false);
          return;
        }
      } else {
        if (values.packageId || values.packageIds) {
          values.start = shiftDay?.unix();
        }

        shiftResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/v3/users/schedule-models/${shift!.weekId}/shifts`,
          {
            ...removeUnnecessaryProperties(values),
            departmentId: activeDepartmentId,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
            headers: {
              'X-Locale': i18n.language,
            },
          },
        );
      }

      if (shiftResponse) {
        onSave(shiftResponse.data);
        setLoading(false);
        setVisible(false);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          message.error(error.response?.data?.message);
        } else {
          message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
        }
      }
      setLoading(false);
      console.log(error);
    }
  };

  const onShiftTypeChange = (e: RadioChangeEvent) => {
    const shiftType = e.target.value;
    setShiftType(shiftType);
    if (shiftType === 'absence') {
      const unpaidPause = form.getFieldValue(['pause', 'unpaid']);
      if (unpaidPause) {
        const endDate = moment.unix(form.getFieldValue('end')).subtract(unpaidPause, 'seconds');
        form.setFieldsValue({
          end_time: endDate.format('HH:mm'),
        });
        changeEndDate({
          hour: endDate.hour(),
          minute: endDate.minute(),
        });
      }
    }
  };

  const onWantToDeleteShift = () => {
    setVisible(false);
    hoursDispatch({
      type: 'SET_SHIFT_IDS_TO_DELETE',
      payload: [shift!.id!],
    });
  };

  const getHrPartners = () => {
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingHrPartners(true);
    setHrPartners([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/hr-partners`, {
        params: {
          departmentId: activeDepartmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const hr = response.data;
        setHrPartners(hr);
        setLoadingHrPartners(false);
      })
      .catch((error) => {
        setLoadingHrPartners(false);
        console.log(error);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  };

  return (
    <Drawer
      getContainer={false}
      forceRender={true}
      maskClosable={false}
      title={shift && shift.id ? t('SHIFTS.UPDATE_SHIFT') : t('SHIFTS.SHIFT_CREATION')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={() => {
        hoursDispatch({
          type: 'SET_SELECTED_SHIFT',
          payload: null,
        });
        setVisible(false);
      }}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <ScrollToTop />
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        initialValues={initialValues}
        scrollToFirstError={true}
        style={{ paddingBottom: constraintsHeight + 20 }}
      >
        <Form.Item name="type">
          <Radio.Group buttonStyle="solid" className="light block" onChange={onShiftTypeChange}>
            <Radio.Button value="shift">{t('GLOBAL.SHIFT')}</Radio.Button>
            <Radio.Button value="absence">{t('GLOBAL.ABSENCE')}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {shiftType === 'shift' && (
          <React.Fragment>
            {isFeatureEnabled(features, FEATURES.SECTIONS) && !!userParams.sections?.length && !!sections.length && (
              <Form.Item label={t('GLOBAL.SECTION')} name="section">
                <Select
                  clearIcon
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  placeholder={t('FORMS.SECTION_PLACEHOLDER')}
                  optionFilterProp="children"
                  loading={loadingSections}
                  disabled={loadingSections}
                  allowClear={true}
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={''}>{t('GLOBAL.NO_SECTION')}</Option>
                  {userParams?.sections?.map((section_id) => {
                    const section = sections.find((section) => section.id == section_id);
                    if (!section) return null;
                    return (
                      <Option key={`section_${section.id}`} value={`${section.id}`}>
                        {section.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            {isFeatureEnabled(features, FEATURES.SKILLS) && !!userParams.skills?.length && !!skills?.length && (
              <Form.Item label={t('GLOBAL.SKILLS')} name="skills">
                <Select
                  id="shift_skills"
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  clearIcon
                  allowClear
                  mode="multiple"
                  maxTagCount={3}
                  placeholder={t('FORMS.SKILLS_PLACEHOLDER')}
                  optionFilterProp="children"
                  disabled={loadingSkills}
                  loading={loadingSkills}
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {userParams.skills?.map((skill_id: string) => {
                    const skill = skills.find((skill) => skill.id == skill_id);
                    if (!skill) return null;
                    return (
                      <Option value={skill.id!} key={`skill_${skill.id}`}>
                        {skill.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
            {!!isFeatureEnabled(features, FEATURES.ATTRIBUTES) && !!resources?.length && (
              <Form.Item name="attributes" label={t('GLOBAL.ATTRIBUTES')}>
                <Select
                  mode="multiple"
                  placeholder={t('FORMS.ATTRIBUTE_PLACEHOLDER')}
                  maxTagCount={3}
                  suffixIcon={<i className="icon-search" />}
                  disabled={loadingResources}
                  loading={loadingResources}
                  filterOption={(input, option) => {
                    if (option) {
                      if (!option.label) {
                        let children = option.children;
                        if (Array.isArray(children)) {
                          children = children.join();
                        }
                        return valueForSearch(children).includes(valueForSearch(input));
                      }
                    }
                    return false;
                  }}
                >
                  {resources.map((resource = {}) => (
                    <OptGroup label={resource.name} key={`resource_${resource.id}`}>
                      {(resource.attributes || []).map((attribut) => (
                        <Option value={attribut.id!} key={`attribut_${attribut.id}`}>
                          {attribut.name}
                        </Option>
                      ))}
                    </OptGroup>
                  ))}
                </Select>
              </Form.Item>
            )}
            {shift && shift.id && !!user?.package && (
              <Form.Item label={t('GLOBAL.PACKAGES')} name="packageId">
                <Select
                  clearIcon
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  placeholder={t('FORMS.PACKAGE_PLACEHOLDER')}
                  optionFilterProp="children"
                  loading={loadingPackages}
                  disabled={loadingPackages}
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {packages.map((pack) => (
                    <Option key={`package_${pack.id}`} value={`${pack.id}`}>
                      {pack.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {!(shift && shift.id) && !!user?.package && (
              <Spin spinning={loadingPackages}>
                <Form.Item label={t('GLOBAL.PACKAGES')} name="packageIds" rules={[{ required: true }]}>
                  <Select
                    clearIcon
                    mode="multiple"
                    getPopupContainer={(trigger) => trigger}
                    showSearch
                    placeholder={t('FORMS.PACKAGE_PLACEHOLDER')}
                    optionFilterProp="children"
                    loading={loadingPackages}
                    disabled={loadingPackages}
                    filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {packages.map((pack) => (
                      <Option key={`package_${pack.id}`} value={`${pack.id}`}>
                        {pack.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Spin>
            )}
            {!user?.package && (
              <React.Fragment>
                <Form.Item name="start" className="hidden">
                  <InputNumber disabled={true} />
                </Form.Item>
                <TimePickerFormItem
                  rules={[{ required: true, message: '' }]}
                  name="start_time"
                  label={t('GLOBAL.START')}
                  start={shiftDay?.unix()}
                  style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                  changeTime={changeStartDate}
                />
                <Form.Item name="end" className="hidden">
                  <InputNumber disabled={true} />
                </Form.Item>
                <TimePickerFormItem
                  rules={[{ required: true, message: '' }]}
                  name="end_time"
                  label={t('GLOBAL.END')}
                  displayDuration={true}
                  start={newStart || shiftDay?.unix()}
                  style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                  changeTime={changeEndDate}
                />
                <Form.Item label={t('GLOBAL.SHORTCUTS')} style={{ display: 'inline-block', width: '200px' }}>
                  <Select
                    clearIcon
                    getPopupContainer={(trigger) => trigger}
                    showSearch
                    placeholder={t('GLOBAL.SHORTCUTS')}
                    optionFilterProp="children"
                    loading={loadingShortcuts}
                    disabled={loadingShortcuts}
                    filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={changeShortcut}
                  >
                    {shortcuts.map((shortcut) => (
                      <Option key={`shortcut_${shortcut.id}`} value={`${shortcut.id}`}>
                        {shortcut.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <br />
                <Form.Item name={['pause', 'paid']} className="hidden">
                  <InputNumber disabled={true} />
                </Form.Item>
                <TimePickerFormItem
                  name={['pause', 'paid_time']}
                  label={t('GLOBAL.PAID_BREAK')}
                  style={{ display: 'inline-block', width: '110px', marginRight: '10px' }}
                  maxHours={7}
                  changeTime={changePaidPauseDate}
                />
                <Form.Item name={['pause', 'unpaid']} className="hidden">
                  <InputNumber disabled={true} />
                </Form.Item>
                <TimePickerFormItem
                  name={['pause', 'unpaid_time']}
                  label={t('GLOBAL.UNPAID_BREAK_SHORT')}
                  style={{ display: 'inline-block', width: '110px', marginRight: '10px' }}
                  maxHours={7}
                  changeTime={changeUnpaidPauseDate}
                />
                {isFeatureEnabled(features, FEATURES.TASKS) && !!tasks?.length && (
                  <React.Fragment>
                    <Divider orientation="left" plain>
                      {t('GLOBAL.TASKS')}
                    </Divider>
                    <Form.List name="tasks">
                      {(taskItems, { add, remove }) => {
                        return (
                          <div>
                            {taskItems.map((taskItem) => {
                              return (
                                <div className="form-list" key={taskItem.key}>
                                  <Form.Item
                                    {...taskItem}
                                    name={[taskItem.name, 'taskTypeId']}
                                    style={{ display: 'inline-block', flex: '1', marginRight: '10px' }}
                                    fieldKey={[taskItem.fieldKey, 'taskTypeId']}
                                    rules={[{ required: true, message: '' }]}
                                  >
                                    <Select
                                      allowClear={true}
                                      getPopupContainer={(trigger) => trigger}
                                      showSearch
                                      placeholder={t('GLOBAL.TASK')}
                                      optionFilterProp="children"
                                      disabled={loadingTasks}
                                      loading={loadingTasks}
                                      filterOption={(input, option) =>
                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {(tasks || []).map((task) => (
                                        <Option key={`task_${task.id}`} value={task.id!}>
                                          {task.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    name={[taskItem.name, 'start']}
                                    fieldKey={[taskItem.fieldKey, 'start']}
                                    className="hidden"
                                  >
                                    <InputNumber disabled={true} />
                                  </Form.Item>
                                  <TimePickerFormItem
                                    placeholder={t('GLOBAL.START')}
                                    rules={[
                                      {
                                        required: true,
                                        message: '',
                                        validator: (_rule, value) => {
                                          if (!value) {
                                            return Promise.reject();
                                          }
                                          const startShift = form.getFieldValue('start');
                                          const startTask = form.getFieldValue(['tasks', taskItem.name, 'start']);
                                          if (startTask < startShift) {
                                            return Promise.reject();
                                          } else {
                                            return Promise.resolve(value);
                                          }
                                        },
                                      },
                                    ]}
                                    name={[taskItem.name, 'start_time']}
                                    fieldKey={[taskItem.fieldKey, 'start_time']}
                                    start={lastTaskEndTimestamp || newStart || shiftDay?.unix()}
                                    style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                                    changeTime={({ hour, minute }) =>
                                      changeTaskDate({ hour, minute, name: `start`, index: taskItem.fieldKey })
                                    }
                                  />
                                  <Form.Item
                                    name={[taskItem.name, 'end']}
                                    fieldKey={[taskItem.fieldKey, 'end']}
                                    className="hidden"
                                  >
                                    <InputNumber disabled={true} />
                                  </Form.Item>
                                  <TimePickerFormItem
                                    placeholder={t('GLOBAL.END')}
                                    rules={[
                                      {
                                        required: true,
                                        message: '',
                                        validator: (_rule, value) => {
                                          if (!value) {
                                            return Promise.reject();
                                          }
                                          const endShift = form.getFieldValue('end');
                                          const endTask = form.getFieldValue(['tasks', taskItem.name, 'end']);
                                          if (endTask > endShift) {
                                            return Promise.reject();
                                          } else {
                                            return Promise.resolve(value);
                                          }
                                        },
                                      },
                                    ]}
                                    name={[taskItem.name, 'end_time']}
                                    fieldKey={[taskItem.fieldKey, 'end_time']}
                                    displayDuration={true}
                                    start={tasksStart[taskItem.fieldKey] || newStart || shiftDay?.unix()}
                                    style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                                    changeTime={({ hour, minute }) =>
                                      changeTaskDate({ hour, minute, name: `end`, index: taskItem.fieldKey })
                                    }
                                  />
                                  <MinusCircleOutlined
                                    onClick={() => {
                                      remove(taskItem.name);
                                      const tasks = form.getFieldValue('tasks');
                                      const _lastTaskEndTimestamp = Math.max(
                                        ...(tasks?.map((task: { end?: number }) => task?.end || 0) || [0]),
                                        0,
                                      );
                                      setLastTaskEndTimestamp(_lastTaskEndTimestamp);
                                    }}
                                  />
                                </div>
                              );
                            })}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => {
                                  add();
                                }}
                                block
                              >
                                <PlusOutlined /> {t('TASKS.ADD_TASK')}
                              </Button>
                            </Form.Item>
                          </div>
                        );
                      }}
                    </Form.List>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {hrPartners.length > 0 && activeDepartment?.hr_codes && activeDepartment?.hr_codes.length > 0 && (
              <Form.Item name="hr_code" label={t('GLOBAL.HR_CODE')}>
                <Select
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  placeholder={t('GLOBAL.HR_CODE')}
                  optionFilterProp="children"
                  loading={loadingHrPartners}
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  allowClear
                >
                  {activeDepartment?.hr_codes.map((hr_code) => (
                    <Option key={hr_code.id} value={`${hr_code.id}`}>
                      {hr_code.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item name="hide" valuePropName="checked">
              <Checkbox>
                <span style={{ display: 'flex', alignItems: 'center' }}>{t('SHIFTS.NOT_COUNTED')}</span>
              </Checkbox>
            </Form.Item>
            <Form.Item label={t('GLOBAL.COMMENT')} name="comment">
              <TextArea rows={4} />
            </Form.Item>
          </React.Fragment>
        )}
        {shiftType === 'absence' && (
          <React.Fragment>
            <Form.Item
              name={['dayoff', 'dayOffType']}
              label={t('FORMS.ABSENCE_TYPE')}
              rules={[{ required: true, message: '' }]}
            >
              <Select
                getPopupContainer={(trigger) => trigger}
                showSearch
                placeholder={t('FORMS.ABSENCE_TYPE_PLACEHOLDER')}
                optionFilterProp="children"
                loading={loadingDayoffs}
                disabled={loadingDayoffs}
                filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {dayoffs.map((dayoff) => (
                  <Option value={dayoff.id!} key={`dayoff_${dayoff.id}`}>
                    {dayoff.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0, display: 'inline-block' }}>
              <Form.Item
                name={['dayoff', 'fullDay']}
                valuePropName="checked"
                style={{ marginBottom: 0, display: 'inline-block' }}
              >
                <Checkbox style={{ lineHeight: '32px' }}>{t('GLOBAL.FULL_DAY')}</Checkbox>
              </Form.Item>
              <Form.Item
                name={['dayoff', 'paid']}
                valuePropName="checked"
                style={{ marginBottom: 0, display: 'inline-block' }}
              >
                <Checkbox style={{ lineHeight: '32px' }}>{t('GLOBAL.PAID_ABSENCE')}</Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item className="form-item-should-update" style={{ marginBottom: 0 }}>
              <Form.Item name="start" className="hidden">
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                rules={[{ required: true, message: '' }]}
                name="start_time"
                label={t('GLOBAL.START')}
                start={shiftDay?.unix()}
                style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                changeTime={changeStartDate}
              />
              <Form.Item name="end" className="hidden">
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                rules={[{ required: true, message: '' }]}
                name="end_time"
                label={t('GLOBAL.END')}
                displayDuration={true}
                start={newStart || shiftDay?.unix()}
                style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                changeTime={changeEndDate}
              />
              <Form.Item label={t('GLOBAL.SHORTCUTS')} style={{ display: 'inline-block', width: '200px' }}>
                <Select
                  clearIcon
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  placeholder={t('GLOBAL.SHORTCUTS')}
                  optionFilterProp="children"
                  loading={loadingShortcuts}
                  disabled={loadingShortcuts}
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={changeShortcut}
                >
                  {shortcuts.map((shortcut) => (
                    <Option key={`shortcut_${shortcut.id}`} value={`${shortcut.id}`}>
                      {shortcut.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item label={t('GLOBAL.COMMENT')} name="comment">
              <TextArea rows={4} />
            </Form.Item>
          </React.Fragment>
        )}
        <div className="actions-container">
          <div className="infos">
            <ReactHeight onHeightReady={(height: number) => setConstraintsHeight(height)}>
              {/* <Constraints message={activeUser?.constraints?.message || []} /> */}
            </ReactHeight>
          </div>
          <div className="actions">
            <Button
              type="primary"
              danger
              onClick={() => {
                setVisible(false);
              }}
            >
              {t('GLOBAL.CANCEL')}
            </Button>
            {!!shift?.id && (
              <Button
                type="default"
                danger
                onClick={() => {
                  onWantToDeleteShift();
                }}
              >
                {t('GLOBAL.REMOVE')}
              </Button>
            )}
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              // disabled={activeUser?.constraints?.message?.some((c) => c.type === 'not_available')}
            >
              {shift && shift.id ? t('SHIFTS.UPDATE_SHIFT') : t('SHIFTS.CREATE_SHIFT')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

const DrawerShiftStyled = styled(DrawerShift)`
  .recurrence-container {
    display: inline-block;
    background-color: ${colors.greyLight};
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;

    .anticon-close {
      background: ${colors.red};
      padding: 1px;
      border-radius: 10px;
      margin-left: 5px;
      svg {
        transform: scale(0.8);
        fill: white;
      }
    }
  }

  .hidden {
    display: none;
  }

  .ant-form-item-explain {
    display: none;
  }

  .form-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-select-item.ant-select-item-option.not-compatible:not(.ant-select-item-option-selected) {
    background: ${Colors.greyLight};
    color: ${Colors.grey};
  }

  .form-item-should-update {
    .ant-form-item-control-input {
      min-height: 0;
    }

    .ant-row.ant-form-item.min-height {
      .ant-form-item-control-input {
        min-height: 40px;
      }
    }
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
    .ant-select-item-option-content {
      .select-user-option {
        .infos  {
          opacity: 0.8;
        }
      }
    }
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    .ant-select-item-option-content {
      .select-user-option {
        .infos  {
          opacity: 1;
        }
      }
    }
  }
  .ant-select-item-option-content .select-user-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .displayName {
    }
    .infos {
      opacity: 0.3;
      transition: opacity 0.3s;
      span {
        background-color: ${colors.bluePastel};
        color: white;
        border-radius: 3px;
        margin-left: 4px;
        padding: 1px 5px;
      }
    }
  }
`;

export default DrawerShiftStyled;

function removeUnnecessaryProperties(object: any): any {
  delete object.end_time;
  delete object.start_time;
  delete object.type;

  if (object.pause) {
    delete object.pause.paid_time;
    delete object.pause.unpaid_time;
  }

  if (object.end && object.start) {
    if (object.end < object.start) {
      object.end = object.end + 86400;
    }
  }

  return object;
}

const initialValues = {
  section: null,
  type: 'shift',
  tasks: [],
  pause: {
    paid: 0,
    paid_time: null,
    unpaid: 0,
    unpaid_time: null,
  },
  dayoff: {
    paid: true,
  },
};
