import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setActivateDimonaCorrectionAll: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalDimonaCorrectionAllUsers: React.FC<Props> = ({ visible, setVisible, setActivateDimonaCorrectionAll }) => {
  const { t } = useTranslation();

  const onOk = () => {
    setActivateDimonaCorrectionAll(true);
    setVisible(false);
  };

  const onCancel = () => {
    setActivateDimonaCorrectionAll(false);
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={t('SETTINGS.ACCOUNT.GENERAL.DIMONA_CORRECTION_EACH_USER.TITLE_1')}
      closable={false}
      footer={[
        <Button type="primary" onClick={onOk}>
          {t('SETTINGS.ACCOUNT.GENERAL.DIMONA_CORRECTION_EACH_USER.YES_TEXT')}
        </Button>,
        <Button type="default" danger onClick={onCancel}>
          {t('SETTINGS.ACCOUNT.GENERAL.DIMONA_CORRECTION_EACH_USER.NO_TEXT')}
        </Button>,
      ]}
      width={600}
    >
      {t('SETTINGS.ACCOUNT.GENERAL.DIMONA_CORRECTION_EACH_USER.TITLE_2')}
    </Modal>
  );
};

export default ModalDimonaCorrectionAllUsers;
