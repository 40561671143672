import React, { useRef, useState } from 'react';
import { Input, Tooltip, Button, Space } from 'antd';
import DrawerAttribute from '@/pages/app/components/drawers/Attribute';
import { ExportMode } from '..';
import { useTranslation } from 'react-i18next';
import { IAttribut } from '@/types/attribut.model';
import { IResource } from '@/types/resource.model';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const QRCode = require('qrcode.react');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Barcode = require('react-barcode');

interface Props {
  className?: string;
  activeDepartmentId?: string;
  disabled: boolean;
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
  setExportMode: React.Dispatch<React.SetStateAction<ExportMode>>;
  onAttributSave: (attribut: IAttribut) => void;
  resource: IResource | null;
}

const Header: React.FC<Props> = ({
  className,
  activeDepartmentId,
  disabled,
  setFilterSearch,
  setExportMode,
  onAttributSave,
  resource,
}) => {
  const [drawerAttributIsVisible, setDrawerAttributIsVisible] = useState<boolean>(false);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const refPageContainer = useRef(null);

  return (
    <header className={className}>
      <div className="left">
        <Space>
          <Input
            disabled={disabled}
            allowClear
            placeholder={t('GLOBAL.SEARCH')}
            suffix={<i className="icon-search"></i>}
            width={190}
            onChange={(e) => {
              setFilterSearch(e.target.value);
            }}
          />
        </Space>
      </div>
      <div className="right">
        <div className="actions">
          <Tooltip title={t('GLOBAL.DOWNLOAD')}>
            <Button
              shape="circle"
              className="action"
              onClick={() => {
                setExportMode('XLS');
              }}
            >
              <i className="icon-download" />
            </Button>
          </Tooltip>
          <Tooltip title={t('GLOBAL.PRINT')}>
            <ReactToPrint
              pageStyle={pageStyle}
              trigger={() => (
                <Button shape="circle" className="action">
                  <i className="icon-print" />
                </Button>
              )}
              content={() => refPageContainer.current}
            />
          </Tooltip>
          <Button type="primary" style={{ width: '160px' }} onClick={() => setDrawerAttributIsVisible(true)}>
            {t('GLOBAL.ADD')}
          </Button>
        </div>
      </div>
      <DrawerAttribute
        departmentId={activeDepartmentId}
        resource={resource}
        visible={drawerAttributIsVisible}
        onClose={() => setDrawerAttributIsVisible(false)}
        onSave={onAttributSave}
      />
      <div className="codes" ref={refPageContainer}>
        {resource?.attributes?.map((attribute) => {
          const { codeType, code, id, name, address = '' } = attribute;
          if (code || (codeType === 'qr-code' && id)) {
            return (
              <div className="code-container" key={`code_${attribute.id}`}>
                {codeType === 'qr-code' && (
                  <div className="qr-code-container">
                    <div className="qr-code">
                      <QRCode
                        renderAs="svg"
                        id="qr-code"
                        className="qr-code-a"
                        size={640}
                        level="H"
                        value={code || id}
                      />
                    </div>
                    <h3>{name}</h3>
                    <p>{address}</p>
                  </div>
                )}
                {codeType === 'bar-code' && (
                  <div className="bar-code-container">
                    <div className="bar-code">
                      <Barcode value={code} />
                    </div>
                    <h3>{name}</h3>
                    <p>{address}</p>
                  </div>
                )}
              </div>
            );
          }
        })}
      </div>
    </header>
  );
};

export default styled(Header)`
  @media screen {
    .codes {
      display: none;
    }
  }
`;

const pageStyle = `
 @media print {
   margin: 0;
   padding: 0;
   width: 210mm;
   position: relative;
    @page {
      size: page;
      margin: 0;
      padding: 0;
      height: 297mm;
      width: 210mm;
    }

    .codes {
      display: block;
      position: relative;
    }

    .code-container {
      position: relative;
      page-break-inside: avoid;
      width: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed grey;
      height: auto;
      display: flex-inline-flex;
      box-sizing: border-box;
      padding: 20px 0;
    }

    .code-container h3 {
      font-size: 12px;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 90%;
      margin: 0 auto;
    }

    .code-container p {
      font-size: 10px;
      text-align: center;
      max-width: 90%;
      margin: 0 auto;
      color: grey;
      -webkit-line-clamp: 2;
    }
      
    .qr-code {
      width: 100%;
      height: 60mm;
      text-align: center;
    }

    .qr-code svg {
      width: 60mm;
      height: 60mm;
      padding: 4mm;
    }

    .bar-code {
      width: 100%;
      height: 60mm;
    }

    .bar-code svg {
      width: 60mm;
      height: 60mm;
      padding: 4mm;
    }
  }
`;
