import { IConstraint } from '@/types/constraint.model';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  messages: string[];
}

const ValidationMessages: React.FC<Props> = ({ messages }) => {
  return (
    <ul>
      {messages.map((message, index) => (
        <li key={index}>{message}</li>
      ))}
    </ul>
  );
};

export default ValidationMessages;
