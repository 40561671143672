import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import { IDepartment } from '@/types/department.model';
import { IUserParams } from '@/types/user-params.model';
import { IUser } from '@/types/user.model';
import { Col, Form, message, Result, Row, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import Axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormActions from '../FormActions';
import AccessLevel from './AccessLevel';
import AdditionalInformation from './AdditionalInformation';
import Automation from './Automation';
import Integrations from './Integrations';
import Notifications from './Notifications';
import Printing from './Printing';
import Sections from './Sections';
import Skills from './Skills';
import { FEATURES } from '@/types/features.model';
import { isClockingAccount, isFeatureEnabled } from '@/utils';
interface Props {
  user?: IUser;
  className?: string;
  department?: IDepartment;
  setAyActivated: React.Dispatch<React.SetStateAction<boolean>>;
}

const Parameters: React.FC<Props> = ({ className, user, department, setAyActivated }) => {
  const {
    state: { userDetails },
  } = useContext(AuthContext);
  const {
    state: { features },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: true });
  const { dispatch: appContextDispatch } = useContext(AppContext);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params, setParams] = useState<IUserParams | null>(null);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [activeRole, setActiveRole] = useState<string | null>(null);
  const [saveAyValue, setSaveAyValue] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    setParams(null);
    if (user) {
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/params`, {
        params: {
          departmentId: department?.id,
        },
      })
        .then((response) => {
          setParams(response.data);
          setActiveRole(response.data.params.account_type);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [user]);

  useEffect(() => {
    form.resetFields();
    let fieldsValue: any = {};

    console.log(params?.integrations);
    if (params) {
      fieldsValue = {
        params: {
          ...params?.params,
          hr_partners: params.params?.hr_partners?.map((x) => ({
            ...x,
            start: x.start ? moment.unix(x.start) : undefined,
            end: x.end ? moment.unix(x.end) : undefined,
          })),
          hides_delay: params.params?.hides_delay == 0 ? t('GLOBAL.NEVER') : params.params?.hides_delay,
        },
        access: {
          ...params?.access,
        },
        notifications: {
          ...params?.notifications,
        },
        integrations: {
          ...params?.integrations,
        },
        skills: [...params?.skills],
        sections: [...params?.sections],
        reports: [...params?.reports],
      };
    }
    form.setFieldsValue(fieldsValue);
    setFormHasChanged(false);
  }, [params]);

  const onFinish = (values: { [index: string]: any }) => {
    if (!user?.recordId) return;
    setIsLoading(true);

    if ('workerType' in values.params) {
      values.params.workerType = values.params.workerType || null;
    }

    if ('automatic_contract_template_id' in values.params) {
      values.params.automatic_contract_template_id = values.params.automatic_contract_template_id || null;
    }

    if (values.integrations) {
      for (const key in values.integrations) {
        if (values.integrations[key] === undefined) {
          values.integrations[key] = null;
        }
      }
    }

    Axios.patch(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/params`, {
      departmentId: department?.id,
      ...values,
      params: {
        ...values.params,
        user_type: values.params.user_type || null,
        hides_delay: values.params.hides_delay == t('GLOBAL.NEVER') ? 0 : values.params.hides_delay,
      },
    })
      .then(({ data }) => {
        if (user?.mainId === userDetails?.id) {
          Axios.get(`${process.env.REACT_APP_API_URL}/v3/departments`)
            .then((response) => {
              const departments = response.data.departments as IDepartment[];
              appContextDispatch({
                type: 'SET_DEPARTMENTS',
                payload: departments,
              });
              setParams(data);
              setIsLoading(false);
            })
            .catch((error) => {
              appContextDispatch({
                type: 'SET_DEPARTMENTS',
                payload: [],
              });
              setParams(data);
              setIsLoading(false);
              console.error(error);
            });
        } else {
          setParams(data);
          setIsLoading(false);
        }
        setAyActivated(saveAyValue);
      })
      .catch((error) => {
        message.error(t('SETTINGS.MESSAGE_UPDATING_ERROR'));
        console.error(error);
        setIsLoading(false);
      });
  };

  const onReset = () => {
    setParams({
      ...params,
    });
    setAyActivated(params?.params?.ay_sync || false);
  };

  const onValuesChange = (changedValues: Store, values: Store) => {
    setFormHasChanged(true);
  };

  return (
    <div className={className}>
      <Spin spinning={isLoading}>
        <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish} size="large">
          {params && (
            <React.Fragment>
              <Row gutter={[40, 30]}>
                <Col span={24} style={{ width: '100%' }}>
                  <AccessLevel
                    form={form}
                    department={department}
                    user={user}
                    params={params}
                    setActiveRole={setActiveRole}
                  />
                </Col>
              </Row>
              {isFeatureEnabled(features, FEATURES.SKILLS) && (
                <Row gutter={[40, 30]}>
                  <Col span={24} style={{ width: '100%' }}>
                    <Skills form={form} />
                  </Col>
                </Row>
              )}
              {isFeatureEnabled(features, FEATURES.SECTIONS) && (
                <Row gutter={[40, 30]}>
                  <Col span={24} style={{ width: '100%' }}>
                    <Sections form={form} />
                  </Col>
                </Row>
              )}
              <Row gutter={[40, 30]}>
                <Col span={24} style={{ width: '100%' }}>
                  <AdditionalInformation
                    form={form}
                    params={params}
                    user={user}
                    department={department}
                    activeRole={activeRole}
                    saveAyValue={saveAyValue}
                    setSaveAyValue={setSaveAyValue}
                  />
                </Col>
              </Row>
              {/* {!!params?.socialPartners?.length && (
                <Row gutter={[40, 30]}>
                  <Col span={24}>
                    <SocialPartners form={form} socialPartners={params?.socialPartners} />
                  </Col>
                </Row>
              )} */}
              <Row gutter={[40, 30]}>
                <Col span={24} style={{ width: '100%' }}>
                  <Notifications form={form} department={department} />
                </Col>
              </Row>
              {(isFeatureEnabled(features, FEATURES.PLUG_POS) || isFeatureEnabled(features, FEATURES.RESTOMAX)) && (
                <Row gutter={[40, 30]}>
                  <Col span={24} style={{ width: '100%' }}>
                    <Integrations integrations={params.integrations} integrationValues={params.integrationValues} />
                  </Col>
                </Row>
              )}
              {isFeatureEnabled(features, FEATURES.SCHEDULE) && !!params?.reportsList?.length && (
                <Row gutter={[40, 30]}>
                  <Col span={24} style={{ width: '100%' }}>
                    <Printing reportsList={params?.reportsList} />
                  </Col>
                </Row>
              )}
              {(isFeatureEnabled(features, FEATURES.CONTRACTS) || isFeatureEnabled(features, FEATURES.DIMONA)) &&
                isFeatureEnabled(features, FEATURES.AUTOMATIC_DIMONA_CONTRACTS) &&
                (department?.params?.automaticContracts || department?.params?.automaticDimona) &&
                params.params &&
                !params.params.ay_sync && (
                  <Row gutter={[40, 30]}>
                    <Col span={24} style={{ width: '100%' }}>
                      <Automation department={department} />
                    </Col>
                  </Row>
                )}
              <FormActions saving={isLoading} onReset={onReset} active={formHasChanged} />
            </React.Fragment>
          )}
          {!isLoading && !params && (
            <Result status="500" title="Erreur" subTitle="Désolé, une erreur s'est produite." />
          )}
        </Form>
        {!!user?.pending && <div className="disable-block"></div>}
      </Spin>
    </div>
  );
};

export default styled(Parameters)`
  h4 {
    font-weight: bold;
  }

  form {
    padding-bottom: 20px;
    > .ant-row {
      > .ant-col {
        > div {
          display: block;
          padding-bottom: 0;
          text-align: left;
        }
      }
    }
    > .actions {
      position: fixed;
      width: calc(100vw - 740px);
      background: white;
      bottom: -80px;
      margin: 0;
      margin-left: -20px;
      display: flex;
      box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
      padding: 10px;
      border-radius: 3px 3px 0 0;
      transition: bottom 0.2s;

      &.active {
        transition-timing-function: ease-in;
        bottom: 0px;
      }

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .disable-block {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background: rgba(247, 248, 251, 0.5);
    z-index: 1;
    cursor: not-allowed;
  }
`;
