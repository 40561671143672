import ScrollToTop from '@/components/ScrollToTop';
import TimePickerFormItem from '@/pages/app/components/TimePickerFormItem';
import AppContext from '@/pages/app/context';
import { default as Colors, default as colors } from '@/styles/colors';
import { IShift } from '@/types/shift.model';
import { getWindowSize, isFeatureEnabled, isNullOrUndefined, valueForSearch } from '@/utils';
import { Button, Checkbox, Drawer, Form, InputNumber, message, Modal, Radio, Select, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { RadioChangeEvent } from 'antd/lib/radio';
import axios from 'axios';
import { cloneDeep, pick } from 'lodash';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType, updateShiftsAndContextWithShiftResponse } from '../../hours/manage/redux/actions';
import { InitialStateType } from '../../hours/manage/redux/store';
import Constraints from '../Constraints';
import { FEATURES } from '@/types/features.model';

const { Option, OptGroup } = Select;

interface Props {
  className?: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const DrawerMultipleShifts: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { state: appContextState } = useContext(AppContext);
  const {
    activeSection,
    activeDepartment,
    activeDepartmentId,
    shortcuts,
    loadingShortcuts,
    dayoffs,
    loadingDayoffs,
    loadingResources,
    resources,
    skills,
    userCategories,
    features,
  } = appContextState;
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { startDate, endDate, picker, selectedShifts, shiftsMap, selected_shifts_on_shift_key } = useSelector(
    ({ startDate, endDate, picker, selectedShifts, shiftsMap, selected_shifts_on_shift_key }: InitialStateType) => ({
      startDate,
      endDate,
      picker,
      selectedShifts,
      shiftsMap,
      selected_shifts_on_shift_key,
    }),
    shallowEqual,
  );
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const [form] = Form.useForm();
  const [shiftDay, setShiftDay] = useState<moment.Moment>();
  const [newStart, setNewStart] = useState<number | null>(null);
  const [shiftType, setShiftType] = useState<'shift' | 'absence'>('shift');
  const [loading, setLoading] = useState<boolean>(false);
  const [shiftExample, setShiftExample] = useState<IShift | null>(null);
  const [shiftIds, setShiftIds] = useState<string[]>([]);
  const [hrPartners, setHrPartners] = useState<any[]>([]);
  const [loadingHrPartners, setLoadingHrPartners] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!visible) {
      return;
    }

    const shiftIds = [];
    const dayOffs: IShift[] = [];
    const shifts: IShift[] = [];

    if (picker !== 'month') {
      for (const shiftId of selectedShifts) {
        const shift = shiftsMap.get(shiftId);
        if (shift) {
          shiftIds.push(shiftId);
          if (shift?.dayoff) {
            dayOffs.push(shift);
          } else {
            shifts.push(shift!);
          }
        }
      }
    } else {
      selected_shifts_on_shift_key?.forEach((shift) => {
        if (shift) {
          shiftIds.push(shift.id);
          if (shift?.dayoff) {
            dayOffs.push(shift);
          } else {
            shifts.push(shift!);
          }
        }
      });
    }

    if (shifts.length > 0) {
      setShiftExample({
        ...pick(shifts[0], ['start', 'end', 'pause', 'attributes']),
      });
    } else if (dayOffs.length > 0) {
      setShiftExample({
        ...pick(dayOffs[0], ['start', 'end', 'dayoff']),
      });
    }
    setShiftIds(shiftIds);
  }, [visible, selectedShifts, selected_shifts_on_shift_key]);

  useEffect(() => {
    if (!shiftExample) {
      return;
    }
    getHrPartners();
    // Set fields value
    form.resetFields();
    const fieldsValue: any = {
      ...cloneDeep(initialValues),
    };

    const { start, end, pause, attributes, dayoff } = shiftExample;

    const startMoment = moment.unix(start!);
    fieldsValue.start = start;
    fieldsValue.start_time = startMoment.format('HH:mm');
    setNewStart(start!);
    setShiftDay(startMoment.startOf('day'));

    const endMoment = moment.unix(end!);
    fieldsValue.end = end;
    fieldsValue.end_time = endMoment.format('HH:mm');

    if (dayoff) {
      setShiftType('absence');
      fieldsValue.type = 'absence';
      fieldsValue.dayoff = dayoff;
    } else {
      setShiftType('shift');
      fieldsValue.type = 'shift';
    }

    if (pause?.unpaid) {
      const _minutes = moment.duration(pause!.unpaid, 'seconds').asMinutes();
      const minutes = _minutes % 60;
      const hours = Math.trunc(_minutes / 60);
      fieldsValue.pause.unpaid_time = `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`;
      fieldsValue.pause.unpaid = pause.unpaid;
    }

    if (pause?.paid) {
      const _minutes = moment.duration(pause!.paid, 'seconds').asMinutes();
      const minutes = _minutes % 60;
      const hours = Math.trunc(_minutes / 60);
      fieldsValue.pause.paid_time = `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`;
      fieldsValue.pause.paid = pause.paid;
    }

    if (attributes && attributes.length > 0) {
      fieldsValue.attributes = attributes.map((attribut) => {
        return attribut.id;
      });
    } else {
      fieldsValue.attributes = [];
    }

    form.setFieldsValue(fieldsValue);
  }, [shiftExample]);

  const changeStartDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    const startTimestamp = moment(shiftDay)
      .set('hour', hour || 0)
      .set('minute', minute || 0)
      .unix();
    form.setFieldsValue({
      start: startTimestamp,
    });
    setNewStart(hour ? startTimestamp : null);
    const endDate = form.getFieldValue('end');
    if (endDate) {
      const endMoment = moment.unix(endDate);
      let endTimestamp = moment(shiftDay).set('hour', endMoment.hours()).set('minute', endMoment.minutes()).unix();
      if (endTimestamp < startTimestamp) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      form.setFieldsValue({
        end: endTimestamp,
      });
    }

    if (shiftType === 'shift') {
      didChangeDate();
    }
  };

  const changeEndDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    let endValue = null;
    const startDate = form.getFieldValue('start');
    if (!isNullOrUndefined(hour)) {
      let endTimestamp = moment(shiftDay)
        .set('hour', hour || 0)
        .set('minute', minute || 0)
        .unix();
      if (startDate && endTimestamp < startDate) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      endValue = endTimestamp;
    }

    form.setFieldsValue({
      end: endValue,
    });

    if (shiftType === 'shift') {
      didChangeDate();
    }
  };

  const didChangeDate = () => {
    if (activeDepartment?.scheduleParams?.pauseAuto) {
      const { pauses } = activeDepartment?.scheduleParams;
      const startDate = form.getFieldValue('start');
      const endDate = form.getFieldValue('end');
      const duration = endDate - startDate;

      const sortedPauses = pauses?.filter((x) => duration > x.from).sort((a, b) => b.from - a.from);
      if (sortedPauses?.length) {
        const greatestPause = sortedPauses[0];
        const paid = greatestPause?.paid ? greatestPause.duration : 0;
        const unpaid = !greatestPause?.paid ? greatestPause.duration : 0;

        if (shiftType === 'shift') {
          form.setFieldsValue({
            pause: {
              paid,
              unpaid,
              paid_time: paid ? moment.unix(paid).format('HH:mm') : null,
              unpaid_time: unpaid ? moment.unix(unpaid).format('HH:mm') : null,
            },
          });
        } else {
          if (unpaid) {
            const endDate = moment.unix(form.getFieldValue('end')).subtract(unpaid, 'seconds');
            form.setFieldsValue({
              end_time: endDate.format('HH:mm'),
            });
            changeEndDate({
              hour: endDate.hour(),
              minute: endDate.minute(),
            });
          }
        }
      } else {
        form.setFieldsValue({
          pause: {
            paid: 0,
            unpaid: 0,
            paid_time: null,
            unpaid_time: null,
          },
        });
      }
    }
  };

  const changePaidPauseDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    const pause = form.getFieldValue('pause');
    if (isNullOrUndefined(hour)) {
      pause.paid = 0;
    } else {
      pause.paid = moment
        .duration(0)
        .add(hour || 0, 'hour')
        .add(minute || 0, 'minute')
        .asSeconds();
    }
    form.setFieldsValue({
      pause,
    });
  };

  const changeUnpaidPauseDate = ({ hour, minute }: { hour: number | null; minute: number | null }) => {
    const pause = form.getFieldValue('pause');
    if (isNullOrUndefined(hour)) {
      pause.unpaid = 0;
    } else {
      pause.unpaid = moment
        .duration(0)
        .add(hour || 0, 'hour')
        .add(minute || 0, 'minute')
        .asSeconds();
    }
    form.setFieldsValue({
      pause,
    });
  };

  const changeShortcut = (value: string) => {
    if (value) {
      const shortcut = shortcuts.find((x) => x.id === value);
      if (shortcut) {
        const { starthour, startmin, endhour, endmin } = shortcut;
        form.setFieldsValue({
          start_time: `${starthour?.toString().padStart(2, '0')}:${startmin?.toString().padStart(2, '0')}`,
        });
        changeStartDate({ hour: shortcut.starthour || null, minute: shortcut.startmin || null });
        form.setFieldsValue({
          end_time: `${endhour?.toString().padStart(2, '0')}:${endmin?.toString().padStart(2, '0')}`,
        });
        changeEndDate({ hour: shortcut.endhour || null, minute: shortcut.endmin || null });
      }
    }
    if (shiftType === 'absence') {
      didChangeDate();
    }
  };

  const onFinish = async (values: Store) => {
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/shifts`,
        {
          shiftIds: [...shiftIds],
          ...removeUnnecessaryProperties(values),
        },
        {
          params: {
            picker,
            startDate: startDate.unix(),
            endDate: endDate.unix(),
            departmentId: activeDepartmentId,
            sectionId: activeSection,
          },
        },
      )
      .then((response) => {
        if (picker == 'month') {
          hoursDispatch({
            type: 'SET_MONTHLY_SELECTED_ELEMENTS',
            payload: [],
          });
          hoursDispatch({
            type: 'SET_MONTHLY_SELECTING_ELEMENTS',
            payload: false,
          });
          hoursDispatch({
            type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
            payload: null,
          });
        }
        updateShiftsAndContextWithShiftResponse(hoursDispatch, response, {
          department: activeDepartment!,
          picker,
          startDate,
          endDate,
          activeSection,
          skills,
          userStatus: userCategories,
        });
        setLoading(false);
        setVisible(false);
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          const message = error.response?.data?.message as any[];
          const forceable = !message?.some((m) => m.type === 'not_available');
          if (forceable) {
            Modal.confirm({
              className: 'modal-constraints',
              title: t('SCHEDULE.CONSTRAINTS.TITLE'),
              icon: null,
              content: <Constraints message={message || []} />,
              cancelText: t('GLOBAL.CANCEL'),
              okText: t('GLOBAL.FORCE'),
              onOk: () => {
                axios({
                  method: error.response.config.method,
                  url: error.response.config.url,
                  data: {
                    ...JSON.parse(error.response.config.data),
                    force: true,
                  },
                  params: {
                    picker,
                    startDate: startDate.unix(),
                    endDate: endDate.unix(),
                    departmentId: activeDepartmentId,
                    sectionId: activeSection,
                  },
                })
                  .then((response) => {
                    updateShiftsAndContextWithShiftResponse(hoursDispatch, response, {
                      department: activeDepartment!,
                      picker,
                      startDate,
                      endDate,
                      activeSection,
                      skills,
                      userStatus: userCategories,
                    });

                    setLoading(false);
                    setVisible(false);
                  })
                  .catch((err) => {
                    console.error(err);
                    setLoading(false);
                  });
              },
              onCancel: () => {
                setLoading(false);
              },
            });
          } else {
            Modal.info({
              className: 'modal-constraints',
              title: t('SCHEDULE.CONSTRAINTS.TITLE'),
              icon: null,
              content: <Constraints message={message || []} />,
              okText: t('GLOBAL.OK'),
              okType: 'default',
              onOk: () => {
                setLoading(false);
              },
            });
          }
        } else {
          if (error?.response?.status === 422) {
            message.error(error?.response?.data?.message);
          }
          setLoading(false);
        }
        console.error(error);
      });
  };

  const onShiftTypeChange = (e: RadioChangeEvent) => {
    const shiftType = e.target.value;
    setShiftType(shiftType);
    if (shiftType === 'absence') {
      const unpaidPause = form.getFieldValue(['pause', 'unpaid']);
      if (unpaidPause) {
        const endDate = moment.unix(form.getFieldValue('end')).subtract(unpaidPause, 'seconds');
        form.setFieldsValue({
          end_time: endDate.format('HH:mm'),
        });
        changeEndDate({
          hour: endDate.hour(),
          minute: endDate.minute(),
        });
      }
    }
  };

  const getHrPartners = () => {
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingHrPartners(true);
    setHrPartners([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/hr-partners`, {
        params: {
          departmentId: activeDepartmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const hr = response.data;
        setHrPartners(hr);
        setLoadingHrPartners(false);
      })
      .catch((error) => {
        setLoadingHrPartners(false);
        console.log(error);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  };

  return (
    <Drawer
      getContainer={false}
      forceRender={true}
      maskClosable={false}
      title={t('SHIFTS.MODIFY_X_SHIFTS', { count: shiftIds.length })}
      placement="right"
      visible={!!visible}
      destroyOnClose={true}
      onClose={() => {
        setVisible(false);
      }}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <ScrollToTop />
      {!shiftExample ? (
        <Spin spinning={true} />
      ) : (
        <Form
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          initialValues={initialValues}
          scrollToFirstError={true}
          style={{ paddingBottom: 20 }}
        >
          <Form.Item name="type">
            <Radio.Group buttonStyle="solid" className="light block" onChange={onShiftTypeChange}>
              <Radio.Button value="shift">{t('GLOBAL.SHIFT')}</Radio.Button>
              <Radio.Button value="absence">{t('GLOBAL.ABSENCE')}</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {shiftType === 'shift' && (
            <React.Fragment>
              <Form.Item name="start" className="hidden">
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                rules={[{ required: true, message: '' }]}
                name="start_time"
                label={t('GLOBAL.START')}
                start={shiftDay?.unix()}
                style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                changeTime={changeStartDate}
              />
              <Form.Item name="end" className="hidden">
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                rules={[{ required: true, message: '' }]}
                name="end_time"
                label={t('GLOBAL.END')}
                displayDuration={true}
                start={newStart || shiftDay?.unix()}
                style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                changeTime={changeEndDate}
              />
              <Form.Item label={t('GLOBAL.SHORTCUTS')} style={{ display: 'inline-block', width: '200px' }}>
                <Select
                  clearIcon
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  placeholder={t('GLOBAL.SHORTCUTS')}
                  optionFilterProp="children"
                  loading={loadingShortcuts}
                  disabled={loadingShortcuts}
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={changeShortcut}
                >
                  {shortcuts.map((shortcut) => (
                    <Option key={`shortcut_${shortcut.id}`} value={`${shortcut.id}`}>
                      {shortcut.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <br />
              <Form.Item name={['pause', 'paid']} className="hidden">
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                name={['pause', 'paid_time']}
                label={t('GLOBAL.PAID_BREAK')}
                style={{ display: 'inline-block', width: '110px', marginRight: '10px' }}
                maxHours={7}
                changeTime={changePaidPauseDate}
              />
              <Form.Item name={['pause', 'unpaid']} className="hidden">
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                name={['pause', 'unpaid_time']}
                label={t('GLOBAL.UNPAID_BREAK_SHORT')}
                style={{ display: 'inline-block', width: '110px', marginRight: '10px' }}
                maxHours={7}
                changeTime={changeUnpaidPauseDate}
              />
              {hrPartners.length > 0 && activeDepartment?.hr_codes && activeDepartment?.hr_codes.length > 0 && (
                <Form.Item name="hr_code" label={t('GLOBAL.HR_CODE')}>
                  <Select
                    getPopupContainer={(trigger) => trigger}
                    showSearch
                    placeholder={t('GLOBAL.HR_CODE')}
                    optionFilterProp="children"
                    loading={loadingHrPartners}
                    filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    allowClear
                  >
                    {activeDepartment?.hr_codes.map((hr_code) => (
                      <Option key={hr_code.id} value={`${hr_code.id}`}>
                        {hr_code.description}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {!!isFeatureEnabled(features, FEATURES.ATTRIBUTES) && !!resources?.length && (
                <Form.Item name="attributes" label={t('GLOBAL.ATTRIBUTES')}>
                  <Select
                    mode="multiple"
                    placeholder={t('FORMS.ATTRIBUTE_PLACEHOLDER')}
                    maxTagCount={3}
                    suffixIcon={<i className="icon-search" />}
                    disabled={loadingResources}
                    loading={loadingResources}
                    filterOption={(input, option) => {
                      if (option) {
                        if (!option.label) {
                          let children = option.children;
                          if (Array.isArray(children)) {
                            children = children.join();
                          }
                          return valueForSearch(children).includes(valueForSearch(input));
                        }
                      }
                      return false;
                    }}
                  >
                    {resources.map((resource = {}) => (
                      <OptGroup label={resource.name} key={`resource_${resource.id}`}>
                        {(resource.attributes || []).map((attribut) => (
                          <Option value={attribut.id!} key={`attribut_${attribut.id}`}>
                            {attribut.name}
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </React.Fragment>
          )}
          {shiftType === 'absence' && (
            <React.Fragment>
              <Form.Item
                name={['dayoff', 'dayOffType']}
                label={t('FORMS.ABSENCE_TYPE')}
                rules={[{ required: true, message: '' }]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  placeholder={t('FORMS.ABSENCE_TYPE_PLACEHOLDER')}
                  optionFilterProp="children"
                  loading={loadingDayoffs}
                  disabled={loadingDayoffs}
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {dayoffs.map((dayoff) => (
                    <Option value={dayoff.id!} key={`dayoff_${dayoff.id}`}>
                      {dayoff.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, display: 'inline-block' }}>
                <Form.Item
                  name={['dayoff', 'fullDay']}
                  valuePropName="checked"
                  style={{ marginBottom: 0, display: 'inline-block' }}
                >
                  <Checkbox style={{ lineHeight: '32px' }}>{t('GLOBAL.FULL_DAY')}</Checkbox>
                </Form.Item>
                <Form.Item
                  name={['dayoff', 'paid']}
                  valuePropName="checked"
                  style={{ marginBottom: 0, display: 'inline-block' }}
                >
                  <Checkbox style={{ lineHeight: '32px' }}>{t('GLOBAL.PAID_ABSENCE')}</Checkbox>
                </Form.Item>
              </Form.Item>
              <Form.Item className="form-item-should-update" style={{ marginBottom: 0 }}>
                <Form.Item name="start" className="hidden">
                  <InputNumber disabled={true} />
                </Form.Item>
                <TimePickerFormItem
                  rules={[{ required: true, message: '' }]}
                  name="start_time"
                  label={t('GLOBAL.START')}
                  start={shiftDay?.unix()}
                  style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                  changeTime={changeStartDate}
                />
                <Form.Item name="end" className="hidden">
                  <InputNumber disabled={true} />
                </Form.Item>
                <TimePickerFormItem
                  rules={[{ required: true, message: '' }]}
                  name="end_time"
                  label={t('GLOBAL.END')}
                  displayDuration={true}
                  start={newStart || shiftDay?.unix()}
                  style={{ display: 'inline-block', width: '75px', marginRight: '10px' }}
                  changeTime={changeEndDate}
                />
                <Form.Item
                  label={t('GLOBAL.SHORTCUTS')}
                  style={{ display: 'inline-block', width: '200px' }}
                  shouldUpdate={(prevValues, curValues) => prevValues.section !== curValues.section}
                >
                  <Select
                    clearIcon
                    getPopupContainer={(trigger) => trigger}
                    showSearch
                    placeholder={t('GLOBAL.SHORTCUTS')}
                    optionFilterProp="children"
                    loading={loadingShortcuts}
                    disabled={loadingShortcuts}
                    filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={changeShortcut}
                  >
                    {shortcuts.map((shortcut) => (
                      <Option key={`shortcut_${shortcut.id}`} value={`${shortcut.id}`}>
                        {shortcut.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item name="autoApprove" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>{t('GLOBAL.APPROVE')}</Checkbox>
              </Form.Item>
            </React.Fragment>
          )}
          <div className="actions-container">
            <div className="actions">
              <Button
                type="primary"
                danger
                onClick={() => {
                  setVisible(false);
                }}
              >
                {t('GLOBAL.CANCEL')}
              </Button>
              <Button loading={loading} type="primary" htmlType="submit">
                {t('SHIFTS.MODIFY_X_SHIFTS', { count: shiftIds.length })}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Drawer>
  );
};

const DrawerMultipleShiftsStyled = styled(DrawerMultipleShifts)`
  .hidden {
    display: none;
  }

  .ant-form-item-explain {
    display: none;
  }

  .form-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-select-item.ant-select-item-option.not-compatible:not(.ant-select-item-option-selected) {
    background: ${Colors.greyLight};
    color: ${Colors.grey};
  }

  .form-item-should-update {
    .ant-form-item-control-input {
      min-height: 0;
    }

    .ant-row.ant-form-item.min-height {
      .ant-form-item-control-input {
        min-height: 40px;
      }
    }
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
    .ant-select-item-option-content {
      .select-user-option {
        .infos  {
          opacity: 0.8;
        }
      }
    }
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    .ant-select-item-option-content {
      .select-user-option {
        .infos  {
          opacity: 1;
        }
      }
    }
  }
  .ant-select-item-option-content .select-user-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .displayName {
    }
    .infos {
      opacity: 0.3;
      transition: opacity 0.3s;
      span {
        background-color: ${colors.bluePastel};
        color: white;
        border-radius: 3px;
        margin-left: 4px;
        padding: 1px 5px;
      }
    }
  }
`;

export default DrawerMultipleShiftsStyled;

const initialValues = {
  type: 'shift',
  pause: {
    paid: 0,
    paid_time: null,
    unpaid: 0,
    unpaid_time: null,
  },
  dayoff: {
    paid: true,
  },
};

function removeUnnecessaryProperties(object: any): any {
  delete object.end_time;
  delete object.start_time;
  delete object.type;

  if (object.pause) {
    delete object.pause.paid_time;
    delete object.pause.unpaid_time;
  }

  if (object.end && object.start) {
    if (object.end < object.start) {
      object.end = object.end + 86400;
    }
  }

  return object;
}
