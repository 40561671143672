import AppContext from '@/pages/app/context';
import { IPayPeriod } from '@/types/pay-periods/pay-period.model';
import { capitalize, handleError } from '@/utils';
import { List, Modal, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  period: IPayPeriod | null;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setPayPeriodToDelete: React.Dispatch<React.SetStateAction<IPayPeriod | null>>;
  getPayPeriods: () => void;
}

const ModalDeletePayPeriod: React.FC<Props> = ({
  className,
  period,
  visible,
  setVisible,
  setPayPeriodToDelete,
  getPayPeriods,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, userCategories },
    dispatch,
  } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);

  const month = period && `${capitalize(moment(period.month).format('MMMM'))} ${moment(period.month).format('YYYY')}`;
  const filteredStatus = period && userCategories.filter((status) => period.status.includes(status.id!));

  const onCancel = () => {
    setVisible(false);
    setPayPeriodToDelete(null);
  };

  const onDelete = () => {
    if (!period) return;

    setLoading(true);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/pay-periods/${period.id}`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        message.success(t('REPORTS.PAY_PERIODS.DELETED_MESSAGE'));
        getPayPeriods();
        onCancel();
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      className={`${className} modal-danger`}
      visible={visible}
      onCancel={onCancel}
      onOk={onDelete}
      maskClosable
      okText={t('GLOBAL.DELETE')}
      title={t('REPORTS.PAY_PERIODS.MODAL_DELETE.TITLE', { date: month })}
      confirmLoading={loading}
    >
      <h3>{t('REPORTS.PAY_PERIODS.MODAL_DELETE.DESCRIPTION')}</h3>
      {period && filteredStatus && (
        <List className="content">
          <List.Item key="start">
            <List.Item.Meta title="Start" />
            <div>
              {moment(period.start).format('DD')} {t(`GLOBAL.MONTHS.${moment(period.start).month() + 1}`).toLowerCase()}{' '}
              {moment(period.start).format('YYYY')}
            </div>
          </List.Item>
          <List.Item key="end">
            <List.Item.Meta title="End" />
            <div>
              {moment(period.end).format('DD')} {t(`GLOBAL.MONTHS.${moment(period.end).month() + 1}`).toLowerCase()}{' '}
              {moment(period.end).format('YYYY')}
            </div>
          </List.Item>
          <List.Item key="status">
            <List.Item.Meta title="Status" />
            <div>{filteredStatus.map((status) => status.name).join(', ')}</div>
          </List.Item>
        </List>
      )}
    </Modal>
  );
};

export default styled(ModalDeletePayPeriod)`
  .content {
    margin-top: 15px;
  }
`;
