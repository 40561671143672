import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Form, Checkbox, Button, InputNumber, Select, Radio, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import colors from '@/styles/colors';
import { ORDINAL_NUMBERS } from '@/utils';

const { Option } = Select;

interface Props {
  className?: string;
  visible: boolean;
  recurrence: any;
  setRecurrence: React.Dispatch<React.SetStateAction<false>>;
  shiftDay?: moment.Moment;
  onClose: () => void;
}

const ModalShiftRecurrence: React.FC<Props> = ({
  className,
  recurrence,
  setRecurrence,
  shiftDay,
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...recurrence,
      days: recurrence?.days || [`${shiftDay?.isoWeekday()}`],
    });
  }, [recurrence, visible, shiftDay]);

  const onRepeatEndChange = (e: any) => {
    if (e.target.value == 'DATE') {
      form.setFieldsValue({
        end: moment().weekday(6),
      });
    }
  };

  const onFinish = (values: any) => {
    setRecurrence({
      ...values,
    });
    onClose();
  };

  return (
    <Modal
      className={className}
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={visible}
      onOk={() => form.submit()}
      onCancel={onClose}
      title={t('SHIFTS.MODAL_SHIFT_RECURRENCE.TITLE')}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          {t('GLOBAL.VALIDATE')}
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        initialValues={{
          repeatFrequency: 1,
          repeatOn: 'WEEK',
          repeatEnd: 'OCCURRENCE',
          occurrenceCounter: 1,
        }}
      >
        {/* <div className="form-items-group one-line">
          <span>{t('SHIFTS.MODAL_SHIFT_RECURRENCE.REPEAT_EVERY')}</span>
          <Form.Item name={'repeatFrequency'} className="one-line">
            <InputNumber style={{ width: 50 }} type="number" min={1} step={1} />
          </Form.Item>
          <Form.Item name={'repeatOn'} className="one-line">
            <Select style={{ width: 120 }}>
              <Option value="WEEK">{t('GLOBAL.WEEK(S)')}</Option>
              <Option value="MONTH">{t('GLOBAL.MONTH(S)')}</Option>
            </Select>
          </Form.Item>
        </div> */}
        <Form.Item
          shouldUpdate={(prevValues, curValues) => prevValues.repeatOn !== curValues.repeatOn}
          style={{ margin: 0 }}
        >
          {(form) => {
            const repeatOn = form.getFieldValue('repeatOn');
            if (repeatOn === 'WEEK') {
              return (
                <Form.Item
                  label={t('SHIFTS.MODAL_SHIFT_RECURRENCE.REPEAT_ON')}
                  name="days"
                  rules={[{ required: true }]}
                  className="checkbox-days"
                >
                  <Checkbox.Group style={{ margin: '10px 0 0' }}>
                    {moment.weekdaysShort(true)?.map((day: string, index: number) => (
                      <Checkbox
                        key={`day_${index}`}
                        disabled={index + 1 === shiftDay?.isoWeekday()}
                        value={`${index + 1}`}
                      >
                        {day?.substring(0, 1).toLocaleUpperCase()}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              );
            } else if (repeatOn === 'MONTH' && shiftDay) {
              const date = shiftDay.date();
              const position = Math.ceil(date / 7);
              form.setFieldsValue({
                day: `${date}`,
              });
              return (
                <Form.Item name="day" rules={[{ required: true }]}>
                  <Select>
                    <Option value={`${date}`}>
                      {t('SHIFTS.MODAL_SHIFT_RECURRENCE.MONTHLY_ON_DATE', { number: date })}
                    </Option>
                    <Option value={`${position}_${shiftDay.isoWeekday()}`}>
                      {t('SHIFTS.MODAL_SHIFT_RECURRENCE.MONTHLY_ON_ORDINAL', {
                        ordinal: t(`GLOBAL.${ORDINAL_NUMBERS[position]}`),
                        weekday: shiftDay.format('dddd'),
                      })}
                    </Option>
                  </Select>
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Form.Item name={'repeatEnd'} label={t('SHIFTS.MODAL_SHIFT_RECURRENCE.ENDS')}>
          <Radio.Group onChange={onRepeatEndChange}>
            <Radio value="DATE">
              <Form.Item
                label={t('SHIFTS.MODAL_SHIFT_RECURRENCE.ON')}
                className="one-line"
                shouldUpdate={(prevValues, curValues) => prevValues.repeatEnd !== curValues.repeatEnd}
              >
                {(form) => {
                  const active = form.getFieldValue('repeatEnd') === 'DATE';
                  if (active) {
                    return (
                      <Form.Item name="end" rules={[{ required: active, message: '' }]}>
                        <DatePicker
                          style={{ width: '100%' }}
                          format={'L'}
                          onSelect={(e) => {
                            form.setFieldsValue({
                              end: e,
                            });
                          }}
                        />
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>
            </Radio>
            <Radio value="OCCURRENCE">
              <Form.Item
                label={t('SHIFTS.MODAL_SHIFT_RECURRENCE.AFTER')}
                className="one-line"
                shouldUpdate={(prevValues, curValues) => prevValues.repeatEnd !== curValues.repeatEnd}
              >
                {(form) => {
                  const active = form.getFieldValue('repeatEnd') === 'OCCURRENCE';
                  if (active) {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Item
                          name="occurrenceCounter"
                          className="one-line"
                          rules={[{ required: active, message: '' }]}
                        >
                          <InputNumber type="number" min={1} step={1} />
                        </Form.Item>
                        <span style={{ marginLeft: 8 }}>
                          {t('SHIFTS.MODAL_SHIFT_RECURRENCE.OCCURRENCE(S)').toLocaleLowerCase()}
                        </span>
                      </div>
                    );
                  }
                }}
              </Form.Item>
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="approved" valuePropName="checked">
          <Checkbox>{t('SHIFTS.MODAL_SHIFT_RECURRENCE.AUTO_APPROVE_SHIFTS')}</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalShiftRecurrence)`
  .checkbox-days  {
    .ant-checkbox {
      display: none;
    }
    .ant-checkbox-wrapper {
      width: 30px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      background: ${colors.greyLight};
      color: ${colors.grey};
      &.ant-checkbox-wrapper-checked {
        background: ${colors.green};
        color: white;
      }
    }
  }

  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .ant-form-item-label {
      padding: 0;
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      display: none;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;
