import AppContext from '@/pages/app/context';
import { IUser } from '@/types/user.model';
import { handleError } from '@/utils';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, Upload, message } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { ColumnsType } from 'antd/lib/table';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  user?: IUser;
}

const safeMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/x-eps',
  'image/vnd.adobe.photoshop',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/plain',
  'application/zip',
];

const FilesList: React.FC<Props> = ({ className, user }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, users },
    dispatch,
  } = useContext(AppContext);
  const [userFiles, setUserFiles] = useState<any[]>([]);
  const [loadingUserFiles, setLoadingUserFiles] = useState<boolean>(false);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [loadingFileDelete, setLoadingFileDelete] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [columns, setColumns] = useState<ColumnsType<any>>([]);

  useEffect(() => {
    setColumns([
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
      },
      {
        title: t('GLOBAL.FILE_NAME'),
        key: 'filename',
        dataIndex: 'filename',
      },
      {
        title: t('GLOBAL.UPLOADED_BY'),
        key: 'created_by',
        dataIndex: 'created_by',
        render: (_, record: any) => {
          const user = users.find((user) => user.mainId == record.created_by);
          if (!user) return;
          return (
            <a href={`/app/team/collaborators/${user.recordId}/dashboard`} style={{ textDecoration: 'underline' }}>
              {user.displayName || `${user.firstname} ${user.lastname}`}
            </a>
          );
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'actions',
        dataIndex: 'actions',
        render: (_, record: any) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <Button type="ghost">
                <a href={record.url} download target="_blank">
                  {t('GLOBAL.DOWNLOAD')}
                </a>
              </Button>
              <Popconfirm
                title={t('GLOBAL.ARE_YOU_SURE?')}
                okText={t('GLOBAL.YES')}
                cancelText={t('GLOBAL.NO')}
                onConfirm={() => onDelete(record.id)}
              >
                <Button type="primary" danger loading={record.id == loadingFileDelete}>
                  {t('GLOBAL.DELETE')}
                </Button>
              </Popconfirm>
            </div>
          );
        },
      },
    ]);
  }, [loadingFileDelete, users]);

  useEffect(() => {
    getUserFiles();
  }, [user]);

  useEffect(() => {
    if (files.length == 0) return;
    uploadFiles();
  }, [files]);

  const onDelete = (fileId: string) => {
    setLoadingFileDelete(fileId);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/custom-files/${fileId}`)
      .then(({ data }) => {
        getUserFiles();
        message.success(t('USERS.CUSTOM_FILE_DELETED'));
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoadingUserFiles(false);
        setLoadingFileDelete('');
      });
  };

  const getUserFiles = () => {
    setLoadingUserFiles(false);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/custom-files`)
      .then(({ data }) => {
        setUserFiles(data);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoadingUserFiles(false);
      });
  };

  const uploadFiles = () => {
    setLoadingUpload(true);
    const formData = new FormData();

    files.map((file) => {
      formData.append('files[]', file);
    });

    axios({
      url: `${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/custom-files`,
      method: 'POST',
      data: formData,
      params: {
        departmentId: activeDepartmentId,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        getUserFiles();
        message.success(t('USERS.CUSTOM_FILES_UPLOADED'));
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setFiles([]);
        setLoadingUpload(false);
      });
  };

  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    setFiles(files);
    return false;
  };

  return (
    <div className={className}>
      <Upload multiple beforeUpload={beforeUpload} showUploadList={false} maxCount={5} accept={safeMimeTypes.join(',')}>
        <Button type="primary" size="large" loading={loadingUpload}>
          <UploadOutlined />
          <span>{t('GLOBAL.UPLOAD')}</span>
        </Button>
      </Upload>
      <Table
        columns={columns}
        dataSource={userFiles}
        loading={loadingUserFiles}
        rowKey="id"
        style={{ marginTop: 15 }}
      />
    </div>
  );
};

export default styled(FilesList)``;
