import AppContext from '@/pages/app/context';
import { handleError } from '@/utils';
import { Button, Form, InputNumber, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import Chartjs from 'chart.js';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { V4DailyTurnover } from '../types/daily-turnover.types';

interface Props {
  className?: string;
  dailyTurnover: V4DailyTurnover | null;
  chartRef: React.MutableRefObject<HTMLCanvasElement | null>;
  startDate: Moment;
  loadingDailyTurnover: boolean;
  setDailyTurnover: React.Dispatch<React.SetStateAction<V4DailyTurnover | null>>;
}

const DailyTurnoverForm: React.FC<Props> = ({
  className,
  dailyTurnover,
  chartRef,
  loadingDailyTurnover,
  startDate,
  setDailyTurnover,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  const [form] = useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (!dailyTurnover) return;
    setFieldValues(dailyTurnover);
  }, [dailyTurnover]);

  const setFieldValues = (dailyTurnover: V4DailyTurnover) => {
    const { lastYear, provisional, real } = dailyTurnover;
    form.setFieldsValue({ real, provisional });
    new Chartjs(chartRef.current!, {
      type: 'line',
      data: {
        datasets: [
          {
            label: moment(startDate).subtract(1, 'year').format('YYYY'),
            data: Object.entries(lastYear).map((data: any) => ({ x: moment(data[0]).add(1, 'year'), y: data[1] })),
            backgroundColor: 'rgba(255,0,0,0.3)',
            fill: 'start',
          },
          {
            label: startDate.format('YYYY'),
            data: Object.entries(real).map((data: any) => ({ x: moment(data[0]), y: data[1] })),
            backgroundColor: 'rgba(0,0,255,0.3)',
            fill: 'start',
          },
        ],
      },
      options: {
        spanGaps: true,
        maintainAspectRatio: false,
        responsive: true,
        elements: {
          line: {
            tension: 0.5,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'day',
              },
            },
          ],
        },
      },
    });
    setFormHasChanged(false);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    axios
      .patch(`${process.env.REACT_APP_API_URL_V4}/insights`, values, {
        params: {
          departmentId: activeDepartmentId,
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: startDate.clone().endOf('month').format('YYYY-MM-DD'),
        },
      })
      .then(({ data }) => {
        setDailyTurnover(data);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <Spin spinning={loadingDailyTurnover}>
        <Form form={form} onFinish={onFinish} onValuesChange={() => setFormHasChanged(true)} size="large">
          {dailyTurnover &&
            Object.keys(dailyTurnover.provisional).map((date) => {
              const day = moment(date);
              return (
                <div key={date} className="day">
                  <span className="L">{day.format('L')}</span>
                  <span className="weekday">{day.format('dddd')}</span>
                  <Form.Item name={['provisional', date]}>
                    <InputNumber type="number" />
                  </Form.Item>
                  <Form.Item name={['real', date]}>
                    <InputNumber type="number" />
                  </Form.Item>
                </div>
              );
            })}
          <Form.Item className={`actions ${formHasChanged ? 'active' : ''}`}>
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
              {t('GLOBAL.SAVE')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default styled(DailyTurnoverForm)`
  form {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    .day {
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:nth-of-type(odd) {
        padding-right: 20px;
      }
      &:nth-of-type(even) {
        padding-left: 20px;
      }
      .L {
        width: 100px;
      }
      .weekday {
        width: 80px;
        text-align: right;
        margin-right: 10px;
      }
      .ant-row.ant-form-item {
        flex: 1;
        margin-bottom: 0;
        width: 100%;
        &:first-of-type {
          margin-right: 5px;
        }
        &:nth-of-type(2) {
          margin-left: 5px;
        }
        .ant-input-number.ant-input-number-lg {
          width: 100%;
        }
      }
    }
    > .actions {
      position: fixed;
      width: 100%;
      max-width: 1024px;
      background: white;
      bottom: -80px;
      margin: 0;
      display: flex;
      box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
      padding: 10px;
      border-radius: 3px 3px 0 0;
      transition: bottom 0.2s;
      z-index: 2;

      &.active {
        transition-timing-function: ease-in;
        bottom: 0px;
      }

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
