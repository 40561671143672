import colors from '@/styles/colors';
import { Form, message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useContext, useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';

interface Props {
  currentSettings?: IDepartmentSettings | null;
}

const POSLogo: React.FC<Props> = ({ currentSettings }) => {
  const {
    state: { activeDepartment, activeDepartmentId },
  } = useContext(AppContext);
  const [filesList, setFilesList] = useState<any[]>([]);

  useEffect(() => {
    if (currentSettings?.pos?.logo) {
      setFilesList([
        {
          id: '-1',
          url: currentSettings.pos.logo,
        },
      ]);
    }
  }, [currentSettings]);

  const pictureCustomRequest = (options: any) => {
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append('file', file);

    const url = `${process.env.REACT_APP_API_URL}/v3/departments/${activeDepartmentId}/pos-picture`;

    axios({
      method: 'POST',
      url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        const { url, status } = response.data;

        setFilesList([
          {
            ...file,
            url,
            status,
          },
        ]);
        onSuccess(response, file);
      })
      .catch((error) => {
        message.error(`La photo n'as pas pu être téléversée`);
        onError(error);
        console.log(error);
      });
  };

  const onPictureRemove = async (e: any) => {
    return new Promise<boolean>((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}/v3/departments/${activeDepartmentId}/pos-picture`;

      axios
        .delete(url)
        .then(() => {
          resolve(true);
          setFilesList([]);
        })
        .catch((error) => {
          message.error(`La photo n'as pas pu être supprimée`);
          console.error(error);
          reject(false);
        });
    });
  };

  return (
    <ImgCrop>
      <Upload
        listType="picture-card"
        customRequest={(e) => pictureCustomRequest(e)}
        onRemove={(e) => onPictureRemove(e)}
        fileList={filesList}
        accept="image/png"
      >
        {filesList.length < 1 && <UploadOutlined />}
      </Upload>
    </ImgCrop>
  );
};

export default POSLogo;
