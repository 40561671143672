import colors from '@/styles/colors';
import { minutesToHoursAndOrMinutes } from '@/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  totalMinutes?: number;
  hrTotalMinutes?: number;
}

const TypicalWeekSubHeader: React.FC<Props> = ({ className, totalMinutes, hrTotalMinutes }) => {
  const {
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: false });
  const [days, setDays] = useState<string[]>([]);
  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(language, {
      week: {
        dow: 1,
      },
    });
    setDays(moment.weekdays(true));
  }, [language]);

  return (
    <div className={className}>
      <div className="left">
        {(!!totalMinutes || !!hrTotalMinutes) && (
          <div className="statistics">
            {!!totalMinutes ? minutesToHoursAndOrMinutes(totalMinutes) : '0'}
            {!!hrTotalMinutes ? ` / ${minutesToHoursAndOrMinutes(hrTotalMinutes)}` : null}
          </div>
        )}
      </div>
      <div className="right">
        {days.map((day) => {
          return (
            <div className="day" key={day}>
              <div className="date">{day}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default styled(TypicalWeekSubHeader)`
  display: flex;
  position: relative;
  border-bottom: 1px solid ${colors.blueLight};
  height: 70px;
  background: #f7f9ff;
  border-top: 1px solid ${colors.blueLight};
  border-bottom: 1px solid ${colors.blueLight};
  z-index: 11;

  > div {
    &.left {
      background: #f7f9ff;
      flex-shrink: 0;
      position: sticky;
      left: 0;
      border-right: 1px solid ${colors.blueLight};
      width: 210px;
      padding: 10px;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;

      .statistics {
        border-radius: 3px;
        padding: 2px 6px;
        font-size: 12px;
        display: inline-block;
        font-size: 16px;
        background: ${colors.bluePastel};
        color: white;
      }
    }

    &.right {
      display: flex;
      flex-grow: 1;

      > .day {
        padding: 10px;
        height: 100%;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 0px;
        flex-shrink: 0;
        position: relative;

        &:nth-child(n + 2) {
          border-left: 1px solid ${colors.blueLight};
        }
      }
    }
  }
`;
