import { IShift } from '@/types/shift.model';
import { createStore } from 'redux';
import reducer from './reducer';
import moment from 'moment';
import 'moment-timezone';
import { ISection } from '@/types/section.model';
import { ISkill } from '@/types/skill.model';

moment.tz.setDefault('Atlantic/Reykjavik');

export interface ITypicalWeekShift extends IShift {
  scheduleModelId?: string;
  typicalWeekIndex?: number;
  weekId: string;
}

export interface IDay {
  index: number;
  shifts: ITypicalWeekShift[];
}
export interface IWeek {
  id: string;
  days: IDay[];
  totalMinutes: number;
}

export type InitialStateType = {
  selectedShifts: Set<string>;
  shiftDrawerVisible: boolean;
  weeks: IWeek[];
  indexMapByShiftId: Map<string, [number, number, number]>;
  selectedShift: ITypicalWeekShift | null;
  shiftIdsToDelete: string[] | null;
  scheduleModelList: { name: string; id: string }[];
  sections: ISection[];
  skills: ISkill[];
  isLoading: boolean;
  clipboardHistory: ITypicalWeekShift | null;
};

export const initialState: InitialStateType = {
  selectedShifts: new Set<string>(),
  shiftDrawerVisible: false,
  weeks: [],
  indexMapByShiftId: new Map<string, [number, number, number]>(),
  selectedShift: null,
  shiftIdsToDelete: null,
  scheduleModelList: [],
  sections: [],
  skills: [],
  isLoading: false,
  clipboardHistory: null,
};

export const store = createStore(
  reducer,
  initialState,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
);
