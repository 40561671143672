import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const WelcomeModal: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { t } = useTranslation();
  const onCancel = () => {
    location.href = '/app/dashboard';
    setVisible(false);
  };

  return (
    <Modal
      className={className}
      forceRender={true}
      destroyOnClose={true}
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <div className="container">
        <h1 style={{ fontWeight: 'bold' }}>{t('DASHBOARD.WELCOME_MODAL.TITLE')}</h1>
        <p>{t('DASHBOARD.WELCOME_MODAL.CONTENT')}</p>
        <p>{t('DASHBOARD.WELCOME_MODAL.SUBCONTENT')}</p>
        <p>{t('DASHBOARD.WELCOME_MODAL.OUTRO')}</p>
      </div>
    </Modal>
  );
};

export default styled(WelcomeModal)`
  .ant-modal-body {
    padding-top: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
