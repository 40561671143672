import AppContext from '@/pages/app/context';
import Colors from '@/styles/colors';
import { IShift } from '@/types/shift.model';
import { isNullOrUndefined } from '@/utils';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHourlyDispatch, useHourlySelector } from '..';
import { ActionType, updateShiftAndContext } from '../../redux/actions';
import { InitialStateType } from '../../redux/store';
import { ActionType as HourlyActionType } from '../redux/actions';
import { InitialStateType as HourlyInitialStateType } from '../redux/store';
import Shift from './Shift';
import ShiftsBackground from './ShiftsBackground';
import Unvailability from './Unvailability';
interface Props {
  className?: string;
  rowIndex: number;
  userRecordId: string | null;
  shifts: IShift[];
  height: number;
  taskHeight: number;
  hideShifts?: boolean;
  hourlyRate?: number;
  totalShifts?: number[];
  style?: React.CSSProperties;
  columnWidth: number;
  refPageContainer: React.MutableRefObject<null> | null;
  refDraggedShift: React.MutableRefObject<any>;
  refDraggedShiftData: React.MutableRefObject<any>;
}

const Shifts: React.FC<Props> = ({
  className,
  rowIndex,
  userRecordId,
  hideShifts,
  shifts,
  height,
  taskHeight,
  hourlyRate,
  totalShifts,
  style,
  columnWidth,
  refPageContainer,
  refDraggedShift,
  refDraggedShiftData,
}) => {
  const {
    state: { activeDepartmentId, activeDepartment, activeSection, skills, userCategories },
  } = useContext(AppContext);

  const { shiftIsDragging } = useHourlySelector(
    ({ shiftIsDragging }: HourlyInitialStateType) => ({
      shiftIsDragging,
    }),
    shallowEqual,
  );

  const hourlyDispatch: Dispatch<HourlyActionType> = useHourlyDispatch();

  const { selectedDate, startDate, endDate, picker, hours, shiftsByUsersMapWithShiftId, shiftsByUsers } = useSelector(
    ({
      selectedDate,
      startDate,
      endDate,
      picker,
      hours,
      shiftsByUsersMapWithShiftId,
      shiftsByUsers,
    }: InitialStateType) => ({
      hours,
      startDate,
      endDate,
      picker,
      shiftsByUsersMapWithShiftId,
      shiftsByUsers,
      selectedDate,
    }),
    shallowEqual,
  );
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const [shiftsRows, setShiftRows] = useState<IShift[][]>([]);
  const [activeShiftDraggingId, setActiveShiftDraggingId] = useState<string | null>(null);
  const [newStart, setNewStart] = useState<number>(0);
  const [newEnd, setNewEnd] = useState<number>(0);

  useEffect(() => {
    const shiftsRows: IShift[][] = [];
    shiftsRows.push([]);
    for (let shiftIdx = 0; shiftIdx < shifts.length; shiftIdx++) {
      const shift = shifts[shiftIdx];
      const { start, end } = shift;
      for (let rowIdx = 0; rowIdx < shiftsRows.length; rowIdx++) {
        const row = shiftsRows[rowIdx];
        let isSuitableForCurrentShift = true;
        for (let limitsIdx = 0; limitsIdx < row.length; limitsIdx++) {
          const { start: limitStart, end: limitEnd } = row[limitsIdx];
          if (limitStart! > start! && limitStart! < end!) {
            isSuitableForCurrentShift = false;
            break;
          }
          if (limitEnd! > start! && limitEnd! < end!) {
            isSuitableForCurrentShift = false;
            break;
          }
          if (start! > limitStart! && start! < limitEnd!) {
            isSuitableForCurrentShift = false;
            break;
          }
          if (end! > limitStart! && end! < limitEnd!) {
            isSuitableForCurrentShift = false;
            break;
          }
          if (start === limitStart && end === limitEnd) {
            isSuitableForCurrentShift = false;
            break;
          }
        }
        if (isSuitableForCurrentShift) {
          shiftsRows[rowIdx].push(shift);
          break;
        } else if (!isSuitableForCurrentShift && rowIdx === shiftsRows.length - 1) {
          shiftsRows.push([]);
        }
      }
    }
    setShiftRows(shiftsRows);
  }, [shifts, userRecordId]);

  const onShiftDragEnd = (e: React.DragEvent) => {
    const target = e.target as HTMLElement;
    target.classList.remove('isMoving');
    target.classList.remove('shift-drag-enter');
    if (refDraggedShift.current?.parentNode?.parentNode?.parentNode) {
      (refDraggedShift.current as HTMLDivElement).parentNode!.parentNode!.parentNode!.parentElement!.style!.zIndex =
        '1';
      (refDraggedShift.current! as HTMLDivElement).style.transform = 'none';
      refDraggedShift.current = null;
      refDraggedShiftData.current = null;
    }
    hourlyDispatch({
      type: 'SET_SHIFT_IS_DRAGGING',
      payload: false,
    });
  };

  const onShiftDrop = (e: React.DragEvent) => {
    setActiveShiftDraggingId(null);
    const scrollLeft = refPageContainer ? (refPageContainer!.current! as HTMLElement).scrollLeft : 0;
    const left = e.pageX - 225 + scrollLeft;
    const columnIndex = Math.round(left / (columnWidth / 4));
    const columnClicked = +e.dataTransfer.getData('columnClicked');
    const fullDay = e.dataTransfer.getData('fullDay') === 'TRUE';
    const difference = fullDay ? 0 : columnIndex - columnClicked;

    const source = {
      shiftId: e.dataTransfer.getData('shiftId'),
      userRecordId: e.dataTransfer.getData('userRecordId') === 'null' ? null : e.dataTransfer.getData('userRecordId'),
    };

    if (difference === 0 && `${userRecordId}` === source.userRecordId) {
      return;
    }

    const shiftsByUsersMapWithShiftIdResult = shiftsByUsersMapWithShiftId.get(source.shiftId);
    if (!shiftsByUsersMapWithShiftIdResult) {
      return console.log(`Shift not found ${JSON.stringify(source)}`);
    }

    const { userIndex, shiftIndex } = shiftsByUsersMapWithShiftIdResult;
    const shift = shiftsByUsers[userIndex].shifts[shiftIndex];

    const newStart = moment
      .unix(shift.start!)
      .add(difference * 15, 'minutes')
      .unix();
    const newEnd = moment
      .unix(shift.end!)
      .add(difference * 15, 'minutes')
      .unix();

    const oldValues: IShift = {
      id: shift.id,
      start: shift.start,
      end: shift.end,
    };

    const newValues: IShift = {
      id: shift.id,
      start: newStart,
      end: newEnd,
    };

    if (userRecordId !== source.userRecordId) {
      oldValues.userRecordId = source.userRecordId;
      newValues.userRecordId = userRecordId;
    }

    if (shift.tasks && shift.tasks.length) {
      oldValues.tasks = shift.tasks.map((task) => ({
        ...task,
      }));
      const difference = newStart - shift.start!;
      newValues.tasks = shift.tasks.map((task) => ({
        ...task,
        start: task.start! + difference,
        end: task.end! + difference,
      }));
    }

    updateShiftAndContext(hoursDispatch, oldValues, newValues, {
      picker,
      startDate,
      endDate: endDate.unix(),
      departmentId: activeDepartmentId,
      sectionId: activeSection,
      department: activeDepartment,
      activeSection,
      skills,
      userStatus: userCategories,
    });

    onShiftDragEnd(e);
  };

  const onShiftDragStart = (
    e: React.DragEvent,
    shift: IShift,
    openShiftRowIndex: number,
    openShiftRowLength: number,
  ) => {
    //e.dataTransfer.setDragImage(document.createElement('div'), 10, 10);
    e.dataTransfer.setData('shiftId', `${shift.id}`);
    e.dataTransfer.setData('rowIndex', `${rowIndex}`);
    e.dataTransfer.setData('userRecordId', `${userRecordId}`);
    e.dataTransfer.setData('fullDay', shift?.dayoff?.fullDay ? 'TRUE' : 'FALSE');
    setActiveShiftDraggingId(`${shift.id}`);

    e.dataTransfer.dropEffect = 'none';

    const target = e.target as HTMLElement;
    const scrollLeft = refPageContainer ? (refPageContainer!.current! as HTMLElement).scrollLeft : 0;
    const columnClicked = Math.trunc((e.pageX - 225 + scrollLeft) / (columnWidth / 4));
    const columnStarted = Math.trunc(target.offsetLeft / (columnWidth / 4));
    e.dataTransfer.setData('column', `${columnStarted}`);
    e.dataTransfer.setData('columnClicked', `${columnClicked}`);

    refDraggedShift.current = target;

    setTimeout(() => {
      target.parentNode!.parentNode!.parentNode!.parentElement!.style!.zIndex = '9998';
      target.classList.add('shift-drag-enter');
    }, 0);

    refDraggedShiftData.current = {
      shiftId: shift.id,
      rowIndex,
      userRecordId,
      fullDay: shift?.dayoff?.fullDay,
      columnStarted,
      columnClicked,
      openShiftRowIndex: !isNullOrUndefined(openShiftRowIndex) ? openShiftRowIndex : null,
      openShiftRowLength: !isNullOrUndefined(openShiftRowLength) ? openShiftRowLength : null,
    };
    setTimeout(() => {
      hourlyDispatch({
        type: 'SET_SHIFT_IS_DRAGGING',
        payload: true,
      });
    }, 0);
  };

  const onDragOver = (e: React.DragEvent) => {
    if (refDraggedShift.current) {
      const scrollLeft = refPageContainer ? (refPageContainer!.current! as HTMLElement).scrollLeft : 0;
      const left = e.pageX - 225 + scrollLeft;
      const columnIndex = Math.round(left / (columnWidth / 4));
      const {
        columnClicked,
        fullDay,
        rowIndex: _rowIndex,
        openShiftRowIndex = null,
        openShiftRowLength = null,
      } = refDraggedShiftData.current;
      const differenceRowIndex = rowIndex - _rowIndex;
      const difference = fullDay ? 0 : columnIndex - columnClicked;

      let affixHeight = activeDepartment?.scheduleParams?.enableProvisional ? 83 : 40;
      if (differenceRowIndex && _rowIndex === 0) {
        affixHeight += (openShiftRowLength - openShiftRowIndex - 1) * height;
      }

      const translateY =
        differenceRowIndex && rowIndex === 0
          ? differenceRowIndex * height - affixHeight
          : differenceRowIndex && _rowIndex === 0
          ? differenceRowIndex * height + affixHeight
          : differenceRowIndex * height;
      (refDraggedShift.current as HTMLDivElement).style.transform = `translate(${
        difference * (columnWidth / 4)
      }px, ${translateY}px)`;

      const source = {
        shiftId: (refDraggedShift.current as HTMLDivElement).id,
        userRecordId: shifts.find((shift) => shift.id == (refDraggedShift.current as HTMLDivElement).id)?.userRecordId,
      };

      if (difference === 0 && `${userRecordId}` === source.userRecordId) {
        return;
      }

      const shiftsByUsersMapWithShiftIdResult = shiftsByUsersMapWithShiftId.get(source.shiftId);
      if (!shiftsByUsersMapWithShiftIdResult) {
        return console.log(`Shift not found ${JSON.stringify(source)}`);
      }

      const { userIndex, shiftIndex } = shiftsByUsersMapWithShiftIdResult;
      const shift = shiftsByUsers[userIndex].shifts[shiftIndex];
      const newStart = moment
        .unix(shift.start!)
        .add(difference * 15, 'minutes')
        .unix();
      const newEnd = moment
        .unix(shift.end!)
        .add(difference * 15, 'minutes')
        .unix();
      setNewStart(newStart);
      setNewEnd(newEnd);
    }
    setActiveShiftDraggingId((refDraggedShift.current as HTMLDivElement).id);
    e.preventDefault();
  };

  return (
    <div className={className}>
      <ShiftsBackground
        selectedDate={selectedDate}
        hours={hours.map((x) => x.date)}
        hideShifts={hideShifts!}
        userRecordId={userRecordId!}
        totalShifts={totalShifts}
        activeSection={activeSection}
      />
      {!hideShifts && (
        <React.Fragment>
          {shiftsRows.map((row, rowIdx) => {
            const containsTasks = row.some((shift) => shift.tasks && shift.tasks.length > 0);
            return (
              <div
                className={`shifts`}
                key={`row_${rowIdx}`}
                style={{
                  ...style,
                  width: `${columnWidth * hours.length}px`,
                  height: containsTasks ? `${height}px` : '70px',
                }}
              >
                {row.map((shift) => {
                  if (shift.shyftType === 3) {
                    return <Unvailability key={`shift_${shift.id}`} shift={shift} columnWidth={columnWidth} />;
                  } else {
                    return (
                      <Tooltip
                        id={`tooltip-${shift.id}`}
                        overlay={
                          <span>
                            {moment.unix(newStart).format('HH:mm')} - {moment.unix(newEnd).format('HH:mm')}
                          </span>
                        }
                        visible={activeShiftDraggingId == shift.id}
                      >
                        <Shift
                          key={`shift_${shift.id}`}
                          shift={shift}
                          taskHeight={taskHeight}
                          userRecordId={userRecordId}
                          onShiftDragStartHandler={(e, shift) => onShiftDragStart(e, shift, rowIdx, shiftsRows.length)}
                          onShiftDragEndHandler={onShiftDragEnd}
                          columnWidth={columnWidth}
                          hourlyRate={hourlyRate}
                          refPageContainer={refPageContainer}
                          activeSection={activeSection}
                          activeDepartment={activeDepartment}
                        />
                      </Tooltip>
                    );
                  }
                })}
              </div>
            );
          })}
        </React.Fragment>
      )}
      {!!shiftIsDragging && (
        <div className="droppable-zone" style={{ zIndex: 99999 }} onDrop={onShiftDrop} onDragOver={onDragOver} />
      )}
    </div>
  );
};

const ShiftsStyled = styled(Shifts)`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;

  .background {
    position: absolute;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;

    > .hour {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 0px;
      flex-shrink: 0;
      box-sizing: border-box;
      flex-direction: column;
      padding: 10px;
      border-bottom: 1px solid ${Colors.blueLight};

      &:nth-child(n + 2) {
        border-left: 1px solid ${Colors.blueLight};
      }
    }
  }

  .shifts {
    /* overflow: hidden; */
    pointer-events: none;
    position: relative;
    padding: 5px 0;
    display: block;
    width: 100%;
    > div {
      pointer-events: all;
    }
  }

  .preview-shift {
    overflow: hidden;
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    padding: 5px 0;
    display: grid;
    grid-template-rows: repeat(1, 1fr);
  }

  .droppable-zone {
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-grid;
    grid-template-rows: repeat(1, 1fr);
  }
`;

export default ShiftsStyled;
