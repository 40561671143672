import AppContext from '@/pages/app/context';
import { IPaySheet, IPaySheetRow } from '@/types/pay-periods/pay-sheet.model';
import { convertDecimalHourToTime, handleError } from '@/utils';
import { Input, Table } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  paySheet: IPaySheet | null;
  rows: IPaySheetRow[];
  rowsCopy: IPaySheetRow[];
  loading: boolean;
  format: 'h,m' | 'hhmm';
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const WorkedTable: React.FC<Props> = ({ className, paySheet, rows, rowsCopy, loading, format, onSearch }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [columns, setColumns] = useState<ColumnsType<IPaySheetRow>>([]);
  const [payrollSettings, setPayrollSettings] = useState<any>(null);

  useEffect(() => {
    getPayrollSettings();
  }, [paySheet]);

  const getPayrollSettings = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/settings/payroll`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setPayrollSettings(data.data);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        title: <Input placeholder={t('GLOBAL.SEARCH')} onChange={onSearch} />,
        className: 'user',
        render(value, record, index) {
          return (
            <a
              href={`/app/team/collaborators/${record.record_id}`}
              style={{ textDecoration: 'underline' }}
            >{`${record.name}`}</a>
          );
        },
        fixed: 'left',
      },
      {
        key: 'contract',
        align: 'center',
        title: t('REPORTS.PAY_PERIODS.WORKED_TABLE.CONTRACT_HOURS'),
        render(value, record, index) {
          return `${convertDecimalHourToTime(record.contract_hours, format)}`;
        },
      },
      {
        key: 'worked',
        align: 'center',
        title: t('REPORTS.PAY_PERIODS.WORKED_TABLE.WORKED_HOURS'),
        render(value, record, index) {
          return `${convertDecimalHourToTime(record.worked_time, format)}`;
        },
      },
      {
        key: 'missing',
        align: 'center',
        title: t('REPORTS.PAY_PERIODS.WORKED_TABLE.MISSING_HOURS'),
        render(value, record, index) {
          return `${convertDecimalHourToTime(record.missing_hours, format)}`;
        },
      },
      {
        key: 'supp-1',
        align: 'center',
        className: 'supp',
        title: `${t('REPORTS.PAY_PERIODS.WORKED_TABLE.EXTRA_1')} ${
          payrollSettings ? `${payrollSettings.extra_hours_slot1_increase}%` : ''
        }`,
        render(value, record, index) {
          return `${convertDecimalHourToTime(record.overtime_25, format)}`;
        },
      },
      {
        key: 'supp-2',
        align: 'center',
        className: 'supp',
        title: `${t('REPORTS.PAY_PERIODS.WORKED_TABLE.EXTRA_2')} ${
          payrollSettings ? `${payrollSettings.extra_hours_slot2_increase}%` : ''
        }`,
        render(value, record, index) {
          return `${convertDecimalHourToTime(record.overtime_50, format)}`;
        },
      },
      {
        key: 'comp-1',
        align: 'center',
        className: 'comp',
        title: `${t('REPORTS.PAY_PERIODS.WORKED_TABLE.COMP_1')} ${
          payrollSettings ? `${payrollSettings.additional_hours_slot1_increase}%` : ''
        }`,
        render(value, record, index) {
          return `${convertDecimalHourToTime(record.extra_hours_10, format)}`;
        },
      },
      {
        key: 'comp-2',
        align: 'center',
        className: 'comp',
        title: `${t('REPORTS.PAY_PERIODS.WORKED_TABLE.COMP_2')} ${
          payrollSettings ? `${payrollSettings.additional_hours_slot2_increase}%` : ''
        }`,
        render(value, record, index) {
          return `${convertDecimalHourToTime(record.extra_hours_25, format)}`;
        },
      },
      {
        key: 'holidays',
        align: 'center',
        title: t('REPORTS.PAY_PERIODS.WORKED_TABLE.HOLIDAYS'),
        render(value, record, index) {
          return `${convertDecimalHourToTime(record.overtime_holidays, format)}`;
        },
      },
      {
        key: 'night-1',
        align: 'center',
        title: `${t('REPORTS.PAY_PERIODS.WORKED_TABLE.NIGHT_1')} ${
          payrollSettings ? `${payrollSettings.night_hours_slot1_increase}%` : ''
        }`,
        render(value, record, index) {
          return `${convertDecimalHourToTime(record.night_early, format)}`;
        },
      },
      {
        key: 'night-1',
        align: 'center',
        title: `${t('REPORTS.PAY_PERIODS.WORKED_TABLE.NIGHT_2')} ${
          payrollSettings ? `${payrollSettings.night_hours_slot2_increase}%` : ''
        }`,
        render(value, record, index) {
          return `${convertDecimalHourToTime(record.night_late, format)}`;
        },
      },
    ]);
  }, [rowsCopy, paySheet, format, payrollSettings]);

  return <Table className={className} columns={columns} dataSource={rows} pagination={false} loading={loading} />;
};

export default styled(WorkedTable)`
  .ant-table-tbody {
    .ant-table-cell {
      &.user {
        background-color: #f7f7f7;
      }
      &.supp {
        background-color: #f0f9ff;
      }
      &.comp {
        background-color: #ffedf1;
      }
    }
  }
`;
