import { Select } from 'antd';
import React, { useContext } from 'react';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import { OpsFilter } from '@/types/ops-filter.model';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled } from '@/utils';

interface Props {
  className?: string;
}

const OperationalViewFilters: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, sections, skills, loadingSections, loadingSkills, loadingUserCategories, features },
    dispatch,
  } = useContext(AppContext);

  const onChange = (value: string) => {
    dispatch({
      type: 'SET_OPS_FILTER',
      payload: value as OpsFilter,
    });
  };

  return (
    <div className={className}>
      <Select
        allowClear
        placeholder={t('GLOBAL.FILTER')}
        className="selector"
        maxTagTextLength={10}
        maxTagCount={1}
        onChange={onChange}
        loading={loadingSections || loadingSkills || loadingUserCategories}
      >
        {isFeatureEnabled(features, FEATURES.SECTIONS) && sections.length > 0 && (
          <Select.Option value="sections">{t('GLOBAL.SECTIONS')}</Select.Option>
        )}
        <Select.Option value="status">{t('GLOBAL.STATUS')}</Select.Option>
        {isFeatureEnabled(features, FEATURES.SKILLS) && skills.length > 0 && (
          <Select.Option value="skills">{t('GLOBAL.SKILLS')}</Select.Option>
        )}
      </Select>
    </div>
  );
};

export default styled(OperationalViewFilters)`
  display: flex;
  align-items: center;

  .selector {
    width: 200px;
  }
`;
