import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { IShift } from '@/types/shift.model';
import { IUser } from '@/types/user.model';
import { isNullOrUndefined, minutesToHoursAndOrMinutes } from '@/utils';
import { BackTop, Form, InputNumber, Space, Table } from 'antd';
import Axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DisplayNameColumn from '../../components/DisplayNameColumn';
import TimePickerFormItem from '../../components/TimePickerFormItem';
import Header from './components/Header';
import ModalDeleteShiftConfirmation from './components/ModalDeleteShiftConfirmation';
import TableRowActions from './components/TableRowActions';

const ShiftApprovalsPage: React.FC = () => {
  const {
    state: { activeDepartmentId, activeDepartment },
  } = useContext(AppContext);
  const [shifts, setShifts] = useState<IShift[]>([]);
  const shiftsRef = useRef<IShift[]>([]);
  shiftsRef.current = shifts;
  const [filteredShifts, setFilteredShifts] = useState<IShift[]>();
  const filteredShiftsRef = useRef<IShift[]>([]);
  filteredShiftsRef.current = shifts;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const [shiftToDelete, setShiftToDelete] = useState<string | null>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const history = useHistory();

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.NAME'),
        key: 'displayName',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shift: IShift) => {
          return (
            <DisplayNameColumn
              user={shift.user!}
              onClick={() => history.push(`/app/team/collaborators/${shift.user!.recordId!}`)}
            />
          );
        },
        sorter: (a: IShift, b: IShift) => a.user?.displayName?.localeCompare(b.user?.displayName || '') || 0,
      },
      {
        title: t('GLOBAL.USER_CATEGORY_SHORT'),
        key: 'status',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shift: IShift) => {
          return <Space>{shift?.user?.userType?.name}</Space>;
        },
        sorter: (a: IShift, b: IShift) => a.user?.userType?.name?.localeCompare(b.user?.userType?.name || '') || 0,
      },
      {
        title: t('GLOBAL.DATE'),
        key: 'date',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shift: IShift) => {
          return <Space>{moment.unix(shift.start!).format('LL')}</Space>;
        },
        sorter: (a: IShift, b: IShift) => a.start! - b.start!,
      },
      {
        title: t('GLOBAL.START'),
        key: 'start',
        width: 100,
        // eslint-disable-next-line react/display-name
        render: (_text: string, shift: IShift) => {
          return (
            <React.Fragment>
              <Form.Item name={`shift_${shift.id}_start`} style={{ display: 'none' }}>
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                allowClear={false}
                name={`shift_${shift.id}_start_time`}
                start={moment.unix(shift.start!).startOf('day').unix()}
                style={{ marginBottom: 0 }}
                changeTime={({ hour, minute }) => changeStartDate({ hour, minute, shiftId: shift.id! })}
              />
            </React.Fragment>
          );
        },
      },
      {
        title: t('GLOBAL.END'),
        key: 'end',
        width: 100,
        // eslint-disable-next-line react/display-name
        render: (_text: string, shift: IShift) => {
          return (
            <React.Fragment>
              <Form.Item name={`shift_${shift.id}_end`} style={{ display: 'none' }}>
                <InputNumber disabled={true} />
              </Form.Item>
              <TimePickerFormItem
                allowClear={false}
                name={`shift_${shift.id}_end_time`}
                displayDuration={true}
                start={form.getFieldValue(`shift_${shift.id}_start`)}
                style={{ marginBottom: 0 }}
                changeTime={({ hour, minute }) => changeEndDate({ hour, minute, shiftId: shift.id! })}
              />
            </React.Fragment>
          );
        },
      },
      {
        title: t('SCHEDULE.WORKTIME_SHORT'),
        key: 't.preste',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shift: IShift) => {
          const worktimeInMinutes = (shift.worktime && shift.worktime / 60) || 0;
          return <Space>{minutesToHoursAndOrMinutes(worktimeInMinutes)}</Space>;
        },
      },
      {
        title: t('GLOBAL.BREAKS'),
        key: 'pauses',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shift: IShift) => {
          const totalPauses = (shift.pause?.paid || 0) + (shift.pause?.unpaid || 0);
          const totalPausesInMinutes = (totalPauses && totalPauses / 60) || 0;
          return <Space>{minutesToHoursAndOrMinutes(totalPausesInMinutes)}</Space>;
        },
      },
      {
        title: t('SCHEDULE.TOTAL_WORKED_SHORT'),
        key: 'totalWorked',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shift: IShift) => {
          const effectiveWorktimeInMinutes = (shift.effectiveWorktime && shift.effectiveWorktime / 60) || 0;
          return <Space>{minutesToHoursAndOrMinutes(effectiveWorktimeInMinutes)}</Space>;
        },
      },
      {
        title: t('GLOBAL.COST'),
        key: 'cout',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shift: IShift) => {
          return (
            <Space>
              {new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: activeDepartment?.currency || 'EUR',
              }).format(shift.price || 0)}
            </Space>
          );
        },
        sorter: (a: IShift, b: IShift) => (a.price || 0) - (b.price || 0),
      },
      {
        key: 'actions',
        displayName: 'actions',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shift: IShift) => (
          <TableRowActions
            shift={shift}
            handleDelete={() => setShiftToDelete(shift.id!)}
            handleValidate={handleValidate}
          />
        ),
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    setIsLoading(true);
    const cancelTokenSource = Axios.CancelToken.source();
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/shifts`, {
      params: {
        departmentId: activeDepartmentId,
        validated: false,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        const { shifts, users } = response.data as {
          shifts: IShift[];
          users: IUser[];
        };

        const usersMap = new Map<string, IUser>();
        for (let i = 0; i < users.length; i++) {
          const user = users[i];
          usersMap.set(user.recordId!, user);
        }

        const shiftsWithUser = shifts.map((shift) => ({
          ...shift,
          user: usersMap.get(shift.userRecordId!),
        }));
        setShifts(shiftsWithUser);
        setFilteredShifts(shiftsWithUser);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (filteredShifts) {
      const fieldsValue: any = {};
      filteredShifts.map((shift) => {
        fieldsValue[`shift_${shift.id}_start`] = shift.start;
        fieldsValue[`shift_${shift.id}_start_time`] = moment.unix(shift.start!).format('HH:mm');
        fieldsValue[`shift_${shift.id}_end`] = shift.end;
        fieldsValue[`shift_${shift.id}_end_time`] = moment.unix(shift.end!).format('HH:mm');
      });
      form.setFieldsValue(fieldsValue);
    }
  }, [filteredShifts]);

  const changeStartDate = ({
    shiftId,
    hour,
    minute,
  }: {
    shiftId: string;
    hour: number | null;
    minute: number | null;
  }) => {
    let startTimestamp = 0;
    const shift = shiftsRef.current.find((shift) => shift.id === shiftId)!;
    if (isNullOrUndefined(hour)) {
      form.setFieldsValue({
        [`shift_${shiftId}_start`]: shift.start,
        [`shift_${shiftId}_start_time`]: moment.unix(shift.start!).format('HH:mm'),
      });
    } else {
      startTimestamp = moment
        .unix(shift.start!)
        .startOf('day')
        .set('hour', hour || 0)
        .set('minute', minute || 0)
        .unix();
      form.setFieldsValue({
        [`shift_${shiftId}_start`]: startTimestamp,
      });
    }

    const endDate = shift.end;
    if (endDate) {
      const endMoment = moment.unix(endDate);
      let endTimestamp = moment
        .unix(shift.start!)
        .startOf('day')
        .set('hour', endMoment.hours())
        .set('minute', endMoment.minutes())
        .unix();
      if (endTimestamp < startTimestamp) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      form.setFieldsValue({
        [`shift_${shiftId}_end`]: endTimestamp,
      });
    }
  };

  const changeEndDate = ({
    hour,
    minute,
    shiftId,
  }: {
    hour: number | null;
    minute: number | null;
    shiftId: string;
  }) => {
    const shift = shiftsRef.current.find((shift) => shift.id === shiftId)!;
    if (isNullOrUndefined(hour)) {
      form.setFieldsValue({
        [`shift_${shiftId}_end`]: shift.end,
        [`shift_${shiftId}_end_time`]: moment.unix(shift.end!).format('HH:mm'),
      });
    } else {
      let endTimestamp = moment
        .unix(shift.start!)
        .startOf('day')
        .set('hour', hour || 0)
        .set('minute', minute || 0)
        .unix();
      const startDate = form.getFieldValue(`shift_${shiftId}_start`);
      if (startDate && endTimestamp < startDate) {
        endTimestamp = moment.unix(endTimestamp).add(1, 'day').unix();
      }
      form.setFieldsValue({
        [`shift_${shiftId}_end`]: endTimestamp,
      });
    }
  };

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    if (shifts) {
      const filteredShifts = shifts.filter((shift: IShift) => {
        return shift.user?.displayName?.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredShifts(filteredShifts);
    } else {
      setFilteredShifts([]);
    }
  };

  const handleDelete = (): Promise<void | AxiosResponse<any>> => {
    return Axios.delete(`${process.env.REACT_APP_API_URL}/v3/shifts/${shiftToDelete}`, {
      params: {
        departmentId: activeDepartmentId,
        type: 'shift-approval',
      },
    }).then((response) => {
      const shifts = [...shiftsRef.current];
      const filteredShifts = [...filteredShiftsRef.current];
      setShifts(shifts.filter((shift) => shift.id !== shiftToDelete));
      setFilteredShifts(filteredShifts.filter((shift) => shift.id !== shiftToDelete));
    });
  };

  const handleValidate = (shiftId: string): Promise<void | AxiosResponse<any>> => {
    const start = form.getFieldValue(`shift_${shiftId}_start`);
    const end = form.getFieldValue(`shift_${shiftId}_end`);

    return Axios.patch(`${process.env.REACT_APP_API_URL}/v3/shifts/${shiftId}`, {
      start,
      end,
      validated: true,
    }).then(() => {
      const shifts = [...shiftsRef.current];
      const filteredShifts = [...filteredShiftsRef.current];
      setShifts(shifts.filter((shift) => shift.id !== shiftId));
      setFilteredShifts(filteredShifts.filter((shift) => shift.id !== shiftId));
    });
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header onSearchChange={onSearchChange} disabled={isLoading} />
      <Form form={form}>
        <Table loading={isLoading} dataSource={filteredShifts} columns={columns} rowKey="id" pagination={false} />
      </Form>
      <ModalDeleteShiftConfirmation
        onClose={() => setShiftToDelete(null)}
        visible={!!shiftToDelete}
        onConfirm={handleDelete}
      />
    </TableView>
  );
};

export default ShiftApprovalsPage;
