import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';
import { Form, Switch } from 'antd';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { handleError } from '@/utils';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const ScheduleStaff: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.SCHEDULES.STAFF.HIDE_SHIFTS_END.LABEL'),
          description: t('SETTINGS.SCHEDULES.STAFF.HIDE_SHIFTS_END.DESCRIPTION'),
          name: ['general', 'show_only_startdate'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.general?.show_only_startdate} />,
          show: true,
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    form.setFieldsValue({
      ...currentSettings,
    });
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings({
      ...values,
    })
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.SCHEDULES.STAFF.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(ScheduleStaff)`
  .form-list {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px !important;
    max-width: 400px;

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
  }

  .option-label-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-select-selection-item {
    .option-label-item-circle {
      margin-top: 4px;
    }
  }

  margin-top: 4px;
  .option-label-item-circle {
    display: inline-block;
    background-color: red;
    width: 100%;
    height: 20px;
    border-radius: 5px;
  }

  .expenseAllowances {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li:nth-of-type(n + 2) {
      padding-top: 5px;
    }

    .anticon-close {
      background: ${colors.red};
      padding: 1px;
      border-radius: 10px;
      margin-right: 5px;
      svg {
        transform: scale(0.8);
        fill: white;
      }
    }
  }
  .target-days {
    label {
      min-width: 100px;
    }
  }

  @media screen and (max-width: 900px) {
    .one-line {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .pauses {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li:nth-of-type(n + 2) {
      padding-top: 5px;
    }

    .anticon-close {
      background: ${colors.red};
      padding: 1px;
      border-radius: 10px;
      margin-right: 5px;
      svg {
        transform: scale(0.8);
        fill: white;
      }
    }
  }
`;
