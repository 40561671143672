import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Form, Button, Input } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { IUser } from '@/types/user.model';
import colors from '@/styles/colors';
import { SyncOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

interface Props {
  className?: string;
  user: IUser;
  visible: boolean;
  onClose: () => void;
}

const ModalUpdatePinCode: React.FC<Props> = ({ className, user, visible, onClose }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (!visible) {
      setIsSaving(false);
      setIsGenerating(false);
      form.resetFields();
    }
  }, [visible]);

  const onFinish = (values: any) => {
    setIsSaving(true);

    axios
      .patch(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/pincode`, {
        pinCode: values.pinCode,
      })
      .then(() => {
        setIsSaving(false);
        onClose();
        history.push(`/app/team/collaborators`);
        setTimeout(() => {
          history.push(`/app/team/collaborators/${user?.recordId}`);
        }, 0);
      })
      .catch((error) => {
        const names = Object.keys(error?.response?.data?.errors || {});
        form.setFields(
          names.map((name) => ({
            name,
            errors: [error?.response?.data?.errors[name]],
          })),
        );
        setIsSaving(false);
      });
  };

  const onGenerate = () => {
    setIsGenerating(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/generate-pincode`)
      .then((response) => {
        setIsGenerating(false);
        form.setFieldsValue({ pinCode: response?.data?.pinCode || '' });
      })
      .catch((error) => {
        console.log(error);
        setIsGenerating(false);
      });
  };

  return (
    <Modal
      className={className}
      visible={visible}
      onOk={() => form.submit()}
      onCancel={() => onClose()}
      title={t('GLOBAL.PIN_CODE')}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
          {t('GLOBAL.VALIDATE')}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ pinCode: user.pinCode }}>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <Form.Item name="pinCode" rules={[{ required: true, min: 4, max: 4, pattern: /^[0-9]{4}$/, message: '' }]}>
            <Input
              disabled={isGenerating}
              style={{ width: 90 }}
              maxLength={4}
              minLength={4}
              suffix={
                <span
                  style={{
                    position: 'absolute',
                    left: 10,
                    fontSize: 22,
                    fontFamily: 'monospace',
                    letterSpacing: 3,
                    color: colors.greyLight,
                  }}
                >
                  ____
                </span>
              }
            />
          </Form.Item>
          <Button type="ghost" className="generate-pincode" disabled={isGenerating} onClick={onGenerate}>
            <SyncOutlined spin={isGenerating} />
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default styled(ModalUpdatePinCode)`
  .ant-row.ant-form-item {
    margin-bottom: 0px;
  }
  .ant-input {
    font-size: 20px;
    margin-bottom: 5px;
    font-family: 'Arial';
    letter-spacing: 5px;
    padding: 0 10px;
  }
  .ant-form-item-control-input-content {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .generate-pincode {
    margin-top: 8px;
    margin-left: 140px;
    position: absolute;
  }
`;
