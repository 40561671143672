import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { getHoursBefore, getMinutesBefore, isFeatureEnabled, valueForSearch } from '@/utils';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Form, Input, Modal, Select } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { INewClockingParams } from '.';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option, OptGroup } = Select;
const { TextArea } = Input;

interface Props {
  className?: string;
  visible: boolean;
  params?: INewClockingParams;
  start?: number;
  onClose: () => void;
  onCreated: (data: any) => void;
}

const ModalCreateClocking: React.FC<Props> = ({ className, visible, start, onClose, onCreated, params }) => {
  const {
    state: {
      activeDepartment,
      loadingUsers,
      users,
      skills,
      loadingSkills,
      resources,
      loadingResources,
      loadingSections,
      sections,
      features,
      activeSection,
    },
  } = useContext(AppContext);
  const {
    state: { userDetails },
  } = useContext(AuthContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [hrPartners, setHrPartners] = useState<any[]>([]);
  const [loadingHrPartners, setLoadingHrPartners] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue({
        ...params,
        start: params?.start ? moment.unix(params.start) : undefined,
        end: params?.end ? moment.unix(params.end) : undefined,
      });
      getHrPartners();
    }
  }, [visible, params]);

  const getHrPartners = () => {
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingHrPartners(true);
    setHrPartners([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/hr-partners`, {
        params: {
          departmentId: activeDepartment?.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const hr = response.data;
        setHrPartners(hr);
        setLoadingHrPartners(false);
      })
      .catch((error) => {
        setLoadingHrPartners(false);
        console.log(error);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  };

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    const { start, end, pauses } = values;
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/operations/create-clocking`, {
        departmentId: activeDepartment?.id,
        shiftId: params?.shiftId,
        ...values,
        start: start ? start.unix() : null,
        end: end ? end.unix() : null,
        pauses: pauses?.map((pause: any) => {
          const { start, end } = pause;
          return {
            start: start ? start.unix() : null,
            end: end ? end.unix() : null,
          };
        }),
        manual_create: true,
        sectionId: values.sectionId ? values.sectionId : activeSection ? activeSection : undefined,
      })
      .then(({ data }) => {
        setIsSaving(false);
        form.resetFields();
        onCreated(data);
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const start = form && form.getFieldValue('start');
    if (!start) return false;
    return current && current.isBefore(start, 'date');
  };

  const disabledDateTime = () => {
    let start = form && form.getFieldValue('start');
    let end = form && form.getFieldValue('end');
    if (!start || !end) return {};
    if (start.day() != end.day()) return {};

    let lockedHours = getHoursBefore(start);
    let lockedMinutes = getMinutesBefore(start);

    if (end.isBefore(start)) {
      form.setFieldsValue({
        end: start,
      });
    }

    return {
      disabledHours: () => lockedHours,
      disabledMinutes: () => (start.hours() == end.hours() ? lockedMinutes : []),
      disaledSeconds: () => [],
    };
  };

  return (
    <Modal
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={visible}
      onOk={() => form.submit()}
      onCancel={onCancel}
      title={t('CLOCKINGS.ADD_CLOCKING')}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
          {t('GLOBAL.CREATE')}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} className={className}>
        <Form.Item label={t('GLOBAL.USER')} name="userRecordId" rules={[{ required: true }]}>
          <Select
            getPopupContainer={(trigger) => trigger}
            showSearch
            loading={loadingUsers}
            placeholder={t('FORMS.USER_PLACEHOLDER')}
            optionFilterProp="children"
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {users?.map((user) => {
              const { recordId, displayName } = user;
              const CANNOT_EDIT_OWN_CLOCKINGS =
                recordId == activeDepartment?.userRecordId && userDetails
                  ? userDetails.params
                    ? !userDetails.params.params?.allow_edit_own_clockings
                    : false
                  : false;
              return (
                <Option key={`user_${recordId}`} value={`${recordId}`} disabled={CANNOT_EDIT_OWN_CLOCKINGS}>
                  {displayName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {isFeatureEnabled(features, FEATURES.SKILLS) && !!skills?.length && (
          <Form.Item label={t('GLOBAL.SKILLS')} name="skillIds">
            <Select
              id="clocking_skills"
              getPopupContainer={(trigger) => trigger}
              showSearch
              clearIcon
              allowClear
              placeholder={t('FORMS.SKILLS_PLACEHOLDER')}
              optionFilterProp="children"
              disabled={loadingSkills}
              loading={loadingSkills}
              filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {skills.map((skill: any) => (
                <Option value={skill.id!} key={`skill_${skill.id}`}>
                  {skill.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {!!resources?.length && (
          <Form.Item name="attributeIds" label={t('GLOBAL.ATTRIBUTES')}>
            <Select
              mode="multiple"
              placeholder={t('FORMS.ATTRIBUTES_PLACEHOLDER')}
              maxTagCount={3}
              suffixIcon={<i className="icon-search" />}
              disabled={loadingResources}
              loading={loadingResources}
              filterOption={(input, option) => {
                if (option) {
                  if (!option.label) {
                    let children = option.children;
                    if (Array.isArray(children)) {
                      children = children.join();
                    }
                    return valueForSearch(children).includes(valueForSearch(input));
                  }
                }
                return false;
              }}
            >
              {resources.map((resource = {}) => (
                <OptGroup label={resource.name} key={`resource_${resource.id}`}>
                  {(resource.attributes || []).map((attribut) => (
                    <Option value={attribut.id!} key={`attribut_${attribut.id}`}>
                      {attribut.name}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>
        )}
        {!activeSection && isFeatureEnabled(features, FEATURES.SECTIONS) && !!sections?.length && (
          <Form.Item label={t('GLOBAL.SECTION')} name="sectionId">
            <Select
              clearIcon
              getPopupContainer={(trigger) => trigger}
              showSearch
              placeholder={t('FORMS.SECTIONS_PLACEHOLDER')}
              optionFilterProp="children"
              loading={loadingSections}
              disabled={loadingSections}
              allowClear={true}
              filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option value={''}>{t('GLOBAL.NO_SECTION')}</Option>
              {sections.map((section) => (
                <Option key={`section_${section.id}`} value={`${section.id}`}>
                  {section.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item name="start" label={t('GLOBAL.START')} rules={[{ required: true }]}>
          <DatePicker
            style={{ width: '100%' }}
            showTime
            format={'L - HH:mm'}
            onSelect={(e) => {
              form.setFieldsValue({
                start: e,
              });
            }}
          />
        </Form.Item>
        <Form.Item name="end" label={t('GLOBAL.END')}>
          <DatePicker
            style={{ width: '100%' }}
            showTime
            format={'L - HH:mm'}
            onSelect={(e) => {
              form.setFieldsValue({
                end: e,
              });
            }}
            value={form.getFieldValue('end')}
            defaultValue={form.getFieldValue('start') ? form.getFieldValue('start') : undefined}
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
          />
        </Form.Item>
        {hrPartners.length > 0 && activeDepartment?.hr_codes && activeDepartment?.hr_codes.length > 0 && (
          <Form.Item name="hr_code" label={t('GLOBAL.HR_CODE')}>
            <Select
              getPopupContainer={(trigger) => trigger}
              showSearch
              placeholder={t('GLOBAL.HR_CODE')}
              optionFilterProp="children"
              loading={loadingHrPartners}
              filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              allowClear
            >
              {activeDepartment?.hr_codes.map((hr_code) => (
                <Option key={hr_code.id} value={`${hr_code.id}`}>
                  {hr_code.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label={t('GLOBAL.COMMENT')} name="comment">
          <TextArea rows={4} />
        </Form.Item>
        <Divider orientation="left" plain>
          {t('GLOBAL.BREAKS')}
        </Divider>
        <Form.List name="pauses">
          {(pauseItems, { add, remove }) => {
            return (
              <div>
                {pauseItems.map((pauseItem) => {
                  return (
                    <div className="form-list" key={pauseItem.key}>
                      <Form.Item
                        name={[pauseItem.name, 'start']}
                        fieldKey={[pauseItem.fieldKey, 'start']}
                        style={{ width: '100%' }}
                        rules={[{ required: true, message: '' }]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          showTime
                          format={'L - HH:mm'}
                          defaultPickerValue={start ? moment.unix(start) : undefined}
                          onSelect={(e) => {
                            const pauses = [...form.getFieldValue('pauses')];
                            if (pauses[pauseItem.fieldKey]) {
                              pauses[pauseItem.fieldKey].start = e;
                            } else if (pauses[pauses.length - 1]) {
                              pauses[pauses.length - 1].start = e;
                            }
                            form.setFieldsValue({
                              pauses,
                            });
                          }}
                        />
                      </Form.Item>
                      <div style={{ margin: '0 10px' }}>-</div>
                      <Form.Item
                        name={[pauseItem.name, 'end']}
                        fieldKey={[pauseItem.fieldKey, 'end']}
                        style={{ width: '100%', marginRight: 10 }}
                        rules={[{ required: true, message: '' }]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          showTime
                          format={'L - HH:mm'}
                          defaultPickerValue={start ? moment.unix(start) : undefined}
                          onSelect={(e) => {
                            const pauses = [...form.getFieldValue('pauses')];
                            if (pauses[pauseItem.fieldKey]) {
                              pauses[pauseItem.fieldKey].end = e;
                            } else if (pauses[pauses.length - 1]) {
                              pauses[pauses.length - 1].end = e;
                            }
                            form.setFieldsValue({
                              pauses,
                            });
                          }}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(pauseItem.name);
                        }}
                      />
                    </div>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      const start = form.getFieldValue('start');
                      const startMoment = start ? moment(start) : null;
                      add({
                        start: startMoment,
                        end: startMoment ? startMoment.add(30, 'minutes') : null,
                      });
                    }}
                    block
                  >
                    <PlusOutlined /> {t('CLOCKINGS.ADD_BREAK')}
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default styled(ModalCreateClocking)`
  .form-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-explain-error {
      display: none;
    }
  }
`;
