import colors from '@/styles/colors';
import { IUser } from '@/types/user.model';
import { Button, Form, Input, List, message, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  user: IUser;
}

const ModalChangePassword: React.FC<Props> = ({ className, visible, setVisible, user }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[] | null>(null);

  useEffect(() => {
    form.resetFields();
    setErrors(null);
  }, [visible]);

  useEffect(() => {
    let timeout: any;
    if (errors) {
      timeout = setTimeout(() => {
        setErrors(null);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [errors]);

  const onCancel = () => {
    setVisible(false);
  };

  const onFinish = (values: any) => {
    const { password, password2 } = values || {};

    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/users/${user.recordId}/change-password`,
        {
          userId: user.recordId,
          password,
          password_confirmation: password2,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then((response) => {
        message.success(
          <>
            {t('USERS.USER.PROFILE.PASSWORD_CHANGED', { name: user.displayName })}
            <br />
            {t('USERS.USER.PROFILE.EMAIL_SENT')}
          </>,
        );
        setVisible(false);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setErrors(Object.values(error.response.data.errors).flat(1) as string[]);
        }
        message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
        setLoading(false);
      });
  };

  return (
    <Modal
      className={className}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          {t('GLOBAL.CHANGE_PASSWORD')}
        </Button>,
      ]}
    >
      <h2>{t('GLOBAL.CHANGE_PASSWORD')}</h2>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="password" label={t('FORMS.PASSWORD')} rules={[{ required: true }]}>
          <Input type="password" />
        </Form.Item>
        <Form.Item name="password2" label={t('FORMS.PASSWORD_2')} rules={[{ required: true }]}>
          <Input type="password" />
        </Form.Item>
      </Form>
      {errors && (
        <List
          style={{ margin: 0, padding: 0 }}
          dataSource={errors}
          renderItem={(item) => (
            <List.Item>
              <span className="error">&#8226; {item}</span>
            </List.Item>
          )}
        />
      )}
    </Modal>
  );
};

export default styled(ModalChangePassword)`
  p {
    margin: 0;
  }

  .error {
    color: ${colors.red};
  }
`;
