import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import { DIMONA_WORK_TYPES, IDimona } from '@/types/dimona.model';
import { getWindowSize } from '@/utils';
import { Button, DatePicker, Drawer, Form, InputNumber, Radio, Select, message, notification } from 'antd';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
  visible: boolean;
  dimona: Partial<IDimona> | null;
  onClose: () => void;
  onSave: () => void;
}

const DrawerDimona: React.FC<Props> = ({ className, visible, dimona, onClose, onSave }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [workerTypeIsLoading, setWorkerTypeIsLoading] = useState<boolean>(false);
  const [placesOfWork, setPlacesOfWork] = useState<string[]>([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (visible) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/department/places-of-work`, {
          params: {
            departmentId: activeDepartmentId,
          },
        })
        .then(({ data }) => {
          setPlacesOfWork(data);
        })
        .catch((err) => {
          console.log(err);
          message.error(t('DIMONA.GET_PLACES_OF_WORK_ERROR'));
        });
    }
  }, [visible]);

  const {
    state: { loadingUsers, users },
  } = useContext(AppContext);

  const {
    state: { activeDepartmentId, sections },
  } = useContext(AppContext);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    form.resetFields();
    const recordId = dimona?.user?.recordId;
    if (recordId) {
      setWorkerTypeIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/users/${recordId}/worker-type`, {
          params: {
            departmentId: activeDepartmentId,
          },
        })
        .then(({ data }) => {
          form.setFieldsValue({
            ...dimona,
            userRecord: recordId,
            startingDate: dimona?.startingDate ? moment.unix(dimona.startingDate) : null,
            endingDate:
              data.workerType && data.workerType === 'STU'
                ? dimona?.startingDate
                  ? moment.unix(dimona.startingDate)
                  : null
                : dimona?.endingDate
                ? moment.unix(dimona.endingDate)
                : null,
          });
          form.setFieldsValue({ workerType: data.workerType });
          setWorkerTypeIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          form.setFieldsValue({ workerType: null });
          setWorkerTypeIsLoading(false);
        });
    } else {
      form.setFieldsValue({ workerType: null });
    }
    // eslint-disable-next-line
  }, [visible, dimona]);

  const onFinish = async (_values: any) => {
    setIsSaving(true);

    const values = {
      ..._values,
      startingDate: _values?.startingDate ? _values?.startingDate?.unix() : null,
      endingDate: _values?.endingDate ? _values?.endingDate?.unix() : null,
      placeOfWork:
        _values?.workerType == 'STU' ? (_values?.placeOfWork == 'null' ? null : _values.placeOfWork) : undefined,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/dimona`, {
        departmentId: activeDepartmentId,
        ...values,
      })
      .then(({ data }) => {
        onSave();
        setIsSaving(false);
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
        const has_error = error && error.response && error.response.data && error.response.data.errors;
        notification.open({
          message: t('GLOBAL.AN_ERROR_OCCURRED'),
          description: has_error ? Object.values(error.response.data.errors).flat(1)[0] : t('DIMONA.ADD_DIMONA_ERROR'),
          type: 'error',
        });
      });
  };

  const onUserRecordIdChange = (userRecordId: string) => {
    if (userRecordId) {
      setWorkerTypeIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/users/${userRecordId}/worker-type`, {
          params: {
            departmentId: activeDepartmentId,
          },
        })
        .then(({ data }) => {
          form.setFieldsValue({ workerType: data.workerType });
          setWorkerTypeIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          form.setFieldsValue({ workerType: null });
          setWorkerTypeIsLoading(false);
        });
    } else {
      form.setFieldsValue({ workerType: null });
    }
  };

  const onWorkerTypeChange = (change: any) => {
    form.setFieldsValue({
      endingDate:
        change && change === 'STU'
          ? dimona?.startingDate
            ? moment.unix(dimona.startingDate)
            : null
          : dimona?.endingDate
          ? moment.unix(dimona.endingDate)
          : null,
    });
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={t('DIMONA.NEW_DECLARATION')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item label={t('GLOBAL.USER')} name="userRecord" rules={[{ required: true }]}>
          <Select
            allowClear={true}
            getPopupContainer={(trigger) => trigger}
            showSearch
            optionFilterProp="children"
            placeholder={t('FORMS.USER_PLACEHOLDER')}
            loading={loadingUsers}
            disabled={loadingUsers}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={onUserRecordIdChange}
          >
            {users
              ?.filter((x) => x.dimona)
              .map((user) => {
                const { recordId, firstname, lastname } = user;
                return (
                  <Option key={`user_${recordId}`} value={`${recordId}`}>
                    {`${firstname} ${lastname}`}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item label={t('GLOBAL.WORKER_TYPE')} name="workerType" rules={[{ required: true }]}>
          <Select
            loading={workerTypeIsLoading}
            getPopupContainer={(trigger) => trigger}
            placeholder={t('FORMS.WORKER_TYPE_PLACEHOLDER')}
            onChange={onWorkerTypeChange}
          >
            {DIMONA_WORK_TYPES.map((workType) => {
              const { id } = workType;
              return (
                <Option key={`workType_${id}`} value={id!}>
                  {t(`DIMONA.WORKTYPES.${id}`)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.workerType !== curValues.workerType}>
          {(form) => {
            const workerType = form.getFieldValue('workerType');
            switch (workerType) {
              case 'A17': {
                return (
                  <Form.Item name="startingDate" label={t('GLOBAL.START_PERIOD')} rules={[{ required: true }]}>
                    <DatePicker
                      style={{ width: '100%' }}
                      format={'L'}
                      onSelect={(e) => {
                        form.setFieldsValue({
                          startingDate: e,
                          endingDate: e,
                        });
                      }}
                    />
                  </Form.Item>
                );
              }
              case 'DWD':
              case 'PMP':
              case 'OTH':
              case 'ALT': {
                return (
                  <React.Fragment>
                    <Form.Item
                      label={t('GLOBAL.START_PERIOD')}
                      name="startingDate"
                      style={{ display: 'inline-block', width: '236px', marginRight: '10px' }}
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            startingDate: e,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t('GLOBAL.END_PERIOD')}
                      name="endingDate"
                      style={{ display: 'inline-block', width: '236px' }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            endingDate: e,
                          });
                        }}
                      />
                    </Form.Item>
                  </React.Fragment>
                );
              }
              case 'STU':
              case 'S17':
              case 'O17': {
                return (
                  <React.Fragment>
                    <Form.Item
                      label={t('GLOBAL.START_PERIOD')}
                      name="startingDate"
                      style={{ display: 'inline-block', width: '236px', marginRight: '10px' }}
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            startingDate: e,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t('GLOBAL.END_PERIOD')}
                      name="endingDate"
                      style={{ display: 'inline-block', width: '236px' }}
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            endingDate: e,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('GLOBAL.HOURS_NUMBER')} name="plannedHoursNbr" rules={[{ required: true }]}>
                      <InputNumber type="number" min={1} step={1} precision={0} style={{ width: '100%' }} />
                    </Form.Item>
                    {placesOfWork.length > 0 && (
                      <Form.Item
                        label={
                          <span style={{ display: 'flex', alignItems: 'center' }}>{t('DIMONA.PLACE_OF_WORK')}</span>
                        }
                        name="placeOfWork"
                      >
                        <Select
                          placeholder={t('DIMONA.PLACE_OF_WORK')}
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Select.Option key={`dimona-no-place-of-work`} value={'null'}>
                            {t('DIMONA.NO_PLACE_OF_WORK')}
                          </Select.Option>
                          {placesOfWork.map((place) => (
                            <Select.Option key={place} value={place}>
                              {place}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </React.Fragment>
                );
              }
              case 'FLX': {
                return (
                  <React.Fragment>
                    <Form.Item name="periodType" label={t('DIMONA.PERIOD_TYPE_LABEL')} rules={[{ required: true }]}>
                      <Radio.Group
                        options={[
                          { label: t('DIMONA.A_PERIOD'), value: 'range' },
                          { label: t('DIMONA.A_DAY'), value: 'day' },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) => prevValues.periodType !== curValues.periodType}
                      rules={[{ required: true }]}
                    >
                      {(form) => {
                        const periodType = form.getFieldValue('periodType');
                        switch (periodType) {
                          case 'range': {
                            return (
                              <React.Fragment>
                                <Form.Item
                                  label={t('GLOBAL.START_PERIOD')}
                                  name="startingDate"
                                  style={{ display: 'inline-block', width: '236px', marginRight: '10px' }}
                                >
                                  <DatePicker
                                    style={{ width: '100%' }}
                                    format={'L'}
                                    onSelect={(e) => {
                                      form.setFieldsValue({
                                        startingDate: e,
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={t('GLOBAL.END_PERIOD')}
                                  name="endingDate"
                                  style={{ display: 'inline-block', width: '236px' }}
                                >
                                  <DatePicker
                                    style={{ width: '100%' }}
                                    format={'L'}
                                    onSelect={(e) => {
                                      form.setFieldsValue({
                                        endingDate: e,
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </React.Fragment>
                            );
                          }
                          case 'day': {
                            return (
                              <React.Fragment>
                                <Form.Item
                                  label={t('GLOBAL.START_HOUR')}
                                  name="startingDate"
                                  style={{ display: 'inline-block', width: '236px', marginRight: '10px' }}
                                >
                                  <DatePicker
                                    style={{ width: '100%' }}
                                    showTime
                                    format={'L - HH:mm'}
                                    onSelect={(e) => {
                                      form.setFieldsValue({
                                        startingDate: e,
                                      });
                                      const endingDate = form.getFieldValue('endingDate');
                                      if (!endingDate) {
                                        form.setFieldsValue({
                                          endingDate: e,
                                        });
                                      }
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={t('GLOBAL.END_HOUR')}
                                  name="endingDate"
                                  style={{ display: 'inline-block', width: '236px' }}
                                >
                                  <DatePicker
                                    style={{ width: '100%' }}
                                    showTime
                                    format={'L - HH:mm'}
                                    onSelect={(e) => {
                                      form.setFieldsValue({
                                        endingDate: e,
                                      });
                                      const startingDate = form.getFieldValue('startingDate');
                                      if (!startingDate) {
                                        form.setFieldsValue({
                                          startingDate: e,
                                        });
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </React.Fragment>
                            );
                          }
                        }
                      }}
                    </Form.Item>
                  </React.Fragment>
                );
              }
              case 'STG':
              case 'TRI':
              case 'IVT': {
                return (
                  <React.Fragment>
                    <Form.Item name="userType" rules={[{ required: true }]}>
                      <Select
                        allowClear={true}
                        getPopupContainer={(trigger) => trigger}
                        placeholder={t('DIMONA.CHOOSE_EMPLOYEE_OR_WORKER')}
                      >
                        <Option value={'employee'}>{t('GLOBAL.EMPLOYEE')}</Option>
                        <Option value={'worker'}>{t('GLOBAL.WORKER')}</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name="userStatus" rules={[{ required: true }]}>
                      <Select
                        allowClear={true}
                        getPopupContainer={(trigger) => trigger}
                        placeholder={t('GLOBAL.USER_CATEGORY_SHORT')}
                      >
                        <Option value={'F1'}>
                          {`F1 - Stagiaires avec le régime d'indemnisation accident du travail/maladie professionnelle des apprentis`}
                        </Option>
                        <Option value={'F2'}>
                          {`F2 - Stagiaires avec un régime d'indemnisation accident du travail/maladie professionnelle autre que celui des apprentis`}
                        </Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={t('GLOBAL.START_PERIOD')}
                      name="startingDate"
                      style={{ display: 'inline-block', width: '236px', marginRight: '10px' }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            startingDate: e,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t('GLOBAL.END_PERIOD')}
                      name="endingDate"
                      style={{ display: 'inline-block', width: '236px' }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            endingDate: e,
                          });
                        }}
                      />
                    </Form.Item>
                  </React.Fragment>
                );
              }
              case 'EXT': {
                return (
                  <React.Fragment>
                    <Form.Item name="periodType" label={t('DIMONA.PERIOD_TYPE_LABEL')} rules={[{ required: true }]}>
                      <Radio.Group
                        options={[
                          { label: t('DIMONA.HOURLY_PACKAGE'), value: 'hourly' },
                          { label: t('DIMONA.DAILY_PACKAGE'), value: 'daily' },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) => prevValues.periodType !== curValues.periodType}
                      rules={[{ required: true }]}
                    >
                      {(form) => {
                        const periodType = form.getFieldValue('periodType');
                        switch (periodType) {
                          case 'hourly': {
                            return (
                              <React.Fragment>
                                {' '}
                                <Form.Item
                                  label={t('GLOBAL.START_HOUR')}
                                  name="startingDate"
                                  style={{ display: 'inline-block', width: '236px', marginRight: '10px' }}
                                >
                                  <DatePicker
                                    style={{ width: '100%' }}
                                    showTime
                                    format={'L - HH:mm'}
                                    onSelect={(e) => {
                                      form.setFieldsValue({
                                        startingDate: e,
                                      });
                                      const endingDate = form.getFieldValue('endingDate');
                                      if (!endingDate) {
                                        form.setFieldsValue({
                                          endingDate: e,
                                        });
                                      }
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={t('GLOBAL.END_HOUR')}
                                  name="endingDate"
                                  style={{ display: 'inline-block', width: '236px' }}
                                >
                                  <DatePicker
                                    style={{ width: '100%' }}
                                    showTime
                                    format={'L - HH:mm'}
                                    onSelect={(e) => {
                                      form.setFieldsValue({
                                        endingDate: e,
                                      });
                                      const startingDate = form.getFieldValue('startingDate');
                                      if (!startingDate) {
                                        form.setFieldsValue({
                                          startingDate: e,
                                        });
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </React.Fragment>
                            );
                          }
                          case 'daily': {
                            return (
                              <React.Fragment>
                                <Form.Item label={t('GLOBAL.START_HOUR')} name="startingDate">
                                  <DatePicker
                                    style={{ width: '100%' }}
                                    showTime
                                    format={'L - HH:mm'}
                                    onSelect={(e) => {
                                      form.setFieldsValue({
                                        startingDate: e,
                                      });
                                      const endingDate = form.getFieldValue('endingDate');
                                      if (!endingDate) {
                                        form.setFieldsValue({
                                          endingDate: e,
                                        });
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </React.Fragment>
                            );
                          }
                        }
                      }}
                    </Form.Item>
                  </React.Fragment>
                );
              }
            }
          }}
        </Form.Item>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('DIMONA.SEND_DECLARATION')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerDimona;
