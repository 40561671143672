import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select, Space, Switch, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
  rangePickerValues: [Moment, Moment];
  setRangePickerValues: React.Dispatch<React.SetStateAction<[Moment, Moment]>>;
  selectUsers: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCategories: string[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
  isDownloading: boolean;
  onDownload: () => void;
  showAllAbsences: boolean;
  setShowAllAbsences: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<Props> = ({
  className,
  rangePickerValues,
  setRangePickerValues,
  selectUsers,
  setSelectedUsers,
  selectedCategories,
  setSelectedCategories,
  isDownloading,
  onDownload,
  showAllAbsences,
  setShowAllAbsences,
}) => {
  const { RangePicker } = DatePicker;
  const {
    state: { loadingUsers, users, loadingUserCategories, userCategories },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });

  const onShowAbsencesChange = (checked: boolean) => {
    localStorage.setItem('leave-reports-show-all-absences', `${checked}`);
    setShowAllAbsences(checked);
  };

  return (
    <header className={className}>
      <div className="left">
        <Space>
          <RangePicker
            allowClear={false}
            getPopupContainer={(trigger) => trigger}
            format={'L'}
            value={rangePickerValues}
            onChange={(e: any) => {
              setRangePickerValues(e);
            }}
          />
          <Select
            getPopupContainer={(trigger) => trigger}
            showSearch
            clearIcon
            allowClear
            mode="multiple"
            maxTagTextLength={10}
            maxTagCount={3}
            placeholder={t('GLOBAL.USERS')}
            loading={loadingUsers}
            disabled={loadingUsers}
            value={selectUsers}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(e) => {
              setSelectedUsers(e);
            }}
            className="select-users"
          >
            {users?.map((user) => {
              const { recordId, displayName } = user;
              return (
                <Option key={`user_${recordId}`} value={recordId!}>
                  {displayName}
                </Option>
              );
            })}
          </Select>
          <Select
            getPopupContainer={(trigger) => trigger}
            showSearch
            allowClear
            mode="multiple"
            optionFilterProp="children"
            placeholder={t('GLOBAL.USER_CATEGORY_SHORT')}
            loading={loadingUserCategories}
            disabled={loadingUserCategories}
            value={selectedCategories}
            onChange={(e) => {
              setSelectedCategories(e);
            }}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            className="select-user-statuses"
          >
            <Option value="">{t('GLOBAL.ALL')}</Option>
            {userCategories
              ?.filter((x) => x.active)
              .map((userCategory) => {
                const { id, name } = userCategory;
                return (
                  <Option key={`userCategory_${id}`} value={name!}>
                    {name}
                  </Option>
                );
              })}
          </Select>
          <div>
            <span>{t('REPORTS.LEAVE_REPORTS.DISPLAY_ALL_LEAVES')} </span>
            <Switch checked={showAllAbsences} onChange={onShowAbsencesChange} />
          </div>
        </Space>
      </div>
      <div className="right">
        <Tooltip title={t('GLOBAL.DOWNLOAD')}>
          <Button
            icon={<CloudDownloadOutlined />}
            type="primary"
            loading={isDownloading}
            onClick={onDownload}
            style={{ backgroundColor: colors.blueLightPastel, borderColor: colors.blueLightPastel }}
          >
            {t('GLOBAL.DOWNLOAD')}
          </Button>
        </Tooltip>
      </div>
    </header>
  );
};

export default styled(Header)`
  .left {
    .ant-space {
      .ant-space-item {
        .select-users {
          width: 400px;
        }

        .select-user-statuses {
          width: 200px;
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 15px;

    .left {
      .ant-space {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .ant-space-item {
          width: 100%;

          > div {
            width: 100%;
          }

          .select-users {
            width: 100%;
          }

          .select-user-statuses {
            width: 100%;
          }
        }
      }
    }

    .right {
      button {
        width: 100%;
      }
    }
  }
`;
