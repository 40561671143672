export enum USER_ROLES {
  ADMIN = '1',
  PLANNING = '2',
  USER = '3',
  HR = '4',
}
export interface IUserRole {
  id?: string;
  name?: string;
}
