import colors from '@/styles/colors';
import { IShiftBlock } from '@/types/shift-block.model';
import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import React, { Dispatch, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType, updateShiftsAndContextWithShiftResponse } from '../../redux/actions';
import { InitialStateType } from '../../redux/store';
import AppContext from '@/pages/app/context';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import ModalShiftsToReplace from './ModalShiftsToReplace';
import { getContrast, isFeatureEnabled } from '@/utils';
import { FEATURES } from '@/types/features.model';
// import { getTextColor } from '@/utils';

interface Props {
  className?: string;
  block: IShiftBlock;
}

const ShiftBlock: React.FC<Props> = ({ className, block }) => {
  const { t } = useTranslation();
  const { id, shortcode, name, background_color } = block;
  const {
    state: { activeDepartmentId, activeDepartment, activeSection, skills, userCategories, features },
  } = useContext(AppContext);
  const dispatch: Dispatch<ActionType> = useDispatch();
  const { monthly_selected_elements, selected_shifts_on_shift_key, startDate, endDate, usersMap, picker } = useSelector(
    ({
      monthly_selected_elements,
      selected_shifts_on_shift_key,
      startDate,
      endDate,
      usersMap,
      picker,
    }: InitialStateType) => ({
      monthly_selected_elements,
      selected_shifts_on_shift_key,
      usersMap,
      startDate,
      endDate,
      picker,
    }),
  );
  const [replaceShiftsWithSameValues, setReplaceShiftsWithSameValues] = useState<boolean>(false);

  const onClick = () => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const selected_ids: string[] = [];
    if (monthly_selected_elements.length > 0) {
      const data = monthly_selected_elements.map((el: HTMLElement) => {
        const target_id = el.id;
        selected_ids.push(target_id);
        const userRecordId = target_id.split('_')[0];
        const date = target_id.split('_')[1];
        return {
          userRecordId,
          date: +date / 1000,
        };
      });
      dispatch({
        type: 'SET_LOADING_ELEMENTS',
        payload: selected_ids,
      });
      const cancel_token_source: CancelTokenSource = axios.CancelToken.source();
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/shifts/month`,
          {
            shift_block_id: id,
            data,
          },
          {
            params: {
              departmentId: activeDepartmentId,
              picker: 'month',
              startDate: startDate.unix(),
              endDate: endDate.unix(),
              sectionId: isFeatureEnabled(features, FEATURES.SECTIONS)
                ? activeSection
                  ? activeSection
                  : undefined
                : undefined,
            },
            cancelToken: cancel_token_source.token,
          },
        )
        .then((res) => {
          if (res.data.result) {
            const recordIds = Object.keys(res.data.result);
            const data = Object.values(res.data.result);
            const errors: any[] = [];
            recordIds.forEach((id, index) => {
              const username = usersMap.get(id)?.displayName;
              const err = data[index];
              errors.push({ username, data: err });
            });
            if (errors.length > 0) {
              Modal.confirm({
                className: 'modal-constraints',
                title: t('SCHEDULE.CONSTRAINTS.TITLE'),
                icon: null,
                content: (
                  <ul>
                    {errors.map((error) => (
                      <li>
                        <div>{error.username}</div>
                        <ul>
                          {error.data.map((err: any) => (
                            <li>
                              {err.detail} : {t(`SCHEDULE.CONSTRAINTS.${err.type}`)}
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                ),
                cancelText: t('GLOBAL.CANCEL'),
                okText: t('GLOBAL.OK'),
                closable: true,
              });
            }
          }
          updateShiftsAndContextWithShiftResponse(dispatch, res, {
            department: activeDepartment!,
            picker,
            startDate,
            endDate,
            activeSection,
            skills,
            userStatus: userCategories,
          });
          monthly_selected_elements.map((el: Element) => el.classList.remove('highlight'));
          dispatch({
            type: 'SET_LOADING',
            payload: false,
          });
          dispatch({
            type: 'SET_LOADING_ELEMENTS',
            payload: null,
          });
          dispatch({
            type: 'SET_SELECTED_SHIFTS',
            payload: [],
          });
          dispatch({
            type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
            payload: null,
          });
          dispatch({
            type: 'SET_MONTHLY_SELECTED_ELEMENTS',
            payload: [],
          });
          dispatch({
            type: 'SET_MONTHLY_SELECTING_ELEMENTS',
            payload: false,
          });
        })
        .catch((err) => {
          dispatch({
            type: 'SET_LOADING',
            payload: false,
          });
          dispatch({
            type: 'SET_LOADING_ELEMENTS',
            payload: null,
          });
          dispatch({
            type: 'SET_SELECTED_SHIFTS',
            payload: [],
          });
          dispatch({
            type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
            payload: null,
          });
          dispatch({
            type: 'SET_MONTHLY_SELECTED_ELEMENTS',
            payload: [],
          });
          dispatch({
            type: 'SET_MONTHLY_SELECTING_ELEMENTS',
            payload: false,
          });
          if (err?.response?.data?.errors) {
            dispatch({
              type: 'SET_ERRORS',
              payload: Object.values(err.response.data.errors).flat(1) as string[],
            });
          }
        });
    } else if (selected_shifts_on_shift_key && selected_shifts_on_shift_key.length > 0) {
      dispatch({
        type: 'SET_MONTHLY_SHIFTS_TO_REPLACE',
        payload: selected_shifts_on_shift_key.map((shift) => shift.id!),
      });
      dispatch({
        type: 'SET_MONTHLY_SHIFTS_TO_REPLACE_SHIFT_BLOCK',
        payload: block.id,
      });
    } else {
      dispatch({
        type: 'SET_EDITING_SHIFT_BLOCK',
        payload: true,
      });
      dispatch({
        type: 'SET_ACTIVE_SHIFT_BLOCK',
        payload: block,
      });
    }
  };

  const onDragStart = (e: React.DragEvent) => {
    const { id } = block;
    (e.target as HTMLElement).style.opacity = '0.3';
    e.dataTransfer.setData('shift_block_id', id);
    e.dataTransfer.dropEffect = 'move';
  };

  const onDragEnd = (e: React.DragEvent) => {
    (e.target as HTMLElement).style.opacity = '1';
  };

  return (
    <div
      className={`${className} shift-block`}
      style={{
        backgroundColor: background_color,
        cursor: monthly_selected_elements.length > 0 ? 'pointer' : 'grab',
        color: getContrast(background_color),
      }}
      draggable
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onClick={onClick}
    >
      <span>
        {shortcode}-{name}
      </span>
    </div>
  );
};

export default styled(ShiftBlock)`
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
  max-height: 35px;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    display: block;
    overflow: hidden;
    font-size: 12px;
  }
`;
