import { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
import { PickerType } from '../hours/manage/redux/store';
import CustomDatePicker from './CustomDatePicker';

interface Props {
  className?: string;
  picker: PickerType;
  startDate: Moment;
  endDate: Moment;
  onArrowClick: (value: number) => void;
  onDatePickerChange: (date: moment.Moment | null) => void;
  disabledDate?: (date: Moment) => boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
}

const DateSelector: React.FC<Props> = ({
  className,
  picker,
  startDate,
  endDate,
  disabledDate,
  onArrowClick,
  onDatePickerChange,
  onKeyDown,
}) => {
  return (
    <div className={className}>
      <button onClick={() => onArrowClick(-1)} onKeyDown={onKeyDown}>
        <i className="icon-angle-left" />
      </button>
      {
        // ! The "picker" prop of <DatePicker /> is not dynamic, that's why we need those next lines
      }
      {(picker === 'day' || picker == 'operational') && (
        <CustomDatePicker
          disabledDate={disabledDate}
          picker={picker}
          startDate={startDate}
          endDate={endDate}
          onDatePickerChange={onDatePickerChange}
        />
      )}
      {picker === 'week' && (
        <CustomDatePicker
          picker={picker}
          startDate={startDate}
          endDate={endDate}
          onDatePickerChange={onDatePickerChange}
        />
      )}
      {picker === 'month' && (
        <CustomDatePicker
          picker={picker}
          startDate={startDate}
          endDate={endDate}
          onDatePickerChange={onDatePickerChange}
        />
      )}
      <button onClick={() => onArrowClick(1)} onKeyDown={onKeyDown}>
        <i className="icon-angle-right" />
      </button>
    </div>
  );
};

const DateSelectorStyled = styled(DateSelector)`
  display: flex;
  button {
    padding: 0;
    i {
      font-size: 22px;
      line-height: 12px;
    }
  }

  .datePicker {
    text-align: center;
    width: fit-content;
  }
`;

export default DateSelectorStyled;
