import { Button, Input, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  documentType: 'CONTRACT' | 'DOCUMENT';
  disabled: boolean;
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<Props> = ({ className, documentType, disabled, setFilterSearch }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();

  return (
    <header className={className}>
      <div className="left">
        <h2>{documentType === 'DOCUMENT' ? t('DOCUMENTS.TEMPLATES.TITLE') : t('CONTRACTS.TEMPLATES.TITLE')}</h2>
      </div>
      <div className="right">
        <div className="actions">
          <Space>
            <Input
              disabled={disabled}
              allowClear
              placeholder={t('GLOBAL.SEARCH')}
              suffix={<i className="icon-search"></i>}
              onChange={(e) => {
                setFilterSearch(e.target.value);
              }}
            />
            <Button type="primary" onClick={() => history.push('new')}>
              {t('GLOBAL.ADD')}
            </Button>
          </Space>
        </div>
      </div>
    </header>
  );
};

export default styled(Header)`
  .right {
    .ant-space {
      .ant-space-item {
        &:nth-child(1) {
          .ant-input-affix-wrapper {
            width: 200px;
          }
        }
        &:nth-child(2) {
          button {
            width: 150px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;

    .left {
      h2 {
        margin-left: 0 !important;
      }
    }

    .right {
      .actions {
        .ant-space {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;

          .ant-space-item {
            width: 100%;
            &:nth-child(1) {
              .ant-input-affix-wrapper {
                width: 100%;
              }
            }
            &:nth-child(2) {
              button {
                width: 100%;
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
`;
