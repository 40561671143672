import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IPendingRegistration } from '@/types/users/pending-registration';
import { handleError } from '@/utils';
import { Button, Form, Modal, Select, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
  registration: IPendingRegistration;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  get: () => void;
}

const ModalLinkToUser: React.FC<Props> = ({ className, visible, registration, setVisible, get }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, users, loadingUsers },
    dispatch,
  } = useContext(AppContext);
  const [form] = useForm();
  const [loadingLinkToUser, setLoadingLinkToUser] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const onCancel = () => {
    setVisible(false);
  };

  const onFinish = (values: any) => {
    setLoadingLinkToUser(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/users/pending/${registration.hash}/to-user`,
        {
          record_id: values.recordId,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
      .then((res) => {
        message.success(t('SELF_SERVICE.MESSAGES.MARKED_AS_COMPLETED'));
        get();
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setVisible(false);
        setLoadingLinkToUser(false);
      });
  };

  return (
    <Modal
      className={className}
      visible={visible}
      onCancel={onCancel}
      title={t('SELF_SERVICE.ACTIONS.LINK_TO_USER')}
      footer={null}
    >
      <span className="description">{t('SELF_SERVICE.MESSAGES.LINK_TO_USER_DESCRIPTION')}</span>
      <Form form={form} layout="vertical" onFinish={onFinish} className="form">
        <Form.Item name="recordId" label={t('GLOBAL.USER')}>
          <Select
            size="large"
            placeholder={t('GLOBAL.USER')}
            optionFilterProp="children"
            loading={loadingUsers}
            disabled={loadingUsers}
            showSearch
          >
            {users
              .filter((user) => !user.email?.startsWith('unknown+') && user.email !== registration.email)
              .map((user) => (
                <Select.Option key={user.recordId!} value={user.recordId!}>
                  {user.displayName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <div className="actions">
          <Button htmlType="button" type="primary" danger onClick={onCancel} disabled={loadingLinkToUser}>
            {t('GLOBAL.CANCEL')}
          </Button>
          <Button htmlType="submit" type="primary" loading={loadingLinkToUser} disabled={loadingLinkToUser}>
            {t('GLOBAL.CONFIRM')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default styled(ModalLinkToUser)`
  .description {
    font-size: 12px;
    color: ${colors.grey};
  }

  .form {
    margin-top: 15px;
    .actions {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
      margin-top: 25px;
    }
  }
`;
