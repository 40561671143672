import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { IUser } from '@/types/user.model';
import { BackTop, Table } from 'antd';
import Axios, { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import TableRowActions from './components/TableRowActions';
moment.tz.setDefault('Atlantic/Reykjavik');

const AppReportsAttendanceSheets: React.FC = () => {
  const {
    state: { activeDepartmentId, activeSection, loadingUsers, users },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [rangePickerValues, setRangePickerValues] = useState<[Moment, Moment]>([
    moment().startOf('month'),
    moment().endOf('month'),
  ]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    const [start, end] = rangePickerValues;
    const startFormatted = start.format('L');
    const endFormatted = end.format('L');

    setColumns([
      {
        title: t('GLOBAL.ID'),
        key: 'recordId',
        dataIndex: 'recordId',
      },
      {
        title: t('GLOBAL.NAME'),
        key: 'displayName',
        dataIndex: 'displayName',
      },
      {
        title: t('GLOBAL.USER_CATEGORY_SHORT'),
        key: 'status',
        render: (text: string, user: IUser) => {
          return user?.category?.name;
        },
      },
      {
        title: t('GLOBAL.ENTRY_DATE'),
        key: 'start',
        render: () => startFormatted,
      },
      {
        title: t('GLOBAL.RELEASE_DATE'),
        key: 'end',
        render: () => endFormatted,
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'actions',
        // eslint-disable-next-line react/display-name
        render: (text: string, user: IUser) => {
          return <TableRowActions recordId={user.recordId!} onDownload={onDownload} />;
        },
      },
    ]);
  }, [i18n.language, rangePickerValues]);

  const onDownload = (recordId: string): Promise<AxiosResponse<any>> => {
    const [start, end] = rangePickerValues;
    return Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/attendance-sheets`, {
      params: {
        departmentId: activeDepartmentId,
        sectionId: activeSection,
        start: start.unix(),
        end: end.unix(),
        recordId,
      },
    });
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header
        rangePickerValues={rangePickerValues}
        setRangePickerValues={setRangePickerValues}
        selectUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        loadingUsers={loadingUsers}
        users={users}
      />
      <Table
        dataSource={selectedUsers?.length ? users.filter((user) => selectedUsers.includes(user.recordId!)) : users}
        columns={columns}
        rowKey="recordId"
        pagination={false}
      />
    </TableView>
  );
};

export default AppReportsAttendanceSheets;
