import { setToken } from '@/actions';
import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { handleError } from '@/utils';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Divider, Form, Modal, Select, Spin } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { store as dashboardStore } from '../dashboard/redux/store';
import { store as manageStore } from '../hours/manage/redux/store';
import CheckoutForm from '../settings/billing/payment-details/CheckoutForm';
import ModalOpenInvoices from './ModalOpenInvoices';

const { Option } = Select;
interface Props {
  className?: string;
  visible: boolean;
}

const ModalAccountBlocked: React.FC<Props> = ({ className, visible }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { activeDepartmentId, departments, loadingDepartments },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const {
    dispatch: authContextDispatch,
    state: { impersonate },
  } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [showInvoices, setShowInvoices] = useState<boolean>(false);
  const history = useHistory();

  const myDepartments = departments?.filter((department) => department.role !== 'USER');

  const [changingPaymentMethod, setChangingPaymentMethod] = useState<boolean>(false);
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState<string>('');

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY!));
  }, []);

  const createPaymentIntent = (force: boolean = false) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/billing/stripe/create-setup-intent`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setClientSecret(data.clientSecret);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangePayementMethod = () => {
    setChangingPaymentMethod(true);
    createPaymentIntent();
  };

  const handleDepartmentChange = (value: string) => {
    if (value && activeDepartmentId !== value) {
      const department = departments?.find((x) => x.id === value);
      if (department) {
        appContextDispatch({
          type: 'SET_ACTIVE_DEPARTMENT',
          payload: department?.id,
        });
        authContextDispatch({
          type: 'SET_TRIAL_END_DATE',
          payload: department?.trialEndDate || null,
        });
        authContextDispatch({
          type: 'SET_BLOCKED',
          payload: department?.accountType === 'BLOCKED' ? true : false,
        });
        history.push('/app/dashboard');
      }
    }
  };

  const handleLogout = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (impersonate && accessToken) {
      sessionStorage.removeItem('impersonateToken');
      history.push('/app');
      setToken(authContextDispatch, accessToken);
    } else {
      history.push('/app');
      localStorage.clear();
      manageStore.dispatch({
        type: 'RESET',
      });
      dashboardStore.dispatch({
        type: 'RESET',
      });
      authContextDispatch({
        type: 'RESET',
      });
      appContextDispatch({
        type: 'RESET',
      });
    }
  };

  return (
    <Modal
      className={className}
      width={750}
      forceRender={true}
      closable={false}
      visible={visible}
      footer={null}
      style={{ top: changingPaymentMethod && !loading ? 10 : undefined }}
    >
      <h1 style={{ color: colors.red }}>{t('ACCOUNT.MODAL_BLOCKED_ACCOUNT.TITLE')}</h1>
      <p style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}>{t('ACCOUNT.MODAL_BLOCKED_ACCOUNT.CONTENT')}</p>
      <p style={{ whiteSpace: 'pre-wrap', color: colors.grey }}>{t('ACCOUNT.MODAL_BLOCKED_ACCOUNT.EXPLANATION')}</p>
      <Button type="link" style={{ color: colors.green }} onClick={() => setShowInvoices(true)}>
        {'> '}
        {t('ACCOUNT.MODAL_BLOCKED_ACCOUNT.DOWNLOAD')}
      </Button>
      {!changingPaymentMethod ? (
        <Button
          loading={loading}
          type="primary"
          size="large"
          onClick={onChangePayementMethod}
          style={{ margin: '15px 0', alignSelf: 'center' }}
        >
          {t('ACCOUNT.MODAL_BLOCKED_ACCOUNT.BUTTON')}
        </Button>
      ) : (
        <>
          {stripePromise && clientSecret ? (
            <>
              <Button
                type="link"
                onClick={() => setChangingPaymentMethod(false)}
                style={{ textAlign: 'left', paddingLeft: 20, marginRight: 'auto' }}
              >
                {'< '}
                {t('GLOBAL.BACK')}
              </Button>
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm
                  setupLoading={loading}
                  style={{ width: '100%', paddingTop: 10 }}
                  return_url="/app/dashboard?pm=true"
                />
              </Elements>
            </>
          ) : (
            <>
              {loading ? (
                <div
                  style={{
                    backgroundColor: '#FFF',
                    padding: 25,
                    borderRadius: 8,
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Spin spinning={loading} />
                </div>
              ) : null}
            </>
          )}
        </>
      )}
      {myDepartments?.length > 1 && (
        <Form layout="vertical">
          <Divider>{t('GLOBAL.OR').toLowerCase()}</Divider>
          <Form.Item label={t('FORMS.ACCOUNT_PLACEHOLDER')}>
            <Select
              getPopupContainer={(trigger) => trigger}
              disabled={loadingDepartments}
              loading={loadingDepartments}
              placeholder={t('FORMS.ACCOUNT_PLACEHOLDER')}
              onChange={handleDepartmentChange}
              size="large"
            >
              {myDepartments?.map((department) => {
                const { id, company } = department;
                return (
                  <Option key={`dept_${id}`} value={`${id}`}>
                    {company}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      )}
      <Button type="primary" danger size="large" style={{ width: '100%' }} onClick={handleLogout}>
        {t('GLOBAL.LOGOUT')}
      </Button>
      {visible && <ModalOpenInvoices visible={showInvoices} onClose={() => setShowInvoices(false)} />}
    </Modal>
  );
};

export default styled(ModalAccountBlocked)`
  .ant-modal-body {
    padding-top: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;

    h1 {
      font-weight: 600;
      margin-bottom: 20px;
    }
  }
`;
