import React, { Dispatch, useContext, useState } from 'react';
import styled from 'styled-components';
import Colors from '@/styles/colors';
import { ActionType, updateScheduleModelStore } from '../../redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InitialStateType, ITypicalWeekShift } from '../../redux/store';
import AppContext from '@/pages/app/context';
import { Dropdown, Menu, message } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import moment from 'moment';
import axios from 'axios';
import { IScheduleModel } from '@/types/schedule-model.model';

interface Props {
  className?: string;
  size: 'small' | 'large';
  shift: ITypicalWeekShift;
  style?: React.CSSProperties;
  scheduleModel: IScheduleModel;
  setScheduleModel: React.Dispatch<React.SetStateAction<IScheduleModel | null>>;
}

const RightClickMenu: React.FC<{
  shift: ITypicalWeekShift;
  scheduleModel: IScheduleModel;
  setScheduleModel: React.Dispatch<React.SetStateAction<IScheduleModel | null>>;
  close: () => void;
}> = ({ shift, scheduleModel, setScheduleModel, close }) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const dispatch: Dispatch<ActionType> = useDispatch();
  const { clipboardHistory } = useSelector(
    ({ clipboardHistory }: InitialStateType) => ({ clipboardHistory }),
    shallowEqual,
  );

  const handlePasteShift = (e: MenuInfo) => {
    dispatch({
      type: 'SET_IS_LOADING',
      payload: true,
    });
    if (clipboardHistory) {
      close();
      const originalShift = { ...clipboardHistory };
      const { timestamp, weekId } = shift;

      const differenceInDays = moment(timestamp).diff(moment.unix(originalShift.start!).startOf('day'), 'days');
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/users/schedule-models/${weekId}/shifts`,
          {
            ...removeUnnecessaryProperties(originalShift),
            start: moment.unix(originalShift.start!).add(differenceInDays, 'day').unix(),
            end: moment.unix(originalShift.end!).add(differenceInDays, 'day').unix(),
            departmentId: activeDepartmentId,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          const found = data.find((model: IScheduleModel) => model.id == scheduleModel.id);
          if (found) {
            setScheduleModel(found);
          }
          dispatch({
            type: 'SET_IS_LOADING',
            payload: false,
          });
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 422) {
              message.error(error.response?.data?.message);
            } else {
              message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
            }
          }
          console.log(error);
          dispatch({
            type: 'SET_IS_LOADING',
            payload: false,
          });
        });
    }

    e.domEvent.stopPropagation();
  };

  return (
    <Menu>
      <Menu.Item key="0" onClick={handlePasteShift} disabled={!clipboardHistory}>
        {t('SCHEDULE.PASTE_SHIFT(S)')}
      </Menu.Item>
    </Menu>
  );
};

const AddShift: React.FC<Props> = ({ className, size, shift, style, setScheduleModel, scheduleModel }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const dispatch: Dispatch<ActionType> = useDispatch();
  const { clipboardHistory } = useSelector(
    ({ clipboardHistory }: InitialStateType) => ({ clipboardHistory }),
    shallowEqual,
  );
  const [rightClickMenuVisible, setRightClickMenuVisible] = useState<boolean>(false);

  const onClick = () => {
    dispatch({
      type: 'SET_SELECTED_SHIFT',
      payload: shift,
    });
    dispatch({
      type: 'SET_SHIFT_DRAWER_VISIBLE',
      payload: true,
    });
  };

  if (clipboardHistory) {
    return (
      <div className={className} style={style}>
        <Dropdown
          overlay={() => (
            <RightClickMenu
              shift={shift}
              setScheduleModel={setScheduleModel}
              scheduleModel={scheduleModel}
              close={() => {
                setRightClickMenuVisible(false);
              }}
            />
          )}
          trigger={['contextMenu']}
          visible={rightClickMenuVisible}
          onVisibleChange={(e) => {
            setRightClickMenuVisible(e);
          }}
        >
          <button onClick={onClick}>
            <i>+</i> {size !== 'small' && <span>{t('SCHEDULE.ADD_SHIFT')}</span>}
          </button>
        </Dropdown>
      </div>
    );
  }
  return (
    <div className={className} style={style}>
      <button onClick={onClick}>
        <i>+</i> {size !== 'small' && <span>{t('SCHEDULE.ADD_SHIFT')}</span>}
      </button>
    </div>
  );
};

const AddShiftStyled = styled(AddShift)`
  display: flex;
  opacity: 0;
  height: 100%;
  width: 100%;
  padding-top: 5px;
  position: relative;

  @media print {
    display: none !important;
  }

  &:hover {
    opacity: 1;
    button {
      position: relative;
    }
  }

  button {
    position: absolute;
    display: flex;
    border: 1px dashed ${Colors.green};
    border-radius: 5px;
    background: white;
    align-items: center;
    justify-content: center;
    color: ${Colors.green};
    width: 100%;
    height: 100%;

    i {
      font-style: normal;
    }
  }
`;

export default AddShiftStyled;

function removeUnnecessaryProperties(shift: ITypicalWeekShift): any {
  const obj: any = { ...shift };
  delete obj.id;
  delete obj.day;
  delete obj.night;

  if (shift.skills) {
    obj.skills = shift.skills.map((skill) => skill.id);
  } else {
    obj.skills = [];
  }

  if (shift.attributes) {
    obj.attributes = shift.attributes.map((attribute) => attribute.id);
  } else {
    obj.attributes = [];
  }

  if (shift.tasks) {
    obj.tasks = shift.tasks.map((task) => ({
      taskTypeId: task.taskTypeId,
      start: task.start,
      end: task.end,
    }));
  } else {
    obj.tasks = [];
  }

  if (shift.section) {
    obj.section = shift.section.id;
  } else {
    obj.section = null;
  }

  if (shift.end && shift.start) {
    if (shift.end < shift.start) {
      obj.end = shift.end + 86400;
    }
  }

  return obj;
}
