import colors from '@/styles/colors';
import { WIDGETS } from '@/types/widget.model';
import { minutesToHoursAndOrMinutes } from '@/utils';
import { Table } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Callout from './Callout';

interface Props {
  departmentId?: string;
  sectionId?: string;
}

const columns = [
  {
    title: 'Date',
    key: 'date',
    // eslint-disable-next-line react/display-name
    render: (text: any, record: any) => {
      return <span>{moment.unix(record.start).format('L')}</span>;
    },
  },
  {
    title: 'Hours',
    key: 'hours',
    render: (text: any, record: any) => {
      if (record.overbook) {
        return <span style={{ color: '#004CA5' }}>Manque {minutesToHoursAndOrMinutes(record.minutes)}</span>;
      } else {
        return <span style={{ color: colors.red }}>{minutesToHoursAndOrMinutes(record.minutes)} en surbooking</span>;
      }
    },
  },
];

const ShiftsCheck: React.FC<Props> = ({ departmentId, sectionId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    if (!departmentId) {
      return;
    }

    setIsLoading(true);
    setDataSource([]);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/widgets/${WIDGETS.SHIFTS_CHECK}`, {
      params: {
        departmentId,
        sectionId,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        if (mounted) {
          setIsLoading(false);
          const { shifts } = response.data;
          setDataSource(shifts);
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [departmentId, sectionId]);

  return (
    <div>
      <h4>{t(`DASHBOARD.WIDGETS.SHIFTS_CHECK`)}</h4>
      {!!dataSource.length && (
        <Callout error={true}>{t('GLOBAL.X_ERRORS_FOUND', { count: dataSource.length })}</Callout>
      )}
      {!dataSource.length && <Callout>{t('GLOBAL.NO_ERROR_FOUND')}</Callout>}
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        showHeader={false}
        pagination={false}
        rowKey="id"
        size="small"
      />
    </div>
  );
};

export default ShiftsCheck;
