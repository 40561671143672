import { AlignType } from '@/types/alignType.model';
import { WIDGETS } from '@/types/widget.model';
import { Table } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Tag from '../../components/Tag';
import colors from '@/styles/colors';
import { useTranslation } from 'react-i18next';

interface Props {
  departmentId?: string;
  sectionId?: string;
}

const Clockings: React.FC<Props> = ({ departmentId, sectionId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const {
    t,
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    setColumns([
      {
        title: 'Name',
        dataIndex: 'displayName',
        key: 'displayName',
      },
      {
        title: 'Hours',
        key: 'hours',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          return (
            <span>
              {moment.unix(record.checkIn.start).format('HH:mm')} -{' '}
              <span style={{ color: !record.checkOut?.end ? colors.green : undefined }}>
                {record.checkOut?.end ? moment.unix(record.checkOut.end).format('HH:mm') : t('GLOBAL.IN_PROGRESS')}
              </span>
            </span>
          );
        },
      },
      {
        title: 'Section',
        key: 'section',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          const { section } = record;
          if (!section) {
            return null;
          }
          return <Tag color={section.color} name={section.name} background={section.background} />;
        },
      },
    ]);
  }, [language]);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    if (!departmentId) {
      return;
    }

    setIsLoading(true);
    setDataSource([]);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/widgets/${WIDGETS.CLOCKINGS}`, {
      params: {
        departmentId,
        sectionId,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        if (mounted) {
          setIsLoading(false);
          setDataSource(response.data || []);
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [departmentId, sectionId]);

  return (
    <React.Fragment>
      <h4>{t(`DASHBOARD.HEADER.CLOCKING_IN_PROGRESS`)}</h4>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        showHeader={false}
        pagination={false}
        rowKey="id"
        size="small"
      />
    </React.Fragment>
  );
};

export default Clockings;
