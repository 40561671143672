import React, { useEffect, useState, useContext, useRef } from 'react';
import AppContext from '@/pages/app/context';
import Axios from 'axios';
import { IDocumentTemplate } from '@/types/document.model';
import TemplatesList from './components/TemplatesList';
import Error404 from '@/components/Error404';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Template from './components/Template';

interface Props {
  documentType: 'CONTRACT' | 'DOCUMENT';
}

const AppDocumentsTemplates: React.FC<Props> = ({ documentType }) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  const [templates, setTemplates] = useState<IDocumentTemplate[]>([]);
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
  const [filteringTemplates, setFilteringTemplates] = useState<boolean>(false);
  const [filteredTemplates, setFilteredTemplates] = useState<IDocumentTemplate[]>([]);
  const [filterSearch, setFilterSearch] = useState<string>('');
  const { path, url } = useRouteMatch();

  const templatesRef = useRef<any[]>([]);
  templatesRef.current = templates;

  useEffect(() => {
    if (!location?.pathname || !location?.pathname.endsWith('all')) {
      return;
    }
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    setLoadingTemplates(true);
    setTemplates([]);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/templates`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
        filter: documentType,
      },
    })
      .then(({ data }) => {
        if (mounted) {
          setTemplates(data.templates);
          setLoadingTemplates(false);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingTemplates(false);
        }
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [location?.pathname, documentType]);

  useEffect(() => {
    setFilteringTemplates(true);
    if (templates) {
      const filteredDocuments = templates.filter((template) => {
        let showDocument = true;
        if (filterSearch) {
          const { id, name } = template;
          showDocument = [id, name].some((key) => {
            return key.toLowerCase().includes(filterSearch.toLowerCase());
          });
        }
        return showDocument;
      });
      setFilteredTemplates(filteredDocuments);
    } else {
      setFilteredTemplates([]);
    }
    setTimeout(() => {
      setFilteringTemplates(false);
    }, 0);
  }, [templates, filterSearch]);

  const onDeleteTemplate = (templateId: string) => {
    Axios.delete(`${process.env.REACT_APP_API_URL}/v3/documents/templates/${templateId}`, {
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then(() => {
        const templatesCopy = [...templatesRef.current];
        const index = templatesCopy.findIndex((x) => x.id === templateId);
        templatesCopy.splice(index, 1);
        setTemplates(templatesCopy);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Switch>
      <Route exact path={`${path}`} render={() => <Redirect to={`${url}/all`} />} />
      <Route path={`${path}/all`}>
        <TemplatesList
          documentType={documentType}
          filteredTemplates={filteredTemplates}
          filteringTemplates={filteringTemplates}
          loadingTemplates={loadingTemplates}
          setFilterSearch={setFilterSearch}
          onDeleteTemplate={onDeleteTemplate}
        />
      </Route>
      <Route path={`${path}/:templateId`}>
        <Template documentType={documentType} />
      </Route>
      <Route component={() => <Error404 />} />
    </Switch>
  );
};

export default AppDocumentsTemplates;
