import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Form, Button, DatePicker, Input, Row, Col, InputNumber, message } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment-timezone';
import { IClockingValidation } from '..';
import { isEqual } from 'lodash';
import { minutesToHoursAndMinutes2 } from '@/utils';
import colors from '@/styles/colors';
import { AxiosResponse } from 'axios';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  clocking: IClockingValidation | null;
  onClose: () => void;
  onAfterSaved: () => void;
  handleModify: (clockingId: string, values: any) => Promise<AxiosResponse<any>>;
  handleCopyFromShift: (clockingId: string) => Promise<AxiosResponse<any>>;
}

const ModalUpdateClocking: React.FC<Props> = ({ className, clocking, onClose, handleModify, handleCopyFromShift }) => {
  const [isCopying, setIsCopying] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    if (clocking) {
      form.resetFields();
      form.setFieldsValue({
        ...clocking,
        start: clocking?.start ? moment.unix(clocking.start) : undefined,
        end: clocking?.end ? moment.unix(clocking.end) : undefined,
      });
    }
  }, [clocking]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    const { start, end, breaks } = values;
    handleModify(clocking!.id, { start: start.unix(), end: end.unix(), breaks })
      .then(() => {
        setIsSaving(false);
        form.resetFields();
        onClose();
      })
      .catch((error) => {
        message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
        console.log(error);
      });
  };

  const onCancel = () => {
    Modal.confirm({
      title: t('GLOBAL.WARNING'),
      icon: null,
      content: t('SCHEDULE.CLOCKING_VERIFICATION.MODAL_EDIT_CLOCKING_CANCEL_VALIDATION.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.YES'),
      onOk: () => {
        if (isCopying || isSaving) {
          return;
        }
        form.resetFields();
        onClose();
      },
      onCancel: () => {},
    });
  };

  const onCopyFromShift = () => {
    setIsCopying(true);
    handleCopyFromShift(clocking!.id)
      .then(() => {
        setIsCopying(false);
        onClose();
      })
      .catch((error) => {
        setIsCopying(false);
        message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
        console.log(error);
      });
  };

  return (
    <Modal
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={!!clocking}
      onOk={() => form.submit()}
      onCancel={onCancel}
      title={t('CLOCKINGS.DRAWER_UPDATE_CLOCKING.TITLE')}
      footer={[
        <Button key={'cancel'} style={{ color: colors.grey, borderColor: colors.grey }} onClick={onCancel}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key={'copy'} loading={isCopying} onClick={onCopyFromShift}>
          <i className="icon-copy" />
        </Button>,
        <Button key={'modify'} type="primary" loading={isSaving} onClick={() => form.submit()}>
          {t('GLOBAL.MODIFY')}
        </Button>,
      ]}
    >
      {clocking && (
        <Form layout="vertical" form={form} onFinish={onFinish} className={className}>
          <Row style={{ margin: 0 }}>
            <Col span={12}>
              <Form.Item label={t('GLOBAL.USER')} style={{ marginRight: 10 }}>
                <Input value={clocking?.user?.displayName} disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('GLOBAL.USER_CATEGORY')}>
                <Input value={clocking?.user?.category?.name} disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ margin: 0 }}>
            <Col span={12}>
              <Form.Item className="one-line" label={t('GLOBAL.CONTRACT')} style={{ marginRight: 10 }}>
                <Input value={clocking?.contract ? clocking?.contract + t('GLOBAL.HOUR_UNIT') : ''} disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item className="one-line" label={t('GLOBAL.DATE')}>
                <Input value={moment.unix(clocking?.start).format('L')} disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          {clocking?.shift && (
            <React.Fragment>
              <Row style={{ margin: 0 }}>
                <Col span={12}>
                  <Form.Item
                    label={`${t('GLOBAL.SHIFT')} (${t('GLOBAL.START').toLowerCase()})`}
                    style={{ marginRight: 10 }}
                  >
                    <Input value={moment.unix(clocking?.shift?.start).format('L - HH:mm')} disabled={true} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={`${t('GLOBAL.SHIFT')} (${t('GLOBAL.END').toLowerCase()})`}
                    style={{ marginRight: 10 }}
                  >
                    <Input value={moment.unix(clocking?.shift?.end).format('L - HH:mm')} disabled={true} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label={`${t('GLOBAL.UNPAID_BREAK_SHORT')}`}>
                    <InputNumber
                      disabled={true}
                      value={clocking?.shift?.breaks || 0}
                      step={1}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>
          )}
          <Row style={{ margin: 0 }}>
            <Col span={12}>
              <Form.Item
                name="start"
                label={t('GLOBAL.START')}
                rules={[{ required: true }]}
                style={{ marginRight: 10 }}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  showTime
                  format={'L - HH:mm'}
                  onSelect={(e) => {
                    form.setFieldsValue({
                      start: e,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="end" label={t('GLOBAL.END')} rules={[{ required: true }]}>
                <DatePicker
                  style={{ width: '100%' }}
                  showTime
                  format={'L - HH:mm'}
                  onSelect={(e) => {
                    form.setFieldsValue({
                      end: e,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ margin: 0 }}>
            <Col span={12}>
              <Form.Item name="breaks" className="one-line" label={t('GLOBAL.BREAKS')} style={{ marginRight: 10 }}>
                <InputNumber step={1} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item className="one-line" label={t('SCHEDULE.TOTAL_WORKED_SHORT')}>
                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => !isEqual(prevValues, curValues)}>
                  {(form) => {
                    const values = form.getFieldsValue();
                    const { start, end, breaks = 0 } = values;
                    if (start && end) {
                      const total = values.end.unix() - values.start.unix() - breaks * 60;
                      if (total) {
                        return <Input value={minutesToHoursAndMinutes2(total / 60, true)} disabled={true} />;
                      } else {
                        return <Input value={minutesToHoursAndMinutes2(0, true)} disabled={true} />;
                      }
                    } else {
                      return <Input value="" disabled={true} />;
                    }
                  }}
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ margin: 0 }}>
            {clocking?.shift && (
              <Col span={12}>
                <Form.Item label="Diff.Plan." className="one-line" style={{ marginRight: 10 }}>
                  <Form.Item noStyle shouldUpdate={(prevValues, curValues) => !isEqual(prevValues, curValues)}>
                    {(form) => {
                      const values = form.getFieldsValue();
                      const { start, end, breaks = 0 } = values;
                      if (start && end) {
                        const totalClocking = values.end.unix() - values.start.unix() - breaks * 60;
                        const totalShift = (clocking?.shift?.end || 0) - (clocking?.shift?.start || 0);
                        const total = totalClocking - totalShift;
                        if (total) {
                          return <Input value={minutesToHoursAndMinutes2(total / 60, true)} disabled={true} />;
                        } else {
                          return <Input value={minutesToHoursAndMinutes2(0, true)} disabled={true} />;
                        }
                      } else {
                        return <Input value="" disabled={true} />;
                      }
                    }}
                  </Form.Item>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default styled(ModalUpdateClocking)`
  .ant-col .ant-form-item-label label {
    white-space: pre !important;
  }

  .one-line {
    .ant-form-item-label {
      padding: 0;
    }
  }

  .form-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-explain-error {
      display: none;
    }
  }
`;
