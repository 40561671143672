import { ITask } from '@/types/task.model';
import { createStore } from 'redux';
import reducer from './reducer';

export type InitialStateType = {
  totalEmployees: number[];
  totalHeadCounts: (number | null)[];
  totalBudgets: (number | null)[];
  totalOpenShifts: number[];
  provisionals: {
    total: number;
    items: number[];
  } | null;
  shiftIsDragging: boolean;
  taskIsDragging: { padding: number; task: ITask } | null;
  taskIsDragOver: { column: number } | null;
};

export const initialState: InitialStateType = {
  totalEmployees: [],
  totalHeadCounts: [],
  totalBudgets: [],
  totalOpenShifts: [],
  provisionals: null,
  shiftIsDragging: false,
  taskIsDragging: null,
  taskIsDragOver: null,
};

export const store = createStore(
  reducer,
  initialState,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
);
