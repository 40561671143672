import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IScheduleModel } from '@/types/schedule-models/schedule-model.model';
import { IUserParams } from '@/types/user-params.model';
import { Button, message, Modal } from 'antd';
import axios from 'axios';
import { uniqueId } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ApplyForm from './ApplyForm';
import WeekRow from './WeekRow';

interface Props {
  className?: string;
  scheduleModel: IScheduleModel;
  scheduleModels: IScheduleModel[];
  days: any;
  index: number;
  userParams: IUserParams;
  setScheduleModels: React.Dispatch<React.SetStateAction<IScheduleModel[]>>;
  setDays: React.Dispatch<React.SetStateAction<any[]>>;
  setActiveScheduleModel: React.Dispatch<React.SetStateAction<IScheduleModel | null>>;
  setFormHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScheduleModel: React.FC<Props> = ({
  className,
  scheduleModel,
  scheduleModels,
  days,
  index,
  userParams,
  setScheduleModels,
  setDays,
  setActiveScheduleModel,
  setFormHasChanged,
}) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const { activeDepartment } = state;
  const [applyModel, setApplyModel] = useState<boolean>(false);

  const has_weeks = scheduleModel.weeks && scheduleModel.weeks.length > 0;

  useEffect(() => {
    const found = userParams?.params?.schedule_models?.find((usm) => usm.id == scheduleModel.id);
    if (found) {
      setApplyModel(true);
    }
  }, [userParams]);

  const onAddWeek = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/users/schedule-models/${scheduleModel.id}/weeks`,
        {},
        {
          params: {
            departmentId: activeDepartment?.id,
          },
        },
      )
      .then(({ data }) => {
        setScheduleModels(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onApply = () => {
    setApplyModel(!applyModel);
    setFormHasChanged(true);
  };

  const onEdit = () => {
    setActiveScheduleModel(scheduleModel);
  };

  const onDelete = () => {
    Modal.confirm({
      className: 'modal-danger',
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('SCHEDULE_MODELS.MODAL_DELETE.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
      onOk: () => {
        return axios
          .delete(`${process.env.REACT_APP_API_URL}/v3/users/schedule-models/${scheduleModel.id}`, {
            params: {
              departmentId: activeDepartment?.id,
            },
          })
          .then(({ data }) => {
            message.success(t('SCHEDULE_MODELS.MESSAGES.DELETED'));
            setScheduleModels(data);
          })
          .catch((err) => {
            console.error(err);
          });
      },
    });
  };

  return (
    <div
      className={`${className} schedule-model`}
      style={{ backgroundColor: scheduleModels.length > 1 ? '#F4F4F4' : '#fff' }}
    >
      <p className="title">
        {t('SCHEDULE_MODELS.MODEL')} #{index + 1}: {scheduleModel.name}
      </p>
      {scheduleModel.weeks?.map((smw) => (
        <WeekRow sm={scheduleModel} smw={smw} setScheduleModels={setScheduleModels} days={days} setDays={setDays} />
      ))}
      <div className="actions">
        <div className="left">
          <Button type="dashed" htmlType="button" icon={<i className="icon-plus"></i>} onClick={onAddWeek}>
            {t('SCHEDULE_MODELS.ADD_WEEK')}
          </Button>
          {has_weeks && (
            <Button
              type="primary"
              color={colors.orange}
              htmlType="button"
              icon={<i className="icon-edit"></i>}
              onClick={onEdit}
            >
              {t('GLOBAL.MODIFY')}
            </Button>
          )}
          <Button
            type="primary"
            danger
            htmlType="button"
            icon={<i className="icon-trash-empty"></i>}
            onClick={onDelete}
          >
            {t('GLOBAL.REMOVE')}
          </Button>
          {has_weeks && (
            <Button
              type="primary"
              style={{ backgroundColor: colors.orange, borderColor: colors.orange }}
              onClick={onApply}
            >
              {!applyModel && t('SCHEDULE_MODELS.APPLY')}
            </Button>
          )}
        </div>
      </div>
      {applyModel ? <ApplyForm key={uniqueId()} sm={scheduleModel} onApply={onApply} /> : null}
    </div>
  );
};

export default styled(ScheduleModel)`
  border: 1px solid #efefef;
  padding: 25px 15px;
  margin-bottom: 25px;

  .title {
    text-decoration: underline;
  }
`;
