import React, { useEffect, useState, useContext } from 'react';
import { Table, Tooltip, message } from 'antd';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { IUser } from '@/types/user.model';
import axios from 'axios';
import { Link } from 'react-router-dom';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  selectedUsers: string[];
  rangePickerValues: [Moment, Moment];
  userRecordId?: string;
}

const WorkedHours: React.FC<Props> = ({ selectedUsers, rangePickerValues, userRecordId }) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);
  const [extraColumns, setExtraColumns] = useState<IColumn[]>([]);
  const [rows, setRows] = useState<IRow[]>([]);
  const [filteredRows, setFilteredRows] = useState<IRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const [start, end] = rangePickerValues;

    if (activeDepartmentId && start && end) {
      let mounted = true;
      const cancelTokenSource = axios.CancelToken.source();
      const [startMoment, endMoment] = rangePickerValues;

      setLoading(true);
      setRows([]);
      setColumns([]);

      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/reports/worked-hours/users`, {
          cancelToken: cancelTokenSource.token,
          params: {
            departmentId: activeDepartmentId,
            start: startMoment.format('YYYY-MM-DD'),
            end: endMoment.format('YYYY-MM-DD'),
            userRecordId,
          },
        })
        .then(({ data }) => {
          if (mounted) {
            setExtraColumns(data.columns);
            setRows(data.rows);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
          if (mounted) {
            setLoading(false);
            message.error(t('GLOBAL.MESSAGE_DOWNLOAD_ERROR'));
          }
        });

      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [activeDepartmentId, rangePickerValues, userRecordId]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      ...(!userRecordId
        ? [
            {
              title: t('GLOBAL.NAME'),
              key: 'displayName',
              className: 'displayName',
              dataIndex: ['user', 'displayName'],
              render: (text: any, record: IRow) => {
                const { recordId, displayName } = record.user;
                if (recordId) {
                  return <Link to={`/app/team/collaborators/${recordId}`}>{displayName}</Link>;
                } else {
                  return <span>{displayName}</span>;
                }
              },
            },
          ]
        : []),
      ...extraColumns?.map(({ title, code, id, background }) => ({
        title: (
          <Tooltip title={title}>
            <span>{code}</span>
          </Tooltip>
        ),
        key: `columns.${id}`,
        align: 'center',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IRow) => {
          return {
            // props: {
            //   style: {
            //     backgroundColor: background,
            //   },
            // },
            children: <div>{record.columns[id]}</div>,
          };
        },
        onHeaderCell: (column: any) => {
          return {
            style: {
              background: background,
            },
          };
        },
      })),
    ]);
  }, [i18n.language, extraColumns, userRecordId]);

  useEffect(() => {
    setFilteredRows(
      rows.filter((x) => {
        if (selectedUsers.length) {
          if (selectedUsers.includes(x.user.recordId || '')) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }),
    );
  }, [selectedUsers, rows, userRecordId]);

  return (
    <Table
      loading={loading}
      dataSource={filteredRows}
      columns={columns}
      rowKey={(x) => x.user.recordId!}
      pagination={false}
    />
  );
};

export default WorkedHours;

interface IColumn {
  id: string;
  title: string;
  code: string;
  background: string;
}

interface IRow {
  user: IUser;
  columns: {
    [key: string]: string | number;
  };
}
