import React, { useEffect, useState, useContext, useRef } from 'react';
import { Table, BackTop, message, Tooltip } from 'antd';
import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import Header from './components/Header';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { IUser } from '@/types/user.model';
import ModalAddCustomColumn from './components/ModalAddCustomColumn';
import { Link } from 'react-router-dom';
moment.tz.setDefault('Atlantic/Reykjavik');
import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import colors from '@/styles/colors';

interface IActivityReportsCustomColumnsResponse {
  id: string;
  name: string;
  code: string;
}

interface IActivityReportsDataResponse {
  id: string;
  user: IUser | any;
  userCategory: string;
  start: number;
  end: number;
  hours: number;
  dayoffs: number;
  recovery: number;
  publicHolidays: number;
  customColumns?: {
    [key: string]: number;
  };
}

interface Props {
  className?: string;
}

const AppReportsActivityReports: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartment, activeDepartmentId, activeSection },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [rangePickerValues, setRangePickerValues] = useState<[Moment, Moment]>([
    moment().startOf('month'),
    moment().endOf('month'),
  ]);
  const [showModalAddCustomColumn, setShowModalAddCustomColumn] = useState<boolean>(false);
  const [userRecordId, setUserRecordId] = useState<string | null>(null);
  const [userCategoryId, setUserCategoryId] = useState<string | null>(null);
  const [groupBy, setGroupBy] = useState<string>('WEEK');
  const [type, setType] = useState<string>('SHIFT');
  const [defaultColumns, setDefaultColumns] = useState<any[]>([]);
  const [customColumns, setCustomColumns] = useState<any[]>([]);
  const [data, setData] = useState<IActivityReportsDataResponse[]>([]);
  const [counter, setCounter] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const counterRef = useRef<number>(counter);
  counterRef.current = counter;

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setDefaultColumns([
      {
        title: t('GLOBAL.NAME'),
        width: 180,
        key: 'user',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IActivityReportsDataResponse) => {
          const { recordId, displayName, weekWorkHours } = record?.user || {};
          const name = weekWorkHours ? `${displayName} (${weekWorkHours})` : displayName;
          if (recordId) {
            return <Link to={`/app/team/collaborators/${recordId}`}>{name}</Link>;
          } else {
            return <span>{name}</span>;
          }
        },
      },
      {
        title: t('GLOBAL.USER_CATEGORY_SHORT'),
        width: 140,
        key: 'userCategory',
        dataIndex: 'userCategory',
      },
      {
        title: t('GLOBAL.START'),
        width: 130,
        key: 'start',
        sorter: (a: IActivityReportsDataResponse, b: IActivityReportsDataResponse) => b?.start - a?.start,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IActivityReportsDataResponse) => {
          const start = record?.start;
          const startMoment = moment.unix(start);
          if (start) {
            return (
              <Link
                to={`/app/hours/manage/${
                  activeDepartment?.scheduleParams?.default_schedule_view == 'ops'
                    ? 'operational'
                    : activeDepartment?.scheduleParams?.default_schedule_view
                }/${startMoment.format('YYYY-MM-DD')}`}
              >
                <span>{startMoment.format('L')}</span>
              </Link>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: t('GLOBAL.END'),
        key: 'end',
        width: 130,
        sorter: (a: IActivityReportsDataResponse, b: IActivityReportsDataResponse) => (b?.end || 0) - (a?.end || 0),
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IActivityReportsDataResponse) => {
          const end = record?.end;
          if (end) {
            return <span>{moment.unix(end).format('L')}</span>;
          } else {
            return null;
          }
        },
      },
      {
        width: 80,
        title: (
          <Tooltip title={t('GLOBAL.HOURS_WORKED')}>
            <span>{t('GLOBAL.HOURS_WORKED_SHORT')}</span>
          </Tooltip>
        ),
        key: 'hours',
        dataIndex: 'hours',
      },
      {
        width: 80,
        title: (
          <Tooltip title={t('GLOBAL.OVERTIME')}>
            <span>{t('GLOBAL.OVERTIME_SHORT')}</span>
          </Tooltip>
        ),
        key: 'extrahours',
        dataIndex: 'extrahours',
      },
      {
        width: 80,
        title: (
          <Tooltip title={t('GLOBAL.ABSENCES')}>
            <span>{t('GLOBAL.ABSENCES_SHORT')}</span>
          </Tooltip>
        ),
        key: 'dayoffs',
        dataIndex: 'dayoffs',
      },
      {
        width: 80,
        title: (
          <Tooltip title={t('GLOBAL.VACATION_DAY')}>
            <span>{t('GLOBAL.VACATION_DAY_SHORT')}</span>
          </Tooltip>
        ),
        key: 'vacation',
        dataIndex: 'vacation',
      },
      {
        width: 80,
        title: (
          <Tooltip title={t('GLOBAL.PUBLIC_HOLIDAYS')}>
            <span>{t('GLOBAL.PUBLIC_HOLIDAYS_SHORT')}</span>
          </Tooltip>
        ),
        key: 'publicHolidays',
        dataIndex: 'publicHolidays',
      },
      {
        width: 80,
        title: (
          <Tooltip title={t('GLOBAL.RECOVERY')}>
            <span>{t('GLOBAL.RECOVERY_SHORT')}</span>
          </Tooltip>
        ),
        key: 'recovery',
        dataIndex: 'recovery',
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    if (activeDepartmentId) {
      setCounter(counterRef.current + 1);
    }
  }, [activeDepartmentId, activeSection, groupBy, type, rangePickerValues, userRecordId, userCategoryId]);

  useEffect(() => {
    if (!counter) {
      return;
    }
    setIsLoading(true);
    setData([]);
    setCustomColumns([]);
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    const [startMoment, endMoment] = rangePickerValues;

    Axios.get(`${process.env.REACT_APP_API_URL}/v3/reports/activity-reports`, {
      params: {
        departmentId: activeDepartmentId,
        sectionId: activeSection,
        groupBy,
        type,
        userRecordId,
        userCategoryId,
        start: startMoment.unix(),
        end: endMoment.unix(),
      },
    })
      .then((response) => {
        if (mounted) {
          const { data, customColumns } = response.data;
          setData(data);
          setCustomColumns([
            ...customColumns?.map((customColumn: IActivityReportsCustomColumnsResponse) => {
              const { id, name, code } = customColumn;
              return {
                width: 80,
                title: (
                  <Tooltip title={name}>
                    <span>
                      {code} <DeleteOutlined className="delete-icon" onClick={() => onDeleteCustomColumn(id)} />
                    </span>
                  </Tooltip>
                ),
                key: `custom_${id}`,
                dataIndex: ['customColumns', `${id}`],
              };
            }),
          ]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (mounted && !Axios.isCancel(error)) {
          console.log(error);
          message.error(t('REPORTS.ACTIVITY_REPORT.MESSAGE_LOADING_ERROR'));
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [counter, defaultColumns]);

  const onDeleteCustomColumn = (customColumnId: string) => {
    setIsLoading(true);
    Axios.delete(`${process.env.REACT_APP_API_URL}/v3/reports/activity-reports`, {
      params: {
        departmentId: activeDepartmentId,
        customColumnId,
      },
    })
      .then(() => {
        setCounter(counterRef.current + 1);
      })
      .catch(() => {
        message.error(t('REPORTS.ACTIVITY_REPORT.MESSAGE_COUNTER_DELETION_ERROR'));
      });
  };

  const onDownload = () => {
    const [startMoment, endMoment] = rangePickerValues;
    setIsDownloading(true);
    Axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/v3/operations/export-activity-reports`,
      responseType: 'arraybuffer',
      params: {
        departmentId: activeDepartmentId,
        sectionId: activeSection,
        groupBy,
        type,
        userRecordId,
        userCategoryId,
        start: startMoment.unix(),
        end: endMoment.unix(),
      },
    })
      .then((response) => {
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileURL = URL.createObjectURL(file);
        if (fileURL) {
          window.open(fileURL);
        }
        setIsDownloading(false);
      })
      .catch((error) => {
        console.error(error);
        message.error(t('GLOBAL.MESSAGE_DOWNLOAD_ERROR'));
        setIsDownloading(false);
      });
  };

  return (
    <TableView className={className}>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header
        rangePickerValues={rangePickerValues}
        setRangePickerValues={setRangePickerValues}
        userRecordId={userRecordId}
        setUserRecordId={setUserRecordId}
        userCategoryId={userCategoryId}
        setUserCategoryId={setUserCategoryId}
        groupBy={groupBy}
        setGroupBy={setGroupBy}
        type={type}
        setType={setType}
        setShowModalAddCustomColumn={setShowModalAddCustomColumn}
        onDownload={onDownload}
        isDownloading={isDownloading}
      />
      <Table
        scroll={{ y: 'calc(100vh - 200px)', x: 1000 + customColumns?.length * 80 }}
        loading={isLoading}
        dataSource={data}
        columns={[...defaultColumns, ...customColumns]}
        rowKey="id"
        pagination={false}
      />
      <ModalAddCustomColumn
        departmentId={activeDepartmentId}
        visible={showModalAddCustomColumn}
        onClose={() => setShowModalAddCustomColumn(false)}
        onSave={() => setCounter(counter + 1)}
      />
    </TableView>
  );
};

export default styled(AppReportsActivityReports)`
  .delete-icon {
    margin-left: 5px;
    cursor: pointer;
    color: ${colors.grey};
  }
`;
