import React from 'react';
import Colors from '@/styles/colors';
import styled from 'styled-components';
import AddShift from '@/pages/app/hours/manage/components/AddShift';
import moment, { Moment } from 'moment';

interface Props {
  className?: string;
  totalShifts?: number[];
  hideShifts: boolean;
  userRecordId: string;
  hours: number[];
  activeSection?: string;
  selectedDate: Moment;
}

const ShiftsBackground: React.FC<Props> = ({
  className,
  hideShifts,
  userRecordId,
  totalShifts,
  hours,
  activeSection,
  selectedDate,
}) => {
  return (
    <div className={className}>
      {hours.map((hour, hourIndex) => {
        const outOfBounds = !moment.unix(hour).isSame(selectedDate, 'day');
        return (
          <div className={`hour ${outOfBounds ? 'out-of-bounds' : ''}`} key={`bg_hour_${hourIndex}`}>
            {!hideShifts && (
              <AddShift
                size="small"
                shift={{
                  userRecordId,
                  start: hour,
                  timestamp: hour,
                  end: moment.unix(hour).add(3, 'hour').unix(),
                  section: activeSection ? { id: activeSection } : undefined,
                }}
              />
            )}
            {hideShifts && totalShifts && totalShifts[hourIndex] > 0 && (
              <div className="shift-counter">{totalShifts[hourIndex]}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const ShiftsBackgroundStyled = styled(ShiftsBackground)`
  position: absolute;
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;

  > .hour {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0px;
    flex-shrink: 0;
    box-sizing: border-box;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid ${Colors.blueLight};

    &.out-of-bounds {
      background: ${Colors.greyLight} !important;
    }

    &:nth-child(n + 2) {
      border-left: 1px solid ${Colors.blueLight};
    }

    > .shift-counter {
      background: rgba(0, 0, 0, 0.1);
      padding: 0px 10px;
      border-radius: 5px;
    }
  }
`;

export default ShiftsBackgroundStyled;
