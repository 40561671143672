import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Image, Modal, Radio, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { saveAs } from 'file-saver'; // npm install file-saver
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IVacationRequest } from '..';
import Availabilities from './Availabilities';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
  request: IVacationRequest | null;
  onClose: () => void;
  onChangeStatus: (id: string, status: string, params?: any) => Promise<void>;
}

const ModalDetail: React.FC<Props> = ({ className, request, onClose, onChangeStatus }) => {
  const {
    state: { dayoffs, activeDepartment, activeDepartmentId },
  } = useContext(AppContext);
  const [form] = useForm();
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [isDeclining, setIsDeclining] = useState<boolean>(false);
  const [isAccepting, setIsAccepting] = useState<boolean>(false);

  useEffect(() => {
    if (request) {
      setIsAccepting(false);
      setIsDeclining(false);
    }
    form.resetFields();
  }, [request]);

  const onWantDecline = () => {
    setIsDeclining(true);
    onChangeStatus(request!.id!, 'DECLINED')
      .then(() => {
        onClose();
      })
      .finally(() => {
        setIsDeclining(false);
      });
  };

  const onWantAccept = (params: any) => {
    setIsAccepting(true);
    onChangeStatus(request!.id!, 'ACCEPTED', params)
      .then(() => {
        onClose();
      })
      .finally(() => {
        setIsAccepting(false);
      });
  };

  const onFinish = (values: any) => {
    onWantAccept({ ...values });
  };

  const handleDownloadClick = (imageUrl: string) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, 'image.jpg'); // Specify filename for download
      })
      .catch((error) => {
        console.error('Error downloading image:', error);
      });
  };

  return (
    <Modal
      className={className}
      visible={!!request}
      onCancel={onClose}
      title={t('VACATION_REQUESTS.MODAL_DETAIL.TITLE')}
      getContainer={false}
      forceRender
      width={600}
      footer={
        request?.status === 'PENDING'
          ? [
              <Button loading={isDeclining} key="decline" type="primary" danger onClick={onWantDecline}>
                {t('GLOBAL.DECLINE')}
              </Button>,
              <Button loading={isAccepting} key="accept" type="primary" onClick={form.submit}>
                {t('GLOBAL.ACCEPT')}
              </Button>,
            ]
          : null
      }
    >
      {!!request && (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <p className="infos">
            <span className="displayName">{request.user.displayName}</span>
            {request.fullDay && (
              <span className="date">
                {t('GLOBAL.FROM_DATE_TO_DATE', {
                  date1: moment.unix(request.start).format('L'),
                  date2: moment.unix(request.end).format('L'),
                })}
              </span>
            )}
            {!request.fullDay && (
              <span className="date">
                {moment.unix(request.start).format('LL')}{' '}
                {t('GLOBAL.FROM_HOUR_TO_HOUR', {
                  hour1: moment.unix(request.start).format('HH:mm'),
                  hour2: moment.unix(request.end).format('HH:mm'),
                })}
              </span>
            )}
          </p>
          <div className="reason">
            <span className="type">{request.dayoffType.name}</span>
          </div>
          {request?.status === 'PENDING' && (
            <React.Fragment>
              <Form.Item name="dayoffId" label={t('VACATION_REQUESTS.MODAL_DETAIL.CHANGE_DAYOFF_TYPE')}>
                <Select
                  getPopupContainer={(trigger) => trigger}
                  placeholder={t('VACATION_REQUESTS.MODAL_DETAIL.CHANGE_DAYOFF_TYPE')}
                  optionFilterProp="children"
                >
                  {dayoffs.map((dayoff) => (
                    <Option value={dayoff.id!} key={`dayoff_${dayoff.id}`}>
                      {dayoff.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="comment" label={t('GLOBAL.COMMENT')}>
                <TextArea rows={2} />
              </Form.Item>
              <Form.Item name="paid" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>{t('GLOBAL.PAID_LEAVE')}</Checkbox>
              </Form.Item>
            </React.Fragment>
          )}
          {!!request?.files?.length && (
            <React.Fragment>
              <Divider orientation="left" plain>
                {t('GLOBAL.FILES')}
              </Divider>
              <div className="pictures">
                <Image.PreviewGroup>
                  {request?.files?.map((image, index) => (
                    <div
                      key={`image_${index}`}
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}
                    >
                      <div style={{ flex: 1 }}>
                        <Image src={image} preview={true} width={100} height={100} style={{ objectFit: 'cover' }} />
                      </div>
                      <div>
                        <Button
                          type="primary"
                          icon={<DownloadOutlined />}
                          onClick={() => handleDownloadClick(image)} // Define a function to handle download
                        >
                          {t('GLOBAL.DOWNLOAD')}
                        </Button>
                      </div>
                    </div>
                  ))}
                </Image.PreviewGroup>
              </div>
            </React.Fragment>
          )}

          {request?.status === 'PENDING' && <Availabilities request={request} departmentId={activeDepartmentId} />}
          {!!request?.currentShifts?.length && request?.status === 'PENDING' && (
            <Form.Item
              label={t('VACATION_REQUESTS.MODAL_DETAIL.MULTIPLE_SHIFTS_QUESTION', {
                count: request?.currentShifts?.length,
              })}
              name="option"
              rules={[{ required: true }]}
              style={{ marginTop: 20, fontWeight: 'bold' }}
            >
              <Radio.Group style={{ fontWeight: 'normal' }}>
                <Radio value="FREE_SHIFT">{t('VACATION_REQUESTS.MODAL_DETAIL.FREE_SHIFT')}</Radio>
                <Radio value="DELETE">{t('VACATION_REQUESTS.MODAL_DETAIL.DELETE')}</Radio>
                <Radio value="SPLIT">{t('VACATION_REQUESTS.MODAL_DETAIL.SPLIT')}</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          {/* {!!request?.currentShifts?.length && request?.status === 'PENDING' && (
            <React.Fragment>
              <Divider orientation="left" plain>
                {t('GLOBAL.SHIFTS')}
              </Divider>
              <ul>
                {request?.currentShifts?.map((shift, index) => {
                  const { start, end } = shift;
                  return (
                    <li key={`shift_${index}`}>{`${moment.unix(start).format('L')} ${moment
                      .unix(start)
                      .format('HH:mm')}-${moment.unix(end).format('HH:mm')}`}</li>
                  );
                })}
              </ul>
              <Form.Item label="Option" name="option" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value="FREE_SHIFT">{t('VACATION_REQUESTS.MODAL_DETAIL.FREE_SHIFT')}</Radio>
                  <Radio value="DELETE">{t('VACATION_REQUESTS.MODAL_DETAIL.DELETE')}</Radio>
                </Radio.Group>
              </Form.Item>
            </React.Fragment>
          )} */}
        </Form>
      )}
      {!request && <Form form={form} />}
    </Modal>
  );
};

export default styled(ModalDetail)`
  p.infos {
    text-align: center;
    > span {
      display: block;
      &.displayName {
        font-size: 14px;
      }
      &.department {
        color: ${colors.grey};
      }
    }
  }

  .reason {
    border-radius: 3px;
    background: ${colors.greyLight};
    text-align: center;
    padding: 15px;
    margin-bottom: 10px;
    > span {
      display: block;
      &.type {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .pictures {
    display: flex;
    overflow: auto;
    .ant-image:nth-of-type(n + 2) {
      margin-left: 5px;
      flex-shrink: 0;
    }
  }
`;
