import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IShiftRequest } from '@/types/shift-request.model';
import { Button, Space, Table, Tooltip } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DisplayNameColumn from '../../../components/DisplayNameColumn';

moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  loading: boolean;
  dataSource: IShiftRequest[];
  displayName?: boolean;
  setShiftRequest?: React.Dispatch<React.SetStateAction<IShiftRequest | null>>;
}

const ShiftRequestsTable: React.FC<Props> = ({ className, loading, dataSource, displayName, setShiftRequest }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const { state: appContextState, dispatch: appContextDispatch } = useContext(AppContext);
  const { sections, activeSection } = appContextState;
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('SCHEDULE.CHANGE_REQUESTS.SHIFT_DATE'),
        key: 'date',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shiftRequest: IShiftRequest) => {
          const { shift } = shiftRequest;

          return (
            <Space>
              <span>{moment.unix(shift!.start!).format('L')}</span>
              {!activeSection && shift?.section && typeof shift.section === 'number' && (
                <span className="section">
                  {sections.find((section) => section.id === (shift as any).section?.toString())?.name}
                </span>
              )}
            </Space>
          );
        },
        sorter: (a: IShiftRequest, b: IShiftRequest) => a.shift!.start! - b.shift!.start!,
      },
      {
        title: t('SCHEDULE.CHANGE_REQUESTS.SHIFT_HOUR'),
        key: 'hour',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shiftRequest: IShiftRequest) => {
          const { shift } = shiftRequest;
          const { start, end } = shift!;
          return <Space>{`${moment.unix(start!).format('HH:mm')} - ${moment.unix(end!).format('HH:mm')}`}</Space>;
        },
      },
      {
        title: t('GLOBAL.TYPE'),
        key: 'requestType',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shiftRequest: IShiftRequest) => {
          if (shiftRequest.dayoffType) {
            return shiftRequest.dayoffType;
          }

          return t(`SCHEDULE.CHANGE_REQUESTS.TYPES.${shiftRequest.requestType}`);
        },
      },
      {
        title: t('SCHEDULE.CHANGE_REQUESTS.REQUEST_STATUS'),
        key: 'status',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shiftRequest: IShiftRequest) => {
          return t(`SCHEDULE.CHANGE_REQUESTS.STATUS.${shiftRequest.status}`);
        },
      },
      {
        title: t('SCHEDULE.CHANGE_REQUESTS.REQUEST_DATE'),
        key: 'requestDate',
        // eslint-disable-next-line react/display-name
        render: (_text: string, shiftRequest: IShiftRequest) => {
          return moment.unix(shiftRequest.created!).format('L HH:MM');
        },
        sorter: (a: IShiftRequest, b: IShiftRequest) => a.created! - b.created!,
      },
    ]);
  }, [i18n.language, activeSection, sections]);

  if (displayName) {
    columns.unshift({
      title: t('GLOBAL.NAME'),
      key: 'displayName',
      // eslint-disable-next-line react/display-name
      render: (_text: string, shiftRequest: IShiftRequest) => {
        const { shift } = shiftRequest;
        return <DisplayNameColumn user={shift!.user!} />;
      },
      sorter: (a: IShiftRequest, b: IShiftRequest) =>
        a.shift?.user?.displayName?.localeCompare(b.shift?.user?.displayName || '') || 0,
    });
  }

  if (setShiftRequest) {
    columns.push({
      key: 'actions',
      align: 'left',
      // eslint-disable-next-line react/display-name
      render: (_text: string, shiftRequest: IShiftRequest) => {
        if (shiftRequest?.status == 'REQUEST_OPEN') {
          return (
            <>
              {shiftRequest.files && shiftRequest.files.length > 0 && (
                <Tooltip overlay={t('SCHEDULE.CHANGE_REQUESTS.REQUEST_INCLUDES_FILES')}>
                  <i className="icon-doc-text-inv" style={{ marginRight: 5 }} />
                </Tooltip>
              )}
              <Button type="primary" onClick={() => setShiftRequest(shiftRequest)}>
                {t('GLOBAL.TREAT')}
              </Button>
            </>
          );
        } else {
          return (
            <>
              {shiftRequest.files && shiftRequest.files.length > 0 && (
                <Tooltip overlay={t('SCHEDULE.CHANGE_REQUESTS.REQUEST_INCLUDES_FILES')}>
                  <i className="icon-doc-text-inv" style={{ marginRight: 5 }} />
                </Tooltip>
              )}
              <Button type="ghost" onClick={() => setShiftRequest(shiftRequest)} style={{ alignSelf: 'end' }}>
                <i className="icon-eye" />
              </Button>
            </>
          );
        }
      },
    });
  }

  return (
    <Table
      className={className}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      rowKey="id"
      pagination={false}
      rowClassName={(record: any) => record.status}
    />
  );
};

export default styled(ShiftRequestsTable)`
  .REQUEST_APROVED {
    &:hover,
    &::selection,
    & {
      background-color: ${colors.greenExtraLight};
      > td {
        background-color: ${colors.greenExtraLight};
      }
    }
  }

  .REQUEST_REJECTED {
    &:hover,
    &::selection,
    & {
      background-color: ${colors.redExtraLight};
      > td {
        background-color: ${colors.redExtraLight};
      }
    }
  }

  .REQUEST_TRANSFERED {
    &:hover,
    &::selection,
    & {
      background-color: ${colors.blueExtraLight};
      > td {
        background-color: ${colors.blueExtraLight};
      }
    }
  }

  .section {
    background-color: rgb(96, 205, 255);
    padding: 2px 5px;
    border-radius: 5px;
  }
`;
