import colors from '@/styles/colors';
import { AlignType } from '@/types/alignType.model';
import { IDepartment } from '@/types/department.model';
import { IUser } from '@/types/user.model';
import { isFeatureEnabled, minutesToHoursAndMinutes } from '@/utils';
import { SyncOutlined } from '@ant-design/icons';
import { Button, message, Modal, Table, Tooltip } from 'antd';
import Axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ModalAdjustment from './ModalAdjustment';
import ModalWeekDetails from './ModalWeekDetails';
import { FEATURES } from '@/types/features.model';
import AppContext from '@/pages/app/context';

moment.tz.setDefault('Atlantic/Reykjavik');

interface IUserExtraHour {
  start: number;
  end: number;
  period: number;
  week: number;
  totalPlanned: number;
  totalClocking: number;
  balance: number;
  total: number;
  label: string;
  adjust: {
    id: string;
    adjusted: number;
    comment: string;
  } | null;
}

interface Props {
  className?: string;
  user?: IUser;
  department?: IDepartment;
}

const ExtraHours: React.FC<Props> = ({ className, user, department }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { features },
  } = useContext(AppContext);
  const [recup, setRecup] = useState<number>(0);
  const [extraHours, setExtraHours] = useState<IUserExtraHour[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [weekDetails, setWeekDetails] = useState<any>({});
  const [selectedWeek, setSelectedWeek] = useState<{ title: string; dataSource: any[] } | null>(null);
  const [viewMode, setViewMode] = useState<number>(1);
  const [periodToAdjust, setPeriodToAdjust] = useState<number | null>(null);

  useEffect(() => {
    moment.updateLocale(language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.DATE'),
        key: 'date',
        width: 200,
        // eslint-disable-next-line react/display-name
        render: (_text: string, extraHour: IUserExtraHour) => {
          const { week, start, end } = extraHour;
          return (
            <span>
              {t('SCHEDULE.WEEK_FIRST_LETTER')}
              {week} -{' '}
              {t('GLOBAL.DATE_TO_DATE', {
                date1: moment.unix(start).format('DD'),
                date2: moment.unix(end).format('L'),
              })}
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.PLANNED'),
        key: 'planned',
        // eslint-disable-next-line react/display-name
        render: (_text: string, extraHour: IUserExtraHour) => {
          const { totalPlanned } = extraHour;
          return minutesToHoursAndMinutes(totalPlanned);
        },
      },
      ...(isFeatureEnabled(features, FEATURES.CLOCKING)
        ? [
            {
              title: t('GLOBAL.CLOCKING'),
              key: 'clocking',
              // eslint-disable-next-line react/display-name
              render: (_text: string, extraHour: IUserExtraHour) => {
                const { totalClocking } = extraHour;
                return (
                  <div>
                    {minutesToHoursAndMinutes(totalClocking)}
                    {extraHour.label && <span style={{ color: colors.red, paddingLeft: 5 }}>{extraHour.label}</span>}
                  </div>
                );
              },
            },
          ]
        : []),
      {
        title: t('GLOBAL.BALANCE'),
        key: 'balance',
        // eslint-disable-next-line react/display-name
        render: (_text: string, extraHour: IUserExtraHour) => {
          const { balance } = extraHour;
          return minutesToHoursAndMinutes(balance, true);
        },
      },
      {
        title: t('GLOBAL.TOTAL'),
        align: 'right' as AlignType,
        key: 'total',
        // eslint-disable-next-line react/display-name
        render: (_text: string, extraHour: IUserExtraHour) => {
          const { total, adjust, period } = extraHour;
          if (adjust) {
            const { comment, id, adjusted } = adjust;
            return (
              <Tooltip title={comment}>
                <div className={`total ${adjusted < 0 ? 'red' : 'green'}`}>
                  <span>{minutesToHoursAndMinutes(Math.round(Number(adjust.adjusted)), true)}</span>
                  <Button
                    shape="circle"
                    type="text"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onWantToDeleteAdjustment(id);
                    }}
                  >
                    <i className="icon-trash-empty" />
                  </Button>
                </div>
              </Tooltip>
            );
          } else {
            return (
              <div className="total">
                <span>{minutesToHoursAndMinutes(total, true)}</span>
                <Button
                  shape="circle"
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setPeriodToAdjust(period);
                  }}
                >
                  <i className="icon-edit" style={{ color: colors.green }} />
                </Button>
              </div>
            );
          }
        },
      },
    ]);
  }, [language]);

  useEffect(() => {
    let mounted = true;
    setExtraHours([]);
    setLoading(true);
    if (department && user) {
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/hr/extra-hours`, {
        params: {
          departmentId: department.id,
          recordId: user?.recordId,
        },
      })
        .then(({ data }) => {
          setRecup(data.recup);
          setLoading(false);
          if (mounted) {
            updateTableWithData(data);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });

      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [department, user?.recordId]);

  const onWantToDeleteAdjustment = (id: string) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('EXTRA_HOURS.MODAL_DELETE_ADJUSTMENT.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        return new Promise((resolve, reject) => {
          onDeleteAdjustment(id)
            .then(({ data }) => {
              updateTableWithData(data);
              resolve(true);
            })
            .catch((error) => {
              message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
              console.error(error);
              reject();
            });
        });
      },
      onCancel: () => {},
    });
  };

  const onDeleteAdjustment = (id: string) => {
    return Axios.delete(`${process.env.REACT_APP_API_URL}/v3/hr/extra-hours`, {
      params: {
        departmentId: department?.id,
        id,
      },
    });
  };

  const onRefresh = () => {
    setRefreshing(true);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/hr/extra-hours-count`, {
      params: {
        departmentId: department?.id,
        recordId: user?.recordId,
      },
    })
      .then(({ data }) => {
        setRefreshing(false);
        updateTableWithData(data);
      })
      .catch((error) => {
        setRefreshing(false);
        message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
        console.error(error);
      });
  };

  const updateTableWithData = (data: any) => {
    setViewMode(data?.viewMode);
    setExtraHours(data?.weeks || []);
    setWeekDetails(data?.days);
  };

  const onRowClick = (extraHour: IUserExtraHour) => {
    const { period } = extraHour;
    const weekDetail = weekDetails?.[period];
    if (weekDetail) {
      const { week, start, end } = extraHour;
      setSelectedWeek({
        title: `${t('SCHEDULE.WEEK_FIRST_LETTER')}${week} - ${t('GLOBAL.DATE_TO_DATE', {
          date1: moment.unix(start).format('DD'),
          date2: moment.unix(end).format('L'),
        })}`,
        dataSource: weekDetail,
      });
    }
  };

  const onRow = (extraHour: IUserExtraHour) => {
    return {
      onClick: () => {
        onRowClick(extraHour);
      },
    };
  };

  const onSaveAdjustment = (response: AxiosResponse<any>) => {
    const { data } = response;
    updateTableWithData(data);
    setPeriodToAdjust(null);
  };

  return (
    <div className={className}>
      <header className="header">
        <div>
          <h4>{t('EXTRA_HOURS.EXTRA_HOURS_AND_RECOVERY')}</h4>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 50 }}>
          <div>
            {recup > 0 && (
              <span style={{ fontWeight: 'bold' }}>
                {t('EXTRA_HOURS.RECUP_PLANNED')}:{' '}
                <span
                  style={{
                    marginLeft: 5,
                    backgroundColor: '#000',
                    color: 'white',
                    borderRadius: 50,
                    padding: '0 10px',
                  }}
                >
                  {recup}
                  {t('GLOBAL.HOURS_SHORT')}
                </span>
              </span>
            )}
          </div>
          <div>
            <span style={{ color: colors.grey, marginRight: 10 }}>
              {t('GLOBAL.BASIS_OF_CALCULATION')}:{' '}
              {viewMode === 1 ? t('GLOBAL.PLANNED_HOURS') : t('GLOBAL.CLOCKING_HOURS')}
            </span>
            <Button type="primary" danger onClick={onRefresh}>
              <SyncOutlined spin={refreshing} />
            </Button>
          </div>
        </div>
      </header>
      <Table
        loading={loading || refreshing}
        dataSource={extraHours}
        columns={columns}
        rowKey="period"
        onRow={onRow}
        pagination={false}
      />
      <ModalWeekDetails selectedWeek={selectedWeek} onClose={() => setSelectedWeek(null)} department={department} />
      <ModalAdjustment
        departmentId={department?.id}
        recordId={user?.recordId}
        period={periodToAdjust}
        onClose={() => setPeriodToAdjust(null)}
        onSave={onSaveAdjustment}
      />
    </div>
  );
};

export default styled(ExtraHours)`
  header {
    h4 {
      margin: 0;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .ant-table-row {
    background-color: white;
  }

  tbody tr {
    cursor: pointer;
  }

  .ant-table-cell .total {
    display: flex;
    align-items: center;
    justify-content: center;
    &.red,
    &.green {
      span {
        color: white;
        padding: 1px 3px;
        border-radius: 4px;
        font-weight: bold;
      }
    }
    &.red {
      span {
        background-color: ${colors.red};
      }
      i {
        color: ${colors.red};
      }
    }
    &.green {
      span {
        background-color: ${colors.green};
      }
      i {
        color: ${colors.green};
      }
    }
  }

  @media screen and (max-width: 900px) {
    .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
