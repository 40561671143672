import { message, Modal, Form, Input, Button } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';

interface Props {
  className?: string;
  visible: boolean;
  departmentId?: string;
  onClose: () => void;
}

const ModalResetTrial: React.FC<Props> = ({ visible, departmentId, onClose }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  const onFinish = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/operations/reset-demo`, null, {
        params: {
          departmentId,
        },
      })
      .then(() => {
        setLoading(false);
        location.reload();
      })
      .catch(() => {
        setLoading(false);
        message.error(t('TRIAL.MODAL_TRIAL_OVER.MESSAGE_RESET_DEMO_ERROR'));
      });
  };

  return (
    <Modal
      className={'modal-danger'}
      forceRender={true}
      visible={visible}
      onCancel={onClose}
      title={t('GLOBAL.DELETION')}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          {t('GLOBAL.REMOVE')}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <p style={{ whiteSpace: 'pre-line' }}>
          {t('TRIAL.MODAL_RESET_CONFIRMATION.CONTENT', {
            confirmText: t('TRIAL.MODAL_RESET_CONFIRMATION.CONFIRM_TEXT'),
          })}
        </p>
        <Form.Item
          name="sentence"
          rules={[
            {
              required: true,
              validator: (_rule, value = '') => {
                if (
                  value.toLocaleLowerCase() !== t('TRIAL.MODAL_RESET_CONFIRMATION.CONFIRM_TEXT').toLocaleLowerCase()
                ) {
                  return Promise.reject();
                } else {
                  return Promise.resolve(value);
                }
              },
            },
          ]}
        >
          <Input placeholder={t('TRIAL.MODAL_RESET_CONFIRMATION.CONFIRM_TEXT')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalResetTrial;
