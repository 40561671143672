import React, { Dispatch, useContext, useEffect, useState } from 'react';
import AppContext from '@/pages/app/context';
import { Button, Form, InputNumber, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InitialStateType } from '../redux/store';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import axios from 'axios';
import { ActionType, updateShiftsAndContextWithShiftResponse } from '../redux/actions';
import Constraints from '@/pages/app/components/Constraints';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const ModalPasteMultipleFreeShifts: React.FC<Props> = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, activeSection, activeDepartment, skills, userCategories },
  } = useContext(AppContext);
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const { clipboardHistory, startDate, endDate, picker, freeShiftToPasteMultiple } = useSelector(
    ({ clipboardHistory, startDate, endDate, picker, freeShiftToPasteMultiple }: InitialStateType) => ({
      clipboardHistory,
      startDate,
      endDate,
      picker,
      freeShiftToPasteMultiple,
    }),
  );
  const [form] = useForm();
  const [loadingMultipleFreeShifts, setLoadingMultipleFreeShifts] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      items: clipboardHistory
        ? clipboardHistory.shifts[0].count
          ? clipboardHistory.shifts[0].count
          : undefined
        : undefined,
    });
  }, [visible]);

  const onFinish = (values: any) => {
    const { items } = values;
    if (freeShiftToPasteMultiple) {
      const { id, start, end } = clipboardHistory!.shifts[0];
      const { timestamp, userRecordId } = freeShiftToPasteMultiple;

      if (id) {
        const shift = document.getElementById(id);
        shift?.classList.add('optimistic');
      }

      const differenceInDays = moment(timestamp).diff(moment.unix(start!).startOf('day'), 'days');

      let data: any = {
        shift: {
          id,
          start: moment.unix(start!).add(differenceInDays, 'day').unix(),
          end: moment.unix(end!).add(differenceInDays, 'day').unix(),
          userRecordId,
        },
        series: items && items > 1,
        items: items ? (items > 1 ? items : undefined) : undefined,
      };

      if (items == 1) {
        data = {
          shifts: [
            {
              id,
              start: moment.unix(start!).add(differenceInDays, 'day').unix(),
              end: moment.unix(end!).add(differenceInDays, 'day').unix(),
              userRecordId,
            },
          ],
        };
      }

      const axiosResponse = axios.post(
        `${process.env.REACT_APP_API_URL}/v3/shifts${items && items > 1 ? '-multiple' : ''}`,
        data,
        {
          params: {
            picker,
            startDate: startDate.unix(),
            endDate: endDate.unix(),
            departmentId: activeDepartmentId,
            sectionId: activeSection,
          },
        },
      );

      if (axiosResponse) {
        setLoadingMultipleFreeShifts(true);
        axiosResponse
          .then((response) => {
            updateShiftsAndContextWithShiftResponse(hoursDispatch, response, {
              department: activeDepartment!,
              picker,
              startDate,
              endDate,
              activeSection,
              skills,
              userStatus: userCategories,
            });
            const { totalErrors = 0 } = response.data.message;
            if (totalErrors) {
              Modal.confirm({
                className: 'modal-constraints',
                icon: null,
                title: t('SCHEDULE.CONSTRAINTS.TITLE'),
                content: <Constraints message={response.data.message.error?.[0]?.message || []} />,
                cancelText: t('GLOBAL.CANCEL'),
                okText: t('GLOBAL.FORCE'),
                onOk: () => {
                  return axios({
                    method: response.config.method,
                    url: response.config.url,
                    data: {
                      ...JSON.parse(response.config.data),
                      force: true,
                    },
                    params: {
                      picker,
                      startDate: startDate.unix(),
                      endDate: endDate.unix(),
                      departmentId: activeDepartmentId,
                      sectionId: activeSection,
                    },
                  })
                    .then((response) => {
                      updateShiftsAndContextWithShiftResponse(hoursDispatch, response, {
                        department: activeDepartment!,
                        picker,
                        startDate,
                        endDate,
                        activeSection,
                        skills,
                        userStatus: userCategories,
                      });
                      if (id) {
                        const shift = document.getElementById(id);
                        shift?.classList.remove('optimistic');
                      }
                      setLoadingMultipleFreeShifts(false);
                      hoursDispatch({
                        type: 'SET_FREE_SHIFT_TO_PASTE_MULTIPLE',
                        payload: null,
                      });
                      hoursDispatch({
                        type: 'SET_PASTE_MULTIPLE_FREE_SHIFTS_MODAL_VISIBLE',
                        payload: false,
                      });
                    })
                    .catch((err) => {
                      if (id) {
                        const shift = document.getElementById(id);
                        shift?.classList.remove('optimistic');
                      }
                      console.error(err);
                    });
                },
              });
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            if (id) {
              const shift = document.getElementById(id);
              shift?.classList.remove('optimistic');
            }
            setLoadingMultipleFreeShifts(false);
            hoursDispatch({
              type: 'SET_FREE_SHIFT_TO_PASTE_MULTIPLE',
              payload: null,
            });
            hoursDispatch({
              type: 'SET_PASTE_MULTIPLE_FREE_SHIFTS_MODAL_VISIBLE',
              payload: false,
            });
          });
      }
    }
  };

  return (
    <Modal
      className="modal-paste"
      visible={visible}
      onCancel={onClose}
      title={t('SHIFTS.MULTIPLE_FREE_SHIFTS.NUMBER_OF_PASTE')}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()} loading={loadingMultipleFreeShifts}>
          {t('GLOBAL.CONFIRM')}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label={t('SHIFTS.MULTIPLE_FREE_SHIFTS.NUMBER_OF_EDITS')} name="items" style={{ marginTop: 10 }}>
          <InputNumber
            type="number"
            placeholder={t('SHIFTS.MULTIPLE_FREE_SHIFTS.NUMBER_OF_EDITS')}
            min={1}
            max={clipboardHistory ? (clipboardHistory.shifts[0].count ? clipboardHistory.shifts[0].count : 1) : 1}
            step={1}
            precision={0}
            size="large"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalPasteMultipleFreeShifts;
