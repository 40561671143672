import { getContrast } from '@/utils';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  start: number;
  end: number;
  size?: 'small' | 'large';
  fulldate?: boolean;
  backgroundColor?: string;
  bold?: boolean;
}
const ShiftPeriod: React.FC<Props> = ({ className, start, end, size = 'large', fulldate, backgroundColor, bold }) => {
  const start_hour = fulldate ? moment(start) : moment.unix(start);
  const end_hour = fulldate ? moment(end) : moment.unix(end);
  return (
    <p
      className={`${className} ${size}`}
      style={{
        color: backgroundColor ? getContrast(backgroundColor) : undefined,
        fontWeight: bold ? 'bold' : undefined,
      }}
    >
      {start_hour.format('H:mm')}-{end_hour.format('H:mm')}
    </p>
  );
};

export default styled(ShiftPeriod)`
  font-weight: 500;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  &.small {
    font-size: 10px !important;
  }
`;
