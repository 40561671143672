import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import SubHeader from './components/SubHeader';
import ModalAppDetails from './components/modal';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import axios from 'axios';
import { Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import App from './components/App';
import { IMarketplaceAppCategory } from '@/types/marketplace/marketplace-app-category.model';
import { IFeature } from '@/types/features.model';
import { handleError } from '@/utils';

interface Props {
  className?: string;
  category: IMarketplaceAppCategory;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const Marketplace: React.FC<Props> = ({ className, category }) => {
  const {
    state: { activeDepartmentId, activeDepartment, marketplaceApps },
    dispatch,
  } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const [apps, setApps] = useState<IMarketplaceApp[]>([]);
  const [filteredApps, setFilteredApps] = useState<IMarketplaceApp[]>([]);
  const [appsLoading, setAppsLoading] = useState<boolean>(false);
  const [onlyInstalled, setOnlyInstalled] = useState<boolean>(false);
  const [activeApp, setActiveApp] = useState<IMarketplaceApp | null>(null);
  const [activePage, setActivePage] = useState<'content' | 'auth' | 'success' | 'uninstalled'>('content');

  useEffect(() => {
    setActivePage('content');
  }, [activeApp]);

  useEffect(() => {
    getApps();
  }, []);

  const getApps = () => {
    setAppsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/marketplace`, {
        params: {
          departmentId: activeDepartmentId,
          full: true,
        },
      })
      .then(({ data }) => {
        getFeatures();
        setAppsLoading(false);
        const apps = data
          .filter(
            (app: IMarketplaceApp) =>
              app.countries.includes('ALL') || app.countries.includes(activeDepartment!.country!),
          )
          .sort((a: IMarketplaceApp, b: IMarketplaceApp) => {
            // If both have x: true or both have x: false, no change in order
            if (a.preferred_partner === b.preferred_partner) {
              return 0;
            }
            // If a.x is true, move it before b
            if (a.preferred_partner) {
              return -1;
            }
            // If b.x is true, move it before a
            return 1;
          });
        const filtered = apps.filter(
          (app: IMarketplaceApp) => app.category == category || app.extended_categories.includes(category),
        );
        setApps(filtered);
        setFilteredApps(filtered);
        dispatch({
          type: 'SET_MARKETPLACE_APPS',
          payload: apps,
        });
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setAppsLoading(false);
      });
  };

  const getFeatures = () => {
    dispatch({
      type: 'SET_LOADING_FEATURES',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/get-features`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_FEATURES',
          payload: data as IFeature[],
        });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        dispatch({
          type: 'SET_LOADING_FEATURES',
          payload: false,
        });
      });
  };

  const onShowInstalled = () => {
    setOnlyInstalled(!onlyInstalled);
    if (onlyInstalled) {
      setFilteredApps(apps);
    } else {
      setFilteredApps(marketplaceApps.filter((app) => app.installed));
    }
  };

  return (
    <div className={className}>
      <div className="header">
        <div className="left">
          <h2>{t(`SETTINGS.MARKETPLACE.${category.toUpperCase()}.TITLE`)}</h2>
          <p>{t(`SETTINGS.MARKETPLACE.${category.toUpperCase()}.DESCRIPTION`)}</p>
        </div>
        <div className="right">
          <p onClick={onShowInstalled}>{t(`SETTINGS.MARKETPLACE.${onlyInstalled ? 'SHOW_ALL' : 'SHOW_INSTALLED'}`)}</p>
        </div>
      </div>
      <div className="container">
        <div className="apps-container">
          {!appsLoading ? (
            <div className="section">
              {filteredApps.length > 0 ? (
                <>
                  {filteredApps.map((app) => (
                    <App app={app} setActiveApp={setActiveApp} />
                  ))}
                </>
              ) : (
                <p>{t('MARKETPLACE.NO_APPS_YET')}</p>
              )}
            </div>
          ) : (
            <div className="loader">
              <Spin spinning={appsLoading} />
            </div>
          )}
        </div>
      </div>
      <ModalAppDetails
        activeApp={activeApp}
        setActiveApp={setActiveApp}
        activePage={activePage}
        category={category}
        setActivePage={setActivePage}
        getApps={getApps}
        getFeatures={getFeatures}
      />
    </div>
  );
};

export default styled(Marketplace)`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 15px;
    gap: 15px;

    .right {
      p {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .apps-container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .section {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }

    .loader {
      align-self: center;
    }
  }
`;
