import React, { useContext } from 'react';
import { Button, DatePicker, Select, Space, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import { CloudDownloadOutlined } from '@ant-design/icons';
import colors from '@/styles/colors';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
  rangePickerValues: [Moment, Moment];
  setRangePickerValues: React.Dispatch<React.SetStateAction<[Moment, Moment]>>;
  selectUsers: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  isDownloading: boolean;
  onDownload: () => void;
}

const Header: React.FC<Props> = ({
  className,
  rangePickerValues,
  setRangePickerValues,
  selectUsers,
  setSelectedUsers,
  isDownloading,
  onDownload,
}) => {
  const { RangePicker } = DatePicker;
  const {
    state: { loadingUsers, users },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <header className={className}>
      <div className="left">
        <Space>
          <RangePicker
            allowClear={false}
            getPopupContainer={(trigger) => trigger}
            format={'L'}
            value={rangePickerValues}
            onChange={(e: any) => {
              setRangePickerValues(e);
            }}
          />
          <Select
            getPopupContainer={(trigger) => trigger}
            showSearch
            clearIcon
            allowClear
            mode="multiple"
            maxTagTextLength={10}
            maxTagCount={3}
            placeholder={t('GLOBAL.USERS')}
            loading={loadingUsers}
            disabled={loadingUsers}
            style={{ width: 400 }}
            value={selectUsers}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(e) => {
              setSelectedUsers(e);
            }}
          >
            {users?.map((user) => {
              const { recordId, displayName } = user;
              return (
                <Option key={`user_${recordId}`} value={recordId!}>
                  {displayName}
                </Option>
              );
            })}
          </Select>
        </Space>
      </div>
      {/* <div className="right">
        <Tooltip title={t('GLOBAL.DOWNLOAD')}>
          <Button
            icon={<CloudDownloadOutlined />}
            type="primary"
            loading={isDownloading}
            onClick={onDownload}
            style={{ backgroundColor: colors.blueLightPastel, borderColor: colors.blueLightPastel }}
          >
            {t('GLOBAL.DOWNLOAD')}
          </Button>
        </Tooltip>
      </div> */}
    </header>
  );
};

export default Header;
