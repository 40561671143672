import { Modal } from 'antd';
import React, { useContext } from 'react';
import UsersForm from './UsersForm';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalFreemiumOnboarding: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    state: { users },
  } = useContext(AppContext);

  const onCancel = () => {
    setVisible(false);
    history.replace('/app/dashboard');
    TagManager.dataLayer({
      dataLayer: {
        event: 'startProductTour',
      },
      dataLayerName: 'PageDataLayer',
    });
  };

  return (
    <Modal width={1000} visible={visible} className={className} onCancel={onCancel} onOk={onCancel} footer={null}>
      <div className="section">
        <UsersForm setModalFreemiumOnboardingVisible={setVisible} />
      </div>
    </Modal>
  );
};

export default styled(ModalFreemiumOnboarding)`
  display: flex;
  flex-direction: column;

  .ant-modal-content {
    top: -50px !important;
  }

  .section {
    h1 {
      margin: 15px 0;
    }

    .title {
      font-weight: bold;
    }

    .collapse {
      background-color: transparent;

      .panel {
        background-color: #fff;
        padding: 5px 10px;

        .ant-collapse-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;
        }

        .title {
          display: flex;
          font-weight: lighter;
          align-items: center;
          gap: 25px;
        }
      }
    }
  }
`;
