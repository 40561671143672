import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import Header from './Header';
import Content from './Content';
import Details from './Details';
import Auth from './Auth';
import Success from './Success';
import Uninstalled from './Uninstalled';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import { IMarketplaceAppCategory } from '@/types/marketplace/marketplace-app-category.model';
import { IMarketplaceAppFields } from '@/types/marketplace/marketplace-app-fields.model';
import axios from 'axios';
import { Alert, Button, Divider, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import colors from '@/styles/colors';
import { handleError } from '@/utils';

interface Props {
  className?: string;
  activeApp: IMarketplaceApp;
  activePage: 'content' | 'auth' | 'success' | 'uninstalled';
  category: IMarketplaceAppCategory;
  style?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  setActiveApp: React.Dispatch<React.SetStateAction<IMarketplaceApp | null>>;
  setActivePage: React.Dispatch<React.SetStateAction<'content' | 'auth' | 'success' | 'uninstalled'>>;
  getApps: () => void;
  getFeatures: () => void;
}

const MarketplaceAppContainer: React.FC<Props> = ({
  className,
  activeApp,
  activePage,
  category,
  style,
  headerStyle,
  contentStyle,
  setActiveApp,
  setActivePage,
  getApps,
  getFeatures,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, marketplaceApps },
    dispatch,
  } = useContext(AppContext);
  const [fieldDefinitions, setFieldDefinitions] = useState<IMarketplaceAppFields | null>(null);
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    getAppFieldDefinitions();
  }, [activeApp]);

  const getAppFieldDefinitions = () => {
    if (!activeApp || !activeApp.installed) return;

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/marketplace/${activeApp.id}`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setFieldDefinitions(data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const onInstallDependency = (app: IMarketplaceApp) => {
    setActiveApp(app);
  };

  const result = activeApp?.dependency ? activeApp?.dependency.slice(1, -1).split('],[') : [];
  const dependencies = result.map((subArray) =>
    subArray.split(',').map((id) => (id ? marketplaceApps.find((item) => item.id === id.trim()) : null)),
  );
  const allDependenciesAreInstalled =
    dependencies.length == 0 ? true : dependencies.some((subArray) => subArray.every((item) => item && item.installed));

  const appsDependingOnActive = marketplaceApps.filter(
    (app) => app.installed && app.dependency?.slice(1, -1).split('],[')?.includes(activeApp.id),
  );

  return (
    <div className={className} style={style}>
      <Header activeApp={activeApp} style={headerStyle} />
      <div className="container" style={contentStyle}>
        {activePage == 'content' && (
          <div className="content-container">
            {((!allDependenciesAreInstalled && !activeApp.installed) ||
              activeApp.category == 'PAYROLL' ||
              appsDependingOnActive.length > 0) && (
              <div className="top">
                {appsDependingOnActive.length > 0 ? (
                  <Alert
                    className="alert"
                    type="error"
                    message={
                      <div className="alert-message" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div
                          className="message danger"
                          dangerouslySetInnerHTML={{
                            __html: t('SETTINGS.MARKETPLACE.UNINSTALL_DEPENDENCY_ALERT'),
                          }}
                        />
                        <div className="message danger">
                          {appsDependingOnActive.map((app) => (
                            <div className="dependency-app" onClick={() => setActiveApp(app)}>
                              - {app.title}
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                  />
                ) : category == 'PAYROLL' ? (
                  <Alert
                    className="alert"
                    type="error"
                    message={
                      <div className="alert-message">
                        <div className="message danger">{t('SETTINGS.MARKETPLACE.HR_PARTNER_DEPENDENCY_ALERT')}</div>
                      </div>
                    }
                  />
                ) : (
                  dependencies.map((sub, index) => {
                    const hasNext = dependencies[index + 1];
                    return (
                      <>
                        {sub.map((app) => {
                          if (!app) return;
                          return (
                            <Alert
                              className="alert"
                              type="error"
                              message={
                                <div className="alert-message">
                                  <span className="message">
                                    {t('SETTINGS.MARKETPLACE.DEPENDENCY_TEXT', {
                                      app: activeApp.title,
                                      dependency: app.title,
                                    })}
                                  </span>
                                  <Button type="primary" onClick={() => onInstallDependency(app)}>
                                    {t('SETTINGS.MARKETPLACE.INSTALL_DEPENDENCY', { dependency: app.title })}
                                  </Button>
                                </div>
                              }
                            />
                          );
                        })}
                        {!allDependenciesAreInstalled && hasNext && (
                          <Divider className="dependencies-divider">{t('GLOBAL.OR')}</Divider>
                        )}
                      </>
                    );
                  })
                )}
              </div>
            )}
            <div className="bottom">
              <Content activeApp={activeApp} />
              <Details
                activeApp={activeApp}
                category={category}
                setActivePage={setActivePage}
                getAppFieldDefinitions={getAppFieldDefinitions}
                fieldDefinitions={fieldDefinitions}
                getApps={getApps}
                setActiveApp={setActiveApp}
                getFeatures={getFeatures}
                allDependenciesAreInstalled={allDependenciesAreInstalled}
                appsDependingOnActive={appsDependingOnActive}
              />
            </div>
          </div>
        )}
        {activePage == 'auth' && (
          <Auth
            activeApp={activeApp}
            category={category}
            setActivePage={setActivePage}
            fieldDefinitions={fieldDefinitions}
            getApps={getApps}
          />
        )}
        {activePage == 'success' && <Success activeApp={activeApp} setActiveApp={setActiveApp} />}
        {activePage == 'uninstalled' && <Uninstalled activeApp={activeApp} />}
      </div>
    </div>
  );
};

export default styled(MarketplaceAppContainer)`
  .container {
    display: flex;
  }

  .content-container {
    display: flex;
    flex-direction: column;

    .top {
      margin: 25px;

      .ant-alert {
        background-color: #fff2f0 !important;
        border: 1px solid #ffccc7 !important;
      }

      .alert-message {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .message {
          font-size: 14px;
          font-weight: bold;

          &.danger {
            color: ${colors.red};
          }
        }

        .dependency-app {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .dependencies-divider {
        text-transform: uppercase;
      }
    }

    .bottom {
      display: flex;
    }
  }
`;
