import { IUser } from '@/types/user.model';
import { DatePicker, Select, Space } from 'antd';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
  rangePickerValues: [Moment, Moment];
  setRangePickerValues: React.Dispatch<React.SetStateAction<[Moment, Moment]>>;
  selectUsers: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  loadingUsers: boolean;
  users: IUser[];
}

const Header: React.FC<Props> = ({
  className,
  rangePickerValues,
  setRangePickerValues,
  selectUsers,
  setSelectedUsers,
  loadingUsers,
  users,
}) => {
  const { RangePicker } = DatePicker;
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <header className={className}>
      <div className="left">
        <Space>
          <RangePicker
            allowClear={false}
            getPopupContainer={(trigger) => trigger}
            format={'L'}
            value={rangePickerValues}
            onChange={(e: any) => {
              setRangePickerValues(e);
            }}
          />
          <Select
            getPopupContainer={(trigger) => trigger}
            showSearch
            clearIcon
            allowClear
            mode="multiple"
            maxTagTextLength={10}
            maxTagCount={3}
            placeholder={t('GLOBAL.USERS')}
            loading={loadingUsers}
            disabled={loadingUsers}
            value={selectUsers}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(e) => {
              setSelectedUsers(e);
            }}
          >
            {users?.map((user) => {
              const { recordId, displayName } = user;
              return (
                <Option key={`user_${recordId}`} value={recordId!}>
                  {displayName}
                </Option>
              );
            })}
          </Select>
        </Space>
      </div>
    </header>
  );
};

export default styled(Header)`
  .left {
    .ant-space {
      .ant-space-item {
        &:nth-child(2) {
          .ant-select {
            width: 400px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    .left {
      width: 100%;
      .ant-space {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .ant-space-item {
          width: 100%;

          .ant-picker {
            width: 100%;
          }

          &:nth-child(2) {
            .ant-select {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;
