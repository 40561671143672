import React, { useEffect } from 'react';
import { Popover, Space } from 'antd';
import Options from '@/pages/app/components/Options';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import DateSelector from '../../components/DateSelector';
import { useTranslation } from 'react-i18next';
import { IWidget } from '@/types/widget.model';
import HeaderOptionDetails from './HeaderOptionDetails';
import { Dispatch } from 'react';
import { ActionType } from '../redux/actions';
import { useDispatch } from 'react-redux';
import 'moment-timezone';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  startDate: Moment;
  endDate: Moment;
  selectedDate: Moment;
  widgets: IWidget[];
  departmentId?: string;
}

const DashboardHeader: React.FC<Props> = ({ className, startDate, endDate, selectedDate, widgets, departmentId }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const dashboardDispatch: Dispatch<ActionType> = useDispatch();

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  const onDatePickerChange = (date: moment.Moment | null) => {
    if (date) {
      dashboardDispatch({
        type: 'SET_SELECTED_DATE',
        payload: date,
      });
    }
  };

  const onArrowClick = (value: number) => {
    dashboardDispatch({
      type: 'SET_SELECTED_DATE',
      payload: moment(selectedDate).add(value, 'week'),
    });
  };

  return (
    <header className={className}>
      <Space>
        <h1>{t('DASHBOARD.TITLE')}</h1>
        <DateSelector
          picker="week"
          startDate={startDate}
          endDate={endDate}
          onArrowClick={onArrowClick}
          onDatePickerChange={onDatePickerChange}
        />
      </Space>
      {!!widgets?.length && (
        <Options>
          <Popover
            placement="bottomLeft"
            content={() => <HeaderOptionDetails widgets={widgets} departmentId={departmentId} />}
          >
            <div className="option">
              <i className="icon-toggle-on" />
            </div>
          </Popover>
        </Options>
      )}
    </header>
  );
};

const DashboardHeaderStyled = styled(DashboardHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;

  h1 {
    margin-bottom: 0;
    margin-right: 10px;
  }
`;

export default DashboardHeaderStyled;
