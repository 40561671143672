import colors from '@/styles/colors';
import { Button, Input, Space } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import ModalDefaultHrCodes from './ModalDefaultHrCodes';
import { IHrPartner } from '@/types/hr-partner.model';

interface Props {
  className?: string;
  onSearchChange: (e: React.ChangeEvent) => void;
  onCreate: () => void;
  setModalDefaultHrPartnersVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<Props> = ({ className, onSearchChange, onCreate, setModalDefaultHrPartnersVisible }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const onViewDefault = () => {
    setModalDefaultHrPartnersVisible(true);
  };

  return (
    <header className={className}>
      <div className="left">
        <Input
          allowClear
          placeholder={t('GLOBAL.SEARCH')}
          suffix={<i className="icon-search"></i>}
          onChange={onSearchChange}
        />
        <Button
          type="primary"
          style={{ background: colors.blueLightPastel, borderColor: colors.blueLightPastel, marginLeft: 25 }}
          onClick={onViewDefault}
        >
          {t('CUSTOM_HR_CODES.VIEW_DEFAULT_CODES')}
        </Button>
      </div>
      <div className="right">
        <div className="actions">
          <Button type="primary" onClick={onCreate}>
            {t('GLOBAL.NEW')}
          </Button>
        </div>
      </div>
    </header>
  );
};

export default styled(Header)`
  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 15px;

    .left {
      .ant-space {
        width: 100%;
        .ant-space-item {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
    }

    .right {
      .actions {
        button {
          width: 100%;
          margin: 0 !important;
        }
      }
    }
  }
`;
