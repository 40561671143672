import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import VacationRequests from '@/pages/app/hours/vacation-requests';
import { IDepartment } from '@/types/department.model';
import { IUser } from '@/types/user.model';
import { Tabs } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Availability from '../availability';
import Clockings from './Clockings';
import Logs from './Logs';
import Schedule from './Schedule';
import ShiftRequests from './shift-requests';
import { isFeatureEnabled } from '@/utils';
import { FEATURES } from '@/types/features.model';

const { TabPane } = Tabs;

interface Props {
  className?: string;
  user?: IUser;
  department?: IDepartment;
  activeSubTab: string;
  setActiveSubTab: React.Dispatch<React.SetStateAction<string>>;
}

const Activity: React.FC<Props> = ({ className, user, department, activeSubTab, setActiveSubTab }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();
  const {
    state: { userDetails },
  } = useContext(AuthContext);
  const {
    state: { features },
  } = useContext(AppContext);

  useEffect(() => {
    if (activeSubTab == '') {
      history.replace(`/app/team/collaborators/${user?.recordId}/activity/schedule`);
    }
  }, [activeSubTab, user]);

  const onTabChange = (tab: string) => {
    setActiveSubTab(tab);
    history.replace(`/app/team/collaborators/${user?.recordId}/activity/${tab.toLowerCase()}`);
  };

  return (
    <div className={className}>
      <Tabs
        type="card"
        onChange={onTabChange}
        activeKey={
          activeSubTab == ''
            ? isFeatureEnabled(features, FEATURES.SCHEDULE)
              ? 'SCHEDULE'
              : isFeatureEnabled(features, FEATURES.CLOCKING)
              ? 'CLOCKINGS'
              : department?.params?.enableAvailability
              ? 'UNAVAILABILITIES'
              : 'LOGS'
            : activeSubTab
        }
      >
        {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
          <TabPane tab={t('GLOBAL.SCHEDULE')} key="SCHEDULE">
            <Schedule user={user!} />
          </TabPane>
        )}
        {isFeatureEnabled(features, FEATURES.CLOCKING) && userDetails?.params?.access?.clockings && (
          <TabPane disabled={user?.pending} tab={t('GLOBAL.CLOCKINGS')} key="CLOCKINGS">
            <Clockings user={user} departmentId={department?.id} />
          </TabPane>
        )}
        {department?.params?.enableAvailability && (
          <TabPane disabled={user?.pending} tab={t('GLOBAL.AVAILABILITIES_SHORT')} key="UNAVAILABILITIES">
            <Availability user={user} departmentId={department?.id} />
          </TabPane>
        )}
        <TabPane disabled={user?.pending} tab={t('GLOBAL.HISTORY')} key="LOGS">
          <Logs user={user} departmentId={department?.id} />
        </TabPane>
        {isFeatureEnabled(features, FEATURES.LEAVE_MANAGEMENT) && (
          <TabPane disabled={user?.pending} tab={t('VACATION_REQUESTS.TITLE')} key="VACATION_REQUESTS">
            <VacationRequests userRecordId={user?.recordId || undefined} />
          </TabPane>
        )}
        {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
          <TabPane disabled={user?.pending} tab={t('SCHEDULE.CHANGE_REQUESTS.TITLE_SHORT')} key="CHANGE_REQUESTS">
            <ShiftRequests user={user} departmentId={department?.id} />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default styled(Activity)``;
