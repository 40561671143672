import colors from '@/styles/colors';
import { Button, Form, InputNumber, message, Modal } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  departmentId?: string;
  lastTurnover: number | null;
  onClose: () => void;
}

const ModalYesterdayTurnover: React.FC<Props> = ({ className, departmentId, lastTurnover, onClose }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const history = useHistory();

  const onFinish = (values: any) => {
    setIsSaving(true);
    Axios.post(
      `${process.env.REACT_APP_API_URL}/v3/insights/daily-turnover`,
      {
        real: {
          ...values,
        },
      },
      {
        params: {
          departmentId: departmentId,
          start: moment.unix(lastTurnover!).startOf('month').unix(),
        },
      },
    )
      .then(() => {
        setIsSaving(false);
        onClose();
      })
      .catch((error) => {
        message.error(t('GLOBAL.MESSAGE_SAVING_ERROR'));
        setIsSaving(false);
        console.log(error);
      });
  };

  const hideModalToday = () => {
    localStorage.setItem(`${departmentId}_hideTurnover`, moment().format('YYYY-MM-DD'));
    onClose();
  };

  return (
    <Modal
      className={className}
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={!!lastTurnover}
      footer={null}
      onCancel={() => onClose()}
    >
      <Form layout="vertical" size="large" form={form} onFinish={onFinish} requiredMark={false}>
        {lastTurnover && (
          <Form.Item
            label={t('SETTINGS.INSIGHTS.DAILY_TURNOVER.MODAL_TURNOVER.TITLE')}
            name={lastTurnover}
            rules={[{ required: true }]}
          >
            <InputNumber type="number" style={{ width: 220 }} />
          </Form.Item>
        )}

        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose} style={{ width: 180, marginRight: 20 }}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving} style={{ width: 180 }}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
          <div className="links">
            <Button size="small" type="link" onClick={hideModalToday}>
              {t('SETTINGS.INSIGHTS.DAILY_TURNOVER.MODAL_TURNOVER.NOT_SHOW_AGAIN')}
            </Button>
            <Button
              size="small"
              type="link"
              onClick={() => {
                history.push('/app/settings/daily-turnover');
              }}
            >
              {t('SETTINGS.INSIGHTS.DAILY_TURNOVER.MODAL_TURNOVER.SEE_HISTORY')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default styled(ModalYesterdayTurnover)`
  .ant-modal-body {
    padding-top: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-form-item-label {
      text-align: center;
      label {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .ant-input-number-input-wrap {
      input {
        text-align: center;
      }
    }

    .actions-container {
      .links {
        padding-top: 10px;
        button {
          span {
            font-weight: normal;
            text-decoration: underline;
          }
          &:last-child {
            color: ${colors.grey};
          }
        }
      }
    }
  }
`;
