import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IPayPeriod } from '@/types/pay-periods/pay-period.model';
import { Button } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  period: IPayPeriod;
  setActivePayPeriod?: React.Dispatch<React.SetStateAction<IPayPeriod | null>>;
  setPayPeriodToDelete?: React.Dispatch<React.SetStateAction<IPayPeriod | null>>;
  setModalDeletPayPeriodVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PeriodExtra: React.FC<Props> = ({
  className,
  period,
  setActivePayPeriod,
  setPayPeriodToDelete,
  setModalDeletPayPeriodVisible,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();

  const upcoming = moment().isSameOrBefore(moment(period.end));

  const onDetails = () => {
    history.push(`/app/reports/pay-periods/${period.id}`);
  };

  const onWantToDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (setPayPeriodToDelete && setModalDeletPayPeriodVisible && upcoming) {
      setModalDeletPayPeriodVisible(true);
      setPayPeriodToDelete(period);
    }
  };

  const onEdit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (setActivePayPeriod) {
      setActivePayPeriod(period);
    }
  };

  return (
    <div className={className}>
      {upcoming ? (
        <>
          <Button type="primary" className="details" onClick={onDetails}>
            {t('REPORTS.PAY_PERIODS.VIEW_PAYROLL')}
          </Button>
          <Button type="primary" onClick={(e) => onEdit(e)}>
            {t('REPORTS.PAY_PERIODS.EDIT_PAYROLL_REPORT')}
          </Button>
          <Button type="primary" danger onClick={(e) => onWantToDelete(e)}>
            {t('GLOBAL.DELETE')}
          </Button>
        </>
      ) : (
        <Button type="link" style={{ transform: 'rotate(90deg' }} size="small" onClick={onDetails}>
          <i className="icon-up-big previous-icon" style={{ fontSize: 14 }} />
        </Button>
      )}
    </div>
  );
};

export default styled(PeriodExtra)`
  display: flex;
  align-items: center;
  gap: 10px;

  .details {
    background-color: ${colors.blueLightPastel};
    border-color: ${colors.blueLightPastel};
  }
`;
