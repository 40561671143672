import { createContext } from 'react';
import { ActionsType } from './actions';
import { IUserParams } from './types/user-params.model';

export type InitialStateType = {
  accessToken: string | null;
  lang: string | null;
  userDetails: null | {
    id: string;
    email: string;
    initials: string;
    pinCode: string;
    customLevel: string;
    userLang: string;
    userName: string;
    role: string;
    picture?: string;
    userhash?: string;
    phone?: string;
    params: IUserParams;
  };
  impersonate: boolean;
  impersonateDepartmentId: string | null;
  trialEndDate: number | null;
  blocked: boolean;
  gitSha: string | null;
  pusherId: string | null;
};

export const initialState: InitialStateType = {
  lang: null,
  accessToken: null,
  userDetails: null,
  impersonate: false,
  impersonateDepartmentId: null,
  trialEndDate: null,
  blocked: false,
  gitSha: null,
  pusherId: null,
};

export default createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<ActionsType>;
}>({
  state: initialState,
  dispatch: () => null,
});
