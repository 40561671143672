import React, { Dispatch } from 'react';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Menu } from 'antd';
import { IShiftsByUser, PickerType } from '@/pages/app/hours/manage/redux/store';
import { useDispatch } from 'react-redux';
import { ActionType } from '../redux/actions';
import colors from '@/styles/colors';
import { useTranslation } from 'react-i18next';

interface Props {
  picker: PickerType;
  shiftsByUser: IShiftsByUser;
  close: () => void;
}

const OpenShiftRightClickMenu: React.FC<Props> = ({ shiftsByUser, picker, close }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const reduxDispatch: Dispatch<ActionType> = useDispatch();
  const userRecordId = shiftsByUser?.user?.recordId;
  const shiftsToPublish = shiftsByUser?.shifts.filter((shift) => !shift.approved) || [];
  // Handlers
  const handleApproveShifts = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    reduxDispatch({ type: 'SET_SHIFT_IDS_TO_PUBLISH', payload: shiftsToPublish.map((shift) => shift.id!) });
    close();
  };

  const handleSelectShifts = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    reduxDispatch({
      type: 'ADD_SELECTED_SHIFTS',
      payload: shiftsByUser.shifts.map((shift) => shift.id!),
    });
    close();
  };

  return (
    <Menu>
      {shiftsToPublish.length > 0 && (
        <Menu.Item
          key={`user_${userRecordId}_rightClick_0`}
          onClick={handleApproveShifts}
          style={{ color: colors.green }}
        >
          <i className="icon-ok-circled"></i> {t('SHIFTS.APPROVE_SHIFTS')}
        </Menu.Item>
      )}
      {picker !== 'day' && (
        <Menu.Item
          disabled={!shiftsByUser?.shifts.length}
          key={`user_${userRecordId}_rightClick_2`}
          onClick={handleSelectShifts}
        >
          <i className="icon-check"></i> {t('SHIFTS.SELECT_FREE_SHIFTS')}
        </Menu.Item>
      )}
    </Menu>
  );
};

export default OpenShiftRightClickMenu;
