import React, { useContext } from 'react';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
  departmentId: string;
}

const ShiftDepartment: React.FC<Props> = ({ className, departmentId }) => {
  const {
    state: { departments },
  } = useContext(AppContext);
  return <div className={`${className} department`}>{departments.find((x) => x.id === departmentId)?.company}</div>;
};

const ShiftDepartmentStyled = styled(ShiftDepartment)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  max-width: calc(100% - 25px);
  display: block;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.4);
`;

export default ShiftDepartmentStyled;
