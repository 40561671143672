import React, { Dispatch, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import AppContext from '@/pages/app/context';
import { InitialStateType } from '../../redux/store';
import { ITask } from '@/types/task.model';
import { shallowEqual, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import colors from '@/styles/colors';
import AuthContext from '@/context';
import moment from 'moment';
import { useHourlyDispatch } from '..';
import { ActionType as HourlyActionType } from '../redux/actions';

interface Props {
  className?: string;
  columnWidth: number;
  onTaskDropHandler: (e: React.DragEvent) => void;
}

const TaskScheduler: React.FC<Props> = ({ className, columnWidth, onTaskDropHandler }) => {
  const { state: appContextState } = useContext(AppContext);
  const hourlyDispatch: Dispatch<HourlyActionType> = useHourlyDispatch();
  const {
    state: { trialEndDate },
  } = useContext(AuthContext);
  const { loadingTasks, tasks } = appContextState;
  const { t } = useTranslation(undefined, { useSuspense: false });

  const { hours } = useSelector(
    ({ hours }: InitialStateType) => ({
      hours,
    }),
    shallowEqual,
  );

  const onTaskDragStartHandler = (e: any, task: ITask) => {
    e.stopPropagation(); // Needed to avoid to drag the shift itself
    e.dataTransfer.setData('taskId', `${task.id}`);
    e.dataTransfer.dropEffect = 'move';

    const target = e.target as HTMLElement;
    const columnClicked = Math.trunc((e.pageX - 225) / (columnWidth / 4));
    const columnStarted = Math.trunc((target.offsetLeft - 210) / (columnWidth / 4));

    setTimeout(() => {
      target.classList.add('block-hide');
      hourlyDispatch({
        type: 'SET_TASK_IS_DRAGGING',
        payload: {
          task: {
            ...task,
            start: hours[0].date,
            end: task.minutes ? hours[0].date + 60 * task.minutes : hours[0].date + 60 * 60 * 2,
          },
          padding: columnClicked - columnStarted,
        },
      });
    }, 0);
  };
  const onTaskDragEndHandler = () => {};

  return (
    <div className={className} onDrop={onTaskDropHandler} onDragOver={(e) => e.preventDefault()}>
      <div className="left">
        <h3>{t('GLOBAL.TASKS')}</h3>
        <p style={{ marginBottom: trialEndDate && trialEndDate > moment().unix() ? '20px' : undefined }}>
          {t('TASKS.DRAG_DROP')}
        </p>
      </div>
      <div className="right" style={{ width: columnWidth * hours.length }}>
        {!!loadingTasks && <Spin size="large" />}
        {tasks?.map((task) => {
          const { color, background, id, minutes } = task;
          return (
            <div
              key={id}
              className="task"
              draggable={true}
              onDragStart={(e) => onTaskDragStartHandler(e, task)}
              onDragEnd={onTaskDragEndHandler}
              style={{
                color: color || '#000000',
                background: background || '#000000',
                left: 0,
                width: minutes ? minutes * (columnWidth / 60) : columnWidth * 2,
                fontSize: 14,
              }}
            >
              <span>{`${task.shortCode || ''} (${task.name})`}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const enterAnimation = keyframes`
  from {
    transform: translateY(68px);
  }

  to {
    transform: translateY(0px);
  }
`;

export default styled(TaskScheduler)`
  position: fixed;
  bottom: 0px;
  z-index: 99;
  border-radius: 4px 4px 0 0;
  background: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  width: calc(100vw - 30px);
  left: 15px;
  display: flex;
  padding: 10px 0;
  animation: ${enterAnimation} 0.3s ease-in normal;

  .left {
    width: 210px;
    flex-shrink: 0;
    padding: 0 10px;
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;

    h3 {
      margin: 0;
    }
    p {
      line-height: 16px;
      margin: 0;
      padding: 0;
      color: ${colors.grey};
    }
  }
  .right {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-height: 48px;
    overflow: auto;
    > div {
      flex-grow: 0;
      flex-shrink: 0;
      border-radius: 4px;
      margin-bottom: 4px;
      margin-right: 8px;
      cursor: pointer;
      height: 22px;
      overflow: hidden;
      display: flex;
      align-items: center;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 11px;
        padding-left: 4px;
        display: inline-block;
      }
    }
  }
`;
