import React from 'react';
import axios from 'axios';
import { IWeek, ITypicalWeekShift, InitialStateType } from './store';
import { IScheduleModelResponse } from '../ScheduleModel';
import { message } from 'antd';
import i18n from '@/i18n.js';
import { store } from './store';
import { IShift } from '@/types/shift.model';

interface RESET {
  type: 'RESET';
}

interface SET_SHIFT_DRAWER_VISIBLE {
  type: 'SET_SHIFT_DRAWER_VISIBLE';
  payload: boolean;
}

interface SET_SELECTED_SHIFTS {
  type: 'SET_SELECTED_SHIFTS';
  payload: string[];
}

interface ADD_SELECTED_SHIFT {
  type: 'ADD_SELECTED_SHIFT';
  payload: string;
}

interface ADD_SELECTED_SHIFTS {
  type: 'ADD_SELECTED_SHIFTS';
  payload: string[];
}

interface REMOVE_SELECTED_SHIFT {
  type: 'REMOVE_SELECTED_SHIFT';
  payload: string;
}

interface SET_IS_LOADING {
  type: 'SET_IS_LOADING';
  payload: boolean;
}

interface SET_WEEKS {
  type: 'SET_WEEKS';
  payload: IWeek[];
}

interface SET_SCHEDULE_MODEL_LIST {
  type: 'SET_SCHEDULE_MODEL_LIST';
  payload: InitialStateType['scheduleModelList'];
}

interface SET_SKILLS {
  type: 'SET_SKILLS';
  payload: InitialStateType['skills'];
}

interface SET_SECTIONS {
  type: 'SET_SECTIONS';
  payload: InitialStateType['sections'];
}

interface SET_SHIFT_OPTIMISTIC {
  type: 'SET_SHIFT_OPTIMISTIC';
  payload: {
    shiftId: string;
    optimistic: boolean;
  };
}

interface SET_SELECTED_SHIFT {
  type: 'SET_SELECTED_SHIFT';
  payload: ITypicalWeekShift | null;
}

interface SET_SHIFT_IDS_TO_DELETE {
  type: 'SET_SHIFT_IDS_TO_DELETE';
  payload: string[] | null;
}

interface UPDATE_SHIFT {
  type: 'UPDATE_SHIFT';
  payload: ITypicalWeekShift;
}

interface COPY_SHIFTS {
  type: 'COPY_SHIFTS';
  payload: ITypicalWeekShift | null;
}

export type ActionType =
  | RESET
  | SET_SHIFT_DRAWER_VISIBLE
  | ADD_SELECTED_SHIFT
  | ADD_SELECTED_SHIFTS
  | REMOVE_SELECTED_SHIFT
  | SET_SELECTED_SHIFTS
  | SET_IS_LOADING
  | SET_WEEKS
  | SET_SKILLS
  | SET_SECTIONS
  | SET_SCHEDULE_MODEL_LIST
  | SET_SHIFT_OPTIMISTIC
  | SET_SELECTED_SHIFT
  | SET_SHIFT_IDS_TO_DELETE
  | UPDATE_SHIFT
  | COPY_SHIFTS;

export const updateScheduleModelStore = (dispatch: React.Dispatch<ActionType>, data: IScheduleModelResponse) => {
  const { weeks, skills, sections, scheduleModelList } = data;

  dispatch({
    type: 'SET_IS_LOADING',
    payload: true,
  });
  dispatch({
    type: 'SET_WEEKS',
    payload: weeks,
  });
  dispatch({
    type: 'SET_SCHEDULE_MODEL_LIST',
    payload: scheduleModelList,
  });
  dispatch({
    type: 'SET_SECTIONS',
    payload: sections,
  });
  dispatch({
    type: 'SET_SKILLS',
    payload: skills,
  });
  dispatch({
    type: 'COPY_SHIFTS',
    payload: null,
  });
  dispatch({
    type: 'SET_IS_LOADING',
    payload: false,
  });
};

export const copyShifts = (distpatch: React.Dispatch<ActionType>, shift: ITypicalWeekShift) => {
  distpatch({
    type: 'COPY_SHIFTS',
    payload: shift,
  });
  message.info(i18n.t('SCHEDULE.X_SHIFT(S)_COPIED', { count: 1 }));
};
