import AppContext from '@/pages/app/context';
import ShiftDetailsIcons from '@/pages/app/hours/manage/components/ShiftDetailsIcons';
import ShiftPeriod from '@/pages/app/hours/manage/components/ShiftPeriod';
import ShiftTags from '@/pages/app/hours/manage/components/ShiftTags';
import ShiftTasks from '@/pages/app/hours/manage/daily/components/ShiftTasks';
import { InitialStateType } from '@/pages/app/hours/manage/redux/store';
import Colors from '@/styles/colors';
import { IShift } from '@/types/shift.model';
import moment from 'moment';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
interface Props {
  className?: string;
  shift: IShift;
}

const Shift: React.FC<Props> = ({ className, shift }) => {
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const { showPrices } = useSelector(({ showPrices }: InitialStateType) => ({
    showPrices,
  }));
  const { start: shiftStart, end: shiftEnd, price, pause } = shift;
  const duration = moment.duration(shiftEnd! - shiftStart!, 'seconds');
  duration.subtract(pause!.unpaid!, 'seconds');
  const durationInMinutes = duration.asMinutes();
  const pauseDurationInMinutes = moment.duration(pause!.paid! + pause!.unpaid!, 'seconds').asMinutes();

  return (
    <div className={`${className} ${shift.dayoff ? 'dayoff' : ''}`} key={`shift_${shift.id}`}>
      {!shift.dayoff && (
        <React.Fragment>
          <ShiftPeriod start={shiftStart!} end={shiftEnd!} />
          <ShiftTasks tasks={shift.tasks || []} shiftId={shift.id!} />
          <ShiftDetailsIcons
            hidePrice={!activeDepartment?.scheduleParams?.showShiftPrice}
            durationInMinutes={durationInMinutes}
            pauseDurationInMinutes={pauseDurationInMinutes}
            price={price!}
            currency={activeDepartment?.currency}
          />
          <ShiftTags picker={'day'} section={shift.section} skills={shift.skills || []} />
        </React.Fragment>
      )}
      {shift.dayoff && (
        <React.Fragment>
          {!shift.dayoff!.fullDay && <ShiftPeriod start={shiftStart!} end={shiftEnd!} />}
          <h3>{shift.dayoff!.shortCode}</h3>
          <p className="dayoff-label">{shift.dayoff!.name}</p>
          <ShiftDetailsIcons
            hidePrice={!activeDepartment?.scheduleParams?.showShiftPrice}
            durationInMinutes={durationInMinutes}
            pauseDurationInMinutes={pauseDurationInMinutes}
            price={price!}
            currency={activeDepartment?.currency}
          />
          <ShiftTags picker={'day'} section={shift.section} skills={shift.skills || []} />
          <div></div>
        </React.Fragment>
      )}
    </div>
  );
};

const ShiftStyled = styled(Shift)`
  background: ${Colors.blueExtraLight};
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin: 2px 0;
  text-align: left;
  color: ${Colors.blue};
  margin-bottom: 5px;

  h3 {
    font-weight: 600;
    color: black;
    font-size: 22px;
    margin: 0;
  }

  p.dayoff-label {
    color: ${Colors.grey};
  }

  > div {
    &:nth-of-type(n + 2) {
      margin-top: 8px;
      span {
        display: inline-block;
        &:nth-of-type(n - 1) {
          margin-right: 5px;
        }
      }
    }
  }

  &.dayoff {
    background-image: repeating-linear-gradient(
      -55deg,
      transparent,
      transparent 10px,
      rgba(0, 0, 0, 0.03) 10px,
      rgba(0, 0, 0, 0.03) 20px
    );

    background-image: repeating-linear-gradient(-55deg, transparent 0 10px, rgba(0, 0, 0, 0.03) 10px 20px);
  }
`;

export default ShiftStyled;
