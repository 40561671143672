import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { Button, notification } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InitialStateType } from '../../redux/store';

interface Props {
  small?: boolean;
  isLoading: boolean;
  shiftNotApprovedIds: string[];
}

const GeneratePdf: React.FC<Props> = ({ small, isLoading, shiftNotApprovedIds }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, activeSection },
  } = useContext(AppContext);
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const { startDate } = useSelector(({ startDate }: InitialStateType) => ({
    startDate,
  }));

  const onGeneratePdf = () => {
    setGeneratingPdf(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/operations/publish-shifts`, {
        sectionId: activeSection,
        departmentId: activeDepartment?.id,
        generate: true,
        start: startDate.unix(),
      })
      .then(() => {
        setGeneratingPdf(false);
        notification.open({
          message: t('SHIFTS.GENERATE_SCHEDULE.MESSAGE'),
          type: 'info',
        });
      })
      .catch(() => {
        setGeneratingPdf(false);
        notification.open({
          message: t('SHIFTS.GENERATE_SCHEDULE.ERROR'),
          type: 'error',
        });
      });
  };

  return (
    <>
      {!isLoading && !shiftNotApprovedIds.length && (
        <Button
          loading={generatingPdf}
          type="primary"
          style={{
            width: small ? 'auto' : '160px',
            flex: 1,
            backgroundColor: colors.blueLightPastel,
            borderColor: colors.blueLightPastel,
            borderRadius: 8,
          }}
          onClick={onGeneratePdf}
        >
          {t('SCHEDULE.GENERATE_PDF')}
        </Button>
      )}
    </>
  );
};

export default GeneratePdf;
