import React from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';
import Colors from '@/styles/colors';
import { RESOURCE_TYPES } from '@/types/field.model';
import { useTranslation } from 'react-i18next';
import { getResourceTranslated } from '@/utils';

interface Props {
  className?: string;
  activeDepartmentId?: string;
  columnFilters: Map<string, { name: string; value: boolean; resourceType?: string }>;
  setColumnFilters: React.Dispatch<React.SetStateAction<Map<string, { name: string; value: boolean }>>>;
}

const HeaderOptionDetails: React.FC<Props> = ({ className, columnFilters, activeDepartmentId, setColumnFilters }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <div className={className}>
      {[...columnFilters.keys()].map((columnKey) => {
        const column = columnFilters.get(columnKey);
        const title = column ? getResourceTranslated(column) : '';
        return (
          <div className="row" key={columnKey}>
            <span>{title}</span>
            <Switch
              size="small"
              checked={column?.value}
              onClick={() => {
                const newColumnFilters = new Map(columnFilters);
                const newColumn: any = { ...column, value: !column?.value };
                newColumnFilters.set(columnKey, newColumn);
                const userColmunFilters = localStorage.getItem('userColmunFilters');
                const userColmunFiltersParsed = JSON.parse(userColmunFilters || '{}');
                for (const key of columnFilters.keys()) {
                  userColmunFiltersParsed[key] = newColumnFilters.get(key)?.value || false;
                }
                localStorage.setItem(
                  `userColumnFilters_${activeDepartmentId}`,
                  JSON.stringify(userColmunFiltersParsed),
                );
                setColumnFilters(newColumnFilters);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const HeaderOptionDetailsStyled = styled(HeaderOptionDetails)`
  width: 230px;
  color: ${Colors.grey};

  .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:nth-of-type(n + 2) {
      padding-top: 5px;
    }
  }
`;

export default HeaderOptionDetailsStyled;
