import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { createNumberArray } from '@/utils';
import { IBiDepartment } from '@/types/bi/department.model';
import { IBiShift } from '@/types/bi/shift.model';
import moment from 'moment';

interface Props {
  className?: string;
  department: IBiDepartment;
  shifts: IBiShift[];
}

const DepartmentPlannedUsers: React.FC<Props> = ({ className, department, shifts }) => {
  const { t } = useTranslation();
  const {
    state: {
      activeDepartment,
      groupAdminStart,
      groupAdminSections,
      groupAdminAttributes,
      groupAdminSkills,
      groupAdminStatus,
      groupAdminUsers,
    },
    dispatch,
  } = useContext(AppContext);

  const hours = createNumberArray(0, 23);

  let filteredShifts = [...shifts];
  filteredShifts = filteredShifts.filter((shift) => {
    if (
      groupAdminAttributes.length == 0 &&
      groupAdminSections.length == 0 &&
      groupAdminSkills.length == 0 &&
      groupAdminStatus.length == 0 &&
      groupAdminUsers.length == 0
    )
      return true;
    const user = department.users.find((user) => user.id == shift.ressource_record_id);
    if (user && groupAdminUsers.length > 0 && groupAdminUsers.includes(user.id)) return true;
    if (user && user.user_type && groupAdminStatus.length > 0 && groupAdminStatus.includes(user.user_type)) return true;
    if (groupAdminSections.length > 0 && groupAdminSections.includes(String(shift.section))) return true;
    if (
      shift.skills &&
      groupAdminSkills.length > 0 &&
      groupAdminSkills.some((element) => shift.skills.includes(element))
    )
      return true;
    if (
      shift.attributes &&
      groupAdminAttributes.length > 0 &&
      groupAdminAttributes.some((element) => shift.attributes.includes(element))
    )
      return true;
    return false;
  });

  return (
    <div className={className}>
      <div className="empty"></div>
      <div className="hours">
        {hours.map((hour) => {
          const shiftsInHour = filteredShifts.filter((shift) => {
            const shiftStart = moment(shift.startdate);
            const shiftEnd = moment(shift.enddate);
            const startHour = shiftStart.hour();
            const endMinutes = shiftEnd.minutes();
            const endHour = shiftEnd.hour() + endMinutes / 60;
            if (startHour <= hour && shiftEnd.date() > shiftStart.date()) return true;
            return startHour <= hour && endHour > hour;
          });
          return (
            <div key={hour} className="hour">
              {shiftsInHour.length}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default styled(DepartmentPlannedUsers)`
  display: flex;
  align-items: center;
  background-color: #e2e6ef;

  .empty {
    width: 200px;
  }

  .hours {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;

    .hour {
      flex: 1;
      text-align: center;
    }
  }
`;
