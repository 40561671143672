import { Button, message, Modal, Space, Tooltip } from 'antd';
import { CheckSquareFilled, PushpinOutlined, LoadingOutlined, CloseSquareFilled } from '@ant-design/icons';
import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '@/styles/colors';
import { AxiosResponse } from 'axios';
import { IClockingValidation } from '..';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  clocking: IClockingValidation;
  departmentId?: string;
  handleValidate: (clockingIds: string[]) => Promise<AxiosResponse<any>>;
  handleCopyFromShift: (clockingId: string) => Promise<AxiosResponse<any>>;
  handleDelete: (clockingId: string) => Promise<AxiosResponse<any>>;
  setClockingToAttach: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedClocking: (value: React.SetStateAction<IClockingValidation | null>) => void;
}

const TableRowActions: React.FC<Props> = ({
  className,
  clocking,
  handleValidate,
  handleCopyFromShift,
  handleDelete,
  setClockingToAttach,
  setSelectedClocking,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [actionLoading, setActionLoading] = useState<boolean>(false);

  const onApprove = (e: React.MouseEvent<HTMLElement>) => {
    setActionLoading(true);
    e.preventDefault();
    e.stopPropagation();

    handleValidate([clocking.id])
      .then(() => {
        setActionLoading(false);
      })
      .catch((error) => {
        setActionLoading(false);
        message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
        console.log(error);
      });
  };

  const onCopyFromShift = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setActionLoading(true);
    handleCopyFromShift(clocking.id)
      .then(() => {
        setActionLoading(false);
      })
      .catch((error) => {
        setActionLoading(false);
        message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
        console.log(error);
      });
  };

  const onDelete = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setSelectedClocking(null);
    Modal.confirm({
      className: 'modal-danger',
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('SCHEDULE.CLOCKING_VERIFICATION.MODAL_DELETE_CLOCKING.CONTENT', { name: clocking.user.displayName }),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        setActionLoading(true);
        handleDelete(clocking.id)
          .then(() => {
            setActionLoading(false);
          })
          .catch((error) => {
            setActionLoading(false);
            message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
            console.log(error);
          });
      },
      onCancel: () => {},
    });
  };

  const onAttachToShift = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setClockingToAttach(clocking.id);
  };

  return (
    <Space size="middle" className={className}>
      {actionLoading ? (
        <LoadingOutlined className="loading" />
      ) : (
        <React.Fragment>
          {clocking?.shift && (
            <Tooltip title={t('CLOCKINGS.USE_PLANNED_SHIFT_HOURS')}>
              <Button onClick={onCopyFromShift}>
                <i className="icon-copy" />
              </Button>
            </Tooltip>
          )}
          {!clocking?.shift && (
            <Tooltip title={t('CLOCKINGS.MODAL_CLOCKING_TO_ATTACH.TITLE')}>
              <Button onClick={onAttachToShift} style={{ color: colors.blue, borderColor: colors.blue }}>
                <PushpinOutlined />
              </Button>
            </Tooltip>
          )}
          <CheckSquareFilled className="green" onClick={onApprove} />
          <CloseSquareFilled className="red" onClick={onDelete} />
        </React.Fragment>
      )}
    </Space>
  );
};

const TableRowActionsStyled = styled(TableRowActions)`
  .green {
    font-size: 34px;
    cursor: pointer;
    color: ${colors.green};
  }
  .red {
    font-size: 34px;
    color: ${colors.redDark};
    cursor: pointer;
  }
  .loading {
    font-size: 34px;
    color: ${colors.greenLight};
    text-align: center;
    padding: 0 8px;
  }
`;
export default TableRowActionsStyled;
