import CustomDatePicker from '@/pages/app/components/CustomDatePicker';
import NewBadge from '@/pages/app/components/NewBadge';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { Button } from 'antd';
import moment, { Moment } from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ModalImportTurnoverFile from './modal-import-turnover-file';

interface Props {
  className?: string;
  startDate: moment.Moment;
  setStartDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
}

const Header: React.FC<Props> = ({ className, startDate, setStartDate }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  const [modalUploadDataVisible, setModalUploadDataVisible] = useState(false);

  const onArrowClick = (n: number) => {
    setStartDate(moment(startDate).add(n, 'month'));
  };

  const onDatePickerChange = (date: Moment | null) => {
    setStartDate(date || moment().startOf('month'));
  };

  const onCopyLastMonth = () => {
    // waiting for v4 endpoint
  };

  const onImportProvisional = () => {
    setModalUploadDataVisible(true);
  };

  return (
    <div className={className}>
      <div className="left">
        <button onClick={() => onArrowClick(-1)}>
          <i className="icon-angle-left" />
        </button>
        <CustomDatePicker picker="month" startDate={startDate} onDatePickerChange={onDatePickerChange} />
        <button onClick={() => onArrowClick(1)}>
          <i className="icon-angle-right" />
        </button>
      </div>
      <div className="right">
        <Button type="primary" onClick={onImportProvisional} style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <NewBadge noTooltip end="2024-12-01" /> {t('SETTINGS.INSIGHTS.DAILY_TURNOVER.UPLOAD_PROVISIONAL_DATA')}
        </Button>
        <Button onClick={onCopyLastMonth}>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.COPY_LAST_MONTH')}</Button>
      </div>
      <ModalImportTurnoverFile
        modalUploadDataVisible={modalUploadDataVisible}
        setModalUploadDataVisible={setModalUploadDataVisible}
      />
    </div>
  );
};

export default styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: ${colors.greyLight};
  padding: 20px;

  .right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
