import React, { Dispatch } from 'react';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Menu } from 'antd';
import { IShift } from '@/types/shift.model';
import colors from '@/styles/colors';
import { useDispatch } from 'react-redux';
import { ActionType, copyShifts } from '../../redux/actions';
import { useTranslation } from 'react-i18next';
import { ITypicalWeekShift } from '../../redux/store';

interface Props {
  shift: IShift;
  close: () => void;
}

const ShiftRightClickMenu: React.FC<Props> = ({ shift, close }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const dispatch: Dispatch<ActionType> = useDispatch();

  // Handlers
  const handleDeleteShift = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    dispatch({
      type: 'SET_SHIFT_IDS_TO_DELETE',
      payload: [shift.id!],
    });
    close();
  };

  const handleCopyShift = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    copyShifts(dispatch, shift as ITypicalWeekShift);
    close();
  };

  if (shift.shyftType === 3) {
    return (
      <Menu>
        <Menu.Item
          key={`shift_${shift.id}_rightClick_delete`}
          onClick={handleDeleteShift}
          style={{ color: colors.red }}
        >
          <i className="icon-delete" /> {t('GLOBAL.REMOVE')}
        </Menu.Item>
      </Menu>
    );
  } else {
    return (
      <Menu>
        <Menu.Item key={`shift_${shift.id}_rightClick_copy`} onClick={handleCopyShift}>
          <i className="icon-copy" /> {t('GLOBAL.COPY')}
        </Menu.Item>
        <Menu.Item
          key={`shift_${shift.id}_rightClick_delete`}
          onClick={handleDeleteShift}
          style={{ color: colors.red }}
        >
          <i className="icon-delete" /> {t('GLOBAL.REMOVE')}
        </Menu.Item>
      </Menu>
    );
  }
};

export default ShiftRightClickMenu;
