import AppContext from '@/pages/app/context';
import { ITurnoverTemplate } from '@/types/insights/turnover-template.model';
import { handleError } from '@/utils';
import { Form, message, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InitialStateType } from '../hours/manage/redux/store';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalApplyTurnoverTemplate: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, turnoverTemplates, loadingTurnoverTemplates },
    dispatch,
  } = useContext(AppContext);
  const { startDate, endDate } = useSelector(({ startDate, endDate }: InitialStateType) => ({
    startDate,
    endDate,
  }));
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    setVisible(false);
  };

  const onFinish = (values: any) => {
    if (!values.turnover_template_id) return;
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL_V4}/turnover-quota-templates/${values.turnover_template_id}/copy`,
        {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        setVisible(false);
        message.success(t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TEMPLATE_APPLIED'));
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      className={className}
      maskClosable={true}
      visible={visible}
      onCancel={onCancel}
      onOk={() => form.submit()}
      title={t('SCHEDULE.APPLY_TURNOVER_TEMPLATE')}
      okText={t('GLOBAL.CONFIRM')}
      cancelText={t('GLOBAL.CANCEL')}
      confirmLoading={loading}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item label={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TITLE')} name="turnover_template_id">
          <Select
            allowClear
            showSearch
            loading={loadingTurnoverTemplates}
            placeholder={t('SETTINGS.INSIGHTS.TURNOVER_TEMPLATES.TITLE')}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {turnoverTemplates.map((template: ITurnoverTemplate) => (
              <Select.Option value={template.id}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>{template.name}</span>
                  {template.is_default && <span>{t('GLOBAL.DEFAULT')}</span>}
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalApplyTurnoverTemplate)``;
