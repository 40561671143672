import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IAvailableUser } from '@/types/swap-shift/available-user.model';
import { ISwapShiftFilter } from '@/types/swap-shift/filter.model';
import { ISwapShift } from '@/types/swap-shift/swap-shift.model';
import { ISwapShiftUser } from '@/types/swap-shift/user.model';
import { IUser } from '@/types/user.model';
import { getWindowSize, minutesToHoursAndOrMinutes } from '@/utils';
import { Button, List, Modal, Select, Table, Tooltip } from 'antd';
import { SelectValue } from 'antd/es/select';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  filter: ISwapShiftFilter;
  swapShifts: ISwapShift[];
  setSwapShifts: React.Dispatch<React.SetStateAction<ISwapShift[]>>;
  swapShift: ISwapShift;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalSwapShiftDetails: React.FC<Props> = ({
  className,
  filter,
  swapShifts,
  setSwapShifts,
  swapShift,
  visible,
  setVisible,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const [columns, setColumns] = useState<any[]>([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [availableUsers, setAvailableUsers] = useState<IAvailableUser[]>([]);
  const [availableUsersLoading, setAvailableUsersLoading] = useState<boolean>(false);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [activeUser, setActiveUser] = useState<SelectValue | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);

  const approved = swapShift.swapShift.users.find((user) => user.admin_approved_at);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setColumns([
        {
          title: t('GLOBAL.COLLABORATOR'),
          key: 'name',
          render: (text: string, record: ISwapShiftUser) => {
            return <span>{record.name || `${record.first_name} ${record.last_name}`}</span>;
          },
        },
        {
          title: t('GLOBAL.STATUS'),
          key: 'status',
          render: (text: string, record: ISwapShiftUser) => {
            if (record.admin_approved_at) {
              return (
                <div className="badge admin-approved">
                  <span>{t('SWAP_SHIFT.STATUSES.ADMIN_APPROVED')}</span>
                </div>
              );
            }

            if (!record.user_approved_at && !record.user_denied_at) {
              if (!swapShift.swapShift.active || approved) {
                return (
                  <div className="badge user-not-replied">
                    <span>{t('SWAP_SHIFT.STATUSES.USER_NOT_REPLIED')}</span>
                  </div>
                );
              }
              return (
                <div className="badge user-waiting">
                  <span>{t('SWAP_SHIFT.STATUSES.USER_WAITING')}</span>
                </div>
              );
            }

            if (record.user_approved_at) {
              return (
                <div className="badge user-approved">
                  <span>{t('SWAP_SHIFT.STATUSES.USER_APPROVED')}</span>
                </div>
              );
            }

            if (record.user_denied_at) {
              return (
                <div className="badge user-denied">
                  <span>{t('SWAP_SHIFT.STATUSES.USER_DENIED')}</span>
                </div>
              );
            }
          },
        },
        {
          title: t('GLOBAL.COST'),
          key: 'cost',
          render: (text: string, record: ISwapShiftUser) => {
            return <span>{record.hourly_rate || '-'}</span>;
          },
        },
        {
          title: t('SWAP_SHIFT.COLUMNS.QUARTER_WORKED_HOURS'),
          key: 'quarter_hours',
          render: (text: string, record: ISwapShiftUser) => {
            return <span>{minutesToHoursAndOrMinutes(Number(record.worktime_trimester)) || '-'}</span>;
          },
        },
        {
          title: t('SWAP_SHIFT.COLUMNS.MONTHLY_WORKED_HOURS'),
          key: 'monthly_hours',
          render: (text: string, record: ISwapShiftUser) => {
            return <span>{minutesToHoursAndOrMinutes(Number(record.worktime_month)) || '-'}</span>;
          },
        },
        {
          title: t('GLOBAL.APPROVE'),
          key: 'approve',
          render: (text: string, record: ISwapShiftUser) => {
            if (!approved && !record.admin_approved_at && swapShift.swapShift.active) {
              return (
                <Tooltip overlay={<span>{t('GLOBAL.APPROVE')}</span>}>
                  <Button
                    type="primary"
                    onClick={() => onApprove(record.id)}
                    loading={swapShift.swapShift.id === record.swap_shift_id && approveLoading}
                    disabled={approveLoading}
                  >
                    <i className="icon-ok" />
                  </Button>
                </Tooltip>
              );
            }
          },
        },
      ]);
    }

    return () => {
      mounted = false;
    };
  }, [swapShift, approveLoading]);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();

    if (mounted) {
      setAvailableUsersLoading(true);
      const { id, start, end } = swapShift.shift;
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/operations/get-users`, {
          params: {
            departmentId: activeDepartmentId,
            start,
            end,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then(({ data }) => {
          const res = data.filter((el: any) => el.constraints == undefined);
          setAvailableUsers(res);
          setAvailableUsersLoading(false);
        })
        .catch((error) => {
          setAvailableUsersLoading(false);
          console.log(error);
        });
    }

    return () => {
      mounted = false;
    };
  }, []);

  const onApprove = (swapShiftRequestId: string) => {
    setApproveLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/swap-shift/${swapShift.swapShift.id}/user-request/${swapShiftRequestId}/accept`,
        null,
        {
          params: {
            departmentId: activeDepartmentId,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
      .then(({ data }) => {
        setVisible(false);
        setSwapShifts(swapShifts.filter((ss: ISwapShift) => ss.swapShift.id !== swapShift.swapShift.id));
        setApproveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setApproveLoading(false);
        if (err.response?.data?.errors) {
          setShowErrorsModal(true);
          setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
        }
      });
  };

  const onOk = () => {
    setVisible(false);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onAssignUser = () => {
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/swap-shift/${swapShift.swapShift.id}/force-user`,
        {
          recordId: activeUser,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
      .then(({ data }) => {
        setVisible(false);
        setSwapShifts(swapShifts.filter((ss: ISwapShift) => ss.swapShift.id !== swapShift.swapShift.id));
        setApproveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data?.errors) {
          setShowErrorsModal(true);
          setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
        }
      });
  };

  const onErrorsOk = () => {
    setShowErrorsModal(false);
  };

  const onErrorsCancel = () => {
    setShowErrorsModal(false);
  };

  return (
    <Modal
      className={className}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      title={t('SWAP_SHIFT.MODAL_DETAILS.TITLE')}
      width={windowSize.innerWidth > 1000 ? 1000 : '100%'}
      footer={null}
    >
      <Modal
        forceRender={true}
        destroyOnClose={true}
        visible={showErrorsModal}
        className={'modal-danger'}
        title={t('SWAP_SHIFT.DELETE_ERROR')}
        onOk={onErrorsOk}
        onCancel={onErrorsCancel}
        footer={[
          <Button key="back" onClick={onErrorsCancel}>
            {t('GLOBAL.OK')}
          </Button>,
        ]}
        style={{ zIndex: 999 }}
        bodyStyle={{ zIndex: 999 }}
        maskStyle={{ zIndex: 999 }}
      >
        <List
          dataSource={errors}
          renderItem={(item) => (
            <List.Item>
              <p>{item}</p>
            </List.Item>
          )}
        />
      </Modal>
      <TableView>
        <Table
          dataSource={swapShift.swapShift.users}
          columns={columns}
          pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
        />

        {!approved && (filter === 'PENDING' || (filter === 'ALL' && swapShift.swapShift.active)) && (
          <div className="available-users-container">
            <h4>{t('SWAP_SHIFT.MODAL_DETAILS.SELECT_DIFFERENT_USER_PLACEHOLDER')}</h4>
            <Select
              className="available-users-dropdown"
              size="large"
              placeholder={t('SWAP_SHIFT.MODAL_DETAILS.SELECT_DIFFERENT_USER_PLACEHOLDER')}
              loading={availableUsersLoading}
              onChange={(value) => setActiveUser(value)}
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {availableUsers.map((user) => (
                <Select.Option value={user.recordId}>{user.displayName}</Select.Option>
              ))}
            </Select>
            <Button size="large" type="primary" onClick={onAssignUser}>
              {t('SWAP_SHIFT.ASSIGN_USER')}
            </Button>
          </div>
        )}
      </TableView>
    </Modal>
  );
};

export default styled(ModalSwapShiftDetails)`
  .badge {
    display: inline-block;
    padding: 3px 10px;
    border-radius: 10px;
    color: #fff;
  }

  .admin-approved {
    background-color: ${colors.green};
  }

  .user-waiting,
  .user-not-replied {
    background-color: ${colors.orange};
  }

  .user-approved {
    background-color: ${colors.green};
  }

  .user-denied {
    background-color: ${colors.redDark};
  }

  .available-users-container {
    display: flex;
    align-items: center;
    margin-top: 25px;
    gap: 25px;

    h4 {
      margin: 0;
    }

    .available-users-dropdown {
      width: 50%;
    }
  }
`;
