import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import HrSheet from './HrSheet';
import moment from 'moment';
import 'moment-timezone';
import styled from 'styled-components';
import { IHrSheet } from '..';
import AppContext from '@/pages/app/context';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  hrSheets: IHrSheet[];
  datePickerValue: moment.Moment;
  setDatePickerValue: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setHrSheets: React.Dispatch<React.SetStateAction<IHrSheet[]>>;
}

const HrSheetPage: React.FC<Props> = ({ className, hrSheets, datePickerValue, setDatePickerValue, setHrSheets }) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const { userRecordId, start } = useParams() as any;

  return (
    <div className={className}>
      <HrSheet
        userRecordId={userRecordId}
        start={moment.unix(parseInt(start))}
        datePickerValue={datePickerValue}
        setDatePickerValue={setDatePickerValue}
        hrSheets={hrSheets}
        setHrSheets={setHrSheets}
      />
    </div>
  );
};

export default styled(HrSheetPage)`
  position: relative;
`;
