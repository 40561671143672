import colors from '@/styles/colors';
import { Button } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  notification: any;
}

const NotificationItem: React.FC<Props> = ({ className, notification }) => {
  return (
    <div className={className}>
      <div className="content">
        <h4>{notification?.data?.message}</h4>
        {!!notification?.data?.description && <p className="description">{notification?.data?.description}</p>}
        {!!notification?.created_at && <p className="date">{moment(notification?.created_at).format('LLL')}</p>}
        <NotificationItemActions notification={notification} />
      </div>
      {!notification.read_at && (
        <div className="status">
          <div className="unread" />
        </div>
      )}
    </div>
  );
};

const NotificationItemActions: React.FC<Props> = ({ notification }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const {
    data: { action = null, meta = null },
  } = notification;

  const link = notification.data.action_url || notification.data.link;
  const hasLink = meta ? (meta.file ? true : false) : false;

  if (hasLink) {
    return (
      <div>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            window.open(meta.file, '_blank');
          }}
          style={{ background: colors.blue, borderColor: colors.blue }}
        >
          {t('GLOBAL.DOWNLOAD')}
        </Button>
      </div>
    );
  }

  switch (action) {
    case 'DOWNLOAD': {
      return (
        <div>
          <Button
            size="small"
            type="primary"
            onClick={() => {
              window.open(link, '_blank');
            }}
            style={{ background: colors.blue, borderColor: colors.blue }}
          >
            {t('GLOBAL.DOWNLOAD')}
          </Button>
        </div>
      );
    }
    case 'LINK': {
      return (
        <div>
          <a style={{ color: colors.green }} href={link}>
            {t('GLOBAL.GO_TO_PAGE')}
          </a>
        </div>
      );
    }
    default:
      return null;
  }
};

export default styled(NotificationItem)`
  display: flex;
  .content {
    position: relative;
    flex: 1;
    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #273140;
      margin-bottom: 0;
    }
    p {
      font-size: 12px;
      line-height: 18px;
      color: ${colors.grey};
      font-style: normal;
      white-space: normal;

      &.description {
        margin: 0;
      }
      &.date {
        font-style: italic;
        font-size: 10px;
      }
    }
  }
  .status {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
  .unread {
    background: ${colors.green};
    border-radius: 4px;
    height: 8px;
    width: 8px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
`;
