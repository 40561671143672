import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import colors from '@/styles/colors';
import { Button, DatePicker, Divider, Empty, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import axios from 'axios';
import { handleError } from '@/utils';
import { IShiftsHistory } from '@/types/shifts-history/shifts-history.model';
import { IShiftsHistoryData } from '@/types/shifts-history/shifts-history-data.model';
import moment from 'moment';
import { uniqueId } from 'lodash';
import { IShiftsHistoryShift } from '@/types/shifts-history/shifts-history-shift.model';
import { useLocation } from 'react-router-dom';

interface Props {
  className?: string;
}

const ShiftsHistory: React.FC<Props> = ({ className }) => {
  const { t, i18n } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [columns, setColumns] = useState<ColumnsType<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [shiftsHistory, setShiftsHistory] = useState<IShiftsHistory | null>(null);
  const [date, setDate] = useState<moment.Moment | null>(null);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    i18n.changeLanguage('en');
    const date = params.get('date');
    if (!date) return;
    setDate(moment(date));
  }, [search]);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.ACTION'),
        dataIndex: 'action',
        key: 'action',
        render: (value, record: IShiftsHistoryData | IShiftsHistoryShift) => {
          if ('parent' in record) {
            return `${record.parent.action}`;
          }
          return `${record.action}`;
        },
        width: `${100 / columns.length}%`,
      },
      {
        title: t('GLOBAL.DATE'),
        dataIndex: 'date',
        key: 'date',
        render: (value, record: IShiftsHistoryData | IShiftsHistoryShift) => {
          if ('parent' in record) {
            return `${moment(record.parent.start).format('L')}`;
          }
          return `${moment(record.start).format('L')}`;
        },
        width: `${100 / columns.length}%`,
      },
      {
        title: t('GLOBAL.START'),
        dataIndex: 'start_time',
        key: 'start_time',
        render: (value, record: IShiftsHistoryData | IShiftsHistoryShift) => {
          if ('parent' in record) {
            return `${moment(record.parent.start).format('HH:mm')}`;
          }
          return `${moment(record.start).format('HH:mm')}`;
        },
        width: `${100 / columns.length}%`,
      },
      {
        title: t('GLOBAL.END'),
        dataIndex: 'end_time',
        key: 'end_time',
        render: (value, record: IShiftsHistoryData | IShiftsHistoryShift) => {
          if ('parent' in record) {
            return `${moment(record.parent.end).format('HH:mm')}`;
          }
          return `${moment(record.end).format('HH:mm')}`;
        },
        width: `${100 / columns.length}%`,
      },
      {
        title: t('GLOBAL.FIRSTNAME'),
        dataIndex: 'first_name',
        key: 'first_name',
        render: (value, record: IShiftsHistoryData | IShiftsHistoryShift) => {
          if ('parent' in record) {
            return `${record.parent.first_name}`;
          }
          return `${record.first_name}`;
        },
        width: `${100 / columns.length}%`,
      },
      {
        title: t('GLOBAL.LASTNAME'),
        dataIndex: 'last_name',
        key: 'last_name',
        render: (value, record: IShiftsHistoryData | IShiftsHistoryShift) => {
          if ('parent' in record) {
            return `${record.parent.last_name}`;
          }
          return `${record.last_name}`;
        },
        width: `${100 / columns.length}%`,
      },
      {
        title: t('GLOBAL.NATIONAL_NUMBER'),
        dataIndex: 'national_number',
        key: 'national_number',
        render: (value, record: IShiftsHistoryData | IShiftsHistoryShift) => {
          if ('parent' in record) {
            return record.parent.national_number || '-';
          }
          return record.national_number || '-';
        },
        width: `${100 / columns.length}%`,
      },
      {
        title: 'Added at',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (value, record: IShiftsHistoryData | IShiftsHistoryShift) => {
          if ('parent' in record) {
            return moment(record.parent.created_at).format('DD/MM/YY HH:mm:ss');
          }
          return moment(record.created_at).format('DD/MM/YY HH:mm:ss');
        },
        width: `${100 / columns.length}%`,
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (value, record: IShiftsHistoryData | IShiftsHistoryShift) => {
          if ('parent' in record && !record.parent.managed) {
            return (
              <Button type="primary" onClick={() => onApprove(`${record.parent.id}`)} className="approve-btn">
                Handled
              </Button>
            );
          }
          if ('parent' in record && record.parent.managed) {
            return (
              <>
                <Button
                  type="primary"
                  danger
                  onClick={() => onApprove(`${record.parent.id}`, true)}
                  style={{ padding: '0 10px' }}
                >
                  Unhandle
                </Button>
                <div style={{ fontSize: 10 }}>{moment(record.parent.managed_date).format('DD/MM/YY HH:mm:ss')}</div>
              </>
            );
          }
        },
        width: `${100 / columns.length}%`,
      },
    ]);
  }, []);

  useEffect(() => {
    getShiftsHistory();
  }, [date]);

  const onApprove = (id: string, unhandled?: boolean) => {
    const key = params.get('key');
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/sec/shiftsHistory`,
        {},
        {
          params: {
            key,
            start: date ? date.format('YYYY-MM-DD') : undefined,
            id,
            unhandled,
          },
        },
      )
      .then(({ data }: { data: IShiftsHistory }) => {
        let formattedData: IShiftsHistory = { ...data };
        formattedData.data = data.data.map((element) => {
          return {
            id: uniqueId(),
            ...element,
          };
        });
        setShiftsHistory(formattedData);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getShiftsHistory = (managed?: '1' | '0' | 'all') => {
    const key = params.get('key');
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/sec/shiftsHistory`, {
        params: {
          key,
          start: date ? date.format('YYYY-MM-DD') : undefined,
          managed,
        },
      })
      .then(({ data }: { data: IShiftsHistory }) => {
        let formattedData: IShiftsHistory = { ...data };
        formattedData.data = data.data.map((element) => {
          return {
            id: uniqueId(),
            ...element,
          };
        });
        setShiftsHistory(formattedData);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDateChange = (date: moment.Moment | null) => {
    if (date == null) setDate(null);
    else setDate(date);
  };

  return (
    <div className={className}>
      <div className="header">
        <h4 className="title">{shiftsHistory?.infos.name}</h4>
        <div className="right">
          <span className="show" onClick={() => getShiftsHistory('1')}>
            Display handled
          </span>
          <Divider type="vertical" />
          <span className="show" onClick={() => getShiftsHistory('0')}>
            Display non-handled
          </span>
          <Divider type="vertical" />
          <span className="show" onClick={() => getShiftsHistory('all')}>
            Display all
          </span>
          <Divider type="vertical" />
          <div>
            <span style={{ marginRight: 10, fontSize: 13, textTransform: 'uppercase', fontWeight: 'bold' }}>
              Since date:
            </span>
            <DatePicker value={date} onChange={(date) => onDateChange(date)} allowClear />
          </div>
        </div>
      </div>
      {shiftsHistory?.data && shiftsHistory.data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={shiftsHistory?.data}
          pagination={false}
          rowKey="id"
          rowClassName={(record: IShiftsHistoryData) => `${record.parent.action}`}
          loading={loading}
          expandable={{
            expandedRowRender: (record: IShiftsHistoryData) => (
              <Table
                columns={columns}
                dataSource={record.parent.childs}
                pagination={false}
                rowKey="id"
                rowClassName={(record: IShiftsHistoryShift) => `${record.action}`}
                showHeader={false}
              />
            ),
            rowExpandable: (record: IShiftsHistoryData) => (record.parent.childs || []).length > 0,
          }}
        />
      ) : (
        <Table loading={loading} />
      )}
    </div>
  );
};

export default styled(ShiftsHistory)`
  background-color: ${colors.blueExtraLight};
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 25px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 25px;
    }

    .right {
      display: flex;
      align-items: center;
      gap: 10px;

      .show {
        background: #00a651;
        padding: 10px 20px;
        font-weight: bold;
        border-radius: 10px;
        font-size: 13px;
        color: white;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }

  .ant-table-expanded-row {
    > td {
      padding: 0;
      padding-left: 46px;
    }
    .ant-table {
      margin: 0 !important;
    }
  }

  .approve {
    background-color: ${colors.greenLight};
  }

  .delete {
    background-color: ${colors.redLight};
  }

  .update,
  .unapproved {
    background-color: ${colors.blueLight};
  }

  .insert {
    background-color: ${colors.orangeLight};
  }

  .approve-btn {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }

  .ant-table-expanded-row {
    .ant-table-tbody {
      .ant-table-row:nth-child(even) {
        background-color: #dddddd;
      }
      .ant-table-row:nth-child(odd) {
        background-color: #efefef;
      }
    }
  }
`;
