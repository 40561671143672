import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { getWindowSize, isFeatureEnabled } from '@/utils';
import { Form, InputNumber, Select, Switch } from 'antd';
import { SelectValue } from 'antd/es/select';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import SettingsForm from '../../components/SettingsForm';

const { Option } = Select;
interface Props {
  className?: string;
  department?: IDepartment;
  currentSettings?: IDepartmentSettings;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const InsightSettings: React.FC<Props> = ({ className, department, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { features },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [typeSelected, setTypeSelected] = useState<SelectValue | null>(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [checked, setChecked] = useState<(string | null)[]>([]);

  const updateForm = (c: any, e: any, name: any) => {
    setChecked(c ? [...checked, name] : checked.filter((el) => el != name));
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    if (currentSettings) {
      setChecked([
        ...checked,
        ...Object.entries(currentSettings.general!).map(([key, value]: any) =>
          value == true || (value !== undefined && value > 1) ? key : null,
        ),
        ...Object.entries(currentSettings.schedule!).map(([key, value]: any) =>
          value == true || (value !== undefined && value > 1) ? key : null,
        ),
        ...Object.entries(currentSettings.insights!).map(([key, value]: any) =>
          value == true || (value !== undefined && value > 1) ? key : null,
        ),
      ]);
    }
    let fieldsValue: any = {};
    if (currentSettings) {
      fieldsValue = {
        ...currentSettings,
      };
    }
    if (currentSettings?.insights?.productivity_calculation_type) {
      setTypeSelected(currentSettings?.insights?.productivity_calculation_type);
    }
    form.setFieldsValue(fieldsValue);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings({
      ...values,
      insights: {
        ...values.insights,
        default_hours: values.insights.default_hours || 0,
      },
    })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onProductivityTypeChange = (value: SelectValue) => {
    setTypeSelected(value);
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: isFeatureEnabled(features, FEATURES.PRODUCTIVITY),
    },
    {
      title: t('SETTINGS.INSIGHTS.SETTINGS.TITLES.PRODUCTIVITY'),
      visible: checked.includes('enable_turnover') && isFeatureEnabled(features, FEATURES.PRODUCTIVITY),
    },
    {
      title: t('SETTINGS.INSIGHTS.SETTINGS.TITLES.STAFF_NEEDS'),
      visible: isFeatureEnabled(features, FEATURES.QUOTAS),
    },
  ];

  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.INSIGHTS.SETTINGS.TURNOVER_RECORDING.LABEL'),
          description: t('SETTINGS.INSIGHTS.SETTINGS.TURNOVER_RECORDING.DESCRIPTION'),
          name: ['general', 'enable_turnover'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.general?.enable_turnover}
              onChange={(checked, e) => updateForm(checked, e, 'enable_turnover')}
            />
          ),
          show: true,
        },
      ],
    ],
    [
      [
        {
          label: '',
          description: '',
          rowStyle: { marginTop: 10 },
          style: { margin: 0 },
          element: (
            <div
              style={{
                display: 'flex',
                alignItems: windowSize.innerWidth > 900 ? 'center' : 'flex-start',
                flexDirection: windowSize.innerWidth > 900 ? 'row' : 'column',
                gap: 15,
              }}
            >
              <Form.Item
                style={{ margin: 0, width: windowSize.innerWidth > 900 ? 'auto' : '100%' }}
                label={t('SETTINGS.INSIGHTS.PRODUCTIVITY_CALCULATION_TYPE')}
                name={['insights', 'productivity_calculation_type']}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  style={{ width: windowSize.innerWidth > 900 ? 300 : '100%' }}
                  onChange={onProductivityTypeChange}
                >
                  <Option value="HOURS_WORKED">{t('SETTINGS.INSIGHTS.TURNOVER_VS_HOURS_WORKED')}</Option>
                  <Option value="STAFF_COSTS">{t('SETTINGS.INSIGHTS.TURNOVER_VS_STAFF_COSTS')}</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={['insights', 'default_hours']}
                label={
                  typeSelected && typeSelected == 'STAFF_COSTS' ? t('INSIGHTS.COST_TO_ADD') : t('INSIGHTS.HOURS_TO_ADD')
                }
                style={{ margin: 0, width: windowSize.innerWidth > 900 ? 'auto' : '100%' }}
              >
                <InputNumber
                  min={0}
                  step={1}
                  parser={(value) => {
                    return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                  }}
                  style={{ width: windowSize.innerWidth > 900 ? 'auto' : '100%' }}
                />
              </Form.Item>
              <Form.Item
                name={['insights', 'coefficient']}
                label={t('GLOBAL.COEFFICIENT')}
                style={{ margin: 0, width: windowSize.innerWidth > 900 ? 'auto' : '100%' }}
              >
                <InputNumber
                  min={0}
                  step={1}
                  parser={(value) => {
                    return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                  }}
                  style={{ width: windowSize.innerWidth > 900 ? 'auto' : '100%' }}
                />
              </Form.Item>
            </div>
          ),
          elementBelow: true,
          show: true,
        },
        {
          label: t('SETTINGS.INSIGHTS.SETTINGS.ENABLE_PRODUCTIVITY.LABEL'),
          description: t('SETTINGS.INSIGHTS.SETTINGS.ENABLE_PRODUCTIVITY.DESCRIPTION'),
          name: ['schedule', 'enable_provisional'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.schedule?.enable_provisional} />,
          show: true,
        },
        {
          label: t('SETTINGS.INSIGHTS.SETTINGS.USE_CLOCKING_DETAILS.LABEL'),
          description: t('SETTINGS.INSIGHTS.SETTINGS.USE_CLOCKING_DETAILS.DESCRIPTION'),
          name: ['insights', 'productivity_clocking'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.insights?.productivity_clocking} />,
          show: isFeatureEnabled(features, FEATURES.CLOCKING),
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.INSIGHTS.SETTINGS.USE_QUOTA_TEMPLATES.LABEL'),
          description: t('SETTINGS.INSIGHTS.SETTINGS.USE_QUOTA_TEMPLATES.DESCRIPTION'),
          name: ['insights', 'custom_quota_templates'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.insights?.custom_quota_templates} />,
          show: true,
        },
      ],
    ],
  ];

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.INSIGHTS.SETTINGS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default InsightSettings;
