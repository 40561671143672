import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Modal } from 'antd';

interface Props {
  className?: string;
  visible: boolean;
  title?: string;
  content: React.ReactNode;
  onOk?: () => void;
  onCancel: () => void;
}

const ModalChangesNotSaved: React.FC<Props> = ({ className, visible, title, content, onOk, onCancel }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  return (
    <Modal
      className={`modal-danger ${className}`}
      maskClosable={true}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk ? onOk : undefined}
      title={title ? title : t('GLOBAL.CHANGES_NOT_SAVED')}
      okText={onOk ? t('GLOBAL.QUIT') : undefined}
      okButtonProps={{ hidden: !onOk }}
      cancelText={onOk ? t('GLOBAL.STAY') : 'Ok'}
    >
      {content}
    </Modal>
  );
};

export default styled(ModalChangesNotSaved)``;
