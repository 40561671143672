import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

interface Props {
  userMainId?: string;
  departmentId?: string;
  setHideWorkRate: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkRate: React.FC<Props> = ({ departmentId, userMainId, setHideWorkRate }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [data, setData] = useState<{ date: number; count: number }[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    const cancelTokenSource = Axios.CancelToken.source();
    if (!departmentId || !userMainId) {
      return;
    }

    setIsLoading(true);
    setData([]);

    Axios.get(`${process.env.REACT_APP_API_URL}/v3/dashboard/work-rate`, {
      params: {
        userMainId,
        departmentId,
        shiftType: 1,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        if (response.data.find((day: any) => day.count !== 0)) {
          setData(response.data);
        } else {
          setHideWorkRate(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setIsLoading(false);

    return () => {
      cancelTokenSource.cancel();
    };
  }, [departmentId, userMainId]);

  return (
    <div style={{ width: '100%' }}>
      <Spin spinning={isLoading}>
        <h4>{t('USERS.USER.DASHBOARD.BENEFIT_RATES')}</h4>
        <CalendarHeatmap
          monthLabels={moment.monthsShort()}
          startDate={moment().subtract(1, 'year').toDate()}
          endDate={moment().toDate()}
          values={data.map((item) => ({
            date: item.date * 1000,
            count: item.count,
          }))}
          classForValue={(value: any) => {
            if (value?.count) {
              return 'color-scale-1';
            }
            return `color-empty`;
          }}
          tooltipDataAttrs={(value: any) => {
            return {
              'data-tip': `${moment(value.date).format('LL')} : ${value.count}`,
            };
          }}
        />
        <ReactTooltip />
      </Spin>
    </div>
  );
};

export default WorkRate;
