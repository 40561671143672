import produce, { enableMapSet } from 'immer';
import { InitialStateType, initialState, ITypicalWeekShift, IWeek } from './store';
import { ActionType } from './actions';
import { isNullOrUndefined } from '@/utils';

enableMapSet();

export default function reducer(state = initialState, action: ActionType): InitialStateType {
  switch (action.type) {
    case 'RESET': {
      return initialState;
    }
    case 'SET_SHIFT_DRAWER_VISIBLE': {
      return produce(state, (draftState) => {
        draftState.shiftDrawerVisible = action.payload;
      });
    }
    case 'ADD_SELECTED_SHIFT': {
      const shiftId = action.payload;
      return produce(state, (draftState) => {
        draftState.selectedShifts.add(shiftId);
      });
    }
    case 'ADD_SELECTED_SHIFTS': {
      return produce(state, (draftState) => {
        const { payload = [] } = action;
        for (let i = 0; i < payload.length; i++) {
          draftState.selectedShifts.add(payload[i]);
        }
      });
    }
    case 'REMOVE_SELECTED_SHIFT': {
      const shiftId = action.payload;
      return produce(state, (draftState) => {
        draftState.selectedShifts.delete(shiftId);
      });
    }
    case 'SET_SELECTED_SHIFTS': {
      const shiftIds = action.payload;
      return produce(state, (draftState) => {
        draftState.selectedShifts = new Set(shiftIds);
      });
    }
    case 'SET_IS_LOADING': {
      return produce(state, (draftState) => {
        const isLoading = action.payload;
        draftState.isLoading = isLoading;
        if (isLoading) {
          draftState.weeks = [];
        }
      });
    }
    case 'SET_SCHEDULE_MODEL_LIST': {
      return produce(state, (draftState) => {
        draftState.scheduleModelList = action.payload;
      });
    }
    case 'SET_WEEKS': {
      return produce(state, (draftState) => {
        const weeks = action.payload;
        draftState.weeks = weeks;

        const indexMapByShiftId = new Map<string, [number, number, number]>();
        for (let i = 0; i < weeks.length; i++) {
          const days = weeks[i].days;
          for (let j = 0; j < days.length; j++) {
            const shifts = days[j].shifts;
            for (let k = 0; k < shifts.length; k++) {
              indexMapByShiftId.set(shifts[k].id!, [i, j, k]);
            }
          }
        }
        draftState.indexMapByShiftId = indexMapByShiftId;
      });
    }
    case 'UPDATE_SHIFT': {
      const newShiftDifferences = action.payload;
      return produce(state, (draftState) => {
        const { indexMapByShiftId, weeks } = draftState;
        updateShift({
          newShiftDifferences,
          indexMapByShiftId,
          weeks,
        });
      });
    }
    case 'SET_SHIFT_OPTIMISTIC': {
      const payload = action.payload;
      return produce(state, (draftState) => {
        const shift = getShiftById({
          weeks: draftState.weeks,
          indexMapByShiftId: draftState.indexMapByShiftId,
          shiftId: payload.shiftId,
          needToRemove: false,
        });
        if (shift) {
          shift.optimistic = payload.optimistic;
        }
      });
    }
    case 'SET_SELECTED_SHIFT': {
      return produce(state, (draftState) => {
        draftState.selectedShift = action.payload;
      });
    }
    case 'SET_SHIFT_IDS_TO_DELETE': {
      return produce(state, (draftState) => {
        draftState.shiftIdsToDelete = action.payload;
      });
    }
    case 'SET_SECTIONS': {
      return produce(state, (draftState) => {
        draftState.sections = action.payload;
      });
    }
    case 'SET_SKILLS': {
      return produce(state, (draftState) => {
        draftState.skills = action.payload;
      });
    }
    case 'COPY_SHIFTS': {
      return produce(state, (draftState) => {
        const shift = action.payload;
        // const { indexMapByShiftId, weeks } = draftState;
        // const shifts: ITypicalWeekShift[] = [];
        // console.log(indexMapByShiftId);

        // for (let index = 0; index < ids.length; index++) {
        //   const indexes = indexMapByShiftId.get(ids[index]);
        //   if (indexes) {
        //     const [i, j, k] = indexes;
        //     shifts.push(weeks[i].days[j].shifts[k]);
        //   }
        // }
        draftState.clipboardHistory = shift;
      });
    }
    default: {
      return state;
    }
  }
}

function getShiftById({
  weeks,
  indexMapByShiftId,
  shiftId,
  needToRemove = false,
}: {
  weeks: IWeek[];
  indexMapByShiftId: Map<string, [number, number, number]>;
  shiftId: string;
  needToRemove: boolean;
}) {
  const indexes = indexMapByShiftId.get(shiftId);
  if (indexes) {
    const [i, j, k] = indexes;
    if (needToRemove) {
      return weeks[i].days[j].shifts.splice(k, 1)[0];
    } else {
      return weeks[i].days[j].shifts[k];
    }
  }
  return null;
}

function updateShift({
  newShiftDifferences,
  weeks,
  indexMapByShiftId,
}: {
  newShiftDifferences: ITypicalWeekShift;
  weeks: IWeek[];
  indexMapByShiftId: Map<string, [number, number, number]>;
}) {
  const shiftId = newShiftDifferences.id;
  if (isNullOrUndefined(shiftId)) {
    throw new Error('A valid shift id is mandatory!');
  }

  const indexes = indexMapByShiftId.get(shiftId!);
  if (!indexes) {
    return console.log(`Shift not found ${JSON.stringify(shiftId)}`);
  }

  const [i, j, k] = indexes;
  const shift = weeks[i].days[j].shifts[k];

  Object.keys(newShiftDifferences).map((key) => {
    if (key === 'id') {
      return false;
    } else if (key === 'typicalWeekIndex') {
      const newTypicalWeekIndex = (newShiftDifferences as any)[key];
      if (shift.typicalWeekIndex !== newTypicalWeekIndex) {
        shift.typicalWeekIndex = newTypicalWeekIndex;
        const shifts = weeks[i].days[j].shifts;
        shifts.splice(k, 1);

        for (let l = k; l < shifts.length; l++) {
          const _shift = shifts[k];
          indexMapByShiftId.set(_shift.id!, [i, j, l]);
        }
      }
    } else {
      (shift as any)[key] = (newShiftDifferences as any)[key];
    }
    return false;
  });
}
