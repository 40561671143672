import Error404 from '@/components/Error404';
import ContainerView from '@/layouts/ContainerView';
import { IDepartment } from '@/types/department.model';
import { isCountry } from '@/utils';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import AppReportsActivityReports from './activity-reports';
import AppReportsAttendanceSheets from './attendance-sheets';
import AppReportsHrSheetExports from './hr-sheet-exports';
import AppReportsHrSheets from './hr-sheets';
import AppReportsLeaveReports from './leave-reports';
import AppReportsPayPeriods from './pay-periods';
import AppReportsPaySheet from './pay-periods/[id]';
import AppReportsPayReports from './pay-reports';
import AppReportTimesheets from './timesheets';

interface Props {
  department?: IDepartment;
}

const AppReportsPage: React.FC<Props> = ({ department }) => {
  const { path, url } = useRouteMatch();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { access } = department || {};

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('MENU.REPORTS.TITLE')} - Shyfter</title>
      </Helmet>
      <Switch>
        <Route exact path={`${path}`} render={() => <Redirect to={`${url}/hr-sheets`} />} />
        {access?.reports && (
          <Route path={`${path}/timesheets`}>
            <ContainerView>
              <AppReportTimesheets />
            </ContainerView>
          </Route>
        )}
        {department?.accountType !== 'RGPD' && access?.reports && isCountry(department!, ['FR']) && (
          <Route exact path={`${path}/pay-periods`}>
            <ContainerView>
              <AppReportsPayPeriods />
            </ContainerView>
          </Route>
        )}
        {department?.accountType !== 'RGPD' && access?.reports && isCountry(department!, ['FR']) && (
          <Route path={`${path}/pay-periods/:payPeriodId`}>
            <ContainerView>
              <AppReportsPaySheet />
            </ContainerView>
          </Route>
        )}
        {department?.accountType !== 'RGPD' && access?.reports && isCountry(department!, ['FR']) && (
          <Route path={`${path}/pay-periods/:payPeriodId/absences`}>
            <ContainerView>
              <AppReportsPaySheet />
            </ContainerView>
          </Route>
        )}
        {department?.accountType !== 'RGPD' && access?.timesheets && (
          <Route path={`${path}/hr-sheets`}>
            <ContainerView containerStyle={{ borderTopLeftRadius: 0 }}>
              <AppReportsHrSheets />
            </ContainerView>
          </Route>
        )}
        {department?.accountType !== 'RGPD' && access?.timesheets && (
          <Route path={`${path}/hr-sheet-exports`}>
            <ContainerView>
              <AppReportsHrSheetExports />
            </ContainerView>
          </Route>
        )}
        {access?.reports && (
          <Route path={`${path}/service-sheets`}>
            <ContainerView>
              <AppReportsAttendanceSheets />
            </ContainerView>
          </Route>
        )}
        {department?.accountType !== 'RGPD' && access?.reports && (
          <Route path={`${path}/activity-reports`}>
            <ContainerView>
              <AppReportsActivityReports />
            </ContainerView>
          </Route>
        )}
        {access?.reports && (
          <Route path={`${path}/leave-reports`}>
            <ContainerView>
              <AppReportsLeaveReports />
            </ContainerView>
          </Route>
        )}
        {access?.reports && department?.params?.payReport && (
          <Route path={`${path}/pay-reports`}>
            <ContainerView>
              <AppReportsPayReports />
            </ContainerView>
          </Route>
        )}
        <Route component={() => <Error404 />} />
      </Switch>
    </React.Fragment>
  );
};

export default AppReportsPage;
