import React, { useContext, useEffect, useRef, useState } from 'react';
import { BackTop, Form, Spin } from 'antd';
import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Header from './Header';
import Error404 from '@/components/Error404';
import DefaultView from './default';
import { IExtraColum } from '@/types/timesheet.model';
import { ITimesheetView } from '@/types/timesheet-view.model';
import Axios from 'axios';
import ModalCreateClocking from './ModalCreateClocking';

interface Props {
  className?: string;
}

export interface INewClockingParams {
  shiftId?: string;
  userRecordId?: string;
  start?: number;
  end?: number;
  sectionId?: string;
  skillIds?: string[];
  attributeIds?: string[];
}

const AppReportTimesheets: React.FC<Props> = ({ className }) => {
  const {
    state: {
      activeDepartmentId,
      activeDepartment,
      activeSection,
      loadingDayoffs,
      loadingResources,
      loadingSections,
      loadingSkills,
      loadingUsers,
    },
  } = useContext(AppContext);
  const { path, url } = useRouteMatch();
  const [loadingDataSource, setLoadingDataSource] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [hideColumns, setHideColumns] = useState<{ [key: string]: boolean }>({});
  const [extraColumns, setExtraColumns] = useState<IExtraColum[]>([]);
  const [loadingViews, setLoadingViews] = useState<boolean>(true);
  const [counter, setCounter] = useState<number>(0);
  const [views, setViews] = useState<ITimesheetView[]>([]);
  const refHeader = useRef(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [clockingModalParams, setClockingModalParams] = useState<INewClockingParams | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoadingViews(true);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/reports/custom-timesheets`, {
      params: {
        departmentId: activeDepartmentId,
        sectionId: activeSection,
      },
    })
      .then((response) => {
        setViews(response.data);
        setLoadingViews(false);
      })
      .catch((error) => {
        setLoadingViews(false);
        console.log(error);
      });
  }, [activeDepartmentId, activeSection]);

  if (loadingDayoffs || loadingResources || loadingSections || loadingSkills || loadingUsers || loadingViews) {
    return (
      <Spin
        spinning={true}
        style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', padding: 20 }}
      />
    );
  }

  return (
    <TableView className={className}>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header
        ref={refHeader}
        form={form}
        columns={columns}
        hideColumns={hideColumns}
        loadingDataSource={loadingDataSource}
        views={views}
        counter={counter}
        setDataSource={setDataSource}
        setExtraColumns={setExtraColumns}
        setHideColumns={setHideColumns}
        setHiddenColumns={setHiddenColumns}
        setLoadingDataSource={setLoadingDataSource}
        setViews={setViews}
        setHeaderHeight={setHeaderHeight}
        setClockingModalParams={setClockingModalParams}
      />
      <Switch>
        <Route exact path={`${path}`} render={() => <Redirect to={`${url}/default`} />} />
        <Route path={`${path}/default`}>
          <DefaultView
            headerForm={form}
            activeDepartment={activeDepartment}
            dataSource={dataSource}
            setCounter={setCounter}
            extraColumns={extraColumns}
            columns={columns}
            hideColumns={hideColumns}
            hiddenColumns={hiddenColumns}
            loadingDataSource={loadingDataSource}
            headerHeight={headerHeight}
            setColumns={setColumns}
            setClockingModalParams={setClockingModalParams}
          />
        </Route>
        <Route path={`${path}/default/:view`}>
          <DefaultView
            headerForm={form}
            activeDepartment={activeDepartment}
            dataSource={dataSource}
            setCounter={setCounter}
            extraColumns={extraColumns}
            columns={columns}
            hideColumns={hideColumns}
            hiddenColumns={hiddenColumns}
            loadingDataSource={loadingDataSource}
            headerHeight={headerHeight}
            setColumns={setColumns}
            setClockingModalParams={setClockingModalParams}
          />
        </Route>
        <Route component={() => <Error404 />} />
      </Switch>
      <ModalCreateClocking
        visible={!!clockingModalParams}
        params={clockingModalParams || undefined}
        onClose={() => setClockingModalParams(null)}
        onCreated={() => form.submit()}
      />
    </TableView>
  );
};

export default AppReportTimesheets;
