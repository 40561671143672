import AppContext from '@/pages/app/context';
import { IPendingRegistration } from '@/types/users/pending-registration';
import { Button, message, Spin, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ModalLinkToUser from './ModalLinkToUser';

interface Props {
  pendingUsers: IPendingRegistration[];
  registration: IPendingRegistration;
  setActiveRegistration: React.Dispatch<React.SetStateAction<IPendingRegistration | null>>;
  setModalUpdateEmailVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setPendingUsers: React.Dispatch<React.SetStateAction<IPendingRegistration[]>>;
  get: () => void;
}

const Actions: React.FC<Props> = ({
  pendingUsers,
  registration,
  setActiveRegistration,
  setModalUpdateEmailVisible,
  setPendingUsers,
  get,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  const [loadingResendInvitation, setLoadingResendInvitation] = useState<boolean>(false);
  const [loadingMarkAsCompleted, setLoadingMarkAsCompleted] = useState<boolean>(false);
  const [modalLinkToUserVisible, setModalLinkToUserVisible] = useState<boolean>(false);

  const onUpdateEmail = () => {
    setActiveRegistration(registration);
    setModalUpdateEmailVisible(true);
  };

  const onViewProfile = () => {
    history.push(`/app/team/collaborators/${registration.record_id}/profile/profile-details`);
  };

  const onResendInvitation = () => {
    setLoadingResendInvitation(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/users/pending/${registration.hash}/send`,
        {},
        {
          params: {
            departmentId: activeDepartmentId,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
      .then((response) => {
        setLoadingResendInvitation(false);
        message.success(t('SELF_SERVICE.MESSAGES.INVATION_EMAIL_SENT'));
      })
      .catch((error) => {
        setLoadingResendInvitation(false);
        console.error(error);
      });
  };

  const onMarkAsCompleted = () => {
    setLoadingMarkAsCompleted(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/users/pending/${registration.hash}`, {
        params: {
          departmentId: activeDepartmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        setPendingUsers(
          pendingUsers.filter((registration) => registration.registration_id !== res.data.registration_id),
        );
        setLoadingMarkAsCompleted(false);
        message.success(t('SELF_SERVICE.MESSAGES.MARKED_AS_COMPLETED'));
      })
      .catch((error) => {
        setLoadingMarkAsCompleted(false);
        console.error(error);
      });
  };

  const onLinkToUser = () => {
    setModalLinkToUserVisible(true);
  };

  return (
    <div>
      {registration.needs_correct_email ? (
        <>
          <Tooltip overlay={<span>{t('SELF_SERVICE.ACTIONS.UPDATE_EMAIL')}</span>}>
            <Button type="link" onClick={onUpdateEmail}>
              <i className="icon-attention" />
              <span style={{ marginLeft: 5 }}>{t('SELF_SERVICE.ACTIONS.ACTION_REQUIRED')}</span>
            </Button>
          </Tooltip>
          <Tooltip overlay={<span>{t('SELF_SERVICE.ACTIONS.LINK_TO_USER')}</span>}>
            <Button type="ghost" onClick={onLinkToUser}>
              <i className="icon-shuffle" />
            </Button>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip overlay={<span>{t('SELF_SERVICE.ACTIONS.RESEND_INVITATION')}</span>}>
            {loadingResendInvitation ? (
              <Button type="link">
                <Spin spinning={loadingResendInvitation} size="small" />
              </Button>
            ) : (
              <Button type="link" onClick={onResendInvitation}>
                <i className="icon-paper-plane-empty" />
              </Button>
            )}
          </Tooltip>
          <Tooltip overlay={<span>{t('SELF_SERVICE.ACTIONS.LINK_TO_USER')}</span>}>
            <Button type="link" onClick={onLinkToUser}>
              <i className="icon-shuffle" />
            </Button>
          </Tooltip>
          <Tooltip overlay={<span>{t('SELF_SERVICE.ACTIONS.VIEW_PROFILE')}</span>}>
            <Button type="link" onClick={onViewProfile}>
              <i className="icon-search" />
            </Button>
          </Tooltip>
          <Tooltip overlay={<span>{t('SELF_SERVICE.ACTIONS.MARK_AS_COMPLETED')}</span>}>
            {loadingMarkAsCompleted ? (
              <Button type="link">
                <Spin spinning={loadingMarkAsCompleted} size="small" />
              </Button>
            ) : (
              <Button type="link" onClick={onMarkAsCompleted}>
                <i className="icon-ok" />
              </Button>
            )}
          </Tooltip>
        </>
      )}
      <ModalLinkToUser
        visible={modalLinkToUserVisible}
        setVisible={setModalLinkToUserVisible}
        registration={registration}
        get={get}
      />
    </div>
  );
};

export default Actions;
