import { Button, Select, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import { IReportTemplateColumnField, IReportTemplateField } from '@/types/report-template.model';
import { AlignType } from '@/types/alignType.model';
import { capitalize } from '@/utils';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import { SelectValue } from 'antd/es/select';

interface Props {
  fields: IReportTemplateField[];
  columnFields: IReportTemplateColumnField[];
  setColumnFields: React.Dispatch<React.SetStateAction<IReportTemplateColumnField[]>>;
}

const SortableElementItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerItem = SortableContainer((props: any) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />);

const ColumnFieldTable: React.FC<Props> = ({ fields, columnFields, setColumnFields }) => {
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const [columns, setColumns] = useState<any[]>([]);
  const [activeField, setActiveField] = useState<any | null>(null);

  useEffect(() => {
    setColumns([
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        // eslint-disable-next-line react/display-name
        render: () => <DragHandle />,
      },
      {
        title: t('GLOBAL.ID'),
        width: '50%',
        render: (text: string, record: IReportTemplateColumnField) => {
          const found = columnFields.find((columnField) => columnField.id == record.id);
          let foundField = null;
          if (found) {
            const type = found && found.id && typeof found.id !== 'number' && found.id.split('.')[0];
            foundField = fields.find((field) => field.type == type)?.fields.find((field) => field.id == found.id);
          }

          return (
            <Select
              defaultValue={foundField && typeof foundField.id !== 'number' ? `${foundField.label}` : undefined}
              onChange={(value, option) => onFieldChange(value, option, record)}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option && option.children && option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {fields.map((field) => (
                <Select.OptGroup label={capitalize(field.type)}>
                  {field.fields.map((sub_field, i) => (
                    <Select.Option key={`${field.type}/${sub_field.id}/${sub_field.type}`} value={`${sub_field.id}`}>
                      {sub_field.label}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          );
        },
      },
      {
        title: t('REPORT_TEMPLATES.FORMAT'),
        dataIndex: 'format',
        key: 'format',
        width: '50%',
        render: (text: string, record: IReportTemplateColumnField) => {
          const id = record.id;
          let found = null;

          if (id && typeof id == 'string') {
            const type = id.split('.')[0];
            const foundFields = fields.find((field) => field.type == type)?.fields;
            found = foundFields?.find((field) => field.id == id);
          }

          if (!found) {
            return null;
          }

          if (found.type == 'minutes') {
            return (
              <Select
                defaultValue={record && record.format ? `${record.format}` : ''}
                allowClear
                onChange={(value) => onChangeFormat(value, record, true)}
              >
                <Select.Option value="minutes">{t('GLOBAL.MINUTES')}</Select.Option>
                <Select.Option value="hours">{t('GLOBAL.HOURS')}</Select.Option>
              </Select>
            );
          }
          if (found.type == 'string' || found.type == 'text' || found.type == 'select') {
            return (
              <Select
                defaultValue={record && record.text_transform ? `${record.text_transform}` : ''}
                onChange={(value) => onChangeFormat(value, record)}
                allowClear
              >
                <Select.Option value="uppercase">{t('REPORT_TEMPLATES.UPPERCASE')}</Select.Option>
                <Select.Option value="lowercase">{t('REPORT_TEMPLATES.LOWERCASE')}</Select.Option>
                <Select.Option value="sentencecase">{t('REPORT_TEMPLATES.SENTENCECASE')}</Select.Option>
              </Select>
            );
          }
          if (found.type == 'attribute') {
            return (
              <Select
                defaultValue={record && record.attributeFieldId ? `${record.attributeFieldId}` : ''}
                onChange={(value) => onChangeFormat(value, record, false, true)}
                allowClear
              >
                {found.fields?.map((field) => {
                  return <Select.Option value={`${field.id}`}>{field.label}</Select.Option>;
                })}
              </Select>
            );
          }

          return null;
        },
      },
      {
        title: '',
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        width: '10%',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IReportTemplateColumnField) => (
          <button type="button" className="red" onClick={() => onRemoveColumnField(record)}>
            <i className="icon-delete" />
          </button>
        ),
      },
    ]);
  }, [columnFields, activeField]);

  const onFieldChange = (e: SelectValue, option: any, record: IReportTemplateColumnField) => {
    if (!option) {
      const found = columnFields.find((column_field) => column_field.id == record.id);
      if (found) {
        setColumnFields(
          columnFields.map((customField) => (customField.id !== found.id ? customField : { ...found, id: '' })),
        );
      }
      return;
    }

    const group = String(option.key).split('/')[0];
    const id = String(option.key).split('/')[1];
    const type = String(option.key).split('/')[2];
    const field = {
      group,
      id,
      type,
    };

    setActiveField(field);
    const found = columnFields.find((column_field) => column_field.id == record.id);
    setColumnFields(
      columnFields.map((columnField) =>
        columnField.id !== record.id
          ? columnField
          : {
              ...found,
              id,
            },
      ),
    );
  };

  const onChangeFormat = (value: SelectValue, record: IReportTemplateColumnField, time = false, attribute = false) => {
    const found = columnFields.find((column_field) => column_field.id == record.id);
    if (!value) {
      if (found) {
        setColumnFields(
          columnFields.map((customField) =>
            customField.id !== found.id ? customField : { ...found, text_transform: null },
          ),
        );
      }
      return;
    }

    if (found) {
      setColumnFields(
        columnFields.map((columnField) =>
          columnField.id !== record.id
            ? columnField
            : {
                ...found,
                text_transform: !time && !attribute ? (value as any) : null,
                attributeFieldId: attribute ? (value as any) : null,
                format: time ? (value as any) : null,
              },
        ),
      );
    }
  };

  const onAddField = () => {
    setColumnFields([
      ...columnFields,
      {
        id: columnFields.length + 1,
      } as any,
    ]);
  };

  const onRemoveColumnField = (field: IReportTemplateColumnField) => {
    setActiveField(null);
    setColumnFields(columnFields.filter((column_field) => column_field.id !== field.id));
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const oldFields = [...columnFields];
    const newFields: IReportTemplateColumnField[] = arrayMove(columnFields, oldIndex, newIndex);
    setColumnFields(newFields);
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerItem useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = columnFields.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableElementItem index={index} {...restProps} />;
  };

  return (
    <>
      <Button htmlType="button" type="dashed" style={{ marginBottom: 15 }} block onClick={onAddField}>
        {t('REPORT_TEMPLATES.ADD_FIELD')}
      </Button>
      <Table
        columns={columns}
        dataSource={columnFields}
        pagination={false}
        rowKey="id"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </>
  );
};

export default ColumnFieldTable;
