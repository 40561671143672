import { Button, Modal, Space, Tooltip, message } from 'antd';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Colors from '@/styles/colors';
import { useTranslation } from 'react-i18next';
import colors from '@/styles/colors';
import { IUser } from '@/types/user.model';
import axios from 'axios';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
  user: IUser;
  onActivate: () => void;
  onDeletedPermanently: () => void;
}

const TableRowActions: React.FC<Props> = ({ className, user, onActivate, onDeletedPermanently }) => {
  const [loadingDeletePermanently, setLoadingDeletePermanently] = useState<boolean>(false);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  const onWantToDeletePermanently = () => {
    setLoadingDeletePermanently(true);
    Modal.confirm({
      title: t('GLOBAL.WARNING'),
      type: 'warning',
      content: t('SETTINGS.TOOLBOX.DEACTIVATED_USERS.DELETE_PERMANENTLY_CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.DELETE_PERMANENTLY'),
      okType: 'danger',
      onOk: () => {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v3/users/${user.recordId}/anonymize`,
            {},
            {
              params: {
                departmentId: activeDepartmentId,
                recordId: user.recordId,
              },
            },
          )
          .then(({ data }) => {
            onDeletedPermanently();
            setLoadingDeletePermanently(false);
          })
          .catch((error) => {
            setLoadingDeletePermanently(false);
            const has_error = error && error.response && error.response.data && error.response.data.errors;
            message.error(
              has_error
                ? Object.values(error.response.data.errors).flat(1)[0]
                : t('SETTINGS.TOOLBOX.DEACTIVATED_USERS.DELETE_PERMANENTLY_ERROR'),
            );
          });
      },
    });
  };

  return (
    <Space size="middle" className={className}>
      <button className="red" onClick={onActivate}>
        {t('GLOBAL.ACTIVATE')}
      </button>
      <Tooltip overlay={<span>{t('GLOBAL.DELETE_PERMANENTLY')}</span>}>
        <Button type="primary" danger onClick={onWantToDeletePermanently}>
          <i className="icon-trash-empty" />
        </Button>
      </Tooltip>
    </Space>
  );
};

export default styled(TableRowActions)`
  a {
    color: ${Colors.green};
    &.impersonate {
      border: 1px solid ${Colors.green};
      border-radius: 3px;
      padding: 2px 4px;
      opacity: 0.6;
    }
  }
`;
