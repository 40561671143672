import TableView from '@/layouts/TableView';
import { Checkbox, message, Modal, Table } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from './components/Header';
import TableRowActions from './components/TableRowActions';

import { AlignType } from '@/types/alignType.model';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { IWeeklySchedule } from '@/types/weekly-schedule';
import { useTranslation } from 'react-i18next';
import DrawerWeeklySchedule from '@/pages/app/components/drawers/WeeklySchedule';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings;
}

const ManageWeeklySchedules: React.FC<Props> = ({ className, currentSettings }) => {
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });
  const [weeklySchedules, setWeeklySchedules] = useState<IWeeklySchedule[]>([]);
  const weeklySchedulesRef = useRef(weeklySchedules);
  weeklySchedulesRef.current = weeklySchedules;
  const [loadingWeeklySchedules, setLoadingWeeklySchedules] = useState<boolean>(false);
  const [filteredWeeklySchedules, setFilteredWeeklySchedules] = useState<IWeeklySchedule[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeWeeklySchedule, setActiveWeeklySchedule] = useState<IWeeklySchedule | null>(null);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    let mounted = true;
    setLoadingWeeklySchedules(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/weekly-schedules`, {
        params: {
          departmentId: activeDepartmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        if (mounted) {
          setWeeklySchedules(response.data.weeklySchedules);
          setLoadingWeeklySchedules(false);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingWeeklySchedules(false);
        }
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId]);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      },
      {
        title: t('GLOBAL.RECIPIENTS'),
        dataIndex: 'recipients',
        key: 'recipients',
      },
      {
        title: t('GLOBAL.FREE_SHIFTS'),
        dataIndex: 'freeshifts',
        key: 'freeshifts',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IWeeklySchedule) => <Checkbox disabled checked={record.freeshifts} />,
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IWeeklySchedule) => (
          <TableRowActions onEdit={() => setActiveWeeklySchedule(record)} onDelete={() => onWantToDelete(record.id!)} />
        ),
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    const filteredWeeklySchedules = weeklySchedules?.filter((weeklySchedule: any) => {
      return Object.keys(weeklySchedule).some((key) => {
        if (typeof weeklySchedule[key] === 'string') {
          return weeklySchedule[key].toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return false;
        }
      });
    });
    setFilteredWeeklySchedules(filteredWeeklySchedules);
  }, [weeklySchedules, searchTerm]);

  const onWantToDelete = (weeklyScheduleId: string) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('WEEKLY_SCHEDULES.MODAL_DELETE.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDelete(weeklyScheduleId);
      },
      onCancel: () => {},
    });
  };

  const onDelete = (weeklyScheduleId: string) => {
    const index = weeklySchedulesRef.current.findIndex((weeklySchedule) => weeklySchedule.id === weeklyScheduleId);
    let weeklySchedulesCopy: IWeeklySchedule[];

    if (~index) {
      weeklySchedulesCopy = [...weeklySchedulesRef.current];
      const weeklySchedulesResult = [...weeklySchedulesRef.current];
      weeklySchedulesResult.splice(index, 1);
      setWeeklySchedules(weeklySchedulesResult);
    }

    axios.delete(`${process.env.REACT_APP_API_URL}/v3/weekly-schedules/${weeklyScheduleId}`).catch((error) => {
      if (~index) {
        console.error(error);
        message.error(t('WEEKLY_SCHEDULES.MODAL_DELETE.MESSAGE_ERROR'));
        if (weeklySchedulesCopy) {
          setWeeklySchedules(weeklySchedulesCopy);
        }
      }
    });
  };

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchTerm(value);
  };

  const onSave = (result: IWeeklySchedule) => {
    const weeklySchedulesCopy = [...weeklySchedulesRef.current];
    const index = weeklySchedulesCopy.findIndex((weeklySchedule) => weeklySchedule.id === result.id);

    if (~index) {
      weeklySchedulesCopy[index] = result;
      setWeeklySchedules(weeklySchedulesCopy);
    } else {
      weeklySchedulesCopy.push(result);
      const weeklySchedulesCopySorted = weeklySchedulesCopy.sort((a, b) => {
        if (a.name!.toLowerCase() < b.name!.toLowerCase()) {
          return -1;
        }
        if (a.name!.toLowerCase() > b.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setWeeklySchedules(weeklySchedulesCopySorted);
    }
    setActiveWeeklySchedule(null);
  };

  return (
    <React.Fragment>
      <h2>{t('SETTINGS.SCHEDULES.PDF_SCHEDULES.TITLE')}</h2>
      <div
        style={{
          backgroundColor: 'white',
          padding: 25,
          borderRadius: 10,
          marginTop: 25,
        }}
      >
        <TableView>
          <Header disabled={false} onSearchChange={onSearchChange} onCreate={() => setActiveWeeklySchedule({})} />
          <Table
            className={className}
            loading={loadingWeeklySchedules}
            dataSource={filteredWeeklySchedules}
            columns={columns}
            rowKey="id"
            pagination={false}
          />
        </TableView>
        <DrawerWeeklySchedule
          currentSettings={currentSettings}
          weeklySchedule={activeWeeklySchedule}
          visible={!!activeWeeklySchedule}
          onClose={() => setActiveWeeklySchedule(null)}
          onSave={onSave}
        />
      </div>
    </React.Fragment>
  );
};

export default ManageWeeklySchedules;
