import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  spinning?: boolean;
  style?: React.CSSProperties;
}

const SSpin: React.FC<Props> = ({ className, spinning, style }) => {
  return (
    <div className={className} style={style}>
      <img className="logo" src={process.env.PUBLIC_URL + '/shyfter-logo.svg'} alt="Shyfter" />
      <Spin spinning={spinning} size="large" />
      <p>Loading ...</p>
    </div>
  );
};

export default styled(SSpin)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  > .logo {
    max-width: 190px;
    width: 100%;
  }
  > .ant-spin {
    padding: 22px 0 15px;
  }
  > p {
    font-size: 24px;
  }
`;
