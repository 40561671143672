import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';
import { PickerType } from '../hours/manage/redux/store';

interface Props {
  startDate: Moment;
  endDate?: Moment; // Needed for week
  picker: PickerType;
  disabledDate?: (date: Moment) => boolean;
  onDatePickerChange: (date: moment.Moment | null) => void;
}

const CustomDatePicker: React.FC<Props> = ({ picker, startDate, endDate, disabledDate, onDatePickerChange }) => {
  const getDateRangeLabel = (): string => {
    let result = '';
    switch (picker) {
      case 'day':
      case 'operational':
        result = moment(startDate.valueOf()).format('ddd DD MMM');
        break;
      case 'week': {
        result = `${moment(startDate.valueOf()).format('D MMM')} - ${moment(endDate!.valueOf()).format('D MMM')}`;
        break;
      }
      case 'month':
        result = `${moment(startDate.valueOf()).format('MMMM YYYY')}`;
        break;
    }
    return `[${result}]`;
  };
  return (
    <DatePicker
      disabledDate={disabledDate}
      picker={picker === 'day' ? 'date' : picker}
      className="datePicker"
      format={`${getDateRangeLabel()}`}
      suffixIcon={null}
      value={startDate}
      allowClear={false}
      onChange={onDatePickerChange}
    />
  );
};

export default CustomDatePicker;
