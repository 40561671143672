import { withQuote } from '@/hoc/withQuote';
import { IResource } from '@/types/resource.model';
import { Button, Form, Input, Modal, Spin } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import PlaceFields from '../app/components/PlaceFields';
interface IResourceSubscribeData {
  company?: string;
  content?: string;
  title?: string;
  subtitle?: string;
  picturePath?: string;
  resourceType: IResource['resourceType'];
  fields?: IResource['fields'];
}

interface Props {
  quote?: {
    quote: string;
    author: string;
  };
}

const ResourceSubscribePage: React.FC<Props> = ({ quote }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [data, setData] = useState<IResourceSubscribeData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [backgroundImageLink, setBackgroundImageLink] = useState<string>('');
  const history = useHistory();

  useEffect(() => {
    const random = Math.floor(Math.random() * 2 + 1);
    setBackgroundImageLink(`${process.env.PUBLIC_URL}/img/image-login-${random}.jpg`);
  }, []);

  useEffect(() => {
    let mounted = true;
    setData(null);
    if (quote?.quote && id) {
      setIsLoading(true);
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/resources-subscription/${id}`, {
        cancelToken: cancelTokenSource.token,
      })
        .then((response) => {
          if (mounted) {
            setIsLoading(false);
            setData(response.data);
            form.resetFields();
          }
        })
        .catch((error) => {
          if (mounted) {
            setIsLoading(false);
            form.resetFields();
          }
          if (!Axios.isCancel(error)) {
            console.log(error);
            if (error.response?.status === 403) {
              history.push('/app');
            }
          }
        });
      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [quote, id]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    Axios.post(`${process.env.REACT_APP_API_URL}/resource-subscription`, {
      ...values,
      resourceId: id,
    })
      .then((response) => {
        setIsSaving(false);
        Modal.info({
          title: t('SUBSCRIBE.MODAL_SUBSCRIPTION_SENT.TITLE'),
          content: t('SUBSCRIBE.MODAL_SUBSCRIPTION_SENT.CONTENT'),
          okText: 'Ok',
          okType: 'default',
          onOk: () => {
            form.resetFields();
          },
        });
      })
      .catch((error) => {
        setIsSaving(false);
        console.log(error);
      });
  };

  return (
    <div className="dual-page">
      <div className="left" style={{ backgroundImage: `url("${backgroundImageLink}")` }}>
        {!!data?.picturePath && <img src={data?.picturePath} />}
        <h2>{quote?.quote}</h2>
        <i>{quote?.author}</i>
      </div>
      <div className="right">
        {isLoading && <Spin size="large" />}
        {data && (
          <Form form={form} size="large" layout="vertical" onFinish={onFinish}>
            <h1>{data?.title}</h1>
            <h2>{data?.subtitle}</h2>
            <p dangerouslySetInnerHTML={{ __html: data?.content || '' }} />
            <div className="fields">
              <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true, max: 254 }]}>
                <Input placeholder={t('GLOBAL.NAME')} />
              </Form.Item>
              {data?.resourceType === 'PLACE' && <PlaceFields form={form} withoutCode={true} />}
              {data?.fields?.map((field) => (
                <Form.Item
                  key={`field_${field.id}`}
                  name={`generic_${field.id}`}
                  label={field.name}
                  rules={[{ required: field.mandatory, max: 254 }]}
                >
                  <Input placeholder={field.name} />
                </Form.Item>
              ))}
            </div>
            <Form.Item>
              <Button loading={isSaving} size="large" type="primary" htmlType="submit" className="login-form-button">
                {t('GLOBAL.SAVE')}
              </Button>
            </Form.Item>
            <span className="accept_terms">{t('FORMS.ACCEPT_TERMS')}</span>
            <span className="generatedBy">{t('SUBSCRIBE.GENERATE_BY_SHYFTER')}</span>
          </Form>
        )}
      </div>
    </div>
  );
};

const ResourceSubscribePageWithQuotes: React.FC = () => {
  const Component = withQuote(ResourceSubscribePage);
  return <Component />;
};

export default ResourceSubscribePageWithQuotes;
