import { IDepartmentSettings } from '@/types/department-settings.model';
import { POSColorKey, POSColorType } from '@/types/pos-colors.model';
import { Form, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';

interface Props {
  color_key: POSColorKey;
  color: string | null;
  selected: string | null;
  _default: string;
  posColors: POSColorType[];
  currentSettings?: IDepartmentSettings | null;
  setPosColors: React.Dispatch<React.SetStateAction<POSColorType[]>>;
  setFormHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const POSColorBox: React.FC<Props> = ({
  color_key,
  color,
  selected,
  _default,
  posColors,
  currentSettings,
  setPosColors,
  setFormHasChanged,
}) => {
  const [custom, setCustom] = useState<string | null>();

  useEffect(() => {
    if (currentSettings?.pos && currentSettings.pos[color_key]) {
      const color_palette = posColors.find((palette) => palette.key === color_key);
      const edited_color_palette: POSColorType = {
        ...color_palette!,
        selected: currentSettings.pos[color_key],
        custom: posColors.some((color) => color.colors.includes(currentSettings.pos![color_key])),
      };
      if (!posColors.some((color) => color.colors.includes(currentSettings.pos![color_key]))) {
        setCustom(currentSettings.pos![color_key]);
      }
      setPosColors(posColors.map((color) => (color.key === color_key ? edited_color_palette : color)));
    }
  }, [currentSettings]);

  const onColorClick = () => {
    if (posColors.length && color) {
      setFormHasChanged(true);
      const color_palette = posColors.find((palette) => palette.key === color_key);
      const edited_color_palette: POSColorType = { ...color_palette!, selected: color };
      setPosColors(posColors.map((color) => (color.key === color_key ? edited_color_palette : color)));
    }
  };

  const onPlusClick = () => {
    if (custom) {
      setFormHasChanged(true);
      const color_palette = posColors.find((palette) => palette.key === color_key);
      const edited_color_palette: POSColorType = { ...color_palette!, selected: custom, custom: true };
      setPosColors(posColors.map((color) => (color.key === color_key ? edited_color_palette : color)));
    }
  };

  const onChange = (e: any) => {
    if (e && e.hex) {
      setFormHasChanged(true);
      const hex = e.hex;
      setCustom(hex);
      const color_palette = posColors.find((palette) => palette.key === color_key);
      const edited_color_palette: POSColorType = { ...color_palette!, selected: hex, custom: true };
      setPosColors(posColors.map((color) => (color.key === color_key ? edited_color_palette : color)));
    }
  };

  if (color) {
    return (
      <Form.Item name={color_key} style={{ margin: 0 }}>
        <button
          type="button"
          style={{
            border: selected
              ? color === selected
                ? '2px solid black'
                : 'none'
              : color === _default
              ? '2px solid black'
              : 'none',
            padding: 1,
            borderRadius: 5,
          }}
          onClick={onColorClick}
        >
          <div
            style={{
              width: 25,
              height: 25,
              backgroundColor: color,
              borderRadius: 5,
            }}
          >
            {!color && <i className="icon-plus" />}
          </div>
        </button>
      </Form.Item>
    );
  } else {
    return (
      <Form.Item name={color_key} style={{ margin: 0 }}>
        <Tooltip placement="right" overlay={<ChromePicker color={custom ? custom : '#00A651'} onChange={onChange} />}>
          <button
            type="button"
            style={{
              border: custom && selected && custom === selected ? '2px solid black' : 'none',
              padding: 1,
              borderRadius: 5,
            }}
            onClick={onPlusClick}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 25,
                height: 25,
                backgroundColor: custom ? custom : '#fff',
                border: '1px solid black',
                borderRadius: 5,
              }}
            >
              {!color && !custom && <i className="icon-plus" />}
            </div>
          </button>
        </Tooltip>
      </Form.Item>
    );
  }
};

export default POSColorBox;
