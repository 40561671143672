import React, { useState, useContext } from 'react';
import { BackTop, message, Tabs } from 'antd';
import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import Header from './components/Header';
import moment, { Moment } from 'moment';
import 'moment-timezone';
moment.tz.setDefault('Atlantic/Reykjavik');
import styled from 'styled-components';
import colors from '@/styles/colors';
import WorkedHours from './components/WorkedHours';
import Leaves from './components/Leaves';

const { TabPane } = Tabs;

interface Props {
  className?: string;
}

const AppReportsPayReports: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [rangePickerValues, setRangePickerValues] = useState<[Moment, Moment]>([
    moment().startOf('month'),
    moment().endOf('month'),
  ]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const onDownload = () => {
    const [start, end] = rangePickerValues;
    setIsDownloading(true);
    Axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/v3/reports/worked-hours/users/export`,
      responseType: 'blob',
      params: {
        departmentId: activeDepartmentId,
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),
      },
    })
      .then((response) => {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(file);
        downloadLink.download = response.headers['content-disposition']?.match(/filename=(.*)(; )?/)?.[1];
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setIsDownloading(false);
      })
      .catch((error) => {
        console.error(error);
        message.error(t('GLOBAL.MESSAGE_DOWNLOAD_ERROR'));
        setIsDownloading(false);
      });
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header
        rangePickerValues={rangePickerValues}
        setRangePickerValues={setRangePickerValues}
        selectUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        isDownloading={isDownloading}
        onDownload={onDownload}
      />
      <div className={className}>
        <Tabs type="card" tabBarStyle={{ marginLeft: 20 }}>
          <TabPane tab={t('GLOBAL.WORKED_HOURS')} key="WORKED_HOURS">
            <WorkedHours rangePickerValues={rangePickerValues} selectedUsers={selectedUsers} />
          </TabPane>
          <TabPane tab={t('GLOBAL.ABSENCES')} key="ABSENCES">
            <Leaves rangePickerValues={rangePickerValues} selectedUsers={selectedUsers} />
          </TabPane>
        </Tabs>
      </div>
    </TableView>
  );
};

export default styled(AppReportsPayReports)`
  .ant-tabs-nav-list > .ant-tabs-tab {
    margin-left: 0px !important;
    border-radius: 0 !important;

    &:first-child {
      border-radius: 3px 0 0 0 !important;
    }

    &:nth-last-child(2n) {
      border-radius: 0 3px 0 0 !important;
    }

    border: 1px solid ${colors.blueLight};
    background: transparent;
    padding: 10px 40px;
    font-weight: bold;
    color: ${colors.green};
    &.ant-tabs-tab-active {
      border: 1px solid ${colors.blueLight};
      margin: 0;
      .ant-tabs-tab-btn {
        color: ${colors.green};
      }
      background: ${colors.blueLight};
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    }
  }

  .ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
    border-radius: 0px 0px 3px 3px;
  }

  .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 20px;
    background: #fff;
  }

  .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }

  .ant-table-tbody .ant-table-cell.displayName  {
    border-right: 1px solid ${colors.greyLight};
  }
`;
