import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Modal } from 'antd';
import styled from 'styled-components';
import Colors from '@/styles/colors';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { withQuote } from '@/hoc/withQuote';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  quote?: {
    quote: string;
    author: string;
  };
}

const ForgotPasswordPage: React.FC<Props> = ({ quote }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { email } = (location?.state as any) || { email: '' };
  const [form] = Form.useForm();
  const [backgroundImageLink, setBackgroundImageLink] = useState<string>('');
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    const random = Math.floor(Math.random() * 2 + 1);
    setBackgroundImageLink(`${process.env.PUBLIC_URL}/img/image-login-${random}.jpg`);
  }, []);

  const onFinish = (values: any) => {
    const { email } = values;
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/forgot-password`, {
        email,
      })
      .then(() => {
        setIsLoading(false);
        Modal.info({
          title: t('FORGOT_PASSWORD.MODAL_SENT_CONFIRMATION.TITLE'),
          content: t('FORGOT_PASSWORD.MODAL_SENT_CONFIRMATION.MESSAGE'),
          okText: t('GLOBAL.OK'),
          okType: 'default',
        });
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(error.response?.data?.message);
        console.error(error);
      });
  };

  return (
    <div className="dual-page">
      <div className="left" style={{ backgroundImage: `url("${backgroundImageLink}")` }}>
        <h2>{quote?.quote}</h2>
        <i>{quote?.author}</i>
      </div>
      <div className="right">
        <Form form={form} size="large" layout="vertical" onFinish={onFinish}>
          <h1>{t('FORGOT_PASSWORD.TITLE')}</h1>
          <p>{t('FORGOT_PASSWORD.DESCRIPTION')}</p>
          <div className="fields">
            <Form.Item
              label={t('GLOBAL.EMAIL')}
              name="email"
              rules={[{ required: true, message: t('FORMS.EMAIL_ERROR') }]}
              initialValue={email}
            >
              <Input size="large" prefix={<i className="icon-user"></i>} placeholder={t('GLOBAL.EMAIL')} />
            </Form.Item>
          </div>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
              {t('FORGOT_PASSWORD.RECOVER_PASSWORD_BUTTON')}
            </Button>
          </Form.Item>
          <Link to={`/login`}>{`< ${t('GLOBAL.BACK')}`}</Link>
        </Form>
      </div>
    </div>
  );
};

const ForgotPasswordPageStyled = styled(ForgotPasswordPage)`
  min-height: 400px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #56ab2f; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #00a651, #2ad27c); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #00a651,
    #2ad27c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  form {
    padding: 35px;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      padding-bottom: 20px;
    }

    header {
      img {
        max-width: 150px;
        width: 100%;
      }
      padding-bottom: 25px;
    }

    .ant-form-item {
      margin-bottom: 5px;
      width: 100%;
    }

    .ant-btn-lg {
      width: 100%;
      margin: 10px 0 5px;
    }

    a {
      color: ${Colors.grey};
    }
  }
`;

const ForgotPasswordPageWithQuotes: React.FC = () => {
  const Component = withQuote(ForgotPasswordPageStyled);
  return <Component />;
};

export default ForgotPasswordPageWithQuotes;
