import colors from '@/styles/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

interface Props {
  className?: string;
  date: number;
}

const TrialStrip: React.FC<Props> = ({ className, date }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const differenceInDays = moment.unix(date).startOf('day').diff(moment(), 'day');

  return (
    <div className={className}>
      <span>{t('TRIAL.STRIP.TEXT', { days: differenceInDays })}</span>
      <Link to="/app/settings/manage">{t('TRIAL.STRIP.LINK')}</Link>
    </div>
  );
};

export default styled(TrialStrip)`
  z-index: 2;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: ${colors.blue};
  color: white;
  font-weight: bold;
  font-size: 11px;
  padding: 5px 15px;
  border-top-right-radius: 6px;

  a {
    margin-left: 30px;
    color: ${colors.green};
    text-decoration: underline;
  }
`;
