import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import colors from '@/styles/colors';
import { INewClockingParams } from '../../reports/timesheets';
import moment, { Moment } from 'moment';

interface Props {
  className?: string;
  today: boolean;
  absolute?: boolean;
  newClockingParams: INewClockingParams;
  setNewClockingParams: React.Dispatch<React.SetStateAction<INewClockingParams>>;
  setDrawerClockingVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateClockingPlaceholder: React.FC<Props> = ({
  className,
  today,
  absolute,
  newClockingParams,
  setNewClockingParams,
  setDrawerClockingVisible,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);

  const onClick = () => {
    setDrawerClockingVisible(true);
    setNewClockingParams(newClockingParams);
  };

  return (
    <div
      className={className}
      style={{
        position: absolute ? 'absolute' : 'relative',
        width: absolute ? '95%' : '100%',
        height: absolute ? 'initial' : '100%',
        backgroundColor: today && absolute ? 'rgb(226, 230, 239)' : 'rgb(247, 249, 255)',
      }}
      onClick={onClick}
    >
      + {t('CLOCKINGS.CREATE_CLOCKING')}
    </div>
  );
};

export default styled(CreateClockingPlaceholder)`
  border: 1px dashed ${colors.green};
  padding: 5px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  font-size: 12px;
`;
