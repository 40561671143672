import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';
import Colors from '@/styles/colors';

interface Props {
  className?: string;
  columns: any[];
  hideColumns: { [key: string]: boolean };
  setHideColumns: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  setLoadingDataSource: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderOptionDetails: React.FC<Props> = ({
  className,
  columns,
  hideColumns,
  setHideColumns,
  setLoadingDataSource,
}) => {
  const [options, setOptions] = useState<{ name: string; key: string; value: boolean }[]>([]);

  useEffect(() => {
    setOptions(
      columns
        ?.filter((column) => column.title && column.key !== 'name')
        ?.map((column: any) => {
          const { title: name, key } = column;
          return {
            name,
            key,
            value: !hideColumns[key],
          };
        }),
    );
    setLoadingDataSource(false);
  }, [columns, hideColumns]);

  return (
    <div className={className}>
      {options.map((option) => {
        const { name, value, key } = option;
        return (
          <div key={`option_detail_${key}`} className="row">
            <span>{name}</span>
            <Switch
              size="small"
              checked={value}
              onClick={() => {
                setLoadingDataSource(true);
                setHideColumns({
                  ...hideColumns,
                  [key]: value,
                });
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const HeaderOptionDetailsStyled = styled(HeaderOptionDetails)`
  width: 230px;
  color: ${Colors.grey};

  .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:nth-of-type(n + 2) {
      padding-top: 5px;
    }
  }
`;

export default HeaderOptionDetailsStyled;
