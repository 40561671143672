import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { RESOURCE_TYPES } from '@/types/field.model';
import { IUser } from '@/types/user.model';
import { getWindowSize, isFeatureEnabled } from '@/utils';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, List, Modal, Radio, RadioChangeEvent, Select, Spin, Upload, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Axios from 'axios';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { useHistory } from 'react-router-dom';
import ModalPaid from '../ModalPaid';

const { Option } = Select;

interface Props {
  className?: string;
  visible: boolean;
  user?: IUser | null;
  onClose: () => void;
  onSave: (result: IUser) => void;
  openDrawer: () => void;
}

let emailId: string | null = null;

const DrawerUser: React.FC<Props> = ({ className, visible, user, onClose, onSave, openDrawer }) => {
  const {
    state: {
      loadingUsers,
      usersFields,
      sections,
      loadingSections,
      skills,
      loadingSkills,
      activeDepartmentId,
      activeDepartment,
      loadingUserCategories,
      userCategories,
      users,
      features,
      activeSection,
    },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [type, setType] = useState<'manual' | 'emails' | 'upload'>('manual');
  const [file, setFile] = useState<RcFile | null>(null);
  const [fileId, setFileId] = useState<string | null>(null);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [fileColumns, setFileColumns] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [modalPaidVisible, setModalPaidVisible] = useState<boolean>(false);
  let modalUsersImported: any;

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setType('manual');
    const fields = { ...form.getFieldValue('fields') };
    form.setFieldsValue({
      ...fields,
      type: 'manual',
    });
  }, [visible]);

  const onEmailChange = () => {
    if (userEmail) {
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/operations/users/check-user-fields`, {
        params: {
          departmentId: activeDepartmentId,
          email: userEmail,
        },
        cancelToken: cancelTokenSource.token,
      })
        .then((response) => {
          const fields = { ...form.getFieldValue('fields') };
          form.setFieldsValue({
            fields: {
              ...fields,
              ...response.data.fields,
            },
          });
          form.setFields([
            {
              name: ['fields', emailId!],
              errors: [],
            },
          ]);
        })
        .catch((error) => {
          if (emailId && error.response?.data?.message) {
            form.setFields([
              {
                name: ['fields', emailId!],
                errors: [error.response?.data?.message],
              },
            ]);
          }
        });
      return () => {
        cancelTokenSource.cancel();
      };
    }
  };

  const onEmailChangeDebounce = useCallback(debounce(onEmailChange, 300), [userEmail]);

  useEffect(() => {
    onEmailChangeDebounce();
    return () => {
      onEmailChangeDebounce.cancel();
    };
  }, [userEmail, onEmailChangeDebounce]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.currentTarget?.validity?.valid) {
      setUserEmail(event.currentTarget.value);
    }
  };

  const onFinish = async (values: any) => {
    delete values.type;

    if (activeDepartment?.accountType == 'FREE-CLOCKING' && users.length >= 5) {
      onClose();
      setModalPaidVisible(true);
    } else {
      if (type === 'manual') {
        const data = {
          ...values,
          sections: activeSection
            ? values.sections
              ? values.sections.includes(activeSection)
                ? values.sections
                : [...values.sections, activeSection]
              : [activeSection]
            : values.sections,
        };
        setIsSaving(true);
        Axios.post(`${process.env.REACT_APP_API_URL}/v3/users`, {
          departmentId: activeDepartmentId,
          ...data,
        })
          .then((response) => {
            onSave(response.data?.user);
            setIsSaving(false);
            form.resetFields();
            onClose();
          })
          .catch((error) => {
            message.error(t('USERS.USER_CREATION_FAILED'));
            setIsSaving(false);
            console.error(error);
          });
      } else if (type === 'upload') {
        if (file && fileId) {
          const formData = new FormData();
          const values = form.getFieldsValue();
          for (var key in values) {
            if (values[key] && key !== 'type') {
              formData.append(`${key}`, values[key]);
            }
          }
          formData.append('file', file);
          formData.append('file_id', fileId);

          Axios({
            url: `${process.env.REACT_APP_API_URL}/v3/users/import`,
            method: 'POST',
            data: formData,
            params: {
              departmentId: activeDepartmentId,
            },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(({ data }) => {
              modalUsersImported = Modal.success({
                title: t('GLOBAL.CONGRATULATIONS'),
                content:
                  data.users_created !== 0 ? (
                    <>
                      <p>{t('USERS.X_USERS_IMPORTED', { amount: data.users_created })}</p>
                      <p>{t('USERS.EMAIL_SENT_TO_USERS')}</p>
                      <p>
                        {t('USERS.YOU_CAN')}{' '}
                        <span
                          onClick={() => {
                            history.push('/app/team/pending-registrations');
                            modalUsersImported.destroy();
                          }}
                          style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                        >
                          {t('USERS.MANAGE_USERS')}
                        </span>
                      </p>
                    </>
                  ) : (
                    <p>{t('USERS.ALREADY_IMPORTED')}</p>
                  ),
              });
              setIsSaving(false);
              form.resetFields();
              setFile(null);
              setFileId(null);
              setFileLoading(false);
              setFileColumns([]);
              onClose();
            })
            .catch((error) => {
              if (error.response?.data?.errors) {
                setErrors(Object.values(error.response.data.errors).flat(1) as string[]);
              } else {
                message.error(t('USERS.USER_CREATION_FAILED'));
              }
              form.setFieldsValue({
                file: null,
              });
              setIsSaving(false);
              console.error(error);
            });
        }
      } else if (type === 'emails') {
        const emails = form.getFieldValue('emails');
        const emails_array = emails && emails.split('\n');
        Axios.post(`${process.env.REACT_APP_API_URL}/v3/users/import`, {
          departmentId: activeDepartmentId,
          emails: emails_array && emails_array.length > 0 ? emails_array : [emails],
        })
          .then(({ data }) => {
            modalUsersImported = Modal.success({
              title: t('GLOBAL.CONGRATULATIONS'),
              content:
                data.users_created > 0 ? (
                  <>
                    <p>{t('USERS.X_USERS_IMPORTED', { amount: data.users_created })}</p>
                    <p>{t('USERS.EMAIL_SENT_TO_USERS')}</p>
                    <p>
                      {t('USERS.YOU_CAN')}{' '}
                      <span
                        onClick={() => {
                          history.push('/app/team/pending-registrations');
                          modalUsersImported.destroy();
                        }}
                        style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                      >
                        {t('USERS.MANAGE_USERS')}
                      </span>
                    </p>
                  </>
                ) : (
                  <p>{t('USERS.ALREADY_IMPORTED')}</p>
                ),
            });
            setIsSaving(false);
            form.resetFields();
            onClose();
          })
          .catch((error) => {
            if (error.response?.data?.errors) {
              setErrors(Object.values(error.response.data.errors).flat(1) as string[]);
            } else {
              message.error(t('USERS.USER_CREATION_FAILED'));
            }
            setIsSaving(false);
            console.error(error);
          });
      }
      setFile(null);
    }
  };

  const onTypeChange = (e: RadioChangeEvent) => {
    setErrors([]);
    setType(e.target.value);
  };

  const beforeUpload = (file: RcFile) => {
    setFileLoading(true);
    setFile(file);

    const formData = new FormData();
    formData.append('file', file);

    Axios({
      url: `${process.env.REACT_APP_API_URL}/v3/users/import-file`,
      method: 'POST',
      data: formData,
      params: {
        departmentId: activeDepartmentId,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        setFileId(data.file_id);
        setFileColumns(data.columns);
        setFileLoading(false);
      })
      .catch((err) => {
        message.error(t('SELF_SERVICE.MESSAGES.ERROR_IMPORTING_FILE'));
        setFileLoading(false);
      });

    return false;
  };

  useEffect(() => {
    let timeout: any;
    if (errors.length > 0) {
      timeout = setTimeout(() => {
        setErrors([]);
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [errors]);

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={user?.recordId ? t('USERS.USER_MODIFICATION') : t('USERS.ADDING_USER')}
      placement="right"
      visible={visible}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 400 : '75%'}
    >
      <ModalPaid visible={modalPaidVisible} setVisible={setModalPaidVisible} openUserDrawer={openDrawer} />
      <ScrollToTop />
      <Spin spinning={loadingUsers}></Spin>
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        scrollToFirstError={true}
        style={{ display: loadingUsers ? 'none' : undefined }}
      >
        <Form.Item name="type">
          <Radio.Group buttonStyle="solid" className="light block" onChange={onTypeChange}>
            <Radio.Button value="manual">{t('GLOBAL.MANUAL')}</Radio.Button>
            <Radio.Button value="emails">{t('GLOBAL.EMAILS')}</Radio.Button>
            <Radio.Button value="upload">{t('GLOBAL.UPLOAD')}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {type === 'manual' ? (
          <>
            {usersFields
              .filter((field) => field.mandatory)
              .map((field) => {
                if (field.resourceType === RESOURCE_TYPES.EMAIL) {
                  emailId = field.id!;
                  return (
                    <Form.Item
                      key={`field_${field.id}`}
                      label={field.name}
                      name={['fields', field.id!]}
                      rules={[
                        {
                          required: field.mandatory,
                          message: t('FORMS.EMAIL_ERROR'),
                        },
                      ]}
                    >
                      <Input placeholder={field.name} type="email" onChange={onChange} />
                    </Form.Item>
                  );
                } else {
                  const email = field.resourceType === RESOURCE_TYPES.EMAIL;
                  return (
                    <Form.Item
                      key={`field_${field.id}`}
                      label={field.name}
                      name={['fields', field.id!]}
                      rules={[
                        {
                          required: field.mandatory,
                          message: '',
                        },
                      ]}
                    >
                      {field.resourceType === RESOURCE_TYPES.PHONE ? (
                        <PhoneInput
                          containerClass="ant-form-item-control-input-content"
                          inputClass="ant-input"
                          inputStyle={{ width: '100%', background: '#f7f8fb' }}
                        />
                      ) : (
                        <Input placeholder={field.name} />
                      )}
                    </Form.Item>
                  );
                }
              })}
            <Form.Item label={t('GLOBAL.USER_CATEGORY_SHORT')} name="user-category">
              <Select
                getPopupContainer={(trigger) => trigger}
                placeholder={t('FORMS.USER_CATEGORY_PLACEHOLDER')}
                loading={loadingUserCategories}
                disabled={loadingUserCategories}
              >
                {userCategories.map((userCategory) => (
                  <Option key={`status_${userCategory.id}`} value={userCategory.id!}>
                    {userCategory.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {isFeatureEnabled(features, FEATURES.SKILLS) && (
              <Form.Item name="skills" label={t('GLOBAL.SKILLS')}>
                <Select
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  clearIcon
                  allowClear
                  mode="multiple"
                  placeholder={t('FORMS.SKILLS_PLACEHOLDER')}
                  optionFilterProp="children"
                  disabled={loadingSkills}
                  loading={loadingSkills}
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {skills.map((skill: any) => (
                    <Option value={skill.id!} key={`skill_${skill.id}`}>
                      {skill.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {isFeatureEnabled(features, FEATURES.SECTIONS) && !!sections?.length && (
              <Form.Item name="sections" label={t('GLOBAL.SECTIONS')}>
                <Select
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  clearIcon
                  allowClear
                  mode="multiple"
                  placeholder={t('FORMS.SECTIONS_PLACEHOLDER')}
                  optionFilterProp="children"
                  disabled={loadingSections}
                  loading={loadingSections}
                  filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {sections.map((section: any) => (
                    <Option value={section.id!} key={`section_${section.id}`}>
                      {section.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </>
        ) : type === 'emails' ? (
          <>
            <Form.Item name="emails" label={t('GLOBAL.EMAILS')}>
              <Input.TextArea
                autoSize={{ minRows: 5 }}
                inputMode="email"
                placeholder="email1@email.com&#10;email2@email.com&#10;email3@email.com&#10;..."
              />
            </Form.Item>
            {errors.length > 0 && (
              <List dataSource={errors} renderItem={(item) => <p style={{ color: colors.red }}>* {item}</p>} />
            )}
            <h4 style={{ color: colors.green }}>{t('GLOBAL.EXPLANATION')}</h4>
            <span
              dangerouslySetInnerHTML={{ __html: t('SELF_SERVICE.DETAILS.EMAILS') }}
              style={{ color: colors.grey }}
            ></span>
          </>
        ) : (
          <>
            <Form.Item name="file" label={t('GLOBAL.FILE')}>
              <Upload.Dragger
                beforeUpload={beforeUpload}
                maxCount={1}
                multiple={false}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('UPLOAD.DRAGGER.TEXT')}</p>
              </Upload.Dragger>
            </Form.Item>
            {file ? (
              <Form.Item name="column" label={t('GLOBAL.COLUMN_NAME')} rules={[{ required: true }]}>
                <Select
                  defaultValue={fileColumns.length === 1 ? fileColumns[0] : undefined}
                  placeholder={t('GLOBAL.COLUMN_NAME')}
                  loading={fileLoading}
                >
                  {fileColumns.map((column) => (
                    <Select.Option value={column}>{column}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <>
                <h4 style={{ color: colors.green }}>{t('GLOBAL.EXPLANATION')}</h4>
                <span style={{ color: colors.grey }}>{t('SELF_SERVICE.DETAILS.UPLOAD')}</span>
                <Button
                  block
                  download
                  href="/emails.xlsx"
                  icon={<DownloadOutlined />}
                  style={{ paddingBottom: 0, marginTop: 25 }}
                >
                  {t('SELF_SERVICE.DETAILS.DOWNLOAD_EXCEL_FILE')}
                </Button>
              </>
            )}
            {errors.length > 0 && (
              <List dataSource={errors} renderItem={(item) => <p style={{ color: colors.red }}>* {item}</p>} />
            )}
          </>
        )}
        <div className="actions-container">
          <div className="actions">
            <Button
              type="primary"
              danger
              onClick={() => {
                form.resetFields();
                onClose();
              }}
            >
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {type === 'manual' ? t('GLOBAL.SAVE') : t('GLOBAL.SEND')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerUser;
