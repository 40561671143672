import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import { IMessage } from '@/types/message.model';
import { getWindowSize, valueForSearch } from '@/utils';
import { Button, Checkbox, DatePicker, Drawer, Form, message as Message, Modal, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface Props {
  className?: string;
  visible: boolean;
  message?: IMessage | null;
  onClose: () => void;
  onSave: (result: IMessage) => void;
}

const DrawerFreeShiftsAlert: React.FC<Props> = ({ className, visible, message, onClose, onSave }) => {
  const {
    state: { activeDepartmentId: departmentId, activeDepartment, loadingUserCategories, userCategories },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    form.resetFields();
    let fieldsValue: any = {
      recipients: {
        userCategories: [],
      },
      email: true,
      app: true,
      sms: false,
      sendingDate: moment.tz(moment(), activeDepartment?.timezone!),
    };
    if (message) {
      const { sendingDate, recipients } = message;
      fieldsValue = {
        ...fieldsValue,
        ...message,
        recipients: {
          userCategories: recipients?.userCategories?.map((x) => x.id) || [],
        },
        sendingDate: sendingDate
          ? moment.tz(moment.unix(sendingDate), activeDepartment?.timezone!)
          : fieldsValue.sendingDate,
      };
    }
    form.setFieldsValue(fieldsValue);
    // eslint-disable-next-line
  }, [message]);

  const onFinish = async (values: any) => {
    setIsSaving(true);
    Axios.post(
      `${process.env.REACT_APP_API_URL}/v3/messages`,
      {
        ...values,
        type: 'FREE_SHIFT_ALERT',
        sendingDate: values.sendingDate.unix(),
      },
      {
        params: {
          departmentId,
        },
      },
    )
      .then(({ data }) => {
        setIsSaving(false);
        onSave(data);
        onClose();
      })
      .catch((error) => {
        Message.error(error?.response?.data?.message);
        const names = Object.keys(error?.response?.data?.errors || {});
        form.setFields(
          names.map((name) => ({
            name,
            errors: error?.response?.data?.errors[name],
          })),
        );
        setIsSaving(false);
        console.log(error);
      });
  };

  const deleteMessage = () => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('FREE_SHIFT_NOTIFICATIONS.MODAL_DELETE.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDeleteMessage(message!.id!);
      },
      onCancel: () => {},
    });
  };

  const onDeleteMessage = (messageId: string) => {
    Axios.delete(`${process.env.REACT_APP_API_URL}/v3/messages/${messageId}`, {
      params: {
        departmentId,
      },
    })
      .then((response) => {
        onSave(response.data);
        onClose();
      })
      .catch(() => {
        Message.error(t('FREE_SHIFT_NOTIFICATIONS.MODAL_DELETE.ERROR'));
      });
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={message?.id ? t('GLOBAL.ALERT') : t('FREE_SHIFT_NOTIFICATIONS.NEW_ALERT')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name={['recipients', 'userCategories']} label={t('GLOBAL.RECIPIENTS')} rules={[{ required: true }]}>
          <Select
            mode="multiple"
            placeholder={t('FORMS.RECIPIENTS_SEARCH')}
            maxTagTextLength={10}
            maxTagCount={3}
            suffixIcon={<i className="icon-search" />}
            disabled={loadingUserCategories || !!message?.id}
            loading={loadingUserCategories}
            filterOption={(input, option) => {
              if (option) {
                if (!option.label) {
                  let children = option.children;
                  if (Array.isArray(children)) {
                    children = children.join();
                  }
                  return valueForSearch(children).includes(valueForSearch(input));
                }
              }
              return false;
            }}
          >
            {userCategories.map((userCategory) => {
              const { id, name } = userCategory;
              return (
                <Option key={`userCategory_${id}`} value={id!}>
                  {name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="message" label={t('GLOBAL.MESSAGE')}>
          <TextArea rows={4} disabled={!!message?.id} />
        </Form.Item>
        <Form.Item name="sendingDate" label={t('GLOBAL.SENDING_DATE')} rules={[{ required: true }]}>
          <DatePicker
            disabled={!!message?.id}
            style={{ width: '100%' }}
            showTime
            format={'L - HH:mm'}
            onSelect={(e) => {
              form.setFieldsValue({
                sendingDate: e,
              });
            }}
          />
        </Form.Item>
        <Form.Item name="email" valuePropName="checked" style={{ marginBottom: 0 }}>
          <Checkbox disabled={!!message?.id}>{t('GLOBAL.SEND_BY_EMAIL')}</Checkbox>
        </Form.Item>
        <Form.Item name="app" valuePropName="checked" style={{ marginBottom: 0 }}>
          <Checkbox disabled={!!message?.id}>{t('GLOBAL.SEND_ON_MOBILE_APP')}</Checkbox>
        </Form.Item>
        <Form.Item name="sms" valuePropName="checked" style={{ marginBottom: 0 }}>
          <Checkbox disabled={!!message?.id}>{t('GLOBAL.SEND_BY_SMS')}</Checkbox>
        </Form.Item>
        <div className="actions-container">
          {!!message?.id && message.status === 'SCHEDULED' && (
            <div className="actions">
              <Button type="primary" danger onClick={onClose}>
                {t('GLOBAL.BACK')}
              </Button>
              <Button type="default" onClick={deleteMessage}>
                {t('FREE_SHIFT_NOTIFICATIONS.REMOVE_ALERT')}
              </Button>
            </div>
          )}
          {!!message?.id && message.status !== 'SCHEDULED' && (
            <div className="actions">
              <Button type="primary" danger onClick={onClose}>
                {t('GLOBAL.BACK')}
              </Button>
            </div>
          )}
          {!message?.id && (
            <div className="actions">
              <Button type="primary" danger onClick={onClose}>
                {t('GLOBAL.CANCEL')}
              </Button>
              <Button type="primary" htmlType="submit" loading={isSaving}>
                {t('GLOBAL.SAVE')}
              </Button>
            </div>
          )}
        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerFreeShiftsAlert;
