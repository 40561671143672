import colors from '@/styles/colors';
import { IUser } from '@/types/user.model';
import { Space, Select } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface Props {
  className?: string;
  user?: IUser;
}

const TypicalWeekHeader: React.FC<Props> = ({ className, user }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <header className={className}>
      <div className="left">
        <Space>
          <h2>
            {t('GLOBAL.SCHEDULE_MODELS')} <span>{user?.displayName}</span>
          </h2>
        </Space>
      </div>
    </header>
  );
};

export default styled(TypicalWeekHeader)`
  padding: 15px 25px;

  h2 {
    margin: 0;
    font-weight: bold;

    span {
      font-weight: normal;
      color: ${colors.grey};
    }
  }
`;
