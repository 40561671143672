import colors from '@/styles/colors';
import { handleError } from '@/utils';
import { Button, Checkbox, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const TermsModal: React.FC<Props> = ({ className }) => {
  const { i18n, t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const [checked, setChecked] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(`/rgpd-${i18n.language || 'en'}.html`)
      .then((response) => response.text())
      .then((data) => setContent(data));
  }, []);

  useEffect(() => {
    const handleClose = (e: any) => {
      if (visible) {
        e.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleClose);
    return () => {
      window.removeEventListener('beforeunload', handleClose);
    };
  }, [visible]);

  const handleAccept = () => {
    setVisible(false);
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/legal/terms-aknowledge`)
      .then(() => {})
      .catch((error) => handleError(error));
  };

  const onAknowledgeChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  return (
    <Modal
      visible={visible && content !== ''}
      closable={false}
      zIndex={10000}
      width={'50%'}
      className={className}
      footer={
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Checkbox onChange={onAknowledgeChange} style={{ textAlign: 'left', fontWeight: 'bold' }}>
            {t('GLOBAL.TERMS_CHECK')}
          </Checkbox>
          <Button
            disabled={!checked}
            key="accept"
            type="primary"
            onClick={handleAccept}
            style={{ background: colors.green, color: '#FFF' }}
          >
            {t('GLOBAL.ACCEPT')}
          </Button>
        </div>
      }
    >
      <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
    </Modal>
  );
};

export default styled(TermsModal)`
  .ant-modal-content {
    padding-top: 15px;
  }

  .ant-modal-content,
  .ant-modal-header {
    border-radius: 8px;
  }

  .ant-modal-body {
    padding: 20px;
    margin: 25px 35px;
    border: 2px solid #999;
    opacity: 0.7;
    border-radius: 8px;
  }

  .ant-modal-footer {
    padding: 25px 35px;
    padding-top: 0;
    border-top: none;
  }

  .content {
    max-height: 300px;
    overflow-y: scroll;
    font-size: 11px;
    line-height: 20px;
    color: #999;
  }
`;
