import React from 'react';
import { ISection } from '@/types/section.model';
import { ISkill } from '@/types/skill.model';
import styled from 'styled-components';
import { getContrast, isNullOrUndefined, lighten } from '@/utils';
import { PickerType } from '../redux/store';
import { ColoredShiftsTypes } from '@/types/department-settings.model';

interface Props {
  className?: string;
  section: ISection | undefined;
  skills: ISkill[];
  style?: React.CSSProperties | undefined;
  picker: PickerType;
  schedule?: boolean;
  backgroundColor?: string;
  coloredShifts?: ColoredShiftsTypes | null;
}
const ShiftTags: React.FC<Props> = (props) => {
  const { className, section, skills, style, picker, schedule, backgroundColor, coloredShifts } = props;
  return (
    <React.Fragment>
      {(skills.length > 0 || section) && (
        <div className={className} style={{ ...style }}>
          {!isNullOrUndefined(section) && (
            <span
              className="tag square small dark-purple"
              key={`section_${section!.id}`}
              style={{
                backgroundColor: schedule ? section!.color : section!.background || '#000000',
                color: schedule ? getContrast(section!.color!) : section!.color || '#000000',
                wordWrap: 'break-word',
                width: picker == 'operational' ? '100%' : 'auto',
                borderRadius: picker == 'operational' ? 5 : undefined,
              }}
            >
              {section!.name || section!.section_name}
            </span>
          )}
          {skills &&
            skills.length > 0 &&
            skills.map((skill) => (
              <span
                className="tag small dark-blue"
                key={`skill_${skill.id}`}
                style={{
                  background:
                    coloredShifts && coloredShifts == ColoredShiftsTypes.SKILLS && backgroundColor && skill.background
                      ? lighten(skill.background, 50)
                      : schedule
                      ? skill.color
                        ? skill.color
                        : 'transparent'
                      : skill.background
                      ? skill.background
                      : '#000000',
                  color: schedule
                    ? skill.color
                      ? getContrast(skill.color)
                      : '#000000'
                    : skill.color
                    ? skill.color
                    : '#000000',
                  display: picker !== 'day' ? 'block' : 'inline-block',
                  marginBottom: picker !== 'day' ? '3px' : '0px',
                  marginTop: picker !== 'day' ? '3px' : '0px',
                  fontWeight: 'initial',
                  hyphens: 'auto',
                  width: picker == 'operational' ? '100%' : 'auto',
                }}
              >
                {skill.name || skill.skill_name}
              </span>
            ))}
        </div>
      )}
    </React.Fragment>
  );
};

const ShiftTagsStyled = styled(ShiftTags)`
  span:nth-of-type(n - 1) {
    margin-right: 5px;
  }
`;

export default ShiftTagsStyled;
