import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import {
  IReportTemplate,
  IReportTemplateColumn,
  IReportTemplateColumnField,
  IReportTemplateField,
} from '@/types/report-template.model';
import { getWindowSize } from '@/utils';
import { Button, Drawer, Form, Input, message } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ColumnFieldTable from '../../settings/general/custom-reports/components/ColumnFieldTable';

interface Props {
  className?: string;
  visible: boolean;
  fields: IReportTemplateField[];
  column?: IReportTemplateColumn | null;
  activeReport: IReportTemplate;
  reports: IReportTemplate[];
  onClose: () => void;
  onSave: (result: IReportTemplateColumn) => void;
  setActiveReport: React.Dispatch<React.SetStateAction<IReportTemplate | null>>;
  setReportColumns: React.Dispatch<React.SetStateAction<IReportTemplateColumn[]>>;
  setReports: React.Dispatch<React.SetStateAction<IReportTemplate[]>>;
}

const DrawerReportColumn: React.FC<Props> = ({
  className,
  visible,
  fields,
  column,
  activeReport,
  reports,
  onClose,
  onSave,
  setActiveReport,
  setReportColumns,
  setReports,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [columnFields, setColumnFields] = useState<IReportTemplateColumnField[]>([]);
  const [currentConcat, setCurrentConcat] = useState<string>('');
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  useEffect(() => {
    form.resetFields();
    setCurrentConcat('');

    if (column) {
      form.setFieldsValue({
        ...column,
      });
    } else {
      setColumnFields([]);
    }
  }, [visible]);

  useEffect(() => {
    if (column && column.fields) {
      setColumnFields(column.fields);
    }
  }, [column]);

  const onFinish = async (values: any) => {
    setLoading(true);
    if (activeReport.columns && activeReport.columns.length > 0) {
      let newColumns = null;
      if (column && column.title) {
        const found = activeReport.columns.find((col) => col.title == column.title);
        if (found) {
          newColumns = activeReport.columns.map((col) =>
            col.title !== found.title
              ? col
              : {
                  ...values,
                  position: col.position,
                  fields: [...columnFields],
                },
          );
        }
      }
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${activeReport.id}`,
          {
            ...activeReport,
            columns: newColumns || [
              ...activeReport.columns,
              {
                ...values,
                position: activeReport.columns.length + 1,
                fields: [...columnFields],
              },
            ],
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          setActiveReport(data);
          setReportColumns(data.columns);
          onClose();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(Object.values(err.response.data.errors).flat(1)[0]);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template`,
          {
            ...activeReport,
            columns: [
              {
                ...values,
                position: 1,
                fields: [...columnFields],
              },
            ],
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          setActiveReport(data);
          setReportColumns(data.columns);
          setReports([...reports, data]);
          onClose();
          history.push(`/app/settings/general/custom-reports/${data.id}`);
          setLoading(false);
        })
        .catch((err) => {
          message.error(Object.values(err.response.data.errors).flat(1)[0]);
          setLoading(false);
        });
    }
  };

  const concat_result: any =
    columnFields &&
    columnFields.length > 1 &&
    columnFields.map((columnField) => {
      const type = columnField.id && typeof columnField.id !== 'number' && columnField.id.split('.')[0];
      const found = fields.find((field) => field.type == type)?.fields.find((field) => field.id == columnField.id);
      if (found) {
        return found.label;
      }
      return;
    });

  return (
    <Drawer
      forceRender={true}
      title={
        column && column.title
          ? t('REPORT_TEMPLATES.EDIT_COLUMN', { name: column?.title })
          : t('REPORT_TEMPLATES.ADD_COLUMN')
      }
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 650 : '75%'}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="title" label={t('REPORT_TEMPLATES.COLUMN_NAME')} rules={[{ required: true }]}>
          <Input placeholder={t('REPORT_TEMPLATES.COLUMN_NAME')} />
        </Form.Item>
        <Form.Item label={t('REPORT_TEMPLATES.FIELDS')} rules={[{ required: true }]}>
          <ColumnFieldTable fields={fields} columnFields={columnFields} setColumnFields={setColumnFields} />
        </Form.Item>
        {columnFields.length > 1 && (
          <>
            <Form.Item name="concat_character" label={t('REPORT_TEMPLATES.CONCAT_STRING')}>
              <Input
                placeholder={t('REPORT_TEMPLATES.CONCAT_STRING')}
                onChange={(e) => setCurrentConcat(e.target.value)}
              />
            </Form.Item>
            {!concat_result.includes(undefined) && (
              <>
                <div>
                  {t('REPORT_TEMPLATES.CONCAT_RESULT')}: {concat_result.join(currentConcat || ' ')}
                </div>
                <br />
              </>
            )}
            <span
              style={{ marginTop: 15, color: colors.grey }}
              dangerouslySetInnerHTML={{ __html: t('REPORT_TEMPLATES.CONCAT_EXPLANATION') }}
            ></span>
          </>
        )}
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerReportColumn;
