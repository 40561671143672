import { IUserParams } from '@/types/user-params.model';
import { Checkbox, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  reportsList: IUserParams['reportsList'];
}

const Printing: React.FC<Props> = ({ reportsList }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <div>
      <h4>{t('USERS.USER.SETTINGS.PRINTING_SELECTION')}</h4>
      <Form.Item name="reports">
        <Checkbox.Group>
          {reportsList?.map((report) => (
            <Form.Item style={{ margin: 0 }} className="one-line">
              <Checkbox key={`report_${report.id}`} value={`${report.id}`} style={{ width: '100%' }}>
                {report.name}
              </Checkbox>
            </Form.Item>
          ))}
        </Checkbox.Group>
      </Form.Item>
    </div>
  );
};

export default Printing;
