import React from 'react';
import { IAttribut } from '@/types/attribut.model';
import styled from 'styled-components';
import { getContrast, lighten } from '@/utils';

interface Props {
  className?: string;
  attributes: IAttribut[];
  style?: React.CSSProperties | undefined;
  backgroundColor?: string;
}
const ShiftAttributes: React.FC<Props> = ({ className, attributes, style, backgroundColor }) => {
  return (
    <div className={className} style={{ ...style }}>
      {attributes &&
        attributes.length > 0 &&
        attributes.map((attribut, i) => (
          <span
            key={`attribut_${attribut.id}`}
            style={{
              background:
                backgroundColor && i == 0 && attribut.background
                  ? lighten(attribut.background, 50)
                  : attribut.background
                  ? attribut.background
                  : '#666666',
              color: attribut.color ? attribut.color : '#666666',
              hyphens: 'auto',
            }}
          >
            {attribut.name}
          </span>
        ))}
    </div>
  );
};

const ShiftAttributesStyled = styled(ShiftAttributes)`
  span {
    font-size: 9px;
    padding: 2px 3px;
    border-radius: 2px;
    display: inline-flex;
  }
  span:nth-of-type(n - 1) {
    margin-right: 5px;
  }
`;

export default ShiftAttributesStyled;
