import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IShiftRequest } from '@/types/shift-request.model';
import { IUserWithInfos } from '@/types/user.model';
import { minutesToHours } from '@/utils';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Image, Input, Modal, Row, Select } from 'antd';
import { SelectValue } from 'antd/es/select';
import Axios from 'axios';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

moment.tz.setDefault('Atlantic/Reykjavik');

const { TextArea } = Input;
const { Option } = Select;

type Action = 'APPROVE' | 'REFUSE' | 'WAIT' | 'TRANSFER';

interface Props {
  className?: string;
  departmentId?: string;
  shiftRequest: IShiftRequest | null;
  onClose: () => void;
  onSave: () => void;
}

const ModalProceedShiftRequest: React.FC<Props> = ({ className, departmentId, shiftRequest, onClose, onSave }) => {
  const [form] = Form.useForm();
  const {
    state: { dayoffs, loadingDayoffs },
    dispatch,
  } = useContext(AppContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [action, setAction] = useState<Action | null>(null);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<IUserWithInfos[]>([]);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [startMoment, setStartMoment] = useState<Moment | null>(null);
  const [endMoment, setEndMoment] = useState<Moment | null>(null);
  const [dayoffType, setDayoffType] = useState<SelectValue | null>(null);

  useEffect(() => {
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    setAction(null);
    setIsSaving(false);
    setUsers([]);
    setStartMoment(null);
    setEndMoment(null);
    form.resetFields();
    if (shiftRequest) {
      const { start, end } = shiftRequest.shift!;
      setStartMoment(moment.unix(start!));
      setEndMoment(moment.unix(end!));
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/operations/get-users`, {
        params: {
          departmentId,
          shiftId: shiftRequest?.shift?.id,
        },
        cancelToken: cancelTokenSource.token,
      })
        .then(({ data }) => {
          setUsers(data);
        })
        .catch((error) => {
          setLoadingUsers(false);
          console.log(error);
        });

      return () => {
        cancelTokenSource.cancel();
      };
    }
  }, [shiftRequest]);

  const onFinish = (values: any) => {
    Axios.patch(
      `${process.env.REACT_APP_API_URL}/v3/operations/request-shifts/${shiftRequest?.id}`,
      {
        ...values,
        action,
        dayoff_type: action == 'APPROVE' ? (dayoffType ? dayoffType : undefined) : undefined,
      },
      {
        params: {
          departmentId,
        },
      },
    )
      .then(() => {
        onSave();
        onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDownloadClick = (imageUrl: string) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, 'image.jpg'); // Specify filename for download
      })
      .catch((error) => {
        console.error('Error downloading image:', error);
      });
  };

  return (
    <Modal
      className={className}
      visible={!!shiftRequest}
      onOk={() => form.submit()}
      onCancel={() => onClose()}
      forceRender={true}
      destroyOnClose={true}
      title={t('GLOBAL.PROCESSING')}
      footer={
        shiftRequest?.status == 'REQUEST_OPEN'
          ? [
              <Button key="back" onClick={onClose}>
                {t('GLOBAL.CANCEL')}
              </Button>,
              <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
                {t('GLOBAL.VALIDATE')}
              </Button>,
            ]
          : null
      }
    >
      <p className="infos">
        <span className="displayName">{shiftRequest?.shift?.user?.displayName}</span>
        <span className="date">
          {startMoment?.format('LL')}{' '}
          {t('GLOBAL.FROM_HOUR_TO_HOUR', {
            hour1: startMoment?.format('HH:mm'),
            hour2: endMoment?.format('HH:mm'),
          }).toLocaleLowerCase()}
        </span>
      </p>
      <div className="reason">
        <span className="type">{shiftRequest?.requestType}</span>
        <span className="reason">{shiftRequest?.reason?.replace(/\\n/g, '\n')}</span>
      </div>
      {!!shiftRequest?.files?.length && (
        <React.Fragment>
          <Divider orientation="left" plain>
            {t('GLOBAL.FILES')}
          </Divider>
          <div className="pictures">
            <Image.PreviewGroup>
              {shiftRequest?.files?.map((image, index) => (
                <div key={`image_${index}`} style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                  <div style={{ flex: 1 }}>
                    <Image src={image} preview={true} width={100} height={100} style={{ objectFit: 'cover' }} />
                  </div>
                  <div>
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      onClick={() => handleDownloadClick(image)} // Define a function to handle download
                    >
                      {t('GLOBAL.DOWNLOAD')}
                    </Button>
                  </div>
                </div>
              ))}
            </Image.PreviewGroup>
          </div>
        </React.Fragment>
      )}
      <Divider />
      {shiftRequest?.status == 'REQUEST_OPEN' && (
        <div style={{ marginBottom: 25 }}>
          <span>{t('DAYOFFS.FORMS.CHANGE_DAYOFF_LABEL')}</span>
          <Select
            allowClear
            getPopupContainer={(trigger) => trigger}
            placeholder={t('DAYOFFS.DAYOFF_TYPE')}
            optionFilterProp="children"
            loading={loadingDayoffs}
            disabled={loadingDayoffs}
            style={{ width: '100%' }}
            onChange={(value) => setDayoffType(value)}
          >
            {dayoffs.map((dayoff) => (
              <Select.Option value={dayoff.id!}>{dayoff.name}</Select.Option>
            ))}
          </Select>
        </div>
      )}
      {!action && shiftRequest?.status == 'REQUEST_OPEN' && (
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Button type="primary" style={{ width: '100%' }} onClick={() => setAction('APPROVE')}>
              {t('GLOBAL.APPROVE')}
            </Button>
          </Col>
          <Col span={12}>
            <Button type="primary" style={{ width: '100%' }} danger onClick={() => setAction('REFUSE')}>
              {t('GLOBAL.REFUSE')}
            </Button>
          </Col>
          {shiftRequest?.requestType !== 'DAYOFF' && (
            <Col span={12}>
              <Button
                style={{ width: '100%', background: colors.orange, borderColor: colors.orange, color: 'white' }}
                onClick={() => setAction('WAIT')}
              >
                {t('GLOBAL.PENDING')}
              </Button>
            </Col>
          )}
          <Col span={shiftRequest?.requestType !== 'DAYOFF' ? 12 : 24}>
            <Button
              type="default"
              style={{ width: '100%', color: 'black', background: colors.greyLight, borderColor: colors.greyLight }}
              onClick={() => setAction('TRANSFER')}
            >
              {t('GLOBAL.TRANSFER')}
            </Button>
          </Col>
        </Row>
      )}
      <Form layout="vertical" form={form} onFinish={onFinish}>
        {!!action && (
          <React.Fragment>
            <Button type="text" onClick={() => setAction(null)} style={{ color: colors.grey, fontWeight: 'normal' }}>
              {`< ${t('GLOBAL.BACK')}`}
            </Button>
            <h2>{action}</h2>
            {action === 'TRANSFER' && (
              <Form.Item name="userRecordId" rules={[{ required: true }]}>
                <Select
                  allowClear={true}
                  getPopupContainer={(trigger) => trigger}
                  showSearch
                  placeholder="Choisir un salarié"
                  optionFilterProp="children"
                  loading={loadingUsers}
                  disabled={loadingUsers}
                  optionLabelProp="label"
                  filterOption={(input, option) => {
                    if (option && option.label) {
                      return (option!.label! as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                  }}
                >
                  {users.map((user) => (
                    <Option
                      key={`user_${user.recordId}`}
                      value={`${user.recordId}`}
                      label={user.displayName}
                      disabled={
                        !!user?.constraints?.message?.length &&
                        user?.constraints?.message?.some((c) => c.type === 'not_available')
                      }
                    >
                      <div className="select-user-option">
                        <div className="displayName">{user.displayName}</div>
                        <div className="infos">
                          {!!user?.constraints?.message?.length && <span>{user?.constraints?.message[0]?.title}</span>}
                          {(!!user.totalWeekMinutes || !!user.maxWeekMinutes) && (
                            <span
                              style={{
                                backgroundColor:
                                  user.maxWeekMinutes &&
                                  user.totalWeekMinutes &&
                                  user.totalWeekMinutes > user.maxWeekMinutes
                                    ? colors.red
                                    : colors.green,
                              }}
                            >
                              {minutesToHours(user.totalWeekMinutes || 0)}
                              {user.maxWeekMinutes ? `/${minutesToHours(user.maxWeekMinutes)}` : ''}h
                            </span>
                          )}
                          {(!!user.totalTrimesterMinutes || !!user.maxTrimesterMinutes) && (
                            <span
                              style={{
                                backgroundColor:
                                  user.maxTrimesterMinutes &&
                                  user.totalTrimesterMinutes &&
                                  user.totalTrimesterMinutes > user.maxTrimesterMinutes
                                    ? colors.red
                                    : colors.green,
                              }}
                            >
                              {minutesToHours(user.totalTrimesterMinutes || 0)}
                              {user.maxTrimesterMinutes ? `/${user.maxTrimesterMinutes}` : ''}m
                            </span>
                          )}
                        </div>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item name="comment" label={t('GLOBAL.COMMENT')}>
              <TextArea rows={4} />
            </Form.Item>
            {action === 'APPROVE' && (
              <Form.Item name="freeShift" className="one-line" valuePropName="checked" style={{ margin: 0 }}>
                <Checkbox>{t('SHIFTS.MODAL_TRANSFER_SHIFTS.TRANSFORM_TO_FREESHIFTS')}</Checkbox>
              </Form.Item>
            )}
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default styled(ModalProceedShiftRequest)`
  p.infos {
    text-align: center;
    > span {
      display: block;
      &.displayName {
        font-size: 14px;
      }
      &.department {
        color: ${colors.grey};
      }
    }
  }

  div.reason {
    border-radius: 3px;
    background: ${colors.greyLight};
    text-align: center;
    padding: 15px;
    > span {
      display: block;
      &.type {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .reason {
      white-space: pre-wrap;
    }
  }

  .ant-select-item.ant-select-item-option.not-compatible:not(.ant-select-item-option-selected) {
    background: ${colors.greyLight};
    color: ${colors.grey};
  }

  .form-item-should-update {
    .ant-form-item-control-input {
      min-height: 0;
    }

    .ant-row.ant-form-item.min-height {
      .ant-form-item-control-input {
        min-height: 40px;
      }
    }
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
    .ant-select-item-option-content {
      .select-user-option {
        .infos  {
          opacity: 0.8;
        }
      }
    }
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    .ant-select-item-option-content {
      .select-user-option {
        .infos  {
          opacity: 1;
        }
      }
    }
  }
  .ant-select-item-option-content .select-user-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .displayName {
    }
    .infos {
      opacity: 0.3;
      transition: opacity 0.3s;
      span {
        background-color: ${colors.bluePastel};
        color: white;
        border-radius: 3px;
        margin-left: 4px;
        padding: 1px 5px;
      }
    }
  }
`;
