import React, { useState, useEffect, useRef } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';
import { Table, TableProps } from 'antd';

interface Props extends React.PropsWithChildren<TableProps<any>> {
  staticGridRef: React.MutableRefObject<any>;
  previousOffset: number;
  setPreviousOffset: React.Dispatch<React.SetStateAction<number>>;
}

const VirtualTable: React.FC<Props> = (props) => {
  const { columns, dataSource, scroll, onRow, rowClassName, staticGridRef, previousOffset, setPreviousOffset } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const [mergedColumns, setMergedColumns] = useState<any[]>([]);

  const gridRef = useRef<any>();
  const [connectObject] = useState<any>(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    });

    return obj;
  });

  const resizeHandler = () => {
    if (columns?.length) {
      let lastColumnWidth = +columns[columns.length - 1].width!;
      const columnsWidth = columns?.reduce((a, b) => a + +b!.width!, 0);
      const tableWidth = window.innerWidth - 30;
      if (columnsWidth < tableWidth) {
        lastColumnWidth = tableWidth - columnsWidth + lastColumnWidth;
      }
      const mergedColumns = [...columns];
      mergedColumns[mergedColumns.length - 1] = { ...mergedColumns[mergedColumns.length - 1], width: lastColumnWidth };
      setMergedColumns(mergedColumns);
    }
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [columns]);

  const resetVirtualGrid = () => {
    gridRef?.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: false,
    });
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  (staticGridRef?.current as any)?.scrollTo({ scrollLeft: 0, scrollTop: previousOffset });
  (gridRef?.current as any)?.scrollTo({ scrollLeft: 0, scrollTop: previousOffset });

  const renderVirtualList = (rawData: any[], { scrollbarSize, ref, onScroll }: any) => {
    ref.current = connectObject;
    const totalHeight = rawData.length * 33;

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Grid
          ref={staticGridRef}
          style={{ overflowY: 'hidden', flexShrink: 0 }}
          className="virtual-grid"
          columnCount={1}
          columnWidth={(i: number) => {
            const { width } = mergedColumns[i];
            return width;
          }}
          height={scroll!.y as number}
          rowCount={rawData.length}
          row
          rowHeight={() => 33}
          width={180}
        >
          {({
            columnIndex,
            rowIndex,
            style,
          }: {
            columnIndex: number;
            rowIndex: number;
            style: React.CSSProperties;
          }) => {
            const rowData = rawData[rowIndex];
            const mergedColumn = mergedColumns[columnIndex];
            let content = (rowData as any)[mergedColumn.dataIndex];
            if ('render' in mergedColumn) {
              content = (mergedColumn as any).render(content, rowData);
            }

            const className = (rowClassName as any)?.(rowData);
            return (
              <div
                className={classNames(className, 'virtual-table-cell', 'virtual-table-cell-fix-left', {
                  'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                })}
                style={style}
                onClick={() => {
                  (onRow?.(rowData) as any)?.onClick?.(rowData);
                }}
              >
                {content}
              </div>
            );
          }}
        </Grid>
        <Grid
          ref={gridRef}
          className="virtual-grid"
          columnCount={mergedColumns.length - 1}
          columnWidth={(index: number) => {
            const { width } = mergedColumns[index + 1];
            return totalHeight > scroll!.y! && index + 1 === mergedColumns.length - 1
              ? (width as number) - scrollbarSize - 1
              : (width as number);
          }}
          height={scroll!.y as number}
          rowCount={rawData.length}
          row
          rowHeight={() => 33}
          width={tableWidth}
          onScroll={({
            scrollLeft,
            scrollTop,
            scrollUpdateWasRequested,
          }: {
            scrollLeft: number;
            scrollTop: number;
            scrollUpdateWasRequested: boolean;
          }) => {
            onScroll({ scrollLeft });
            if (!scrollUpdateWasRequested) {
              (staticGridRef?.current as any)?.scrollTo({ scrollLeft: 0, scrollTop });
            }
          }}
        >
          {({
            columnIndex,
            rowIndex,
            style,
          }: {
            columnIndex: number;
            rowIndex: number;
            style: React.CSSProperties;
          }) => {
            const rowData = rawData[rowIndex];
            const mergedColumn = mergedColumns[columnIndex + 1] as any;
            let content = (rowData as any)[mergedColumn.dataIndex];
            if ('render' in mergedColumn) {
              content = (mergedColumn as any).render(content, rowData);
            }

            const className = (rowClassName as any)?.(rowData);
            const lastColumn = columnIndex + 1 === mergedColumns.length - 1;
            return (
              <div
                className={classNames(className, 'virtual-table-cell', {
                  'virtual-table-cell-last': lastColumn,
                })}
                style={style}
                onClick={() => {
                  (onRow?.(rowData) as any)?.onClick?.(rowData);
                }}
              >
                {content}
              </div>
            );
          }}
        </Grid>
      </div>
    );
  };

  if (!dataSource?.length) {
    return <Table {...props}></Table>;
  }

  if (mergedColumns?.length) {
    return (
      <ResizeObserver
        onResize={({ width }) => {
          setTableWidth(width);
        }}
      >
        <Table
          {...props}
          style={{
            width: mergedColumns?.reduce((a, b) => a + +b.width!, 0),
          }}
          columns={mergedColumns}
          pagination={false}
          components={{
            body: renderVirtualList as any,
          }}
        />
      </ResizeObserver>
    );
  } else {
    return <Table {...props} columns={mergedColumns} pagination={false} />;
  }
};

export default VirtualTable;
