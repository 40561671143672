import AppContext from '@/pages/app/context';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { isCountry, isFeatureEnabled, isTrial } from '@/utils';
import { Menu } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface Props {
  department?: IDepartment;
}

const MenuReports: React.FC<Props> = (props) => {
  const { department, ...otherProps } = props;
  const { access } = department || {};
  const {
    state: { hrPartners, features },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <Menu style={{ maxHeight: 'calc(100vh - 80px)', overflowY: 'auto' }} {...otherProps}>
      <Menu.ItemGroup title={t('MENU.REPORTS.TITLE')}>
        {access?.reports && (
          <Menu.Item key="timesheets">
            <NavLink activeClassName="is-active" to="/app/reports/timesheets" className="menu_reports_timesheets">
              {t('REPORTS.CONTROL_OF_SERVICES.TITLE')}
            </NavLink>
          </Menu.Item>
        )}
        {department?.accountType !== 'RGPD' && isCountry(department!, ['FR']) && (
          <Menu.Item key="pay-periods">
            <NavLink activeClassName="is-active" to="/app/reports/pay-periods" className="menu_reports_pay-periods">
              {t('REPORTS.PAY_PERIODS.TITLE')}
            </NavLink>
          </Menu.Item>
        )}
        {department?.accountType !== 'RGPD' &&
          department?.accountType !== 'FREE-TRIAL' &&
          access?.reports &&
          isFeatureEnabled(features, FEATURES.SCHEDULE) && (
            <Menu.Item key="activity-reports">
              <NavLink
                activeClassName="is-active"
                to="/app/reports/activity-reports"
                className="menu_reports_activity-reports"
              >
                {t('REPORTS.ACTIVITY_REPORT.TITLE')}
              </NavLink>
            </Menu.Item>
          )}
        {department?.accountType !== 'RGPD' &&
          !isCountry(department!, ['FR']) &&
          access?.reports &&
          !isTrial(department!) && (
            <Menu.Item key="leave-reports">
              <NavLink
                activeClassName="is-active"
                to="/app/reports/leave-reports"
                className="menu_reports_leave-reports"
              >
                {t('REPORTS.LEAVE_REPORTS.TITLE')}
              </NavLink>
            </Menu.Item>
          )}
        {department?.accountType !== 'RGPD' &&
          department?.accountType !== 'FREE-TRIAL' &&
          access?.timesheets &&
          hrPartners.length > 0 && (
            <Menu.Item key="hr-sheets">
              <NavLink activeClassName="is-active" to="/app/reports/hr-sheets" className="menu_reports_hr-sheets">
                {t('REPORTS.HR_TIMESHEETS.TITLE')}
              </NavLink>
            </Menu.Item>
          )}
        {department?.accountType !== 'RGPD' &&
          department?.accountType !== 'FREE-TRIAL' &&
          access?.timesheets &&
          hrPartners.length > 0 && (
            <Menu.Item key="hr-sheet-exports">
              <NavLink
                activeClassName="is-active"
                to="/app/reports/hr-sheet-exports"
                className="menu_reports_hr-sheet-exports"
              >
                {t('REPORTS.EXPORT_HR_TIMESHEETS.TITLE')}
              </NavLink>
            </Menu.Item>
          )}
        {access?.reports && (
          <Menu.Item key="service-sheets">
            <NavLink
              activeClassName="is-active"
              to="/app/reports/service-sheets"
              className="menu_reports_service-sheets"
            >
              {t('REPORTS.SIGNUP_SHEETS.TITLE')}
            </NavLink>
          </Menu.Item>
        )}
      </Menu.ItemGroup>
    </Menu>
  );
};

export default MenuReports;
