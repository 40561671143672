import { Button, Checkbox, Form, Input, InputNumber, Radio, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ResultsModal from './components/ResultsModal';

interface Props {
  className?: string;
}

interface HorecaExpoData {
  currentCost: string;
  hoursMonth: number | null;
  meetingLink: string;
  shyfterCost: string;
  users: number | null;
  timeSaved: number | null;
}

const HorecaExpoPage: React.FC<Props> = ({ className }) => {
  const { t, i18n } = useTranslation();
  const [form] = useForm();
  const [resultsVisible, setResultsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<HorecaExpoData | null>(null);
  const [error, setError] = useState<boolean>(false);

  const onChangeLanguage = (value: string) => {
    i18n.changeLanguage(value);
  };

  const onFinish = (values: any) => {
    const formatted_values = {
      ...values,
      type: values.type ? values.type : 'other',
    };

    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/horeca-expo`, formatted_values, {})
      .then((res) => {
        form.resetFields();
        setResults(res.data);
        setLoading(false);
        setResultsVisible(true);
      })
      .catch((err) => {
        setError(true);
        setResultsVisible(false);
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <ResultsModal visible={resultsVisible} setVisible={setResultsVisible} data={results} />
      <div className="container">
        <div className="language">
          <Select size="large" value={i18n.language} onChange={onChangeLanguage}>
            <Select.Option value="fr" key="fr">
              FR
            </Select.Option>
            <Select.Option value="nl" key="nl">
              NL
            </Select.Option>
            <Select.Option value="en" key="en">
              EN
            </Select.Option>
          </Select>
        </div>
        <div className="content">
          <h1 className="title">Horeca Expo</h1>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item name="lastname" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
              <Input size="large" placeholder={t('GLOBAL.NAME')} />
            </Form.Item>
            <Form.Item name="firstname" label={t('GLOBAL.FIRSTNAME')} rules={[{ required: true }]}>
              <Input size="large" placeholder={t('GLOBAL.FIRSTNAME')} />
            </Form.Item>
            <Form.Item name="company" label={t('GLOBAL.COMPANY')} rules={[{ required: true }]}>
              <Input size="large" placeholder={t('GLOBAL.COMPANY')} />
            </Form.Item>
            <Form.Item name="employees" label={t('HORECA_EXPO.NUMBER_OF_EMPLOYEES')}>
              <InputNumber inputMode="numeric" size="large" placeholder={t('HORECA_EXPO.NUMBER_OF_EMPLOYEES')} />
            </Form.Item>
            <Form.Item name="type" label={t('HORECA_EXPO.INSTITUTION_TYPE')}>
              <Radio.Group defaultValue="other">
                <Space direction="vertical">
                  <Radio value="restaurant">{t('HORECA_EXPO.RESTAURANT')}</Radio>
                  <Radio value="fast-food">{t('HORECA_EXPO.FAST_FOOD')}</Radio>
                  <Radio value="french-fry">{t('HORECA_EXPO.FRENCH_FRY')}</Radio>
                  <Radio value="cafe">{t('HORECA_EXPO.CAFE')}</Radio>
                  <Radio value="other">{t('HORECA_EXPO.OTHER')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="time" label={t('HORECA_EXPO.TIME_SPENT')}>
              <InputNumber inputMode="numeric" size="large" placeholder={t('HORECA_EXPO.TIME_SPENT')} />
            </Form.Item>
            <Form.Item name="hour_cost" label={t('HORECA_EXPO.AVERAGE_HOUR_COST')}>
              <InputNumber inputMode="numeric" size="large" placeholder={t('HORECA_EXPO.AVERAGE_HOUR_COST')} />
            </Form.Item>
            <Form.Item name="contracts" label={t('HORECA_EXPO.WHAT_TO_USE')} valuePropName="checked">
              <Checkbox value="contracts">{t('GLOBAL.CONTRACTS')}</Checkbox>
            </Form.Item>
            <Form.Item name="dimona" valuePropName="checked">
              <Checkbox value="contracts">Dimona</Checkbox>
            </Form.Item>
            <Form.Item name="clocking" valuePropName="checked">
              <Checkbox value="clocking">{t('HORECA_EXPO.CLOCKING')}</Checkbox>
            </Form.Item>
            <Form.Item name="email" label={t('GLOBAL.EMAIL')} rules={[{ required: true }]}>
              <Input inputMode="email" size="large" placeholder={t('GLOBAL.EMAIL')} />
            </Form.Item>
            {error && <span>An error occured, please try again</span>}
            <Button htmlType="submit" type="primary" size="large" block loading={loading} disabled={loading}>
              {t('GLOBAL.SEND')}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default styled(HorecaExpoPage)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;

  input {
    height: 75px;
    font-size: 20px;
  }

  .ant-input-number {
    width: 100%;
  }

  label {
    font-size: 25px;
  }

  .container {
    width: 75vw;
    height: 100%;
    display: flex;
    flex-direction: column;

    .language {
      align-self: flex-end;
    }

    .content {
      flex: 1;
      padding: 25px 0;

      .title {
        text-align: center;
        font-weight: bold;
        font-size: 48px;
      }

      .ant-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .ant-btn-lg {
        height: 75px;

        span {
          font-size: 28px;
        }
      }
    }
  }
`;
