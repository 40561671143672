import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { IShift } from '@/types/shift.model';
import { handleError, isFeatureEnabled } from '@/utils';
import { Menu, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { Dispatch, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ActionType, copyShifts } from '../redux/actions';
import { InitialStateType } from '../redux/store';

interface Props {
  shift: IShift;
  close: () => void;
  closeMonthRightMenu?: () => void;
}

const ShiftRightClickMenu: React.FC<Props> = ({ shift, close, closeMonthRightMenu }) => {
  const history = useHistory();
  const { t } = useTranslation(undefined, { useSuspense: true });
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const {
    state: { features, activeDepartment },
  } = useContext(AppContext);
  const [modalErrorVisible, setModalErrorVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const {
    usersMap,
    selectedShifts,
    assignModalVisible,
    addOccurrencesModalVisible,
    selected_shifts_on_shift_key,
    picker,
    ayShiftIds,
  } = useSelector(
    ({
      usersMap,
      shiftsMap,
      assignModalVisible,
      addOccurrencesModalVisible,
      selectedShifts,
      selected_shifts_on_shift_key,
      picker,
      ayShiftIds,
    }: InitialStateType) => ({
      usersMap,
      assignModalVisible,
      addOccurrencesModalVisible,
      shiftsMap,
      selectedShifts,
      selected_shifts_on_shift_key,
      picker,
      ayShiftIds,
    }),
    shallowEqual,
  );

  const aySynced = shift.id && ayShiftIds ? ayShiftIds.includes(shift.id) : false;
  const user = shift.userRecordId ? usersMap.get(shift.userRecordId) : null;

  // useEffect(() => {
  //   const selectedShiftsExcludingDayoffs = [];
  //   for (const shiftId of selectedShifts) {
  //     if (!shiftsMap.get(shiftId)?.dayoff) {
  //       selectedShiftsExcludingDayoffs.push(shiftId);
  //     }
  //   }
  //   setSelectedShiftsExcludingDayoffs(selectedShiftsExcludingDayoffs);
  // }, [selectedShifts]);

  // Handlers
  const handleDeleteShift = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    if (picker == 'month' && selected_shifts_on_shift_key && selected_shifts_on_shift_key.length > 0) {
      hoursDispatch({
        type: 'SET_SHIFT_IDS_TO_DELETE',
        payload: selected_shifts_on_shift_key!.map((el: IShift) => el!.id as string),
      });
    } else {
      hoursDispatch({
        type: 'SET_SHIFT_IDS_TO_DELETE',
        payload: [shift.id!],
      });
    }
    close();
    if (closeMonthRightMenu) {
      closeMonthRightMenu();
    }
  };

  const handleCopyShift = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    copyShifts(hoursDispatch, {
      ids: [shift.id!],
    });
    close();
    if (closeMonthRightMenu) {
      closeMonthRightMenu();
    }
  };

  const handleApproveShift = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    hoursDispatch({ type: 'SET_SHIFT_IDS_TO_PUBLISH', payload: [shift.id!] });
    close();
    if (closeMonthRightMenu) {
      closeMonthRightMenu();
    }
  };

  const handleCreateDimona = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    const { start, end, userRecordId } = shift;
    const duration = moment.duration(moment.unix(end!).diff(moment.unix(start!)));
    const durationAsHours = duration.asHours();
    const user = usersMap.get(userRecordId!);
    if (user) {
      hoursDispatch({
        type: 'SET_NEW_DIMONA',
        payload: {
          user: {
            displayName: user.displayName!,
            recordId: user.recordId!,
          },
          startingDate: shift.start!,
          endingDate: shift.end!,
          plannedHoursNbr: durationAsHours,
        },
      });
    }
    close();
    if (closeMonthRightMenu) {
      closeMonthRightMenu();
    }
  };

  const handleCreateContract = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    const { start, end, userRecordId } = shift;
    const duration = moment.duration(moment.unix(end!).diff(moment.unix(start!)));
    const durationAsHours = duration.asHours();

    history.push('/app/documents/contracts/new', {
      userRecordId: userRecordId,
      start: start,
      end: end,
      salaryPeriod: '0',
      weekHours: durationAsHours,
      weekDays: 1,
      maxDailyHours: Math.ceil(durationAsHours),
      comingFrom: 'SHIFT_RIGHT_CLICK',
    });
    close();
    if (closeMonthRightMenu) {
      closeMonthRightMenu();
    }
  };

  const handleMassEdition = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    hoursDispatch({
      type: 'SET_MULTIPLE_SHIFTS_DRAWER_VISIBLE',
      payload: true,
    });
    close();
    if (closeMonthRightMenu) {
      closeMonthRightMenu();
    }
  };

  const setAssignModalVisible = () => {
    close();
    if (closeMonthRightMenu) {
      closeMonthRightMenu();
    }
    hoursDispatch({
      type: 'SET_ASSIGN_MODAL_VISIBLE',
      payload: !assignModalVisible,
    });
  };

  const handleAddOccurrences = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    close();
    if (closeMonthRightMenu) {
      closeMonthRightMenu();
    }
    hoursDispatch({
      type: 'SET_ADD_OCCURRENCES_MODAL_VISIBLE',
      payload: !addOccurrencesModalVisible,
    });
    hoursDispatch({
      type: 'SET_SHIFT_TO_ADD_OCCURRENCES',
      payload: shift,
    });
  };

  const onAySync = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    e.domEvent.preventDefault();
    close();

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/ay/sync-shift`,
        {},
        {
          params: {
            departmentId: activeDepartment?.id,
            shiftId: shift.id,
          },
        },
      )
      .then(({ data }) => {
        message.success(t('SHIFTS.AY_SYNCED'));
        hoursDispatch({
          type: 'SET_SELECTED_SHIFTS',
          payload: [],
        });
      })
      .catch((error) => {
        if (error && error.response && error.response && error.response.data && error.response.data.errors) {
          const errors = { ...error.response.data.errors };
          if (errors.planning_id) {
            delete errors.planning_id;
          }
          hoursDispatch({
            type: 'SET_AY_ERRORS',
            payload: errors,
          });
        } else {
          handleError(error);
        }
      });
  };

  if (shift.shyftType === 3) {
    return (
      <Menu>
        <Menu.Item
          key={`shift_${shift.id}_rightClick_delete`}
          onClick={handleDeleteShift}
          style={{ color: colors.red }}
        >
          <i className="icon-delete" /> {t('GLOBAL.REMOVE')}
        </Menu.Item>
      </Menu>
    );
  } else {
    const canCreateDimona =
      shift.dimona && !shift.dayoff && shift.userRecordId && shift.dimonaQualify && !shift.dimonaValid;

    const canCreateContract = !shift.hasContract && shift.contractQualify;

    return (
      <Menu style={{ zIndex: 999999 }}>
        {shift.approved === false && (
          <Menu.Item
            key={`shift_${shift.id}_rightClick_approve`}
            onClick={handleApproveShift}
            style={{ color: colors.green }}
          >
            <i className="icon-ok-circled" /> {t('SHIFTS.APPROVE_THIS_SHIFT')}
          </Menu.Item>
        )}
        {!shift.userRecordId && (
          <Menu.Item key={`shift_${shift.id}_rightClick_assign`} onClick={setAssignModalVisible}>
            <i className="icon-user" /> {t('SHIFTS.ASSIGN.TITLE')}
          </Menu.Item>
        )}
        {picker !== 'month' && (
          <Menu.Item key={`shift_${shift.id}_rightClick_copy`} onClick={handleCopyShift}>
            <i className="icon-copy" /> {t('GLOBAL.COPY')}
          </Menu.Item>
        )}
        {picker == 'month' && (!selected_shifts_on_shift_key || selected_shifts_on_shift_key!.length == 1) && (
          <Menu.Item key={`shift_${shift.id}_rightClick_copy`} onClick={handleCopyShift}>
            <i className="icon-copy" /> {t('GLOBAL.COPY')}
          </Menu.Item>
        )}
        {isFeatureEnabled(features, FEATURES.DIMONA) && canCreateDimona && (
          <Menu.Item key={`shift_${shift.id}_rightClick_createDimona`} onClick={handleCreateDimona}>
            <i className="icon-upload-cloud" /> {t('DIMONA.CREATE_DIMONA')}
          </Menu.Item>
        )}
        {isFeatureEnabled(features, FEATURES.CONTRACTS) && canCreateContract && (
          <Menu.Item key={`shift_${shift.id}_rightClick_createContract`} onClick={handleCreateContract}>
            <i className="icon-align-justify" /> {t('CONTRACTS.CREATE_CONTRACT')}
          </Menu.Item>
        )}
        {selectedShifts.size > 1 && selectedShifts.has(shift.id || '') && (
          <Menu.Item key={`shift_${shift.id}_rightClick_edit`} onClick={handleMassEdition}>
            <i className="icon-edit" />{' '}
            {t('SHIFTS.MODIFY_X_SHIFTS', {
              count: selectedShifts.size,
            })}
          </Menu.Item>
        )}
        {selected_shifts_on_shift_key && selected_shifts_on_shift_key.length > 0 && (
          <Menu.Item key={`shift_${shift.id}_rightClick_edit`} onClick={handleMassEdition}>
            <i className="icon-edit" />{' '}
            {t('SHIFTS.MODIFY_X_SHIFTS', {
              count: selected_shifts_on_shift_key.length,
            })}
          </Menu.Item>
        )}
        {picker == 'week' && !shift.userRecordId && (
          <Menu.Item
            key={`shift_${shift.id}_rightClick_add_occurrences`}
            onClick={handleAddOccurrences}
            style={{ color: '#03a9f4' }}
          >
            <i className="icon-plus" /> {t('SHIFTS.MULTIPLE_FREE_SHIFTS.ADD_OCCURRENCES')}
          </Menu.Item>
        )}
        {activeDepartment?.ay_core && !aySynced && shift.approved && user && user.ay_sync && (
          <Menu.Item key={`shift_${shift.id}_rightClick_ay_sync`} onClick={onAySync} style={{ color: colors.ayBrand }}>
            <i className="icon-ay_icon" /> {t('GLOBAL.SYNC_TO_AY')}
          </Menu.Item>
        )}
        <Menu.Item
          key={`shift_${shift.id}_rightClick_delete`}
          onClick={handleDeleteShift}
          style={{ color: colors.red }}
        >
          <i className="icon-delete" /> {t('GLOBAL.REMOVE')}
        </Menu.Item>
      </Menu>
    );
  }
};

export default ShiftRightClickMenu;
