import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Form, Input, Button } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { IDepartment } from '@/types/department.model';
import { IUser } from '@/types/user.model';
import { IScheduleModel } from '@/types/schedule-model.model';

interface Props {
  visible: boolean;
  user: IUser;
  department: IDepartment;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setScheduleModels: React.Dispatch<React.SetStateAction<IScheduleModel[]>>;
}

const ModalCreateScheduleModel: React.FC<Props> = ({ visible, user, department, setVisible, setScheduleModels }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = ({ name = '' }) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/users/${user.recordId}/schedule-models`,
        {
          name,
        },
        {
          params: {
            departmentId: department.id,
          },
        },
      )
      .then(({ data }) => {
        setVisible(false);
        setScheduleModels(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Modal
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={visible}
      onCancel={onClose}
      title={t('SCHEDULE_MODELS.CREATE_MODEL')}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          {t('GLOBAL.CREATE')}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="name" label={t('GLOBAL.NAME')} required rules={[{ required: true }]}>
          <Input placeholder={t('GLOBAL.NAME')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalCreateScheduleModel)``;
