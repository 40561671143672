import Constraints from '@/pages/app/components/Constraints';
import AppContext from '@/pages/app/context';
import Colors from '@/styles/colors';
import { IShift } from '@/types/shift.model';
import { Dropdown, Menu, Modal } from 'antd';
import axios from 'axios';
import { isNull, isUndefined } from 'lodash';
import moment from 'moment';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { Dispatch, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType, updateShiftsAndContextWithShiftResponse } from '../redux/actions';
import { InitialStateType } from '../redux/store';

const RightClickMenu: React.FC<{ shift: IShift; close: () => void }> = ({ shift, close }) => {
  const {
    state: { activeDepartmentId, activeSection, activeDepartment, skills, userCategories },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const { clipboardHistory, startDate, endDate, picker, pasteMultipleFreeShiftsVisible } = useSelector(
    ({ clipboardHistory, startDate, endDate, picker, pasteMultipleFreeShiftsVisible }: InitialStateType) => ({
      clipboardHistory,
      startDate,
      endDate,
      picker,
      pasteMultipleFreeShiftsVisible,
    }),
    shallowEqual,
  );

  const handlePasteShift = (e: MenuInfo) => {
    const { id, start, end, count } = clipboardHistory!.shifts[0];
    const { timestamp, userRecordId } = shift;

    if (id) {
      const shift = document.getElementById(id);
      shift?.classList.add('optimistic');
    }

    let axiosResponse;
    if (picker === 'day') {
      const differenceInHours = moment.unix(timestamp!).diff(moment.unix(start!), 'hour');
      axiosResponse = axios.post(
        `${process.env.REACT_APP_API_URL}/v3/shifts`,
        {
          shifts: [
            {
              id,
              start: moment.unix(start!).add(differenceInHours, 'hours').unix(),
              end: moment.unix(end!).add(differenceInHours, 'hours').unix(),
              userRecordId,
            },
          ],
        },
        {
          params: {
            picker,
            startDate: startDate.unix(),
            endDate: endDate.unix(),
            departmentId: activeDepartmentId,
            sectionId: activeSection,
          },
        },
      );
    } else {
      if (count && count > 1) {
        close();
        if (id) {
          const shift = document.getElementById(id);
          shift?.classList.remove('optimistic');
        }

        hoursDispatch({
          type: 'SET_FREE_SHIFT_TO_PASTE_MULTIPLE',
          payload: shift,
        });

        hoursDispatch({
          type: 'SET_PASTE_MULTIPLE_FREE_SHIFTS_MODAL_VISIBLE',
          payload: true,
        });
      } else {
        const differenceInDays = moment(timestamp).diff(moment.unix(start!).startOf('day'), 'days');
        axiosResponse = axios.post(
          `${process.env.REACT_APP_API_URL}/v3/shifts`,
          {
            shifts: [
              {
                id,
                start: moment.unix(start!).add(differenceInDays, 'day').unix(),
                end: moment.unix(end!).add(differenceInDays, 'day').unix(),
                userRecordId,
              },
            ],
          },
          {
            params: {
              picker,
              startDate: startDate.unix(),
              endDate: endDate.unix(),
              departmentId: activeDepartmentId,
              sectionId: activeSection,
            },
          },
        );
      }
    }

    if (axiosResponse) {
      axiosResponse
        .then((response) => {
          updateShiftsAndContextWithShiftResponse(hoursDispatch, response, {
            department: activeDepartment!,
            picker,
            startDate,
            endDate,
            activeSection,
            skills,
            userStatus: userCategories,
          });
          const { totalErrors = 0 } = response.data.message;
          if (totalErrors) {
            Modal.confirm({
              className: 'modal-constraints',
              icon: null,
              title: t('SCHEDULE.CONSTRAINTS.TITLE'),
              content: <Constraints message={response.data.message.error?.[0]?.message || []} />,
              cancelText: t('GLOBAL.CANCEL'),
              okText: t('GLOBAL.FORCE'),
              onOk: () => {
                return axios({
                  method: response.config.method,
                  url: response.config.url,
                  data: {
                    ...JSON.parse(response.config.data),
                    force: true,
                  },
                  params: {
                    picker,
                    startDate: startDate.unix(),
                    endDate: endDate.unix(),
                    departmentId: activeDepartmentId,
                    sectionId: activeSection,
                  },
                })
                  .then((response) => {
                    updateShiftsAndContextWithShiftResponse(hoursDispatch, response, {
                      department: activeDepartment!,
                      picker,
                      startDate,
                      endDate,
                      activeSection,
                      skills,
                      userStatus: userCategories,
                    });
                    if (id) {
                      const shift = document.getElementById(id);
                      shift?.classList.remove('optimistic');
                    }
                  })
                  .catch((err) => {
                    if (id) {
                      const shift = document.getElementById(id);
                      shift?.classList.remove('optimistic');
                    }
                    console.error(err);
                  });
              },
            });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          if (id) {
            const shift = document.getElementById(id);
            shift?.classList.remove('optimistic');
          }
        });
    }

    e.domEvent.stopPropagation();
  };

  return (
    <Menu>
      <Menu.Item key="0" onClick={handlePasteShift} disabled={!clipboardHistory?.shifts.length}>
        {shift.userRecordId && t('SCHEDULE.PASTE_FOR_THIS_USER')}
        {isNull(shift.userRecordId) && t('SCHEDULE.PASTE_AS_FREE_SHIFT')}
      </Menu.Item>
    </Menu>
  );
};

interface Props {
  className?: string;
  size: 'small' | 'large';
  shift: IShift;
  style?: React.CSSProperties;
}

const AddShift: React.FC<Props> = ({ className, size, shift, style }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const clipboardHistory = useSelector((store: InitialStateType) => store.clipboardHistory);
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const [rightClickMenuVisible, setRightClickMenuVisible] = useState<boolean>(false);

  const onClick = () => {
    hoursDispatch({
      type: 'SET_SELECTED_SHIFT',
      payload: shift,
    });
    hoursDispatch({
      type: 'SET_SHIFT_DRAWER_VISIBLE',
      payload: true,
    });
  };

  if (!isUndefined(shift.userRecordId) && clipboardHistory?.shifts.length && clipboardHistory?.shifts.length > 0) {
    return (
      <div className={className} style={style}>
        <Dropdown
          overlay={() => (
            <RightClickMenu
              shift={shift}
              close={() => {
                setRightClickMenuVisible(false);
              }}
            />
          )}
          trigger={['contextMenu']}
          visible={rightClickMenuVisible}
          onVisibleChange={(e) => {
            setRightClickMenuVisible(e);
          }}
        >
          <button onClick={onClick}>
            <i>+</i> {size !== 'small' && <span>{t('SCHEDULE.ADD_SHIFT')}</span>}
          </button>
        </Dropdown>
      </div>
    );
  } else {
    return (
      <div className={className} style={style}>
        <button onClick={onClick}>
          <i>+</i> {size !== 'small' && <span>{t('SCHEDULE.ADD_SHIFT')}</span>}
        </button>
      </div>
    );
  }
};

const AddShiftStyled = styled(AddShift)`
  display: flex;
  opacity: 0;
  height: 100%;
  width: 100%;
  padding-top: 5px;
  position: relative;

  @media print {
    display: none !important;
  }

  &:hover {
    opacity: 1;
    button {
      position: relative;
    }
  }

  button {
    position: absolute;
    display: flex;
    border: 1px dashed ${Colors.green};
    border-radius: 5px;
    background: white;
    align-items: center;
    justify-content: center;
    color: ${Colors.green};
    width: 100%;
    height: 100%;

    i {
      font-style: normal;
    }
  }
`;

export default AddShiftStyled;
