import appContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IScheduleModel } from '@/types/schedule-models/schedule-model.model';
import { ISection } from '@/types/section.model';
import { ISkill } from '@/types/skill.model';
import { IUser } from '@/types/user.model';
import { Button, Empty, Spin } from 'antd';
import React, { Dispatch, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import DrawerShift from './DrawerShift';
import Header from '../Header';
import ModalDeleteShiftConfirmation from '../../modals/ModalDeleteShiftConfirmation';
import { ActionType, updateScheduleModelStore } from '../../redux/actions';
import { InitialStateType, IWeek } from '../../redux/store';
import Shifts from './Shifts';
import SubHeader from './SubHeader';
import WeekRow from './WeekRow';
import { IUserParams } from '@/types/user-params.model';

export interface IScheduleModelResponse {
  weeks: IWeek[];
  skills: ISkill[];
  sections: ISection[];
  scheduleModelList: InitialStateType['scheduleModelList'];
}

interface Props {
  className?: string;
  user: IUser;
  scheduleModel: IScheduleModel;
  userParams: IUserParams;
  setScheduleModel: React.Dispatch<React.SetStateAction<IScheduleModel | null>>;
  setScheduleModels: React.Dispatch<React.SetStateAction<IScheduleModel[]>>;
}

const ModalScheduleModelDetails: React.FC<Props> = ({
  className,
  user,
  scheduleModel,
  userParams,
  setScheduleModel,
  setScheduleModels,
}) => {
  const {
    state: { activeDepartmentId: departmentId, loadingUsers, users },
  } = useContext(appContext);
  const { isLoading, shiftDrawerVisible, shiftIdsToDelete } = useSelector(
    ({ isLoading, shiftDrawerVisible, shiftIdsToDelete }: InitialStateType) => ({
      isLoading,
      shiftDrawerVisible,
      shiftIdsToDelete,
    }),
    shallowEqual,
  );
  const { t } = useTranslation(undefined, { useSuspense: false });
  const dispatch: Dispatch<ActionType> = useDispatch();

  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
  const [showModalCopy, setShowModalCopy] = useState<boolean>(false);

  const weeks = scheduleModel && scheduleModel.weeks!;

  const onDataChange = (data: IScheduleModel[]) => {
    setScheduleModels(data);
    const found = data.find((sm) => sm.id === scheduleModel.id);
    if (found) {
      setScheduleModel(found);
    }
  };

  return (
    <div className={`${className} app-hours-container week`}>
      <Spin spinning={!user || isLoading} size="large">
        <Header user={user} />
        {!!scheduleModel && (
          <React.Fragment>
            <SubHeader
              totalMinutes={weeks?.reduce((a, b) => a + b.totalMinutes, 0)}
              hrTotalMinutes={user?.currentHoursWeek}
            />
            {!!user &&
              weeks?.map((week, i) => {
                return (
                  <WeekRow key={i} index={i} totalMinutes={week.totalMinutes} totalWeeks={weeks?.length}>
                    <Shifts
                      days={week.days as any}
                      weekIndex={i}
                      weekId={week.id}
                      scheduleModel={scheduleModel}
                      setScheduleModel={setScheduleModel}
                      setScheduleModels={setScheduleModels}
                    />
                  </WeekRow>
                );
              })}
            <p style={{ textAlign: 'center', marginTop: 20, color: colors.grey }}>{t('SCHEDULE_MODELS.HELP_TEXT')}</p>
          </React.Fragment>
        )}
        {!scheduleModel && (
          <Empty style={{ padding: 40 }}>
            {/* <h3 style={{ textAlign: 'center', color: colors.green }}>{t('SCHEDULE_MODELS.NO_SELECTION')}</h3> */}
            <Button type="primary" size="large" style={{ marginTop: 10 }} onClick={() => setShowModalCreate(true)}>
              {t('SCHEDULE_MODELS.CREATE_MODEL')}
            </Button>
          </Empty>
        )}
      </Spin>
      <DrawerShift
        visible={shiftDrawerVisible}
        user={user}
        setVisible={(visibility: boolean) =>
          dispatch({
            type: 'SET_SHIFT_DRAWER_VISIBLE',
            payload: visibility,
          })
        }
        onSave={onDataChange}
        userParams={userParams}
      />
      {/* <ModalCopyScheduleTemplate
        loadingUsers={loadingUsers}
        users={users}
        scheduleModelId={scheduleModelId}
        departmentId={departmentId}
        visible={showModalCopy}
        setScheduleModelId={setScheduleModelId}
        onClose={() => {
          setShowModalCopy(false);
        }}
      /> */}
      <ModalDeleteShiftConfirmation
        departmentId={departmentId}
        shiftIds={shiftIdsToDelete}
        visible={!!shiftIdsToDelete}
        onSuccess={onDataChange}
        onClose={() => dispatch({ type: 'SET_SHIFT_IDS_TO_DELETE', payload: null })}
      />
    </div>
  );
};

export default styled(ModalScheduleModelDetails)``;

export const MONDAY = 345600;
export const DAY = 86400;
