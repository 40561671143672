import TableView from '@/layouts/TableView';
import Tag from '@/pages/app/components/Tag';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IInvoice } from '@/types/invoice.model';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, message, Table } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

const CreditNotes: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartment },
    dispatch,
  } = useContext(AppContext);
  const [creditNotes, setCreditNotes] = useState<IInvoice[]>([]);
  const [loadingCreditNotes, setLoadingCreditNotes] = useState<boolean>(false);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.CREDIT_NOTE'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.DATE'),
        dataIndex: 'date',
        key: 'date',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IInvoice) => {
          return <span>{moment(record.date).format('L')}</span>;
        },
      },
      {
        title: t('GLOBAL.TVAC_AMOUNT'),
        dataIndex: 'total',
        key: 'total',
        sorter: (a: IInvoice, b: IInvoice) => b.total - a.total,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IInvoice) => {
          return (
            <span>
              {new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: activeDepartment?.currency || 'EUR',
              }).format(record.total / 100)}
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.STATUS'),
        dataIndex: 'status',
        key: 'status',
        sorter: (a: any, b: any) => a.status.localeCompare(b.status),
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IInvoice) => {
          return (
            <Tag
              size="large"
              color={'white'}
              name={record.status.toUpperCase()}
              background={record.status === 'paid' ? colors.green : colors.red}
            />
          );
        },
      },
      {
        title: t('GLOBAL.DOCUMENT'),
        key: 'document',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IInvoice) => {
          return (
            <Button
              loading={isLoading}
              icon={<DownloadOutlined />}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(record.invoice_pdf, '_blank');
              }}
            >
              {t('GLOBAL.DOWNLOAD')}
            </Button>
          );
        },
      },
    ]);
  }, [i18n]);

  useEffect(() => {
    setCreditNotes([]);
    setLoadingCreditNotes(true);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/billing/creditnotes`, {
      params: {
        departmentId: activeDepartment?.id,
      },
    })
      .then((response) => {
        setCreditNotes(response.data);
        setLoadingCreditNotes(false);
      })
      .catch((error) => {
        console.log(error);
        message.error(t('SETTINGS.BILLING.CREDIT_NOTES.MESSAGE_ERROR_LOADING'));
        setLoadingCreditNotes(false);
      });
  }, [activeDepartment?.id]);

  return (
    <React.Fragment>
      <h2>{t('SETTINGS.BILLING.CREDIT_NOTES.TITLE')}</h2>
      <TableView>
        <Table
          className={className}
          loading={loadingCreditNotes}
          dataSource={creditNotes}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
      </TableView>
    </React.Fragment>
  );
};

export default CreditNotes;
