import { Button, Modal } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  openUserDrawer: () => void;
}

const ModalPaid: React.FC<Props> = ({ className, visible, setVisible, openUserDrawer }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);

  const onCancel = () => {
    setVisible(false);
    openUserDrawer();
  };

  const onClick = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/update-method`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        location.href = data.url;
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <Modal
      className={className}
      forceRender={true}
      destroyOnClose={true}
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <div className="container">
        <h1 style={{ fontWeight: 'bold' }}>{t('MODAL_PAID.TITLE')}</h1>
        <p>{t('MODAL_PAID.DESCRIPTION')}</p>
        <Button type="primary" size="large" onClick={onClick} loading={loading}>
          {t('MODAL_PAID.BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};

export default styled(ModalPaid)`
  .ant-modal-body {
    padding-top: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
