import Error404 from '@/components/Error404';
import ContainerView from '@/layouts/ContainerView';
import AppContext from '@/pages/app/context';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import AppTeamCollaboratorsPage from './collaborators';
import AppTeamPendingCollaboratorsPage from './pending-collaborators';
import AppTeamPendingPage from './pending-registrations';

interface Props {
  userSubscription?: boolean;
  resourceSubscription?: boolean;
}

const AppTeamPage: React.FC<Props> = ({ userSubscription, resourceSubscription }) => {
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const { path, url } = useRouteMatch();
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('MENU.TEAM.TITLE')} - Shyfter</title>
      </Helmet>
      <Switch>
        <Route exact path={`${path}`} render={() => <Redirect to={`${url}/collaborators`} />} />
        <Route path={`${path}/collaborators`}>
          <AppTeamCollaboratorsPage />
        </Route>
        {!!userSubscription && (
          <Route path={`${path}/pending-collaborators`}>
            <ContainerView>
              <AppTeamPendingCollaboratorsPage />
            </ContainerView>
          </Route>
        )}
        <Route path={`${path}/pending-registrations`}>
          <ContainerView>
            <AppTeamPendingPage />
          </ContainerView>
        </Route>
        <Route component={() => <Error404 />} />
      </Switch>
    </React.Fragment>
  );
};

export default AppTeamPage;
