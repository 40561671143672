import Colors from '@/styles/colors';
import { IAddress } from '@/types/address.model';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { getWindowSize } from '@/utils';
import { UploadOutlined } from '@ant-design/icons';
import { Divider, Form, Input, List, message, Select, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';
import PlaceAutocomplete from '@/pages/app/components/PlaceAutocomplete';
import AppContext from '@/pages/app/context';
import { ISettingsListElement } from '@/types/settings/list-element.model';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const { Option } = Select;

const AccountDetails: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    let fieldsValue: any = {};
    if (currentSettings) {
      fieldsValue = {
        ...currentSettings,
        'search-input': currentSettings?.department?.fulladdress,
      };
    }
    const image = currentSettings?.department?.image;
    if (image) {
      setFileList([
        {
          uid: '-1',
          status: 'done',
          url: image,
        },
      ]);
    } else {
      setFileList([]);
    }
    form.setFieldsValue(fieldsValue);
  };

  const onAddressChange = (address: IAddress) => {
    const departmentField = { ...form.getFieldValue('department') };
    form.setFieldsValue({
      department: {
        ...departmentField,
        ...address,
        fulladdress: address.fullAddress,
      },
      ['search-input']: address.fullAddress,
    });
  };

  const pictureCustomRequest = (options: any) => {
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append('file', file);
    Axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/v3/departments/${activeDepartmentId}/picture`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        const { url, status } = response.data;
        setFileList([
          {
            ...file,
            url,
            status,
          },
        ]);
        onSuccess(response, file);
      })
      .catch((error) => {
        message.error(t('FORMS.PICTURE_UPLOAD_ERROR'));
        onError(error);
        console.log(error);
      });
  };

  const onPictureRemove = async (file: any) => {
    return new Promise<boolean>((resolve, reject) => {
      Axios.delete(`${process.env.REACT_APP_API_URL}/v3/departments/${activeDepartmentId}/picture`)
        .then(() => {
          resolve(true);
          setFileList([]);
        })
        .catch((error) => {
          message.error(t('FORMS.PICTURE_DELETION_ERROR'));
          console.error(error);
          reject(false);
        });
    });
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings(values)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
    {
      title: t('SETTINGS.BILLING.ACCOUNT_DETAILS.TITLES.ADDRESS'),
      visible: true,
    },
    {
      title: t('SETTINGS.BILLING.ACCOUNT_DETAILS.TITLES.EXTRA'),
      visible: true,
    },
  ];

  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.COMPANY.LABEL'),
          name: ['department', 'company'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input placeholder={t('SETTINGS.BILLING.POINT_OF_SALE.DEPARTMENT_NAME')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
        {
          label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.CODE.LABEL'),
          name: ['department', 'shortcode'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input placeholder={t('GLOBAL.CODE')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
      ],
      [
        {
          label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.PHONE.LABEL'),
          name: ['department', 'landphone'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: (
            <PhoneInput
              containerClass="ant-form-item-control-input-content"
              inputClass="ant-input"
              inputStyle={{ width: 265 }}
            />
          ),
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.COUNTRY.LABEL'),
          name: ['department', 'country'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input disabled placeholder={t('GLOBAL.COUNTRY')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
        {
          label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.ADDRESS.LABEL'),
          name: ['department', 'address'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <PlaceAutocomplete onAddressChange={onAddressChange} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
        {
          label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.CITY.LABEL'),
          name: ['department', 'city'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input disabled placeholder={t('GLOBAL.CITY')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
        {
          label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.POSTAL_CODE.LABEL'),
          name: ['department', 'zip'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input disabled placeholder={t('GLOBAL.ZIP')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.LANGUAGE.LABEL'),
          name: ['department', 'lang'],
          style: { margin: 0, width: 265 },
          element: (
            <Select style={{ width: '100%' }} placeholder={t('FORMS.LANGUAGE')}>
              {currentSettings?.datas?.languages?.map((language) => (
                <Option key={`lang_${language.id}`} value={language.id}>
                  {language.name}
                </Option>
              ))}
            </Select>
          ),
          show: true,
        },
      ],
      [
        {
          label: t('SETTINGS.BILLING.ACCOUNT_DETAILS.PROFILE_PICTURE.LABEL'),
          style: { margin: 0 },
          element: (
            <ImgCrop>
              <Upload
                listType="picture-card"
                customRequest={pictureCustomRequest}
                onRemove={onPictureRemove}
                fileList={fileList}
                accept="image/*"
              >
                {fileList.length < 1 && <UploadOutlined />}
              </Upload>
            </ImgCrop>
          ),
          show: true,
        },
      ],
    ],
  ];

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.BILLING.POINT_OF_SALE.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(AccountDetails)`
  .react-tel-input .form-control {
    border: 1px solid ${Colors.blueLight};
    font-size: 12px;
    line-height: 1.5715;
    height: auto;
    border-radius: 3px;
    width: 210px;
  }
`;
