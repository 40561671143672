import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import { DIMONA_WORK_TYPES, IV4Dimona } from '@/types/dimona.model';
import { getWindowSize } from '@/utils';
import { Button, DatePicker, Drawer, Form, Input, Select, notification } from 'antd';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
  activeDimona: IV4Dimona | null;
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
}

const DrawerUpdateDimona: React.FC<Props> = ({ className, activeDimona, visible, onClose, onSave }) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const hasTime =
    activeDimona &&
    activeDimona.workerType === 'FLX' &&
    ((activeDimona.startHour && activeDimona.startHour !== '') ||
      (activeDimona.endHour && activeDimona.endHour !== ''));

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const {
    state: { loadingUsers, users },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });

  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...activeDimona,
      start_date: activeDimona?.startDate ? moment(activeDimona.startDate) : null,
      end_date: activeDimona?.endDate
        ? hasTime && activeDimona?.endHour !== ''
          ? moment(`${moment(activeDimona?.endDate).format('YYYY-MM-DD')} ${activeDimona?.endHour}`)
          : moment(activeDimona?.endDate)
        : null,
      user: {
        ...activeDimona?.user,
        record_id: activeDimona?.user.recordId,
      },
      worker_type: activeDimona?.workerType,
      planned_hours: activeDimona?.plannedHours,
    });
    // eslint-disable-next-line
  }, [activeDimona]);

  const onFinish = async (values: any) => {
    setIsSaving(true);

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/dimona/${activeDimona?.id}`,
        {
          plannedHoursNbr: values.planned_hours,
          endingDate: values?.end_date ? values?.end_date?.unix() : null,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        onSave();
        setIsSaving(false);
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
        notification.open({
          message: t('GLOBAL.AN_ERROR_OCCURRED'),
          description: t('DIMONA.UPDATE_DIMONA_ERROR'),
          type: 'error',
        });
      });
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={t('DIMONA.DRAWER_UPDATE.TITLE')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <ScrollToTop />
      {activeDimona && (
        <p>
          {t('DIMONA.N_DECLARATION')} : {activeDimona?.dimonaPeriodId}
          <br />
          {t('GLOBAL.CREATION_DATE')} : {moment(activeDimona.createdAt).format('L HH:mm')}
        </p>
      )}

      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item label={t('GLOBAL.USER')} name={['user', 'record_id']}>
          <Select
            allowClear={true}
            getPopupContainer={(trigger) => trigger}
            showSearch
            optionFilterProp="children"
            placeholder={t('FORMS.USER_PLACEHOLDER')}
            loading={loadingUsers}
            disabled={true}
          >
            {users
              ?.filter((x) => x.dimonaQualify)
              .map((user) => {
                const { recordId, displayName } = user;
                return (
                  <Option key={`user_${recordId}`} value={recordId!}>
                    {displayName}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item label={t('GLOBAL.WORKER_TYPE')} name="worker_type">
          <Select disabled={true} getPopupContainer={(trigger) => trigger} placeholder="Choisir un type de travailleur">
            {DIMONA_WORK_TYPES.map((workType) => {
              const { id } = workType;
              return (
                <Option key={`${id}`} value={id!}>
                  {t(`DIMONA.WORKTYPES.${id}`)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('GLOBAL.START_PERIOD')}
          name="start_date"
          style={{ display: 'inline-block', width: '236px', marginRight: '10px' }}
        >
          <DatePicker
            disabled={true}
            style={{ width: '100%' }}
            showTime={hasTime ? true : false}
            format={hasTime ? 'L HH:mm' : 'L'}
            onChange={(e) => {
              form.setFieldsValue({
                startingDate: e,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('GLOBAL.END_PERIOD')}
          name="end_date"
          style={{ display: 'inline-block', width: '236px' }}
          rules={[{ required: activeDimona?.workerType !== 'OTH' ? true : false }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            showTime={hasTime ? true : false}
            format={hasTime ? 'L HH:mm' : 'L'}
            onChange={(e) => {
              form.setFieldsValue({
                endingDate: e,
              });
            }}
          />
        </Form.Item>
        <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.worker_type !== curValues.worker_type}>
          {(form) => {
            const workerType = form.getFieldValue('worker_type');
            if (workerType === 'STU' || workerType == 'S17') {
              return (
                <Form.Item label={t('GLOBAL.HOURS_NUMBER')} name="planned_hours" rules={[{ required: true }]}>
                  <Input type="number" />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('DIMONA.CHANGE_DECLARATION')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerUpdateDimona;
