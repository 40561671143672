import { IScheduleModel } from '@/types/schedule-model.model';
import { IUserParams } from '@/types/user-params.model';
import { IUser } from '@/types/user.model';
import { Button, DatePicker, Form, Select, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
  sm: IScheduleModel;
  onApply: () => void;
}

const ApplyForm: React.FC<Props> = ({ className, sm, onApply }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
  } = useContext(AppContext);

  return (
    <div className={className}>
      <Form.Item name={[sm.id, 'id']} fieldKey="id" hidden></Form.Item>
      <Form.Item
        name={[sm.id, 'start']}
        fieldKey={'start'}
        rules={[{ required: true, message: '' }]}
        label={t('GLOBAL.FROM_DATE')}
      >
        <DatePicker
          className="date-picker"
          placeholder={t('GLOBAL.FROM_DATE')}
          format={'L'}
          // onSelect={(e) => onChangeDate(e, field.fieldKey, 'start')}
        />
      </Form.Item>
      <Form.Item name={[sm.id, 'end']} fieldKey={'end'} label={t('GLOBAL.TO_DATE')}>
        <DatePicker
          className="date-picker"
          placeholder={t('GLOBAL.TO_DATE')}
          format={'L'}
          // onSelect={(e) => onChangeDate(e, field.fieldKey, 'start')}
        />
      </Form.Item>
      <Form.Item
        name={[sm.id, 'generation']}
        fieldKey={'generation'}
        rules={[{ required: true, message: '' }]}
        label={t('GLOBAL.MODE')}
      >
        <Select style={{ width: '100%' }} placeholder={t('GLOBAL.MODE')}>
          <Select.Option value="MANUAL">{t('GLOBAL.MANUAL')}</Select.Option>
          <Select.Option value="AUTO">{t('GLOBAL.AUTOMATIC')}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item style={{ flex: 0 }}>
        <Tooltip overlay={<span>{t('SCHEDULE_MODELS.UNAPPLY')}</span>}>
          <Button type="primary" danger htmlType="button" onClick={onApply}>
            <i className="icon-trash-empty"></i>
          </Button>
        </Tooltip>
      </Form.Item>
    </div>
  );
};

export default styled(ApplyForm)`
  display: flex;
  margin: 25px 0;
  gap: 15px;
  align-items: flex-end;

  .ant-form-item {
    margin-bottom: 0px !important;
    flex: 1;
  }

  .date-picker {
    width: 100%;
  }

  .ant-form-item-explain {
    display: none;
  }
`;
