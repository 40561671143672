import colors from '@/styles/colors';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import { Button, Divider } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SuggestedApp from '../SuggestedApp';
import AppContext from '@/pages/app/context';

interface Props {
  className?: string;
  activeApp: IMarketplaceApp | null;
  setActiveApp: React.Dispatch<React.SetStateAction<IMarketplaceApp | null>>;
}

const Success: React.FC<Props> = ({ className, activeApp, setActiveApp }) => {
  const { t } = useTranslation();
  const {
    state: { marketplaceApps },
  } = useContext(AppContext);
  return (
    <div className={className}>
      <h4 className="title">
        {t(activeApp?.category == 'TRAINING' ? 'MARKETPLACE.SUCCESS.TRAINING.TITLE' : 'MARKETPLACE.SUCCESS.TITLE')} 🙂
      </h4>
      <p
        dangerouslySetInnerHTML={{
          __html: activeApp!.confirmation_popup,
        }}
      ></p>
      {activeApp?.suggested && (
        <div className="suggested">
          <Divider />
          <h2>{t('SETTINGS.MARKETPLACE.SUGGESTED_TITLE')}</h2>
          <div className="apps">
            {activeApp.suggested.split(',').map((suggested) => {
              const app = marketplaceApps.find((marketplaceApp) => marketplaceApp.id == suggested);
              if (!app || app.installed) return;
              return <SuggestedApp app={app} setActiveApp={setActiveApp} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default styled(Success)`
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;

  .title {
    font-weight: bold;
    font-size: 24px;
    color: ${colors.green};
  }

  .connected {
    font-weight: bold;
  }

  .suggested {
    .ant-divider {
      margin-top: 0;
    }

    .apps {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
`;
