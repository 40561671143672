import { WIDGETS } from '@/types/widget.model';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { capitalize } from '@/utils';
import colors from '@/styles/colors';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

interface IWeather {
  city: {
    name: string;
  };
  list: {
    dt: number;
    temp: {
      day: number;
    };
    weather: {
      id: number;
      description: string;
    }[];
  }[];
}
interface Props {
  className?: string;
  departmentId?: string;
}

const Weather: React.FC<Props> = ({ departmentId, className }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [weather, setWeather] = useState<IWeather | null>();
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    if (!departmentId) {
      return;
    }

    setIsLoading(true);
    setWeather(null);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/widgets/${WIDGETS.WEATHER}`, {
      params: {
        departmentId: departmentId,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        if (mounted) {
          setIsLoading(false);
          const { weather } = response.data;
          if (weather) {
            setWeather(response.data.weather);
          }
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [departmentId]);

  return (
    <div className={className}>
      <Spin spinning={isLoading}>
        <h4>
          {t(`DASHBOARD.WIDGETS.WEATHER`)} {weather?.city?.name}
        </h4>
        <div className="list">
          {weather?.list?.slice(0, 4).map((item, index) => {
            return (
              <div className="item" key={`weather_${index}`}>
                <i className={`owf owf-${item.weather[0]?.id}-d owf-3x`}></i>
                <span className="day">{capitalize(moment.unix(item.dt).format('dddd'))}</span>
                <span className="temperature">{Math.round((item.temp.day + Number.EPSILON) * 10) / 10}°</span>
                <span className="description">{capitalize(item.weather[0]?.description)}</span>
              </div>
            );
          })}
        </div>
      </Spin>
    </div>
  );
};

const WeatherStyled = styled(Weather)`
  .list {
    display: flex;
    flex-direction: row;
    width: 100%;

    .item {
      padding: 10px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      flex-basis: 0;

      i {
        padding-bottom: 10px;
        color: ${colors.green};
      }

      .temperature {
        font-size: 24px;
        font-weight: 700;
        color: #c4c4c4;
      }
      .description {
        color: #7f8894;
      }
    }
  }
`;

export default WeatherStyled;
