import TimePickerFormItem from '@/pages/app/components/TimePickerFormItem';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { handleError } from '@/utils';
import { Form, InputNumber, Spin } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const HrPayrollReports: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [data, setData] = useState<any>({});
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  useEffect(() => {
    if (!activeDepartment) {
      return;
    }
    setData({});
    setLoading(true);
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/settings/payroll`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        if (mounted) {
          const newData = data?.data || {};
          form.setFieldsValue({
            ...newData,
            extra_hours_slot1_start: newData.extra_hours_slot1_start
              ? decimalToTime(newData.extra_hours_slot1_start)
              : null,
            extra_hours_slot1_end: newData.extra_hours_slot1_end ? decimalToTime(newData.extra_hours_slot1_end) : null,
            extra_hours_slot2_start: newData.extra_hours_slot2_start
              ? decimalToTime(newData.extra_hours_slot2_start)
              : null,
            extra_hours_slot2_end: newData.extra_hours_slot2_end ? decimalToTime(newData.extra_hours_slot2_end) : null,
            additional_hours_slot2_percent: newData.additional_hours_slot2_percent
              ? decimalToTime(newData.additional_hours_slot2_percent)
              : null,
            night_hours_slot1_start: newData.night_hours_slot1_start
              ? decimalToTime(newData.night_hours_slot1_start)
              : null,
            night_hours_slot1_end: newData.night_hours_slot1_end ? decimalToTime(newData.night_hours_slot1_end) : null,
            night_hours_slot2_start: newData.night_hours_slot2_start
              ? decimalToTime(newData.night_hours_slot2_start)
              : null,
            night_hours_slot2_end: newData.night_hours_slot2_end ? decimalToTime(newData.night_hours_slot2_end) : null,
          });
          setData(newData || {});
          setLoading(false);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.log(error);
        }
        if (mounted) {
          setLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartment]);

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    form.setFieldsValue({
      ...data,
      extra_hours_slot1_start: data.extra_hours_slot1_start
        ? typeof data.extra_hours_slot1_start == 'number'
          ? decimalToTime(data.extra_hours_slot1_start)
          : data.extra_hours_slot1_start
        : null,
      extra_hours_slot1_end: data.extra_hours_slot1_end
        ? typeof data.extra_hours_slot1_end == 'number'
          ? decimalToTime(data.extra_hours_slot1_end)
          : data.extra_hours_slot1_end
        : null,
      extra_hours_slot2_start: data.extra_hours_slot2_start
        ? typeof data.extra_hours_slot2_start == 'number'
          ? decimalToTime(data.extra_hours_slot2_start)
          : data.extra_hours_slot2_start
        : null,
      extra_hours_slot2_end: data.extra_hours_slot2_end
        ? typeof data.extra_hours_slot2_end == 'number'
          ? decimalToTime(data.extra_hours_slot2_end)
          : data.extra_hours_slot2_end
        : null,
      additional_hours_slot2_percent: data.additional_hours_slot2_percent
        ? typeof data.additional_hours_slot2_percent == 'number'
          ? decimalToTime(data.additional_hours_slot2_percent)
          : data.additional_hours_slot2_percent
        : null,
      night_hours_slot1_start: data.night_hours_slot1_start
        ? typeof data.night_hours_slot1_start == 'number'
          ? decimalToTime(data.night_hours_slot1_start)
          : data.night_hours_slot1_start
        : null,
      night_hours_slot1_end: data.night_hours_slot1_end
        ? typeof data.night_hours_slot1_end == 'number'
          ? decimalToTime(data.night_hours_slot1_end)
          : data.night_hours_slot1_end
        : null,
      night_hours_slot2_start: data.night_hours_slot2_start
        ? typeof data.night_hours_slot2_start == 'number'
          ? decimalToTime(data.night_hours_slot2_start)
          : data.night_hours_slot2_start
        : null,
      night_hours_slot2_end: data.night_hours_slot2_end
        ? typeof data.night_hours_slot2_end == 'number'
          ? decimalToTime(data.night_hours_slot2_end)
          : data.night_hours_slot2_end
        : null,
    });
  };

  const onFinish = (values: any) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/settings/payroll`,
        {
          data: {
            ...values,
            extra_hours_slot1_start: values.extra_hours_slot1_start
              ? timeToDecimal(values.extra_hours_slot1_start)
              : null,
            extra_hours_slot1_end: values.extra_hours_slot1_end ? timeToDecimal(values.extra_hours_slot1_end) : null,
            extra_hours_slot2_start: values.extra_hours_slot2_start
              ? timeToDecimal(values.extra_hours_slot2_start)
              : null,
            extra_hours_slot2_end: values.extra_hours_slot2_end ? timeToDecimal(values.extra_hours_slot2_end) : null,
            additional_hours_slot2_percent: values.additional_hours_slot2_percent
              ? timeToDecimal(values.additional_hours_slot2_percent)
              : null,
            night_hours_slot1_start: values.night_hours_slot1_start
              ? timeToDecimal(values.night_hours_slot1_start)
              : null,
            night_hours_slot1_end: values.night_hours_slot1_end ? timeToDecimal(values.night_hours_slot1_end) : null,
            night_hours_slot2_start: values.night_hours_slot2_start
              ? timeToDecimal(values.night_hours_slot2_start)
              : null,
            night_hours_slot2_end: values.night_hours_slot2_end ? timeToDecimal(values.night_hours_slot2_end) : null,
          },
        },
        {
          params: {
            departmentId: activeDepartment?.id,
          },
        },
      )
      .then((response) => {
        const newData = response.data?.data || {};
        const data = {
          ...newData,
          extra_hours_slot1_start: newData.extra_hours_slot1_start
            ? decimalToTime(newData.extra_hours_slot1_start)
            : null,
          extra_hours_slot1_end: newData.extra_hours_slot1_end ? decimalToTime(newData.extra_hours_slot1_end) : null,
          extra_hours_slot2_start: newData.extra_hours_slot2_start
            ? decimalToTime(newData.extra_hours_slot2_start)
            : null,
          extra_hours_slot2_end: newData.extra_hours_slot2_end ? decimalToTime(newData.extra_hours_slot2_end) : null,
          additional_hours_slot2_percent: newData.additional_hours_slot2_percent
            ? decimalToTime(newData.additional_hours_slot2_percent)
            : null,
          night_hours_slot1_start: newData.night_hours_slot1_start
            ? decimalToTime(newData.night_hours_slot1_start)
            : null,
          night_hours_slot1_end: newData.night_hours_slot1_end ? decimalToTime(newData.night_hours_slot1_end) : null,
          night_hours_slot2_start: newData.night_hours_slot2_start
            ? decimalToTime(newData.night_hours_slot2_start)
            : null,
          night_hours_slot2_end: newData.night_hours_slot2_end ? decimalToTime(newData.night_hours_slot2_end) : null,
        };
        updateCurrentSettings(data)
          .then(() => {})
          .catch((error) => {
            handleError(error);
          });
        setData(data || {});
      })
      .catch((error) => {
        handleError(error);
      });
  };

  if (loading) {
    return <Spin spinning={true} />;
  }

  const elements = [
    {
      title: t('SETTINGS.ACCOUNT.PAYROLL.TITLES.EXTRA_HOURS_1'),
      visible: true,
    },
    {
      title: t('SETTINGS.ACCOUNT.PAYROLL.TITLES.EXTRA_HOURS_2'),
      visible: true,
    },
    {
      title: t('SETTINGS.ACCOUNT.PAYROLL.TITLES.NIGHT_HOURS'),
      visible: true,
    },
    {
      title: t('SETTINGS.ACCOUNT.PAYROLL.TITLES.PUBLIC_HOLIDAYS_HOURS'),
      visible: true,
    },
  ];

  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.EXTRA_HOURS_SLOT_1'),
          description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.EXTRA_HOURS_SLOT_1'),
          style: { margin: 0 },
          element: (
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item name={['extra_hours_slot1_start']} style={{ margin: 0 }}>
                  <TimePickerFormItem
                    rules={[{ message: '' }]}
                    name="extra_hours_slot1_start"
                    style={{ width: 100, margin: 0 }}
                    show24
                  />
                </Form.Item>
                <span style={{ marginLeft: 15, marginRight: 15, textTransform: 'lowercase' }}>{t('GLOBAL.AND')}</span>
                <Form.Item name={['extra_hours_slot1_end']} style={{ margin: 0 }}>
                  <TimePickerFormItem
                    rules={[{ message: '' }]}
                    name="extra_hours_slot1_end"
                    style={{ width: 100, margin: 0 }}
                    show24
                  />
                </Form.Item>
              </div>
            </div>
          ),
          elementAddon: (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25 }}>
              <span>{t('SETTINGS.ACCOUNT.PAYROLL.MAJORITY')}</span>
              <Form.Item name={['extra_hours_slot1_increase']} style={{ margin: 0, marginLeft: 15 }}>
                <InputNumber
                  style={{ width: 100 }}
                  type="number"
                  min={0}
                  step={1}
                  parser={(value) => {
                    return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                  }}
                  addonAfter={'%'}
                />
              </Form.Item>
            </div>
          ),
          show: true,
        },
      ],
      [
        {
          label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.EXTRA_HOURS_SLOT_2'),
          description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.EXTRA_HOURS_SLOT_2'),
          style: { margin: 0 },
          element: (
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item name={['extra_hours_slot2_start']} style={{ margin: 0 }}>
                  <TimePickerFormItem
                    rules={[{ message: '' }]}
                    name="extra_hours_slot2_start"
                    style={{ width: 100, margin: 0 }}
                    show24
                  />
                </Form.Item>
                <span style={{ marginLeft: 15, marginRight: 15, textTransform: 'lowercase' }}>{t('GLOBAL.AND')}</span>
                <Form.Item name={['extra_hours_slot2_end']} style={{ margin: 0 }}>
                  <TimePickerFormItem
                    rules={[{ message: '' }]}
                    name="extra_hours_slot2_end"
                    style={{ width: 100, margin: 0 }}
                    show24
                  />
                </Form.Item>
              </div>
            </div>
          ),
          elementAddon: (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25 }}>
              <span>{t('SETTINGS.ACCOUNT.PAYROLL.MAJORITY')}</span>
              <Form.Item name={['extra_hours_slot2_increase']} style={{ margin: 0, marginLeft: 15 }}>
                <InputNumber
                  style={{ width: 100 }}
                  type="number"
                  min={0}
                  step={1}
                  parser={(value) => {
                    return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                  }}
                  addonAfter={'%'}
                />
              </Form.Item>
            </div>
          ),
          show: true,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.EXTRA_HOURS_SLOT_3'),
          description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.EXTRA_HOURS_SLOT_3'),
          style: { margin: 0 },
          element: (
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item name={['additional_hours_slot1_percent']} style={{ margin: 0 }}>
                  <InputNumber style={{ width: 100 }} type="number" min={1} step={1} addonAfter={'%'} />
                </Form.Item>
              </div>
            </div>
          ),
          elementAddon: (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25 }}>
              <span>{t('SETTINGS.ACCOUNT.PAYROLL.MAJORITY')}</span>
              <Form.Item name={['additional_hours_slot1_increase']} style={{ margin: 0, marginLeft: 15 }}>
                <InputNumber
                  style={{ width: 100 }}
                  type="number"
                  min={0}
                  step={1}
                  parser={(value) => {
                    return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                  }}
                  addonAfter={'%'}
                />
              </Form.Item>
            </div>
          ),
          show: true,
        },
      ],
      [
        {
          label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.EXTRA_HOURS_SLOT_4'),
          description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.EXTRA_HOURS_SLOT_4'),
          style: { margin: 0 },
          element: (
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item name={['additional_hours_slot2_percent']} style={{ margin: 0 }}>
                  <TimePickerFormItem
                    rules={[{ message: '' }]}
                    name="additional_hours_slot2_percent"
                    style={{ width: 100, margin: 0 }}
                    show24
                  />
                </Form.Item>
              </div>
            </div>
          ),
          elementAddon: (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25 }}>
              <span>{t('SETTINGS.ACCOUNT.PAYROLL.MAJORITY')}</span>
              <Form.Item name={['additional_hours_slot2_increase']} style={{ margin: 0, marginLeft: 15 }}>
                <InputNumber
                  style={{ width: 100 }}
                  type="number"
                  min={0}
                  step={1}
                  parser={(value) => {
                    return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                  }}
                  addonAfter={'%'}
                />
              </Form.Item>
            </div>
          ),
          show: true,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.NIGHT_HOURS_SLOT_1'),
          description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.NIGHT_HOURS_SLOT_1'),
          style: { margin: 0 },
          element: (
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item name={['night_hours_slot1_start']} style={{ margin: 0 }}>
                  <TimePickerFormItem
                    rules={[{ message: '' }]}
                    name="night_hours_slot1_start"
                    style={{ width: 100, margin: 0 }}
                    show24
                  />
                </Form.Item>
                <span style={{ marginLeft: 25, marginRight: 15, textTransform: 'lowercase' }}>{t('GLOBAL.AND')}</span>
                <Form.Item name={['night_hours_slot1_end']} style={{ margin: 0 }}>
                  <TimePickerFormItem
                    rules={[{ message: '' }]}
                    name="night_hours_slot1_end"
                    style={{ width: 100, margin: 0 }}
                    show24
                  />
                </Form.Item>
              </div>
            </div>
          ),
          elementAddon: (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25 }}>
              <span>{t('SETTINGS.ACCOUNT.PAYROLL.MAJORITY')}</span>
              <Form.Item name={['night_hours_slot1_increase']} style={{ margin: 0, marginLeft: 15 }}>
                <InputNumber
                  style={{ width: 100 }}
                  type="number"
                  min={0}
                  step={1}
                  parser={(value) => {
                    return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                  }}
                  addonAfter={'%'}
                />
              </Form.Item>
            </div>
          ),
          show: true,
        },
      ],
      [
        {
          label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.NIGHT_HOURS_SLOT_2'),
          description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.NIGHT_HOURS_SLOT_2'),
          style: { margin: 0 },
          element: (
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item name={['night_hours_slot2_start']} style={{ margin: 0 }}>
                  <TimePickerFormItem
                    rules={[{ message: '' }]}
                    name="night_hours_slot2_start"
                    style={{ width: 100, margin: 0 }}
                    show24
                  />
                </Form.Item>
                <span style={{ marginLeft: 15, marginRight: 15, textTransform: 'lowercase' }}>{t('GLOBAL.AND')}</span>
                <Form.Item name={['night_hours_slot2_end']} style={{ margin: 0 }}>
                  <TimePickerFormItem
                    rules={[{ message: '' }]}
                    name="night_hours_slot2_end"
                    style={{ width: 100, margin: 0 }}
                    show24
                  />
                </Form.Item>
              </div>
            </div>
          ),
          elementAddon: (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25 }}>
              <span>{t('SETTINGS.ACCOUNT.PAYROLL.MAJORITY')}</span>
              <Form.Item name={['night_hours_slot2_increase']} style={{ margin: 0, marginLeft: 15 }}>
                <InputNumber
                  style={{ width: 100 }}
                  type="number"
                  min={0}
                  step={1}
                  parser={(value) => {
                    return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                  }}
                  addonAfter={'%'}
                />
              </Form.Item>
            </div>
          ),
          show: true,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.PUBLIC_HOLIDAYS_HOURS_SLOT_1'),
          description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.PUBLIC_HOLIDAYS_HOURS_SLOT_1'),
          style: { margin: 0 },
          element: (
            <div style={{ display: 'flex', marginTop: 15 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{t('SETTINGS.ACCOUNT.PAYROLL.MAJORITY')}</span>
                <Form.Item name={['public_holidays_generic_increase']} style={{ margin: 0, marginLeft: 15 }}>
                  <InputNumber
                    style={{ width: 100 }}
                    type="number"
                    min={0}
                    step={1}
                    parser={(value) => {
                      return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                    }}
                    addonAfter={'%'}
                  />
                </Form.Item>
              </div>
            </div>
          ),
          show: true,
          width: '75%',
        },
      ],
      [
        {
          label: t('SETTINGS.ACCOUNT.PAYROLL.NAMES.PUBLIC_HOLIDAYS_HOURS_SLOT_2'),
          description: t('SETTINGS.ACCOUNT.PAYROLL.DESCRIPTIONS.PUBLIC_HOLIDAYS_HOURS_SLOT_2'),
          style: { margin: 0 },
          element: (
            <div style={{ display: 'flex', marginTop: 15 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{t('SETTINGS.ACCOUNT.PAYROLL.MAJORITY')}</span>
                <Form.Item name={['public_holidays_first_may_increase']} style={{ margin: 0, marginLeft: 15 }}>
                  <InputNumber
                    style={{ width: 100 }}
                    type="number"
                    min={0}
                    step={1}
                    parser={(value) => {
                      return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                    }}
                    addonAfter={'%'}
                  />
                </Form.Item>
              </div>
            </div>
          ),
          show: true,
          width: '75%',
        },
      ],
    ],
  ];

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  return (
    <div className={className}>
      <h2>{t('REPORTS.PAY_PERIODS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(HrPayrollReports)`
  .ant-row {
    margin-bottom: 20px;
  }

  .ant-col.center {
    display: flex;
    align-items: center;
    justify-content: center;
    .form-items-group.one-line .ant-row {
      margin-bottom: 0;
    }
  }

  .ant-col p {
    color: ${colors.grey};
    font-size: 12px;
  }

  @media (max-width: 992px) {
    .ant-col.center {
      display: block;
    }
  }
`;

function timeToDecimal(time: string) {
  // Split the input time string into hours and minutes
  const [hours, minutes] = time.split(':').map(Number);

  // Convert the minutes to a decimal representation
  const decimalMinutes = (minutes / 60).toFixed(2).split('.')[1];

  // Combine hours and decimal minutes
  const decimalTime = `${hours}.${decimalMinutes}`;

  // Convert to number for final result
  return parseFloat(decimalTime);
}

function decimalToTime(decimal: number) {
  // Extract the integer part for hours
  const hours = Math.floor(decimal);

  // Calculate the minutes by subtracting the integer part and converting to minutes
  const minutes = Math.round((decimal - hours) * 60);

  // Format the hours and minutes to ensure they are two digits
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Combine hours and minutes to the final time string
  return `${formattedHours}:${formattedMinutes}`;
}
