import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Form, Input, Button } from 'antd';
import axios from 'axios';
import { ITimesheetView } from '@/types/timesheet-view.model';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  name?: string;
  visible: boolean;
  departmentId?: string;
  params: { [key: string]: string[] };
  hideColumns: { [key: string]: boolean };
  onClose: () => void;
  onSave: (result: ITimesheetView[]) => void;
}

const ModalSaveView: React.FC<Props> = ({ name, departmentId, visible, params, hideColumns, onSave, onClose }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      name,
    });
  }, [visible]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/reports/custom-timesheets`,
        {
          name: values.name,
          ...params,
          hideColumns,
        },
        {
          params: {
            departmentId,
          },
        },
      )
      .then((response) => {
        setIsSaving(false);
        onSave(response.data);
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  return (
    <Modal
      visible={visible}
      onOk={() => form.submit()}
      onCancel={onClose}
      title={t('REPORTS.HR_TIMESHEETS.MODAL_SAVE_VIEW.TITLE')}
      getContainer={false}
      forceRender
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
          {t('GLOBAL.CREATE')}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="name" label={t('GLOBAL.NAME')} required rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalSaveView)``;
