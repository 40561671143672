import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IShift } from '@/types/shift.model';
import { IUserWithInfos } from '@/types/user.model';
import { minutesToHours } from '@/utils';
import { Button, Form, message, Modal, Select } from 'antd';
import axios from 'axios';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType, updateShiftAndContext } from '../redux/actions';
import { InitialStateType } from '../redux/store';

interface Props {
  className?: string;
  visible: boolean;
  onClose: () => void;
}

const ModalAssignShiftTemplate: React.FC<Props> = ({ className, visible, onClose }) => {
  const { state: appContextState } = useContext(AppContext);
  const { activeSection, activeDepartmentId, activeDepartment, skills, userCategories } = appContextState;
  const [form] = Form.useForm();
  const { t } = useTranslation(undefined, { useSuspense: false });

  const [users, setUsers] = useState<IUserWithInfos[]>([]);
  const [userId, setUserId] = useState<string>('');
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: Dispatch<ActionType> = useDispatch();

  const { selectedShift, startDate, endDate, picker } = useSelector(
    ({ selectedShift, startDate, endDate, picker }: InitialStateType) => ({
      selectedShift,
      startDate,
      endDate,
      picker,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!visible) {
      return;
    }
    form.resetFields();
    let mounted = true;
    setUsers([]);
    setLoadingUsers(false);
    const cancelTokenSource = axios.CancelToken.source();
    if (mounted) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/operations/get-users`, {
          params: {
            departmentId: activeDepartmentId,
            shiftId: selectedShift?.id,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then(({ data }) => {
          setUsers(data);
        })
        .catch((error) => {
          setLoadingUsers(false);
          console.log(error);
        });
    }
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [visible]);

  const onFinishUpdate = () => {
    const user = users.find((user) => user.recordId == userId);
    message.success(
      t('SHIFTS.ASSIGN.SUCCESS', {
        displayName: user?.displayName,
      }),
    );
    setLoading(false);
    onClose();
  };

  const onFinish = () => {
    setLoading(true);
    const values: IShift = {
      id: selectedShift?.id,
      start: selectedShift?.start,
      end: selectedShift?.end,
    };

    updateShiftAndContext(
      dispatch,
      values,
      values,
      {
        picker,
        startDate,
        endDate: endDate.unix(),
        departmentId: activeDepartmentId,
        sectionId: activeSection,
        userRecordId: userId,
        department: activeDepartment,
        activeSection,
        skills,
        userStatus: userCategories,
      },
      onFinishUpdate,
    );
  };

  return (
    <Modal
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={visible}
      onOk={() => form.submit()}
      onCancel={onClose}
      title={t('SHIFTS.ASSIGN.MODAL.TITLE')}
      footer={null}
      className={className}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="user_id" label={t('GLOBAL.COLLABORATOR')}>
          <Select
            loading={loadingUsers}
            clearIcon
            getPopupContainer={(trigger) => trigger}
            showSearch
            placeholder={t('SHIFTS.ASSIGN.MODAL.PLACEHOLDER')}
            optionFilterProp="label"
            onChange={(value) => setUserId(value as string)}
            filterOption={(input, option) => option?.label?.toString().toLocaleLowerCase().includes(input) as boolean}
          >
            {users?.map((user) => (
              <Select.Option
                key={`user_${user.recordId}`}
                value={`${user.recordId}`}
                label={user.displayName}
                disabled={
                  !!user?.constraints?.message?.length &&
                  user?.constraints?.message?.some((c) => c.type === 'not_available')
                }
              >
                <div className="select-user-option">
                  <div className="displayName">{user.displayName}</div>
                  <div className="infos">
                    {!!user?.constraints?.message?.length && (
                      <span>{t(`SCHEDULE.CONSTRAINTS.${user?.constraints?.message[0]?.title}_SHORT`)}</span>
                    )}
                    {(!!user.totalWeekMinutes || !!user.maxWeekMinutes) && (
                      <span
                        style={{
                          backgroundColor:
                            user.maxWeekMinutes && user.totalWeekMinutes && user.totalWeekMinutes > user.maxWeekMinutes
                              ? colors.red
                              : colors.green,
                        }}
                      >
                        {minutesToHours(user.totalWeekMinutes || 0)}
                        {user.maxWeekMinutes ? `/${minutesToHours(user.maxWeekMinutes)}` : ''}h
                      </span>
                    )}
                    {(!!user.totalTrimesterMinutes || !!user.maxTrimesterMinutes) && (
                      <span
                        style={{
                          backgroundColor:
                            user.maxTrimesterMinutes &&
                            user.totalTrimesterMinutes &&
                            user.totalTrimesterMinutes > user.maxTrimesterMinutes
                              ? colors.red
                              : colors.green,
                        }}
                      >
                        {minutesToHours(user.totalTrimesterMinutes || 0)}
                        {user.maxTrimesterMinutes ? `/${user.maxTrimesterMinutes}` : ''}m
                      </span>
                    )}
                  </div>
                </div>
              </Select.Option>
            ))}
          </Select>
          <Button loading={loading} type="primary" htmlType="submit" className="actions_btn assign_btn">
            {t('GLOBAL.ASSIGN')}
          </Button>
          <Button key="back" onClick={onClose} className="actions_btn" style={{ marginRight: 5 }}>
            {t('GLOBAL.CANCEL')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalAssignShiftTemplate)`
  .recurrence-container {
    display: inline-block;
    background-color: ${colors.greyLight};
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;

    .anticon-close {
      background: ${colors.red};
      padding: 1px;
      border-radius: 10px;
      margin-left: 5px;
      svg {
        transform: scale(0.8);
        fill: white;
      }
    }
  }

  .hidden {
    display: none;
  }

  .actions_btn {
    margin-top: 15px;
    float: right;
  }

  .ant-form-item-explain {
    display: none;
  }

  .form-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-select-item.ant-select-item-option.not-compatible:not(.ant-select-item-option-selected) {
    background: ${colors.greyLight};
    color: ${colors.grey};
  }

  .form-item-should-update {
    .ant-form-item-control-input {
      min-height: 0;
    }

    .ant-row.ant-form-item.min-height {
      .ant-form-item-control-input {
        min-height: 40px;
      }
    }
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
    .ant-select-item-option-content {
      .select-user-option {
        .infos  {
          opacity: 0.8;
        }
      }
    }
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    .ant-select-item-option-content {
      .select-user-option {
        .infos  {
          opacity: 1;
        }
      }
    }
  }
  .ant-select-item-option-content .select-user-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .displayName {
    }
    .infos {
      opacity: 0.3;
      transition: opacity 0.3s;
      span {
        background-color: ${colors.bluePastel};
        color: white;
        border-radius: 3px;
        margin-left: 4px;
        padding: 1px 5px;
      }
    }
  }
`;
