import ShiftAttributes from '@/pages/app/hours/manage/components/ShiftAttributes';
import ShiftDetailsIcons from '@/pages/app/hours/manage/components/ShiftDetailsIcons';
import ShiftPeriod from '@/pages/app/hours/manage/components/ShiftPeriod';
import ShiftSectionBar from '@/pages/app/hours/manage/components/ShiftSectionBar';
import ShiftSpecialHrCodeStyled from '@/pages/app/hours/manage/components/ShiftSpecialHrCode';
import ShiftTags from '@/pages/app/hours/manage/components/ShiftTags';
import TopIcons from '@/pages/app/hours/manage/components/TopIcons';
import ShiftTasks from '@/pages/app/hours/manage/daily/components/ShiftTasks';
import { default as Colors, default as colors } from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { Divider } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import React, { Dispatch, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../../redux/actions';
import { ITypicalWeekShift, InitialStateType } from '../../redux/store';
import ShiftRightClick from './ShiftRightClick';

interface Props {
  className?: string;
  showComment?: boolean;
  onDragStartHandler: (e: React.DragEvent, shiftId: string) => void;
  onDragEndHandler: (e: React.DragEvent) => void;
  shift: ITypicalWeekShift;
  activeDepartment?: IDepartment;
  activeSection?: string;
  weekId: string;
}

const Shift: React.FC<Props> = ({
  className,
  showComment,
  shift,
  activeDepartment,
  activeSection,
  weekId,
  onDragStartHandler,
  onDragEndHandler,
}) => {
  const { selectedShifts } = useSelector(
    ({ selectedShifts }: InitialStateType) => ({
      selectedShifts,
    }),
    shallowEqual,
  );

  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const { start: shiftStart, end: shiftEnd, price, pause } = shift;
  const duration = moment.duration(shiftEnd! - shiftStart!, 'seconds');
  duration.subtract(pause?.unpaid || 0, 'seconds');
  const durationInMinutes = duration.asMinutes();
  const pauseDurationInMinutes = moment.duration((pause?.paid || 0) + (pause?.unpaid || 0), 'seconds').asMinutes();

  const [selected, setSelected] = useState<boolean>(false);

  const hr_code_id =
    activeDepartment?.hr_codes && activeDepartment?.hr_codes.find((code) => code.id === shift?.hr_code)?.code;

  useEffect(() => {
    if (selectedShifts.has(shift.id!)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedShifts]);

  const onClick = (e: React.MouseEvent) => {
    hoursDispatch({
      type: 'SET_SELECTED_SHIFT',
      payload: {
        ...shift,
        weekId,
      },
    });
    hoursDispatch({
      type: 'SET_SHIFT_DRAWER_VISIBLE',
      payload: true,
    });
  };

  const style = shift.package
    ? { backgroundColor: shift.package.background, color: shift.package.color, fontWeight: 800 }
    : {};

  return (
    <div
      className={`
        ${className}
        ${shift.optimistic ? 'optimistic' : ''}
        ${shift.userRecordId ? '' : 'open-shift'}
        ${shift.dayoff ? 'dayoff' : ''}
        ${shift.approved ? '' : 'not-approved'}
        ${shift.request ? 'request' : ''}
        ${
          activeDepartment?.scheduleParams?.ghost_shifts && activeSection && activeSection !== shift.section?.id
            ? 'other-section'
            : ''
        }
      `}
      draggable="true"
      onDragStart={(e) => onDragStartHandler(e, shift.id!)}
      onDragEnd={onDragEndHandler}
      onClick={onClick}
      style={style}
    >
      <ShiftRightClick shift={shift}>
        <div className="card">
          {activeSection !== shift.section?.id && <ShiftSectionBar section={shift.section} />}
          {/* <Checkbox className="no-print" checked={selected} onClick={checkboxOnClick} /> */}
          {!shift.dayoff && <TopIcons shift={shift} department={activeDepartment!} />}
          {!shift.dayoff && !shift.package && (
            <React.Fragment>
              <ShiftPeriod start={shiftStart!} end={shiftEnd!} />
              <ShiftTasks tasks={shift.tasks || []} shiftId={shift.id!} />
              <div className="shift-details">
                <ShiftDetailsIcons
                  hidePrice={!activeDepartment?.scheduleParams?.showShiftPrice}
                  currency={activeDepartment?.currency}
                  durationInMinutes={durationInMinutes}
                  pauseDurationInMinutes={pauseDurationInMinutes}
                  price={price!}
                />
              </div>
              <ShiftTags
                picker={'week'}
                section={activeSection !== shift.section?.id ? shift.section : undefined}
                skills={shift.skills || []}
                schedule
              />
              <ShiftAttributes attributes={shift.attributes || []} />
            </React.Fragment>
          )}
          {!shift.dayoff && shift.package && (
            <div>
              <div>{shift.package.name}</div>
              <ShiftTasks tasks={shift.tasks || []} shiftId={shift.id!} />
              <ShiftTags
                picker="week"
                section={activeSection !== shift.section?.id ? shift.section : undefined}
                skills={shift.skills || []}
                schedule
              />
              <ShiftAttributes attributes={shift.attributes || []} />
            </div>
          )}
          {shift.dayoff && (
            <React.Fragment>
              <h3>
                <span className="smaller">{shift.dayoff!.shortCode}</span>
                {shift.dayoff.paid ? (
                  <span className="devise">{getSymbolFromCurrency(`${activeDepartment?.currency}`)}</span>
                ) : (
                  ''
                )}
              </h3>
              <p className="dayoff-label">{shift.dayoff!.name}</p>

              <div className="shift-details" style={{ display: 'flex', flexWrap: 'wrap', whiteSpace: 'nowrap' }}>
                <ShiftDetailsIcons
                  hidePrice={!activeDepartment?.scheduleParams?.showShiftPrice}
                  durationInMinutes={durationInMinutes}
                  pauseDurationInMinutes={pauseDurationInMinutes}
                  price={price!}
                  currency={activeDepartment?.currency}
                />
                {!shift.dayoff!.fullDay && (
                  <React.Fragment>
                    <ShiftPeriod size="small" start={shiftStart!} end={shiftEnd!} />
                  </React.Fragment>
                )}
              </div>
              <ShiftTags picker="week" section={shift.section} skills={shift.skills || []} />
            </React.Fragment>
          )}
          <ShiftSpecialHrCodeStyled hr_code_id={hr_code_id} />
          {!shift.dayoff && showComment && activeDepartment?.scheduleParams?.showComment && shift.comment && (
            <div className="comment">
              <Divider plain />
              {shift.comment}
            </div>
          )}
          {(shift.count || 1) > 1 && <div className="count">{shift.count}</div>}
        </div>
      </ShiftRightClick>
    </div>
  );
};

export default styled(React.memo(Shift))`
  position: relative;
  background: #f7f9ff;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin: 2px 0;
  text-align: left;
  color: ${Colors.blue};
  cursor: grab;
  flex: 1;
  box-sizing: border-box;

  @media print {
    font-size-adjust: 0.5;
  }

  &.open-shift {
    background-color: white;
    border: 1px solid ${Colors.greenLight};
  }

  &.month {
    width: 180px;
  }

  &.optimistic {
    pointer-events: none;
    animation: blink 1s infinite alternate-reverse;
  }

  &.other-section {
    opacity: 0.15;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }

  &.request {
    background-color: #B3E6EF;
  }

  &.dayoff {
    background-image: repeating-linear-gradient(-55deg,
      transparent,
      transparent 10px,
      rgba(0,0,0,0.03) 10px,
      rgba(0,0,0,0.03) 20px);

    background-image: repeating-linear-gradient(-55deg, 
      transparent 0 10px, 
      rgba(0,0,0,0.03) 10px 20px);
      
    @media print {
      background-image: none;
      background-color: antiquewhite;
    }
  }
  
}

  .shift-details {
    font-size: 10px;
    color: gray;
    font-weight: 100 !important;
  }

  .ant-checkbox-wrapper {
    right: 7px;
    top: 7px;
    position: absolute;

    &.ant-checkbox-wrapper-checked:hover {
      border: none !important;
    }

    
    .ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after, .ant-checkbox-checked::after {
      visibility: hidden !important;
      border: none !important;
    }
    .ant-checkbox {
      padding: 3px;
    }
  }

  .section {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    border-right: 5px solid black;
    pointer-events: none;
  }

  .card {
    height: 100%;
    h3 {
      font-weight: 600;
      color: black;
      font-size: 22px;
      margin: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      .smaller {
        font-size: 16px;
      }

      .devise {
        margin-left: 5px;
        background-color: ${colors.blue};
        font-size: 12px;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
      }
    }

    p {
      margin-bottom: 4px;
      &.dayoff-label {
      color: ${Colors.grey};
      white-space: nowrap;
      overflow: hidden;
    }

    > div {
      &:nth-of-type(n + 2) {
        margin-top: 2px;
        span {
          display: inline-block;
          &:nth-of-type(n - 1) {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .comment {
    color: #444444;
    font-size: 12px;
    line-height: 16px;
    .ant-divider-horizontal {
      margin: 10px 0 8px;
    }
  }

  .count {
    background: #d0d3dd;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    float: right;
    color: #66676a;
    font-size: 11px;
    font-weight: bold;
    padding: 0px 12px;
    margin-top: 5px;
    border: 1px solid white;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.6;
    }
  }

  p {
    font-size: 13px;
    font-weight: normal;
  }
`;
