import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IPendingRegistration } from '@/types/users/pending-registration';
import { Button, Form, Input, List, message, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  pendingRegistrations: IPendingRegistration[];
  setPendingRegistrations: React.Dispatch<React.SetStateAction<IPendingRegistration[]>>;
  activeRegistration: IPendingRegistration;
  setActiveRegistration: React.Dispatch<React.SetStateAction<IPendingRegistration | null>>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalUpdateEmail: React.FC<Props> = ({
  className,
  pendingRegistrations,
  setPendingRegistrations,
  activeRegistration,
  setActiveRegistration,
  visible,
  setVisible,
}) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    let timeout: any;

    if (errors.length > 0) {
      timeout = setTimeout(() => {
        setErrors([]);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [errors]);

  const onCancel = () => {
    setVisible(false);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/users/pending/${activeRegistration.hash}`,
        {
          email: values.email,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
      .then(({ data }) => {
        message.success(t('USERS.PENDING_REGISTRATIONS.SUCCESSFULLY_UPDATED'));
        setPendingRegistrations(
          pendingRegistrations.filter((registration) => registration.registration_id !== data.registration_id),
        );
        setActiveRegistration(null);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.data?.errors && err.response.status === 422) {
          setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
        } else if (err.response.status === 403) {
          message.error(t('USERS.PENDING_REGISTRATIONS.ALREADY_COMPLETED'));
        }
        setLoading(false);
      });
  };

  return (
    <Modal
      className={className}
      visible={visible}
      footer={null}
      onCancel={onCancel}
      title={t('SELF_SERVICE.ACTIONS.UPDATE_EMAIL')}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="email" label={t('GLOBAL.EMAIL')} rules={[{ required: true }]}>
          <Input type="email" placeholder={t('GLOBAL.EMAIL')} size="large" />
        </Form.Item>
        {errors.length > 0 && (
          <List
            dataSource={errors}
            renderItem={(item) => (
              <List.Item
                style={{ paddingTop: errors.length === 1 ? 0 : 10, paddingBottom: errors.length === 1 ? 0 : 10 }}
              >
                <p style={{ margin: 0, color: colors.red }}>{item}</p>
              </List.Item>
            )}
          />
        )}
        <Form.Item style={{ textAlign: 'right' }}>
          <Button loading={loading} disabled={loading} type="primary" htmlType="submit" style={{ marginRight: '10px' }}>
            {t('GLOBAL.SAVE')}
          </Button>
          <Button type="default" className="grey" onClick={onCancel}>
            {t('GLOBAL.CANCEL')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalUpdateEmail;
