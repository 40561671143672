import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Button, Form, Modal, Select, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import { handleError } from '@/utils';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalManageNotificationsForAllUsers: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const onCancel = () => {
    setVisible(false);
  };

  const onChangeStatus = (status: 'active' | 'inactive') => {
    const channels = form.getFieldValue('notifications');
    if (!channels) return;

    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/settings/notifiations/channels-state/${status}`,
        {
          channels,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        message.success(t('SETTINGS.ACCOUNT.NOTIFICATIONS.MANAGE_ALL_USERS_NOTIFICATIONS_MODAL.SUCCESS_MESSAGE'));
        setVisible(false);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      visible={visible}
      maskClosable
      onCancel={onCancel}
      className={`${className} modal-constraints`}
      title={t('SETTINGS.ACCOUNT.NOTIFICATIONS.MANAGE_ALL_USERS_NOTIFICATIONS_MODAL.TITLE')}
      footer={null}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="notifications"
          label={t('SETTINGS.ACCOUNT.NOTIFICATIONS.MANAGE_ALL_USERS_NOTIFICATIONS_MODAL.NOTIFICATION_METHODS')}
          rules={[{ required: true }]}
        >
          <Select
            mode="multiple"
            placeholder={t('SETTINGS.ACCOUNT.NOTIFICATIONS.MANAGE_ALL_USERS_NOTIFICATIONS_MODAL.NOTIFICATION_METHODS')}
            size="large"
          >
            <Select.Option key="push" value="push">
              Push ({t('GLOBAL.MOBILE_APP')})
            </Select.Option>
            <Select.Option key="email" value="email">
              E-mail
            </Select.Option>
            <Select.Option key="sms" value="sms">
              SMS
            </Select.Option>
          </Select>
        </Form.Item>
        <div className="actions">
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            onClick={() => onChangeStatus('active')}
            loading={loading}
            disabled={loading}
          >
            {t('GLOBAL.ENABLE')}
          </Button>
          <Button
            type="primary"
            danger
            htmlType="submit"
            size="middle"
            onClick={() => onChangeStatus('inactive')}
            loading={loading}
            disabled={loading}
          >
            {t('GLOBAL.DEACTIVATE')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default styled(ModalManageNotificationsForAllUsers)`
  .actions {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
`;
