import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Error404 from '@/components/Error404';
import DimonaStatement from './statement';
import DimonaAuditPage from './audit';
import ContainerView from '@/layouts/ContainerView';

const AppDocuments: React.FC = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} render={() => <Redirect to={`${url}/statement`} />} />
      <Route path={`${path}/statement`}>
        <ContainerView>
          <DimonaStatement />
        </ContainerView>
      </Route>
      <Route path={`${path}/audit`}>
        <DimonaAuditPage />
      </Route>
      <Route component={() => <Error404 />} />
    </Switch>
  );
};

export default AppDocuments;
