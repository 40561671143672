import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { FEATURES } from '@/types/features.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { SCHEDULE_VIEWS, WEEKDAYS, getWindowSize, handleError, isFeatureEnabled, lioWeekdays } from '@/utils';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Form,
  InputNumber,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Select,
  Switch,
  TimePicker,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';
import ModalUnavailabilityAllUsers from './components/ModalUnavailabilityAllUsers';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const weekdayTargets = [
  'target_mon',
  'target_tue',
  'target_wed',
  'target_thu',
  'target_fri',
  'target_sat',
  'target_sun',
];

const BACKGROUND_COLORS = [
  'rgb(247, 231, 231)',
  'rgb(236, 247, 231)',
  'rgb(207, 227, 243)',
  'rgb(239, 253, 161)',
  'rgb(221, 208, 229)',
  'rgb(255, 255, 255)',
];

const ScheduleSettings: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, features },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<(string | null)[]>([]);
  const [modalUnavailability, setModalUnavailability] = useState<boolean>(false);
  const [activateAvailabilityAll, setActivateUnavailabilityAll] = useState<boolean>(false);
  const [isRefreshingPublicLink, setIsRefreshingPublicLink] = useState<boolean>(false);
  const [isRefreshingCalendarLink, setIsRefreshingCalendarLink] = useState<boolean>(false);
  const [defaultView, setDefaultView] = useState<string>('week');
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const updateForm = (c: boolean, e: any, name: any, deactivedNames: string[] = []) => {
    setChecked(
      c
        ? [...checked, name].filter((el) => el !== null).filter((el) => !deactivedNames.includes(el!))
        : checked.filter((el) => el != null).filter((el) => el != name && !deactivedNames.includes(el!)),
    );
  };

  const onUnavailabilitySwitchChange = (checked: boolean, e: any) => {
    updateForm(checked, e, 'enable_availability');
    setModalUnavailability(checked);
  };

  const onRefreshPublicLink = () => {
    setIsRefreshingPublicLink(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/operations/generate-publiclink`,
        {},
        {
          params: {
            departmentId: activeDepartment?.id,
          },
        },
      )
      .then(() => {
        updateCurrentSettings({})
          .then(() => {})
          .catch((error) => {
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setIsRefreshingPublicLink(false);
      });
  };

  const onRefreshCalendarLink = () => {
    setIsRefreshingCalendarLink(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/operations/generate-calendarlink`,
        {},
        {
          params: {
            departmentId: activeDepartment?.id,
          },
        },
      )
      .then(() => {
        updateCurrentSettings({})
          .then(() => {})
          .catch((error) => {
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setIsRefreshingCalendarLink(false);
      });
  };

  const onChangeDate = (value: moment.Moment, index: number, name: string) => {
    const schedule = { ...form.getFieldValue('schedule') };
    const backgrounds = { ...schedule.backgrounds };

    if (backgrounds[index] == undefined) {
      onChangeDate(value, index - 1, name);
      return;
    }

    if (typeof backgrounds[index][name] !== undefined) {
      backgrounds[index][name] = value;
      setFormHasChanged(true);
    }

    form.setFieldsValue({
      schedule,
    });
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
    {
      title: t('SETTINGS.SCHEDULES.SETTINGS.TITLES.DAY_START_END_TIME'),
      visible: true,
    },
    {
      title: t('SETTINGS.SCHEDULES.SETTINGS.TITLES.SHIFTS_DEFAULT_HOURS'),
      visible: true,
    },
    {
      title: t('SETTINGS.SCHEDULES.SETTINGS.TITLES.USERS_ORDER'),
      visible: true,
    },
    {
      title: t('SETTINGS.SCHEDULES.SETTINGS.TITLES.DAYS_IN_SCHEDULE'),
      visible: true,
    },
    {
      title: t('SETTINGS.SCHEDULES.SETTINGS.TITLES.DAY_HOURS_TARGET'),
      visible: true,
    },
    {
      title: t('SETTINGS.SCHEDULES.SETTINGS.TITLES.SHIFTS_BACKGROUND'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.HR_SHIFTS_VALIDATION.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.HR_SHIFTS_VALIDATION.DESCRIPTION'),
          name: ['schedule', 'validate_shifts'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.schedule?.validate_shifts} />,
          show: true,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_EXTRA_INFO.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_EXTRA_INFO.DESCRIPTION'),
          name: ['schedule', 'global_stats'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.schedule?.global_stats} />,
          show: true,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_EXTRA_INFO_SECTIONS.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_EXTRA_INFO_SECTIONS.DESCRIPTION'),
          name: ['schedule', 'filter_stats'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.schedule?.filter_stats} />,
          show: true,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_ALL_USERS.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_ALL_USERS.DESCRIPTION'),
          name: ['schedule', 'showall_ressources'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.schedule?.showall_ressources} />,
          show: true,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_SHIFTS_COMMENTS.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_SHIFTS_COMMENTS.DESCRIPTION'),
          name: ['schedule', 'show_comments'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.schedule?.show_comments} />,
          show: true,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_EXTRA_COUNTERS.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_EXTRA_COUNTERS.DESCRIPTION'),
          name: ['schedule', 'sidebarCounters'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.schedule?.sidebarCounters} />,
          show: true,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.DIFFERENCIATE_NIGHT_SHIFTS.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.DIFFERENCIATE_NIGHT_SHIFTS.DESCRIPTION'),
          name: ['schedule', 'split_daynight'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.schedule?.split_daynight}
              onChange={(checked, e) => updateForm(checked, e, 'split_daynight')}
            />
          ),
          show: true,
        },
        {
          label: '',
          description: '',
          style: { margin: 0 },
          element: (form: any) => {
            const show = form.getFieldValue(['schedule', 'split_daynight']);
            if (show) {
              return (
                <div className="form-items-group one-line" style={{ marginTop: -25 }}>
                  <span>{t('SETTINGS.ACCOUNT.SCHEDULE.SHOW_DAY_AND_NIGHT.NIGHT_START_AT')}</span>
                  <Form.Item
                    name={['schedule', 'split_daynight_time']}
                    style={{ width: 120 }}
                    rules={[{ required: true }]}
                  >
                    <Select>
                      {[...new Array(25)].map((_item, index) => (
                        <Select.Option key={`daynight_time_${index}`} value={index}>
                          {index}:00
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              );
            }
          },
          show: checked.includes('split_daynight'),
          shouldUpdate: (prevValues, curValues) =>
            prevValues?.schedule?.split_daynight !== curValues?.schedule?.split_daynight,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_SHIFT_PRICE_ON_EACH_BLOCK.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.DISPLAY_SHIFT_PRICE_ON_EACH_BLOCK.DESCRIPTION'),
          name: ['schedule', 'show_shift_price'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.schedule?.show_shift_price} />,
          show: true,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.FILTER_METHOD.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.FILTER_METHOD.DESCRIPTION'),
          name: ['schedule', 'filter_operator'],
          style: { margin: 0 },
          element: (
            <Select style={{ textTransform: 'uppercase' }}>
              <Select.Option key={'OR'} value={'OR'} style={{ textTransform: 'uppercase' }}>
                {t('GLOBAL.OR')}
              </Select.Option>
              <Select.Option key={'AND'} value={'AND'} style={{ textTransform: 'uppercase' }}>
                {t('GLOBAL.AND')}
              </Select.Option>
            </Select>
          ),
          show: true,
          elementBelow: windowSize.innerWidth <= 900,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.SCHEDULE_VIEWS.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.SCHEDULE_VIEWS.DESCRIPTION'),
          style: { width: 350, marginBottom: 0 },
          element: (
            <div className="form-items-group schedule_views" style={{ marginTop: 10 }}>
              <div className="left">
                {SCHEDULE_VIEWS.map((view) => (
                  <Form.Item
                    key={view}
                    name={['schedule', 'schedule_views', view]}
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Checkbox style={{ lineHeight: '32px' }}>{t(`GLOBAL.${view.toUpperCase()}`)}</Checkbox>
                  </Form.Item>
                ))}
              </div>
              <Radio.Group
                value={defaultView}
                className="right"
                onChange={(e: RadioChangeEvent) => {
                  setDefaultView(e.target.value);
                }}
              >
                {SCHEDULE_VIEWS.map((view) => {
                  return (
                    <Form.Item
                      key={view}
                      name={['schedule', 'default_schedule_view']}
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}
                    >
                      <Radio value={view} style={{ lineHeight: '32px' }}>
                        {t('SCHEDULE.DEFAULT_VIEW')}
                      </Radio>
                    </Form.Item>
                  );
                })}
              </Radio.Group>
            </div>
          ),
          elementBelow: true,
          show: true,
        },
      ],
      [
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.ACTIVATE_UNAVAILABILITIES.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.ACTIVATE_UNAVAILABILITIES.DESCRIPTION'),
          name: ['general', 'enable_availability'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.general?.enable_availability}
              onChange={onUnavailabilitySwitchChange}
            />
          ),
          show: true,
        },
        {
          label: '',
          description: t('SETTINGS.SCHEDULES.SETTINGS.ACTIVATE_UNAVAILABILITIES.EXTRA_DESCRIPTION'),
          element: (form: any) => {
            const show = form.getFieldValue(['general', 'enable_availability']);
            if (show) {
              return (
                <div className="form-items-group one-line" style={{ marginTop: 10 }}>
                  <Form.Item name={['general', 'availability_delay']}>
                    <InputNumber type="number" min={0} step={1} defaultValue={0} style={{ width: 60 }} />
                  </Form.Item>
                </div>
              );
            }
          },
          show: checked.includes('enable_availability'),
          shouldUpdate: (prevValues, curValues) =>
            prevValues.general?.enable_availability !== curValues.general?.enable_availability,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.DYNAMIC_HOURS.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.DYNAMIC_HOURS.DESCRIPTION'),
          name: ['schedule', 'dynamic_hour'],
          element: (
            <Switch
              defaultChecked={currentSettings?.schedule?.dynamic_hour}
              onChange={(checked, e) => updateForm(checked, e, 'dynamic_hour')}
            />
          ),
          show: true,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.DAY_START_HOUR.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.DAY_START_HOUR.DESCRIPTION'),
          name: ['schedule', 'start_hour'],
          valuePropName: 'checked',
          style: { margin: 0 },
          rules: [{ required: true }],
          element: (
            <Select>
              {[...new Array(25)].map((_item, index) => (
                <Select.Option key={`start_hour_${index}`} value={index}>
                  {index}:00
                </Select.Option>
              ))}
            </Select>
          ),
          show: !checked.includes('dynamic_hour'),
          elementBelow: windowSize.innerWidth <= 900,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.DAY_END_HOUR.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.DAY_END_HOUR.DESCRIPTION'),
          name: ['schedule', 'end_hour'],
          valuePropName: 'checked',
          style: { margin: 0 },
          rules: [{ required: true }],
          element: (
            <Select>
              {[...new Array(25)].map((_item, index) => (
                <Select.Option key={`end_hour_${index}`} value={index}>
                  {index}:00
                </Select.Option>
              ))}
            </Select>
          ),
          show: !checked.includes('dynamic_hour'),
          elementBelow: windowSize.innerWidth <= 900,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.SHIFTS_START_HOUR.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.SHIFTS_START_HOUR.DESCRIPTION'),
          name: ['schedule', 'default_shift_start_time'],
          style: { margin: 0 },
          rules: [{ required: true }],
          element: (
            <Select>
              {[...new Array(24)].map((_item, index) => (
                <Select.Option key={`default_shift_start_time${index}`} value={index}>
                  {index}:00
                </Select.Option>
              ))}
            </Select>
          ),
          show: true,
          elementBelow: windowSize.innerWidth <= 900,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.SHIFTS_END_HOUR.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.SHIFTS_END_HOUR.DESCRIPTION'),
          name: ['schedule', 'default_shift_end_time'],
          style: { margin: 0 },
          rules: [{ required: true }],
          element: (
            <Select>
              {[...new Array(24)].map((_item, index) => (
                <Select.Option key={`default_shift_end_time${index}`} value={index}>
                  {index}:00
                </Select.Option>
              ))}
            </Select>
          ),
          show: true,
          elementBelow: windowSize.innerWidth <= 900,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.ORDER_WEEK_MONTH.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.ORDER_WEEK_MONTH.DESCRIPTION'),
          name: ['schedule', 'order_by_week'],
          style: { margin: 0, minWidth: 175 },
          element: (
            <Select>
              <Select.Option value={1}>{t('GLOBAL.USER_CATEGORY')}</Select.Option>
              {isFeatureEnabled(features, FEATURES.SKILLS) && (
                <Select.Option value={2}>{t('GLOBAL.SKILL')}</Select.Option>
              )}
              <Select.Option value={3}>{t('GLOBAL.NAME')}</Select.Option>
            </Select>
          ),
          show: true,
          elementBelow: windowSize.innerWidth <= 900,
        },
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.ORDER_DAY.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.ORDER_DAY.DESCRIPTION'),
          name: ['schedule', 'order_by_day'],
          style: { margin: 0, minWidth: 175 },
          element: (
            <Select>
              <Select.Option value={1}>{t('GLOBAL.USER_CATEGORY')}</Select.Option>
              {isFeatureEnabled(features, FEATURES.SKILLS) && (
                <Select.Option value={2}>{t('GLOBAL.SKILL')}</Select.Option>
              )}
              <Select.Option value={3}>{t('GLOBAL.NAME')}</Select.Option>
              <Select.Option value={4}>{t('GLOBAL.DATE')}</Select.Option>
            </Select>
          ),
          show: true,
          elementBelow: windowSize.innerWidth <= 900,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.DAYS_IN_SCHEDULE.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.DAYS_IN_SCHEDULE.DESCRIPTION'),
          style: { margin: 0 },
          element: (
            <div className="form-items-group one-line" style={{ marginTop: 15 }}>
              {lioWeekdays?.map((day: string, index: number) => (
                <Form.Item key={`day_${index}`} name={['schedule', lioWeekdays[index]]} valuePropName="checked">
                  <Checkbox style={{ lineHeight: '32px' }}>{t(`GLOBAL.${day.toUpperCase()}`)}</Checkbox>
                </Form.Item>
              ))}
            </div>
          ),
          elementBelow: true,
          show: true,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.DAY_HOURS_TARGET.LABEL'),
          description: t('SETTINGS.SCHEDULES.SETTINGS.DAY_HOURS_TARGET.DESCRIPTION'),
          style: { margin: 0 },
          element: (
            <div style={{ marginTop: 15 }}>
              {WEEKDAYS?.map((day: string, index: number) => (
                <Form.Item
                  key={`day_target_${index}`}
                  name={['schedule', weekdayTargets[index]]}
                  style={{ lineHeight: '32px' }}
                  label={t(`GLOBAL.${day.toUpperCase()}`)}
                  className="one-line target-days"
                >
                  <InputNumber
                    type="number"
                    min={0}
                    step="1"
                    parser={(value) => {
                      return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                    }}
                  />
                </Form.Item>
              ))}
            </div>
          ),
          elementBelow: true,
          show: true,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.SCHEDULES.SETTINGS.CHANGE_SHIFTS_COLORS.LABEL'),
          description: '',
          name: ['schedule', 'colored_shifts'],
          style: { margin: 0 },
          element: <></>,
          show: true,
        },
        {
          label: '',
          description: t('SETTINGS.SCHEDULES.SETTINGS.CHANGE_SHIFTS_COLORS.DESCRIPTION'),
          name: ['schedule', 'colored_shifts_enabled'],
          rowStyle: { margin: 0 },
          style: { margin: 0 },
          element: (
            <Switch
              checked={checked.includes('colored_shifts_enabled')}
              onChange={(checked, e) => updateForm(checked, e, 'colored_shifts_enabled', ['backgrounds_enabled'])}
            />
          ),
          show: true,
        },
        {
          label: '',
          description: '',
          name: ['schedule', 'colored_shifts'],
          style: { margin: 0, width: 150 },
          element: (
            <Select>
              <Select.Option key={'null'} value={'null'}>
                {t('GLOBAL.NONE')}
              </Select.Option>
              <Select.Option value="status">{t(`GLOBAL.STATUS`)}</Select.Option>
              {isFeatureEnabled(features, FEATURES.SKILLS) && (
                <Select.Option value="skills">{t(`GLOBAL.SKILLS`)}</Select.Option>
              )}
              {isFeatureEnabled(features, FEATURES.ATTRIBUTES) && (
                <Select.Option value="attributes">{t(`GLOBAL.ATTRIBUTES`)}</Select.Option>
              )}
            </Select>
          ),
          show: checked.includes('colored_shifts_enabled'),
        },
        {
          label: '',
          description: t('SETTINGS.SCHEDULES.SETTINGS.SHIFTS_BACKGROUND.DESCRIPTION'),
          name: ['schedule', 'backgrounds_enabled'],
          rowStyle: { margin: 0 },
          style: { margin: 0 },
          element: (
            <Switch
              checked={checked.includes('backgrounds_enabled')}
              onChange={(checked, e) => updateForm(checked, e, 'backgrounds_enabled', ['colored_shifts_enabled'])}
            />
          ),
          show: true,
        },
        {
          label: '',
          description: '',
          style: { margin: 0 },
          name: ['schedule', 'backgrounds'],
          element: (
            <Form.List name={['schedule', 'backgrounds']}>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field) => {
                      return (
                        <div className="form-list" key={field.key}>
                          <Form.Item
                            name={[field.name, 'start']}
                            rules={[{ required: true }]}
                            style={{ marginRight: '10px' }}
                          >
                            <TimePicker
                              placeholder={t('GLOBAL.START')}
                              format="HH:mm"
                              style={{ width: '100%' }}
                              onSelect={(e) => onChangeDate(e, field.fieldKey, 'start')}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, 'end']}
                            rules={[{ required: true }]}
                            style={{ marginRight: '10px' }}
                          >
                            <TimePicker
                              placeholder={t('GLOBAL.END')}
                              format="HH:mm"
                              style={{ width: '100%' }}
                              onSelect={(e) => {
                                onChangeDate(e, field.fieldKey, 'end');
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, 'color']}
                            style={{ display: 'inline-block', flex: '1', marginRight: '10px' }}
                            rules={[{ required: true }]}
                          >
                            <Select
                              style={{ width: 100 }}
                              getPopupContainer={(trigger) => trigger}
                              placeholder={t('GLOBAL.COLOR')}
                            >
                              {BACKGROUND_COLORS?.map((color) => (
                                <Select.Option value={color} key={color}>
                                  <div className="option-label-item">
                                    <div className="option-label-item-circle" style={{ backgroundColor: color }}></div>
                                  </div>
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Popconfirm
                            title={t('GLOBAL.ARE_YOU_SURE?')}
                            okText={t('GLOBAL.YES')}
                            cancelText={t('GLOBAL.NO')}
                            onConfirm={() => {
                              remove(field.name);
                            }}
                          >
                            <MinusCircleOutlined style={{ marginRight: '10px' }} />
                          </Popconfirm>
                        </div>
                      );
                    })}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add({
                            start: null,
                            end: null,
                            color: null,
                          });
                        }}
                        block
                        style={{ maxWidth: 400 }}
                      >
                        <PlusOutlined /> {t('SHIFTS.ADD_BACKGROUND_COLOR')}
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          ),
          elementBelow: true,
          show: checked.includes('backgrounds_enabled'),
        },
      ],
    ],
  ];

  const onReset = () => {
    setActivateUnavailabilityAll(false);
    setFormHasChanged(false);
    const alreadyChecked = [];
    form.resetFields();
    if (currentSettings?.general) {
      alreadyChecked.push(
        ...Object.entries(currentSettings.general).map(([key, value]: any) => {
          if (key == 'enable_availability') {
            return key;
          }
          return value == true || (value !== undefined && value > 1) ? key : null;
        }),
      );
    }
    if (currentSettings?.schedule) {
      alreadyChecked.push(
        ...Object.entries(currentSettings.schedule).map(([key, value]) => {
          if (key == 'colored_shifts' && value !== null) {
            return 'colored_shifts_enabled';
          }
          if (key == 'backgrounds' && value !== null) {
            return 'backgrounds_enabled';
          }
          return value == true ? key : null;
        }),
      );
      if (currentSettings.schedule.default_schedule_view) {
        setDefaultView(currentSettings.schedule.default_schedule_view);
      }
    }
    setChecked(alreadyChecked);
    form.setFieldsValue({
      ...currentSettings,
      schedule: {
        ...currentSettings?.schedule,
        backgrounds: [
          ...(currentSettings?.schedule?.backgrounds?.map(({ start, end, color }) => ({
            start: moment.unix(start),
            end: moment.unix(end),
            color,
          })) || []),
        ],
        colored_shifts: currentSettings?.schedule?.colored_shifts ? currentSettings?.schedule?.colored_shifts : null,
        schedule_views: currentSettings?.schedule?.schedule_views
          ? currentSettings?.schedule?.schedule_views
              .split(',')
              .reduce((prev, value) => ({ ...prev, [value]: true }), {})
          : {},
      },
    });
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    const entries = Object.entries(values.schedule.schedule_views);
    const views = entries.filter(([key, value]) => value == true).map(([key, value]) => key);
    if (views.length == 0) {
      views.push('week');
    }
    setLoading(true);
    const order = ['day', 'ops', 'week', 'month'];
    if (!views.includes(defaultView)) {
      views.push(defaultView);
    }

    const orderedArray = [...views].sort((a, b) => {
      const aIndex = order.indexOf(a);
      const bIndex = order.indexOf(b);

      // If both elements are not in the predefined order, keep their original order
      if (aIndex === -1 && bIndex === -1) {
        return 0;
      }

      // If one element is not in the predefined order, place it after the other
      if (aIndex === -1) {
        return 1;
      }

      if (bIndex === -1) {
        return -1;
      }

      return aIndex - bIndex;
    });
    if (activateAvailabilityAll) {
      values = {
        ...values,
        general: {
          ...values.general,
          activateAvailabilityAll,
        },
      };
    }
    updateCurrentSettings({
      ...values,
      schedule: {
        ...values.schedule,
        colored_shifts: checked.includes('colored_shifts_enabled')
          ? values.schedule.colored_shifts == 'null'
            ? null
            : values.schedule.colored_shifts
          : null,
        backgrounds: checked.includes('backgrounds_enabled') ? values.schedule.backgrounds : [],
        schedule_views: orderedArray.length == 0 ? ['week'] : orderedArray,
        colored_shifts_enabled: undefined,
        backgrounds_enabled: undefined,
        default_schedule_view: defaultView || 'week',
      },
    })
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.SCHEDULES.SETTINGS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
      <ModalUnavailabilityAllUsers
        visible={modalUnavailability}
        setVisible={setModalUnavailability}
        setActivateAvailabilityAll={setActivateUnavailabilityAll}
      />
    </div>
  );
};

export default styled(ScheduleSettings)`
  .form-list {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px !important;
    max-width: 400px;

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
  }

  .schedule_views {
    display: flex;
    align-items: flex-start;
    gap: 25px;
  }

  .option-label-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-select-selection-item {
    .option-label-item-circle {
      margin-top: 4px;
    }
  }

  margin-top: 4px;
  .option-label-item-circle {
    display: inline-block;
    background-color: red;
    width: 100%;
    height: 20px;
    border-radius: 5px;
  }

  .expenseAllowances {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li:nth-of-type(n + 2) {
      padding-top: 5px;
    }

    .anticon-close {
      background: ${colors.red};
      padding: 1px;
      border-radius: 10px;
      margin-right: 5px;
      svg {
        transform: scale(0.8);
        fill: white;
      }
    }
  }
  .target-days {
    label {
      min-width: 100px;
    }
  }

  @media screen and (max-width: 900px) {
    .one-line {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .pauses {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li:nth-of-type(n + 2) {
      padding-top: 5px;
    }

    .anticon-close {
      background: ${colors.red};
      padding: 1px;
      border-radius: 10px;
      margin-right: 5px;
      svg {
        transform: scale(0.8);
        fill: white;
      }
    }
  }
`;
