import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { MapContainer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import { LatLngBoundsLiteral, Icon, Map } from 'leaflet';
import { IClocking } from '@/types/clocking.model';
import moment, { Moment } from 'moment';
import colors from '@/styles/colors';
import 'moment-timezone';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import VectorTileLayer from 'react-leaflet-vector-tile-layer';
import * as L from 'leaflet';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
import 'leaflet-extra-markers/dist/js/leaflet.extra-markers.min.js';
import AppContext from '@/pages/app/context';

moment.tz.setDefault('Atlantic/Reykjavik');
require('leaflet/dist/leaflet.css');
require('react-leaflet-markercluster/dist/styles.min.css');

delete (Icon.Default.prototype as any)._getIconUrl;

const createClusterCustomIcon = (cluster: any) => {
  return L.divIcon({
    html: `<div class="cluster-container">${cluster.getChildCount()}</div>`,
    className: 'marker-cluster-custom',
    iconSize: L.point(40, 40, true),
  });
};

const currentClockingMarker = (L as any).ExtraMarkers.icon({
  icon: 'fa-spinner',
  prefix: 'fa',
  extraClasses: 'fa-spin',
  markerColor: 'green',
  svg: true,
});

const todayClockingMarker = (L as any).ExtraMarkers.icon({
  markerColor: 'green',
});

const pastClockingMarker = (L as any).ExtraMarkers.icon({
  markerColor: 'orange',
});

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

interface Props {
  departmentId?: string;
  sectionId?: string;
  height: number | string;
  startDate?: Moment;
  endDate?: Moment;
}

const Geolocation: React.FC<Props> = ({ departmentId, height, startDate, endDate, sectionId }) => {
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clockings, setClockings] = useState<IClocking[]>([]);
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    if (!mounted || !activeDepartment || !startDate || !endDate) {
      return;
    }

    setIsLoading(true);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/widgets/99`, {
      params: {
        departmentId,
        sectionId,
        start: startDate?.unix(),
        end: endDate?.unix(),
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        if (mounted) {
          setClockings(response.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [sectionId, startDate, endDate]);

  const bounds: LatLngBoundsLiteral = clockings.map((clocking) => {
    const clockingLocation = clocking?.checkOut?.location || clocking?.checkIn?.location;
    if (clockingLocation) {
      const { latitude = 0, longitude = 0 } = clockingLocation;
      return [latitude, longitude];
    } else {
      return [0, 0];
    }
  });

  return (
    <div style={{ height, minHeight: 260 }}>
      {typeof height !== 'string' && <h4>{t(`DASHBOARD.WIDGETS.GEOLOC`)}</h4>}
      {isLoading && <Spin spinning={isLoading} />}
      {!isLoading && (
        <MapContainer
          style={{
            position: 'absolute',
            left: 0,
            marginTop: typeof height === 'string' ? -20 : -10,
            minHeight: 250,
            height: typeof height === 'string' ? 'calc(100vh - 280px)' : height - 10,
            width: '100%',
          }}
          zoom={bounds.length > 0 ? 13 : activeDepartment?.lat && activeDepartment.lng ? 13 : 2}
          maxZoom={bounds.length > 0 ? 20 : activeDepartment?.lat && activeDepartment.lng ? 15 : 2}
          center={[0, 0]}
          scrollWheelZoom={false}
          whenCreated={(map: Map) => {
            map.fitBounds(
              bounds.length
                ? bounds
                : activeDepartment?.lat && activeDepartment.lng
                ? [
                    [Number(activeDepartment?.lat), Number(activeDepartment.lng)],
                    [Number(activeDepartment?.lat), Number(activeDepartment.lng)],
                  ]
                : [
                    [50.85045, 4.34878],
                    [50.95045, 4.44878],
                  ],
            );
          }}
        >
          <VectorTileLayer
            attribution="© MapTiler © OpenStreetMap contributors"
            styleUrl="https://api.maptiler.com/maps/70ddf71e-c9a7-4112-b00f-9124ba482a89/style.json?key=a9UYy1T8E5qkxvDgC0fH"
          />
          <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
            {clockings.map((clocking) => {
              const clockingLocation = clocking?.checkIn?.location;
              const now = moment().startOf('day').unix();
              if (clockingLocation) {
                const { latitude, longitude } = clockingLocation;
                return (
                  <Marker
                    position={[latitude!, longitude!]}
                    key={clocking.id!}
                    icon={
                      !clocking?.checkOut?.end
                        ? currentClockingMarker
                        : clocking.checkIn!.start! < now
                        ? pastClockingMarker
                        : todayClockingMarker
                    }
                  >
                    <Popup>
                      <b>{clocking.displayName}</b>
                      <br />
                      <span>
                        {moment.unix(clocking.checkIn!.start!).format('L HH:mm')} -{' '}
                        <span style={{ color: !clocking.checkOut?.end ? colors.green : undefined }}>
                          {clocking.checkOut?.end
                            ? moment.unix(clocking.checkOut.end).format('HH:mm')
                            : t('GLOBAL.IN_PROGRESS')}
                        </span>
                      </span>
                    </Popup>
                  </Marker>
                );
              } else {
                return null;
              }
            })}
          </MarkerClusterGroup>
        </MapContainer>
      )}
    </div>
  );
};

export default Geolocation;
