import AppContext from '@/pages/app/context';
import { InitialStateType } from '@/pages/app/hours/manage/redux/store';
import { IShift } from '@/types/shift.model';
import { Menu } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { Dispatch, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ActionType, updateShiftsAndContextWithShiftResponse } from '../../redux/actions';

interface Props {
  close: () => void;
}

const MonthRightClickMenu: React.FC<Props> = ({ close }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const dispatch: Dispatch<ActionType> = useDispatch();
  const {
    state: { activeDepartment, activeDepartmentId, activeSection, skills, userCategories },
  } = useContext(AppContext);
  const {
    monthly_selected_elements,
    clipboardHistory,
    opened_monthly_dropdown_identifier,
    startDate,
    endDate,
    picker,
  } = useSelector(
    ({
      monthly_selected_elements,
      clipboardHistory,
      opened_monthly_dropdown_identifier,
      startDate,
      endDate,
      picker,
    }: InitialStateType) => ({
      monthly_selected_elements,
      clipboardHistory,
      opened_monthly_dropdown_identifier,
      startDate,
      endDate,
      picker,
    }),
  );

  const onRemoveWeekend = () => {
    dispatch({
      type: 'SET_MONTHLY_SELECTED_ELEMENTS',
      payload: monthly_selected_elements.filter((el: HTMLElement) => {
        if (el.className.includes('weekend')) {
          el.classList.remove('highlight');
        }
        return !el.className.includes('weekend');
      }),
    });
    close();
  };

  const onPasteShift = () => {
    if (opened_monthly_dropdown_identifier && clipboardHistory) {
      let elements: string[] = [opened_monthly_dropdown_identifier];
      if (monthly_selected_elements) {
        elements = monthly_selected_elements.map((element) => element.id);
      }
      let shifts: any = [];
      elements.forEach((element) => {
        const data = element.split('_');
        const userRecordId = data[0];
        const start = +data[1] / 1000;
        const shift_to_copy = clipboardHistory.shifts[0];

        const startMoment = moment.unix(shift_to_copy.start!);
        const endMoment = moment.unix(shift_to_copy.end!);
        const duration = moment.duration(endMoment.diff(startMoment)).asSeconds();
        const real_start_date = moment
          .unix(start)
          .add(startMoment.hour(), 'hour')
          .add(startMoment.minutes(), 'minute')
          .unix();
        const real_end_date = moment.unix(real_start_date + duration).unix();

        shifts.push({
          id: shift_to_copy.id,
          start: real_start_date,
          end: real_end_date,
          userRecordId,
        });

        dispatch({
          type: 'SET_LOADING',
          payload: true,
        });
        dispatch({
          type: 'SET_LOADING_ELEMENTS',
          payload: elements,
        });
      });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/shifts`,
          {
            departmentId: activeDepartmentId,
            shifts,
            force: true,
          },
          {
            params: {
              picker: 'month',
              startDate: startDate.unix(),
              endDate: endDate.unix(),
              departmentId: activeDepartmentId,
              sectionId: activeSection ? activeSection : null,
            },
          },
        )
        .then((res) => {
          if (res.data.message.totalErrors > 0) {
            const errors = res.data.message.error;
            const messages = errors.map((error: any) => error.message[0]);

            dispatch({
              type: 'SET_ERRORS',
              payload: [
                ...messages.map((message: any) => (
                  <div>
                    <div>{t(`SCHEDULE.CONSTRAINTS.${message.title}`)}</div>
                    {message.details.infos}
                  </div>
                )),
              ],
            });
          }
          updateShiftsAndContextWithShiftResponse(dispatch, res, {
            department: activeDepartment!,
            picker,
            startDate,
            endDate,
            activeSection,
            skills,
            userStatus: userCategories,
          });
          batch(() => {
            dispatch({
              type: 'UPDATE_FILTERED_SHIFTS',
              payload: { department: activeDepartment },
            });
            dispatch({
              type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
              payload: null,
            });
            dispatch({
              type: 'SET_MONTHLY_SELECTED_ELEMENTS',
              payload: [],
            });
            dispatch({
              type: 'SET_MONTHLY_SELECTING_ELEMENTS',
              payload: false,
            });
            dispatch({
              type: 'SET_LOADING',
              payload: false,
            });
            dispatch({
              type: 'SET_LOADING_ELEMENTS',
              payload: null,
            });
          });
        })
        .catch((err) => {
          batch(() => {
            dispatch({
              type: 'SET_LOADING',
              payload: false,
            });
            dispatch({
              type: 'SET_LOADING_ELEMENTS',
              payload: null,
            });
            dispatch({
              type: 'SET_SELECTED_SHIFTS',
              payload: [],
            });
            dispatch({
              type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
              payload: null,
            });
            dispatch({
              type: 'SET_LOADING',
              payload: false,
            });
            dispatch({
              type: 'SET_LOADING_ELEMENTS',
              payload: null,
            });
          });
        });
    }
    close();
  };

  return (
    <Menu>
      {monthly_selected_elements.length > 0 && (
        <Menu.Item onClick={onRemoveWeekend} key="remove_weekend">
          <i className="icon-delete"></i> {t('SCHEDULE.MONTHLY_VIEW.REMOVE_WEEKEND')}
        </Menu.Item>
      )}
      <Menu.Item onClick={onPasteShift}>
        <i className="icon-paste" key="paste_shift"></i> {t('SCHEDULE.MONTHLY_VIEW.PASTE_SHIFT')}
      </Menu.Item>
    </Menu>
  );
};

export default MonthRightClickMenu;

function removeUnnecessaryProperties(shift: IShift): any {
  const obj: any = { ...shift };
  delete obj.id;
  delete obj.day;
  delete obj.night;
  delete obj.package;
  delete obj.packageId;
  delete obj.userRecordId;
  delete obj.startDate;
  delete obj.endDate;
  delete obj.start;
  delete obj.end;

  if (shift.skills) {
    obj.skills = shift.skills.map((skill) => skill.id);
  } else {
    obj.skills = [];
  }

  if (shift.attributes) {
    obj.attributes = shift.attributes.map((attribute) => attribute.id);
  } else {
    obj.attributes = [];
  }

  if (shift.tasks) {
    obj.tasks = shift.tasks.map((task) => ({
      taskTypeId: task.taskTypeId,
      start: task.start,
      end: task.end,
    }));
  } else {
    obj.tasks = [];
  }

  if (shift.section) {
    obj.section = shift.section.id;
  } else {
    obj.section = null;
  }

  if (typeof shift.shyftType == 'number') {
    obj.shyftType = [shift.shyftType + ''];
  }

  if (shift.end && shift.start) {
    if (shift.end < shift.start) {
      obj.end = shift.end + 86400;
    }
  }

  return obj;
}
