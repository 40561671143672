import React, { useState, useEffect, Dispatch, useRef, useContext } from 'react';
import styled from 'styled-components';
import { ITask } from '@/types/task.model';
import { getContrast, isNullOrUndefined } from '@/utils';
import moment from 'moment';
import { moveTask } from '../../redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { InitialStateType } from '../../redux/store';
import { ActionType } from '../../redux/actions';
import { Tooltip } from 'antd';
import AppContext from '@/pages/app/context';

type ResizeMode = 'start' | 'end';

interface Props {
  className?: string;
  key: string;
  task: ITask;
  taskHeight: number;
  shiftStart: number;
  shiftId: string;
  columnWidth: number;
  draggable: boolean;
  refPageContainer: React.MutableRefObject<null> | null;
  taskIsResizingRef: React.MutableRefObject<boolean>;
  onTaskDragStartHandler: (e: React.DragEvent, task: ITask) => void;
  onTaskDragEndHandler: (e: React.DragEvent) => void;
  activeDepartmentId?: string;
  activeSection?: string;
  backgroundColor?: string;
}

const ShiftTask: React.FC<Props> = ({
  className,
  draggable,
  task,
  taskHeight,
  shiftStart,
  shiftId,
  columnWidth,
  key,
  refPageContainer,
  taskIsResizingRef,
  onTaskDragStartHandler,
  onTaskDragEndHandler,
  activeDepartmentId,
  activeSection,
  backgroundColor,
}) => {
  const { shiftsMap, usersMap, picker, startDate, endDate } = useSelector(
    ({ shiftsMap, usersMap, picker, startDate, endDate }: InitialStateType) => ({
      shiftsMap,
      usersMap,
      picker,
      startDate,
      endDate,
    }),
    shallowEqual,
  );
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const {
    state: { activeDepartment, userCategories, skills },
  } = useContext(AppContext);

  const [initialMousePositionX, setInitialMousePositionX] = useState<number | null>(null);
  const [resizeMode, setResizeMode] = useState<ResizeMode | null>(null);
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(0);
  const [left, setLeft] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [startTooltip, setStartTooltip] = useState<boolean>(false);
  const [endTooltip, setEndTooltip] = useState<boolean>(false);

  const startRef = useRef<number>(start);
  startRef.current = start;
  const endRef = useRef<number>(end);
  endRef.current = end;
  const widthRef = useRef<number>(width);
  widthRef.current = width;

  const initStateWithTask = ({
    task,
    columnWidth,
    shiftStart,
  }: {
    task: ITask;
    columnWidth: number;
    shiftStart: number;
  }) => {
    const { start, end } = task;
    const left = ((start! - shiftStart) / 3600) * columnWidth;
    const width = ((end! - start!) / 3600) * columnWidth;

    setStart(start!);
    setEnd(end!);
    setLeft(left);
    setWidth(width);
  };

  useEffect(() => {
    initStateWithTask({ task, columnWidth, shiftStart });
  }, [task, columnWidth, shiftStart]);

  const resizeMouseDownHandler = (e: React.MouseEvent, resizeMode: ResizeMode) => {
    e.preventDefault();
    e.stopPropagation();
    const scrollLeft = refPageContainer ? (refPageContainer!.current! as HTMLElement).scrollLeft : 0;
    setInitialMousePositionX(e.pageX + scrollLeft);
    setResizeMode(resizeMode);
    if (resizeMode == 'start') {
      setStartTooltip(true);
    }
    if (resizeMode == 'end') {
      setEndTooltip(true);
    }
    taskIsResizingRef.current = true;
  };

  const resizeMouseUpHandler = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setEndTooltip(false);
    setStartTooltip(false);
    setInitialMousePositionX(null);
    setResizeMode(null);
    setTimeout(() => {
      taskIsResizingRef.current = false;
    });

    const { start: taskStart, end: taskEnd, id } = task;
    const source = {
      shiftId,
      taskId: id!,
      start: taskStart!,
      end: taskEnd!,
    };

    const destination = {
      shiftId,
      newStart: startRef.current,
      newEnd: endRef.current,
    };

    moveTask(
      shiftsMap,
      usersMap,
      hoursDispatch,
      { source, destination },
      {
        picker,
        startDate: startDate,
        endDate: endDate.unix(),
        departmentId: activeDepartmentId,
        sectionId: activeSection,
        department: activeDepartment,
        activeSection,
        userStatus: userCategories,
        skills,
      },
    );
  };

  const resizeMouseLeaveHandler = (e: MouseEvent) => {
    e.preventDefault();
    setInitialMousePositionX(null);
    setResizeMode(null);
    initStateWithTask({ task, columnWidth, shiftStart });
  };

  const resizeMouseMoveHandler = (e: MouseEvent) => {
    e.preventDefault();
    const scrollLeft = refPageContainer ? (refPageContainer!.current! as HTMLElement).scrollLeft : 0;
    const difference = e.pageX + scrollLeft - (initialMousePositionX || 0);
    const columnDifference = Math.trunc((difference / columnWidth) * 4);

    if (resizeMode === 'end') {
      const newEnd = moment
        .unix(task.end!)
        .add(columnDifference * 15, 'minutes')
        .unix();
      setEnd(newEnd);
      setWidth(((newEnd - task.start!) / 3600) * columnWidth);
    }
    if (resizeMode === 'start') {
      const newStart = moment
        .unix(task.start!)
        .add(columnDifference * 15, 'minutes')
        .unix();
      const left = ((newStart! - shiftStart) / 3600) * columnWidth;
      setLeft(left);
      setStart(newStart);
      setWidth(((task.end! - newStart) / 3600) * columnWidth);
    }
  };

  useEffect(() => {
    if (initialMousePositionX) {
      document.addEventListener('mouseup', resizeMouseUpHandler);
      document.addEventListener('mousemove', resizeMouseMoveHandler);
      document.addEventListener('mouseleave', resizeMouseLeaveHandler);
      return () => {
        document.removeEventListener('mouseup', resizeMouseUpHandler);
        document.removeEventListener('mousemove', resizeMouseMoveHandler);
        document.removeEventListener('mouseleave', resizeMouseLeaveHandler);
      };
    }
  }, [initialMousePositionX]);

  return (
    <div key={key} className={`${className} ${initialMousePositionX ? 'resize' : ''}`} style={{ height: taskHeight }}>
      <div
        className={`extra ${!isNullOrUndefined(initialMousePositionX) ? 'visible' : ''}`}
        style={{
          left: left - columnWidth,
          width: columnWidth,
        }}
      ></div>
      <Tooltip overlay={<span>{moment.unix(start).format('HH:mm')}</span>} visible={startTooltip}>
        <div
          className="resize left"
          style={{
            left,
          }}
          onMouseDown={(e) => {
            resizeMouseDownHandler(e, 'start');
          }}
        >
          |
        </div>
      </Tooltip>
      <div
        className="task"
        draggable={draggable}
        onDragStart={(e) => onTaskDragStartHandler(e, task)}
        onDragEnd={onTaskDragEndHandler}
        style={{
          background: task.background || '#000000',
          left,
          width,
          fontSize: 14,
          color: backgroundColor ? getContrast(backgroundColor) : task.color || '#000000',
        }}
      >
        {task.shortCode}
      </div>
      <Tooltip overlay={<span>{moment.unix(end).format('HH:mm')}</span>} visible={endTooltip}>
        <div
          className="resize right"
          style={{
            left: left + width - 5,
          }}
          onMouseDown={(e) => {
            resizeMouseDownHandler(e, 'end');
          }}
        >
          |
        </div>
      </Tooltip>
      <div
        className={`extra ${!isNullOrUndefined(initialMousePositionX) ? 'visible' : ''}`}
        style={{
          left: left + width,
          width: columnWidth,
        }}
      ></div>
    </div>
  );
};

const ShiftTaskStyled = styled(ShiftTask)`
  position: absolute;

  &.resize {
    cursor: ew-resize;
  }

  &:hover {
    z-index: 9999999;
    > .resize {
      display: flex;
    }
  }

  > .extra {
    display: none;
    position: absolute;
    height: 100%;
    top: 0px;
    &.visible {
      display: block;
    }
  }

  > .resize {
    display: none;
    height: 100%;
    position: absolute;
    width: 5px;
    height: 100%;
    background: #d0d8e4;
    top: 0;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: ew-resize;
    z-index: 2;

    &.left {
      left: 0;
      border-radius: 2px 0 0 2px;
    }

    &.right {
      right: 0;
      border-radius: 0 2px 2px 0;
    }
  }

  > .task {
    background: #000000;
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    font-size: 11px;
    padding: 2px 4px;
    border-radius: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;

    &.block-hide {
      display: none;
    }
  }
`;

export default ShiftTaskStyled;
