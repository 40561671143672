import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { handleError } from '@/utils';
import { Button, Form } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const PublicSchedules: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isRefreshingPublicLink, setIsRefreshingPublicLink] = useState<boolean>(false);
  const [isRefreshingCalendarLink, setIsRefreshingCalendarLink] = useState<boolean>(false);

  const url = `${window.location.protocol}//${window.location.host}`;

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const onRefreshPublicLink = () => {
    setIsRefreshingPublicLink(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/operations/generate-publiclink`,
        {},
        {
          params: {
            departmentId: activeDepartment?.id,
          },
        },
      )
      .then(() => {
        updateCurrentSettings({})
          .then(() => {})
          .catch((error) => {
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setIsRefreshingPublicLink(false);
      });
  };

  const onRefreshCalendarLink = () => {
    setIsRefreshingCalendarLink(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/operations/generate-calendarlink`,
        {},
        {
          params: {
            departmentId: activeDepartment?.id,
          },
        },
      )
      .then(() => {
        updateCurrentSettings({})
          .then(() => {})
          .catch((error) => {
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setIsRefreshingCalendarLink(false);
      });
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.SCHEDULES.PUBLIC_SCHEDULES.PUBLIC_SCHEDULES_PAGE.LABEL'),
          description: t('SETTINGS.SCHEDULES.PUBLIC_SCHEDULES.PUBLIC_SCHEDULES_PAGE.DESCRIPTION'),
          style: { margin: 0 },
          element: (
            <div style={{ marginTop: 15 }}>
              <a href={`${url}/schedule/${currentSettings?.department?.public_url}`}>
                {url}/schedule/{currentSettings?.department?.public_url}
              </a>
              <br />
              <Button loading={isRefreshingPublicLink} onClick={onRefreshPublicLink} style={{ marginTop: 15 }}>
                {t('GLOBAL.GENERATE_NEW_LINK')}
              </Button>
            </div>
          ),
          elementBelow: true,
          show: true,
        },
      ],
      [
        {
          label: t('SETTINGS.SCHEDULES.PUBLIC_SCHEDULES.PUBLIC_SCHEDULES_ICAL.LABEL'),
          description: t('SETTINGS.SCHEDULES.PUBLIC_SCHEDULES.PUBLIC_SCHEDULES_ICAL.DESCRIPTION'),
          style: { margin: 0 },
          element: (
            <div style={{ marginTop: 15 }}>
              <a
                href={`${process.env.REACT_APP_API_URL?.slice(0, -4)}/calendar/${
                  currentSettings?.department?.calendar_link
                }`}
              >
                {process.env.REACT_APP_API_URL?.slice(0, -4)}/calendar/{currentSettings?.department?.calendar_link}
              </a>
              <br />
              <Button loading={isRefreshingCalendarLink} onClick={onRefreshCalendarLink} style={{ marginTop: 15 }}>
                {t('GLOBAL.GENERATE_NEW_LINK')}
              </Button>
            </div>
          ),
          elementBelow: true,
          show: true,
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    form.setFieldsValue({
      ...currentSettings,
    });
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {};

  return (
    <div className={className}>
      <h2>{t('SETTINGS.SCHEDULES.PUBLIC_SCHEDULES.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(PublicSchedules)``;
