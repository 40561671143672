import AppContext from '@/pages/app/context';
import { ISwapShiftFilter } from '@/types/swap-shift/filter.model';
import { ISwapShift } from '@/types/swap-shift/swap-shift.model';
import { Button, List, Modal, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  filter: ISwapShiftFilter;
  swapShift: ISwapShift;
  swapShifts: ISwapShift[];
  setSwapShifts: React.Dispatch<React.SetStateAction<ISwapShift[]>>;
  setActiveSwapShift: React.Dispatch<React.SetStateAction<ISwapShift | null>>;
  setModalSwapShiftDetailsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Actions: React.FC<Props> = ({
  className,
  filter,
  swapShift,
  swapShifts,
  setSwapShifts,
  setActiveSwapShift,
  setModalSwapShiftDetailsVisible,
}) => {
  const {
    state: { activeDepartmentId },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [denyLoading, setDenyLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);

  const onManage = () => {
    setModalSwapShiftDetailsVisible(true);
    setActiveSwapShift(swapShift);
  };

  const onDelete = () => {
    setDeleteLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/swap-shift/${swapShift.swapShift.id}`, {
        params: {
          departmentId: activeDepartmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        setDeleteLoading(false);
        setSwapShifts(swapShifts.filter((ss) => ss.swapShift.id !== swapShift.swapShift.id));
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data?.errors) {
          setShowErrorsModal(true);
          setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
        }
      });
  };

  const onDeny = () => {
    setDenyLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .patch(`${process.env.REACT_APP_API_URL}/v3/swap-shift/${swapShift.swapShift.id}/cancel`, null, {
        params: {
          departmentId: activeDepartmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        setDenyLoading(false);
        setSwapShifts(swapShifts.filter((ss) => ss.swapShift.id !== swapShift.swapShift.id));
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data?.errors) {
          setShowErrorsModal(true);
          setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
        }
      });
  };

  const onErrorsOk = () => {
    setShowErrorsModal(false);
  };

  const onErrorsCancel = () => {
    setShowErrorsModal(false);
  };

  return (
    <div className={className}>
      <Modal
        forceRender={true}
        destroyOnClose={true}
        visible={showErrorsModal}
        className={'modal-danger'}
        title={t('SWAP_SHIFT.DELETE_ERROR')}
        onOk={onErrorsOk}
        onCancel={onErrorsCancel}
        footer={[
          <Button key="back" onClick={onErrorsCancel}>
            {t('GLOBAL.OK')}
          </Button>,
        ]}
      >
        <List
          dataSource={errors}
          renderItem={(item) => (
            <List.Item>
              <p>{item}</p>
            </List.Item>
          )}
        />
      </Modal>
      <div className="actions">
        <Tooltip overlay={<span>{t('GLOBAL.MANAGE')}</span>}>
          <Button type="primary" onClick={onManage}>
            <i className="icon-cog-alt" />
          </Button>
        </Tooltip>
        {!swapShift.swapShift.accepted_at && swapShift.swapShift.active && (
          <Tooltip overlay={<span>{t('GLOBAL.REFUSE')}</span>}>
            <Button type="primary" danger onClick={onDeny} loading={denyLoading} disabled={denyLoading}>
              <i className="icon-cancel" />
            </Button>
          </Tooltip>
        )}
        {!swapShift.swapShift.active && (
          <Tooltip overlay={<span>{t('GLOBAL.DELETE')}</span>}>
            <Button type="primary" danger onClick={onDelete} loading={deleteLoading} disabled={deleteLoading}>
              <i className="icon-trash-empty" />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default styled(Actions)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .actions {
    display: flex;
    gap: 10px;
  }
`;
