import { Button, Checkbox, message, Popconfirm, Table, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import { IHrRule } from '@/types/hr-rule.model';
import { getContrast, WEEKDAYS } from '@/utils';
import axios from 'axios';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  rules: IHrRule[];
  hrPartners: any[];
  setActiveRule: React.Dispatch<React.SetStateAction<IHrRule | null>>;
  setRules: React.Dispatch<React.SetStateAction<IHrRule[]>>;
  getRules: () => void;
}

const SortableElementItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerItem = SortableContainer((props: any) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />);

const HrRuleTable: React.FC<Props> = ({ className, hrPartners, rules, setActiveRule, setRules, getRules }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, userCategories },
  } = useContext(AppContext);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    setColumns([
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      {
        title: '#',
        render: (text: string, record: IHrRule) => {
          const index = rules.findIndex((rule) => rule.id == record.id);
          return `${index + 1}`;
        },
      },
      {
        title: t('GLOBAL.CODE'),
        dataIndex: 'hr_timesheet_code_id',
        key: 'hr_timesheet_code_id',
        render: (text: string, record: IHrRule) => {
          const codes = hrPartners.reduce((acc, obj) => {
            return acc.concat(obj.codes);
          }, []);
          const found = codes.find((code: any) => code.id == record.hr_timesheet_code_id);

          if (!found) return;

          return (
            <Tooltip overlay={<span>{found.name}</span>}>
              <span>{found.code}</span>
            </Tooltip>
          );
        },
      },
      {
        title: t('GLOBAL.TYPE'),
        dataIndex: 'type',
        key: 'type',
        render: (text: string, record: IHrRule) => {
          return t(`HR.RULES.TIMESHEET_TYPES.${record.type.toUpperCase()}`);
        },
      },
      {
        title: t('GLOBAL.STATUS'),
        dataIndex: 'statuses',
        key: 'statuses',
        width: 250,
        render: (text: string, record: IHrRule) => {
          if (!userCategories) return;

          if (record.statuses.length == 0) return t('GLOBAL.ALL_STATUSES');

          return (
            <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 15, rowGap: 5 }}>
              {record.statuses.map((status: string) => {
                const found = userCategories.find((category) => category.id == status);
                if (found) {
                  return (
                    <span
                      style={{
                        backgroundColor: found.background !== '#FFFFFF' ? found.background : '#c8c8c8',
                        color: getContrast(found.background as any),
                        padding: '2px 10px',
                        borderRadius: 50,
                      }}
                    >
                      {found.name}
                    </span>
                  );
                }
              })}
            </div>
          );
        },
      },
      {
        title: t('GLOBAL.HOURS'),
        render: (text: string, record: IHrRule) => {
          return (
            <div style={{ display: 'flex' }}>
              <span className="hour-container">{record.start_time}</span>
              <i className="icon-up-big" style={{ transform: 'rotate(90deg)' }} />
              <span className="hour-container" style={{ marginLeft: 3 }}>
                {record.end_time}
              </span>
            </div>
          );
        },
      },
      {
        title: t('GLOBAL.DAY(S)'),
        width: 125,
        render: (text: string, record: IHrRule) => {
          if (record.days.length == 7) {
            return t('GLOBAL.EVERYDAY');
          }
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {record.days.map((day: string) => (
                <span>{t(`GLOBAL.${WEEKDAYS[Number(day)].toUpperCase()}`)}</span>
              ))}
            </div>
          );
        },
      },
      {
        title: t('HR.RULES.OVERHOURS'),
        dataIndex: 'overhours',
        key: 'overhours',
        render: (text: string, record: IHrRule) => {
          if (!record.overhours_direction || !record.overhours_type) return;

          return (
            <span>
              {t(`GLOBAL.${record.overhours_direction.toUpperCase()}`)}{' '}
              {t(`HR.RULES.OVERHOURS_TYPES.${record.overhours_type.toUpperCase()}`).toLowerCase()}
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.DETAILS'),
        width: 200,
        render: (text: string, record: IHrRule) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              {record.holiday ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{t('GLOBAL.HOLIDAY')}</span>
                  <Checkbox disabled checked={record.holiday} />
                </div>
              ) : null}
              {record.add_default_code ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{t('HR.RULES.ADD_DEFAULT')}</span>
                  <Checkbox disabled checked={record.add_default_code} />
                </div>
              ) : null}
              {record.override_default_code ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{t('HR.RULES.OVERRIDE_DEFAULT')}</span>
                  <Checkbox disabled checked={record.override_default_code} />
                </div>
              ) : null}
              {record.subtract_default_code ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{t('HR.RULES.SUBTRACT_DEFAULT')}</span>
                  <Checkbox disabled checked={record.subtract_default_code} />
                </div>
              ) : null}
              {record.overnight ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{t('HR.RULES.OVERNIGHT')}</span>
                  <Checkbox disabled checked={record.overnight} />
                </div>
              ) : null}
              {record.regime ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{t(`CONTRACTS.REGIME_TYPES.${record.regime}`)}</span>
                  <Checkbox disabled checked />
                </div>
              ) : null}
              {record.stop ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>Stop</span>
                  <Checkbox disabled checked={record.stop} />
                </div>
              ) : null}
            </div>
          );
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        render: (text: string, record: IHrRule) => {
          return (
            <div style={{ display: 'flex', gap: 10 }}>
              <Tooltip overlay={<span>{t('GLOBAL.EDIT')}</span>} placement="bottom">
                <Button type="primary" onClick={() => setActiveRule(record)}>
                  <i className="icon-edit" />
                </Button>
              </Tooltip>
              <Popconfirm
                title={t('GLOBAL.ARE_YOU_SURE?')}
                okText={t('GLOBAL.YES')}
                cancelText={t('GLOBAL.NO')}
                onConfirm={() => deleteRule(record)}
              >
                <Tooltip overlay={<span>{t('GLOBAL.DELETE')}</span>} placement="bottom">
                  <Button type="primary" danger>
                    <i className="icon-trash-empty" />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </div>
          );
        },
      },
    ]);
  }, [rules, userCategories]);

  const deleteRule = (record: IHrRule) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/hr/rules/${record.id}`, {
        params: {
          departmentId: activeDepartment?.id,
        },
      })
      .then(({ data }) => {
        setRules(rules.filter((rule) => rule.id !== record.id));
        getRules();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          message.error(Object.values(err.response.data.errors).flat(1)[0]);
        } else {
          message.error(t('HR.RULES.DELETE_ERROR'));
        }
      });
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      const rule = rules.find((x) => x.position == oldIndex + 1);

      if (!rule) return;

      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/hr/rules/${rule.id}/order`,
          {
            ...rule,
            new_index: newIndex + 1,
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }) => {
          getRules();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            message.error(Object.values(err.response.data.errors).flat(1)[0]);
          } else {
            message.error(t('HR.RULES.UPDATE_ERROR'));
          }
        });
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerItem useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = rules.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableElementItem index={index} {...restProps} />;
  };

  return (
    <Table
      className={className}
      dataSource={rules}
      columns={columns}
      loading={false}
      pagination={false}
      rowKey="id"
      size="small"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};

export default styled(HrRuleTable)`
  * {
    font-size: 11px;
  }
  td {
    vertical-align: top;
  }

  .hour-container {
    background-color: #000;
    color: #fff;
    padding: 2px 10px;
    border-radius: 50px;
  }
`;
