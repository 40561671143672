import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IAttribut } from '@/types/attribut.model';
import { FEATURES } from '@/types/features.model';
import { IReportTemplate } from '@/types/report-template.model';
import { ITimesheetView } from '@/types/timesheet-view.model';
import { IExtraColum } from '@/types/timesheet.model';
import { getWindowSize, isFeatureEnabled, isTrial, localDateToUnixUTC, saveBlob, valueForSearch } from '@/utils';
import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Dropdown, Form, Menu, Modal, Popover, Select, Space, message, notification } from 'antd';
import { FormInstance } from 'antd/lib/form';
import Axios from 'axios';
import { isEmpty } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Options from '../../components/Options';
import HeaderOptionDetails from './HeaderOptionDetails';
import ModalSaveView from './ModalSaveView';

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;

interface Props {
  className?: string;
  form: FormInstance<any>;
  columns: any[];
  hideColumns: { [key: string]: boolean };
  loadingDataSource: boolean;
  views: ITimesheetView[];
  counter: number;
  setViews: React.Dispatch<React.SetStateAction<ITimesheetView[]>>;
  setExtraColumns: React.Dispatch<React.SetStateAction<IExtraColum[]>>;
  setHideColumns: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  setDataSource: React.Dispatch<React.SetStateAction<any[]>>;
  setHiddenColumns: React.Dispatch<React.SetStateAction<string[]>>;
  setLoadingDataSource: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderHeight: React.Dispatch<React.SetStateAction<number>>;
  setClockingModalParams: React.Dispatch<React.SetStateAction<any>>;
}

const Header = React.forwardRef<unknown, Props>(
  (
    {
      className,
      form,
      columns,
      hideColumns,
      loadingDataSource,
      views,
      counter,
      setViews,
      setHideColumns,
      setHiddenColumns,
      setDataSource,
      setExtraColumns,
      setLoadingDataSource,
      setHeaderHeight,
      setClockingModalParams,
    },
    ref,
  ) => {
    const {
      state: {
        activeDepartment,
        activeSection,
        users,
        dayoffs,
        resources,
        sections,
        skills,
        loadingUserCategories,
        userCategories,
        features,
      },
    } = useContext(AppContext);
    const { pathname } = useLocation();
    const routes = pathname.split('/');
    const [layout, viewId] = routes.slice(routes.indexOf('timesheets') + 1);
    const history = useHistory();
    const { t } = useTranslation(undefined, { useSuspense: false });
    const [rangePickerValues, setRangePickerValues] = useState<[Moment, Moment]>([
      moment().startOf('month'),
      moment().endOf('month'),
    ]);
    const [view, setView] = useState<ITimesheetView | null>();
    const [selectedUserOptions, setSelectedUserOptions] = useState<{ children: string }[]>([]);
    const [selectedUserValues, setSelectedUserValues] = useState<string[]>([]);
    const [selectedAttributeOptions, setSelectedAttributeOptions] = useState<{ children: string }[]>([]);
    const [selectedAttributeValues, setSelectedAttributeValues] = useState<string[]>([]);
    const [showModalCreateView, setShowModalCreateView] = useState<boolean>(false);
    const [isDownloadingPDF, setIsDownloadingPDF] = useState<boolean>(false);
    const [isDownloadingXLS, setIsDownloadingXSL] = useState<boolean>(false);
    const [customReports, setCustomReports] = useState<{ name: string; endpoint: string }[]>([]);
    const [customReportTemplates, setCustomReportTemplates] = useState<IReportTemplate[]>([]);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const usersSelect = useRef(null);
    const attributesSelect = useRef(null);

    useEffect(() => {
      const handleResize = () => {
        setWindowSize(getWindowSize());
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      let mounted = true;

      if (activeDepartment && mounted) {
        Axios.get(`${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template`, {
          params: {
            departmentId: activeDepartment.id,
            active: true,
          },
        })
          .then(({ data }) => {
            setCustomReportTemplates(data.templates);
          })
          .catch((err) => {
            console.log(err);
            message.error(Object.values(err.response.data.errors).flat(1)[0]);
          });
      }

      return () => {
        mounted = false;
      };
    }, [activeDepartment]);

    useEffect(() => {
      setDataSource([]);
      setExtraColumns([]);
      setHiddenColumns([]);
      setSelectedUserOptions([]);
      setSelectedUserValues([]);
      setSelectedAttributeOptions([]);
      setSelectedAttributeValues([]);
      setHideColumns({});
      setCustomReports([]);
      setView(null);
      form.resetFields();
      form.setFieldsValue({
        ['range-picker']: rangePickerValues,
        sort: 'user',
      });
      if (activeDepartment) {
        let mounted = true;
        setLoadingDataSource(true);

        const defaultParams: any = {};
        if (!['default'].includes(layout)) {
          return;
        } else {
          const [start, end] = rangePickerValues;
          defaultParams.start = localDateToUnixUTC(start.toDate());
          defaultParams.end = localDateToUnixUTC(end.endOf('day').toDate());
          if (viewId) {
            const timesheetView = views.find((x) => x.id === viewId);
            if (timesheetView) {
              setView(timesheetView);
              const {
                hideColumns = {},
                users = [],
                attributes = [],
                sections = [],
                dayoffs = [],
                skills = [],
                statuses = [],
                sort = 'user',
              } = timesheetView;
              form.setFieldsValue({
                users,
                attributes: [
                  ...statuses?.map((x) => `status.${x}`),
                  ...sections?.map((x) => `section.${x}`),
                  ...skills?.map((x) => `skill.${x}`),
                  ...attributes?.map((x) => `attribute.${x}`),
                  ...dayoffs?.map((x) => `dayoff.${x}`),
                ],
                sort,
              });
              setHideColumns(hideColumns);
            } else {
              history.push('/app/reports/timesheets/default');
            }
          }
        }

        const {
          users: selectedUsers = [],
          attributes: attributesFieldValues = [],
          sort = 'user',
        } = form.getFieldsValue();
        const {
          skills: selectedSkills = [],
          statuses: selectedStatuses = [],
          sections: selectedSections = [],
          attributes: selectedAttributes = [],
          dayoffs: selectedDayoffs = [],
        } = getAttributeIdsByCategory(attributesFieldValues);

        const cancelTokenSource = Axios.CancelToken.source();
        Axios.get(`${process.env.REACT_APP_API_URL}/v3/reports/timesheets`, {
          params: {
            ...defaultParams,
            departmentId: activeDepartment?.id,
            sectionId: activeSection,
            users: selectedUsers,
            skills: selectedSkills,
            sections: selectedSections,
            attributes: selectedAttributes,
            dayoffs: selectedDayoffs,
            statuses: selectedStatuses,
            sort,
          },
          cancelToken: cancelTokenSource.token,
        })
          .then((response) => {
            const {
              data: { dataSource = [], extraColumns = [], hiddenColumns = [], customReports = [] },
            } = response;
            if (mounted) {
              setCustomReports(customReports);
              setDataSource(dataSource);
              setExtraColumns(extraColumns);
              setHiddenColumns(hiddenColumns);
              setLoadingDataSource(false);
            }
          })
          .catch(() => {
            if (mounted) {
              setLoadingDataSource(false);
            }
          });

        const selectedAttributeOptions: { children: string }[] = [];
        const selectedAttributeValues: string[] = [];
        const selectedUserOptions: { children: string }[] = [];
        const selectedUserValues: string[] = [];

        const allAttributes: IAttribut[] = [];
        for (let i = 0; i < resources.length; i++) {
          const attributes = resources[i]?.attributes || [];
          for (let j = 0; j < attributes.length; j++) {
            allAttributes.push(...attributes);
          }
        }

        const allAttributeIdsByCategory: any[] = [
          [selectedStatuses, userCategories, 'status'],
          [selectedSections, sections, 'section'],
          [selectedSkills, skills, 'skill'],
          [selectedAttributes, allAttributes, 'attribute'],
          [selectedDayoffs, dayoffs, 'dayoff'],
        ];

        for (let i = 0; i < allAttributeIdsByCategory.length; i++) {
          const [selectedItems, items, type] = allAttributeIdsByCategory[i];
          if (selectedItems?.length && items?.length) {
            const itemsToCheck = [...selectedItems];
            for (let index = 0; index < items.length; index++) {
              const { id, name } = items[index];
              const itemToCheckIndex = itemsToCheck.findIndex((x) => x === id);
              if (~itemToCheckIndex) {
                itemsToCheck.splice(itemToCheckIndex, 1);
                selectedAttributeOptions.push({ children: name! });
                selectedAttributeValues.push(`${type}.${id}`);
              }
              if (!itemsToCheck?.length) {
                break;
              }
            }
          }
        }

        if (selectedUsers?.length && users?.length) {
          const itemsToCheck = [...selectedUsers];
          for (let index = 0; index < users.length; index++) {
            const item = users[index];
            const { recordId, displayName } = item;
            const itemToCheckIndex = itemsToCheck.findIndex((x) => x === recordId);
            if (~itemToCheckIndex) {
              itemsToCheck.splice(itemToCheckIndex, 1);
              selectedUserOptions.push({ children: displayName! });
              selectedUserValues.push(`${recordId}`);
            }
            if (!itemsToCheck?.length) {
              break;
            }
          }
        }

        setSelectedUserOptions(selectedUserOptions);
        setSelectedUserValues(selectedUserValues);
        setSelectedAttributeOptions(selectedAttributeOptions);
        setSelectedAttributeValues(selectedAttributeValues);

        return () => {
          mounted = false;
          cancelTokenSource.cancel();
        };
      }
    }, [activeDepartment, activeSection, pathname]);

    useEffect(() => {
      if (counter) {
        form.submit();
      }
    }, [counter]);

    useEffect(() => {
      setTimeout(() => {
        const height: number = ((ref as any)?.current as any)?.clientHeight || 0;
        setHeaderHeight(height);
      }, 0);
    }, [selectedUserOptions, selectedAttributeOptions]);

    const onUsersChange = (value: string[], options: any) => {
      setSelectedUserValues(value);
      setSelectedUserOptions(options);
    };

    const onAttributesChange = (value: string[], options: any) => {
      setSelectedAttributeValues(value);
      setSelectedAttributeOptions(options);
    };

    const onDeleteAttributeOption = (index: number) => {
      const selectedAttributeOptionsCopy = [...selectedAttributeOptions];
      const selectedAttributeValuesCopy = [...selectedAttributeValues];
      selectedAttributeOptionsCopy.splice(index, 1);
      selectedAttributeValuesCopy.splice(index, 1);
      form.setFieldsValue({
        attributes: selectedAttributeValuesCopy,
      });
      setSelectedAttributeValues(selectedAttributeValuesCopy);
      setSelectedAttributeOptions(selectedAttributeOptionsCopy);
    };

    const onDeleteUserOption = (index: number) => {
      const selectedUserOptionsCopy = [...selectedUserOptions];
      const selectedUserValuesCopy = [...selectedUserValues];
      selectedUserOptionsCopy.splice(index, 1);
      selectedUserValuesCopy.splice(index, 1);
      form.setFieldsValue({
        users: selectedUserValuesCopy,
      });
      setSelectedUserValues(selectedUserValuesCopy);
      setSelectedUserOptions(selectedUserOptionsCopy);
    };

    const saveView = () => {
      setShowModalCreateView(true);
    };

    const deleteView = () => {
      Modal.confirm({
        title: t('GLOBAL.DELETION'),
        icon: null,
        content: t('REPORTS.HR_TIMESHEETS.MODAL_DELETE_VIEW.CONTENT', { name: view?.name }),
        cancelText: t('GLOBAL.CANCEL'),
        okText: t('GLOBAL.REMOVE'),
        okType: 'danger',
        onOk: () => {
          onDeleteView(viewId);
        },
        onCancel: () => {},
      });
    };

    const onDeleteView = (viewId: string) => {
      Axios.delete(`${process.env.REACT_APP_API_URL}/v3/reports/custom-timesheets/${viewId}`, {
        params: {
          departmentId: activeDepartment?.id,
          sectionId: activeSection,
        },
      })
        .then((response) => {
          setViews(response.data);
          history.push(`/app/reports/timesheets/default`);
        })
        .catch((error) => {
          message.error(t('REPORTS.HR_TIMESHEETS.MODAL_DELETE_VIEW..MESSAGE_DELETING_ERROR'));
        });
    };

    const onViewSave = (results: ITimesheetView[]) => {
      setViews(results);
      const created = results.find((x) => x.created);
      if (created) {
        history.push(`/app/reports/timesheets/default/${created.id}`);
      }
    };

    const onFinish = (values: any) => {
      if (loadingDataSource) {
        return;
      }
      const {
        ['range-picker']: [start, end],
        attributes: formAttributes = [],
        sort,
        users = [],
      } = values;

      setDataSource([]);
      setExtraColumns([]);
      setHiddenColumns([]);

      if (activeDepartment?.id) {
        setLoadingDataSource(true);

        const { skills, statuses, sections, attributes, dayoffs } = getAttributeIdsByCategory(formAttributes);

        const cancelTokenSource = Axios.CancelToken.source();
        Axios.get(`${process.env.REACT_APP_API_URL}/v3/reports/timesheets`, {
          params: {
            start: start ? localDateToUnixUTC(start.toDate()) : undefined,
            end: end ? localDateToUnixUTC(end.endOf('day').toDate()) : undefined,
            departmentId: activeDepartment?.id,
            sectionId: activeSection,
            users,
            statuses,
            skills,
            sections,
            attributes,
            dayoffs,
            sort,
          },
          cancelToken: cancelTokenSource.token,
        })
          .then((response) => {
            const {
              data: { dataSource = [], extraColumns = [], hiddenColumns = [] },
            } = response;
            setDataSource(dataSource);
            setExtraColumns(extraColumns);
            setHiddenColumns(hiddenColumns);
            setLoadingDataSource(false);
          })
          .catch(() => {
            setLoadingDataSource(false);
          });
      }
    };

    const onDownloadReport = (endPoint?: string) => {
      const {
        ['range-picker']: [start, end],
        attributes: formAttributes = [],
        users = [],
        sort = 'user',
      } = form.getFieldsValue();

      if (activeDepartment) {
        setIsDownloadingXSL(true);
        const { skills, statuses, sections: formSections, attributes, dayoffs } = getAttributeIdsByCategory(
          formAttributes,
        );
        Axios.get(
          !endPoint
            ? `${process.env.REACT_APP_API_URL}/v3/reports/timesheets`
            : `${process.env.REACT_APP_API_URL}/v3${endPoint}`,
          {
            params: {
              start: start ? localDateToUnixUTC(start.toDate()) : undefined,
              end: end ? localDateToUnixUTC(end.endOf('day').toDate()) : undefined,
              departmentId: activeDepartment?.id,
              sectionId: activeSection,
              users,
              statuses,
              skills,
              formSections,
              attributes,
              dayoffs,
              mode: 'XLS',
              hideColumns,
              sort,
            },
            responseType: 'blob',
          },
        )
          .then((response) => {
            if (endPoint && endPoint != '/reports/sage-coala') {
              const endpoint_array = endPoint?.split('/');
              const report_name = endpoint_array && endpoint_array[endpoint_array.length - 1];
              saveBlob(response.data, `${report_name ? report_name : 'report'}${moment().unix()}.xls`);
            }

            setIsDownloadingXSL(false);
          })
          .catch((error) => {
            console.log(error);
            message.error(t('GLOBAL.MESSAGE_DOWNLOAD_ERROR'));
            setIsDownloadingXSL(false);
          });
      }
    };

    const onDownload = (mode: string) => {
      const {
        ['range-picker']: [start, end],
        attributes: formAttributes = [],
        users = [],
        sort = 'user',
      } = form.getFieldsValue();

      if (activeDepartment) {
        if (mode === 'PDF') {
          setIsDownloadingPDF(true);
        } else if (mode === 'XLS') {
          setIsDownloadingXSL(true);
        }
        const { skills, statuses, sections, attributes, dayoffs } = getAttributeIdsByCategory(formAttributes);
        Axios.get(`${process.env.REACT_APP_API_URL}/v3/reports/timesheets`, {
          params: {
            start: start ? localDateToUnixUTC(start.toDate()) : undefined,
            end: end ? localDateToUnixUTC(end.endOf('day').toDate()) : undefined,
            departmentId: activeDepartment?.id,
            sectionId: activeSection,
            users,
            statuses,
            skills,
            sections,
            attributes,
            dayoffs,
            mode,
            hideColumns,
            sort,
          },
          responseType: 'blob',
        })
          .then((response) => {
            // let fileURL;
            notification.open({
              type: 'info',
              message: t('NOTIFS.PREPARING_FILE_FOR_DOWNLOAD.MESSAGE'),
              description: t('NOTIFS.PREPARING_FILE_FOR_DOWNLOAD.DESCRIPTION'),
            });
            if (mode === 'PDF') {
              setIsDownloadingPDF(false);
              //   const file = new Blob([response.data], { type: 'application/pdf' });
              //   fileURL = URL.createObjectURL(file);
            } else if (mode === 'XLS') {
              setIsDownloadingXSL(false);
              //   const file = new Blob([response.data], {
              //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              //   });
              //   fileURL = URL.createObjectURL(file);
            }
            // if (fileURL) {
            //   window.open(fileURL);
            // }
          })
          .catch((error) => {
            console.log(error);
            message.error(t('GLOBAL.MESSAGE_DOWNLOAD_ERROR'));
            if (mode === 'PDF') {
              setIsDownloadingPDF(false);
            } else if (mode === 'XLS') {
              setIsDownloadingXSL(false);
            }
          });
      }
    };

    const generateCustomTemplate = (report: IReportTemplate, mode: 'XLS' | 'CSV') => {
      const {
        ['range-picker']: [start, end],
        attributes: formAttributes = [],
        users = [],
        sort = 'user',
      } = form.getFieldsValue();
      const { skills, statuses, sections, attributes, dayoffs } = getAttributeIdsByCategory(formAttributes);

      if (activeDepartment) {
        Axios.post(
          `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${report.id}/generate`,
          {},
          {
            params: {
              start: start ? localDateToUnixUTC(start.toDate()) : undefined,
              end: end ? localDateToUnixUTC(end.endOf('day').toDate()) : undefined,
              departmentId: activeDepartment?.id,
              sectionId: activeSection,
              users,
              statuses,
              skills,
              sections,
              attributes,
              dayoffs,
              mode,
              hideColumns,
              sort,
            },
            responseType: 'blob',
          },
        )
          .then(({ data }) => {
            notification.open({
              type: 'info',
              message: t('NOTIFS.PREPARING_FILE_FOR_DOWNLOAD.MESSAGE'),
              description: t('NOTIFS.PREPARING_FILE_FOR_DOWNLOAD.DESCRIPTION'),
            });
          })
          .catch((err) => {
            message.error(Object.values(err.response.data.errors).flat(1)[0]);
          });
      }
    };

    return (
      <div ref={ref as any} className={className}>
        <header>
          <div className="top">
            <div className="left">
              <Form form={form} onFinish={onFinish}>
                <div className="form-items-group one-line">
                  {layout === 'default' && (
                    <React.Fragment>
                      <Form.Item
                        className={'one-line'}
                        name="range-picker"
                        rules={[{ type: 'array' as const, required: true, message: 'Please select time!' }]}
                      >
                        <RangePicker
                          allowClear={false}
                          getPopupContainer={(trigger) => trigger}
                          format={'L'}
                          value={rangePickerValues}
                          onChange={(e: any) => setRangePickerValues(e)}
                          renderExtraFooter={() => <RangePickerFooter myForm={form} />}
                        />
                      </Form.Item>
                      <React.Fragment>
                        <Form.Item name="users" className="select-users one-line">
                          <Select
                            ref={usersSelect}
                            clearIcon
                            getPopupContainer={(trigger) => trigger}
                            showSearch
                            mode="multiple"
                            maxTagTextLength={windowSize.innerWidth > 900 ? 7 : users.length}
                            maxTagCount={windowSize.innerWidth > 900 ? 1 : users.length}
                            placeholder={t('GLOBAL.USERS')}
                            optionFilterProp="children"
                            onChange={onUsersChange}
                            value={selectedUserValues}
                            filterOption={(input, option) =>
                              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {users.map((user) => (
                              <Option key={`user_${user.recordId}`} value={`${user.recordId}`}>
                                {user.displayName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item name="attributes" className={'one-line'}>
                          <Select
                            className="select-attributes"
                            ref={attributesSelect}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            mode="multiple"
                            placeholder={`${t('GLOBAL.USER_CATEGORIES')}, ${t('GLOBAL.SECTIONS').toLowerCase()}, ${t(
                              'GLOBAL.SKILLS',
                            ).toLowerCase()}, ...`}
                            maxTagTextLength={windowSize.innerWidth > 900 ? 3 : users.length}
                            maxTagCount={
                              windowSize.innerWidth > 900
                                ? 2
                                : userCategories.length +
                                  sections.length +
                                  skills.length +
                                  resources.length +
                                  dayoffs.length
                            }
                            loading={loadingUserCategories}
                            suffixIcon={<i className="icon-search" />}
                            onChange={onAttributesChange}
                            value={selectedAttributeValues}
                            filterOption={(input, option) => {
                              if (option) {
                                if (!option.label) {
                                  let children = option.children;
                                  if (Array.isArray(children)) {
                                    children = children.join();
                                  }
                                  return valueForSearch(children).includes(valueForSearch(input));
                                }
                              }
                              return false;
                            }}
                          >
                            {!!userCategories?.length && (
                              <OptGroup label={t('GLOBAL.USER_CATEGORIES')}>
                                {userCategories.map((userCategory) => (
                                  <Option key={`status.${userCategory.id}`} value={`status.${userCategory.id}`}>
                                    {userCategory.name}
                                  </Option>
                                ))}
                              </OptGroup>
                            )}
                            {isFeatureEnabled(features, FEATURES.SECTIONS) && !!sections?.length && (
                              <OptGroup label={t('GLOBAL.SECTIONS')}>
                                {sections.map((section) => (
                                  <Option key={`section_${section.id}`} value={`section.${section.id}`}>
                                    {section.name}
                                  </Option>
                                ))}
                              </OptGroup>
                            )}
                            {isFeatureEnabled(features, FEATURES.SKILLS) && !!skills?.length && (
                              <OptGroup label={t('GLOBAL.SKILLS')}>
                                {skills.map((skill) => (
                                  <Option key={`skill.${skill.id}`} value={`skill.${skill.id}`}>
                                    {skill.name}
                                  </Option>
                                ))}
                              </OptGroup>
                            )}
                            {resources
                              .filter((resource) => resource?.attributes?.length)
                              .map((resource) => (
                                <OptGroup label={resource.name} key={`resource_${resource.id}`}>
                                  {(resource.attributes || []).map((attribute) => (
                                    <Option value={`attribute.${attribute.id}`} key={`attribute.${attribute.id}`}>
                                      {attribute.name}
                                    </Option>
                                  ))}
                                </OptGroup>
                              ))}
                            {!!dayoffs?.length &&
                              !(isTrial(activeDepartment!) && !isFeatureEnabled(features, FEATURES.SCHEDULE)) && (
                                <OptGroup label={t('GLOBAL.ABSENCES')}>
                                  {dayoffs.map((dayoff) => (
                                    <Option key={`dayoff.${dayoff.id}`} value={`dayoff.${dayoff.id}`}>
                                      {dayoff.name}
                                    </Option>
                                  ))}
                                </OptGroup>
                              )}
                          </Select>
                        </Form.Item>
                        <Form.Item name="sort" className={'one-line'}>
                          <Select className="select-sort">
                            <Option value="user">{t('GLOBAL.SORT_BY_NAME')}</Option>
                            <Option value="day">{t('GLOBAL.SORT_BY_DATE')}</Option>
                          </Select>
                        </Form.Item>
                      </React.Fragment>
                      <Form.Item>
                        <Button
                          loading={loadingDataSource}
                          type="primary"
                          htmlType="submit"
                          style={{ marginRight: '10px' }}
                        >
                          {t('GLOBAL.FILTER')}
                        </Button>
                      </Form.Item>
                    </React.Fragment>
                  )}
                </div>
              </Form>
            </div>
            <div className="right">
              <div className="actions">
                <Space>
                  <Select
                    style={{ width: 200 }}
                    value={layout ? `/app/reports/timesheets/${viewId ? layout + '/' + viewId : layout}` : undefined}
                    onChange={(e, _option) => {
                      history.push(`${e}`);
                    }}
                  >
                    <Option value="/app/reports/timesheets/default">{t('GLOBAL.DEFAULT')}</Option>
                    {!!views?.length && (
                      <OptGroup label="Custom">
                        {views?.map((timesheetView) => {
                          const { id, name } = timesheetView;
                          return (
                            <Option key={`custom-timesheet_${id}`} value={`/app/reports/timesheets/default/${id}`}>
                              {name}
                            </Option>
                          );
                        })}
                      </OptGroup>
                    )}
                  </Select>
                  {/* <Popover
                    placement="bottomRight"
                    content={
                      <Button
                        loading={isDownloadingXLS}
                        type="link"
                        style={{ display: 'block' }}
                        onClick={() => onDownload('XLS')}
                      >
                        XLS
                      </Button>
                    }
                    trigger="click"
                  >
                    <Button loading={isDownloadingXLS} shape="circle" className="action">
                      <i className="icon-download" />
                    </Button>
                  </Popover> */}
                  {!customReports?.length && !customReportTemplates?.length && (
                    <Button
                      loading={isDownloadingXLS}
                      onClick={() => onDownload('XLS')}
                      type="primary"
                      className="action"
                    >
                      {!isDownloadingXLS && <i className="icon-download" />}
                    </Button>
                  )}
                  {(!!customReports?.length || !!customReportTemplates.length) && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => onDownloadReport()}>{t('GLOBAL.DEFAULT')}</Menu.Item>
                          {customReports.map((customReport, index) => {
                            if (!customReport) return;
                            return (
                              <Menu.Item
                                key={`report_${index}`}
                                onClick={() => onDownloadReport(customReport.endpoint)}
                              >
                                {customReport.name}
                              </Menu.Item>
                            );
                          })}
                          {customReportTemplates.map((template, index) => {
                            return (
                              <>
                                <Menu.Item
                                  key={`custom_template_${index}`}
                                  onClick={() => generateCustomTemplate(template, 'XLS')}
                                >
                                  {template.name} Excel
                                </Menu.Item>
                                <Menu.Item
                                  key={`custom_template_${index}`}
                                  onClick={() => generateCustomTemplate(template, 'CSV')}
                                >
                                  {template.name} CSV
                                </Menu.Item>
                              </>
                            );
                          })}
                        </Menu>
                      }
                      trigger={['click']}
                    >
                      <Button
                        loading={isDownloadingXLS}
                        type="primary"
                        className="action"
                        onClick={(e) => e.preventDefault()}
                      >
                        {!isDownloadingXLS && <i className="icon-download" />}
                      </Button>
                    </Dropdown>
                  )}
                  <Options>
                    <Popover
                      placement="bottomLeft"
                      content={() => (
                        <HeaderOptionDetails
                          columns={columns}
                          hideColumns={hideColumns}
                          setHideColumns={setHideColumns}
                          setLoadingDataSource={setLoadingDataSource}
                        />
                      )}
                    >
                      <div className="option">
                        <i className="icon-toggle-on" />
                      </div>
                    </Popover>
                  </Options>
                  {activeDepartment?.accountType !== 'RGPD' && isFeatureEnabled(features, FEATURES.CLOCKING) && (
                    <Button onClick={() => setClockingModalParams({})}>{t('CLOCKINGS.CREATE_CLOCKING')}</Button>
                  )}
                </Space>
              </div>
            </div>
          </div>
          {(!!viewId ||
            !isEmpty(hideColumns) ||
            !!selectedUserOptions?.length ||
            !!selectedAttributeOptions?.length) && (
            <div className="bottom">
              <div className="filters">
                {selectedUserOptions?.map((option, index) => {
                  return (
                    <span className="filter" key={`option_users_${index}`}>
                      {option.children}
                      <CloseOutlined onClick={() => onDeleteUserOption(index)} />
                    </span>
                  );
                })}
                {selectedAttributeOptions?.map((option, index) => {
                  return (
                    <span className="filter" key={`option_attributes_${index}`}>
                      {option.children}
                      <CloseOutlined onClick={() => onDeleteAttributeOption(index)} />
                    </span>
                  );
                })}
                <button style={{ color: colors.green }} onClick={saveView}>
                  <i className="icon-save" style={{ marginRight: 4 }} />
                  {t('REPORTS.CONTROL_OF_SERVICES.SAVE_VIEW')}
                </button>
                {!!viewId && (
                  <button style={{ color: colors.red }} onClick={deleteView}>
                    <i className="icon-delete" style={{ marginRight: 4 }} />
                    {t('REPORTS.CONTROL_OF_SERVICES.REMOVE_VIEW')}
                  </button>
                )}
              </div>
            </div>
          )}
        </header>
        <ModalSaveView
          departmentId={activeDepartment?.id}
          name={view?.name}
          params={{ ...getAttributeIdsByCategory(selectedAttributeValues), users: selectedUserValues }}
          hideColumns={hideColumns}
          visible={showModalCreateView}
          onClose={() => setShowModalCreateView(false)}
          onSave={onViewSave}
        />
      </div>
    );
  },
);

Header.displayName = 'Header';

interface RangePickerFooterProps {
  myForm: FormInstance<any>;
}

const RangePickerFooter: React.FC<RangePickerFooterProps> = ({ myForm }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const changeDate = (start: Moment, end: Moment) => {
    myForm.setFieldsValue({
      ['range-picker']: [start, end],
    });
  };

  return (
    <div>
      <Button onClick={() => changeDate(moment().startOf('day'), moment().endOf('day'))}>{t('GLOBAL.TODAY')}</Button>
      <Button
        onClick={() =>
          changeDate(moment().startOf('day').subtract(1, 'day'), moment().endOf('day').subtract(1, 'days'))
        }
      >
        {t('GLOBAL.YESTERDAY')}
      </Button>
      <Button
        onClick={() =>
          changeDate(moment().startOf('day').subtract(8, 'day'), moment().endOf('day').subtract(1, 'days'))
        }
      >
        {t('GLOBAL.LAST_7_DAYS')}
      </Button>
      <Button
        onClick={() =>
          changeDate(moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'week'))
        }
      >
        {t('REPORTS.CONTROL_OF_SERVICES.WEEK-1')}
      </Button>
      <Button onClick={() => changeDate(moment().startOf('month'), moment().endOf('month'))}>
        {t('GLOBAL.THIS_MONTH')}
      </Button>
      <Button
        onClick={() =>
          changeDate(
            moment().startOf('month').subtract(1, 'month'),
            moment().startOf('month').subtract(1, 'month').endOf('month'),
          )
        }
      >
        {t('GLOBAL.LAST_MONTH')}
      </Button>
    </div>
  );
};

function getAttributeIdsByCategory(selectedAttributeValues: string[]) {
  const skills = [];
  const statuses = [];
  const sections = [];
  const attributes = [];
  const dayoffs = [];

  for (let index = 0; index < selectedAttributeValues.length; index++) {
    const [property, id] = selectedAttributeValues[index].split('.');
    switch (property) {
      case 'section':
        sections.push(id);
        break;
      case 'skill':
        skills.push(id);
        break;
      case 'attribute':
        attributes.push(id);
        break;
      case 'dayoff':
        dayoffs.push(id);
        break;
      case 'status':
        statuses.push(id);
        break;
      default:
        break;
    }
  }

  return {
    skills,
    statuses,
    sections,
    attributes,
    dayoffs,
  };
}

export default styled(Header)`
  .ant-picker-footer {
    display: block;
    .ant-picker-footer-extra {
      button {
        margin-right: 5px;
      }
    }
  }

  header {
    padding: 20px 10px;
    .top {
      flex: 1;
      display: flex;
      justify-content: space-between;

      .form-items-group.one-line {
        margin-bottom: 0px;

        .select-users {
          width: 200px;
        }

        .select-attributes {
          width: 300px;
        }

        .select-sort {
          width: 150px;
        }
      }
    }

    .bottom {
      .filters {
        padding-top: 10px;
        .filter {
          display: inline-block;
          background-color: #dbe4f7;
          margin-right: 7px;
          border-radius: 6px;
          padding: 2px 6px;
          font-size: 10px;
          font-weight: 500;

          .anticon-close {
            background: white;
            border-radius: 5px;
            font-size: 8px;
            padding: 2px;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
    }

    @media screen and (max-width: 900px) {
      .top {
        flex-direction: column;
        gap: 10px;

        .left {
          .form-items-group {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            > div,
            .select-users,
            .select-attributes,
            .select-sort {
              width: 100%;
            }

            .select-sort {
              .ant-select-arrow {
                display: none;
              }
            }
            .ant-picker-range {
              width: 100%;
            }

            button {
              width: 100%;
            }
          }
        }

        .right {
        }
      }
    }
  }
`;
