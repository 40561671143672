import React, { useEffect, useRef } from 'react';
import { Avatar, Button, message, Skeleton, Upload } from 'antd';
import styled from 'styled-components';
import Colors from '@/styles/colors';
import { IUser } from '@/types/user.model';
import ImgCrop from 'antd-img-crop';
import { useState } from 'react';
import Axios from 'axios';
import FormData from 'form-data';
import { useTranslation } from 'react-i18next';
import colors from '@/styles/colors';
import { useHistory } from 'react-router';
import ModalUpdatePinCode from './ModalUpdatePinCode';
interface Props {
  className?: string;
  user?: IUser;
  departmentId?: string;
  next?: string | null;
  previous?: string | null;
  route?: string;
}

const UserInfos: React.FC<Props> = ({ className, user, departmentId, next, previous, route }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const history = useHistory();
  const [fileList, setFileList] = useState<any[]>([]);
  const [showModalPinCode, setShowModalPinCode] = useState<boolean>(false);

  const refNext = useRef(next);
  refNext.current = next;

  const refPrevious = useRef(previous);
  refPrevious.current = previous;

  const refRoute = useRef(route);
  refRoute.current = route;

  const keyDownHandler = (e: any) => {
    const { keyCode } = e;
    if ([37, 39].includes(keyCode)) {
      if (document.activeElement === document.body) {
        onChangeUser(keyCode);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    setShowModalPinCode(false);
    if (user?.picture) {
      setFileList([
        {
          uid: '-1',
          status: 'done',
          url: user?.picture,
        },
      ]);
    } else {
      setFileList([]);
    }
  }, [user]);

  const onChangeUser = (keyCode: 37 | 39) => {
    const next = refNext.current;
    const previous = refPrevious.current;
    const route = refRoute.current;

    if (route) {
      if (keyCode === 39 && next) {
        history.replace(route?.replace('$', next));
      } else if (keyCode === 37 && previous) {
        history.replace(route?.replace('$', previous));
      }
    }
  };

  const pictureCustomRequest = (options: any) => {
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append('file', file);
    Axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/picture`,
      params: {
        departmentId,
      },
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        const { url, status } = response.data;
        setFileList([
          {
            ...file,
            url,
            status,
          },
        ]);
        onSuccess(response, file);
      })
      .catch((error) => {
        message.error(t('FORMS.PICTURE_UPLOAD_ERROR'));
        onError(error);
        console.log(error);
      });
  };

  const onPictureRemove = async (file: any) => {
    return new Promise<boolean>((resolve, reject) => {
      Axios.delete(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/picture`, {
        params: {
          departmentId,
        },
      })
        .then(() => {
          resolve(true);
          setFileList([]);
        })
        .catch((error) => {
          message.error(t('FORMS.PICTURE_DELETION_ERROR'));
          console.error(error);
          reject(false);
        });
    });
  };

  if (user) {
    return (
      <div className={className}>
        <div className="picture-container">
          <Button
            shape="circle"
            type="text"
            style={{ visibility: previous ? 'visible' : 'hidden' }}
            onClick={() => {
              onChangeUser(37);
            }}
          >
            <i className="icon-angle-left" />
          </Button>
          <ImgCrop shape="round">
            <Upload
              listType="picture-card"
              customRequest={pictureCustomRequest}
              onRemove={onPictureRemove}
              fileList={fileList}
              accept="image/*"
            >
              {fileList.length < 1 && <Avatar size={100}>{user.initials}</Avatar>}
            </Upload>
          </ImgCrop>
          <Button
            shape="circle"
            type="text"
            style={{ visibility: next ? 'visible' : 'hidden' }}
            onClick={() => {
              onChangeUser(39);
            }}
          >
            <i className="icon-angle-right" />
          </Button>
        </div>
        <div className="infos">
          <span className="name">{user?.displayName}</span>
          <span className="pin">
            {t('GLOBAL.PIN_CODE')}: {user?.pinCode}{' '}
            <i
              className="icon-edit"
              style={{ color: colors.green, cursor: 'pointer' }}
              onClick={() => {
                setShowModalPinCode(true);
              }}
            />
          </span>
        </div>
        <ModalUpdatePinCode user={user} onClose={() => setShowModalPinCode(false)} visible={showModalPinCode} />
      </div>
    );
  } else {
    return (
      <div className={className}>
        <Skeleton.Avatar size={60} active /> <br />
        <Skeleton title={false} paragraph={{ rows: 2 }} className="skeleton" active />
      </div>
    );
  }
};

const UserInfosStyled = styled(UserInfos)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
  width: 100%;

  .picture-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-btn-circle {
      margin: 10px;
      background-color: white;
      border: 1px solid ${colors.blueLight};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    padding: 0;
    margin: 0;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info {
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }

  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
  }

  .ant-upload-list-picture-card-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .ant-upload.ant-upload-select-picture-card {
    background: none;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    padding: 0;
    margin: 0;
    .ant-upload {
      padding: 0;
    }
    .ant-avatar {
      margin: 0;
    }
  }

  .skeleton {
    text-align: center;
    width: 258px;
  }

  .skeleton-button {
    text-align: center;
    width: 150px;
  }

  div.infos {
    text-align: center;
    padding: 15px 0 0;

    span {
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      color: ${Colors.grey};

      &.name {
        display: block;
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        color: ${Colors.blue};
        padding-bottom: 5px;
      }

      &.pin {
        display: block;
        color: ${Colors.blue};
        padding: 0;
      }
    }
  }
`;

export default UserInfosStyled;
