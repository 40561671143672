import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Chartjs from 'chart.js';
import { WIDGETS } from '@/types/widget.model';
import { Spin } from 'antd';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import colors from '@/styles/colors';
import { useTranslation } from 'react-i18next';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  departmentId?: string;
  sectionId?: string;
  startDate?: Moment;
  endDate?: Moment;
}

const UserStatuses: React.FC<Props> = ({ departmentId, startDate, endDate, sectionId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState<Chartjs | null>(null);
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    if (!departmentId) {
      return;
    }

    setIsLoading(true);
    if (chartInstance) {
      chartInstance.destroy();
    }
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/widgets/${WIDGETS.SHIFTS_PERIOD}`, {
      params: {
        departmentId,
        sectionId,
        start: startDate?.unix(),
        end: endDate?.unix(),
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        if (mounted) {
          setIsLoading(false);
          const data = response.data || [];
          const dates: string[] = [];
          const values: number[] = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            dates.push(moment.unix(item.date).format('ddd'));
            values.push(item.value);
          }

          const newChartInstance = new Chartjs(chartContainer.current!, {
            type: 'bar',
            data: {
              labels: dates,
              datasets: [
                {
                  data: values,
                  backgroundColor: colors.green,
                },
              ],
            },
            options: {
              responsive: true,
              legend: {
                display: false,
              },
            },
          });
          setChartInstance(newChartInstance);
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [departmentId, sectionId, startDate?.unix(), endDate?.unix()]);

  return (
    <Spin spinning={isLoading}>
      <h4>{t(`DASHBOARD.WIDGETS.SHIFTS_PERIOD`)}</h4>
      <div className="chart-container" style={{ position: 'relative' }}>
        <canvas ref={chartContainer} />
      </div>
    </Spin>
  );
};

export default UserStatuses;
