import { Button, message, Select, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import { IReportTemplate, IReportTemplateColumn, IReportTemplateField } from '@/types/report-template.model';
import { AlignType } from '@/types/alignType.model';
import TableRowActions from './TableRowActions';
import DrawerReportColumn from '@/pages/app/components/drawers/ReportColumn';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import axios from 'axios';
import colors from '@/styles/colors';

interface Props {
  reports: IReportTemplate[];
  fields: IReportTemplateField[];
  activeReport: IReportTemplate;
  reportColumns: IReportTemplateColumn[];
  setReportColumns: React.Dispatch<React.SetStateAction<IReportTemplateColumn[]>>;
  setReports: React.Dispatch<React.SetStateAction<IReportTemplate[]>>;
  setActiveReport: React.Dispatch<React.SetStateAction<IReportTemplate | null>>;
}

const SortableElementItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerItem = SortableContainer((props: any) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />);

const ReportTable: React.FC<Props> = ({
  reports,
  fields,
  activeReport,
  reportColumns,
  setReportColumns,
  setReports,
  setActiveReport,
}) => {
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeColumn, setActiveColumn] = useState<IReportTemplateColumn | null>(null);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    setColumns([
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        // eslint-disable-next-line react/display-name
        render: () => <DragHandle />,
      },
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'title',
        key: 'title',
        sorter: (a: IReportTemplateColumn, b: IReportTemplateColumn) => a.title?.localeCompare(b.title),
      },
      {
        title: t('REPORT_TEMPLATES.FIELDS'),
        dataIndex: 'fields',
        key: 'fields',
        render: (text: string, record: IReportTemplateColumn) => {
          if (!record.fields) return null;

          return (
            <div style={{ display: 'flex' }}>
              {record.fields.map((field) => {
                const type = field.id && typeof field.id !== 'number' && field.id.split('.')[0];
                const found = fields.find((field) => field.type == type)?.fields.find((f) => f.id == field.id);
                const attributeId = record.fields?.find((field) => field.attributeFieldId !== null)?.attributeFieldId;
                const attributes = fields.find((field) => field.type == 'attributes');
                const attributesList = attributes ? attributes.fields : [];
                const attribute = attributesList.find((attribute) => attribute.id == found?.id) || null;
                const attr = attribute?.fields?.find((attribute) => attribute.id == attributeId);

                return (
                  <>
                    {found && (
                      <div
                        style={{
                          marginRight: 5,
                          backgroundColor: colors.greenLight,
                          padding: '2px 10px',
                          borderRadius: 50,
                        }}
                      >
                        <span>
                          {found.label} {attributeId ? `(${attr?.label})` : null}
                        </span>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          );
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IReportTemplateColumn) => (
          <TableRowActions onEdit={() => setActiveColumn(record)} onDelete={() => onDeleteColumn(record)} />
        ),
        width: '30%',
      },
    ]);
  }, [activeReport, reportColumns]);

  const onDeleteColumn = (column: IReportTemplateColumn) => {
    if (activeReport.columns.length == 1) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${activeReport.id}`, {
          params: {
            departmentId: activeDepartmentId,
          },
        })
        .then((data: any) => {
          setReports(reports.filter((report) => report.id !== activeReport.id));
          if (data.columns) {
            setReportColumns(data.columns);
          }
          setActiveReport(null);
          message.success(t('REPORT_TEMPLATES.DELETED'));
        })
        .catch((err) => {
          message.error(Object.values(err.response.data.errors).flat(1)[0]);
        });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${activeReport.id}`,
          {
            ...activeReport,
            name: activeReport.name,
            columns: activeReport.columns.filter((col) => col.position !== column.position),
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          setActiveReport(data);
          if (data.columns) {
            setReportColumns(data.columns);
          }
          message.success(t('REPORT_TEMPLATES.COLUMN_DELETED'));
        })
        .catch((err) => {
          message.error(Object.values(err.response.data.errors).flat(1)[0]);
        });
    }
  };

  const onAddColumn = () => {
    setActiveColumn({} as any);
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newColumns: IReportTemplateColumn[] = arrayMove(reportColumns, oldIndex, newIndex);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template/${activeReport.id}`,
        {
          ...activeReport,
          name: activeReport.name,
          columns: newColumns.map((col, i) => {
            return { ...col, position: i + 1 };
          }),
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        setReportColumns(newColumns);
        message.success(t('REPORT_TEMPLATES.COLUMNS_REORDERED'));
      })
      .catch((err) => {
        console.log(err);
        message.error(Object.values(err.response.data.errors).flat(1)[0]);
      });
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerItem useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = reportColumns.findIndex((x) => x.position === restProps['data-row-key']);
    return <SortableElementItem index={index} {...restProps} />;
  };

  return (
    <React.Fragment>
      <Button htmlType="button" onClick={onAddColumn} block style={{ marginBottom: 25 }} size="large" type="dashed">
        {t('REPORT_TEMPLATES.ADD_COLUMN')}
      </Button>
      <Table
        columns={columns}
        dataSource={reportColumns}
        pagination={false}
        rowKey="position"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
      <DrawerReportColumn
        visible={!!activeColumn}
        fields={fields}
        column={activeColumn}
        onSave={() => {}}
        onClose={() => setActiveColumn(null)}
        activeReport={activeReport}
        setActiveReport={setActiveReport}
        setReportColumns={setReportColumns}
        setReports={setReports}
        reports={reports}
      />
    </React.Fragment>
  );
};

export default ReportTable;
