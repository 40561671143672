import { IHrCode } from '@/types/hr-code.model';
import { IHrPartner } from '@/types/hr-partner.model';
import { Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  visible: boolean;
  defaultHrCodes: IHrPartner[];
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalDefaultHrCodes: React.FC<Props> = ({ className, visible, defaultHrCodes, setVisible }) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState<any[]>();

  const onClose = () => {
    setVisible(false);
  };

  const onOk = () => {
    setVisible(false);
  };

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.CODE'),
        key: 'code',
        dataIndex: 'code',
        sorter: (a: IHrCode, b: IHrCode) => a.code.localeCompare(b.code),
      },
      {
        title: t('GLOBAL.NAME'),
        key: 'name',
        dataIndex: 'name',
        sorter: (a: IHrCode, b: IHrCode) => a.name.localeCompare(b.name),
      },
    ]);
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      className={`${className} modal-info`}
      title={t('CUSTOM_HR_CODES.DEFAULT_CODES.TITLE')}
      style={{ height: '500px' }}
      bodyStyle={{ overflowY: 'auto', height: '500px' }}
    >
      {defaultHrCodes.map((partner) => (
        <Table dataSource={partner.codes} columns={columns} pagination={false} />
      ))}
    </Modal>
  );
};

export default ModalDefaultHrCodes;
