import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { IAttribut } from '@/types/attribut.model';
import { IResource } from '@/types/resource.model';
import { IUser } from '@/types/user.model';
import { BackTop, Image, Space, Table } from 'antd';
import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Header from './components/Header';
import ModalExport from './components/ModalExport';

export type ExportMode = 'XLS' | 'PDF' | null;

const AppTeamResourcesPage: React.FC = () => {
  const {
    state: { activeDepartmentId },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const tableRef = useRef<any>();
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [resource, setResource] = useState<IResource | null>(null);
  const [loadingResource, setLoadingResource] = useState<boolean>(false);
  const [filteredAttributes, setFilteredAttributes] = useState<IAttribut[] | null>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [defaultColumns, setDefaultColumns] = useState<any[]>([]);
  const [filterSearch, setFilterSearch] = useState<string>('');
  const [exportMode, setExportMode] = useState<ExportMode>(null);
  const history = useHistory();
  const { id } = useParams();

  const location = useLocation();

  useEffect(() => {
    // ATTRIBUTES
    const cancelTokenSource = Axios.CancelToken.source();
    setLoadingResource(true);
    setResource(null);
    setFilteredAttributes([]);
    if (!id) return;
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/resources/${id}`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then(({ data }) => {
        const { resource } = data;
        setResource(resource);
        setLoadingResource(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingResource(false);
      });
  }, [location]);

  useEffect(() => {
    setDefaultColumns([
      {
        title: t('GLOBAL.NAME'),
        key: 'name',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          const src = record.image || 'error';
          return (
            <Space>
              <Image
                src={src}
                width={35}
                height={35}
                fallback={`${process.env.PUBLIC_URL}/img/image-placeholder.png`}
              />
              {record.name}
            </Space>
          );
        },
        sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    const fields = resource?.fields || [];
    setFilteredAttributes(resource?.attributes || []);

    const columns = [...defaultColumns];

    if (resource?.resourceType === 'TOOL') {
      columns.push({
        title: t('GLOBAL.CODE_TYPE'),
        key: 'codeType',
        dataIndex: 'codeType',
        sorter: (a: any, b: any) => a.codeType?.localeCompare(b.codeType),
      });
    }

    for (let i = 1; i < fields.length; i++) {
      const { id, label } = fields[i];
      columns.push({
        title: label,
        key: `field_${id}`,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          const value = record.fields?.find((x: any) => x.id === id)?.value;
          return <Space>{value}</Space>;
        },
        sorter: (a: any, b: any) => {
          const aValue: string | undefined = a.fields?.find((x: any) => x.id === id)?.value;
          if (aValue) {
            const bValue: string | undefined = b.fields?.find((x: any) => x.id === id)?.value;
            if (bValue) {
              return aValue.localeCompare(bValue);
            } else {
              return 0;
            }
          } else {
            return -1;
          }
        },
      });
    }
    setColumns(columns);
  }, [resource?.attributes, defaultColumns]);

  useEffect(() => {
    const attributes = resource?.attributes;
    if (attributes) {
      const filteredAttributes = attributes.filter((user: IUser) => {
        let showUser = true;
        if (filterSearch) {
          showUser = Object.keys(user).some((key) => {
            if (typeof (user as any)[key] === 'string') {
              return (user as any)[key].toLowerCase().includes(filterSearch.toLowerCase());
            } else {
              return false;
            }
          });
        }
        return showUser;
      });
      setFilteredAttributes(filteredAttributes as IAttribut[]);
    } else {
      setFilteredAttributes([]);
    }
  }, [resource?.attributes, filterSearch]);

  const onRowClick = (attribut: IAttribut) => {
    history.push(`/app/settings/attributes/manage/${id}/${attribut.id}`);
  };

  const onRow = (attribut: IAttribut) => {
    return {
      onClick: () => {
        onRowClick(attribut);
      },
    };
  };

  const onAttributSave = (attribut: IAttribut) => {
    const updatedResource = {
      ...resource,
      attributes: [...(resource?.attributes || []), attribut],
    };
    setResource(updatedResource);
    appContextDispatch({
      type: 'UPDATE_RESOURCE',
      payload: updatedResource,
    });
    history.push(`/app/settings/attributes/manage/${resource!.id!}/${attribut.id}`);
  };

  const onPageChange = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header
        setExportMode={setExportMode}
        setFilterSearch={setFilterSearch}
        disabled={loadingResource}
        activeDepartmentId={activeDepartmentId}
        resource={resource}
        onAttributSave={onAttributSave}
      />
      <Table
        ref={tableRef}
        loading={loadingResource}
        dataSource={filteredAttributes || []}
        columns={columns}
        rowKey="id"
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 50,
          showSizeChanger: false,
          onChange: onPageChange,
        }}
        onRow={onRow}
        scroll={{ scrollToFirstRowOnChange: true }}
        rowClassName="row-clickable"
      />
      <ModalExport
        departmentId={activeDepartmentId}
        mode={exportMode}
        resource={resource}
        onClose={() => setExportMode(null)}
      />
      {/* <ModalExport
        departmentId={activeDepartmentId}
        mode={exportMode}
        userIds={filteredAttributes?.map((attribut) => attribut.id!) || []}
        fields={fields}
        onClose={() => setExportMode(null)}
      /> */}
    </TableView>
  );
};

export default AppTeamResourcesPage;
