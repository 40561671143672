import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IPayPeriod } from '@/types/pay-periods/pay-period.model';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  period: IPayPeriod;
}

const PeriodHeader: React.FC<Props> = ({ className, period }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, userCategories },
    dispatch,
  } = useContext(AppContext);

  const start = moment(period.start);
  const end = moment(period.end);
  const month = moment(period.month);
  const title = `${t(`GLOBAL.MONTHS.${month.month() + 1}`)} ${month.format('YYYY')}`;
  const range = `(${t('GLOBAL.FROM')} ${start.format('DD-MM-YYYY')} ${t('GLOBAL.TO')} ${end.format('DD-MM-YYYY')})`;
  const filteredStatus = userCategories.filter((status) => period.status.includes(status.id!));

  return (
    <div className={className}>
      <span className="title">
        {title} <div className="range">{range}</div>
      </span>
      <span className="status">{filteredStatus.map((status) => status.name).join(', ')}</span>
      <span className="type">
        {t('REPORT_TEMPLATES.CALCULATION_METHOD')} :{' '}
        {t(`REPORTS.PAY_PERIODS.TYPES.${period.smooth_contract_hours ? 'SMOOTHED' : 'FULL'}`)}
      </span>
    </div>
  );
};

export default styled(PeriodHeader)`
  display: flex;
  flex-direction: column;

  .title {
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;

    .range {
      font-weight: lighter;
    }
  }

  .status {
    font-size: 12px;
    color: ${colors.grey};
  }

  .type {
    font-weight: bold;
  }
`;
