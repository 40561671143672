import { Modal, Form, TimePicker, Radio, Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form';
import moment, { Moment } from 'moment';
import React, { useRef, useEffect } from 'react';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { IDepartmentSettings } from '@/types/department-settings.model';
moment.tz.setDefault('Atlantic/Reykjavik');

export interface IAutoPause {
  paid: boolean;
  duration: Moment;
  from: Moment;
  onlyClocking: boolean;
}

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }: { form: FormInstance; visible: boolean }) => {
  const prevVisibleRef = useRef(false);
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;

  useEffect(() => {
    if (visible && !prevVisible) {
      form.resetFields();
      form.setFieldsValue({
        paid: false,
        duration: moment.unix(0),
        from: moment.unix(0),
      });
    }
  }, [visible]);
};

interface ModalFormProps {
  visible: boolean;
  onlyClocking?: boolean;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
  onCancel: () => void;
}

const timePickerValidator = (e: any, value: Moment) => {
  if (value.unix() > 0) {
    return Promise.resolve();
  } else {
    return Promise.reject('Temps positif obligatoire');
  }
};

const ModalPause: React.FC<ModalFormProps> = ({
  visible,
  onlyClocking = false,
  currentSettings,
  updateCurrentSettings,
  onCancel,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    const values = form.getFieldsValue();
    const pauses = [...(currentSettings?.pauses || [])];
    updateCurrentSettings({
      pauses: [
        ...pauses,
        {
          paid: values.paid as boolean,
          onlyClocking: onlyClocking,
          duration: values.duration.unix() as number,
          from: values.from.unix() as number,
        },
      ],
    } as IDepartmentSettings);
    onCancel();
  };

  return (
    <Modal title={t('BREAKS.MODAL_ADD_AUTO_BREAK.TITLE')} visible={visible} onOk={onOk} onCancel={onCancel}>
      <Form form={form} layout="vertical" name="pauseForm">
        <Form.Item name="paid" label={t('GLOBAL.BREAK_TYPE')}>
          <Radio.Group>
            <Radio value={true}>{t('GLOBAL.PAID_BREAK')}</Radio>
            <Radio value={false}>{t('GLOBAL.UNPAID_BREAK')}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="from"
          label={t('BREAKS.MODAL_ADD_AUTO_BREAK.RIGHT_TO_A_BREAK_AFTER')}
          rules={[
            {
              required: true,
              validator: timePickerValidator,
            },
          ]}
        >
          <TimePicker
            format="HH:mm"
            onSelect={(e) => {
              form.setFieldsValue({
                from: e,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          name="duration"
          label={t('BREAKS.BREAK_DURATION')}
          rules={[
            {
              required: true,
              validator: timePickerValidator,
            },
          ]}
        >
          <TimePicker
            format="HH:mm"
            onSelect={(e) => {
              form.setFieldsValue({
                duration: e,
              });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalPause;
