import { Select, Checkbox, Row, Col, Form } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AUTO_DIMONA_WORK_TYPES } from '@/types/dimona.model';
import axios from 'axios';
import { IDocumentTemplate } from '@/types/document.model';
import { IDepartment } from '@/types/department.model';
import { isFeatureEnabled } from '@/utils';
import { FEATURES } from '@/types/features.model';
import AppContext from '@/pages/app/context';

const Option = Select.Option;

interface Props {
  className?: string;
  department?: IDepartment;
}

const Automation: React.FC<Props> = ({ className, department }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { features },
  } = useContext(AppContext);
  const [templates, setTemplates] = useState<IDocumentTemplate[]>([]);
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);

  useEffect(() => {
    if (!department?.id) {
      return;
    }

    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingTemplates(true);
    setTemplates([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/documents/templates`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: department?.id,
          filter: 'CONTRACT',
          auto: true,
        },
      })
      .then(({ data }) => {
        if (mounted) {
          setTemplates(data.templates);
          setLoadingTemplates(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingTemplates(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [department?.id]);

  return (
    <div className={className}>
      <h4>{t('GLOBAL.AUTOMATION')}</h4>
      <Row style={{ margin: '20px 0 20px' }}>
        <Col lg={16} md={24}>
          {isFeatureEnabled(features, FEATURES.DIMONA) && department?.params?.automaticDimona && (
            <div className="form-items-group one-line">
              <span style={{ width: 120 }}>Dimona</span>
              <Form.Item noStyle name={['params', 'workerType']} className="one-line">
                <Select
                  style={{ marginLeft: 10, width: '100%' }}
                  allowClear={true}
                  getPopupContainer={(trigger) => trigger}
                  placeholder={t('FORMS.WORKER_TYPE_PLACEHOLDER')}
                >
                  {AUTO_DIMONA_WORK_TYPES.map((workType) => {
                    const { id } = workType;
                    return (
                      <Option key={`workType_${id}`} value={id!}>
                        {t(`DIMONA.WORKTYPES.${id}`)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item noStyle name={['params', 'automatic_dimona']} valuePropName="checked" className={'one-line'}>
                <Checkbox style={{ marginLeft: 10 }}>{t('GLOBAL.AUTOMATIC')}</Checkbox>
              </Form.Item>
            </div>
          )}
          {isFeatureEnabled(features, FEATURES.CONTRACTS) && department?.params?.automaticContracts && (
            <div className="form-items-group one-line">
              <span style={{ width: 120 }}>{t('GLOBAL.CONTRACT')}</span>
              <Form.Item noStyle name={['params', 'automatic_contract_template_id']}>
                <Select
                  style={{ marginLeft: 10, width: '100%' }}
                  getPopupContainer={(trigger) => trigger}
                  disabled={loadingTemplates}
                  loading={loadingTemplates}
                  placeholder={t('USER_CATEGORIES.DEFAULT_CONTRACT_TEMPLATE')}
                  allowClear={true}
                >
                  {templates?.map((department) => {
                    const { id, name } = department;
                    return (
                      <Option key={`template_${id}`} value={`${id}`}>
                        {name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                noStyle
                name={['params', 'automatic_contracts']}
                valuePropName="checked"
                className={'one-line'}
              >
                <Checkbox style={{ marginLeft: 10 }}>{t('GLOBAL.AUTOMATIC')}</Checkbox>
              </Form.Item>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

const AutomationStyled = styled(Automation)`
  .ant-row.ant-form-item.one-line {
    .ant-col.ant-form-item-label {
      label {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
`;

export default AutomationStyled;
