import useCommands from '@/hooks/use-commands';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { handleError } from '@/utils';
import { Input, List, Modal, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import NewBadge from './NewBadge';
import { Command, getCommandsList } from './commands-list';

interface Props {
  className?: string;
}

interface CrispArticle {
  id: number;
  crisp_id: string;
  locale: string;
  title: string;
  description: string;
  url: string;
  active: number;
}

const CommandsModal: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartment, features, loadingFeatures, badges, hrPartners, users },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();
  const [data, setData] = useState<Command[]>([]);
  const [visible, setVisible] = useState(false);
  const [query, setQuery] = useState('');
  const { t } = useTranslation();

  const [results, setResults] = useState(data);

  const inputRef = useRef<Input>(null);

  useEffect(() => {
    if (!activeDepartment) return;
    if (visible) {
      getCrispArticles();
    }
    if (visible && inputRef.current) {
      inputRef.current.focus({
        cursor: 'start',
      });
    }
  }, [activeDepartment, visible, features]);

  const getCrispArticles = () => {
    if (!activeDepartment) return;
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/crisp/articles`, {
        params: {
          departmentId: activeDepartment.id,
          locale: activeDepartment.userLanguageCode,
        },
      })
      .then(({ data: articles }) => {
        const data = getCommandsList(t, activeDepartment, features, badges, hrPartners);
        const formattedArticles: Command[] = articles.map((article: CrispArticle) => ({
          label: article.title,
          icon: 'newspaper',
          extra: 'newspaper',
          description: article.description !== '' ? article.description : undefined,
          keywords: [],
          link: article.url,
        }));
        const formattedUsers = users.map((user) => ({
          label: user.displayName || `${user.firstname} ${user.lastname}`,
          icon: 'user',
          extra: 'user',
          description: user.email,
          keywords: [],
          link: `/app/team/collaborators/${user.recordId}`,
        }));
        setData([...data, ...formattedUsers, ...formattedArticles]);
        setResults([...data, ...formattedUsers, ...formattedArticles]);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const showSearch = () => setVisible(true);

  const hideSearch = () => {
    setVisible(false);
    setQuery('');
    setResults(data);
  };

  const handleSearch = (value: string) => {
    setQuery(value);
    if (!value) {
      setResults(data);
      return;
    }

    const lowercasedValue = value.toLowerCase();
    const filteredResults = data.filter((item) =>
      [item.label, item.description || '', ...item.keywords].some((field) =>
        field.toLowerCase().includes(lowercasedValue),
      ),
    );
    setResults(filteredResults);
  };

  useCommands('k', showSearch);

  const handleItemClick = (item: Command) => {
    if (item.link.includes('http') || item.link.includes('https')) {
      window.open(item.link, '_blank');
    } else {
      history.push(`${item.link}?command=${item.label}`);
    }
    hideSearch();
  };

  const highlightText = (text: string, query: string) => {
    if (!query) return text;

    const regex = new RegExp(`(${query})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: 'lightgreen', fontWeight: 'bold' }}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  if (!visible || loadingFeatures)
    return (
      <Tooltip overlay={<span>⌘+K/CTRL+K</span>}>
        <div
          id="global-shortcuts"
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => {
            showSearch();
          }}
        >
          <NewBadge end="2024-07-01" noTooltip />
          <i className="icon-search" style={{ cursor: 'pointer', fontSize: 14, color: '#7f8894' }} />
        </div>
      </Tooltip>
    );

  return (
    <>
      <Tooltip overlay={<span>⌘+K/CTRL+K</span>}>
        <div
          id="global-shortcuts"
          style={{ display: 'flex', cursor: 'pointer', border: '1px solid #dadada', padding: '3px', borderRadius: 5 }}
          onClick={() => {
            showSearch();
          }}
        >
          <NewBadge end="2024-07-01" noTooltip />
          <i className="icon-search" style={{ cursor: 'pointer', fontSize: 14, color: '#7f8894' }} />
        </div>
      </Tooltip>
      <Modal
        visible={visible}
        onCancel={hideSearch}
        footer={null}
        width={600}
        bodyStyle={{ padding: 0 }}
        className={className}
      >
        <Input
          className="input"
          ref={inputRef}
          placeholder={t('GLOBAL.SEARCH') + '...'}
          value={query}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <List
          style={{ cursor: 'pointer', borderTop: '1px solid #e8e8e8', maxHeight: 350, overflowY: 'scroll' }}
          dataSource={results.filter((result) => result.visible == undefined || result.visible == true)}
          renderItem={(item) => (
            <List.Item style={{ padding: '10px 15px' }} onClick={() => handleItemClick(item)}>
              <List.Item.Meta
                style={{ alignItems: 'center' }}
                avatar={
                  item.icon ? (
                    <i
                      style={{
                        fontSize: 24,
                        color:
                          item.icon == 'settings'
                            ? '#ea5165'
                            : item.icon == 'newspaper'
                            ? colors.green
                            : item.icon == 'user'
                            ? '#60cdFF'
                            : '',
                      }}
                      className={`icon-${item.icon}`}
                    />
                  ) : undefined
                }
                title={<span>{highlightText(item.label, query)}</span>}
                description={item.description ? <span>{highlightText(item.description, query)}</span> : undefined}
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default styled(CommandsModal)`
  .input {
    border: none;
    padding: 15px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    &:focus,
    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  .ant-list-item-meta-avatar {
    margin-right: 10px;
  }

  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-modal-content {
    border-radius: 16px;
  }
`;
