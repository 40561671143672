import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import { useHistory, useParams } from 'react-router-dom';
import HrSheetHeader from './HrSheetHeader';
import { Button, Spin } from 'antd';
import styled from 'styled-components';
import colors from '@/styles/colors';
import NewBadge from '@/pages/app/components/NewBadge';
import HrSheetTable from './HrSheetTable';
import axios from 'axios';
import { handleError } from '@/utils';
import { IHrSheet, IHrSheetTimesheets } from '@/types/reports/timesheets/sheet.model';
import { IHrSheet as IHrSheetOld } from '..';

interface Props {
  className?: string;
  hrSheets?: IHrSheetOld[];
  userRecordId: any;
  start: moment.Moment;
  datePickerValue: moment.Moment;
  user?: boolean;
  setDatePickerValue: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setHrSheets?: React.Dispatch<React.SetStateAction<IHrSheetOld[]>>;
}

const HrSheet: React.FC<Props> = ({
  className,
  hrSheets,
  userRecordId,
  start,
  datePickerValue,
  user,
  setDatePickerValue,
  setHrSheets,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [sheet, setSheet] = useState<IHrSheet | null>(null);
  const [format, setFormat] = useState<'hh:mm' | 'h,m'>('hh:mm');
  const [fullmonth, setFullmonth] = useState<boolean>(
    localStorage.getItem('timesheets-fullmonth') && localStorage.getItem('timesheets-fullmonth') == 'true'
      ? true
      : false,
  );
  const [preventBack, setPreventBack] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [newCodes, setNewCodes] = useState<any[]>([]);
  const [newUrl, setNewUrl] = useState<string | null>(null);
  const [result, setResult] = useState<IHrSheetTimesheets>({});
  const [timesheetCopy, setTimesheetCopy] = useState<IHrSheetTimesheets>({});
  const [invalidInputKeys, setInvalidInputKeys] = useState<string[]>([]);

  useEffect(() => {
    let mounted = true;
    if (!mounted || !userRecordId) return;

    setLoading(true);
    setPreventBack(false);
    setFormHasChanged(false);
    setNewUrl(null);
    getHrSheet();

    return () => {
      mounted = false;
    };
  }, [fullmonth, datePickerValue, userRecordId]);

  const getHrSheet = (type: 'planned' | 'clocking' | null = null) => {
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/reports/hr-sheets/new/${userRecordId}`, {
        params: {
          departmentId: activeDepartmentId,
          start: datePickerValue ? datePickerValue.startOf('month').unix() : start.unix(),
          fullmonth,
          type: !type ? undefined : type == 'planned' ? 'SHIFT' : 'CLOCKING',
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        setSheet(data);
        setResult({ ...data.TIMESHEETS });
        setTimesheetCopy({ ...data.TIMESHEETS });
        setInvalidInputKeys([]);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const localStorageTimesheetsFullmonth = localStorage.getItem('timesheets-fullmonth');
    if (localStorageTimesheetsFullmonth && JSON.parse(localStorageTimesheetsFullmonth) !== 'undefined') {
      setFullmonth(localStorageTimesheetsFullmonth == 'true' ? true : false);
    }

    const localStorageTimesheetsFormat = localStorage.getItem('timesheets-format');
    if (
      localStorageTimesheetsFormat &&
      (localStorageTimesheetsFormat == 'hh:mm' || localStorageTimesheetsFormat == 'h,m')
    ) {
      setFormat(localStorageTimesheetsFormat);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (hrSheets && hrSheets.length > 0) return;

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/reports/hr-sheets`, {
        params: {
          departmentId: activeDepartmentId,
          start: datePickerValue.unix(),
        },
      })
      .then(({ data }) => {
        if (setHrSheets) {
          setHrSheets(data);
        }
      });
  }, [hrSheets]);

  return (
    <div className={className}>
      {!sheet ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin spinning={loading} />
        </div>
      ) : (
        <>
          <HrSheetHeader
            fullmonth={fullmonth}
            setFullmonth={setFullmonth}
            format={format}
            setFormat={setFormat}
            datePickerValue={datePickerValue}
            setDatePickerValue={setDatePickerValue}
            hrSheets={hrSheets}
            preventBack={preventBack}
            setPreventBack={setPreventBack}
            formHasChanged={formHasChanged}
            setFormHasChanged={setFormHasChanged}
            newCodes={newCodes}
            setNewCodes={setNewCodes}
            setNewUrl={setNewUrl}
            sheet={sheet}
            getHrSheet={getHrSheet}
            userOnly={user}
          />
          <div className="content">
            <HrSheetTable
              loading={loading}
              sheet={sheet}
              format={format}
              hrSheets={hrSheets}
              preventBack={preventBack}
              setPreventBack={setPreventBack}
              formHasChanged={formHasChanged}
              setFormHasChanged={setFormHasChanged}
              newCodes={newCodes}
              setNewCodes={setNewCodes}
              newUrl={newUrl}
              setNewUrl={setNewUrl}
              result={result}
              setResult={setResult}
              invalidInputKeys={invalidInputKeys}
              setInvalidInputKeys={setInvalidInputKeys}
              datePickerValue={datePickerValue}
              getHrSheet={getHrSheet}
              timesheetCopy={timesheetCopy}
              recordId={userRecordId}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default styled(HrSheet)`
  position: relative;
  padding: 20px;

  .content {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
`;
