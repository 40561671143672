import React from 'react';
import styled from 'styled-components';
import Colors from '@/styles/colors';

interface Props {
  className?: string;
  children?: any;
}

const Options: React.FC<Props> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

const OptionsStyled = styled(Options)`
  border: 1px solid ${Colors.blueLight};
  border-radius: 3px;
  height: 30px;

  .option {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 100%;
  }
`;

export default OptionsStyled;
