import { Button, Input, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  disabled: boolean;
  onSearchChange: (e: React.ChangeEvent) => void;
  onCreate: () => void;
}

const Header: React.FC<Props> = ({ className, disabled, onSearchChange, onCreate }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <header className={className}>
      <div className="left">
        <Space>
          <Input
            disabled={disabled}
            allowClear
            placeholder={t('GLOBAL.SEARCH')}
            suffix={<i className="icon-search"></i>}
            width={190}
            onChange={onSearchChange}
          />
        </Space>
      </div>
      <div className="right">
        <div className="actions">
          <Button type="primary" onClick={onCreate}>
            {t('GLOBAL.NEW')}
          </Button>
        </div>
      </div>
    </header>
  );
};

export default Header;
