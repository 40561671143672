import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Colors from '@/styles/colors';
import AppContext from '@/pages/app/context';
import { IShift } from '@/types/shift.model';
import ShiftDepartment from '../../components/ShiftDepartment';
import ShiftRightClick from '@/pages/app/hours/manage/components/ShiftRightClick';
import ShiftSectionBar from '@/pages/app/hours/manage/components/ShiftSectionBar';
import { shallowEqual, useSelector } from 'react-redux';
import { InitialStateType } from '../../redux/store';
import { IScheduleHour } from '@/types/schedule-hour.model';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface Props {
  className?: string;
  columnWidth: number;
  shift: IShift;
}

const Unvailability: React.FC<Props> = ({ className, shift, columnWidth }) => {
  const {
    state: { activeSection, activeDepartment },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: true });

  const { hours, showOtherDepartments } = useSelector(
    ({ hours, showOtherDepartments }: InitialStateType) => ({
      hours,
      showOtherDepartments,
    }),
    shallowEqual,
  );
  const [left, setLeft] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  const initState = ({ columnWidth, hours }: { columnWidth: number; hours: IScheduleHour[] }) => {
    const { start: shiftStart, end: shiftEnd, pause } = shift;
    const startMoment = moment.unix(shiftStart!);
    const endMoment = moment.unix(shiftEnd!);
    const duration = moment.duration(endMoment.diff(startMoment));
    duration.subtract(pause!.unpaid!, 'seconds');
    const diffInMinutes = hours[0] ? startMoment.diff(moment.unix(hours[0].date), 'minutes') : 0;
    const left = (diffInMinutes / 60) * columnWidth;
    const width = (moment.duration(duration).add(pause!.unpaid!, 'seconds').asMinutes() / 60) * columnWidth;
    setLeft(left);
    setWidth(width);
  };

  useEffect(() => {
    initState({ columnWidth, hours });
  }, [columnWidth, hours]);

  return (
    <ShiftRightClick shift={shift}>
      <div
        className={`${className}
          ${
            activeDepartment?.scheduleParams?.ghost_shifts && activeSection && activeSection !== shift.section?.id
              ? 'other-section'
              : ''
          }
        `}
        style={{
          left,
          width,
        }}
      >
        <div className="card">
          {!activeSection && <ShiftSectionBar section={shift.section} />}
          {t('GLOBAL.UNAVAILABLE')}
          {showOtherDepartments && <ShiftDepartment departmentId={shift.departmentId!} />}
        </div>
      </div>
    </ShiftRightClick>
  );
};

const UnvailabilityStyled = styled(Unvailability)`
  background: #f4f4f4;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
  margin: 0;
  text-align: left;
  color: ${Colors.blue};
  position: absolute;
  height: calc(100% - 10px);
  cursor: grab;
  flex: 1;
  box-sizing: border-box;
  z-index: 1;

  &.other-section {
    opacity: 0.5;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }

  .card {
    pointer-events: all;
    overflow: hidden;
    max-width: 100%;
    color: #bebebe;
    text-transform: uppercase;

    .department {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    h3 {
      font-size: 12px;
      font-weight: 600;
      color: black;
      margin: 0 5px 0 0;
      padding-bottom: 12px;
    }
  }
`;

export default UnvailabilityStyled;
