import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children?: any;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
}

const ContainerView: React.FC<Props> = ({ className, children, style, containerStyle }) => {
  return (
    <div className={className} style={style}>
      <div className="container" style={containerStyle}>
        {children}
      </div>
    </div>
  );
};

export default styled(ContainerView)`
  padding: 15px;
  background-color: rgb(240, 245, 250);

  .container {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
`;
