import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IPaySheet } from '@/types/pay-periods/pay-sheet.model';
import { getFullWeeksBetween, handleError } from '@/utils';
import { Button, Tooltip, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  payPeriodId: string;
  paySheet: IPaySheet;
  format: 'h,m' | 'hhmm';
  setFormat: React.Dispatch<React.SetStateAction<'h,m' | 'hhmm'>>;
}

const Header: React.FC<Props> = ({ className, payPeriodId, paySheet, format, setFormat }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, payPeriods, userCategories },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const sheet = payPeriods.find((period) => period.id == payPeriodId);
  const status = sheet ? userCategories.filter((userCategory) => sheet.status.includes(userCategory.id!)) : [];

  const onBack = () => {
    history.goBack();
  };

  const onExport = () => {
    setLoadingExport(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/reports/pay-sheets/${payPeriodId}/export`,
        {},
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        message.success(t('REPORTS.PAY_PERIODS.PAY_SHEET_EXPORT_PENDING'));
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoadingExport(false);
      });
  };

  const onSetFormat = (value: 'hhmm' | 'h,m') => {
    localStorage.setItem('payroll-reports-format', value);
    setFormat(value);
  };

  return (
    <div className={className}>
      <div className="top">
        <div className="left">
          <div className="row">
            <Button type="link" style={{ transform: 'rotate(-90deg' }} size="small" onClick={onBack}>
              <i className="icon-up-big previous-icon" style={{ fontSize: 14 }} />
            </Button>
            <div>
              <h2 className="title">
                {t(`GLOBAL.MONTHS.${moment(paySheet.start.period).month() + 1}`)}{' '}
                {moment(paySheet.start.period).format('YYYY')}
              </h2>
              <span className="status">{status.map((status) => status.name).join(', ')}</span>
              <span className="type">
                {t('REPORT_TEMPLATES.CALCULATION_METHOD')} :{' '}
                {t(`REPORTS.PAY_PERIODS.TYPES.${sheet?.smooth_contract_hours ? 'SMOOTHED' : 'FULL'}`)}
              </span>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="left">
            <span>
              <b>{t('REPORTS.PAY_PERIODS.RECORDED_PAYROLL')}</b> {moment(paySheet.start.period).format('DD')}{' '}
              {t(`GLOBAL.MONTHS.${moment(paySheet.start.period).month() + 1}`).toLowerCase()}{' '}
              {moment(paySheet.start.period).format('YYYY')} {t('GLOBAL.TO_(DATE)')}{' '}
              {moment(paySheet.end.period).format('DD')}{' '}
              {t(`GLOBAL.MONTHS.${moment(paySheet.end.period).month() + 1}`).toLowerCase()}{' '}
              {moment(paySheet.end.period).format('YYYY')}
            </span>
            <span>
              <b>{t('REPORTS.PAY_PERIODS.EFFECTIVE_PAYROLL')}</b> {moment(paySheet.start.actual).format('DD')}{' '}
              {t(`GLOBAL.MONTHS.${moment(paySheet.start.actual).month() + 1}`).toLowerCase()}{' '}
              {moment(paySheet.start.actual).format('YYYY')} {t('GLOBAL.TO_(DATE)')}{' '}
              {moment(paySheet.end.actual).format('DD')}{' '}
              {t(`GLOBAL.MONTHS.${moment(paySheet.end.actual).month() + 1}`).toLowerCase()}{' '}
              {moment(paySheet.end.actual).format('YYYY')} (
              {getFullWeeksBetween(moment(paySheet.start.actual), moment(paySheet.end.actual))}{' '}
              {t('REPORTS.PAY_PERIODS.FULL_WEEKS')})
            </span>
          </div>
          <Button
            type="primary"
            style={{ backgroundColor: colors.blueLightPastel, borderColor: colors.blueLightPastel }}
            onClick={onExport}
            loading={loadingExport}
          >
            {t('GLOBAL.EXPORT')}
          </Button>
        </div>
      </div>
      <div className="bottom">
        <div className="selector">
          <button type="button" className={`${format == 'hhmm' ? 'active' : ''}`} onClick={() => onSetFormat('hhmm')}>
            hhmm
          </button>
          <Tooltip overlay={<span>{t('GLOBAL.DECIMALS')}</span>}>
            <button type="button" className={`${format == 'h,m' ? 'active' : ''}`} onClick={() => onSetFormat('h,m')}>
              {t('GLOBAL.DECIMALS_SHORT')}
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default styled(Header)`
  display: flex;
  flex-direction: column;
  gap: 25px;

  .top {
    display: flex;
    justify-content: space-between;

    .left {
      .row {
        display: flex;
        align-items: center;
        gap: 10px;

        .title {
          margin: 0;
          font-weight: bold;
        }
      }

      .status {
        font-size: 12px;
        color: ${colors.grey};
      }

      .type {
        margin-left: 10px;
        font-weight: bold;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 10px;

      .left {
        display: flex;
        flex-direction: column;
        text-align: right;
        margin-right: 10px;
      }
    }
  }

  .bottom {
    display: flex;
  }
`;
