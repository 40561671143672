import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Modal, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

interface Props {
  className?: string;
  departmentId?: string;
  userRecordId?: string;
  visible: boolean;
  onClose: () => void;
  changeForm: (formId: string) => void;
}

const ModalHrFormList: React.FC<Props> = ({ className, departmentId, userRecordId, visible, onClose, changeForm }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [data, setData] = useState<IRow[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    setColumns([
      {
        key: 'id',
        dataIndex: 'id',
      },
      {
        key: 'date',
        render: (text: string, record: IRow) => {
          return t('GLOBAL.DATE_TO_DATE', {
            date1: record.start,
            date2: record.end ? record.end : '__/__/____',
          });
        },
      },
      {
        key: 'action',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IRow) => {
          return (
            <Button
              type="link"
              onClick={() => {
                onClose();
                changeForm(record.id);
              }}
            >
              {t('HR.SEE_FORM')}
            </Button>
          );
        },
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    if (!visible || !userRecordId || !departmentId) {
      return;
    }

    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setIsLoading(true);
    setData([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${userRecordId}/hr`, {
        params: {
          departmentId,
        },
      })
      .then(({ data }) => {
        if (mounted) {
          setData(data?.data || []);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (mounted) {
          setIsLoading(false);
        }
        console.error(error);
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [visible, departmentId, userRecordId]);

  return (
    <Modal
      className={className}
      visible={visible}
      onCancel={() => onClose()}
      title={t('HR.FORM_HISTORY')}
      footer={null}
      width={750}
    >
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        rowKey="id"
        showHeader={false}
        pagination={false}
      />
    </Modal>
  );
};

export default styled(ModalHrFormList)`
  .ant-row.ant-form-item {
    margin-bottom: 0px;
  }
`;

interface IRow {
  id: string;
  start: number;
  end?: number;
}
