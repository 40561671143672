import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  onNewSignature: () => void;
}

const Header: React.FC<Props> = ({ className, onNewSignature }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <header className={className}>
      <div className="left">
        <h2>{t('DOCUMENTS.SIGNATURES.TITLE')}</h2>
      </div>
      <div className="right">
        <div className="actions">
          <Button type="primary" onClick={onNewSignature}>
            {t('DOCUMENTS.SIGNATURES.NEW_SIGNATURE')}
          </Button>
        </div>
      </div>
    </header>
  );
};

export default styled(Header)`
  .right {
    .actions {
      button {
        width: 160ps;
      }
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 15px;

    .left {
      h2 {
        margin-left: 0 !important;
      }
    }

    .right {
      .actions  {
        width: 100%;
        button {
          margin-left: 0 !important;
          width: 100%;
        }
      }
    }
  }
`;
