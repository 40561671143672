import colors from '@/styles/colors';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../../redux/actions';

interface Props {
  className?: string;
}

const CreateShiftBlockButton: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const dispatch: Dispatch<ActionType> = useDispatch();

  const onClick = () => {
    dispatch({
      type: 'SET_CREATE_NEW_SHIFT_BLOCK',
      payload: true,
    });
  };

  return (
    <button className={className} onClick={onClick}>
      <i className="icon-plus" />
      {t('SCHEDULE.MONTHLY_VIEW.SHIFT_BLOCKS.ADD_NEW_SHIFT_BLOCK')}
    </button>
  );
};

export default styled(CreateShiftBlockButton)`
  border: 1px dashed ${colors.green};
  padding: 0 15px;
  border-radius: 5px;
`;
