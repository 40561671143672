import React from 'react';
import { IScheduleDay } from '@/types/schedule-day.model';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  differences: IScheduleDay['differences'];
}

const ComparisonDetails: React.FC<Props> = ({ className, differences }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const missingCount = differences?.missing?.reduce((a, b) => a + b.count, 0) || 0;
  const addedCount = differences?.added?.reduce((a, b) => a + b.count, 0) || 0;

  return (
    <div className={className}>
      <h4>
        <i className="icon-compare" /> {t('GLOBAL.COMPARISON')}
      </h4>
      {!!differences?.missing?.length && (
        <React.Fragment>
          <b>{missingCount > 1 ? t('GLOBAL.MISSING_SHIFTS') : t('GLOBAL.MISSING_SHIFT')}</b>
          <ul>
            {differences?.missing?.map(({ count, from, to }, index) => (
              <li key={index}>
                {count}{' '}
                {t('GLOBAL.FROM_HOUR_TO_HOUR', {
                  hour1: from,
                  hour2: to,
                }).toLocaleLowerCase()}
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
      {!!differences?.added?.length && (
        <React.Fragment>
          <b>{addedCount > 1 ? t('GLOBAL.ADDED_SHIFTS') : t('GLOBAL.ADDED_SHIFT')}</b>
          <ul>
            {differences?.added?.map(({ count, from, to }, index) => (
              <li key={index}>
                {count}{' '}
                {t('GLOBAL.FROM_HOUR_TO_HOUR', {
                  hour1: from,
                  hour2: to,
                }).toLocaleLowerCase()}
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
    </div>
  );
};

export default styled(ComparisonDetails)`
  h4 {
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: 1px solid ${colors.greyLight};
    margin-bottom: 10px;
    color: ${colors.grey};
  }
  ul {
    margin: 0;
    padding: 0 0 10px 10px;
    list-style: none;
    li {
      margin: 0;
      list-style: none;
    }
  }
`;
