import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Button, Input, Modal } from 'antd';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  activeApp: IMarketplaceApp;
  visible?: boolean;
  loading: boolean;
  onInstall: () => void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalPaidOption: React.FC<Props> = ({ className, activeApp, visible, loading, onInstall, setVisible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [consent, setConsent] = useState<string>('');

  useEffect(() => {
    setConsent('');
  }, [activeApp, visible]);

  const onCancel = () => {
    setVisible(false);
    setConsent('');
  };

  const onConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConsent(e.target.value);
  };

  const onActivate = () => {
    onInstall();
  };

  return (
    <Modal width={750} className={className} visible={visible} footer={null} onCancel={onCancel}>
      <div className="header">
        <h4 className="title">{t('MARKETPLACE.MODAL_INSTALL_APP.TITLE')}</h4>
      </div>
      <div className="content">
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              `MARKETPLACE.MODAL_INSTALL_APP.${activeApp.price_type == 'ONETIME' ? 'CONTENT_ONETIME' : 'CONTENT'}`,
              {
                word: t('GLOBAL.CONFIRM').toUpperCase(),
                price: activeApp.price,
              },
            ),
          }}
        />
        <Input
          value={consent}
          className="input"
          size="large"
          placeholder={t('GLOBAL.CONFIRM').toUpperCase()}
          onChange={onConsentChange}
        />
        <div className="actions">
          <Button type="primary" danger size="large" onClick={onCancel}>
            {t('GLOBAL.CANCEL')}
          </Button>
          <Button
            type="primary"
            size="large"
            disabled={t('GLOBAL.CONFIRM').toUpperCase() !== consent}
            onClick={onActivate}
            loading={loading}
          >
            {t('GLOBAL.ACTIVATE')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default styled(ModalPaidOption)`
  .ant-modal-content {
    border-radius: 8px !important;
  }
  .ant-modal-body {
    padding: 0;
  }

  .header {
    background-color: ${colors.green};
    display: flex;
    align-items: center;
    padding: 35px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .title {
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  .content {
    padding: 25px 35px;

    p {
      margin: 0;
      font-size: 14px;
      color: #444;
    }

    .input {
      margin-top: 18px;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 25px;
    }
  }
`;
