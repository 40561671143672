import ScrollToTop from '@/components/ScrollToTop';
import colors from '@/styles/colors';
import { ISignature } from '@/types/signature.model';
import { dataURItoBlob, getWindowSize } from '@/utils';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Drawer, Form, Image, Input, notification, Radio, Upload } from 'antd';
import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';
import styled from 'styled-components';

interface Props {
  className?: string;
  departmentId?: string;
  signature: Partial<ISignature> | null;
  onClose: () => void;
  onSave: (result: ISignature) => void;
}

const DrawerSignature: React.FC<Props> = ({ className, departmentId, signature, onClose, onSave }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [signatureIsEmpty, setSignatureIsEmpty] = useState<boolean>(true);
  const signaturePadRef = useRef<any>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const [formData, setFormData] = useState<FormData>(new FormData());
  const [signatureImage, setSignatureImage] = useState<string>('');
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setFormData(new FormData());
    setFileList([]);
    form.resetFields();

    if (signature) {
      form.setFieldsValue({ ...signature });
      const { signaturePath = '' } = signature;
      if (signaturePath) {
        setSignatureImage(signaturePath);
      }
    } else {
      setSignatureImage('');
    }
    // eslint-disable-next-line
  }, [signature]);

  const onFinish = async (values: any) => {
    setIsSaving(true);
    formData.append('name', values.name);
    formData.append('title', values.title);
    if (values.default) {
      formData.append('default', '1');
    }
    if (!signatureImage && values.drawSignature) {
      formData.delete('file');
      const blob = dataURItoBlob(signaturePadRef.current.toDataURL());
      formData.append('file', blob);
    }

    const url = signature?.id
      ? `${process.env.REACT_APP_API_URL}/v3/documents/signatures/${signature.id}`
      : `${process.env.REACT_APP_API_URL}/v3/documents/signatures`;

    Axios({
      method: 'POST',
      url,
      data: formData,
      params: {
        departmentId,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        const result = response.data as ISignature;
        onSave(result);
        onClose();
      })
      .catch((error) => {
        console.log(error);
        const description = signature?.id
          ? t('DOCUMENTS.SIGNATURES.NOTIFICATIONS.EDIT_FAILED')
          : t('DOCUMENTS.SIGNATURES.NOTIFICATIONS.CREATE_FAILED');
        notification.open({
          message: t('DOCUMENTS.SIGNATURES.NOTIFICATIONS.TITLE'),
          description,
          type: 'error',
        });
      });

    setIsSaving(false);
  };

  const onSignatureBegin = () => {
    setSignatureIsEmpty(false);
  };

  const onEraseSignature = () => {
    signaturePadRef.current?.clear();
    setSignatureIsEmpty(true);
  };

  const pictureCustomRequest = (options: any) => {
    const { onSuccess, file } = options;
    const formData = new FormData();
    formData.append('file', file);
    setFormData(formData);
    setFileList([
      {
        ...file,
        name: file.name,
        status: 'done',
      },
    ]);
    onSuccess('done', file);
  };

  const onPictureRemove = async () => {
    return new Promise<boolean>((resolve) => {
      setFileList([]);
      resolve(true);
    });
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={
        signature?.id
          ? t('DOCUMENTS.SIGNATURES.DRAWER.SIGNATURE_MODIFICATION')
          : t('DOCUMENTS.SIGNATURES.DRAWER.ADD_SIGNATURE')
      }
      placement="right"
      visible={!!signature}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <ScrollToTop />
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        scrollToFirstError={true}
        initialValues={{ drawSignature: true }}
      >
        <Form.Item name="name" label={t('GLOBAL.FULLNAME')} rules={[{ required: true }]}>
          <Input id="signature-name" />
        </Form.Item>
        <Form.Item name="title" label={t('DOCUMENTS.SIGNATURES.TITLE/FUNCTION')} rules={[{ required: true }]}>
          <Input id="signature-title" />
        </Form.Item>
        <Form.Item name="default" valuePropName="checked">
          <Checkbox>{t('DOCUMENTS.SIGNATURES.DRAWER.DEFAULT')}</Checkbox>
        </Form.Item>
        {signatureImage && (
          <React.Fragment>
            <Image
              src={signatureImage}
              height={130}
              width={'100%'}
              preview={false}
              fallback={`${process.env.PUBLIC_URL}/img/image-placeholder.png`}
            />
            <Button style={{ width: '100%' }} type="ghost" onClick={() => setSignatureImage('')}>
              {t('DOCUMENTS.SIGNATURES.DRAWER.REMOVE_SIGNATURE')}
            </Button>
          </React.Fragment>
        )}
        {!signatureImage && (
          <React.Fragment>
            <Form.Item name="drawSignature">
              <Radio.Group>
                <Radio value={true}>{t('DOCUMENTS.SIGNATURES.DRAWER.DRAW')}</Radio>
                <Radio value={false}>{t('DOCUMENTS.SIGNATURES.DRAWER.SEND_FILE')}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.drawSignature !== curValues.drawSignature}>
              {() => {
                const drawSignature = form.getFieldValue('drawSignature');
                if (drawSignature) {
                  return (
                    <div className="signature-container">
                      <div className="signature">
                        <SignaturePad
                          options={{ onBegin: onSignatureBegin }}
                          redrawOnResize={true}
                          height={130}
                          ref={signaturePadRef}
                        />
                        {signatureIsEmpty && <span>{t('DOCUMENTS.SIGNATURES.DRAWER.SIGN_HERE')}</span>}
                      </div>
                      <Button disabled={signatureIsEmpty} type="ghost" onClick={onEraseSignature}>
                        {t('GLOBAL.ERASE')}
                      </Button>
                    </div>
                  );
                } else {
                  return (
                    <Upload
                      multiple={false}
                      fileList={fileList}
                      customRequest={pictureCustomRequest}
                      onRemove={onPictureRemove}
                      accept="image/*"
                    >
                      {!fileList?.length && <Button icon={<UploadOutlined />}>Upload</Button>}
                    </Upload>
                  );
                }
              }}
            </Form.Item>
          </React.Fragment>
        )}
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default styled(DrawerSignature)`
  .signature-container {
    .signature {
      background: ${colors.blueExtraLight};
      border-radius: 5px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        position: absolute;
        font-size: 28px;
        color: ${colors.grey};
        animation: blink 4s infinite alternate-reverse;
        pointer-events: none;
      }
    }
    button {
      margin-top: 5px;
      width: 100%;
    }
  }

  .ant-upload {
    width: 100%;
    button {
      width: 100%;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.6;
    }
  }
`;
