import { IDepartment } from '@/types/department.model';
import { Menu } from 'antd';
import { Crisp } from 'crisp-sdk-web';
import React from 'react';
import { useTranslation } from 'react-i18next';
interface Props {
  department?: IDepartment;
  openSupport: () => void;
}

const MenuHelp: React.FC<Props> = (props) => {
  const { department, openSupport, ...otherProps } = props;
  const { t, i18n } = useTranslation(undefined, { useSuspense: true });

  const openChat = (e: any) => {
    if (process.env.REACT_APP_CRISP_WEBSITE_ID) {
      Crisp.chat.show();
      Crisp.chat.open();
    }
  };

  const openWhatsNew = () => {
    switch (i18n.language) {
      case 'fr':
        window.open('https://shyfter.notion.site/Quoi-de-neuf-sur-Shyfter-3d85b835f4444550b582a10e8325f585', '_blank');
        break;
      case 'nl':
        window.open(
          'https://shyfter.notion.site/Wat-is-er-nieuw-op-Shyfter-8b8df2f8403b447fab339f7afe0b39fc',
          '_blank',
        );
        break;
      default:
        window.open('https://shyfter.notion.site/What-s-new-on-Shyfter-fdce3ee77547498b87419094b984ca35', '_blank');
        break;
    }
  };

  return (
    <Menu style={{ maxHeight: 'calc(100vh - 80px)', overflowY: 'auto' }} {...otherProps}>
      <Menu.Item key="whats_new" onClick={openWhatsNew} className="menu_help_whats_new">
        &#x1F680; {t('MENU.HELP.WHATS_NEW')}
      </Menu.Item>
      <Menu.Item key="support" onClick={openSupport} className="menu_help_support">
        &#x2753; {t('MENU.HELP.SUPPORT')}
      </Menu.Item>
      {department?.support && (
        <Menu.Item key="chat" onClick={openChat} className="menu_help_chat">
          &#x1F4AC; {t('MENU.HELP.CHAT')}
        </Menu.Item>
      )}
    </Menu>
  );
};

export default MenuHelp;
