import AppContext from '@/pages/app/context';
import ShiftRightClick from '@/pages/app/hours/manage/components/ShiftRightClick';
import ShiftSectionBar from '@/pages/app/hours/manage/components/ShiftSectionBar';
import Colors from '@/styles/colors';
import { ColoredShiftsTypes } from '@/types/department-settings.model';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IScheduleHour } from '@/types/schedule-hour.model';
import { IShift } from '@/types/shift.model';
import { isFeatureEnabled, isNullOrUndefined } from '@/utils';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHourlyDispatch, useHourlySelector } from '..';
import ShiftAttributes from '../../components/ShiftAttributes';
import ShiftDepartment from '../../components/ShiftDepartment';
import ShiftDetailsIcons from '../../components/ShiftDetailsIcons';
import ShiftSpecialHrCodeStyled from '../../components/ShiftSpecialHrCode';
import ShiftTags from '../../components/ShiftTags';
import TopIcons from '../../components/TopIcons';
import { ActionType, moveTask, updateShiftAndContext } from '../../redux/actions';
import { InitialStateType } from '../../redux/store';
import { ActionType as HourlyActionType } from '../redux/actions';
import { InitialStateType as HourlyInitialStateType } from '../redux/store';
import ShiftTasks from './ShiftTasks';

interface Props {
  className?: string;
  onShiftDragStartHandler: (e: React.DragEvent, shift: IShift) => void;
  onShiftDragEndHandler: (e: React.DragEvent) => void;
  columnWidth: number;
  shift: IShift;
  taskHeight: number;
  userRecordId: string | null;
  hourlyRate?: number;
  refPageContainer: React.MutableRefObject<null> | null;
  activeDepartment?: IDepartment;
  activeSection?: string;
}

type ResizeMode = 'start' | 'end';

const Shift: React.FC<Props> = ({
  className,
  shift,
  userRecordId,
  columnWidth,
  hourlyRate,
  taskHeight,
  refPageContainer,
  activeDepartment,
  activeSection,
  onShiftDragStartHandler,
  onShiftDragEndHandler,
}) => {
  const {
    hours,
    showTasks,
    showSkills,
    showDetails,
    showSections,
    showAttributes,
    showOtherDepartments,
    showPrices,
    showHrCode,
    picker,
    startDate,
    endDate,
    shiftsMap,
    usersMap,
  } = useSelector(
    ({
      hours,
      showTasks,
      showSkills,
      showDetails,
      showSections,
      showAttributes,
      showOtherDepartments,
      showPrices,
      showHrCode,
      picker,
      startDate,
      endDate,
      shiftsMap,
      usersMap,
    }: InitialStateType) => ({
      hours,
      showTasks,
      showSkills,
      showDetails,
      showSections,
      showAttributes,
      showOtherDepartments,
      showPrices,
      showHrCode,
      picker,
      startDate,
      endDate,
      shiftsMap,
      usersMap,
    }),
    shallowEqual,
  );
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  const { taskIsDragging, taskIsDragOver } = useHourlySelector(
    ({ taskIsDragging, taskIsDragOver }: HourlyInitialStateType) => ({
      taskIsDragging,
      taskIsDragOver,
    }),
    shallowEqual,
  );

  const hourlyContextDispatch: Dispatch<HourlyActionType> = useHourlyDispatch();

  const {
    state: { skills, userCategories, features },
  } = useContext(AppContext);

  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(0);
  const [left, setLeft] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [durationInMinutes, setDurationInMinutes] = useState<number>(0);
  const [pauseDurationInMinutes, setPauseDurationInMinutes] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [initialMousePositionX, setInitialMousePositionX] = useState<number | null>(null);
  const [resizeMode, setResizeMode] = useState<ResizeMode | null>(null);
  const [isDragEnter, setIsDragEnter] = useState<number>(0);
  const [startTooltip, setStartTooltip] = useState<boolean>(false);
  const [endTooltip, setEndTooltip] = useState<boolean>(false);
  const [newTaskStart, setNewTaskStart] = useState<number>(0);
  const [newTaskEnd, setNewTaskEnd] = useState<number>(0);
  const [activeTaskDraggingId, setActiveTaskDraggingId] = useState<string | null>(null);
  const canResize =
    (shift.locked && activeDepartment!.role! === 'ADMIN') || (!shift.locked && shift.dayoff?.fullDay) ? false : true;
  const taskIsResizingRef = useRef(false);

  const hr_code_id =
    activeDepartment?.hr_codes && activeDepartment?.hr_codes.find((code) => code.id === shift.hr_code)?.code;

  const initStateWithShift = ({
    shift,
    columnWidth,
    hours,
  }: {
    shift: IShift;
    columnWidth: number;
    hours: IScheduleHour[];
  }) => {
    const { start: shiftStart, end: shiftEnd, pause, price } = shift;
    const startMoment = moment.unix(shiftStart!);
    const endMoment = moment.unix(shiftEnd!);
    const duration = moment.duration(endMoment.diff(startMoment));
    duration.subtract(pause!.unpaid!, 'seconds');
    const durationAsMinutes = duration.asMinutes();
    const pauseDurationInMinutes = moment.duration(pause!.paid! + pause!.unpaid!, 'seconds').asMinutes();

    const diffInMinutes = hours[0] ? startMoment.diff(moment.unix(hours[0].date), 'minutes') : 0;
    const left = (diffInMinutes / 60) * columnWidth;
    const width = (moment.duration(duration).add(pause!.unpaid!, 'seconds').asMinutes() / 60) * columnWidth;

    setStart(shiftStart!);
    setEnd(shiftEnd!);
    setDurationInMinutes(durationAsMinutes);
    setPrice(price!);
    setPauseDurationInMinutes(pauseDurationInMinutes);
    setLeft(left);
    setWidth(width);
  };

  useEffect(() => {
    initStateWithShift({ shift, columnWidth, hours });
  }, [shift, columnWidth, hours]);

  const resizeMouseDownHandler = (e: React.MouseEvent, resizeMode: ResizeMode) => {
    e.preventDefault();
    e.stopPropagation();
    const scrollLeft = refPageContainer ? (refPageContainer!.current! as HTMLElement).scrollLeft : 0;
    setInitialMousePositionX(e.pageX + scrollLeft);
    setResizeMode(resizeMode);
    if (resizeMode == 'start') {
      setStartTooltip(true);
    }
    if (resizeMode == 'end') {
      setEndTooltip(true);
    }
  };

  const resizeMouseUpHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setEndTooltip(false);
    setStartTooltip(false);
    setInitialMousePositionX(null);
    setResizeMode(null);

    const oldValues: IShift = {
      id: shift.id,
      start: shift.start,
      end: shift.end,
    };

    const newValues: IShift = {
      id: shift.id,
      start: start,
      end: end,
    };

    updateShiftAndContext(hoursDispatch, oldValues, newValues, {
      picker,
      startDate,
      endDate: endDate.unix(),
      departmentId: activeDepartment?.id,
      sectionId: activeSection,
      department: activeDepartment,
      activeSection,
      skills,
      userStatus: userCategories,
    });

    return false;
  };

  const resizeMouseLeaveHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    setInitialMousePositionX(null);
    setResizeMode(null);
    initStateWithShift({ shift, columnWidth, hours });
  };

  const resizeMouseMoveHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    const scrollLeft = refPageContainer ? (refPageContainer!.current! as HTMLElement).scrollLeft : 0;
    const difference = e.pageX + scrollLeft - (initialMousePositionX || 0);
    const columnDifference = Math.trunc((difference / columnWidth) * 4);

    if (resizeMode === 'end') {
      const newEnd = moment.unix(shift.end!).add(columnDifference * 15, 'minutes');
      setEnd(newEnd.unix());
    } else if (resizeMode === 'start') {
      const newStart = moment.unix(shift.start!).add(columnDifference * 15, 'minutes');
      const diffInMinutes = newStart.diff(moment.unix(hours[0].date), 'minutes');
      const left = (diffInMinutes / 60) * columnWidth;
      setStart(newStart.unix());
      setLeft(left);
    }

    const duration = moment.duration(moment.unix(end).diff(moment.unix(start)));
    const unpaidPause = shift.pause!.unpaid;
    duration.subtract(unpaidPause, 'seconds');
    const durationAsMinutes = duration.asMinutes();
    if (isNullOrUndefined(hourlyRate) && shift.skills && shift.skills.length > 0) {
      const maxSkillPrice = Math.max(...shift.skills.map((skill) => skill.price || 0));
      hourlyRate = maxSkillPrice;
    }
    const price = (durationAsMinutes / 60) * (hourlyRate || 0);

    setDurationInMinutes(durationAsMinutes);
    setPrice(price);
    setWidth((moment.duration(duration).add(unpaidPause, 'seconds').asMinutes() / 60) * columnWidth);
  };

  const onTaskDragStartHandler = (e: React.DragEvent, shiftId: string, task: any) => {
    e.stopPropagation(); // Needed to avoid to drag the shift itself
    e.dataTransfer.setData('taskId', `${task.id}`);
    e.dataTransfer.setData('shiftId', `${shiftId}`);
    e.dataTransfer.dropEffect = 'move';
    setActiveTaskDraggingId(`${task.id}`);

    const target = e.target as HTMLElement;
    const scrollLeft = refPageContainer ? (refPageContainer!.current! as HTMLElement).scrollLeft : 0;
    const columnClicked = Math.trunc((e.pageX - 225 + scrollLeft) / (columnWidth / 4));
    const offsetLeftShift = target.parentNode!.parentNode!.parentNode!.parentElement!.offsetLeft!;
    const offsetLeft = target.offsetLeft;
    const columnStarted = Math.trunc((offsetLeft + offsetLeftShift) / (columnWidth / 4));

    setTimeout(() => {
      target.classList.add('block-hide');
      hourlyContextDispatch({
        type: 'SET_TASK_IS_DRAGGING',
        payload: {
          task,
          padding: columnClicked - columnStarted,
        },
      });
    }, 0);
  };

  const onTaskDragEnterHandler = (e: React.DragEvent) => {
    e.preventDefault();
    const scrollLeft = refPageContainer ? (refPageContainer!.current! as HTMLElement).scrollLeft : 0;
    const left = e.clientX - scrollLeft - 225;
    const column = Math.round(left / (columnWidth / 4));
    if (taskIsDragging?.task.start && taskIsDragging?.task.end) {
      const newStart = hours[0].date + (column - taskIsDragging!.padding) * 900;
      const newEnd = newStart + taskIsDragging?.task.end - taskIsDragging.task.start;
      setNewTaskStart(newStart);
      setNewTaskEnd(newEnd);
    } else {
      setActiveTaskDraggingId(null);
    }
    if (taskIsDragOver?.column !== column) {
      hourlyContextDispatch({
        type: 'SET_TASK_IS_DRAG_OVER',
        payload: {
          column: column,
        },
      });
    }
  };

  const onTaskDragEndHandler = (e: React.DragEvent) => {
    const target = e.target as HTMLElement;
    setActiveTaskDraggingId(null);
    hourlyContextDispatch({
      type: 'SET_TASK_IS_DRAGGING',
      payload: null,
    });
    hourlyContextDispatch({
      type: 'SET_TASK_IS_DRAG_OVER',
      payload: null,
    });
    setIsDragEnter(0);
    target.classList.remove('block-hide');
  };

  const onTaskDropHandler = (e: React.DragEvent) => {
    const taskId = e.dataTransfer.getData('taskId');
    const shiftId = e.dataTransfer.getData('shiftId');
    const start = taskIsDragging!.task.start!;
    const end = taskIsDragging!.task.end!;
    const newStart = hours[0].date + (taskIsDragOver!.column - taskIsDragging!.padding) * 900;
    const newEnd = newStart + end - start;

    const source = {
      shiftId,
      taskId,
      start,
      end,
    };

    const destination = {
      shiftId: shift.id!,
      newStart,
      newEnd,
    };

    moveTask(
      shiftsMap,
      usersMap,
      hoursDispatch,
      { source, destination },
      {
        picker,
        startDate: startDate,
        endDate: endDate.unix(),
        departmentId: activeDepartment?.id,
        sectionId: activeSection,
        department: activeDepartment,
        activeSection,
        skills,
        userStatus: userCategories,
      },
    );
    onTaskDragEndHandler(e);
  };

  const onClick = (e: React.MouseEvent) => {
    if (!taskIsResizingRef.current) {
      if (e.button !== 2) {
        hoursDispatch({
          type: 'SET_SELECTED_SHIFT',
          payload: shift,
        });
        hoursDispatch({
          type: 'SET_SHIFT_DRAWER_VISIBLE',
          payload: true,
        });
      }
    }
  };

  let style: any = {
    left,
    width,
    backgroundColor: shift.locked && activeDepartment!.role! !== 'ADMIN' ? '#bdbcc657' : 'rgb(235, 235, 235)',
    cursor: shift.locked && activeDepartment!.role! !== 'ADMIN' ? 'no-allowed' : 'grab',
  };
  const coloredShifts = activeDepartment?.scheduleParams?.colored_shifts;
  if (coloredShifts && shift.userRecordId) {
    if (coloredShifts == ColoredShiftsTypes.SKILLS && shift.skills && shift.skills[0].background) {
      style.backgroundColor = shift.skills[0].background;
    }
    if (coloredShifts == ColoredShiftsTypes.ATTRIBUTES && shift.attributes && shift.attributes[0].background) {
      style.backgroundColor = shift.attributes[0].background;
    }
    if (coloredShifts == ColoredShiftsTypes.STATUS) {
      const user = usersMap.get(shift.userRecordId);
      if (user && user.userType && user.userType.background) {
        style.backgroundColor = user.userType.background;
        if (shift.dayoff)
          style.backgroundImage = `repeating-linear-gradient(-55deg, transparent 0 10px, rgba(0,0,0,0.08) 10px 20px)`;
      }
    }
  }

  return (
    <ShiftRightClick shift={shift}>
      <div
        id={shift.id}
        className={`${className}
          ${shift.optimistic ? 'optimistic' : ''} ${initialMousePositionX ? 'resize' : ''}
          ${userRecordId ? '' : 'open-shift'}
          ${taskIsDragging ? 'task-drag-enter' : ''}
          ${resizeMode ? resizeMode : ''}
          ${shift.dayoff?.name ? 'dayoff' : ''}
          ${shift.approved ? '' : 'not-approved'}
          ${
            activeDepartment?.scheduleParams?.ghost_shifts && activeSection && activeSection !== shift.section?.id
              ? 'other-section'
              : ''
          }
        `}
        draggable={shift.optimistic ? false : true}
        onDragStart={(e) => {
          if ((shift.locked && activeDepartment!.role! === 'ADMIN') || !shift.locked) {
            onShiftDragStartHandler(e, shift);
          }
        }}
        onDragEnd={(e) => {
          if ((shift.locked && activeDepartment!.role! === 'ADMIN') || !shift.locked) {
            onShiftDragEndHandler(e);
          }
        }}
        onDragEnter={() => {
          if ((shift.locked && activeDepartment!.role! === 'ADMIN') || !shift.locked) {
            setIsDragEnter(isDragEnter + 1);
          }
        }}
        onDragLeave={(e) => {
          if ((shift.locked && activeDepartment!.role! === 'ADMIN') || !shift.locked) {
            setIsDragEnter(isDragEnter - 1);
          }
        }}
        onMouseMove={(e) => {
          if ((shift.locked && activeDepartment!.role! === 'ADMIN') || !shift.locked) {
            initialMousePositionX ? resizeMouseMoveHandler(e) : undefined;
          }
        }}
        onMouseLeave={(e) => {
          if ((shift.locked && activeDepartment!.role! === 'ADMIN') || !shift.locked) {
            initialMousePositionX ? resizeMouseLeaveHandler : undefined;
          }
        }}
        onMouseUp={(e) => {
          if ((shift.locked && activeDepartment!.role! === 'ADMIN') || !shift.locked) {
            initialMousePositionX ? resizeMouseUpHandler(e) : onClick(e);
          }
        }}
        style={style}
      >
        {canResize && (
          <React.Fragment>
            <div
              className={`extra ${!isNullOrUndefined(initialMousePositionX) ? 'visible' : ''}`}
              style={{
                left: `${-columnWidth}px`,
                width: `${columnWidth}px`,
              }}
            ></div>
            <Tooltip
              overlay={<span>{moment.unix(start).format('HH:mm')}</span>}
              visible={startTooltip && !taskIsDragging}
            >
              <div
                className="resize left"
                onMouseDown={(e) => {
                  e.stopPropagation();
                  resizeMouseDownHandler(e, 'start');
                }}
              >
                |
              </div>
            </Tooltip>
          </React.Fragment>
        )}
        <div className="card">
          {!activeSection && <ShiftSectionBar section={shift.section} />}
          {!shift.dayoff && (
            <React.Fragment>
              {showOtherDepartments && <ShiftDepartment departmentId={shift.departmentId!} />}
              {showDetails && (
                <div style={{ display: 'flex' }}>
                  <ShiftDetailsIcons
                    hidePrice={!showPrices || !activeDepartment?.scheduleParams?.showShiftPrice}
                    durationInMinutes={durationInMinutes}
                    pauseDurationInMinutes={pauseDurationInMinutes}
                    price={price!}
                    currency={activeDepartment?.currency}
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                    backgroundColor={style.backgroundColor}
                  />
                  <TopIcons shift={shift} position="relative" department={activeDepartment!} />
                </div>
              )}
              <ShiftTags
                picker={picker}
                section={showSections ? shift.section : undefined}
                skills={isFeatureEnabled(features, FEATURES.SKILLS) && showSkills ? shift.skills || [] : []}
                style={{ display: 'inline' }}
                backgroundColor={style.backgroundColor}
                coloredShifts={coloredShifts}
              />
              {showHrCode && <ShiftSpecialHrCodeStyled hr_code_id={hr_code_id} />}
              <ShiftAttributes
                attributes={showAttributes ? shift.attributes || [] : []}
                style={{ display: 'inline' }}
                backgroundColor={style.background}
              />
              {isFeatureEnabled(features, FEATURES.TASKS) && showTasks && (
                <ShiftTasks
                  taskIsResizingRef={taskIsResizingRef}
                  refPageContainer={refPageContainer}
                  columnWidth={columnWidth}
                  shiftStart={start!}
                  shiftId={shift.id!}
                  draggable={shift.optimistic ? false : true}
                  tasks={shift.tasks || []}
                  taskHeight={taskHeight}
                  onTaskDragStartHandler={(e, task) => onTaskDragStartHandler(e, shift.id!, task)}
                  onTaskDragEndHandler={onTaskDragEndHandler}
                  activeDepartmentId={activeDepartment?.id}
                  activeSection={activeSection}
                  backgroundColor={style.background}
                />
              )}
            </React.Fragment>
          )}
          {shift.dayoff && (
            <React.Fragment>
              {showOtherDepartments && <ShiftDepartment departmentId={shift.departmentId!} />}
              {showDetails && (
                <div className="detail">
                  <h3>{shift.dayoff!.shortCode}</h3>
                  <p> - {shift.dayoff!.name}</p>{' '}
                  <ShiftDetailsIcons
                    hidePrice={!showPrices || !activeDepartment?.scheduleParams?.showShiftPrice}
                    durationInMinutes={durationInMinutes}
                    pauseDurationInMinutes={pauseDurationInMinutes}
                    price={price!}
                    currency={activeDepartment?.currency}
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        {taskIsDragging && (
          <div style={{ zIndex: 2 }}>
            <div
              className="droppable-zone"
              style={{
                left: -left,
              }}
              onDragOver={(e) => {
                onTaskDragEnterHandler(e);
              }}
              onDrop={onTaskDropHandler}
            />
            {!!isDragEnter && taskIsDragging && taskIsDragOver && (
              <Tooltip
                overlay={
                  <span>
                    {moment.unix(newTaskStart).format('HH:mm')} - {moment.unix(newTaskEnd).format('HH:mm')}
                  </span>
                }
                visible={activeTaskDraggingId !== null && taskIsDragging!.task.id == activeTaskDraggingId}
              >
                <div
                  className="task-preview"
                  style={{
                    width: ((taskIsDragging!.task.end! - taskIsDragging!.task.start!) / 3600) * columnWidth,
                    left: (taskIsDragOver!.column - taskIsDragging!.padding) * (columnWidth / 4) - left,
                    height: taskHeight,
                  }}
                />
              </Tooltip>
            )}
          </div>
        )}
        {canResize && (
          <React.Fragment>
            <Tooltip overlay={<span>{moment.unix(end).format('HH:mm')}</span>} visible={endTooltip && !taskIsDragging}>
              <div
                className="resize right"
                onMouseDown={(e) => {
                  e.stopPropagation();
                  resizeMouseDownHandler(e, 'end');
                }}
              >
                |
              </div>
            </Tooltip>
            <div
              className={`extra ${!isNullOrUndefined(initialMousePositionX) ? 'visible' : ''}`}
              style={{
                right: `${-columnWidth}px`,
                width: `${columnWidth}px`,
              }}
            ></div>
          </React.Fragment>
        )}
      </div>
    </ShiftRightClick>
  );
};

const ShiftStyled = styled(Shift)`
  background: #f7f9ff;
  border-radius: 5px;
  padding: 10px;
  margin: 0;
  text-align: left;
  color: ${Colors.blue};
  position: absolute;
  height: calc(100% - 10px);
  cursor: grab;
  flex: 1;
  box-sizing: border-box;
  z-index: 1;

  &.open-shift {
    background-color: white;
    border: 1px solid ${Colors.greenLight};
  }

  &.other-section {
    opacity: 0.5;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }

  &.not-approved {
    &:after {
      content: '';
      top: 0;
      left: 0;
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      border: 2px dashed ${Colors.green};
      border-radius: 5px;
      pointer-events: none;
    }
  }

  &.dayoff {
    background-image: repeating-linear-gradient(
      -55deg,
      transparent,
      transparent 10px,
      rgba(0, 0, 0, 0.03) 10px,
      rgba(0, 0, 0, 0.03) 20px
    );

    background-image: repeating-linear-gradient(-55deg, transparent 0 10px, rgba(0, 0, 0, 0.03) 10px 20px);
  }

  .card {
    .detail {
      display: flex;
      align-items: center;
      p,
      h3 {
        padding: 0;
        margin: 0;
      }
    }
  }

  &.optimistic {
    pointer-events: none;
    animation: blink 1s infinite alternate-reverse;
    .resize {
      pointer-events: none;
    }
  }

  &.task-drag-enter {
    overflow: hidden;
  }

  &.shift-drag-enter {
    pointer-events: none !important;
    & > * {
      pointer-events: none !important;
    }
  }

  &.resize {
    cursor: ew-resize;
    &.start {
      > .resize.left {
        z-index: 4;
        opacity: 1;
      }
    }
    &.end {
      > .resize.right {
        z-index: 4;
        opacity: 1;
      }
    }
  }

  &.block-hide {
    display: none;
  }

  &:hover {
    z-index: 10;
  }

  > .extra {
    display: none;
    position: absolute;
    height: 100%;
    top: 0px;
    &.visible {
      display: block;
    }
  }

  > .resize {
    display: flex;
    opacity: 0.01;
    position: absolute;
    width: 5px;
    height: 100%;
    background: #d0d8e4;
    top: 0;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: ew-resize;
    z-index: 2;

    &:hover {
      opacity: 1;
      z-index: 4;
    }

    &.left {
      left: 0;
      border-radius: 5px 0 0 5px;
    }

    &.right {
      right: 0;
      border-radius: 0 5px 5px 0;
    }
  }

  .card {
    pointer-events: all;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;

    .department {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    h3 {
      font-size: 12px;
      font-weight: 600;
      color: black;
      margin: 0 5px 0 0;
      padding-bottom: 12px;
    }
  }

  .droppable-zone {
    position: absolute;
    z-index: 4;
    top: 0 !important;
    width: calc(100vw - 240px) !important;
    pointer-events: all;
  }

  .task-preview {
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    bottom: 3px;
    z-index: 3;
    pointer-events: none;
  }

  .ant-checkbox-wrapper {
    position: absolute;
    right: 10px;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.6;
    }
  }
`;

export default ShiftStyled;
