import AppContext from '@/pages/app/context';
import { IWeeklyView } from '@/types/weekly-view.model';
import { Button, Form, Modal } from 'antd';
import axios from 'axios';
import React, { Dispatch, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../redux/actions';

interface Props {
  className?: string;
  visible: boolean;
  onClose: () => void;
  view: IWeeklyView;
}

const ModalDeleteView: React.FC<Props> = ({ visible, onClose, view, className }) => {
  const {
    state: { activeDepartment, activeDepartmentId },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onFinish = () => {
    setLoading(true);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/shifts/views/${view.id}`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then((res) => {
        batch(() => {
          appContextDispatch({
            type: 'SET_WEEKLY_VIEWS',
            payload: res.data,
          });
          hoursDispatch({
            type: 'SET_FILTER_OPTIONS',
            payload: [],
          });
          hoursDispatch({
            type: 'UPDATE_FILTERED_SHIFTS',
            payload: { department: activeDepartment },
          });
        });
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Modal className={className} visible={visible} onCancel={onClose} closable={false} footer={null}>
      <Form layout="vertical" onFinish={onFinish}>
        <div style={{ padding: 15 }}>
          <h2>{t('GLOBAL.DELETION')}</h2>
          <p>{t('WEEKLY_VIEWS.DELETE_VIEW_DESCRIPTION', { name: view?.name })}</p>
        </div>
        <div className="buttons-container">
          <Button key="back" onClick={onClose}>
            {t('GLOBAL.CANCEL')}
          </Button>
          <Button loading={loading} danger htmlType="submit" style={{ marginLeft: 10 }}>
            {t('GLOBAL.REMOVE')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default styled(ModalDeleteView)`
  .buttons-container {
    display: flex;
    justify-content: flex-end;
  }
`;
