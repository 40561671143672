import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IResponse } from '.';

const { Option } = Select;

interface Props {
  className?: string;
  meta?: IResponse['meta'];
}

const Occupation: React.FC<Props> = ({ className, meta }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <div className={className}>
      <Row gutter={[20, 10]}>
        <Col lg={8} md={12}>
          <Form.Item
            style={{ width: '100%' }}
            name={'salary'}
            label={t('USERS.USER.HR.SALARY')}
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              step="any"
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item name={'fixed_advance'} label={t('USERS.USER.HR.FIXED_ADVANCE')}>
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              step="any"
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item name={'working_hours_numerator'} label={t('USERS.USER.HR.WORKING_HOURS_NUMERATOR')}>
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              step="any"
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item name={'working_hours_denominator'} label={t('USERS.USER.HR.WORKING_HOURS_DENOMINATOR')}>
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              step="any"
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.QUOTIENT')} name={'quotient'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>

        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.CATEGORY_CODE')} name={'category_code'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.SUBJECT_TO_NSSO')} name={'subject_to_nsso'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.SUBJECT_TO_PAYROLL_TAX')} name={'subject_to_payroll_tax'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.NIGHT_OR_SHIFT_WORK')} name={'night_or_shift_work'}>
            <Select style={{ width: '100%' }}>
              {meta?.['night_or_shift_work']?.map(({ id, name }) => (
                <Option key={`night_or_shift_work_${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.VOLUNTEER_AMOUNT')} name={'volunteer_amount'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.VOLUNTEER_PERCENTAGE')} name={'volunteer_percentage'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item
            label={t('USERS.USER.HR.WORK_LOCATION')}
            name={'work_location'}
            rules={[{ required: true, message: '' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item label={t('USERS.USER.HR.WORKTIME_MEASURE')} name={'worktime_measure'}>
            <InputNumber style={{ width: '100%' }} type="number" min={1} step={1} precision={0} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default styled(Occupation)``;
