import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Form, Button, Checkbox, notification } from 'antd';
import { ExportMode } from '..';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { IResource } from '@/types/resource.model';

interface Props {
  className?: string;
  departmentId?: string;
  mode: ExportMode;
  resource?: IResource | null;
  onClose: () => void;
}

const ModalExport: React.FC<Props> = ({ className, departmentId, mode, resource, onClose }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onFinish = (values: any) => {
    setIsSaving(true);
    const data = {
      fields: [] as string[],
      extraFields: [] as string[],
    };

    for (const [key, value] of Object.entries(values)) {
      if (value) {
        if (key.startsWith('field_')) {
          data.fields.push(key?.substring(6));
        } else {
          data.extraFields.push(key);
        }
      }
    }

    Axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/v3/resources/${resource?.id}/export`,
      data,
      params: {
        departmentId,
      },
    })
      .then((response) => {
        notification.open({
          message: response?.data?.message,
          type: 'info',
        });
        setIsSaving(false);
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  return (
    <Modal
      className={className}
      visible={!!mode}
      onOk={() => form.submit()}
      onCancel={() => onClose()}
      title={mode === 'PDF' ? t('GLOBAL.PRINT_OPTIONS') : t('GLOBAL.EXPORT_OPTIONS')}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
          {t('GLOBAL.VALIDATE')}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        {resource?.fields?.map((field) => {
          const { id, label } = field;
          return (
            <Form.Item key={id} name={'field_' + id} valuePropName="checked" initialValue={true}>
              <Checkbox>{label}</Checkbox>
            </Form.Item>
          );
        })}
        {resource?.resourceType === 'TOOL' && (
          <Form.Item name="codeType" valuePropName="checked" initialValue={true}>
            <Checkbox>{t('GLOBAL.CODE_TYPE')}</Checkbox>
          </Form.Item>
        )}
        {resource?.resourceType === 'PLACE' && (
          <React.Fragment>
            <Form.Item name="address" valuePropName="checked" initialValue={true}>
              <Checkbox>{t('GLOBAL.ADDRESS')}</Checkbox>
            </Form.Item>
            <Form.Item name="lat" valuePropName="checked" initialValue={true}>
              <Checkbox>Latitude</Checkbox>
            </Form.Item>
            <Form.Item name="lng" valuePropName="checked" initialValue={true}>
              <Checkbox>Longitude</Checkbox>
            </Form.Item>
          </React.Fragment>
        )}
        <Form.Item name="code" valuePropName="checked" initialValue={true}>
          <Checkbox>{t('GLOBAL.CODE')}</Checkbox>
        </Form.Item>
        <Form.Item name="comment" valuePropName="checked" initialValue={true}>
          <Checkbox>{t('GLOBAL.COMMENT')}</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalExport)`
  .ant-row.ant-form-item {
    margin-bottom: 0px;
  }
`;
