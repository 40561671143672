import React, { useEffect, useState } from 'react';
import FullCalendar, { DatesSetArg, EventSourceInput } from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import ContainerView from '@/layouts/ContainerView';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import frLocales from '@fullcalendar/core/locales/fr';
import nlLocales from '@fullcalendar/core/locales/nl';
import { NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
}

interface IResource {
  id: string;
  title: string;
}

interface IInputEvent {
  id: string;
  resourceId: string;
  start: number;
  end: number;
  dayoff?: {
    name: string;
    fullDay: boolean;
  };
  allDay: boolean;
}

const SchedulePage: React.FC<Props> = ({ className }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation(undefined, { useSuspense: false });

  const { token } = useParams();
  const [period, setPeriod] = useState<{ start: number | null; end: number | null; view: string | null }>({
    start: null,
    end: null,
    view: null,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [resources, setResources] = useState<IResource[]>([]);
  const [events, setEvents] = useState<EventSourceInput>([]);

  useEffect(() => {
    const { start, end, view } = period;
    if (view && start && end && token) {
      let mounted = true;
      setLoading(true);
      setResources([]);
      setEvents([]);

      const cancelTokenSource = axios.CancelToken.source();
      axios
        .get(`${process.env.REACT_APP_API_URL}/public-schedule`, {
          params: {
            start,
            end,
            token,
          },
        })
        .then(({ data }) => {
          if (mounted) {
            const { resources = [], events = [] } = data;
            if (view === 'dayGridMonth') {
              const resourcesMap = new Map<string, string>();
              for (let i = 0; i < resources.length; i++) {
                const { id, title } = resources[i];
                resourcesMap.set(id, title);
              }
              setEvents(
                events.map((e: IInputEvent) => ({
                  ...e,
                  start: moment.unix(e.start).toDate(),
                  end: moment.unix(e.end).toDate(),
                  title: resourcesMap.get(e.resourceId),
                  ...(e.dayoff
                    ? {
                        backgroundColor: 'grey',
                        borderColor: 'grey',
                        title: `${resourcesMap.get(e.resourceId)} (${e.dayoff.name})`,
                        allDay: e.dayoff.fullDay,
                      }
                    : {}),
                })),
              );
            } else {
              setResources(resources.map((r: IResource, i: number) => ({ ...r, index: i })));
              setEvents(
                events.map((e: IInputEvent) => ({
                  ...e,
                  start: moment.unix(e.start).toDate(),
                  end: moment.unix(e.end).toDate(),
                  ...(e.dayoff
                    ? {
                        backgroundColor: 'grey',
                        borderColor: 'grey',
                        title: e.dayoff.name,
                        allDay: e.dayoff.fullDay,
                      }
                    : {}),
                })),
              );
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            console.log(error);
          }
          if (mounted) {
            setLoading(false);
          }
        });
      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [period.start, period.end, period.view]);

  const onDatesSet = (args: DatesSetArg) => {
    const { start, end } = args;
    const startTime = start.getTime() / 1000;
    const endTime = end.getTime() / 1000;

    if (period.start !== startTime || period.end !== endTime) {
      setPeriod({
        start: startTime,
        end: endTime,
        view: args.view.type,
      });
    }
  };

  return (
    <div className={className}>
      <header>
        <div className="left">
          <NavLink to="/app/dashboard" className="shyfter-logo">
            <img src={process.env.PUBLIC_URL + '/shyfter-logo.svg'} alt="Shyfter" />
            <img className="logo-icon" src={process.env.PUBLIC_URL + '/shyfter-logo-min.svg'} alt="Shyfter" />
          </NavLink>
        </div>
      </header>

      <ContainerView>
        <div className="calendar-container">
          <FullCalendar
            viewClassNames={loading ? 'loading' : ''}
            height="calc(100vh - 100px)"
            timeZone="Atlantic/Reykjavik"
            locales={[frLocales, nlLocales]}
            locale={language}
            plugins={[resourceTimelinePlugin, dayGridPlugin]}
            initialView={'resourceTimelineWeek'}
            resources={resources}
            resourceOrder="index"
            resourceAreaHeaderContent={t('GLOBAL.USERS')}
            resourceAreaWidth={220}
            datesSet={onDatesSet}
            firstDay={1}
            displayEventEnd={true}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'resourceTimelineDay,resourceTimelineWeek,dayGridMonth',
            }}
            stickyHeaderDates={true}
            events={events}
          />
        </div>
      </ContainerView>
    </div>
  );
};

export default styled(SchedulePage)`
  padding-top: 50px;
  header {
    top: 0;
    left: 0;
    position: fixed;
    height: 50px;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;

      a.shyfter-logo {
        img {
          height: 22px;
        }
        img.logo-icon {
          display: none;
        }

        @media screen and (max-width: 900px) {
          img {
            display: none;
          }
          img.logo-icon {
            display: inline;
          }
        }
      }

      .select-section {
        min-width: 150px;
        max-width: 300px;
      }
    }
  }

  .calendar-container {
    padding: 10px;
    height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      flex: 1;
    }
    .loading {
      animation: blinker 1s linear infinite;
    }
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;
