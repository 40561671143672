import React, { useContext, useEffect, useState } from 'react';
import TableView from '@/layouts/TableView';
import { BackTop, Button, Space, Table } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { Link, useHistory } from 'react-router-dom';
import { IHrSheetData, IHrSheetExport } from '@/types/hr-sheet-export.model';
import FileSaver from 'file-saver';
import axios from 'axios';
import Actions from './components/Actions';

const AppReportsHrSheetExports: React.FC = () => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);
  const [hrSheetData, setHrSheetData] = useState<IHrSheetData | null>(null);
  const [hrSheetLoading, setHrSheetsLoading] = useState<boolean>(false);

  const onDownload = async (file: any, record: IHrSheetExport) => {
    const ext_splitted = file.url.split('.');
    const ext = ext_splitted[ext_splitted.length - 1];
    const { period, createdAt } = record;
    const name = `${file.name.toLowerCase()}_${activeDepartmentId}_${period}_${createdAt}`;

    FileSaver.saveAs(file.url, `${name}.${ext}`);
  };

  useEffect(() => {
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    if (hrSheetData) {
      setColumns([
        {
          title: t('GLOBAL.PERIOD'),
          key: 'period',
          dataIndex: 'period',
        },
        {
          title: t('GLOBAL.DATE'),
          key: 'date',
          // eslint-disable-next-line react/display-name
          render: (text: string, record: IHrSheetExport) => {
            const period = record.period;
            if (period) {
              return <span>{moment(`${period}01`).format('MMMM YYYY')}</span>;
            } else {
              return null;
            }
          },
        },
        {
          title: t('GLOBAL.CREATION'),
          key: 'creation',
          // eslint-disable-next-line react/display-name
          render: (text: string, record: IHrSheetExport) => {
            const createdAt = record.createdAt;
            if (createdAt) {
              return <span>{moment.unix(createdAt).format('L HH:mm')}</span>;
            } else {
              return null;
            }
          },
        },
        {
          title: t('GLOBAL.CREATED_BY'),
          key: 'createdBy',
          // eslint-disable-next-line react/display-name
          render: (text: string, record: IHrSheetExport) => {
            const { createdBy } = record;
            if (createdBy) {
              const { recordId, displayName } = createdBy;
              return (
                <Link to={`/app/team/collaborators/${recordId}`} className="green">
                  {displayName}
                </Link>
              );
            } else {
              return null;
            }
          },
        },
        {
          title: t('GLOBAL.DOWNLOADING'),
          key: 'download',
          // eslint-disable-next-line react/display-name
          render: (text: string, record: IHrSheetExport) => {
            return (
              <Space size="middle">
                {record.files?.map((file, index) => (
                  <button
                    style={{ color: colors.green }}
                    key={`export_${record.id}_file_${index}`}
                    onClick={() => onDownload(file, record)}
                  >
                    {file.name}
                  </button>
                ))}
              </Space>
            );
          },
        },
        {
          title: t('GLOBAL.DETAIL'),
          key: 'detail',
          // eslint-disable-next-line react/display-name
          render: (text: string, record: IHrSheetExport) => {
            return <Actions record={record} data={hrSheetData} />;
          },
        },
      ]);
    }
  }, [i18n.language, hrSheetData]);

  useEffect(() => {
    if (!activeDepartmentId) {
      return;
    }
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setHrSheetsLoading(true);
    setHrSheetData(null);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/reports/hr-sheet-exports`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        if (mounted) {
          setHrSheetData(data);
          setHrSheetsLoading(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setHrSheetsLoading(false);
        }
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId]);

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <header>
        <div className="left">
          <h2>{t('REPORTS.EXPORT_HR_TIMESHEETS.TITLE')}</h2>
        </div>
      </header>
      <Table
        loading={hrSheetLoading}
        dataSource={hrSheetData?.exports}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
    </TableView>
  );
};

export default AppReportsHrSheetExports;
