import ModalSwitchToProAccount from '@/pages/app/components/ModalSwitchToProAccount';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IMarketplaceAppCategory } from '@/types/marketplace/marketplace-app-category.model';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import { capitalize, isAdmin, isFree, isTrial } from '@/utils';
import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  app: IMarketplaceApp;
  setActiveApp: React.Dispatch<React.SetStateAction<IMarketplaceApp | null>>;
}

const App: React.FC<Props> = ({ className, app, setActiveApp }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const [modalSwitchProVisible, setModalSwitchProVisible] = useState<boolean>(false);

  const onClick = () => {
    if (isFree(activeDepartment!)) {
      setModalSwitchProVisible(!modalSwitchProVisible);
    } else {
      setActiveApp(app);
    }
  };

  return (
    <div className={`${className} ${app.preferred_partner && !app.installed ? 'preferred' : ''}`} onClick={onClick}>
      {app.category !== IMarketplaceAppCategory.MODULES && <img className="app-icon" src={app.logo} alt="app-logo" />}
      <div className="top">
        <h4 className="left">{app.title}</h4>
        <div className={`right ${app.category !== IMarketplaceAppCategory.MODULES ? 'absolute' : ''}`}>
          {app.installed && app.category !== IMarketplaceAppCategory.TRAINING ? (
            <div className="installed-badge">
              <span className="installed">
                {IMarketplaceAppCategory.MODULES ? t('MARKETPLACE.ACTIVATED') : t('MARKETPLACE.INSTALLED')}
              </span>
            </div>
          ) : (
            <div className="price">
              <i className="icon icon-money" />
              <span className="app-top-icon-text">
                {app.price_type !== 'FREE' && <>{app.price}&euro;</>}
                {app.price_type !== 'ONETIME' && app.price_type !== 'FREE' ? '/' : ' '}
                {app.price_type == 'FREE'
                  ? `${capitalize(t(`MARKETPLACE.PRICE_TYPES.FREE`))}`
                  : t(`MARKETPLACE.PRICE_TYPES.${app.price_type}`).toLowerCase()}
              </span>
            </div>
          )}
        </div>
      </div>
      <p className="app-description">{app.description}</p>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        {app.preferred_partner && <div className="preferred_partner">{t('MARKETPLACE.PREFERRED_PARTNER')}</div>}
        {!isTrial(activeDepartment!) &&
          isAdmin(activeDepartment!.role!) &&
          !app.installed &&
          app.category == IMarketplaceAppCategory.MODULES && (
            <Button className="activate-module" type="primary" size="small">
              {t(isFree(activeDepartment!) ? 'GLOBAL.SWITCH_TO_PRO' : 'MARKETPLACE.ACTIVATE_MODULE')}
            </Button>
          )}
        {!app.installed && isTrial(activeDepartment!) && <div className="trial">{t('MARKETPLACE.TRIAL')}</div>}
      </div>
      <ModalSwitchToProAccount visible={modalSwitchProVisible} setVisible={setModalSwitchProVisible} />
    </div>
  );
};

export default styled(App)`
  display: flex;
  flex-direction: column;
  width: 32%;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  cursor: pointer;
  background-color: #fff;

  &.preferred {
    background-color: aliceblue;
  }

  .top {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    justify-content: space-between;

    .left {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 0;

      .icon {
        color: ${colors.green};
      }
    }

    .right {
      &.absolute {
        position: absolute;
        top: 15px;
        right: 20px;
      }

      .price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 10px;
        color: ${colors.grey};
      }

      .installed-badge {
        background-color: ${colors.orange};
        padding: 1px 10px;
        border-radius: 5px;
      }

      .installed {
        font-size: 10px;
        color: white;
        text-align: end;
      }

      .deactivated {
        font-size: 10px;
        color: ${colors.red};
        text-align: end;
      }

      &.active {
        .icon {
          color: #000;
        }

        .app-top-icon-text {
          color: ${colors.green};
        }

        .app-top-icon-text {
          font-weight: bold;
        }
      }
    }
  }

  .app-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
  }

  .app-description {
    font-size: 12px;
    color: ${colors.grey};
    flex: 1;
  }

  .preferred_partner {
    text-transform: uppercase;
    background-color: darkblue;
    text-align: center;
    padding: 5px 0;
    border-radius: 7px;
    font-weight: bold;
    color: #fff;
  }

  .activate-module {
    align-self: flex-end;
    margin-top: 10px;
  }

  .trial {
    background-color: #bbbbbb;
    padding: 5px 15px;
    border-radius: 8px;
    text-align: center;
    font-size: 13px;
  }
`;
