import React, { useContext, useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import styled from 'styled-components';
import Header from './Header';
import Content from './Content';
import Details from './Details';
import Auth from './Auth';
import Success from './Success';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import axios from 'axios';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import { IMarketplaceAppFields } from '@/types/marketplace/marketplace-app-fields.model';
import { IMarketplaceAppCategory } from '@/types/marketplace/marketplace-app-category.model';
import Uninstalled from './Uninstalled';
import Container from './Container';

interface Props {
  className?: string;
  activeApp: IMarketplaceApp | null;
  activePage: 'content' | 'auth' | 'success' | 'uninstalled';
  category: IMarketplaceAppCategory;
  setActiveApp: React.Dispatch<React.SetStateAction<IMarketplaceApp | null>>;
  setActivePage: React.Dispatch<React.SetStateAction<'content' | 'auth' | 'success' | 'uninstalled'>>;
  getApps: () => void;
  getFeatures: () => void;
}

const ModalAppDetails: React.FC<Props> = ({
  className,
  activeApp,
  activePage,
  category,
  setActivePage,
  setActiveApp,
  getApps,
  getFeatures,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  return (
    <Modal
      width={1000}
      className={className}
      visible={activeApp !== null}
      footer={null}
      onCancel={() => setActiveApp(null)}
    >
      {activeApp && (
        <Container
          activeApp={activeApp}
          activePage={activePage}
          category={category}
          getApps={getApps}
          setActiveApp={setActiveApp}
          setActivePage={setActivePage}
          getFeatures={getFeatures}
        />
      )}
    </Modal>
  );
};

export default styled(ModalAppDetails)`
  & {
    top: 50px !important;
  }

  .ant-modal-body {
    padding: 0;
  }
`;
