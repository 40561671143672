import React from 'react';
import { Input, Space } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  disabled: boolean;
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<Props> = ({ className, disabled, setFilterSearch }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <header className={className}>
      <div className="left">
        <h2>{t('LOGS.TITLE')}</h2>
      </div>
      <div className="right">
        <div className="actions">
          <Space>
            <Input
              disabled={disabled}
              allowClear
              placeholder={t('GLOBAL.SEARCH')}
              suffix={<i className="icon-search"></i>}
              width={190}
              onChange={(e) => {
                setFilterSearch(e.target.value);
              }}
            />
          </Space>
        </div>
      </div>
    </header>
  );
};

export default Header;
