import { Space } from 'antd';
import { CheckSquareFilled, CloseSquareFilled, LoadingOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import styled from 'styled-components';
import Colors from '@/styles/colors';
import { AxiosResponse } from 'axios';
import { IShift } from '@/types/shift.model';

interface Props {
  className?: string;
  shift: IShift;
  handleDelete: () => void;
  handleValidate: (shiftId: string) => Promise<void | AxiosResponse<any>>;
}

const TableRowActions: React.FC<Props> = ({ className, shift, handleDelete, handleValidate }) => {
  const [actionLoading, setActionLoading] = useState<boolean>(false);

  const onApprove = () => {
    setActionLoading(true);
    const promise = handleValidate(shift.id!);
    promise.catch((error) => {
      console.error(error);
      setActionLoading(false);
    });
  };

  return (
    <Space size="middle" className={className}>
      {actionLoading ? (
        <LoadingOutlined className="loading" />
      ) : (
        <React.Fragment>
          <CheckSquareFilled className="green" onClick={onApprove} />
          <CloseSquareFilled className="red" onClick={handleDelete} />
        </React.Fragment>
      )}
    </Space>
  );
};

const TableRowActionsStyled = styled(TableRowActions)`
  .green {
    font-size: 34px;
    cursor: pointer;
    color: ${Colors.green};
  }
  .red {
    font-size: 34px;
    color: ${Colors.redDark};
    cursor: pointer;
  }
  .loading {
    font-size: 34px;
    color: ${Colors.greenLight};
    text-align: center;
    padding: 0 8px;
  }
`;
export default TableRowActionsStyled;
