import AppContext from '@/pages/app/context';
import Colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { getWindowSize } from '@/utils';
import { Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import AuthContext from '@/context';
import colors from '@/styles/colors';
import { ISettingsRoutes } from '@/types/settings/routes.model';

interface Props {
  className?: string;
  path: string;
  routes: ISettingsRoutes;
}

const SettingsMenu: React.FC<Props> = ({ className, path, routes }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation(undefined, { useSuspense: false });

  const [openKeys, setOpenKeys] = useState<string[]>(['information']);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (Object.keys(routes).indexOf(latestOpenKey || '') === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    if (windowSize.innerWidth > 900) {
      let found: string[] = ['information'];
      Object.values(routes)
        .map((element) => element.key)
        .forEach((item: any) => {
          if (pathname.includes(item)) {
            found = item;
          }
        });
      setOpenKeys([Object.keys(routes).find((key: any) => key == found) as string]);
    }
  }, [pathname]);

  const onClick = () => {
    if (windowSize.innerWidth <= 900) {
      setOpenKeys([]);
    }
  };

  return (
    <Menu
      className={`${className} scrollbar-hidden`}
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      onClick={onClick}
    >
      {Object.values(routes).map(({ key, icon, title, children, element, visible, hidden }, index) => {
        const tab = Object.keys(routes)[index];
        const next = Object.values(routes)[index + 1];
        const selected = pathname.includes(key);

        if (hidden) return;

        if (element || !visible) {
          return (
            <React.Fragment key={key}>
              <Menu.Item
                key={`${path}/${tab}`}
                className={`single ${selected ? 'ant-menu-item-selected' : ''} ${!visible ? 'locked' : ''}`}
                style={{ fontSize: 14 }}
              >
                <i className={icon} style={{ marginRight: 5 }} />
                {title} <Link to={`${path}/${tab}${!visible ? '-locked' : ''}`} />
              </Menu.Item>
              {next && <Menu.Divider style={{ margin: '0 18px' }} />}
            </React.Fragment>
          );
        }

        if (!children) return;

        return (
          <React.Fragment key={key}>
            <Menu.SubMenu
              key={key}
              title={
                <>
                  <i className={icon} /> {title}
                </>
              }
              style={{ display: visible ? 'block' : 'none' }}
            >
              {children.map(({ route, title, visible }) => (
                <Menu.Item
                  className={`${path}/${tab}/${route}` == pathname ? 'ant-menu-item-selected' : ''}
                  key={`${path}/${tab}/${route}`}
                  hidden={!visible}
                >
                  {title} <Link to={`${path}/${tab}/${route}`} />
                </Menu.Item>
              ))}
            </Menu.SubMenu>
            {next && next.visible && <Menu.Divider style={{ margin: '0 18px' }} />}
          </React.Fragment>
        );
      })}
    </Menu>
  );
};

const SettingsMenuStyled = styled(SettingsMenu)`
  background: white;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  width: 100%;
  padding: 15px 0px;

  .ant-menu-title-content {
    color: black;
  }

  .ant-menu-item {
    background: #f8f8f8 !important;
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      background-color: ${colors.green};
    }
    .ant-menu-submenu-arrow:after {
      background-color: #fff !important;
    }
    .ant-menu-submenu-arrow:before {
      background-color: #fff !important;
    }
    .ant-menu-title-content:first-child {
      color: #fff;
    }
    .ant-menu-sub {
      .ant-menu-title-content {
        color: #000;
      }
    }
  }

  .ant-menu-item-selected {
    > span {
      color: ${Colors.green};
    }
    background: #efefef !important;
    &:after {
      border-right: 3px solid ${Colors.green};
    }
  }

  .ant-menu-inline {
    border-right: 1px solid #f2f2f2;
  }

  .single {
    background-color: #fff !important;
    margin-bottom: 4px !important;
    border-right: 1px solid #f2f2f2;

    &.locked {
      .ant-menu-title-content {
        color: #c7c7c7;
      }
    }

    .ant-menu-title-content {
      margin-left: -7px;
      text-transform: uppercase;
    }

    &.ant-menu-item-selected {
      background-color: #efefef !important;
      color: #000;
    }
  }
`;

export default SettingsMenuStyled;
