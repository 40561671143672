import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import { ICost } from '@/types/cost.model';
import { getWindowSize } from '@/utils';
import { Button, Drawer, Form, Input, message, Select, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomColorPicker from '../CustomColorPicker';

interface Props {
  className?: string;
  cost: ICost | null;
  visible: boolean;
  setActiveCost: React.Dispatch<React.SetStateAction<ICost | null>>;
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DrawerCost: React.FC<Props> = ({ className, cost, visible, setActiveCost, setDrawerVisible }) => {
  const {
    state: { activeDepartment, costs, userCategories, loadingUserCategories },
    dispatch,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(cost);
  }, [cost]);

  const onClose = () => {
    setActiveCost(null);
    setDrawerVisible(false);
  };

  const onFinish = (values: any) => {
    setLoading(true);

    if (cost) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/costs/${cost.id}`,
          {
            ...values,
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }: { data: ICost }) => {
          dispatch({
            type: 'SET_COSTS',
            payload: costs.map((cost) => (cost.id !== data.id ? cost : data)),
          });
          message.success(t('COSTS.MESSAGES.EDIT_SUCCESS', { name: data.name }));
          setActiveCost(null);
          setDrawerVisible(false);
          form.resetFields();
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/costs`,
          {
            ...values,
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }: { data: ICost }) => {
          dispatch({
            type: 'SET_COSTS',
            payload: [...costs, data],
          });
          message.success(t('COSTS.MESSAGES.CREATE_SUCCESS', { name: data.name }));
          setActiveCost(null);
          setDrawerVisible(false);
          form.resetFields();
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <Drawer
      className={className}
      forceRender={true}
      maskClosable
      title={cost ? t('COSTS.EDIT_COST', { name: cost.name }) : t('COSTS.ADD_COST')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      width={windowSize.innerWidth > 900 ? 400 : '75%'}
      onClose={onClose}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
          <Input placeholder={t('GLOBAL.NAME')} />
        </Form.Item>
        <Form.Item name="format" label={t('COSTS.FORMAT.TITLE')} rules={[{ required: true }]}>
          <Select placeholder={t('COSTS.FORMAT.TITLE')}>
            <Select.Option value="text">{t('COSTS.FORMAT.TEXT')}</Select.Option>
            <Select.Option value="number">{t('COSTS.FORMAT.NUMBER')}</Select.Option>
            <Select.Option value="boolean">{t('COSTS.FORMAT.BOOLEAN')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="statuses" label={t('COSTS.STATUSES.TITLE')}>
          <Select
            loading={loadingUserCategories}
            disabled={loadingUserCategories}
            getPopupContainer={(trigger) => trigger}
            placeholder={t('COSTS.STATUSES.TITLE')}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            suffixIcon={<i className="icon-search" />}
          >
            {userCategories.map((status) => (
              <Select.Option value={status.id!} key={`section_${status.id}`}>
                {status.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="one-line" name="mandatory" valuePropName="checked" label={t('GLOBAL.MANDATORY')}>
          <Switch />
        </Form.Item>
        <Form.Item
          label={t('GLOBAL.COLOR')}
          rules={[{ required: true, message: '' }]}
          shouldUpdate={(prevValues, curValues) => prevValues.color !== curValues.color}
        >
          {() => {
            const color = form.getFieldValue('color');
            return (
              <React.Fragment>
                <Form.Item name="color" style={{ display: 'none' }}>
                  <Input hidden />
                </Form.Item>
                <CustomColorPicker form={form} color={color} property="background" />
              </React.Fragment>
            );
          }}
        </Form.Item>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default styled(DrawerCost)`
  .ant-row.ant-form-item.one-line {
    .ant-form-item-label {
      flex: 1;
    }

    .ant-form-item-control {
      flex: 0;
    }

    .ant-form-item-label > label,
    .ant-form-item.one-line .ant-form-item-label > label {
      font-size: 14px !important;
    }
  }
`;
