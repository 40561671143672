import produce from 'immer';
import { initialState, InitialStateType } from './store';
import { ActionType } from './actions';

export default function reducer(state = initialState, action: ActionType): InitialStateType {
  switch (action.type) {
    case 'RESET': {
      return initialState;
    }
    case 'SET_TOTAL_EMPLOYEES': {
      return produce(state, (draftState) => {
        draftState.totalEmployees = action.payload;
      });
    }
    case 'SET_TOTAL_HEAD_COUNTS': {
      return produce(state, (draftState) => {
        draftState.totalHeadCounts = action.payload;
      });
    }
    case 'SET_TOTAL_BUDGETS': {
      return produce(state, (draftState) => {
        draftState.totalBudgets = action.payload;
      });
    }
    case 'SET_TOTAL_OPEN_SHIFTS': {
      return produce(state, (draftState) => {
        draftState.totalOpenShifts = action.payload;
      });
    }
    case 'SET_SHIFT_IS_DRAGGING': {
      return produce(state, (draftState) => {
        draftState.shiftIsDragging = action.payload;
      });
    }
    case 'SET_TASK_IS_DRAGGING': {
      return produce(state, (draftState) => {
        draftState.taskIsDragging = action.payload;
      });
    }
    case 'SET_TASK_IS_DRAG_OVER': {
      return produce(state, (draftState) => {
        draftState.taskIsDragOver = action.payload;
      });
    }
    default: {
      return state;
    }
  }
}
