export interface IWidget {
  id?: string;
  name?: string;
  position?: number;
  visible?: boolean;
  moving?: boolean;
}

export enum WIDGETS {
  SCHEDULE_TODAY = '1',
  SCHEDULE_TOMORROW = '2',
  OPENSHIFTS = '3',
  USER_TYPE_GRAPH = '5',
  SHIFTS_PERIOD = '6',
  TURNOVER = '7',
  DIMONA = '8',
  SHIFTS_CHECK = '9',
  SHIFTS_TO_APPROVE = '10',
  CLOCKINGS = '11',
  GEOLOC = '12',
  DAYOFF = '13',
  WEATHER = '14',
}
