import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Menu } from 'antd';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  setModalEditQuotaTemplateVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: () => void;
}

const EditQuotaTemplate: React.FC<Props> = ({ className, setModalEditQuotaTemplateVisible, onClick }) => {
  const { t } = useTranslation();

  const onEditQuota = () => {
    setModalEditQuotaTemplateVisible(true);
    onClick();
  };

  return (
    <Menu.Item className={className} onClick={onEditQuota}>
      <div className="container">
        <span>
          <i className="icon-download" /> {t('SCHEDULE.EDIT_QUOTA_TEMPLATE')}
        </span>
        <span className="description">{t('SCHEDULE.EDIT_QUOTA_TEMPLATE_DESCRIPTION').toLowerCase()}</span>
      </div>
    </Menu.Item>
  );
};

export default styled(EditQuotaTemplate)`
  margin: 0 5px;

  .container {
    display: flex;
    flex-direction: column;

    .description {
      font-size: 11px;
      color: ${colors.grey};
      margin-left: 21.5px;
      margin-top: -5px;
    }
  }

  &:hover {
    background-color: #efefef !important;
    border-radius: 8px;
  }
`;
