import ScrollToTop from '@/components/ScrollToTop';
import { IHrRule } from '@/types/hr-rule.model';
import { IUserStatus } from '@/types/user-status.model';
import { getWindowSize, isFeatureEnabled, isNullOrUndefined, REGIME_TYPES, WEEKDAYS } from '@/utils';
import { Button, Checkbox, Drawer, Form, Input, InputNumber, message, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimePickerFormItem from '../TimePickerFormItem';
import AppContext from '@/pages/app/context';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import styled from 'styled-components';
import { SelectValue } from 'antd/es/select';
import { FEATURES } from '@/types/features.model';

interface Props {
  className?: string;
  visible: boolean;
  activeRule: IHrRule | null;
  rules: IHrRule[];
  hrPartners: any[];
  setRules: React.Dispatch<React.SetStateAction<IHrRule[]>>;
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveRule: React.Dispatch<React.SetStateAction<IHrRule | null>>;
}

const OVERHOURS_TYPES = ['day'];

const DrawerHrRule: React.FC<Props> = ({
  className,
  visible,
  activeRule,
  rules,
  hrPartners,
  setRules,
  setDrawerVisible,
  setActiveRule,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, userCategories, loadingUserCategories, features },
  } = useContext(AppContext);
  const [form] = useForm();
  const [start, setStart] = useState<moment.Moment>(moment().startOf('day'));
  const [end, setEnd] = useState<number | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [directionSelected, setDirectionSelected] = useState(false);
  const [typeSelected, setTypeSelected] = useState(false);
  const [belowDay, setBelowDay] = useState(false);
  const [aboveDay, setAboveDay] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setAboveDay(false);
    setBelowDay(false);
    form.resetFields();
    setTypeSelected(false);
    setDirectionSelected(false);
    if (activeRule) {
      form.setFieldsValue(activeRule);
      if (activeRule.statuses.length == 0) {
        form.setFieldsValue({
          statuses: ['all'],
        });
      }
    } else {
      form.setFieldsValue({
        days: ['0', '1', '2', '3', '4', '5', '6'],
        add_default_code: false,
        override_default_code: false,
        subtract_default_code: aboveDay || belowDay ? false : true,
        holiday: false,
        overnight: false,
        stop: false,
      });
    }
  }, [activeRule, visible]);

  const onClose = () => {
    setDrawerVisible(false);
    setActiveRule(null);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    if (!activeRule) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/hr/rules`,
          {
            ...values,
            position: rules.length + 1,
            all_statuses: values.statuses ? values.statuses.includes('all') : false,
            statuses: values.statuses ? (values.statuses.includes('all') ? [] : values.statuses) : [],
            stop: values.stop || false,
            override_default_code: belowDay ? false : values.override_default_code,
            subtract_default_code: belowDay ? false : values.subtract_default_code,
            holiday: aboveDay || belowDay ? false : values.holiday,
            overnight: aboveDay || belowDay ? false : values.overnight,
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }) => {
          setDrawerVisible(false);
          setActiveRule(null);
          setRules([...rules, data]);
          setLoading(false);
          setAboveDay(false);
          setBelowDay(false);
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            message.error(Object.values(err.response.data.errors).flat(1)[0]);
          } else {
            message.error(t('HR.RULES.CREATE_ERROR'));
          }
          setLoading(false);
        });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/hr/rules/${activeRule.id}`,
          {
            ...values,
            position: rules.length,
            all_statuses: values.statuses ? values.statuses.includes('all') : false,
            statuses: values.statuses ? (values.statuses.includes('all') ? [] : values.statuses) : [],
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }) => {
          setDrawerVisible(false);
          setActiveRule(null);
          setRules(rules.map((rule) => (rule.id !== data.id ? rule : data)));
          setLoading(false);
          setAboveDay(false);
          setBelowDay(false);
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            message.error(Object.values(err.response.data.errors).flat(1)[0]);
          } else {
            message.error(t('HR.RULES.CREATE_ERROR'));
          }
          setLoading(false);
        });
    }
  };

  const onChange = (e: CheckboxChangeEvent, name: string) => {
    if (name == 'override_default_code') {
      form.setFieldsValue({
        add_default_code: false,
        subtract_default_code: false,
      });
    }

    if (name == 'subtract_default_code') {
      form.setFieldsValue({
        add_default_code: false,
        override_default_code: false,
      });
    }

    if (name == 'add_default_code') {
      form.setFieldsValue({
        subtract_default_code: false,
        override_default_code: false,
      });
    }
  };

  function findCodeById(id: string, array: any[]) {
    const found = array.find((obj) => obj.codes.find((code: any) => code.id === id));
    return found ? found.codes.find((code: any) => code.id === id) : null;
  }

  const onDirectionChanged = (value: SelectValue) => {
    if (value) {
      setDirectionSelected(true);
      const values = form.getFieldsValue();
      if (value == 'down' && values.overhours_type && values.overhours_type == 'day') {
        setAboveDay(false);
        setBelowDay(true);
        form.setFieldsValue({
          override_default_code: false,
          subtract_default_code: false,
          holiday: false,
          overnight: false,
        });
      }
      if (value == 'up' && values.overhours_type && values.overhours_type == 'day') {
        setBelowDay(false);
        setAboveDay(true);
        form.setFieldsValue({
          holiday: false,
          overnight: false,
        });
      }
    } else {
      setDirectionSelected(false);
    }
  };
  const onTypeChanged = (value: SelectValue) => {
    if (value) {
      setTypeSelected(true);
      const values = form.getFieldsValue();
      if (value == 'day' && values.overhours_type && values.overhours_direction == 'down') {
        setAboveDay(false);
        setBelowDay(true);
        form.setFieldsValue({
          override_default_code: false,
          subtract_default_code: false,
          holiday: false,
          overnight: false,
        });
      }
      if (value == 'day' && values.overhours_type && values.overhours_direction == 'up') {
        setBelowDay(false);
        setAboveDay(true);
        form.setFieldsValue({
          holiday: false,
          overnight: false,
        });
      }
    } else {
      setTypeSelected(false);
    }
  };

  return (
    <Drawer
      className={className}
      forceRender={true}
      maskClosable
      title={
        activeRule
          ? t('SETTINGS.ACCOUNT.HR_RULES.EDIT', {
              code: findCodeById(activeRule.hr_timesheet_code_id, hrPartners)
                ? findCodeById(activeRule.hr_timesheet_code_id, hrPartners).code
                : '',
            })
          : t('SETTINGS.ACCOUNT.HR_RULES.CREATE')
      }
      placement="right"
      visible={visible || activeRule !== null}
      destroyOnClose={true}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
      onClose={onClose}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="hr_timesheet_code_id" label={t('GLOBAL.CODE')} rules={[{ required: true }]}>
          <Select
            placeholder={t('GLOBAL.CODE')}
            allowClear
            showSearch
            filterOption={(input, option) => {
              if (option && (option.value || option?.children)) {
                return (
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }
              return false;
            }}
          >
            {hrPartners.map((partner) => (
              <Select.OptGroup label={partner.name}>
                {partner.codes.map((code: any) => (
                  <Select.Option key={code.id} value={code.id}>
                    {`${code.code} - ${code.name}`}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="type" label={t('HR.RULES.TIMESHEET_TYPE')} rules={[{ required: true }]}>
          <Select placeholder={t('HR.RULES.TIMESHEET_TYPE')} allowClear>
            <Select.Option value="all">{t('HR.RULES.ALL_TIMESHEET_TYPES')}</Select.Option>
            {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
              <Select.Option value="planned">{t(`HR.RULES.TIMESHEET_TYPES.PLANNED`)}</Select.Option>
            )}
            {isFeatureEnabled(features, FEATURES.CLOCKING) && (
              <Select.Option value="clocking">{t(`HR.RULES.TIMESHEET_TYPES.CLOCKING`)}</Select.Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item name="statuses" label={t('GLOBAL.USER_CATEGORIES')} rules={[{ required: true }]}>
          <Select
            mode="multiple"
            placeholder={t('FORMS.USER_CATEGORIES_PLACEHOLDER')}
            allowClear
            getPopupContainer={(trigger) => trigger.parentNode}
            loading={loadingUserCategories}
            disabled={loadingUserCategories}
          >
            <Select.Option value="all">{t('GLOBAL.ALL_STATUS')}</Select.Option>
            {userCategories?.map((userCategory) => {
              const { id, name } = userCategory;
              return (
                <Select.Option key={`status_${id}`} value={`${id!}`}>
                  {name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t('GLOBAL.HOURS')}>
          <div style={{ display: 'flex', columnGap: 25 }}>
            <TimePickerFormItem
              name="start_time"
              label={t('GLOBAL.START')}
              start={start.unix()}
              style={{ flex: 1, marginBottom: 0 }}
              placeholder="00:00"
              rules={[{ required: true }]}
              show24
            />
            <TimePickerFormItem
              name="end_time"
              label={t('GLOBAL.END')}
              style={{ flex: 1, marginBottom: 0 }}
              start={end || start?.unix()}
              placeholder="23:59"
              rules={[{ required: true }]}
              show24
            />
          </div>
        </Form.Item>
        <Form.Item name="days" label={t('GLOBAL.DAY(S)')} rules={[{ required: true }]}>
          <Select mode="multiple" placeholder={t('GLOBAL.DAY(S)')}>
            {WEEKDAYS.map((weekday, index) => (
              <Select.Option key={`${index}`} value={`${index}`}>
                {t(`GLOBAL.${weekday.toUpperCase()}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="regime" label={t('GLOBAL.REGIME')}>
          <Select placeholder={t('GLOBAL.REGIME')} allowClear>
            {REGIME_TYPES.map((type) => (
              <Select.Option value={type}>{t(`CONTRACTS.REGIME_TYPES.${type}`)}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="bold-label" label={t('HR.RULES.APPLY.LABEL')}>
          <Form.Item name="overhours_direction" rules={[{ required: typeSelected }]}>
            <Select
              placeholder={`${t('GLOBAL.UP')} / ${t('GLOBAL.DOWN')}`}
              allowClear
              onChange={(value) => onDirectionChanged(value)}
            >
              <Select.Option value="up">{t('GLOBAL.UP')}</Select.Option>
              <Select.Option value="down">{t('GLOBAL.DOWN')}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="overhours_type" rules={[{ required: directionSelected }]}>
            <Select
              placeholder={t('HR.RULES.OVERHOURS_TYPES.PLACEHOLDER')}
              allowClear
              onChange={(value) => onTypeChanged(value)}
            >
              {OVERHOURS_TYPES.map((type) => (
                <Select.Option value={type}>{t(`HR.RULES.OVERHOURS_TYPES.${type.toUpperCase()}`)}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <div>
            <h4 className="bold">{t('HR.RULES.RULE_DISPLAY')}</h4>
            <Form.Item name="add_default_code" valuePropName="checked" style={{ marginBottom: -10 }}>
              <Checkbox onChange={(e: CheckboxChangeEvent) => onChange(e, 'add_default_code')}>
                {t('HR.RULES.ADD_DEFAULT_LONG')}
              </Checkbox>
            </Form.Item>
            <Form.Item name="override_default_code" valuePropName="checked" style={{ marginBottom: -10 }}>
              <Checkbox onChange={(e: CheckboxChangeEvent) => onChange(e, 'override_default_code')} disabled={belowDay}>
                {t('HR.RULES.OVERRIDE_DEFAULT_LONG')}
              </Checkbox>
            </Form.Item>
            <Form.Item name="subtract_default_code" valuePropName="checked" style={{ marginBottom: -10 }}>
              <Checkbox onChange={(e: CheckboxChangeEvent) => onChange(e, 'subtract_default_code')} disabled={belowDay}>
                {t('HR.RULES.SUBTRACT_DEFAULT_LONG')}
              </Checkbox>
            </Form.Item>
          </div>
          <div>
            <h4 className="bold">{t('HR.RULES.RULE_PROPERTIES')}</h4>
            <Form.Item name="holiday" valuePropName="checked" style={{ marginBottom: -10 }}>
              <Checkbox disabled={belowDay || aboveDay}>{t('GLOBAL.HOLIDAY')}</Checkbox>
            </Form.Item>
            <Form.Item name="overnight" valuePropName="checked" style={{ marginBottom: -10 }}>
              <Checkbox disabled={belowDay || aboveDay}>{t('HR.RULES.OVERNIGHT_LONG')}</Checkbox>
            </Form.Item>
            <Form.Item name="stop" valuePropName="checked" style={{ marginBottom: -10 }}>
              <Checkbox style={{ minHeight: 10 }}>{t('HR.RULES.STOP_AFTER_RULE')}</Checkbox>
            </Form.Item>
          </div>
        </div>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default styled(DrawerHrRule)`
  .bold {
    font-weight: bold;
  }

  .bold-label {
    label {
      font-weight: bold;
    }
  }
`;
