import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import Colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled } from '@/utils';
import { Divider, Switch } from 'antd';
import React, { Dispatch, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../redux/actions';
import { InitialStateType } from '../redux/store';

interface Props {
  className?: string;
  department?: IDepartment;
}

const HeaderOptionDetails: React.FC<Props> = ({ className, department }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartment, features },
  } = useContext(AppContext);
  const { state: authState } = useContext(AuthContext);
  const {
    showSkills,
    showTasks,
    showSections,
    showAttributes,
    showDetails,
    showShifts,
    showHeadCount,
    showAbsences,
    showOtherDepartments,
    showProductivityIndex,
    showPrices,
    showComment,
    showHrCode,
    showClocking,
    showMonthlyHours,
    showMonthlyStart,
    picker,
  } = useSelector(
    ({
      showSkills,
      showTasks,
      showSections,
      showAttributes,
      showDetails,
      showShifts,
      showHeadCount,
      showAbsences,
      showOtherDepartments,
      showProductivityIndex,
      showPrices,
      showComment,
      showHrCode,
      showClocking,
      showMonthlyHours,
      showMonthlyStart,
      picker,
    }: InitialStateType) => ({
      showSkills,
      showTasks,
      showSections,
      showAttributes,
      showDetails,
      showShifts,
      showHeadCount,
      showAbsences,
      showOtherDepartments,
      showProductivityIndex,
      showPrices,
      showComment,
      showHrCode,
      showClocking,
      showMonthlyHours,
      showMonthlyStart,
      picker,
    }),
    shallowEqual,
  );
  const hoursDispatch: Dispatch<ActionType> = useDispatch();

  return (
    <div className={className}>
      <div className="row">
        <span>{t('GLOBAL.SHIFTS')}</span>
        <Switch
          size="small"
          checked={showShifts}
          onClick={() => {
            batch(() => {
              hoursDispatch({
                type: 'SET_SHOW_SHIFTS',
                payload: !showShifts,
              });
              hoursDispatch({
                type: 'UPDATE_FILTERED_SHIFTS',
                payload: { department: activeDepartment },
              });
            });
          }}
        />
      </div>
      <div className="row">
        <span>{t('GLOBAL.ABSENCES')}</span>
        <Switch
          size="small"
          checked={showAbsences}
          onClick={() => {
            batch(() => {
              hoursDispatch({
                type: 'SET_SHOW_ABSENCES',
                payload: !showAbsences,
              });
              hoursDispatch({
                type: 'UPDATE_FILTERED_SHIFTS',
                payload: { department: activeDepartment },
              });
            });
          }}
        />
      </div>
      {picker == 'month' && (
        <div className="row">
          <span>{t('GLOBAL.DISPLAY_START')}</span>
          <Switch
            size="small"
            checked={showMonthlyStart}
            onClick={() => {
              hoursDispatch({
                type: 'SET_SHOW_MONTHLY_START',
                payload: !showMonthlyStart,
              });
            }}
          />
        </div>
      )}
      {picker == 'month' && (
        <div className="row">
          <span>{t('GLOBAL.DISPLAY_HOURS')}</span>
          <Switch
            size="small"
            checked={showMonthlyHours}
            onClick={() => {
              hoursDispatch({
                type: 'SET_SHOW_MONTHLY_HOURS',
                payload: !showMonthlyHours,
              });
            }}
          />
        </div>
      )}
      {picker !== 'month' && (
        <>
          {isFeatureEnabled(features, FEATURES.CLOCKING) && authState.userDetails?.params?.access?.clockings && (
            <div className="row">
              <span>{t('GLOBAL.CLOCKING')}</span>
              <Switch
                size="small"
                checked={showClocking}
                onClick={() => {
                  batch(() => {
                    hoursDispatch({
                      type: 'SET_SHOW_CLOCKING',
                      payload: !showClocking,
                    });
                  });
                }}
              />
            </div>
          )}
          <Divider style={{ margin: '10px 0' }} />
          {isFeatureEnabled(features, FEATURES.SECTIONS) && (
            <div className="row">
              <span>{t('GLOBAL.SECTIONS')}</span>
              <Switch
                size="small"
                checked={showSections}
                onClick={() => {
                  hoursDispatch({
                    type: 'SET_SHOW_SECTIONS',
                    payload: !showSections,
                  });
                }}
              />
            </div>
          )}
          {isFeatureEnabled(features, FEATURES.TASKS) && (
            <div className="row">
              <span>{t('GLOBAL.SKILLS')}</span>
              <Switch
                size="small"
                checked={showSkills}
                onClick={() => {
                  hoursDispatch({
                    type: 'SET_SHOW_SKILLS',
                    payload: !showSkills,
                  });
                }}
              />
            </div>
          )}
          {isFeatureEnabled(features, FEATURES.TASKS) && (
            <div className="row">
              <span>{t('GLOBAL.TASKS')}</span>
              <Switch
                size="small"
                checked={showTasks}
                onClick={() => {
                  hoursDispatch({
                    type: 'SET_SHOW_TASKS',
                    payload: !showTasks,
                  });
                }}
              />
            </div>
          )}
          {isFeatureEnabled(features, FEATURES.ATTRIBUTES) && (
            <div className="row">
              <span>{t('GLOBAL.ATTRIBUTES')}</span>
              <Switch
                size="small"
                checked={showAttributes}
                onClick={() => {
                  hoursDispatch({
                    type: 'SET_SHOW_ATTRIBUTES',
                    payload: !showAttributes,
                  });
                }}
              />
            </div>
          )}
          <div className="row">
            <span>{t('GLOBAL.DETAILS')}</span>
            <Switch
              size="small"
              checked={showDetails}
              onClick={() => {
                hoursDispatch({
                  type: 'SET_SHOW_DETAILS',
                  payload: !showDetails,
                });
              }}
            />
          </div>
          {showDetails && (
            <div className="row">
              <span>{t('SCHEDULE.SHOW_PRICES')}</span>
              <Switch
                size="small"
                checked={showPrices}
                onClick={() => {
                  hoursDispatch({
                    type: 'SET_SHOW_PRICES',
                    payload: !showPrices,
                  });
                }}
              />
            </div>
          )}
          {isFeatureEnabled(features, FEATURES.PRODUCTIVITY) && (
            <div className="row">
              <span>{t('GLOBAL.SHOW_PRODUCTIVITY_INDEX')}</span>
              <Switch
                size="small"
                checked={showProductivityIndex}
                onClick={() => {
                  hoursDispatch({
                    type: 'SET_SHOW_PRODUCTIVITY_INDEX',
                    payload: !showProductivityIndex,
                  });
                }}
              />
            </div>
          )}
          {picker !== 'day' && (
            <div className="row">
              <span>{t('GLOBAL.SHOW_HEAD_COUNT')}</span>
              <Switch
                size="small"
                checked={showHeadCount}
                onClick={() => {
                  hoursDispatch({
                    type: 'SET_SHOW_HEAD_COUNT',
                    payload: !showHeadCount,
                  });
                }}
              />
            </div>
          )}
          {picker !== 'day' && activeDepartment?.scheduleParams?.showComment && (
            <div className="row">
              <span>{t('GLOBAL.COMMENT')}</span>
              <Switch
                size="small"
                checked={showComment}
                onClick={() => {
                  hoursDispatch({
                    type: 'SET_SHOW_COMMENT',
                    payload: !showComment,
                  });
                }}
              />
            </div>
          )}
          {activeDepartment?.accountType !== 'FREE-TRIAL' && (
            <div className="row">
              <span>{t('GLOBAL.HR_CODE')}</span>
              <Switch
                size="small"
                checked={showHrCode}
                onClick={() => {
                  hoursDispatch({
                    type: 'SET_SHOW_HR_CODE',
                    payload: !showHrCode,
                  });
                }}
              />
            </div>
          )}
          {activeDepartment?.accountType !== 'FREE-TRIAL' && (
            <>
              <Divider style={{ margin: '10px 0' }} />
              <div className="row">
                <span>{t('GLOBAL.OTHER_DEPARTMENTS')}</span>
                <Switch
                  size="small"
                  checked={showOtherDepartments}
                  onClick={() => {
                    hoursDispatch({
                      type: 'SET_SHOW_OTHER_DEPARTMENTS',
                      payload: !showOtherDepartments,
                    });
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const HeaderOptionDetailsStyled = styled(HeaderOptionDetails)`
  width: 230px;
  color: ${Colors.grey};

  .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:nth-of-type(n + 2) {
      padding-top: 5px;
    }
  }
`;

export default HeaderOptionDetailsStyled;
