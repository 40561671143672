import { IHrSheetData, IHrSheetExport } from '@/types/hr-sheet-export.model';
import { Button, List, Modal, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import { handleError } from '@/utils';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  record: IHrSheetExport;
  data: IHrSheetData | null;
}

const Actions: React.FC<Props> = ({ className, record, data }) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const onExport = (force: boolean = false) => {
    if (record.export) {
      setExportLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL?.slice(0, -4)}${record.export.url}`,
          {
            force: force ? force : undefined,
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          setExportLoading(false);
        })
        .catch((err) => {
          if (err.response?.data?.errors) {
            const error_keys = Object.keys(err.response.data.errors).flat(1) as string[];
            const error_values = Object.values(err.response.data.errors).flat(1) as string[];

            if (error_keys.includes('export')) {
              Modal.error({
                title: err.response.data.errors['export'][0],
                okText: t('GLOBAL.FORCE'),
                cancelText: t('GLOBAL.CANCEL'),
                onOk: () => onExport(true),
                closable: true,
                maskClosable: true,
              });
            } else {
              setErrors(error_values);
            }
          }
          setExportLoading(false);
        });
    }
  };

  const onErrorsOk = () => {
    setErrors([]);
  };

  const onErrorsCancel = () => {
    setErrors([]);
  };

  return (
    <div className={className}>
      <Modal
        forceRender={true}
        maskClosable={false}
        destroyOnClose={true}
        visible={errors.length > 0}
        className={'modal-danger'}
        title={t('CONTRACTS.CREATE_CONTRACT_ERROR')}
        onOk={onErrorsOk}
        onCancel={onErrorsCancel}
        footer={[
          <Button key="back" onClick={onErrorsCancel}>
            {t('GLOBAL.OK')}
          </Button>,
        ]}
      >
        <List
          dataSource={errors}
          renderItem={(item) => (
            <List.Item>
              <p>{item}</p>
            </List.Item>
          )}
        />
      </Modal>
      <Button
        type="primary"
        onClick={() =>
          history.push(`/app/reports/hr-sheets/all`, {
            pickerDate: moment(`${record.period}01`).unix(),
          })
        }
      >
        {t('REPORTS.EXPORT_HR_TIMESHEETS.SEE_HR_TIMESHEETS')}
      </Button>
      {record.export && (
        <>
          {data?.down ? (
            <Tooltip
              overlay={
                <span>{t('REPORTS.EXPORT_HR_TIMESHEETS.PARTNER_DOWN', { partner: record.export.partner })}</span>
              }
            >
              <Button
                loading={exportLoading}
                disabled={true}
                onClick={() => onExport()}
                style={{ backgroundColor: colors.red, borderColor: colors.red, color: '#FFF' }}
              >
                {t('REPORTS.EXPORT_HR_TIMESHEETS.PUSH_TO_PARTNER', { partner: record.export.partner })}
              </Button>
            </Tooltip>
          ) : (
            <Button
              loading={exportLoading}
              onClick={() => onExport()}
              style={{ backgroundColor: colors.red, borderColor: colors.red, color: '#FFF' }}
            >
              {t('REPORTS.EXPORT_HR_TIMESHEETS.PUSH_TO_PARTNER', { partner: record.export.partner })}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default styled(Actions)`
  display: flex;
  gap: 10px;
`;
