import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { IShift } from '@/types/shift.model';
import AppContext from '@/pages/app/context';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Skeleton, message, Table, Tooltip, DatePicker, Button } from 'antd';
import { IUser } from '@/types/user.model';
import axios from 'axios';
import 'moment-timezone';
import { isFeatureEnabled, minutesToHoursAndOrMinutes } from '@/utils';
import Tag from '@/pages/app/components/Tag';
import colors from '@/styles/colors';
import { ISkill } from '@/types/skill.model';
import { IAttribut } from '@/types/attribut.model';
import MonthSelector from '@/pages/app/components/MonthSelector';
import { Link } from 'react-router-dom';
import { FEATURES } from '@/types/features.model';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  user: IUser;
}

const Schedule: React.FC<Props> = ({ className, user }) => {
  const { state: appContextState } = useContext(AppContext);
  const { activeDepartmentId, activeSection, activeDepartment, features } = appContextState;
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });

  const [date, setDate] = useState<Moment>(moment().startOf('month'));
  const [columns, setColumns] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shifts, setShifts] = useState<IShift[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.WEEK'),
        key: 'week',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IShift) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 10 }}>{moment.unix(record.start!).week()}</span>
              {renderShiftType(record)}
            </div>
          );
        },
      },
      {
        title: t('GLOBAL.DATE'),
        key: 'start',
        render: (text: string, record: IShift) => {
          return moment.unix(record.start!).format('dddd LL');
        },
      },
      {
        title: t('GLOBAL.DATE'),
        key: 'hours',
        render: (text: string, record: IShift) => {
          return t('GLOBAL.FROM_HOUR_TO_HOUR_SHORT', {
            hour1: moment.unix(record.start!).format('HH:mm'),
            hour2: moment.unix(record.end!).format('HH:mm'),
          }).toLocaleLowerCase();
        },
      },
      {
        title: t('GLOBAL.BREAKS'),
        key: 'break',
        render: (text: string, record: IShift) => {
          const { pause } = record;
          const pauseDurationInMinutes = moment.duration(pause!.paid! + pause!.unpaid!, 'seconds').asMinutes();
          return minutesToHoursAndOrMinutes(pauseDurationInMinutes);
        },
      },
      ...(isFeatureEnabled(features, FEATURES.SECTIONS)
        ? [
            {
              title: t('GLOBAL.SECTION'),
              key: 'section',
              width: 180,
              // eslint-disable-next-line react/display-name
              render: (text: any, record: any) => {
                const { section } = record;
                if (section) {
                  const { color, background, name } = section;
                  return (
                    <Tag
                      size="large"
                      color={color}
                      background={background}
                      name={name}
                      style={{
                        maxWidth: '100%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    />
                  );
                } else {
                  return (
                    <Tag size="large" background={'#F9F9F9'} color={'#F9F9F9'} name={'-'} style={{ width: '100%' }} />
                  );
                }
              },
            },
          ]
        : []),
      ...(isFeatureEnabled(features, FEATURES.SKILLS)
        ? [
            {
              title: t('GLOBAL.SKILLS'),
              key: 'skills',
              width: 180,
              // eslint-disable-next-line react/display-name
              render: (text: any, record: any) => {
                const skills = record.skills;
                const skillsLength = skills?.length || 0;
                if (skillsLength) {
                  const { color, background, name } = skills[0];
                  if (skillsLength > 1) {
                    const otherSkills = skills
                      .slice(1)
                      .map((skill: ISkill) => skill.name)
                      .join(', ');
                    return (
                      <React.Fragment>
                        <Tag
                          size="large"
                          color={color}
                          background={background}
                          name={name}
                          style={{
                            maxWidth: '100%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        />
                        <Tooltip title={otherSkills}>
                          <span>
                            <Tag size="large" background={colors.blueExtraLight} name={`+ ${skillsLength - 1} ...`} />
                          </span>
                        </Tooltip>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <Tag
                        size="large"
                        color={color}
                        background={background}
                        name={name}
                        style={{
                          maxWidth: '100%',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      />
                    );
                  }
                } else {
                  return (
                    <Tag size="large" background={'#F9F9F9'} color={'#F9F9F9'} name={'-'} style={{ width: '100%' }} />
                  );
                }
              },
            },
          ]
        : []),
      {
        title: t('GLOBAL.ATTRIBUTES'),
        key: 'attributes',
        width: 180,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          const { attributes } = record;
          const attributesLength = attributes?.length || 0;
          if (attributesLength) {
            const { color, background, name } = attributes[0];
            if (attributesLength > 1) {
              const otherAttributes = attributes
                .slice(1)
                .map((attribute: IAttribut) => attribute.name)
                .join(', ');
              return (
                <React.Fragment>
                  <Tag
                    size="large"
                    color={color}
                    background={background}
                    name={name}
                    style={{
                      maxWidth: '100%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  />
                  <Tooltip title={otherAttributes}>
                    <span>
                      <Tag size="large" background={colors.blueExtraLight} name={`+ ${attributesLength - 1} ...`} />
                    </span>
                  </Tooltip>
                </React.Fragment>
              );
            } else {
              return (
                <Tag
                  size="large"
                  color={color}
                  background={background}
                  name={name}
                  style={{
                    maxWidth: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                />
              );
            }
          } else {
            return <Tag size="large" background={'#F9F9F9'} color={'#F9F9F9'} name={'-'} style={{ width: '100%' }} />;
          }
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'actions',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IShift) => {
          return (
            <Link
              to={`/app/hours/manage/${activeDepartment?.scheduleParams?.default_schedule_view == 'ops'
                  ? 'operational'
                  : activeDepartment?.scheduleParams?.default_schedule_view}/${moment
                .unix(record.start!)
                .format('YYYY-MM-DD')}`}
              className="green"
            >
              <Button type="primary">{t('GLOBAL.SEE')}</Button>
            </Link>
          );
        },
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    setShifts([]);
    setIsLoading(true);
    const start = moment(date).startOf('month').unix();
    const end = moment(date).endOf('month').unix();
    const cancelTokenSource = axios.CancelToken.source();

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/shifts`, {
        params: {
          departmentId: activeDepartmentId,
          sectionId: activeSection,
          userRecordId: user.recordId,
          startDate: start,
          endDate: end,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        setShifts(response?.data?.shifts || []);
        setIsLoading(false);
      })
      .catch((error) => {
        message.error(t('SHIFTS.MESSAGE_LOADING_ERROR'));
        setIsLoading(false);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId, activeSection, user, date]);

  const onMonthChange = (e: Moment | null) => {
    if (e) {
      setDate(e);
    }
  };

  const renderShiftType = (shift: IShift) => {
    if (shift.shyftType !== 3) {
      if (shift.dayoff) {
        return <Tag size="large" background={colors.redExtraLight} color={colors.blue} name={shift.dayoff.name} />;
      } else {
        return <Tag size="large" background={colors.blueExtraLight} color={colors.blue} name={t('GLOBAL.SHIFT')} />;
      }
    } else {
      return <Tag size="large" background={colors.greyLight} color={colors.blue} name={t('GLOBAL.UNAVAILABILITY')} />;
    }
  };

  return (
    <div className={className}>
      <header>
        <MonthSelector value={date} onChange={onMonthChange} />
      </header>
      <Table dataSource={shifts} columns={columns} rowKey="id" loading={isLoading} pagination={false} />
    </div>
  );
};

export default styled(Schedule)`
  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
`;
