import { IDepartment } from '@/types/department.model';
import { IUser } from '@/types/user.model';
import { isTrial } from '@/utils';
import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AttendanceSheets from './AttendanceSheets';
import HrSheet from './HrSheet';

const { TabPane } = Tabs;

interface Props {
  className?: string;
  user?: IUser;
  department?: IDepartment;
  activeSubTab: string;
  setActiveSubTab: React.Dispatch<React.SetStateAction<string>>;
}

const Reports: React.FC<Props> = ({ className, user, department, activeSubTab, setActiveSubTab }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();

  useEffect(() => {
    if (activeSubTab == '') {
      history.replace(`/app/team/collaborators/${user?.recordId}/reports/attendance-sheets`);
    }
  }, [activeSubTab]);

  const onTabChange = (tab: string) => {
    setActiveSubTab(tab);
    history.replace(`/app/team/collaborators/${user?.recordId}/reports/${tab.toLowerCase()}`);
  };

  return (
    <div className={className}>
      <Tabs type="card" onChange={onTabChange} activeKey={activeSubTab == '' ? 'ATTENDANCE-SHEETS' : activeSubTab}>
        <TabPane disabled={user?.pending} tab={t('REPORTS.SIGNUP_SHEETS.TITLE')} key="ATTENDANCE-SHEETS">
          <AttendanceSheets user={user} department={department} />
        </TabPane>
        {department?.accountType !== 'FREE-TRIAL' && !!department?.access?.timesheets && !isTrial(department!) && (
          <TabPane disabled={user?.pending} tab={t('REPORTS.HR_TIMESHEETS.TITLE')} key="TIMESHEETS">
            <HrSheet user={user} />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default styled(Reports)``;
