export enum RESOURCE_TYPES {
  FIRSTNAME = '101',
  LASTNAME = '100',
  PHONE = '103',
  EMAIL = '113',
  PASSWORD = '200',
}
export interface IField {
  id?: string;
  name?: string;
  value?: string;
  resourceType?: string;
  locked?: boolean;
  disabled?: boolean;
  mandatory?: boolean;
  label?: string;
  displayForm?: boolean;
  showApp?: boolean;
  custom_field_id?: number;
  display?: boolean;
  position?: number;
  sections?: string;
  order?: number;
}
