import colors from '@/styles/colors';
import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  chilren?: any;
  style?: React.CSSProperties;
}

const Callout: React.FC<Props> = ({ className, style, children }) => (
  <div className={className} style={style}>
    {children}
  </div>
);

const CalloutStyled = styled(Callout)`
  color: white;
  border-radius: 3px;
  padding: 7px 10px;
  background-color: ${colors.blueExtraLight};
  color: ${colors.grey};
`;

export default CalloutStyled;
