import { IConstraint } from '@/types/constraint.model';
import { IUser } from '@/types/user.model';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  message: IConstraint[];
  oneMessage?: boolean;
  recordIds?: any[];
  users?: IUser[];
}

const Constraints: React.FC<Props> = ({ message, oneMessage, recordIds, users }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  if (oneMessage) {
    return <span>{message}</span>;
  }

  return (
    <ul>
      {message.map((constraint, index) => {
        let recordId: string | null = null;
        if (recordIds) {
          recordId = recordIds[index];
        }
        if (recordId && constraint.title == 'WORKING' && users) {
          const data = users.find((user) => user.recordId == recordId);
          if (!data) return;
          return (
            <li key={`constraint_${index}`}>{t('SCHEDULE.CONSTRAINTS.WORKING_USER', { user: data.displayName })}</li>
          );
        } else {
          return (
            <li key={`constraint_${index}`}>{t(`SCHEDULE.CONSTRAINTS.${constraint.title}`, constraint.details)}</li>
          );
        }
      })}
    </ul>
  );
};

export default Constraints;
