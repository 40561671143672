import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Colors from '@/styles/colors';
import { useTranslation } from 'react-i18next';
import { ICost } from '@/types/cost.model';

interface Props {
  className?: string;
  cost: ICost;
  setActiveCost: React.Dispatch<React.SetStateAction<ICost | null>>;
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setCostToDelete: React.Dispatch<React.SetStateAction<ICost | null>>;
}

const TableRowActions: React.FC<Props> = ({ className, cost, setActiveCost, setDrawerVisible, setCostToDelete }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const onEdit = () => {
    setActiveCost(cost);
  };

  const onDelete = () => {
    setCostToDelete(cost);
  };

  return (
    <div className={className}>
      <Button type="link" className="green" onClick={onEdit}>
        {t('GLOBAL.EDIT')}
      </Button>
      <Button type="link" danger onClick={onDelete}>
        {t('GLOBAL.REMOVE')}
      </Button>
    </div>
  );
};

export default styled(TableRowActions)`
  a {
    color: ${Colors.green};
    &.impersonate {
      border: 1px solid ${Colors.green};
      border-radius: 3px;
      padding: 2px 4px;
      opacity: 0.6;
    }
  }
`;
