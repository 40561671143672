import colors from '@/styles/colors';
import { IUserWithInfos } from '@/types/user.model';
import { minutesToHours } from '@/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  displayName?: boolean;
  user: IUserWithInfos;
}

const UserSelectInfos: React.FC<Props> = ({ className, user, displayName }) => {
  const { t } = useTranslation();
  return (
    <div className={`${className} select-user-option`}>
      {displayName && <div className="displayName">{user.displayName}</div>}
      <div className="infos">
        {!!user?.constraints?.message?.length && (
          <span>{t(`SCHEDULE.CONSTRAINTS.${user?.constraints?.message[0]?.title}_SHORT`)}</span>
        )}
        {(!!user.totalWeekMinutes || !!user.maxWeekMinutes) && (
          <span
            style={{
              backgroundColor:
                user.maxWeekMinutes && user.totalWeekMinutes && user.totalWeekMinutes > user.maxWeekMinutes
                  ? colors.red
                  : colors.green,
            }}
          >
            {minutesToHours(user.totalWeekMinutes || 0)}
            {user.maxWeekMinutes ? `/${minutesToHours(user.maxWeekMinutes)}` : ''}h
          </span>
        )}
        {(!!user.totalTrimesterMinutes || !!user.maxTrimesterMinutes) && (
          <span
            style={{
              backgroundColor:
                user.maxTrimesterMinutes &&
                user.totalTrimesterMinutes &&
                user.totalTrimesterMinutes > user.maxTrimesterMinutes
                  ? colors.red
                  : colors.green,
            }}
          >
            {minutesToHours(user.totalTrimesterMinutes || 0)}
            {user.maxTrimesterMinutes ? `/${user.maxTrimesterMinutes}` : ''}m
          </span>
        )}
      </div>
    </div>
  );
};

export default styled(UserSelectInfos)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .infos {
    transition: opacity 0.3s;
    span {
      background-color: ${colors.bluePastel};
      color: white;
      border-radius: 3px;
      margin-left: 4px;
      padding: 1px 5px;
    }
  }
`;
