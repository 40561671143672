import { Button, message, Switch, Tooltip } from 'antd';
import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  selectedRowKeys: string[];
  handleValidate: (clockingIds: string[]) => Promise<AxiosResponse<any>>;
  onlyAnomalies: boolean;
  setOnlyAnomalies: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<Props> = ({ className, selectedRowKeys, handleValidate, onlyAnomalies, setOnlyAnomalies }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [isSaving, setIsSaving] = useState(false);

  const onValidate = () => {
    setIsSaving(true);
    handleValidate([...selectedRowKeys])
      .then(() => {
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
        message.error(t('GLOBAL.AN_ERROR_OCCURRED'));
        console.log(error);
      });
  };

  return (
    <header className={className}>
      <div className="left" style={{ display: 'flex', alignItems: 'center' }}>
        <h2 style={{ marginLeft: 0, marginRight: 15 }}>{t('SCHEDULE.CLOCKING_VERIFICATION.TITLE')}</h2>
      </div>
      <div className="right">
        <div className="switch-container">
          <p>{t('SCHEDULE.CLOCKING_VERIFICATION.SHOW_ONLY_ANOMALIES')}</p>
          <Tooltip title={t('SCHEDULE.CLOCKING_VERIFICATION.SHOW_ONLY_ANOMALIES')}>
            <Switch checked={onlyAnomalies} onChange={setOnlyAnomalies} />
          </Tooltip>
        </div>
        <Button onClick={onValidate} loading={isSaving} type="primary" disabled={selectedRowKeys?.length === 0}>
          {t('GLOBAL.VALIDATE')} ({selectedRowKeys?.length})
        </Button>
      </div>
    </header>
  );
};

export default styled(Header)`
  .right {
    display: flex;
    align-items: center;

    .switch-container {
      display: flex;
      align-items: center;
      margin-right: 15px;

      p {
        margin: 0;
        margin-right: 10px;
      }
    }
  }
`;
