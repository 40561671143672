import TableView from '@/layouts/TableView';
import { IUser } from '@/types/user.model';
import { BackTop, Table } from 'antd';
import React from 'react';
import { ExportMode } from '.';
import Header from './components/Header';

interface Props {
  departmentId?: string;
  loading: boolean;
  dataSource: IUser[];
  columns: any[];
  columnFilters: Map<string, { name: string; value: boolean; resourceType?: string }>;
  filterSearch?: string;
  filterStatus?: string;
  filterRole?: string;
  userIds: string[];
  onRow?: (user: IUser) => { onClick: () => void };
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
  setFilterStatus: React.Dispatch<React.SetStateAction<string | null>>;
  setFilterRole: React.Dispatch<React.SetStateAction<string | null>>;
  setExportMode: React.Dispatch<React.SetStateAction<ExportMode>>;
  setColumnFilters: React.Dispatch<React.SetStateAction<Map<string, { name: string; value: boolean }>>>;
}

const CollaboratorsList: React.FC<Props> = ({
  departmentId,
  loading,
  dataSource,
  columns,
  columnFilters,
  filterSearch,
  filterStatus,
  filterRole,
  userIds,
  onRow,
  setFilterSearch,
  setFilterStatus,
  setFilterRole,
  setExportMode,
  setColumnFilters,
}) => {
  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header
        filterSearch={filterSearch}
        setFilterSearch={setFilterSearch}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        filterRole={filterRole}
        setFilterRole={setFilterRole}
        setExportMode={setExportMode}
        disabled={loading}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        activeDepartmentId={departmentId}
        userIds={userIds}
        dataSource={dataSource}
      />
      <Table
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        rowKey="recordId"
        pagination={{ position: ['bottomCenter', 'bottomCenter'] }}
        onRow={onRow}
        rowClassName="row-clickable"
      />
    </TableView>
  );
};

export default CollaboratorsList;
