import colors from '@/styles/colors';
import { getWindowSize } from '@/utils';
import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  data: HorecaExpoData | null;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface HorecaExpoData {
  currentCost: string;
  hoursMonth: number | null;
  meetingLink: string;
  shyfterCost: string;
  users: number | null;
  timeSaved: number | null;
}

const ResultsModal: React.FC<Props> = ({ className, data, visible, setVisible }) => {
  const { t } = useTranslation();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const { currentCost, shyfterCost } = data || {};
  const current_cost_number = currentCost && Number(currentCost.replace('€', ''));
  const shyfter_cost_number = shyfterCost && Number(shyfterCost.replace('€', ''));

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const closeModal = () => {
    setVisible(false);
  };

  return (
    <Modal
      className={className}
      width={windowSize.innerWidth}
      visible={visible}
      onCancel={closeModal}
      onOk={closeModal}
      style={{ top: 0, maxWidth: windowSize.innerWidth }}
      footer={null}
      maskClosable
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          marginTop: -50,
        }}
      >
        <video style={{ width: '75vw' }} autoPlay loop src={process.env.PUBLIC_URL + '/confetti.mp4'} />

        <div className="results">
          <div className="row">
            <div className="result">
              <span className="value">{data?.users || 0}</span>
              <span style={{ fontSize: 20 }}>{t('GLOBAL.USERS')}</span>
            </div>

            <div className="result">
              <span className="value">{data?.hoursMonth || 0}</span>
              <span style={{ fontSize: 20 }}>{t('HORECA_EXPO.HOURS_MONTH')}</span>
            </div>

            <div className="result">
              <span className="value">{data?.currentCost || 0}</span>
              <span style={{ fontSize: 20 }}>{t('HORECA_EXPO.CURRENT_COST')}</span>
            </div>
          </div>

          <div className="row">
            <div className="result shyfter">
              <span className="value shyfter_value">{data?.shyfterCost || 0}</span>
              <span style={{ fontSize: 20 }}>{t('HORECA_EXPO.SHYFTER_COST')}</span>
            </div>

            <div className="result shyfter">
              <span className="value shyfter_value">{data?.timeSaved || '20%'}</span>
              <span style={{ fontSize: 20 }}>{t('HORECA_EXPO.TIME_SAVED')}</span>
            </div>

            <div className="result shyfter">
              <span className="value shyfter_value">
                {current_cost_number && shyfter_cost_number ? current_cost_number - shyfter_cost_number : '0'}€
              </span>
              <span style={{ fontSize: 20 }}>{t('HORECA_EXPO.SAVINGS')}</span>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 25 }}>
          <Button
            type="primary"
            block
            style={{ backgroundColor: colors.blueLightPastel, borderColor: colors.blueLightPastel }}
            size="large"
            htmlType="button"
            onClick={() => window.open(data?.meetingLink)}
          >
            {t('HORECA_EXPO.APPOINTMENT')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default styled(ResultsModal)`
  height: calc(100vh + 25px);

  .ant-modal-body,
  .ant-modal-content {
    height: calc(100vh + 25px);
  }

  .results {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;

    .row {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;

      .result {
        background: ${colors.greyLight};
        padding: 25px;
        border-radius: 10px;
        border: 1px solid ${colors.grey};
        align-items: center;
        flex-direction: column;
        display: flex;
        min-width: 200px;
        margin: 0 10px;

        .value {
          font-size: 40px;
          font-weight: 'bold';
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #000;
        }

        .shyfter_value {
          -webkit-text-stroke-color: #fff;
        }
      }

      .shyfter {
        background: ${colors.green};
        color: #fff;
      }
    }
  }
`;
