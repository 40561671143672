import Error404 from '@/components/Error404';
import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { IField } from '@/types/field.model';
import { IUser } from '@/types/user.model';
import { isFeatureEnabled, PROFILE_TABS } from '@/utils';
import { message } from 'antd';
import { default as axios, default as Axios } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import CollaboratorOverview from './overview/index';
import CollaboratorSchedule from './schedule/index';

const AppTeamCollaborator: React.FC = () => {
  const {
    state: { activeDepartment, features },
  } = useContext(AppContext);
  const { path, url } = useRouteMatch();
  const [user, setUser] = useState<IUser>();
  const [fields, setFields] = useState<IField[]>();
  const { id } = useParams() as any;
  const { t } = useTranslation(undefined, { useSuspense: true });

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${id}`, {
        params: {
          departmentId: activeDepartment?.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        if (mounted) {
          setUser(response.data.user);
          setFields(response.data.fields);
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.error(error);
          if (mounted) {
            message.error(t('USERS.USER.MESSAGE_LOADING_ERROR'));
          }
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [id, activeDepartment]);

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => (
          <Redirect to={!isFeatureEnabled(features, FEATURES.SCHEDULE) ? `${url}/profile` : `${url}/dashboard`} />
        )}
      />
      <Route path={PROFILE_TABS.map((tab) => `${path}${tab}`)}>
        <CollaboratorOverview user={user} department={activeDepartment} fields={fields} />
      </Route>
      <Route path={`${path}/schedule`}>
        <CollaboratorSchedule user={user} />
      </Route>
      <Route component={() => <Error404 />} />
    </Switch>
  );
};

export default AppTeamCollaborator;
