import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';
import * as legoData from '../../../../../legoloading.json';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  visible: boolean;
}

const ModalGeneratingSchedule: React.FC<Props> = ({ visible, className }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <Modal
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={visible}
      title={null}
      footer={null}
      closable={false}
      centered
    >
      <div className={className}>
        <FadeIn>
          <Lottie options={defaultOptions} height={120} width={120} />
          <p>{t('SCHEDULE_MODELS.GENERATION_LOADING')}</p>
        </FadeIn>
      </div>
    </Modal>
  );
};

export default styled(ModalGeneratingSchedule)`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  p {
    font-size: 1.4em;
    color: ${colors.grey};
    white-space: pre-line;
  }
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: (legoData as any).default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
