import { Button, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { IDocumentTemplate } from '@/types/document.model';
import { useTranslation } from 'react-i18next';
import colors from '@/styles/colors';
interface Props {
  className?: string;
  template: IDocumentTemplate;
  onEdit: (templateId: string) => void;
  onWantToDeleteTemplate: (templateId: string) => void;
}

const TableRowActions: React.FC<Props> = ({ className, template, onEdit, onWantToDeleteTemplate }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <div className={className}>
      <Tooltip title={t('GLOBAL.MODIFY')}>
        <Button shape="circle" className="action" onClick={() => onEdit(template.id)}>
          <i className="icon-edit" />
        </Button>
      </Tooltip>
      <Tooltip title={t('GLOBAL.REMOVE')}>
        <Button
          shape="circle"
          className="action"
          onClick={() => {
            onWantToDeleteTemplate(template.id);
          }}
        >
          <i className="icon-trash-empty" style={{ color: colors.red }} />
        </Button>
      </Tooltip>
    </div>
  );
};

export default styled(TableRowActions)``;
