import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import axios from 'axios';
import { handleError } from '@/utils';
import { IQuotaTemplate } from '@/types/insights/quota-template.model';
import { Button, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Tab from '../components/Tab';
import TemplatesTable from './QuotaTemplatesTable';
import QuotaTemplateBreadcrumb from './QuotaTemplateBreadcrumb';
import QuotaTemplate from './QuotaTemplate';

interface Props {
  className?: string;
}

const QuotaTemplates: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { quotaTemplates },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();
  const { templateId } = useParams() as any;

  const activeTemplate =
    templateId == 'new' ? null : quotaTemplates.find((template) => template.id == templateId) || null;

  const onCreateNewTemplate = () => {
    history.push(`/app/settings/insights/quota-templates/new`);
  };

  const Table = () => {
    return (
      <div className="container">
        <Button className="add-btn" type="primary" onClick={onCreateNewTemplate}>
          {t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.CREATE_TEMPLATE')}
        </Button>
        <TemplatesTable />
      </div>
    );
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.INSIGHTS.QUOTA_TEMPLATES.TITLE')}</h2>
      {templateId ? <QuotaTemplate activeTemplate={activeTemplate} /> : <Table />}
    </div>
  );
};

export default styled(QuotaTemplates)`
  .container {
    background-color: #fff;
    padding: 25px;
    border: 1px solid #f0f0f0;
    border-top: 0px;

    .add-btn {
      margin-bottom: 15px;
    }
  }
`;
