import AppContext from '@/pages/app/context';
import { PickerType } from '@/pages/app/hours/manage/redux/store';
import Colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { IShift } from '@/types/shift.model';
import { IUser } from '@/types/user.model';
import { getContrast, isFeatureEnabled, isNullOrUndefined } from '@/utils';
import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import ViewportList from 'react-viewport-list';
import styled from 'styled-components';
import MonthlyUserShiftRow from '../components/MonthlyUserShiftRow';
import NoSectionSelected from '../components/NoSectionSelected';
import UserShiftLoadingRow from '../components/UserShiftLoadingRow';
import { ActionType, copyShifts, updateShiftsAndContextWithShiftResponse } from '../redux/actions';
import { InitialStateType } from '../redux/store';
import Shifts from './components/Shifts';
import { Button, Modal } from 'antd';
import { FEATURES } from '@/types/features.model';

interface Props {
  className?: string;
  key?: string;
  refTotalHours: React.MutableRefObject<null> | null;
  refPageContainer: React.MutableRefObject<null> | null;
  activeSection: string | undefined;
  department: IDepartment | null;
}

export type DailyUserShiftsType = {
  user: IUser;
  days: {
    date: number;
    shifts: IShift[];
  }[];
  totalMinutes: number;
  totalPrice: number;
};

export interface ITotalRole {
  id: string | null;
  name: string;
  color: string | undefined;
  background: string | undefined;
  minutes: number;
  price: number;
}

const AppHoursManageDaily: React.FC<Props> = ({ className, key, refTotalHours, refPageContainer, department }) => {
  const {
    filteredShiftsByUsers,
    days,
    picker,
    selectedDate,
    showOpenShifts,
    isLoading,
    comparisonTemplate,
    monthly_selected_elements,
    selected_shifts_on_shift_key,
    monthly_selecting_elements,
    startDate,
    endDate,
    errors,
    copied_shift,
    shiftsByUsers,
    usersMap,
  } = useSelector(
    ({
      filteredShiftsByUsers,
      days,
      picker,
      selectedDate,
      showOpenShifts,
      isLoading,
      comparisonTemplate,
      selected_shifts_on_shift_key,
      monthly_selected_elements,
      monthly_selecting_elements,
      startDate,
      endDate,
      errors,
      copied_shift,
      shiftsByUsers,
      usersMap,
    }: InitialStateType) => ({
      filteredShiftsByUsers,
      days,
      picker,
      selectedDate,
      showOpenShifts,
      isLoading,
      comparisonTemplate,
      selected_shifts_on_shift_key,
      monthly_selected_elements,
      monthly_selecting_elements,
      startDate,
      endDate,
      errors,
      copied_shift,
      shiftsByUsers,
      usersMap,
    }),
    shallowEqual,
  );
  const ref = useRef(null);
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const routeMatch = useRouteMatch();
  const paths = routeMatch.path.split('/');
  const currentPicker = paths[paths.length - 2] as PickerType;
  const { formatedDate } = routeMatch.params as any;
  const {
    state: { activeDepartmentId, activeDepartment, activeSection, sections, skills, userCategories, features },
  } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (picker !== currentPicker || selectedDate.format('YYYY-MM-DD') !== formatedDate) {
      let startDate;
      let endDate;

      switch (currentPicker) {
        case 'week':
          startDate = moment(formatedDate).startOf('isoWeek');
          endDate = moment(formatedDate).endOf('isoWeek');
          break;
        case 'month':
          startDate = moment(formatedDate).startOf('month');
          endDate = moment(formatedDate).endOf('month');
          break;
        default:
          startDate = moment(formatedDate).startOf('isoWeek');
          endDate = moment(formatedDate).endOf('isoWeek');
      }

      hoursDispatch({
        type: 'SET_PICKER_AND_SELECTED_DATE',
        payload: {
          picker: currentPicker,
          selectedDate: moment(formatedDate).valueOf(),
          startDate: startDate.valueOf(),
          endDate: endDate.valueOf(),
        },
      });
    }
    // eslint-disable-next-line
  }, [currentPicker, formatedDate]);

  const [shifts, setShifts] = useState<DailyUserShiftsType[]>([]);
  const [openShifts, setOpenShifts] = useState<DailyUserShiftsType | null>(null);
  const [totalByRole, setTotalByRole] = useState<ITotalRole[][]>([]);
  const [selectedHeadcounts, setSelectedHeadcounts] = useState<number[]>([]);

  useEffect(() => {
    hoursDispatch({
      type: 'SET_MONTHLY_SELECTED_ELEMENTS',
      payload: [],
    });
    hoursDispatch({
      type: 'SET_MONTHLY_SELECTING_ELEMENTS',
      payload: false,
    });
    hoursDispatch({
      type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
      payload: null,
    });
  }, []);

  useEffect(() => {
    // CHECK IF THE DATE AND INTERVAL ARE CORRECTS
    if (picker !== currentPicker || selectedDate.format('YYYY-MM-DD') !== formatedDate) {
      return;
    }

    const totalByRole: ITotalRole[][] = [];
    const selectedHeadcounts: number[] = [];
    const dailyUsersShifts: DailyUserShiftsType[] = [];
    const dailyOpenShifts: DailyUserShiftsType = {
      user: {
        recordId: null,
      },
      days: [],
      totalMinutes: 0,
      totalPrice: 0,
    };

    for (let i = 0; i < days.length; i++) {
      const currentDay = moment.unix(days[i].date);
      const workingUser = new Set();

      dailyOpenShifts.days.push({
        date: currentDay.valueOf(),
        shifts: [],
      });

      const totalDayByRole: ITotalRole[] = [];

      // eslint-disable-next-line
      filteredShiftsByUsers.forEach((shiftsByUser) => {
        let totalUserMinute = 0;
        let totalUserPrice = 0;

        let totalUserDayMinute = 0;
        let totalUserDayPrice = 0;

        if (shiftsByUser.user.recordId) {
          // USERS SHIFTS
          let userDailyShifts = dailyUsersShifts.find((x) => x.user?.recordId === shiftsByUser.user.recordId);
          if (!userDailyShifts) {
            const length = dailyUsersShifts.push({
              user: shiftsByUser.user,
              days: [
                {
                  date: currentDay.valueOf(),
                  shifts: [],
                },
              ],
              totalMinutes: 0,
              totalPrice: 0,
            });
            userDailyShifts = dailyUsersShifts[length - 1];
          } else {
            userDailyShifts.days.push({
              date: currentDay.valueOf(),
              shifts: [],
            });
          }

          const shiftsOfDayOfUser: IShift[] = [];
          shiftsByUser.shifts.forEach((shift) => {
            const startShiftMoment = moment.unix(shift.start!);
            const endShiftMoment = moment.unix(shift.end!);

            if (startShiftMoment.isBetween(currentDay, moment(currentDay).add(1, 'day'), undefined, '[)')) {
              let hourlyRate = userDailyShifts?.user.hourlyRate;
              const duration = moment.duration(endShiftMoment.diff(startShiftMoment));
              duration.subtract(shift.pause!.unpaid, 'seconds');
              const durationAsMinutes = duration.asMinutes();

              if (isNullOrUndefined(hourlyRate) && shift.skills && shift.skills.length > 0) {
                const maxSkillPrice = Math.max(...shift.skills.map((skill) => skill.price || 0));
                hourlyRate = maxSkillPrice;
              }

              const shiftPrice = shift.price!;
              if (shift.shyftType !== 3) {
                // shiftPrice = (durationAsMinutes / 60) * (hourlyRate || 0);
                const shiftSectionId = shift?.section?.id;
                if (!activeSection || (activeSection && shiftSectionId === activeSection)) {
                  if (!shift.dayoff) {
                    totalUserDayMinute += durationAsMinutes;
                    workingUser.add(shiftsByUser.user.recordId);
                  }
                  totalUserMinute += durationAsMinutes;
                  if (!shift.dayoff || shift?.dayoff?.paid) {
                    totalUserPrice += shiftPrice;
                    totalUserDayPrice += shiftPrice;
                  }
                }
              }

              shiftsOfDayOfUser.push({
                ...shift,
                price: shiftPrice,
              });
            }
          });

          userDailyShifts.days[i].shifts = shiftsOfDayOfUser;
          userDailyShifts.totalMinutes += totalUserMinute;
          userDailyShifts.totalPrice += totalUserPrice;

          const role = userDailyShifts.user.userType || {
            id: null,
            name: 'Autres',
            color: '#ffffff',
            background: '#666666',
          };
          const totalDayRole = totalDayByRole.find((x) => x.id! === role.id!);

          if (totalDayRole) {
            totalDayRole.minutes += totalUserDayMinute;
            totalDayRole.price += totalUserDayPrice;
          } else {
            totalDayByRole.push({
              id: role.id!,
              name: role.name!,
              color: role.color!,
              background: role.background!,
              minutes: totalUserDayMinute,
              price: totalUserDayPrice,
            });
          }
        } else {
          // OPEN SHIFTS
          shiftsByUser.shifts.forEach((shift) => {
            const startShiftMoment = moment.unix(shift.start!);
            if (startShiftMoment.isBetween(currentDay, moment(currentDay).add(1, 'day'), undefined, '[)')) {
              dailyOpenShifts.days[i].shifts.push(shift);
              if (department?.scheduleParams?.count_free_shift_in_head_count) {
                workingUser.add('free-shifts');
              }
            }
          });
        }
      });

      totalByRole.push(totalDayByRole);
      selectedHeadcounts.push(workingUser.size);
    }
    setTotalByRole(totalByRole);
    setShifts(dailyUsersShifts);
    setOpenShifts(dailyOpenShifts);
    setSelectedHeadcounts(selectedHeadcounts);
    // eslint-disable-next-line
  }, [filteredShiftsByUsers]);

  const onMouseUp = (e: MouseEvent) => {
    hoursDispatch({
      type: 'SET_MONTHLY_SELECTING_ELEMENTS',
      payload: false,
    });
  };

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [selected_shifts_on_shift_key, monthly_selected_elements]);

  const onKeyDown = (e: globalThis.KeyboardEvent) => {
    if (selected_shifts_on_shift_key?.length == 1 && e.metaKey && e.key === 'c') {
      copyShifts(hoursDispatch, {
        ids: [selected_shifts_on_shift_key[0].id as string],
      });
      hoursDispatch({
        type: 'SET_COPIED_SHIFT',
        payload: selected_shifts_on_shift_key[0],
      });
    }
    if ((selected_shifts_on_shift_key?.length == 1 || copied_shift) && e.metaKey && e.key === 'v') {
      const shifts: any = [];
      const elements: string[] = [];
      monthly_selected_elements.forEach((element: Element, i) => {
        const shift = selected_shifts_on_shift_key?.length == 1 ? selected_shifts_on_shift_key[0] : copied_shift;
        const data = element.id.split('_');
        const userRecordId = data[0];
        const start = +data[1] / 1000;
        const startMoment = moment.unix(shift!.start!);
        const endMoment = moment.unix(shift!.end!);
        const duration = moment.duration(endMoment.diff(startMoment)).asSeconds();
        const real_start_date = moment
          .unix(start)
          .add(startMoment.hour(), 'hour')
          .add(startMoment.minutes(), 'minute')
          .unix();
        const real_end_date = moment.unix(real_start_date + duration).unix();
        shifts.push({
          id: shift!.id,
          start: real_start_date,
          end: real_end_date,
          userRecordId,
        });
        elements.push(element.id);
      });
      hoursDispatch({
        type: 'SET_LOADING_ELEMENTS',
        payload: elements,
      });
      hoursDispatch({
        type: 'SET_LOADING',
        payload: true,
      });
      const cancel_token_source: CancelTokenSource = axios.CancelToken.source();
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/shifts`,
          {
            departmentId: activeDepartmentId,
            shifts,
            force: true,
          },
          {
            params: {
              picker: 'month',
              startDate: startDate.unix(),
              endDate: endDate.unix(),
              departmentId: activeDepartmentId,
              sectionId: activeSection ? activeSection : null,
            },
            cancelToken: cancel_token_source.token,
          },
        )
        .then((res) => {
          if (res.data.result) {
            const recordIds = Object.keys(res.data.result);
            const data = Object.values(res.data.result);
            const errors: any[] = [];
            recordIds.forEach((id, index) => {
              const username = usersMap.get(id)?.displayName;
              const err = data[index];
              errors.push({ username, data: err });
            });
            if (errors.length > 0) {
              Modal.confirm({
                className: 'modal-constraints',
                title: t('SCHEDULE.CONSTRAINTS.TITLE'),
                icon: null,
                content: (
                  <ul>
                    {errors.map((error) => (
                      <li>
                        <div>{error.username}</div>
                        <ul>
                          {error.data.map((err: any) => (
                            <li>
                              {err.detail} : {t(`SCHEDULE.CONSTRAINTS.${err.type}`)}
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                ),
                cancelText: t('GLOBAL.CANCEL'),
                okText: t('GLOBAL.OK'),
                closable: true,
              });
            }
          }
          updateShiftsAndContextWithShiftResponse(hoursDispatch, res, {
            department: activeDepartment!,
            picker,
            startDate,
            endDate,
            activeSection,
            skills,
            userStatus: userCategories,
          });
          monthly_selected_elements.map((el: Element) => el.classList.remove('highlight'));
          hoursDispatch({
            type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
            payload: null,
          });
          hoursDispatch({
            type: 'SET_MONTHLY_SELECTED_ELEMENTS',
            payload: [],
          });
          hoursDispatch({
            type: 'SET_MONTHLY_SELECTING_ELEMENTS',
            payload: false,
          });
          hoursDispatch({
            type: 'SET_LOADING_ELEMENTS',
            payload: null,
          });
          hoursDispatch({
            type: 'SET_LOADING',
            payload: false,
          });
        })
        .catch((err) => {
          monthly_selected_elements.map((el: Element) => el.classList.remove('highlight'));
          hoursDispatch({
            type: 'SET_LOADING_ELEMENTS',
            payload: null,
          });
          hoursDispatch({
            type: 'SET_MONTHLY_SELECTED_ELEMENTS',
            payload: [],
          });
          hoursDispatch({
            type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
            payload: null,
          });
          hoursDispatch({
            type: 'SET_LOADING',
            payload: false,
          });
          if (err?.response?.data?.errors) {
            hoursDispatch({
              type: 'SET_ERRORS',
              payload: Object.values(err.response.data.errors).flat(1) as string[],
            });
          }
        });
    }
  };

  if (department?.scheduleParams?.force_section && !activeSection) {
    return <NoSectionSelected />;
  }

  if (isLoading) {
    return (
      <React.Fragment>
        {[...new Array(3)].map((loadingRow, loadingRowIndex) => (
          <UserShiftLoadingRow
            key={`userLoading_${loadingRowIndex}`}
            baseClassName={`${className} ${picker}`}
          ></UserShiftLoadingRow>
        ))}
      </React.Fragment>
    );
  }

  let filteredShiftsOnSections = new Map();
  filteredShiftsOnSections.set('all', []);
  sections.map((section) => {
    filteredShiftsOnSections.set(section.name, []);
    shifts.map((shift) => {
      const section_data = filteredShiftsOnSections.get(section.name);
      const all_data = filteredShiftsOnSections.get('all');
      shift.days.map((day) => {
        if (day.shifts.length) {
          day.shifts.map((shift_data) => {
            if (shift_data.section?.id == section.id) {
              filteredShiftsOnSections.set(section.name, [...section_data, shift]);
            } else {
              if (!all_data.includes(shift)) {
                if (!shift_data.section) {
                  filteredShiftsOnSections.set('all', [...all_data, shift]);
                }
              }
            }
          });
        }
      });
    });
  });

  return (
    <React.Fragment key={key}>
      <div className="list" ref={ref} style={{ height: shifts.length == 0 ? 0 : 'auto' }}>
        {activeDepartment?.scheduleParams?.monthly_group_by_sections &&
        isFeatureEnabled(features, FEATURES.SECTIONS) &&
        !!sections?.length &&
        !activeSection ? (
          <>
            {Array.from(filteredShiftsOnSections.entries()).map((entry) => {
              const [key, value] = entry;
              const section = sections.find((section) => section.name === key);
              if (key !== 'all' && value.length) {
                return (
                  <>
                    <div style={{ paddingTop: '15px', backgroundColor: 'rgb(240, 245, 250)' }}>
                      <span
                        style={{
                          backgroundColor: section?.background,
                          color: getContrast(section?.background ? section.background : '#ffffff'),
                          padding: '5px 10px',
                          borderRadius: '5px 5px 0 0',
                        }}
                      >
                        {key}
                      </span>
                    </div>
                    <div style={{ border: `1px solid ${section?.background}` }}>
                      <ViewportList viewportRef={ref} items={value} itemMinSize={40}>
                        {(item, index) => {
                          const { user, totalPrice, days, totalMinutes } = item as any;
                          const { recordId: userRecordId } = user;
                          return (
                            <MonthlyUserShiftRow
                              key={`userShifts_${userRecordId}`}
                              baseClassName={`${className} ${picker}`}
                              totalMinutes={totalMinutes}
                              totalPrice={totalPrice}
                              user={user}
                              activeSection={activeSection}
                              activeDepartment={department || undefined}
                              showSkillInsteadOfStatus={department?.scheduleParams?.orderByWeek === 'SKILL'}
                            >
                              <Shifts
                                showComment={department?.scheduleParams?.showComment}
                                userRecordId={userRecordId!}
                                days={days}
                                section={sections.find((section) => section.name === key)}
                              />
                            </MonthlyUserShiftRow>
                          );
                        }}
                      </ViewportList>
                    </div>
                  </>
                );
              }
            })}
            {Array.from(filteredShiftsOnSections.entries()).map((entry) => {
              const [key, value] = entry;
              if (key === 'all') {
                if ([...filteredShiftsOnSections.values()].every((arr) => arr.length === 0)) {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '50px 0',
                      }}
                    >
                      <p>{t('SCHEDULE.MONTHLY_VIEW.EMPTY_SCHEDULE.TITLE')}</p>
                      <Button
                        type="primary"
                        size="large"
                        onClick={() => {
                          hoursDispatch({
                            type: 'SET_SELECTED_SHIFT',
                            payload: null,
                          });
                          hoursDispatch({
                            type: 'SET_SHIFT_DRAWER_VISIBLE',
                            payload: true,
                          });
                        }}
                      >
                        {t('SCHEDULE.MONTHLY_VIEW.EMPTY_SCHEDULE.CREATE_SHIFT')}
                      </Button>
                    </div>
                  );
                }
                return (
                  <>
                    <div style={{ paddingTop: '15px', backgroundColor: 'rgb(240, 245, 250)' }}>
                      <span
                        style={{
                          backgroundColor: '#000000',
                          color: getContrast('#000000'),
                          padding: '5px 10px',
                          borderRadius: '5px 5px 0 0',
                        }}
                      >
                        {t('SCHEDULE.MONTHLY_VIEW.NO_SECTION')}
                      </span>
                    </div>
                    <div style={{ border: `1px solid black` }}>
                      <ViewportList viewportRef={ref} items={value} itemMinSize={40}>
                        {(item, index) => {
                          const { user, totalPrice, days, totalMinutes } = item as any;
                          const { recordId: userRecordId } = user;
                          return (
                            <MonthlyUserShiftRow
                              key={`userShifts_${userRecordId}`}
                              baseClassName={`${className} ${picker}`}
                              totalMinutes={totalMinutes}
                              totalPrice={totalPrice}
                              user={user}
                              activeSection={activeSection}
                              activeDepartment={department || undefined}
                              showSkillInsteadOfStatus={department?.scheduleParams?.orderByWeek === 'SKILL'}
                            >
                              <Shifts
                                showComment={department?.scheduleParams?.showComment}
                                userRecordId={userRecordId!}
                                days={days}
                              />
                            </MonthlyUserShiftRow>
                          );
                        }}
                      </ViewportList>
                    </div>
                  </>
                );
              }
            })}
          </>
        ) : (
          <ViewportList viewportRef={ref} items={shifts} itemMinSize={40}>
            {(item, index) => {
              const { user, totalPrice, days, totalMinutes } = item;
              const { recordId: userRecordId } = user;
              return (
                <MonthlyUserShiftRow
                  key={`userShifts_${userRecordId}`}
                  baseClassName={`${className} ${picker}`}
                  totalMinutes={totalMinutes}
                  totalPrice={totalPrice}
                  user={user}
                  activeSection={activeSection}
                  activeDepartment={department || undefined}
                  showSkillInsteadOfStatus={department?.scheduleParams?.orderByWeek === 'SKILL'}
                >
                  <Shifts
                    showComment={department?.scheduleParams?.showComment}
                    userRecordId={userRecordId!}
                    days={days}
                  />
                </MonthlyUserShiftRow>
              );
            }}
          </ViewportList>
        )}
      </div>
    </React.Fragment>
  );
};

const AppHoursManageDailyStyled = styled(AppHoursManageDaily)`
  display: flex;
  position: relative;
  width: 100%;
  min-width: 100%;

  &.month {
    width: fit-content;
  }

  > .left {
    position: sticky;
    z-index: 1;
    left: 0;
    padding: 10px;
    border-right: 1px solid ${Colors.blueLight};
    border-bottom: 1px solid ${Colors.blueLight};
    width: 12%;
    overflow: hidden;
    background: white;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #f9f9f9;
    @media print {
      width: 120px;
    }
  }

  *::-moz-selection {
    background: transparent;
  }

  *::selection {
    background: transparent;
  }
`;

export default AppHoursManageDailyStyled;
