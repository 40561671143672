import { default as Colors, default as colors } from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { IUser } from '@/types/user.model';
import { getWindowSize, minutesToHoursAndOrMinutes } from '@/utils';
import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { InitialStateType } from '../redux/store';
import UserShiftRightClick from './UserShiftRightClick';

interface Props {
  baseClassName: string;
  className?: string;
  children?: any;
  user: IUser;
  totalPrice: number;
  totalMinutes: number;
  activeSection?: string;
  activeDepartment?: IDepartment;
  showSkillInsteadOfStatus?: boolean;
}

const UserShiftRow: React.FC<Props> = ({
  baseClassName,
  className,
  children,
  user,
  totalPrice,
  totalMinutes,
  activeSection,
  activeDepartment,
  showSkillInsteadOfStatus,
}) => {
  const {
    showDetails,
    showShifts,
    showAbsences,
    showOtherDepartments,
    filterOptions,
    picker,
    filteredShiftsByUsers,
  } = useSelector(
    ({
      showDetails,
      showShifts,
      showAbsences,
      showOtherDepartments,
      filterOptions,
      picker,
      filteredShiftsByUsers,
    }: InitialStateType) => ({
      showDetails,
      showShifts,
      showAbsences,
      showOtherDepartments,
      filterOptions,
      picker,
      filteredShiftsByUsers,
    }),
    shallowEqual,
  );

  const {
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    displayName,
    lastname,
    firstname,
    recordId: userRecordId,
    picture,
    initials,
    maxWeekMinutes,
    totalMinutes: globalTotalMinutes,
    price: globalTotalPrice,
    ay_sync,
  } = user;

  const weekMinutesDifference = globalTotalMinutes! - maxWeekMinutes!;
  const positiveDifference = weekMinutesDifference > 0;
  const shiftsByUser = filteredShiftsByUsers.find((shiftsByUser) => shiftsByUser.user?.recordId === userRecordId)!;

  return (
    <div className={`${baseClassName} ${className}`} key={`sgi_${userRecordId}`}>
      <UserShiftRightClick shiftsByUser={shiftsByUser} picker={picker}>
        <div
          className="left"
          style={{
            borderLeftColor: showSkillInsteadOfStatus ? user?.skills?.[0]?.background : user?.userType?.background,
            padding: '6px 15px 0',
            width: windowSize.innerWidth > 900 ? 210 : 150,
          }}
        >
          <div className="user-info">
            {windowSize.innerWidth > 900 && (
              <div className="left no-print">
                <Avatar src={picture}>{`${initials!}`}</Avatar>
              </div>
            )}
            <div className="right">
              <Link to={`/app/team/collaborators/${userRecordId}`} className="name">
                {windowSize.innerWidth > 900 ? displayName : `${firstname?.charAt(0)}. ${lastname}`}
              </Link>
              {showSkillInsteadOfStatus && user?.skills?.[0]?.name ? (
                <span style={{ fontSize: 11, marginTop: -3, color: colors.grey }}>{user?.skills?.[0]?.name}</span>
              ) : (
                <span style={{ fontSize: 11, marginTop: -3, color: colors.grey }}>{user?.userType?.name}</span>
              )}
              {showDetails && (
                <span className="role">
                  {ay_sync && <i className="icon-ay_icon" style={{ color: colors.ayBrand }} />}
                  {/* {showSkillInsteadOfStatus ? user?.skills?.[0]?.name : user?.userType?.name} */}
                  {!!activeDepartment?.scheduleParams?.sidebarCounters && globalTotalMinutes && (
                    <span style={{ color: colors.orange, marginLeft: 5 }}>
                      {minutesToHoursAndOrMinutes(maxWeekMinutes!)}
                    </span>
                  )}
                </span>
              )}
            </div>
          </div>
          {windowSize.innerWidth > 900 && showDetails === true && (
            <React.Fragment>
              {!activeDepartment?.scheduleParams?.sidebarCounters && globalTotalMinutes! > 0 && (
                <div className="statistics no-print">
                  <span className={`tag ${positiveDifference ? 'red' : 'blue'}`}>
                    {minutesToHoursAndOrMinutes(globalTotalMinutes!)}{' '}
                    {globalTotalPrice! > 0 && (
                      <React.Fragment>
                        -{' '}
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: activeDepartment?.currency || 'EUR',
                        })
                          .format(Number(globalTotalPrice))
                          .slice(Number(globalTotalPrice) < 0 ? 2 : 1)
                          .replace(/[,\.]/g, (match) => (match === ',' ? '.' : ','))
                          .replace(/(?:\.(?:00)+|,(?:00)+)$/, '') || '-'}
                        €
                      </React.Fragment>
                    )}
                  </span>
                  {picker === 'week' && maxWeekMinutes! > 0 && weekMinutesDifference !== 0 && (
                    <React.Fragment>
                      <span className={`tag indicator ${positiveDifference ? 'red' : 'blue'}`}>
                        {positiveDifference && '+'}
                        {minutesToHoursAndOrMinutes(weekMinutesDifference)}
                      </span>
                      <span className={`tag blue-light`}>{minutesToHoursAndOrMinutes(maxWeekMinutes!)}</span>
                    </React.Fragment>
                  )}
                </div>
              )}
              {(!!activeSection || showOtherDepartments || !showAbsences || !showShifts || !!filterOptions?.length) &&
                totalMinutes! > 0 &&
                totalPrice !== globalTotalPrice && (
                  <div className="statistics no-print">
                    <span className="tag local">
                      {minutesToHoursAndOrMinutes(totalMinutes!)}{' '}
                      {totalPrice > 0 && (
                        <React.Fragment>
                          -{' '}
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: activeDepartment?.currency || 'EUR',
                          })
                            .format(Number(totalPrice))
                            .slice(Number(totalPrice) < 0 ? 2 : 1)
                            .replace(/[,\.]/g, (match) => (match === ',' ? '.' : ','))
                            .replace(/(?:\.(?:00)+|,(?:00)+)$/, '') || '-'}
                          €
                        </React.Fragment>
                      )}
                    </span>
                  </div>
                )}
            </React.Fragment>
          )}
        </div>
      </UserShiftRightClick>
      <div className="right">{children}</div>
    </div>
  );
};

const UserShiftRowStyled = styled(React.memo(UserShiftRow))`
  @media print {
    position: relative;
    page-break-inside: avoid;
  }

  transition: background 500ms;

  &:hover {
    background: #fafafa;
  }

  > .left {
    border-left: 6px solid transparent;
  }

  .user-info {
    display: flex;
    padding-bottom: 5px;

    > .left {
      padding-right: 9px;
    }

    > .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      span,
      a {
        font-weight: 500;
        display: block;

        &.name,
        &.role {
          display: flex;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 140px;
          flex-wrap: wrap;
        }

        &.name {
          color: ${Colors.blue};
          font-size: 12px;
        }
        &.role {
          font-size: 10px;
          color: ${Colors.grey};
        }
      }
    }
  }

  .statistics {
    padding: 2px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .tag {
      border-radius: 3px;
      padding: 2px 3px;
      font-size: 12px;
      display: inline-block;
      line-height: 12px;
      font-size: 10px;
      &:nth-child(n + 2) {
        margin-left: 3px;
      }
      &.blue:not(.indicator) {
        background: ${colors.bluePastel};
        color: white;
      }
      &.red:not(.indicator) {
        background: ${colors.red};
        color: white;
      }
      &.blue.indicator {
        background: rgba(0, 0, 0, 0.14);
        color: ${colors.bluePastel};
      }
      &.red.indicator {
        background: rgba(0, 0, 0, 0.14);
        color: ${colors.red};
      }
    }

    .tag.local {
      color: ${colors.grey};
      margin: 0;
      border: 1px solid ${colors.grey};
      border-radius: 3px;
      padding: 2px 3px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 180px;
    }
  }
`;

export default UserShiftRowStyled;
