import { IUser } from '@/types/user.model';
import { getWindowSize } from '@/utils';
import { Button, DatePicker, Select, Space } from 'antd';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
  datePickerValue: Moment;
  setDatePickerValue: React.Dispatch<React.SetStateAction<Moment>>;
  userRecordId: string | null;
  setUserRecordId: React.Dispatch<React.SetStateAction<string | null>>;
  onWantToExportTimesheets: () => void;
  isExportingTimesheets: boolean;
  allSelected: boolean;
  selectAll: (selected: boolean) => void;
  onValidate: () => void;
  onInvalidate: () => void;
  validating: boolean;
  dataSource: any[];
  loadingUsers: boolean;
  users: IUser[];
}

const Header: React.FC<Props> = ({
  className,
  datePickerValue,
  setDatePickerValue,
  userRecordId,
  setUserRecordId,
  onWantToExportTimesheets,
  isExportingTimesheets,
  allSelected,
  selectAll,
  onValidate,
  onInvalidate,
  validating,
  dataSource,
  loadingUsers,
  users,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [otherUsers, setOtherUsers] = useState<any[]>([]);
  const [usersList, setUsersList] = useState<any[]>([]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setOtherUsers(dataSource.map((element) => ({ displayName: element.displayName, recordId: element.recordId })));
  }, [dataSource]);

  useEffect(() => {
    const mergedArray = [...users, ...otherUsers];
    const uniqueArray = Array.from(new Map(mergedArray.map((item) => [item.recordId, item])).values());
    setUsersList(uniqueArray);
  }, [otherUsers]);

  return (
    <header className={className}>
      <div className="left">
        <Space>
          <DatePicker
            picker={'month'}
            className="datePicker"
            format={`MMMM YYYY`}
            suffixIcon={null}
            value={datePickerValue}
            allowClear={false}
            onChange={(e) => {
              if (e) {
                setDatePickerValue(e);
              }
            }}
          />
          <Select
            getPopupContainer={(trigger) => trigger}
            showSearch
            clearIcon
            allowClear
            mode="multiple"
            maxTagTextLength={windowSize.innerWidth > 900 ? 10 : users.length}
            maxTagCount={windowSize.innerWidth > 900 ? 3 : users.length}
            placeholder={t('GLOBAL.USERS')}
            loading={loadingUsers}
            disabled={loadingUsers}
            value={userRecordId ? userRecordId : undefined}
            onChange={(v) => setUserRecordId(`${v}`)}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            className="select-users"
          >
            {usersList?.map((user) => {
              const { recordId, displayName } = user;
              return (
                <Option key={`user_${recordId}`} value={recordId!}>
                  {displayName}
                </Option>
              );
            })}
          </Select>
        </Space>
      </div>
      <div className="right">
        <Button
          type="default"
          onClick={() => selectAll(allSelected ? false : true)}
          className="action"
          style={{ marginRight: 10 }}
        >
          {allSelected ? t('GLOBAL.UNSELECT') : t('GLOBAL.SELECT_ALL')}
        </Button>
        <Button loading={validating} type="primary" onClick={onValidate} className="action" style={{ marginRight: 10 }}>
          {t('GLOBAL.VALIDATE')}
        </Button>
        <Button
          loading={validating}
          type="primary"
          onClick={onInvalidate}
          className="action"
          style={{ marginRight: 10, backgroundColor: '#cf6363', borderColor: '#cf6363' }}
        >
          {t('GLOBAL.INVALIDATE')}
        </Button>
        <Button onClick={onWantToExportTimesheets} loading={isExportingTimesheets} type="primary" danger>
          {t('REPORTS.HR_TIMESHEETS.GENERATE_EXPORT_FILES')}
        </Button>
      </div>
    </header>
  );
};

export default styled(Header)`
  .left {
    .ant-space {
      .ant-space-item {
        &:first-child {
          .ant-picker {
            width: 150px;
          }
        }
        .select-users {
          width: 250px;
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 15px;

    .left {
      .ant-space {
        width: 100%;
        .ant-space-item {
          &:first-child {
            .ant-picker {
              width: 150px;
            }
          }
          &:nth-child(2) {
            flex: 1;
          }
          .select-users {
            width: 100%;
          }
        }
      }
    }
  }
`;
