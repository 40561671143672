import { IDimona } from '@/types/dimona.model';
import { IProductivityIndex } from '@/types/productivity-index.model';
import { IScheduleDay } from '@/types/schedule-day.model';
import { IScheduleHour } from '@/types/schedule-hour.model';
import { IShiftBlock } from '@/types/shift-block.model';
import { IShift } from '@/types/shift.model';
import { IUser } from '@/types/user.model';
import { IWeeklyView } from '@/types/weekly-view.model';
import moment from 'moment';
import 'moment-timezone';
import { createStore } from 'redux';
import reducer from './reducer';
moment.tz.setDefault('Atlantic/Reykjavik');

export interface IShiftsByUser {
  user: IUser;
  shifts: IShift[];
  totalPrice?: number;
  totalMinutes?: number;
  hasOnlyAbsence?: boolean;
}

export type PickerType = 'day' | 'operational' | 'week' | 'month';

export type InitialStateType = {
  selectedShifts: Set<string>;
  shiftIdsToPublish: string[] | null;
  shiftsToTransfer: {
    ids: string[];
    userRecordId: string;
  } | null;
  // CONTEXT
  pasteMultipleFreeShiftsVisible: boolean;
  freeShiftToPasteMultiple: IShift | null;
  addOccurrencesModalVisible: boolean;
  shiftToAddOccurrences: IShift | null;
  assignModalVisible: boolean;
  shiftDrawerVisible: boolean;
  multipleShiftsDrawerVisible: boolean;
  picker: PickerType;
  selectedDate: moment.Moment;
  startDate: moment.Moment;
  endDate: moment.Moment;
  shiftsMap: Map<string, IShift>;
  usersMap: Map<string, IUser>;
  days: IScheduleDay[];
  hours: IScheduleHour[];
  totalBudget: number | null;
  cycleNumber: number | null;
  productivityIndex: IProductivityIndex | null;
  productivityIndexReal: IProductivityIndex | null;
  scheduleModel: boolean;
  comparisonTemplate: null | Partial<{ id: string; name: string }>;
  shiftsByUsers: IShiftsByUser[];
  shiftsByUsersMapWithShiftId: Map<string, { userIndex: number; shiftIndex: number }>;
  shiftsByUsersMapWithUserId: Map<string, number>;
  filteredShiftsByUsers: IShiftsByUser[];
  selectedShift: IShift | null;
  shiftIdsToDelete: string[] | null;
  showOpenShifts: boolean;
  showShifts: boolean;
  showHeadCount: boolean;
  showComment: boolean;
  showHrCode: boolean;
  showAbsences: boolean;
  showSkills: boolean;
  showTasks: boolean;
  showSections: boolean;
  showAttributes: boolean;
  showDetails: boolean;
  showPrices: boolean;
  showOtherDepartments: boolean;
  showProductivityIndex: boolean;
  showClocking: boolean;
  showMonthlyHours: boolean;
  showMonthlyStart: boolean;
  filterOptions: string[];
  clipboardHistory: {
    shifts: IShift[];
    startDate: moment.Moment;
  } | null;
  clipboardHistoryDay: {
    shifts: IShift[];
    startDate: moment.Moment;
  } | null;
  isLoading: boolean;
  hasError: boolean;
  newDimona: Partial<IDimona> | null;
  taskScheduler: boolean;
  monthly_selecting_elements: boolean;
  monthly_selected_elements: any[];
  errors: any[];
  create_new_shift_block: boolean;
  shift_blocks: IShiftBlock[];
  shift_blocks_loading: boolean;
  active_shift_block: IShiftBlock | null;
  editing_shift_block: boolean;
  selected_shifts_on_shift_key: IShift[] | null;
  opened_monthly_dropdown_identifier: string | null;
  loading_elements: string[] | null;
  loading: boolean;
  monthly_start_point_element: string | null;
  monthly_selecting_direction: 'row' | 'column' | null;
  copied_shift: IShift | null;
  monthly_shifts_to_replace: string[] | null;
  monthly_shifts_to_replace_shift_block: string | null;
  active_weekly_view: IWeeklyView | null;
  ayShiftIds: string[];
  ayShiftIdsLoading: boolean;
  ayErrors: any;
  commandSelectedElement: Element | null;
  commandSelectedKey: number;
  commandSelectedIndex: number;
  commandSelectedActivated: boolean;
};

export const initialState: InitialStateType = {
  selectedShifts: new Set<string>(),
  shiftIdsToPublish: null,
  shiftsToTransfer: null,
  // CONTEXT
  pasteMultipleFreeShiftsVisible: false,
  freeShiftToPasteMultiple: null,
  addOccurrencesModalVisible: false,
  shiftToAddOccurrences: null,
  assignModalVisible: false,
  shiftDrawerVisible: false,
  multipleShiftsDrawerVisible: false,
  picker: 'week',
  selectedDate: moment(),
  startDate: moment().startOf('isoWeek'),
  endDate: moment().endOf('isoWeek'),
  shiftsMap: new Map(),
  usersMap: new Map(),
  days: [],
  hours: [],
  totalBudget: null,
  cycleNumber: null,
  productivityIndex: null,
  productivityIndexReal: null,
  scheduleModel: false,
  comparisonTemplate: null,
  shiftsByUsers: [],
  shiftsByUsersMapWithShiftId: new Map(),
  shiftsByUsersMapWithUserId: new Map(),
  filteredShiftsByUsers: [],
  selectedShift: null,
  shiftIdsToDelete: null,
  showShifts: localStorage.getItem('showShifts') ? JSON.parse(localStorage.getItem('showShifts')!) : true,
  showAbsences: localStorage.getItem('showAbsences') ? JSON.parse(localStorage.getItem('showAbsences')!) : true,
  showOpenShifts: localStorage.getItem('showOpenShifts') ? JSON.parse(localStorage.getItem('showOpenShifts')!) : true,
  showSkills: localStorage.getItem('showSkills') ? JSON.parse(localStorage.getItem('showSkills')!) : true,
  showTasks: localStorage.getItem('showTasks') ? JSON.parse(localStorage.getItem('showTasks')!) : true,
  showSections: localStorage.getItem('showSections') ? JSON.parse(localStorage.getItem('showSections')!) : true,
  showAttributes: localStorage.getItem('showAttributes') ? JSON.parse(localStorage.getItem('showAttributes')!) : true,
  showDetails: localStorage.getItem('showDetails') ? JSON.parse(localStorage.getItem('showDetails')!) : true,
  showPrices: localStorage.getItem('showPrices') ? JSON.parse(localStorage.getItem('showPrices')!) : true,
  showHeadCount: localStorage.getItem('showHeadCount') ? JSON.parse(localStorage.getItem('showHeadCount')!) : true,
  showComment: localStorage.getItem('showComment') ? JSON.parse(localStorage.getItem('showComment')!) : true,
  showHrCode: localStorage.getItem('showHrCode') ? JSON.parse(localStorage.getItem('showHrCode')!) : true,
  showClocking: localStorage.getItem('showClocking') ? JSON.parse(localStorage.getItem('showClocking')!) : false,
  showMonthlyHours: localStorage.getItem('showMonthlyHours')
    ? JSON.parse(localStorage.getItem('showMonthlyHours')!)
    : false,
  showMonthlyStart: localStorage.getItem('showMonthlyStart')
    ? JSON.parse(localStorage.getItem('showMonthlyStart')!)
    : false,
  showOtherDepartments: localStorage.getItem('showOtherDepartments')
    ? JSON.parse(localStorage.getItem('showOtherDepartments')!)
    : false,
  showProductivityIndex: localStorage.getItem('showProductivityIndex')
    ? JSON.parse(localStorage.getItem('showProductivityIndex')!)
    : false,
  filterOptions: [],
  clipboardHistory: null,
  clipboardHistoryDay: null,
  isLoading: false,
  hasError: false,
  newDimona: null,
  taskScheduler: false,
  monthly_selecting_elements: false,
  monthly_selected_elements: [],
  errors: [],
  create_new_shift_block: false,
  shift_blocks: [],
  shift_blocks_loading: false,
  active_shift_block: null,
  editing_shift_block: false,
  selected_shifts_on_shift_key: null,
  loading_elements: null,
  loading: false,
  opened_monthly_dropdown_identifier: null,
  monthly_start_point_element: null,
  monthly_selecting_direction: null,
  copied_shift: null,
  monthly_shifts_to_replace: null,
  monthly_shifts_to_replace_shift_block: null,
  active_weekly_view: null,
  ayShiftIds: [],
  ayShiftIdsLoading: true,
  ayErrors: null,
  commandSelectedElement: null,
  commandSelectedKey: -1,
  commandSelectedIndex: -1,
  commandSelectedActivated: false,
};

export const store = createStore(
  reducer,
  initialState,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
);
