import { Button, message } from 'antd';
import Axios from 'axios';
import { EditorState, RawDraftContentState, convertFromRaw, convertToRaw } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
  departmentId?: string;
}

const Notes: React.FC<Props> = ({ className, departmentId }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { id, attributeId } = useParams() as any;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [contentState, setContentState] = useState({});
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!id || !attributeId) return;
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/resources/${id}/${attributeId}/notes`, {
      params: {
        departmentId,
      },
    })
      .then(({ data }) => {
        const { notes } = data;
        if (notes) {
          const content = JSON.parse(notes);
          const convertedState = convertFromRaw(content as any);
          const editorState = EditorState.createWithContent(convertedState);
          setEditorState(editorState);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSave = () => {
    setIsSaving(true);
    Axios.put(`${process.env.REACT_APP_API_URL}/v3/resources/${id}/${attributeId}/notes`, {
      departmentId,
      notes: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
    })
      .then(() => {
        setIsSaving(false);
        setFormHasChanged(false);
      })
      .catch((error) => {
        message.error(t('NOTES.MESSAGE_SAVING_ERROR'));
        setIsSaving(false);
        console.log(error);
      });
  };

  const onReset = () => {
    console.log('onReset');
  };

  const onContentStateChange = (contentState: any) => {
    setFormHasChanged(true);
    setContentState(contentState);
  };

  return (
    <div className={className}>
      <Editor
        editorState={editorState}
        initialContentState={contentState as RawDraftContentState}
        toolbar={{
          options: ['inline', 'fontSize', 'list', 'textAlign', 'history', 'emoji', 'colorPicker'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
        }}
        toolbarClassName="toolbarClassName"
        wrapperClassName="card-shadow"
        editorClassName="editorClassName"
        onEditorStateChange={setEditorState}
        onContentStateChange={onContentStateChange}
      />
      <div className={`actions ${formHasChanged ? 'active' : ''}`}>
        <Button size="large" type="primary" onClick={onSave} loading={isSaving} style={{ marginRight: '10px' }}>
          {t('GLOBAL.SAVE')}
        </Button>
        <Button size="large" type="default" className="grey" onClick={onReset}>
          {t('GLOBAL.CANCEL')}
        </Button>
      </div>
    </div>
  );
};

const NotesStyled = styled(Notes)`
  .toolbarClassName {
    background-color: white;
  }
  .card-shadow {
    padding: 0;
    margin-bottom: 40px;
  }
  .editorClassName {
    padding: 20px;
    min-height: 100px;
  }

  .actions {
    position: fixed;
    width: calc(100vw - 740px);
    background: white;
    bottom: -80px;
    margin: 0;
    margin-left: -20px;
    display: flex;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
    padding: 10px;
    border-radius: 3px 3px 0 0;
    transition: bottom 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    &.active {
      transition-timing-function: ease-in;
      bottom: 0px;
    }
  }
`;

export default NotesStyled;
