import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
interface Props {
  className?: string;
  value: Moment;
  onChange: (date: Moment | null) => void;
}

const MonthSelector: React.FC<Props> = ({ className, value, onChange }) => {
  const onArrowClick = (step: number) => {
    const newValue = moment(value).add(step, 'month');
    onChange(newValue);
  };

  return (
    <div className={className}>
      <button onClick={() => onArrowClick(-1)}>
        <i className="icon-angle-left" />
      </button>
      <DatePicker
        picker={'month'}
        className="datePicker"
        format={`MMMM YYYY`}
        suffixIcon={null}
        value={value}
        allowClear={false}
        onChange={onChange}
      />
      <button onClick={() => onArrowClick(1)}>
        <i className="icon-angle-right" />
      </button>
    </div>
  );
};

export default styled(MonthSelector)`
  display: flex;
  button {
    padding: 0;
    i {
      font-size: 22px;
      line-height: 12px;
    }
  }

  .datePicker {
    text-align: center;
    width: fit-content;
    width: 150px;
  }
`;
