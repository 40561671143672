import { Button, Space } from 'antd';
import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  recordId: string;
  onDownload: (recordId: string) => Promise<AxiosResponse<any>>;
}

const TableRowActions: React.FC<Props> = ({ className, recordId, onDownload }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const wantDownload = () => {
    setIsLoading(true);
    onDownload(recordId)
      .then(({ data }) => {
        window.open(data.url, '_blank');
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <Space size="middle" className={className}>
      <Button loading={isLoading} type="default" onClick={wantDownload}>
        {t('GLOBAL.DOWNLOAD')}
      </Button>
    </Space>
  );
};

export default TableRowActions;
