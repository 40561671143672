import TableView from '@/layouts/TableView';
import Color from '@/pages/app/components/Color';
import DrawerAssignUsers from '@/pages/app/components/drawers/AssignUsers';
import DrawerSkill from '@/pages/app/components/drawers/Skill';
import AppContext from '@/pages/app/context';
import { AlignType } from '@/types/alignType.model';
import { ISkill } from '@/types/skill.model';
import { MenuOutlined } from '@ant-design/icons';
import { message, Modal, Table } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Header from './components/Header';
import TableRowActions from './components/TableRowActions';

const SortableElementItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerItem = SortableContainer((props: any) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />);

interface Props {
  className?: string;
}

interface IUserForAttribution {
  recordId: string;
  displayName: string;
  active: boolean;
}

const ManageSkills: React.FC<Props> = ({ className }) => {
  const {
    state: { loadingSkills, skills, activeDepartment, activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const skillsRef = useRef(skills);
  skillsRef.current = skills;
  const [filteredSkills, setFilteredSkills] = useState<ISkill[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeSkill, setActiveSkill] = useState<ISkill | null>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [showAssignDrawer, setShowAssignDrawer] = useState<ISkill | null>(null);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    setColumns([
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        // eslint-disable-next-line react/display-name
        render: () => <DragHandle />,
      },
      {
        title: t('GLOBAL.ID'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      },
      {
        title: t('GLOBAL.EMPLOYEE_COST'),
        dataIndex: 'price',
        key: 'price',
        sorter: (a: any, b: any) => a.price - b.price,
        // eslint-disable-next-line react/display-name
        render: (price: number) => {
          return (
            <span>
              {new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: activeDepartment?.currency || 'EUR',
              }).format(price)}{' '}
              / h
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.EMPLOYER_COST'),
        dataIndex: 'priceBruto',
        key: 'priceBruto',
        sorter: (a: any, b: any) => a.priceBruto - b.priceBruto,
        // eslint-disable-next-line react/display-name
        render: (priceBruto: number) => {
          return (
            <span>
              {new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: activeDepartment?.currency || 'EUR',
              }).format(priceBruto)}{' '}
              / h
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.COLOR'),
        dataIndex: 'background',
        key: 'background',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: ISkill) => {
          return <Color style={{ background: record.background }} />;
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: ISkill) => (
          <TableRowActions
            onEdit={() => setActiveSkill(record)}
            onDelete={() => onWantToDelete(record.id!)}
            onAssign={() => setShowAssignDrawer(record)}
          />
        ),
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    const filteredSkills = skills.filter((skill: any) => {
      return Object.keys(skill).some((key) => {
        if (key === 'background') {
          return false;
        }
        if (typeof skill[key] === 'string') {
          return skill[key].toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return false;
        }
      });
    });
    setFilteredSkills(filteredSkills);
  }, [skills, searchTerm]);

  const onWantToDelete = (skillId: string) => {
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/skills/${skillId}/users`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        if (data.length > 0 && data.find((user: IUserForAttribution) => user.active == true)) {
          return Modal.error({
            title: t('GLOBAL.CANNOT_DELETE'),
            content: t('SETTINGS.TOOLBOX.SKILLS.CANNOT_DELETE'),
          });
        } else {
          Modal.confirm({
            title: t('GLOBAL.DELETION'),
            icon: null,
            content: t('SKILLS.MODAL_DELETE_SKILL.CONTENT'),
            cancelText: t('GLOBAL.CANCEL'),
            okText: t('GLOBAL.REMOVE'),
            okType: 'danger',
            onOk: () => {
              onDelete(skillId);
            },
            onCancel: () => {},
          });
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      });
  };

  const onDelete = (skillId: string) => {
    const skillsCopy = [...skillsRef.current];
    const index = skillsCopy.findIndex((skill) => skill.id === skillId);

    if (~index) {
      const skillsResult = [...skillsCopy];
      skillsResult.splice(index, 1);
      dispatch({
        type: 'SET_SKILLS',
        payload: skillsResult,
      });
    }

    axios.delete(`${process.env.REACT_APP_API_URL}/v3/skills/${skillId}`).catch((error) => {
      if (~index) {
        console.error(error);
        message.error(t('SKILLS.MODAL_DELETE_SKILL.MESSAGE_ERROR'));
        if (skillsCopy) {
          dispatch({
            type: 'SET_SKILLS',
            payload: skillsCopy,
          });
        }
      }
    });
  };

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchTerm(value);
  };

  const onSave = (result: ISkill) => {
    const skillsCopy = [...skillsRef.current];
    const index = skillsCopy.findIndex((skill) => skill.id === result.id);

    if (~index) {
      skillsCopy[index] = result;
      dispatch({
        type: 'SET_SKILLS',
        payload: skillsCopy,
      });
    } else {
      skillsCopy.push(result);
      const skillsCopySorted = skillsCopy.sort((a, b) => {
        if (a.name!.toLowerCase() < b.name!.toLowerCase()) {
          return -1;
        }
        if (a.name!.toLowerCase() > b.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      dispatch({
        type: 'SET_SKILLS',
        payload: skillsCopySorted,
      });
    }
    setActiveSkill(null);
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerItem useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const oldSkills = [...skills];
    if (skills && oldIndex !== newIndex) {
      dispatch({
        type: 'SET_LOADING_SKILLS',
        payload: true,
      });
      const newSkills: ISkill[] = arrayMove(skills, oldIndex, newIndex);
      dispatch({
        type: 'SET_SKILLS',
        payload: newSkills,
      });
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/skills`,
          {
            fields: newSkills.map((x) => x.id),
          },
          {
            params: {
              departmentId: activeDepartment?.id,
            },
          },
        )
        .then(({ data }) => {
          dispatch({
            type: 'SET_SKILLS',
            payload: data.skills,
          });
          dispatch({
            type: 'SET_LOADING_SKILLS',
            payload: false,
          });
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: 'SET_SKILLS',
            payload: oldSkills,
          });
          dispatch({
            type: 'SET_LOADING_SKILLS',
            payload: false,
          });
        });
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = skills.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableElementItem index={index} {...restProps} />;
  };

  return (
    <React.Fragment>
      <h2>{t('GLOBAL.SKILLS')}</h2>
      <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10, marginTop: 25 }}>
        <TableView>
          <Header disabled={false} onSearchChange={onSearchChange} onCreate={() => setActiveSkill({})} />
          <Table
            className={className}
            loading={loadingSkills}
            dataSource={filteredSkills}
            columns={columns}
            rowKey="id"
            pagination={false}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          />
        </TableView>
        <DrawerSkill skill={activeSkill} visible={!!activeSkill} onClose={() => setActiveSkill(null)} onSave={onSave} />
        <DrawerAssignUsers
          departmentId={activeDepartmentId}
          entity={showAssignDrawer}
          entityName="skills"
          onClose={() => setShowAssignDrawer(null)}
        />
      </div>
    </React.Fragment>
  );
};

export default ManageSkills;
