import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore } from 'redux';
import reducer from './reducer';
import { IWidget } from '@/types/widget.model';
import moment, { Moment } from 'moment';
import 'moment-timezone';
moment.tz.setDefault('Atlantic/Reykjavik');

export type InitialStateType = {
  selectedDate: Moment;
  startDate: Moment;
  endDate: Moment;
  widgets: IWidget[];
};

export const initialState: InitialStateType = {
  selectedDate: moment().startOf('day'),
  startDate: moment().startOf('isoWeek'),
  endDate: moment().endOf('isoWeek'),
  widgets: [],
};

const composeEnhancers = composeWithDevTools({
  name: 'Dashboard',
});

export const store = createStore(reducer, initialState, composeEnhancers());
