import { Button, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import { useDispatch, useSelector } from 'react-redux';
import { InitialStateType } from '../redux/store';
import { ActionsType } from '@/pages/app/actions';

interface Props {
  className?: string;
  visible: boolean;
  onClose: () => void;
}

const ModalSaveView: React.FC<Props> = ({ className, visible, onClose }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { state: appContextState, dispatch: appContextDispatch } = useContext(AppContext);
  const { activeDepartmentId } = appContextState;
  const { filterOptions } = useSelector(({ filterOptions }: InitialStateType) => ({
    filterOptions,
  }));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, []);

  const onFinish = () => {
    setLoading(true);
    const users: string[] = [];
    const sections: string[] = [];
    const departments: string[] = [];
    const skills: string[] = [];
    const tasks: string[] = [];
    const attributes: string[] = [];
    const userCategories: string[] = [];

    if (filterOptions.length) {
      for (let index = 0; index < filterOptions.length; index++) {
        const [property, id] = filterOptions[index].split('.');
        switch (property) {
          case 'user':
            users.push(id);
            break;
          case 'userCategory':
            userCategories.push(id);
            break;
          case 'section':
            sections.push(id);
            break;
          case 'department':
            departments.push(id);
            break;
          case 'skill':
            skills.push(id);
            break;
          case 'task':
            tasks.push(id);
            break;
          case 'attribut':
            attributes.push(id);
            break;
          default:
            break;
        }
      }
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/shifts/views`,
        {
          name: form.getFieldValue('name'),
          users: users.length > 0 ? users : undefined,
          statuses: userCategories.length > 0 ? userCategories : undefined,
          sections: sections.length > 0 ? sections : undefined,
          skills: skills.length > 0 ? skills : undefined,
          tasks: tasks.length > 0 ? tasks : undefined,
          attributes: attributes.length > 0 ? attributes : undefined,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then((res) => {
        appContextDispatch({
          type: 'SET_WEEKLY_VIEWS',
          payload: res.data,
        });
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Modal className={className} visible={visible} onCancel={onClose} title={t('SCHEDULE.SAVE_VIEW')} footer={null}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="name" rules={[{ required: true }]} label={t('GLOBAL.NAME')}>
          <Input placeholder={t('GLOBAL.NAME')} />
        </Form.Item>
        <div className="buttons-container">
          <Button key="back" onClick={onClose}>
            {t('GLOBAL.CANCEL')}
          </Button>
          <Button loading={loading} type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
            {t('GLOBAL.OK')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default styled(ModalSaveView)`
  .buttons-container {
    display: flex;
    justify-content: flex-end;
  }
`;
