import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled } from '@/utils';
import { Tabs } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SectionTab from '../../../components/SectionTab';

interface Props {
  className?: string;
  activeKey: string;
  setActiveKey: React.Dispatch<React.SetStateAction<string>>;
}

const SectionTabs: React.FC<Props> = ({ className, activeKey, setActiveKey }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, sections, features },
    dispatch,
  } = useContext(AppContext);

  const onChangeTab = (key: string) => {
    setActiveKey(key);
  };

  if (!isFeatureEnabled(features, FEATURES.SECTIONS) || sections.length == 0) return null;

  return (
    <div className={className}>
      <Tabs onChange={onChangeTab}>
        <Tabs.TabPane key="null" tab={t('GLOBAL.GENERAL')}></Tabs.TabPane>
        {sections.map((section) => (
          <Tabs.TabPane
            key={section.id}
            tab={<SectionTab section={section} hasValues activeKey={activeKey} />}
          ></Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default styled(SectionTabs)``;
