import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import colors from '@/styles/colors';
import { formatPrice, minutesToHoursAndOrMinutes } from '@/utils';
import { IBiDashboard } from '@/types/bi/dashboard.model';
import { isNaN } from 'lodash';

interface Props {
  className?: string;
  dashboard: IBiDashboard;
}

const Subheader: React.FC<Props> = ({ className, dashboard }) => {
  const { t } = useTranslation();
  const {
    state: {
      activeDepartment,
      activeDepartmentId,
      groupAdminStart,
      groupAdminUsers,
      groupAdminAttributes,
      groupAdminSections,
      groupAdminSkills,
      groupAdminStatus,
    },
    dispatch,
  } = useContext(AppContext);

  const hours = dashboard.shifts
    .filter((shift) => {
      if (
        groupAdminAttributes.length == 0 &&
        groupAdminSections.length == 0 &&
        groupAdminSkills.length == 0 &&
        groupAdminStatus.length == 0 &&
        groupAdminUsers.length == 0
      )
        return true;
      const user = dashboard.departments
        .flatMap((department) => department.users)
        .find((user) => user.id == shift.ressource_record_id);
      if (user && groupAdminUsers.length > 0 && groupAdminUsers.includes(user.id)) return true;
      if (user && user.user_type && groupAdminStatus.length > 0 && groupAdminStatus.includes(user.user_type))
        return true;
      if (groupAdminSections.length > 0 && groupAdminSections.includes(String(shift.section))) return true;
      if (
        shift.skills &&
        groupAdminSkills.length > 0 &&
        groupAdminSkills.some((element) => shift.skills.includes(element))
      )
        return true;
      if (
        shift.attributes &&
        groupAdminAttributes.length > 0 &&
        groupAdminAttributes.some((element) => shift.attributes.includes(element))
      )
        return true;
      return false;
    })
    .reduce((totalHours, shift) => {
      return totalHours + shift.shift_duration / 60;
    }, 0);

  const cost = dashboard.shifts
    .filter((shift) => {
      if (
        groupAdminAttributes.length == 0 &&
        groupAdminSections.length == 0 &&
        groupAdminSkills.length == 0 &&
        groupAdminStatus.length == 0 &&
        groupAdminUsers.length == 0
      )
        return true;
      const user = dashboard.departments
        .flatMap((department) => department.users)
        .find((user) => user.id == shift.ressource_record_id);
      if (user && groupAdminUsers.length > 0 && groupAdminUsers.includes(user.id)) return true;
      if (user && user.user_type && groupAdminStatus.length > 0 && groupAdminStatus.includes(user.user_type))
        return true;
      if (groupAdminSections.length > 0 && groupAdminSections.includes(String(shift.section))) return true;
      if (
        shift.skills &&
        groupAdminSkills.length > 0 &&
        groupAdminSkills.some((element) => shift.skills.includes(element))
      )
        return true;
      if (
        shift.attributes &&
        groupAdminAttributes.length > 0 &&
        groupAdminAttributes.some((element) => shift.attributes.includes(element))
      )
        return true;
      return false;
    })
    .reduce((totalCost, shift) => {
      return totalCost + (shift.shift_duration / 60) * shift.price_per_hour;
    }, 0);

  const hasTurnover = dashboard.departments.find((department) => department.turnover !== null);
  const averagePayrollCost = cost / hours;

  return (
    <div className={className}>
      <div className="indicator">
        <span className="name">{t('GLOBAL.PERIOD')}</span>
        <span className="value">{groupAdminStart.format('L')}</span>
      </div>
      <div className="indicator">
        <span className="name">{t('GLOBAL.HOURS')}</span>
        <span className="value">{minutesToHoursAndOrMinutes(hours * 60) || `0${t('GLOBAL.HOUR_UNIT')}`}</span>
      </div>
      <div className="indicator">
        <span className="name">{t('GLOBAL.COST')}</span>
        <span className="value">{formatPrice(cost, activeDepartment)}</span>
      </div>
      <div className="indicator">
        <span className="name">{t('SCHEDULE.STATS.AVERAGE_COST')}</span>
        <span className="value">
          {isNaN(averagePayrollCost) ? `0€` : formatPrice(averagePayrollCost, activeDepartment)}
        </span>
      </div>
      {hasTurnover && (
        <>
          <div className="indicator">
            <span className="name">Turnover</span>
            <span className="value">{formatPrice(46820, activeDepartment)}</span>
          </div>
          <div className="indicator">
            <span className="name">Productivity</span>
            <span className="value">81</span>
          </div>
        </>
      )}
    </div>
  );
};

export default styled(Subheader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;

  .indicator {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.greenLight};
    padding: 25px 10px;
    border-radius: 10px;

    .name {
      font-size: 14px;
    }

    .value {
      font-weight: bold;
      font-size: 16px;
    }
  }
`;
