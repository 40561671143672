import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled } from '@/utils';
import { Spin } from 'antd';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DailyTurnoverForm from './components/form';
import FormHeader from './components/form-header';
import Header from './components/header';
import SectionTabs from './components/section-tabs';
import { useDailyTurnover } from './hooks/use-daily-turnover';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
}

const InsightDailyTurnover: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { sections, features },
  } = useContext(AppContext);

  const chartRef = useRef<HTMLCanvasElement | null>(null);

  const [activeKey, setActiveKey] = useState<string>('null');
  const [startDate, setStartDate] = useState<Moment>(moment().startOf('month'));

  const { dailyTurnover, loadingDailyTurnover, setDailyTurnover } = useDailyTurnover({ startDate, activeKey });

  useEffect(() => {
    if (activeKey) return;
    if (!isFeatureEnabled(features, FEATURES.SECTIONS)) return;
    if (sections.length > 0) {
      setActiveKey(sections[0].id!);
    }
  }, [sections, activeKey]);

  return (
    <div className={className}>
      <h2>{t('SETTINGS.INSIGHTS.DAILY_TURNOVER.TITLE')}</h2>
      <Header startDate={startDate} setStartDate={setStartDate} />
      <Spin spinning={loadingDailyTurnover}>
        <div className="chart-container" style={{ position: 'relative', width: '100%', height: 200 }}>
          <canvas ref={chartRef} />
        </div>
      </Spin>
      <SectionTabs activeKey={activeKey} setActiveKey={setActiveKey} />
      <FormHeader />
      <DailyTurnoverForm
        chartRef={chartRef}
        dailyTurnover={dailyTurnover}
        startDate={startDate}
        loadingDailyTurnover={loadingDailyTurnover}
        setDailyTurnover={setDailyTurnover}
      />
    </div>
  );
};

export default styled(InsightDailyTurnover)``;
