import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IAttribut } from '@/types/attribut.model';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { ISkill } from '@/types/skill.model';
import { IExtraColum } from '@/types/timesheet.model';
import { isFeatureEnabled, minutesToHoursAndOrMinutes } from '@/utils';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tag from '../../../components/Tag';
import DrawerClocking from './DrawerClocking';
import VirtualTable from './VirtualTable';
moment.tz.setDefault('Atlantic/Reykjavik');

export type ExportMode = 'XLS' | 'PDF' | null;
interface Props {
  className?: string;
  headerForm: FormInstance<any>;
  activeDepartment?: IDepartment;
  dataSource: any[];
  extraColumns: IExtraColum[];
  hiddenColumns: string[];
  loadingDataSource: boolean;
  columns: any[];
  hideColumns: { [key: string]: boolean };
  headerHeight: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  setColumns: React.Dispatch<React.SetStateAction<any[]>>;
  setClockingModalParams: React.Dispatch<React.SetStateAction<any>>;
}

const FREE_CLOCKING_COLOR = colors.red;
const CLOCKIN_ACCOUNT_COLOR = '#000';

const AppReportTimesheetsDefaultView: React.FC<Props> = ({
  className,
  activeDepartment,
  headerForm,
  dataSource,
  extraColumns,
  loadingDataSource,
  columns,
  hideColumns,
  hiddenColumns,
  headerHeight,
  setColumns,
  setClockingModalParams,
  setCounter,
}) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { customHrCodes, features },
  } = useContext(AppContext);
  const [activeClockingId, setActiveClockingId] = useState<string | null>(null);
  const [windowHeight, setWindowHeight] = useState(0);
  const [dataSourceExplode, setDataSourceExplode] = useState<any[]>([]);
  const [defaultColumns, setDefaultColumns] = useState<any[]>([]);
  const [previousOffset, setPreviousOffset] = useState<number>(0);
  const staticGrid = React.useRef<any | undefined>();

  const ROW_COLOR = isFeatureEnabled(features, FEATURES.SCHEDULE) ? FREE_CLOCKING_COLOR : CLOCKIN_ACCOUNT_COLOR;

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setDefaultColumns([
      {
        title: t('GLOBAL.NAME'),
        key: 'name',
        fixed: 'left',
        width: 180,
        textWrap: 'word-break',
        ellipsis: true,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any, index: number) => {
          const { type, name, bigTotal = false } = record;
          if (['shift', 'dayoff', 'free-clocking'].includes(type)) {
            return <div style={styles[type as string]}>{name}</div>;
          } else if (['summary-planned', 'summary-clocking', 'summary-dayoff'].includes(record.type)) {
            switch (record.type) {
              case 'summary-planned': {
                return (
                  <span style={styles[record.type as string]}>
                    {t(`REPORTS.CONTROL_OF_SERVICES.${bigTotal ? 'BIG_' : ''}TOTAL_PERIOD`).toLocaleUpperCase()}
                  </span>
                );
              }
              case 'summary-clocking': {
                return (
                  <span style={styles[record.type as string]}>
                    {t(`REPORTS.CONTROL_OF_SERVICES.${bigTotal ? 'BIG_' : ''}TOTAL_CLOCKING`).toLocaleUpperCase()}
                  </span>
                );
              }
              case 'summary-dayoff': {
                return (
                  <span style={styles[record.type as string]}>
                    {t(`REPORTS.CONTROL_OF_SERVICES.${bigTotal ? 'BIG_' : ''}TOTAL_DAYOFF`).toLocaleUpperCase()}
                  </span>
                );
              }
            }
          }
        },
      },
      {
        title: t('GLOBAL.USER_CATEGORY_SHORT'),
        key: 'status',
        width: 180,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          const { type, userType } = record;
          if (['shift', 'dayoff', 'free-clocking'].includes(type)) {
            return <span style={styles[type as string]}>{userType?.name}</span>;
          }
        },
      },
      ...(isFeatureEnabled(features, FEATURES.SECTIONS)
        ? [
            {
              title: t('GLOBAL.SECTION'),
              key: 'section',
              width: 180,
              // eslint-disable-next-line react/display-name
              render: (text: any, record: any) => {
                if (['shift', 'free-clocking', 'clocking'].includes(record.type)) {
                  const { section } = record;
                  if (section) {
                    const { color, background, name } = section;
                    return (
                      <Tag
                        size="large"
                        color={color}
                        background={background}
                        name={name}
                        style={{
                          maxWidth: '100%',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      />
                    );
                  } else {
                    return (
                      <Tag size="large" background={'#F9F9F9'} color={'#F9F9F9'} name={'-'} style={{ width: '100%' }} />
                    );
                  }
                }
              },
            },
          ]
        : []),
      ...(isFeatureEnabled(features, FEATURES.SKILLS)
        ? [
            {
              title: t('GLOBAL.SKILLS'),
              key: 'skills',
              width: 180,
              // eslint-disable-next-line react/display-name
              render: (text: any, record: any) => {
                if (['shift', 'clocking', 'free-clocking'].includes(record.type)) {
                  const skills = record.skills;
                  const skillsLength = skills.length;
                  if (skillsLength) {
                    const { color, background, name } = skills[0];
                    if (skillsLength > 1) {
                      const otherSkills = skills
                        .slice(1)
                        .map((skill: ISkill) => skill.name)
                        .join(', ');
                      return (
                        <React.Fragment>
                          <Tag
                            size="large"
                            color={color}
                            background={background}
                            name={name}
                            style={{
                              maxWidth: '100%',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          />
                          <Tooltip title={otherSkills}>
                            <span>
                              <Tag size="large" background={colors.blueExtraLight} name={`+ ${skillsLength - 1} ...`} />
                            </span>
                          </Tooltip>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <Tag
                          size="large"
                          color={color}
                          background={background}
                          name={name}
                          style={{
                            maxWidth: '100%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        />
                      );
                    }
                  } else {
                    return (
                      <Tag size="large" background={'#F9F9F9'} color={'#F9F9F9'} name={'-'} style={{ width: '100%' }} />
                    );
                  }
                }
              },
            },
          ]
        : []),
      {
        title: t('GLOBAL.ATTRIBUTES'),
        key: 'attributes',
        width: 180,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          const { type } = record;
          if (['shift', 'free-clocking', 'clocking'].includes(type)) {
            const { attributes } = record;
            const attributesLength = attributes.length;
            if (attributesLength) {
              const { color, background, name } = attributes[0];
              if (attributesLength > 1) {
                const otherAttributes = attributes
                  .slice(1)
                  .map((attribute: IAttribut) => attribute.name)
                  .join(', ');
                return (
                  <React.Fragment>
                    <Tag
                      size="large"
                      color={color}
                      background={background}
                      name={name}
                      style={{
                        maxWidth: '100%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    />
                    <Tooltip title={otherAttributes}>
                      <span>
                        <Tag size="large" background={colors.blueExtraLight} name={`+ ${attributesLength - 1} ...`} />
                      </span>
                    </Tooltip>
                  </React.Fragment>
                );
              } else {
                return (
                  <Tag
                    size="large"
                    color={color}
                    background={background}
                    name={name}
                    style={{
                      maxWidth: '100%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  />
                );
              }
            } else {
              return <Tag size="large" background={'#F9F9F9'} color={'#F9F9F9'} name={'-'} style={{ width: '100%' }} />;
            }
          } else if (type === 'dayoff') {
            return (
              <Tag
                size="large"
                background={'#7F8894'}
                color={'white'}
                name={record.dayoff?.name?.toUpperCase()}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              />
            );
          }
        },
      },
      {
        title: '',
        key: 'icon',
        width: 80,
        align: 'right',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          switch (record.type) {
            case 'clocking': {
              const logsWithoutRobot = record.logs && record.logs.filter((log: any) => log.recordId !== 0);
              return (
                <div className="column-icon">
                  {logsWithoutRobot.length > 0 && (
                    <Tooltip title={t('REPORTS.HR_TIMESHEETS.HAS_LOGS')}>
                      <i className="icon-edit" style={{ color: 'cornflowerblue' }}></i>
                    </Tooltip>
                  )}
                  {record.homeworking && <i className="icon-home" style={{ color: 'cornflowerblue' }}></i>}
                  <i className="icon-clock" style={{ color: 'cornflowerblue' }}></i>
                  {record.hasComment && <i className="icon-doc-text-inv" style={{ color: 'cornflowerblue' }}></i>}
                  {record.hasPicture && <i className="icon-camera" style={{ color: 'cornflowerblue' }}></i>}
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {record?.origin && record?.origin && record.origin !== 'manual' && (
                      <Tooltip overlay={t(`CLOCKINGS.ORIGINS.CHECK_IN.${record.origin}`)}>
                        <i
                          className={`icon-${
                            record.origin == 'pos'
                              ? 'tablet'
                              : record.origin == 'mobile-v3' || record.origin == 'mobile-v4'
                              ? 'mobile'
                              : ''
                          }`}
                          style={{ color: colors.blueLightPastel, rotate: record.origin == 'pos' ? '90deg' : '' }}
                        />
                      </Tooltip>
                    )}
                    {record?.origin_clock_out && record?.origin_clock_out && record.origin_clock_out !== 'manual' && (
                      <Tooltip overlay={t(`CLOCKINGS.ORIGINS.CHECK_OUT.${record.origin_clock_out}`)}>
                        <i
                          className={`icon-${
                            record.origin_clock_out == 'pos'
                              ? 'tablet'
                              : record.origin_clock_out == 'mobile-v3' || record.origin_clock_out == 'mobile-v4'
                              ? 'mobile'
                              : ''
                          }`}
                          style={{ color: colors.red, rotate: record.origin == 'pos' ? '90deg' : '' }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              );
            }
            case 'free-clocking': {
              const logsWithoutRobot = record.logs && record.logs.filter((log: any) => log.recordId !== 0);
              return (
                <div className="column-icon">
                  {logsWithoutRobot.length > 0 && (
                    <Tooltip title={t('REPORTS.HR_TIMESHEETS.HAS_LOGS')}>
                      <i className="icon-edit" style={{ color: ROW_COLOR }}></i>
                    </Tooltip>
                  )}
                  {record.homeworking && <i className="icon-home" style={{ color: ROW_COLOR }}></i>}
                  {!record?.approved && (
                    <i
                      className="icon-attention"
                      style={{
                        color: ROW_COLOR,
                      }}
                    />
                  )}
                  {record.hasComment && <i className="icon-doc-text-inv" style={{ color: ROW_COLOR }}></i>}
                  {record.hasPicture && <i className="icon-camera" style={{ color: ROW_COLOR }}></i>}
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {record?.origin && record?.origin && record.origin !== 'manual' && (
                      <Tooltip overlay={t(`CLOCKINGS.ORIGINS.CHECK_IN.${record.origin}`)}>
                        <i
                          className={`icon-${
                            record.origin == 'pos'
                              ? 'tablet'
                              : record.origin == 'mobile-v3' || record.origin == 'mobile-v4'
                              ? 'mobile'
                              : ''
                          }`}
                          style={{ color: colors.blueLightPastel, rotate: record.origin == 'pos' ? '90deg' : '' }}
                        />
                      </Tooltip>
                    )}
                    {record?.origin_clock_out && record?.origin_clock_out && record.origin_clock_out !== 'manual' && (
                      <Tooltip overlay={t(`CLOCKINGS.ORIGINS.CHECK_OUT.${record.origin_clock_out}`)}>
                        <i
                          className={`icon-${
                            record.origin_clock_out == 'pos'
                              ? 'tablet'
                              : record.origin_clock_out == 'mobile-v3' || record.origin_clock_out == 'mobile-v4'
                              ? 'mobile'
                              : ''
                          }`}
                          style={{ color: colors.red, rotate: record.origin == 'pos' ? '90deg' : '' }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              );
            }
            case 'shift': {
              if (!isFeatureEnabled(features, FEATURES.CLOCKING)) {
                return null;
              }
              return (
                <div className="column-icon">
                  {record.homeworking && <i className="icon-home"></i>}
                  <Button
                    style={{
                      padding: 0,
                      width: 18.2,
                      height: 20.4,
                    }}
                    type="text"
                    onClick={() => {
                      const { id, userRecordId, start, end, skills = [], attributes } = record;
                      setClockingModalParams({
                        shiftId: id,
                        userRecordId,
                        start,
                        end,
                        skillIds: skills?.map((skill: ISkill) => skill.id),
                        attributeIds: attributes?.map((attribut: IAttribut) => attribut.id),
                        sectionId: record?.section?.id,
                      });
                    }}
                  >
                    <PlusCircleOutlined style={{ color: colors.green }} />
                  </Button>
                </div>
              );
            }
            default:
              return null;
          }
        },
      },
      {
        title: t('GLOBAL.DATE'),
        key: 'date',
        width: 110,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          if (['shift', 'dayoff', 'clocking', 'free-clocking'].includes(record.type)) {
            return <span style={styles[record.type as string]}>{moment.unix(record.start).format('L')}</span>;
          }
        },
      },
      {
        title: t('GLOBAL.SHIFT'),
        key: 'shift',
        width: 120,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          if (['shift', 'dayoff', 'clocking', 'free-clocking'].includes(record.type)) {
            return (
              <span style={styles[record.type as string]}>{`${moment.unix(record.start).format('HH:mm')} - ${
                record.end ? moment.unix(record.end).format('HH:mm') : '__:__'
              }`}</span>
            );
          } else if (['summary-planned', 'summary-clocking', 'summary-dayoff']) {
            return <span style={styles[record.type as string]}>{record.totalItems}</span>;
          }
        },
      },
      {
        title: (
          <Tooltip overlay={<span>{t('TIMESHEETS.COLUMNS.TS')}</span>}>
            {t('REPORTS.CONTROL_OF_SERVICES.TOTAL_TIME_CODE')}
          </Tooltip>
        ),
        key: 'totalTime',
        width: 120,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          if (
            [
              'shift',
              'dayoff',
              'clocking',
              'free-clocking',
              'summary-planned',
              'summary-clocking',
              'summary-dayoff',
            ].includes(record.type)
          ) {
            return <span style={styles[record.type as string]}>{minutesToHoursAndOrMinutes(record.totalTime)}</span>;
          }
        },
      },
      {
        title: (
          <Tooltip overlay={<span>{t('TIMESHEETS.COLUMNS.TP')}</span>}>
            {t('REPORTS.CONTROL_OF_SERVICES.TOTAL_BREAK_CODE')}
          </Tooltip>
        ),
        key: 'totalPauses',
        width: 120,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          const { type, totalPauses, dayoff } = record;
          if (['shift', 'clocking', 'free-clocking'].includes(type)) {
            const { paid, unpaid } = totalPauses;
            return (
              <div className="column-pause">
                {!!paid && (
                  <span style={styles[type as string]}>
                    <i className="paid-pause-icon">P</i>
                    {minutesToHoursAndOrMinutes(paid)}
                  </span>
                )}
                {!!unpaid && <span style={styles[type as string]}>{minutesToHoursAndOrMinutes(unpaid)}</span>}
                {!paid && !unpaid && <span style={styles[type as string]}>0m</span>}
              </div>
            );
          } else if (type === 'dayoff') {
            if (dayoff?.paid) {
              return (
                <div className="column-pause">
                  <span style={styles[type as string]}>
                    <i className="paid-pause-icon">P</i>
                  </span>
                </div>
              );
            }
          } else if (['summary-planned', 'summary-clocking', 'summary-dayoff'].includes(type)) {
            return (
              <div className="column-pause">
                <span style={styles[type as string]}>{minutesToHoursAndOrMinutes(totalPauses)}</span>
              </div>
            );
          }
        },
      },
      {
        title: (
          <Tooltip overlay={<span>{t('TIMESHEETS.COLUMNS.TR')}</span>}>
            {t('REPORTS.CONTROL_OF_SERVICES.TOTAL_WORK_CODE')}
          </Tooltip>
        ),
        key: 'totalWork',
        width: 120,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          const { type } = record;
          if (type == 'summary-dayoff') {
            return <span style={styles[type as string]}>{minutesToHoursAndOrMinutes(record.totalTime)}</span>;
          }
          return <span style={styles[type as string]}>{minutesToHoursAndOrMinutes(record.totalWork)}</span>;
        },
      },
      {
        title: t('GLOBAL.COST'),
        key: 'price',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          if (
            [
              'shift',
              'dayoff',
              'clocking',
              'free-clocking',
              'summary-planned',
              'summary-clocking',
              'summary-dayoff',
            ].includes(record.type)
          ) {
            return (
              <span style={styles[record.type as string]}>
                {new Intl.NumberFormat(i18n.language, {
                  style: 'currency',
                  currency: activeDepartment?.currency || 'EUR',
                }).format(record.price)}
              </span>
            );
          }
        },
      },
      {
        title: t('GLOBAL.HR_CODE_SHORT'),
        key: 'hr_code',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          if (record.hr_code_custom) {
            const found = customHrCodes.find((code) => code.id == record.hr_code_custom);
            if (found) {
              return <span>{found.name}</span>;
            }
          }
          return <span>{record.hr_code}</span>;
        },
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const dataSourceExplode = [];
    for (let i = 0; i < dataSource.length; i++) {
      const element = dataSource[i];
      const children = element?.children;
      dataSourceExplode.push(element);
      if (children?.length) {
        for (let j = 0; j < children.length; j++) {
          const child = children[j];
          dataSourceExplode.push(child);
        }
        delete element.children;
      }
    }
    setDataSourceExplode(dataSourceExplode);
  }, [dataSource]);

  const onRow = (row: any) => {
    if (['clocking', 'free-clocking'].includes(row.type)) {
      return {
        onClick: () => {
          setPreviousOffset((staticGrid?.current as any).state.scrollTop);
          setActiveClockingId(row.id);
        },
      };
    } else {
      return {};
    }
  };

  useEffect(() => {
    setColumns([
      ...defaultColumns.filter((column) => {
        if (column.key == 'status') {
          return false;
        }
        return !hiddenColumns?.includes(column.key);
      }),
      ...extraColumns
        .filter((x) => x.dataIndex !== 'hr_code')
        ?.map((extraColumn) => {
          const { name, dataIndex, type } = extraColumn;
          return {
            title: name,
            key: dataIndex,
            width: 100,
            // eslint-disable-next-line react/display-name
            render: (text: any, record: any) => {
              if (record?.extraColumns) {
                switch (type) {
                  case 'minutes': {
                    return (
                      <span style={styles[record.type as string]}>
                        {minutesToHoursAndOrMinutes(record?.extraColumns[dataIndex] || 0)}
                      </span>
                    );
                  }
                  case 'currency': {
                    return (
                      <span style={styles[record.type as string]}>
                        {new Intl.NumberFormat(i18n.language, {
                          style: 'currency',
                          currency: activeDepartment?.currency || 'EUR',
                        }).format(record?.extraColumns[dataIndex] || 0)}
                      </span>
                    );
                  }
                  default: {
                    return <span style={styles[record.type as string]}>{record?.extraColumns[dataIndex]}</span>;
                  }
                }
              }
            },
          };
        }),
    ]);
  }, [defaultColumns, extraColumns, hiddenColumns]);

  const onClockingSave = () => {
    headerForm.submit();
    setActiveClockingId(null);
  };

  useEffect(() => {
    (staticGrid?.current as any)?.scrollTo({ scrollLeft: 0, scrollTop: previousOffset });
  }, [dataSourceExplode]);

  const styles: { [key: string]: any } = {
    shift: {
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    dayoff: {
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    clocking: {
      color: 'cornflowerblue',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    ['free-clocking']: {
      color: !isFeatureEnabled(features, FEATURES.SCHEDULE) ? 'black' : colors.red,
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    ['summary-planned']: {
      fontWeight: 'bold',
      color: '#000000',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    ['summary-dayoff']: {
      fontWeight: 'bold',
      color: '#000000',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    ['summary-clocking']: {
      fontWeight: 'bold',
      color: !isFeatureEnabled(features, FEATURES.SCHEDULE) ? 'black' : colors.red,
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  };

  return (
    <div className={className}>
      {(dataSource?.length === 0 || loadingDataSource) && <Table loading={loadingDataSource} columns={[]}></Table>}
      {!loadingDataSource && dataSource?.length > 0 && (
        <VirtualTable
          dataSource={dataSourceExplode}
          columns={columns?.filter((column) => !hideColumns[column.key])}
          rowKey="id"
          pagination={false}
          defaultExpandAllRows={true}
          rowClassName={(record: any) =>
            `${record.type} ${
              isFeatureEnabled(features, FEATURES.SCHEDULE) &&
              isFeatureEnabled(features, FEATURES.CLOCKING) &&
              record.type == 'shift' &&
              moment.unix(record.end).isBefore(moment()) &&
              record.children &&
              record.children.length == 0
                ? 'shift-without-clocking'
                : ''
            }`
          }
          scroll={{
            x: columns.reduce((a, b) => a + b, 0),
            y: windowHeight - 50 - headerHeight - 100,
          }}
          expandIconColumnIndex={-1}
          indentSize={0}
          onRow={onRow}
          staticGridRef={staticGrid}
          setPreviousOffset={setPreviousOffset}
          previousOffset={previousOffset}
        />
      )}

      <DrawerClocking
        clockingId={activeClockingId}
        visible={!!activeClockingId}
        onClose={() => {
          setActiveClockingId(null);
        }}
        setActiveClockingId={setActiveClockingId}
        dataSourceExplode={dataSourceExplode}
        setDataSourceExplode={setDataSourceExplode}
        updateDataSource={() => {
          setCounter(moment().unix());
        }}
        onSave={onClockingSave}
        staticGridRef={staticGrid}
      />
    </div>
  );
};

export default styled(React.memo(AppReportTimesheetsDefaultView))`
  .ant-table-fixed {
    table-layout: fixed;
  }

  .ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-all;
  }

  .virtual-grid {
    .virtual-table-cell {
      display: flex;
      padding: 5px 16px;
      align-items: center;
      &.summary-planned,
      &.summary-clocking,
      &.summary-dayoff {
        background: ${colors.blueLight};
        td {
          background: ${colors.blueLight};
        }
      }
      &.clocking,
      &.free-clocking {
        cursor: pointer;
      }
      &.dayoff {
        background-color: ${colors.greenExtraLight};
      }
    }
  }

  .column-icon  {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .column-pause {
    display: flex;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      .paid-pause-icon {
        width: 14px;
        height: 14px;
        display: flex;
        border-radius: 7px;
        background: #c4c4c4;
        color: white;
        font-size: 10px;
        margin-right: 3px;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-style: normal;
      }
    }
  }

  .shift-without-clocking {
    background-color: ${colors.redExtraLight};
  }
`;
