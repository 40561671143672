import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import { ITask } from '@/types/task.model';
import { difference, getWindowSize } from '@/utils';
import { Button, Drawer, Form, Input, InputNumber, Switch, notification } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomColorPicker from '../CustomColorPicker';

interface Props {
  className?: string;
  visible: boolean;
  task?: ITask | null;
  onClose: () => void;
  onSave: (result: ITask) => void;
}

const DrawerTask: React.FC<Props> = ({ className, visible, task, onClose, onSave }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  const initialValues: any = {
    background: '#03a9f4',
  };

  useEffect(() => {
    form.resetFields();
    let fieldsValue: any = {
      ...initialValues,
    };

    if (task) {
      fieldsValue = {
        ...fieldsValue,
        ..._(task).pick(['name', 'background', 'shortCode', 'minutes', 'day_subtract']).value(),
      };

      if (!task.background) {
        fieldsValue.background = '#FFFFFF';
      }
    }
    form.setFieldsValue(fieldsValue);
    // eslint-disable-next-line
  }, [task]);

  const onFinish = async (values: any) => {
    setIsSaving(true);
    let taskResponse;

    try {
      if (task && !_.isEmpty(task)) {
        const differences = difference(values, task);
        if (!_.isEmpty(differences)) {
          taskResponse = await axios.patch(`${process.env.REACT_APP_API_URL}/v3/tasks/${task.id!}`, {
            ...differences,
          });
        }
      } else {
        taskResponse = await axios.post(`${process.env.REACT_APP_API_URL}/v3/tasks`, {
          departmentId: activeDepartmentId,
          ...values,
        });
      }

      if (taskResponse) {
        onSave({
          ...taskResponse?.data.task,
        });
        onClose();
      } else {
        onClose();
      }
    } catch (error) {
      console.error(error);
      const description = task?.id ? t('TASKS.TASK_UPDATE_FAILED') : t('TASKS.TASK_CREATION_FAILED');
      notification.open({
        message: 'Une erreur est survenue',
        description,
        type: 'error',
      });
    }
    setIsSaving(false);
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={task?.id ? t('TASKS.TASK_MODIFICATION') : t('TASKS.ADDING_TASK')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 400 : '75%'}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
          <Input placeholder={t('TASKS.TASK_NAME_PLACEHOLDER')} id="task-name" />
        </Form.Item>
        <Form.Item name="shortCode" label={t('GLOBAL.CODE')} rules={[{ max: 3 }]}>
          <Input placeholder={t('TASKS.TASK_CODE_PLACEHOLDER')} />
        </Form.Item>
        <Form.Item label={t('GLOBAL.DEFAULT_DURATION')} name="minutes">
          <InputNumber type="number" min={1} step={1} precision={0} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label={t('TASKS.DAY_SUBTRACT')} name="day_subtract" valuePropName="checked" className="row">
          <Switch />
        </Form.Item>
        <Form.Item
          label={t('GLOBAL.COLOR')}
          shouldUpdate={(prevValues, curValues) => prevValues.background !== curValues.background}
        >
          {() => {
            const background = form.getFieldValue('background');
            return (
              <React.Fragment>
                <Form.Item name="background" style={{ display: 'none' }}>
                  <Input hidden id="task-background" />
                </Form.Item>
                <CustomColorPicker form={form} color={background} property="background" />
              </React.Fragment>
            );
          }}
        </Form.Item>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default styled(DrawerTask)`
  .row {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .ant-form-item-label {
      flex: 1;
      padding: 0;
      label {
        padding: 0;
      }
    }

    .ant-form-item-control {
      flex: unset;
    }
  }
`;
