import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  interval: string[];
}

const OperationalInterval: React.FC<Props> = ({ className, interval }) => {
  const start = interval[0];

  return (
    <div className={className}>
      <span>{start}</span>
      {/* <span className="semi">{end}</span> */}
    </div>
  );
};

export default styled(OperationalInterval)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  text-align: center;
  max-width: 55px;
  flex: 1;
  min-height: 40px;

  .semi {
    font-size: 11px;
    color: #bbb;
  }
`;
