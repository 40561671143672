import React, { Dispatch, useContext } from 'react';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Menu } from 'antd';
import { InitialStateType, IShiftsByUser, PickerType } from '@/pages/app/hours/manage/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType } from '../redux/actions';
import colors from '@/styles/colors';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';

interface Props {
  picker: PickerType;
  shiftsByUser: IShiftsByUser;
  close: () => void;
}

const UserShiftRightClickMenu: React.FC<Props> = ({ shiftsByUser, picker, close }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const reduxDispatch: Dispatch<ActionType> = useDispatch();
  const userRecordId = shiftsByUser?.user?.recordId;
  const shiftsToPublish = shiftsByUser?.shifts.filter((shift) => !shift.approved) || [];
  const { selected_shifts_on_shift_key } = useSelector(({ selected_shifts_on_shift_key }: InitialStateType) => ({
    selected_shifts_on_shift_key,
  }));
  const {
    state: { activeDepartment },
  } = useContext(AppContext);

  // Handlers
  const handleApproveShifts = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    reduxDispatch({ type: 'SET_SHIFT_IDS_TO_PUBLISH', payload: shiftsToPublish.map((shift) => shift.id!) });
    close();
  };

  const handleTransferShifts = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    reduxDispatch({
      type: 'SET_SHIFTS_TO_TRANSFER',
      payload: {
        ids: shiftsByUser.shifts.map((shift) => shift.id!),
        userRecordId: shiftsByUser.user.recordId!,
      },
    });
    close();
  };

  const handleSelectShifts = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    let shifts = shiftsByUser.shifts;
    if (!['ADMIN', 'SUPER-ADMIN'].includes(activeDepartment!.role!)) {
      shifts = shifts.filter((shift) => shift.locked == false);
    }
    if (picker !== 'month') {
      reduxDispatch({
        type: 'ADD_SELECTED_SHIFTS',
        payload: shifts.map((shift) => shift.id!),
      });
      close();
    } else {
      const res = shifts.filter((shift) => shift.shyftType !== 3);
      if (res.length > 0) {
        if (selected_shifts_on_shift_key) {
          reduxDispatch({
            type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
            payload: [...selected_shifts_on_shift_key, ...res],
          });
        } else {
          reduxDispatch({
            type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
            payload: res,
          });
        }
      }
      close();
    }
  };

  const handleDeleteShifts = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    reduxDispatch({
      type: 'SET_SHIFT_IDS_TO_DELETE',
      payload: shiftsByUser.shifts.map((shift) => shift.id!),
    });
    close();
  };

  return (
    <Menu>
      {shiftsToPublish.length > 0 && (
        <Menu.Item
          key={`user_${userRecordId}_rightClick_0`}
          onClick={handleApproveShifts}
          style={{ color: colors.green }}
        >
          <i className="icon-ok-circled"></i> {t('SHIFTS.APPROVE_SHIFTS')}
        </Menu.Item>
      )}
      {picker !== 'month' && (
        <Menu.Item
          disabled={shiftsByUser?.shifts?.findIndex((shift) => shift.shyftType !== 3 && !shift.dayoff) < 0}
          key={`user_${userRecordId}_rightClick_1`}
          onClick={handleTransferShifts}
        >
          <i className="icon-shuffle"></i> {t('SHIFTS.ASSIGN_TO_COLLABORATOR')}
        </Menu.Item>
      )}
      {picker !== 'day' && (
        <Menu.Item
          disabled={!shiftsByUser?.shifts.length}
          key={`user_${userRecordId}_rightClick_2`}
          onClick={handleSelectShifts}
        >
          <i className="icon-check"></i> {t('SHIFTS.SELECT_USER_SHIFTS')}
        </Menu.Item>
      )}
      <Menu.Item
        disabled={!shiftsByUser?.shifts.length}
        key={`user_${userRecordId}_rightClick_3`}
        onClick={handleDeleteShifts}
      >
        <i className="icon-delete" style={{ color: colors.red, marginRight: 5 }} />
        {t('SCHEDULE.REMOVE_SHIFT(S)')}
      </Menu.Item>
    </Menu>
  );
};

export default UserShiftRightClickMenu;
