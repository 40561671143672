import DrawerPayPeriod from '@/pages/app/components/drawers/PayPeriod';
import AppContext from '@/pages/app/context';
import { IPayPeriod } from '@/types/pay-periods/pay-period.model';
import { handleError } from '@/utils';
import { Divider, Spin } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header';
import ModalDeletePayPeriod from './components/ModalDeletePayPeriod';
import PeriodExtra from './components/PeriodExtra';
import PeriodHeader from './components/PeriodHeader';

interface Props {
  className?: string;
}

const AppReportsPayPeriods: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, payPeriods, loadingPayPeriods },
    dispatch,
  } = useContext(AppContext);
  const [activePayPeriod, setActivePayPeriod] = useState<IPayPeriod | null>(null);
  const [payPeriodToDelete, setPayPeriodToDelete] = useState<IPayPeriod | null>(null);
  const [modalDeletePayPeriodVisible, setModalDeletPayPeriodVisible] = useState<boolean>(false);
  const [drawerPayPeriodVisible, setDrawerPayPeriodVisible] = useState<boolean>(false);
  const history = useHistory();

  const upcomingPeriods = payPeriods.filter((period) => moment().isSameOrBefore(moment(period.end)));
  const pastPeriods = payPeriods.filter((period) => moment().isAfter(moment(period.end)));

  const getPayPeriods = () => {
    dispatch({
      type: 'SET_LOADING_PAY_PERIODS',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/pay-periods`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_PAY_PERIODS',
          payload: data,
        });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        dispatch({
          type: 'SET_LOADING_PAY_PERIODS',
          payload: false,
        });
      });
  };

  const onPeriodClick = (period: IPayPeriod) => {
    history.push(`/app/reports/pay-periods/${period.id}`);
  };

  return (
    <div className={className}>
      <Header setDrawerPayPeriodVisible={setDrawerPayPeriodVisible} />
      <Divider />
      <div className="content">
        {loadingPayPeriods ? (
          <Spin spinning={loadingPayPeriods} />
        ) : (
          <>
            <h4>{t('REPORTS.PAY_PERIODS.UPCOMING_PERIODS')}</h4>
            {upcomingPeriods.map((period) => (
              <div className="period" onClick={() => onPeriodClick(period)}>
                <PeriodHeader period={period} />
                <PeriodExtra
                  period={period}
                  setActivePayPeriod={setActivePayPeriod}
                  setPayPeriodToDelete={setPayPeriodToDelete}
                  setModalDeletPayPeriodVisible={setModalDeletPayPeriodVisible}
                />
              </div>
            ))}
          </>
        )}
        <Divider />
        {loadingPayPeriods ? (
          <Spin spinning={loadingPayPeriods} />
        ) : (
          <>
            <h4>{t('REPORTS.PAY_PERIODS.PAST_PERIODS')}</h4>
            {pastPeriods.map((period) => (
              <div className="period">
                <PeriodHeader period={period} />
                <PeriodExtra period={period} />
              </div>
            ))}
          </>
        )}
      </div>
      <DrawerPayPeriod
        activePayPeriod={activePayPeriod}
        visible={drawerPayPeriodVisible || activePayPeriod !== null}
        setActivePayPeriod={setActivePayPeriod}
        setVisible={setDrawerPayPeriodVisible}
        getPayPeriods={getPayPeriods}
      />
      <ModalDeletePayPeriod
        period={payPeriodToDelete}
        visible={modalDeletePayPeriodVisible}
        setVisible={setModalDeletPayPeriodVisible}
        setPayPeriodToDelete={setPayPeriodToDelete}
        getPayPeriods={getPayPeriods}
      />
    </div>
  );
};

export default styled(AppReportsPayPeriods)`
  padding: 25px;

  .content {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .period {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5f5f5;
      padding: 15px;
      border-radius: 10px;
      cursor: pointer;
    }
  }
`;
