import React, { useState } from 'react';
import { Dropdown } from 'antd';
import UserShiftRightClickMenu from './UserShiftRightClickMenu';
import { IShiftsByUser, PickerType } from '../redux/store';

interface Props {
  children?: any;
  shiftsByUser: IShiftsByUser;
  picker: PickerType;
}
const UserShiftRightClick: React.FC<Props> = ({ children, shiftsByUser, picker }) => {
  const [rightClickMenuVisible, setRightClickMenuVisible] = useState<boolean>(false);

  return (
    <Dropdown
      getPopupContainer={(trigger) => {
        return trigger.parentNode?.parentNode as HTMLElement;
      }}
      overlay={() => (
        <UserShiftRightClickMenu
          shiftsByUser={shiftsByUser}
          picker={picker}
          close={() => {
            setRightClickMenuVisible(false);
          }}
        />
      )}
      trigger={['contextMenu']}
      visible={rightClickMenuVisible}
      onVisibleChange={(e) => {
        setRightClickMenuVisible(e);
      }}
    >
      {children}
    </Dropdown>
  );
};

export default UserShiftRightClick;
