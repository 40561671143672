import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { ColoredShiftsTypes } from '@/types/department-settings.model';
import { IShift } from '@/types/shift.model';
import { IUser } from '@/types/user.model';
import { getContrast } from '@/utils';
import moment from 'moment';
import React, { Dispatch, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ShiftAttributesStyled from '../../components/ShiftAttributes';
import ShiftDetailsIconsStyled from '../../components/ShiftDetailsIcons';
import ShiftPeriod from '../../components/ShiftPeriod';
import ShiftSpecialHrCodeStyled from '../../components/ShiftSpecialHrCode';
import ShiftTagsStyled from '../../components/ShiftTags';
import TopIcons from '../../components/TopIcons';
import TopIconsDayoff from '../../components/TopIconsDayoff';
import { ActionType } from '../../redux/actions';
import { IShiftsByUser, InitialStateType } from '../../redux/store';

interface Props {
  shift: IShift;
  intervalHeight: number;
  index: number;
  userShifts: IShiftsByUser;
  contentRef: React.MutableRefObject<any>;
  allShifts?: {
    shifts: IShift[];
    user: IUser;
    totalPrice?: number | undefined;
    totalMinutes?: number | undefined;
    hasOnlyAbsence?: boolean | undefined;
  }[];
  intervals: string[][];
}

const OpsShift: React.FC<Props> = ({ shift, intervalHeight, index, userShifts, contentRef, allShifts, intervals }) => {
  const { picker, selectedDate, filteredShiftsByUsers, usersMap, showPrices } = useSelector(
    ({ picker, selectedDate, filteredShiftsByUsers, showPrices, usersMap }: InitialStateType) => ({
      picker,
      selectedDate,
      filteredShiftsByUsers,
      usersMap,
      showPrices,
    }),
  );
  const {
    state: { activeDepartment, activeSection },
  } = useContext(AppContext);
  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const history = useHistory();

  const coloredShifts = activeDepartment?.scheduleParams?.colored_shifts;
  const startOfDay = selectedDate ? selectedDate.unix() : moment().startOf('day').unix();

  let style: any = {};
  if (coloredShifts && shift.userRecordId) {
    if (coloredShifts == ColoredShiftsTypes.SKILLS && shift.skills && shift.skills[0].background) {
      style.background = shift.skills[0].background;
    }
    if (coloredShifts == ColoredShiftsTypes.ATTRIBUTES && shift.attributes && shift.attributes[0].background) {
      style.background = shift.attributes[0].background;
    }
    if (coloredShifts == ColoredShiftsTypes.STATUS) {
      const user = usersMap.get(shift.userRecordId);
      if (user && user.userType && user.userType.background) {
        style.background = user.userType.background;
        if (shift.dayoff)
          style.backgroundImage = `repeating-linear-gradient(-55deg, transparent 0 10px, rgba(0,0,0,0.03) 10px 20px)`;
      }
    }
  } else {
    style.background = '#D1DFF099';
  }

  const hourNormal = (shift.start! - startOfDay) / 60 / 60;
  const hour =
    intervals?.findIndex((interval) => Number(interval[0].split(':')[0]) == Math.floor(hourNormal)) || hourNormal;
  const multiplier = Math.floor(hour);
  const offset = intervalHeight * multiplier + (hour % 9);
  const duration = moment.duration(shift.end! - shift.start!, 'seconds');
  duration.subtract(shift.pause!.unpaid!, 'seconds');
  const durationInMinutes = duration.asMinutes();
  const pauseDurationInMinutes = moment.duration(shift.pause!.paid! + shift.pause!.unpaid!, 'seconds').asMinutes();
  const hr_code_id =
    activeDepartment?.hr_codes && activeDepartment?.hr_codes.find((code) => code.id === shift.hr_code)?.code;
  const height = intervalHeight * (moment.unix(shift.end!).diff(moment.unix(shift.start!), 'minutes') / 60);
  const overflowingAfter = contentRef && contentRef.current && contentRef.current.clientHeight < height + offset;
  const overflowingBefore = offset < 0;
  const clientWidth = contentRef && contentRef.current && contentRef.current.clientWidth - 40;
  const allShiftsWidth = allShifts ? allShifts.length * 105 + 25 : clientWidth;

  const onClick = (shift: IShift) => {
    if (!shift.locked) {
      hoursDispatch({
        type: 'SET_SELECTED_SHIFT',
        payload: shift,
      });
      hoursDispatch({
        type: 'SET_SHIFT_DRAWER_VISIBLE',
        payload: true,
      });
    }
  };

  const onOverflowAfterClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const date = moment(selectedDate).clone().add(1, 'day');

    hoursDispatch({
      type: 'SET_PICKER_AND_SELECTED_DATE',
      payload: {
        picker: picker,
        selectedDate: date.valueOf(),
        startDate: date.startOf('day').valueOf(),
        endDate: date.endOf('day').valueOf(),
      },
    });

    const body = document.getElementById('app-hours-container');
    if (body) {
      body.scrollTo({ top: 0, behavior: 'smooth' });
    }
    history.push(`/app/hours/manage/${picker}/${date.format('YYYY-MM-DD')}`);
  };

  const onOverflowBeforeClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const date = moment(selectedDate).clone().subtract(1, 'day');

    hoursDispatch({
      type: 'SET_PICKER_AND_SELECTED_DATE',
      payload: {
        picker: picker,
        selectedDate: date.valueOf(),
        startDate: date.startOf('day').valueOf(),
        endDate: date.endOf('day').valueOf(),
      },
    });

    const body = document.getElementById('app-hours-container');
    if (body) {
      body.scrollTo({ top: 0, behavior: 'smooth' });
    }
    history.push(`/app/hours/manage/${picker}/${date.format('YYYY-MM-DD')}`);
  };

  return (
    <div
      className="shift scrollbar-hidden"
      style={{
        top: overflowingBefore ? 0 : offset,
        left: allShifts
          ? allShiftsWidth < clientWidth
            ? (clientWidth / allShifts.length - 10) * index + 25
            : 105 * index + 25
          : 105 * index + 25,
        height: overflowingAfter
          ? contentRef.current.clientHeight - offset
          : overflowingBefore
          ? moment.unix(shift.end!).hours() * intervalHeight
          : height,
        border: !shift.approved ? `2px dashed ${colors.green}` : `1px solid ${shift.dayoff ? '#efefef' : '#efefef'}`,
        borderRadius: overflowingAfter ? '7px 7px 0 0' : overflowingBefore ? '0 0 7px 7px' : 7,
        display: 'flex',
        flexDirection: offset < 0 ? 'column-reverse' : 'column',
        justifyContent: 'space-between',
        cursor: 'pointer',
        overflow: 'scroll',
        color: getContrast(style.background!),
        width: allShifts ? (allShiftsWidth < clientWidth ? clientWidth / allShifts.length - 15 : 100) : 100,
        minWidth: 100,
        ...style,
      }}
      onClick={() => onClick(shift)}
    >
      <div
        className="top"
        style={{
          display: 'flex',
          flexDirection: offset < 0 ? 'column-reverse' : 'column',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            fontWeight: 'bold',
            backgroundColor: coloredShifts ? style.background : '#D1DFF0',
            minHeight: 45,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px 2px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {userShifts.user.displayName}
        </span>
        {shift.dayoff ? <span style={{ fontSize: 24, fontWeight: 'bold' }}>{shift.dayoff.shortCode}</span> : null}
        {!shift.dayoff && <TopIcons position="relative" shift={shift} department={activeDepartment!} />}
        {shift.dayoff && <TopIconsDayoff position="relative" shift={shift} />}
        <div className="period">
          <ShiftPeriod start={shift.start!} end={shift.end!} bold backgroundColor={style.background} />
        </div>
        <div className="details">
          <ShiftDetailsIconsStyled
            hidePrice={showPrices || !activeDepartment?.scheduleParams?.showShiftPrice}
            currency={activeDepartment?.currency}
            durationInMinutes={durationInMinutes}
            pauseDurationInMinutes={pauseDurationInMinutes}
            price={shift.price!}
            clockings={shift.clocking}
            backgroundColor={style.background}
          />
        </div>
        <div className="tags">
          <ShiftTagsStyled
            picker={picker}
            section={activeSection !== shift.section?.id ? shift.section : undefined}
            skills={shift.skills || []}
            backgroundColor={style.background}
            coloredShifts={coloredShifts}
          />
        </div>
        <ShiftSpecialHrCodeStyled hr_code_id={hr_code_id} />
        <div className="tags">
          <ShiftAttributesStyled attributes={shift.attributes || []} backgroundColor={style.background} />
        </div>
      </div>
      <div
        className="strip"
        style={{
          backgroundColor: coloredShifts ? style.background : '#D1DFF0',
          height: overflowingAfter || overflowingBefore ? 25 : 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {overflowingAfter && (
          <div className="bottom" style={{ transform: 'rotate(180deg)' }} onClick={onOverflowAfterClick}>
            <i className="icon-up-big" />
          </div>
        )}
        {overflowingBefore && (
          <div className="bottom" style={{ padding: '10px 0' }} onClick={onOverflowBeforeClick}>
            <i className="icon-up-big" />
          </div>
        )}
      </div>
    </div>
  );
};

export default OpsShift;
