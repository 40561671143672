import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { handleError } from '@/utils';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CalloutStyled from '../../components/Callout';
import SettingsForm from '../../components/SettingsForm';
import ModalNotification from './components/ModalNotification';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const ScheduleNotifications: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalNotificationVisible, setModalNotificationVisible] = useState<boolean>(false);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const showModalNotification = () => {
    setModalNotificationVisible(true);
  };

  const hideModalNotification = () => {
    setModalNotificationVisible(false);
  };

  const deleteNotification = (index: number) => {
    const alerts = [...(currentSettings?.alerts || [])];
    alerts.splice(index, 1);
    updateCurrentSettings({
      alerts,
    });
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.SCHEDULES.NOTIFICATIONS.MANAGE_SHIFTS_ALERTS.LABEL'),
          description: t('SETTINGS.SCHEDULES.NOTIFICATIONS.MANAGE_SHIFTS_ALERTS.DESCRIPTION'),
          style: { margin: 0 },
          element: (
            <>
              <Button
                type="dashed"
                style={{ marginTop: 15, marginBottom: 15, marginRight: 15 }}
                onClick={showModalNotification}
              >
                {t('SETTINGS.ACCOUNT.NOTIFICATIONS.ADD_SHIFT_ALERT')}
              </Button>
            </>
          ),
          elementBelow: true,
          show: true,
          showCallout: true,
          callout: (
            <>
              {!!currentSettings?.alerts?.length && (
                <CalloutStyled>
                  <ul className="notifications">
                    {currentSettings?.alerts?.map((alert, index: number) => (
                      <li key={index}>
                        <CloseOutlined
                          onClick={() => {
                            deleteNotification(index);
                          }}
                        />
                        {t('GLOBAL.ALERT')} {alert.value}{' '}
                        {alert.unit === 'day'
                          ? t('SETTINGS.ACCOUNT.NOTIFICATIONS.DAY(S)_BEFORE')
                          : t('SETTINGS.ACCOUNT.NOTIFICATIONS.HOUR(S)_BEFORE')}
                      </li>
                    ))}
                  </ul>
                </CalloutStyled>
              )}
            </>
          ),
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    form.setFieldsValue(currentSettings);
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings(values)
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.SCHEDULES.NOTIFICATIONS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
      <ModalNotification
        visible={modalNotificationVisible}
        onCancel={hideModalNotification}
        currentSettings={currentSettings}
        updateCurrentSettings={updateCurrentSettings}
      />
    </div>
  );
};

export default styled(ScheduleNotifications)`
  .notifications {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li:nth-of-type(n + 2) {
      padding-top: 5px;
    }

    .anticon-close {
      background: ${colors.red};
      padding: 1px;
      border-radius: 10px;
      margin-right: 5px;
      svg {
        transform: scale(0.8);
        fill: white;
      }
    }
  }
`;
