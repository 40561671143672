import { IShiftRequest } from '@/types/shift-request.model';
import { IUser } from '@/types/user.model';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import ShiftRequestsTable from '@/pages/app/hours/shift-modification-requests/components/ShiftRequestsTable';

interface Props {
  user?: IUser;
  departmentId?: string;
}

const ShiftRequests: React.FC<Props> = ({ user, departmentId }) => {
  const [shiftRequests, setShiftRequests] = useState<IShiftRequest[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    setShiftRequests([]);
    setIsLoading(true);
    if (departmentId && user) {
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/shift-requests`, {
        params: {
          departmentId,
          userRecordId: user?.recordId,
        },
      })
        .then((response) => {
          setIsLoading(false);
          if (mounted) {
            const requests = response.data?.requests || [];
            setShiftRequests(requests);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });

      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [departmentId, user?.recordId]);

  return (
    <div className="card-shadow">
      <ShiftRequestsTable loading={isLoading} dataSource={shiftRequests} />
    </div>
  );
};

export default ShiftRequests;
