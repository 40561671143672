import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.fr';
import 'rc-year-calendar/locales/rc-year-calendar.nl';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import ModalRangeSelection from './ModalRangeSelection';
import { IUser } from '@/types/user.model';
import Axios from 'axios';
import { Spin } from 'antd';
import colors from '@/styles/colors';
moment.tz.setDefault('Atlantic/Reykjavik');

export interface IPeriod {
  id: string;
  startDate: number;
  endDate: number;
  type: string;
  dayoffType?: string;
  dayoffName?: string;
  fullDay: boolean;
}

interface Props {
  user?: IUser;
  className?: string;
  departmentId?: string;
}

const Availability: React.FC<Props> = ({ className, departmentId, user }) => {
  const { i18n } = useTranslation(undefined, { useSuspense: false });
  const [rangeSelection, setRangeSelection] = useState<{ startDate: Moment; endDate: Moment; events: any[] } | null>(
    null,
  );
  const [periods, setPeriods] = useState<IPeriod[]>([]);
  const [loadingPeriods, setLoadingPeriods] = useState<boolean>();
  const [currentDate, setCurrentDate] = useState<{ year: string }>({ year: moment().format('YYYY') });
  const [timestamp, setTimestamp] = useState<number>(moment().unix());

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    if (departmentId) {
      let mounted = true;
      setPeriods([]);
      setLoadingPeriods(true);
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/availability`, {
        params: {
          departmentId,
          year: currentDate.year,
        },
        cancelToken: cancelTokenSource.token,
      })
        .then(({ data }) => {
          if (mounted) {
            setPeriods(data.periods);
            setLoadingPeriods(false);
          }
        })
        .catch((error) => {
          if (Axios.isCancel(error)) {
            console.error(error);
          }
          if (mounted) {
            setLoadingPeriods(false);
          }
        });
      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [currentDate, timestamp, user]);

  return (
    <div className={`${className} card-shadow`}>
      <Spin spinning={loadingPeriods}>
        <Calendar
          language={i18n.language}
          enableRangeSelection={true}
          displayWeekNumber={true}
          style="background"
          onDayClick={({ date, events = [] }: any) => {
            if (events.some((event: any) => event.type === 'SHIFT')) {
              return;
            }
            setRangeSelection({
              startDate: moment(date.yyyymmdd()),
              endDate: moment(date.yyyymmdd()),
              events,
            });
          }}
          onRangeSelected={({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
            if (startDate.getTime() !== endDate.getTime()) {
              setRangeSelection({
                startDate: moment(startDate.yyyymmdd()),
                endDate: moment(endDate.yyyymmdd()),
                events: [],
              });
            }
          }}
          onYearChanged={({ currentYear }: any) => {
            setCurrentDate({ year: currentYear });
          }}
          dataSource={periods?.map(({ startDate, endDate, type, fullDay, dayoffName, id }) => ({
            startDate: new Date(moment.unix(startDate).format('YYYY-MM-DDTHH:mm:ss')),
            endDate: new Date(moment.unix(endDate).format('YYYY-MM-DDTHH:mm:ss')),
            color: type === 'DAYOFF' ? colors.red : type === 'SHIFT' ? '#EFEFEF' : colors.grey,
            fullDay,
            type,
            dayoffName,
            id,
          }))}
        />
      </Spin>
      <ModalRangeSelection
        user={user}
        rangeSelection={rangeSelection}
        setRangeSelection={setRangeSelection}
        onSave={() => setTimestamp(moment().unix())}
        onClose={() => setRangeSelection(null)}
      />
    </div>
  );
};

export default styled(Availability)`
  background-color: white;

  .calendar {
    table {
      td.day-start {
        border-radius: 5px 0 0 5px;
      }
      td.day-end {
        border-radius: 0 5px 5px 0;
      }
    }
  }
`;

declare global {
  interface Date {
    yyyymmdd(): string;
  }
}

(function () {
  Date.prototype.yyyymmdd = function () {
    const mm = this.getMonth() + 1; // getMonth() is zero-based
    const dd = this.getDate();
    return [this.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('');
  };
})();
