import React, { useEffect } from 'react';
import colors from '@/styles/colors';
import { Indicator } from '@/types/indicator.model';
import { message, Spin, Tooltip } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  recordId?: string;
  departmentId?: string;
}

const Indicators: React.FC<Props> = ({ className, recordId, departmentId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [indicators, setIndicators] = useState<Indicator[]>([]);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${recordId}/indicators`, {
        params: {
          departmentId,
        },
      })
      .then(({ data }) => {
        setIndicators(data.indicators);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(t('USERS.ERROR_LOADING_INDICATORS'));
      });
  }, [departmentId, recordId]);

  return (
    <div className={`${className} indicators`}>
      {indicators?.map(({ title, value }, i) => (
        <div key={`indicator_${i}`} className="card-shadow indicator-container">
          <Tooltip title={title}>
            <h4>{title}</h4>
          </Tooltip>
          <span className="indicator">{value}</span>
        </div>
      ))}
    </div>
  );
};

export default styled(Indicators)`
  display: flex;
  margin-bottom: 20px;
  .card-shadow {
    flex: 1 1 0px;
    text-align: center;
    &:nth-of-type(n + 2) {
      margin-left: 10px;
    }
  }
  .indicator {
    margin-top: 10px;
    color: ${colors.grey};
    font-size: 24px;
    line-height: 14px;
  }

  @media screen and (max-width: 900px) {
    .indicators {
      flex-direction: column;

      .card-shadow {
        padding: 35px 0;

        &:nth-of-type(n + 2) {
          margin-left: 0;
          margin-top: 15px;
        }

        h4 {
          margin: 0;
        }
      }
    }
  }
`;
