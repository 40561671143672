import { Button, Checkbox, Form, Modal, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios, { CancelTokenSource } from 'axios';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ActionType, updateShiftsAndContextWithShiftResponse } from '../../redux/actions';
import { InitialStateType } from '../../redux/store';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
}

const ModalShiftsToReplace: React.FC<Props> = ({ className, visible }) => {
  const { t } = useTranslation();
  const dispatch: Dispatch<ActionType> = useDispatch();
  const {
    state: { activeDepartmentId, activeDepartment, activeSection, skills, userCategories },
  } = useContext(AppContext);
  const { monthly_shifts_to_replace, picker, monthly_shifts_to_replace_shift_block, startDate, endDate } = useSelector(
    ({
      monthly_shifts_to_replace,
      picker,
      monthly_shifts_to_replace_shift_block,
      startDate,
      endDate,
    }: InitialStateType) => ({
      monthly_shifts_to_replace,
      monthly_shifts_to_replace_shift_block,
      startDate,
      endDate,
      picker,
    }),
  );
  const [sameValues, setSameValues] = useState(false);
  const [autoApprove, setAutoApprove] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSameValues(false);
    setAutoApprove(false);
  }, []);

  const onOk = () => {
    setLoading(true);
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    dispatch({
      type: 'SET_LOADING_ELEMENTS',
      payload: monthly_shifts_to_replace,
    });
    const cancel_token_source: CancelTokenSource = axios.CancelToken.source();
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/shifts/month`,
        {
          shift_block_id: monthly_shifts_to_replace_shift_block,
          shifts: monthly_shifts_to_replace,
          sameValues,
        },
        {
          cancelToken: cancel_token_source.token,
          params: {
            departmentId: activeDepartmentId,
            picker: 'month',
            startDate: startDate.unix(),
            endDate: endDate.unix(),
          },
        },
      )
      .then((res) => {
        axios
          .post(`${process.env.REACT_APP_API_URL}/v3/operations/publish-shifts`, {
            shiftIds: monthly_shifts_to_replace,
            departmentId: activeDepartmentId,
            sectionId: activeSection ? activeSection : undefined,
          })
          .then(() => {
            batch(() => {
              dispatch({
                type: 'UPDATE_SHIFTS',
                payload: {
                  newShiftsDifferences: monthly_shifts_to_replace!.map((id) => ({
                    id,
                    approved: true,
                  })),
                },
              });
              dispatch({
                type: 'UPDATE_FILTERED_SHIFTS',
                payload: { department: activeDepartment },
              });
            });
          })
          .catch((error) => {
            console.error(error);
            message.error(t('SHIFTS.MODAL_PUBLISH_SHIFTS.MESSAGE_PUBLICATION_ERROR'));
          });
        updateShiftsAndContextWithShiftResponse(dispatch, res, {
          department: activeDepartment!,
          picker,
          startDate,
          endDate,
          activeSection,
          skills,
          userStatus: userCategories,
        });
        setLoading(false);
        dispatch({
          type: 'SET_LOADING',
          payload: false,
        });
        dispatch({
          type: 'SET_MONTHLY_SHIFTS_TO_REPLACE',
          payload: null,
        });
        dispatch({
          type: 'SET_LOADING_ELEMENTS',
          payload: null,
        });
        dispatch({
          type: 'SET_SELECTED_SHIFTS_ON_SHIFT_KEY',
          payload: null,
        });
        dispatch({
          type: 'SET_MONTHLY_SELECTED_ELEMENTS',
          payload: [],
        });
        dispatch({
          type: 'SET_MONTHLY_SELECTING_ELEMENTS',
          payload: false,
        });
      });
  };

  const onChange = () => {
    setSameValues(!sameValues);
  };

  const onChangeAutoApprove = () => {
    setAutoApprove(!autoApprove);
  };

  const onCancel = () => {
    setLoading(false);
    dispatch({
      type: 'SET_MONTHLY_SHIFTS_TO_REPLACE',
      payload: null,
    });
  };

  return (
    <Modal
      className={className}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onOk}>
          {t('GLOBAL.SEND')}
        </Button>,
      ]}
    >
      <p>{t('SCHEDULE.MONTHLY_VIEW.SHIFTS_TO_REPLACE_CONTENT')}</p>
      <Checkbox className="checkbox" onChange={onChange}>
        {t('SCHEDULE.MONTHLY_VIEW.SHIFTS_TO_REPLACE_CHECKBOX')}
      </Checkbox>
      <Checkbox className="checkbox" onChange={onChangeAutoApprove}>
        {t('SCHEDULE.MONTHLY_VIEW.AUTO_APPROVE')}
      </Checkbox>
    </Modal>
  );
};

export default styled(ModalShiftsToReplace)`
  .checkbox {
    margin: 5px 0;
  }
`;
