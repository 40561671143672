import React, { useState, useEffect, useContext, useRef } from 'react';
import TableView from '@/layouts/TableView';
import Header from './components/Header';
import TableRowActions from './components/TableRowActions';
import { Table, message, Modal } from 'antd';
import axios from 'axios';
import DrawerTask from '@/pages/app/components/drawers/Task';
import Color from '@/pages/app/components/Color';
import { ITask } from '@/types/task.model';
import AppContext from '@/pages/app/context';
import { AlignType } from '@/types/alignType.model';
import { useTranslation } from 'react-i18next';
import colors from '@/styles/colors';

interface Props {
  className?: string;
}

const ManageTasks: React.FC<Props> = ({ className }) => {
  const {
    state: { loadingTasks, tasks },
    dispatch,
  } = useContext(AppContext);
  const tasksRef = useRef(tasks);
  tasksRef.current = tasks;
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [filteredTasks, setFilteredTasks] = useState<ITask[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeTask, setActiveTask] = useState<ITask | null>(null);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.ID'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      },
      {
        title: t('GLOBAL.COLOR'),
        dataIndex: 'background',
        key: 'background',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: ITask) => {
          return <Color style={{ background: record.background }} />;
        },
      },
      {
        title: t('GLOBAL.CODE'),
        dataIndex: 'shortCode',
        key: 'shortCode',
        sorter: (a: any, b: any) => a.shortCode?.localeCompare(b.shortCode),
      },
      {
        title: t('GLOBAL.DEFAULT_DURATION'),
        dataIndex: 'minutes',
        key: 'minutes',
        sorter: (a: any, b: any) => b.minutes || 0 - a.minutes || 0,
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: ITask) => (
          <TableRowActions onEdit={() => setActiveTask(record)} onDelete={() => onWantToDelete(record.id!)} />
        ),
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    const filteredTasks = tasks.filter((task: any) => {
      return Object.keys(task).some((key) => {
        if (key === 'background') {
          return false;
        }
        if (typeof task[key] === 'string') {
          return task[key].toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return false;
        }
      });
    });
    setFilteredTasks(filteredTasks);
  }, [tasks, searchTerm]);

  const onWantToDelete = (taskId: string) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('TASKS.MODAL_DELETE_TASK.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDelete(taskId);
      },
      onCancel: () => {},
    });
  };

  const onDelete = (taskId: string) => {
    const tasksCopy = [...tasksRef.current];
    const index = tasksCopy.findIndex((task) => task.id === taskId);

    if (~index) {
      const tasksResult = [...tasksCopy];
      tasksResult.splice(index, 1);
      dispatch({
        type: 'SET_TASKS',
        payload: tasksResult,
      });
    }

    axios.delete(`${process.env.REACT_APP_API_URL}/v3/tasks/${taskId}`).catch((error) => {
      if (~index) {
        console.error(error);
        message.error(t('TASKS.MODAL_DELETE_TASK.MESSAGE_ERROR'));
        if (tasksCopy) {
          dispatch({
            type: 'SET_TASKS',
            payload: tasksCopy,
          });
        }
      }
    });
  };

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchTerm(value);
  };

  const onSave = (result: ITask) => {
    const index = tasks.findIndex((task) => task.id === result.id);
    const tasksCopy = [...tasks];
    if (~index) {
      tasksCopy[index] = result;
      dispatch({
        type: 'SET_TASKS',
        payload: tasksCopy,
      });
    } else {
      tasksCopy.push(result);
      const tasksCopySorted = tasksCopy.sort((a, b) => {
        if (a.name!.toLowerCase() < b.name!.toLowerCase()) {
          return -1;
        }
        if (a.name!.toLowerCase() > b.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      dispatch({
        type: 'SET_TASKS',
        payload: tasksCopySorted,
      });
    }
  };

  return (
    <React.Fragment>
      <h2>{t('GLOBAL.TASKS')}</h2>
      <span style={{ color: colors.grey }}>{t('SETTINGS.TASKS.MANAGE_TASKS.DESCRIPTION')}</span>
      <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10, marginTop: 25 }}>
        <TableView>
          <Header disabled={false} onSearchChange={onSearchChange} onCreate={() => setActiveTask({})} />
          <Table
            className={className}
            loading={loadingTasks}
            dataSource={filteredTasks}
            columns={columns}
            rowKey="id"
            pagination={false}
          />
        </TableView>
        <DrawerTask task={activeTask} visible={!!activeTask} onClose={() => setActiveTask(null)} onSave={onSave} />
      </div>
    </React.Fragment>
  );
};

export default ManageTasks;
