import AppContext from '@/pages/app/context';
import { IResource } from '@/types/resource.model';
import { message } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AttributOverview from './overview/index';

const AppTeamResourcePage: React.FC = () => {
  const {
    state: { activeDepartmentId },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const [resource, setResource] = useState<IResource>();
  const { id, attributeId } = useParams() as any;
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (!id || !attributeId) return;
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/resources/${id}/${attributeId}`, {
        params: {
          departmentId: activeDepartmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        setResource(response.data.resource);
      })
      .catch((error) => {
        message.error(t('ATTRIBUTES.MESSAGE_LOADING_ERROR'));
        console.error(error);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [id, activeDepartmentId]);

  const updateResource = (updatedResource: IResource) => {
    appContextDispatch({
      type: 'UPDATE_RESOURCE_ATTRIBUT',
      payload: updatedResource,
    });
    setResource(updatedResource);
  };

  return <AttributOverview resource={resource} departmentId={activeDepartmentId} updateResource={updateResource} />;
};

export default AppTeamResourcePage;
