import { EditableCell, EditableRow } from '@/pages/app/components/EditableCell';
import { IDepartment } from '@/types/department.model';
import { IUser } from '@/types/user.model';
import { IBalanceSheetData } from '@/types/user/balance-sheet-data.model';
import { IBalanceSheet } from '@/types/user/balance-sheet.model';
import { MONTHS } from '@/utils';
import { Button, Input, Table, Tooltip, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBalanceSheetDetails from '../balance-sheet/components/ModalBalanceSheetDetails';
import colors from '@/styles/colors';
import styled from 'styled-components';
import moment from 'moment';

interface Props {
  className?: string;
  department?: IDepartment;
  user?: IUser;
}

const ALLOW_HOURS_REGEX = /^[0-9:h-]*?$/;

const Addendum: React.FC<Props> = ({ className, department, user }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<IBalanceSheetData | null>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [savingInput, setSavingInput] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<IBalanceSheet | null>(null);
  const [currentBase, setCurrentBase] = useState<string | null>(null);
  const [currentModified, setCurrentModified] = useState<string | null>(null);
  const [modalDetailsVisible, setModalDetailsVisible] = useState<boolean>(false);
  const [activeBalanceSheet, setActiveBalanceSheet] = useState<IBalanceSheet | null>(null);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.YEAR'),
        key: 'year',
        align: 'center',
        render: (text: string, record: IBalanceSheet) => {
          return record.year.toString();
        },
      },
      {
        title: t('GLOBAL.MONTH'),
        key: 'month',
        align: 'center',
        render: (text: string, record: IBalanceSheet) => {
          return t(`GLOBAL.${MONTHS[record.month - 1]}`);
        },
      },
      {
        title: 'Cont.',
        key: 'weeklyHours',
        align: 'center',
        render: (text: string, record: IBalanceSheet) => {
          if (!record.weeklyHours) return;

          return Math.round(Number(record.weeklyHours / 60));
        },
      },
      {
        title: 'Base',
        key: 'base',
        width: 175,
        align: 'center',
        render: (text: string, record: IBalanceSheet) => {
          const value = record.modified_norm || record.modified_norm == 0 ? record.modified_norm : record.norm;
          const modified = record.modified_norm !== null;

          if (value == null || value == undefined) return;

          const data = Number(value / 60)
            .toFixed(2)
            .split('.');
          const decimal = Number(Number('0.' + data[1]) * 60);
          const minute = decimal.toFixed(0);
          const display_save = activeRow?.year === record.year && activeRow.month === record.month;

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <Input
                className={modified ? 'modified' : ''}
                value={
                  currentBase !== null && display_save
                    ? currentBase
                    : `${data[0]}:${decimal >= 0 && decimal <= 9 ? `0${minute}` : minute}`
                }
                defaultValue={`${data[0]}:${decimal}`}
                maxLength={6}
                onChange={(e) => onChangeBase(e, record)}
              />
              {modified && (
                <i
                  className="icon-delete"
                  style={{ color: colors.red, cursor: 'pointer' }}
                  onClick={() => onSave(record, 'base', 'null')}
                />
              )}
              {currentBase !== null && display_save && (
                <Button
                  type="primary"
                  style={{ cursor: 'pointer', border: 'none', marginLeft: 5 }}
                  onClick={() => onSave(record, 'base')}
                >
                  <Tooltip title={t('GLOBAL.SAVE')}>
                    <i className="icon-ok" />
                  </Tooltip>
                </Button>
              )}
            </div>
          );
        },
      },
      {
        title: t('GLOBAL.PLANNED'),
        key: 'planned',
        align: 'center',
        render: (text: string, record: IBalanceSheet) => {
          return Math.round(Number(record.planned / 60));
        },
      },
      {
        title: t('GLOBAL.CLOCKING'),
        key: 'clocking',
        align: 'center',
        render: (text: string, record: IBalanceSheet) => {
          return Math.round(Number(record.clocking / 60));
        },
      },
      {
        title: t('GLOBAL.TOTAL'),
        key: 'total',
        align: 'center',
        render: (text: string, record: IBalanceSheet) => {
          return `${Math.round(Number(record.planned / 60)) + Math.round(Number(record.clocking / 60))}`;
        },
      },
      {
        title: t('GLOBAL.COUNTER'),
        key: 'counter',
        align: 'center',
        render: (text: string, record: IBalanceSheet) => {
          if (!record.counter) return '';
          const data = Number(record.counter / 60)
            .toFixed(2)
            .split('.');

          const decimal = Number(Number('0.' + data[1]) * 60);
          const minute = decimal.toFixed(0);

          return `${data[0]}:${decimal >= 0 && decimal <= 9 ? `0${minute}` : minute}`;
        },
      },
      {
        title: t('GLOBAL.BALANCE'),
        key: 'modification',
        align: 'center',
        width: 200,
        render: (text: string, record: IBalanceSheet) => {
          const value =
            record.modified_balance || record.modified_balance == 0 ? record.modified_balance : record.balance;
          const modified = record.modified_balance !== null;

          if (value == null || value == undefined) return;

          const data = Number(value / 60)
            .toFixed(2)
            .split('.');

          const decimal = Number(Number('0.' + data[1]) * 60);
          const minute = decimal.toFixed(0);
          const display_save = activeRow?.year === record.year && activeRow.month === record.month;

          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <Input
                className={modified ? 'modified' : ''}
                value={
                  currentModified !== null && display_save
                    ? currentModified
                    : `${data[0]}:${decimal >= 0 && decimal <= 9 ? `0${minute}` : minute}`
                }
                defaultValue={value ? `${Math.round(Number(value / 60))}` : ''}
                maxLength={6}
                onChange={(e) => onChangeModified(e, record)}
                id="current-modified"
              />
              {modified && (
                <i
                  className="icon-delete"
                  style={{ color: colors.red, cursor: 'pointer' }}
                  onClick={() => onSave(record, 'modified', 'null')}
                />
              )}
              {currentModified !== null && display_save && (
                <Button
                  type="primary"
                  style={{ cursor: 'pointer', border: 'none', marginLeft: 5 }}
                  onClick={() => onSave(record, 'modified')}
                >
                  <Tooltip title={t('GLOBAL.SAVE')}>
                    <i className="icon-ok" />
                  </Tooltip>
                </Button>
              )}
            </div>
          );
        },
      },
      {
        title: '',
        width: 50,
        render: (text: string, record: IBalanceSheet) => {
          return (
            <Button type="ghost" style={{ cursor: 'pointer' }} onClick={() => onRowClick(record)}>
              <Tooltip title={t('GLOBAL.DETAILS')}>
                <i className="icon-eye" />
              </Tooltip>
            </Button>
          );
        },
      },
    ]);
  }, [data, activeRow, user, currentBase, currentModified]);

  useEffect(() => {}, []);

  useEffect(() => {
    let mounted = true;

    if (!mounted || !department || !user) return;

    getAddenBalance();

    return () => {
      mounted = false;
    };
  }, [department, user]);

  const getAddenBalance = () => {
    if (!department || !user) return;

    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/hr/balance-aden`, {
        params: {
          departmentId: department.id,
          recordId: user.recordId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        message.error(Object.values(err.response.data.errors).flat(1)[0]);
        setLoading(false);
      });
  };

  const onSave = (record: IBalanceSheet, input: 'base' | 'modified', value: string | null = null) => {
    if (!department || !user) return;

    setSavingInput(true);
    const cancelTokenSource = axios.CancelToken.source();
    const data = {
      ...record,
      norm: input == 'base' ? (value == 'null' ? null : currentBase) : undefined,
      addendum: input == 'modified' ? (value == 'null' ? null : currentModified) : undefined,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/hr/balance`, data, {
        params: {
          departmentId: department.id,
          recordId: user.recordId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        getAddenBalance();
        setSavingInput(false);
        setActiveRow(null);
        setCurrentBase(null);
      })
      .catch((err) => {
        message.error(Object.values(err.response.data.errors).flat(1)[0]);
        setSavingInput(false);
      });
  };

  const onChangeBase = (e: React.ChangeEvent<HTMLInputElement>, record: IBalanceSheet) => {
    let value = e.target.value;

    if (!ALLOW_HOURS_REGEX.test(value)) return;

    if (value.includes('-') && value.indexOf('-') !== 0) return;

    if (value.includes('h')) {
      value = value.replace('h', ':');
    }

    if (value.includes(':')) {
      const data = value.split(':');
      const hours = data[0];
      const minutes = data[1];

      if (minutes.length >= 3) return;

      const minutes_number = Number(minutes);
      const hours_number = Number(hours);
      if (minutes_number > 59) {
        const excess = minutes_number / 60;
        const modified_hours = hours_number + excess;
        const rest = excess - Math.floor(excess);
        const modified_minutes = rest * 60;

        value = `${Math.round(modified_hours)}:${modified_minutes <= 9 ? '0' : ''}${Math.round(modified_minutes)}`;
      }
    }

    setCurrentBase(value);
    setActiveRow(record);
  };

  const onChangeModified = (e: React.ChangeEvent<HTMLInputElement>, record: IBalanceSheet) => {
    let value = e.target.value;

    if (!ALLOW_HOURS_REGEX.test(value)) return;

    if (value.includes('-') && value.indexOf('-') !== 0) return;

    if (value.includes('h')) {
      value = value.replace('h', ':');
    }

    if (value.includes(':')) {
      const data = value.split(':');
      const hours = data[0];
      const minutes = data[1];

      if (minutes.length >= 3) return;

      const minutes_number = Number(minutes);
      const hours_number = Number(hours);
      if (minutes_number > 59) {
        const excess = minutes_number / 60;
        const modified_hours = hours_number + excess;
        const rest = excess - Math.floor(excess);
        const modified_minutes = rest * 60;

        value = `${Math.round(modified_hours)}:${modified_minutes <= 9 ? '0' : ''}${Math.round(modified_minutes)}`;
      }
    }

    setCurrentModified(value);
    setActiveRow(record);
  };

  const onRowClick = (record: IBalanceSheet) => {
    setModalDetailsVisible(true);
    setActiveBalanceSheet(record);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <div className={className}>
      <Table
        components={components}
        loading={loading}
        dataSource={data?.months}
        columns={columns}
        // rowKey={rowKey}
        pagination={false}
        rowClassName={(record: IBalanceSheet) => {
          const currentMonth = moment().month();
          const currentYear = moment().year();
          const current = Number(record.year) == currentYear && Number(record.month) == currentMonth + 1;
          return `editable-row ${current ? 'current-month' : ''}`;
        }}
      />
      <ModalBalanceSheetDetails
        activeBalanceSheet={activeBalanceSheet}
        balanceSheets={data}
        visible={modalDetailsVisible}
        setVisible={setModalDetailsVisible}
        norm="adden"
      />
    </div>
  );
};

export default styled(Addendum)`
  .modified {
    background-color: ${colors.redLight};

    input {
      background-color: ${colors.redLight};
      font-weight: bold;
      color: #000;
    }
  }

  .current-month {
    background-color: rgba(158, 221, 185, 0.2);
  }
`;
