import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { IBiUser } from '@/types/bi/user.model';
import { IBiDepartment } from '@/types/bi/department.model';
import DepartmentUserShift from './DepartmentUserShift';
import { debounce } from 'lodash';
import { IBiSort } from '@/types/bi/sort.model';
import moment from 'moment';
import { DAYS_MAPPING, createNumberArray } from '@/utils';
import { IBiDashboard } from '@/types/bi/dashboard.model';

interface Props {
  className?: string;
  dashboard: IBiDashboard;
  department: IBiDepartment;
  day: string;
}

const DepartmentUserRow: React.FC<Props> = ({ className, dashboard, department, day }) => {
  const { t } = useTranslation();
  const {
    state: { groupAdminActiveUser },
    dispatch,
  } = useContext(AppContext);
  let rightRef = useRef<HTMLDivElement | null>(null);
  const [fullWidth, setFullWidth] = useState<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (rightRef.current) {
        setFullWidth(rightRef.current.clientWidth);
      }
    };
    const handleResizeDebounced = debounce(handleResize, 100);

    handleResizeDebounced();

    window.addEventListener('resize', handleResizeDebounced);

    return () => {
      window.removeEventListener('resize', handleResizeDebounced);
    };
  }, []);

  const hours = createNumberArray(0, 23);

  const dayNumber = DAYS_MAPPING[day];
  const userShifts = [...dashboard.shifts.filter((shift) => shift.department_id == department.id)];
  const shifts = userShifts.filter((shift) => {
    return moment(shift.startdate).day() == dayNumber;
  });

  return (
    <div className={className}>
      <div className="left">
        <span className="day">{t(`GLOBAL.${day.toUpperCase()}`)}</span>
      </div>
      <div ref={rightRef} className="right">
        {fullWidth &&
          hours.map((hour) => (
            <div key={hour} className="hour">
              {shifts?.map((shift) => (
                <DepartmentUserShift
                  key={shift.id}
                  department={department}
                  shift={shift}
                  fullWidth={fullWidth}
                  hours={hours}
                />
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default styled(DepartmentUserRow)`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;

  .left {
    width: 200px;
    border-right: 1px solid #efefef;
    border-left: 1px solid #efefef;
    padding: 5px 10px;
  }

  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    .hour {
      flex: 1;
      text-align: center;
      border-right: 1px solid #efefef;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
