import colors from '@/styles/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Popconfirm } from 'antd';
import { minutesToHoursAndOrMinutes } from '@/utils';

interface Props {
  className?: string;
  index: number;
  onRemoveWeek?: (index: number) => void;
  totalMinutes: number;
  totalWeeks: number;
}

const TypicalWeekRowWeek: React.FC<Props> = ({
  className,
  children,
  index,
  totalMinutes,
  totalWeeks,
  onRemoveWeek,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <div className={className}>
      <div className="left">
        <div>
          {onRemoveWeek && (
            <Popconfirm
              title={t('GLOBAL.ARE_YOU_SURE?')}
              okText={t('GLOBAL.YES')}
              cancelText={t('GLOBAL.NO')}
              onConfirm={() => onRemoveWeek(index)}
            >
              <Button shape="circle" danger type="primary" size="small">
                -
              </Button>
            </Popconfirm>
          )}
          <span className="week">
            {`${t('GLOBAL.WEEK')} ${index + 1}`} / {totalWeeks}
          </span>
        </div>
        {!!totalMinutes && (
          <div className="statistics">
            <span className="tag">{minutesToHoursAndOrMinutes(totalMinutes!)} </span>
          </div>
        )}
      </div>
      <div className="right">{children}</div>
    </div>
  );
};

export default styled(TypicalWeekRowWeek)`
  display: flex;
  position: relative;
  width: 100%;
  min-width: 100%;
  > .left {
    position: sticky;
    z-index: 1;
    left: 0;
    padding: 10px;
    border-right: 1px solid ${colors.blueLight};
    border-bottom: 1px solid ${colors.blueLight};
    width: 210px;
    background: white;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #f9f9f9;
    @media print {
      width: 120px;
    }

    button {
      width: 15px;
      height: 15px;
      display: inline-flex;
      min-width: auto;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }

    .week  {
      font-weight: bold;
    }

    .statistics {
      margin-top: 5px;
      padding: 2px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .tag {
        border-radius: 3px;
        padding: 2px 3px;
        font-size: 12px;
        display: inline-block;
        line-height: 12px;
        font-size: 10px;
        background: ${colors.bluePastel};
        color: white;
      }
    }
  }

  > .right {
    flex: 1;
    width: auto;
  }
`;
