import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Form, Input, Button, Select } from 'antd';
import axios from 'axios';
import AppContext from '@/pages/app/context';
import { useTranslation } from 'react-i18next';
import colors from '@/styles/colors';
import { IShiftTemplate } from '@/types/shift-template.model';

const { Option } = Select;
interface Props {
  visible: boolean;
  start?: number;
  onClose: () => void;
}

const ModalCreateShiftTemplate: React.FC<Props> = ({ visible, start, onClose }) => {
  const { state: appContextState } = useContext(AppContext);
  const { activeSection, activeDepartmentId } = appContextState;
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t } = useTranslation(undefined, { useSuspense: false });

  const [shiftTemplates, setShiftTemplates] = useState<IShiftTemplate[]>([]);
  const [loadingShiftTemplates, setLoadingShiftTemplates] = useState<boolean>(false);

  useEffect(() => {
    if (!visible) {
      return;
    }
    form.resetFields();
    setLoadingShiftTemplates(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/shift-templates`, {
        params: {
          departmentId: activeDepartmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        setShiftTemplates(response.data);
      })
      .catch((error) => {
        console.error(error);
        setShiftTemplates([]);
      })
      .finally(() => {
        setLoadingShiftTemplates(false);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [visible]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    const { templateId = '', name = '' } = values;
    if (templateId) {
      axios
        .patch(`${process.env.REACT_APP_API_URL}/v3/shift-templates/update/${templateId}`, {
          params: {
            departmentId: activeDepartmentId,
            sectionId: activeSection,
            start,
          },
        })
        .then(() => {
          setIsSaving(false);
          onClose();
        })
        .catch((error) => {
          console.error(error);
          setIsSaving(false);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/v3/shift-templates`, {
          params: {
            departmentId: activeDepartmentId,
            sectionId: activeSection,
            name,
            start,
          },
        })
        .then(() => {
          setIsSaving(false);
          onClose();
        })
        .catch((error) => {
          console.error(error);
          setIsSaving(false);
        });
    }
  };

  return (
    <Modal
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={visible}
      onOk={() => form.submit()}
      onCancel={onClose}
      title={t('WEEK_TEMPLATES.MODAL_CREATE_TEMPLATE.TITLE')}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="templateId" label={t('GLOBAL.TEMPLATE')}>
          <Select
            loading={loadingShiftTemplates}
            clearIcon
            getPopupContainer={(trigger) => trigger}
            showSearch
            placeholder={t('WEEK_TEMPLATES.MODAL_CREATE_TEMPLATE.PLACEHOLDER')}
            optionFilterProp="children"
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="" style={{ color: colors.red }}>
              {t('WEEK_TEMPLATES.NEW_TEMPLATE')}
            </Option>
            {shiftTemplates.map((shiftTemplate) => {
              const { id, template_name } = shiftTemplate;
              return (
                <Option key={id} value={`${id}`}>
                  {template_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.templateId !== curValues.templateId} noStyle>
          {(form) => {
            if (!form.getFieldValue('templateId')) {
              return (
                <React.Fragment>
                  <Form.Item name="name" label={t('GLOBAL.NAME')} required rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button key="back" onClick={onClose} style={{ marginRight: 5 }}>
                      {t('GLOBAL.CANCEL')}
                    </Button>
                    <Button key="submit" type="primary" loading={isSaving} htmlType="submit">
                      {t('GLOBAL.CREATE')}
                    </Button>
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button key="back" onClick={onClose} style={{ marginRight: 5 }}>
                    {t('GLOBAL.CANCEL')}
                  </Button>
                  <Button key="submit" type="primary" htmlType="submit" loading={isSaving}>
                    {t('GLOBAL.MODIFY')}
                  </Button>
                </div>
              );
            }
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalCreateShiftTemplate)``;
