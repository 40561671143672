import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { Menu } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  selectedShifts: Set<string>;
  onClick: () => void;
}

const AySync: React.FC<Props> = ({ className, selectedShifts, onClick }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);

  return (
    <Menu.Item className={className} onClick={onClick} disabled={selectedShifts.size <= 0}>
      <i className="icon-ay_icon" style={{ color: colors.ayBrand }} /> {t('GLOBAL.SYNC_TO_AY')}
    </Menu.Item>
  );
};

export default styled(AySync)`
  margin: 0 5px;
  &:hover {
    background-color: #efefef !important;
    border-radius: 8px;
  }
`;
