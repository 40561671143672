import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Form, Input, Button, Select, InputNumber } from 'antd';
import axios from 'axios';
import { ITimesheetView } from '@/types/timesheet-view.model';
import AppContext from '@/pages/app/context';
import { isFeatureEnabled, valueForSearch } from '@/utils';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { FEATURES } from '@/types/features.model';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option, OptGroup } = Select;

interface Props {
  departmentId?: string;
  className?: string;
  visible: boolean;
  onClose: () => void;
  onSave: (result: ITimesheetView[]) => void;
}

const ModalAddCustomColumn: React.FC<Props> = ({ className, departmentId, visible, onSave, onClose }) => {
  const {
    state: { loadingSkills, skills, dayoffs, loadingDayoffs, loadingResources, resources, features },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/reports/activity-reports`,
        {
          ...values,
        },
        {
          params: {
            departmentId,
          },
        },
      )
      .then((response) => {
        setIsSaving(false);
        onSave(response.data);
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  return (
    <Modal
      visible={visible}
      onOk={() => form.submit()}
      onCancel={onClose}
      title={t('REPORTS.ACTIVITY_REPORT.MODAL.ADD_COLUMN')}
      getContainer={false}
      forceRender
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
          {t('GLOBAL.ADD')}
        </Button>,
      ]}
    >
      <Form className={className} layout="vertical" form={form} onFinish={onFinish} initialValues={{ operand: 'MIN' }}>
        <Form.Item
          name="name"
          label={t('REPORTS.ACTIVITY_REPORT.MODAL.COLUMN_NAME')}
          required
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="code"
          label={t('REPORTS.ACTIVITY_REPORT.MODAL.COLUMN_CODE')}
          required
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="counterType"
          label={t('REPORTS.ACTIVITY_REPORT.MODAL.RESULT_TYPE')}
          required
          rules={[{ required: true }]}
        >
          <Select getPopupContainer={(trigger) => trigger}>
            <Option value={'HOUR'}>{t('GLOBAL.HOURS_NUMBER')}</Option>
            <Option value={'OCCURANCE'}>{t('REPORTS.ACTIVITY_REPORT.MODAL.OCCURRENCES_NUMBER')}</Option>
          </Select>
        </Form.Item>
        <Form.Item name="type" label={t('GLOBAL.SELECTOR')} required rules={[{ required: true }]}>
          <Select getPopupContainer={(trigger) => trigger}>
            <Option value={'HOURS'}>{t('GLOBAL.TIME_SLOT')}</Option>
            <Option value={'DURATION'}>{t('GLOBAL.DURATION')}</Option>
            <Option value={'DAYS'}>{t('GLOBAL.DAY(S)')}</Option>
            {isFeatureEnabled(features, FEATURES.SKILLS) && <Option value={'SKILL'}>{t('GLOBAL.SKILL')}</Option>}
            <Option value={'ATTRIBUTE'}>{t('GLOBAL.ATTRIBUTE')}</Option>
            <Option value={'ABSENCE'}>{t('GLOBAL.ABSENCE')}</Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}>
          {(form) => {
            const type = form.getFieldValue('type');
            switch (type) {
              case 'HOURS':
                return (
                  <React.Fragment>
                    <Form.Item label={t('GLOBAL.START')} name={'start'} rules={[{ required: true }]}>
                      <Select>
                        {[...new Array(25)].map((_item, index) => (
                          <Option key={`start_${index}`} value={index}>
                            {index}:00
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label={t('GLOBAL.END')} name={'end'} rules={[{ required: true }]}>
                      <Select>
                        {[...new Array(25)].map((_item, index) => (
                          <Option key={`end_${index}`} value={index}>
                            {index}:00
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </React.Fragment>
                );
              case 'DAYS':
                return (
                  <React.Fragment>
                    <Form.Item label={t('GLOBAL.DAY(S)')} name="days" rules={[{ required: true }]}>
                      <Select mode="multiple" getPopupContainer={(trigger) => trigger}>
                        {moment.weekdays(true)?.map((day: string, index: number) => (
                          <Option key={`day_${index}`} value={index + 1}>
                            {day}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </React.Fragment>
                );
              case 'SKILL':
                return (
                  <Form.Item label={t('GLOBAL.SKILL')} name="skillId" rules={[{ required: true }]}>
                    <Select
                      getPopupContainer={(trigger) => trigger}
                      showSearch
                      disabled={loadingSkills}
                      loading={loadingSkills}
                      placeholder={t('FORMS.SKILL_PLACEHOLDER')}
                      optionFilterProp="children"
                      filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {skills?.map((skill) => {
                        const { id, name } = skill;
                        return (
                          <Option key={`skill_${id}`} value={`${id}`}>
                            {name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                );
              case 'ABSENCE':
                return (
                  <Form.Item label={t('GLOBAL.ABSENCE')} name="dayoffId" rules={[{ required: true }]}>
                    <Select
                      getPopupContainer={(trigger) => trigger}
                      showSearch
                      disabled={loadingDayoffs}
                      loading={loadingDayoffs}
                      placeholder={t('FORMS.ABSENCE_TYPE_PLACEHOLDER')}
                      optionFilterProp="children"
                      filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {dayoffs?.map((dayoff) => {
                        const { id, name } = dayoff;
                        return (
                          <Option key={`dayoff_${id}`} value={`${id}`}>
                            {name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                );
              case 'ATTRIBUTE':
                return (
                  <Form.Item name="attributeId" label={t('GLOBAL.ATTRIBUTE')}>
                    <Select
                      placeholder={t('FORMS.ATTRIBUTE_PLACEHOLDER')}
                      disabled={loadingResources}
                      loading={loadingResources}
                      filterOption={(input, option) => {
                        if (option) {
                          if (!option.label) {
                            let children = option.children;
                            if (Array.isArray(children)) {
                              children = children.join();
                            }
                            return valueForSearch(children).includes(valueForSearch(input));
                          }
                        }
                        return false;
                      }}
                    >
                      {resources.map((resource = {}) => (
                        <OptGroup label={resource.name} key={`resource_${resource.id}`}>
                          {(resource.attributes || []).map((attribut) => (
                            <Option value={attribut.id!} key={`attribut_${attribut.id}`}>
                              {attribut.name}
                            </Option>
                          ))}
                        </OptGroup>
                      ))}
                    </Select>
                  </Form.Item>
                );
              case 'DURATION':
                return (
                  <React.Fragment>
                    <div className="form-items-group one-line">
                      <Form.Item
                        name="operand"
                        style={{ width: 120 }}
                        rules={[{ required: true }]}
                        className="one-line"
                      >
                        <Select getPopupContainer={(trigger) => trigger}>
                          <Option value="MIN">{t('GLOBAL.GREATER_THAN')}</Option>
                          <Option value="MAX">{t('GLOBAL.LOWER_THAN')}</Option>
                          <Option value="BETWEEN">{t('GLOBAL.BETWEEN')}</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => prevValues.operand !== curValues.operand}
                        className="form-items-group one-line"
                      >
                        {(form) => {
                          const operand = form.getFieldValue('operand');
                          switch (operand) {
                            case 'MIN':
                              return (
                                <div className="form-items-group one-line">
                                  <Form.Item name="start" rules={[{ required: true }]} className="one-line">
                                    <InputNumber type="number" min={1} step={1} precision={0} />
                                  </Form.Item>
                                  <span>{t('GLOBAL.HOUR(S)').toLocaleLowerCase()}</span>
                                </div>
                              );
                            case 'MAX':
                              return (
                                <div className="form-items-group one-line">
                                  <Form.Item name="end" rules={[{ required: true }]} className="one-line">
                                    <InputNumber type="number" min={1} step={1} precision={0} />
                                  </Form.Item>
                                  <span>{t('GLOBAL.HOUR(S)').toLocaleLowerCase()}</span>
                                </div>
                              );
                            case 'BETWEEN':
                              return (
                                <div className="form-items-group one-line">
                                  <Form.Item name="start" rules={[{ required: true }]} className="one-line">
                                    <InputNumber type="number" min={1} step={1} precision={0} />
                                  </Form.Item>
                                  <span>{t('GLOBAL.AND').toLocaleLowerCase()}</span>
                                  <Form.Item name="end" rules={[{ required: true }]} className="one-line">
                                    <InputNumber type="number" min={1} step={1} precision={0} />
                                  </Form.Item>
                                  <span>{t('GLOBAL.HOUR(S)').toLocaleLowerCase()}</span>
                                </div>
                              );
                          }
                        }}
                      </Form.Item>
                    </div>
                    <Form.Item
                      label={t('REPORTS.ACTIVITY_REPORT.MODAL.COUNT_BY')}
                      name="period"
                      rules={[{ required: true }]}
                      style={{ marginTop: 10 }}
                    >
                      <Select getPopupContainer={(trigger) => trigger}>
                        <Option value="DAY">{t('GLOBAL.DAY')}</Option>
                        <Option value="WEEK">{t('GLOBAL.WEEK')}</Option>
                        <Option value="MONTH">{t('GLOBAL.MONTH')}</Option>
                        <Option value="QUARTER">{t('GLOBAL.QUARTER')}</Option>
                        <Option value="SEMESTER">{t('GLOBAL.SEMESTER')}</Option>
                        <Option value="YEAR">{t('GLOBAL.YEAR')}</Option>
                      </Select>
                    </Form.Item>
                  </React.Fragment>
                );
            }
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalAddCustomColumn)`
  .form-items-group.one-line {
    .ant-row.ant-form-item.one-line {
      height: 30px;
      margin-bottom: 0;
    }
  }
`;
