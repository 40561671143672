import Colors from '@/styles/colors';
import { Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  onEdit: () => void;
  onDelete: () => void;
}

const TableRowActions: React.FC<Props> = ({ className, onEdit, onDelete }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <Space size="middle" className={className}>
      <button
        className="green"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onEdit();
        }}
      >
        {t('GLOBAL.EDIT')}
      </button>
      <button
        className="red"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onDelete();
        }}
      >
        {t('GLOBAL.REMOVE')}
      </button>
    </Space>
  );
};

const TableRowActionsStyled = styled(TableRowActions)`
  a {
    color: ${Colors.green};
    &.impersonate {
      border: 1px solid ${Colors.green};
      border-radius: 3px;
      padding: 2px 4px;
      opacity: 0.6;
    }
  }
`;
export default TableRowActionsStyled;
