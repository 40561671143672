import AppContext from '@/pages/app/context';
import { Menu, Tooltip } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InitialStateType } from '../../redux/store';

interface Props {
  className?: string;
  setImportWeekVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: () => void;
}

const ImportWeek: React.FC<Props> = ({ className, setImportWeekVisible, onClick }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const { selectedDate } = useSelector(({ selectedDate }: InitialStateType) => ({
    selectedDate,
  }));

  const onImport = () => {
    setImportWeekVisible(true);
    onClick();
  };

  const savedWeeks = localStorage.getItem('schedule.duplications.locked-weeks');
  const lockedWeeks = savedWeeks ? JSON.parse(savedWeeks) : [];

  if (
    lockedWeeks &&
    lockedWeeks.some(
      (entry: any) => entry.week_number == moment(selectedDate).week() && entry.year == moment(selectedDate).year(),
    )
  ) {
    return (
      <Menu.Item className={className} onClick={onImport} disabled>
        <Tooltip overlay={t('SCHEDULE.DUPLICATE_PREVIOUS_WEEK_LOCKED_TOOLTIP')}>
          <i className="icon-dupe" /> {t('SCHEDULE.IMPORT_WEEK.TITLE')}
        </Tooltip>
      </Menu.Item>
    );
  } else {
    return (
      <Menu.Item className={className} onClick={onImport}>
        <i className="icon-dupe" /> {t('SCHEDULE.IMPORT_WEEK.TITLE')}
      </Menu.Item>
    );
  }
};

export default styled(ImportWeek)`
  margin: 0 5px;
  &:hover {
    background-color: #efefef !important;
    border-radius: 8px;
  }
`;
