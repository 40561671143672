import { usePusher } from '@/components/PusherProvider';
import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { ISwapShiftFilter } from '@/types/swap-shift/filter.model';
import { ISwapShift } from '@/types/swap-shift/swap-shift.model';
import { message, Select, Table, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Actions from './components/Actions';
import ModalSwapShiftDetails from './components/ModalSwapShiftDetails';
import Shift from './components/Shift';

interface Props {
  className?: string;
}

const AppHoursShiftSwapRequestsPage: React.FC<Props> = ({ className }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, activeSection, activeDepartment },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const pusher = usePusher();
  const [columns, setColumns] = useState<any[]>();
  const [swapShifts, setSwapShifts] = useState<ISwapShift[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<ISwapShiftFilter>('PENDING');
  const [modalSwapShiftDetailsVisible, setModalSwapShiftDetailsVisible] = useState<boolean>(false);
  const [activeSwapShift, setActiveSwapShift] = useState<ISwapShift | null>(null);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.FROM'),
        key: 'swapShift',
        render: (text: string, record: ISwapShift) => {
          return (
            <button
              style={{ textDecorationLine: 'underline' }}
              onClick={() => history.push(`/app/team/collaborators/${record.swapShift.profile.record_id}`)}
            >
              {record.swapShift.profile.name ||
                `${record.swapShift.profile.first_name} ${record.swapShift.profile.last_name}`}
            </button>
          );
        },
      },
      {
        title: t('SWAP_SHIFT.COLUMNS.REQUEST_DATE'),
        key: 'date',
        render: (text: string, record: ISwapShift) => {
          return moment(record.swapShift.created_at).format('L HH:mm');
        },
      },
      {
        title: t('GLOBAL.SHIFT'),
        key: 'shift',
        width: 250,
        render: (text: string, record: ISwapShift) => {
          return <Shift shift={record.shift} department={activeDepartment!} section={activeSection} />;
        },
      },
      {
        title: t('GLOBAL.TO'),
        key: 'collaborators',
        render: (text: string, record: ISwapShift) => {
          const approved = record.swapShift.users.find((user) => user.admin_approved_at);
          return (
            <>
              {record.swapShift.users.map((user) => {
                let badge: any = null;
                if (!record.swapShift.active || approved) {
                  badge = (
                    <Tooltip overlay={t('SWAP_SHIFT.STATUSES.USER_NOT_REPLIED')}>
                      <i className="icon-minus-circled" />
                    </Tooltip>
                  );
                } else {
                  badge = (
                    <Tooltip overlay={t('SWAP_SHIFT.STATUSES.USER_WAITING')}>
                      <i className="icon-arrows-cw" style={{ color: colors.orange }} />
                    </Tooltip>
                  );
                }

                if (user.user_approved_at) {
                  badge = (
                    <Tooltip overlay={t('SWAP_SHIFT.STATUSES.USER_APPROVED')}>
                      <i className="icon-ok-circled" style={{ color: colors.green }} />
                    </Tooltip>
                  );
                }

                if (user.user_denied_at) {
                  badge = (
                    <Tooltip overlay={t('SWAP_SHIFT.STATUSES.USER_DENIED')}>
                      <i className="icon-delete" style={{ color: colors.red }} />
                    </Tooltip>
                  );
                }

                return (
                  <div className="dual-cell">
                    <button
                      style={{ textDecorationLine: 'underline' }}
                      onClick={() => history.push(`/app/team/collaborators/${user.record_id}`)}
                    >
                      <i className="icon-right-circled" />
                      {user.name || `${user.first_name} ${user.last_name}`}
                      {badge}
                    </button>
                  </div>
                );
              })}
            </>
          );
        },
      },
      {
        title: t('GLOBAL.STATUS'),
        key: 'status',
        render: (text: string, record: ISwapShift) => {
          return (
            <>
              {record.swapShift.users.map((user) => (
                <div className="dual-cell">
                  <span>{user.status || '-'}</span>
                </div>
              ))}
            </>
          );
        },
      },
      {
        title: t('SWAP_SHIFT.REASON'),
        key: 'reason',
        width: 200,
        render: (text: string, record: ISwapShift) => {
          return (
            <Tooltip placement="topLeft" overlay={<span>{record.swapShift.reason}</span>}>
              <p className="reason">{record.swapShift.reason}</p>
            </Tooltip>
          );
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'actions',
        render: (text: string, record: ISwapShift) => {
          return (
            <Actions
              filter={filter}
              swapShift={record}
              swapShifts={swapShifts}
              setSwapShifts={setSwapShifts}
              setModalSwapShiftDetailsVisible={setModalSwapShiftDetailsVisible}
              setActiveSwapShift={setActiveSwapShift}
            />
          );
        },
      },
    ]);
  }, [swapShifts]);

  useEffect(() => {
    if (pusher && activeDepartment) {
      const channel = pusher?.subscribe(`private-department-${activeDepartment.id}`);
      channel?.bind(`Illuminate\\Notifications\\Events\\BroadcastNotificationCreated`, (payload: any) => {
        if (payload.type == 'refresh' && payload.resource == 'SWAP_SHIFTS') {
          getData();
        }
      });
    }
  }, [pusher, activeDepartment]);

  const getData = () => {
    setLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/swap-shift`, {
        params: {
          departmentId: activeDepartmentId,
          status: filter,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        setSwapShifts(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(t('SWAP_SHIFT.MESSAGES.LOADING_ERROR'));
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [filter]);

  const onFilterChange = (filter: ISwapShiftFilter) => {
    setFilter(filter);
  };

  return (
    <TableView className={className}>
      <header>
        <div className="left">
          <h2>{t('SWAP_SHIFT.TITLE')}</h2>
        </div>
        <div className="right">
          <Select
            getPopupContainer={(trigger) => trigger}
            style={{ width: 250 }}
            value={filter}
            onChange={onFilterChange}
          >
            <Select.Option value="ALL">{t('GLOBAL.ALL')}</Select.Option>
            <Select.Option value="PENDING">{t('GLOBAL.PENDING')}</Select.Option>
            <Select.Option value="ACCEPTED">{t('VACATION_REQUESTS.ACCEPTED_PLURAL')}</Select.Option>
            <Select.Option value="INACTIVE">{t('GLOBAL.INACTIVE')}</Select.Option>
          </Select>
        </div>
      </header>
      <Table
        loading={loading}
        dataSource={swapShifts}
        columns={columns}
        rowKey="id"
        pagination={{ position: ['bottomCenter'], hideOnSinglePage: true }}
      />
      {activeSwapShift && (
        <ModalSwapShiftDetails
          filter={filter}
          swapShifts={swapShifts}
          setSwapShifts={setSwapShifts}
          swapShift={activeSwapShift}
          visible={modalSwapShiftDetailsVisible}
          setVisible={setModalSwapShiftDetailsVisible}
        />
      )}
    </TableView>
  );
};

export default styled(AppHoursShiftSwapRequestsPage)`
  .dual-cell {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .reason {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
`;
