import { Button, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  saving: boolean;
  active: boolean;
  onReset: () => void;
  setWantToCreateNewForm?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormActions: React.FC<Props> = ({ className, saving, active, onReset, setWantToCreateNewForm }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const reset = () => {
    if (setWantToCreateNewForm) {
      setWantToCreateNewForm(false);
    }
    onReset();
  };

  return (
    <Form.Item className={`${className} ${active ? 'active' : ''}`} style={{ marginTop: 50, justifyContent: 'center' }}>
      <Button
        loading={saving}
        disabled={saving}
        type="primary"
        htmlType="submit"
        className="ant-btn-md"
        style={{ marginRight: '10px' }}
      >
        {t('GLOBAL.SAVE')}
      </Button>
      <Button type="default" className="ant-btn-md grey" onClick={reset}>
        {t('GLOBAL.CANCEL')}
      </Button>
    </Form.Item>
  );
};

export default styled(FormActions)`
  position: fixed;
  width: calc(100vw - 740px);
  min-width: 320px;
  background: white;
  bottom: -80px;
  margin: 0 !important;
  margin-left: -20px;
  display: flex;
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
  padding: 10px;
  border-radius: 3px 3px 0 0;
  transition: bottom 0.2s;
  transform: translateX(-50%);
  left: 50%;
  z-index: 3;

  &.active {
    transition-timing-function: ease-in;
    bottom: 0px;
  }

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
