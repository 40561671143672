import React from 'react';
import { Input, Space } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  disabled: boolean;
  onSearchChange?: (e: React.ChangeEvent) => void;
}

const Header: React.FC<Props> = ({ className, onSearchChange, disabled }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <header className={className}>
      <div className="left">
        <h2 style={{ marginLeft: 0 }}>{t('RESOURCES.PENDING_RESOURCES')}</h2>
      </div>
      <div className="right">
        <Space>
          <Input
            disabled={disabled}
            allowClear
            placeholder={t('GLOBAL.SEARCH')}
            suffix={<i className="icon-search"></i>}
            width={190}
            onChange={onSearchChange}
          />
        </Space>
      </div>
    </header>
  );
};

export default Header;
