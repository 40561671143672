import colors from '@/styles/colors';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  activeApp: IMarketplaceApp | null;
}

const Uninstalled: React.FC<Props> = ({ className, activeApp }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <h4 className="title">{t('MARKETPLACE.UNINSTALLED.TITLE', { app: activeApp?.title })}</h4>
      <p
        dangerouslySetInnerHTML={{
          __html: activeApp!.deleted_app_email,
        }}
      ></p>
    </div>
  );
};

export default styled(Uninstalled)`
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;

  .title {
    font-weight: bold;
    font-size: 24px;
    color: ${colors.red};
  }

  .connected {
    font-weight: bold;
  }
`;
