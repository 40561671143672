import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Menu } from 'antd';

interface Props {
  className?: string;
  allShiftsSelected: boolean;
  onClick: () => void;
}

const SelectAllShifts: React.FC<Props> = ({ className, allShiftsSelected, onClick }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, activeSection },
    dispatch,
  } = useContext(AppContext);

  return (
    <Menu.Item className={className} onClick={onClick}>
      <i className={allShiftsSelected ? 'icon-check' : 'icon-check-empty'} />{' '}
      {allShiftsSelected
        ? t('SCHEDULE.MONTHLY_VIEW.HEADER.DESELECT_ALL_SHIFTS')
        : t('SCHEDULE.MONTHLY_VIEW.HEADER.SELECT_ALL_SHIFTS')}
    </Menu.Item>
  );
};

export default styled(SelectAllShifts)`
  margin: 0 5px;
  &:hover {
    background-color: #efefef !important;
    border-radius: 8px;
  }
`;
