import Constraints from '@/pages/app/components/Constraints';
import AppContext from '@/pages/app/context';
import { Button, Form, InputNumber, Modal } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType, updateShiftsAndContextWithShiftResponse } from '../redux/actions';
import { InitialStateType } from '../redux/store';

interface Props {
  className?: string;
  visible: boolean;
  onClose: () => void;
}

const ModalAddOccurrences: React.FC<Props> = ({ className, visible, onClose }) => {
  const { state: appContextState } = useContext(AppContext);
  const { activeSection, activeDepartmentId, activeDepartment, skills, userCategories } = appContextState;
  const [form] = Form.useForm();
  const { t } = useTranslation(undefined, { useSuspense: false });

  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: Dispatch<ActionType> = useDispatch();

  useEffect(() => {
    if (!visible) {
      return;
    }
    form.resetFields();
  }, [visible]);

  const { shiftToAddOccurrences, startDate, endDate, picker } = useSelector(
    ({ shiftToAddOccurrences, startDate, endDate, picker }: InitialStateType) => ({
      startDate,
      shiftToAddOccurrences,
      endDate,
      picker,
    }),
    shallowEqual,
  );

  const onFinish = (values: any) => {
    if (shiftToAddOccurrences) {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/shifts-multiple`,
          {
            shift: {
              id: shiftToAddOccurrences.id,
              start: moment.unix(shiftToAddOccurrences.start!).unix(),
              end: moment.unix(shiftToAddOccurrences.end!).unix(),
            },
            occurrences: values.occurences,
          },
          {
            params: {
              picker,
              startDate: startDate.unix(),
              endDate: endDate.unix(),
              departmentId: activeDepartmentId,
              sectionId: activeSection,
            },
          },
        )
        .then((response) => {
          setLoading(false);
          updateShiftsAndContextWithShiftResponse(dispatch, response, {
            department: activeDepartment!,
            picker,
            startDate,
            endDate,
            activeSection,
            skills,
            userStatus: userCategories,
          });
          const { totalErrors = 0 } = response.data.message;
          if (totalErrors) {
            Modal.confirm({
              className: 'modal-constraints',
              icon: null,
              title: t('SCHEDULE.CONSTRAINTS.TITLE'),
              content: <Constraints message={response.data.message.error?.[0]?.message || []} />,
              cancelText: t('GLOBAL.CANCEL'),
              okText: t('GLOBAL.FORCE'),
              onOk: () => {
                return axios({
                  method: response.config.method,
                  url: response.config.url,
                  data: {
                    ...JSON.parse(response.config.data),
                    force: true,
                  },
                  params: {
                    picker,
                    startDate: startDate.unix(),
                    endDate: endDate.unix(),
                    departmentId: activeDepartmentId,
                    sectionId: activeSection,
                  },
                })
                  .then((response) => {
                    updateShiftsAndContextWithShiftResponse(dispatch, response, {
                      department: activeDepartment!,
                      picker,
                      startDate,
                      endDate,
                      activeSection,
                      skills,
                      userStatus: userCategories,
                    });
                    if (shiftToAddOccurrences.id) {
                      const shift = document.getElementById(shiftToAddOccurrences.id);
                      shift?.classList.remove('optimistic');
                    }
                    onClose();
                  })
                  .catch((err) => {
                    if (shiftToAddOccurrences.id) {
                      const shift = document.getElementById(shiftToAddOccurrences.id);
                      shift?.classList.remove('optimistic');
                    }
                    console.error(err);
                  });
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (shiftToAddOccurrences.id) {
            const shift = document.getElementById(shiftToAddOccurrences.id);
            shift?.classList.remove('optimistic');
          }
          onClose();
        });
    }
  };

  return (
    <Modal
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={visible}
      onCancel={onClose}
      title={t('SHIFTS.MULTIPLE_FREE_SHIFTS.ADD_OCCURRENCES')}
      footer={
        <>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="actions_btn assign_btn"
            onClick={() => form.submit()}
          >
            {t('GLOBAL.CONFIRM')}
          </Button>
          <Button key="back" onClick={onClose} className="actions_btn" style={{ marginRight: 5 }}>
            {t('GLOBAL.CANCEL')}
          </Button>
        </>
      }
      className={className}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item label={t('SHIFTS.MULTIPLE_FREE_SHIFTS.NUMBER_OF_OCCURENCES')} name="occurences">
          <InputNumber
            type="number"
            placeholder={t('SHIFTS.MULTIPLE_FREE_SHIFTS.NUMBER_OF_OCCURENCES')}
            min={1}
            step={1}
            precision={0}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalAddOccurrences)``;
