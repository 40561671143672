import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IUser } from '@/types/user.model';
import { isClockingAccount, isFeatureEnabled } from '@/utils';
import { Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Hr from '../hr';
import Parameters from '../parameters';
import ProfileTab from './ProfileTab';
import AbsoluteYou from './components/AbsoluteYou';
import TabScheduleModels from './schedule-models';

const { TabPane } = Tabs;

interface Props {
  className?: string;
  user?: IUser;
  department?: IDepartment;
  activeSubTab: string;
  setActiveSubTab: React.Dispatch<React.SetStateAction<string>>;
}

const Profile: React.FC<Props> = ({ className, user, department, activeSubTab, setActiveSubTab }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { features },
  } = useContext(AppContext);
  const history = useHistory();
  const [ayActivated, setAyActivated] = useState<boolean>(false);

  useEffect(() => {
    if (activeSubTab == 'AY-CORE' && !(department?.ay_core && (user?.ay_sync || ayActivated))) {
      history.replace(`/app/team/collaborators/${user?.recordId}/profile/profile-details`);
      setActiveSubTab('PROFILE-DETAILS');
    } else {
      if (activeSubTab) {
        history.replace(`/app/team/collaborators/${user?.recordId}/profile/${activeSubTab.toLowerCase()}`);
      }
    }
  }, [user]);

  useEffect(() => {
    if (activeSubTab == '') {
      history.replace(`/app/team/collaborators/${user?.recordId}/profile/profile-details`);
      setActiveSubTab('PROFILE-DETAILS');
    }
  }, [activeSubTab]);

  const onTabChange = (tab: string) => {
    setActiveSubTab(tab);
    history.replace(`/app/team/collaborators/${user?.recordId}/profile/${tab.toLowerCase()}`);
  };

  return (
    <div className={className}>
      <Tabs type="card" onChange={onTabChange} activeKey={activeSubTab == '' ? 'PROFILE-DETAILS' : activeSubTab}>
        <TabPane
          tab={
            <span>
              <i className="icon-user"></i> {t('USERS.PROFILE_DETAIL')}
            </span>
          }
          key="PROFILE-DETAILS"
        >
          <ProfileTab user={user} department={department} />
        </TabPane>
        <TabPane
          tab={
            <span>
              <i className="icon-settings"></i> {t('GLOBAL.SETTINGS')}
            </span>
          }
          key="SETTINGS"
        >
          <Parameters user={user} department={department} setAyActivated={setAyActivated} />
        </TabPane>
        {['ADMIN', 'HR'].includes(department?.role || '') &&
          (!department?.ay_core || (!user?.ay_sync && !ayActivated)) &&
          !isClockingAccount(features) && (
            <TabPane
              tab={
                <span>
                  <i className="icon-doc-text-inv"></i> {t('USERS.CONTRACTUAL_DATA')}
                </span>
              }
              key="HR"
            >
              <Hr user={user} department={department} />
            </TabPane>
          )}
        {department?.ay_core && (user?.ay_sync || ayActivated) && (
          <TabPane
            tab={
              <span className="tab-title">
                <i className="icon-ay_icon"></i> AbsoluteYOU
              </span>
            }
            key="AY-CORE"
          >
            <AbsoluteYou user={user} />
          </TabPane>
        )}
        {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
          <TabPane
            tab={
              <span>
                <i className="icon-calendar-alt"></i> {t('GLOBAL.SCHEDULE_MODELS')}
              </span>
            }
            key="SCHEDULE-MODELS"
          >
            <TabScheduleModels user={user} department={department} activeSubTab={activeSubTab} />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default styled(Profile)`
  .icon-ay_icon,
  .tab-title {
    color: ${colors.ayBrand};
  }
`;
