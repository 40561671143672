import React, { useContext, useState } from 'react';
import { Button, Checkbox, Input, Space, message } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import AppContext from '@/pages/app/context';
import { handleError } from '@/utils';

interface Props {
  className?: string;
  disabled: boolean;
  selectedUsers: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  onSearchChange?: (e: React.ChangeEvent) => void;
  getUsers: () => void;
}

const Header: React.FC<Props> = ({
  className,
  onSearchChange,
  selectedUsers,
  setSelectedUsers,
  disabled,
  getUsers,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);

  const onValidateAllUsers = async () => {
    setLoadingValidation(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/users/validation`,
        {
          users: selectedUsers,
          approvePending: true,
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(() => {
        message.success(t('USERS.USER_VALIDATION.ALL_USERS_VALIDATED'));
        getUsers();
        setSelectedUsers([]);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingValidation(false);
      });
  };

  return (
    <header className={className}>
      <div className="left">
        <h2 style={{ marginLeft: 0 }}>{t('USERS.USER_VALIDATION.TITLE')}</h2>
      </div>
      <div className="right">
        <Button
          type="primary"
          style={{ marginRight: 15 }}
          onClick={onValidateAllUsers}
          loading={loadingValidation}
          disabled={selectedUsers.length == 0}
        >
          {t('USERS.USER_VALIDATION.VALIDATE_ALL_USERS')}
        </Button>
        <Space>
          <Input
            disabled={disabled}
            allowClear
            placeholder={t('GLOBAL.SEARCH')}
            suffix={<i className="icon-search"></i>}
            width={190}
            onChange={onSearchChange}
          />
        </Space>
      </div>
    </header>
  );
};

export default Header;
