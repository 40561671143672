import React, { useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { IBiDepartment } from '@/types/bi/department.model';
import DepartmentHeader from './DepartmentHeader';
import { IBiDashboard } from '@/types/bi/dashboard.model';
import DepartmentSubHeader from './DepartmentSubHeader';
import DepartmentPlannedUsers from './DepartmentPlannedUsers';
import DepartmentUserRow from './DepartmentUserRow';
import { IBiUser } from '@/types/bi/user.model';
import { IBiSort } from '@/types/bi/sort.model';
import moment from 'moment';
import DepartmentDayRow from './DepartmentDayRow';
import { WEEKDAYS } from '@/utils';

interface Props {
  className?: string;
  dashboard: IBiDashboard;
  department: IBiDepartment;
  getDashboard: (recordId?: string | null) => void;
}

const Department: React.FC<Props> = ({ className, dashboard, department, getDashboard }) => {
  const { t } = useTranslation();
  const {
    state: {
      activeDepartment,
      groupAdminUsers,
      groupAdminSort,
      groupAdminStatus,
      groupAdminActiveUser,
      groupAdminAttributes,
      groupAdminSections,
      groupAdminSkills,
    },
    dispatch,
  } = useContext(AppContext);

  const departmentShifts = useMemo(() => {
    let shifts = dashboard.shifts
      .filter((shift) => shift.department_id === department.id)
      .sort((a, b) => {
        const startDateA = moment(a.startdate) as any;
        const startDateB = moment(b.startdate) as any;
        return startDateA - startDateB;
      });

    shifts = [...shifts].filter((shift) => {
      const user = department.users.find((user) => user.id == shift.ressource_record_id);
      if (
        groupAdminAttributes.length == 0 &&
        groupAdminSections.length == 0 &&
        groupAdminSkills.length == 0 &&
        groupAdminStatus.length == 0 &&
        groupAdminUsers.length == 0
      )
        return true;
      if (user && groupAdminUsers.length > 0 && groupAdminUsers.includes(user.id)) return true;
      if (user && user.user_type && groupAdminStatus.length > 0 && groupAdminStatus.includes(user.user_type))
        return true;
      if (groupAdminSections.length > 0 && groupAdminSections.includes(String(shift.section))) return true;
      if (
        shift.skills &&
        groupAdminSkills.length > 0 &&
        groupAdminSkills.some((element) => shift.skills.includes(element))
      )
        return true;
      if (
        shift.attributes &&
        groupAdminAttributes.length > 0 &&
        groupAdminAttributes.some((element) => shift.attributes.includes(element))
      )
        return true;
      return false;
    });

    return shifts;
  }, [
    dashboard.shifts,
    department.id,
    groupAdminStatus,
    groupAdminUsers,
    groupAdminSections,
    groupAdminSkills,
    groupAdminAttributes,
  ]);

  const usersWithShifts: IBiUser[] = useMemo(() => {
    let users = [...department.users].sort((a, b) => {
      const startDateA = a.shifts && a.shifts.length > 0 ? (moment(a.shifts[0].startdate) as any) : null;
      const startDateB = b.shifts && b.shifts.length > 0 ? (moment(b.shifts[0].startdate) as any) : null;
      return startDateA - startDateB;
    });
    if (groupAdminSort) {
      if (groupAdminSort == IBiSort.NAME) {
        users = users.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      }
    } else {
      users = [...department.users];
    }
    users = users.map((user) => {
      const shifts = dashboard.shifts.filter((shift) => shift.ressource_record_id === user.id);
      return {
        ...user,
        shifts,
      };
    });
    if (groupAdminSort == IBiSort.SKILLS && department.skills && department.skills.length > 0) {
      // Sort users based on the 'pos' property of skills in department.skills
      users = users.sort((a, b) => {
        const skillA = department.skills!.find(
          (skill) => a.shifts && a.shifts.length > 0 && a.shifts[0].skills && a.shifts[0].skills[0] == skill.id,
        );
        const skillAIndex = department.skills!.findIndex(
          (skill) => a.shifts && a.shifts.length > 0 && a.shifts[0].skills && a.shifts[0].skills[0] == skill.id,
        );
        const skillB = department.skills!.find(
          (skill) => b.shifts && b.shifts.length > 0 && b.shifts[0].skills && b.shifts[0].skills[0] == skill.id,
        );
        const skillBIndex = department.skills!.findIndex(
          (skill) => b.shifts && b.shifts.length > 0 && b.shifts[0].skills && b.shifts[0].skills[0] == skill.id,
        );

        const posA = skillA && skillA.pos ? skillA.pos : skillAIndex;
        const posB = skillB && skillB.pos ? skillB.pos : skillBIndex;

        return posA - posB;
      });
    }
    if (groupAdminSort == IBiSort.STATUS && department.categories && department.categories.length > 0) {
      // Sort users based on the 'pos' property of skills in department.skills
      users = users.sort((a, b) => {
        const statusA = department.categories!.find((status) => a.user_type == status.id);
        const statusAIndex = department.categories!.findIndex((status) => a.user_type == status.id);
        const statusB = department.categories!.find((status) => b.user_type == status.id);
        const statusBIndex = department.categories!.findIndex((status) => b.user_type == status.id);

        const posA = statusA ? statusA.pos : statusAIndex;
        const posB = statusB ? statusB.pos : statusBIndex;

        return posA - posB;
      });
    }
    if (groupAdminSort == IBiSort.SECTIONS && department.sections && department.sections.length > 0) {
      // Sort users based on the 'pos' property of skills in department.skills
      users = users.sort((a, b) => {
        const sectionAIndex = department.sections!.findIndex(
          (section) =>
            a.shifts && a.shifts.length > 0 && a.shifts[0].section && String(a.shifts[0].section) == section.id,
        );
        const sectionBIndex = department.sections!.findIndex(
          (section) =>
            b.shifts && b.shifts.length > 0 && b.shifts[0].section && String(b.shifts[0].section) == section.id,
        );

        const posA = sectionAIndex;
        const posB = sectionBIndex;

        return posA - posB;
      });
    }
    return users;
  }, [dashboard.shifts, department.users, groupAdminSort]);

  if (departmentShifts.length == 0) return null;

  return (
    <div className={className}>
      <DepartmentHeader department={department} shifts={departmentShifts} />
      <div className="schedule">
        <DepartmentSubHeader department={department} />
        <DepartmentPlannedUsers department={department} shifts={departmentShifts} />
        {groupAdminActiveUser && (
          <>
            {WEEKDAYS.map((day, index) => (
              <DepartmentDayRow key={index} dashboard={dashboard} department={department} day={day} />
            ))}
          </>
        )}
        {!groupAdminActiveUser &&
          usersWithShifts.map((userWithShift) => {
            return (
              <DepartmentUserRow
                key={userWithShift.id}
                department={department}
                user={userWithShift}
                getDashboard={getDashboard}
              />
            );
          })}
      </div>
    </div>
  );
};

export default styled(Department)`
  .shifts {
    margin-top: 5px;
  }

  .schedule {
    margin-top: 5px;
  }
`;
