import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { FEATURES } from '@/types/features.model';
import { POSColorType } from '@/types/pos-colors.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { handleError, isAdmin, isFeatureEnabled } from '@/utils';
import { Form, Select, Switch } from 'antd';
import { SelectValue } from 'antd/es/select';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';
import POSColor from './components/POSColor';
import POSLogo from './components/POSLogo';
import POSPreview from './components/POSPreview';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const ClockingPos: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, activeSection, sections, loadingSections, features },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [posColors, setPosColors] = useState<POSColorType[]>([]);
  const [reseting, setReseting] = useState<boolean>(false);
  const [commentActive, setCommentActive] = useState<boolean>(false);
  const [apiKeySectionId, setApiKeySectionId] = useState<string | null>(activeSection || null);

  const DEFAULT_COLORS = ['#00A651', '#d1fbdf', '#29303D', '#3d4044', '#ed6e5d'];
  const POS_COLORS: POSColorType[] = [
    {
      title: t('SETTINGS.ACCOUNT.APPS.POS.COLORS.PRIMARY'),
      key: 'primary',
      colors: [DEFAULT_COLORS[0], '#ffb001', '#e73838', '#6734f3'],
      selected: !reseting ? (currentSettings?.pos?.primary ? currentSettings?.pos.primary : null) : DEFAULT_COLORS[0],
      custom: !reseting ? (currentSettings?.pos?.primary ? true : false) : false,
    },
    {
      title: t('SETTINGS.ACCOUNT.APPS.POS.COLORS.SECONDARY'),
      key: 'secondary',
      colors: [DEFAULT_COLORS[1], '#fee0ca', '#fec7c7', '#d7c9fc'],
      selected: !reseting
        ? currentSettings?.pos?.secondary
          ? currentSettings?.pos.secondary
          : null
        : DEFAULT_COLORS[1],
      custom: !reseting ? (currentSettings?.pos?.secondary ? true : false) : false,
    },
    {
      title: t('SETTINGS.ACCOUNT.APPS.POS.COLORS.BACKGROUND'),
      key: 'background',
      colors: [DEFAULT_COLORS[2], '#b29453', '#9b619b', '#638d71'],
      selected: !reseting
        ? currentSettings?.pos?.background
          ? currentSettings?.pos.background
          : null
        : DEFAULT_COLORS[2],
      custom: !reseting ? (currentSettings?.pos?.background ? true : false) : false,
    },
    {
      title: t('SETTINGS.ACCOUNT.APPS.POS.COLORS.DISABLED'),
      key: 'disabled',
      colors: [DEFAULT_COLORS[3], '#858483', '#c3c3c3', '#2b2b2b'],
      selected: !reseting ? (currentSettings?.pos?.disabled ? currentSettings?.pos.disabled : null) : DEFAULT_COLORS[3],
      custom: !reseting ? (currentSettings?.pos?.disabled ? true : false) : false,
    },
    {
      title: t('SETTINGS.ACCOUNT.APPS.POS.COLORS.ERROR'),
      key: 'error',
      colors: [DEFAULT_COLORS[4], '#7e0202', '#ff3636', '#ff9696'],
      selected: !reseting ? (currentSettings?.pos?.error ? currentSettings?.pos.error : null) : DEFAULT_COLORS[4],
      custom: !reseting ? (currentSettings?.pos?.error ? true : false) : false,
    },
  ];

  useEffect(() => {
    if (activeSection) {
      setApiKeySectionId(activeSection);
    } else {
      setApiKeySectionId(null);
    }
  }, [activeSection]);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  useEffect(() => {
    setPosColors(POS_COLORS);
    if (reseting) {
      setFormHasChanged(true);
    }
  }, [reseting]);

  const onPosColorsReset = () => {
    setReseting(true);
  };

  const onChangeApiKeySection = (value: SelectValue) => {
    setApiKeySectionId(value as string);
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: isAdmin(activeDepartment!.role!),
    },
    {
      title: t('GLOBAL.API_KEY'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.CLOCKING.POS.AUTO_PICTURE.LABEL'),
          description: t('SETTINGS.CLOCKING.POS.AUTO_PICTURE.DESCRIPTION'),
          name: ['department', 'tablet_picture'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.department?.tablet_picture} />,
          show: true,
        },
        {
          label: t('SETTINGS.CLOCKING.POS.DISPLAY_CLOCKING_DETAILS.LABEL'),
          description: t('SETTINGS.CLOCKING.POS.DISPLAY_CLOCKING_DETAILS.DESCRIPTION'),
          name: ['pos', 'clocking_details'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.pos?.clocking_details} />,
          show: true,
        },
        {
          label: t('SETTINGS.CLOCKING.POS.DISPLAY_CLOCKING_DURATION.LABEL'),
          description: t('SETTINGS.CLOCKING.POS.DISPLAY_CLOCKING_DURATION.DESCRIPTION'),
          name: ['pos', 'worked_hours'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.pos?.worked_hours} />,
          show: true,
        },
        {
          label: t('SETTINGS.CLOCKING.POS.ACTIVATE_CLOCKOUT_COMMENT.LABEL'),
          description: t('SETTINGS.CLOCKING.POS.ACTIVATE_CLOCKOUT_COMMENT.DESCRIPTION'),
          name: ['pos', 'comment'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch defaultChecked={currentSettings?.pos?.comment} onChange={(checked) => setCommentActive(checked)} />
          ),
          show: true,
        },
        {
          label: t('SETTINGS.CLOCKING.POS.COMMENT_MANDATORY.LABEL'),
          description: t('SETTINGS.CLOCKING.POS.COMMENT_MANDATORY.DESCRIPTION'),
          name: ['pos', 'comment_mandatory'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: <Switch defaultChecked={currentSettings?.pos?.comment_mandatory} />,
          show: commentActive,
        },
      ],
      [
        {
          label: t('SETTINGS.CLOCKING.POS.CUSTOM_POS.LABEL'),
          description: t('SETTINGS.CLOCKING.POS.CUSTOM_POS.DESCRIPTION'),
          name: ['pos'],
          style: { margin: 0 },
          element: (
            <button type="button" onClick={onPosColorsReset}>
              {t('GLOBAL.RESET')}
            </button>
          ),
          show: true,
        },
        {
          label: '',
          description: '',
          style: { margin: 0 },
          element: (
            <div style={{ display: 'flex', gap: 50, marginTop: 15, paddingBottom: 50 }}>
              <div>
                <span>{t('GLOBAL.COMPANY_LOGO')}</span>
                <div style={{ marginTop: 15 }}>
                  <POSLogo currentSettings={currentSettings} />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                {posColors.map((color) => (
                  <POSColor
                    color={color}
                    posColors={posColors}
                    setPosColors={setPosColors}
                    setFormHasChanged={setFormHasChanged}
                    currentSettings={currentSettings}
                  />
                ))}
              </div>
              <div style={{ position: 'relative' }}>
                <span>{t('GLOBAL.PREVIEWS')}</span>
                <div
                  style={{ position: 'absolute', transform: 'scale(0.2)', transformOrigin: 'top left', marginTop: 15 }}
                >
                  <POSPreview posColors={posColors} />
                </div>
              </div>
            </div>
          ),
          elementBelow: true,
          show: true,
        },
      ],
    ],
    [
      [
        {
          label: t('SETTINGS.CLOCKING.POS.API_KEY.LABEL'),
          description: t('SETTINGS.CLOCKING.POS.API_KEY.DESCRIPTION'),
          style: { margin: 0 },
          element: (
            <Paragraph keyboard copyable style={{ fontSize: 16, marginTop: 15 }}>
              {currentSettings?.department?.api_key}
            </Paragraph>
          ),
          elementBelow: true,
          show: true,
        },
        {
          label: t('SETTINGS.CLOCKING.POS.API_KEY_SECTION.LABEL'),
          description: t('SETTINGS.CLOCKING.POS.API_KEY_SECTION.DESCRIPTION'),
          style: { margin: 0 },
          element: (
            <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
              <Select
                loading={loadingSections}
                disabled={loadingSections}
                showSearch
                optionFilterProp="children"
                placeholder={t('GLOBAL.SECTIONS')}
                filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                style={{ maxWidth: '33%' }}
                size="large"
                onChange={onChangeApiKeySection}
                value={apiKeySectionId as any}
                allowClear
              >
                {sections.map((section) => (
                  <Select.Option value={section.id!}>{section.name}</Select.Option>
                ))}
              </Select>
              {apiKeySectionId && (
                <Paragraph keyboard copyable style={{ fontSize: 16, marginTop: 15, maxWidth: '66%' }}>
                  {`${currentSettings?.department?.api_key}#sectionId=${apiKeySectionId}`}
                </Paragraph>
              )}
            </div>
          ),
          elementBelow: true,
          show: isFeatureEnabled(features, FEATURES.SECTIONS),
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    setPosColors(POS_COLORS);
    if (currentSettings) {
      if (currentSettings.pos?.comment) {
        setCommentActive(currentSettings.pos.comment);
      } else {
        setCommentActive(false);
      }
    }
    form.setFieldsValue(currentSettings);
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    posColors.forEach((color) => {
      if (color.selected) {
        values = { ...values, pos: { ...values.pos, [color.key]: color.selected } };
      }
    });
    updateCurrentSettings(values)
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>Shyfter POS</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(ClockingPos)``;
