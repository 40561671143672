import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { USER_ROLES } from '@/types/user-role.model';
import { isFeatureEnabled } from '@/utils';
import { Checkbox, Col, Divider, Form, FormInstance } from 'antd';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';

interface Props {
  form: FormInstance;
  department?: IDepartment;
}

const Notifications: React.FC<Props> = ({ form, department }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { features },
  } = useContext(AppContext);
  return (
    <div>
      <h4>{t('USERS.USER.SETTINGS.ALERTS_AND_NOTIFICATIONS')}</h4>
      <Col span={24} style={{ padding: 0 }}>
        <Form.Item
          name={['notifications', 'email_active']}
          className="one-line"
          valuePropName="checked"
          style={{ margin: 0 }}
        >
          <Checkbox>{t('USERS.USER.SETTINGS.CAN_RECEIVE_EMAIL_NOTIF')}</Checkbox>
        </Form.Item>
        <Form.Item
          name={['notifications', 'sms_active']}
          className="one-line"
          valuePropName="checked"
          style={{ margin: 0 }}
        >
          <Checkbox>{t('USERS.USER.SETTINGS.CAN_RECEIVE_SMS_ALERT')}</Checkbox>
        </Form.Item>
        <Form.Item
          name={['notifications', 'push_active']}
          valuePropName="checked"
          className="one-line"
          style={{ margin: 0 }}
        >
          <Checkbox>{t('USERS.USER.SETTINGS.CAN_RECEIVE_APP_ALERT')}</Checkbox>
        </Form.Item>
      </Col>
      <Form.Item
        shouldUpdate={(prevValues, curValues) => prevValues.params?.account_type !== curValues.params?.account_type}
        style={{ margin: 0 }}
      >
        {() => {
          const activeRole = form.getFieldValue(['params', 'account_type']);
          if (activeRole !== USER_ROLES.USER) {
            return (
              <Fragment>
                <Divider />
                {!!isFeatureEnabled(features, FEATURES.FREESHIFTS) && (
                  <Form.Item name={['notifications', 'booking']} valuePropName="checked" className="one-line margin-0">
                    <Checkbox>{t('USERS.USER.SETTINGS.AUTHORIZE_ALERT_BOOKING')}</Checkbox>
                  </Form.Item>
                )}
                {!!isFeatureEnabled(features, FEATURES.CLOCKING) && (
                  <Form.Item name={['notifications', 'clockin']} valuePropName="checked" className="one-line margin-0">
                    <Checkbox>{t('USERS.USER.SETTINGS.AUTHORIZE_ALERT_CLOCKING')}</Checkbox>
                  </Form.Item>
                )}
                {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
                  <Form.Item name={['notifications', 'request']} valuePropName="checked" className="one-line margin-0">
                    <Checkbox>{t('USERS.USER.SETTINGS.AUTHORIZE_ALERT_REQUEST')}</Checkbox>
                  </Form.Item>
                )}
                {!!isFeatureEnabled(features, FEATURES.CONTRACTS) && (
                  <Form.Item
                    name={['notifications', 'contracts']}
                    valuePropName="checked"
                    className="one-line margin-0"
                  >
                    <Checkbox>{t('USERS.USER.SETTINGS.AUTHORIZE_ALERT_CONTRACTS')}</Checkbox>
                  </Form.Item>
                )}
                {!!isFeatureEnabled(features, FEATURES.DIMONA) &&
                  !!department?.params?.dimona &&
                  !!department?.access?.dimona &&
                  ['ADMIN', 'HR', 'PLANNING'].includes(department?.role || '') && (
                    <Form.Item name={['notifications', 'dimona']} valuePropName="checked" className="one-line margin-0">
                      <Checkbox>{t('USERS.USER.SETTINGS.AUTHORIZE_DIMONA_ALERTS')}</Checkbox>
                    </Form.Item>
                  )}
                {isFeatureEnabled(features, FEATURES.SCHEDULE) &&
                  !!department?.scheduleParams?.swap_shift_enabled &&
                  ['ADMIN', 'HR', 'PLANNING'].includes(department?.role || '') && (
                    <Form.Item
                      name={['notifications', 'swap_shift']}
                      valuePropName="checked"
                      className="one-line margin-0"
                    >
                      <Checkbox>{t('USERS.USER.SETTINGS.AUTHORIZE_SWAP_SHIFT_ALERTS')}</Checkbox>
                    </Form.Item>
                  )}
              </Fragment>
            );
          } else {
            return null;
          }
        }}
      </Form.Item>
    </div>
  );
};

export default Notifications;
