import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'antd';
import colors from '@/styles/colors';

interface Props {
  className?: string;
  element: 'BLUE' | 'PINK' | 'LIGHTYELLOW' | 'RED';
}

const HrSheetRuleLegendElement: React.FC<Props> = ({ className, element }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Button
        type="link"
        className="button"
        style={{
          borderColor:
            element == 'BLUE'
              ? 'lightskyblue'
              : element == 'PINK'
              ? '#FFE4E1'
              : element == 'LIGHTYELLOW'
              ? '#FFFFE0'
              : element == 'RED'
              ? colors.redDark
              : '',
          background:
            element == 'BLUE'
              ? 'lightskyblue'
              : element == 'PINK'
              ? '#FFE4E1'
              : element == 'LIGHTYELLOW'
              ? '#FFFFE0'
              : element == 'RED'
              ? colors.redDark
              : '',
          color: element == 'BLUE' || element == 'RED' ? '#FFF' : '#000',
        }}
      >
        {t(`GLOBAL.COLORS.${element}`)}
      </Button>
      <div className="text">{t(`REPORTS.HR_TIMESHEETS.LEGEND.${element}`)}</div>
    </div>
  );
};

export default styled(HrSheetRuleLegendElement)`
  display: flex;
  align-items: center;
  gap: 5px;

  .button {
    cursor: default;
  }

  .text {
    color: ${colors.grey};
    font-size: 12px;
    margin-right: 5px;
  }
`;
