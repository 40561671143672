import AppContext from '@/pages/app/context';
import { IShift } from '@/types/shift.model';
import { Dropdown } from 'antd';
import React, { useContext, useState } from 'react';
import ShiftRightClickMenu from './ShiftRightClickMenu';

interface Props {
  children?: any;
  shift: IShift;
  closeMonthRightMenu?: () => void;
}
const ShiftRightClick: React.FC<Props> = ({ children, shift, closeMonthRightMenu }) => {
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const [rightClickMenuVisible, setRightClickMenuVisible] = useState<boolean>(false);

  if (shift.locked && !['ADMIN', 'SUPER-ADMIN'].includes(activeDepartment!.role!)) {
    return <>{children}</>;
  }

  return (
    <Dropdown
      getPopupContainer={(trigger) => {
        return trigger.parentNode?.parentNode?.parentNode as HTMLElement;
      }}
      overlay={() => (
        <ShiftRightClickMenu
          shift={shift}
          close={() => {
            setRightClickMenuVisible(false);
          }}
          closeMonthRightMenu={closeMonthRightMenu}
        />
      )}
      trigger={['contextMenu']}
      visible={rightClickMenuVisible}
      onVisibleChange={(e) => {
        setRightClickMenuVisible(e);
        if (closeMonthRightMenu) {
          closeMonthRightMenu();
        }
      }}
      overlayStyle={{ zIndex: 99999 }}
    >
      {children}
    </Dropdown>
  );
};

export default ShiftRightClick;
