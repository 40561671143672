import { Input } from 'antd';
import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const UserListSearch: React.FC<Props> = ({ className, value, onChange }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <div className={className}>
      <Input
        allowClear
        placeholder={t('GLOBAL.SEARCH')}
        suffix={<i className="icon-search"></i>}
        width={190}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default styled(UserListSearch)`
  padding: 0 20px 10px;
  background: white;
`;
