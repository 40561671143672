import { IDepartment } from '@/types/department.model';
import { Checkbox, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IResponse } from '.';

const { Option } = Select;

interface Props {
  className?: string;
  department?: IDepartment;
  form: FormInstance;
  meta?: IResponse['meta'];
}

const Reduction: React.FC<Props> = ({ className, form, meta }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <div className={className}>
      <Row gutter={[20, 10]}>
        <Col lg={8} md={12}>
          <Form.Item name={'long_term_unemployment'} valuePropName="checked">
            <Checkbox>{t('USERS.USER.HR.LONG_TERM_UNEMPLOYMENT')}</Checkbox>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item name={'unskilled'} valuePropName="checked">
            <Checkbox>{t('USERS.USER.HR.UNSKILLED')}</Checkbox>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item name={'restructuring'} valuePropName="checked">
            <Checkbox>{t('USERS.USER.HR.RESTRUCTURING')}</Checkbox>
          </Form.Item>
        </Col>
        <Col lg={8} md={12}>
          <Form.Item name={'target_group_reduction_flanders'} valuePropName="checked">
            <Checkbox>{t('USERS.USER.HR.TARGET_GROUP_REDUCTION_FLANDERS')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default styled(Reduction)``;
