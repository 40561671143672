import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { ISection } from '@/types/section.model';
import { ISkill } from '@/types/skill.model';
import { IUserStatus } from '@/types/user-status.model';
import { IUser } from '@/types/user.model';
import { getWindowSize } from '@/utils';
import { Button, Checkbox, Drawer, Input, message, Spin } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  departmentId?: string;
  entity: ISection | ISkill | IUserStatus | null;
  entityName: 'sections' | 'skills' | 'status';
  onClose: () => void;
}

interface IUserForAttribution {
  recordId: string;
  displayName: string;
  active: boolean;
}

const DrawerAssignUsers: React.FC<Props> = ({ className, departmentId, entity, entityName, onClose }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { users },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [userList, setUserList] = useState<IUserForAttribution[]>([]);
  const [filteredUserList, setFilteredUserList] = useState<IUserForAttribution[]>([]);
  const [toggleAllUsers, setToggleAllUsers] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (entity && entityName) {
      setSearch('');
      let mounted = true;
      const cancelTokenSource = axios.CancelToken.source();
      setLoading(true);
      setUserList([]);
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/${entityName}/${entity.id}/users`, {
          cancelToken: cancelTokenSource.token,
          params: {
            departmentId,
          },
        })
        .then(({ data }) => {
          if (mounted) {
            setLoading(false);
            setUserList(data.filter((user: IUser) => user.displayName?.trim().length !== 0));
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
          if (mounted) {
            setLoading(false);
            message.error(t('GLOBAL.MESSAGE_DOWNLOAD_ERROR'));
          }
        });

      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [entity]);

  useEffect(() => {
    setFilteredUserList(
      userList?.filter((user) => user.displayName && user.displayName!.toLowerCase().includes(search.toLowerCase())),
    );
  }, [userList, search]);

  const onCheck = (recorId: string) => {
    const userListCopy = [...userList];
    const index = userListCopy.findIndex((x) => x.recordId == recorId);
    if (~index) {
      userListCopy[index].active = !userListCopy[index].active;
    }
    setUserList(userListCopy);
  };

  const toggleCheckAll = () => {
    const userListCopy = userList.map((user) => {
      if (toggleAllUsers) {
        return { ...user, active: true };
      } else {
        return { ...user, active: false };
      }
    });
    setToggleAllUsers(!toggleAllUsers);
    setUserList(userListCopy);
  };

  const onSubmit = () => {
    setIsSaving(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/${entityName}/${entity!.id}/users`,
        {
          data: userList.reduce((a, b) => {
            if (b.active) {
              a.push(b.recordId);
            }
            return a;
          }, [] as string[]),
        },
        {
          params: {
            departmentId,
          },
        },
      )
      .then(() => {
        setIsSaving(false);
        onClose();
      })
      .catch((error) => {
        message.error(t('GLOBAL.MESSAGE_SAVING_ERROR'));
        setIsSaving(false);
        console.log(error);
      });
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={entity?.name}
      placement="right"
      visible={!!entity}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <div className={className}>
        {loading ? (
          <Spin spinning={loading} />
        ) : (
          <React.Fragment>
            <div className="counter">
              <span className="number">
                {userList?.reduce((a, b) => {
                  return b.active ? a + 1 : a;
                }, 0)}
              </span>
              <span className="description">{t('GLOBAL.USER(S)')}</span>
              <Button
                style={{ marginTop: '10px' }}
                type={toggleAllUsers ? 'default' : 'primary'}
                onClick={toggleCheckAll}
              >
                {toggleAllUsers ? t('GLOBAL.SELECT_ALL_USERS') : t('GLOBAL.DESELECT_ALL_USERS')}
              </Button>
            </div>
            <Input
              placeholder={t('GLOBAL.SEARCH')}
              onChange={(e) => setSearch(e.target.value)}
              style={{ marginBottom: 10 }}
            />
            <ul className="user-list">
              {filteredUserList?.map((user) => (
                <li key={user.recordId}>
                  <Checkbox checked={user.active} onClick={() => onCheck(user.recordId)}>
                    {user.displayName}
                  </Checkbox>{' '}
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}
        <div className="actions-container">
          <div className="actions">
            <Button
              type="primary"
              danger
              onClick={() => {
                onClose();
              }}
            >
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button disabled={loading} loading={isSaving} type="primary" onClick={onSubmit}>
              {t('GLOBAL.MODIFY')}
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default styled(DrawerAssignUsers)`
  .counter {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: column;
    margin-bottom: 20px;
    color: ${colors.green};

    .number {
      font-size: 3em;
      line-height: 0.9em;
      font-weight: bold;
    }
  }

  .user-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 5px 0;
    }
  }
`;
