import AppContext from '@/pages/app/context';
import { IDepartment } from '@/types/department.model';
import { DIMONA_WORK_TYPES } from '@/types/dimona.model';
import { FEATURES } from '@/types/features.model';
import { REGIME_TYPES, isFeatureEnabled } from '@/utils';
import { Checkbox, Col, Form, InputNumber, Row, Select } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IResponse } from '.';

interface Props {
  className?: string;
  department?: IDepartment;
  meta?: IResponse['meta'];
}

const Settings: React.FC<Props> = ({ className, department, meta }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: true });
  const [weekDays, setWeekdays] = useState(moment.weekdays());
  const {
    state: { features },
  } = useContext(AppContext);

  const freemium = department?.accountType == 'FREE-CLOCKING';

  useEffect(() => {
    setWeekdays(moment.weekdays(true));
  }, [i18n.language]);

  return (
    <div className={className}>
      <h4>{t('USERS.USER.SETTINGS.HR_SETTINGS')}</h4>
      <Row gutter={[20, 10]}>
        <Col lg={8} md={12} style={{ width: '100%', display: 'flex', gap: 10 }}>
          <Form.Item name={'price'} label={<span style={{ fontSize: 12 }}>{t('GLOBAL.EMPLOYEE_COST')}</span>}>
            <InputNumber
              style={{ width: '100%' }}
              // disabled={response?.params?.price_disabled}
              min={0}
              step="0.0001"
              precision={4}
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
              disabled={freemium}
            />
          </Form.Item>
          <Form.Item name={'price_bruto'} label={<span style={{ fontSize: 12 }}>{t('GLOBAL.EMPLOYER_COST')}</span>}>
            <InputNumber
              style={{ width: '100%' }}
              // disabled={response?.params?.price_disabled}
              min={0}
              step="0.0001"
              precision={4}
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
              disabled={freemium}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={12} style={{ width: '100%' }}>
          <Form.Item name={'hr_days_week'} label={`${t('GLOBAL.DAYS_WORKED_BY_WEEK')} :`}>
            <InputNumber
              style={{ width: '100%' }}
              // disabled={response?.params?.hr_days_week_disabled}
              min={0}
              step="any"
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
              disabled={freemium}
            />
          </Form.Item>
        </Col>
        <Col lg={8} md={12} style={{ width: '100%' }}>
          <Form.Item name={'hr_max_hours_week'} label={`${t('GLOBAL.HOURS_NUMBER_BY_WEEK')} :`}>
            <InputNumber
              style={{ width: '100%' }}
              // disabled={response?.params?.hr_max_hours_week_disabled}
              min={0}
              step="any"
              parser={(value) => {
                return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
              }}
              disabled={freemium}
            />
          </Form.Item>
        </Col>
        <>
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'hr_max_hours_year'} label={`${t('USERS.USER.SETTINGS.MAX_HOURS_BY_YEAR')} :`}>
              <InputNumber type="number" min={0} step="any" style={{ width: '100%' }} disabled={freemium} />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'hr_max_hours_trimester'} label={`${t('USERS.USER.SETTINGS.MAX_HOURS_BY_QUARTER')} :`}>
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                step="any"
                parser={(value) => {
                  return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                }}
                disabled={freemium}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'wage_type'} label={t('USERS.USER.HR.WAGE_TYPE')}>
              <Select style={{ width: '100%' }} disabled={freemium}>
                {meta?.['wage_type']?.map(({ id, name }) => (
                  <Select.Option key={`wage_type_${id}`} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </>
        {department?.countryCode == 'BE' && isFeatureEnabled(features, FEATURES.DIMONA) && (
          <>
            <Col lg={8} md={12} style={{ width: '100%' }}>
              <Form.Item name={'worker_type'} label={t('DIMONA.DIMONA_STATUS')}>
                <Select
                  allowClear={true}
                  getPopupContainer={(trigger) => trigger}
                  placeholder={t('FORMS.WORKER_TYPE_PLACEHOLDER')}
                  disabled={freemium}
                >
                  {DIMONA_WORK_TYPES.map((workType) => {
                    const { id } = workType;
                    return (
                      <Select.Option key={`workType_${id}`} value={id!}>
                        {t(`DIMONA.WORKTYPES.${id}`)}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8} md={12} style={{ width: '100%' }}>
              <Form.Item name={'default_dimona_hours'} label={`${t('USERS.USER.SETTINGS.DEFAULT_DIMONA_HOURS')} :`}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  step="1"
                  parser={(value) => {
                    return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                  }}
                  disabled={freemium}
                />
              </Form.Item>
            </Col>
          </>
        )}
        <Col lg={8} md={12} style={{ width: '100%' }}>
          <Form.Item
            name="regime"
            label={<span style={{ display: 'flex', alignItems: 'center' }}>{t('GLOBAL.REGIME')}</span>}
          >
            <Select
              allowClear
              getPopupContainer={(trigger) => trigger}
              placeholder={t('GLOBAL.REGIME')}
              disabled={freemium}
            >
              {REGIME_TYPES.map((type) => {
                return (
                  <Select.Option key={`regime_${type}`} value={type!}>
                    {t(`CONTRACTS.REGIME_TYPES.${type}`)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <br />
      <h4>{t('HR.LEAVE_AND_RECOVERY_SETTINGS')}</h4>
      <Row gutter={[20, 10]}>
        {department?.params?.worktimeCalculationMethod == 'HOURS' ? (
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'legal_leaves'} label={t('HR.HOURS_OF_LEGAL_LEAVE')}>
              <InputNumber style={{ width: '100%' }} type="number" step={1} precision={0} disabled={freemium} />
            </Form.Item>
          </Col>
        ) : (
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'legal_leaves'} label={t('HR.DAYS_OF_LEGAL_LEAVE')}>
              <InputNumber style={{ width: '100%' }} type="number" step={1} precision={0} disabled={freemium} />
            </Form.Item>
          </Col>
        )}
        {department?.params?.worktimeCalculationMethod == 'HOURS' ? (
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'rtt'} label={t('HR.RTT_HOURS')}>
              <InputNumber style={{ width: '100%' }} type="number" step={1} precision={0} disabled={freemium} />
            </Form.Item>
          </Col>
        ) : (
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'rtt'} label={t('HR.RTT_DAYS')}>
              <InputNumber style={{ width: '100%' }} type="number" step={1} precision={0} disabled={freemium} />
            </Form.Item>
          </Col>
        )}
        {department?.params?.worktimeCalculationMethod == 'HOURS' ? (
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'previous_recovery'} label={t('HR.PREVIOUS_YEAR_RECOVERY_HOURS')}>
              <InputNumber style={{ width: '100%' }} type="number" step={1} precision={0} disabled={freemium} />
            </Form.Item>
          </Col>
        ) : (
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'previous_recovery'} label={t('HR.PREVIOUS_YEAR_RECOVERY_DAYS')}>
              <InputNumber style={{ width: '100%' }} type="number" step={1} precision={0} disabled={freemium} />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={[20, 10]}>
        {department?.params?.worktimeCalculationMethod == 'HOURS' ? (
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'extra_legal_days_off'} label={t('HR.EXTRA_LEGAL_HOURS_OFF')}>
              <InputNumber style={{ width: '100%' }} type="number" step={1} precision={0} disabled={freemium} />
            </Form.Item>
          </Col>
        ) : (
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'extra_legal_days_off'} label={t('HR.EXTRA_LEGAL_DAYS_OFF')}>
              <InputNumber style={{ width: '100%' }} type="number" step={1} precision={0} disabled={freemium} />
            </Form.Item>
          </Col>
        )}
        <Col lg={8}></Col>
        {department?.params?.worktimeCalculationMethod == 'HOURS' ? (
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'recovery_hours'} label={t('HR.RECOVERY_HOURS_(TO DATE)')}>
              <InputNumber style={{ width: '100%' }} type="number" step={1} precision={0} disabled={freemium} />
            </Form.Item>
          </Col>
        ) : (
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item name={'recovery_hours'} label={t('HR.RECOVERY_DAYS_(TO DATE)')}>
              <InputNumber style={{ width: '100%' }} type="number" step={1} precision={0} disabled={freemium} />
            </Form.Item>
          </Col>
        )}
      </Row>
      <br />
      {department?.params?.fullHrTab && (
        <React.Fragment>
          <h4>{t('HR.FLEXIBILITY_AND_ALLOWANCES')}</h4>
          <Row gutter={[20, 10]}>
            <Col lg={8} md={12} style={{ width: '100%' }}>
              <Form.Item name="high_flexibility" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox disabled={freemium}>{t('HR.ENABLE_HIGH_FLEXIBILITY')}</Checkbox>
              </Form.Item>
            </Col>
            <Col lg={8} md={12} style={{ width: '100%' }}>
              <Form.Item name="cash_benefits" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox disabled={freemium}>{t('HR.ENABLE_CASH_BENEFITS')}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
};

export default styled(Settings)`
  .weekdays-container {
    display: flex;
  }
`;
