import AppContext from '@/pages/app/context';
import { IReportTemplate, IReportTemplateColumn } from '@/types/report-template.model';
import { getWindowSize, handleError } from '@/utils';
import { Button, Drawer, Form, Input } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  visible: boolean;
  reports: IReportTemplate[];
  reportColumns: IReportTemplateColumn[];
  setActiveReport: React.Dispatch<React.SetStateAction<IReportTemplate | null>>;
  setReports: React.Dispatch<React.SetStateAction<IReportTemplate[]>>;
  setReportColumns: React.Dispatch<React.SetStateAction<IReportTemplateColumn[]>>;
  onClose: () => void;
}

const DrawerTimesheetReport: React.FC<Props> = ({
  className,
  visible,
  reports,
  reportColumns,
  setActiveReport,
  setReports,
  setReportColumns,
  onClose,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onFinish = (values: any) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/timesheet-reports/template`,
        {
          name: values.name,
          active: true,
          columns: [],
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(({ data }) => {
        setActiveReport(data);
        setReportColumns([]);
        onClose();
      })
      .catch((err) => {
        handleError(err);
      });
  };

  return (
    <Drawer
      forceRender={true}
      title={t('REPORT_TEMPLATES.CREATE')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="name" label={t('REPORT_TEMPLATES.REPORT_NAME')} rules={[{ required: true }]}>
          <Input placeholder={t('REPORT_TEMPLATES.REPORT_NAME')} />
        </Form.Item>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerTimesheetReport;
