import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { WIDGETS } from '@/types/widget.model';
import { formatPrice, isFeatureEnabled, isNullOrUndefined, minutesToHoursAndOrMinutes } from '@/utils';
import { Spin } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ModalYesterdayTurnover from '../components/ModalYesterdayTurnover';
import Clockings from './components/Clockings';
import Dayoff from './components/Dayoff';
import Dimona from './components/Dimona';
import DragItem from './components/DragItem';
import Geolocation from './components/Geolocation';
import DashboardHeader from './components/Header';
import OpenShifts from './components/OpenShifts';
import ScheduleToday from './components/ScheduleToday';
import ScheduleTomorrow from './components/ScheduleTomorrow';
import ShiftsPeriod from './components/ShiftsPeriod';
import ShiftsToApprove from './components/ShiftsToApprove';
import ShiftsToCheck from './components/ShiftsToCheck';
import Turnover from './components/Turnover';
import UserStatuses from './components/UserStatuses';
import Weather from './components/Weather';
import { ActionType } from './redux/actions';
import { InitialStateType, store } from './redux/store';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  showModalTrialOver: boolean;
}

const DashboardProvider: React.FC<Props> = ({ showModalTrialOver }) => {
  return (
    <Provider store={store}>
      <DashboardPageStyled showModalTrialOver={showModalTrialOver} />
    </Provider>
  );
};

interface IDashboardData {
  shifts?: number;
  totalMinutes?: number;
  totalCosts?: number;
  totalMoney?: number;
  productivity?: number;
  people?: number;
  stressLevel?: number;
}

const DashboardPage: React.FC<Props> = ({ className, showModalTrialOver }) => {
  const {
    state: { activeDepartment, activeSection, features },
  } = useContext(AppContext);

  const {
    t,
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });
  const [dashboardData, setDashboardData] = useState<IDashboardData>({});
  const [dashboardDataIsLoading, setDashboardDataIsLoading] = useState<boolean>(false);
  const [lastTurnoverTimestamp, setLastTurnoverTimestamp] = useState<number | null>(null);

  const { widgets, selectedDate, startDate, endDate } = useSelector(
    ({ widgets, selectedDate, startDate, endDate }: InitialStateType) => ({
      widgets,
      selectedDate,
      startDate,
      endDate,
    }),
    shallowEqual,
  );

  const dashboardDispatch: Dispatch<ActionType> = useDispatch();

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    if (mounted) {
      if (!activeDepartment) {
        return;
      }
      dashboardDispatch({
        type: 'SET_WIDGETS',
        payload: [],
      });
      dashboardDispatch({
        type: 'SET_WIDGETS_ARE_LOADING',
        payload: true,
      });
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/widgets`, {
        params: {
          departmentId: activeDepartment?.id,
        },
        cancelToken: cancelTokenSource.token,
      })
        .then((response) => {
          dashboardDispatch({
            type: 'SET_WIDGETS',
            payload: response.data,
          });
          dashboardDispatch({
            type: 'SET_WIDGETS_ARE_LOADING',
            payload: false,
          });
        })
        .catch((error) => {
          dashboardDispatch({
            type: 'SET_WIDGETS_ARE_LOADING',
            payload: false,
          });
        });

      if (activeDepartment?.role === 'ADMIN' && activeDepartment.params?.enableTurnover) {
        const hideTurnover = localStorage.getItem(`${activeDepartment?.id}_hideTurnover`);
        if (hideTurnover !== moment().format('YYYY-MM-DD')) {
          Axios.get(`${process.env.REACT_APP_API_URL}/v3/insights/daily-turnover/last`, {
            params: {
              departmentId: activeDepartment?.id,
              sectionId: activeSection,
            },
            cancelToken: cancelTokenSource.token,
          })
            .then(({ data }) => {
              if (mounted) {
                setLastTurnoverTimestamp(data.timestamp);
              }
            })
            .catch(() => {
              if (mounted) {
                setLastTurnoverTimestamp(null);
              }
            });
        }
      }
    }

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartment]);

  useEffect(() => {
    dashboardDispatch({
      type: 'SET_START_DATE',
      payload: moment(selectedDate).startOf('isoWeek'),
    });
    dashboardDispatch({
      type: 'SET_END_DATE',
      payload: moment(selectedDate).endOf('isoWeek'),
    });
  }, [selectedDate]);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      // DASHBOARD TOP DATA
      setDashboardDataIsLoading(true);
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/dashboard`, {
        params: {
          departmentId: activeDepartment?.id,
          sectionId: activeSection,
          start: startDate.unix(),
          end: endDate.unix(),
        },
        cancelToken: cancelTokenSource.token,
      })
        .then((response) => {
          if (mounted) {
            setDashboardData(response.data);
            setDashboardDataIsLoading(false);
          }
        })
        .catch((error) => {
          if (mounted) {
            setDashboardDataIsLoading(false);
          }
        });
    }

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartment, startDate.unix(), endDate.unix(), activeSection]);

  return (
    <Provider store={store}>
      <Helmet>
        <title>{t('GLOBAL.DASHBOARD')} - Shyfter</title>
      </Helmet>
      <div className={className}>
        <DashboardHeader
          startDate={startDate}
          endDate={endDate}
          selectedDate={selectedDate}
          widgets={widgets}
          departmentId={activeDepartment?.id}
        />
        <Spin spinning={dashboardDataIsLoading} size="large">
          <div className="indicators">
            {isFeatureEnabled(features, FEATURES.SCHEDULE) && !isNullOrUndefined(dashboardData.shifts) && (
              <div className="indicator">
                <div className="card-shadow center">
                  <h4>{t('GLOBAL.SHIFTS')}</h4>
                  <p>{dashboardData.shifts || '--'}</p>
                </div>
              </div>
            )}
            {isFeatureEnabled(features, FEATURES.SCHEDULE) && !isNullOrUndefined(dashboardData.totalMinutes) && (
              <div className="indicator">
                <div className="card-shadow center">
                  <h4>{t('GLOBAL.HOURS')}</h4>
                  <p>{dashboardData.totalMinutes ? minutesToHoursAndOrMinutes(dashboardData.totalMinutes) : '--'}</p>
                </div>
              </div>
            )}
            {!isNullOrUndefined(dashboardData.totalCosts) && (
              <div className="indicator">
                <div className="card-shadow center">
                  <h4>{t('GLOBAL.COST')}</h4>
                  <p>
                    {dashboardData.totalCosts
                      ? new Intl.NumberFormat(language, {
                          style: 'currency',
                          currency: activeDepartment?.currency || 'EUR',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(dashboardData.totalCosts)
                      : '--'}
                  </p>
                </div>
              </div>
            )}
            {dashboardData.totalMoney && (
              <div className="indicator">
                <div className="card-shadow center">
                  <h4>{t('GLOBAL.TURNOVER')}</h4>
                  <p>{formatPrice(dashboardData.totalMoney)}</p>
                </div>
              </div>
            )}
            {isFeatureEnabled(features, FEATURES.PRODUCTIVITY) && !isNullOrUndefined(dashboardData.productivity) && (
              <div className="indicator">
                <div className="card-shadow center">
                  <h4>{t('DASHBOARD.HEADER.PRODUCTIVITY')}</h4>
                  <p>
                    {dashboardData.productivity
                      ? typeof dashboardData.productivity == 'string'
                        ? Math.round(Number((dashboardData.productivity as string).replace(',', '')))
                        : Math.round(dashboardData.productivity)
                      : '--'}
                  </p>
                </div>
              </div>
            )}
            {!isNullOrUndefined(dashboardData.stressLevel) && (
              <div className="indicator">
                <div className="card-shadow center">
                  <h4>{t('DASHBOARD.HEADER.STRESS_LEVEL')}</h4>
                  <p>{dashboardData.stressLevel ? Math.round(dashboardData.stressLevel) : '--'}</p>
                </div>
              </div>
            )}
          </div>
        </Spin>
        <DndProvider debugMode={true} backend={HTML5Backend}>
          <div className="grid">
            {widgets
              .filter((widget) => widget.visible)
              .map((widget, index) => {
                let component: any;
                let canDrag = true;
                switch (widget.id) {
                  case WIDGETS.TURNOVER: {
                    if (!isFeatureEnabled(features, FEATURES.PRODUCTIVITY)) break;
                    component = <Turnover departmentId={activeDepartment?.id} sectionId={activeSection} />;
                    break;
                  }
                  case WIDGETS.SCHEDULE_TODAY: {
                    if (!isFeatureEnabled(features, FEATURES.SCHEDULE)) break;
                    component = <ScheduleToday departmentId={activeDepartment?.id} sectionId={activeSection} />;
                    break;
                  }
                  case WIDGETS.SCHEDULE_TOMORROW: {
                    if (!isFeatureEnabled(features, FEATURES.SCHEDULE)) break;
                    component = <ScheduleTomorrow departmentId={activeDepartment?.id} sectionId={activeSection} />;
                    break;
                  }
                  case WIDGETS.OPENSHIFTS: {
                    if (!isFeatureEnabled(features, FEATURES.FREESHIFTS)) break;
                    component = <OpenShifts departmentId={activeDepartment?.id} sectionId={activeSection} />;
                    break;
                  }
                  case WIDGETS.DIMONA: {
                    if (!isFeatureEnabled(features, FEATURES.DIMONA)) break;
                    component = <Dimona departmentId={activeDepartment?.id} />;
                    break;
                  }
                  case WIDGETS.SHIFTS_CHECK: {
                    component = <ShiftsToCheck departmentId={activeDepartment?.id} sectionId={activeSection} />;
                    break;
                  }
                  case WIDGETS.SHIFTS_TO_APPROVE: {
                    component = <ShiftsToApprove departmentId={activeDepartment?.id} sectionId={activeSection} />;
                    break;
                  }
                  case WIDGETS.DAYOFF: {
                    if (!isFeatureEnabled(features, FEATURES.LEAVE_MANAGEMENT)) break;
                    component = <Dayoff departmentId={activeDepartment?.id} sectionId={activeSection} />;
                    break;
                  }
                  case WIDGETS.CLOCKINGS: {
                    if (!isFeatureEnabled(features, FEATURES.CLOCKING)) break;
                    component = <Clockings departmentId={activeDepartment?.id} sectionId={activeSection} />;
                    break;
                  }
                  case WIDGETS.SHIFTS_PERIOD: {
                    if (!isFeatureEnabled(features, FEATURES.SCHEDULE)) break;
                    component = (
                      <ShiftsPeriod
                        departmentId={activeDepartment?.id}
                        startDate={startDate}
                        endDate={endDate}
                        sectionId={activeSection}
                      />
                    );
                    break;
                  }
                  case WIDGETS.USER_TYPE_GRAPH: {
                    component = (
                      <UserStatuses departmentId={activeDepartment?.id} startDate={startDate} endDate={endDate} />
                    );
                    break;
                  }
                  case WIDGETS.GEOLOC: {
                    canDrag = false;
                    component = <Geolocation departmentId={activeDepartment?.id} sectionId={activeSection} />;
                    break;
                  }
                  case WIDGETS.WEATHER: {
                    component = <Weather departmentId={activeDepartment?.id} />;
                    break;
                  }
                }
                if (!component) return;
                return (
                  <DragItem
                    key={`WIDGET_${index}`}
                    id={widget.id!}
                    position={widget.position!}
                    className="drag-item"
                    widgets={widgets}
                    departmentId={activeDepartment?.id}
                    canDrag={canDrag}
                  >
                    {component}
                  </DragItem>
                );
              })}
          </div>
        </DndProvider>
        {!showModalTrialOver &&
          activeDepartment?.role === 'ADMIN' &&
          isFeatureEnabled(features, FEATURES.PRODUCTIVITY) &&
          !dashboardDataIsLoading && (
            <ModalYesterdayTurnover
              departmentId={activeDepartment?.id}
              lastTurnover={lastTurnoverTimestamp}
              onClose={() => setLastTurnoverTimestamp(null)}
            />
          )}
      </div>
    </Provider>
  );
};

const DashboardPageStyled = styled(DashboardPage)`
  background-color: ${colors.blueExtraLight};
  min-height: calc(100vh - 50px);

  .indicators {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .indicator {
      display: flex;
      padding: 10px 20px;
      flex: 1;
      min-width: 160px;

      .card-shadow {
        width: 100%;
      }
    }
  }

  .grid {
    display: flex;
    flex-wrap: wrap;

    .drag-item {
      width: 33.333%;
    }
  }

  .isDragging .chart-container {
    display: none;
  }

  .isDragging .leaflet-container {
    display: none;
  }

  .card-shadow {
    justify-content: flex-start;
    position: relative;

    &.center {
      p {
        font-size: 24px;
        line-height: 24px;
        margin: 0;
        padding: 0;
        color: ${colors.grey};
      }
    }

    &.widget {
      h4 {
        margin: 0 -20px 10px;
        padding: 0 20px 10px;
        border-bottom: 1px solid ${colors.greyLight};
      }
    }

    .ant-table-wrapper {
      max-height: 200px;
      overflow: auto;
    }

    .anticon-close {
      position: absolute;
      right: 20px;
    }
  }

  @media screen and (max-width: 1350px) {
    .indicators {
    }

    .grid {
      .drag-item {
        width: 50%;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .indicators {
      flex-direction: column;
    }

    .grid {
      flex-direction: column;

      .drag-item {
        width: 100%;
      }
    }

    .card-shadow {
      padding-top: 25px;
      padding-bottom: 25px;
      h4 {
        margin: 0;
      }
    }

    .widget {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
`;

export default DashboardProvider;
