import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled } from '@/utils';
import { Divider } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ShiftAttributesStyled from '../../manage/components/ShiftAttributes';
import ShiftSectionBar from '../../manage/components/ShiftSectionBar';
import ShiftTagsStyled from '../../manage/components/ShiftTags';
import ShiftTasksStyled from '../../manage/daily/components/ShiftTasks';
import ShiftDetailsIcons from './ShiftDetailsIcons';
import ShiftPeriod from './ShiftPeriod';

interface Props {
  className?: string;
  shift: any;
  department: IDepartment;
  section?: string;
}

const Shift: React.FC<Props> = ({ className, shift, section, department }) => {
  const history = useHistory();
  const {
    state: { activeDepartment, features },
  } = useContext(AppContext);
  const { start: shiftStart, end: shiftEnd, price, pause } = shift;
  const duration = moment.duration(shiftEnd! - shiftStart!, 'seconds');
  duration.subtract(pause?.unpaid!, 'seconds');
  const durationInMinutes = duration.asMinutes();
  const pauseDurationInMinutes = moment.duration(pause?.paid! + pause?.unpaid!, 'seconds').asMinutes();

  const onClick = () => {
    history.push(
      `/app/hours/manage/${
        activeDepartment?.scheduleParams?.default_schedule_view == 'ops'
          ? 'operational'
          : activeDepartment?.scheduleParams?.default_schedule_view
      }/${moment.unix(shiftStart).format('YYYY-MM-DD')}`,
    );
  };

  return (
    <div className={`${className} card`} onClick={onClick}>
      {isFeatureEnabled(features, FEATURES.SECTIONS) && shift.section?.id && (
        <ShiftSectionBar section={shift.section} />
      )}
      {!shift.dayoff && (
        <React.Fragment>
          <span>{moment.unix(shiftStart).format('L')}</span>
          <ShiftPeriod start={shiftStart!} end={shiftEnd!} />
          {isFeatureEnabled(features, FEATURES.TASKS) && (
            <ShiftTasksStyled tasks={shift.tasks || []} shiftId={shift.id!} />
          )}
          <div className="shift-details">
            <ShiftDetailsIcons
              hidePrice={!activeDepartment?.scheduleParams?.showShiftPrice}
              currency={department?.currency}
              durationInMinutes={durationInMinutes}
              pauseDurationInMinutes={pauseDurationInMinutes}
              price={price!}
              clockings={shift.clocking}
            />
          </div>
          <ShiftTagsStyled
            picker="week"
            section={
              isFeatureEnabled(features, FEATURES.SECTIONS) && section && section !== shift.section?.id
                ? shift.section
                : undefined
            }
            skills={isFeatureEnabled(features, FEATURES.SKILLS) ? shift.skills || [] : []}
          />
          <ShiftAttributesStyled attributes={shift.attributes || []} />
          {shift.comment && <Divider plain style={{ margin: '10px 0 5px 0' }} />}
          {shift.comment}
        </React.Fragment>
      )}
    </div>
  );
};

export default styled(Shift)`
  padding: 10px;
  border: 1px solid ${colors.greyLight};
  border-radius: 10px;
  background-color: #fafafa;
  cursor: pointer;
  max-width: 250px;

  .shift-details {
    font-size: 10px;
    color: gray;
    font-weight: 100 !important;
  }
`;
