import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { handleError } from '@/utils';
import { Form, InputNumber, Select, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SettingsForm from '../../components/SettingsForm';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const ClockingNotifications: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<(string | null)[]>([]);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const updateForm = (c: any, e: any, name: any) => {
    setChecked(c ? [...checked, name] : checked.filter((el) => el != name));
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];
  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_NO_CHECK_IN.LABEL'),
          description: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_NO_CHECK_IN.DESCRIPTION'),
          name: ['clockin', 'clockin_warning_manager'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.clockin?.clockin_warning_manager}
              onChange={(checked, e) => updateForm(checked, e, 'clockin_warning_manager')}
            />
          ),
          show: true,
        },
        {
          label: '',
          description: '',
          style: { margin: 0 },
          element: (form: any) => {
            const show = form.getFieldValue(['clockin', 'clockin_warning_manager']);
            if (show) {
              return (
                <div className="form-items-group one-line" style={{ marginTop: -25 }}>
                  <Form.Item name={['clockin', 'clockin_warning_manager_tresh']}>
                    <InputNumber type="number" min={1} step={1} style={{ width: 60 }} />
                  </Form.Item>
                  <span>{t('GLOBAL.MINUTES')}</span>
                  <Form.Item name={['clockin', 'clockin_warning_manager_type']}>
                    <Select style={{ width: '100%' }}>
                      <Select.Option value={1}>{t('GLOBAL.AFTER')}</Select.Option>
                      <Select.Option value={2}>{t('GLOBAL.BEFORE')}</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              );
            }
          },
          elementLeft: true,
          show: checked.includes('clockin_warning_manager'),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.clockin_warning_manager !== curValues.clockin?.clockin_warning_manager;
          },
        },
        {
          label: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_NO_CHECK_OUT.LABEL'),
          description: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_NO_CHECK_OUT.DESCRIPTION'),
          name: ['clockin', 'clockout_warning_manager'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.clockin?.clockout_warning_manager}
              onChange={(checked, e) => updateForm(checked, e, 'clockout_warning_manager')}
            />
          ),
          show: true,
        },
        {
          label: '',
          description: '',
          style: { margin: 0 },
          element: (form: any) => {
            const show = form.getFieldValue(['clockin', 'clockout_warning_manager']);
            if (show) {
              return (
                <div className="form-items-group one-line" style={{ marginTop: -25 }}>
                  <Form.Item name={['clockin', 'clockout_warning_manager_tresh']}>
                    <InputNumber type="number" min={5} />
                  </Form.Item>
                  <span>{t('SETTINGS.ACCOUNT.CLOCKING.CLOCKING_OUT_WARNING.MINUTES_AFTER_SHIFT_END')}</span>
                </div>
              );
            }
          },
          elementLeft: true,
          show: checked.includes('clockout_warning_manager'),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.clockout_warning_manager !== curValues.clockin?.clockout_warning_manager;
          },
        },
        {
          label: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_BEFORE_END_SHIFT.LABEL'),
          description: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_BEFORE_END_SHIFT.DESCRIPTION'),
          name: ['clockin', 'clockout_warning'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.clockin?.clockout_warning}
              onChange={(checked, e) => updateForm(checked, e, 'clockout_warning')}
            />
          ),
          show: true,
        },
        {
          label: '',
          description: '',
          style: { margin: 0 },
          element: (form: any) => {
            const show = form.getFieldValue(['clockin', 'clockout_warning']);
            if (show) {
              return (
                <div className="form-items-group one-line" style={{ marginTop: -25 }}>
                  <Form.Item name={['clockin', 'clockout_warning_tresh']}>
                    <InputNumber type="number" min={5} />
                  </Form.Item>
                  <span>{t('SETTINGS.ACCOUNT.CLOCKING.CLOCKING_OUT_WARNING.MINUTES_BEFORE_SHIFT_END')}</span>
                </div>
              );
            }
          },
          elementLeft: true,
          show: checked.includes('clockout_warning'),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.clockout_warning !== curValues.clockin?.clockout_warning;
          },
        },
        {
          label: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_FORGOT_CHECK_OUT.LABEL'),
          description: t('SETTINGS.CLOCKING.NOTIFICATIONS.ALERT_FORGOT_CHECK_OUT.DESCRIPTION'),
          name: ['clockin', 'clockout_warning_late'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.clockin?.clockout_warning_late}
              onChange={(checked, e) => updateForm(checked, e, 'clockout_warning_late')}
            />
          ),
          show: true,
        },
        {
          label: '',
          description: '',
          style: { margin: 0 },
          element: (form: any) => {
            const show = form.getFieldValue(['clockin', 'clockout_warning_late']);
            if (show) {
              return (
                <div className="form-items-group one-line" style={{ marginTop: -25 }}>
                  <Form.Item name={['clockin', 'clockout_warning_late_tresh']}>
                    <InputNumber type="number" min={5} />
                  </Form.Item>
                  <span>{t('SETTINGS.ACCOUNT.CLOCKING.CLOCKING_OUT_WARNING.MINUTES_AFTER_SHIFT_END')}</span>
                </div>
              );
            }
          },
          elementLeft: true,
          show: checked.includes('clockout_warning_late'),
          shouldUpdate: (prevValues, curValues) => {
            return prevValues.clockin?.clockout_warning_late !== curValues.clockin?.clockout_warning_late;
          },
        },
        {
          label: t('SETTINGS.CLOCKING.NOTIFICATIONS.AUTO_CLOSE_CLOCKINGS.LABEL'),
          description: t('SETTINGS.CLOCKING.NOTIFICATIONS.AUTO_CLOSE_CLOCKINGS.DESCRIPTION'),
          name: ['clockin', 'clocking_forgot'],
          valuePropName: 'checked',
          style: { margin: 0 },
          element: (
            <Switch
              defaultChecked={currentSettings?.clockin?.clocking_forgot}
              onChange={(checked, e) => updateForm(checked, e, 'clocking_forgot')}
            />
          ),
          show: true,
        },
      ],
    ],
  ];

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    if (currentSettings?.clockin) {
      setChecked(
        Object.entries(currentSettings?.clockin).map(([key, value]: any) =>
          value == true || (value !== undefined && value > 1) ? key : null,
        ),
      );
    }
    form.setFieldsValue(currentSettings);
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings(values)
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.CLOCKING.NOTIFICATIONS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(ClockingNotifications)``;
