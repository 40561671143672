import DrawerUser from '@/pages/app/components/drawers/User';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IUser } from '@/types/user.model';
import { Layout, List, message, Tabs } from 'antd';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import UserListItem from './UserListItem';
import UserListSearch from './UserListSearch';

const { Sider } = Layout;

interface Props {
  className?: string;
  user: IUser | undefined;
  currentTab: string;
}

const UserList: React.FC<Props> = ({ className, user, currentTab }) => {
  const {
    state: { activeDepartment, activeDepartmentId, activeSection },
    dispatch,
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [users, setUsers] = useState<IUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<'ACTIVE' | 'INACTIVE' | string>('ACTIVE');
  const [filter, setFilter] = useState<string>('');
  const [showCreateUserDrawer, setShowCreateUserDrawer] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (user?.pending) {
      setActiveTab('INACTIVE');
    }
  }, [user]);

  const onRefChange = useCallback((node) => {
    if (node !== null) {
      (node as HTMLDivElement).scrollIntoView(true);
    }
  }, []);

  useEffect(() => {
    if (!activeDepartmentId) {
      return;
    }

    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setUsers([]);
    setLoadingUsers(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users`, {
        params: {
          departmentId: activeDepartmentId,
          inactive: activeTab === 'INACTIVE' ? true : undefined,
          sectionId: activeSection ? activeSection : undefined,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        if (mounted) {
          const { data } = response;
          const existingUser =
            user && data.users ? data.users.find((u: IUser) => u.recordId == user.recordId && user.active) : true;
          if (!existingUser && activeTab != 'INACTIVE') {
            history.push('/app/team/collaborators/all');
          }
          if (activeTab !== 'INACTIVE') {
            dispatch({
              type: 'SET_USERS',
              payload: data.users,
            });
            dispatch({
              type: 'SET_USERS_FIELDS',
              payload: data.fields,
            });
            dispatch({
              type: 'SET_LOADING_USERS',
              payload: false,
            });
          }
          setUsers(data.users);
          setLoadingUsers(false);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error(error);
          message.error(t('USERS.MESSAGE_LOADING_ERROR'));
        }
        if (mounted) {
          dispatch({
            type: 'SET_LOADING_USERS',
            payload: false,
          });
          setLoadingUsers(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId, activeTab, activeSection]);

  useEffect(() => {
    const filteredUsers = users.filter(
      (user) => user.displayName && user.displayName!.toLowerCase().includes(filter.toLowerCase()),
    );
    setFilteredUsers(filteredUsers);
  }, [users, filter]);

  const renderItem = (u: IUser) => {
    const selected = u.recordId === user?.recordId;
    return (
      <div ref={selected ? onRefChange : undefined} style={{ background: selected ? colors.blueLight : undefined }}>
        <UserListItem user={u} currentTab={currentTab} />
      </div>
    );
  };

  const onUserSave = (user: IUser) => {
    const usersCopy = [...users];
    usersCopy.push(user);

    const usersCopySorted = usersCopy.sort((a, b) => {
      if (a.lastname) {
        if (b.lastname) {
          if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) {
            return -1;
          }
          if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) {
            return 1;
          }
          return 0;
        }
        return 1;
      }
      return 0;
    });

    dispatch({
      type: 'SET_USERS',
      payload: usersCopySorted,
    });
    setUsers(usersCopySorted);
    history.push(`/app/team/collaborators/${user.recordId}?newUser=true`);
  };

  return (
    <Sider className={className} width={300} theme="light">
      <Tabs
        style={{ fontSize: 16 }}
        centered
        activeKey={activeTab}
        size="small"
        tabBarStyle={{ margin: 20 }}
        onChange={(value) => setActiveTab(value)}
      >
        <Tabs.TabPane tab={t('USERS.ACTIVE')} key="ACTIVE">
          <UserListSearch
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          />
          <List
            loading={loadingUsers}
            dataSource={filteredUsers}
            header={<UserListItem onClick={() => setShowCreateUserDrawer(true)} />}
            renderItem={renderItem}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('USERS.DISABLED')} key="INACTIVE">
          <UserListSearch
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          />
          <List loading={loadingUsers} dataSource={filteredUsers} renderItem={renderItem} />
        </Tabs.TabPane>
      </Tabs>
      <DrawerUser
        user={null}
        visible={showCreateUserDrawer}
        onClose={() => setShowCreateUserDrawer(false)}
        onSave={onUserSave}
        openDrawer={() => setShowCreateUserDrawer(true)}
      />
    </Sider>
  );
};

export default styled(UserList)`
  .ant-tabs {
    display: flex;
    height: 100%;
    .ant-tabs-tab-btn {
      font-size: 16px;
    }
    .ant-tabs-content-holder {
      height: 100%;
      .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane {
          display: flex;
          flex-direction: column;
          max-height: 100%;
          .ant-list {
            overflow: scroll;
          }
        }
      }
    }
    .ant-list-header {
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;
