import React from 'react';

const Bank = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g fill="none" fillRule="evenodd">
        <path d="M-1-1h32v32H-1z"></path>
        <path
          fill="#ED6E5D"
          fillRule="nonzero"
          d="M15 0l15 6.429V8.57h-2c0 .29-.107.542-.32.754a1.04 1.04 0 01-.758.318H3.078a1.04 1.04 0 01-.758-.318A1.026 1.026 0 012 8.57H0V6.43L15 0zM4 10.714h4v12.857h2V10.714h4v12.857h2V10.714h4v12.857h2V10.714h4v12.857h.922c.292 0 .544.106.758.319.213.212.32.463.32.753v1.071H2v-1.071c0-.29.107-.541.32-.753a1.04 1.04 0 01.758-.319H4V10.714zm24.922 16.072c.292 0 .544.106.758.318.213.212.32.463.32.753V30H0v-2.143c0-.29.107-.541.32-.753a1.04 1.04 0 01.758-.318h27.844z"
        ></path>
      </g>
    </svg>
  );
};

export default Bank;
