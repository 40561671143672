import colors from '@/styles/colors';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { getWindowSize } from '@/utils';
import { Form, Input, List, Select } from 'antd';
import { countryCodeEmoji } from 'country-code-emoji';
import translatedCountries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import nl from 'i18n-iso-countries/langs/nl.json';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import SettingsForm from '../../components/SettingsForm';
import { ISettingsListElement } from '@/types/settings/list-element.model';

translatedCountries.registerLocale(en);
translatedCountries.registerLocale(fr);
translatedCountries.registerLocale(nl);
interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const { Option } = Select;

const BillingDetails: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [countriesTranslated, setCountriesTranslated] = useState<[string, string][]>();
  const [countriesEmojis, setCountriesEmojis] = useState<string[]>([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const countr = translatedCountries.getNames(i18next.language);
    const sorted = Object.entries(countr).sort((a, b) => (a[1].toLowerCase() < b[1].toLowerCase() ? -1 : 1));
    setCountriesEmojis(sorted.map((el) => el[0]).map(countryCodeEmoji));
    setCountriesTranslated(sorted);
    onReset();
  }, [currentSettings]);

  const onReset = () => {
    setFormHasChanged(false);
    form.resetFields();
    let fieldsValue: any = {};
    if (currentSettings) {
      fieldsValue = {
        ...currentSettings,
      };
    }
    form.setFieldsValue(fieldsValue);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings(values)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const elements = [
    {
      title: t('GLOBAL.GENERAL'),
      visible: true,
    },
  ];

  const lists: ISettingsListElement[][][] = [
    [
      [
        {
          label: t('SETTINGS.BILLING.BILLING_DETAILS.COMPANY.LABEL'),
          description: t('SETTINGS.BILLING.BILLING_DETAILS.COMPANY.DESCRIPTION'),
          name: ['account', 'company'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input placeholder={t('GLOBAL.COMPANY')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
        {
          label: t('SETTINGS.BILLING.BILLING_DETAILS.VAT.LABEL'),
          description: t('SETTINGS.BILLING.BILLING_DETAILS.VAT.DESCRIPTION'),
          name: ['account', 'vat'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input placeholder={t('GLOBAL.VAT_NUMBER')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
      ],
      [
        {
          label: t('SETTINGS.BILLING.BILLING_DETAILS.COUNTRY.LABEL'),
          description: t('SETTINGS.BILLING.BILLING_DETAILS.COUNTRY.DESCRIPTION'),
          name: ['account', 'country'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: (
            <Select
              showSearch
              style={{ minWidth: '100%' }}
              placeholder={t('GLOBAL.COUNTRY')}
              filterOption={(input, option) => {
                const label = option!.label! as string;
                return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {countriesTranslated?.map((key, i) => {
                return (
                  <Option value={key[0]} key={key[0]} label={key[1]}>
                    <div className="demo-option-label-item">
                      <span role="img" aria-label={key[1]}>
                        {countriesEmojis[i]}{' '}
                      </span>
                      {key[1]}
                    </div>
                  </Option>
                );
              })}
            </Select>
          ),
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
        {
          label: t('SETTINGS.BILLING.BILLING_DETAILS.ADDRESS.LABEL'),
          description: t('SETTINGS.BILLING.BILLING_DETAILS.ADDRESS.DESCRIPTION'),
          name: ['account', 'address'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input placeholder={t('GLOBAL.ADDRESS')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
        {
          label: t('SETTINGS.BILLING.BILLING_DETAILS.CITY.LABEL'),
          description: t('SETTINGS.BILLING.BILLING_DETAILS.CITY.DESCRIPTION'),
          name: ['account', 'city'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input placeholder={t('GLOBAL.CITY')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
        {
          label: t('SETTINGS.BILLING.BILLING_DETAILS.POSTAL_CODE.LABEL'),
          description: t('SETTINGS.BILLING.BILLING_DETAILS.POSTAL_CODE.DESCRIPTION'),
          name: ['account', 'zip'],
          style: { margin: 0, width: windowSize.innerWidth > 900 ? 265 : '100%' },
          element: <Input placeholder={t('GLOBAL.ZIP')} />,
          elementBelow: windowSize.innerWidth <= 900,
          show: true,
        },
      ],
    ],
  ];

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  return (
    <div className={className}>
      <h2>{t('SETTINGS.BILLING.BILLING_DETAILS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default BillingDetails;
