import AppContext from '@/pages/app/context';
import { IDepartmentSettings } from '@/types/department-settings.model';
import { ISettingsListElement } from '@/types/settings/list-element.model';
import { handleError } from '@/utils';
import { Checkbox, Form, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CalloutStyled from '../../components/Callout';
import SettingsForm from '../../components/SettingsForm';

interface Props {
  className?: string;
  currentSettings?: IDepartmentSettings | null;
  updateCurrentSettings: (settings: IDepartmentSettings) => Promise<boolean>;
}

const AttributesRegistrationForms: React.FC<Props> = ({ className, currentSettings, updateCurrentSettings }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, resources },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<(string | null)[]>([]);

  useEffect(() => {
    onReset();
  }, [currentSettings]);

  const onReset = () => {
    setFormHasChanged(false);
    setChecked([]);
    setChecked(
      currentSettings?.['resources-subscribe']
        ? Object.keys(currentSettings?.['resources-subscribe'] as any)
        : [...checked],
    );
    form.resetFields();
    let fieldsValue: any = {};
    if (currentSettings) {
      fieldsValue = {
        ...currentSettings,
      };
    }
    form.setFieldsValue(fieldsValue);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    updateCurrentSettings(values)
      .then(() => {})
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (c: any, e: any, id: any) => {
    setChecked(c ? [...checked, id] : checked.filter((el) => el != id));
  };

  const onValuesChanged = () => {
    setFormHasChanged(true);
  };

  let lists: ISettingsListElement[][][] = [];
  const elements: {
    title: string;
    visible: boolean;
  }[] = [];

  resources?.forEach(({ id, name, attributes, fields, pending, resourceType }, i) => {
    elements.push({
      title: t('SETTINGS.ACCOUNT.RESOURCES_REGISTRATION.SUBTITLE', { name }),
      visible: true,
    });
    lists = [
      ...lists,
      [
        [
          {
            label: t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.ACTIVATE_FORM.LABEL'),
            description: t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.ACTIVATE_FORM.DESCRIPTION'),
            name: ['resources-subscribe', `${id}`, 'subscribe_active'],
            valuePropName: 'checked',
            style: { margin: 0 },
            element: (
              <Switch
                defaultChecked={
                  currentSettings?.['resources-subscribe'] &&
                  currentSettings?.['resources-subscribe']![id!] &&
                  currentSettings?.['resources-subscribe']![id!].subscribe_active
                    ? true
                    : false
                }
                onChange={(checked, e) => onChange(checked, e, id)}
              />
            ),
            show: true,
            showCallout: true,
            callout: (
              <CalloutStyled style={{ marginBottom: 10 }}>
                <span>
                  {t('SETTINGS.ACCOUNT.RESOURCES_REGISTRATION.ACTIVATION_MESSAGE', { name })}
                  <a href={`/resources-subscribe/${id}`}>
                    {process.env.REACT_APP_APP_URL}/resources-subscribe/{id}
                  </a>
                </span>
              </CalloutStyled>
            ),
          },
          {
            label: t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.FORM_TITLE.LABEL'),
            description: t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.FORM_TITLE.DESCRIPTION'),
            name: ['resources-subscribe', `${id}`, 'subscribe_title'],
            style: { margin: 0 },
            element: <TextArea rows={2} style={{ marginTop: 15 }} />,
            elementBelow: true,
            show: checked.includes(id as string),
          },
          {
            label: t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.FORM_DESCRIPTION.LABEL'),
            description: t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.FORM_DESCRIPTION.DESCRIPTION'),
            name: ['resources-subscribe', `${id}`, 'subscribe_content'],
            style: { margin: 0 },
            element: <TextArea rows={4} style={{ marginTop: 15 }} />,
            elementBelow: true,
            show: checked.includes(id as string),
          },
          {
            label: t('GLOBAL.FIELDS'),
            style: { margin: 0 },
            element: (
              <>
                <Checkbox disabled={true} className="my-checkbox" style={{ lineHeight: '32px' }} defaultChecked={true}>
                  {t('GLOBAL.NAME')}
                </Checkbox>
                {resourceType === 'PLACE' && (
                  <Checkbox
                    disabled={true}
                    className="my-checkbox"
                    style={{ lineHeight: '32px' }}
                    defaultChecked={true}
                  >
                    {t('GLOBAL.ADDRESS')}
                  </Checkbox>
                )}
                <Form.Item
                  name={['resources-subscribe', `${id}`, 'extraFields']}
                  initialValue={fields?.filter((field) => !field.locked && field.mandatory).map((field) => field.id)}
                  style={{ display: 'inline-block' }}
                >
                  <Checkbox.Group>
                    {fields
                      ?.filter((field) => !field.locked)
                      .map((field) => {
                        const { id, label, mandatory } = field;
                        return (
                          <Checkbox
                            disabled={mandatory}
                            className="my-checkbox"
                            key={`field_${id}`}
                            value={`${id}`}
                            style={{ lineHeight: '32px' }}
                          >
                            {label}
                          </Checkbox>
                        );
                      })}
                  </Checkbox.Group>
                </Form.Item>
              </>
            ),
            elementBelow: true,
            show: checked.includes(id as string),
          },
        ],
      ],
    ];
  });

  return (
    <div className={className}>
      <h2>{t('SETTINGS.ATTRIBUTES.REGISTRATION_FORMS.TITLE')}</h2>
      <SettingsForm
        form={form}
        formHasChanged={formHasChanged}
        elements={elements}
        lists={lists}
        loading={loading}
        onFinish={onFinish}
        onReset={onReset}
        onValuesChange={onValuesChanged}
      />
    </div>
  );
};

export default styled(AttributesRegistrationForms)``;
