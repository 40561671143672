import React, { useEffect, useState, useContext } from 'react';
import Header from './components/Header';
import { Table, BackTop, Button } from 'antd';
import TableView from '@/layouts/TableView';
import Axios from 'axios';
import AppContext from '@/pages/app/context';
import { IUser } from '@/types/user.model';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AlignType } from '@/types/alignType.model';
import { useHistory } from 'react-router-dom';
import { handleError } from '@/utils';

const AppTeamPendingCollaboratorsPage: React.FC = () => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const [users, setUsers] = useState<IUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [columns, setColumns] = useState<any[]>([]);
  const history = useHistory();

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.LASTNAME'),
        key: 'lastname',
        dataIndex: 'lastname',
        // eslint-disable-next-line react/display-name
        sorter: (a: IUser, b: IUser) => a.lastname?.localeCompare(b?.lastname || '') || 0,
      },
      {
        title: t('GLOBAL.FIRSTNAME'),
        key: 'firstname',
        dataIndex: 'firstname',
        // eslint-disable-next-line react/display-name
        sorter: (a: IUser, b: IUser) => a.firstname?.localeCompare(b?.firstname || '') || 0,
      },
      {
        title: t('GLOBAL.EMAIL'),
        key: 'email',
        dataIndex: 'email',
        // eslint-disable-next-line react/display-name
        sorter: (a: IUser, b: IUser) => a.email?.localeCompare(b?.email || '') || 0,
      },
      {
        title: t('GLOBAL.PHONE'),
        key: 'phone',
        dataIndex: 'phone',
      },
      {
        key: 'actions',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (_text: string, user: IUser) => (
          <Button
            type="primary"
            onClick={() => history.push(`/app/team/collaborators/${user.recordId}/profile/profile-details`)}
          >
            {t('GLOBAL.TREAT')}
          </Button>
        ),
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    if (!location?.pathname) {
      return;
    }
    let mounted = true;
    getUsers();

    return () => {
      mounted = false;
    };
  }, [location]);

  const getUsers = () => {
    setIsLoading(true);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/users`, {
      params: {
        departmentId: activeDepartmentId,
        pending: true,
      },
    })
      .then((response) => {
        const { users } = response.data;
        setUsers(users);
        setFilteredUsers(users);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error);
      });
  };

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    if (users) {
      const filteredUsers = users.filter((user) => {
        return user?.displayName?.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredUsers(filteredUsers);
    } else {
      setFilteredUsers([]);
    }
  };

  const rowSelection = {
    onChange: (keys: React.Key[], users: IUser[]) => {
      setSelectedUsers(users.map((user) => user.recordId!));
    },
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header
        onSearchChange={onSearchChange}
        disabled={isLoading}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        getUsers={getUsers}
      />
      <Table
        loading={isLoading}
        dataSource={filteredUsers}
        columns={columns}
        rowKey="recordId"
        pagination={false}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    </TableView>
  );
};

export default AppTeamPendingCollaboratorsPage;
