import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
interface Props {
  visible: boolean;
  shiftIds?: string[] | null;
  departmentId?: string | null;
  onClose: () => void;
  onSuccess: (data: any) => void;
}

const ModalDeleteShiftConfirmation: React.FC<Props> = ({ visible, shiftIds, departmentId, onClose, onSuccess }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onFinish = async () => {
    setIsSaving(true);
    if (shiftIds?.length === 1) {
      await axios
        .delete(`${process.env.REACT_APP_API_URL}/v3/users/schedule-models/shifts/${shiftIds[0]}`, {
          params: {
            departmentId,
          },
        })
        .then(({ data }) => {
          setIsSaving(false);
          onSuccess(data);
          onClose();
        })
        .catch((error) => {
          // TODO:
          setIsSaving(false);
          console.log(error);
        });
    }
  };

  if (shiftIds) {
    if (shiftIds.length > 1) {
      return (
        <Modal
          forceRender={true}
          maskClosable={false}
          destroyOnClose={true}
          visible={visible}
          onCancel={onClose}
          className={'modal-danger'}
          title={t('SHIFTS.MODAL_DELETE_SHIFT.TITLE')}
          footer={[
            <Button key="back" onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>,
            <Button key="submit" type="primary" loading={isSaving} onClick={onFinish}>
              {t('SHIFTS.MODAL_DELETE_SHIFT.CONFIRM_BUTTON_FOR_MULTIPLE')}
            </Button>,
          ]}
        ></Modal>
      );
    } else {
      return (
        <Modal
          visible={visible}
          onCancel={onClose}
          className={'modal-danger'}
          title={t('SHIFTS.MODAL_DELETE_SHIFT.TITLE')}
          footer={[
            <Button key="back" onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>,
            <Button key="submit" type="primary" loading={isSaving} onClick={onFinish}>
              {t('SHIFTS.MODAL_DELETE_SHIFT.CONFIRM_BUTTON_FOR_ONE')}
            </Button>,
          ]}
        >
          {shiftIds.length} shift{shiftIds.length > 1 ? 's' : ''} will be deleted
        </Modal>
      );
    }
  }
  return null;
};

export default ModalDeleteShiftConfirmation;
