import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import colors from '@/styles/colors';
import { Button } from 'antd';

interface Props {
  className?: string;
  setDrawerPayPeriodVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<Props> = ({ className, setDrawerPayPeriodVisible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);

  const onCreate = () => {
    setDrawerPayPeriodVisible(true);
  };

  return (
    <div className={className}>
      <div>
        <h2 className="title">{t('REPORTS.PAY_PERIODS.TITLE')}</h2>
        <p className="description">{t('REPORTS.PAY_PERIODS.DESCRIPTION')}</p>
      </div>
      <div className="actions">
        <Button type="primary" onClick={onCreate}>
          {t('REPORTS.PAY_PERIODS.CREATE_PERIOD')}
        </Button>
      </div>
    </div>
  );
};

export default styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-weight: bold;
  }

  .description {
    font-size: 12px;
    color: ${colors.grey};
    margin: 0;
  }
`;
