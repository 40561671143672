import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const OperationalRow: React.FC<Props> = ({ className }) => {
  return <div className={className}></div>;
};

export default styled(OperationalRow)``;
