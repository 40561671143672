import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Form, Button, Select, message, Divider, Checkbox, TimePicker } from 'antd';
import AppContext from '@/pages/app/context';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import Axios from 'axios';
import { IUser } from '@/types/user.model';
import { useTranslation } from 'react-i18next';
import colors from '@/styles/colors';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
  rangeSelection: { startDate: Moment; endDate: Moment; events: any[] } | null;
  setRangeSelection: React.Dispatch<React.SetStateAction<{ startDate: Moment; endDate: Moment; events: any[] } | null>>;
  user?: IUser;
  onSave: () => void;
  onClose: () => void;
}

const ModalRangeSelection: React.FC<Props> = ({ className, rangeSelection, user, onSave, onClose }) => {
  const {
    state: { activeDepartment, loadingDayoffs, dayoffs },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [rangeSelection]);

  const onFinish = (values: any) => {
    setIsSaving(true);

    if (rangeSelection) {
      const { startDate, endDate } = rangeSelection;
      const { fullDay, type, dayOffType } = values;

      const selectedDates = [];

      if (startDate && endDate) {
        const date = moment(startDate);
        const endDateIncr = moment(endDate).add(1, 'day');
        do {
          selectedDates.push(date.format('YYYY-MM-DD'));
          date.add(1, 'day');
        } while (date.isBefore(endDateIncr));

        Axios.post(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/availability`, {
          departmentId: activeDepartment?.id,
          days: selectedDates,
          fullDay,
          type,
          dayOffType,
          ...(fullDay
            ? {}
            : {
                startHour: values.start.format('HH:mm'),
                endHour: values.end.format('HH:mm'),
              }),
        })
          .then(() => {
            onSave();
            setIsSaving(false);
            onClose();
          })
          .catch((error) => {
            setIsSaving(false);
            message.error(error.response?.data?.message);
          });
      }
    }
  };

  const onWantRemove = (id: string) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('AVAILABILITIES.MODAL_DELETE.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      onOk: () => {
        return new Promise((resolve, reject) => {
          Axios.delete(`${process.env.REACT_APP_API_URL}/v3/unavailabilities/${id}`, {
            params: {
              departmentId: activeDepartment?.id,
            },
          })
            .then(() => {
              resolve(true);
              onSave();
              onClose();
            })
            .catch((error) => {
              message.error(t('AN_ERROR_OCCURRED'));
              reject();
            });
        });
      },
      onCancel: () => {},
    });
  };

  return (
    <Modal
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      className={className}
      visible={!!rangeSelection}
      onOk={() => form.submit()}
      onCancel={onClose}
      title={
        rangeSelection?.startDate?.isSame(rangeSelection?.endDate)
          ? rangeSelection?.startDate.format('L')
          : t('GLOBAL.FROM_DATE_TO_DATE', {
              date1: rangeSelection?.startDate.format('L'),
              date2: rangeSelection?.endDate.format('L'),
            })
      }
      footer={[
        <Button key="back" onClick={onClose}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" loading={isSaving} onClick={() => form.submit()}>
          {t('GLOBAL.CREATE')}
        </Button>,
      ]}
    >
      {!!rangeSelection?.events?.length && (
        <React.Fragment>
          {rangeSelection?.events?.map((event, index) => (
            <div className="event" key={`event_${index}`}>
              <div className="left">
                <b>{event.type === 'DAYOFF' ? event.dayoffName : t('GLOBAL.UNAVAILABILITY')}</b>
                <br />
                <span>
                  {event.fullDay
                    ? t('GLOBAL.FULL_DAY')
                    : t('GLOBAL.FROM_HOUR_TO_HOUR', {
                        hour1: moment(event.startDate).local().format('HH:mm'),
                        hour2: moment(event.endDate).local().format('HH:mm'),
                      })}
                </span>
              </div>
              <div>
                <Button type="primary" danger onClick={() => onWantRemove(event.id)}>
                  {t('GLOBAL.REMOVE')}
                </Button>
              </div>
            </div>
          ))}
          <Divider>{t('GLOBAL.ADD')}</Divider>
        </React.Fragment>
      )}
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item label="Type" name={'type'} rules={[{ required: true, message: '' }]}>
          <Select getPopupContainer={(trigger) => trigger} placeholder={t('FORMS.TYPE_PLACEHOLDER')}>
            <Option value="DAYOFF">{t('AVAILABILITIES.USER_ABSENCE')}</Option>
            {activeDepartment?.params?.enableAvailability && (
              <Option value="UNAVAILABILITY">{t('AVAILABILITIES.USER_UNAVAILABLE')}</Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}>
          {() => {
            const type = form.getFieldValue('type');
            if (type === 'DAYOFF') {
              return (
                <Form.Item
                  name={'dayOffType'}
                  label={t('FORMS.ABSENCE_TYPE')}
                  rules={[{ required: true, message: '' }]}
                >
                  <Select
                    getPopupContainer={(trigger) => trigger}
                    showSearch
                    placeholder={t('FORMS.ABSENCE_TYPE_PLACEHOLDER')}
                    optionFilterProp="children"
                    loading={loadingDayoffs}
                    disabled={loadingDayoffs}
                    filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {dayoffs.map((dayoff) => (
                      <Option value={dayoff.id!} key={`dayoff_${dayoff.id}`}>
                        {dayoff.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            } else {
              return null;
            }
          }}
        </Form.Item>
        <Form.Item name="fullDay" valuePropName="checked" style={{ marginBottom: 0 }}>
          <Checkbox>{t('GLOBAL.FULL_DAY')}</Checkbox>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.fullDay !== curValues.fullDay}>
          {() => {
            const fullDay = form.getFieldValue('fullDay');
            if (!fullDay) {
              return (
                <div style={{ marginTop: 10 }}>
                  <Form.Item
                    label={t('GLOBAL.START')}
                    name={'start'}
                    style={{ display: 'inline-block', width: 'calc(50% - 10px)', marginRight: 10 }}
                    rules={[{ required: true }]}
                  >
                    <TimePicker
                      format="HH:mm"
                      style={{ width: '100%' }}
                      onSelect={(e) => {
                        form.setFieldsValue({
                          start: e,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('GLOBAL.END')}
                    name={'end'}
                    style={{ display: 'inline-block', width: 'calc(50% - 10px)', marginRight: 10 }}
                    rules={[{ required: true }]}
                  >
                    <TimePicker
                      format="HH:mm"
                      style={{ width: '100%' }}
                      onSelect={(e) => {
                        form.setFieldsValue({
                          end: e,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
              );
            } else {
              return null;
            }
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalRangeSelection)`
  .event {
    background-color: ${colors.blueExtraLight};
    border-radius: 4px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .left span {
      color: ${colors.grey};
    }
  }
`;
