import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import CustomDatePicker from '@/pages/app/components/CustomDatePicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import UserInfos from '../components/UserInfos';
import Colors from '@/styles/colors';
import { Affix, message, Skeleton, Button, Empty } from 'antd';
import { IShift } from '@/types/shift.model';
import { chain } from 'lodash';
import Shift from '../components/Shift';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { IUser } from '@/types/user.model';

interface Props {
  className?: string;
  user?: IUser;
}

const CollaboratorSchedule: React.FC<Props> = ({ className, user }) => {
  const { id } = useParams();
  const [startDate, setStartDate] = useState<moment.Moment>(moment().startOf('month'));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [affixed, setAffixed] = useState(false);
  const [shiftsByDays, setShiftsByDays] = useState<{ day: number; shifts: IShift[] }[]>([]);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const { state: appContextState } = useContext(AppContext);
  const { activeDepartmentId, activeSection, users } = appContextState;

  const [nextUserId, setNextUserId] = useState<string | null>(null);
  const [previousUserId, setPreviousUserId] = useState<string | null>(null);

  const onDatePickerChange = (date: moment.Moment | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const onArrowClick = (value: number) => {
    setStartDate(moment(startDate.add(value, 'month')));
  };

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n]);

  useEffect(() => {
    const endDate = moment(startDate).endOf('month');
    const cancelTokenSource = axios.CancelToken.source();
    setPreviousUserId(null);
    setNextUserId(null);
    if (user && users) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/shifts`, {
          params: {
            startDate: startDate.unix(),
            endDate: endDate.unix(),
            users: true,
            userRecordId: user.recordId,
            departmentId: activeDepartmentId,
            sectionId: activeSection,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          setShiftsByDays(
            chain(response.data.shifts)
              .filter((x) => {
                return x.shyftType !== 3;
              })
              .groupBy(({ start }: any) => {
                return moment.unix(start).startOf('day').unix();
              })
              .map((value, key) => ({ day: +key, shifts: value as IShift[] }))
              .value(),
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          message.error(t('SHIFTS.MESSAGE_LOADING_ERROR'));
          setIsLoading(false);
        });

      const userIndex = users?.findIndex((x) => x.recordId === user?.recordId);
      if (~userIndex) {
        if (userIndex > 0) {
          setPreviousUserId(users[userIndex - 1]?.recordId || null);
        }
        if (userIndex < users.length - 1) {
          setNextUserId(users[userIndex + 1]?.recordId || null);
        }
      }
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, [startDate, activeDepartmentId, activeSection, user, users]);

  return (
    <div className={className}>
      <Link to={`/app/team/collaborators/${id}`} className="go-back">
        <Button>
          <i className="icon-angle-left"></i>
          {t('GLOBAL.BACK')}
        </Button>
      </Link>
      <header>
        <UserInfos
          user={user}
          departmentId={activeDepartmentId}
          previous={previousUserId}
          next={nextUserId}
          route="/app/team/collaborators/$/schedule"
        />
        <Affix offsetTop={50} onChange={(affixed) => setAffixed(!!affixed)}>
          <div className={`date-selector ${affixed ? 'affixed' : ''}`}>
            <button onClick={() => onArrowClick(-1)}>
              <i className="icon-angle-left" />
            </button>
            <CustomDatePicker picker="month" startDate={startDate} onDatePickerChange={onDatePickerChange} />
            <button onClick={() => onArrowClick(1)}>
              <i className="icon-angle-right" />
            </button>
          </div>
        </Affix>
      </header>
      <div className="days">
        {!isLoading &&
          shiftsByDays.map((shiftsByDay, shiftsByDayIndex) => {
            const date = moment.unix(shiftsByDay.day);
            return (
              <div className="day" key={shiftsByDayIndex}>
                <header>
                  <div>
                    <strong>{date.format('D MMMM')}</strong> {date.format('YYYY')}
                  </div>
                  <div>{date.format('dddd')}</div>
                </header>
                <div className="shifts">
                  {shiftsByDay.shifts.map((shift) => (
                    <Shift shift={shift} key={`shift_${shift.id}}`} />
                  ))}
                </div>
              </div>
            );
          })}
        {!isLoading && !shiftsByDays.length && <Empty style={{ padding: 40 }} />}
        {isLoading && (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const CollaboratorScheduleStyled = styled(CollaboratorSchedule)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .go-back {
    position: absolute;
    top: 20px;
    left: -5px;

    button {
      padding-left: 15px;
      padding-right: 20px;
    }
  }

  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: ${Colors.blueExtraLight};

    .date-selector {
      background: white;
      width: 100vw;
      display: flex;
      justify-content: center;
      padding: 20px 0;

      &.affixed {
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
      }
    }
  }

  > .days {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;

    .day {
      max-width: 400px;
      width: 100%;
      margin: 10px;

      header {
        font-size: 22px;
        line-height: 24px;
        padding: 10px;
        border-bottom: 1px solid ${Colors.grey};
        color: black;
        margin-bottom: 10px;

        div:last-child {
          font-size: 14px;
        }
      }
    }
  }

  .ant-skeleton {
    width: 100%;
    max-width: 400px;
  }
`;

export default CollaboratorScheduleStyled;
