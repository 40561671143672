import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { getWindowSize, roundNumber } from '@/utils';
import { Button, Empty, Form, InputNumber, Modal, Spin, Table, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Store } from 'redux';
import styled from 'styled-components';

interface Props {
  className?: string;
  recordId?: string;
  departmentId?: string;
}

const Leaves: React.FC<Props> = ({ className, departmentId, recordId }) => {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [data, setData] = useState<ILeaveResponse | null>(null);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [modalDayoffDetailsVisible, setModalDayoffDetailsVisible] = useState(false);
  const [dayoffDetails, setDayoffDetails] = useState<IDayoffDetails[]>([]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (!departmentId || !recordId) {
      return;
    }

    setIsLoading(true);
    setData(null);
    setFormHasChanged(false);
    form.resetFields();

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${recordId}/absences`, {
        params: {
          departmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }: { data: ILeaveResponse }) => {
        setData(data);
        form.setFieldsValue({
          leaves: data?.dayOffType?.reduce(
            (a, b) => ({
              ...a,
              [b.dayoffType]: {
                base_value: Number(b.base_value.toFixed(2)),
                value: data?.unit == 'HOURS' ? Number((b.value / 60).toFixed(2)) : Number(b.value.toFixed(2)),
              },
            }),
            {},
          ),
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });

    return () => {
      cancelTokenSource.cancel();
      setIsSaving(false);
    };
  }, [departmentId, recordId]);

  const onValuesChange = (changedValues: Store, values: Store) => {
    setFormHasChanged(true);
  };

  const onReset = () => {
    form.setFieldsValue({
      leaves: data?.dayOffType?.reduce(
        (a, b) => ({
          ...a,
          [b.dayoffType]: {
            base_value: Number(b.base_value.toFixed(2)),
            value: data?.unit == 'HOURS' ? Number((b.value / 60).toFixed(2)) : Number(b.value.toFixed(2)),
          },
        }),
        {},
      ),
    });
    setFormHasChanged(false);
  };

  const onFinish = (values: any) => {
    const filteredValues = Object.entries(values.leaves).map(([k, v]: [any, any]) => {
      return { dayoffType: k, value: v.base_value };
    });

    setIsSaving(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v3/users/${recordId}/absences`,
        {
          absences: { ...filteredValues },
        },
        {
          params: {
            departmentId,
          },
        },
      )
      .then(({ data }: { data: ILeaveResponse }) => {
        setData(data);
        form.setFieldsValue({
          leaves: data?.dayOffType?.reduce(
            (a, b) => ({
              ...a,
              [b.dayoffType]: {
                base_value: Number(b.base_value.toFixed(2)),
                value: data?.unit == 'HOURS' ? Number((b.value / 60).toFixed(2)) : Number(b.value.toFixed(2)),
              },
            }),
            {},
          ),
        });
        setIsSaving(false);
        setFormHasChanged(false);
        // location.reload();
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  const onDayoffDetailsClick = (detail: ILeaveInfo) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${recordId}/absences/detail`, {
        params: {
          departmentId,
          absenceId: detail.id,
        },
      })
      .then(({ data }: { data: IDayoffDetails[] }) => {
        setDayoffDetails(data);
        setModalDayoffDetailsVisible(true);
      })
      .catch((err) => {
        setModalDayoffDetailsVisible(false);
        console.log(err);
      });
  };

  return (
    <div className={className}>
      <ModalDayoffDetails
        data={dayoffDetails}
        visible={modalDayoffDetailsVisible}
        setVisible={setModalDayoffDetailsVisible}
      />
      <Form form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
        {isLoading ? (
          <Spin spinning={isLoading} style={{ width: '100%' }} />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: windowSize.innerWidth > 900 ? 'row' : 'column',
              justifyContent: 'space-around',
              gap: 25,
            }}
          >
            <div style={{ flex: 1 }}>
              <header>
                <h4>{t('LEAVES.LEAVE_COUNTERS')}</h4>
                <div
                  style={{
                    width: windowSize.innerWidth > 900 ? '260px' : '100%',
                    marginTop: windowSize.innerWidth > 900 ? 0 : 15,
                  }}
                >
                  <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.leaves !== curValues.leaves}>
                    {(form) => {
                      const leaves = form.getFieldValue('leaves');
                      let currentBalance = 0;
                      if (leaves) {
                        delete leaves.STANDARD;
                        delete leaves.RECUP;
                        currentBalance = Object.values(leaves).reduce((a: any, b: any) => {
                          return a + b.base_value - b.value;
                        }, 0) as number;
                      }
                      return (
                        <span style={{ display: 'inline-block', marginBottom: 10 }}>
                          {t('GLOBAL.CURRENT_BALANCE')} : {roundNumber(currentBalance, 1)}
                          {/* {data?.unitShort} */}
                        </span>
                      );
                    }}
                  </Form.Item>
                  <div>
                    <span>{t('LEAVES.AVAILABLE_SHORT')}</span>
                    <span>{t('LEAVES.USED_SHORT')}</span>
                  </div>
                </div>
              </header>
              <div className="leave-row">
                <span style={{ color: colors.green, fontWeight: 'bold' }}>{t('GLOBAL.TOTAL')}</span>
                <div style={{ display: 'flex', width: '100%' }}>
                  <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.leaves !== curValues.leaves}>
                    {(form) => {
                      const leaves = form.getFieldValue('leaves');
                      let values = { base_value: 0, value: 0 };
                      if (leaves) {
                        values = Object.values(leaves).reduce(
                          (a: any, b: any) => {
                            return {
                              base_value: a.base_value + b.base_value,
                              value: a.value + b.value,
                            };
                          },
                          { ...values },
                        ) as { base_value: number; value: number; display: boolean };
                      }
                      return (
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: windowSize.innerWidth > 900 ? 'flex-end' : 'space-between',
                          }}
                        >
                          <Form.Item noStyle>
                            <InputNumber
                              className="green-light"
                              type="number"
                              style={{
                                margin: 5,
                                width: windowSize.innerWidth > 900 ? 110 : 'auto',
                                flex: windowSize.innerWidth < 900 ? 1 : undefined,
                              }}
                              disabled={true}
                              min={0}
                              value={Number(values?.base_value.toFixed(2))}
                              addonAfter={t(`GLOBAL.${data?.unit}_SHORT`)}
                            />
                          </Form.Item>
                          <Form.Item noStyle>
                            <InputNumber
                              className="green-light"
                              type="number"
                              style={{
                                margin: 5,
                                width: windowSize.innerWidth > 900 ? 110 : 'auto',
                                flex: windowSize.innerWidth < 900 ? 1 : undefined,
                              }}
                              disabled={true}
                              value={Number(values?.value.toFixed(2))}
                              min={0}
                              addonAfter={t(`GLOBAL.${data?.unit}_SHORT`)}
                            />
                          </Form.Item>
                        </div>
                      );
                    }}
                  </Form.Item>
                </div>
              </div>

              {data?.dayOffType?.map((leave, index) => {
                if (leave.display) {
                  return (
                    <div key={leave.dayoffType} className="leave-row">
                      <span>{t(`DAYOFFS.${leave.dayoffType}`)}</span>
                      <div style={{ width: windowSize.innerWidth > 900 ? 'auto' : '100%' }}>
                        <Form.Item name={['leaves', leave.dayoffType, 'base_value']} fieldKey={index} noStyle>
                          <InputNumber
                            type="number"
                            style={{
                              margin: 5,
                              width: windowSize.innerWidth > 900 ? 110 : 'auto',
                              flex: windowSize.innerWidth < 900 ? 1 : undefined,
                            }}
                            disabled={false}
                            min={0}
                            parser={(value) => {
                              return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                            }}
                            addonAfter={t(`GLOBAL.${data.unit}_SHORT`)}
                          />
                        </Form.Item>
                        <Form.Item name={['leaves', leave.dayoffType, 'value']} noStyle>
                          <InputNumber
                            type="number"
                            style={{
                              margin: 5,
                              width: windowSize.innerWidth > 900 ? 110 : 'auto',
                              flex: windowSize.innerWidth < 900 ? 1 : undefined,
                            }}
                            disabled={true}
                            min={0}
                            parser={(value) => {
                              return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                            }}
                            addonAfter={t(`GLOBAL.${data.unit}_SHORT`)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  );
                }
              })}
            </div>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
              <header>
                <h4>{t('LEAVES.DAYOFFS_OVERVIEW')}</h4>
              </header>
              {data && data.detail && !data.detail.find((detail) => detail.value > 0) && <Empty />}
              <div
                className="scrollbar-hidden"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  flex: 1,
                  maxHeight: 160,
                  overflow: 'scroll',
                  marginTop: windowSize.innerWidth > 900 ? 0 : 15,
                }}
              >
                {data?.detail && data.detail.length > 0 ? (
                  <>
                    {data?.detail.map((detail) => {
                      if (detail.value > 0) {
                        return (
                          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <span>
                              {detail.shortcode} - {detail.name}
                            </span>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span>
                                {data.unit == 'HOURS' ? (
                                  <>
                                    {Number(Number(detail?.value / 60).toFixed(2))} {t(`GLOBAL.${data.unit}`)}
                                  </>
                                ) : (
                                  <>
                                    {Number(Number(detail?.value).toFixed(2))} {t(`GLOBAL.${data.unit}`)}
                                  </>
                                )}
                              </span>
                              <button
                                type="button"
                                style={{ cursor: 'pointer', paddingRight: 0 }}
                                onClick={() => onDayoffDetailsClick(detail)}
                              >
                                <Tooltip title={t('GLOBAL.DETAILS')}>
                                  <i className="icon-eye" style={{ color: colors.green }} />
                                </Tooltip>
                              </button>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </>
                ) : (
                  <span style={{ color: colors.grey }}>{t('LEAVES.DAYOFFS_OVERVIEW_NO_DATA')}</span>
                )}
              </div>
            </div>
          </div>
        )}
        {formHasChanged && (
          <div className="actions">
            <Button type="default" className="grey" onClick={onReset} style={{ marginRight: 10 }}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button loading={isSaving} type="primary" htmlType="submit">
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data: IDayoffDetails[];
}

const ModalDayoffDetails: React.FC<ModalProps> = ({ data, visible, setVisible }) => {
  const {
    state: { activeDepartment },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    setColumns([
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'name',
        key: 'name',
        // sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.DURATION'),
        render: (text: string, record: IDayoffDetails) => {
          const start_moment = moment(record.startdate);
          const end_moment = moment(record.enddate);
          const duration = Number(moment.duration(end_moment.diff(start_moment)).asHours().toFixed(2));
          return (
            <span>
              {duration} {t('GLOBAL.HOURS')}
            </span>
          );
        },
        // sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.START'),
        dataIndex: 'startdate',
        key: 'start',
        // sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.END'),
        dataIndex: 'enddate',
        key: 'end',
        // sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.ACTIONS'),
        render: (text: string, record: IDayoffDetails) => {
          return (
            <Button htmlType="button" type="primary" onClick={() => onShiftClick(record)}>
              {t('GLOBAL.SEE_SHIFT')}
            </Button>
          );
        },
        // sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
    ]);
  }, []);

  const hide = () => {
    setVisible(false);
  };

  const onShiftClick = (detail: IDayoffDetails) => {
    window.location.href = `/app/hours/manage/${
      activeDepartment?.scheduleParams?.default_schedule_view == 'ops'
        ? 'operational'
        : activeDepartment?.scheduleParams?.default_schedule_view
    }/${detail.startdate.slice(0, 10)}`;
  };

  return (
    <Modal
      width={windowSize.innerWidth > 900 ? 1000 : '100%'}
      visible={visible}
      footer={null}
      onCancel={hide}
      onOk={hide}
      title={t('GLOBAL.DETAILS')}
    >
      {data.length > 0 ? (
        <>
          <TableView>
            <Table dataSource={data} columns={columns} rowKey="id" pagination={false} />
          </TableView>
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <span>{t('GLOBAL.NO_DATA')}</span>
        </div>
      )}
    </Modal>
  );
};

export default styled(Leaves)`
  width: 100%;
  padding: 0 20px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h4 {
      font-weight: bold;
      font-size: 1.4em;
      margin: 0;
    }
    > div {
      width: 240px;
      font-size: 1.1em;
      font-weight: bold;
      color: ${colors.green};
      > div {
        display: flex;
        justify-content: space-between;
        span {
          flex: 1;
          font-size: 0.8em;
          font-weight: normal;
        }
      }
    }
  }

  .leave-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .ant-input-number-group-wrapper {
      width: 110px;
    }
    .ant-input-number-input {
      text-align: center;
    }
    .green-light {
      background-color: ${colors.greenLight};
      color: ${colors.green};
    }
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: end;
  }

  @media screen and (max-width: 900px) {
    header {
      flex-direction: column;
    }

    .leave-row {
      flex-direction: column;
      align-items: flex-start;

      > div {
        display: flex;
      }
    }
  }
`;

interface ILeaveInfo {
  id: string;
  name: string;
  shortcode: string;
  value: number;
}

interface IDayoffType {
  dayoffType: string;
  value: number;
  base_value: number;
  display: boolean;
}

interface ILeaveResponse {
  dayOffType: IDayoffType[];
  detail: ILeaveInfo[];
  unit: string;
}

interface IDayoffDetails {
  dayoff_type: string;
  enddate: string;
  name: string;
  shiftId: string;
  startdate: string;
}
