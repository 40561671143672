import { IWidget } from '@/types/widget.model';
import React, { useRef } from 'react';
import { useDrag, useDrop, useDragLayer } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { ActionType } from '../redux/actions';
import { CloseOutlined } from '@ant-design/icons';
import Axios from 'axios';

interface Props {
  className?: string;
  id: string;
  children: any;
  widgets: IWidget[];
  position: number;
  departmentId?: string;
  canDrag: boolean;
}

const DragItem: React.FC<Props> = ({ id, children, className, widgets, position, departmentId, canDrag }) => {
  const refDrag = useRef(null);
  const refDrop = useRef(null);
  const dashboardDispatch: Dispatch<ActionType> = useDispatch();

  const { isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const [, drag] = useDrag({
    item: { type: 'WIDGET', id, position },
  });
  drag(refDrag);

  const [{ isOver }, drop] = useDrop({
    accept: 'WIDGET',
    drop(item: any) {
      const widgetsMap = new Map<string, number>();
      const sortedWidgets = [...widgets].sort((a, b) => a.position! - b.position!);
      const selectedWidget = sortedWidgets.find((widget) => widget.id! === item.id)!;
      const originalPosition = selectedWidget.position!;
      if (originalPosition === position) {
        return;
      }

      for (let i = 0; i < widgets.length; i++) {
        const widget = sortedWidgets[i];
        const widgetPosition = widget.position!;
        let finalPosition = widgetPosition;
        if (widget.id === item.id) {
          finalPosition = position;
        } else {
          if (originalPosition > position) {
            if (widgetPosition >= position && widgetPosition < originalPosition) {
              finalPosition = widgetPosition + 1;
            }
          } else {
            if (widgetPosition <= position && widgetPosition > originalPosition) {
              finalPosition = widgetPosition - 1;
            }
          }
        }
        widgetsMap.set(widget.id!, finalPosition);
      }

      dashboardDispatch({
        type: 'SET_WIDGETS_POSITION',
        payload: widgetsMap,
      });
      Axios.patch(`${process.env.REACT_APP_API_URL}/v3/widgets`, {
        departmentId,
        widgets: widgets.map((w) => {
          return {
            id: w.id,
            position: widgetsMap.get(w.id!),
            visible: w.visible,
          };
        }),
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });
  drop(refDrop);

  const opacity = isDragging ? 0.5 : 1;
  const background = isOver ? 'rgba(0,0,0,0.1)' : 'transparent';

  return (
    <div
      ref={refDrop}
      style={{ background, opacity, padding: 20, order: position, borderRadius: 3 }}
      className={`${className} ${isDragging ? 'isDragging' : ''}`}
    >
      <div className="card-shadow widget" ref={canDrag ? refDrag : null}>
        <CloseOutlined
          onClick={() => {
            const index = widgets.findIndex((w) => w.id === id)!;
            dashboardDispatch({
              type: 'SET_WIDGET_VISIBILITY',
              payload: {
                index,
                visibility: false,
                departmentId,
              },
            });
            Axios.patch(`${process.env.REACT_APP_API_URL}/v3/widgets`, {
              departmentId,
              widgets: widgets.map((w, idx) => {
                return {
                  id: w.id,
                  position: w.position,
                  visible: index === idx ? !w.visible : w.visible,
                };
              }),
            });
          }}
        />
        {children}
      </div>
    </div>
  );
};

export default DragItem;
