import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Chartjs from 'chart.js';
import { WIDGETS } from '@/types/widget.model';
import { Spin } from 'antd';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import * as randomColor from 'randomcolor';
import { useTranslation } from 'react-i18next';

moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  departmentId?: string;
  startDate?: Moment;
  endDate?: Moment;
}

const UserStatuses: React.FC<Props> = ({ departmentId, startDate, endDate }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState<Chartjs | null>(null);
  const { t } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    if (!departmentId) {
      return;
    }

    setIsLoading(true);
    if (chartInstance) {
      chartInstance.destroy();
    }
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/widgets/${WIDGETS.USER_TYPE_GRAPH}`, {
      params: {
        departmentId: departmentId,
        start: startDate?.unix(),
        end: endDate?.unix(),
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        if (mounted) {
          setIsLoading(false);
          const { userTypes } = response.data;
          const labels = [];
          const data = [];
          const backgroundColor = randomColor({ count: userTypes?.length || 0, hue: 'green' });

          for (const userType of userTypes) {
            labels.push(userType.name);
            data.push(userType.value);
          }
          const newChartInstance = new Chartjs(chartContainer.current!, {
            type: 'pie',
            data: {
              labels,
              datasets: [
                {
                  data,
                  backgroundColor,
                },
              ],
            },
            options: {
              responsive: true,
              legend: {
                position: 'bottom',
              },
            },
          });
          setChartInstance(newChartInstance);
        }
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [departmentId, startDate?.unix(), endDate?.unix()]);

  return (
    <>
      {isLoading ? (
        <Spin spinning={isLoading} />
      ) : (
        <>
          <h4>{t(`DASHBOARD.WIDGETS.USER_TYPE_GRAPH`)}</h4>
          <div className="chart-container" style={{ position: 'relative' }}>
            <canvas ref={chartContainer} />
          </div>
        </>
      )}
    </>
  );
};

export default UserStatuses;
