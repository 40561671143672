import React, { useContext, useEffect } from 'react';
import { Menu, Button, Spin } from 'antd';
import NotificationItem from '../components/NotificationItem';
import colors from '@/styles/colors';
import axios from 'axios';
import AppContext from '@/pages/app/context';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface Props {
  notifications: any[];
  setNotifications: React.Dispatch<React.SetStateAction<any[]>>;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
  loadingNotifications: boolean;
}

const MenuNotifications: React.FC<Props> = (props) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const { notifications, setNotifications, loadingNotifications, setShowNotification, ...otherProps } = props;
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  const clearNotifications = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/v3/notifications/clear`, {
      params: {
        departmentId: activeDepartmentId,
      },
    });
    setShowNotification(false);
  };

  const readNotification = (notification: any) => {
    if (notification.id && activeDepartmentId) {
      axios.post(`${process.env.REACT_APP_API_URL}/v3/notifications/read`, {
        departmentId: activeDepartmentId,
        id: notification.id,
      });
    }
    setShowNotification(false);
  };

  return (
    <Menu
      style={{
        maxHeight: 'calc(100vh - 80px)',
        overflowY: 'auto',
        marginTop: 20,
        marginRight: -13,
        boxShadow: '0 0 40px rgba(39, 49, 64, 0.29)',
      }}
      {...otherProps}
    >
      {loadingNotifications && (
        <Menu.Item key="loading">
          <div style={{ textAlign: 'center' }}>
            <Spin spinning={true} />
          </div>
        </Menu.Item>
      )}
      {!loadingNotifications && (
        <React.Fragment>
          {notifications
            ?.filter((x) => x && x.data?.message !== 'REFRESH_DATA')
            ?.map((notification, index) => (
              <React.Fragment key={`notification_${index}`}>
                <Menu.Item
                  key={`read_${index}`}
                  onClick={() => {
                    readNotification(notification);
                  }}
                >
                  <NotificationItem notification={notification} />
                </Menu.Item>
                <Menu.Divider />
              </React.Fragment>
            ))}
          {!notifications?.length && (
            <Menu.Item key="notif">
              <div style={{ textAlign: 'center' }}>
                <h4 style={{ fontWeight: 'bold' }}>{t('NOTIFS.NO_NOTIF.TITLE')}</h4>
                <p>{t('NOTIFS.NO_NOTIF.DESCRIPTION')}</p>
              </div>
            </Menu.Item>
          )}
          {!!notifications?.length && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0 8px',
              }}
            >
              <Button
                type="link"
                size="small"
                style={{ color: colors.green, fontWeight: 'normal' }}
                onClick={clearNotifications}
              >
                {t('NOTIFS.MARK_ALL_AS_READ')}
              </Button>
            </div>
          )}
        </React.Fragment>
      )}
    </Menu>
  );
};

export default MenuNotifications;
