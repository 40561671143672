import TableView from '@/layouts/TableView';
import DrawerResource from '@/pages/app/components/drawers/Resource';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { AlignType } from '@/types/alignType.model';
import { IAttribut } from '@/types/attribut.model';
import { IResource } from '@/types/resource.model';
import { handleError } from '@/utils';
import { MenuOutlined } from '@ant-design/icons';
import { Modal, Space, Table, message } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import AppTeamResourcesPage from '../../../team/resources';
import AppTeamAttributPage from '../../../team/resources/attribute';
import Header from './components/Header';
import TableRowActions from './components/TableRowActions';

const SortableElementItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerItem = SortableContainer((props: any) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />);

interface Props {
  className?: string;
}

const ManageResources: React.FC<Props> = ({ className }) => {
  const {
    state: { loadingResources, resources: _resources, activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const { id, attributeId } = useParams() as any;
  const [resources, setResources] = useState<IResource[]>([]);
  const resourcesRef = useRef(resources);
  resourcesRef.current = resources;
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });
  const [filteredResources, setFilteredResources] = useState<IResource[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeResource, setActiveResource] = useState<IResource | null>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pendingAttribute, setPendingAttribute] = useState<IAttribut | null>(null);
  const history = useHistory();
  const pending = history.location.search.includes('pending=true');

  const resource = id ? resources.find((resource) => resource.id == id) : null;
  const attribute = attributeId
    ? resources.flatMap((resource) => resource.attributes).find((attribute) => attribute?.id == attributeId)
    : null;

  useEffect(() => {
    if (!attributeId) {
      setPendingAttribute(null);
    } else {
      getAttribute();
    }
  }, [attributeId]);

  useEffect(() => {
    if (!pending) return;
    getAttribute();
  }, [pending]);

  const getAttribute = () => {
    if (!id || !attributeId) return;
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/resources/${id}/${attributeId}`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setPendingAttribute(data.resource.attributes[0]);
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    setColumns([
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        // eslint-disable-next-line react/display-name
        render: () => <DragHandle />,
      },
      {
        title: t('GLOBAL.ID'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      },
      {
        title: t('GLOBAL.ITEMS'),
        key: 'items',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IResource) => <Space>{(record?.attributes || []).length}</Space>,
        sorter: (a: any, b: any) => (b.attributes || []).length - (a.attributes || []).length,
      },
      {
        title: t('GLOBAL.FIELDS'),
        key: 'fields',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IResource) => <Space>{(record?.fields || []).length}</Space>,
        sorter: (a: any, b: any) => (b.fields || []).length - (a.fields || []).length,
      },
      {
        title: t('GLOBAL.VISIBLE'),
        key: 'displayWhenFreeClocking',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: any) => {
          return <span>{record?.displayWhenFreeClocking ? t('GLOBAL.YES') : t('GLOBAL.NO')}</span>;
        },
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IResource) => (
          <TableRowActions onEdit={() => setActiveResource(record)} onDelete={() => onWantToDelete(record.id!)} />
        ),
      },
    ]);
  }, [i18n.language, resources]);

  useEffect(() => {
    setResources(_resources);
  }, [_resources]);

  useEffect(() => {
    setFilteredResources([]);
    if (resources && resources?.length) {
      const filteredResources = resources.filter((resource: any) => {
        return Object.keys(resource).some((key) => {
          if (typeof resource[key] === 'string') {
            return resource[key].toLowerCase().includes(searchTerm.toLowerCase());
          } else {
            return false;
          }
        });
      });
      setFilteredResources(filteredResources);
    }
  }, [resources, searchTerm]);

  const onWantToDelete = (resourceId: string) => {
    const attributesCategory = resources.find((resource) => resource.id == resourceId);
    if (attributesCategory && attributesCategory.attributes && attributesCategory.attributes.length > 0) {
      message.error(t('SETTINGS.ATTRIBUTES.MANAGE_ATTRIBUTES.CATEGORY_CONTAINS_ATTRIBUTES'));
      return;
    }
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('RESOURCES.MODAL_DELETE_RESOURCE.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        onDelete(resourceId);
      },
      onCancel: () => {},
    });
  };

  const onDelete = (resourceId: string) => {
    const resourcesCopy = [...resourcesRef.current];
    const index = resourcesCopy.findIndex((resource) => resource.id === resourceId);

    if (~index) {
      const resourcesResult = [...resourcesCopy];
      resourcesResult.splice(index, 1);
      dispatch({
        type: 'SET_RESOURCES',
        payload: resourcesResult,
      });
    }

    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/resources/${resourceId}`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .catch((error) => {
        if (~index) {
          console.error(error);
          message.error(t('RESOURCES.MODAL_DELETE_RESOURCE.MESSAGE_ERROR'));
          if (resourcesCopy) {
            dispatch({
              type: 'SET_RESOURCES',
              payload: resourcesCopy,
            });
          }
        }
      });
  };

  const onSearchChange = (e: React.ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchTerm(value);
  };

  const onSave = (result: IResource[]) => {
    dispatch({
      type: 'SET_RESOURCES',
      payload: result,
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const oldResources = [...resources];
    if (resources && oldIndex !== newIndex) {
      setIsLoading(true);
      const newResources: IResource[] = arrayMove(oldResources, oldIndex, newIndex);
      setResources(newResources);
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/resources`,
          {
            resources: newResources.map((x) => x.id),
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then((response) => {
          setResources(response.data?.resources);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setResources(oldResources);
          setIsLoading(false);
        });
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = resources.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableElementItem index={index} {...restProps} />;
  };
  const DraggableContainer = (props: any) => (
    <SortableContainerItem useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const onRow = (resource: IResource) => {
    return {
      onClick: () => {
        history.push(`/app/settings/attributes/manage/${resource.id}`);
      },
    };
  };

  const onBack = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: 15 }}>
        {(attribute || pendingAttribute || resource) && (
          <i className="icon-angle-left" style={{ fontSize: 16, cursor: 'pointer' }} onClick={onBack} />
        )}
        <h2 style={{ marginBottom: 0, marginLeft: resource ? -10 : 0 }}>
          {pendingAttribute
            ? pendingAttribute.name
            : attribute
            ? attribute.name
            : resource
            ? resource.name
            : t('GLOBAL.ATTRIBUTES')}
        </h2>
      </div>
      {!resource && (
        <span style={{ color: colors.grey }}>{t('SETTINGS.ATTRIBUTES.MANAGE_ATTRIBUTES.DESCRIPTION')}</span>
      )}
      <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10, marginTop: 25 }}>
        {!resource ? (
          <TableView>
            <Header disabled={false} onSearchChange={onSearchChange} onCreate={() => setActiveResource({})} />
            <Table
              className={`${className} row-clickable`}
              loading={loadingResources || isLoading}
              dataSource={filteredResources}
              columns={columns}
              rowKey="id"
              pagination={false}
              onRow={onRow}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            />
          </TableView>
        ) : attribute || pendingAttribute ? (
          <AppTeamAttributPage />
        ) : (
          <AppTeamResourcesPage />
        )}

        <DrawerResource
          departmentId={activeDepartmentId}
          resource={activeResource}
          visible={!!activeResource}
          onClose={() => setActiveResource(null)}
          onSave={onSave}
        />
      </div>
    </React.Fragment>
  );
};

export default ManageResources;
