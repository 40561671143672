import ScrollToTop from '@/components/ScrollToTop';
import ContainerView from '@/layouts/ContainerView';
import TableViewStyled from '@/layouts/TableView';
import ModalHowToUseVariables from '@/pages/app/components/ModalHowToUseVariables';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { DocumentPreview } from '@/types/document-preview.model';
import { IDocumentPlaceholder } from '@/types/document.model';
import { ISignature } from '@/types/signature.model';
import { getHtmlWithCodes, getWindowSize, TINYMCE_CONTENT_STYLE, TINYMCE_PLUGINS, TINYMCE_TOOLBAR } from '@/utils';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Col, Form, Input, InputNumber, List, message, Modal, Row, Select, Space, Spin, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

const { Option } = Select;

interface Props {
  className?: string;
  documentType: 'CONTRACT' | 'DOCUMENT';
}

const Template: React.FC<Props> = ({ className, documentType }) => {
  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { templateId } = useParams<any>();
  const [form] = Form.useForm();
  const previewRef = React.createRef<any>();
  const [loadingTemplate, setLoadingTemplate] = useState<boolean>(false);
  const [signatures, setSignatures] = useState<ISignature[]>([]);
  const [loadingSignatures, setLoadingSignatures] = useState<boolean>(false);
  const [placeholders, setPlaceholders] = useState<IDocumentPlaceholder[]>([]);
  const placeholdersRef = useRef<IDocumentPlaceholder[]>(placeholders);
  placeholdersRef.current = placeholders;
  const [placeholdersReady, setPlaceholdersReady] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [data, setData] = useState<DocumentPreview | null>(null);
  const [htmlContent, setHtmlContent] = useState<string>('');
  const editorRef = useRef<any>(null);
  const history = useHistory();
  const [errors, setErrors] = useState<any[]>([]);
  const [file, setFile] = useState<RcFile | null>(null);
  const [preview, setPreview] = useState<DocumentPreview | null>(null);
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [activeTemplatePageIndex, setActiveTemplatePageIndex] = useState<number>(0);
  const [fileNameOnUpdate, setFileNameOnUpdate] = useState<string | null>(null);
  const [howToUseVariablesVisible, setHowToUseVariablesVisible] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!file) return;

    if (!preview || (preview && preview.images && preview.images.length > 0)) return;

    beforeUpload(file);
  }, [preview, file]);

  useEffect(() => {
    form.resetFields();
    setPlaceholders([]);
    setLoadingTemplate(true);
    setPlaceholdersReady(false);

    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();

    if (!templateId || templateId === 'new') {
      setLoadingTemplate(false);
      return;
    }

    Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/templates/${templateId}`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then(({ data }) => {
        if (mounted) {
          if (data.render === 'docx') {
            const source_splitted = data.template_download_url.split('/');
            const name = source_splitted[source_splitted.length - 1];
            if (name) {
              setFileNameOnUpdate(name);
            }
            setPreviewLoading(true);
            fetch(data.template_download_url)
              .then(async (res) => {
                if (data.render == 'docx' && data && data.images && data.images.length == 0) {
                  beforeUpload((await res.blob()) as RcFile);
                }
                setFile((await res.blob()) as any);
                setPreview(data);
                setPreviewLoading(false);
              })
              .catch((err) => console.log(err));
          }

          form.setFieldsValue({ ...data });
          setData(data);
          setLoadingTemplate(false);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingTemplate(false);
          history.push(`/app/documents/templates/new`);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [location?.pathname]);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();

    setSignatures([]);
    setLoadingSignatures(true);

    Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/signatures`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then(({ data }) => {
        if (mounted) {
          setSignatures(data);
          setLoadingSignatures(false);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingSignatures(false);
        }
      });

    Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/placeholders`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
        docType: documentType,
      },
    })
      .then(({ data }) => {
        if (mounted) {
          setPlaceholders(data);
          setPlaceholdersReady(true);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [documentType, location?.pathname]);

  useEffect(() => {
    if (placeholdersReady && data) {
      setFormFieldsAndHtml(data);
      setLoadingTemplate(false);
    }
  }, [data, placeholdersReady]);

  const getHtmlWithPlaceholders = () => {
    const html = editorRef.current.getContent() || '';
    const regex = /(<code.+?data\-key\="([a-zA-Z0-9_]+)".+?>)(.*?)(<\/code>)?/g;

    const htmlWithIds = html.replace(regex, (match: any, p1: string, p2: string) => {
      if (p2) {
        return `{{${p2}}}`;
      } else {
        return match;
      }
    });

    return htmlWithIds;
  };

  const setFormFieldsAndHtml = (data: any) => {
    if (data.type !== documentType) {
      if (data.type === 'CONTRACT') {
        history.push(`/app/documents/contracts/templates/${data.id}`);
      } else {
        history.push(`/app/documents/documents/templates/${data.id}`);
      }
    }

    form.setFieldsValue({
      ...data,
      name: data.name,
      type: data.type,
    });
    setHtmlContent(getHtmlWithCodes(data?.html || '', placeholdersRef?.current));
    setLoadingTemplate(false);
  };

  const onFinish = (values: any) => {
    setIsSaving(true);
    const parsedValues = {
      ...values,
      start: values.start?.unix(),
      end: values.start?.unix(),
      type: documentType,
    };
    const url =
      templateId !== 'new'
        ? `${process.env.REACT_APP_API_URL}/v3/documents/templates/${templateId}`
        : `${process.env.REACT_APP_API_URL}/v3/documents/templates`;

    const formData = new FormData();
    for (var key in parsedValues) {
      if (parsedValues[key]) {
        formData.append(`formValues[${key}]`, parsedValues[key]);
      }
    }

    if (file) {
      formData.append('formValues[file]', file);
      formData.delete('html');
    } else {
      formData.append('html', getHtmlWithPlaceholders());
    }

    Axios({
      url,
      method: 'POST',
      data: formData,
      params: {
        departmentId: activeDepartmentId,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        if (data.type === 'CONTRACT') {
          history.push(`/app/documents/contracts/templates/${data.id}`);
        } else {
          history.push(`/app/documents/documents/templates/${data.id}`);
        }
        form.setFieldsValue({ ...data });
        message.success(t('DOCUMENTS.TEMPLATES.MESSAGES.SAVED'));
        setIsSaving(false);
      })
      .catch((err) => {
        setFile(null);
        setPreview(null);
        setPreviewLoading(false);
        if (err.response?.data?.errors) {
          setIsSaving(false);
          setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
        }
      });
  };

  const clearErrors = () => {
    setErrors([]);
  };

  const beforeUpload = (file: RcFile) => {
    setFile(file);
    setPreview(null);
    setPreviewLoading(true);

    const formData = new FormData();
    const values = form.getFieldsValue();
    for (var key in values) {
      if (values[key]) {
        formData.append(`formValues[${key}]`, values[key]);
      }
    }
    formData.append('formValues[file]', file);
    // render_signature is boolean but can only send strings in FormData
    // formData.append('render_signature', '0');

    Axios({
      url: `${process.env.REACT_APP_API_URL}/v3/documents/preview`,
      method: 'POST',
      data: formData,
      params: {
        departmentId: activeDepartmentId,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        setPreviewLoading(false);
        setPreview(data);
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
        }
        console.log(err);
        setPreviewLoading(false);
      });

    return false;
  };

  const onClearTemplate = () => {
    setFile(null);
    setPreview(null);
  };

  const onChangePage = (direction: string, pagination: string) => {
    if (preview && preview.images.length > 0) {
      switch (direction) {
        case 'left': {
          if (activeTemplatePageIndex == 0) {
            setActiveTemplatePageIndex(preview.images.length - 1);
          } else {
            setActiveTemplatePageIndex(activeTemplatePageIndex - 1);
          }
          if (pagination == 'bottom') {
            window.scroll({ behavior: 'smooth', top: previewRef?.current?.offsetTop });
          }
          break;
        }
        case 'right': {
          if (activeTemplatePageIndex == preview.images.length - 1) {
            setActiveTemplatePageIndex(0);
          } else {
            setActiveTemplatePageIndex(activeTemplatePageIndex + 1);
          }
          if (pagination == 'bottom') {
            window.scroll({ behavior: 'smooth', top: previewRef?.current?.offsetTop });
          }
          break;
        }
      }
    }
  };

  const onHowToUseVariables = () => {
    setHowToUseVariablesVisible(true);
  };

  return (
    <ContainerView>
      <ModalHowToUseVariables
        howToUseVariablesVisible={howToUseVariablesVisible}
        setHowToUseVariablesVisible={setHowToUseVariablesVisible}
        placeholders={placeholders}
        documentType={documentType}
      />
      <Modal
        destroyOnClose={true}
        visible={errors.length > 0}
        className={'modal-danger'}
        title={t('CONTRACTS.TEMPLATES.ERRORS.TITLE')}
        onOk={clearErrors}
        onCancel={clearErrors}
      >
        <List
          dataSource={errors}
          renderItem={(item) => (
            <List.Item>
              <p>{item}</p>
            </List.Item>
          )}
        />
      </Modal>
      <TableViewStyled className={className}>
        <header>
          <div className="left">
            <h2>
              {templateId !== 'new'
                ? t('DOCUMENTS.TEMPLATES.TEMPLATE_MODIFICATION')
                : t('DOCUMENTS.TEMPLATES.NEW_TEMPLATE')}
            </h2>
          </div>
          {!previewLoading && (
            <div className="right">
              <Space>
                <Button loading={isSaving} type="primary" onClick={() => form.submit()}>
                  {t('GLOBAL.SAVE')}
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    if (documentType === 'CONTRACT') {
                      history.push('/app/documents/contracts/templates/all');
                    } else {
                      history.push('/app/documents/documents/templates/all');
                    }
                  }}
                >
                  {t('GLOBAL.BACK')}
                </Button>
              </Space>
            </div>
          )}
        </header>
        <ScrollToTop />
        <Spin spinning={loadingTemplate || isSaving}>
          <Form
            layout="vertical"
            size="large"
            form={form}
            onFinish={onFinish}
            scrollToFirstError={true}
            initialValues={{ ['type']: 'DOCUMENT' }}
            className="template-container"
          >
            {documentType === 'CONTRACT' ? (
              <React.Fragment>
                <Row gutter={[20, 30]}>
                  <Col span={windowSize.innerWidth > 900 ? 12 : 24}>
                    <div className="card-shadow">
                      <Form.Item name="name" label={t('DOCUMENTS.TEMPLATES.FORM.NAME')} rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name="signatory" label={t('CONTRACTS.FORM.SIGNATORY')} rules={[{ required: true }]}>
                        <Select
                          clearIcon
                          getPopupContainer={(trigger) => trigger}
                          showSearch
                          loading={loadingSignatures}
                          placeholder={t('CONTRACTS.FORM.SIGNATORY_PLACEHOLDER')}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option!.children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {signatures?.map((signature) => {
                            const { name, id, title } = signature;
                            return (
                              <Option key={`signatory_${id}`} value={`${id}`}>
                                {name} - {title}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item label={t('DOCUMENTS.CONTRACTS.UPLOAD_CONTRACT')}>
                        <div className="upload-file-container">
                          <div className="left">
                            <Upload
                              beforeUpload={beforeUpload}
                              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              maxCount={1}
                              showUploadList={false}
                              className="browse-btn-container"
                            >
                              <div className="content">
                                <button type="button" className="browse-btn">
                                  {file
                                    ? file.name
                                      ? file.name
                                      : fileNameOnUpdate && fileNameOnUpdate
                                    : t('GLOBAL.BROWSE')}
                                </button>
                              </div>
                            </Upload>
                            {file && <i className="icon-plus" onClick={onClearTemplate} />}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <button type="button" style={{ textDecoration: 'underline' }} onClick={onHowToUseVariables}>
                            {t('DOCUMENTS.VARIABLES.TITLE')}
                          </button>
                        </div>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={windowSize.innerWidth > 900 ? 12 : 24}>
                    <div className="card-shadow">
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Form.Item
                          name="weekHours"
                          label={t('CONTRACTS.FORM.WEEK_HOURS')}
                          style={{
                            display: 'inline-block',
                            width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                            marginRight: 10,
                          }}
                        >
                          <InputNumber
                            type="number"
                            min={0}
                            style={{ width: '100%' }}
                            step="any"
                            parser={(value) => {
                              return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="salary"
                          label={t('CONTRACTS.FORM.SALARY')}
                          style={{
                            display: 'inline-block',
                            width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                            marginRight: 10,
                          }}
                          rules={[{ required: true }]}
                        >
                          <InputNumber
                            type="number"
                            min={0}
                            style={{ width: '100%' }}
                            step={1}
                            parser={(value) => {
                              return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="salaryPeriod"
                          label={t('CONTRACTS.FORM.SALARY_PERIOD')}
                          style={{
                            display: 'inline-block',
                            width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                          }}
                          rules={[{ required: true }]}
                        >
                          <Select
                            allowClear
                            getPopupContainer={(trigger) => trigger}
                            placeholder={t('CONTRACTS.FORM.SALARY_PERIOD')}
                            optionFilterProp="children"
                          >
                            <Option value="HOUR">{t('GLOBAL.HOUR')}</Option>
                            <Option value="DAY">{t('GLOBAL.DAY')}</Option>
                            <Option value="WEEK">{t('GLOBAL.WEEK')}</Option>
                            <Option value="MONTH">{t('GLOBAL.MONTH')}</Option>
                          </Select>
                        </Form.Item>
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Form.Item
                          name="weekDays"
                          label={t('CONTRACTS.FORM.WEEK_DAYS')}
                          style={{
                            display: 'inline-block',
                            width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                            marginRight: 10,
                          }}
                        >
                          <InputNumber type="number" min={0} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                          name="yearDayoffs"
                          label={t('CONTRACTS.FORM.DAYOFFS_NUMBER_BY_YEAR')}
                          style={{
                            display: 'inline-block',
                            width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                            marginRight: 10,
                          }}
                        >
                          <InputNumber type="number" min={0} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                          name="maxDailyHours"
                          label={t('CONTRACTS.FORM.MAX_DAILY_HOURS')}
                          style={{
                            display: 'inline-block',
                            width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                          }}
                        >
                          <InputNumber type="number" min={0} style={{ width: '100%' }} />
                        </Form.Item>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row gutter={[20, 30]}>
                  <Col span={24}>
                    <div className="card-shadow">
                      <Form.Item name="name" label={t('DOCUMENTS.TEMPLATES.FORM.NAME')} rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item label={t('DOCUMENTS.CONTRACTS.UPLOAD_CONTRACT')}>
                        <div className="upload-file-container">
                          <div className="left">
                            <Upload
                              beforeUpload={beforeUpload}
                              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              maxCount={1}
                              showUploadList={false}
                              className="browse-btn-container"
                            >
                              <div className="content">
                                <button type="button" className="browse-btn">
                                  {file
                                    ? file.name
                                      ? file.name
                                      : fileNameOnUpdate && fileNameOnUpdate
                                    : t('GLOBAL.BROWSE')}
                                </button>
                              </div>
                            </Upload>
                            {file && <i className="icon-plus" onClick={onClearTemplate} />}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <button type="button" style={{ textDecoration: 'underline' }} onClick={onHowToUseVariables}>
                            {t('DOCUMENTS.VARIABLES.TITLE')}
                          </button>
                        </div>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {!file && (
              <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={htmlContent}
                init={{
                  height: 600,
                  menubar: false,
                  setup: function (editor) {
                    editor.ui.registry.addMenuButton('placeholders', {
                      text: 'Variables',
                      fetch: (callback) => {
                        callback(
                          placeholdersRef.current?.map(({ id, label }: any) => ({
                            type: 'menuitem',
                            text: label,
                            onAction: function () {
                              editor.insertContent(
                                `<code data-key="${id}" class="mceNonEditable" contenteditable="false">${label}</code>`,
                              );
                            },
                          })),
                        );
                      },
                    });
                  },
                  plugins: TINYMCE_PLUGINS,
                  toolbar: TINYMCE_TOOLBAR,
                  content_style: TINYMCE_CONTENT_STYLE,
                }}
              />
            )}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 15 }} ref={previewRef}>
              <>
                {file && (
                  <>
                    {previewLoading ? (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Spin spinning={previewLoading} />
                        <span>{t('DOCUMENTS.PREVIEWS.LOADING')}</span>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: 15,
                          width: '100%',
                        }}
                      >
                        {preview && (
                          <div>
                            {preview.images.length !== 1 && (
                              <button
                                className="slider-arrow"
                                type="button"
                                onClick={() => onChangePage('left', 'top')}
                              >
                                &lt;
                              </button>
                            )}
                            {t('DOCUMENTS.PREVIEWS.PAGE_X_OF_X', {
                              current: activeTemplatePageIndex + 1,
                              length: (data && data.images.length) || (preview && preview.images.length),
                            })}
                            {preview.images.length !== 1 && (
                              <button
                                className="slider-arrow"
                                type="button"
                                onClick={() => onChangePage('right', 'top')}
                              >
                                &gt;
                              </button>
                            )}
                          </div>
                        )}
                        <div style={{ width: '100%' }}>
                          {preview && (
                            <img
                              className="template-image"
                              src={preview.images[activeTemplatePageIndex]}
                              style={{ width: '100%' }}
                            />
                          )}
                        </div>
                        <div>
                          {preview && (
                            <div>
                              {preview.images.length !== 1 && (
                                <button
                                  className="slider-arrow"
                                  type="button"
                                  onClick={() => onChangePage('left', 'bottom')}
                                >
                                  &lt;
                                </button>
                              )}
                              {t('DOCUMENTS.PREVIEWS.PAGE_X_OF_X', {
                                current: activeTemplatePageIndex + 1,
                                length: preview.images.length,
                              })}
                              {preview.images.length !== 1 && (
                                <button
                                  className="slider-arrow"
                                  type="button"
                                  onClick={() => onChangePage('right', 'bottom')}
                                >
                                  &gt;
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
              {!previewLoading && (
                <div className="actions active">
                  <Button loading={isSaving} type="primary" htmlType="submit">
                    {t('GLOBAL.SAVE')}
                  </Button>
                </div>
              )}
            </div>
          </Form>
        </Spin>
      </TableViewStyled>
    </ContainerView>
  );
};

export default styled(Template)`
  background: ${colors.blueExtraLight};
  border-radius: 3px;

  label {
    width: 100%;
  }

  .upload-file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      border-radius: 5px;
      background-color: #f6f8fc;
      border: 1px solid #e2e6ef;
      .icon-plus {
        transform: rotate(45deg);
        padding: 0 15px;
        cursor: pointer;
      }
    }
    .browse-btn-container {
      flex: 1;
      .ant-upload-select {
        width: 100%;
        .browse-btn {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          width: 100%;
        }
      }
    }
  }

  .slider-arrow {
    margin: 0px 10px;
  }

  .ant-row {
    margin-bottom: 20px;
  }
  .template-container {
    padding: 0 25px 25px;
    .ant-col .card-shadow {
      padding: 0 20px;
    }
  }
  .tox-tinymce {
    margin-bottom: 40px;
  }
  .card-shadow {
    padding: 0;
    margin-bottom: 40px;
  }

  .actions {
    position: fixed;
    width: calc(100vw - 160px);
    background: white;
    bottom: -80px;
    margin: 0;
    display: flex;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
    padding: 10px;
    border-radius: 3px 3px 0 0;
    transition: bottom 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    &.active {
      transition-timing-function: ease-in;
      bottom: 0px;
    }
  }
`;
