import { Button, Space } from 'antd';
import React, { useState } from 'react';
import { IWeeklyReport } from '.';
import colors from '@/styles/colors';
import { useTranslation } from 'react-i18next';

interface Props {
  weeklyReport: IWeeklyReport;
  handleSend: (id: string) => Promise<void>;
  handleSchedule: (timestamp: number) => void;
}

const TableRowActions: React.FC<Props> = ({ weeklyReport, handleSend, handleSchedule }) => {
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const { t } = useTranslation(undefined, { useSuspense: false });

  const onSend = (id: string) => {
    setActionLoading(true);
    handleSend(id).finally(() => {
      setActionLoading(false);
    });
  };

  return (
    <Space>
      <Button type="default" onClick={() => handleSchedule(weeklyReport.start!)}>
        {t('GLOBAL.SEE_SCHEDULE')}
      </Button>
      <Button
        type="primary"
        onClick={() => {
          window.open(weeklyReport.pdf);
        }}
      >
        <i className="icon-download" />
      </Button>
      <Button
        loading={actionLoading}
        onClick={() => onSend(weeklyReport.id)}
        style={{ background: colors.orange, borderColor: colors.orange }}
        type="primary"
      >
        {t('GLOBAL.SEND')}
      </Button>
    </Space>
  );
};

export default TableRowActions;
