import { IDepartment } from '@/types/department.model';
import { Menu } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled } from '@/utils';

interface Props {
  department?: IDepartment;
}

const MenuDocuments: React.FC<Props> = (props) => {
  const { department, ...otherProps } = props;
  const { t } = useTranslation(undefined, { useSuspense: true });
  const {
    state: { features },
  } = useContext(AppContext);
  if (!!department) {
    const { access, params, weeklyReports = [] } = department;
    return (
      <Menu style={{ maxHeight: 'calc(100vh - 80px)', overflowY: 'auto' }} {...otherProps}>
        <>
          {!!weeklyReports?.length && (
            <Menu.ItemGroup title={t('MENU.DOCUMENTS.PRINTING_SCHEDULES.TITLE')}>
              {weeklyReports?.map((weeklyReport) => {
                const { id, name } = weeklyReport;
                return (
                  <Menu.Item key={`weeklyReport_${id}`}>
                    <NavLink
                      activeClassName="is-active"
                      to={`/app/documents/weekly-reports/${id}`}
                      className="menu_documents_weekly-reports"
                    >
                      {name}
                    </NavLink>
                  </Menu.Item>
                );
              })}
            </Menu.ItemGroup>
          )}
        </>
        {isFeatureEnabled(features, FEATURES.CONTRACTS) && access?.documents && (
          <Menu.ItemGroup title={t('GLOBAL.CONTRACTS')}>
            <Menu.Item key="contracts">
              <NavLink activeClassName="is-active" to="/app/documents/contracts" className="menu_documents_contracts">
                {t('DOCUMENTS.CONTRACTS.TITLE')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="new-contract">
              <NavLink
                activeClassName="is-active"
                to="/app/documents/contracts/new"
                className="menu_documents_contracts_new"
              >
                {t('CONTRACTS.NEW_CONTRACT')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="contract-templates">
              <NavLink
                activeClassName="is-active"
                to="/app/documents/contracts/templates"
                className="menu_documents_contracts_templates"
              >
                {t('CONTRACTS.TEMPLATES.TITLE')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="signatures">
              <NavLink
                activeClassName="is-active"
                to="/app/documents/contracts/signatures"
                className="menu_documents_signatures"
              >
                {t('DOCUMENTS.SIGNATURES.TITLE')}
              </NavLink>
            </Menu.Item>
          </Menu.ItemGroup>
        )}
        {isFeatureEnabled(features, FEATURES.CONTRACTS) && access?.documents && (
          <Menu.ItemGroup title={t('GLOBAL.DOCUMENTS')}>
            <Menu.Item key="documents">
              <NavLink activeClassName="is-active" to="/app/documents/documents" className="menu_documents_documents">
                {t('DOCUMENTS.DOCUMENTS.TITLE')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="new-documents">
              <NavLink
                activeClassName="is-active"
                to="/app/documents/documents/new"
                className="menu_documents_documents_new"
              >
                {t('DOCUMENTS.DOCUMENTS.DOCUMENT.NEW_DOCUMENT')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="document-templates">
              <NavLink
                activeClassName="is-active"
                to="/app/documents/documents/templates"
                className="menu_documents_documents_templates"
              >
                {t('DOCUMENTS.TEMPLATES.TITLE')}
              </NavLink>
            </Menu.Item>
          </Menu.ItemGroup>
        )}
        {isFeatureEnabled(features, FEATURES.DIMONA) && params?.dimona && access?.dimona && (
          <Menu.ItemGroup title={t('MENU.DOCUMENTS.DIMONA_MANAGEMENT.TITLE')}>
            <Menu.Item key="dimona">
              <NavLink
                activeClassName="is-active"
                to="/app/documents/dimona/statement"
                className="menu_documents_dimona_statement"
              >
                {t('DIMONA.TITLE')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="dimona-audit">
              <NavLink
                activeClassName="is-active"
                to="/app/documents/dimona/audit"
                className="menu_documents_dimona_audit"
              >
                {t('DIMONA.AUDIT.TITLE')}
              </NavLink>
            </Menu.Item>
          </Menu.ItemGroup>
        )}
      </Menu>
    );
  } else {
    return null;
  }
};

export default MenuDocuments;
