import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { isFeatureEnabled } from '@/utils';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const HelpTooltip: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    state: { features },
  } = useContext(AppContext);

  return (
    <div className={className}>
      <div className="help">
        <div className="shift initial" style={{ backgroundColor: `${colors.green}`, color: '#fff' }}>
          {t('SCHEDULE.MONTHLY_VIEW.WORK_SHORT')}
        </div>
        <span className="title">{t('SCHEDULE.MONTHLY_VIEW.SHIFT_BLOCKS.HELP.WORKING_USER')}</span>
      </div>
      <div className="help">
        <div className="shift initial" style={{ backgroundColor: `${colors.red}`, color: '#fff' }}>
          {t('GLOBAL.DAYOFF_SHORT')}
        </div>
        <span className="title">{t('SCHEDULE.MONTHLY_VIEW.SHIFT_BLOCKS.HELP.LEAVE')}</span>
      </div>
      <div className="help">
        <div
          className="shift initial"
          style={{ backgroundColor: '#F4F4F4', color: '#BEBEBE', border: '1px solid #d9d9d9' }}
        >
          {t('GLOBAL.UNAVAILABILITY_SHORT')}
        </div>
        <span className="title">{t('SCHEDULE.MONTHLY_VIEW.SHIFT_BLOCKS.HELP.UNAVAILABILITY')}</span>
      </div>
      <div className="help">
        <div className="shift shift-not-approved" style={{ backgroundColor: `${colors.green}` }}></div>
        <span className="title">{t('SCHEDULE.MONTHLY_VIEW.SHIFT_BLOCKS.HELP.SHIFT_NOT_APPROVED')}</span>
      </div>
      {isFeatureEnabled(features, FEATURES.DIMONA) && (
        <div className="help">
          <div className="shift invalid-dimona" style={{ backgroundColor: `${colors.green}` }}></div>
          <span className="title">{t('SCHEDULE.MONTHLY_VIEW.SHIFT_BLOCKS.HELP.INVALID_DIMONA')}</span>
        </div>
      )}
    </div>
  );
};

export default styled(HelpTooltip)`
  color: #000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .help {
    display: flex;
    align-items: center;

    .title {
      margin-left: 5px;
    }
  }

  .shift {
    overflow: hidden;
    width: 25px;
    height: 25px;
    position: relative;
    border-radius: 5px;
  }

  .invalid-dimona {
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      right: -10px;
      top: -10px;
      transform: rotate(45deg);
      background-color: #ed6f5d;
      overflow: hidden;
    }
  }

  .shift-not-approved {
    border: 2px dashed #60cdff;
  }

  .initial {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
