import { IUser } from '@/types/user.model';
import React, { useState } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import Sheet from '../../../../../reports/hr-sheets/components/HrSheet';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  user?: IUser;
}

const HrSheet: React.FC<Props> = ({ className, user }) => {
  const [date, setDate] = useState<Moment>(moment().startOf('month'));
  return (
    <div className={className}>
      <Sheet userRecordId={user?.recordId} start={date} datePickerValue={date} setDatePickerValue={setDate} user />
    </div>
  );
};

export default styled(HrSheet)`
  > div > header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
