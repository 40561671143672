import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { IUser } from '@/types/user.model';
import { convertSomeMomentPropertiesToStringDate, convertSomePropertiesToMoment } from '@/utils';
import { Button, Form, List, Modal, Spin } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormActions from '../FormActions';
import Car from './Car';
import Contract from './Contract';
import MealVoucher from './MealVoucher';
import ModalHrFormList from './ModalHrFormList';
import Occupation from './Occupation';
import Reduction from './Reduction';
import Settings from './Settings';
import Status from './Status';
import WageGap from './WageGap';
import Worker from './Worker';
import PaidOptionOverlay from '@/pages/app/components/PaidOptionOverlay';
import ModalSwitchToProAccount from '@/pages/app/components/ModalSwitchToProAccount';

const dateProperties = [
  'start',
  'end',
  'contract_start',
  'contract_end',
  'meal_voucher_from_date',
  'meal_voucher_to_date',
  'first_registration_date',
  'car_from_date',
  'car_date_to',
];
interface Props {
  className?: string;
  user?: IUser;
  department?: IDepartment;
}

const HR: React.FC<Props> = ({ className, user, department }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [wantToCreateNewForm, setWantToCreateNewForm] = useState<boolean>(false);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(false);
  const [formId, setFormId] = useState<string>();
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [isModalHrFormListVisible, setIsModalHrFormListVisible] = useState<boolean>(false);
  const [response, setResponse] = useState<IResponse>();
  const [newStart, setNewStart] = useState<moment.Moment>();
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
  const [modalSwitchProVisible, setModalSwitchProVisible] = useState<boolean>(false);

  const freemium = department?.accountType == 'FREE-CLOCKING';

  useEffect(() => {
    if (!user) {
      return;
    }
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setFormId(undefined);
    setResponse(undefined);
    setLoading(true);
    // formHasChanged && setFormHasChanged(false);
    // wantToCreateNewForm && setWantToCreateNewForm(false);

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/hr/active`, {
        params: {
          departmentId: department?.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        if (mounted) {
          const {
            user_type,
            hr_days_week,
            hr_max_hours_week,
            hr_max_hours_year,
            hr_max_hours_trimester,
            legal_leaves,
            extra_legal_days_off,
            previous_recovery,
            recovery_hours,
            default_dimona_hours,
            rtt,
          } = response.data.data;
          const res = {
            ...response.data,
            data: {
              ...response.data.data,
              user_type: user_type == '' || !user_type || user_type == '0' ? null : user_type,
              hr_days_week: hr_days_week == '0' || hr_days_week == 0 ? null : hr_days_week,
              hr_max_hours_week: hr_max_hours_week == '0' || hr_max_hours_week == 0 ? null : hr_max_hours_week,
              hr_max_hours_year: hr_max_hours_year == '0' || hr_max_hours_year == 0 ? null : hr_max_hours_year,
              hr_max_hours_trimester:
                hr_max_hours_trimester == '0' || hr_max_hours_trimester == 0 ? null : hr_max_hours_trimester,
              legal_leaves: legal_leaves == '0' || legal_leaves == 0 ? null : legal_leaves,
              extra_legal_days_off:
                extra_legal_days_off == '0' || extra_legal_days_off == 0 ? null : extra_legal_days_off,
              rtt: rtt == '0' || rtt == 0 ? null : rtt,
              previous_recovery: previous_recovery == '0' || previous_recovery == 0 ? null : previous_recovery,
              recovery_hours: recovery_hours == '0' || recovery_hours == 0 ? null : recovery_hours,
              default_dimona_hours:
                default_dimona_hours == '0' || default_dimona_hours == 0 ? null : default_dimona_hours,
            },
          };

          setResponse(res as IResponse);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (mounted) {
          setLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, [user, department?.id, wantToCreateNewForm]);

  useEffect(() => {
    if (!formId) {
      return;
    }
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setResponse(undefined);
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/hr/${formId}`, {
        params: {
          departmentId: department?.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        if (mounted) {
          setResponse(response.data as IResponse);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (mounted) {
          setLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, [formId]);

  useEffect(() => {
    if (!response) {
      return;
    }
    onReset();
  }, [response]);

  const onReset = () => {
    setNewStart(moment());
    form.resetFields();
    if (response) {
      const { data } = response;
      let start: null | moment.Moment;
      let end: null | moment.Moment = null;

      if (wantToCreateNewForm) {
        if (data?.contract_end) {
          start = moment(data?.contract_end).add(1, 'day');
        } else {
          start = moment();
        }
      } else {
        if (data?.contract_start) {
          start = moment(data?.contract_start);
        } else {
          start = moment();
        }
        if (data?.contract_end && !wantToCreateNewForm) {
          end = moment(data?.contract_end);
        }
      }

      form.setFieldsValue({
        ...convertSomePropertiesToMoment(data, dateProperties),
        contract_start: start,
        contract_end: end,
      });
    }
    setFormHasChanged(false);
  };

  const onNewForm = () => {
    setFormId(undefined);
    if (response) {
      setResponse({
        ...response,
        data: {
          ...response.data,
          id: undefined,
        },
      });
    }
    setTimeout(() => {
      form.setFieldsValue({
        start: response?.data.contract_end,
      });
      setFormHasChanged(true);
    });
  };

  const onWantToCreateNewForm = () => {
    Modal.confirm({
      title: t('HR.MODAL_NEW_SHEET.TITLE'),
      icon: null,
      content: t('HR.MODAL_NEW_SHEET.DESCRIPTION'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.CREATE'),
      onOk: () => {
        axios
          .post(`${process.env.REACT_APP_API_URL}/v3/users/${user!.recordId}/hr`, {
            departmentId: department?.id,
          })
          .then((response) => {
            setResponse(response.data);
            setSaving(false);
            setFormHasChanged(false);
            setWantToCreateNewForm(false);
          })
          .catch((error) => {
            setSaving(false);
            console.error(error);
          });
      },
      onCancel: () => {},
    });
  };

  const onFinish = (values: any) => {
    //TODO: save the changes
    setSaving(true);
    const id = formId || response?.data?.id;
    const formattedProps = convertSomeMomentPropertiesToStringDate(values, dateProperties);
    const {
      hr_days_week,
      hr_max_hours_week,
      hr_max_hours_year,
      hr_max_hours_trimester,
      legal_leaves,
      extra_legal_days_off,
      previous_recovery,
      recovery_hours,
      default_dimona_hours,
      worker_type,
      rtt,
    } = formattedProps;
    if (id) {
      axios
        .patch(`${process.env.REACT_APP_API_URL}/v3/users/${user!.recordId}/hr/${id}`, {
          departmentId: department?.id,
          ...formattedProps,
          worktime_calculation_method:
            values.worktime_calculation_method == undefined ? null : values.worktime_calculation_method,
          contract_end: values.contract_end == undefined ? null : formattedProps.contract_end,
          user_type:
            formattedProps.user_type == '' || !formattedProps.user_type || formattedProps.user_type == '0'
              ? null
              : formattedProps.user_type,
          regime: values.regime ? values.regime : null,
        })
        .then((response) => {
          const userType = response.data.data.user_type;
          const res = {
            ...response.data,
            data: {
              ...response.data.data,
              user_type: userType == '' || !userType || userType == '0' ? null : userType,
              hr_days_week: hr_days_week == '0' || hr_days_week == 0 ? null : hr_days_week,
              hr_max_hours_week: hr_max_hours_week == '0' || hr_max_hours_week == 0 ? null : hr_max_hours_week,
              hr_max_hours_year: hr_max_hours_year == '0' || hr_max_hours_year == 0 ? null : hr_max_hours_year,
              hr_max_hours_trimester:
                hr_max_hours_trimester == '0' || hr_max_hours_trimester == 0 ? null : hr_max_hours_trimester,
              legal_leaves: legal_leaves == '0' || legal_leaves == 0 ? null : legal_leaves,
              extra_legal_days_off:
                extra_legal_days_off == '0' || extra_legal_days_off == 0 ? null : extra_legal_days_off,
              rtt: rtt == '0' || rtt == 0 ? null : rtt,
              previous_recovery: previous_recovery == '0' || previous_recovery == 0 ? null : previous_recovery,
              recovery_hours: recovery_hours == '0' || recovery_hours == 0 ? null : recovery_hours,
              default_dimona_hours:
                default_dimona_hours == '0' || default_dimona_hours == 0 ? null : default_dimona_hours,
              worker_type: worker_type == undefined ? null : worker_type,
            },
          };
          setResponse(res);
          setSaving(false);
          setFormHasChanged(false);
          setWantToCreateNewForm(false);
        })
        .catch((err) => {
          if (err.response?.data?.errors) {
            setShowErrorsModal(true);
            setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
          }
          setSaving(false);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/v3/users/${user!.recordId}/hr`, {
          departmentId: department?.id,
          ...convertSomeMomentPropertiesToStringDate(values, dateProperties),
          contract_end: values.contract_end == undefined && null,
          user_type:
            formattedProps.user_type == '' || !formattedProps.user_type || formattedProps.user_type == '0'
              ? null
              : formattedProps.user_type,
        })
        .then((response) => {
          setResponse(response.data);
          setSaving(false);
          setFormHasChanged(false);
          setWantToCreateNewForm(false);
        })
        .catch((error) => {
          setSaving(false);
          console.error(error);
        });
    }
  };

  if (loading) {
    return <Spin spinning={true} />;
  }

  const onErrorsOk = () => {
    setShowErrorsModal(false);
  };

  const onErrorsCancel = () => {
    setShowErrorsModal(false);
  };

  return (
    <div className={className}>
      {department?.accountType == 'FREE-CLOCKING' && (
        <ModalSwitchToProAccount visible={modalSwitchProVisible} setVisible={setModalSwitchProVisible} />
      )}
      {department?.accountType == 'FREE-CLOCKING' && (
        <PaidOptionOverlay setModalSwitchProVisible={setModalSwitchProVisible} />
      )}
      <Modal
        forceRender={true}
        maskClosable={false}
        destroyOnClose={true}
        visible={showErrorsModal && errors.length > 0}
        className={'modal-danger'}
        title={t('CONTRACTS.CREATE_CONTRACT_ERROR')}
        onOk={onErrorsOk}
        onCancel={onErrorsCancel}
        footer={[
          <Button key="back" onClick={onErrorsCancel}>
            {t('GLOBAL.OK')}
          </Button>,
        ]}
      >
        <List
          dataSource={errors}
          renderItem={(item) => (
            <List.Item>
              <p>{item}</p>
            </List.Item>
          )}
        />
      </Modal>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          className="user-form"
          onValuesChange={() => setFormHasChanged(true)}
          onFinish={onFinish}
          size={'large'}
        >
          {!!department?.params?.hr_historisation && (
            <header className="header">
              <div className="left">
                <b>{t('HR.HR_FORM_DATE')}</b>{' '}
                {t('GLOBAL.DATE_TO_DATE', {
                  date1: wantToCreateNewForm
                    ? moment(newStart).format('L')
                    : moment(response?.data.contract_start).format('L'),
                  date2: response?.data?.contract_end ? moment(response?.data.contract_end).format('L') : '__/__/____',
                })}
              </div>
              <div className="right">
                <Button
                  size="middle"
                  type="link"
                  style={{ fontWeight: 'normal', textDecoration: 'underline' }}
                  onClick={() => setIsModalHrFormListVisible(!isModalHrFormListVisible)}
                  disabled={freemium}
                >
                  {t('HR.SEE_ALL_FORMS')}
                </Button>
                <Button
                  className="create-form"
                  size="middle"
                  type="primary"
                  onClick={onWantToCreateNewForm}
                  disabled={freemium}
                >
                  {t('HR.NEW_FORM')}
                </Button>
                {/* <Button
                  size="middle"
                  type="primary"
                  style={{ background: colors.blueLightPastel, borderColor: colors.blueLightPastel }}
                >
                  {t('CONTRACTS.CREATE_CONTRACT')}
                </Button> */}
              </div>
            </header>
          )}
          <div className="card-shadow">
            <Status form={form} meta={response?.meta} setNewStart={setNewStart} />
          </div>
          <div className="card-shadow">
            <Settings department={department} meta={response?.meta} />
          </div>
          {department?.params?.fullHrTab && department.accountType == 'FREE-CLOCKING' && (
            <React.Fragment>
              <div className="card-shadow">
                <Worker meta={response?.meta} />
              </div>
              <div className="card-shadow">
                <Contract form={form} meta={response?.meta} />
              </div>
            </React.Fragment>
          )}
          {department?.params?.fullHrTab && department.accountType == 'FREE-CLOCKING' && (
            <div className="card-shadow">
              <Occupation meta={response?.meta} />
            </div>
          )}
          {department?.params?.fullHrTab && department.accountType == 'FREE-CLOCKING' && (
            <React.Fragment>
              <div className="card-shadow">
                <Reduction form={form} meta={response?.meta} />
              </div>
              <div className="card-shadow">
                <WageGap form={form} meta={response?.meta} />
              </div>
              <div className="card-shadow">
                <MealVoucher form={form} meta={response?.meta} />
              </div>
              <div className="card-shadow">
                <Car form={form} meta={response?.meta} />
              </div>
            </React.Fragment>
          )}
          <ModalHrFormList
            visible={isModalHrFormListVisible}
            onClose={() => setIsModalHrFormListVisible(false)}
            changeForm={(formId) => setFormId(formId)}
            userRecordId={user?.recordId || undefined}
            departmentId={department?.id}
          />
          <FormActions
            saving={saving}
            onReset={onReset}
            setWantToCreateNewForm={setWantToCreateNewForm}
            active={formHasChanged}
          />
        </Form>
      </Spin>
    </div>
  );
};

export default styled(HR)`
  position: relative;
  header {
    background: ${colors.blueLight};
    padding: 15px 20px;
    border-radius: 3px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h4 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  .card-shadow {
    margin-bottom: 20px;
  }

  .ant-row {
    margin-bottom: 5px !important;
  }
  margin-bottom: 10px;

  .ant-row.ant-form-item.one-line {
    .ant-col.ant-form-item-label {
      flex-grow: 1;
      text-align: left;
      label {
        color: rgba(0, 0, 0, 0.65);
      }
    }
    &.no-grow {
      padding: 0;
      margin-bottom: 10px;
      .ant-col.ant-form-item-control {
        flex-grow: inherit;
      }
    }
    .ant-col.ant-form-item-control {
      .ant-input-number.ant-input-number-lg {
        width: 70px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .header {
      flex-direction: column;

      .right {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 15px;

        .create-form {
          width: 100%;
        }
      }
    }
  }
`;

export interface IResponse {
  data: {
    id?: string;
    start: string;
    end?: string;
    km: number;
    transportation: string;
    partner_maritalstatus: string;
    wage_seizure: boolean;
    alimony: number;
    dependent_child: number;
    dependent_child_disabled: number;
    dependent_adult_min65: number;
    dependent_adult_min65_disabled: number;
    dependent_adult_plus65: number;
    dependent_adult_plus65_disabled: number;
    contract_number: string;
    contract_type: string;
    contract_start: string;
    contract_end: string;
    profession_code: string;
    employee_code: number;
    joint_committee: number;
    index_nsso: number;
    service_charge_type: number;
    department: any;
    sort_department: number;
    wage_category: number;
    function_classification: number;
    function_name: number;
    function_on_payslip: string;
    price: string;
  };
  meta: {
    [key: string]: {
      id: string;
      name: string;
    }[];
  };
}
