import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import { IDayoff } from '@/types/dayoff.model';
import { difference, getWindowSize } from '@/utils';
import { Button, Checkbox, Divider, Drawer, Form, Input, Radio, Select, notification } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
interface IHrPartnerCode {
  code: string;
  id: string;
  name: string;
  shortcode: string;
}

interface IHrPartner {
  id: string;
  name: string;
  codes: IHrPartnerCode[];
}

interface Props {
  className?: string;
  visible: boolean;
  dayoff?: IDayoff | null;
  onClose: () => void;
  onSave: (result: IDayoff) => void;
}

const DrawerDayoff: React.FC<Props> = ({ className, visible, dayoff, onClose, onSave }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hrPartners, setHrPartners] = useState<IHrPartner[]>([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    state: { activeDepartmentId, dayoffTypes },
  } = useContext(AppContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/hr-partners`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        setHrPartners(data);
      });

    form.resetFields();
    let fieldsValue: any = {};

    if (dayoff) {
      fieldsValue = {
        ...fieldsValue,
        ..._(dayoff).pick(['name', 'shortCode', 'dayoffType', 'hrPartners', 'displayUser', 'not_counted']).value(),
      };
    }
    form.setFieldsValue(fieldsValue);
    // eslint-disable-next-line
  }, [dayoff]);

  const onFinish = async (values: any) => {
    setIsSaving(true);
    let dayoffResponse;

    try {
      if (dayoff && !_.isEmpty(dayoff)) {
        const differences = difference(values, dayoff);
        if (!_.isEmpty(differences)) {
          dayoffResponse = await axios.patch(
            `${process.env.REACT_APP_API_URL}/v3/dayoffs/${dayoff.id!}`,
            {
              ...differences,
            },
            {
              params: {
                departmentId: activeDepartmentId,
              },
            },
          );
        }
      } else {
        dayoffResponse = await axios.post(`${process.env.REACT_APP_API_URL}/v3/dayoffs`, {
          departmentId: activeDepartmentId,
          ...values,
        });
      }

      if (dayoffResponse) {
        onSave({
          ...dayoffResponse?.data?.dayoff,
        });
        onClose();
      } else {
        onClose();
      }
    } catch (error) {
      console.error(error);
      const description = dayoff?.id ? t('DAYOFFS.UPDATE_DAYOFF_TYPE_ERROR') : t('DAYOFFS.DAYOFF_TYPE_CREATION_ERROR');
      notification.open({
        message: t('GLOBAL.AN_ERROR_OCCURRED'),
        description,
        type: 'error',
      });
    }
    setIsSaving(false);
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={dayoff?.id ? t('DAYOFFS.DAYOFF_TYPE_MODIFICATION') : t('DAYOFFS.ADDING_DAYOFF_TYPE')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
          <Input placeholder={t('DAYOFFS.DAYOFF_TYPE_NAME_PLACEHOLDER')} />
        </Form.Item>
        <Form.Item name="shortCode" label={t('GLOBAL.CODE')} rules={[{ required: true }, { max: 5 }]}>
          <Input placeholder={t('DAYOFFS.DAYOFF_TYPE_CODE_PLACEHOLDER')} />
        </Form.Item>
        <Form.Item name={'dayoffType'} label={t('FORMS.ABSENCE_TYPE')} rules={[{ required: true }]}>
          <Select placeholder={t('FORMS.ABSENCE_TYPE')}>
            {dayoffTypes.map((dayoff) => (
              <Option value={dayoff.id}>{dayoff.label}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="displayUser" label={t('DAYOFFS.DISPLAY_USER')}>
          <Radio.Group>
            <Radio value={true}>{t('GLOBAL.YES')}</Radio>
            <Radio value={false}>{t('GLOBAL.NO')}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="not_counted" valuePropName="checked">
          <Checkbox>
            <span style={{ display: 'flex', alignItems: 'center' }}>{t('DAYOFFS.NOT_COUNTED')}</span>
          </Checkbox>
        </Form.Item>
        {!!hrPartners?.length && (
          <React.Fragment>
            <Divider orientation="left" plain>
              {t('DAYOFFS.HR_PARTNER_CODE_EQUIVALENT')}
            </Divider>
            {hrPartners?.map((hrPartner) => {
              return (
                <Form.Item key={hrPartner.id} name={['hrPartners', hrPartner.id]} label={hrPartner.name}>
                  <Select
                    showSearch
                    filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    allowClear
                    placeholder={hrPartner.name}
                  >
                    {hrPartner?.codes?.map((code) => {
                      return (
                        <Option key={`${hrPartner.id}_${code.id}`} value={code.id}>
                          {`${code.name} (${code.code})`}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            })}
          </React.Fragment>
        )}
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerDayoff;
