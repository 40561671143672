import { Button, Form, InputNumber, message, Modal } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  departmentId?: string;
  timestamp: number | null;
  value?: number | null;
  onClose: () => void;
  onSave: () => void;
}

const ModalProvisional: React.FC<Props> = ({ className, departmentId, timestamp, value, onClose, onSave }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
    if (timestamp) {
      form.setFieldsValue({ [timestamp!]: value });
    }
  }, [timestamp, value]);

  const onFinish = (values: any) => {
    setIsSaving(true);
    let data = {};

    if (moment.unix(timestamp!).startOf('day').unix() > moment().startOf('day').unix()) {
      data = {
        provisional: {
          ...values,
        },
      };
    } else {
      data = {
        real: {
          ...values,
        },
      };
    }

    Axios.post(
      `${process.env.REACT_APP_API_URL}/v3/insights/daily-turnover`,
      {
        ...data,
      },
      {
        params: {
          departmentId: departmentId,
          start: moment.unix(timestamp!).startOf('month').unix(),
        },
      },
    )
      .then(() => {
        setIsSaving(false);
        onClose();
        onSave();
      })
      .catch((error) => {
        message.error(t('GLOBAL.MESSAGE_SAVING_ERROR'));
        setIsSaving(false);
        console.log(error);
      });
  };

  return (
    <Modal
      className={className}
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={!!timestamp}
      footer={null}
      onCancel={() => onClose()}
    >
      <Form layout="vertical" size="large" form={form} onFinish={onFinish} requiredMark={false}>
        {timestamp && (
          <Form.Item
            label={t(
              `SETTINGS.INSIGHTS.DAILY_TURNOVER.${
                moment.unix(timestamp).startOf('day').unix() > moment().startOf('day').unix()
                  ? 'MODAL_PROVISIONAL'
                  : 'MODAL_REAL'
              }.TITLE`,
            )}
            name={timestamp}
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: 220 }} />
          </Form.Item>
        )}

        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose} style={{ width: 180, marginRight: 20 }}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving} style={{ width: 180 }}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default styled(ModalProvisional)`
  .ant-modal-body {
    padding-top: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-form-item-label {
      text-align: center;
      label {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .ant-input-number-input-wrap {
      input {
        text-align: center;
      }
    }
  }
`;
