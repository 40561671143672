import ContainerView from '@/layouts/ContainerView';
import TableView from '@/layouts/TableView';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from './components/Header';
import AppContext from '@/pages/app/context';
import Axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Select, Table, Form, Modal, Empty, message } from 'antd';
import styled from 'styled-components';
import { isEmpty, pick } from 'lodash';
import colors from '@/styles/colors';
import { DIMONA_WORK_TYPES } from '@/types/dimona.model';
import NewBadge from '@/pages/app/components/NewBadge';

interface IDimona {
  shyftId: string;
  user: {
    recordId: string;
    displayName: string;
  };
  status?: string;
  workerType?: string;
  start: number;
  end: number;
  hours: number;
  process?: boolean;
  placeOfWork?: string | null;
}

const { Option } = Select;

interface Props {
  className?: string;
}

const DimonaAuditPage: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartmentId, activeDepartment },
  } = useContext(AppContext);
  const history = useHistory();
  const [loadingDimona, setLoadingDimona] = useState<boolean>(false);
  const [refreshCounter, setRefreshCounter] = useState<number>(moment().unix());
  const [dimona, setDimona] = useState<IDimona[]>([]);
  const [filteringDimona, setFilteringDimona] = useState<boolean>(false);
  const [filteredDimona, setFilteredDimona] = useState<IDimona[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [filterSearch, setFilterSearch] = useState<string>('');
  const [columns, setColumns] = useState<any[]>([]);
  const [form] = Form.useForm();
  const refDimona = useRef<IDimona[]>(dimona);
  const [placesOfWork, setPlacesOfWork] = useState<string[]>([]);
  refDimona.current = dimona;

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/department/places-of-work`, {
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then(({ data }) => {
        setPlacesOfWork(data);
      })
      .catch((err) => {
        console.log(err);
        message.error(t('DIMONA.GET_PLACES_OF_WORK_ERROR'));
      });
  }, []);

  useEffect(() => {
    const momentLocale = moment.locale();
    if (i18n.language !== momentLocale) {
      moment.tz.setDefault('Atlantic/Reykjavik');
      moment.updateLocale(i18n.language, {
        week: {
          dow: 1,
        },
      });
    }
    const columns = [
      {
        title: t('GLOBAL.NAME'),
        key: 'user',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IDimona) => {
          const { recordId, displayName } = record?.user || {};
          if (recordId) {
            return <Link to={`/app/team/collaborators/${recordId}`}>{displayName}</Link>;
          } else {
            return <span>{displayName}</span>;
          }
        },
      },
      {
        title: t('GLOBAL.STATUS'),
        key: 'status',
        dataIndex: ['user', 'status'],
      },
      {
        title: t('GLOBAL.WORKER_TYPE'),
        key: 'workerType',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          const { shyftId } = record;
          return (
            <Form.Item name={[shyftId]}>
              <Select style={{ width: 200 }}>
                {DIMONA_WORK_TYPES.map((workType) => {
                  const { id } = workType;
                  return (
                    <Option key={`workType_${id}`} value={id!}>
                      {t(`DIMONA.WORKTYPES.${id}`)}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          );
        },
      },
      ...(placesOfWork.length > 0
        ? [
            {
              title: <span style={{ display: 'flex', alignItems: 'center' }}>{t('DIMONA.PLACE_OF_WORK')}</span>,
              key: 'placeOfWork',
              // eslint-disable-next-line react/display-name
              render: (text: any, record: IDimona) => {
                return record.placeOfWork ? record.placeOfWork : t('DIMONA.NO_PLACE_OF_WORK');
              },
            },
          ]
        : []),
      {
        title: t('GLOBAL.START'),
        key: 'start',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IDimona) => {
          return moment.unix(record.start).format('L HH:mm');
        },
      },
      {
        title: t('GLOBAL.END'),
        key: 'end',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IDimona) => {
          return moment.unix(record.end).format('L HH:mm');
        },
      },
      {
        title: t('GLOBAL.HOURS'),
        key: 'hours',
        dataIndex: 'hours',
      },
      {
        key: 'action',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: IDimona) => {
          return (
            <Link
              style={{ color: colors.green }}
              to={`/app/hours/manage/${
                activeDepartment?.scheduleParams?.default_schedule_view == 'ops'
                  ? 'operational'
                  : activeDepartment?.scheduleParams?.default_schedule_view
              }/${moment.unix(record.start).startOf('week').format('YYYY-MM-DD')}`}
            >
              {t('GLOBAL.SEE_SCHEDULE')}
            </Link>
          );
        },
      },
    ];
    setColumns(columns);
  }, [i18n.language, placesOfWork]);

  useEffect(() => {
    if (!location?.pathname) {
      return;
    }
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    setLoadingDimona(true);
    setDimona([]);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/operations/dimona-control`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then(({ data }) => {
        if (mounted) {
          setDimona(data);
          setLoadingDimona(false);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingDimona(false);
        }
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [location?.pathname, refreshCounter]);

  useEffect(() => {
    setFilteringDimona(true);
    if (dimona) {
      const filteredDimona = dimona.filter((dimo) => {
        let showDimona = true;
        if (filterSearch) {
          const { user: { displayName = '' } = {} } = dimo;
          showDimona = [displayName].some((key) => {
            return key.toLowerCase().includes(filterSearch.toLowerCase());
          });
        }
        return showDimona;
      });
      setFilteredDimona(filteredDimona);
    } else {
      setFilteredDimona([]);
    }
    setTimeout(() => {
      setFilteringDimona(false);
    }, 0);
  }, [dimona, filterSearch]);

  useEffect(() => {
    resetSelectedRows();
  }, [filteredDimona]);

  const resetSelectedRows = () => {
    form.resetFields();
    const defaultValues: { [index: string]: any } = {};
    for (let index = 0; index < filteredDimona.length; index++) {
      const { shyftId, workerType } = filteredDimona[index];
      defaultValues[shyftId] = workerType;
    }
    form.setFieldsValue(defaultValues);
    setSelectedRowKeys([]);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IDimona[]) => {
      setSelectedRowKeys(selectedRows.map((x) => x.shyftId));
    },
    getCheckboxProps: (record: IDimona) => ({
      disabled: !!record.process,
    }),
  };

  const onWantToSend = () => {
    const body = pick(form.getFieldsValue(), selectedRowKeys);
    const fields = form.getFieldsError();
    const errors = fields.filter((x) => x.errors?.length);
    form.setFields(errors.map((x) => ({ ...x, errors: [] })));

    if (isEmpty(body)) {
      return;
    }

    let error = null;
    for (const property in body) {
      if (!body[property]) {
        error = property;
        break;
      }
    }

    if (error) {
      form.setFields([
        {
          name: error,
          errors: ['invalid'],
        },
      ]);
      return;
    }

    const modal = Modal.confirm({
      title: t('DIMONA.AUDIT.BTN'),
      icon: null,
      content: (
        <React.Fragment>
          {t('DIMONA.AUDIT.MODAL_CONTENT')}{' '}
          <a
            style={{ color: colors.green, display: 'inline-block' }}
            onClick={(e) => {
              e.preventDefault();
              history.push('/app/documents/dimona');
              modal.destroy();
            }}
          >
            {t('DIMONA.TITLE')}
          </a>
        </React.Fragment>
      ),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.SEND'),
      onOk: () => onSend(),
      onCancel: () => {},
    });
  };

  const onSend = () => {
    const body = pick(form.getFieldsValue(), selectedRowKeys);

    return Axios.post(
      `${process.env.REACT_APP_API_URL}/v3/operations/dimona-batch`,
      { dimona: Object.entries(body).map(([k, v]) => ({ shyftId: k, workerType: v })) },
      {
        params: {
          departmentId: activeDepartmentId,
        },
      },
    )
      .then(() => {
        setRefreshCounter(moment().unix());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ContainerView>
      <TableView className={className}>
        <Header setFilterSearch={setFilterSearch} onWantToSend={onWantToSend} />
        <Form form={form} scrollToFirstError={true}>
          <Table
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys,
              ...rowSelection,
            }}
            dataSource={filteredDimona}
            columns={columns}
            loading={loadingDimona || filteringDimona}
            rowClassName={(record: IDimona) => (record.process ? 'processing' : '')}
            rowKey="shyftId"
            pagination={{ position: ['bottomCenter', 'bottomCenter'] }}
            onChange={() => resetSelectedRows()}
            locale={{ emptyText: <Empty description={t('DIMONA.AUDIT.NO_DATA')} /> }}
          />
        </Form>
      </TableView>
    </ContainerView>
  );
};

export default styled(DimonaAuditPage)`
  .ant-form-item-has-success {
    margin-bottom: 0;
  }
  .ant-table-row {
    &.processing {
      background-color: ${colors.greenExtraLight};
      .ant-table-selection-column .ant-checkbox-wrapper {
        display: none;
      }
    }
  }
`;
