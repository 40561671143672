import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import { IShortcut } from '@/types/shortcut.model';
import { difference, getWindowSize } from '@/utils';
import { Button, Drawer, Form, Input, notification, TimePicker } from 'antd';
import Axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  visible: boolean;
  shortcut?: IShortcut | null;
  onClose: () => void;
  onSave: (result: IShortcut) => void;
}

const DrawerShortcut: React.FC<Props> = ({ className, visible, shortcut, onClose, onSave }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    state: { activeDepartmentId },
  } = useContext(AppContext);

  useEffect(() => {
    form.resetFields();
    let fieldsValue: any = {};

    if (shortcut) {
      fieldsValue = {
        ...fieldsValue,
        ..._(shortcut).pick(['name', 'shortcut']).value(),
        start: shortcut?.start ? moment.unix(shortcut.start) : undefined,
        end: shortcut?.end ? moment.unix(shortcut.end) : undefined,
      };
    }
    form.setFieldsValue(fieldsValue);
    // eslint-disable-next-line
  }, [shortcut]);

  const onFinish = async (values: any) => {
    setIsSaving(true);
    let shortcutResponse;

    try {
      if (shortcut && !_.isEmpty(shortcut)) {
        const differences = difference(values, shortcut);
        if (!_.isEmpty(differences)) {
          shortcutResponse = await Axios.patch(
            `${process.env.REACT_APP_API_URL}/v3/shortcuts/${shortcut.id!}`,
            {
              ...differences,
              start: differences?.start ? differences.start.unix() : undefined,
              end: differences?.end ? differences.end.unix() : undefined,
            },
            {
              params: {
                departmentId: activeDepartmentId,
              },
            },
          );
        }
      } else {
        shortcutResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/v3/shortcuts`, {
          departmentId: activeDepartmentId,
          ...values,
          start: values?.start ? values.start.unix() : undefined,
          end: values?.end ? values.end.unix() : undefined,
        });
      }

      if (shortcutResponse) {
        onSave({
          ...shortcutResponse?.data?.shortcut,
        });
        onClose();
      } else {
        onClose();
      }
    } catch (error) {
      console.error(error);
      const description = shortcut?.id
        ? t('SHORTCUTS.SHORTCUT_UPDATE_FAILED')
        : t('SHORTCUTS.SHORTCUT_CREATION_FAILED');
      notification.open({
        message: t('GLOBAL.AN_ERROR_OCCURRED'),
        description,
        type: 'error',
      });
    }
    setIsSaving(false);
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={shortcut?.id ? t('SHORTCUTS.SHORTCUT_MODIFICATION') : t('SHORTCUTS.ADDING_SHORTCUT')}
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
          <Input placeholder={t('SHORTCUTS.SHORTCUT_NAME_PLACEHOLDER')} />
        </Form.Item>
        <Form.Item name="shortcut" label={t('GLOBAL.CODE')} rules={[{ required: true }]}>
          <Input placeholder={t('SHORTCUTS.SHORTCUT_CODE_PLACEHOLDER')} />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label={t('GLOBAL.START')}
            name={'start'}
            style={{ display: 'inline-block', width: 'calc(50% - 10px)', marginRight: 10 }}
            rules={[{ required: true }]}
          >
            <TimePicker
              format="HH:mm"
              style={{ width: '100%' }}
              onSelect={(e) => {
                form.setFieldsValue({
                  start: e,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label={t('GLOBAL.END')}
            name={'end'}
            style={{ display: 'inline-block', width: 'calc(50% - 10px)' }}
            rules={[{ required: true }]}
          >
            <TimePicker
              format="HH:mm"
              style={{ width: '100%' }}
              onSelect={(e) => {
                form.setFieldsValue({
                  end: e,
                });
              }}
            />
          </Form.Item>
        </Form.Item>

        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default DrawerShortcut;
