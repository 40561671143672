import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '@/pages/app/context';
import styled from 'styled-components';
import { Button } from 'antd';
import moment from 'moment';

interface Props {
  className?: string;
  today?: boolean;
  yesterday?: boolean;
  thisWeek?: boolean;
  last7days?: boolean;
  lastWeek?: boolean;
  thisMonth?: boolean;
  lastMonth?: boolean;
  thisYear?: boolean;
  lastYear?: boolean;
  setRangePickerValues: React.Dispatch<React.SetStateAction<[moment.Moment, moment.Moment]>>;
}

const name: React.FC<Props> = ({
  className,
  today = true,
  yesterday = true,
  thisWeek = true,
  last7days = true,
  lastWeek = true,
  thisMonth = true,
  lastMonth = true,
  thisYear = true,
  lastYear = true,
  setRangePickerValues,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);

  return (
    <div className={className}>
      {today && (
        <Button type="ghost" onClick={() => setRangePickerValues([moment().startOf('day'), moment().endOf('day')])}>
          {t('GLOBAL.TODAY')}
        </Button>
      )}
      {yesterday && (
        <Button
          type="ghost"
          onClick={() =>
            setRangePickerValues([
              moment().startOf('day').subtract(1, 'day'),
              moment().endOf('day').subtract(1, 'days'),
            ])
          }
        >
          {t('GLOBAL.YESTERDAY')}
        </Button>
      )}
      {thisWeek && (
        <Button type="ghost" onClick={() => setRangePickerValues([moment().startOf('week'), moment().endOf('week')])}>
          {t('GLOBAL.THIS_WEEK')}
        </Button>
      )}
      {lastWeek && (
        <Button
          type="ghost"
          onClick={() =>
            setRangePickerValues([
              moment().startOf('week').subtract(1, 'week'),
              moment().endOf('week').subtract(1, 'week'),
            ])
          }
        >
          {t('GLOBAL.LAST_WEEK')}
        </Button>
      )}
      {last7days && (
        <Button
          type="ghost"
          onClick={() =>
            setRangePickerValues([
              moment().startOf('day').subtract(8, 'day'),
              moment().endOf('day').subtract(1, 'days'),
            ])
          }
        >
          {t('GLOBAL.LAST_7_DAYS')}
        </Button>
      )}
      {thisMonth && (
        <Button type="ghost" onClick={() => setRangePickerValues([moment().startOf('month'), moment().endOf('month')])}>
          {t('GLOBAL.THIS_MONTH')}
        </Button>
      )}
      {lastMonth && (
        <Button
          type="ghost"
          onClick={() =>
            setRangePickerValues([
              moment().startOf('month').subtract(1, 'month'),
              moment().startOf('month').subtract(1, 'month').endOf('month'),
            ])
          }
        >
          {t('GLOBAL.LAST_MONTH')}
        </Button>
      )}
      {thisYear && (
        <Button type="ghost" onClick={() => setRangePickerValues([moment().startOf('year'), moment()])}>
          {t('GLOBAL.THIS_YEAR')}
        </Button>
      )}
      {lastYear && (
        <Button
          type="ghost"
          onClick={() =>
            setRangePickerValues([
              moment().startOf('year').subtract(1, 'year'),
              moment().endOf('year').subtract(1, 'year'),
            ])
          }
        >
          {t('GLOBAL.LAST_YEAR')}
        </Button>
      )}
    </div>
  );
};

export default styled(name)`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px 0;
`;
