import TableView from '@/layouts/TableView';
import { Button, message, notification, Space, Upload } from 'antd';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import DateSelector from '../../components/DateSelector';
import ForecastsTable from './ForecastsTable';
import { UploadOutlined } from '@ant-design/icons';
import AppContext from '@/pages/app/context';
import axios from 'axios';

export interface IForecast {
  taskId: string;
  label: string;
  data: IForecastData[];
}

export interface IForecastData {
  currentValue: number;
  forecastValue: number;
  hour: number;
  details: {
    displayName: string;
    start: number;
    end: number;
  }[];
}

const Forecast: React.FC = () => {
  const {
    state: { activeDepartment, activeSection },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();
  const { formatedDate } = useParams();
  const [selectedDate, setSelectedDate] = useState<Moment>(moment(formatedDate));
  const [counter, setCounter] = useState<number>(0);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [forecasts, setForecasts] = useState<IForecast[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCopying, setIsCopying] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploadingForecast, setIsUploadingForecast] = useState<boolean>(false);
  const counterRef = useRef<number>(counter);
  counterRef.current = counter;

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    setIsUploading(false);
    setIsLoading(true);
    setIsDeleting(false);
    setIsCopying(false);
    setForecasts([]);
    if (formatedDate && activeDepartment) {
      setSelectedDate(moment(formatedDate));
      setCounter(counterRef.current + 1);
    }
  }, [formatedDate, activeDepartment, activeSection]);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/task-forecast`, {
        params: {
          departmentId: activeDepartment?.id,
          sectionId: activeSection,
          day: moment(formatedDate).unix(),
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        if (mounted) {
          setForecasts(data.forecasts);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error) && mounted) {
          message.error(t('TASK_FORECAST.MESSAGE_LOADING_ERROR'));
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [counter]);

  const uploadRequest = (options: any) => {
    setIsUploading(true);
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append('file', file);
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/v3/operations/task-forecast/import`,
      data: formData,
      params: {
        departmentId: activeDepartment?.id,
        sectionId: activeSection,
        day: moment(formatedDate).unix(),
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        notification.open({
          message: response?.data?.message,
          type: 'info',
        });
        onSuccess(response, file);
        setIsUploading(false);
        setCounter(counter + 1);
      })
      .catch((error) => {
        message.error(t('FORMS.FILE_UPLOAD_ERROR'));
        onError(error);
        console.log(error);
        setIsUploading(false);
      });
  };

  const uploadForecastRequest = (options: any) => {
    setIsUploadingForecast(true);
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append('file', file);
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/v3/forecasts/import`,
      data: formData,
      params: {
        departmentId: activeDepartment?.id,
        sectionId: activeSection,
        day: moment(formatedDate).unix(),
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        onSuccess(response, file);
        setIsUploadingForecast(false);
        setCounter(counter + 1);
      })
      .catch((error) => {
        message.error(t('FORMS.FILE_UPLOAD_ERROR'));
        onError(error);
        console.log(error);
        setIsUploadingForecast(false);
      });
  };

  const onCopyLastWeekDay = () => {
    setIsCopying(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/operations/task-forecast/copy`, null, {
        params: {
          departmentId: activeDepartment?.id,
          sectionId: activeSection,
          day: moment(formatedDate).unix(),
        },
        headers: {
          'X-Locale': i18n.language,
        },
      })
      .then(({ data }) => {
        setForecasts(data.forecasts);
        setIsCopying(false);
      })
      .catch((error) => {
        setIsCopying(false);
        console.log(error);
        if (error.response?.data?.message) {
          message.error(error.response.data.message);
        } else {
          message.error(t('TASK_FORECAST.MESSAGE_COPYING_ERROR'));
        }
      });
  };

  const onDeleteDay = () => {
    setIsDeleting(true);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/operations/task-forecast`, {
        params: {
          departmentId: activeDepartment?.id,
          sectionId: activeSection,
          day: moment(formatedDate).unix(),
        },
      })
      .then(({ data }) => {
        setForecasts(data.forecasts);
        setIsDeleting(false);
      })
      .catch((error) => {
        console.log(error);
        message.error(t('TASK_FORECAST.MESSAGE_DELETION_ERROR'));
        setIsDeleting(false);
      });
  };

  const onDownload = () => {
    setIsDownloading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/task-forecast/export`, {
        params: {
          departmentId: activeDepartment?.id,
          sectionId: activeSection,
          day: moment(formatedDate).unix(),
        },
        responseType: 'blob',
      })
      .then((response) => {
        setIsDownloading(false);
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        window.open(URL.createObjectURL(file));
      })
      .catch((error) => {
        console.log(error);
        message.error(t('GLOBAL.MESSAGE_DOWNLOAD_ERROR'));
        setIsDownloading(false);
      });
  };

  return (
    <TableView>
      <header>
        <div className="left">
          <h2 style={{ marginLeft: 0, marginRight: 20 }}>{t('TASK_FORECAST.TITLE')}</h2>
          <DateSelector
            picker={'day'}
            startDate={moment(selectedDate)}
            endDate={moment(selectedDate).endOf('day')}
            onArrowClick={(value) => {
              setForecasts([]);
              history.push(moment(selectedDate).add(value, 'day').format('YYYY-MM-DD'));
            }}
            onDatePickerChange={(date) => {
              if (date) {
                setForecasts([]);
                history.push(date?.format('YYYY-MM-DD'));
              }
            }}
          />
          <Space>
            {activeSection && (
              <React.Fragment>
                <Button loading={isDeleting} onClick={onDeleteDay} type="primary" danger>
                  {t('GLOBAL.ERASE')}
                </Button>
                <Button loading={isCopying} onClick={onCopyLastWeekDay}>
                  {t('TASK_FORECAST.COPY_LAST_WEEKDAY', { weekday: moment(selectedDate).format('dddd') })}
                </Button>
              </React.Fragment>
            )}
          </Space>
        </div>
        <div className="right">
          <Space>
            <Upload showUploadList={false} multiple={false} customRequest={uploadRequest} accept=".skv, .csv">
              <Button loading={isUploading} danger icon={<UploadOutlined />}>
                Upload planning
              </Button>
            </Upload>
            {activeSection && (
              <Upload
                showUploadList={false}
                multiple={false}
                customRequest={uploadForecastRequest}
                accept=".xls, .xlsx"
              >
                <Button loading={isUploadingForecast} type="primary" danger icon={<UploadOutlined />}>
                  Upload Forecast
                </Button>
              </Upload>
            )}
            <Button type="primary" loading={isDownloading} onClick={onDownload}>
              <i className="icon-download" />
              {t('TASK_FORECAST.DOWNLOAD_TASK_REPORT')}
            </Button>
          </Space>
        </div>
      </header>
      <ForecastsTable
        loading={isLoading}
        department={activeDepartment || null}
        activeSection={activeSection}
        forecasts={forecasts}
      />
    </TableView>
  );
};

export default Forecast;
