import { Button, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
  onWantToSend: () => void;
}

const Header: React.FC<Props> = ({ className, setFilterSearch, onWantToSend }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });

  return (
    <header className={className}>
      <div className="left">
        <h2>{t('DIMONA.AUDIT.TITLE')}</h2>
      </div>
      <div className="right">
        <Input
          allowClear
          placeholder={t('GLOBAL.SEARCH')}
          onChange={(e) => {
            setFilterSearch(e.target.value);
          }}
        />
        <Button type="primary" onClick={onWantToSend}>
          {t('DIMONA.AUDIT.BTN')}
        </Button>
      </div>
    </header>
  );
};

export default styled(Header)`
  align-items: center;
  .right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  @media screen and (max-width: 900px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;

    .left {
      h2 {
        margin: 0 !important;
      }
    }

    .right {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      input,
      button {
        width: 100%;
      }
    }
  }
`;
