import colors from '@/styles/colors';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import AppContext from '@/pages/app/context';
import { IShift } from '@/types/shift.model';
import ShiftRightClick from '../../components/ShiftRightClick';
import ShiftSectionBar from '../../components/ShiftSectionBar';
import ShiftDepartment from '../../components/ShiftDepartment';
import { shallowEqual, useSelector } from 'react-redux';
import { InitialStateType } from '../../redux/store';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import ShiftTags from '../../components/ShiftTags';
moment.tz.setDefault('Atlantic/Reykjavik');
interface Props {
  className?: string;
  shift: IShift;
}

const Unaivailability: React.FC<Props> = ({ className, shift }) => {
  const {
    state: { activeSection, activeDepartment },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: true });

  const { showOtherDepartments } = useSelector(
    ({ showOtherDepartments }: InitialStateType) => ({
      showOtherDepartments,
    }),
    shallowEqual,
  );

  const [startDay, setStartDay] = useState<number>(0);
  const [endDay, setEndDay] = useState<number>(0);

  useEffect(() => {
    setStartDay(
      moment
        .unix(shift.start || 0)
        .startOf('day')
        .unix(),
    );
    setEndDay(
      moment
        .unix(shift.start || 0)
        .endOf('day')
        .unix(),
    );
  }, [shift]);

  return (
    <div
      className={`
        ${className}
        ${
          activeDepartment?.scheduleParams?.ghost_shifts && activeSection && activeSection !== shift.section?.id
            ? 'other-section'
            : ''
        }
      `}
    >
      <ShiftRightClick shift={shift}>
        <div className="card">
          {activeSection !== shift.section?.id && <ShiftSectionBar section={shift.section} />}
          {shift.start === startDay && shift.end === endDay && (
            <React.Fragment>{t('GLOBAL.UNAVAILABLE')}</React.Fragment>
          )}
          {(shift.start !== startDay || shift.end !== endDay) && (
            <React.Fragment>
              <span style={{ display: 'block' }}>{t('GLOBAL.UNAVAILABILITY')}</span>
              <div style={{ opacity: 0.3 }}>
                <ShiftTags picker={'week'} section={shift.section} skills={[]} />
              </div>
              <span>
                {moment.unix(shift.start!).format('HH:mm')} - {moment.unix(shift.end!).format('HH:mm')}
              </span>
            </React.Fragment>
          )}

          {showOtherDepartments && <ShiftDepartment departmentId={shift.departmentId!} />}
        </div>
      </ShiftRightClick>
    </div>
  );
};

export default styled(Unaivailability)`
  position: relative;
  background: #f4f4f4;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 5px 10px;
  width: 100%;
  margin: 2px 0;
  text-align: left;
  color: ${colors.blue};
  flex: 1;
  box-sizing: border-box;

  &.other-section {
    opacity: 0.5;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
  .card {
    font-size: 12px;
    color: #bebebe;
    text-transform: uppercase;
  }
`;
