import colors from '@/styles/colors';
import { IShiftBlock } from '@/types/shift-block.model';
import React, { Dispatch, useContext, useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { InitialStateType, store } from '../../redux/store';
import ShiftBlock from './ShiftBlock';
import CreateShiftBlockButton from './CreateShiftBlockButton';
import axios, { CancelTokenSource } from 'axios';
import { ActionType } from '../../redux/actions';
import { Spin, Tooltip } from 'antd';
import AppContext from '@/pages/app/context';
import HelpTooltip from './HelpTooltip';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

const ShiftBlocksProvider: React.FC = () => {
  return (
    <Provider store={store}>
      <ShiftBlocksStyled />
    </Provider>
  );
};

const ShiftBlocks: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { state: appContextState } = useContext(AppContext);
  const { activeDepartmentId } = appContextState;
  const dispatch: Dispatch<ActionType> = useDispatch();
  const { shift_blocks, shift_blocks_loading } = useSelector(
    ({ shift_blocks, shift_blocks_loading }: InitialStateType) => ({
      shift_blocks,
      shift_blocks_loading,
    }),
  );
  useEffect(() => {
    get_shift_blocks();
  }, []);

  const get_shift_blocks = async () => {
    try {
      dispatch({
        type: 'SET_SHIFT_BLOCKS_LOADING',
        payload: true,
      });
      const cancel_token_source: CancelTokenSource = axios.CancelToken.source();
      const { data }: { data: IShiftBlock[] } = await axios.get(`${process.env.REACT_APP_API_URL}/v3/shifts/block`, {
        cancelToken: cancel_token_source.token,
        params: {
          departmentId: activeDepartmentId,
          picker: 'month',
        },
      });
      dispatch({
        type: 'SET_SHIFT_BLOCKS',
        payload: data.filter((sb) => sb.active),
      });
      dispatch({
        type: 'SET_SHIFT_BLOCKS_LOADING',
        payload: false,
      });
    } catch (err) {
      dispatch({
        type: 'SET_SHIFT_BLOCKS_LOADING',
        payload: false,
      });
    }
  };

  return (
    <Provider store={store}>
      <div className={`${className}`}>
        <div className="left">
          <p className="title">{t('SCHEDULE.MONTHLY_VIEW.SHIFT_BLOCKS.TITLE')}</p>
          <p className="description">{t('SCHEDULE.MONTHLY_VIEW.SHIFT_BLOCKS.DESCRIPTION')}</p>
        </div>
        <div className="center">
          {!shift_blocks_loading ? (
            <>
              {shift_blocks.map((block) => (
                <ShiftBlock key={`${block.id}`} block={block} />
              ))}
              <CreateShiftBlockButton />
            </>
          ) : (
            <div className="spinner">
              <Spin spinning={shift_blocks_loading} size="small" />
            </div>
          )}
        </div>
        <div className="right">
          <Tooltip color="#f7f9ff" title={<HelpTooltip />} placement="topRight">
            <i className="icon-info-circled"></i>
          </Tooltip>
        </div>
      </div>
    </Provider>
  );
};

const ShiftBlocksStyled = styled(ShiftBlocks)`
  position: sticky;
  bottom: 0;
  z-index: 2;
  margin: 15px;
  background-color: #fff;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }

  .left {
    max-width: 100px;

    .title {
      font-size: 16px;
    }

    .description {
      font-size: 11px;
      color: ${colors.bluePastel};
    }
  }

  .center {
    display: flex;
    align-items: center;
    margin-left: 25px;
    flex: 1;
    flex-wrap: wrap;
    gap: 5px;

    .spinner {
      display: flex;
      justify-content: center;
      flex: 1;
    }
  }

  .right {
    cursor: pointer;
  }
`;

export default ShiftBlocksProvider;
