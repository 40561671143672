import React, { Dispatch, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '@/styles/colors';
import AppContext from '@/pages/app/context';
import Axios from 'axios';
import { Col, Row, Spin } from 'antd';
import { isFeatureEnabled, isNullOrUndefined } from '@/utils';
import Clockings from './components/Clockings';
import WeekGeolocation from './components/WeekGeolocation';
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { InitialStateType, store } from './redux/store';
import { ActionType } from './redux/actions';
import moment from 'moment';
import 'moment-timezone';
import ModalYesterdayTurnover from '../components/ModalYesterdayTurnover';
import DashboardHeader from './components/Header';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ModalFreemiumOnboarding from './components/freemium-onboarding/ModalFreemiumOnboarding';
import { useParams } from 'react-router-dom';
import { FEATURES } from '@/types/features.model';
moment.tz.setDefault('Atlantic/Reykjavik');

interface Props {
  className?: string;
  showModalTrialOver: boolean;
}

const DashboardProvider: React.FC<Props> = ({ showModalTrialOver }) => {
  return (
    <Provider store={store}>
      <DashboardPageStyled showModalTrialOver={showModalTrialOver} />
    </Provider>
  );
};

interface IDashboardData {
  shifts?: number;
  totalMinutes?: number;
  totalCosts?: number;
  totalMoney?: number;
  productivity?: number;
  people?: number;
  stressLevel?: number;
  currentClockings?: number;
  finishedClockings?: number;
  weekClockings?: number;
}

const DashboardPage: React.FC<Props> = ({ className, showModalTrialOver }) => {
  const {
    state: { activeDepartment, activeSection, users, features },
  } = useContext(AppContext);

  const { t } = useTranslation(undefined, { useSuspense: true });
  const [dashboardData, setDashboardData] = useState<IDashboardData>({});
  const [dashboardDataIsLoading, setDashboardDataIsLoading] = useState<boolean>(false);
  const [lastTurnoverTimestamp, setLastTurnoverTimestamp] = useState<number | null>(null);
  const params = new URLSearchParams(window.location.search);
  const [modalFreemiumOnboardingVisible, setModalFreemiumOnboardingVisible] = useState<boolean>(false);

  const { selectedDate, startDate, endDate } = useSelector(
    ({ selectedDate, startDate, endDate }: InitialStateType) => ({
      selectedDate,
      startDate,
      endDate,
    }),
    shallowEqual,
  );

  const dashboardDispatch: Dispatch<ActionType> = useDispatch();

  useEffect(() => {
    if (users.length < 5 && params.get('productTour') == 'true') {
      setModalFreemiumOnboardingVisible(true);
    }
  }, [params]);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    if (!activeDepartment) {
      return;
    }

    if (activeDepartment?.role === 'ADMIN' && activeDepartment.params?.enableTurnover) {
      const hideTurnover = localStorage.getItem(`${activeDepartment?.id}_hideTurnover`);
      if (hideTurnover !== moment().format('YYYY-MM-DD')) {
        Axios.get(`${process.env.REACT_APP_API_URL}/v3/insights/daily-turnover/last`, {
          params: {
            departmentId: activeDepartment?.id,
            sectionId: activeSection,
          },
          cancelToken: cancelTokenSource.token,
        })
          .then(({ data }) => {
            if (mounted) {
              setLastTurnoverTimestamp(data.timestamp);
            }
          })
          .catch(() => {
            if (mounted) {
              setLastTurnoverTimestamp(null);
            }
          });
      }
    }

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartment, activeSection]);

  useEffect(() => {
    dashboardDispatch({
      type: 'SET_START_DATE',
      payload: moment(selectedDate).startOf('isoWeek'),
    });
    dashboardDispatch({
      type: 'SET_END_DATE',
      payload: moment(selectedDate).endOf('isoWeek'),
    });
  }, [selectedDate]);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    // DASHBOARD TOP DATA
    setDashboardDataIsLoading(true);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/dashboard`, {
      params: {
        departmentId: activeDepartment?.id,
        sectionId: activeSection,
        start: startDate.unix(),
        end: endDate.unix(),
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        if (mounted) {
          setDashboardData(response.data);
          setDashboardDataIsLoading(false);
        }
      })
      .catch((error) => {
        if (mounted) {
          setDashboardDataIsLoading(false);
        }
        console.error(error);
      });
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartment, activeSection, startDate.unix(), endDate.unix()]);

  return (
    <Provider store={store}>
      <Helmet>
        <title>{t('GLOBAL.DASHBOARD')} - Shyfter</title>
      </Helmet>
      <div className={className}>
        <DashboardHeader
          startDate={startDate}
          endDate={endDate}
          selectedDate={selectedDate}
          widgets={[]}
          departmentId={activeDepartment?.id}
        />
        <Spin spinning={dashboardDataIsLoading} size="large">
          <div className="indicators">
            {!isNullOrUndefined(dashboardData.currentClockings) && (
              <div className="indicator">
                <div className="card-shadow center">
                  <h4>{t('DASHBOARD.HEADER.CLOCKING_IN_PROGRESS')}</h4>
                  <p>{dashboardData.currentClockings}</p>
                </div>
              </div>
            )}
            {!isNullOrUndefined(dashboardData.finishedClockings) && (
              <div className="indicator">
                <div className="card-shadow center">
                  <h4>{t('DASHBOARD.HEADER.FINISHED_CLOCKINGS')}</h4>
                  <p>{dashboardData.finishedClockings}</p>
                </div>
              </div>
            )}
            {!isNullOrUndefined(dashboardData.weekClockings) && (
              <div className="indicator">
                <div className="card-shadow center">
                  <h4>{t('DASHBOARD.HEADER.TOTAL_WEEK_CLOCKINGS')}</h4>
                  <p>{dashboardData.weekClockings}</p>
                </div>
              </div>
            )}
          </div>
        </Spin>
        <div style={{ padding: 25 }}>
          <Row gutter={[15, 15]}>
            <Col span={16}>
              <div className="card-shadow widget" style={{ height: 'calc(100vh - 280px)' }}>
                <WeekGeolocation
                  departmentId={activeDepartment?.id}
                  sectionId={activeSection}
                  height="calc(100vh - 280px)"
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="card-shadow widget">
                <Clockings departmentId={activeDepartment?.id} sectionId={activeSection} />
              </div>
            </Col>
          </Row>
        </div>
        {!showModalTrialOver &&
          activeDepartment?.role === 'ADMIN' &&
          isFeatureEnabled(features, FEATURES.PRODUCTIVITY) &&
          !dashboardDataIsLoading && (
            <ModalYesterdayTurnover
              departmentId={activeDepartment?.id}
              lastTurnover={lastTurnoverTimestamp}
              onClose={() => setLastTurnoverTimestamp(null)}
            />
          )}
        <ModalFreemiumOnboarding
          visible={modalFreemiumOnboardingVisible}
          setVisible={setModalFreemiumOnboardingVisible}
        />
      </div>
    </Provider>
  );
};

const DashboardPageStyled = styled(DashboardPage)`
  background-color: ${colors.blueExtraLight};
  min-height: calc(100vh - 50px);

  .indicators {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .indicator {
      display: flex;
      padding: 10px 20px;
      flex: 1;
      min-width: 160px;

      .card-shadow {
        width: 100%;
      }
    }
  }

  .grid {
    display: flex;
    flex-wrap: wrap;

    .drag-item {
      width: 33.333%;
    }
  }

  .isDragging .chart-container {
    display: none;
  }

  .isDragging .leaflet-container {
    display: none;
  }

  .card-shadow {
    justify-content: flex-start;
    position: relative;

    &.center {
      p {
        font-size: 24px;
        line-height: 24px;
        margin: 0;
        padding: 0;
        color: ${colors.grey};
      }
    }

    &.widget {
      h4 {
        margin: 0 -20px 10px;
        padding: 0 20px 10px;
        border-bottom: 1px solid ${colors.greyLight};
      }
    }

    .ant-table-wrapper {
      max-height: 200px;
      overflow: auto;
    }

    .anticon-close {
      position: absolute;
      right: 20px;
    }
  }
`;

export default DashboardProvider;
