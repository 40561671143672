import React from 'react';
import { ISection } from '@/types/section.model';
import styled from 'styled-components';

interface Props {
  className?: string;
  section: ISection | undefined;
}

const ShiftSectionBar: React.FC<Props> = ({ className, section }) => {
  if (section) {
    return <div className={className} style={{ borderRightColor: section.background }} />;
  } else {
    return null;
  }
};

export default styled(ShiftSectionBar)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
  border-right: 3px solid black;
  pointer-events: none;
`;
