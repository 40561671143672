import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IPayPeriod } from '@/types/pay-periods/pay-period.model';
import { getWindowSize, handleError } from '@/utils';
import { Button, DatePicker, Drawer, Form, Select, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
  activePayPeriod: IPayPeriod | null;
  setActivePayPeriod: React.Dispatch<React.SetStateAction<IPayPeriod | null>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  getPayPeriods: () => void;
}

const DrawerPayPeriod: React.FC<Props> = ({
  className,
  visible,
  activePayPeriod,
  setVisible,
  setActivePayPeriod,
  getPayPeriods,
}) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId, userCategories },
    dispatch,
  } = useContext(AppContext);
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedStatusIds, setSelectedStatusIds] = useState<string[]>([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    form.resetFields();

    if (activePayPeriod) {
      form.setFieldsValue({
        ...activePayPeriod,
        start: moment(activePayPeriod.start),
        end: moment(activePayPeriod.end),
        month: moment(activePayPeriod.month),
        smooth_contract_hours: String(activePayPeriod.smooth_contract_hours),
      });
      setSelectedStatusIds(activePayPeriod.status);
    } else {
      form.setFieldsValue({
        month: moment(moment().format('YYYY-MM')),
      });
      setSelectedStatusIds([]);
    }
  }, [visible, activePayPeriod]);

  const onClose = () => {
    setVisible(false);
    if (activePayPeriod) {
      setActivePayPeriod(null);
    }
  };

  const onStatusChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    const value = e.target.value;

    if (checked) {
      setSelectedStatusIds([...selectedStatusIds, value]);
    } else {
      setSelectedStatusIds(selectedStatusIds.filter((id) => id !== value));
    }
  };

  const onFinish = (values: any) => {
    setLoading(true);
    if (!activePayPeriod) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v3/pay-periods`,
          {
            ...values,
            start: values.start.format('YYYY-MM-DD'),
            end: values.end.format('YYYY-MM-DD'),
            month: values.month.format('YYYY-MM'),
            smooth_contract_hours: values.smooth_contract_hours == 'true',
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          getPayPeriods();
          message.success(t('REPORTS.PAY_PERIODS.CREATED_MESSAGE'));
          setVisible(false);
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/v3/pay-periods/${activePayPeriod.id}`,
          {
            ...values,
            start: values.start.format('YYYY-MM-DD'),
            end: values.end.format('YYYY-MM-DD'),
            month: values.month.format('YYYY-MM'),
            smooth_contract_hours: values.smooth_contract_hours == 'true',
          },
          {
            params: {
              departmentId: activeDepartmentId,
            },
          },
        )
        .then(({ data }) => {
          getPayPeriods();
          message.success(t('REPORTS.PAY_PERIODS.EDITED_MESSAGE'));
          setVisible(false);
          setActivePayPeriod(null);
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  console.log(userCategories);

  return (
    <Drawer
      className={className}
      visible={visible}
      maskClosable
      onClose={onClose}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
      title={activePayPeriod ? t('REPORTS.PAY_PERIODS.EDIT_PERIOD') : t('REPORTS.PAY_PERIODS.CREATE_PERIOD')}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="row">
          <Form.Item name="start" className="item" label={t('GLOBAL.START_DATE')} rules={[{ required: true }]}>
            <DatePicker size="large" className="datepicker" />
          </Form.Item>
          <Form.Item name="end" className="item" label={t('GLOBAL.END_DATE')} rules={[{ required: true }]}>
            <DatePicker size="large" className="datepicker" />
          </Form.Item>
        </div>
        <Form.Item name="month" label={t('GLOBAL.MONTH')} rules={[{ required: true }]}>
          <DatePicker className="datepicker" size="large" picker="month" format="MMMM YYYY" />
        </Form.Item>
        <Form.Item
          name="smooth_contract_hours"
          label={t('REPORT_TEMPLATES.CALCULATION_METHOD')}
          rules={[{ required: true }]}
        >
          <Select placeholder={t('REPORT_TEMPLATES.CALCULATION_METHOD')} size="large">
            <Select.Option value="false">{t('REPORTS.PAY_PERIODS.TYPES.FULL')}</Select.Option>
            <Select.Option value="true">{t('REPORTS.PAY_PERIODS.TYPES.SMOOTHED')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="status" label={t('GLOBAL.STATUS')} rules={[{ required: true }]}>
          <Select mode="multiple" size="large" placeholder={t('GLOBAL.STATUS')}>
            {userCategories.map((status) => (
              <Select.Option value={status.id!}>{status.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose} disabled={loading} size="large">
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading} size="large">
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default styled(DrawerPayPeriod)`
  .row {
    display: flex;
    align-items: center;
    gap: 10px;

    .item {
      flex: 1;
    }
  }
  .datepicker {
    width: 100%;
  }

  .status {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .name {
      display: flex;
      align-items: center;
      gap: 5px;

      .color {
        width: 10px;
        height: 10px;
        border-radius: 10px;
      }
    }

    .description {
      font-size: 12px;
      color: ${colors.grey};
    }

    .ant-checkbox-wrapper {
      margin: 0;
    }
  }
`;
